import React from 'react'
import { Link } from 'react-router-dom'

import Table from '../../components/Table'
import Button from '../../components/form/Button'

import Header from '../../layout/Header'
import Container from '../../layout/Container'

import edit from '../../assets/icons/edit.svg'
import {useGetIDBValues} from "../../helpers/getIDBKey";

interface Props {
	toggleSidebar: () => void,
	router: any,
}

interface State {
	search: string,
	rigs: any,
	spts: any,
	company: any,
}

class RigsCompanies extends React.Component<Props, State> {
	constructor(props: any) {
		super(props)

		this.state = {
			search: '',
			rigs: [],
			spts: [],
			company: {
				guid: this.props.router.match.params.id
			}
		}
	}

	rigSptSelect = () => {

	}

	componentDidMount() {
		this.getCacheValues()
	}

	getCacheValues = () => {
		let cachedValues = useGetIDBValues
		cachedValues('spt-hammers/company/' + this.state.company.guid, (data:any) => {
			this.setState({
				spts: data
			})
		}).then()
		cachedValues('rigs/company/' + this.state.company.guid, (data:any) => {
			this.setState({
				rigs: data
			})
		}).then()
	}


	handleSearchUpdate = (value: string) => {
		this.setState({search: value})

	}

	render() {
		return (
			<>
				<Header toggleSidebar={this.props.toggleSidebar} />

				<Container className="mt-10">

					{/* build the rigs table manually as it ha dropdowns*/}
					<div className="mb-6">

						<table className="table-auto w-full">
							<thead className="font-bold">
							<tr>
								<td className="p-0 bg-grey-mid border border-grey-mid rounded-tl-sm null">
									<div className="px-2 py-2 border-r border-grey-light">Rig Name</div>
								</td>
								<td className="p-0 bg-grey-mid border border-grey-mid null null">
									<div className="px-2 py-2 border-r border-grey-light">Plant Type</div>
								</td>
								<td className="p-0 bg-grey-mid border border-grey-mid null null">
									<div className="px-2 py-2 border-r border-grey-light">Plant ID</div>
								</td>
								<td className="p-0 bg-grey-mid border border-grey-mid null null">
									<div className="px-2 py-2 border-r border-grey-light">Test Date Expiry</div>
								</td>
								<td className="p-0 bg-grey-mid border border-grey-mid null null">
									<div className="px-2 py-2 border-r border-grey-light">Current SPT ID</div>
								</td>
								<td className="p-0 bg-grey-mid border border-grey-mid null rounded-tr-sm">
									<div className="px-2 py-2 null"></div>
								</td>
							</tr>
							<tr className="h-2"></tr>
							</thead>
							<tbody>
							{this.state.rigs.map((values:any, index:number) => {
								return (
									<tr className="border border-grey-mid bg-white mb-1" key={index}>
										<td className="p-0 border-r border-grey-mid">
											<div className="px-2 py-2">{ values.name }</div>
										</td>
										<td className="p-0 border-r border-grey-mid">
											<div className="px-2 py-2">{ values.type }</div>
										</td>
										<td className="p-0 border-r border-grey-mid">
											<div className="px-2 py-2">{ values.plant_id }</div>
										</td>
										<td className="p-0 border-r border-grey-mid">
											<div className="px-2 py-2">{ values.test_date_expiry }</div>
										</td>
										<td className="p-0 border-r border-grey-mid">
											<div className="px-2 py-2">
												<Link to={'/rigs/update/' + values.guid + '/' + this.state.company.guid}>
													<select
														name=""
														id=""
														disabled={true}
														className="border border-grey-mid w-32 p-2 rounded"

													>
														<option value="{ values.guid }">
															{ this.state.spts.map((spt:any) => {
																return (
																	(spt.id === values.spt_id) ? spt.id_ref : ''
																)
															})}
														</option>
													</select>
												</Link>
											</div>
										</td>
										<td className="p-0 border-r border-grey-mid text-center">
											<div className="px-2 py-2">
												<Link to={'/rigs/update/' + values.guid + '/' + this.state.company.guid}>
													<img className={`inline`} src={edit} width="20" alt="" />
												</Link>
											</div>
										</td>
									</tr>
								)
							})}
							<tr className="h-2"></tr>
							</tbody>
						</table>
					</div>

					<Table
						header={['SPD ID Number', 'SPT Rod Type', 'SPT Energy Ratio', 'SPT Calibration Date', '']}
						objectColumns={['id_ref', 'rod_type', 'energy_ratio', 'calibration_date']}
						items={this.state.spts}
						isObject={true}
						editingUrl={'spt/update'}
						className="mb-6" />

					<div className="flex items-center">
						<div className="flex items-center">
							<Button theme="primary" className="mr-6">Save</Button>

							<Button theme="secondary">Cancel</Button>
						</div>

						<div className="flex items-center ml-auto">
							<Link to={'/spt/create/' + this.state.company.guid}><Button theme="primary" className="mr-6">Add SPT</Button></Link>

							<Link to={'/rigs/create/' + this.state.company.guid}><Button theme="primary">Add Rig</Button></Link>
						</div>
					</div>
				</Container>
			</>
		)
	}
}

export default RigsCompanies
