import React, { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'

// import Button from '../form/Button'

import { updateSelectedProject } from '../../actions/SelectedProject'

// import icon from '../../assets/icons/go.svg'

type Props = {
	location: string,
	number: string,
	id: string,
	name: string,
	className?: string,
	router: any,
	company: string,
	[x: string]: any;
}

const defaultProps: Props = {
	location: '',
	number: '',
	id: '',
	name: '',
	className: '',
	router: null,
	company: ''
}

const Project: FunctionComponent<Props> = ({ location, company, number, id, name, startdate, className, link, router, ...rest }) => {
	const dispatch = useDispatch()

	const selectProject = (project: object) => {
		dispatch(updateSelectedProject(project))
	}

	const handleClickProject = () => {
		selectProject({
			guid: id,
			location: location,
			number: number,
			name: name,
			company: company,
		})

		router.history.push(`projects/dashboard/${id}/company/${company}`)
	}


		// Create a Date object from the input date string
		const inputDate = new Date(startdate);

		// Get the day, month, and year components
		const day = inputDate.getDate().toString().padStart(2, '0');
		const month = (inputDate.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
		const year = inputDate.getFullYear();

		// Format the date as "dd/mm/yyyy"
		const formattedDateStr = `${day}/${month}/${year}`

	return (
		<>
		{/* <div onClick={handleClickProject} className={`rounded-md md:rounded-lg border-2 md:border-4 border-orange pt-2 px-3 bg-white ${className}`} {...rest}>
			<div className="text-xs w-full">Site Location
			<span className="text-xs text-right float-right font-bold text-blue">{formattedDateStr}</span>
			</div>

			<div className="text-base sm:text-lg mb-1 font-bold" style={ {lineHeight: 1.1} }>{location}</div>

			<div className="text-xs">Project No.</div>

			<div className="text-base sm:text-lg mb-1 font-bold">{number}</div>

			<div className="text-xs text-right w-full font-bold text-blue">{formattedDateStr}</div>

			<Button size="xs" icon={icon} iconPosition="right" onClick={handleClickProject}>Go</Button>

			<div className="flex-grow"></div>

			<div className="mx-auto border-t-2 md:border-t-4 border-orange w-16 rounded-t"></div>
		</div> */}

		<div onClick={handleClickProject} className={`relative grid grid-cols-2 md:grid-cols-1 rounded-md md:rounded-lg border-2 md:border-2 border-orange pt-1 pl-3 bg-white ${className}`} {...rest}>
			<div>
				<div className="text-xs w-full">Site Location</div>
				<div className="text-base sm:text-lg mb-1 font-bold" style={{lineHeight: 1.1}}>{location}</div>
			</div>
			<div>
				<div className="text-xs w-full">Project No.
					{/* <span className="text-xs text-right float-right font-bold text-blue">{formattedDateStr}</span> */}
					</div>
				<div className="text-base sm:text-lg mb-2 font-bold" style={{lineHeight: 1.1}}>{number}</div>
			</div>
			{/* <div className="flex-grow col-span-2 md:col-span-1"> */}
			{/* <div className="mx-auto border-t-2 md:border-t-4 border-orange w-16 rounded-t"></div> */}
			{/* <div className="ml-auto md:mx-auto bg-orange w-16 rounded-tl-md md:rounded-t text-center font-bold text-white h-3.5 col-span-2 md:col-span-1" style={{fontSize: '10px'}}>{formattedDateStr}</div> */}
			<div className="absolute bottom-0 left-0 right-0">
				<div className="-mt-2 ml-auto bg-orange w-16 rounded-tl-md text-center font-bold text-white h-3.5 col-span-2 md:col-span-1" style={{fontSize: '10px'}}>{formattedDateStr}</div>
			</div>
			{/* </div> */}
		</div>
		</>
	)
}

Project.defaultProps = defaultProps

export default Project
