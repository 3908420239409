import React, { FunctionComponent, useState } from 'react'

import WorkflowSteps from '../../WorkflowSteps'
import Modal from '../../Modal'
import Button from '../../form/Button'
import NumberInput from '../../NumberInput'
import MultiNumberInput from '../../MultiNumberInput'
// import InputGroup from '../../form/InputGroup'

import { v4 as uuidv4 } from 'uuid';
import stepStatus from '../../../helpers/stepStatus'
import { useISODate } from "../../../helpers/useISODate";

import PrintLabel from '../../../components/PrintLabel'
import { updateSelectedProject } from '../../../actions/SelectedProject'
import { useSelector, useDispatch } from 'react-redux'
import { useGetIDBValues } from "../../../helpers/getIDBKey"
import { useGetLoggedInUser } from '../../../helpers/getLoggedInUser'
import warningIcon from '../../../assets/icons/warning.png'

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: string,
	title: string,
	defaultValues?: any,
	selectedDate?: string,
	update?: boolean,
	projectId?: string,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => { },
	onComplete: () => { },
	borehole: '',
	title: '',
	defaultValues: {
		guid: '',
		depth_to_top: '',
		depth_to_base: '',
		casing_depth: '',
		water_depth: '',
		remarks: '',
	},
	selectedDate: '',
}

const useForceUpdate = () => {
	const [updateValue, setUpdateValue] = useState(0);
	return () => setUpdateValue(value => value + 1)
}

const Samples: FunctionComponent<Props> = ({
	onClose, update, onComplete, borehole, title,
	defaultValues, selectedDate, casing_preset,
	from_type, addSampleData,
	setShowSamplesCreateD, setShowSamplesCreateB, setShowSamplesCreateWS, new_type_number, new_type_number_sequencial, numbering_type, 
	spt_number, cpt_number, spt_number_sequencial, cpt_number_sequencial, u_number, sampled_by,
	...rest }) => {

	// const [currentStep, setCurrentStep] = useState(1)

	// const [id, setId] = useState(defaultValues.id)
	// const [guid, setGuid] = useState(defaultValues.guid)
	// const [depth_to_top, setDepthFrom] = useState(defaultValues.depth_to_top)
	// const [depth_to_base, setDepthTo] = useState(defaultValues.depth_to_base)
	// const [number_blows, setCasing] = useState(defaultValues.number_blows)
	// const [water_depth, setWater] = useState(defaultValues.water_depth)
	// const [remarks, setComment] = useState(defaultValues.general_remarks)
	// const [time_of_action, setTimeOfAction] = useState(defaultValues.time_of_action)

	// const isoDate = selectedDate ? `${new Date(selectedDate).toISOString().split('T')[0]} 09:00:00` : new Date().toISOString().split('.')[0]

	const [currentStep, setCurrentStep] = useState(4)
	const date = useSelector((state: any) => state.date)
	var labelDate = date.toLocaleDateString("en-UK")
	const [sampler, setSampler] = useState(sampled_by)

	const [fromType, setFromType] = useState(from_type)
	const [uType, setUType] = useState(addSampleData.utype)

	console.log('fromType:', fromType)
	console.log('cpt_number_sequencial:', cpt_number_sequencial)

	const [id, setId] = useState(defaultValues.id)
	const [guid, setGuid] = useState(defaultValues.guid)

	let depth_to_top_temp, depth_to_base_temp, casing_depth_temp, depth_of_water_temp

	if (fromType === 'GroundWater') {
		depth_to_top_temp = addSampleData.depth_to_water
		depth_to_base_temp = ''
		casing_depth_temp = addSampleData.casing_depth
		depth_of_water_temp = addSampleData.depth_of_water
	} else {
		depth_to_top_temp = addSampleData.depth_to_top
		depth_to_base_temp = addSampleData.depth_to_base
		casing_depth_temp = addSampleData.casing_depth
		depth_of_water_temp = addSampleData.depth_of_water
	}



	const [depth_to_top, setDepthFrom] = useState(depth_to_top_temp)
	const [depth_to_base, setDepthTo] = useState(depth_to_base_temp)
	const [remarks, setComment] = useState(defaultValues.general_remarks)

	// const [casing_depth, setCasing] = useState(defaultValues.casing_depth)
	const [casing_depth, setCasing] = useState(casing_depth_temp)
	// const [number_blows, setNumberOfBlows] = useState(defaultValues.number_blows)
	const [water_depth, setWater] = useState(depth_of_water_temp)

	// console.log('**************************Inside Samples Panel*****************************')
	// console.log('id: ', defaultValues.id, id)
	// console.log('guid: ', defaultValues.guid, guid)
	// console.log('depth_to_top: ', addSampleData.depth_to_top, depth_to_top, typeof depth_to_top)
	// console.log('depth_to_base: ', addSampleData.depth_to_base, typeof depth_to_base)
	// console.log('remarks: ', addSampleData.general_remarks, depth_to_base, typeof remarks)
	// // console.log('casing_depth: ', defaultValues.casing_depth, casing_depth)
	// console.log('casing_depth: ', addSampleData.casing_depth, casing_depth, typeof casing_depth)
	// console.log('water_depth: ', addSampleData.depth_of_water, water_depth, typeof water_depth)
	// console.log('casing_preset: ', casing_preset, casing_preset)
	// console.log('fromType: ', fromType)
	// console.log('***************************************************************************')

	const [saveButtonText, setButtonText] = useState('Save')
	const [saveButtonColor, setButtonColor] = useState('success')

	const [showLabel, setShowLabel] = useState(false)

	const [showSaveCancelButtons, setShowSaveCancelButtons] = useState(false)

	const closeModal = () => {
		// Modal.toggleModal()
	};

	const [time_of_action, setTimeOfAction] = useState(defaultValues.time_of_action)

	const isoDate = selectedDate ? `${new Date(selectedDate).toISOString().split('T')[0]} 09:00:00` : new Date().toISOString().split('.')[0]

	const selectedProject = useSelector((state: any) => state.selectedProject)
	const dispatch = useDispatch()

	const loggedInUser = useGetLoggedInUser()

	// const updateEmptyProject = () => {
	// 	let cachedValues = useGetIDBValues
	// 	cachedValues('projects', (data:any) => {
	// 		for (const project of data) {
	// 			if (project.guid === projectId) {
	// 				dispatch(updateSelectedProject({
	// 					guid: project.guid,
	// 					name: project.name,
	// 					location: project.location,
	// 					number: project.project_identifier,
	// 				}))
	// 			}
	// 		}
	// 	}).then()
	// }
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	const DeleteButton = () => {
		if (!update) return null;

		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	const deleteRecordConfirm = () => {
		setDeleteConfirmOpen(false)
		// onComplete()
	}

	var labelType, defaultLabelType

	var sampleInitial = title.charAt(0);
	if (sampleInitial === 'D') { defaultLabelType = "D"; labelType = 'general'; }
	else if (sampleInitial === 'B') { defaultLabelType = "B"; labelType = 'general'; }
	else if (sampleInitial === 'E') { defaultLabelType = "ES"; labelType = 'general'; }
	else if (sampleInitial === 'W') { defaultLabelType = "WS"; labelType = 'general'; }
	else if (sampleInitial === 'L') { defaultLabelType = "L"; labelType = 'topImage'; }
	else if (sampleInitial === 'U') { defaultLabelType = "U"; labelType = 'topImage'; }
	else if (sampleInitial === 'C') { defaultLabelType = "C"; labelType = 'topImage'; }
	else if (sampleInitial === 'P') { defaultLabelType = "PS"; labelType = 'topImage'; }
	else { defaultLabelType = "XXX"; labelType = 'general'; }

	let sampleLabel
	
	// console.log('new_type_number:', new_type_number)

	var type_number_output = 0
	if (update) {
		// console.log('update?:', update, defaultValues.type_number)
		if (fromType === 'GroundWater') {
			sampleLabel = 'WS-' + defaultValues.type_number
			type_number_output = defaultValues.type_number
		} else {
			sampleLabel = defaultLabelType + "-" + defaultValues.type_number
			type_number_output = defaultValues.type_number
		}	
	} else {
		if (fromType === 'GroundWater') {
			if (numbering_type === 'default') {
				sampleLabel = 'WS-' + new_type_number
				type_number_output = new_type_number
			} else {
				sampleLabel = 'WS-' + new_type_number_sequencial 
				type_number_output = new_type_number_sequencial
			}
		} else if (fromType === 'SPT') {
			if (numbering_type === 'default') {
				sampleLabel = defaultLabelType + "-" + new_type_number 
				type_number_output = new_type_number
			} else {
				sampleLabel = defaultLabelType + "-" + new_type_number_sequencial 
				type_number_output = new_type_number_sequencial
			}
		} else if (fromType === 'CPT') {
			if (numbering_type === 'default') {
				sampleLabel = defaultLabelType + "-" + new_type_number 
				type_number_output = new_type_number
			} else {
				sampleLabel = defaultLabelType + "-" + new_type_number_sequencial 
				type_number_output = new_type_number_sequencial
			}
		} else {
			if (numbering_type === 'default') {
				sampleLabel = defaultLabelType + "-" + new_type_number 
				type_number_output = new_type_number
			} else {
				sampleLabel = defaultLabelType + "-" + new_type_number_sequencial 
				type_number_output = new_type_number_sequencial
			}
		}	
	}

	// console.log('sampleLabel:', sampleLabel)

	let label_depth_from = ''
	let depth_to_top2DP = ''
	let depth_to_base2DP = ''
	let printDepth = ''

	// console.log('sampleInitial:', sampleInitial)

	if(from_type === 'U'){
		depth_to_top2DP = parseFloat(depth_to_base).toFixed(2)
		depth_to_base2DP = (parseFloat(depth_to_base) + 0.05).toFixed(2)
		console.log('depth_to_base:', typeof depth_to_base)
		console.log('depth_to_base2DP:', depth_to_base2DP)
		label_depth_from = parseFloat(depth_to_base).toFixed(2)
		printDepth = label_depth_from + ' - ' + depth_to_base2DP
	} else {
		if (depth_to_top === 'GL' || depth_to_top === 'G' || depth_to_top === 0 ||
			depth_to_top === 0.00 || depth_to_top === '0.00' || depth_to_top === '0') {
			depth_to_top2DP = '0'
			label_depth_from = 'GL'
		} else {
			depth_to_top2DP = parseFloat(depth_to_top).toFixed(2)
			label_depth_from = depth_to_top2DP
		}
		if (depth_to_base === '') {
			depth_to_base2DP = ''
			printDepth = label_depth_from
		} else {
			depth_to_base2DP = parseFloat(depth_to_base).toFixed(2)
			printDepth = label_depth_from + ' - ' + depth_to_base2DP
		}
	}



	// if (depth_to_base == '') {
	// 	depth_to_base2DP = ''
	// 	printDepth = label_depth_from
	// } else {
	// 	depth_to_base2DP = parseFloat(depth_to_base).toFixed(2)
	// 	printDepth = label_depth_from + '-' + depth_to_base2DP
	// }

	// if (casing_depth === ''){var casing_depth2DP = ''} 
	// else if (casing_depth == 'no casing'){casing_depth2DP = 'no casing'} 
	// else {casing_depth2DP = parseFloat(casing_depth).toFixed(2)}

	if (!casing_preset) {
		var casing_preset_value = 'no casing'
	}
	else {
		casing_preset_value = parseFloat(casing_preset).toFixed(2)
	}

	// if (water_depth === ''){var depth_of_water2DP = ''}
	// else if (water_depth == 'dry') {depth_of_water2DP = 'dry'}
	// else if (water_depth == 'damp') {depth_of_water2DP = 'damp'}
	// else {depth_of_water2DP = parseFloat(water_depth).toFixed(2)}


	let remarksTemp = ''
	// console.log('fromType:', fromType)
	if (fromType === 'GroundWater') {
		remarksTemp = 'Groundwater sample'
	} else if (fromType === 'U') {
		remarksTemp = 'Shoe Sample from U' + uType + "-" + u_number
	} else if (fromType === 'SPT') {
		if (numbering_type === 'default') {
			remarksTemp = 'Sample from ' + fromType + '-' + spt_number
		} else { 
			remarksTemp = 'Sample from ' + fromType + '-' + spt_number_sequencial
		}
	} else if (fromType === 'CPT') {
		if (numbering_type === 'default') {
			remarksTemp = 'Sample from ' + fromType + '-' + cpt_number
		} else { 
			remarksTemp = 'Sample from ' + fromType + '-' + cpt_number_sequencial
		}
	}
	let outputRemarks
	if (remarks === '') { outputRemarks = remarksTemp } else { outputRemarks = remarks }


	const previous = () => {
		if (currentStep > 1)
			setCurrentStep(currentStep - 1)
		setButtonText('Continue')
		setButtonColor('primary')
	}

	const next = () => {
		if (currentStep < 4) {
			setCurrentStep(currentStep + 1)
			if (currentStep === 3) {
				setButtonText('Save')
				setButtonColor('success')
			} else {
				setButtonText('Continue')
				setButtonColor('primary')
			}
			return
		}

		if (!depth_to_top) {
			//do nothing
		} else {

			onComplete({
				// id: id,
				guid: update ? guid : uuidv4(),
				depth_to_top: parseFloat(depth_to_top).toFixed(2), //2DP, // depth_to_top // parseFloat(depth_to_top).toFixed(2),
				depth_to_base: depth_to_base2DP, //parseFloat(depth_to_base).toFixed(2), //.toFixed(2), //2DP,
				casing_depth: casing_depth, //2DP,
				water_depth: water_depth ?? '',//depth_of_water2DP, 
				general_remarks: remarksTemp,
				type_number: type_number_output,
				time_of_action: time_of_action ? time_of_action : isoDate,
				update: update ? true : false,
				// casing_depth: casing_depth, //2DP,
				// number_blows: number_blows,
				// remarks: remarks,
				// remarks: remarksTemp,
				// description: '',
				// prep_details: '',
				// duration: '00:00:00',
				// sample_length: 0,
				// userActivity: 'Drilling',
			})

		}
	}

	const MissingRequiredField = () => {
		if (currentStep === 4 && !depth_to_top) return (
			<div className="text-center bg-sptSB border border-red font-bold rounded mb-2 py-1 text-xs md:text-sm lg:text-base">
				<img src={warningIcon} alt="info" width="20" className="inline-block mr-1 pb-1" />
				Required field: 'Depth From'
			</div>
		)

		return null
	}


	return (
		<Modal title={title} subtitle={borehole} toggleModal={onClose} confirm={false}  >

			<div className="flex flex-col flex-grow px-1 sm:px-2 md:px-10">
				<WorkflowSteps steps={[
					{
						label: 'Depth',
						status: stepStatus(1, currentStep),
						onClick: () => (setCurrentStep(1), setButtonText('Continue'), setButtonColor('primary')),
					}, {
						label: 'Casing',
						status: stepStatus(2, currentStep),
						onClick: () => (setCurrentStep(2), setButtonText('Continue'), setButtonColor('primary')),
					}, {
						label: 'Water',
						status: stepStatus(3, currentStep),
						onClick: () => (setCurrentStep(3), setButtonText('Continue'), setButtonColor('primary')),
					}, {
						label: 'Label',
						status: stepStatus(4, currentStep),
						onClick: () => (setCurrentStep(4), setButtonText('Save'), setButtonColor('success')),
					},
				]} className="mb-2" />

				<div className="flex-1 ">
					{currentStep === 1 &&

						<MultiNumberInput
							title="Depth (m)"
							values={
								[
									{ defaultValue: depth_to_top, onUpdate: setDepthFrom, label: 'From' },
									{ defaultValue: depth_to_base, onUpdate: setDepthTo, label: 'To' },
								]
							}
							className="text-base sm:text-xl md:text-xl"
							inputCSS="text-4xl sm:text-4xl md:text-3xl lg:text-4xl h-16 sm:h-16 mb-4"
						/>

					}

					{currentStep === 2 &&

						<NumberInput onUpdate={(value: any) => setCasing(value)}
							title="Enter Casing Depth (m)"
							defaultValue={casing_depth}
							className="mb-10 text-xl md:text-2xl leading-5 md:leading-8"
							presets={[
								{ value: casing_preset_value, label: casing_preset_value },
							]}
							next={next}
						/>
					}

					{currentStep === 3 &&
						<NumberInput onUpdate={(value: any) => setWater(value)}
							title="Enter Water Depth (m)"
							defaultValue={water_depth}
							className="mb-10 text-xl"
							presets={[
								{ value: 'dry', label: 'dry' },
								{ value: 'damp', label: 'damp' },
							]}
							next={next}
						/>
					}

					{currentStep === 4 &&
						// <InputGroup label="Add a Comment" 
						// onChange={(value: any) => setComment(value)} 
						// title="Add Comment"
						// value={remarks} 
						// className="mb-10 w-full text-center text-xl" />
						<>
							<div className="flex-col ">

								<div className="flex-none text-xl text-center w-full bg-sptTB rounded mb-4 pb-4 py-2">
									Create {defaultLabelType} Sample?

									<div className="text-xs md:text-base text-center mt-2 mb-3 mx-auto bg-white rounded p-1 text-charcoal w-11/12 md:w-2/3">
										Sample: {defaultLabelType}-{type_number_output} | Depth From: {depth_to_top2DP} | Depth To: {depth_to_base2DP} <br/> Casing: {casing_depth}  |
										Water: {water_depth}  | Notes: {remarksTemp}
									</div>

									<div className="flex w-full justify-center">
										<Button theme="secondary"
											size="lg"
											className="px-4 sm:px-10"
											onClick={() => {
												setShowLabel(false)
												onClose()
												if (fromType === 'GroundWater') {
													setShowSamplesCreateWS(false)
												} else if (fromType === 'SPT') {
													setShowSamplesCreateD(false)
												} else if (fromType === 'CPT') {
													setShowSamplesCreateB(false)
												} else if (fromType === 'U') {
													setShowSamplesCreateD(false)
												}
											}}>
											No
										</Button>
										<Button theme={saveButtonColor}
											size="lg"
											className="ml-4 px-8 sm:px-10 w-32 sm:w-60"
											onClick={() => {
												setShowLabel(true)
												setShowSaveCancelButtons(true)
												}}>
											Yes
										</Button>
									</div>

								</div>

								{showLabel &&
									<div className="w-full grow">
										<PrintLabel
											location={selectedProject.location}
											project={selectedProject.number}
											borehole={borehole}
											sampledate={labelDate}
											// user={loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name)}
											sampled_by={sampler}
											sample={sampleLabel}
											depth={printDepth}
											size='printLabel'
										/>
									</div>
								}
							</div>
						</>
					}

				</div>

				<MissingRequiredField />


				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">


							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?

								<div className="flex mt-4">
									<Button theme="secondary" className="mr-5 text-sm sm:text-base px-3.5 md:px-7" onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>

									<Button onClick={deleteRecordConfirm} className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">Delete</Button>
								</div>
							</div>


						</div>
					</div>
				}

				<div className="flex">
					{/* <Button theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button> */}
					<DeleteButton />

					{showSaveCancelButtons &&
						<div className="flex ml-auto">
							<Button theme="primary" 
								size="lg" 
								className="px-4 sm:px-10" 
								onClick={() => {
									if (fromType === 'GroundWater') {
										setShowSamplesCreateWS(false)
									} else if (fromType === 'SPT') {
										setShowSamplesCreateD(false)
									} else if (fromType === 'CPT') {
										setShowSamplesCreateB(false)
									} else if (fromType === 'U') {
										setShowSamplesCreateD(false)
									}
								}}
								>
									Cancel
								</Button>
							<Button theme={saveButtonColor}
								size="lg"
								className="ml-4 px-8 sm:px-10 w-32 sm:w-60"
								onClick={() => next()}
								>
									{saveButtonText}
							</Button>
						</div>
					}
				</div>
			</div>
		</Modal>
	)
}

Samples.defaultProps = defaultProps

export default Samples
