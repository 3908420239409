import React, { FunctionComponent, useState, Component } from 'react'

import Modal from '../components/Modal'
import { Link } from 'react-router-dom'

import warningIcon from '../assets/icons/warning.png'
import checkIcon from '../assets/icons/check.svg'
import CrewList from './CrewList'
import RigSelect from './rigs/RigsSelect'
import Button from '../components/form/Button'

import { useGetLoggedInUser } from '../helpers/getLoggedInUser'

import { setIDB } from "../helpers/setIDBKey";
import {get,set} from "idb-keyval";

type Props = {
	onClose: any,
	// onComplete: any,
	defaultValues?: any,
	projectId?: string,
	[x: string]: any;
	holeType: string,
}

const defaultProps: Props = {
	onClose: () => {},
	defaultValues: {
		guid: '',
	},
	holeType: '',
}


const CurrentInformation: FunctionComponent<Props> = ({ onClose, defaultValues, holeType, holeName,  ...rest }) => {

    // console.log('Hole Type: ', holeType)
    // console.log('Project: ', defaultValues.project)
    // console.log('Location: ', defaultValues.location)
    // console.log('Hole name: ', holeName)

	const loggedInUser = useGetLoggedInUser()
    // console.log('loggedInUser: ', loggedInUser)

    const rigs_link = defaultValues.rigs_link
    // console.log('rigs_link: ', defaultValues.rigs_link)
    const companyGuid = defaultValues.companyGuid
    const projectGuid = defaultValues.projectGuid
    const rig_name = defaultValues.rig_name
	console.log('Rig Name (from Information): ', rig_name)
    const second_man = defaultValues.second_man
    const daily_plant_check = defaultValues.plantCheck
    
    const project_start_date = defaultValues.project_start_date
    const site_description = defaultValues.site_description
    const key_site_features = defaultValues.key_site_features
    const site_history = defaultValues.site_history
    const key_hands = defaultValues.key_hands
    const expected_strata = defaultValues.expected_strata
    const notes = defaultValues.notes
    const equipment_required = defaultValues.equipment_required
    const project_client = defaultValues.project_client
    const client_contact = defaultValues.client_contact
    const client_phone = defaultValues.client_phone
    const client_email = defaultValues.client_email
    const engineer_name = defaultValues.engineer_name
    const engineer_phone = defaultValues.engineer_phone
	// console.log('Project Start Date (from Information): ', project_start_date)
    const [showHoleInformation, setShowHoleInformation] = useState(true)
    const [showSiteInformation, setShowSiteInformation] = useState(false)
    const [showContactInformation, setShowContactInformation] = useState(false)

	const [holeInformationButtonColor, setHoleInformationButtonColor] = useState('orange')
	const [siteInformationButtonColor, setSiteInformationButtonColor] = useState('primary')
	const [contactInformationButtonColor, setContactInformationButtonColor] = useState('primary')
    
    

	let hole = ''
	let plantCheck = false
	let requiredDepth = false
    let rig = false
	let userType = ''
    let secondMan = false
    let plantCheckCP =false
    let plantCheckRT =false
    let plantCheckWSDP =false
    let plantCheckLink = ''
	if (holeType ==='CP') {
        hole = 'Cable Borehole'; plantCheck=true; rig=true; 
        userType = 'Driller'; secondMan=true; requiredDepth=true;
        plantCheckCP = true;
        plantCheckLink = '/daily-plant-check-list-cable-percussive/' + companyGuid + '/' + projectGuid
    }
	else if (holeType ==='RT') {
        hole = 'Rotary Borehole'; plantCheck=true; rig=true; 
        userType = 'Driller'; secondMan=true; requiredDepth=true;
        plantCheckRT = true;
        plantCheckLink ='/daily-plant-check-list-rotary/' + companyGuid + '/' + projectGuid
    }
	else if (holeType ==='WS') {
        hole = 'Window Sample'; plantCheck=true; rig=true; 
        userType = 'Driller'; secondMan=true; requiredDepth=true;
        plantCheckWSDP = true;
        plantCheckLink ='/daily-plant-check-list-window-sample/' + companyGuid + '/' + projectGuid
    }
	else if (holeType ==='TP') {
        userType = 'Engineer'; hole = 'Trialpit'; requiredDepth=true;
    }
	else if (holeType ==='DP') {
        hole = 'Dynamic Probe'; plantCheck=true; rig=true; 
        userType = 'Driller'; secondMan=true; requiredDepth=true;
        plantCheckWSDP = true;
        plantCheckLink ='/daily-plant-check-list-window-sample/company/' + companyGuid + '/' + projectGuid
    }
	else if (holeType ==='HT') {hole = 'Head Test'; userType = 'Engineer'; }
	else if (holeType ==='LG') {hole = 'Borehole'; userType = 'Engineer';}
	else if (holeType ==='SA') {hole = 'Soakaway'; userType = 'Engineer';}
	else if (holeType ==='CB') {hole = 'CBR'; userType = 'Engineer';}
	else if (holeType ==='PT') {hole = 'Plate Test'; userType = 'Engineer';}
	else if (holeType ==='TR') {hole = 'TRL'; userType = 'Engineer';}

    const [showPlantCheck, setShowPlantCheck] = useState(plantCheck)
    const [showRig, setShowRig] = useState(rig)
    const [showSecondMan, setShowSecondMan] = useState(secondMan)
    const [showRequiredDepth, setShowRequiredDepth] = useState(requiredDepth)

    const [showCrewList, setShowCrewList] = useState(false)
    const openCrewList = () => setShowCrewList(true)

    const [showRigs, setShowRigs] = useState(false)
    const openRIgs = () => setShowRigs(true)

    let infoIcon = (<img src={warningIcon} alt="info" width="20" className="inline-block mr-2 pb-2" />)
    

    // 2nd Man
    let second_man_text = ''
	let infoIcon2ndMan = ''
    if (!second_man) {
        second_man_text =''
        infoIcon2ndMan = warningIcon
    }else {
        second_man_text = second_man
        infoIcon2ndMan = checkIcon
    }

    // Rig
	let infoIconRig = ''
	let rig_display_name = ''
    if (rig_name==='Select Current Rig' || rig_name==='') {
        infoIconRig = warningIcon
        rig_display_name = 'Rig Not Selected!'
    }else {
        infoIconRig = checkIcon
        rig_display_name = rig_name
    }

    //Plant Check
	let infoIconPlantCheck = ''
    let plantCheckText = ''
    if (!daily_plant_check) {
        infoIconPlantCheck = warningIcon
        plantCheckText = "Daily Plant Check Not Completed Today!"
    }else {
        infoIconPlantCheck = checkIcon
        plantCheckText = "Daily Plant Check Completed Today!"
    }

    // const [showPlantCheckCP, setShowPlantCheckCP] = useState(false)
    // const openPlantCheckCP = () => setShowPlantCheckCP(true)

    // const [showPlantCheckRT, setShowPlantCheckRT] = useState(false)
    // const openPlantCheckRT = () => setShowPlantCheckRT(true)

    // const [showPlantCheckWSDP, setShowPlantCheckWSDP] = useState(false)
    // const openPlantCheckWSDP = () => setShowPlantCheckWSDP(true)

	return (

        <>

            <Modal title="Information" subtitle="" toggleModal={onClose} confirm={false} >
				
                {showCrewList && 
                    <CrewList onClose={() => { setShowCrewList(false) }} />
                }
				
                {/* {showPlantCheckCP && 
                    <DailyPlantCheckCablePercussive  />
                }
				
                {showPlantCheckRT && 
                    <DailyPlantCheckRotary onClose={() => { setShowPlantCheckRT(false) }} />
                }
				
                {showPlantCheckWSDP && 
                    <DailyPlantCheckWindowSample onClose={() => { setShowPlantCheckWSDP(false) }} />
                } */}

                <div className="flex mb-4 w-full justify-center">

                    <Button onClick={() => {setShowHoleInformation(true); setShowSiteInformation(false); setShowContactInformation(false);
                        setHoleInformationButtonColor('orange'); setSiteInformationButtonColor('primary'); setContactInformationButtonColor('primary')}
                } 
                        theme={holeInformationButtonColor} size="lg" className="btn-lg px-1.5 py-1 mx-1 text-sm md:text-base" >
                            {hole} Info
                    </Button>

                    <Button onClick={() => {setShowHoleInformation(false); setShowSiteInformation(true); setShowContactInformation(false);
                        setHoleInformationButtonColor('primary'); setSiteInformationButtonColor('orange'); setContactInformationButtonColor('primary')}
                }  
                        theme={siteInformationButtonColor} size="lg" className="btn-lg px-1.5 py-1 mx-1 text-sm md:text-base" >
                            Site Info
                    </Button>

                    <Button onClick={() => {setShowHoleInformation(false); setShowSiteInformation(false); setShowContactInformation(true);
                        setHoleInformationButtonColor('primary'); setSiteInformationButtonColor('primary'); setContactInformationButtonColor('orange')}
                } 
                        theme={contactInformationButtonColor} size="lg" className="btn-lg px-1.5 py-1 mx-1 text-sm md:text-base" >
                            Contact Info
                    </Button>

                </div>

                {showHoleInformation &&

                    <table className="table-auto w-full md:w-5/6 text-sm text-center bg-white border border-grey-mid mx-auto text-base md:text-xl">
                        <tbody>
                            <tr>
                                <td className={`w-4/12 md:w-4/12 lg:w-1/2 px-2 py-2 border-r border-b border-grey-mid text-right`}>Project:</td>
                                <td className={`w-8/12 md:w-8/12 lg:w-1/2 px-2 py-2 border-r border-b border-grey-mid text-left`}>{defaultValues.project}</td>
                            </tr>
                            <tr>
                                <td className={`pr-2 py-2 border-r border-b border-grey-mid text-right`}>Location:</td>
                                <td className={`pl-2 py-2 border-r border-b border-grey-mid text-left`}>{defaultValues.location}</td>
                            </tr>
                            <tr>
                                <td className={`pr-2 py-2 border-r border-b border-grey-mid text-right`}>{hole}:</td>
                                <td className={`pl-2 py-2 border-r border-b border-grey-mid text-left`}>{holeName}</td>
                            </tr>
                            {showRequiredDepth && 
                                <>
                                 {/* <tr>
                                     <td className={`pr-2 py-2 border-r border-b border-grey-mid text-right`}>Required Depth:</td>
                                     <td className={`pl-2 py-2 border-r border-b border-grey-mid text-left`}></td>
                                 </tr> */}
                                </>
                            }
                            <tr>
                                <td className={`pr-2 py-2 border-r border-b border-grey-mid text-right`}>{userType}:</td>
                                <td className={`pl-2 py-2 border-r border-b border-grey-mid text-left`}>
                                    {/* <div onClick={openCrewList}> */}
                                    <div>
                                    {loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name) }
                                    </div>
                                </td>
                            </tr>
                            {showSecondMan &&
                                <tr>
                                    <td className={`pr-2 py-2 border-r border-b border-grey-mid text-right`}>
                                        <img src={infoIcon2ndMan} alt="info" width="20" className="inline-block mr-2 pb-2" />
                                        2nd Man:
                                    </td>
                                    <td className={`pl-2 py-2 border-r border-b border-grey-mid text-left`}>
                                    
                                    {/* <div onClick={openCrewList}> */}
                                    {second_man_text}
                                    <div >

                                    </div>

                                    </td>
                                </tr>
                            }
                            {showRig &&
                                <tr>
                                    <td className={`pr-2 py-2 border-r border-b border-grey-mid text-right`}>
                                        <img src={infoIconRig} alt="info" width="20" className="inline-block mr-2 pb-2" />
                                        Rig:
                                    </td>
                                    <td className={`pl-2 pr-1 py-2 border-r border-b border-grey-mid text-left`}>
                                    
                                    {/* <Link to={rigs_link}>
                                            (Select)
                                    </Link> */}

                                    {/* <RigSelect
                                        company={companyGuid}
                                        project={projectGuid}
                                    /> */}

                                        {rig_display_name}

                                    </td>
                                </tr>
                            }
                            {showPlantCheck &&
                                <tr>
                                    <td className={`px-0 py-3  border-r border-grey-mid text-center text-base md:text-2xl font-bold`} colSpan={2}>
                                            <img src={infoIconPlantCheck} alt="info" width="20" className="inline-block mr-2 pb-2" />
                                            <Link to={plantCheckLink}>
                                                {plantCheckText}
                                            </Link>
                                        </td>
                                </tr>
                            }
                        </tbody>
                    </table>

                }  {/* END OF BOREHOLE INFORMATION */}

                {showSiteInformation &&

                    <table className="table-auto w-full md:w-5/6 text-sm text-center bg-white border border-grey-mid mx-auto text-base md:text-xl">
                        <tbody>
                            <tr>
                                <td className={`w-4/12 md:w-4/12 lg:w-1/2 px-2 py-2 border-r border-b border-grey-mid text-right align-top`}>Site Description:</td>
                                <td className={`w-8/12 md:w-8/12 lg:w-1/2 px-2 py-2 border-r border-b border-grey-mid text-left align-top`}>{defaultValues.site_description}</td>
                            </tr>
                            <tr>
                                <td className={`pr-2 py-2 border-r border-b border-grey-mid text-right align-top`}>Key Site Features:</td>
                                <td className={`pl-2 py-2 border-r border-b border-grey-mid text-left align-top`}>{defaultValues.key_site_features}</td>
                            </tr>
                            <tr>
                                <td className={`pr-2 py-2 border-r border-b border-grey-mid text-right align-top`}>Site History:</td>
                                <td className={`pl-2 py-2 border-r border-b border-grey-mid text-left align-top`}>{defaultValues.site_history}</td>
                            </tr>
                            <tr>
                                <td className={`pr-2 py-2 border-r border-b border-grey-mid text-right align-top`}>Key H&S:</td>
                                <td className={`pl-2 py-2 border-r border-b border-grey-mid text-left align-top`}>{defaultValues.key_hands}</td>
                            </tr>
                            <tr>
                                <td className={`pr-2 py-2 border-r border-b border-grey-mid text-right align-top`}>Expected Strata:</td>
                                <td className={`pl-2 py-2 border-r border-b border-grey-mid text-left align-top`}>{defaultValues.expected_strata}</td>
                            </tr>
                            <tr>
                                <td className={`pr-2 py-2 border-r border-b border-grey-mid text-right align-top`}>Notes:</td>
                                <td className={`pl-2 py-2 border-r border-b border-grey-mid text-left align-top`}>{defaultValues.notes}</td>
                            </tr>
                            <tr>
                                <td className={`pr-2 py-2 border-r border-b border-grey-mid text-right align-top`}>Equipment Required:</td>
                                <td className={`pl-2 py-2 border-r border-b border-grey-mid text-left align-top`}>{defaultValues.equipment_required}</td>
                            </tr>
                        </tbody>
                    </table>
                }  {/* END OF SITE INFORMATION */}
                {showContactInformation &&
                    <>
                        <table className="table-auto w-full md:w-5/6 text-sm text-center bg-white border border-grey-mid mx-auto text-base md:text-xl mb-6">    
                            <tbody>
                                <tr>
                                    <td className={`pr-2 py-2 border-r border-b border-grey-mid text-right align-top w-1/2`}>Site Engineer:</td>
                                    <td className={`pl-2 py-2 border-r border-b border-grey-mid text-left align-top w-1/2`}>{defaultValues.engineer_name}</td>
                                </tr>
                                <tr>
                                    <td className={`pr-2 py-2 border-r border-b border-grey-mid text-right align-top w-1/2`}>Engineer Phone:</td>
                                    <td className={`pl-2 py-2 border-r border-b border-grey-mid text-left align-top w-1/2`}>{defaultValues.engineer_phone}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2} className={`pr-2 py-2 border-r border-b border-grey-mid text-center align-top`}>Engineer Email:<br/>
                                    {defaultValues.engineer_email}
                                    </td>
                                </tr>
                        </tbody>
                        </table>  
                        <table className="table-auto w-full md:w-5/6 text-sm text-center bg-white border border-grey-mid mx-auto text-base md:text-xl">
                            <tbody>
                                <tr>
                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 px-2 py-2 border-r border-b border-grey-mid text-right align-top w-1/2`}>Client Contact:</td>
                                    <td className={`w-8/12 md:w-8/12 lg:w-1/2 px-2 py-2 border-r border-b border-grey-mid text-left align-top w-1/2`}>{defaultValues.client_contact}</td>
                                </tr>
                                <tr>
                                    <td className={`pr-2 py-2 border-r border-b border-grey-mid text-right align-top w-1/2`}>Client Phone:</td>
                                    <td className={`pl-2 py-2 border-r border-b border-grey-mid text-left align-top w-1/2`}>{defaultValues.client_phone}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2} className={`pr-2 py-2 border-r border-b border-grey-mid text-center align-top`}>Client Email:<br/>
                                    {defaultValues.client_email}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                }  {/* END OF CONTACT INFORMATION */}

            </Modal>

        </> 

	)
}

CurrentInformation.defaultProps = defaultProps

export default CurrentInformation