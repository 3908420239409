import React, { FunctionComponent, useState, useEffect } from 'react'

import WorkflowSteps from '../../WorkflowSteps'
import Modal from '../../Modal'
import Button from '../../form/Button'
import NumberInput from '../../NumberInput'
import ButtonOptions from '../../form/ButtonOptions'
import MultiNumberInput from '../../MultiNumberInput'
import InputGroup from '../../form/InputGroup'

import {v4 as uuidv4} from 'uuid';
import stepStatus from '../../../helpers/stepStatus'
// import {useISODate} from "../../../helpers/useISODate";

import PrintLabel from '../../../components/PrintLabel'
// import { updateSelectedProject } from '../../../actions/SelectedProject'
import { useSelector, useDispatch } from 'react-redux'
// import { useGetIDBValues } from "../../../helpers/getIDBKey"
import { useGetLoggedInUser } from '../../../helpers/getLoggedInUser'
import warningIcon from '../../../assets/icons/warning.png'
import infoIcon from '../../../assets/icons/info.png'
import closeIcon from '../../../assets/icons/close.svg'

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: string,
	title: string,
	defaultValues?: any,
    selectedDate?: string,
	update?: boolean,
	projectId?: string,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => {},
	onComplete: () => {},
	borehole: '',
	title: '',
	defaultValues: {
		guid: '',
		depth_to_top: '',
		depth_to_base: '',
		casing_depth: '',
		water_depth: '',
		general_remarks: '',
	},
    selectedDate: '',
}

const useForceUpdate = () => { 
    const [updateValue, setUpdateValue] = useState(0);
    return () => setUpdateValue(value => value + 1)
}

const Samples: FunctionComponent<Props> = ({ onClose, update, onComplete, borehole, title, 
	defaultValues, selectedDate, casing_preset, new_type_number, new_type_number_sequencial, numbering_type, onCompleteNumbering,
	setShowDEdit, setShowBEdit, setShowESEdit, setShowWSEdit, sampleType, sampled_by,
	...rest }) => {
	
	// const [currentStep, setCurrentStep] = useState(1)
	console.log('defaultValues:', defaultValues)

    // const [id, setId] = useState(defaultValues.id)
	// const [guid, setGuid] = useState(defaultValues.guid)
	// const [depth_to_top, setDepthFrom] = useState(defaultValues.depth_to_top)
	// const [depth_to_base, setDepthTo] = useState(defaultValues.depth_to_base)
	// const [number_blows, setCasing] = useState(defaultValues.number_blows)
	// const [water_depth, setWater] = useState(defaultValues.water_depth)
	// const [remarks, setComment] = useState(defaultValues.general_remarks)
    // const [time_of_action, setTimeOfAction] = useState(defaultValues.time_of_action)

    // const isoDate = selectedDate ? `${new Date(selectedDate).toISOString().split('T')[0]} 09:00:00` : new Date().toISOString().split('.')[0]
	// console.log('new_type_number_sequencial:', new_type_number_sequencial)
	const [currentStep, setCurrentStep] = useState(1)
	const date = useSelector((state: any) => state.date)
	var labelDate = date.toLocaleDateString("en-UK")

    const [id, setId] = useState(defaultValues.id)
	const [guid, setGuid] = useState(defaultValues.guid)
	const [depth_to_top, setDepthFrom] = useState(defaultValues.depth_to_top)
	const [depth_to_base, setDepthTo] = useState(defaultValues.depth_to_base || '')

	const [general_remarks, setComment] = useState(defaultValues.general_remarks)
	const [show_general_remarks, set_show_general_remarks] = useState(false)
	const [sampler, setSampler] = useState(sampled_by)
	
	useEffect(() => {
	
		// Set the appropriate state based on numbering_type
		if (general_remarks === null || general_remarks === '') {
			set_show_general_remarks(false);
		} else {
			set_show_general_remarks(true);
		}
	}, [general_remarks]);

	// const [casing_depth, setCasing] = useState(defaultValues.casing_depth)
	const [casing_depth, setCasing] = useState(defaultValues.casing_depth || '')
	// const [number_blows, setNumberOfBlows] = useState(defaultValues.number_blows)
	const [water_depth, setWater] = useState(defaultValues.water_depth || '')

	// console.log('**************************Inside Samples Panel*****************************')
	// console.log('id: ', defaultValues.id, id)
	// console.log('guid: ', defaultValues.guid, guid)
	// console.log('depth_to_top: ', defaultValues.depth_to_top, depth_to_top, typeof depth_to_top)
	// console.log('depth_to_base: ', defaultValues.depth_to_base, typeof depth_to_base)
	// console.log('remarks: ', defaultValues.general_remarks, remarks, typeof remarks)
	// console.log('casing_depth: ', defaultValues.casing_depth, casing_depth)
	// console.log('casing_depth: ', defaultValues.casing_depth, casing_depth, typeof casing_depth)
	// console.log('water_depth: ', defaultValues.water_depth, water_depth, typeof water_depth)
	// console.log('casing_preset: ', casing_preset, casing_preset)
	// console.log('***************************************************************************')
	
	const [saveButtonText, setButtonText] = useState('Continue')
	const [saveButtonColor, setButtonColor] = useState('primary')

    const [time_of_action, setTimeOfAction] = useState(defaultValues.time_of_action)

    // const isoDate = selectedDate ? `${new Date(selectedDate).toISOString().split('T')[0]} 09:00:00` : new Date().toISOString().split('.')[0]

	const isoDate_actual = selectedDate ? 
		`${new Date(selectedDate).toISOString().split('T')[0]}T${new Date().toTimeString().split(' ')[0]}` : 
		new Date().toISOString().split('.')[0];

	const isoDate = isoDate_actual.replace('T', ' ');

	const selectedProject = useSelector((state: any) => state.selectedProject)
	const dispatch = useDispatch()

	const loggedInUser = useGetLoggedInUser()

	const [numberingType, setNumberingType] = useState(numbering_type);
	const [showNumberingInfo, setShowNumberingInfo] = useState(false);
	const [showNumberingSelect, setShowNumberingSelect] = useState(false);
	const [showIndividualNumbering, setShowIndividualNumbering] = useState(false);
	const [showOverallNumbering, setShowOverallNumbering] = useState(false);
	
	useEffect(() => {
	
		// Set the appropriate state based on numbering_type
		if (numberingType === null || numberingType === '') {
			setNumberingType('default')
			setShowNumberingSelect(true);
			setShowIndividualNumbering(false);
			setShowOverallNumbering(false);
		} else if (numberingType === 'default') {
			setShowNumberingSelect(false);
			setShowIndividualNumbering(true);
			setShowOverallNumbering(false);
		} else if (numberingType === 'overall') {
			setShowNumberingSelect(false);
			setShowIndividualNumbering(false);
			setShowOverallNumbering(true);
		}
	}, []);

	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	const DeleteButton = () => {
		if (!update) return null;
	
		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}
	
	const [hideRecordOnSave, setHideRecordOnSave] = useState(false)

	const deleteRecordConfirm = () => {
		setDeleteConfirmOpen(false)
		// setHideRecordOnSave(true) // 
		console.log('deleteRecordConfirm TRIGGERED. Record HIDDEN?: ',hideRecordOnSave)
		setCurrentStep(4)
		// next()
		onComplete()
		
	}
	
var labelType, defaultLabelType

	var sampleInitial = title.charAt(0);
	if (sampleInitial ==='D') {defaultLabelType = "D"; labelType = 'general';}
	else if (sampleInitial ==='B') {defaultLabelType = "B"; labelType = 'general';}
	else if (sampleInitial ==='E') {defaultLabelType = "ES"; labelType = 'general';}
	else if (sampleInitial ==='W') {defaultLabelType = "WS"; labelType = 'general';}
	else if (sampleInitial ==='L') {defaultLabelType = "L"; labelType = 'topImage';}
	else if (sampleInitial ==='U') {defaultLabelType = "U"; labelType = 'topImage';}
	else if (sampleInitial ==='C') {defaultLabelType = "C"; labelType = 'topImage';}
	else if (sampleInitial ==='P') {defaultLabelType = "PS"; labelType = 'topImage';}
	else {defaultLabelType = "XXX"; labelType = 'general';}

	
	var sampleLabel = ''
	var type_number_output = 0
	if (update) {
		sampleLabel = defaultLabelType + "-" + defaultValues.type_number
		type_number_output = defaultValues.type_number
	} else {
		if (numberingType === 'overall') {
			sampleLabel = defaultLabelType + "-" + new_type_number_sequencial
			type_number_output = new_type_number_sequencial
		} else {
			sampleLabel = defaultLabelType + "-" + new_type_number
			type_number_output = new_type_number
		}
	}

	console.log('new_type_number:', new_type_number)

	var depth_to_top2DP = parseFloat(depth_to_top).toFixed(2)

	var depth_to_base2DP = ''
	var printDepth = ''
	
	useEffect(() => {
		if(defaultValues.depth_to_base === '' || defaultValues.depth_to_base === null){
			setDepthTo('')
		}else{
			setDepthTo(defaultValues.depth_to_base);
		}
	}, [])

	if(!depth_to_base){
		depth_to_base2DP = ''
		printDepth = depth_to_top2DP
	}else{
		depth_to_base2DP = parseFloat(depth_to_base).toFixed(2)
		printDepth = depth_to_top2DP + ' - ' + depth_to_base2DP
	}

	if (!casing_preset) {
		var casing_preset_value = 'no casing'
	}
	else {
		casing_preset_value = parseFloat(casing_preset).toFixed(2)
	}

	var casing_depth2DP =  ''
	if (casing_depth === 'no casing') {
		casing_depth2DP = 'no casing'
	} else if (casing_depth === '') {
		casing_depth2DP = ''
	} else {
		casing_depth2DP = '' + (parseFloat(casing_depth)).toFixed(2)
	}

	// console.log('casing_depth2DP:', casing_depth2DP)

	var depth_of_water2DP = ''
	if (water_depth === '' || water_depth === null || water_depth === 'NaN'){depth_of_water2DP = ''}
	else if (water_depth == 'dry') {depth_of_water2DP = 'dry'}
	else if (water_depth == 'damp') {depth_of_water2DP = 'damp'}
	else {depth_of_water2DP = parseFloat(water_depth).toFixed(2)}

	var remarksTemp = ''
	let outputRemarks = ''
	if (general_remarks===''){outputRemarks = remarksTemp } else {outputRemarks = general_remarks}

	const previous = () => {
		if(currentStep > 1)
			setCurrentStep(currentStep - 1)
			setButtonText('Continue')
			setButtonColor('primary')
	}

	const next = () => {
		if(currentStep < 4) {
			setCurrentStep(currentStep + 1)
			if(currentStep === 3) {
				setButtonText('Save') 
				setButtonColor('success')
			}else{
				setButtonText('Continue')
				setButtonColor('primary')
			}
			return
		}

		if (!depth_to_top) {
			//do nothing
		} else {

			let numberingTypeToSave = ''
			if (numberingType === '') {
				numberingTypeToSave = 'default'
			} else {
				numberingTypeToSave = numberingType
			}

			onCompleteNumbering(numberingTypeToSave)

			onComplete({
				// id: id,
				guid: update ? guid : uuidv4(),
				depth_to_top: parseFloat(depth_to_top).toFixed(2), //2DP, // depth_to_top // parseFloat(depth_to_top).toFixed(2),
				depth_to_base: depth_to_base2DP, //parseFloat(depth_to_base).toFixed(2), //.toFixed(2), //2DP,
				// casing_depth: casing_depth2DP, //casing_depth, //2DP,
				casing_depth: casing_depth2DP !== 'NaN' ? casing_depth2DP : '',
				water_depth: depth_of_water2DP,//depth_of_water2DP, 
				general_remarks: outputRemarks,
				type_number: type_number_output,
				time_of_action: time_of_action ? time_of_action : isoDate,
				update: update ? true : false,
				// hidden: hideRecordOnSave,
				// casing_depth: casing_depth, //2DP,
				// number_blows: number_blows,
				// remarks: remarks,
				// remarks: outputRemarks, //remarksTemp,
				// description: '',
				// prep_details: '',
				// duration: '00:00:00',
				// sample_length: 0,
				// userActivity: 'Drilling',
			})

		}
	}

	const MissingRequiredField = () => {
		if (currentStep === 4 && !depth_to_top) return (
			<div className="text-center bg-sptSB border border-red font-bold rounded mb-2 py-1 text-xs md:text-sm lg:text-base">
				<img src={warningIcon} alt="info" width="20" className="inline-block mr-1 pb-1" />
				Required field: 'Depth From'
			</div>
		)
	
		return null
	}
	

	return (
		<Modal title={title} subtitle={borehole} toggleModal={onClose} >



		{showNumberingInfo && (
			<div className="z-50 fixed inset-x-0 bottom-20 flex justify-center w-80 md:w-96 mx-auto"> {/* infoContainer */}
				<div className="m-auto w-full rounded-lg border border-orange bg-white modalshadow p-2">
					<div className="flex">
						<div className="info-icon-container">
							<img className="info-icon" src={infoIcon} alt="Info" />
						</div>
						<span className="font-bold text-lg">Information</span>
						<span className="ml-auto">
							<button
								className="flex items-center justify-center w-8 h-8 text-4xl bold text-soil-grey bg-white border border-orange rounded"
								onClick={() => setShowNumberingInfo(false)}
							>
								<img src={closeIcon} width="20" alt="" />
							</button>
						</span>
					</div>

					<div className="bg-white rounded text-center border border-grey-mid mt-2 mb-2 py-1 px-2">
						<div className="text-base md:text-lg">Individual (default)</div>
						<div className="text-lg md:text-2xl">D-{new_type_number}, B-{new_type_number}</div>
						<div className="text-xs md:text-sm">Samples and Tests are individually sequentially numbered.</div>
						<div className="text-xs">ie
							<span className="text-orange"> D-1 </span>
							<span className="text-charcoal">B-1 </span>
							<span className="text-blue">SPT-1 </span>
							<span className="text-charcoal">CPT-1 </span>
							<span className="text-orange">D-2 </span>
							<span className="text-charcoal">B-2 </span>
							<span className="text-blue">SPT-2 </span>
							<span className="text-charcoal">CPT-2 </span>
						</div>
					</div>

					<div className="bg-white rounded text-center border border-grey-mid mb-2 py-1 px-2">
						<div className="text-base md:text-lg">Overall</div>
						<div className="text-lg md:text-2xl">D-{new_type_number_sequencial}, B-{new_type_number_sequencial + 1}</div>
						<div className="text-xs md:text-sm">Samples and Tests are sequentially numbered OVERALL.</div>
						<div className="text-xs">ie
							<span className="text-orange"> D-1 </span>
							<span className="text-charcoal">B-2 </span>
							<span className="text-blue">SPT-1 </span>
							<span className="text-charcoal">CPT-2 </span>
							<span className="text-orange">D-3 </span>
							<span className="text-charcoal">B-4 </span>
							<span className="text-blue">SPT-3 </span>
							<span className="text-charcoal">CPT-4 </span>
						</div>
					</div>

				</div>
			</div>

		)}

			<div className="flex flex-col flex-grow px-1 sm:px-2 md:px-10">

				{/* {update &&
					<div className="text-sm">{guid}</div>
				} */}
				
				<WorkflowSteps steps={[
					{
						label: 'Depth',
						status: stepStatus(1, currentStep),
						onClick: () => {
							setCurrentStep(1)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Casing',
						status: stepStatus(2, currentStep),
						onClick: () => {
							setCurrentStep(2)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Water',
						status: stepStatus(3, currentStep),
						onClick: () => {
							setCurrentStep(3)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Label',
						status: stepStatus(4, currentStep),
						onClick: () => {
							setCurrentStep(4)
							setButtonText('Save')
							setButtonColor('success')
						}
					},
				]} className="mb-2" />

				<div className="flex-1 flex">
					{currentStep === 1 &&

					<>
							<div className="w-full">
					
								<MultiNumberInput
								title="Depth (m)" 
								values={
									[
										{defaultValue: depth_to_top , onUpdate: setDepthFrom, label: 'From'},
										{defaultValue: depth_to_base , onUpdate: setDepthTo, label: 'To'},
									]
								}
								className="text-base sm:text-xl md:text-xl pb-4" 
								inputCSS="text-4xl sm:text-4xl md:text-3xl lg:text-4xl h-16 sm:h-16 mb-4" 
								/>


								<div className="bg-sptSB border border-grey-mid rounded px-1 md:px-2 py-1 md:py-2">

									{showNumberingSelect && (
										<>

											<div className="flex items-center justify-center text-base md:text-lg mb-2">
												<span>Numbering Type</span>
												<img
													className="ml-2" // Add some margin to the left of the image for spacing
													src={infoIcon}
													width="16"
													alt="INFO"
													onClick={() => setShowNumberingInfo(true)}
												/>
											</div>
											<ButtonOptions onUpdate={(value: any) => setNumberingType(value)}
												defaultValue={numberingType}
												title=""
												columns={2}
												columnsMobile={2}
												buttonHeight={20}
												buttonHeightMobile={16}
												options={[
													{
														value: 'default', label: <><div className="text-base md:text-lg">Individual</div>
															<div className="text-lg md:text-2xl">D-{new_type_number}, B-{new_type_number}, ES-{new_type_number}, WS-{new_type_number}</div></>
													},
													{
														value: 'overall', label: <><div className="text-base md:text-lg">Overall</div>
															<div className="text-lg md:text-2xl">D-{new_type_number_sequencial}, B-{new_type_number_sequencial + 1}, B-{new_type_number_sequencial + 2}, B-{new_type_number_sequencial + 3}</div></>
													},
												]} className="mb-1" />

											<div className="text-center text-xs md:text-sm mt-2 mb-1">If not selected, 'Individual Numbering' will be used as default</div>

											{/* <div className="grid grid-cols-2 gap-1 md:gap-2">
										<div className="bg-white rounded text-center border border-grey-mid mb-2 py-1">
											<div className="text-base md:text-lg">Individual</div>
											<div className="text-lg md:text-2xl">SPT-{new_type_number}, CPT-{new_type_number}</div>
										</div>

										<div className="bg-white rounded text-center border border-grey-mid mb-2 py-1">
											<div className="text-base md:text-lg">Overall</div>
											<div className="text-lg md:text-2xl">SPT-{new_type_number_sequencial}, CPT-{new_type_number_sequencial + 1}</div>
										</div>
									</div> */}
										</>
									)}

									{showIndividualNumbering && (
										<>
											<div className="flex items-center justify-center text-base md:text-lg mb-1">
												<span>Numbering Type</span>
											</div>

											<div className="grid grid-cols-1 md:grid-cols-3 gap-1 md:gap-2">
												<div></div>
												<div className="bg-white rounded text-center border border-grey-mid mb-2 py-1">
													<div className="text-base md:text-lg">Individual</div>
													<div className="text-lg md:text-2xl">{sampleType}-{update ? type_number_output : new_type_number}</div>
													{/* <div className="text-xs md:text-sm">Samples and Tests are individually sequentially numbered.</div> */}
												</div>
												<div></div>
											</div>
										</>
									)}

									{showOverallNumbering && (
										<>
											<div className="flex items-center justify-center text-base md:text-lg mb-1">
												<span>Numbering Type</span>
											</div>

											<div className="grid grid-cols-1 md:grid-cols-3 gap-1 md:gap-2">
												<div></div>
												<div className="bg-white rounded text-center border border-grey-mid mb-2 py-1">
													<div className="text-base md:text-lg">Overall</div>
													<div className="text-lg md:text-2xl">{sampleType}-{update ? type_number_output : new_type_number_sequencial}</div>
													{/* <div className="text-xs md:text-sm">Samples and Tests are sequentially numbered OVERALL.</div> */}
												</div>
												<div></div>
											</div>
										</>
									)}
								</div>
							</div>
					</>
					}

					{currentStep === 2 &&
							
							<NumberInput onUpdate={(value: any) => setCasing(value)}
							title="Enter Casing Depth (m)" 
							defaultValue={casing_depth} 
							className="mb-10 text-xl md:text-2xl leading-5 md:leading-8"
							presets={[
								{value: casing_preset_value, label: casing_preset_value},
								]}
							next={next}
							/>
					}

					{currentStep === 3 &&
						<NumberInput onUpdate={(value: any) => setWater(value)}
						title="Enter Water Depth (m)" 
						defaultValue={water_depth} 
						className="mb-10 text-xl"
						presets={[
							{value: 'dry', label: 'dry'},
							{value: 'damp', label: 'damp'},
							]} 
						next={next}
						/>
					}

					{currentStep === 4 &&
					<>

					<div className="w-full">

						<PrintLabel 
                        location={selectedProject.location}
                        project={selectedProject.number}
                        borehole={borehole} 
                        sampledate={labelDate}  
                        // user={loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name) }
                        sampled_by={sampler}
                        sample={sampleLabel} 
                        depth={printDepth}
                        size='printLabel'
                    	/>

						{show_general_remarks &&
							<div className="bg-sptSB border border-grey-mid rounded px-1 md:px-2 py-1 md:py-2">
								<InputGroup label="Notes" 
								onChange={(value: any) => setComment(value)} 
								title="Add Comment"
								value={general_remarks} 
								className="mt-2 mb-4 w-full text-center text-xl" />
							</div>
						}

					</div>
					</>
					}
				</div>

				<MissingRequiredField />
				

				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">
	
	
							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?
	
								<div className="flex mt-4">
									<Button theme="secondary" 
										className="mr-5 text-sm sm:text-base px-3.5 md:px-7" 
										onClick={() => setDeleteConfirmOpen(false)}
										>Cancel
									</Button>
	
									<Button onClick={() => {
												onClose()
												onComplete({
													guid: guid,
													update: false,
													delete: true,
												})
												if (sampleInitial ==='D') {
													setShowDEdit(false)
												} else if (sampleInitial ==='B') {
													setShowBEdit(false)
												} else if (sampleInitial ==='E') {
													setShowESEdit(false)
												} else if (sampleInitial ==='W') {
													setShowWSEdit(false)
												}
											}}
										className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">
										Delete
									</Button>
								</div>
							</div>
	
	
						</div>
					</div>
				}

				<div className="flex">
					{/* <Button theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button> */}
					<DeleteButton/>

					<div className="flex ml-auto">
						<Button theme="primary" size="lg" className="px-4 sm:px-10" onClick={() => previous()}>Back</Button>
						<Button theme={saveButtonColor} 
							size="lg" 
							className="ml-4 px-8 sm:px-10 w-32 sm:w-60" 
							onClick={() => next()}>{saveButtonText}
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

Samples.defaultProps = defaultProps

export default Samples
