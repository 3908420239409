import { get } from 'idb-keyval'
import { setIDB } from './setIDBKey'
import { usePostToApi } from "./postToAPI_Counts";

// take in a string of new values and the key you want to save against
export const useSetIndexedDBValuesCounts = (values: object, idbKey: string, create?: boolean, update?: string): any => {

    // console.log('New Hole addToIDB - PROPS: values:', values, 'idbKey', idbKey, 'create:', create, 'update:', update)

    // if (update){
    //     console.log('New Hole addToIDB - UPDATE')
    // }

    // const fullEndpoint = `${process.env.REACT_APP_API_ADDR}${idbKey}`
    const existingKey = `drillit-${idbKey}`
    const newKey = `${idbKey}-new`
    const updateKey = `${idbKey}-update`

    return new Promise( (result: any, reject: any) => {
        getIDB(existingKey).then(result)
        // console.log('addToIDB_Counts - getIDB(existingKey):', result, 'getIDB(existingKey):', getIDB(existingKey))
    })    
        .then(result => {
            if (!update) {
                const newResult = {}
                if (result) {
                    // console.log('addToIDB_Counts - result', result)
                    // console.log('addToIDB_Counts setIDB(values, existingKey, result as object[])', values, existingKey, result)
                    const newResult = setIDB(values, existingKey, result as object[])
                } else {
                    // console.log('addToIDB_Counts - NO result')
                    const newResult = setIDB(values, existingKey)
                }
            }
            // console.log('addToIDB_Counts - CREATE - setIDB(values, existingKey, result as object[]):', result)
            return result
        })
        .then(result => {
            // if create is true then we can add to another object of purely 'new' entries by recursively calling this function
            if (create) {
                const saveNewKey = useSetIndexedDBValuesCounts
                // console.log('addToIDB_Counts - CREATE - we can add to another object of purely new entries by recursively calling this function')
                // console.log('saveNewKey:', saveNewKey)
                return saveNewKey(values, newKey)
            }
            return result
        })
        .then(result => {
            if (update) {
                const newResult: any = result
                const newItem: any = values
                const updatedArray = newResult.map((obj: any) => obj.guid === newItem.guid ? newItem : obj )
                // console.log('addToIDB_Counts - UPDATE - setIDB ON UPDATE!!!')
                // console.log('addToIDB_Counts - updatedArray, existingKey: ', updatedArray, existingKey)
                setIDB(updatedArray, existingKey)
                // const sendToIDB = setIDB(updatedArray, existingKey)
            }
            return result
        })
        .then(result => {
            // create a new object of updated values
            if (update) {
                const saveUpdateKey = useSetIndexedDBValuesCounts
                // console.log('addToIDB_Counts - UPDATE - create a new object of updated values')
                return saveUpdateKey(values, updateKey)
            }
            return result
        })
        .then(result => {
            // push to api in here ///
            if (create) {
                const postToApi = usePostToApi
                // console.log('addToIDB_Counts - CREATE - Push to API if create')
                // console.log('addToIDB_Counts - idbKey:', idbKey)
                return postToApi(idbKey, 'POST')
            }
            // we can return a variable to check for  redirect here as you probably don't want to stay on the 'new' page
            return result
        })
        .then(result => {
            // push to api in here
            if (update) {
                const postToApi = usePostToApi
                // console.log('addToIDB_Counts - UPDATE - Push to API')
                return postToApi(idbKey, update, true)
            }
            return result
        })
            .catch(reject => reject)
        }

const getIDB = (keyValue: string) => get(keyValue).then(data => data)
