const stepStatus = (step: any, currentStep: any) => {
	if(step < currentStep)
		return 'done'

	if(step === currentStep)
		return 'active'

	if(step > currentStep)
		return 'inactive'
}

export default stepStatus