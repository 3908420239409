import React, { FunctionComponent, useState, useRef, useEffect } from 'react'

// import WorkflowSteps from '../../WorkflowSteps'
import Modal from '../../Modal'
import Button from '../../form/Button'
// import NumberInput from '../../NumberInput'
import InputGroup from '../../form/InputGroup'
// import TextArea from '../../form/TextArea'
// import CompactTable from '../../CompactTable'
// import MiniNumberInput from '../../MiniNumberInput'
// import Timer from '../../Timer'
// import ButtonOptions from '../../form/ButtonOptions'
import ButtonOptionsUserPref from '../../ButtonOptionsUserPref'

// import formatTime from '../../../helpers/formatTime'
// import stepStatus from '../../../helpers/stepStatus'
// import warningIcon from '../../../assets/icons/warning.png'

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: any,
	borehole_guid?: string,
	title: string,
    loadUnits: string,
	// torquetext1: string,
	// torquetext2: string,
	rows?: number,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => {},
	onComplete: () => {},
	borehole: '',
	borehole_guid: '',
	title: '',
	loadUnits: '',
	rows: 150,
	defaultValues: {
		load: '',
		remarks: '',
	},
}

const initializeCells = (rows: number): string[] => {
	let cells: any = {}

	for(const row in [...Array(rows)]) {
		for(const column in [...Array(10)]) {
			cells[parseInt(row + '' + column) + 0] = ''
		}
	}

	return cells
}

const keys = [
	'1', '2', '3',
	'4', '5', '6',
	'7', '8', '9',
	'.', '0',
]
	


const PlateTestReadings: FunctionComponent<Props> = ({ onClose, onComplete, borehole, borehole_guid, defaultValues, title, rows = 50, ...rest }) => {
	const [activeCell, setActiveCell] = useState<number>(0)
	const [activeTorque, setActiveTorque] = useState<number>(-1)
	const [cellNotTorque, setCellNotTorque] = useState<boolean>(true)
	const [numRows, setNumRows] = useState<number>(rows)

	const [cells, setCells] = useState<string[]>(initializeCells(numRows))

	const [load, setLoad] = useState(defaultValues.load)
	const [loadUnits, setLoadUnit] = useState(defaultValues.loadUnits)
	const [remarks, setComment] = useState(defaultValues.remarks)
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	const DeleteButton = () => {
		// if (!update) return null;
	
		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	const deleteRecordConfirm = () => {
		setDeleteConfirmOpen(false)
		// onComplete()
	}

	const addCharacterCell = (character: string) => {
		let newValue = cells[activeCell]

		if(cells[activeCell] === '0')
			newValue = character
		else
			newValue = cells[activeCell] + character

		let newCells = {...cells}
		newCells[activeCell] = newValue

		setCells(newCells)
	}

	const addCharacter = (character: string) => {
		addCharacterCell(character)
	}

	const removeCharacterCell = () => {
		let newValue = cells[activeCell]

		if(cells[activeCell] !== '') {
			if(cells[activeCell].length === 1)
				newValue = ''
			else
				newValue = cells[activeCell].slice(0, -1)
		}

		let newCells = {...cells}
		newCells[activeCell] = newValue

		setCells(newCells)
	}

	const removeCharacter = () => {
		removeCharacterCell()
	}

	const clearEntryCell = () => {
		let newCells = {...cells}
		newCells[activeCell] = ''

		setCells(newCells)
	}

	// const clearEntry = () => {
	// 	clearEntryCell()
	// }

	const save = () => {
		const holeId = borehole_guid
		
		onComplete({
			cells,
			holeId,
			load,
		})
	}

	

	return (
		<Modal title={title} subtitle={borehole} toggleModal={onClose}>

			<div className="flex flex-col">

                <div className="grid grid-cols-1 lg:grid-cols-2">

                    <div>

                        {/* HEADER */}

                        <div className="grid grid-cols-5 text-center">

								<div className="col-span-1 border-r border-white py-1 text-center text-xs md:text-sm bg-grey-mid font-bold">Increment No.</div>
								<div className="col-span-2 border-r border-white py-1 text-center text-lg md:text-lg bg-grey-mid font-bold">Load</div>
								<div className="col-span-2 py-1 text-center text-lg md:text-lg bg-grey-mid font-bold">Unit</div>
                            
                            <div className="border-l border-grey-mid text-3xl md:text-2xl font-bold pt-3">1</div>

							<div className="border-l border-r border-grey-mid col-span-2 p-1.5">
								<InputGroup label="" 
								value='30'
								className="w-full"
								onChange={(value: any) => setLoad(value)} 
								inputCSS="text-2xl text-center h-12 font-bold"
								type="number" 
								/>
							</div>

							<div className="col-span-2">
								<div className="border-r border-grey-mid mb-0 p-1.5">
									<ButtonOptionsUserPref onUpdate={(value: any) => setLoadUnit(value)} 
									defaultValues=''
									buttonoptions={{
										inputOption1:'kN', 
										inputOption2:'T', 
										userPref:'kN',  //User preference - default kN
										inputOption1Title:'', 
										inputOption2Title:'',
										keyboard:false,
									}}
									title="" 
									className="mb-0" />
								</div>
							</div>
                        </div>



                        <table className="table-auto w-full text-xs md:text-lg text-center mb-2">
                            <thead className="font-bold sticky top-0">
                                <tr>
                                    <td rowSpan={2} className={`w-1/6 px-1 sm:px-1 py-1 bg-grey-mid rounded-tl-sm rounded-tr-sm border-r border-white`}>Time (mins)</td>
                                    <td colSpan={4} className={`w-4/6 px-1 sm:px-1 py-1 bg-grey-mid border-r border-b border-white`}>Dial Guage Readings (divs)</td>
                                    <td rowSpan={2} className={`w-1/6 px-1 sm:px-1 py-1 bg-grey-mid text-xs md:text-sm`}>Mean Settlement (mm)</td>
                                </tr>
                                <tr>	
                                    <td className={`px-1 sm:px-1 py-1 bg-grey-mid border-white border-r`}>1</td>
                                    <td className={`px-1 sm:px-1 py-1 bg-grey-mid border-white border-r`}>2</td>
                                    <td className={`px-1 sm:px-1 py-1 bg-grey-mid border-white border-r`}>3</td>
                                    <td className={`px-1 sm:px-1 py-1 bg-grey-mid border-white border-r`}>4</td>
                                </tr>
                            </thead>
                            <tbody className="h-32 overflow-y-auto">
                                <tr className="border border-grey-mid bg-white mb-1 text-lg md:text-2xl px-1">
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid font-bold`}>0</td>
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid`}></td>
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid`}></td>
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid`}></td>
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid`}></td>
                                    <td className={`py-0 md:py-0.5 `}></td>
                                </tr>
                                <tr className="border border-grey-mid bg-white mb-1 text-lg md:text-2xl">
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid font-bold`}>0.5</td>
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid`}></td>
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid`}></td>
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid`}></td>
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid`}></td>
                                    <td className={`py-0 md:py-0.5 `}></td>
                                </tr>
                                <tr className="border border-grey-mid bg-white mb-1 text-lg md:text-2xl">
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid font-bold`}>1</td>
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid`}></td>
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid`}></td>
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid`}></td>
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid`}></td>
                                    <td className={`py-0 md:py-0.5 `}></td>
                                </tr>
                                <tr className="border border-grey-mid bg-white mb-1 text-lg md:text-2xl">
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid font-bold`}>2</td>
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid`}></td>
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid`}></td>
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid`}></td>
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid`}></td>
                                    <td className={`py-0 md:py-0.5 `}></td>
                                </tr>
                                <tr className="border border-grey-mid bg-white mb-1 text-lg md:text-2xl">
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid font-bold`}>3</td>
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid`}></td>
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid`}></td>
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid`}></td>
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid`}></td>
                                    <td className={`py-0 md:py-0.5 `}></td>
                                </tr>
                                <tr className="border border-grey-mid bg-white mb-1 text-lg md:text-2xl">
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid font-bold`}>4</td>
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid`}></td>
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid`}></td>
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid`}></td>
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid`}></td>
                                    <td className={`py-0 md:py-0.5 `}></td>
                                </tr>
                                <tr className="border border-grey-mid bg-white mb-1 text-lg md:text-2xl">
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid font-bold`}>5</td>
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid`}></td>
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid`}></td>
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid`}></td>
                                    <td className={`py-0 md:py-0.5 border-r border-grey-mid`}></td>
                                    <td className={`py-0 md:py-0.5 `}></td>
                                </tr>
                            </tbody>
                        </table>

				
                    </div>
					
					
					{/* BUTTONS ETC */}

					<div className="mt-2 lg:mt-0 w-full pl-0 lg:pl-4"> {/* BUTTONS GRID */}						

							<div className="w-full">

									<div className="w-full inline-flex"> {/* BACK BUTTON */}
										<Button theme="outline" type="button" className="w-full text-lg md:text-2xl lg:text-xl py-1 md:py-4 lg:py-5 mb-1 lg:mb-2" onClick={() => {
											setActiveCell(activeCell === 10 ? activeCell : activeCell - 1)
										}}>Add New Row</Button>
									</div>

							</div>

							<div className="w-full mt-2">
								<div className="grid grid-cols-3 gap-1 lg:gap-2">  {/* NUMBER BUTTONS */}
									{keys.map((key, index) => {
										return <div className="border border-orange rounded py-0.5 md:py-6 lg:py-4 text-2xl md:text-4xl text-center" key={index} onClick={() => addCharacter(key)}>{key}</div>
									})}

									<div className="border border-orange rounded py-0.5 md:py-6 lg:py-4 text-2xl md:text-4xl text-center" onClick={() => removeCharacter()}>⌫</div>
								</div>
							</div>

					</div>

                </div>
				

				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">
	
	
							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?
	
								<div className="flex mt-4">
									<Button theme="secondary" className="mr-5 text-sm sm:text-base px-3.5 md:px-7" onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
	
									<Button onClick={deleteRecordConfirm} className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">Delete</Button>
								</div>
							</div>
	
	
						</div>
					</div>
				}

				<div className="flex items-center mt-6 lg:mt-12">
					{/* <Button theme="secondary" type="button" size="lg">Delete</Button> */}
					<DeleteButton/>

					<Button theme="primary" type="button" size="lg" className="ml-auto" onClick={()=>save()}>Save Increment Readings</Button>
				</div>
			</div>
		</Modal>
	)
}

PlateTestReadings.defaultProps = defaultProps

export default PlateTestReadings
