import React, { FunctionComponent, useState, Component, useRef, useEffect, } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateSelectedProject } from '../../../actions/SelectedProject'

import Panel from '../../../components/Panel'
import PanelSingleUse from '../../../components/PanelSingleUse'
import PanelBackfillInstall from '../../../components/PanelBackfillInstall'
import Button from '../../../components/form/Button'
import Modal from '../../../components/Modal'
import ModalSmall from '../../../components/ModalSmall'

import ProjectOptionsBorehole from '../../../components/project/ProjectOptionsBorehole'
import ProjectHeader from '../../../components/project/ProjectHeader'

import SyncStatus from '../../../helpers/syncStatus'

import Samples from '../../../components/project/workflow/Samples'
import SamplesCreate from '../../../components/project/workflow/SamplesCreate'
import SPT from '../../../components/project/workflow/in-situ-tests/SPT'
import CPT from '../../../components/project/workflow/in-situ-tests/CPT'
import U from '../../../components/project/workflow/in-situ-tests/U'
import PS from '../../../components/project/workflow/in-situ-tests/PS'
import SV from '../../../components/project/workflow/in-situ-tests/SV'
import SoilDescription from '../../../components/project/workflow/SoilDescription'
import SoilDescriptionEditor from '../../../components/project/workflow/SoilDescriptionEditor'
import GroundWater from '../../../components/project/workflow/GroundWaterWindowSample'
import Backfill from '../../../components/project/workflow/Backfill'
import Install from '../../../components/project/workflow/Install'
import InspectionPit from '../../../components/project/workflow/InspectionPit'
import CasingRecord from '../../../components/project/workflow/CasingRecordWindowSample'
import Chiselling from '../../../components/project/workflow/Chiselling'
import WaterAdded from '../../../components/project/workflow/WaterAdded'
import DailyWaterLevel from '../../../components/project/workflow/WaterLevel'
import ReDrill from '../../../components/project/workflow/ReDrill'
import Comments from '../../../components/project/workflow/Comments'
import DriveRecords from '../../../components/project/workflow/DriveRecordsWindowSample'

import Labels from '../../../components/project/workflow/Labels'

import Header from '../../../layout/Header'
import Container from '../../../layout/Container'


import '../../../LogsheetCSS.css';
import Barcode from 'react-jsbarcode'
import closeIcon from '../../../assets/icons/close.svg'
import previewIcon from '../../../assets/icons/preview.svg'
import labelIcon from '../../../assets/icons/label.svg'
import { Link } from 'react-router-dom'

import { useSetIndexedDBValues } from '../../../helpers/addToIDB'
import { useUpdateIndexedDBValues } from "../../../helpers/updateIDB"
import { useDeleteIndexedDBValue } from "../../../helpers/deleteFromIDB"
import { useGetIDBValues } from "../../../helpers/getIDBKey";
import { usePostToApi } from "../../../helpers/postToAPI";
import { updateActions } from "../../../helpers/updateActions"
import CompactTableObject from "../../../components/CompactTableObject";
import getWeatherList from "../../../helpers/getWeatherList";
import { connect } from "react-redux";
import { get, set, update, del } from "idb-keyval";
import edit from "../../../assets/icons/edit.svg";
import { activateHole } from "../../../helpers/activateHole";
import { closeHole } from "../../../helpers/closeHole";
import { updateHoleInformation } from "../../../helpers/updateHoleInformation";
import { updateActionCounts } from "../../../helpers/updateActionCounts";
import { updateWeather } from "../../../helpers/updateWeather";
import { Redirect, useHistory } from "react-router-dom";
import datesAreOnSameDay from "../../../helpers/datesAreOnSameDay";

import menubars from '../../../assets/menubars.svg'

import icon_ags from '../../../assets/icons/icon_ags.png'
import icon_preview from '../../../assets/icons/icon_preview.png'
import icon_savelog from '../../../assets/icons/icon_savelog.png'
import icon_complete from '../../../assets/icons/icon_complete.png'
import icon_project from '../../../assets/icons/icon_project.png'
import icon_email from '../../../assets/icons/icon_email.png'
import icon_labels from '../../../assets/icons/icon_labels.png'
import icon_newborehole from '../../../assets/icons/icon_newborehole.png'
import icon_plantcheck from '../../../assets/icons/icon_plantcheck.png'
import icon_editrig from '../../../assets/icons/icon_editrig.png'
import icon_reload from '../../../assets/icons/reload.png'
import icon_endshift from '../../../assets/icons/icon_endshift.png'

import NewHole from '../../NewHole'
import AGS from '../../AGS'
import Email from '../../Email'
import SaveLog from '../../SaveLog'

import ReactToPrint from 'react-to-print'
import Label from '../../../components/Label'
import PrintLabel from '../../PrintLabelModal'
import LabelBatchPrint from '../../../components/LabelBatchPrint'
import TopImage from '../../../assets/labelTop.jpg'
import { useGetLoggedInUser } from '../../../helpers/getLoggedInUser'
import warningIcon from '../../../assets/icons/warning.png'

interface Props {
	// onClose: any,
	// router: any,
	toggleSidebar: () => void,
	company_guid: string,
	project_guid: string,
	project_id: string,
	project_name: any,
	starting_hole_type_id: string,
	borehole_guid: string,
	selectedWeather: string,
	selectedDate: string,
	lastActiveDate: string,
	borehole_id: string,
	borehole_name: string,
	rig_name: string,
	rig_id: string,

	// HOLE DATA
	AllHoleData: any,
	activeDates: any,

	reloadData: any,
}


interface State {
	// onClose: () => {},
	project_guid: string,
	borehole: any,
	completingBorehole: boolean,

	showD: boolean,
	showSamplesCreateD: boolean,
	showSamplesCreateB: boolean,
	showSamplesCreateWS: boolean,
	showDEdit: boolean,
	showB: boolean,
	showBEdit: boolean,
	showES: boolean,
	showESEdit: boolean,
	showWS: boolean,
	showWSEdit: boolean,
	showSPT: boolean,
	showCPT: boolean,
	showU: boolean,
	showPS: boolean,
	showSV: boolean,
	showSoilDescription: boolean,
	showGroundWater: boolean,
	showInspectionPit: boolean,
	showCasingRecord: boolean,
	showChiselling: boolean,
	showWaterAdded: boolean,
	showDailyWaterLevel: boolean,
	showReDrill: boolean,
	showComments: boolean,
	showSPTEdit: boolean,
	showCPTEdit: boolean,
	showUEdit: boolean,
	showPSEdit: boolean,
	showSVEdit: boolean,
	showSoilDescriptionEdit: boolean,
	showGroundWaterEdit: boolean,
	showInspectionPitEdit: boolean,
	showCasingRecordEdit: boolean,
	showChisellingEdit: boolean,
	showWaterAddedEdit: boolean,
	showDailyWaterLevelEdit: boolean,
	showReDrillEdit: boolean,
	showCommentsEdit: boolean,
	showDriveRecords: boolean,
	showDriveRecordsEdit: boolean,

	showInstall: boolean,
	showInstallEdit: boolean,
	showBackfill: boolean,
	showBackfillEdit: boolean,

	InSituTests: any,
	GroundWaterTests: any,
	Samples: any,
	InspectionPit: any,
	Casing: any,
	Chiselling: any,
	WaterAdded: any,
	DailyWaterLevel: any,
	ReDrill: any,
	SoilDescription: any,
	SoilDescriptionEditor: any,
	// BackfillInstall: any,
	Comments: any,
	Install: any,
	Backfill: any,
	DriveRecords: any,

	addSampleData: any,
	fromType: any,

	redirect: boolean,

	project: any,
	holeType: string,

	previewVisible: boolean,
	labelsVisible: boolean,
	bhMenuVisible: boolean,

	showHoleEdit: boolean,
	showNewHole: boolean,
	showEmail: boolean,
	showAGS: boolean,
	setShowNewHole: boolean,
	setShowEmail: boolean,
	setShowAGS: boolean,

	showSaveLog: boolean,
	setShowSaveLog: boolean,

	showPrintLabel: boolean,
	setShowPrintLabel: boolean,

	defaultValues: object,
	guid: string;

	todaySoilDescriptionsVisible: boolean,
	allSoilDescriptionsVisible: boolean,

	// END SHIFT
	showEndShiftDailyWaterLevels: boolean,
	showEndShiftSaveLog: boolean,
	showEndShiftAGS: boolean,
	showEndShiftEmail: boolean,
	showEndShiftlogout: boolean,
}



const FunctionTemplate: FunctionComponent<Props> = ({
	company_guid, project_guid, project_id, starting_hole_type_id, borehole_guid, selectedWeather, selectedDate, lastActiveDate,
	borehole_id, borehole_name, reloadData, project_name,
	rig_name, rig_id,
	//HOLE DATA
	AllHoleData, activeDates,
	toggleSidebar, ...rest }) => {

	// console.log('AllHoleData', AllHoleData)
	// console.log('activeDates', activeDates)

	// const hole_data = AllHoleData?.hole_data;
	const [hole_data, set_hole_data] = useState(AllHoleData?.hole_data)
	const [hole_guid, set_hole_guid] = useState(AllHoleData?.hole_guid || '')

	// console.log('borehole_id:', borehole_id)

	const [borehole, setBorehole] = useState(null)
	const [project, setProject] = useState(null)

	const [state, setState] = useState({
		completingBorehole: false,

		InSituTests: [],
		GroundWaterTests: [],
		SamplesData: [],
		InspectionPit: [],
		Casing: [],
		Chiselling: [],
		WaterAdded: [],
		DailyWaterLevel: [],
		ReDrill: [],
		SoilDescription: [],
		SoilDescriptionEditor: [],
		Comments: [],
		Install: [],
		Backfill: [],
		DriveRecords: [],

		addSampleData: [],
		fromType: '',

		// ************************************************
		// editingGuid: '',
		// editingIndex: 1, // there is something wrong here - editingIndex won't update null
		redirect: false,

		project: '',
		holeType: '',

		defaultValues: {},

		// currentRigValue: '',
		// currentRigLabel: '',
		rig: [],
		rigs: [],
		currentRig: [],
		spts: [],
		rigsSelect: [],
		currentRig_name: '', //same as Label
		currentRig_type: '',
		currentRig_plant_id: '',
		currentRig_test_date_expiry: '',
		hammers: [],
		currentRig_spt_guid: '',
		currentRig_spt: {
			guid: '',
		},
	})

	// const [editingGuid, setEditingGuid] = useState('')
	const [editingGuid, setEditingGuid] = useState([])
	const [editingIndex, setEditingIndex] = useState(0)
	const [defaultValues, setDefaultValues] = useState([])

	// console.log('editingGuid from root', editingGuid)
	// console.log('editingIndex from root', editingIndex)
	// console.log('defaultValues from root', defaultValues)

	const [addSampleData, setAddSampleData] = useState([])
	const [fromType, setFromType] = useState('')

	// console.log('addSampleData:', addSampleData)

	const [showD, setShowD] = useState(false)
	const [showSamplesCreateD, setShowSamplesCreateD] = useState(false)
	const [showSamplesCreateB, setShowSamplesCreateB] = useState(false)
	const [showSamplesCreateWS, setShowSamplesCreateWS] = useState(false)
	const [showB, setShowB] = useState(false)
	const [showES, setShowES] = useState(false)
	const [showWS, setShowWS] = useState(false)
	const [showDEdit, setShowDEdit] = useState(false)
	const [showBEdit, setShowBEdit] = useState(false)
	const [showESEdit, setShowESEdit] = useState(false)
	const [showWSEdit, setShowWSEdit] = useState(false)
	const [showSPT, setShowSPT] = useState(false)
	const [showSPTEdit, setShowSPTEdit] = useState(false)
	const [showCPT, setShowCPT] = useState(false)
	const [showCPTEdit, setShowCPTEdit] = useState(false)
	const [showU, setShowU] = useState(false)
	const [showUEdit, setShowUEdit] = useState(false)
	const [showPS, setShowPS] = useState(false)
	const [showPSEdit, setShowPSEdit] = useState(false)
	const [showSV, setShowSV] = useState(false)
	const [showSVEdit, setShowSVEdit] = useState(false)
	const [showSoilDescription, setShowSoilDescription] = useState(false)
	const [showSoilDescriptionEdit, setShowSoilDescriptionEdit] = useState(false)
	const [showGroundWater, setShowGroundWater] = useState(false)
	const [showGroundWaterEdit, setShowGroundWaterEdit] = useState(false)
	const [showInspectionPit, setShowInspectionPit] = useState(false)
	const [showInspectionPitEdit, setShowInspectionPitEdit] = useState(false)
	const [showCasingRecord, setShowCasingRecord] = useState(false)
	const [showCasingRecordEdit, setShowCasingRecordEdit] = useState(false)
	const [showChiselling, setShowChiselling] = useState(false)
	const [showChisellingEdit, setShowChisellingEdit] = useState(false)
	const [showWaterAdded, setShowWaterAdded] = useState(false)
	const [showWaterAddedEdit, setShowWaterAddedEdit] = useState(false)
	const [showDailyWaterLevel, setShowDailyWaterLevel] = useState(false)
	const [showDailyWaterLevelEdit, setShowDailyWaterLevelEdit] = useState(false)
	const [showReDrill, setShowReDrill] = useState(false)
	const [showReDrillEdit, setShowReDrillEdit] = useState(false)
	const [showComments, setShowComments] = useState(false)
	const [showCommentsEdit, setShowCommentsEdit] = useState(false)
	const [showDriveRecords, setShowDriveRecords] = useState(false)
	const [showDriveRecordsEdit, setShowDriveRecordsEdit] = useState(false)

	const [showInstall, setShowInstall] = useState(false)
	const [showInstallEdit, setShowInstallEdit] = useState(false)
	const [showBackfill, setShowBackfill] = useState(false)
	const [showBackfillEdit, setShowBackfillEdit] = useState(false)




















	//.......................................................
	//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNN...NNNN..UUUU...UUUU..
	//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNNN..NNNN..UUUU...UUUU..
	//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNNN..NNNN..UUUU...UUUU..
	//.MMMMMMM.MMMMMMM.EEEE........NNNNNN.NNNN..UUUU...UUUU..
	//.MMMMMMM.MMMMMMM.EEEE........NNNNNN.NNNN..UUUU...UUUU..
	//.MMMMMMM.MMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU..
	//.MMMMMMMMMMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU..
	//.MMMMMMMMMMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU..
	//.MMMMMMMMMMMMMMM.EEEE........NNNNNNNNNNN..UUUU...UUUU..
	//.MMMM.MMMMM.MMMM.EEEE........NNNN.NNNNNN..UUUU...UUUU..
	//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN..NNNNN..UUUUUUUUUUU..
	//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN..NNNNN...UUUUUUUUU...
	//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN...NNNN....UUUUUUU....
	//.......................................................

	const [bhMenuVisible, setBhMenuVisible] = useState(false)
	const popupRef = useRef<HTMLDivElement | null>(null); // Specify the type explicitly
	// Event listener to close the menu when clicked outside
	const toggleMenu = (event: MouseEvent) => {
		if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
			setBhMenuVisible(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', toggleMenu);
		return () => {
			document.removeEventListener('click', toggleMenu);
		};
	}, []);


	const handleReloadClick = () => {
		// Call the reload function passed as a prop
		reloadData();
	};




















	//.........................................................................
	//.PPPPPPPPP.....AAAA.....AAANN...NNNN.NNNEEEEEEEE.EEEL.........LSSSSSS....
	//.PPPPPPPPPP...AAAAAA....AAANN...NNNN.NNNEEEEEEEE.EEEL........LLSSSSSSS...
	//.PPPPPPPPPPP..AAAAAA....AAANNN..NNNN.NNNEEEEEEEE.EEEL.......LLLSSSSSSSS..
	//.PPPP...PPPP..AAAAAAA...AAANNNN.NNNN.NNNE........EEEL.......LLLS...SSSS..
	//.PPPP...PPPP.PAAAAAAA...AAANNNN.NNNN.NNNE........EEEL.......LLLSS........
	//.PPPPPPPPPPP.PAAAAAAA...AAANNNNNNNNN.NNNEEEEEEEE.EEEL........LLSSSSS.....
	//.PPPPPPPPPP..PAAA.AAAA..AAANNNNNNNNN.NNNEEEEEEEE.EEEL.........LSSSSSSS...
	//.PPPPPPPPP..PPAAAAAAAA..AAAN.NNNNNNN.NNNEEEEEEEE.EEEL...........SSSSSSS..
	//.PPPP.......PPAAAAAAAAA.AAAN.NNNNNNN.NNNE........EEEL..............SSSS..
	//.PPPP......PPPAAAAAAAAA.AAAN..NNNNNN.NNNE........EEEL.......LLLS...SSSS..
	//.PPPP......PPPA....AAAA.AAAN..NNNNNN.NNNEEEEEEEEEEEELLLLLLLLLLLSSSSSSSS..
	//.PPPP......PPPA....AAAAAAAAN...NNNNN.NNNEEEEEEEEEEEELLLLLLLL.LLSSSSSSSS..
	//.PPPP.....PPPPA.....AAAAAAAN....NNNN.NNNEEEEEEEEEEEELLLLLLLL..LSSSSSS....
	//.........................................................................

	const [todaySoilDescriptionsVisible, setTodaySoilDescriptionsVisible] = useState(true)
	const [allSoilDescriptionsVisible, setAllSoilDescriptionsVisible] = useState(false)

	const [completingBorehole, setCompletingBorehole] = useState(false)
	const menuAndMarkAsComplete = () => {
		setBhMenuVisible(value => !value)
		setCompletingBorehole(value => !value)
	}

	const [previewVisible, setPreviewVisible] = useState(false)
	const previewClick = () => setPreviewVisible(value => !value)
	const menuAndPreview = () => {
		setBhMenuVisible(value => !value)
		setPreviewVisible(value => !value)
	}
	const [labelsVisible, setLabelsVisible] = useState(false)
	const labelsClick = () => setLabelsVisible(value => !value)
	const menuAndLabels = () => {
		setBhMenuVisible(value => !value)
		setLabelsVisible(value => !value)
	}
	const [showHoleEdit, setShowHoleEdit] = useState(false)
	const [showNewHole, setShowNewHole] = useState(false)
	const menuAndNewHole = () => {
		setBhMenuVisible(value => !value)
		setShowNewHole(value => !value)
	}
	const [showEmail, setShowEmail] = useState(false)
	const menuAndEmail = () => {
		setBhMenuVisible(value => !value)
		setShowEmail(value => !value)
	}
	const [showAGS, setShowAGS] = useState(false)
	const menuAndAGS = () => {
		setBhMenuVisible(value => !value)
		setShowAGS(value => !value)
	}
	const [showSaveLog, setShowSaveLog] = useState(false)
	const menuAndSaveLog = () => {
		setBhMenuVisible(value => !value)
		setShowSaveLog(value => !value)
	}
	const [showPrintLabel, setShowPrintLabel] = useState(false)
	const printLabelClick = () => setShowPrintLabel(value => !value)
	const menuAndShowLabels = () => {
		setBhMenuVisible(value => !value)
		setShowPrintLabel(value => !value)
	}

	const [labelSample, setLabelSample] = useState('')
	const [labelDepth, setLabelDepth] = useState('')

	// const [redirect, setRedirect] = useState(false)
	// if (redirect) {
	// 	return <Redirect to={`/projects/dashboard/${project_guid}/window-sample`} />
	// }
	
	const history = useHistory();
	const [redirect, setRedirect] = React.useState(false);

	// const handleConfirmClick = () => {
	// 	closeHole(borehole_guid);
	// 	setRedirect(true);
	// };

	// Redirect manually using history.push
	React.useEffect(() => {
		if (redirect) {
		history.push(`/projects/dashboard/${project_guid}/trialpit`);
		}
	}, [redirect, history, project_guid]);

	const printAllSampleLabelsArea = useRef(null)
	const printAllTestLabelsArea = useRef(null)
	const printAllLinerLabelsArea = useRef(null)

	

	// END SHIFT
	const [showEndShiftDailyWaterLevels, setShowEndShiftDailyWaterLevels] = useState(false)
	const [showEndShiftSaveLog, setShowEndShiftSaveLog] = useState(false)
	const [showEndShiftAGS, setShowEndShiftAGS] = useState(false)
	const [showEndShiftEmail, setShowEndShiftEmail] = useState(false)
	const [showEndShiftLogout, setShowEndShiftLogout] = useState(false)
	
	const menuAndEndShift = () => {
		setBhMenuVisible(value => !value)
		setShowEndShiftSaveLog(value => !value)
	}



















	//.............................................................................................................................
	//.PPPPPPPPP.....AAAA.....AAANN...NNNN.NNNEEEEEEEE.EEEL............ TTTTTTTTTTTY....YYYYYYYPPPPPPP..PPPEEEEEEEE...ESSSSSS....
	//.PPPPPPPPPP...AAAAAA....AAANN...NNNN.NNNEEEEEEEE.EEEL............ TTTTTTTTTTTY...YYYYYYYYPPPPPPPP.PPPEEEEEEEE..EESSSSSSS...
	//.PPPPPPPPPPP..AAAAAA....AAANNN..NNNN.NNNEEEEEEEE.EEEL............ TTTTTTTTTTTYY..YYYY.YYYPPPPPPPPPPPPEEEEEEEE.EEESSSSSSSS..
	//.PPPP...PPPP..AAAAAAA...AAANNNN.NNNN.NNNE........EEEL................TTTT....TTYYYYYYY..YYYP....PPPPPPPE........EEES...SSSS..
	//.PPPP...PPPP.PAAAAAAA...AAANNNN.NNNN.NNNE........EEEL................TTTT.....TYYYYYYY..YYYP....PPPPPPPE........EEESS........
	//.PPPPPPPPPPP.PAAAAAAA...AAANNNNNNNNN.NNNEEEEEEEE.EEEL................TTTT.....TYYYYYY...YYYPPPPPPPPPPPPEEEEEEEE..EESSSSS.....
	//.PPPPPPPPPP..PAAA.AAAA..AAANNNNNNNNN.NNNEEEEEEEE.EEEL................TTTT......YYYYY....YYYPPPPPPPP.PPPEEEEEEEE...ESSSSSSS...
	//.PPPPPPPPP..PPAAAAAAAA..AAAN.NNNNNNN.NNNEEEEEEEE.EEEL................TTTT.......YYYY....YYYPPPPPPP..PPPEEEEEEEE.....SSSSSSS..
	//.PPPP.......PPAAAAAAAAA.AAAN.NNNNNNN.NNNE........EEEL................TTTT.......YYYY....YYYP........PPPE...............SSSS..
	//.PPPP......PPPAAAAAAAAA.AAAN..NNNNNN.NNNE........EEEL................TTTT.......YYYY....YYYP........PPPE........EEES...SSSS..
	//.PPPP......PPPA....AAAA.AAAN..NNNNNN.NNNEEEEEEEEEEEELLLLLLLL.........TTTT.......YYYY....YYYP........PPPEEEEEEEEEEEESSSSSSSS..
	//.PPPP......PPPA....AAAAAAAAN...NNNNN.NNNEEEEEEEEEEEELLLLLLLL.........TTTT.......YYYY....YYYP........PPPEEEEEEEEE.EESSSSSSSS..
	//.PPPP.....PPPPA.....AAAAAAAN....NNNN.NNNEEEEEEEEEEEELLLLLLLL.........TTTT.......YYYY....YYYP........PPPEEEEEEEEE..ESSSSSS....
	//.............................................................................................................................

	type SampleType = {
		guid: string
		id: number
		depth_to_base: any
		depth_to_top: any
		// description: any
		// duration: any
		general_remarks: any
		casing_depth: any
		// prep_details: any
		// sample_length: any
		type: any
		water_depth: any
		weather_details: any
		sampler: any
		time_of_action: any
	}
	type InSituTestsType = {
		id: number
		guid: string
		type: any
		type_number: any
		depth_to_top: any
		casing_depth: any
		depth_of_water: any
		seating_blows_1st_inc: any
		seating_blows_2nd_inc: any
		self_weight_penetration: any
		spt_in_soft_rock: any
		test_blows_1st_inc: any
		test_blows_2nd_inc: any
		test_blows_3rd_inc: any
		test_blows_4th_inc: any
		mm_over: any
		mm_overSB: any
		depth_to_base: any
		pen_1: any
		pen_2: any
		pen_3: any
		pen_4: any
		pen_5: any
		pen_6: any
		seating_blows: any
		total_blows: any
		reported_result: any
		weather_details: string
		general_remarks: string
		utype: any
		ublows: any
		sample_preparation: any
		spt_hammer_id: any
		spt_energy_ratio: any
		recovery: any
		// Shear Vane Stuff
		size: any
		test_type: any
		sv_result1: any
		sv_result2: any
		sv_result3: any
		sv_result4: any
		average: any
		suitableForTesting: any
		time_of_action: string

	}
	type InSituVaneDataType = {
		average: string
		depth_of_test: any
		general_remarks: string
		guid: string
		id: number
		size: string
		test_type: string
		time_of_action: string
		weather_details: string
	}
	type SoilDescriptionDataType = {
		depth_to_base: any
		depth_to_top: any
		general_remarks: string
		guid: string
		id: string
		stratum_type: string
		stratum_description: string
		time_of_action: string
	}
	type GroundWaterTestsDataType = {
		casing_depth: any
		depth_after_5: any
		depth_after_10: any
		depth_after_15: any
		depth_after_20: any
		depth_to_water: any
		depth_sealed: any
		sample_id: any
		general_remarks: string
		guid: string
		id: number
		rate_of_flow: string
		time_of_action: string
		time_of_strike: string
	}
	type InstallationDataType = {
		pipe_solid_1: any
		pipe_slotted_1: any
		pipe_width_1: any
		pipe_geosock_1: any
		gas_tap_1: any
		pipe_solid_2: any
		pipe_slotted_2: any
		pipe_width_2: any
		pipe_geosock_2: any
		gas_tap_2: any
		general_remarks: any
		cover_size: any
		cover_type: any
		cover_comment_text: any
		backfill_text_output: any
		guid: any
		id: any
		time_of_action: any
	}
	type BackfillDataType = {
		depth_to_base: any
		depth_to_top: any
		description: any
		backfillText: any
		layers: any
		general_remarks: any
		type: any
		guid: any
		id: any
		time_of_action: any
	}
	type CommentsDataType = {
		id: string
		guid: string
		comment_type: string
		start_time: any
		duration: string
		general_remarks: string
		time_of_action: string
	}
	type InspectionPitDataType = {
		id: number
		guid: string
		depth_to_top: any
		depth_to_base: any
		duration: any
		weather_details: string
		general_remarks: string
		time_of_action: string
	}
	type CasingDataType = {
		id: number
		guid: string
		casing_type: any
		depth_to_top: any
		casing_diameter: any
		depth_to_base: any
		depth_of_borehole: any
		general_remarks: string
		time_of_action: string
	}
	type ChisellingDataType = {
		id: number
		guid: string
		depth_at_start: any
		depth_at_end: any
		// start_time: any
		duration: any
		general_remarks: any
		time_of_action: any
	}
	type WaterAddedDataType = {
		id: number
		guid: string
		depth_to_top: any
		depth_to_base: any
		amount_added: any
		start_time: any
		general_remarks: any
		time_of_action: any
	}
	type DailyWaterLevelDataType = {
		id: number
		guid: string
		start_boring: any
		end_boring: any
		general_remarks: any
		time_of_action: any
	}
	type ReDrillDataType = {
		base_of_redrill: any
		casing_size: any
		general_remarks: any
		guid: string
		id: number
		time_of_action: any
		top_of_redrill: any
	}
	type DriveRecordDataType = {
		depth_from: any
		depth_to: any
		liner_diameter: any
		sample_length: any
		drive_number: any
		// duration: any
		// general_remarks: string
		hole_depth: any
		guid: string
		id: number
		time_of_action: string
	}

	const [SamplesData, setSamplesData] = useState<SampleType[]>([]);
	const [InSituTestsData, setInSituTestsData] = useState<InSituTestsType[]>([]);
	const [InSituVaneData, setInSituVaneData] = useState<InSituVaneDataType[]>([]);
	const [SoilDescriptionData, setSoilDescriptionData] = useState<SoilDescriptionDataType[]>([]);
	const [GroundWaterTestsData, setGroundWaterTestsData] = useState<GroundWaterTestsDataType[]>([]);
	const [BackfillData, setBackfillData] = useState<BackfillDataType[]>([]);
	const [InstallationData, setInstallationData] = useState<InstallationDataType[]>([]);
	const [CommentsData, setCommentsData] = useState<CommentsDataType[]>([]);
	const [InspectionPitData, setInspectionPitData] = useState<InspectionPitDataType[]>([]);
	const [CasingData, setCasingData] = useState<CasingDataType[]>([]);
	const [ChisellingData, setChisellingData] = useState<ChisellingDataType[]>([]);
	const [WaterAddedData, setWaterAddedData] = useState<WaterAddedDataType[]>([]);
	const [DailyWaterLevelData, setDailyWaterLevelData] = useState<DailyWaterLevelDataType[]>([]);
	const [ReDrillData, setReDrillData] = useState<ReDrillDataType[]>([]);
	const [DriveRecordData, setDriveRecordData] = useState<DriveRecordDataType[]>([]);




















	//.......................................................................................................
	//.PPPPPPPPP...PRRRRRRRRR.....OOOOOOO.........JJJJ....... DDDDDDDD.......AAAAA...AAATTTTTTTTT.TAAAA......
	//.PPPPPPPPPP..PRRRRRRRRRR...OOOOOOOOOO.......JJJJ....... DDDDDDDDD......AAAAA...AAATTTTTTTTT.TAAAA......
	//.PPPPPPPPPPP.PRRRRRRRRRR..ROOOOOOOOOOO......JJJJ....... DDDDDDDDDD....AAAAAA...AAATTTTTTTTT.TAAAAA.....
	//.PPPP...PPPP.PRRR...RRRRR.ROOOO..OOOOO......JJJJ....... DDD...DDDD....AAAAAAA......TTTT....TTAAAAA.....
	//.PPPP...PPPP.PRRR...RRRRRRROOO....OOOOO.....JJJJ....... DDD....DDDD..AAAAAAAA......TTTT....TTAAAAA.....
	//.PPPPPPPPPPP.PRRRRRRRRRR.RROO......OOOO.....JJJJ....... DDD....DDDD..AAAAAAAA......TTTT...TTTA.AAAA....
	//.PPPPPPPPPP..PRRRRRRRRRR.RROO......OOOO.....JJJJ....... DDD....DDDD..AAAA.AAAA.....TTTT...TTTA.AAAA....
	//.PPPPPPPPP...PRRRRRRR....RROO......OOOO.....JJJJ....... DDD....DDDD.DAAAAAAAAA.....TTTT...TTTAAAAAAA...
	//.PPPP........PRRR.RRRR...RROOO....OOOOOOOJJ.JJJJ....... DDD....DDDD.DAAAAAAAAAA....TTTT..TTTTAAAAAAA...
	//.PPPP........PRRR..RRRR...ROOOO..OOOOO.OOJJ.JJJJ....... DDD...DDDDD.DAAAAAAAAAA....TTTT..TTTTAAAAAAA...
	//.PPPP........PRRR..RRRRR..ROOOOOOOOOOO.OOJJJJJJJ....... DDDDDDDDDD.DDAA....AAAA....TTTT..TTTT....AAAA..
	//.PPPP........PRRR...RRRRR..OOOOOOOOOO..OOJJJJJJ........ DDDDDDDDD..DDAA.....AAAA...TTTT.TTTT.....AAAA..
	//.PPPP........PRRR....RRRR....OOOOOO.....OJJJJJ......... DDDDDDDD..DDDAA.....AAAA...TTTT.TTTT.....AAAA..
	//.......................................................................................................


	type ProjectData = {
		location?: string;
		project_identifier?: string;
		start_date?: string;
		site_description?: string;
		key_site_features?: string;
		site_history?: string;
		key_hands?: string;
		expected_strata?: string;
		comments?: string; // MISSING
		equipment_required?: string;
		client_name?: string;
		client_contact?: string;
		client_phone?: string;
		client_email?: string;
		engineer_name?: string;
		engineer_phone?: string;
		engineer_email?: string;
	}

	const cachedProjectValues = useGetIDBValues;

	const [projectData, setProjectData] = useState<ProjectData | null>(null);

	useEffect(() => {
		cachedProjectValues(`projects/${project_guid}`, (data: any) => {
			// console.log('Retrieved project data:', data); // Log the retrieved data
			setProjectData(data);
		}).then();
	}, [project_guid]);

	const project_location = projectData?.location ?? ''
	const projectID = projectData?.project_identifier ?? ''
	const project_start_date = projectData?.start_date ?? ''
	const site_description = projectData?.site_description ?? ''
	const key_site_features = projectData?.key_site_features ?? ''
	const site_history = projectData?.site_history ?? ''
	const key_hands = projectData?.key_hands ?? ''
	const expected_strata = projectData?.expected_strata ?? ''
	const notes = projectData?.comments ?? ''
	const equipment_required = projectData?.equipment_required ?? ''
	const client = projectData?.client_name ?? '' // client_name
	const client_contact = projectData?.client_contact ?? '' //client_contact
	const client_phone = projectData?.client_phone ?? '' // client_phone
	const client_email = projectData?.client_email ?? '' //client_email
	const engineer_name = projectData?.engineer_name ?? '' // engineer_name
	const engineer_phone = projectData?.engineer_phone ?? '' //engineer_phone
	const engineer_email = projectData?.engineer_email ?? '' //engineer_email

	const [engineerName, setEngineerName] = useState(engineer_name)

	if (engineer_name !== engineerName) {
		setEngineerName(engineer_name)
	}




















	//.........................................................
	//....CCCCCCC....RRRRRRRRRR...EEEEEEEEEEEEWWW..WWWWW...WW..
	//...CCCCCCCCC...RRRRRRRRRRR..EEEEEEEEEEEEWWW..WWWWW..WWW..
	//..CCCCCCCCCCC..RRRRRRRRRRR..EEEEEEEEEEEEWWW..WWWWWW.WWW..
	//..CCCC...CCCCC.RRRR...RRRRR.EEEE.......EWWW.WWWWWWW.WWW..
	//.CCCC.....CCC..RRRR...RRRRR.EEEE.......EWWW.WWWWWWW.WWW..
	//.CCCC..........RRRRRRRRRRR..EEEEEEEEEE..WWWWWWWWWWW.WWW..
	//.CCCC..........RRRRRRRRRRR..EEEEEEEEEE..WWWWWWW.WWWWWWW..
	//.CCCC..........RRRRRRRR.....EEEEEEEEEE..WWWWWWW.WWWWWWW..
	//.CCCC.....CCC..RRRR.RRRR....EEEE........WWWWWWW.WWWWWWW..
	//..CCCC...CCCCC.RRRR..RRRR...EEEE........WWWWWWW.WWWWWWW..
	//..CCCCCCCCCCC..RRRR..RRRRR..EEEEEEEEEEE..WWWWW...WWWWW...
	//...CCCCCCCCCC..RRRR...RRRRR.EEEEEEEEEEE..WWWWW...WWWWW...
	//....CCCCCCC....RRRR....RRRR.EEEEEEEEEEE..WWWWW...WWWWW...
	//.........................................................

	const loggedInUser = useGetLoggedInUser()
	// const [User, setUser] = useState(loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name))
	const User = loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name)
	const [userWarning, setUserWarning] = useState(false)
	
	const negPhrases = [
		"You shall not pass!",
		"None shall pass!",
		"Access denied!",
		"No trespassing!",
		"Entry prohibited!",
		"Stay out!",
		"Get yer stinkin' mitts off!",
		"These aren't the droids you're looking for!"
	  ];
	
	const randomNegPhrases = negPhrases[Math.floor(Math.random() * negPhrases.length)];

	const [leadDriller, setLeadDriller] = useState('')
	const [leadDrillerID, setLeadDrillerID] = useState(0)
	const [leadDrillerCSCS, setLeadDrillerCSCS] = useState('')
	const [secondMan, setSecondMan] = useState('')
	const [secondManCSCS, setSecondManCSCS] = useState('')
	const [hole_barcode, setHole_barcode] = useState('')
	const [hole_status, setHole_status] = useState('')
	const [numberingType, setNumberingType] = useState('')

	const [savedRigGuid, setSavedRigValue] = useState('')
	const [plantName, setPlantName] = useState('')
	const [plantMakeModel, setPlantMakeModel] = useState('')
	// console.log('plantMakeModel:', plantMakeModel)
	const [sptGUID, setSptGUID] = useState('')
	const [activityTerminationReason, setActivityTerminationReason] = useState('')

	const [boreholeName, setBoreholeName] = useState(borehole_name)

	const cachedHoleInformation = useGetIDBValues

	useEffect(() => {
		if(borehole_guid){
			cachedHoleInformation(`hole-locations/${borehole_guid}`, (holeData: any) => {
				if (holeData) {
					// console.log('holeData in PERSONNEL:', holeData)
					setLeadDriller(holeData.driller)
					setLeadDrillerID(holeData.driller_id)
					setLeadDrillerCSCS(holeData.driller_cscs);
					setSecondMan(holeData.second_man);
					setSecondManCSCS(holeData.second_man_cscs);
					setHole_barcode(holeData.hole_barcode);
					setSavedRigValue(holeData.rig_id); // RIG GUID
					setPlantName(holeData.rig_name); // RIG NAME
					setPlantMakeModel(holeData.make_model); // RIG MAKE/MODEL
					setSptGUID(holeData.spt_id); // SPT ID
					setActivityTerminationReason(holeData.activity_termination_reason); // TERMINATION REASON
					setBoreholeName(holeData.hole_name); // HOLE NAME
					setHole_status(holeData.status); // HOLE STATUS
					setNumberingType(holeData.numberingType); // NUMBERING TYPE
				
					// console.log('PERSONNEL*********************************************')
					// console.log('leadDriller:', leadDriller)
					// console.log('leadDrillerID:', leadDrillerID)
					// console.log('leadDrillerCSCS:', leadDrillerCSCS)
					// console.log('secondMan:', secondMan)
					// console.log('secondManCSCS:', secondManCSCS)
					// console.log('hole_barcode:', hole_barcode)
					// console.log('savedRigGuid:', savedRigGuid)
					// console.log('plantName:', plantName)
					// console.log('plantName in CREW:', plantMakeModel)
					// console.log('sptGUID:', sptGUID)
					// console.log('boreholeName:', boreholeName)
					// console.log('hole_status:', hole_status)
					// console.log('selectedDate:', selectedDate)
					// console.log('activityTerminationReason:', activityTerminationReason)
					// console.log('******************************************************')
				} else {
					// // console.log(`**ERROR** HOLE DATA NOT LOADED. ID: ${borehole_guid}`);
				}
				// // console.log('holeData:', holeData);

			});
		}
	}, [borehole_guid]);




















	//.............................................................................................
	//....CCCCCCC......OOOOOOO....MMMMMM...MMMMMM.PPPPPPPPP.....AAAA.....ANNNN...NNNNNNYYY....YYY..
	//...CCCCCCCCC....OOOOOOOOOO..MMMMMM...MMMMMM.PPPPPPPPPP...AAAAAA....ANNNN...NNNN.NYYY...YYYY..
	//..CCCCCCCCCCC..OOOOOOOOOOOO.MMMMMM...MMMMMM.PPPPPPPPPPP..AAAAAA....ANNNNN..NNNN..YYYY..YYYY..
	//..CCCC...CCCCC.OOOOO..OOOOO.MMMMMMM.MMMMMMM.PPPP...PPPP..AAAAAAA...ANNNNNN.NNNN..YYYYYYYYY...
	//.CCCC.....CCC.OOOOO....OOOOOMMMMMMM.MMMMMMM.PPPP...PPPP.AAAAAAAA...ANNNNNN.NNNN...YYYYYYYY...
	//.CCCC.........OOOO......OOOOMMMMMMM.MMMMMMM.PPPPPPPPPPP.AAAAAAAA...ANNNNNNNNNNN...YYYYYYY....
	//.CCCC.........OOOO......OOOOMMMMMMMMMMMMMMM.PPPPPPPPPP..AAAA.AAAA..ANNNNNNNNNNN....YYYYY.....
	//.CCCC.........OOOO......OOOOMMMMMMMMMMMMMMM.PPPPPPPPP..AAAAAAAAAA..ANNN.NNNNNNN.....YYYY.....
	//.CCCC.....CCC.OOOOO....OOOOOMMMMMMMMMMMMMMM.PPPP.......AAAAAAAAAAA.ANNN.NNNNNNN.....YYYY.....
	//..CCCC...CCCCC.OOOOO..OOOOO.MMMM.MMMMM.MMMM.PPPP......PAAAAAAAAAAA.ANNN..NNNNNN.....YYYY.....
	//..CCCCCCCCCCC..OOOOOOOOOOOO.MMMM.MMMMM.MMMM.PPPP......PAAA....AAAA.ANNN..NNNNNN.....YYYY.....
	//...CCCCCCCCCC...OOOOOOOOOO..MMMM.MMMMM.MMMM.PPPP......PAAA....AAAAAANNN...NNNNN.....YYYY.....
	//....CCCCCCC.......OOOOOO....MMMM.MMMMM.MMMM.PPPP.....PPAAA.....AAAAANNN....NNNN.....YYYY.....
	//.............................................................................................

	const [companyInfo, setCompanyInfo] = useState({
		companyID: '',
		companyName: '',
		contactEmail: '',
		contactTelephone: '',
		companyAddress1: '',
		companyAddress2: '',
		companyAddress3: '',
		companyCounty: '',
		companyCountry: '',
		companyPostcode: '',
	});

	useEffect(() => {
		const getCompanyInfoFromCache = async () => {
			const response = await get('drillit-user');
			setCompanyInfo({
				companyID: response.user.company.id,
				companyName: response.user.company.name,
				contactEmail: response.user.company.email,
				contactTelephone: response.user.company.phone,
				companyAddress1: response.user.company.address1,
				companyAddress2: response.user.company.address2,
				companyAddress3: response.user.company.address3,
				companyCounty: response.user.company.county,
				companyCountry: response.user.company.country,
				companyPostcode: response.user.company.postcode,
			});
		};

		getCompanyInfoFromCache();
	}, []);

	const companyID = companyInfo.companyID
	const companyName = companyInfo.companyName
	const companyTelephone = companyInfo.contactTelephone
	const companyEmail = companyInfo.contactEmail

	let companyAddress = companyInfo.companyAddress1
	if (companyInfo.companyAddress2) { companyAddress = companyAddress + ', ' + companyInfo.companyAddress2 }
	if (companyInfo.companyAddress3) { companyAddress = companyAddress + ', ' + companyInfo.companyAddress3 }
	if (companyInfo.companyCounty) { companyAddress = companyAddress + ', ' + companyInfo.companyCounty }
	if (companyInfo.companyCountry) { companyAddress = companyAddress + ', ' + companyInfo.companyCountry }
	if (companyInfo.companyPostcode) { companyAddress = companyAddress + ', ' + companyInfo.companyPostcode }

	const companyLogo = "https://drillitlogs.com/images/company/" + companyID + ".png"











	// Info to pass into 'ProjectHeader' & 'ProjectOptionsBorehole'
	// Hole Name
	// Hole Driller
	// 2nd man
	// Rig
	// Daily Plant Check




















	//..........................................................................
	//.LLLL..........AAAAA.....BBBBBBBBBB...EEEEEEEEEEE.LLLL........SSSSSSS.....
	//.LLLL..........AAAAA.....BBBBBBBBBBB..EEEEEEEEEEE.LLLL.......SSSSSSSSS....
	//.LLLL.........AAAAAA.....BBBBBBBBBBB..EEEEEEEEEEE.LLLL.......SSSSSSSSSS...
	//.LLLL.........AAAAAAA....BBBB...BBBB..EEEE........LLLL......LSSSS..SSSS...
	//.LLLL........AAAAAAAA....BBBB...BBBB..EEEE........LLLL......LSSSS.........
	//.LLLL........AAAAAAAA....BBBBBBBBBBB..EEEEEEEEEE..LLLL.......SSSSSSS......
	//.LLLL........AAAA.AAAA...BBBBBBBBBB...EEEEEEEEEE..LLLL........SSSSSSSSS...
	//.LLLL.......AAAAAAAAAA...BBBBBBBBBBB..EEEEEEEEEE..LLLL..........SSSSSSS...
	//.LLLL.......AAAAAAAAAAA..BBBB....BBBB.EEEE........LLLL.............SSSSS..
	//.LLLL.......AAAAAAAAAAA..BBBB....BBBB.EEEE........LLLL......LSSS....SSSS..
	//.LLLLLLLLLLLAAA....AAAA..BBBBBBBBBBBB.EEEEEEEEEEE.LLLLLLLLLLLSSSSSSSSSSS..
	//.LLLLLLLLLLLAAA.....AAAA.BBBBBBBBBBB..EEEEEEEEEEE.LLLLLLLLLL.SSSSSSSSSS...
	//.LLLLLLLLLLLAAA.....AAAA.BBBBBBBBBB...EEEEEEEEEEE.LLLLLLLLLL..SSSSSSSS....
	//..........................................................................

	const projectLocation = project_location
	let logsheetLocationStyle = "headerCellLocationInput"
	if (projectLocation) {
		if (projectLocation.length <= 25) { logsheetLocationStyle = "headerCellLocationInput" }
		if (projectLocation.length > 25 && projectLocation.length <= 60) { logsheetLocationStyle = "headerCellLocationInputSmall" }
		if (projectLocation.length > 60) { logsheetLocationStyle = "headerCellLocationInputXSmall" }
	}

	let sheetDateObject = new Date(selectedDate)
	const sheetDate = sheetDateObject.toLocaleDateString()

	const labelbarcodeVar = projectID + '-' + borehole_name

	const barcodeWidths = [
		3.3, 3.3, 3.3, 3.3, 3.3, 3.3, 3.0, 2.7, 2.5, 2.3, // 1 to 10
		2.1, 2.0, 1.85, 1.75, 1.65, 1.55, 1.5, 1.42, 1.36, 1.3, // 11 to 20
		1.25, 1.2, 1.15, 1.1, 1.05, 1.0, 1.0, 0.95, 0.93, 0.90, // 21 to 30
		0.88, 0.85, 0.83, 0.80, 0.80, 0.76, 0.75, 0.73, 0.72, 0.71 // 31 to 40
	];

	let barcodeWidth = barcodeWidths[labelbarcodeVar.length - 1]





















	//.............................................................
	//.DDDDDDDDD.......AAAAA...AATTTTTTTTTTTEEEEEEEEE...SSSSSSS....
	//.DDDDDDDDDD......AAAAA...AATTTTTTTTTTTEEEEEEEEE..ESSSSSSSS...
	//.DDDDDDDDDDD....AAAAAA...AATTTTTTTTTTTEEEEEEEEE.EESSSSSSSSS..
	//.DDDD...DDDD....AAAAAAA......TTTT...TTEE........EESS...SSSS..
	//.DDDD....DDDD..AAAAAAAA......TTTT...TTEE........EESSS........
	//.DDDD....DDDD..AAAAAAAA......TTTT...TTEEEEEEEEE..ESSSSSS.....
	//.DDDD....DDDD..AAAA.AAAA.....TTTT...TTEEEEEEEEE...SSSSSSSS...
	//.DDDD....DDDD.AAAAAAAAAA.....TTTT...TTEEEEEEEEE.....SSSSSSS..
	//.DDDD....DDDD.AAAAAAAAAAA....TTTT...TTEE...............SSSS..
	//.DDDD...DDDDD.AAAAAAAAAAA....TTTT...TTEE........EESS...SSSS..
	//.DDDDDDDDDDD.DAAA....AAAA....TTTT...TTEEEEEEEEEEEESSSSSSSSS..
	//.DDDDDDDDDD..DAAA.....AAAA...TTTT...TTEEEEEEEEEE.ESSSSSSSSS..
	//.DDDDDDDDD..DDAAA.....AAAA...TTTT...TTEEEEEEEEEE..SSSSSSS....
	//.............................................................

	const [active_dates, setActive_dates] = useState(['06/04/2023', '05/04/2023'])

	function setAllActiveDates() {
		setActive_dates(activeDates)
	}

	useEffect(() => {
		setAllActiveDates()
	}, [activeDates])





	// Convert selectedDate in yyyy-mm-dd format
	// strip selectedDate
	const originalDate = new Date(selectedDate)
	const year = originalDate.getFullYear()
	const month = String(originalDate.getMonth() + 1).padStart(2, '0') // Months are 0-indexed
	const day = String(originalDate.getDate()).padStart(2, '0')

	const formattedDate = `${year}-${month}-${day}`

	// console.log('formattedDate:', formattedDate)




















	//........................................................................................................
	//.HHHH...HHHH....OOOOOOO.....LLLL.......EEEEEEEEEEE...... DDDDDDDD.......AAAAA...AATTTTTTTTTT.AAAAA......
	//.HHHH...HHHH...OOOOOOOOOO...LLLL.......EEEEEEEEEEE...... DDDDDDDDD......AAAAA...AATTTTTTTTTT.AAAAA......
	//.HHHH...HHHH..OOOOOOOOOOOO..LLLL.......EEEEEEEEEEE...... DDDDDDDDDD....AAAAAA...AATTTTTTTTTT.AAAAAA.....
	//.HHHH...HHHH..OOOOO..OOOOO..LLLL.......EEEE............. DDD...DDDD....AAAAAAA......TTTT....TAAAAAA.....
	//.HHHH...HHHH.HOOOO....OOOOO.LLLL.......EEEE............. DDD....DDDD..AAAAAAAA......TTTT....TAAAAAA.....
	//.HHHHHHHHHHH.HOOO......OOOO.LLLL.......EEEEEEEEEE....... DDD....DDDD..AAAAAAAA......TTTT...TTAA.AAAA....
	//.HHHHHHHHHHH.HOOO......OOOO.LLLL.......EEEEEEEEEE....... DDD....DDDD..AAAA.AAAA.....TTTT...TTAA.AAAA....
	//.HHHHHHHHHHH.HOOO......OOOO.LLLL.......EEEEEEEEEE....... DDD....DDDD.DAAAAAAAAA.....TTTT...TTAAAAAAAA...
	//.HHHH...HHHH.HOOOO....OOOOO.LLLL.......EEEE............. DDD....DDDD.DAAAAAAAAAA....TTTT..TTTAAAAAAAA...
	//.HHHH...HHHH..OOOOO..OOOOO..LLLL.......EEEE............. DDD...DDDDD.DAAAAAAAAAA....TTTT..TTTAAAAAAAA...
	//.HHHH...HHHH..OOOOOOOOOOOO..LLLLLLLLLL.EEEEEEEEEEE...... DDDDDDDDDD.DDAA....AAAA....TTTT..TTTA....AAAA..
	//.HHHH...HHHH...OOOOOOOOOO...LLLLLLLLLL.EEEEEEEEEEE...... DDDDDDDDD..DDAA.....AAAA...TTTT.TTTT.....AAAA..
	//.HHHH...HHHH.....OOOOOO.....LLLLLLLLLL.EEEEEEEEEEE...... DDDDDDDD..DDDAA.....AAAA...TTTT.TTTT.....AAAA..
	//........................................................................................................

	function getAllHoleData() { // split out the HOLE DATA and SET CONSTs

		// console.log("getAllHoleData function run! THIS ONLY RUNS ONCE!!!***************************************************************")

		// const SampleArray = hole_data?.action_sample
		// const unpackedSamples = typeof SampleArray !== 'undefined' ? SampleArray : []
		const SampleArray = hole_data?.action_sample
		const unpackedSamples = SampleArray?.filter((sample: any) => sample) || [];
		setSamplesData(unpackedSamples)
		// console.log("Action Sample Array:", AllHoleData?.hole_data?.action_sample)
		// console.log("Unpacked Samples:", unpackedSamples)
		// console.log("samplesData:", SamplesData)

		const InsituTestsArray = hole_data?.action_in_situ_spt;
		const unpackedInsituTests = InsituTestsArray?.filter((sample: any) => sample) || [];
		setInSituTestsData(unpackedInsituTests);

		const SoilDescriptionsArray = hole_data?.action_soil_description;
		const unpackedSoilDescriptions = SoilDescriptionsArray?.filter((sample: any) => sample) || [];
		setSoilDescriptionData(unpackedSoilDescriptions);

		const GroundWaterTestsArray = hole_data?.action_groundwater;
		const unpackedGroundWaterTests = GroundWaterTestsArray?.filter((sample: any) => sample) || [];
		setGroundWaterTestsData(unpackedGroundWaterTests);

		const BackfillArray = hole_data?.action_backfills;
		const unpackedBackfill = BackfillArray?.filter((sample: any) => sample) || [];
		setBackfillData(unpackedBackfill);

		const InstallArray = hole_data?.action_installation;
		const unpackedInstall = InstallArray?.filter((sample: any) => sample) || [];
		setInstallationData(unpackedInstall);

		const CommentsArray = hole_data?.action_comment;
		const unpackedComments = CommentsArray?.filter((sample: any) => sample) || [];
		setCommentsData(unpackedComments);

		const InspectionPitArray = hole_data?.action_inspection_pit;
		const unpackedInspectionPit = InspectionPitArray?.filter((sample: any) => sample) || [];
		setInspectionPitData(unpackedInspectionPit);

		const CasingArray = hole_data?.action_casing;
		const unpackedCasing = CasingArray?.filter((sample: any) => sample) || [];
		setCasingData(unpackedCasing);

		const ChisellingArray = hole_data?.action_chiselling;
		const unpackedChiselling = ChisellingArray?.filter((sample: any) => sample) || [];
		setChisellingData(unpackedChiselling);

		const WaterAddedArray = hole_data?.action_water_added;
		const unpackedWaterAdded = WaterAddedArray?.filter((sample: any) => sample) || [];
		setWaterAddedData(unpackedWaterAdded);

		const ReDrillArray = hole_data?.action_redrill;
		const unpackedReDrill = ReDrillArray?.filter((sample: any) => sample) || [];
		setReDrillData(unpackedReDrill);

		// console.log('unpackedReDrill:', unpackedReDrill)

		const DailyWaterLevelArray = hole_data?.action_water_level;
		const unpackedDailyWaterLevel = DailyWaterLevelArray?.filter((sample: any) => sample) || [];
		setDailyWaterLevelData(unpackedDailyWaterLevel);

		const DriveRecordArray = AllHoleData?.hole_data?.action_drive_record;
		const unpackedDriveRecord = DriveRecordArray?.filter((sample: any) => sample) || [];
		setDriveRecordData(unpackedDriveRecord);
	}

	// console.log('AllHoleData: ', AllHoleData) // use this to check hole data arrays

	useEffect(() => {
		getAllHoleData()
	}, []
	)




















	//.......................................................................................................
	//...SSSSSSS.......AAAAA..AAAVV....VVVVVVEEEEEEEEE...... DDDDDDDD.......AAAA....AAATTTTTTTTT.TAAAA......
	//..SSSSSSSSS......AAAAA...AAVV....VVVVVVEEEEEEEEE...... DDDDDDDDD.....AAAAAA...AAATTTTTTTTT.TAAAA......
	//..SSSSSSSSSS....AAAAAA...AAVV...VVVVVVVEEEEEEEEE...... DDDDDDDDDD....AAAAAA...AAATTTTTTTTTTTAAAA......
	//.SSSSS..SSSS....AAAAAAA..AAVVV..VVVV.VVEE............. DD....DDDD....AAAAAAA......TTTT....TTAAAAA.....
	//.SSSSS.........AAAAAAAA...AVVV..VVVV.VVEE............. DD....DDDDD..AAAAAAAA......TTTT...TTTAAAAA.....
	//..SSSSSSS......AAAAAAAA...AVVV..VVVV.VVEEEEEEEEE...... DD.....DDDD..AAAAAAAA......TTTT...TTTAAAAA.....
	//...SSSSSSSSS...AAAA.AAAA..AVVV.VVVV..VVEEEEEEEEE...... DD.....DDDD..AAAA.AAAA.....TTTT...TTTA.AAAA....
	//.....SSSSSSS..AAAAAAAAAA...VVVVVVVV..VVEEEEEEEEE...... DD.....DDDD.DAAAAAAAAA.....TTTT..TTTTAAAAAA....
	//........SSSSS.AAAAAAAAAAA..VVVVVVVV..VVEE............. DD....DDDDD.DAAAAAAAAAA....TTTT..TTTTAAAAAAA...
	//.SSSS....SSSS.AAAAAAAAAAA..VVVVVVV...VVEE............. DD....DDDD.DDAAAAAAAAAA....TTTT..TTTTAAAAAAA...
	//.SSSSSSSSSSSSSAAA....AAAA...VVVVVV...VVEEEEEEEEEE..... DDDDDDDDDD.DDAA....AAAA....TTTT.TTTT....AAAA...
	//..SSSSSSSSSS.SAAA.....AAAA..VVVVVV...VVEEEEEEEEEE..... DDDDDDDDD..DDAA....AAAAA...TTTT.TTTT.....AAAA..
	//...SSSSSSSS.SSAAA.....AAAA..VVVVV....VVEEEEEEEEEE..... DDDDDDDD..DDDAA.....AAAA...TTTTTTTTT.....AAAA..
	//.......................................................................................................

	function saveAction(value: any, type: string, hide: any) {

		const saveToIDB = useSetIndexedDBValues
		const updateIDB = useUpdateIndexedDBValues
		const deleteFromIDB = useDeleteIndexedDBValue

		value['borehole_guid'] = borehole_guid;
		value['type'] = type;

		switch (type) {

			case 'D':
			case 'B':
			case 'ES':
			case 'WS':
				const submitSamplesData = {
					"task_type": "action_sample",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value,
						"weather_details": selectedWeather,
						"sampler": leadDriller,
					}
				}

				if (value['update']) {
					const newState = SamplesData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setSamplesData(newState)
					del(`drillit-actions/${value['guid']}-update`)
					updateIDB(state, `actions/${value['guid']}`, 'SamplesData', value['guid'], 'sample', submitSamplesData)
				} else if (value['delete']) {
					const newState = SamplesData.filter((obj) => obj.guid !== value['guid']);
					setSamplesData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'SamplesData', value['guid'], 'sample', submitSamplesData)
				} else {
					setSamplesData(prevState => [...prevState, value])
					saveToIDB(submitSamplesData, 'action', true)
				}

				break;


			case 'SPT':
			case 'CPT':
			case 'U':
			case 'PS':
			case 'SV':
				const submitInSituData = {
					"task_type": "action_in_situ_spt",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value,
						"weather_details": selectedWeather,
						"spt_hammer_id": sptID !== undefined ? sptID : '',
						"spt_energy_ratio": sptEnergyRatio !== undefined ? sptEnergyRatio : 0,
						"sampler": leadDriller,
					}
				}

				if (value['update']) {
					const newState = InSituTestsData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setInSituTestsData(newState)
					del(`drillit-actions/${value['guid']}-update`)
					updateIDB(state, `actions/${value['guid']}`, 'InSituTestsData', value['guid'], 'in_situ_spt', submitInSituData)
				} else if (value['delete']) {
					const newState = InSituTestsData.filter((obj) => obj.guid !== value['guid']);
					setInSituTestsData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'InSituTestsData', value['guid'], 'in_situ_spt', submitInSituData)
				} else {
					setInSituTestsData(prevState => [...prevState, value])
					saveToIDB(submitInSituData, 'action', true)
				}

				break;


			case 'SoilDescription':
				const submitSoilDescriptionData = {
					"task_type": "action_soil_description",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value
					}
				}

				if (value['update']) {
					const newState = SoilDescriptionData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setSoilDescriptionData(newState)
					del(`drillit-actions/${value['guid']}-update`)
					updateIDB(state, `actions/${value['guid']}`, 'SoilDescriptionData', value['guid'], 'soil_description', submitSoilDescriptionData)
				} else if (value['delete']) {
					const newState = SoilDescriptionData.filter((obj) => obj.guid !== value['guid']);
					setSoilDescriptionData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'SoilDescriptionData', value['guid'], 'soil_description', submitSoilDescriptionData)
				} else {
					setSoilDescriptionData(prevState => [...prevState, value])
					saveToIDB(submitSoilDescriptionData, 'action', true)
				}

				break;

			case 'GroundWater':
				const submitGroundWaterData = {
					"task_type": "action_groundwater",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value
					}
				}

				if (value['update']) {
					const newState = GroundWaterTestsData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setGroundWaterTestsData(newState)
					del(`drillit-actions/${value['guid']}-update`)
					updateIDB(state, `actions/${value['guid']}`, 'GroundWaterTestsData', value['guid'], 'groundwater', submitGroundWaterData)
				} else if (value['delete']) {
					const newState = GroundWaterTestsData.filter((obj) => obj.guid !== value['guid']);
					setGroundWaterTestsData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'GroundWaterTestsData', value['guid'], 'groundwater', submitGroundWaterData)
				} else {
					setGroundWaterTestsData(prevState => [...prevState, value])
					saveToIDB(submitGroundWaterData, 'action', true)
				}

				break;

			case 'Installation':
				const submitInstallationData = {
					"task_type": "action_installation",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value
					}
				}

				if (value['update']) {
					const newState = InstallationData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setInstallationData(newState)
					del(`drillit-actions/${value['guid']}-update`)
					updateIDB(state, `actions/${value['guid']}`, 'InstallationData', value['guid'], 'installation', submitInstallationData)
				} else if (value['delete']) {
					const newState = InstallationData.filter((obj) => obj.guid !== value['guid']);
					setInstallationData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'InstallationData', value['guid'], 'installation', submitInstallationData)
				} else {
					setInstallationData(prevState => [...prevState, value])
					saveToIDB(submitInstallationData, 'action', true)
				}

				break;

			case 'Backfill':
				const submitBackfillData = {
					"task_type": "action_backfills",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value,
						"weather_details": selectedWeather,
						"hole_location_id": borehole_id,
					}
				}

				if (value['update']) {
					const newState = BackfillData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setBackfillData(newState)
					del(`drillit-actions/${value['guid']}-update`)
					updateIDB(state, `actions/${value['guid']}`, 'BackfillData', value['guid'], 'backfills', submitBackfillData)
				} else if (value['delete']) {
					const newState = BackfillData.filter((obj) => obj.guid !== value['guid']);
					setBackfillData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'BackfillData', value['guid'], 'backfills', submitBackfillData)
				} else {
					setBackfillData(prevState => [...prevState, value])
					saveToIDB(submitBackfillData, 'action', true)
				}

				break;

			case 'Comments':
				const submitCommentsData = {
					"task_type": "action_comment",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value
					}
				}

				if (value['update']) {
					const newState = CommentsData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setCommentsData(newState)
					del(`drillit-actions/${value['guid']}-update`)
					updateIDB(state, `actions/${value['guid']}`, 'CommentsData', value['guid'], 'comment', submitCommentsData)
				} else if (value['delete']) {
					const newState = CommentsData.filter((obj) => obj.guid !== value['guid']);
					setCommentsData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'CommentsData', value['guid'], 'comment', submitCommentsData)
				} else {
					setCommentsData(prevState => [...prevState, value])
					saveToIDB(submitCommentsData, 'action', true)
				}

				break;

			case 'InspectionPit':
				const submitInspectionPitData = {
					"task_type": "action_inspection_pit",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						"weather_details": selectedWeather,
						...value
					}
				}

				if (value['update']) {
					const newState = InspectionPitData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setInspectionPitData(newState)
					del(`drillit-actions/${value['guid']}-update`)
					updateIDB(state, `actions/${value['guid']}`, 'InspectionPitData', value['guid'], 'inspection_pit', submitInspectionPitData)
				} else if (value['delete']) {
					const newState = InspectionPitData.filter((obj) => obj.guid !== value['guid']);
					setInspectionPitData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'InspectionPitData', value['guid'], 'inspection_pit', submitInspectionPitData)
				} else {
					setInspectionPitData(prevState => [...prevState, value])
					saveToIDB(submitInspectionPitData, 'action', true)
				}

				break;

			case 'Casing':
				const submitCasingData = {
					"task_type": "action_casing",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value
					}
				}

				if (value['update']) {
					const newState = CasingData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setCasingData(newState)
					del(`drillit-actions/${value['guid']}-update`)
					updateIDB(state, `actions/${value['guid']}`, 'CasingData', value['guid'], 'casing', submitCasingData)
				} else if (value['delete']) {
					const newState = CasingData.filter((obj) => obj.guid !== value['guid']);
					setCasingData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'CasingData', value['guid'], 'casing', submitCasingData)
				} else {
					setCasingData(prevState => [...prevState, value])
					saveToIDB(submitCasingData, 'action', true)
				}

				break;

			case 'Chiselling':
				const submitChisellingData = {
					"task_type": "action_chiselling",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value
					}
				}

				if (value['update']) {
					const newState = ChisellingData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setChisellingData(newState)
					del(`drillit-actions/${value['guid']}-update`)
					updateIDB(state, `actions/${value['guid']}`, 'ChisellingData', value['guid'], 'chiselling', submitChisellingData)
				} else if (value['delete']) {
					const newState = ChisellingData.filter((obj) => obj.guid !== value['guid']);
					setChisellingData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'ChisellingData', value['guid'], 'chiselling', submitChisellingData)
				} else {
					setChisellingData(prevState => [...prevState, value])
					saveToIDB(submitChisellingData, 'action', true)
				}

				break;

			case 'WaterAdded':
				const submitWaterAddedData = {
					"task_type": "action_water_added",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value
					}
				}

				if (value['update']) {
					const newState = WaterAddedData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setWaterAddedData(newState)
					del(`drillit-actions/${value['guid']}-update`)
					updateIDB(state, `actions/${value['guid']}`, 'WaterAddedData', value['guid'], 'water_added', submitWaterAddedData)
				} else if (value['delete']) {
					const newState = WaterAddedData.filter((obj) => obj.guid !== value['guid']);
					setWaterAddedData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'WaterAddedData', value['guid'], 'water_added', submitWaterAddedData)
				} else {
					setWaterAddedData(prevState => [...prevState, value])
					saveToIDB(submitWaterAddedData, 'action', true)
				}

				break;

			case 'DailyWaterLevel':
				const submitDailyWaterLevelData = {
					"task_type": "action_daily_water_levels",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value
					}
				}

				if (value['update']) {
					const newState = DailyWaterLevelData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setDailyWaterLevelData(newState)
					del(`drillit-actions/${value['guid']}-update`)
					updateIDB(state, `actions/${value['guid']}`, 'DailyWaterLevelData', value['guid'], 'daily_water_levels', submitDailyWaterLevelData)
				} else if (value['delete']) {
					const newState = DailyWaterLevelData.filter((obj) => obj.guid !== value['guid']);
					setDailyWaterLevelData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'DailyWaterLevelData', value['guid'], 'daily_water_levels', submitDailyWaterLevelData)
				} else {
					setDailyWaterLevelData(prevState => [...prevState, value])
					saveToIDB(submitDailyWaterLevelData, 'action', true)
				}

				break;

			case 'ReDrill':
				const submitReDrillData = {
					"task_type": "action_redrill",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value
					}
				}

				if (value['update']) {
					const newState = ReDrillData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setReDrillData(newState)
					del(`drillit-actions/${value['guid']}-update`)
					updateIDB(state, `actions/${value['guid']}`, 'ReDrillData', value['guid'], 'redrill', submitReDrillData)
				} else if (value['delete']) {
					const newState = ReDrillData.filter((obj) => obj.guid !== value['guid']);
					setReDrillData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'ReDrillData', value['guid'], 'redrill', submitReDrillData)
				} else {
					setReDrillData(prevState => [...prevState, value])
					saveToIDB(submitReDrillData, 'action', true)
				}

				break;

			case 'DriveRecords':
				const submitDriveRecordData = {
					"task_type": "action_drive_record",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value
					}
				}

				if (value['update']) {
					const newState = DriveRecordData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setDriveRecordData(newState)
					del(`drillit-actions/${value['guid']}-update`)
					updateIDB(state, `actions/${value['guid']}`, 'DriveRecordData', value['guid'], 'drive_record', submitDriveRecordData)
				} else if (value['delete']) {
					const newState = DriveRecordData.filter((obj) => obj.guid !== value['guid']);
					setDriveRecordData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'DriveRecordData', value['guid'], 'drive_record', submitDriveRecordData)
				} else {
					setDriveRecordData(prevState => [...prevState, value])
					saveToIDB(submitDriveRecordData, 'action', true)
				}

				break;
		}

		saveHoleData()

		// // // Set hole to active if not already
		// if (hole_status === '1') {
		// 	// activateHole(borehole_guid, project_guid)

		// 	updateHoleInformation(
		// 		borehole_guid,
		// 		boreholeName,
		// 		leadDriller,
		// 		leadDrillerID,
		// 		leadDrillerCSCS,
		// 		secondMan,
		// 		secondManCSCS,
		// 		plantGuid || '',
		// 		plantName,
		// 		sptGUID || '',
		// 		hole_barcode,
		// 		borehole_depthOverall,
		// 		activityTerminationReason,
		// 		project_guid,
		// 		'2' // hole_status
		// 	)
		// 	getHoleData()
		// }
	}

	//...............................................................................................................
	//.IIIII.DDDDDDDDD....BBBBBBBBBB........UUUU...UUUU..PPPPPPPPP...DDDDDDDDD.......AAAAA...AATTTTTTTTTTEEEEEEEEEE..
	//.IIIII.DDDDDDDDDD...BBBBBBBBBBB.......UUUU...UUUU..PPPPPPPPPP..DDDDDDDDDD......AAAAA...AATTTTTTTTTTEEEEEEEEEE..
	//.IIIII.DDDDDDDDDDD..BBBBBBBBBBB.......UUUU...UUUU..PPPPPPPPPPP.DDDDDDDDDDD....AAAAAA...AATTTTTTTTTTEEEEEEEEEE..
	//.IIIII.DDDD...DDDD..BBBB...BBBB.......UUUU...UUUU..PPPP...PPPP.DDDD...DDDD....AAAAAAA......TTTT...TEEE.........
	//.IIIII.DDDD....DDDD.BBBB...BBBB.......UUUU...UUUU..PPPP...PPPP.DDDD....DDDD..AAAAAAAA......TTTT...TEEE.........
	//.IIIII.DDDD....DDDD.BBBBBBBBBBB.......UUUU...UUUU..PPPPPPPPPPP.DDDD....DDDD..AAAAAAAA......TTTT...TEEEEEEEEEE..
	//.IIIII.DDDD....DDDD.BBBBBBBBBB........UUUU...UUUU..PPPPPPPPPP..DDDD....DDDD..AAAA.AAAA.....TTTT...TEEEEEEEEEE..
	//.IIIII.DDDD....DDDD.BBBBBBBBBBB.......UUUU...UUUU..PPPPPPPPP...DDDD....DDDD.AAAAAAAAAA.....TTTT...TEEEEEEEEEE..
	//.IIIII.DDDD....DDDD.BBBB....BBBB......UUUU...UUUU..PPPP........DDDD....DDDD.AAAAAAAAAAA....TTTT...TEEE.........
	//.IIIII.DDDD...DDDDD.BBBB....BBBB......UUUU...UUUU..PPPP........DDDD...DDDDD.AAAAAAAAAAA....TTTT...TEEE.........
	//.IIIII.DDDDDDDDDDD..BBBBBBBBBBBB......UUUUUUUUUUU..PPPP........DDDDDDDDDDD.DAAA....AAAA....TTTT...TEEEEEEEEEE..
	//.IIIII.DDDDDDDDDD...BBBBBBBBBBB........UUUUUUUUU...PPPP........DDDDDDDDDD..DAAA.....AAAA...TTTT...TEEEEEEEEEE..
	//.IIIII.DDDDDDDDD....BBBBBBBBBB..........UUUUUUU....PPPP........DDDDDDDDD..DDAAA.....AAAA...TTTT...TEEEEEEEEEE..
	//...............................................................................................................

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_sample = SamplesData;
				return val
			})
		}
	}, [SamplesData])

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_in_situ_spt = InSituTestsData;
				return val
			})
		}
	}, [InSituTestsData])

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_soil_description = SoilDescriptionData;
				return val
			})
		}
	}, [SoilDescriptionData])

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_groundwater = GroundWaterTestsData;
				return val
			})
		}
	}, [GroundWaterTestsData])

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_installation = InstallationData;
				return val
			})
		}
	}, [InstallationData])

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_backfills = BackfillData;
				return val
			})
		}
	}, [BackfillData])

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_comment = CommentsData;
				return val
			})
		}
	}, [CommentsData])

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_inspection_pit = InspectionPitData;
				return val
			})
		}
	}, [InspectionPitData])

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_casing = CasingData;
				return val
			})
		}
	}, [CasingData])

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_chiselling = ChisellingData;
				return val
			})
		}
	}, [ChisellingData])

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_water_added = WaterAddedData;
				return val
			})
		}
	}, [WaterAddedData])

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_daily_water_levels = DailyWaterLevelData;
				return val
			})
		}
	}, [DailyWaterLevelData])

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_redrill = ReDrillData;
				return val
			})
		}
	}, [ReDrillData])

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_drive_record = DriveRecordData;
				return val
			})
		}
	}, [DriveRecordData])
































	//...................................................................................................
	//.HHHH...HHHH....OOOOOOO.....LLLL.......EEEEEEEEEEE..... III.INNN...NNNN..NFFFFFFFFF...OOOOOOO.....
	//.HHHH...HHHH...OOOOOOOOOO...LLLL.......EEEEEEEEEEE..... III.INNNN..NNNN..NFFFFFFFFF..OOOOOOOOOO...
	//.HHHH...HHHH..OOOOOOOOOOOO..LLLL.......EEEEEEEEEEE..... III.INNNN..NNNN..NFFFFFFFFF.OOOOOOOOOOOO..
	//.HHHH...HHHH..OOOOO..OOOOO..LLLL.......EEEE............ III.INNNNN.NNNN..NFFF.......OOOOO..OOOOO..
	//.HHHH...HHHH.HOOOO....OOOOO.LLLL.......EEEE............ III.INNNNN.NNNN..NFFF......FOOOO....OOOO..
	//.HHHHHHHHHHH.HOOO......OOOO.LLLL.......EEEEEEEEEE...... III.INNNNNNNNNN..NFFFFFFFF.FOOO......OOO..
	//.HHHHHHHHHHH.HOOO......OOOO.LLLL.......EEEEEEEEEE...... III.INNNNNNNNNN..NFFFFFFFF.FOOO......OOO..
	//.HHHHHHHHHHH.HOOO......OOOO.LLLL.......EEEEEEEEEE...... III.INNNNNNNNNN..NFFFFFFFF.FOOO......OOO..
	//.HHHH...HHHH.HOOOO....OOOOO.LLLL.......EEEE............ III.INNNNNNNNNN..NFFF......FOOOO....OOOO..
	//.HHHH...HHHH..OOOOO..OOOOO..LLLL.......EEEE............ III.INNN.NNNNNN..NFFF.......OOOOO..OOOOO..
	//.HHHH...HHHH..OOOOOOOOOOOO..LLLLLLLLLL.EEEEEEEEEEE..... III.INNN..NNNNN..NFFF.......OOOOOOOOOOOO..
	//.HHHH...HHHH...OOOOOOOOOO...LLLLLLLLLL.EEEEEEEEEEE..... III.INNN..NNNNN..NFFF........OOOOOOOOOO...
	//.HHHH...HHHH.....OOOOOO.....LLLLLLLLLL.EEEEEEEEEEE..... III.INNN...NNNN..NFFF..........OOOOOO.....
	//...................................................................................................

	//number of elements
	const [numberOfSamplesD, setNumberOfSamplesD] = useState(0)
	const [numberOfSamplesB, setNumberOfSamplesB] = useState(0)
	const [numberOfSamplesES, setNumberOfSamplesES] = useState(0)
	const [numberOfSamplesWS, setNumberOfSamplesWS] = useState(0)
	const [numberOfSamples, setNumberOfSamples] = useState(numberOfSamplesD + numberOfSamplesB + numberOfSamplesES + numberOfSamplesWS)

	const [numberOfSamplesD_TOTAL, setNumberOfSamplesD_TOTAL] = useState(0)
	const [numberOfSamplesB_TOTAL, setNumberOfSamplesB_TOTAL] = useState(0)
	const [numberOfSamplesES_TOTAL, setNumberOfSamplesES_TOTAL] = useState(0)
	const [numberOfSamplesWS_TOTAL, setNumberOfSamplesWS_TOTAL] = useState(0)
	const [numberOfSamples_TOTAL, setNumberOfSamples_TOTAL] = useState(numberOfSamplesD_TOTAL + numberOfSamplesB_TOTAL + numberOfSamplesES_TOTAL + numberOfSamplesWS_TOTAL)
	console.log('numberOfSamples_TOTAL:', numberOfSamples_TOTAL)

	const [numberOfSPTs, setNumberOfSPTs] = useState(0)
	const [numberOfCPTs, setNumberOfCPTs] = useState(0)
	const [numberOfSPTsCPTs, setNumberOfSPTsCPTs] = useState(numberOfSPTs + numberOfCPTs)
	const [numberOfUSamples, setNumberOfUSamples] = useState(0)
	const [numberOfPSSamples, setNumberOfPSSamples] = useState(0)
	const [numberOfShearVane, setNumberOfShearVane] = useState(0)
	const [numberOfInsituTests, setNumberOfInsituTests] = useState(numberOfSPTsCPTs + numberOfUSamples + numberOfPSSamples + numberOfShearVane)

	const [numberOfSPTs_TOTAL, setNumberOfSPTs_TOTAL] = useState(0)
	const [numberOfCPTs_TOTAL, setNumberOfCPTs_TOTAL] = useState(0)
	const [numberOfSPTsCPTs_TOTAL, setNumberOfSPTsCPTs_TOTAL] = useState(numberOfSPTs_TOTAL + numberOfCPTs_TOTAL)
	const [numberOfUSamples_TOTAL, setNumberOfUSamples_TOTAL] = useState(0)
	const [numberOfPSSamples_TOTAL, setNumberOfPSSamples_TOTAL] = useState(0)
	const [numberOfShearVane_TOTAL, setNumberOfShearVane_TOTAL] = useState(0)

	const [numberOfSoilDescriptions, setNumberOfSoilDescriptions] = useState(0)
	const [numberOfComments, setNumberOfComments] = useState(0)
	const [numberOfGroundWaters, setNumberOfGroundWaters] = useState(0)
	const [numberOfBackfills, setNumberOfBackfills] = useState(0)
	const [numberOfInstalls, setNumberOfInstalls] = useState(0)
	const [numberOfInspectionPits, setNumberOfInspectionPits] = useState(0)
	const [numberOfWaterAdded, setNumberOfWaterAdded] = useState(0)
	const [numberOfReDrills, setNumberOfReDrills] = useState(0)
	const [numberOfChiselling, setNumberOfChiselling] = useState(0)
	const [numberOfCasingRecords, setNumberOfCasingRecords] = useState(0)
	const [numberOfDailyWaterLevels, setNumberOfDailyWaterLevels] = useState(0)
	const [numberOfDriveRecords, setNumberOfDriveRecords] = useState(0)

	//depths of elements
	const [depthSamples, setDepthSamples] = useState(0)
	const [depthOverallSamples, setDepthOverallSamples] = useState(0)
	const [depthInsituTests, setDepthInsituTests] = useState(0)
	// console.log('depthInsituTests:', depthInsituTests)
	const [depthOverallInsituTests, setDepthOverallInsituTests] = useState(0)
	const [depthSoilDescriptions, setDepthSoilDescriptions] = useState(0)
	const [depthOverallSoilDescriptions, setDepthOverallSoilDescriptions] = useState(0)
	const [depthInspectionPit, setDepthInspectionPit] = useState(0)
	const [depthOverallInspectionPit, setDepthOverallInspectionPit] = useState(0)
	const [depthCasing, setDepthCasing] = useState(0)
	const [depthOverallCasing, setDepthOverallCasing] = useState(0)
	const [depthGroundwater, setDepthGroundwater] = useState(0)
	const [depthOverallGroundwater, setDepthOverallGroundwater] = useState(0)
	const [depthChiselling, setDepthChiselling] = useState(0)
	const [depthWaterAdded, setDepthWaterAdded] = useState(0)
	const [depthRedrill, setDepthRedrill] = useState(0)
	const [depthOverallRedrill, setDepthOverallRedrill] = useState(0)
	const [depthDriveRecord, setDepthDriveRecord] = useState(0)
	const [depthOverallDriveRecord, setDepthOverallDriveRecord] = useState(0)
	const [allDepths, setAllDepths] = useState([depthSamples, depthInsituTests, depthSoilDescriptions, depthInspectionPit, depthCasing, depthGroundwater, depthDriveRecord])
	const [allDepthsOverall, setAllDepthsOverall] = useState([depthOverallSamples, depthOverallInsituTests, depthOverallSoilDescriptions, depthOverallInspectionPit, depthOverallCasing, depthOverallGroundwater, depthDriveRecord, depthOverallDriveRecord])
	const [borehole_depth, setBorehole_depth] = useState('' + (Math.max(...allDepths)).toFixed(2))
	const [borehole_depthOverall, setBorehole_depthOverall] = useState('' + (Math.max(...allDepthsOverall)).toFixed(2))
	
	const [current_casing_depth, setCurrent_casing_depth] = useState(depthCasing)
	const [last_soil_description_depth, setLast_soil_description_depth] = useState(depthSoilDescriptions)

	const [last_soil_description_depth_from, setLast_soil_description_depth_from] = useState(0)
	const [last_soil_description_depth_to, setLast_soil_description_depth_to] = useState(0)
	const [last_soil_description_depth_text, setLast_soil_description_depth_text] = useState('text')

	// console.log('depthDriveRecord:', depthDriveRecord)
	// console.log('depthOverallDriveRecord:', depthOverallDriveRecord)
	// console.log('borehole_depthOverall:', borehole_depthOverall)
	//..............................................................................
	//....CCCCCCC......OOOOOOO.....UUUU...UUUU..NNNN...NNNN..TTTTTTTTTTS.SSSSSSS....
	//...CCCCCCCCC....OOOOOOOOOO...UUUU...UUUU..NNNNN..NNNN..TTTTTTTTTTSSSSSSSSSS...
	//..CCCCCCCCCCC..OOOOOOOOOOOO..UUUU...UUUU..NNNNN..NNNN..TTTTTTTTTTSSSSSSSSSSS..
	//..CCCC...CCCCC.OOOOO..OOOOO..UUUU...UUUU..NNNNNN.NNNN.....TTTT...SSSSS..SSSS..
	//.CCCC.....CCC.OOOOO....OOOOO.UUUU...UUUU..NNNNNN.NNNN.....TTTT...SSSSS........
	//.CCCC.........OOOO......OOOO.UUUU...UUUU..NNNNNNNNNNN.....TTTT....SSSSSSS.....
	//.CCCC.........OOOO......OOOO.UUUU...UUUU..NNNNNNNNNNN.....TTTT.....SSSSSSSSS..
	//.CCCC.........OOOO......OOOO.UUUU...UUUU..NNNNNNNNNNN.....TTTT.......SSSSSSS..
	//.CCCC.....CCC.OOOOO....OOOOO.UUUU...UUUU..NNNNNNNNNNN.....TTTT..........SSSS..
	//..CCCC...CCCCC.OOOOO..OOOOO..UUUU...UUUU..NNNN.NNNNNN.....TTTT...SSSS....SSS..
	//..CCCCCCCCCCC..OOOOOOOOOOOO..UUUUUUUUUUU..NNNN..NNNNN.....TTTT...SSSSSSSSSSS..
	//...CCCCCCCCCC...OOOOOOOOOO....UUUUUUUUU...NNNN..NNNNN.....TTTT....SSSSSSSSSS..
	//....CCCCCCC.......OOOOOO.......UUUUUUU....NNNN...NNNN.....TTTT.....SSSSSSSS...
	//..............................................................................


	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the type counts for the filtered data - Samples
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateTypeCounts_Samples = (filteredData: any[]) => {

		let totalD = 0; let totalB = 0; let totalES = 0; let totalWS = 0

		filteredDataSamples.forEach((valueSamples: any) => {
			const type = valueSamples.type
			if (type) {
				if (type.includes('D')) { totalD++ }
				if (type.includes('B')) { totalB++ }
				if (type.includes('ES')) { totalES++ }
				if (type.includes('WS')) { totalWS++ }
			}
		});

		return { totalD, totalB, totalES, totalWS }
	}

	// Filter the data
	const filteredDataSamples = SamplesData?.filter((action: any) => {
		return action !== null &&  datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	// Calculate type counts for the filtered data
	const typeCountsSamples = calculateTypeCounts_Samples(filteredDataSamples);
	const { totalD, totalB, totalES, totalWS } = typeCountsSamples;

	if (totalD !== numberOfSamplesD) { setNumberOfSamplesD(totalD) }
	if (totalB !== numberOfSamplesB) { setNumberOfSamplesB(totalB) }
	if (totalWS !== numberOfSamplesWS) { setNumberOfSamplesWS(totalWS) }
	if (totalES !== numberOfSamplesES) { setNumberOfSamplesES(totalES) }

	let totalSamplesCount = numberOfSamplesD + numberOfSamplesB + numberOfSamplesES + numberOfSamplesWS
	if (totalSamplesCount !== numberOfSamples) { setNumberOfSamples(totalSamplesCount) }

	// console.log('numberOfSamplesD:', numberOfSamplesD)
	// console.log('numberOfSamplesB:', numberOfSamplesB)

	const calculateTypeCounts_Samples_TOTAL = (filteredData: any[]) => {

		let totalD_TOTAL = 0; let totalB_TOTAL = 0; let totalES_TOTAL = 0; let totalWS_TOTAL = 0

		filteredDataSamples_TOTAL
		// Filter out null values from filteredDataSamples_TOTAL
		.filter((valueSamples: any) => valueSamples !== null)
		.forEach((valueSamples: any) => {
			const type = valueSamples.type;
			if (type) {
				if (type.includes('D')) { totalD_TOTAL++ }
				if (type.includes('B')) { totalB_TOTAL++ }
				if (type.includes('ES')) { totalES_TOTAL++ }
				if (type.includes('WS')) { totalWS_TOTAL++ }
			}
		});

		return { totalD_TOTAL, totalB_TOTAL, totalES_TOTAL, totalWS_TOTAL }
	}

	// TOTAL SAMPLES ////////////////////////////////////////////////////////////////////////////////

	// Filter the data
	const filteredDataSamples_TOTAL = SamplesData || []

	// Calculate type counts for the filtered data
	const typeCountsSamples_TOTAL = calculateTypeCounts_Samples_TOTAL(filteredDataSamples_TOTAL);
	const { totalD_TOTAL, totalB_TOTAL, totalES_TOTAL, totalWS_TOTAL } = typeCountsSamples_TOTAL;

	if (totalD_TOTAL !== numberOfSamplesD_TOTAL) { setNumberOfSamplesD_TOTAL(totalD_TOTAL) }
	if (totalB_TOTAL !== numberOfSamplesB_TOTAL) { setNumberOfSamplesB_TOTAL(totalB_TOTAL) }
	if (totalWS_TOTAL !== numberOfSamplesWS_TOTAL) { setNumberOfSamplesWS_TOTAL(totalWS_TOTAL) }
	if (totalES_TOTAL !== numberOfSamplesES_TOTAL) { setNumberOfSamplesES_TOTAL(totalES_TOTAL) }
	let totalSamplesALL = totalD_TOTAL + totalB_TOTAL + totalWS_TOTAL + totalES_TOTAL
	if (totalSamplesALL !== numberOfSamples_TOTAL) { setNumberOfSamples_TOTAL(totalD_TOTAL + totalB_TOTAL + totalWS_TOTAL + totalES_TOTAL) }

	// console.log('totalWS_TOTAL:', totalWS_TOTAL)

	const [showPrintAllSampleLabelsButton, setShowPrintAllSampleLabelsButton] = useState(true)

	useEffect(() => {
		if (numberOfSamples > 1) {
			setShowPrintAllSampleLabelsButton(true)
		} else {
			setShowPrintAllSampleLabelsButton(false)
		}

	}, [numberOfSamples]
	)

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the type counts for the filtered data - InsituTests
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateTypeCounts_InsituTests = (filteredData: any[]) => {

		let totalSPT = 0; let totalCPT = 0; let totalU = 0; let totalPS = 0; let totalSV = 0

		filteredDataInsituTests.forEach((valueInsituTests: any) => {
			const type = valueInsituTests.type
			if (type) {
				if (type.includes('SPT')) { totalSPT++ }
				if (type.includes('CPT')) { totalCPT++ }
				if (type.includes('U')) { totalU++ }
				if (type.includes('PS')) { totalPS++ }
				if (type.includes('SV')) { totalSV++ }
			}
		});

		return { totalSPT, totalCPT, totalU, totalPS, totalSV }
	}

	// Filter the data
	const filteredDataInsituTests = InSituTestsData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	// Calculate type counts for the filtered data
	const typeCountsInsituTests = calculateTypeCounts_InsituTests(filteredDataInsituTests);
	const { totalSPT, totalCPT, totalU, totalPS, totalSV } = typeCountsInsituTests;

	if (totalSPT !== numberOfSPTs) { setNumberOfSPTs(totalSPT) }
	if (totalCPT !== numberOfCPTs) { setNumberOfCPTs(totalCPT) }
	if (totalU !== numberOfUSamples) { setNumberOfUSamples(totalU) }
	if (totalPS !== numberOfPSSamples) { setNumberOfPSSamples(totalPS) }
	if (totalSV !== numberOfShearVane) { setNumberOfShearVane(totalSV) }

	let TotalIntitusTestsCount = numberOfSPTs + numberOfCPTs + numberOfUSamples + numberOfPSSamples + numberOfShearVane
	if (TotalIntitusTestsCount !== numberOfInsituTests) { setNumberOfInsituTests(TotalIntitusTestsCount) }

	// console.log('numberOfSPTs:', numberOfSPTs)
	// console.log('numberOfCPTs:', numberOfCPTs)
	// console.log('numberOfUSamples:', numberOfUSamples)
	// console.log('numberOfPSSamples:', numberOfPSSamples)
	// console.log('numberOfShearVane:', numberOfShearVane)

	// TOTAL INSITU TESTS ////////////////////////////////////////////////////////////////////////////////

	const calculateTypeCounts_InsituTests_TOTAL = (filteredData: any[]) => {

		let totalSPT_TOTAL = 0; let totalCPT_TOTAL = 0; let totalU_TOTAL = 0; let totalPS_TOTAL = 0; let totalSV_TOTAL = 0

		filteredDataInsituTests_TOTAL.forEach((valueInsituTests: any) => {
			const type = valueInsituTests.type
			if (type) {
				if (type.includes('SPT')) { totalSPT_TOTAL++ }
				if (type.includes('CPT')) { totalCPT_TOTAL++ }
				if (type.includes('U')) { totalU_TOTAL++ }
				if (type.includes('PS')) { totalPS_TOTAL++ }
				if (type.includes('SV')) { totalSV_TOTAL++ }
			}
		});

		return { totalSPT_TOTAL, totalCPT_TOTAL, totalU_TOTAL, totalPS_TOTAL, totalSV_TOTAL }
	}

	// Filter the data
	const filteredDataInsituTests_TOTAL = InSituTestsData || []

	// Calculate type counts for the filtered data
	const typeCountsInsituTests_TOTAL = calculateTypeCounts_InsituTests_TOTAL(filteredDataInsituTests_TOTAL);
	const { totalSPT_TOTAL, totalCPT_TOTAL, totalU_TOTAL, totalPS_TOTAL, totalSV_TOTAL } = typeCountsInsituTests_TOTAL;

	if (totalSPT_TOTAL !== numberOfSPTs_TOTAL) { setNumberOfSPTs_TOTAL(totalSPT_TOTAL) }
	if (totalCPT_TOTAL !== numberOfCPTs_TOTAL) { setNumberOfCPTs_TOTAL(totalCPT_TOTAL) }
	if (totalU_TOTAL !== numberOfUSamples_TOTAL) { setNumberOfUSamples_TOTAL(totalU_TOTAL) }
	if (totalPS_TOTAL !== numberOfPSSamples_TOTAL) { setNumberOfPSSamples_TOTAL(totalPS_TOTAL) }
	if (totalSV_TOTAL !== numberOfShearVane_TOTAL) { setNumberOfShearVane_TOTAL(totalSV_TOTAL) }

	let totalSPTsCPTsALL = totalSPT_TOTAL + totalCPT_TOTAL
	if (totalSPTsCPTsALL !== numberOfSPTsCPTs_TOTAL) { setNumberOfSPTsCPTs_TOTAL(totalSPTsCPTsALL) }

	let TotalIntitusTestsCountSPT_CPT = numberOfSPTs + numberOfCPTs
	if (TotalIntitusTestsCountSPT_CPT !== numberOfSPTsCPTs) { setNumberOfSPTsCPTs(TotalIntitusTestsCountSPT_CPT) }

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - InsituTests
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	// const calculateCounts_InsituTests = (filteredData: any[]) => {

	// 	let totalInsituTests = 0

	// 	filteredDataInsituTests.forEach((valueInsituTests: any) => {
	// 		const guid = valueInsituTests.guid
	// 		if (guid) { totalInsituTests++ }
	// 	});

	// 	return { totalInsituTests }
	// }

	// // Filter the data
	// const filteredDataInsituTests = InSituTestsData?.filter((action: any) => {
	// 	return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	// }) || []

	// // Calculate type counts for the filtered data
	// const countInsituTests = calculateCounts_InsituTests(filteredDataInsituTests);
	// const { totalInsituTests } = countInsituTests

	// if (totalInsituTests !== numberOfInsituTests) { setNumberOfInsituTests(totalInsituTests) }


	const [showPrintAllInsituTestLabelsButton, setShowPrintAllInsituTestsLabelButton] = useState(false)



	useEffect(() => {
		if (numberOfInsituTests > 1) {
			setShowPrintAllInsituTestsLabelButton(true)
		} else {
			setShowPrintAllInsituTestsLabelButton(false)
		}

	}, [numberOfInsituTests]
	)


	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - Soil Descriptions
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateCounts_SoilDescriptions = (filteredData: any[]) => {

		let totalSoilDescriptions = 0

		filteredDataSoilDescription.forEach((valueSoilDescriptions: any) => {
			const stratum_description = valueSoilDescriptions.stratum_description
			if (stratum_description) { totalSoilDescriptions++ }
		});

		return { totalSoilDescriptions }
	}

	// Filter the data
	const filteredDataSoilDescription = SoilDescriptionData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	// Calculate type counts for the filtered data
	const countSoilDescriptions = calculateCounts_SoilDescriptions(filteredDataSoilDescription);
	const { totalSoilDescriptions } = countSoilDescriptions

	if (totalSoilDescriptions !== numberOfSoilDescriptions) { setNumberOfSoilDescriptions(totalSoilDescriptions) }


	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - Groundwater
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateCounts_Groundwater = (filteredData: any[]) => {

		let totalGroundwater = 0

		filteredDataGroundwater.forEach((valueGroundwater: any) => {
			const guid = valueGroundwater.guid
			if (guid) { totalGroundwater++ }
		});

		return { totalGroundwater }
	}

	// Filter the data
	const filteredDataGroundwater = GroundWaterTestsData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	// Calculate type counts for the filtered data
	const countGroundwater = calculateCounts_Groundwater(filteredDataGroundwater);
	const { totalGroundwater } = countGroundwater

	if (totalGroundwater !== numberOfGroundWaters) { setNumberOfGroundWaters(totalGroundwater) }

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - Backfills
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateCounts_Backfill = (filteredData: any[]) => {

		let totalBackfill = 0

		filteredDataBackfill.forEach((valueBackfill: any) => {
			const guid = valueBackfill.guid
			if (guid) { totalBackfill++ }
		});

		return { totalBackfill }
	}

	// Filter the data
	const filteredDataBackfill = BackfillData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	// Calculate type counts for the filtered data
	const countBackfill = calculateCounts_Backfill(filteredDataBackfill);
	const { totalBackfill } = countBackfill

	if (totalBackfill !== numberOfBackfills) { setNumberOfBackfills(totalBackfill) }

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - Installs
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateCounts_Install = (filteredData: any[]) => {

		let totalInstall = 0

		filteredDataInstall.forEach((valueInstall: any) => {
			const guid = valueInstall.guid
			if (guid) { totalInstall++ }
		});

		return { totalInstall }
	}

	// Filter the data
	const filteredDataInstall = InstallationData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	// Calculate type counts for the filtered data
	const countInstall = calculateCounts_Install(filteredDataInstall);
	const { totalInstall } = countInstall

	if (totalInstall !== numberOfInstalls) { setNumberOfInstalls(totalInstall) }

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - Comments
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateCounts_Comments = (filteredData: any[]) => {

		let totalComments = 0

		filteredDataComments.forEach((valueComments: any) => {
			const guid = valueComments.guid
			if (guid) { totalComments++ }
		});

		return { totalComments }
	}

	// Filter the data
	const filteredDataComments = CommentsData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	// Calculate type counts for the filtered data
	const countComments = calculateCounts_Comments(filteredDataComments);
	const { totalComments } = countComments

	if (totalComments !== numberOfComments) { setNumberOfComments(totalComments) }

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - InspectionPit
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateCounts_InspectionPit = (filteredData: any[]) => {

		let totalInspectionPit = 0

		filteredDataInspectionPit.forEach((valueInspectionPit: any) => {
			const guid = valueInspectionPit.guid
			if (guid) { totalInspectionPit++ }
		});

		return { totalInspectionPit }
	}

	// Filter the data
	const filteredDataInspectionPit = InspectionPitData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	// Calculate type counts for the filtered data
	const countInspectionPit = calculateCounts_InspectionPit(filteredDataInspectionPit);
	const { totalInspectionPit } = countInspectionPit

	if (totalInspectionPit !== numberOfInspectionPits) { setNumberOfInspectionPits(totalInspectionPit) }

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - CasingRecord
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateCounts_CasingRecord = (filteredData: any[]) => {

		let totalCasingRecord = 0

		filteredDataCasingRecord.forEach((valueCasingRecord: any) => {
			const guid = valueCasingRecord.guid
			if (guid) { totalCasingRecord++ }
		});

		return { totalCasingRecord }
	}

	// Filter the data
	const filteredDataCasingRecord = CasingData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []
	// const filteredDataCasingRecord = CasingData // All casing records

	// Calculate type counts for the filtered data
	const countCasingRecord = calculateCounts_CasingRecord(filteredDataCasingRecord);
	const { totalCasingRecord } = countCasingRecord

	if (totalCasingRecord !== numberOfCasingRecords) { setNumberOfCasingRecords(totalCasingRecord) }

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - Chiselling
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateCounts_Chiselling = (filteredData: any[]) => {

		let totalChiselling = 0

		filteredDataChiselling.forEach((valueChiselling: any) => {
			const guid = valueChiselling.guid
			if (guid) { totalChiselling++ }
		});

		return { totalChiselling }
	}

	// Filter the data
	const filteredDataChiselling = ChisellingData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	// Calculate type counts for the filtered data
	const countChiselling = calculateCounts_Chiselling(filteredDataChiselling);
	const { totalChiselling } = countChiselling

	if (totalChiselling !== numberOfChiselling) { setNumberOfChiselling(totalChiselling) }

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - WaterAdded
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateCounts_WaterAdded = (filteredData: any[]) => {

		let totalWaterAdded = 0

		filteredDataWaterAdded.forEach((valueWaterAdded: any) => {
			const guid = valueWaterAdded.guid
			if (guid) { totalWaterAdded++ }
		});

		return { totalWaterAdded }
	}

	// Filter the data
	const filteredDataWaterAdded = WaterAddedData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	// Calculate type counts for the filtered data
	const countWaterAdded = calculateCounts_WaterAdded(filteredDataWaterAdded);
	const { totalWaterAdded } = countWaterAdded

	if (totalWaterAdded !== numberOfWaterAdded) { setNumberOfWaterAdded(totalWaterAdded) }

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - ReDrill
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateCounts_ReDrill = (filteredData: any[]) => {

		let totalReDrill = 0

		filteredDataReDrill.forEach((valueReDrill: any) => {
			const guid = valueReDrill.guid
			if (guid) { totalReDrill++ }
		});

		return { totalReDrill }
	}

	// Filter the data
	const filteredDataReDrill = ReDrillData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	// Calculate type counts for the filtered data
	const countReDrill = calculateCounts_ReDrill(filteredDataReDrill);
	const { totalReDrill } = countReDrill

	if (totalReDrill !== numberOfReDrills) { setNumberOfReDrills(totalReDrill) }

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - WaterLevels
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateCounts_DailyWaterLevel = (filteredData: any[]) => {

		let totalDailyWaterLevel = 0

		filteredDataDailyWaterLevel.forEach((valueDailyWaterLevel: any) => {
			const guid = valueDailyWaterLevel.guid
			if (guid) { totalDailyWaterLevel++ }
		});

		return { totalDailyWaterLevel }
	}

	// Filter the data
	const filteredDataDailyWaterLevel = DailyWaterLevelData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	// Calculate type counts for the filtered data
	const countDailyWaterLevels = calculateCounts_DailyWaterLevel(filteredDataDailyWaterLevel);
	const { totalDailyWaterLevel } = countDailyWaterLevels

	if (totalDailyWaterLevel !== numberOfDailyWaterLevels) { setNumberOfDailyWaterLevels(totalDailyWaterLevel) }

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - DriveRecord
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateCounts_DriveRecord = (filteredData: any[]) => {

		let totalDriveRecord = 0

		filteredDataDriveRecord.forEach((valueDriveRecord: any) => {
			const guid = valueDriveRecord.guid
			if (guid) { totalDriveRecord++ }
		});

		return { totalDriveRecord }
	}

	// Filter the data
	const filteredDataDriveRecord = DriveRecordData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	// Calculate type counts for the filtered data
	const countDriveRecord = calculateCounts_DriveRecord(filteredDataDriveRecord);
	const { totalDriveRecord } = countDriveRecord

	if (totalDriveRecord !== numberOfDriveRecords) { setNumberOfDriveRecords(totalDriveRecord) }


	const [showPrintAllLinerLabelsButton, setShowPrintAllLinerLabelsButton] = useState(false)

	useEffect(() => {
		if (numberOfDriveRecords > 1) {
			setShowPrintAllLinerLabelsButton(true)
		} else {
			setShowPrintAllLinerLabelsButton(false)
		}

	}, [numberOfSamples]
	)










	//...........................................................................
	//.DDDDDDDDD....EEEEEEEEEEE.EPPPPPPPP...PTTTTTTTTTTTHHH...HHHH...SSSSSSS.....
	//.DDDDDDDDDD...EEEEEEEEEEE.EPPPPPPPPP..PTTTTTTTTTTTHHH...HHHH..HSSSSSSSS....
	//.DDDDDDDDDDD..EEEEEEEEEEE.EPPPPPPPPPP.PTTTTTTTTTTTHHH...HHHH..HSSSSSSSSS...
	//.DDDD...DDDD..EEEE........EPPP...PPPP....TTTT....THHH...HHHH.HHSSS..SSSS...
	//.DDDD....DDDD.EEEE........EPPP...PPPP....TTTT....THHH...HHHH.HHSSS.........
	//.DDDD....DDDD.EEEEEEEEEE..EPPPPPPPPPP....TTTT....THHHHHHHHHH..HSSSSSS......
	//.DDDD....DDDD.EEEEEEEEEE..EPPPPPPPPP.....TTTT....THHHHHHHHHH...SSSSSSSSS...
	//.DDDD....DDDD.EEEEEEEEEE..EPPPPPPPP......TTTT....THHHHHHHHHH.....SSSSSSS...
	//.DDDD....DDDD.EEEE........EPPP...........TTTT....THHH...HHHH........SSSSS..
	//.DDDD...DDDDD.EEEE........EPPP...........TTTT....THHH...HHHH.HHSS....SSSS..
	//.DDDDDDDDDDD..EEEEEEEEEEE.EPPP...........TTTT....THHH...HHHH.HHSSSSSSSSSS..
	//.DDDDDDDDDD...EEEEEEEEEEE.EPPP...........TTTT....THHH...HHHH..HSSSSSSSSS...
	//.DDDDDDDDD....EEEEEEEEEEE.EPPP...........TTTT....THHH...HHHH...SSSSSSSS....
	//...........................................................................



	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// CALCULATE MAX DEPTHS
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	/////////////////////////////////////////////////////////
	// SamplesData
	// const filteredSamplesData = SamplesData?.filter((action: any) => {
	// 	const actionDate = new Date(action.time_of_action);
	// 	const endOfDaySelectedDate = new Date(selectedDate);
	// 	endOfDaySelectedDate.setHours(23, 59, 59, 999); // Set to end of the day
	// 	return actionDate <= endOfDaySelectedDate;
	// }) || []
	const filteredSamplesData = SamplesData?.filter((action: any) => {
		return action && action.time_of_action; // Filter out null and undefined entries
	}).filter((action: any) => {
		const actionDate = new Date(action.time_of_action);
		const endOfDaySelectedDate = new Date(selectedDate);
		endOfDaySelectedDate.setHours(23, 59, 59, 999); // Set to end of the day
		return actionDate <= endOfDaySelectedDate;
	}) || [];

	let maxSamplesDepth
	if (filteredSamplesData.length > 0) {

		const maxDepthBase = filteredSamplesData.reduce((maxDepth, action) => {
			return Math.max(maxDepth, action.depth_to_base)
		}, -Infinity);

		const maxDepthTop = filteredSamplesData.reduce((maxDepth, action) => {
			return Math.max(maxDepth, action.depth_to_top)
		}, -Infinity)

		maxSamplesDepth = Math.max(maxDepthBase, maxDepthTop)

	} else {
		maxSamplesDepth = 0 // Set to 0 when there's no data
	}

	if (maxSamplesDepth !== depthSamples) {
		setDepthSamples(maxSamplesDepth)
	}

	/////////////////////////////////////////////////////////
	// MAX OVERALL SAMPLE DEPTH
	let OverallSamplesDepth
	if (SamplesData.length > 0) {
		const maxDepthBase = SamplesData.reduce((maxDepth, action) => {
			return Math.max(maxDepth, action.depth_to_base)
		}, -Infinity);
		const maxDepthTop = SamplesData.reduce((maxDepth, action) => {
			return Math.max(maxDepth, action.depth_to_top)
		}, -Infinity)
		OverallSamplesDepth = Math.max(maxDepthBase, maxDepthTop)
	} else {
		OverallSamplesDepth = 0 // Set to 0 when there's no data
	}
	if (OverallSamplesDepth !== depthOverallSamples) {
		setDepthOverallSamples(OverallSamplesDepth)
	}

	/////////////////////////////////////////////////////////
	// InSituTestsData
	const filteredInSituTestsData = InSituTestsData?.filter((action: any) => {
	const actionDate = new Date(action.time_of_action);
	const endOfDaySelectedDate = new Date(selectedDate);
	endOfDaySelectedDate.setHours(23, 59, 59, 999); // Set to end of the day
	return actionDate <= endOfDaySelectedDate;
	}) || []

	let maxInSituTestsDepth
	if (filteredInSituTestsData.length > 0) {
		const maxInSituTestsDepthFiltered = filteredInSituTestsData.reduce((maxDepth, action) => {
			return Math.max(maxDepth, action.depth_to_base)
		}, -Infinity)
		maxInSituTestsDepth = maxInSituTestsDepthFiltered
	} else {
		maxInSituTestsDepth = 0 // Set to 0 when there's no data
	}

	// console.log('Max InSituTests Depth:', maxInSituTestsDepth)
	if (maxInSituTestsDepth !== depthInsituTests) { 
		setDepthInsituTests(maxInSituTestsDepth)
	}

	/////////////////////////////////////////////////////////
	// MAX OVERALL INSITU DEPTH
	// const filteredInSituTestsDataOverall = InSituTestsData || [];
	let OverallInSituDepth
	if (InSituTestsData.length > 0) {
		const maxDepthBase = InSituTestsData.reduce((maxDepth, action) => {
			return Math.max(maxDepth, action.depth_to_base)
		}, -Infinity)
		OverallInSituDepth = maxDepthBase
	} else {
		OverallInSituDepth = 0 // Set to 0 when there's no data
	}
	// console.log('Max InSituTests Depth:', maxInSituTestsDepth)
	if (OverallInSituDepth !== depthOverallInsituTests) { 
		setDepthOverallInsituTests(OverallInSituDepth)
	}

	/////////////////////////////////////////////////////////
	// SoilDescriptionData
	const filteredSoilDescriptionData = SoilDescriptionData?.filter((action: any) => {
		const actionDate = new Date(action.time_of_action);
		const endOfDaySelectedDate = new Date(selectedDate);
		endOfDaySelectedDate.setHours(23, 59, 59, 999); // Set to end of the day
		return actionDate <= endOfDaySelectedDate;
	}) || []

	let maxSoilDescriptionDepth
	if (filteredSoilDescriptionData.length > 0) {
		const maxSoilDescriptionDepthFiltered = filteredSoilDescriptionData.reduce((maxDepth, action) => {
			return Math.max(maxDepth, action.depth_to_base)
		}, -Infinity)
		maxSoilDescriptionDepth = maxSoilDescriptionDepthFiltered
	} else {
		maxSoilDescriptionDepth = 0 // Set to 0 when there's no data
	}

	if (maxSoilDescriptionDepth !== depthSoilDescriptions) {
		setDepthSoilDescriptions(maxSoilDescriptionDepth)
	}
	//// SET LAST SOIL DESCRIPTION DEPTH
	if (maxSoilDescriptionDepth !== last_soil_description_depth) {
		setLast_soil_description_depth(maxSoilDescriptionDepth)
	}

	/////////////////////////////////////////////////////////
	// MAX OVERALL SOIL DESCRIPTION DEPTH
	// const filteredSoilDescriptionDataOverall = SoilDescriptionData || [];
	let OverallSoilDescriptionDepth
	if (SoilDescriptionData.length > 0) {
		const maxSoilDescriptionDepthFiltered = SoilDescriptionData.reduce((maxDepth, action) => {
			return Math.max(maxDepth, action.depth_to_base)
		}, -Infinity)
		OverallSoilDescriptionDepth = maxSoilDescriptionDepthFiltered
	} else {
		OverallSoilDescriptionDepth = 0 // Set to 0 when there's no data
	}

	if (OverallSoilDescriptionDepth !== depthOverallSoilDescriptions) {
		setDepthOverallSoilDescriptions(OverallSoilDescriptionDepth)
	}

	/////////////////////////////////////////////////////////
	// CasingData
	const filteredCasingData = CasingData?.filter((action: any) => {
		const actionDate = new Date(action.time_of_action);
		const endOfDaySelectedDate = new Date(selectedDate);
		endOfDaySelectedDate.setHours(23, 59, 59, 999); // Set to end of the day
		return actionDate <= endOfDaySelectedDate;
	}) || []

	let maxCasingDepth
	if (filteredCasingData.length > 0) {
		const maxCasingDepthFiltered = filteredCasingData.reduce((maxDepth, action) => {
			return Math.max(maxDepth, action.depth_to_base)
		}, -Infinity)
		maxCasingDepth = maxCasingDepthFiltered
	} else {
		maxCasingDepth = 0 // Set to 0 when there's no data
	}

	if (maxCasingDepth !== depthCasing) {
		setDepthCasing(maxCasingDepth)
	}
	if (maxCasingDepth !== current_casing_depth) { /// SET CURRENT CASING DEPTH FOR PANELS
		setCurrent_casing_depth(maxCasingDepth)
	}


	/////////////////////////////////////////////////////////
	// MAX OVERALL CASING DEPTH
	// const filteredCasingDataOverall = CasingData || [];
	let OverallCasingDepth
	if (CasingData.length > 0) {
		const maxCasingDepthFiltered = CasingData.reduce((maxDepth, action) => {
			return Math.max(maxDepth, action.depth_to_base)
		}, -Infinity)
		OverallCasingDepth = maxCasingDepthFiltered
	} else {
		OverallCasingDepth = 0 // Set to 0 when there's no data
	}
	if (OverallCasingDepth !== depthOverallCasing) {
		setDepthOverallCasing(OverallCasingDepth)
	}

	/////////////////////////////////////////////////////////
	// InspectionPitData
	const filteredInspectionPitData = InspectionPitData?.filter((action: any) => {
		const actionDate = new Date(action.time_of_action);
		const endOfDaySelectedDate = new Date(selectedDate);
		endOfDaySelectedDate.setHours(23, 59, 59, 999); // Set to end of the day
		return actionDate <= endOfDaySelectedDate;
	}) || []

	let maxInspectionPitDepth
	if (filteredInspectionPitData.length > 0) {
		const maxInspectionPitDepthFiltered = filteredInspectionPitData.reduce((maxDepth, action) => {
			return Math.max(maxDepth, action.depth_to_base)
		}, -Infinity)
		maxInspectionPitDepth = maxInspectionPitDepthFiltered
	} else {
		maxInspectionPitDepth = 0 // Set to 0 when there's no data
	}

	if (maxInspectionPitDepth !== depthInspectionPit) {
		setDepthInspectionPit(maxInspectionPitDepth)
	}


	/////////////////////////////////////////////////////////
	// MAX OVERALL INSPECTION PIT DEPTH
	let OverallInspectionPitDepth
	if (InspectionPitData.length > 0) {
		const maxInspectionPitDepthFiltered = InspectionPitData.reduce((maxDepth, action) => {
			return Math.max(maxDepth, action.depth_to_base)
		}, -Infinity)
		OverallInspectionPitDepth = maxInspectionPitDepthFiltered
	} else {
		OverallInspectionPitDepth = 0 // Set to 0 when there's no data
	}

	if (OverallInspectionPitDepth !== depthOverallInspectionPit) {
		setDepthOverallInspectionPit(OverallInspectionPitDepth)
	}

	// console.log('InspectionPitData:', depthInspectionPit, filteredInspectionPitData, maxInspectionPitDepth)

	/////////////////////////////////////////////////////////
	// // GroundWaterTestsData
	// const filteredGroundWaterTestsData = GroundWaterTestsData?.filter((action: any) => {
	// 	const actionDate = new Date(action.time_of_action);
	// 	const endOfDaySelectedDate = new Date(selectedDate);
	// 	endOfDaySelectedDate.setHours(23, 59, 59, 999); // Set to end of the day
	// 	return actionDate <= endOfDaySelectedDate;
	// }) || []

	// let maxGroundWaterTestsDepth
	// if (filteredGroundWaterTestsData.length > 0) {
	// 	const maxGroundWaterTestsDepthFiltered = filteredGroundWaterTestsData.reduce((maxDepth, action) => {
	// 		return Math.max(maxDepth, action.depth_to_water)
	// 	}, -Infinity)
	// 	maxGroundWaterTestsDepth = maxGroundWaterTestsDepthFiltered
	// } else {
	// 	maxGroundWaterTestsDepth = 0 // Set to 0 when there's no data
	// }

	// console.log('Max GroundWater Depth:', maxGroundWaterTestsDepth)
	// if (maxGroundWaterTestsDepth !== depthGroundwater) {
	// 	setDepthSamples(maxGroundWaterTestsDepth)
	// }

	// /////////////////////////////////////////////////////////
	// // ChisellingData
	// const filteredChisellingData = ChisellingData?.filter((action: any) => {
	// 	const actionDate = new Date(action.time_of_action);
	// 	const endOfDaySelectedDate = new Date(selectedDate);
	// 	endOfDaySelectedDate.setHours(23, 59, 59, 999); // Set to end of the day
	// 	return actionDate <= endOfDaySelectedDate;
	// }) || []

	// let maxChisellingDepth
	// if (filteredChisellingData.length > 0) {
	// 	const maxChisellingDepthFiltered = filteredChisellingData.reduce((maxDepth, action) => {
	// 		return Math.max(maxDepth, action.depth_at_end)
	// 	}, -Infinity)
	// 	maxChisellingDepth = maxChisellingDepthFiltered
	// } else {
	// 	maxChisellingDepth = 0 // Set to 0 when there's no data
	// }

	// if (maxChisellingDepth !== depthChiselling) {
	// 	setDepthChiselling(maxChisellingDepth)
	// }

	/////////////////////////////////////////////////////////
	// ReDrillData
	const filteredReDrillData = ReDrillData?.filter((action: any) => {
		const actionDate = new Date(action.time_of_action);
		const endOfDaySelectedDate = new Date(selectedDate);
		endOfDaySelectedDate.setHours(23, 59, 59, 999); // Set to end of the day
		return actionDate <= endOfDaySelectedDate;
	}) || []

	let maxReDrillDepth
	if (filteredReDrillData.length > 0) {
		const maxReDrillDepthFiltered = filteredReDrillData.reduce((maxDepth, action) => {
			return Math.max(maxDepth, action.base_of_redrill)
		}, -Infinity)
		maxReDrillDepth = maxReDrillDepthFiltered
	} else {
		maxReDrillDepth = 0 // Set to 0 when there's no data
	}

	if (maxReDrillDepth !== depthRedrill) {
		setDepthRedrill(maxReDrillDepth)
	}

	/////////////////////////////////////////////////////////
	// DriveRecord
	const filteredDriveRecordData = DriveRecordData?.filter((action: any) => {
		const actionDate = new Date(action.time_of_action);
		const endOfDaySelectedDate = new Date(selectedDate);
		endOfDaySelectedDate.setHours(23, 59, 59, 999); // Set to end of the day
		return actionDate <= endOfDaySelectedDate;
	}) || []

	let maxDriveRecordDepth
	if (filteredDriveRecordData.length > 0) {
		const maxDriveRecordDepthFiltered = filteredDriveRecordData.reduce((maxDepth, action) => {
			return Math.max(maxDepth, action.depth_to)
		}, -Infinity)
		maxDriveRecordDepth = maxDriveRecordDepthFiltered
	} else {
		maxDriveRecordDepth = 0 // Set to 0 when there's no data
	}

	if (maxDriveRecordDepth !== depthDriveRecord) {
		setDepthDriveRecord(maxDriveRecordDepth)
	}

	// console.log('depthDriveRecord:', depthDriveRecord)


	/////////////////////////////////////////////////////////
	// MAX OVERALL DRIVE RECORD DEPTH
	let OverallDriveRecordDepth
	if (DriveRecordData.length > 0) {
		const maxDriveRecordDepthFiltered = DriveRecordData.reduce((maxDepth, action) => {
			return Math.max(maxDepth, action.depth_to)
		}, -Infinity)
		OverallDriveRecordDepth = maxDriveRecordDepthFiltered
	} else {
		OverallDriveRecordDepth = 0 // Set to 0 when there's no data
	}

	if (OverallDriveRecordDepth !== depthOverallDriveRecord) {
		setDepthOverallDriveRecord(OverallDriveRecordDepth)
	}
	// console.log('DriveRecordData:', DriveRecordData)

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// SET ALL DEPTHS
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const allCalculatedDepths = [depthSamples, depthInsituTests , depthSoilDescriptions, depthInspectionPit, depthCasing, depthDriveRecord] // depthGroundwater, ]
	const calculatedBoreholeDepth = ('' + (Math.max(...allCalculatedDepths)).toFixed(2))
	if (calculatedBoreholeDepth !== borehole_depth) { setBorehole_depth(calculatedBoreholeDepth) }

	const allCalculatedDepthsOverall = [depthOverallSamples, depthOverallInsituTests , depthOverallSoilDescriptions, depthOverallInspectionPit, depthOverallCasing, depthOverallDriveRecord] // depthGroundwater, ]
	const calculatedBoreholeDepthOverall = ('' + (Math.max(...allCalculatedDepthsOverall)).toFixed(2))
	if (calculatedBoreholeDepthOverall !== borehole_depthOverall) { setBorehole_depthOverall(calculatedBoreholeDepthOverall) }

	const [borehole_depth_drive_record, setBorehole_depth_drive_record] = useState('')
	const allCalculatedDepthsDriveRecord = [depthInspectionPit, depthDriveRecord] // depthGroundwater, ]
	const calculatedBoreholeDepthDriveRecord = ('' + (Math.max(...allCalculatedDepthsDriveRecord)).toFixed(2))
	if (calculatedBoreholeDepthDriveRecord !== borehole_depth_drive_record) { setBorehole_depth_drive_record(calculatedBoreholeDepthDriveRecord) }





	//.......................................................
	//.PPPPPPPPP..PPIII.IPPPPPPPP...PEEEEEEEEEE..SSSSSSS.....
	//.PPPPPPPPPP.PPIII.IPPPPPPPPP..PEEEEEEEEEE.ESSSSSSSS....
	//.PPPPPPPPPPPPPIII.IPPPPPPPPPP.PEEEEEEEEEE.ESSSSSSSSS...
	//.PPPP...PPPPPPIII.IPPP...PPPP.PEEE.......EESSS..SSSS...
	//.PPPP...PPPPPPIII.IPPP...PPPP.PEEE.......EESSS.........
	//.PPPPPPPPPPPPPIII.IPPPPPPPPPP.PEEEEEEEEE..ESSSSSS......
	//.PPPPPPPPPP.PPIII.IPPPPPPPPP..PEEEEEEEEE...SSSSSSSSS...
	//.PPPPPPPPP..PPIII.IPPPPPPPP...PEEEEEEEEE.....SSSSSSS...
	//.PPPP.......PPIII.IPPP........PEEE..............SSSSS..
	//.PPPP.......PPIII.IPPP........PEEE.......EESS....SSSS..
	//.PPPP.......PPIII.IPPP........PEEEEEEEEEEEESSSSSSSSSS..
	//.PPPP.......PPIII.IPPP........PEEEEEEEEEE.ESSSSSSSSS...
	//.PPPP.......PPIII.IPPP........PEEEEEEEEEE..SSSSSSSS....
	//.......................................................

	const filteredInstallationData = InstallationData?.filter((action: any) => {
		const actionDate = new Date(action.time_of_action);
		const endOfDaySelectedDate = new Date(selectedDate);
		endOfDaySelectedDate.setHours(23, 59, 59, 999); // Set to end of the day
		return actionDate <= endOfDaySelectedDate;
	}) || []

	// console.log('filteredInstallationData', filteredInstallationData)

	const [pipe1solid, setPipe1solid] = useState('')
	const [pipe1slotted, setPipe1slotted] = useState('')
	const [pipe2solid, setPipe2solid] = useState('')
	const [pipe2slotted, setPipe2slotted] = useState('')
	if (filteredInstallationData.length > 0) {
		const installationPipeData = filteredInstallationData[0]; // Access the first element of the array
		if (installationPipeData.pipe_solid_1 !== pipe1solid) { setPipe1solid(installationPipeData.pipe_solid_1); }
		if (installationPipeData.pipe_slotted_1 !== pipe1slotted) { setPipe1slotted(installationPipeData.pipe_slotted_1); }
		if (installationPipeData.pipe_solid_2 !== pipe2solid) { setPipe2solid(installationPipeData.pipe_solid_2); }
		if (installationPipeData.pipe_slotted_2 !== pipe2slotted) { setPipe2slotted(installationPipeData.pipe_slotted_2); }
	}

	// console.log('PIPES:', pipe1solid, pipe1slotted, pipe2solid, pipe2slotted)














	//....................................................................................................
	//...SSSSSSS......OOOOOOO....OIIII.LLLL............DDDDDDDDD....EEEEEEEEEEE..SSSSSSS......CCCCCCC.....
	//..SSSSSSSSS....OOOOOOOOOO..OIIII.LLLL............DDDDDDDDDD...EEEEEEEEEEE.SSSSSSSSS....CCCCCCCCC....
	//..SSSSSSSSSS..OOOOOOOOOOOO.OIIII.LLLL............DDDDDDDDDDD..EEEEEEEEEEE.SSSSSSSSSS..CCCCCCCCCCC...
	//.SSSSS..SSSS..OOOOO..OOOOO.OIIII.LLLL............DDDD...DDDD..EEEE.......ESSSS..SSSS..CCCC...CCCCC..
	//.SSSSS.......SOOOO....OOOOOOIIII.LLLL............DDDD....DDDD.EEEE.......ESSSS.......SCCC.....CCC...
	//..SSSSSSS....SOOO......OOOOOIIII.LLLL............DDDD....DDDD.EEEEEEEEEE..SSSSSSS....SCCC...........
	//...SSSSSSSSS.SOOO......OOOOOIIII.LLLL............DDDD....DDDD.EEEEEEEEEE...SSSSSSSSS.SCCC...........
	//.....SSSSSSS.SOOO......OOOOOIIII.LLLL............DDDD....DDDD.EEEEEEEEEE.....SSSSSSS.SCCC...........
	//........SSSSSSOOOO....OOOOOOIIII.LLLL............DDDD....DDDD.EEEE..............SSSSSSCCC.....CCC...
	//.SSSS....SSSS.OOOOO..OOOOO.OIIII.LLLL............DDDD...DDDDD.EEEE.......ESSS....SSSS.CCCC...CCCCC..
	//.SSSSSSSSSSSS.OOOOOOOOOOOO.OIIII.LLLLLLLLLL......DDDDDDDDDDD..EEEEEEEEEEEESSSSSSSSSSS.CCCCCCCCCCC...
	//..SSSSSSSSSS...OOOOOOOOOO..OIIII.LLLLLLLLLL......DDDDDDDDDD...EEEEEEEEEEE.SSSSSSSSSS...CCCCCCCCCC...
	//...SSSSSSSS......OOOOOO....OIIII.LLLLLLLLLL......DDDDDDDDD....EEEEEEEEEEE..SSSSSSSS.....CCCCCCC.....
	//....................................................................................................


	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// GET LAST SOIL SESCRIPTION DETAILS
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const filteredSoilDescriptionDataDetail = SoilDescriptionData?.filter((action: any) => {
		const actionDate = new Date(action.time_of_action);
		const endOfDaySelectedDate = new Date(selectedDate);
		endOfDaySelectedDate.setHours(23, 59, 59, 999); // Set to end of the day
		return actionDate <= endOfDaySelectedDate;
	}) || [];

	const validSoilDescriptionRecords = filteredSoilDescriptionDataDetail
		.filter(action => action.depth_to_base !== 0); // Filter out records with depth_to_base = 0

	const lastValidSoilDescriptionRecord = validSoilDescriptionRecords
		.reduce((latestRecord, action) => {
			return action.depth_to_base > latestRecord.depth_to_base ? action : latestRecord;
		}, { depth_to_base: -Infinity, depth_to_top: 0, stratum_description: '' });

	if (lastValidSoilDescriptionRecord.depth_to_base !== -Infinity) {
		const lastSoilDescriptionTop = lastValidSoilDescriptionRecord.depth_to_top;
		const lastSoilDescriptionBase = lastValidSoilDescriptionRecord.depth_to_base;
		const lastSoilDescriptionStratum = lastValidSoilDescriptionRecord.stratum_description;

		if (lastSoilDescriptionTop !== last_soil_description_depth_from) {
			setLast_soil_description_depth_from(lastSoilDescriptionTop);
		}
		if (lastSoilDescriptionBase !== last_soil_description_depth_to) {
			setLast_soil_description_depth_to(lastSoilDescriptionBase);
		}
		if (lastSoilDescriptionStratum !== last_soil_description_depth_text) {
			setLast_soil_description_depth_text(lastSoilDescriptionStratum);
		}
	}







	//.................................................
	//.BBBBBBBBBB...KKKK...KKKKK.FFFFFFFFFF.LLLL.......
	//.BBBBBBBBBBB..KKKK..KKKKK..FFFFFFFFFF.LLLL.......
	//.BBBBBBBBBBB..KKKK.KKKKK...FFFFFFFFFF.LLLL.......
	//.BBBB...BBBB..KKKKKKKKK....FFFF.......LLLL.......
	//.BBBB...BBBB..KKKKKKKK.....FFFF.......LLLL.......
	//.BBBBBBBBBBB..KKKKKKKK.....FFFFFFFFF..LLLL.......
	//.BBBBBBBBBB...KKKKKKKK.....FFFFFFFFF..LLLL.......
	//.BBBBBBBBBBB..KKKKKKKKK....FFFFFFFFF..LLLL.......
	//.BBBB....BBBB.KKKK.KKKKK...FFFF.......LLLL.......
	//.BBBB....BBBB.KKKK..KKKK...FFFF.......LLLL.......
	//.BBBBBBBBBBBB.KKKK..KKKKK..FFFF.......LLLLLLLLL..
	//.BBBBBBBBBBB..KKKK...KKKKK.FFFF.......LLLLLLLLL..
	//.BBBBBBBBBB...KKKK...KKKKK.FFFF.......LLLLLLLLL..
	//.................................................


	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// GET Backfill & Install GUIDs
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



	const [backfillGuid, setBackfillGuid] = useState([])
	const [installGuid, setInstallGuid] = useState([])
	const [backfillText, setBackfillText] = useState('')

	const backfill_guid = filteredDataBackfill[0]?.guid || null;

	if (backfill_guid !== backfillGuid) { setBackfillGuid(backfill_guid) }

	const install_guid = filteredDataInstall[0]?.guid || null;

	if (install_guid !== installGuid) { setInstallGuid(install_guid) }

	useEffect(() => {
		const backfillTextOutput = BackfillData
			.filter((entry: any) => entry.layers && Array.isArray(entry.layers) && entry.layers.length > 0)
			.map((valueBackfill: any) => {
				const layersText = valueBackfill.layers
					.filter((layer: any) => layer.backfillType !== null) // Filter out layers with backfillType as null
					.map((layer: any) => {
						// Adjust this part based on the actual structure of your layer object
						return `${layer.backfillType} (${layer.depth}m)`;
					})
					.join(', ');

				return `${layersText}`;
			})
			.join(', ');

		if (backfillTextOutput) {
			setBackfillText('Backfill (top to bottom): ' + backfillTextOutput);
		}
	}, [BackfillData]);


	// // console.log('BackfillData:', BackfillData)

	// // Define a function to calculate and update backfillText // STANDARD ARRAY
	// const updateBackfillText = (backfillData: any) => {
	// 	// Check if backfillData has a layers property and it is an array
	// 	if (backfillData.layers && Array.isArray(backfillData.layers)) {
	// 	  const backfillTextOutput = backfillData.layers
	// 		.filter((entry: any) => entry.backfillType !== '' || entry.depth !== '0.00')
	// 		.map((layer: any) => `${layer.backfillType} (${layer.depth}m)`)
	// 		.join(', ');

	// 	  if (backfillTextOutput) {
	// 		setBackfillText('Backfill (top to bottom): ' + backfillTextOutput);
	// 	  }
	// 	} else {
	// 	  console.error('Invalid backfillData format:', backfillData);
	// 	}
	//   };

	// Define a function to calculate and update backfillText // MULTI DIMENSIONAL ARRAY
	const updateBackfillText = (backfillData: any) => {
		// Check if backfillData has a layers property and it is an array
		if (backfillData.layers && Array.isArray(backfillData.layers)) {
			const backfillTextOutput = backfillData.layers
				.flatMap((layerArray: any) => {
					// Check if layerArray is an array before processing
					if (Array.isArray(layerArray)) {
						return layerArray
							.filter((entry: any) => entry.backfillType !== '' || entry.depth !== '0.00')
							.map((layer: any) => `${layer.backfillType} (${layer.depth}m)`);
					} else {
						return [];
					}
				})
				.join(', ');

			if (backfillTextOutput) {
				setBackfillText('Backfill (top to bottom): ' + backfillTextOutput);
			} else {
				setBackfillText('No backfill layers added');
			}
		} else {
			// console.error('Invalid backfillData format:', backfillData);
			setBackfillText('Error: Invalid backfillData format');
		}
	};


	// console.log('backfillText:', backfillText)







	//...........................................................................................................
	//....CCCCCCC......OOOOOOO....MMMMMM...MMMMMMMMMMMM...MMMMMM.EEEEEEEEEEE.NNNN...NNNN..TTTTTTTTTTS.SSSSSSS....
	//...CCCCCCCCC....OOOOOOOOOO..MMMMMM...MMMMMMMMMMMM...MMMMMM.EEEEEEEEEEE.NNNNN..NNNN..TTTTTTTTTTSSSSSSSSSS...
	//..CCCCCCCCCCC..OOOOOOOOOOOO.MMMMMM...MMMMMMMMMMMM...MMMMMM.EEEEEEEEEEE.NNNNN..NNNN..TTTTTTTTTTSSSSSSSSSSS..
	//..CCCC...CCCCC.OOOOO..OOOOO.MMMMMMM.MMMMMMMMMMMMMM.MMMMMMM.EEEE........NNNNNN.NNNN.....TTTT...SSSSS..SSSS..
	//.CCCC.....CCC.OOOOO....OOOOOMMMMMMM.MMMMMMMMMMMMMM.MMMMMMM.EEEE........NNNNNN.NNNN.....TTTT...SSSSS........
	//.CCCC.........OOOO......OOOOMMMMMMM.MMMMMMMMMMMMMM.MMMMMMM.EEEEEEEEEE..NNNNNNNNNNN.....TTTT....SSSSSSS.....
	//.CCCC.........OOOO......OOOOMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM.EEEEEEEEEE..NNNNNNNNNNN.....TTTT.....SSSSSSSSS..
	//.CCCC.........OOOO......OOOOMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM.EEEEEEEEEE..NNNNNNNNNNN.....TTTT.......SSSSSSS..
	//.CCCC.....CCC.OOOOO....OOOOOMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM.EEEE........NNNNNNNNNNN.....TTTT..........SSSS..
	//..CCCC...CCCCC.OOOOO..OOOOO.MMMM.MMMMM.MMMMMMMM.MMMMM.MMMM.EEEE........NNNN.NNNNNN.....TTTT...SSSS....SSS..
	//..CCCCCCCCCCC..OOOOOOOOOOOO.MMMM.MMMMM.MMMMMMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN..NNNNN.....TTTT...SSSSSSSSSSS..
	//...CCCCCCCCCC...OOOOOOOOOO..MMMM.MMMMM.MMMMMMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN..NNNNN.....TTTT....SSSSSSSSSS..
	//....CCCCCCC.......OOOOOO....MMMM.MMMMM.MMMMMMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN...NNNN.....TTTT.....SSSSSSSS...
	//...........................................................................................................


	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Standing Time & Dayworks TOTAL TIMES
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



	// Filter the data
	const filteredComments = CommentsData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// STANDING TIME

	const standingTimeComments = filteredComments.filter(comment => comment.comment_type === 'Standing Time');

	// Calculate total duration
	const totalDuration_ST = standingTimeComments.reduce((total, comment) => {
		const [hours_ST, mins_ST] = comment.duration.split(':').map(Number);
		return {
			hours_ST: total.hours_ST + hours_ST,
			mins_ST: total.mins_ST + mins_ST,
		};
	}, { hours_ST: 0, mins_ST: 0 });

	// Format the total duration
	// just numbers
	// const totalStandingTime = `${String(totalDuration_ST.hours_ST).padStart(2, '0')}:${String(totalDuration_ST.mins_ST).padStart(2, '0')}`;
	// hrs & mins
	const totalStandingTime = `${String(totalDuration_ST.hours_ST).padStart(2, '0')} hrs ${String(totalDuration_ST.mins_ST).padStart(2, '0')} mins`;
	// console.log('Total Standing Time Duration:', totalStandingTime);

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// DAYWORKS

	const dayworksComments = filteredComments.filter(comment => comment.comment_type === 'Dayworks');

	// Calculate total duration
	const totalDuration_DW = dayworksComments.reduce((total, comment) => {
		const [hours_DW, mins_DW] = comment.duration.split(':').map(Number);
		return {
			hours_DW: total.hours_DW + hours_DW,
			mins_DW: total.mins_DW + mins_DW,
		};
	}, { hours_DW: 0, mins_DW: 0 });

	// Format the total duration
	// just numbers
	// const totalDayworks = `${String(totalDuration_DW.hours_DW).padStart(2, '0')}:${String(totalDuration_DW.mins_DW).padStart(2, '0')}`;
	// hrs & mins
	const totalDayworks = `${String(totalDuration_DW.hours_DW).padStart(2, '0')} hrs ${String(totalDuration_DW.mins_DW).padStart(2, '0')} mins`;

	// console.log('Total Dayworks Duration:', totalDayworks);













	//............................................................................................
	//.WWWW..WWWWW...WWWEEEEEEEEEEEE....AAAAA...ATTTTTTTTTTTHHH....HHHH.HEEEEEEEEEE.ERRRRRRRRRR...
	//.WWWW..WWWWW..WWWW.EEEEEEEEEEE....AAAAA...ATTTTTTTTTTTHHH....HHHH.HEEEEEEEEEE.ERRRRRRRRRRR..
	//.WWWW..WWWWWW.WWWW.EEEEEEEEEEE...AAAAAA...ATTTTTTTTTTTHHH....HHHH.HEEEEEEEEEE.ERRRRRRRRRRR..
	//.WWWW.WWWWWWW.WWWW.EEEE..........AAAAAAA......TTTT...THHH....HHHH.HEEE........ERRR....RRRR..
	//.WWWW.WWWWWWW.WWWW.EEEE.........AAAAAAAA......TTTT...THHH....HHHH.HEEE........ERRR....RRRR..
	//..WWWWWWWWWWW.WWW..EEEEEEEEEE...AAAAAAAA......TTTT...THHHHHHHHHHH.HEEEEEEEEEE.ERRRRRRRRRRR..
	//..WWWWWWW.WWWWWWW..EEEEEEEEEE...AAAA.AAAA.....TTTT...THHHHHHHHHHH.HEEEEEEEEEE.ERRRRRRRRRR...
	//..WWWWWWW.WWWWWWW..EEEEEEEEEE..AAAAAAAAAA.....TTTT...THHHHHHHHHHH.HEEEEEEEEEE.ERRRRRRRR.....
	//..WWWWWWW.WWWWWWW..EEEE........AAAAAAAAAAA....TTTT...THHH....HHHH.HEEE........ERRR.RRRRR....
	//..WWWWWWW.WWWWWWW..EEEE........AAAAAAAAAAA....TTTT...THHH....HHHH.HEEE........ERRR..RRRRR...
	//...WWWWW...WWWWW...EEEEEEEEEEEAAAA....AAAA....TTTT...THHH....HHHH.HEEEEEEEEEEEERRR...RRRRR..
	//...WWWWW...WWWWW...EEEEEEEEEEEAAAA.....AAAA...TTTT...THHH....HHHH.HEEEEEEEEEEEERRR....RRRR..
	//...WWWWW...WWWWW...EEEEEEEEEEEAAAA.....AAAA...TTTT...THHH....HHHH.HEEEEEEEEEEEERRR.....RRR..
	//............................................................................................

	let weatherIcon = ''
	getWeatherList().map((weather) => {
		if (weather.label === selectedWeather)
			weatherIcon = weather.icon
	})
	

	const [boreholeWeather, setBoreholeWeather] = useState(selectedWeather)

	useEffect(() => {
		if(borehole_guid){
			cachedHoleInformation(`hole-locations/${borehole_guid}`, (holeData: any) => {
				if (holeData) {
					setBoreholeWeather(holeData.weather); // HOLE WEATHER
				}
			});
		}
	}, [borehole_guid]);

	useEffect(() => {
		if(borehole_guid){
			updateWeather(
				borehole_guid,
				selectedWeather
			)
		}
	}, [selectedWeather, borehole_guid]);




















	//....................................
	//.RRRRRRRRRR...RIIII.....GGGGGGG.....
	//.RRRRRRRRRRR..RIIII...GGGGGGGGGG....
	//.RRRRRRRRRRR..RIIII..GGGGGGGGGGGG...
	//.RRRR...RRRRR.RIIII..GGGGG..GGGGG...
	//.RRRR...RRRRR.RIIII.IGGGG....GGG....
	//.RRRRRRRRRRR..RIIII.IGGG............
	//.RRRRRRRRRRR..RIIII.IGGG..GGGGGGGG..
	//.RRRRRRRR.....RIIII.IGGG..GGGGGGGG..
	//.RRRR.RRRR....RIIII.IGGGG.GGGGGGGG..
	//.RRRR..RRRR...RIIII..GGGGG....GGGG..
	//.RRRR..RRRRR..RIIII..GGGGGGGGGGGG...
	//.RRRR...RRRRR.RIIII...GGGGGGGGGG....
	//.RRRR....RRRRR.IIII.....GGGGGGG.....
	//....................................

	type RigData = {
		currentRig_guid?: string;
		currentRig_type?: string;
		currentRig_plant_id?: string;
		currentRig_make_model?: string;
		currentRig_test_date_expiry?: string;
		currentRig_spt_guid?: string;
		currentRig_spt?: {
			id_ref: string;
			calibration_date: string;
			energy_ratio: string;
			rod_type: string;
		};
		currentRig_dprg_type?: string;
		currentRig_dprg_hammer_mass?: string;
		currentRig_dprg_standard_drop?: string;
		currentRig_dprg_cone_diameter?: string;
		currentRig_dprg_rod_diameter?: string;
		currentRig_dprg_rod_mass?: string;
		currentRig_dprg_anvil_type?: string;
		currentRig_dprg_anvil_dampner?: string;
		currentRig_torque_default?: string;
		currentRig_test_method?: string;
	};

	const currentRigValue = rig_id



	const [currentRigData, setCurrentRigData] = useState<RigData>({
		currentRig_guid: undefined,
		currentRig_type: undefined,
		currentRig_plant_id: undefined,
		currentRig_make_model: undefined,
		currentRig_test_date_expiry: undefined,
		currentRig_spt_guid: undefined,
		currentRig_spt: undefined,
		currentRig_dprg_type: undefined,
		currentRig_dprg_hammer_mass: undefined,
		currentRig_dprg_standard_drop: undefined,
		currentRig_dprg_cone_diameter: undefined,
		currentRig_dprg_rod_diameter: undefined,
		currentRig_dprg_rod_mass: undefined,
		currentRig_dprg_anvil_type: undefined,
		currentRig_dprg_anvil_dampner: undefined,
		currentRig_torque_default: undefined,
		currentRig_test_method: undefined,
	});

	const cachedRigValues = useGetIDBValues

	console.log('currentRigData:', currentRigData)

	// const [savedRigGuid, setSavedRigValue] = useState('');

	// useEffect(() => {
	// 	cachedRigValues(`hole-locations/${borehole_guid}`, (holeData: any) => {
	// 		if (holeData) {
	// 			console.log('holeData in PERSONNEL:', holeData)
	// 			setSavedRigValue(holeData.rig_id)
	// 			setPlantName(holeData.rig_name);
	// 		} else {
	// 			// console.log(`**ERROR** HOLE DATA NOT LOADED. ID: ${borehole_guid}`);
	// 		}
	// 		// console.log('holeData:', holeData);
	// 	});
	// }, [borehole_guid, cachedRigValues]);

	// useEffect(() => {
	// 	if (savedRigGuid) {
	// 		cachedRigValues(`rigs/${savedRigGuid}`, (rigData: any) => {
	// 			if (rigData) {
	// 				console.log('rigData from HOLE INFORMATION:', rigData)
	// 				setCurrentRigData({
	// 					currentRig_guid: rigData?.guid,
	// 					currentRig_type: rigData?.type,
	// 					currentRig_plant_id: rigData?.plant_id,
	// 					currentRig_test_date_expiry: rigData?.test_date_expiry,
	// 					currentRig_spt_guid: rigData?.spt_guid,
	// 					currentRig_spt: rigData?.spt,
	// 					currentRig_dprg_type: rigData?.dprg_type,
	// 					currentRig_dprg_hammer_mass: rigData?.dprg_hammer_mass + 'kg',
	// 					currentRig_dprg_standard_drop: rigData?.dprg_standard_drop + 'mm',
	// 					currentRig_dprg_cone_diameter: rigData?.dprg_cone_diameter + 'mm',
	// 					currentRig_dprg_rod_diameter: rigData?.dprg_rod_diameter + 'mm',
	// 					currentRig_dprg_rod_mass: rigData?.dprg_rod_mass + 'kg',
	// 					currentRig_dprg_anvil_type: rigData?.dprg_anvil_type,
	// 					currentRig_dprg_anvil_dampner: rigData?.dprg_anvil_dampner,
	// 					currentRig_torque_default: rigData?.torque_default,
	// 					currentRig_test_method: rigData?.test_method,
	// 				});
	// 			} else {
	// 				// console.log(`**ERROR** RIG DATA NOT LOADED. ID: ${currentRigValue}`);
	// 			}
	// 			// console.log('rigData:', rigData);
	// 		});
	// 	}
	// }, [savedRigGuid, cachedRigValues]);

	useEffect(() => {
		if (savedRigGuid) {

			const key = `drillit-rigs/company/${company_guid}`;

			get(key).then((rigs) => {
				if (rigs && Array.isArray(rigs)) {
					const rigData = rigs.find(rig => rig.guid === savedRigGuid);

					if (rigData) {
						// console.log('rigData from HOLE INFORMATION:', rigData);
						setCurrentRigData({
							currentRig_guid: rigData?.guid,
							currentRig_type: rigData?.type,
							currentRig_plant_id: rigData?.plant_id,
							currentRig_make_model: rigData?.make_model,
							currentRig_test_date_expiry: rigData?.test_date_expiry,
							currentRig_spt_guid: rigData?.spt_guid,
							currentRig_spt: rigData?.spt,
							currentRig_dprg_type: rigData?.dprg_type,
							currentRig_dprg_hammer_mass: rigData?.dprg_hammer_mass + 'kg',
							currentRig_dprg_standard_drop: rigData?.dprg_standard_drop + 'mm',
							currentRig_dprg_cone_diameter: rigData?.dprg_cone_diameter + 'mm',
							currentRig_dprg_rod_diameter: rigData?.dprg_rod_diameter + 'mm',
							currentRig_dprg_rod_mass: rigData?.dprg_rod_mass + 'kg',
							currentRig_dprg_anvil_type: rigData?.dprg_anvil_type,
							currentRig_dprg_anvil_dampner: rigData?.dprg_anvil_dampner,
							currentRig_torque_default: rigData?.torque_default,
							currentRig_test_method: rigData?.test_method,
						});
					} else {
						// console.error('Rig with the specified GUID not found.');
					}
				} else {
					// console.error('Invalid data structure or no rigs found.');
				}
			}).catch(error => {
				// console.error('Error fetching data from IndexedDB:', error);
			});
		}
	}, [savedRigGuid, company_guid]);

	const [plantGuid, setPlantGuid] = useState(currentRigData?.currentRig_guid)
	const [plantType, setPlantType] = useState(currentRigData?.currentRig_type)
	const [plantID, setPlantID] = useState(currentRigData?.currentRig_plant_id)
	// const [plantName, setPlantName] = useState(rig_name)
	const [plantTestDate, setPlantTestDate] = useState(currentRigData?.currentRig_test_date_expiry)

	// console.log('plantGuid:', plantGuid)

	useEffect(() => {
		// console.log('RIGDATA*********************************************')
		// console.log('currentRigData.currentRig_type:', currentRigData?.currentRig_type)
		// console.log('currentRigData.currentRig_plant_id:', currentRigData?.currentRig_plant_id)
		// console.log('currentRigData.currentRig_test_date_expiry:', currentRigData?.currentRig_test_date_expiry)
		// console.log('currentRigData.currentRig_spt_guid:', currentRigData?.currentRig_spt_guid)
		// console.log('currentRigData.currentRig_spt:', currentRigData?.currentRig_spt)
		// console.log('****************************************************')
		}, [plantGuid])

	if (currentRigData?.currentRig_guid !== plantGuid) {
		setPlantGuid(currentRigData?.currentRig_guid)
	}
	if (currentRigData?.currentRig_type !== plantType) {
		setPlantType(currentRigData?.currentRig_type)
	}
	if (currentRigData?.currentRig_plant_id !== plantID) {
		setPlantID(currentRigData?.currentRig_plant_id)
	}
	// if (rig_name !== plantName) {
	// 	setPlantName(rig_name)
	// }
	if (currentRigData?.currentRig_test_date_expiry !== plantTestDate) {
		setPlantTestDate(currentRigData?.currentRig_test_date_expiry)
	}

	const sptID = currentRigData.currentRig_spt?.id_ref
	// const sptGUID = currentRigData?.currentRig_spt_guid
	const sptCalibrationDate = currentRigData.currentRig_spt?.calibration_date
	const sptEnergyRatio = currentRigData.currentRig_spt?.energy_ratio
	const sptRodType = currentRigData.currentRig_spt?.rod_type

	const [testType, setTestType] = useState(currentRigData?.currentRig_dprg_type)
	const [hammerMass, setHammerMass] = useState(currentRigData?.currentRig_dprg_hammer_mass)
	const [standardDrop, setStandardDrop] = useState(currentRigData?.currentRig_dprg_standard_drop)
	const [coneDiameter, setConeDiameter] = useState(currentRigData?.currentRig_dprg_cone_diameter)
	const [rodDiameter, setRodDiameter] = useState(currentRigData?.currentRig_dprg_rod_diameter)
	const [rodMass, setRodMass] = useState(currentRigData?.currentRig_dprg_rod_mass)
	const [anvilType, setAnvilType] = useState(currentRigData?.currentRig_dprg_anvil_type)
	const [anvilDampner, setAnvilDampner] = useState(currentRigData?.currentRig_dprg_anvil_dampner)
	const [torque_default, setTorque_default] = useState(currentRigData?.currentRig_torque_default)
	const [testMethod, setTestMethod] = useState(currentRigData?.currentRig_test_method)




	// PLANT CHECKS

	
	const [plantCheck_type, set_plantCheck_type] = useState('')
	const [plantCheckDates, setPlantCheckDates] = useState<string[]>([]);
	const [selectedDate_PlantCheck, set_selectedDate_PlantCheck] = useState<boolean>(false);
	// console.log('plantCheckDates:', typeof plantCheckDates, plantCheckDates)
	// console.log('selectedDate:', typeof selectedDate, selectedDate)
	// console.log('selectedDate_PlantCheck:', selectedDate_PlantCheck)

	// useEffect(() => {

	// 	if (savedRigGuid && plantType) {

	// 		const cachedPlantChecks = useGetIDBValues

	// 		console.log('plantType', plantType)

	// 		if (plantType === 'Cable Percussive') {
	// 			set_plantCheck_type('cable-percussive')
	// 		} else if (plantType === 'Window Sample') {
	// 			set_plantCheck_type('window-sample')
	// 		} else if (plantType === 'Rotary') {
	// 			set_plantCheck_type('rotary')
	// 		}

	// 		if (plantCheck_type) {
				
	// 			cachedPlantChecks(`plant-check-${plantCheck_type}/${savedRigGuid}`, (plantCheckData: any) => {

	// 				if (plantCheckData) {
	// 					console.log('plantCheckData:', plantCheckData.plantCheck) 
	// 					const dates: string[] = plantCheckData.plantCheck.map((check: any) => check.plant_check_date);
	// 					setPlantCheckDates(dates);
	// 				} else {
	// 					// console.log(`**ERROR** PLANTCHECK DATA NOT LOADED: ${plantCheckData}`);
	// 				}
	// 				// console.log('plantCheckData:', plantCheckData);
	// 			});
	// 		}

	// 	}

	// }, [savedRigGuid, cachedRigValues, plantType, plantCheck_type]);

	

	useEffect(() => {
		if (savedRigGuid && plantType) {
			if (plantType === 'Cable Percussive') {
				set_plantCheck_type('cable-percussive')
			} else if (plantType === 'Window Sample') {
				set_plantCheck_type('window-sample')
			} else if (plantType === 'Rotary') {
				set_plantCheck_type('rotary')
			}

			if (plantCheck_type) {

				// const key = `drillit-plant-check-${plantCheck_type}/${savedRigGuid}`;
				const key = `drillit-plant-check-${plantCheck_type}/project/${project_guid}`;

				get(key).then((plantCheckData) => {
					if (plantCheckData && Array.isArray(plantCheckData.plantCheck)) {
						// console.log('plantCheckData:', plantCheckData.plantCheck);
						const dates = plantCheckData.plantCheck.map((check:any) => check.plant_check_date);
						setPlantCheckDates(dates);
					} else {
						// console.error(`**ERROR** PLANTCHECK DATA NOT LOADED: ${plantCheckData}`);
					}
				}).catch(error => {
					// console.error('Error fetching data from IndexedDB:', error);
				});
			}
		}
	}, [savedRigGuid, plantType, plantCheck_type]);



	useEffect(() => {
		// Convert selectedDate string to a JavaScript Date object
		const selectedDateObject = new Date(selectedDate);
		
		// Format plantCheckDates strings into JavaScript Date objects
		const formattedPlantCheckDates = plantCheckDates.map(dateString => new Date(dateString));
		
		// Get selectedDate without the time component
		const selectedDateWithoutTime = new Date(selectedDateObject.getFullYear(), selectedDateObject.getMonth(), selectedDateObject.getDate());
	  
		// Check if any of the plantCheckDates match the selectedDate without time
		const selectedDateMatches = formattedPlantCheckDates.some(date => {
		  const formattedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
		  return formattedDate.getTime() === selectedDateWithoutTime.getTime();
		});
	  
		// Set selectedDate_PlantCheck based on the result of the comparison
		set_selectedDate_PlantCheck(selectedDateMatches);
	  }, [plantCheckDates, selectedDate]);



	  const [plantCheckWarning, setPlantCheckWarning] = useState(false)
  
		  // Universal Plant Check function
		  const panel_plantCheck = (callback: (value: boolean) => void) => {
			  if (selectedDate_PlantCheck) {
				  callback(true);
			  } else {
				  setPlantCheckWarning(true);
			  }
		  };





















	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// TEMP VALUES
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	// let labelSample = ''
	// let labelDepth = ''



















	//..................................................................................................................
	//.IIIII.NNNN...NNNN..FFFFFFFFFF...OOOOOOO............CCCCCCC....HHHH...HHHH..EEEEEEEEEEE...CCCCCCC....KKKK...KKKK..
	//.IIIII.NNNNN..NNNN..FFFFFFFFFF..OOOOOOOOOO.........CCCCCCCCC...HHHH...HHHH..EEEEEEEEEEE..CCCCCCCCC...KKKK..KKKKK..
	//.IIIII.NNNNN..NNNN..FFFFFFFFFF.OOOOOOOOOOOO.......CCCCCCCCCCC..HHHH...HHHH..EEEEEEEEEEE.CCCCCCCCCCC..KKKK.KKKKK...
	//.IIIII.NNNNNN.NNNN..FFFF.......OOOOO..OOOOO.......CCCC...CCCCC.HHHH...HHHH..EEEE........CCCC...CCCCC.KKKKKKKKK....
	//.IIIII.NNNNNN.NNNN..FFFF......OOOOO....OOOOO.....CCCC.....CCC..HHHH...HHHH..EEEE.......ECCC.....CCC..KKKKKKKK.....
	//.IIIII.NNNNNNNNNNN..FFFFFFFFF.OOOO......OOOO.....CCCC..........HHHHHHHHHHH..EEEEEEEEEE.ECCC..........KKKKKKKK.....
	//.IIIII.NNNNNNNNNNN..FFFFFFFFF.OOOO......OOOO.....CCCC..........HHHHHHHHHHH..EEEEEEEEEE.ECCC..........KKKKKKKK.....
	//.IIIII.NNNNNNNNNNN..FFFFFFFFF.OOOO......OOOO.....CCCC..........HHHHHHHHHHH..EEEEEEEEEE.ECCC..........KKKKKKKKK....
	//.IIIII.NNNNNNNNNNN..FFFF......OOOOO....OOOOO.....CCCC.....CCC..HHHH...HHHH..EEEE.......ECCC.....CCC..KKKK.KKKKK...
	//.IIIII.NNNN.NNNNNN..FFFF.......OOOOO..OOOOO.......CCCC...CCCCC.HHHH...HHHH..EEEE........CCCC...CCCCC.KKKK..KKKK...
	//.IIIII.NNNN..NNNNN..FFFF.......OOOOOOOOOOOO.......CCCCCCCCCCC..HHHH...HHHH..EEEEEEEEEEE.CCCCCCCCCCC..KKKK..KKKKK..
	//.IIIII.NNNN..NNNNN..FFFF........OOOOOOOOOO.........CCCCCCCCCC..HHHH...HHHH..EEEEEEEEEEE..CCCCCCCCCC..KKKK...KKKK..
	//.IIIII.NNNN...NNNN..FFFF..........OOOOOO............CCCCCCC....HHHH...HHHH..EEEEEEEEEEE...CCCCCCC....KKKK...KKKK..
	//..................................................................................................................




	const [informationCheck, setInformationCheck] = useState(true)

	useEffect(() => {

		if (selectedDate_PlantCheck && secondMan && plantGuid) {
			setInformationCheck(true)
		} else {
			setInformationCheck(false)
		}
		
	  }, [selectedDate_PlantCheck, secondMan, plantGuid]);

	






//..........................................................................................................................
//.UUUU...UUUU..PPPPPPPPP...PDDDDDDDD.......AAAAA...AAATTTTTTTTTTEEEEEEEEE...... I.IIINN...NNNN.NNNFFFFFFFF...OOOOOOO.....
//.UUUU...UUUU..PPPPPPPPPP..PDDDDDDDDD......AAAAA...AAATTTTTTTTTTEEEEEEEEE...... I.IIINN...NNNN.NNNFFFFFFFF..OOOOOOOOOO...
//.UUUU...UUUU..PPPPPPPPPPP.PDDDDDDDDDD....AAAAAA...AAATTTTTTTTTTEEEEEEEEE...... I.IIINNN..NNNN.NNNFFFFFFFF.FOOOOOOOOOOO..
//.UUUU...UUUU..PPPP...PPPP.PDDD...DDDD....AAAAAAA......TTTT...TTEE............. I.IIINNNN.NNNN.NNNF.......FFOOO...OOOOO..
//.UUUU...UUUU..PPPP...PPPP.PDDD....DDDD..AAAAAAAA......TTTT...TTEE............. I.IIINNNN.NNNN.NNNF.......FFOO.....OOOO..
//.UUUU...UUUU..PPPPPPPPPPP.PDDD....DDDD..AAAAAAAA......TTTT...TTEEEEEEEEE...... I.IIINNNNNNNNN.NNNFFFFFFF.FFOO......OOO..
//.UUUU...UUUU..PPPPPPPPPP..PDDD....DDDD..AAAA.AAAA.....TTTT...TTEEEEEEEEE...... I.IIINNNNNNNNN.NNNFFFFFFF.FFOO......OOO..
//.UUUU...UUUU..PPPPPPPPP...PDDD....DDDD.DAAAAAAAAA.....TTTT...TTEEEEEEEEE...... I.IIIN.NNNNNNN.NNNFFFFFFF.FFOO......OOO..
//.UUUU...UUUU..PPPP........PDDD....DDDD.DAAAAAAAAAA....TTTT...TTEE............. I.IIIN.NNNNNNN.NNNF.......FFOO.....OOOO..
//.UUUU...UUUU..PPPP........PDDD...DDDDD.DAAAAAAAAAA....TTTT...TTEE............. I.IIIN..NNNNNN.NNNF.......FFOOOO..OOOOO..
//.UUUUUUUUUUU..PPPP........PDDDDDDDDDD.DDAA....AAAA....TTTT...TTEEEEEEEEEE..... I.IIIN..NNNNNN.NNNF........FOOOOOOOOOOO..
//..UUUUUUUUU...PPPP........PDDDDDDDDD..DDAA.....AAAA...TTTT...TTEEEEEEEEEE..... I.IIIN...NNNNN.NNNF.........OOOOOOOOOO...
//...UUUUUUU....PPPP........PDDDDDDDD..DDDAA.....AAAA...TTTT...TTEEEEEEEEEE..... I.IIIN....NNNN.NNNF..........OOOOOOO.....
//..........................................................................................................................


function getHoleData() {


	cachedHoleInformation(`hole-locations/${borehole_guid}`, (holeData: any) => {
		if (holeData) {
			// console.log('holeData in getHoleData:', holeData)
			setLeadDriller(holeData.driller)
			setLeadDrillerID(holeData.driller_id)
			setLeadDrillerCSCS(holeData.driller_cscs);
			setSecondMan(holeData.second_man);
			setSecondManCSCS(holeData.second_man_cscs);
				
			setSavedRigValue(holeData.rig_id); // RIG GUID
			setPlantName(holeData.rig_name); // RIG NAME
			setSptGUID(holeData.spt_id); // SPT ID

			setBoreholeName(holeData.hole_name); // HOLE NAME
			setHole_barcode(holeData.hole_barcode); // BARCODE
			setActivityTerminationReason(holeData.activity_termination_reason); // TERMINATION REASON
			setHole_status(holeData.status); // HOLE STATUS
		} else {
			// // console.log(`**ERROR** HOLE DATA NOT LOADED. ID: ${borehole_guid}`);
		}
		// // console.log('holeData:', holeData);
	})

}

		
// function saveHoleData() {
// 	updateHoleInformation(
// 		borehole_guid,
// 		borehole_name,
// 		leadDriller,
// 		leadDrillerID,
// 		leadDrillerCSCS,
// 		secondMan,
// 		secondManCSCS,
// 		plantGuid || '',
// 		plantName,
// 		sptGUID || '',
// 		hole_barcode,
// 		borehole_depthOverall,
// 		activityTerminationReason,
// 		) 
// }


	// function saveHoleData() {

	// 	if (leadDriller && plantGuid && plantName) {

	// 		updateHoleInformation(
	// 			borehole_guid,
	// 			boreholeName,
	// 			leadDriller,
	// 			leadDrillerID,
	// 			leadDrillerCSCS,
	// 			secondMan,
	// 			secondManCSCS,
	// 			plantGuid || '',
	// 			plantName,
	// 			sptGUID || '',
	// 			hole_barcode,
	// 			borehole_depthOverall,
	// 			activityTerminationReason,
	// 			project_guid,
	// 			hole_status
	// 		)

	// 		const cachedHoleInformation = useGetIDBValues

	// 		cachedHoleInformation(`hole-locations/${borehole_guid}`, (holeData: any) => {
	// 			if (holeData) {
	// 				console.log('holeData in saveHoleData:', holeData)
	// 				// setBoreholeName(holeData.hole_name)
	// 				// setLeadDriller(holeData.driller)
	// 				// setLeadDriller(holeData.driller)
	// 				// setLeadDrillerCSCS(holeData.driller_cscs);
	// 				// setSecondMan(holeData.second_man);
	// 				// setSecondManCSCS(holeData.second_man_cscs);
	// 				// setSavedRigValue(holeData.rig_id);
	// 				// setPlantName(holeData.rig_name);
	// 				// setSptGUID(holeData.spt_id);
	// 			} else {
	// 				// // console.log(`**ERROR** HOLE DATA NOT LOADED. ID: ${borehole_guid}`);
	// 			}
	// 			// // console.log('holeData:', holeData);
	// 		})


	// 	} else {
	// 		getHoleData()
	// 	}

	// }

	function saveHoleData() {
	
		// Attempt to delete the outdated hole information
		del(`drillit-hole-locations/updateInformation/${borehole_guid}-update`)
			.catch(error => {
				console.warn('Failed to delete outdated hole information (may not exist):', error);
			})
			.finally(() => {
				let hole_status_temp = '1'
				if (hole_status !== '3') {
					hole_status_temp = '2'
				} else {
					hole_status_temp = '3'
				}
				// Update the hole information
				updateHoleInformation(
					borehole_guid,
					boreholeName,
					leadDriller,
					leadDrillerID,
					leadDrillerCSCS,
					secondMan,
					secondManCSCS,
					plantGuid || '',
					plantName,
					plantMakeModel,
					sptGUID || '',
					hole_barcode,
					borehole_depthOverall,
					activityTerminationReason,
					project_guid,
					hole_status_temp, // hole_status
					numberingType
				)
				.then(() => {
					// Fetch the updated hole data
					getHoleData();
				})
				.catch(error => {
					console.error('Failed to update hole information:', error);
				});
			});
	}

	

	useEffect(() => {
		// console.log('TOTAL COUNTS'
		// , numberOfSamplesD_TOTAL, numberOfSamplesB_TOTAL, numberOfSamplesES_TOTAL, numberOfSamplesWS_TOTAL,
		// numberOfSPTsCPTs_TOTAL, numberOfUSamples_TOTAL, numberOfPSSamples_TOTAL, numberOfShearVane_TOTAL, borehole_depthOverall)
		updateActionCounts(
			borehole_guid,
			'Window Sample',
			boreholeName,
			numberOfSamplesD_TOTAL || 0,
			numberOfSamplesB_TOTAL || 0,
			numberOfSamplesES_TOTAL || 0,
			numberOfSamplesWS_TOTAL || 0,
			numberOfSPTs_TOTAL || 0,
			numberOfCPTs_TOTAL || 0,
			numberOfSPTs_TOTAL + numberOfCPTs_TOTAL || 0,
			numberOfUSamples_TOTAL || 0,
			numberOfPSSamples_TOTAL || 0,
			numberOfShearVane_TOTAL || 0,
			parseFloat(borehole_depthOverall) || 0
		)
	}, [numberOfSamplesD_TOTAL, numberOfSamplesB_TOTAL, numberOfSamplesES_TOTAL, numberOfSamplesWS_TOTAL, numberOfSPTs_TOTAL, numberOfCPTs_TOTAL,
		numberOfSPTsCPTs_TOTAL, numberOfUSamples_TOTAL, numberOfPSSamples_TOTAL, numberOfShearVane_TOTAL, borehole_depthOverall
	])













	return (

		<>

			{/* //..............................................................................
//.HHHH...HHHH..EEEEEEEEEEE....AAAAA.....ADDDDDDDD....DEEEEEEEEEE.ERRRRRRRRR....
//.HHHH...HHHH..EEEEEEEEEEE....AAAAA.....ADDDDDDDDD...DEEEEEEEEEE.ERRRRRRRRRR...
//.HHHH...HHHH..EEEEEEEEEEE...AAAAAA.....ADDDDDDDDDD..DEEEEEEEEEE.ERRRRRRRRRR...
//.HHHH...HHHH..EEEE..........AAAAAAA....ADDD...DDDD..DEEE........ERRR...RRRRR..
//.HHHH...HHHH..EEEE.........AAAAAAAA....ADDD....DDDD.DEEE........ERRR...RRRRR..
//.HHHHHHHHHHH..EEEEEEEEEE...AAAAAAAA....ADDD....DDDD.DEEEEEEEEE..ERRRRRRRRRR...
//.HHHHHHHHHHH..EEEEEEEEEE...AAAA.AAAA...ADDD....DDDD.DEEEEEEEEE..ERRRRRRRRRR...
//.HHHHHHHHHHH..EEEEEEEEEE..EAAAAAAAAA...ADDD....DDDD.DEEEEEEEEE..ERRRRRRR......
//.HHHH...HHHH..EEEE........EAAAAAAAAAA..ADDD....DDDD.DEEE........ERRR.RRRR.....
//.HHHH...HHHH..EEEE........EAAAAAAAAAA..ADDD...DDDDD.DEEE........ERRR..RRRR....
//.HHHH...HHHH..EEEEEEEEEEEEEAA....AAAA..ADDDDDDDDDD..DEEEEEEEEEE.ERRR..RRRRR...
//.HHHH...HHHH..EEEEEEEEEEEEEAA.....AAAA.ADDDDDDDDD...DEEEEEEEEEE.ERRR...RRRRR..
//.HHHH...HHHH..EEEEEEEEEEEEEAA.....AAAA.ADDDDDDDD....DEEEEEEEEEE.ERRR....RRRR..
//.............................................................................. */}

			<Header toggleSidebar={toggleSidebar}
				middle={
					<ProjectHeader projectId={project_guid}
						information_check={informationCheck}
						holeType="WS"
						holeName={borehole_name}
						companyGuid={company_guid}
						company_id={companyID}
						projectGuid={project_guid}
						activeDates={active_dates}
						lastActiveDate={lastActiveDate}

						project_start_date={project_start_date}
						site_description={site_description}
						key_site_features={key_site_features}
						site_history={site_history}
						key_hands={key_hands}
						expected_strata={expected_strata}
						notes={notes}
						equipment_required={equipment_required}
						project_client={client}
						client_contact={client_contact}
						client_phone={client_phone}
						client_email={client_email}
						engineer_name={engineer_name}
						engineer_phone={engineer_phone}
						engineer_email={engineer_email}
						rig_name={plantName}
						driller={leadDriller}
						second_man={secondMan}
						plantCheck={selectedDate_PlantCheck}
					/>
				}
			// companyName={company_name}
			/>








			{/* //.........................................................................
			//.PPPPPPPPP.....AAAA.....AAANN...NNNN.NNNEEEEEEEE.EEEL.........LSSSSSS....
			//.PPPPPPPPPP...AAAAAA....AAANN...NNNN.NNNEEEEEEEE.EEEL........LLSSSSSSS...
			//.PPPPPPPPPPP..AAAAAA....AAANNN..NNNN.NNNEEEEEEEE.EEEL.......LLLSSSSSSSS..
			//.PPPP...PPPP..AAAAAAA...AAANNNN.NNNN.NNNE........EEEL.......LLLS...SSSS..
			//.PPPP...PPPP.PAAAAAAA...AAANNNN.NNNN.NNNE........EEEL.......LLLSS........
			//.PPPPPPPPPPP.PAAAAAAA...AAANNNNNNNNN.NNNEEEEEEEE.EEEL........LLSSSSS.....
			//.PPPPPPPPPP..PAAA.AAAA..AAANNNNNNNNN.NNNEEEEEEEE.EEEL.........LSSSSSSS...
			//.PPPPPPPPP..PPAAAAAAAA..AAAN.NNNNNNN.NNNEEEEEEEE.EEEL...........SSSSSSS..
			//.PPPP.......PPAAAAAAAAA.AAAN.NNNNNNN.NNNE........EEEL..............SSSS..
			//.PPPP......PPPAAAAAAAAA.AAAN..NNNNNN.NNNE........EEEL.......LLLS...SSSS..
			//.PPPP......PPPA....AAAA.AAAN..NNNNNN.NNNEEEEEEEEEEEELLLLLLLLLLLSSSSSSSS..
			//.PPPP......PPPA....AAAAAAAAN...NNNNN.NNNEEEEEEEEEEEELLLLLLLL.LLSSSSSSSS..
			//.PPPP.....PPPPA.....AAAAAAAN....NNNN.NNNEEEEEEEEEEEELLLLLLLL..LSSSSSS....
			//......................................................................... */}


			{completingBorehole &&
				<Modal title="Mark as Complete" toggleModal={() => { setCompletingBorehole(false) }}>
					<div className="flex items-center flex-col pt-36">
						<div className="mb-14">
							Confirm you have completed activity on this borehole
						</div>

						<div className="flex">
							<Button theme="secondary" size="lg" className="mr-8" onClick={() => { setCompletingBorehole(false) }}>Cancel</Button>

							<Button theme="primary" size="lg" onClick={() => {
								closeHole(borehole_guid, formattedDate, project_guid).then(() => { setRedirect(true) })
							}}>Confirm</Button>
						</div>
					</div>
				</Modal>
			}

			{showSamplesCreateD &&
				<SamplesCreate
					onClose={() => { setShowSamplesCreateD(false) }}
					setShowSamplesCreateD={setShowSamplesCreateD}
					onComplete={(value: any) => saveAction(value, 'D', setShowSamplesCreateD(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
					title="Disturbed Sample (D)"
					casing_preset={current_casing_depth}
					addSampleData={addSampleData}
					from_type={fromType}
					new_type_number={numberOfSamplesD_TOTAL + 1}
					new_type_number_sequencial={numberOfSamples_TOTAL + 1}
					numbering_type={numberingType}
					spt_number={numberOfSPTs_TOTAL}
					spt_number_sequencial={numberOfSPTsCPTs_TOTAL}
					u_number={numberOfUSamples_TOTAL}
					sampled_by={leadDriller}
				/>
			}

			{showSamplesCreateB &&
				<SamplesCreate
					onClose={() => { setShowSamplesCreateB(false) }}
					setShowSamplesCreateB={setShowSamplesCreateB}
					onComplete={(value: any) => saveAction(value, 'B', setShowSamplesCreateB(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
					title="Bulk Sample (B)"
					casing_preset={current_casing_depth}
					addSampleData={addSampleData}
					from_type={fromType}
					new_type_number={numberOfSamplesB_TOTAL + 1}
					new_type_number_sequencial={numberOfSamples_TOTAL + 1}
					numbering_type={numberingType}
					cpt_number={numberOfCPTs_TOTAL}
					cpt_number_sequencial={numberOfSPTsCPTs_TOTAL}
					sampled_by={leadDriller}
				/>
			}

			{showSamplesCreateWS &&
				<SamplesCreate
					onClose={() => { setShowSamplesCreateWS(false) }}
					setShowSamplesCreateWS={setShowSamplesCreateWS}
					onComplete={(value: any) => saveAction(value, 'WS', setShowSamplesCreateWS(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
					title="Water Sample (WS)"
					casing_preset={current_casing_depth}
					addSampleData={addSampleData}
					from_type={fromType}
					new_type_number={numberOfSamplesWS_TOTAL + 1}
					new_type_number_sequencial={numberOfSamples_TOTAL + 1}
					numbering_type={numberingType}
					sampled_by={leadDriller}
				/>
			}

			{showD &&
				<Samples
					onClose={() => { setShowD(false) }}
					onCompleteNumbering={(value: any) => {
						setNumberingType(value)
						saveHoleData()
					}}
					onComplete={(value: any) => saveAction(value, 'D', setShowD(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
					title="Disturbed Sample (D)"
					casing_preset={current_casing_depth}
					new_type_number={numberOfSamplesD_TOTAL + 1}
					new_type_number_sequencial={numberOfSamples_TOTAL + 1}
					numbering_type={numberingType}
					sampleType='D'
					sampled_by={leadDriller}
				/>
			}

			{showDEdit &&
				<Samples
					update={true}
					onClose={() => { setShowDEdit(false) }}
					setShowDEdit={setShowDEdit}
					onCompleteNumbering={(value: any) => {
						setNumberingType(value)
						saveHoleData()
					}}
					onComplete={(value: any) => {
						saveAction(value, 'D', setShowDEdit(false))
						// console.log('SAMPLES DATA EDITED:', value)
					}}
					borehole={borehole_name}
					title="Disturbed Sample (D)"
					casing_preset={current_casing_depth}
					defaultValues={SamplesData[editingIndex]}
					numbering_type={numberingType}
					sampleType='D'
					sampled_by={leadDriller}
				/>
			}

			{showB &&
				<Samples
					onClose={() => { setShowB(false) }}
					onCompleteNumbering={(value: any) => {
						setNumberingType(value)
						saveHoleData()
					}}
					onComplete={(value: any) => saveAction(value, 'B', setShowB(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
					title="Bulk Sample (B)"
					casing_preset={current_casing_depth}
					new_type_number={numberOfSamplesB_TOTAL + 1}
					new_type_number_sequencial={numberOfSamples_TOTAL + 1}
					numbering_type={numberingType}
					sampleType='B'
					sampled_by={leadDriller}
				/>
			}

			{showBEdit &&
				<Samples
					update={true}
					onClose={() => { setShowBEdit(false) }}
					setShowBEdit={setShowBEdit}
					onCompleteNumbering={(value: any) => {
						setNumberingType(value)
						saveHoleData()
					}}
					onComplete={(value: any) => saveAction(value, 'B', setShowBEdit(false))}
					borehole={borehole_name}
					title="Bulk Sample (B)"
					casing_preset={current_casing_depth}
					defaultValues={SamplesData[editingIndex]}
					numbering_type={numberingType}
					sampleType='B'
					sampled_by={leadDriller}
				/>
			}

			{showES &&
				<Samples
					onClose={() => { setShowES(false) }}
					onCompleteNumbering={(value: any) => {
						setNumberingType(value)
						saveHoleData()
					}}
					onComplete={(value: any) => saveAction(value, 'ES', setShowES(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
					title="Environmental Sample (ES)"
					casing_preset={current_casing_depth}
					new_type_number={numberOfSamplesES_TOTAL + 1}
					new_type_number_sequencial={numberOfSamples_TOTAL + 1}
					numbering_type={numberingType}
					sampleType='ES'
					sampled_by={leadDriller}
				/>
			}

			{showESEdit &&
				<Samples
					update={true}
					onClose={() => { setShowESEdit(false) }}
					setShowESEdit={setShowESEdit}
					onCompleteNumbering={(value: any) => {
						setNumberingType(value)
						saveHoleData()
					}}
					onComplete={(value: any) => saveAction(value, 'ES', setShowESEdit(false))}
					borehole={borehole_name}
					title="Environmental Sample (ES)"
					casing_preset={current_casing_depth}
					defaultValues={SamplesData[editingIndex]}
					numbering_type={numberingType}
					sampleType='ES'
					sampled_by={leadDriller}
				/>
			}

			{showWS &&
				<Samples
					onClose={() => { setShowWS(false) }}
					onCompleteNumbering={(value: any) => {
						setNumberingType(value)
						saveHoleData()
					}}
					onComplete={(value: any) => saveAction(value, 'WS', setShowWS(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
					title="Water Sample (WS)"
					casing_preset={current_casing_depth}
					new_type_number={numberOfSamplesWS_TOTAL + 1}
					new_type_number_sequencial={numberOfSamples_TOTAL + 1}
					numbering_type={numberingType}
					sampleType='WS'
					sampled_by={leadDriller}
				/>
			}

			{showWSEdit &&
				<Samples
					update={true}
					onClose={() => { setShowWSEdit(false) }}
					setShowWSEdit={setShowWSEdit}
					onCompleteNumbering={(value: any) => {
						setNumberingType(value)
						saveHoleData()
					}}
					onComplete={(value: any) => saveAction(value, 'WS', setShowWSEdit(false))}
					borehole={borehole_name}
					title="Water Sample (WS)"
					casing_preset={current_casing_depth}
					defaultValues={SamplesData[editingIndex]}
					numbering_type={numberingType}
					sampleType='WS'
					sampled_by={leadDriller}
				/>
			}

			{showSPT &&
				<SPT
					onClose={() => { setShowSPT(false) }}
					onCompleteNumbering={(value: any) => {
						setNumberingType(value)
						saveHoleData()
					}}
					onComplete={(value: any) => {
						if (value !== addSampleData) { setAddSampleData(value) }
						if (fromType !== 'SPT') { setFromType('SPT') }
						saveAction(value, 'SPT', setShowSPT(false))
						setShowSamplesCreateD(true)
						// console.log('setAddSampleData value:', value)
					}}
					borehole={borehole_name}
					selectedDate={selectedDate}
					casing_preset={current_casing_depth}
					new_type_number={numberOfSPTs_TOTAL + 1}
					new_type_number_sequencial={numberOfSPTsCPTs_TOTAL + 1}
					numbering_type={numberingType}
					boreholeDepth={borehole_depth}
					sampled_by={leadDriller}
				/>
			}

			{showSPTEdit &&
				<SPT
					update={true}
					onClose={() => { setShowSPTEdit(false) }}
					setShowSPTEdit={setShowSPTEdit}
					onCompleteNumbering={(value: any) => {
						setNumberingType(value)
						saveHoleData()
					}}
					onComplete={(value: any) => saveAction(value, 'SPT', setShowSPTEdit(false))}
					borehole={borehole_name}
					casing_preset={current_casing_depth}
					defaultValues={InSituTestsData[editingIndex]}
					numbering_type={numberingType}
					boreholeDepth={borehole_depth}
					sampled_by={leadDriller}
				/>
			}

			{showCPT &&
				<CPT
					onClose={() => { setShowCPT(false) }}
					onCompleteNumbering={(value: any) => {
						setNumberingType(value)
						saveHoleData()
					}}
					onComplete={(value: any) => {
						// if (value !== addSampleData) { setAddSampleData(value) }
						// if (fromType !== 'CPT') { setFromType('CPT') }
						saveAction(value, 'CPT', setShowCPT(false))
						// setShowSamplesCreateB(true)
					}}
					borehole={borehole_name}
					selectedDate={selectedDate}
					casing_preset={current_casing_depth}
					new_type_number={numberOfCPTs_TOTAL + 1}
					new_type_number_sequencial={numberOfSPTsCPTs_TOTAL + 1}
					numbering_type={numberingType}
					boreholeDepth={borehole_depth}
					sampled_by={leadDriller}
				/>
			}

			{showCPTEdit &&
				<CPT
					update={true}
					onClose={() => { setShowCPTEdit(false) }}
					setShowCPTEdit={setShowCPTEdit}
					onCompleteNumbering={(value: any) => {
						setNumberingType(value)
						saveHoleData()
					}}
					onComplete={(value: any) => {
						saveAction(value, 'CPT', setShowCPTEdit(false))
					}}
					borehole={borehole_name}
					casing_preset={current_casing_depth}
					defaultValues={InSituTestsData[editingIndex]}
					numbering_type={numberingType}
					boreholeDepth={borehole_depth}
					sampled_by={leadDriller}
				/>
			}

			{showU &&
				<U
					onClose={() => { setShowU(false) }}
					// onComplete={(value: any) => saveAction(value, 'U', setShowU(false))}
					onComplete={(value: any) => {
						if (value !== addSampleData) { setAddSampleData(value) }
						if (fromType !== 'U') { setFromType('U') }
						saveAction(value, 'U', setShowU(false))
						setShowSamplesCreateD(true)
					}}
					borehole={borehole_name}
					selectedDate={selectedDate}
					utype='100'
					casing_preset={current_casing_depth}
					new_type_number={numberOfUSamples_TOTAL + 1}
					boreholeDepth={borehole_depth}
					sampled_by={leadDriller}
				/>
			}

			{showUEdit &&
				<U
					update={true}
					onClose={() => { setShowUEdit(false) }}
					setShowUEdit={setShowUEdit}
					onComplete={(value: any) => {
						saveAction(value, 'U', setShowUEdit(false))
					}}
					borehole={borehole_name}
					casing_preset={current_casing_depth}
					utype='100'
					defaultValues={InSituTestsData[editingIndex]}
					boreholeDepth={borehole_depth}
					sampled_by={leadDriller}
				/>
			}

			{showPS &&
				<PS
					onClose={() => { setShowPS(false) }}
					onComplete={(value: any) => {
						saveAction(value, 'PS', setShowPS(false))
					}}
					borehole={borehole_name}
					selectedDate={selectedDate}
					casing_preset={current_casing_depth}
					new_type_number={numberOfPSSamples_TOTAL + 1}
					boreholeDepth={borehole_depth}
					sampled_by={leadDriller}
				/>
			}

			{showPSEdit &&
				<PS
					update={true}
					onClose={() => { setShowPSEdit(false) }}
					setShowPSEdit={setShowPSEdit}
					onComplete={(value: any) => {
						saveAction(value, 'PS', setShowPSEdit(false))
					}}
					borehole={borehole_name}
					casing_preset={current_casing_depth}
					defaultValues={InSituTestsData[editingIndex]}
					boreholeDepth={borehole_depth}
					sampled_by={leadDriller}
				/>
			}

			{showSV &&
				<SV
					onClose={() => { setShowSV(false) }}
					onComplete={(value: any) => {
						saveAction(value, 'SV', setShowSV(false))
					}}
					borehole={borehole_name}
					selectedDate={selectedDate}
					new_type_number={numberOfShearVane_TOTAL + 1}
					boreholeDepth={borehole_depth}
				/>
			}

			{showSVEdit &&
				<SV
					update={true}
					onClose={() => { setShowSVEdit(false) }}
					setShowSVEdit={setShowSVEdit}
					onComplete={(value: any) => {
						saveAction(value, 'SV', setShowSVEdit(false))
					}}
					borehole={borehole_name}
					defaultValues={InSituTestsData[editingIndex]}
					boreholeDepth={borehole_depth}
				/>
			}

			{showSoilDescription &&
				<SoilDescription
					onClose={() => { setShowSoilDescription(false) }}
					onComplete={(value: any) => {
						// console.log('SoilDescription Value on Complete:', value)
						saveAction(value, 'SoilDescription', setShowSoilDescription(false))
					}}
					borehole={borehole_name}
					selectedDate={selectedDate}
					lastSoilDescriptionDepth={last_soil_description_depth_to}
					lastSoilDescriptionDepthToTop={last_soil_description_depth_from}
					lastSoilDescriptionDepthToBase={last_soil_description_depth_to}
					lastSoilDescriptionStratum={last_soil_description_depth_text}
				/>
			}

			{showSoilDescriptionEdit &&
				<SoilDescriptionEditor
					update={true}
					onClose={() => { setShowSoilDescriptionEdit(false) }}
					setShowSoilDescriptionEdit={setShowSoilDescriptionEdit}
					onComplete={(value: any) => saveAction(value, 'SoilDescription', setShowSoilDescriptionEdit(false))}
					borehole={borehole_name}
					// defaultValues={defaultValues}
					defaultValues={SoilDescriptionData[editingIndex]}
				/>
			}

			{showGroundWater &&
				<GroundWater
					onClose={() => { setShowGroundWater(false) }}
					// onComplete={(value: any) => saveAction(value, 'GroundWater', setShowGroundWater(false))}
					onComplete={(value: any) => {
						// if (value !== addSampleData) { setAddSampleData(value) }
						// if (fromType !== 'GroundWater') { setFromType('GroundWater') }
						saveAction(value, 'GroundWater', setShowGroundWater(false))
						// setShowSamplesCreateWS(true)
						// console.log('GroundWater values:', value)
					}}
					borehole={borehole_name}
					selectedDate={selectedDate}
					casing_preset={current_casing_depth}
				/>
			}

			{showGroundWaterEdit &&
				<GroundWater
					update={true}
					onClose={() => { setShowGroundWaterEdit(false) }}
					setShowGroundWaterEdit={setShowGroundWaterEdit}
					onComplete={(value: any) => saveAction(value, 'GroundWater', setShowGroundWaterEdit(false))}
					borehole={borehole_name}
					defaultValues={GroundWaterTestsData[editingIndex]}
					selectedDate={selectedDate}
				/>
			}

			{showInstall &&
				<Install
					onClose={() => { setShowInstall(false) }}
					onComplete={(value: any) => {
						saveAction(value, 'Installation', setShowInstall(false))
						setShowBackfill(true)
						// console.log('Installation values:', value)
					}}
					borehole={borehole_name}
					selectedDate={selectedDate}
					holeDepth={borehole_depth}
					pipe1solid={pipe1solid}
					pipe1slotted={pipe1slotted}
					pipe2solid={pipe2solid}
					pipe2slotted={pipe2slotted}
				/>
			}

			{showInstallEdit &&
				<Install
					update={true}
					onClose={() => { setShowInstallEdit(false) }}
					setShowInstallEdit={setShowInstallEdit}
					onComplete={(value: any) => {
						saveAction(value, 'Installation', setShowInstallEdit(false))
						// console.log('Installation values:', value)
					}}
					borehole={borehole_name}
					holeDepth={borehole_depth}
					pipe1solid={pipe1solid}
					pipe1slotted={pipe1slotted}
					pipe2solid={pipe2solid}
					pipe2slotted={pipe2slotted}
					defaultValues={InstallationData[editingIndex]}
				/>
			}

			{showBackfill &&
				<Backfill
					onClose={() => { setShowBackfill(false) }}
					onComplete={(value: any) => {
						saveAction(value, 'Backfill', setShowBackfill(false))
						// console.log('Backfill values:', value)
						// Call the function to update backfillText
						// updateBackfillText(value)
					}}
					borehole={borehole_name}
					selectedDate={selectedDate}
					holeDepth={borehole_depth}
					pipe1solid={pipe1solid}
					pipe1slotted={pipe1slotted}
					pipe2solid={pipe2solid}
					pipe2slotted={pipe2slotted}
				/>
			}

			{showBackfillEdit &&
				<Backfill
					update={true}
					onClose={() => { setShowBackfillEdit(false) }}
					setShowBackfillEdit={setShowBackfillEdit}
					onComplete={(value: any) => {
						saveAction(value, 'Backfill', setShowBackfillEdit(false))
						// Call the function to update backfillText
						// updateBackfillText(value)
					}}
					borehole={borehole_name}
					holeDepth={borehole_depth}
					pipe1solid={pipe1solid}
					pipe1slotted={pipe1slotted}
					pipe2solid={pipe2solid}
					pipe2slotted={pipe2slotted}
					defaultValues={BackfillData[editingIndex]}
				/>
			}

			{showInspectionPit &&
				<InspectionPit
					onClose={() => { setShowInspectionPit(false) }}
					onComplete={(value: any) => saveAction(value, 'InspectionPit', setShowInspectionPit(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
				/>
			}

			{showInspectionPitEdit &&
				<InspectionPit
					update={true}
					onClose={() => { setShowInspectionPitEdit(false) }}
					setShowInspectionPitEdit={setShowInspectionPitEdit}
					onComplete={(value: any) => saveAction(value, 'InspectionPit', setShowInspectionPitEdit(false))}
					borehole={borehole_name}
					defaultValues={InspectionPitData[editingIndex]}
				/>
			}

			{showCasingRecord &&
				<CasingRecord
					onClose={() => { setShowCasingRecord(false) }}
					onComplete={(value: any) => saveAction(value, 'Casing', setShowCasingRecord(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
					holeDepth={borehole_depth}
				/>
			}

			{showCasingRecordEdit &&
				<CasingRecord
					update={true}
					onClose={() => { setShowCasingRecordEdit(false) }}
					setShowCasingRecordEdit={setShowCasingRecordEdit}
					onComplete={(value: any) => saveAction(value, 'Casing', setShowCasingRecordEdit(false))}
					borehole={borehole_name}
					holeDepth={borehole_depth}
					defaultValues={CasingData[editingIndex]}
				/>
			}

			{showChiselling &&
				<Chiselling
					onClose={() => { setShowChiselling(false) }}
					onComplete={(value: any) => saveAction(value, 'Chiselling', setShowChiselling(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
				/>
			}

			{showChisellingEdit &&
				<Chiselling
					update={true}
					onClose={() => { setShowChisellingEdit(false) }}
					setShowChisellingEdit={setShowChisellingEdit}
					onComplete={(value: any) => saveAction(value, 'Chiselling', setShowChisellingEdit(false))}
					borehole={borehole_name}
					defaultValues={ChisellingData[editingIndex]}
				/>
			}

			{showWaterAdded &&
				<WaterAdded
					onClose={() => { setShowWaterAdded(false) }}
					onComplete={(value: any) => saveAction(value, 'WaterAdded', setShowWaterAdded(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
				/>
			}

			{showWaterAddedEdit &&
				<WaterAdded
					update={true}
					onClose={() => { setShowWaterAddedEdit(false) }}
					setShowWaterAddedEdit={setShowWaterAddedEdit}
					onComplete={(value: any) => saveAction(value, 'WaterAdded', setShowWaterAddedEdit(false))}
					borehole={borehole_name}
					defaultValues={WaterAddedData[editingIndex]}
				/>
			}

			{showDailyWaterLevel &&
				<DailyWaterLevel
					onClose={() => { setShowDailyWaterLevel(false) }}
					onComplete={(value: any) => saveAction(value, 'DailyWaterLevel', setShowDailyWaterLevel(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
				/>
			}

			{showDailyWaterLevelEdit &&
				<DailyWaterLevel
					update={true}
					onClose={() => { setShowDailyWaterLevelEdit(false) }}
					setShowDailyWaterLevelEdit={setShowDailyWaterLevelEdit}
					onComplete={(value: any) => saveAction(value, 'DailyWaterLevel', setShowDailyWaterLevelEdit(false))}
					borehole={borehole_name}
					defaultValues={DailyWaterLevelData[editingIndex]}
				/>
			}

			{showReDrill &&
				<ReDrill
					onClose={() => { setShowReDrill(false) }}
					onComplete={(value: any) => saveAction(value, 'ReDrill', setShowReDrill(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
				/>
			}

			{showReDrillEdit &&
				<ReDrill
					update={true}
					onClose={() => { setShowReDrillEdit(false) }}
					setShowReDrillEdit={setShowReDrillEdit}
					onComplete={(value: any) => saveAction(value, 'ReDrill', setShowReDrillEdit(false))}
					borehole={borehole_name}
					defaultValues={ReDrillData[editingIndex]}
				/>
			}

			{showComments &&
				<Comments
					onClose={() => { setShowComments(false) }}
					onComplete={(value: any) => saveAction(value, 'Comments', setShowComments(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
				/>
			}

			{showCommentsEdit &&
				<Comments
					update={true}
					onClose={() => { setShowCommentsEdit(false) }}
					setShowCommentsEdit={setShowCommentsEdit}
					onComplete={(value: any) => saveAction(value, 'Comments', setShowCommentsEdit(false))}
					borehole={borehole_name}
					defaultValues={CommentsData[editingIndex]}
				/>
			}

			{showDriveRecords &&
				<DriveRecords
					onClose={() => { setShowDriveRecords(false) }}
					onComplete={(value: any) => saveAction(value, 'DriveRecords', setShowDriveRecords(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
					lastDriveRecord={depthDriveRecord}
					holeDepth={borehole_depth_drive_record}
					new_type_number={numberOfDriveRecords + 1}
					sampled_by={leadDriller}
				/>
			}

			{showDriveRecordsEdit &&
				<DriveRecords
					update={true}
					onClose={() => { setShowDriveRecordsEdit(false) }}
					setShowDriveRecordsEdit={setShowDriveRecordsEdit}
					onComplete={(value: any) => {
						// console.log('DriveRecords on EDIT', value)
						saveAction(value, 'DriveRecords', setShowDriveRecordsEdit(false))
					}}
					borehole={borehole_name}
					holeDepth={borehole_depth_drive_record}
					lastDriveRecord={depthDriveRecord}
					defaultValues={DriveRecordData[editingIndex]}
					sampled_by={leadDriller}
				/>
			}















			{/* //....................................................................................................................................
			//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNN...NNNN..UUUU...UUUU.......PPPPPPPPP.....AAAA.....AANNN...NNNN.NNEEEEEEEEE.EELL.........SSSSSSS....
			//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNNN..NNNN..UUUU...UUUU.......PPPPPPPPPP...AAAAAA....AANNN...NNNN.NNEEEEEEEEE.EELL........LSSSSSSSS...
			//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNNN..NNNN..UUUU...UUUU.......PPPPPPPPPPP..AAAAAA....AANNNN..NNNN.NNEEEEEEEEE.EELL.......LLSSSSSSSSS..
			//.MMMMMMM.MMMMMMM.EEEE........NNNNNN.NNNN..UUUU...UUUU.......PPPP...PPPP..AAAAAAA...AANNNNN.NNNN.NNEE........EELL.......LLSS...SSSS..
			//.MMMMMMM.MMMMMMM.EEEE........NNNNNN.NNNN..UUUU...UUUU.......PPPP...PPPP.AAAAAAAA...AANNNNN.NNNN.NNEE........EELL.......LLSSS........
			//.MMMMMMM.MMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU.......PPPPPPPPPPP.AAAAAAAA...AANNNNNNNNNN.NNEEEEEEEEE.EELL........LSSSSSS.....
			//.MMMMMMMMMMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU.......PPPPPPPPPP..AAAA.AAAA..AANNNNNNNNNN.NNEEEEEEEEE.EELL.........SSSSSSSS...
			//.MMMMMMMMMMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU.......PPPPPPPPP..PAAAAAAAAA..AANN.NNNNNNN.NNEEEEEEEEE.EELL...........SSSSSSS..
			//.MMMMMMMMMMMMMMM.EEEE........NNNNNNNNNNN..UUUU...UUUU.......PPPP.......PAAAAAAAAAA.AANN.NNNNNNN.NNEE........EELL..............SSSS..
			//.MMMM.MMMMM.MMMM.EEEE........NNNN.NNNNNN..UUUU...UUUU.......PPPP......PPAAAAAAAAAA.AANN..NNNNNN.NNEE........EELL.......LLSS...SSSS..
			//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN..NNNNN..UUUUUUUUUUU.......PPPP......PPAA....AAAA.AANN..NNNNNN.NNEEEEEEEEEEEELLLLLLLLLLLSSSSSSSSS..
			//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN..NNNNN...UUUUUUUUU........PPPP......PPAA....AAAAAAANN...NNNNN.NNEEEEEEEEEEEELLLLLLLLL.LSSSSSSSSS..
			//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN...NNNN....UUUUUUU.........PPPP.....PPPAA.....AAAAAANN....NNNN.NNEEEEEEEEEEEELLLLLLLLL..SSSSSSS....
			//.................................................................................................................................... */}

			{showNewHole &&
				<NewHole onClose={() => { setShowNewHole(false) }}
					holeType="CP"
					defaultValues={{
						// company_guid: project.company_guid,
						// project_guid: project_guid,
					}}
				/>
			}

			{showEmail &&
				<Email onClose={() => { setShowEmail(false) }}
					company_id={companyID}
					project_location={project_location}
					project_id={projectID}
					project_guid={project_guid}
					selected_date={selectedDate}
				/>
			}

			{showAGS &&
				<AGS onClose={() => { setShowAGS(false) }}
					project_start_date={project_start_date}
					project_id={project_id}
					company_id={companyID}
				/>
			}

			{showSaveLog &&
				<SaveLog onClose={() => { setShowSaveLog(false) }}
					boreholeName={borehole_name}
					holeType="WS"
					project_id={project_id}
					project_guid={project_guid}
					project_name={project_name}
					project_location={project_location}
					hole_type_id={starting_hole_type_id}
					driller_id={leadDrillerID}
					company_id={companyID}
					company_guid={company_guid}
					logDate={sheetDate}
					activeDates={active_dates}

					boreholeDepth={borehole_depth}
					hole_guid={hole_guid}
					endShift={false}
					holeStatus={hole_status}
				/>
			}

			{showPrintLabel &&
				<PrintLabel onClose={() => { setShowPrintLabel(false) }}
					borehole={borehole_name}
					sample={labelSample}
					depth={labelDepth}
					size='printLabel'
					sampled_by={leadDriller}
				/>
			}


			{userWarning &&
				<ModalSmall title="Log Information" toggleModal={() => { setUserWarning(false) }} confirm={false}>
					<div className="flex items-center flex-col pt-16">
						<div className="text-lg">
							This log belongs to:
						</div>
						<div className="text-3xl font-bold">
							{leadDriller}
						</div>
						<div className="text-lg mb-8">
							{randomNegPhrases}
						</div>

						<div className="flex">
							<Button theme="primary" size="lg" className="mb-16" onClick={() => { setUserWarning(false) }}>OK</Button>
						</div>
					</div>
				</ModalSmall>
			}

			{plantCheckWarning &&
				<ModalSmall title="Plant Check" toggleModal={() => { setPlantCheckWarning(false) }} confirm={false}>
					<div className="flex items-center flex-col pt-4">

						{/* <div className="bg-grey-vdark w-full py-1 mb-4">
							<img src={icon_plantcheck} className="h-12 w-12 mx-auto" alt="" />
						</div> */}
						<div className="text-lg mb-4">
							<img src={warningIcon} className="h-12 w-12 mx-auto" alt="" /> You have not performed a Plant Check for this day!
						</div>

						<div className="bg-blue w-full py-2 mb-4 text-center text-lg font-bold text-white mb-8">
							<Link to={`/daily-plant-check-list-window-sample/${company_guid}/${project_guid}`}>
								Click here for Daily Plant Check
							</Link>
						</div>

						{/* <div className="flex">
							<Button theme="primary" size="lg" className="mb-16" onClick={() => { setPlantCheckWarning(false) }}>OK</Button>
						</div> */}
					</div>
				</ModalSmall>
			}

			{/* //.................................................................................................
				//.EEEEEEEEEEE.ENNN...NNNN..NDDDDDDDD..........SSSSSSS....SHHH...HHHH.HHIII.IFFFFFFFFF.FTTTTTTTTT..
				//.EEEEEEEEEEE.ENNNN..NNNN..NDDDDDDDDD........ SSSSSSSS...SHHH...HHHH.HHIII.IFFFFFFFFF.FTTTTTTTTT..
				//.EEEEEEEEEEE.ENNNN..NNNN..NDDDDDDDDDD....... SSSSSSSSS..SHHH...HHHH.HHIII.IFFFFFFFFF.FTTTTTTTTT..
				//.EEEE........ENNNNN.NNNN..NDDD...DDDD...... SSS..SSSS..SHHH...HHHH.HHIII.IFFF..........TTTT.....
				//.EEEE........ENNNNN.NNNN..NDDD....DDDD..... SSS........SHHH...HHHH.HHIII.IFFF..........TTTT.....
				//.EEEEEEEEEE..ENNNNNNNNNN..NDDD....DDDD...... SSSSSS.....SHHHHHHHHHH.HHIII.IFFFFFFFF.....TTTT.....
				//.EEEEEEEEEE..ENNNNNNNNNN..NDDD....DDDD.......SSSSSSSSS..SHHHHHHHHHH.HHIII.IFFFFFFFF.....TTTT.....
				//.EEEEEEEEEE..ENNNNNNNNNN..NDDD....DDDD.........SSSSSSS..SHHHHHHHHHH.HHIII.IFFFFFFFF.....TTTT.....
				//.EEEE........ENNNNNNNNNN..NDDD....DDDD............SSSSS.SHHH...HHHH.HHIII.IFFF..........TTTT.....
				//.EEEE........ENNN.NNNNNN..NDDD...DDDDD..... SS....SSSS.SHHH...HHHH.HHIII.IFFF..........TTTT.....
				//.EEEEEEEEEEE.ENNN..NNNNN..NDDDDDDDDDD...... SSSSSSSSSS.SHHH...HHHH.HHIII.IFFF..........TTTT.....
				//.EEEEEEEEEEE.ENNN..NNNNN..NDDDDDDDDD........ SSSSSSSSS..SHHH...HHHH.HHIII.IFFF..........TTTT.....
				//.EEEEEEEEEEE.ENNN...NNNN..NDDDDDDDD..........SSSSSSSS...SHHH...HHHH.HHIII.IFFF..........TTTT.....
				//................................................................................................. */}
	
				{showEndShiftSaveLog &&
					<SaveLog onClose={() => { 
							setShowEndShiftSaveLog(false) 
							setShowEndShiftAGS(true) 
						}}
						boreholeName={boreholeName}
						holeType="WS"
						project_id={project_id}
						project_guid={project_guid}
						project_name={project_name}
						project_location={project_location}
						hole_type_id={starting_hole_type_id}
						driller_id={leadDrillerID}
						company_id={companyID}
						logDate={sheetDate}
						activeDates={active_dates}
	
						boreholeDepth={borehole_depth}
						hole_guid={hole_guid}
						endShift={true}
					/>
				}
	
				{showEndShiftAGS &&
					<AGS onClose={() => { 
							setShowEndShiftAGS(false)  
							setShowEndShiftEmail(true) 
						}}
						project_start_date={project_start_date}
						project_id={project_id}
						company_id={companyID}
					/>
				}
	
				{showEndShiftEmail &&
					<Email onClose={() => { 
							setShowEndShiftEmail(false) 
							setShowEndShiftLogout(true) 
						}}
						company_id={companyID}
						project_location={project_location}
						project_id={projectID}
						project_guid={project_guid}
						selected_date={selectedDate}
					/>
				}
	
				{showEndShiftLogout &&
					<ModalSmall title="Log Out" toggleModal={() => { setShowEndShiftLogout(false) }} confirm={false}>
						<div className="flex items-center flex-col pt-10">
							
							<Link to={`/logout`}>
								<div className="bg-blue rounded w-48 p-2 mb-10 text-center text-white font-bold text-lg">
									<img src={icon_endshift} className="h-12 w-12 mx-auto mb-1" alt="" />
									Log Out
								</div>
							</Link>
						</div>
					</ModalSmall>
				}










			{/* //....................................................................................
			//....OOOOOOO.....PPPPPPPPP...TTTTTTTTTTTIIII...OOOOOOO.....NNNN...NNNN...SSSSSSS.....
			//...OOOOOOOOOO...PPPPPPPPPP..TTTTTTTTTTTIIII..OOOOOOOOOO...NNNNN..NNNN..SSSSSSSSS....
			//..OOOOOOOOOOOO..PPPPPPPPPPP.TTTTTTTTTTTIIII.OOOOOOOOOOOO..NNNNN..NNNN..SSSSSSSSSS...
			//..OOOOO..OOOOO..PPPP...PPPP....TTTT...TIIII.OOOOO..OOOOO..NNNNNN.NNNN.NSSSS..SSSS...
			//.OOOOO....OOOOO.PPPP...PPPP....TTTT...TIIIIIOOOO....OOOOO.NNNNNN.NNNN.NSSSS.........
			//.OOOO......OOOO.PPPPPPPPPPP....TTTT...TIIIIIOOO......OOOO.NNNNNNNNNNN..SSSSSSS......
			//.OOOO......OOOO.PPPPPPPPPP.....TTTT...TIIIIIOOO......OOOO.NNNNNNNNNNN...SSSSSSSSS...
			//.OOOO......OOOO.PPPPPPPPP......TTTT...TIIIIIOOO......OOOO.NNNNNNNNNNN.....SSSSSSS...
			//.OOOOO....OOOOO.PPPP...........TTTT...TIIIIIOOOO....OOOOO.NNNNNNNNNNN........SSSSS..
			//..OOOOO..OOOOO..PPPP...........TTTT...TIIII.OOOOO..OOOOO..NNNN.NNNNNN.NSSS....SSSS..
			//..OOOOOOOOOOOO..PPPP...........TTTT...TIIII.OOOOOOOOOOOO..NNNN..NNNNN.NSSSSSSSSSSS..
			//...OOOOOOOOOO...PPPP...........TTTT...TIIII..OOOOOOOOOO...NNNN..NNNNN..SSSSSSSSSS...
			//.....OOOOOO.....PPPP...........TTTT...TIIII....OOOOOO.....NNNN...NNNN...SSSSSSSS....
			//.................................................................................... */}

			<Container>

				<ProjectOptionsBorehole
					onMarkAsComplete={() => { setCompletingBorehole(true) }}
					company_guid={company_guid}
					companyID={companyID}
					project_guid={project_guid}
					project_id={project_id}
					project_identifier={projectID}
					boreholeName={boreholeName}
					borehole_guid={borehole_guid}
					boreholeType="WS"
					boreholeDepth={borehole_depth}
					boreholeRequiredText=""
					// boreholeRequiredDepth={this.state.borehole.depth}
					boreholeRequiredDepth=""
					// boreholeRequiredTests={this.state.borehole.samplesTests}
					boreholeRequiredTests=""
					editWeatherLink={'/projects/weather/' + project_guid}
					rig_guid={savedRigGuid}
					driller={leadDriller}
					driller_id={leadDrillerID}
					driller_cscs={leadDrillerCSCS}
					second_man={secondMan}
					second_man_cscs={secondManCSCS}
					rig_name={plantName}
					rig_make_model={plantMakeModel}
					spt_guid={sptGUID}
					borehole_depthOverall={borehole_depthOverall}
					activity_termination_reason={activityTerminationReason}
					className="mt-1 mb-1 py-1"
					getHoleData={getHoleData}
					plantCheck={selectedDate_PlantCheck}
					holeStatus={hole_status}
					numberingType={numberingType}
				/>

				{/* {!informationCheck && (
					<div className="bg-yellow w-36 md:w-64 text-center text-xs font-bold mx-auto -mb-1 -mt-4 rounded-t">
						<Link to={`/rigs/${plantID}/${company_guid}/${project_guid}`}>
							<img src={warningIcon} width="12" className="ml-1 inline-block pb-0.5" alt="" />&nbsp;
							Rig Not Selected
						</Link>
					</div>
				)} */}


				{/* <div className="w-full py-2 bg-grey-vdark text-center mt-1 mb-0 text-sm text-white rounded-md">
					<div onClick={() => setBhMenuVisible(!bhMenuVisible)} ref={popupRef}>
						Borehole Menu <img src={menubars} className="h-3 w-3 mb-0.5 inline" alt="" />
					</div>
				</div> */}


				<div className="flex w-full py-2 bg-grey-vdark text-center mt-1 mb-0 text-sm text-white rounded-md">
					<div className="flex-none ml-0 w-6 pl-3 pt-0.5">
						<img onClick={handleReloadClick} src={icon_reload} className="ml-0 w-3.5 h-3.5" alt="" />
					</div>
					<div onClick={() => {
						setBhMenuVisible(!bhMenuVisible)
						// saveHoleData()
						}} 
						ref={popupRef} className="flex-1">
						Borehole Menu <img src={menubars} className="h-3 w-3 mb-0.5 inline" alt="" />
					</div>
					<div className="flex-none mr-0 w-6">

					</div>
				</div>


				<div className="relative"> {/* menu container */}

					<div className="grid lg:grid-cols-5 lg:gap-6 mt-2">
						<div className="col-span-5 lg:col-span-3 mt-2">





















							{/* LEFT SIDE PANELS GO HERE */}

							{/*//.......................................................
							 //...SSSSSSS.......AAAAA....AMMMMM...MMMMMM.PPPPPPPPP....
							//..SSSSSSSSS......AAAAA....AMMMMM...MMMMMM.PPPPPPPPPP...
							//..SSSSSSSSSS....AAAAAA....AMMMMM...MMMMMM.PPPPPPPPPPP..
							//.SSSSS..SSSS....AAAAAAA...AMMMMMM.MMMMMMM.PPPP...PPPP..
							//.SSSSS.........AAAAAAAA...AMMMMMM.MMMMMMM.PPPP...PPPP..
							//..SSSSSSS......AAAAAAAA...AMMMMMM.MMMMMMM.PPPPPPPPPPP..
							//...SSSSSSSSS...AAAA.AAAA..AMMMMMMMMMMMMMM.PPPPPPPPPP...
							//.....SSSSSSS..AAAAAAAAAA..AMMMMMMMMMMMMMM.PPPPPPPPP....
							//........SSSSS.AAAAAAAAAAA.AMMMMMMMMMMMMMM.PPPP.........
							//.SSSS....SSSS.AAAAAAAAAAA.AMMM.MMMMM.MMMM.PPPP.........
							//.SSSSSSSSSSSSSAAA....AAAA.AMMM.MMMMM.MMMM.PPPP.........
							//..SSSSSSSSSS.SAAA.....AAAAAMMM.MMMMM.MMMM.PPPP.........
							//...SSSSSSSS.SSAAA.....AAAAAMMM.MMMMM.MMMM.PPPP.........
							//....................................................... */}

							<Panel title="Samples" numberOfElements={numberOfSamples} options={[
								{
									name: 'D',
									// onClick: () => { setShowD(true) }
									onClick: () => {panel_plantCheck(setShowD)}
								}, {
									name: 'B',
									// onClick: () => { setShowB(true) }
									onClick: () => {panel_plantCheck(setShowB)}
								}, {
									name: 'ES',
									// onClick: () => { setShowES(true) }
									onClick: () => {panel_plantCheck(setShowES)}
								}, {
									name: 'WS',
									// onClick: () => { setShowWS(true) }
									onClick: () => {panel_plantCheck(setShowWS)}
								}

							]}>
								<table className="table-auto w-full text-xs sm:text-sm text-center border-collapse overflow-hidden rounded-t-md">
									<thead className="font-bold">
										<tr>
											<td className={`px-1 sm:px-1 py-0 bg-grey-mid border-grey-mid rounded-tl-sm rounded-tr-sm border-l border-r border-grey-light`}>Type</td>
											<td className={`px-1 sm:px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>From (m)</td>
											<td className={`px-1 sm:px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>To (m)</td>
											<td className={`px-1 sm:px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>Casing</td>
											<td className={`px-1 sm:px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>Water (m)</td>
											<td className={`px-1 sm:px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light text-left`}>Notes</td>
											<td className={`px-1 sm:px-1 py-0 bg-grey-mid border-grey-mid rounded-tr-sm border-r border-grey-light`}></td>
										</tr>
									</thead>
									<tbody>

										{SamplesData?.filter((action: any) => {
											return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
										}).map((valueSamples: any, index: number) => {

											// Replace depth_to_top = 0 with GL
											let depth_to_top_temp, depth_to_base_temp, casing_depth_temp, water_depth_temp
											if (valueSamples.depth_to_top !== undefined && typeof valueSamples.depth_to_top === 'string' && valueSamples.depth_to_top === '0.00' || valueSamples.depth_to_top === '0') {
												depth_to_top_temp = 'GL'
											} else if (valueSamples.depth_to_top !== undefined && typeof valueSamples.depth_to_top === 'number' && valueSamples.depth_to_top === 0.00 || valueSamples.depth_to_top === 0) {
												depth_to_top_temp = 'GL'
											} else {
												depth_to_top_temp = valueSamples.depth_to_top
											}

											//Get actualIndex
											const actualIndex = SamplesData.map((object: any) => object.guid).indexOf(valueSamples.guid)

											return (
												<React.Fragment key={index}>

													<tr className="border border-grey-mid bg-white mb-1">
														<td className={`px-1 sm:px-1 pt-0.5 border-r border-grey-mid font-bold`}>{valueSamples.type}-{valueSamples.type_number}</td>
														<td className={`px-1 sm:px-1 pt-0.5 border-r border-grey-mid`}>{depth_to_top_temp}</td>
														<td className={`px-1 sm:px-1 pt-0.5 border-r border-grey-mid`}>{valueSamples.depth_to_base}</td>
														<td className={`px-1 sm:px-1 pt-0.5 border-r border-grey-mid`}>{valueSamples.casing_depth}</td>
														<td className={`px-1 sm:px-1 pt-0.5 border-r border-grey-mid`}>{valueSamples.water_depth}</td>
														<td className={`px-1 sm:px-1 pt-0.5 border-r border-grey-mid text-left`}>{valueSamples.general_remarks}</td>
														<td className={`px-1 sm:px-1 py-0 border-r border-grey-mid w-8 md:w-12`}>
															<div className={"text-center px-1 sm:px-0 pb-0.5 edit-background"}
															onClick={() => {
																setEditingGuid(valueSamples.guid)
																setEditingIndex(actualIndex)

																switch (valueSamples.type) {
																	case 'D':
																		setShowDEdit(true)
																		// console.log('Sample Edit:', actualIndex, valueSamples.guid, editingGuid, editingIndex)
																		break
																	case 'B':
																		setShowBEdit(true)
																		break
																	case 'WS':
																		setShowWSEdit(true)
																		break
																	case 'ES':
																		setShowESEdit(true)
																		break
																}
															}}
																>
																<img className="inline z-10" width={"16"} src={edit} alt="" />
															</div>
														</td>
													</tr>
												</React.Fragment>
											)
										})}

									</tbody>
								</table>
							</Panel>














							{/* //...........................................
							//.IIIII..SSSSSSS....PPPPPPPPP...TTTTTTTTTT..
							//.IIIII.SSSSSSSSS...PPPPPPPPPP..TTTTTTTTTT..
							//.IIIII.SSSSSSSSSS..PPPPPPPPPPP.TTTTTTTTTT..
							//.IIIIIISSSS..SSSS..PPPP...PPPP....TTTT.....
							//.IIIIIISSSS........PPPP...PPPP....TTTT.....
							//.IIIII.SSSSSSS.....PPPPPPPPPPP....TTTT.....
							//.IIIII..SSSSSSSSS..PPPPPPPPPP.....TTTT.....
							//.IIIII....SSSSSSS..PPPPPPPPP......TTTT.....
							//.IIIII.......SSSSS.PPPP...........TTTT.....
							//.IIIIIISSS....SSSS.PPPP...........TTTT.....
							//.IIIIIISSSSSSSSSSS.PPPP...........TTTT.....
							//.IIIII.SSSSSSSSSS..PPPP...........TTTT.....
							//.IIIII..SSSSSSSS...PPPP...........TTTT.....
							//........................................... */}

							<Panel title="In Situ Tests" numberOfElements={numberOfInsituTests} options={[
								{
									name: 'SPT',
									// onClick: () => { setShowSPT(true) }
									onClick: () => {panel_plantCheck(setShowSPT)}
								}, {
									name: 'CPT',
									// onClick: () => { setShowCPT(true) }
									onClick: () => {panel_plantCheck(setShowCPT)}
								}, {
									name: 'U',
									// onClick: () => { setShowU(true) }
									onClick: () => {panel_plantCheck(setShowU)}
								}, {
									name: 'PS',
									// onClick: () => { setShowPS(true) }
									onClick: () => {panel_plantCheck(setShowPS)}
								}, {
									name: 'SV',
									// onClick: () => { setShowSV(true) }
									onClick: () => {panel_plantCheck(setShowSV)}
								}
							]}
							>
								<table className="table-auto w-full text-xs sm:text-sm text-center border-collapse overflow-hidden rounded-t-md">
									<thead className="font-bold">
										<tr>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid rounded-tl-sm rounded-tr-sm border-l  border-r border-grey-light`}>Type</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>Fr (m)</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>To (m)</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>Cas (m)</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>Wat (m)</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`} colSpan={2}>Seat Blows</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`} colSpan={4}>Test Drives</td>
										</tr>
									</thead>

									<tbody>

										{InSituTestsData?.filter((action: any) => {
											return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
										}).map((valueInSituTest: any, index: number) => {

											let depth_of_test_temp, depth_to_base_temp, casing_depth_temp, water_depth_temp
											if (valueInSituTest.type === 'SV') {
												// Replace depth_of_test = 0 with GL
												if (valueInSituTest.depth_to_top !== undefined &&
													valueInSituTest.depth_to_top === '0.00' || valueInSituTest.depth_to_top === '0' || valueInSituTest.depth_to_top === 0
												) {
													depth_of_test_temp = 'GL'
												} else {
													depth_of_test_temp = valueInSituTest.depth_to_top
												}
											} else {
												// Replace depth_of_test = 0 with GL
												if (valueInSituTest.depth_to_top !== undefined &&
													valueInSituTest.depth_to_top === '0.00' || valueInSituTest.depth_to_top === '0' || valueInSituTest.depth_to_top === 0
												) {
													depth_of_test_temp = 'GL'
												} else {
													depth_of_test_temp = valueInSituTest.depth_to_top
												}
											}

											if (valueInSituTest.test_blows_1st_inc === '') { var blows1 = 0 } else { blows1 = valueInSituTest.test_blows_1st_inc }
											if (valueInSituTest.test_blows_2nd_inc === '') { var blows2 = 0 } else { blows2 = valueInSituTest.test_blows_2nd_inc }
											if (valueInSituTest.test_blows_3rd_inc === '') { var blows3 = 0 } else { blows3 = valueInSituTest.test_blows_3rd_inc }
											if (valueInSituTest.test_blows_4th_inc === '') { var blows4 = 0 } else { blows4 = valueInSituTest.test_blows_4th_inc }

											const totalBlows = (+blows1 + +blows2 + +blows3 + +blows4)

											let refusal_text = ''
											if (valueInSituTest.mm_overSB !== '' && valueInSituTest.mm_over === '') {
												refusal_text = ' - ' + valueInSituTest.mm_overSB + 'mm refusal depth'
											}
											if (valueInSituTest.mm_overSB === '' && valueInSituTest.mm_over !== '') {
												refusal_text = ' - ' + valueInSituTest.mm_over + 'mm refusal depth'
											}
											if (valueInSituTest.mm_overSB !== '' && valueInSituTest.mm_over !== '') {
												refusal_text = ' - ' + valueInSituTest.mm_overSB + '/' + valueInSituTest.mm_over + 'mm refusal depth'
											}

											let svPrefix = 'Ave: '
											let svSuffix = 'kPa'
											if (valueInSituTest.average === 'Not suitable for testing') { svPrefix = ''; svSuffix = '' }

											let casing = ''
											if (valueInSituTest.casing_depth === 'no casing') {
												casing = '-'
											} else {
												casing = valueInSituTest.casing_depth
											}

											// SPT & CPT reported_result

											let reportedResult = valueInSituTest.reported_result
											let formattedReportedResult = ''

											if (typeof reportedResult === 'string') {
												const startIndex = reportedResult.indexOf('(Penetration:');
												if (startIndex !== -1) {
													const boldText = reportedResult.slice(0, startIndex);
													const remainingText = reportedResult.slice(startIndex);

													formattedReportedResult = `<span style="font-weight: bold;">${boldText}</span>${remainingText}`;

												}
											}

											//Get actualIndex
											const actualIndex = InSituTestsData.map((object: any) => object.guid).indexOf(valueInSituTest.guid)

											if (valueInSituTest.type === 'SV') {

												return (
													<React.Fragment key={index}>
														<tr className="hidden invisible"> {/* hidden invisible */}
															<td>{valueInSituTest.sv_result1} | {valueInSituTest.sv_result2} | {valueInSituTest.sv_result3} | {valueInSituTest.sv_result4} | {valueInSituTest.suitableForTesting}</td>
														</tr>
														<tr className="border border-grey-mid bg-white mb-1">
															<td className={`px-1 sm:px-1 pt-0.5 border-r border-grey-mid font-bold`}>{valueInSituTest.type}-{valueInSituTest.type_number}</td>
															<td className={`px-1 sm:px-1 pt-0.5 border-r border-grey-mid`}>{depth_of_test_temp}</td>
															<td colSpan={2} className={`px-1 sm:px-1 pt-0.5 border-r border-grey-mid`}>Type: {valueInSituTest.test_type}</td>
															<td colSpan={2} className={`px-1 sm:px-1 pt-0.5 border-r border-grey-mid`}>Size: {valueInSituTest.size}</td>
															<td colSpan={4} className={`px-1 sm:px-1 pt-0.5 border-r border-grey-mid`}>{svPrefix}{valueInSituTest.average}{svSuffix}</td>
															<td colSpan={1} className={`px-0 sm:px-1 py-0 border-r border-grey-mid w-8 md:w-12`}>
																<div className={"text-center px-0 sm:px-0 pb-0.5 w-8 md:w-12 edit-background"}
																		onClick={() => {
																			setEditingGuid(valueInSituTest.guid)
																			setEditingIndex(actualIndex)
																			setShowSVEdit(true)
																		}}
																		>
																	<img className="inline z-10" width={"16"} src={edit} alt="" />
																</div>
															</td>
														</tr>
													</React.Fragment>
												)
											} else if (valueInSituTest.type === 'U') {
												return (
													<React.Fragment key={index}>
														<tr className="border border-grey-mid bg-white mb-1">
															<td className={`px-1 py-0.5 border-r border-grey-mid font-bold`}>{valueInSituTest.type}{valueInSituTest.utype}-{valueInSituTest.type_number}</td>
															<td className={`px-1 py-0.5 border-r border-grey-mid`}>{depth_of_test_temp}</td>
															<td className={`px-1 py-0.5 border-r border-grey-mid`}>{valueInSituTest.depth_to_base}</td>
															<td className={`px-1 py-0.5 border-r border-grey-mid`}>{casing}</td>
															<td className={`px-1 py-0.5 border-r border-grey-mid`}>{valueInSituTest.water_depth}</td>
															<td colSpan={2} className={`px-1 py-0.5 border-r border-grey-mid`}>Blows: {valueInSituTest.ublows}</td>
															<td colSpan={3} className={`px-1 py-0.5 border-r border-grey-mid`}>Rec: {valueInSituTest.recovery}mm</td>
															<td colSpan={1} className={`px-0 sm:px-1 py-0 border-r border-grey-mid w-8 md:w-12`}>
																<div className={"text-center px-0 sm:px-0 pb-0.5 w-8 md:w-12 edit-background"}
																		onClick={() => {
																			setEditingGuid(valueInSituTest.guid)
																			setEditingIndex(actualIndex)
																			setShowUEdit(true)
																		}}
																		>
																	<img className="inline z-10" width={"16"} src={edit} alt="" />
																</div>
															</td>
														</tr>
													</React.Fragment>
												)
											} else if (valueInSituTest.type === 'PS') {
												return (
													<React.Fragment key={index}>
														<tr className="border border-grey-mid bg-white mb-1">
															<td className={`px-1 pt-0.5 border-r border-grey-mid font-bold`}>{valueInSituTest.type}-{valueInSituTest.type_number}</td>
															<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{depth_of_test_temp}</td>
															<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{valueInSituTest.depth_to_base}</td>
															<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{casing}</td>
															<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{valueInSituTest.depth_of_water}</td>
															<td colSpan={5} className={`px-1 pt-0.5 border-r border-grey-mid`}>Rec: {valueInSituTest.recovery}mm</td>
															<td colSpan={1} className={`px-0 sm:px-1 py-0 border-r border-grey-mid w-8 md:w-12`}>
																<div className={"text-center px-0 sm:px-0 pb-0.5 w-8 md:w-12 edit-background"}
																		onClick={() => {
																			setEditingGuid(valueInSituTest.guid)
																			setEditingIndex(actualIndex)
																			setShowPSEdit(true)
																		}}
																		>
																	<img className="inline z-10" width={"16"} src={edit} alt="" />
																</div>
															</td>
														</tr>
													</React.Fragment>
												)
											} else {
												// console.log('SPT or CPT?: ', valueInSituTest.type)
												return (
													<React.Fragment key={index}>
														<tr className="border border-grey-mid bg-white mb-1">
															<td className={`px-1 pt-0.5 border-r border-grey-mid font-bold`}>{valueInSituTest.type}-{valueInSituTest.type_number}</td>
															<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{depth_of_test_temp}</td>
															<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{valueInSituTest.depth_to_base}</td>
															<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{casing}</td>
															<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{valueInSituTest.depth_of_water}</td>
															<td className={`px-0 pt-0.5 border-r border-grey-mid bg-sptSB font-bold w-4 md:w-12`}>{valueInSituTest.seating_blows_1st_inc}</td>
															<td className={`px-0 pt-0.5 border-r border-grey-mid bg-sptSB font-bold w-4 md:w-12`}>{valueInSituTest.seating_blows_2nd_inc}</td>
															{/* <td colSpan={6} className={`px-0 pt-0.5 font-bold w-16 md:w-24`}>
																<div className="inline-flex flex-row w-full">
																	<div className="flex-1 border-r border-grey-mid bg-sptSB">{valueInSituTest.seating_blows_1st_inc}</div>
																	<div className="flex-1 border-r border-grey-mid bg-sptSB">{valueInSituTest.seating_blows_2nd_inc}</div>
																	<div className="flex-1 border-r border-grey-mid bg-sptTB">{valueInSituTest.test_blows_1st_inc}</div>
																	<div className="flex-1 border-r border-grey-mid bg-sptTB">{valueInSituTest.test_blows_2nd_inc}</div>
																	<div className="flex-1 border-r border-grey-mid bg-sptTB">{valueInSituTest.test_blows_3rd_inc}</div>
																	<div className="flex-1 bg-sptTB">{valueInSituTest.test_blows_4th_inc}</div>
																</div>
															</td> */}
															<td className={`px-0 pt-0.5 border-r border-grey-mid bg-sptTB font-bold w-4 md:w-12`}>{valueInSituTest.test_blows_1st_inc}</td>
															<td className={`px-0 pt-0.5 border-r border-grey-mid bg-sptTB font-bold w-4 md:w-12`}>{valueInSituTest.test_blows_2nd_inc}</td>
															<td className={`px-0 pt-0.5 border-r border-grey-mid bg-sptTB font-bold w-4 md:w-12`}>{valueInSituTest.test_blows_3rd_inc}</td>
															<td className={`px-0 pt-0.5 border-r border-grey-mid bg-sptTB font-bold w-4 md:w-12`}>{valueInSituTest.test_blows_4th_inc}</td>
														</tr>
														<tr className="border border-grey-mid bg-white mb-1">
															<td colSpan={10} className={`px-0 pt-0.5 border-r border-grey-mid bg-sptSB text-right pr-4`}>
																{/* <span className="font-bold">{totalBlows} blows </span> {refusal_text} */}
																{/* <span className="font-bold">{totalBlows} blows </span> {valueInSituTest.reported_result} */}
																{/* ({valueInSituTest.seating_blows_1st_inc},{valueInSituTest.seating_blows_2nd_inc}/{blows1},{blows2},{blows3},{blows4} N={totalBlows}) */}
																{/* <span className="font-bold">{totalBlows} blows </span>({valueInSituTest.seating_blows_1st_inc},{valueInSituTest.seating_blows_2nd_inc}/{blows1},{blows2},{blows3},{blows4} N={totalBlows}) {valueInSituTest.reported_result} */}
																<div dangerouslySetInnerHTML={{ __html: formattedReportedResult }} />
															</td>
															<td className={`py-0 border-r border-grey-mid bg-sptSB w-8 md:w-12`}>
																<div className={"text-center sm:px-0 pb-0.5 edit-background"}
																		onClick={() => {
																			setEditingGuid(valueInSituTest.guid)
																			setEditingIndex(actualIndex)
																			switch (valueInSituTest.type) {
																				case 'SPT':
																					setShowSPTEdit(true)
																					break
																				case 'CPT':
																					setShowCPTEdit(true)
																			}
																		}}
																		>
																	<img className="inline z-10" width={"16"} src={edit} alt="" />
																</div>
															</td>
														</tr>
													</React.Fragment>
												)
											}

										})}
									</tbody>
								</table>
							</Panel>














							{/* //......................................................
							//.....GGGGGGG....EEEEEEEEEEE...OOOOOOO.....LLLL........
							//...GGGGGGGGGG...EEEEEEEEEEE..OOOOOOOOOO...LLLL........
							//..GGGGGGGGGGGG..EEEEEEEEEEE.OOOOOOOOOOOO..LLLL........
							//..GGGGG..GGGGG..EEEE........OOOOO..OOOOO..LLLL........
							//.GGGGG....GGG...EEEE.......EOOOO....OOOOO.LLLL........
							//.GGGG...........EEEEEEEEEE.EOOO......OOOO.LLLL........
							//.GGGG..GGGGGGGG.EEEEEEEEEE.EOOO......OOOO.LLLL........
							//.GGGG..GGGGGGGG.EEEEEEEEEE.EOOO......OOOO.LLLL........
							//.GGGGG.GGGGGGGG.EEEE.......EOOOO....OOOOO.LLLL........
							//..GGGGG....GGGG.EEEE........OOOOO..OOOOO..LLLL........
							//..GGGGGGGGGGGG..EEEEEEEEEEE.OOOOOOOOOOOO..LLLLLLLLLL..
							//...GGGGGGGGGG...EEEEEEEEEEE..OOOOOOOOOO...LLLLLLLLLL..
							//.....GGGGGGG....EEEEEEEEEEE....OOOOOO.....LLLLLLLLLL..
							//...................................................... */}

							<Panel title="Soil Description" numberOfElements={numberOfSoilDescriptions} 
							// onAdd={() => { setShowSoilDescription(true) }}
							onAdd={() => panel_plantCheck(setShowSoilDescription)}
							>

								<table className="table-auto w-full text-xs sm:text-sm text-center border-collapse overflow-hidden rounded-t-md">
									<thead className="font-bold">
										<tr>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid rounded-tl-sm rounded-tr-sm border-l  border-r border-grey-light`}>From</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>To</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>Soil Description</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light w-8`}></td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}></td>
										</tr>
									</thead>
									<tbody>



										{todaySoilDescriptionsVisible &&

											<>

												{SoilDescriptionData?.filter((action: any) => {
													return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
												}).map((valueSoilDesc: any, index: number) => {

													// Replace depth_of_test = 0 with GL
													let depth_to_top_temp
													if (valueSoilDesc.depth_to_top !== undefined && valueSoilDesc.depth_to_top === '0.00' || valueSoilDesc.depth_to_top === '0') {
														depth_to_top_temp = 'GL'
													} else {
														depth_to_top_temp = valueSoilDesc.depth_to_top
													}

													// console.log('valueSoilDesc.stratum_type:', valueSoilDesc.stratum_type)
													// console.log('valueSoilDesc.depth_to_top:', valueSoilDesc.depth_to_top)
													// console.log('valueSoilDesc.depth_to_base:', valueSoilDesc.depth_to_base)

													let stratum_type_css = ''
													if (valueSoilDesc.stratum_type === 'TOPSOIL') { stratum_type_css = 'soilTopsoil' }
													if (valueSoilDesc.stratum_type === 'SAND') { stratum_type_css = 'soilSand' }
													if (valueSoilDesc.stratum_type === 'GRAVEL') { stratum_type_css = 'soilGravel' }
													if (valueSoilDesc.stratum_type === 'SANDGRAVEL') { stratum_type_css = 'soilGravel' }
													if (valueSoilDesc.stratum_type === 'CLAY') { stratum_type_css = 'soilClay' }
													if (valueSoilDesc.stratum_type === 'SILT') { stratum_type_css = 'soilSilt' }
													if (valueSoilDesc.stratum_type === 'PEAT') { stratum_type_css = 'soilPeat' }
													if (valueSoilDesc.stratum_type === 'CHALK') { stratum_type_css = 'soilChalk' }
													if (valueSoilDesc.stratum_type === 'MADEGROUND') { stratum_type_css = 'soilMadeground' }
													if (valueSoilDesc.stratum_type === 'LIMESTONE') { stratum_type_css = 'soilLimestone' }
													if (valueSoilDesc.stratum_type === 'DOLOMITE') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'SANDSTONE') { stratum_type_css = 'soilSandstone' }
													if (valueSoilDesc.stratum_type === 'SILTSTONE') { stratum_type_css = 'soilSiltstone' }
													if (valueSoilDesc.stratum_type === 'GYPSUM') { stratum_type_css = 'soilGypsum' }
													if (valueSoilDesc.stratum_type === 'MUDSTONE') { stratum_type_css = 'soilMudstone' }
													if (valueSoilDesc.stratum_type === 'CHERT') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'SHALE') { stratum_type_css = 'soilShale' }
													if (valueSoilDesc.stratum_type === 'GRANITE') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'DIORITE') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'RHYOLITE') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'ANDERSITE') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'BASALT') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'OBSIDIAN') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'MARBLE') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'QUARTZITE') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'SLATE') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'COAL') { stratum_type_css = 'soilCoal' }

													//Get actualIndex
													const actualIndex = SoilDescriptionData.map((object: any) => object.guid).indexOf(valueSoilDesc.guid)

													return (
														<React.Fragment key={index}>
															<tr className="hidden invisible">
																<td>{valueSoilDesc.stratum_type}</td>
															</tr>

															<tr className="border border-grey-mid bg-white mb-1">
																<td className={`px-1 pt-0.5 border-r border-grey-mid align-top`}>{depth_to_top_temp}</td>
																<td className={`px-1 pt-0.5 border-r border-grey-mid align-top`}>{valueSoilDesc.depth_to_base}</td>
																<td className={`px-1 pt-0.5 border-r border-grey-mid text-left`}>{valueSoilDesc.stratum_description}</td>
																<td className={`${stratum_type_css} border-r border-grey-mid`}></td>
																<td className={`px-0 py-0 border-r border-grey-mid align-top w-8 md:w-12`}>
																	<div className={"text-center px-0 pb-0.5 edit-background"}
																			onClick={() => {
																				setEditingGuid(valueSoilDesc.guid)
																				setEditingIndex(actualIndex)
																				setShowSoilDescriptionEdit(true)
																			}}
																			>
																		<img className="inline z-10" width={"16"} height={"16"} src={edit} alt="" />
																	</div>
																</td>
															</tr>
														</React.Fragment>
													);
												})}

												{/* Add the static row for viewing all */}
												{/* <tr>
													<td colSpan={4}>
														<div className="font-bold text-charcoal text-left mt-1 w-20" onClick={() => {
															setTodaySoilDescriptionsVisible(false);
															setAllSoilDescriptionsVisible(true);
														}}>
															<div className="bg-grey-mid py-0.5 text-center rounded-bl-md w-full" style={{ fontSize: '9px', lineHeight: '9px' }}>
																View All
															</div>
														</div>
													</td>
												</tr> */}
											</>

										}



										{allSoilDescriptionsVisible &&

											<>

												{SoilDescriptionData?.map((valueSoilDesc: any, index: number) => {

													// Replace depth_to_top = 0 with GL
													let depth_to_top_temp
													if (valueSoilDesc.depth_to_top !== undefined && valueSoilDesc.depth_to_top === '0.00' || valueSoilDesc.depth_to_top === '0') {
														depth_to_top_temp = 'GL'
													} else {
														depth_to_top_temp = valueSoilDesc.depth_to_top
													}

													// change background color of records NOT today
													let bg_date_responsive
													const valueSoilDescTime = new Date(valueSoilDesc.time_of_action);
													const selectedDateObj = new Date(selectedDate);

													const valueSoilDescDate = new Date(valueSoilDescTime.getFullYear(), valueSoilDescTime.getMonth(), valueSoilDescTime.getDate());
													const selectedDateOnly = new Date(selectedDateObj.getFullYear(), selectedDateObj.getMonth(), selectedDateObj.getDate());

													if (valueSoilDescDate.getTime() === selectedDateOnly.getTime()) {
														bg_date_responsive = 'bg-white'
													} else {
														bg_date_responsive = 'bg-sptTB'
													}
													//Get actualIndex
													const actualIndex = SoilDescriptionData.map((object: any) => object.guid).indexOf(valueSoilDesc.guid)

													let stratum_type_css = ''
													if (valueSoilDesc.stratum_type === 'TOPSOIL') { stratum_type_css = 'soilTopsoil' }
													if (valueSoilDesc.stratum_type === 'SAND') { stratum_type_css = 'soilSand' }
													if (valueSoilDesc.stratum_type === 'GRAVEL') { stratum_type_css = 'soilGravel' }
													if (valueSoilDesc.stratum_type === 'SANDGRAVEL') { stratum_type_css = 'soilGravel' }
													if (valueSoilDesc.stratum_type === 'CLAY') { stratum_type_css = 'soilClay' }
													if (valueSoilDesc.stratum_type === 'SILT') { stratum_type_css = 'soilSilt' }
													if (valueSoilDesc.stratum_type === 'PEAT') { stratum_type_css = 'soilPeat' }
													if (valueSoilDesc.stratum_type === 'CHALK') { stratum_type_css = 'soilChalk' }
													if (valueSoilDesc.stratum_type === 'MADEGROUND') { stratum_type_css = 'soilMadeground' }
													if (valueSoilDesc.stratum_type === 'LIMESTONE') { stratum_type_css = 'soilLimestone' }
													if (valueSoilDesc.stratum_type === 'DOLOMITE') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'SANDSTONE') { stratum_type_css = 'soilSandstone' }
													if (valueSoilDesc.stratum_type === 'SILTSTONE') { stratum_type_css = 'soilSiltstone' }
													if (valueSoilDesc.stratum_type === 'GYPSUM') { stratum_type_css = 'soilGypsum' }
													if (valueSoilDesc.stratum_type === 'MUDSTONE') { stratum_type_css = 'soilMudstone' }
													if (valueSoilDesc.stratum_type === 'CHERT') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'SHALE') { stratum_type_css = 'soilShale' }
													if (valueSoilDesc.stratum_type === 'GRANITE') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'DIORITE') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'RHYOLITE') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'ANDERSITE') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'BASALT') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'OBSIDIAN') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'MARBLE') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'QUARTZITE') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'SLATE') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'COAL') { stratum_type_css = 'soilCoal' }

													// console.log('valueSoilDesc.stratum_type:', valueSoilDesc.stratum_type)

													return (
														<React.Fragment key={index}>
															<tr className="hidden invisible">
																<td>{valueSoilDesc.stratum_type}</td>
															</tr>
															<tr className={`border border-grey-mid ${bg_date_responsive} mb-1`}>
																<td className={`px-1 pt-0.5 border-r border-grey-mid align-top`}>{depth_to_top_temp}</td>
																<td className={`px-1 pt-0.5 border-r border-grey-mid align-top`}>{valueSoilDesc.depth_to_base}</td>
																<td className={`px-1 pt-0.5 border-r border-grey-mid text-left`}>{valueSoilDesc.stratum_description}</td>
																<td className={`${stratum_type_css} border-r border-grey-mid`}></td>
																<td className={`px-0 py-0 border-r border-grey-mid align-top w-8 md:w-12`}>
																	<div className={"text-center px-0 pb-0.5 edit-background"}
																			onClick={() => {
																				setEditingGuid(valueSoilDesc.guid);
																				setEditingIndex(actualIndex);
																				setShowSoilDescriptionEdit(true);
																				// console.log('SoilDesc Edit:', actualIndex, valueSoilDesc.guid, editingGuid, editingIndex)
																			}}
																			>
																		<img className="inline z-10" width={"16"} height={"16"} src={edit} alt="" />
																	</div>
																</td>
															</tr>
															<tr>
																{/* <td style={{ fontSize: '9px'}}>
																id: {valueSoilDesc.id}
															</td> */}
																<td colSpan={3} className="text-right h-1">
																	<div className="-mt-5 pr-1" style={{ fontSize: '9px' }}>
																		{new Date(valueSoilDesc.time_of_action).toLocaleDateString('en-UK')}
																	</div>
																</td>
															</tr>
														</React.Fragment>
													);
												})}

												{/* Add the static row for viewing today */}
												<tr>
													<td colSpan={4}>
														<div className="font-bold text-charcoal text-left w-20 mt-1" onClick={() => {
															setTodaySoilDescriptionsVisible(true);
															setAllSoilDescriptionsVisible(false);
														}}>
															<div className="bg-grey-mid py-0.5 text-center rounded-bl-md w-full" style={{ fontSize: '9px', lineHeight: '9px' }}>
																View Today
															</div>
														</div>
													</td>
												</tr>
											</>
										}
									</tbody>
								</table>
							</Panel>














							{/* //..................................................................
							//.WWWW..WWWWW...WWWA..AAAAA...AATTTTTTTTTTTEEEEEEEEE.EERRRRRRRRR...
							//.WWWW..WWWWW..WWWW...AAAAA...AATTTTTTTTTTTEEEEEEEEE.EERRRRRRRRRR..
							//.WWWW..WWWWWW.WWWW..AAAAAA...AATTTTTTTTTTTEEEEEEEEE.EERRRRRRRRRR..
							//.WWWW.WWWWWWW.WWWW..AAAAAAA......TTTT...TTEE........EERR....RRRR..
							//.WWWW.WWWWWWW.WWWW.AAAAAAAA......TTTT...TTEE........EERR....RRRR..
							//..WWWWWWWWWWW.WWW..AAAAAAAA......TTTT...TTEEEEEEEEE.EERRRRRRRRRR..
							//..WWWWWWW.WWWWWWW..AAAA.AAAA.....TTTT...TTEEEEEEEEE.EERRRRRRRRR...
							//..WWWWWWW.WWWWWWW.AAAAAAAAAA.....TTTT...TTEEEEEEEEE.EERRRRRRR.....
							//..WWWWWWW.WWWWWWW.AAAAAAAAAAA....TTTT...TTEE........EERR.RRRRR....
							//..WWWWWWW.WWWWWWW.AAAAAAAAAAA....TTTT...TTEE........EERR..RRRRR...
							//...WWWWW...WWWWW.WAAA....AAAA....TTTT...TTEEEEEEEEEEEERR...RRRRR..
							//...WWWWW...WWWWW.WAAA.....AAAA...TTTT...TTEEEEEEEEEEEERR....RRRR..
							//...WWWWW...WWWWWWWAAA.....AAAA...TTTT...TTEEEEEEEEEEEERR.....RRR..
							//.................................................................. */}

							<Panel title="Ground Water" numberOfElements={numberOfGroundWaters} 
							// onAdd={() => setShowGroundWater(true)}
							onAdd={() => panel_plantCheck(setShowGroundWater)}
							>
								<table className="table-auto w-full text-xs sm:text-sm text-center border-collapse overflow-hidden rounded-t-md">
									<thead className="font-bold">
										<tr>
											<td className={`px-1 sm:px-2 py-0 bg-grey-mid border-grey-mid rounded-tl-sm rounded-tr-sm border-l  border-r border-grey-light`}>Time</td>
											<td className={`px-1 sm:px-2 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>Depth (m)</td>
											<td className={`px-1 sm:px-2 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>Cas. (m)</td>
											<td className={`px-1 sm:px-2 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>Flow</td>
											{/* <td className={`px-2 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`} colSpan={4}>Height water rose to after (mins):</td> */}
											<td className={`px-1 sm:px-2 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>5</td>
											<td className={`px-1 sm:px-2 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>10</td>
											<td className={`px-1 sm:px-2 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>15</td>
											<td className={`px-1 sm:px-2 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>20</td>
											<td className={`px-1 sm:px-2 py-0 bg-grey-mid border-grey-mid rounded-tr-sm border-r border-grey-light`}></td>
										</tr>
									</thead>

									<tbody>

										{GroundWaterTestsData?.filter((action: any) => {
											return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
										}).map((valueGroundWater: any, index: number) => {

											// Replace depth_to_water = 0 with GL
											let depth_to_water_temp
											if (valueGroundWater.depth_to_water !== undefined && valueGroundWater.depth_to_water === '0.00' || valueGroundWater.depth_to_water === '0') {
												depth_to_water_temp = 'GL'
											} else {
												depth_to_water_temp = valueGroundWater.depth_to_water
											}

											// Time
											const formattedTime = valueGroundWater.time_of_strike.split(' ')[1].substring(0, 5);

											//Get actualIndex
											const actualIndex = GroundWaterTestsData.map((object: any) => object.guid).indexOf(valueGroundWater.guid)

											// console.log('selectedDate:', selectedDate)

											return (

												<React.Fragment key={index}>
													<tr className="border border-grey-mid bg-white mb-1">
														<td className={`px-1 sm:px-2 pt-0.5 border-r border-grey-mid`}>{formattedTime}</td>
														<td className={`px-1 sm:px-2 pt-0.5 border-r border-grey-mid`}>{depth_to_water_temp}</td>
														<td className={`px-1 sm:px-2 pt-0.5 border-r border-grey-mid`}>{valueGroundWater.casing_depth}</td>
														<td className={`px-1 sm:px-2 pt-0.5 border-r border-grey-mid`}>{valueGroundWater.rate_of_flow}</td>
														<td className={`px-1 sm:px-2 pt-0.5 border-r border-grey-mid`}>{valueGroundWater.depth_after_5}</td>
														<td className={`px-1 sm:px-2 pt-0.5 border-r border-grey-mid`}>{valueGroundWater.depth_after_10}</td>
														<td className={`px-1 sm:px-2 pt-0.5 border-r border-grey-mid`}>{valueGroundWater.depth_after_15}</td>
														<td className={`px-1 sm:px-2 pt-0.5 border-r border-grey-mid`}>{valueGroundWater.depth_after_20}</td>
														<td className={`px-1 sm:px-2 py-0 border-r border-grey-mid w-8 md:w-12`}>
															<div className={"text-center px-0 pb-0.5 edit-background"}
															onClick={() => {
																setEditingGuid(valueGroundWater.guid)
																setEditingIndex(actualIndex)
																setShowGroundWaterEdit(true)
															}}
																>
																<img className="inline z-10" width={"16"} src={edit} alt="" />
															</div>
														</td>
													</tr>
												</React.Fragment>
											)
										})}

									</tbody>
								</table>
							</Panel>














							{/* //....................................................
							//.TTTTTTTTTTTRRRRRRRRRR...EEEEEEEEEEEEMMMMM...MMMMM..
							//.TTTTTTTTTTTRRRRRRRRRRR..EEEEEEEEEEEEMMMMM...MMMMM..
							//.TTTTTTTTTTTRRRRRRRRRRR..EEEEEEEEEEEEMMMMM...MMMMM..
							//....TTTT....RRRR...RRRRR.EEEE.......EMMMMMM.MMMMMM..
							//....TTTT....RRRR...RRRRR.EEEE.......EMMMMMM.MMMMMM..
							//....TTTT....RRRRRRRRRRR..EEEEEEEEEE.EMMMMMM.MMMMMM..
							//....TTTT....RRRRRRRRRRR..EEEEEEEEEE.EMMMMMMMMMMMMM..
							//....TTTT....RRRRRRRR.....EEEEEEEEEE.EMMMMMMMMMMMMM..
							//....TTTT....RRRR.RRRR....EEEE.......EMMMMMMMMMMMMM..
							//....TTTT....RRRR..RRRR...EEEE.......EMMM.MMMMM.MMM..
							//....TTTT....RRRR..RRRRR..EEEEEEEEEEEEMMM.MMMMM.MMM..
							//....TTTT....RRRR...RRRRR.EEEEEEEEEEEEMMM.MMMMM.MMM..
							//....TTTT....RRRR....RRRR.EEEEEEEEEEEEMMM.MMMMM.MMM..
							//.................................................... */}

							<Panel title="Comments / Standing Time / Day Works" numberOfElements={numberOfComments} 
							// onAdd={() => setShowComments(true)}
							onAdd={() => panel_plantCheck(setShowComments)}
							>
								<table className="table-auto w-full text-xs sm:text-sm text-center border-collapse overflow-hidden rounded-t-md">
									<thead className="font-bold">
										<tr>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid rounded-tl-sm rounded-tr-sm border-l  border-r border-grey-light `}>Type</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>Time From</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>Duration</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>Comment</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}></td>
										</tr>
									</thead>
									<tbody>

										{CommentsData?.filter((action: any) => {
											return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
										}).map((valueComment: any, index: number) => {

											//Get actualIndex
											const actualIndex = CommentsData.map((object: any) => object.guid).indexOf(valueComment.guid)
											// console.log('typeof actualIndex: ', actualIndex, typeof actualIndex)

											return (
												<React.Fragment key={index}>
													<tr className="border border-grey-mid bg-white mb-1">
														<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{valueComment.comment_type}</td>
														<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{valueComment.start_time}</td>
														<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{valueComment.duration}</td>
														<td className={`px-1 pt-0.5 border-r border-grey-mid text-left`}>{valueComment.general_remarks}</td>
														<td className={`px-1 py-0 border-r border-grey-mid w-8 md:w-12`}>
															<div className={"text-center px-0 pb-0.5 edit-background"}
																	onClick={() => {
																		const actualIndex = CommentsData.map((object: any) => object.guid).indexOf(valueComment.guid)
																		setEditingGuid(valueComment.guid)
																		setEditingIndex(actualIndex)
																		setShowCommentsEdit(true)
																	}}
																	>
																<img className="inline z-10" width={"16"} src={edit} alt="" />
															</div>
														</td>
													</tr>
												</React.Fragment>
											)
										})}

										{/* <tr>
											<td className="h-2"></td>
										</tr>
										<tr>
											<td colSpan={5} className="w-full border border-grey-mid">
												<div className="grid grid-cols-12 w-full">
													<div className="col-span-3 pr-2 py-1 border-r border-grey-mid text-right bg-sptTB">
														Total Standing:
													</div>
													<div className="col-span-3 py-1 border-r border-grey-mid text-center font-bold">
														hrs mins
													</div>
													<div className="col-span-3 pr-2 py-1 border-r border-grey-mid text-right bg-sptTB">
														Total Dayworks:
													</div>
													<div className="col-span-3 py-1 text-center font-bold">
														hrs mins
													</div>
												</div>
											</td>
										</tr> */}
									</tbody>
								</table>

							</Panel>







						</div>  {/* END OF LEFT SIDE */}

						<div className="col-span-5 lg:col-span-2 mt-0 md:mt-2"> {/* START OF RIGHT SIDE */}

							{/* RIGHT SIDE PANELS GO HERE */}










							{/* //.........................................................
							//.DDDDDDDDD....RRRRRRRRRR..RIIIIIVVV....VVVVVVEEEEEEEEEE..
							//.DDDDDDDDDD...RRRRRRRRRRR.RIIIIIVVV....VVVV.VEEEEEEEEEE..
							//.DDDDDDDDDDD..RRRRRRRRRRR.RIIIIIVVV....VVVV.VEEEEEEEEEE..
							//.DDDD...DDDD..RRRR...RRRRRRIIIIIVVVV..VVVV..VEEE.........
							//.DDDD....DDDD.RRRR...RRRRRRIIII.VVVV..VVVV..VEEE.........
							//.DDDD....DDDD.RRRRRRRRRRR.RIIII.VVVV..VVVV..VEEEEEEEEE...
							//.DDDD....DDDD.RRRRRRRRRRR.RIIII.VVVVVVVVV...VEEEEEEEEE...
							//.DDDD....DDDD.RRRRRRRR....RIIII..VVVVVVVV...VEEEEEEEEE...
							//.DDDD....DDDD.RRRR.RRRR...RIIII..VVVVVVVV...VEEE.........
							//.DDDD...DDDDD.RRRR..RRRR..RIIII..VVVVVVV....VEEE.........
							//.DDDDDDDDDDD..RRRR..RRRRR.RIIII...VVVVVV....VEEEEEEEEEE..
							//.DDDDDDDDDD...RRRR...RRRRRRIIII...VVVVVV....VEEEEEEEEEE..
							//.DDDDDDDDD....RRRR....RRRRRIIII...VVVVV.....VEEEEEEEEEE..
							//......................................................... */}

							<Panel title="Drive Records" numberOfElements={numberOfDriveRecords} 
							// onAdd={() => setShowDriveRecords(true)}
							onAdd={() => panel_plantCheck(setShowDriveRecords)}
							>
								<table className="table-auto w-full text-xs sm:text-sm text-center">
									<thead className="font-bold">
										<tr>
											<td className={`px-1 py-1 bg-grey-mid border-grey-mid rounded-tl-sm rounded-tr-sm border-l border-r border-grey-light`}>#</td>
											<td className={`px-1 py-1 bg-grey-mid border-grey-mid border-r border-grey-light`}>From (m)</td>
											<td className={`px-1 py-1 bg-grey-mid border-grey-mid border-r border-grey-light`}>To (m)</td>
											<td className={`px-1 py-1 bg-grey-mid border-grey-mid border-r border-grey-light`}>Hole (m)</td>
											<td className={`px-1 py-1 bg-grey-mid border-grey-mid border-r border-grey-light`}>Dia (mm)</td>
											<td className={`px-1 py-1 bg-grey-mid border-grey-mid border-r border-grey-light`} colSpan={2}>Recovery (m)</td>
										</tr>
									</thead>
									<tbody>
										{DriveRecordData?.filter((action: any) => {
											return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
										}).map((valueDriveRecords: any, index: number) => {

											// Replace depth_of_test = 0 with GL
											let depth_from_temp
											if (valueDriveRecords.depth_from !== undefined && valueDriveRecords.depth_from === '0.00' || valueDriveRecords.depth_from === '0') {
												depth_from_temp = 'GL'
											} else {
												depth_from_temp = valueDriveRecords.depth_from
											}

											//Get actualIndex
											const actualIndex = DriveRecordData.map((object: any) => object.guid).indexOf(valueDriveRecords.guid)

											return (
												<React.Fragment key={index}>
													<tr className="border border-grey-mid bg-white mb-1">
														<td className={`px-1 py-1 border-r border-grey-mid font-bold`}>L-{valueDriveRecords.drive_number}</td>
														<td className={`px-1 py-1 border-r border-grey-mid`}>{depth_from_temp}</td>
														<td className={`px-1 py-1 border-r border-grey-mid`}>{valueDriveRecords.depth_to}</td>
														<td className={`px-1 py-1 border-r border-grey-mid`}>{valueDriveRecords.hole_depth}</td>
														<td className={`px-1 py-1 border-r border-grey-mid`}>{valueDriveRecords.liner_diameter}</td>
														<td className={`px-1 py-1 border-r border-grey-mid`}>{valueDriveRecords.sample_length}</td>
														<td className={`px-1 py-1 border-r border-grey-mid w-8 md:w-12`}>
															<div className={"text-center px-0 py-0 edit-background"}
																	onClick={() => {
																		setEditingGuid(valueDriveRecords.guid)
																		setEditingIndex(actualIndex)
																		setShowDriveRecordsEdit(true)
																	}} >
																<img className="inline z-10" width={"20"} src={edit} alt="" />
															</div>
														</td>
													</tr>
													<tr>
														<td colSpan={7} className="hidden">
															duration: {valueDriveRecords.duration} | general_remarks: {valueDriveRecords.general_remarks}
														</td>
													</tr>
												</React.Fragment>
											)
										})}

									</tbody>
								</table>
							</Panel>














							{/* //..............................................................................
							//.IIIII.NNNN...NNNN...SSSSSSS....PPPPPPPPP........PPPPPPPPP..PIIII.TTTTTTTTTT..
							//.IIIII.NNNNN..NNNN..SSSSSSSSS...PPPPPPPPPP.......PPPPPPPPPP.PIIII.TTTTTTTTTT..
							//.IIIII.NNNNN..NNNN..SSSSSSSSSS..PPPPPPPPPPP......PPPPPPPPPPPPIIII.TTTTTTTTTT..
							//.IIIII.NNNNNN.NNNN.SSSSS..SSSS..PPPP...PPPP......PPPP...PPPPPIIII....TTTT.....
							//.IIIII.NNNNNN.NNNN.SSSSS........PPPP...PPPP......PPPP...PPPPPIIII....TTTT.....
							//.IIIII.NNNNNNNNNNN..SSSSSSS.....PPPPPPPPPPP......PPPPPPPPPPPPIIII....TTTT.....
							//.IIIII.NNNNNNNNNNN...SSSSSSSSS..PPPPPPPPPP.......PPPPPPPPPP.PIIII....TTTT.....
							//.IIIII.NNNNNNNNNNN.....SSSSSSS..PPPPPPPPP........PPPPPPPPP..PIIII....TTTT.....
							//.IIIII.NNNNNNNNNNN........SSSSS.PPPP.............PPPP.......PIIII....TTTT.....
							//.IIIII.NNNN.NNNNNN.SSSS....SSSS.PPPP.............PPPP.......PIIII....TTTT.....
							//.IIIII.NNNN..NNNNN.SSSSSSSSSSSS.PPPP.............PPPP.......PIIII....TTTT.....
							//.IIIII.NNNN..NNNNN..SSSSSSSSSS..PPPP.............PPPP.......PIIII....TTTT.....
							//.IIIII.NNNN...NNNN...SSSSSSSS...PPPP.............PPPP.......PIIII....TTTT.....
							//.............................................................................. */}


							<PanelSingleUse title="Inspection Pit"
								numberOfElements={numberOfInspectionPits}
								// onAdd={() => setShowInspectionPit(true)}
								onAdd={() => panel_plantCheck(setShowInspectionPit)}
								// onEdit={() => setShowInspectionPitEdit(true)}
								onEdit={() => panel_plantCheck(setShowInspectionPitEdit)}
							>
								<table className="table-auto w-full text-xs sm:text-sm text-center border-collapse overflow-hidden rounded-t-md">
									<thead className="font-bold">
										<tr>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid rounded-tl-sm rounded-tr-sm border-l  border-r border-grey-light`}>Depth From (m)</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>Depth To (m)</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>Time Taken (hrs)</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}></td>
										</tr>
									</thead>
									<tbody>

										{InspectionPitData?.filter((action: any) => {
											return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
										}).map((valueInspectionPit: any, index: number) => {

											// Replace depth_to_top = 0 with GL
											let depth_to_top_temp
											if (
												valueInspectionPit.depth_to_top !== undefined &&
												(
													valueInspectionPit.depth_to_top === '0.00' ||
													parseFloat(valueInspectionPit.depth_to_top) === 0 ||
													valueInspectionPit.depth_to_top === '0' ||
													parseFloat(valueInspectionPit.depth_to_top) === 0.00
												)
											) {
												depth_to_top_temp = 'GL';
											} else {
												depth_to_top_temp = valueInspectionPit.depth_to_top;
											}

											//   console.log('valueInspectionPit.depth_to_top:', valueInspectionPit.depth_to_top, depth_to_top_temp)

											// Time
											const timeString = valueInspectionPit.duration
											let formattedTime = '' //  timeString.slice(0, -3); // Removes the last 3 characters
											if (timeString.length === 8) {
												formattedTime = timeString.slice(0, -3); // Removes the last 3 characters
											} else {
												formattedTime = timeString
											}

											//Get actualIndex
											const actualIndex = InspectionPitData.map((object: any) => object.guid).indexOf(valueInspectionPit.guid)
											// console.log('typeof actualIndex: ', actualIndex, typeof actualIndex)

											return (
												<React.Fragment key={index}>
													<tr className="border border-grey-mid bg-white mb-1">
														<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{depth_to_top_temp}</td>
														<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{valueInspectionPit.depth_to_base}</td>
														<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{formattedTime}</td>
														<td className={`px-1 py-0 border-r border-grey-mid w-8 md:w-12`}>
															<div className={"text-center px-0 pb-0.5 edit-background"}
																	onClick={() => {
																		// const actualIndex = InspectionPitData.map((object: any) => object.guid).indexOf(valueInspectionPit.guid)
																		setEditingGuid(valueInspectionPit.guid)
																		setEditingIndex(0)
																		setShowInspectionPitEdit(true)
																	}}
																	>
																<img className="inline z-10" width={"16"} src={edit} alt="" />
															</div>
														</td>
													</tr>
												</React.Fragment>
											)
										})}

									</tbody>
								</table>

							</PanelSingleUse>














							{/* //.........................................................................
							//....CCCCCCC.......AAAAA......SSSSSSS...SIIII.NNNN...NNNN.....GGGGGGG.....
							//...CCCCCCCCC......AAAAA.....SSSSSSSSS..SIIII.NNNNN..NNNN...GGGGGGGGGG....
							//..CCCCCCCCCCC....AAAAAA.....SSSSSSSSSS.SIIII.NNNNN..NNNN..GGGGGGGGGGGG...
							//..CCCC...CCCCC...AAAAAAA...SSSSS..SSSS.SIIII.NNNNNN.NNNN..GGGGG..GGGGG...
							//.CCCC.....CCC...AAAAAAAA...SSSSS.......SIIII.NNNNNN.NNNN.NGGGG....GGG....
							//.CCCC...........AAAAAAAA....SSSSSSS....SIIII.NNNNNNNNNNN.NGGG............
							//.CCCC...........AAAA.AAAA....SSSSSSSSS.SIIII.NNNNNNNNNNN.NGGG..GGGGGGGG..
							//.CCCC..........AAAAAAAAAA......SSSSSSS.SIIII.NNNNNNNNNNN.NGGG..GGGGGGGG..
							//.CCCC.....CCC..AAAAAAAAAAA........SSSSSSIIII.NNNNNNNNNNN.NGGGG.GGGGGGGG..
							//..CCCC...CCCCC.AAAAAAAAAAA.SSSS....SSSSSIIII.NNNN.NNNNNN..GGGGG....GGGG..
							//..CCCCCCCCCCC.AAAA....AAAA.SSSSSSSSSSSSSIIII.NNNN..NNNNN..GGGGGGGGGGGG...
							//...CCCCCCCCCC.AAAA.....AAAA.SSSSSSSSSS.SIIII.NNNN..NNNNN...GGGGGGGGGG....
							//....CCCCCCC..CAAAA.....AAAA..SSSSSSSS..SIIII.NNNN...NNNN.....GGGGGGG.....
							//......................................................................... */}

							<Panel title="Casing Record" numberOfElements={numberOfCasingRecords} 
							// onAdd={() => setShowCasingRecord(true)}
								onAdd={() => panel_plantCheck(setShowCasingRecord)}
							>
								<table className="table-auto w-full text-xs sm:text-sm text-center border-collapse overflow-hidden rounded-t-md">
									<thead className="font-bold">
										<tr>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid rounded-tl-sm rounded-tr-sm border-l  border-r border-grey-light`}>Casing Size (mm)</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>Depth To (m)</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>BH Depth (m)</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}></td>
										</tr>
									</thead>
									<tbody>


										{CasingData?.filter((action: any) => {
											return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
										}).map((valueCasing: any, index: number) => {

											//Get actualIndex
											const actualIndex = CasingData.map((object: any) => object.guid).indexOf(valueCasing.guid)
											// console.log('typeof actualIndex: ', actualIndex, typeof actualIndex)

											return (
												<React.Fragment key={index}>
													<tr className="border border-grey-mid bg-white mb-1">
														<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{valueCasing.casing_diameter}</td>
														<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{valueCasing.depth_to_base}</td>
														<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{valueCasing.depth_of_borehole}</td>
														<td className={`px-1 py-0 border-r border-grey-mid w-8 md:w-12`}>
															<div className={"text-center px-0 pb-0.5 edit-background"}
																	onClick={() => {
																		setEditingGuid(valueCasing.guid)
																		setEditingIndex(actualIndex)
																		setShowCasingRecordEdit(true)
																	}}
																	>
																<img className="inline z-10" width={"16"} src={edit} alt="" />
															</div>
														</td>
													</tr>
												</React.Fragment>
											)
										}
										)}



									</tbody>
								</table>

							</Panel>














							{/* //.................................................
							//.BBBBBBBBBB...KKKK...KKKKK.FFFFFFFFFF.LLLL.......
							//.BBBBBBBBBBB..KKKK..KKKKK..FFFFFFFFFF.LLLL.......
							//.BBBBBBBBBBB..KKKK.KKKKK...FFFFFFFFFF.LLLL.......
							//.BBBB...BBBB..KKKKKKKKK....FFFF.......LLLL.......
							//.BBBB...BBBB..KKKKKKKK.....FFFF.......LLLL.......
							//.BBBBBBBBBBB..KKKKKKKK.....FFFFFFFFF..LLLL.......
							//.BBBBBBBBBB...KKKKKKKK.....FFFFFFFFF..LLLL.......
							//.BBBBBBBBBBB..KKKKKKKKK....FFFFFFFFF..LLLL.......
							//.BBBB....BBBB.KKKK.KKKKK...FFFF.......LLLL.......
							//.BBBB....BBBB.KKKK..KKKK...FFFF.......LLLL.......
							//.BBBBBBBBBBBB.KKKK..KKKKK..FFFF.......LLLLLLLLL..
							//.BBBBBBBBBBB..KKKK...KKKKK.FFFF.......LLLLLLLLL..
							//.BBBBBBBBBB...KKKK...KKKKK.FFFF.......LLLLLLLLL..
							//................................................. */}

							<PanelBackfillInstall titleIN="Install" titleBF="Backfill"
								numberOfBackfills={numberOfBackfills} numberOfInstalls={numberOfInstalls}
								// onAddInstall={() => setShowInstall(true)}
								onAddInstall={() => panel_plantCheck(setShowInstall)}
								// onAddBackfill={() => setShowBackfill(true)}
								onAddBackfill={() => panel_plantCheck(setShowBackfill)}
								// onEditInstall={() => setShowInstallEdit(true)}
								onEditInstall={() => panel_plantCheck(setShowInstallEdit)}
								// onEditBackfill={() => setShowBackfillEdit(true)}
								onEditBackfill={() => panel_plantCheck(setShowBackfillEdit)}
							>

								<table className="table-auto w-full text-xs sm:text-sm text-left border-collapse overflow-hidden rounded-t-md">
									<thead className="hidden">
										<tr>
											<td></td>
										</tr>
									</thead>
									<tbody className="">

										{InstallationData.filter((action: any) => {
											return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
										}).map((valueInstall: any, index: number) => {

											//Get actualIndex
											const actualIndex = InstallationData.map((object: any) => object.guid).indexOf(valueInstall.guid)

											let install_1_text
											if (valueInstall.pipe_width_1 || valueInstall.pipe_solid_1 || valueInstall.pipe_slotted_1) {
												if (valueInstall.pipe_width_1) { install_1_text = '#1: ' + valueInstall.pipe_width_1 + 'mm pipe to ' }
												else { install_1_text = '#1: Pipe to ' }
												if (!valueInstall.pipe_solid_1 && !valueInstall.pipe_slotted_1) { install_1_text = install_1_text }
												if (valueInstall.pipe_solid_1 && !valueInstall.pipe_slotted_1) { install_1_text = install_1_text + (valueInstall.pipe_solid_1) + 'm (' + valueInstall.pipe_solid_1 + 'm solid)' }
												if (!valueInstall.pipe_solid_1 && valueInstall.pipe_slotted_1) { install_1_text = install_1_text + (valueInstall.pipe_slotted_1) + 'm (' + valueInstall.pipe_slotted_1 + 'm slotted)' }
												if (valueInstall.pipe_solid_1 && valueInstall.pipe_slotted_1) { install_1_text = install_1_text + (+valueInstall.pipe_solid_1 + +valueInstall.pipe_slotted_1) + 'm (' + valueInstall.pipe_solid_1 + 'm solid & ' + valueInstall.pipe_slotted_1 + 'm slotted)' }
												if (valueInstall.pipe_geosock_1) { install_1_text = install_1_text + ' + geosock' }
												if (valueInstall.gas_tap_1) { install_1_text = install_1_text + ' & gastap' }
											} else { install_1_text = '' }
											// INSTALL #2
											let install_2_text
											if (valueInstall.pipe_width_2 || valueInstall.pipe_solid_2 || valueInstall.pipe_slotted_2) {
												if (valueInstall.pipe_width_2) { install_2_text = '#2: ' + valueInstall.pipe_width_2 + 'mm pipe to ' }
												else { install_2_text = '#2: Pipe to ' }
												if (!valueInstall.pipe_solid_2 && !valueInstall.pipe_slotted_2) { install_2_text = install_2_text }
												if (valueInstall.pipe_solid_2 && !valueInstall.pipe_slotted_2) { install_2_text = install_2_text + (valueInstall.pipe_solid_2) + 'm (' + valueInstall.pipe_solid_2 + 'm solid)' }
												if (!valueInstall.pipe_solid_2 && valueInstall.pipe_slotted_2) { install_2_text = install_2_text + (valueInstall.pipe_slotted_2) + 'm (' + valueInstall.pipe_slotted_2 + 'm slotted)' }
												if (valueInstall.pipe_solid_2 && valueInstall.pipe_slotted_2) { install_2_text = install_2_text + (+valueInstall.pipe_solid_2 + +valueInstall.pipe_slotted_2) + 'm (' + valueInstall.pipe_solid_2 + 'm solid & ' + valueInstall.pipe_slotted_2 + 'm slotted)' }
												if (valueInstall.pipe_geosock_2) { install_2_text = install_2_text + ' + geosock' }
												if (valueInstall.gas_tap_2) { install_2_text = install_2_text + ' & gastap' }
											} else { install_2_text = '' }

											let cover_comment_text = ''
											if (valueInstall.cover_size) {
												cover_comment_text = valueInstall.cover_size + ' - ' + valueInstall.cover_type
											} else {
												cover_comment_text = valueInstall.cover_type
											}
											if (valueInstall.general_remarks) { cover_comment_text = cover_comment_text + ' - ' + valueInstall.general_remarks }



											return (
												<React.Fragment key={index}>
													<tr className="hidden invisible">
														<td>solid1: {valueInstall.pipe_solid_1} slot1: {valueInstall.pipe_slotted_1} w1: {valueInstall.pipe_width_1} geosock: {valueInstall.pipe_geosock_1} gastap: {valueInstall.gas_tap_1}
															solid2: {valueInstall.pipe_solid_2} slot2: {valueInstall.pipe_slotted_2} w2: {valueInstall.pipe_width_2} geosock: {valueInstall.pipe_geosock_2} gastap: {valueInstall.gas_tap_2}</td>
														<td colSpan={2}>cover: {valueInstall.cover_type} covSize: {valueInstall.cover_size} backfill: {valueInstall.layers}  comments: {valueInstall.general_remarks} </td>
													</tr>

													<tr>
														<td className={`px-1 pt-0.5 border-grey-mid rounded-tl-sm rounded-tr-sm border-l border-r border-t`}>{install_1_text}</td>
														<td className={`px-1 pt-0.5 border-grey-mid border-r border-t`}>{install_2_text}</td>
														<td className={`px-1 pb-0.5 border-grey-mid border-r border-t border-b text-center w-8 md:w-12`} rowSpan={2}>
															<div className={"text-center px-0 pb-0.5 edit-background"}
																onClick={() => {
																	setEditingGuid(installGuid)
																	setEditingIndex(0)
																	setShowInstallEdit(true)
																}}
																>
															<img className="inline z-10" width={"16"} src={edit} alt="" />
															</div>
														</td>
													</tr>
													<tr>
														<td className={`px-1 py-1 border-grey-mid rounded-tl-sm rounded-tr-sm border-l border-r border-t`}
															colSpan={2}>{cover_comment_text}
														</td>
													</tr>

												</React.Fragment>
											)
										})}
										{BackfillData.filter((action: any) => {
											return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
										}).map((valueBackfill: any, index: number) => {
											return (
												<React.Fragment key={index}>
													<tr>
														<td className={`px-1 py-1 border-grey-mid rounded-tl-sm rounded-tr-sm border-l border-r border-t border-b`}
															colSpan={2}>{valueBackfill.backfillText}
														</td>
														<td className={`px-1 pb-0.5 border-grey-mid border-r border-t border-b text-center w-8 md:w-12`}>
															<div className={"text-center px-0 pb-0.5 edit-background"}
																onClick={() => {
																	setEditingGuid(backfillGuid)
																	setEditingIndex(0)
																	setShowBackfillEdit(true)
																}}
																>
															<img className="inline z-10" width={"16"} src={edit} alt="" />
															</div>
														</td>
													</tr>
												</React.Fragment>
											)
										})}
									</tbody>
								</table>
							</PanelBackfillInstall>

						</div>  {/* END OF RIGHT SIDE */}

					</div>














					{/* //.................................................................................................................
					//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNN...NNNN..UUUU...UUUU.......BBBBBBBBBB.....OOOOOOO.....DDDDDDDDD...YYYY....YYYY..
					//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNNN..NNNN..UUUU...UUUU.......BBBBBBBBBBB...OOOOOOOOOO...DDDDDDDDDD..YYYYY..YYYYY..
					//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNNN..NNNN..UUUU...UUUU.......BBBBBBBBBBB..OOOOOOOOOOOO..DDDDDDDDDDD..YYYY..YYYY...
					//.MMMMMMM.MMMMMMM.EEEE........NNNNNN.NNNN..UUUU...UUUU.......BBBB...BBBB..OOOOO..OOOOO..DDDD...DDDD..YYYYYYYYY....
					//.MMMMMMM.MMMMMMM.EEEE........NNNNNN.NNNN..UUUU...UUUU.......BBBB...BBBB.OOOOO....OOOOO.DDDD....DDDD..YYYYYYYY....
					//.MMMMMMM.MMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU.......BBBBBBBBBBB.OOOO......OOOO.DDDD....DDDD...YYYYYY.....
					//.MMMMMMMMMMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU.......BBBBBBBBBB..OOOO......OOOO.DDDD....DDDD...YYYYYY.....
					//.MMMMMMMMMMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU.......BBBBBBBBBBB.OOOO......OOOO.DDDD....DDDD....YYYY......
					//.MMMMMMMMMMMMMMM.EEEE........NNNNNNNNNNN..UUUU...UUUU.......BBBB....BBBBOOOOO....OOOOO.DDDD....DDDD....YYYY......
					//.MMMM.MMMMM.MMMM.EEEE........NNNN.NNNNNN..UUUU...UUUU.......BBBB....BBBB.OOOOO..OOOOO..DDDD...DDDDD....YYYY......
					//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN..NNNNN..UUUUUUUUUUU.......BBBBBBBBBBBB.OOOOOOOOOOOO..DDDDDDDDDDD.....YYYY......
					//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN..NNNNN...UUUUUUUUU........BBBBBBBBBBB...OOOOOOOOOO...DDDDDDDDDD......YYYY......
					//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN...NNNN....UUUUUUU.........BBBBBBBBBB......OOOOOO.....DDDDDDDDD.......YYYY......
					//................................................................................................................. */}

					{bhMenuVisible &&

						// <div style={{ display: bhMenuVisible ? 'block' : 'none' }}>
						<div>

							<div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 sm:gap-3 md:gap-4 bg-grey-vdark w-full absolute z-50 top-0 left-0 -mt-3 text-center text-white text-xs md:text-sm pt-2 px-2 md:px-3 lg:px-4 pb-2 md:pb-3 lg:pb-4 rounded-b-lg modalshadow">

								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<Link to={`/projects/update/${project_guid}`}>
										<img src={icon_project} className="h-12 w-12 mx-auto mb-1" alt="" />
										Edit Project
									</Link>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<div onClick={menuAndAGS}>
										<img src={icon_ags} className="h-12 w-12 mx-auto mb-1" alt="" />
										Output AGS
									</div>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<div onClick={menuAndEmail}>
										<img src={icon_email} className="h-12 w-12 mx-auto mb-1" alt="" />
										Send Email
									</div>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<Link to={`/rigs/${company_guid}/${project_guid}`}>
										<img src={icon_editrig} className="h-12 w-12 mx-auto mb-1" alt="" />
										Rig
									</Link>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									{/* <Link to={`/daily-plant-check-list-window-sample/${plantID}/${company_guid}/${project_guid}`}> */}
									<Link to={`/daily-plant-check-list-window-sample/${company_guid}/${project_guid}`}>
										<img src={icon_plantcheck} className="h-12 w-12 mx-auto mb-1" alt="" />
										Daily Plant Check
									</Link>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<button className="items-center justify-center px-2 bold mx-auto" onClick={menuAndLabels} >
										<img src={icon_labels} className="w-12 mx-auto" alt="" />
										View Labels
									</button>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<button className="items-center justify-center px-2 bold mx-auto" onClick={menuAndPreview} >
										<img src={icon_preview} className="h-12 w-12 mx-auto mb-1" alt="" />
										Preview Log
									</button>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<div onClick={menuAndSaveLog}>
										<img src={icon_savelog} className="h-12 w-12 mx-auto mb-1" alt="" />
										Save Log
									</div>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									{/* <Link to={`/#`} onClick={this.bhMenuClick}> */}
									{/* <Link to={`#`} onClick={menuAndMarkAsComplete}>
										<img src={icon_complete} className="h-12 w-12 mx-auto mb-1" alt="" />
										Mark As Complete
									</Link> */}
									<div onClick={menuAndEndShift}>
										<img src={icon_endshift} className="h-12 w-12 mx-auto mb-1" alt="" />
										End Shift
									</div>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									{/* <Link to={`/newhole/CP/${project_guid}/${rig_id}/${leadDriller}/${secondMan}`}> */}
									<Link to={`/newhole/WS/${project_guid}`}>
										<img src={icon_newborehole} className="h-12 w-12 mx-auto mb-1" alt="" />
										New Borehole
									</Link>
								</div>

								<div className="col-span-3 md:col-span-4 lg:col-span-5">
									<div className="grid grid-cols-2 gap-2 sm:gap-3 md:gap-4 ">
										<div className="border border-grey-dark rounded py-2 text-lg">
											<Link to={`/projects/dashboard/company/${company_guid}/${project_guid}/window-sample`}>
												Borehole Select
											</Link>
										</div>
										<div className="border border-grey-dark rounded py-2 text-lg">
											<Link to={`/projects/dashboard/${project_guid}/company/${company_guid}`}>
												Change Activity
											</Link>
										</div>
									</div>
								</div>

							</div>

						</div>

					}

				</div> {/* end of menu container */}














				{/* //.....................................................................................
				//.PPPPPPPPP...PRRRRRRRRR...REEEEEEEEEEEEVV....VVVVVVIII.IEEEEEEEEEEEEWW..WWWWW...WWW..
				//.PPPPPPPPPP..PRRRRRRRRRR..REEEEEEEEEEEEVV....VVVVVVIII.IEEEEEEEEEEEEWW..WWWWW..WWWW..
				//.PPPPPPPPPPP.PRRRRRRRRRR..REEEEEEEEEEEEVV....VVVVVVIII.IEEEEEEEEEEEEWW..WWWWWW.WWWW..
				//.PPPP...PPPP.PRRR...RRRRR.REEE.......EEVVV..VVVV.VVIII.IEEE.......EEWW.WWWWWWW.WWWW..
				//.PPPP...PPPP.PRRR...RRRRR.REEE........EVVV..VVVV.VVIII.IEEE.......EEWW.WWWWWWW.WWWW..
				//.PPPPPPPPPPP.PRRRRRRRRRR..REEEEEEEEE..EVVV..VVVV.VVIII.IEEEEEEEEE..EWWWWWWWWWW.WWW...
				//.PPPPPPPPPP..PRRRRRRRRRR..REEEEEEEEE..EVVVVVVVV..VVIII.IEEEEEEEEE..EWWWWWW.WWWWWWW...
				//.PPPPPPPPP...PRRRRRRR.....REEEEEEEEE...VVVVVVVV..VVIII.IEEEEEEEEE..EWWWWWW.WWWWWWW...
				//.PPPP........PRRR.RRRR....REEE.........VVVVVVVV..VVIII.IEEE........EWWWWWW.WWWWWWW...
				//.PPPP........PRRR..RRRR...REEE.........VVVVVVV...VVIII.IEEE........EWWWWWW.WWWWWWW...
				//.PPPP........PRRR..RRRRR..REEEEEEEEEE...VVVVVV...VVIII.IEEEEEEEEEE..WWWWW...WWWWW....
				//.PPPP........PRRR...RRRRR.REEEEEEEEEE...VVVVVV...VVIII.IEEEEEEEEEE..WWWWW...WWWWW....
				//.PPPP........PRRR....RRRR.REEEEEEEEEE...VVVVV....VVIII.IEEEEEEEEEE..WWWWW...WWWWW....
				//..................................................................................... */}

				{previewVisible &&

					<>

						{/* LOGSHEET */}

						<div className="flex flex-col rounded-lg border border-orange bg-modal-bg px-2 sm:px-2 md:px-2 pb-6 modalshadow previewLogsheet z-50" >

							<div className="flex items-center border-b border-grey-dark py-2">

								<div className="flex-1 flex items-left">

								</div>

								<div className="flex-1 flex justify-center text-lg sm:text-xl font-bold text-text leading-6 text-center mx-auto">
									Preview Logsheet
								</div>

								<div className="flex-1 flex justify-center">
									<span className="ml-auto">
										<button className="flex items-center justify-center w-8 h-8 text-4xl bold text-soil-grey bg-white border border-orange rounded" onClick={previewClick} ><img src={closeIcon} width="32" alt="" /></button>
									</span>
								</div>
							</div>

							<div className="w-full overflow-scroll">

								<div className="logsheetContainer mx-auto pb-6">

									<div className="mainContainer">

										<table className="w-full border-b-none">
											<tbody>
												{/* HEADER TABLE */}
												<tr>
													<td colSpan={2}>
														<div className="headerTableHolder border border-grey-mid">
															<table className="noTableBorder text-center w-full">
																<tbody>
																	<tr>
																		<td rowSpan={3} className="headerLeft">{/* LOGO */}<img className="mt-1" src={companyLogo} /></td>
																		<td colSpan={4} className="headerTitle">Daily Window Sample Record</td>
																		<td rowSpan={2} colSpan={2} className="headerRight headerBarcodeHolder pt-3 pl-5 text-center">
																			{/* <svg id="barcode" className="barcodeCSS"></svg> */}
																			<Barcode value={labelbarcodeVar}
																				options={{
																					format: 'code128',
																					//width: 2,
																					width: barcodeWidth,
																					height: 60,
																					fontSize: 12,
																					//marginLeft: 80,
																					//text: barcodeVar
																					//background: "#ccffff"
																				}} />
																		</td>
																	</tr>
																	<tr>
																		<td className="headerCellTitleLeft bg-sptTB">Location:</td>
																		<td className={`${logsheetLocationStyle}`}>{projectLocation}</td>
																		<td className="headerCellTitleRight bg-sptTB">Client:</td>
																		<td className="headerCellInputRight">{client}</td>
																	</tr>
																	<tr>
																		<td className="headerCellTitleLeft bg-sptTB">Project ID:</td>
																		<td id="projectID" className="headerCellInputLeft">{projectID}</td>
																		<td className="headerCellTitleRight bg-sptTB">BH ID:</td>
																		<td id="boreholeID" className="headerCellInputRight">{borehole_name}</td>
																		<td id="headerDate">{sheetDate}</td>
																		<td id="headerSheets">Sheet 1 of 1</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</td>
												</tr>
												{/* END OF HEADER TABLE */}

												<tr>
													<td className="w-2/4 align-top border-r border-grey-mid"> {/* LEFT SIDE */}
														{/* SOIL DESCRIPTIONS */}
														<div className="soilDescriptionHolder w-full align-top border-l border-b border-grey-mid">
															{/* ////////////////////////////////////////////////////// */}
															<table className="soilTable noTableBorder">
																<tbody>
																	<tr>
																		<td className="tableRow soilDepth bg-sptTB">From:</td>
																		<td className="tableRow soilDepth bg-sptTB">To:</td>
																		<td className="tableRow soilDescription bg-grey-mid sectionTitle text-center">Geology Description</td>
																		<td className="tableRow soilLegend bg-sptTB" style={{ fontSize: '7px' }}>Legend</td>
																	</tr>
																	{/* ////////////////////////////////////////////////////// */}
																	{/* ROW */}
																	<tr>
																		<td colSpan={4} className="soilDescriptionsRowsHolder" >

																			<table className="h-full w-full">

																				{SoilDescriptionData?.filter((action: any) => {
																					return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
																				}).map((valueSoilDesc: any, index: number) => {

																					// Replace depth_to_top = 0 with GL
																					let depth_to_top_temp
																					if (valueSoilDesc.depth_to_top !== undefined && valueSoilDesc.depth_to_top === '0.00' || valueSoilDesc.depth_to_top === '0') {
																						depth_to_top_temp = 'GL'
																					} else {
																						depth_to_top_temp = valueSoilDesc.depth_to_top
																					}

																					let stratum_type_css = ''
																					if (valueSoilDesc.stratum_type === 'TOPSOIL') { stratum_type_css = 'soilTopsoil' }
																					if (valueSoilDesc.stratum_type === 'SAND') { stratum_type_css = 'soilSand' }
																					if (valueSoilDesc.stratum_type === 'GRAVEL') { stratum_type_css = 'soilGravel' }
																					if (valueSoilDesc.stratum_type === 'SANDGRAVEL') { stratum_type_css = 'soilGravel' }
																					if (valueSoilDesc.stratum_type === 'CLAY') { stratum_type_css = 'soilClay' }
																					if (valueSoilDesc.stratum_type === 'SILT') { stratum_type_css = 'soilSilt' }
																					if (valueSoilDesc.stratum_type === 'PEAT') { stratum_type_css = 'soilPeat' }
																					if (valueSoilDesc.stratum_type === 'CHALK') { stratum_type_css = 'soilChalk' }
																					if (valueSoilDesc.stratum_type === 'MADEGROUND') { stratum_type_css = 'soilMadeground' }
																					if (valueSoilDesc.stratum_type === 'LIMESTONE') { stratum_type_css = 'soilLimestone' }
																					if (valueSoilDesc.stratum_type === 'DOLOMITE') { stratum_type_css = 'soilRock' }
																					if (valueSoilDesc.stratum_type === 'SANDSTONE') { stratum_type_css = 'soilSandstone' }
																					if (valueSoilDesc.stratum_type === 'SILTSTONE') { stratum_type_css = 'soilSiltstone' }
																					if (valueSoilDesc.stratum_type === 'GYPSUM') { stratum_type_css = 'soilGypsum' }
																					if (valueSoilDesc.stratum_type === 'MUDSTONE') { stratum_type_css = 'soilMudstone' }
																					if (valueSoilDesc.stratum_type === 'CHERT') { stratum_type_css = 'soilRock' }
																					if (valueSoilDesc.stratum_type === 'SHALE') { stratum_type_css = 'soilShale' }
																					if (valueSoilDesc.stratum_type === 'GRANITE') { stratum_type_css = 'soilRock' }
																					if (valueSoilDesc.stratum_type === 'DIORITE') { stratum_type_css = 'soilRock' }
																					if (valueSoilDesc.stratum_type === 'RHYOLITE') { stratum_type_css = 'soilRock' }
																					if (valueSoilDesc.stratum_type === 'ANDERSITE') { stratum_type_css = 'soilRock' }
																					if (valueSoilDesc.stratum_type === 'BASALT') { stratum_type_css = 'soilRock' }
																					if (valueSoilDesc.stratum_type === 'OBSIDIAN') { stratum_type_css = 'soilRock' }
																					if (valueSoilDesc.stratum_type === 'MARBLE') { stratum_type_css = 'soilRock' }
																					if (valueSoilDesc.stratum_type === 'QUARTZITE') { stratum_type_css = 'soilRock' }
																					if (valueSoilDesc.stratum_type === 'SLATE') { stratum_type_css = 'soilRock' }
																					if (valueSoilDesc.stratum_type === 'COAL') { stratum_type_css = 'soilCoal' }

																					return (
																						<React.Fragment key={index}>
																							<tr className="hidden invisible">
																								<td>{valueSoilDesc.stratum_type}</td>
																							</tr>
																							<tr>
																								<td className="soilDepth">{valueSoilDesc.depth_to_top}</td>
																								<td className="soilDepth">{valueSoilDesc.depth_to_base}</td>
																								<td className="soilDescription">{valueSoilDesc.stratum_description}</td>
																								<td className={`soilLegend ${stratum_type_css}`}></td>
																							</tr>
																						</React.Fragment>
																					)
																				})}

																			</table>

																		</td>
																	</tr>

																	{/* ////////////////////////////////////////////////////// */}
																</tbody>
															</table>
														</div>
														{/* END OF SOIL DESCRIPTIONS */}

														<table className="align-top noTableBorder">
															<tbody>
																<tr>
																	<td>
																		{/* WEATHER */}
																		<div className="weatherHolder border-l border-grey-mid">
																			<table className="h-full w-full text-center noTableBorder">
																				<tbody>
																					<tr>
																						<td className="tableRow bg-sptTB sectionTitle weatherTitle pt-1.5">Weather<br />Details:</td>
																						{/* ////////////////////////////////////////////////////// */}
																						{/* <td className="tableRow weatherInput pt-4">Hot & Sunny</td> */}
																						<td className="tableRow weatherInput">
																							<div>
																								<i className={"text-xl m-0 pt-1 " + weatherIcon}></i>
																							</div>
																							<div className="-mt-1">
																								{selectedWeather}
																							</div>
																						</td>
																						{/* ////////////////////////////////////////////////////// */}
																					</tr>
																				</tbody>
																			</table>
																		</div>
																		{/* END OF WEATHER */}
																	</td>
																	<td>
																		{/* INSPECTION PIT */}
																		<div className="inspectionPitHolder">
																			<table className="w-full text-center ">
																				<tbody>
																					<tr>
																						<td colSpan={3} className="border-b border-grey-mid tableRow bg-grey-mid sectionTitle ">Inspection Pit</td>
																					</tr>
																					<tr>
																						<td className="border-b border-grey-mid tableRow bg-sptTB ipDepth">From (m)</td>
																						<td className="border-b border-grey-mid tableRow bg-sptTB ipDepth">To (m)</td>
																						<td className="border-b border-grey-mid tableRow bg-sptTB ipDuration">Duration</td>
																					</tr>
																					<tr>
																						{/* ////////////////////////////////////////////////////// */}

																						{InspectionPitData?.filter((action: any) => {
																							return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
																						}).map((valueInspectionPit: any, index: number) => {

																							// Time
																							const timeString = valueInspectionPit.duration
																							const formattedTime = timeString.slice(0, -3); // Removes the last 3 characters
																							// console.log('timeString, formattedTime:', timeString, formattedTime)
																							return (
																								<React.Fragment key={index}>
																									<td className="tableRow ipDepth">GL</td>
																									<td className="tableRow ipDepth">{valueInspectionPit.depth_to_base}</td>
																									<td className="tableRow ipDuration">{timeString}</td>
																								</React.Fragment>
																							)
																						})}

																						{/* ////////////////////////////////////////////////////// */}
																					</tr>
																				</tbody>
																			</table>
																		</div>
																		{/* END OF INSPECTION PIT */}
																	</td>
																</tr>
															</tbody>
														</table>

														{/* GROUNDWATER */}
														<div className="groundwaterHolder border-b border-grey-mid">
															<table className="groundwaterTable noTableBorder">
																<tbody>
																	<tr>
																		<td colSpan={10} className="tableRow bg-grey-mid sectionTitle">Groundwater</td>
																	</tr>
																	<tr>
																		<td rowSpan={2} className="tableRowDouble bg-sptTB groundwaterCell">Time Struck</td>
																		<td rowSpan={2} className="tableRowDouble bg-sptTB groundwaterCell">Depth (m)</td>
																		<td rowSpan={2} className="tableRowDouble bg-sptTB groundwaterInflow">Inflow Rate</td>
																		<td colSpan={4} className="tableRow bg-sptTB groundwaterTimeTitle">Depth After mins (m)</td>
																		{/* <td rowSpan={2} className="tableRowDouble bg-sptTB groundwaterSealSample">Sample<br />#</td> */}
																	</tr>
																	<tr>
																		<td className="tableRow bg-sptSB groundwaterTimeCell">5</td>
																		<td className="tableRow bg-sptSB groundwaterTimeCell">10</td>
																		<td className="tableRow bg-sptSB groundwaterTimeCell">15</td>
																		<td className="tableRow bg-sptSB groundwaterTimeCell">20</td>
																	</tr>
																	{/* ////////////////////////////////////////////////////// */}

																	{GroundWaterTestsData?.filter((action: any) => {
																		return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
																	}).map((valueGroundwater: any, index: number) => {

																		// Time
																		const formattedTime = valueGroundwater.time_of_strike.split(' ')[1].substring(0, 5);

																		return (
																			<React.Fragment key={index}>
																				<tr className="border-b border-grey-mid">
																					<td className="tableRow groundwaterCell">{formattedTime}</td>
																					<td className="tableRow groundwaterCell">{valueGroundwater.depth_to_water}</td>
																					<td className="tableRow groundwaterInflow">{valueGroundwater.rate_of_flow}</td>
																					<td className="tableRow groundwaterTimeCell">{valueGroundwater.depth_after_5}</td>
																					<td className="tableRow groundwaterTimeCell">{valueGroundwater.depth_after_10}</td>
																					<td className="tableRow groundwaterTimeCell">{valueGroundwater.depth_after_15}</td>
																					<td className="tableRow groundwaterTimeCell">{valueGroundwater.depth_after_20}</td>
																					{/* <td className="tableRow groundwaterSealSample">WS#</td> */}
																				</tr>
																			</React.Fragment>
																		)
																	})}

																	{/* ////////////////////////////////////////////////////// */}
																</tbody>
															</table>
														</div>
														{/* END OF GROUNDWATER */}
														{/* BACKFILL */}
														<div className="backfillHolder w-full border-l border-grey-mid">
															<table className="w-full noTableBorder">
																<tbody>
																	<tr>
																		<td className="tableRow bg-grey-mid sectionTitle backfillTitle">Backfill / Standpipe Details</td>
																	</tr>
																	{/* ////////////////////////////////////////////////////// */}
																	{InstallationData?.filter((action: any) => {
																		return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
																	}).map((valueInstall: any, index: number) => {
																		let install_1_text
																		if (valueInstall.pipe_width_1 || valueInstall.pipe_solid_1 || valueInstall.pipe_slotted_1) {
																			if (valueInstall.pipe_width_1) { install_1_text = '#1: ' + valueInstall.pipe_width_1 + 'mm pipe to ' }
																			else { install_1_text = '#1: Pipe to ' }
																			if (!valueInstall.pipe_solid_1 && !valueInstall.pipe_slotted_1) { install_1_text = install_1_text }
																			if (valueInstall.pipe_solid_1 && !valueInstall.pipe_slotted_1) { install_1_text = install_1_text + (valueInstall.pipe_solid_1) + 'm (' + valueInstall.pipe_solid_1 + 'm solid)' }
																			if (!valueInstall.pipe_solid_1 && valueInstall.pipe_slotted_1) { install_1_text = install_1_text + (valueInstall.pipe_slotted_1) + 'm (' + valueInstall.pipe_slotted_1 + 'm slotted)' }
																			if (valueInstall.pipe_solid_1 && valueInstall.pipe_slotted_1) { install_1_text = install_1_text + (+valueInstall.pipe_solid_1 + +valueInstall.pipe_slotted_1) + 'm (' + valueInstall.pipe_solid_1 + 'm solid & ' + valueInstall.pipe_slotted_1 + 'm slotted)' }
																			if (valueInstall.pipe_geosock_1) { install_1_text = install_1_text + ' + geosock' }
																			if (valueInstall.gas_tap_1) { install_1_text = install_1_text + ' & gastap' }
																		} else { install_1_text = '' }
																		// INSTALL #2
																		let install_2_text
																		if (valueInstall.pipe_width_2 || valueInstall.pipe_solid_2 || valueInstall.pipe_slotted_2) {
																			if (valueInstall.pipe_width_2) { install_2_text = '#2: ' + valueInstall.pipe_width_2 + 'mm pipe to ' }
																			else { install_2_text = '#2: Pipe to ' }
																			if (!valueInstall.pipe_solid_2 && !valueInstall.pipe_slotted_2) { install_2_text = install_2_text }
																			if (valueInstall.pipe_solid_2 && !valueInstall.pipe_slotted_2) { install_2_text = install_2_text + (valueInstall.pipe_solid_2) + 'm (' + valueInstall.pipe_solid_2 + 'm solid)' }
																			if (!valueInstall.pipe_solid_2 && valueInstall.pipe_slotted_2) { install_2_text = install_2_text + (valueInstall.pipe_slotted_2) + 'm (' + valueInstall.pipe_slotted_2 + 'm slotted)' }
																			if (valueInstall.pipe_solid_2 && valueInstall.pipe_slotted_2) { install_2_text = install_2_text + (+valueInstall.pipe_solid_2 + +valueInstall.pipe_slotted_2) + 'm (' + valueInstall.pipe_solid_2 + 'm solid & ' + valueInstall.pipe_slotted_2 + 'm slotted)' }
																			if (valueInstall.pipe_geosock_2) { install_2_text = install_2_text + ' + geosock' }
																			if (valueInstall.gas_tap_2) { install_2_text = install_2_text + ' & gastap' }
																		} else { install_2_text = '' }

																		let cover_comment_text = ''
																		if (valueInstall.cover_size) {
																			cover_comment_text = valueInstall.cover_size + ' - ' + valueInstall.cover_type
																		} else {
																			cover_comment_text = valueInstall.cover_type
																		}
																		if (valueInstall.general_remarks) { cover_comment_text = cover_comment_text + ' - ' + valueInstall.general_remarks }


																		return (
																			<React.Fragment key={index}>
																				<tr>
																					<td className="w-full align-top text-left pl-2 pt-1 pb-1">
																						{install_1_text}<br />
																						{install_2_text}<br />
																						{backfillText}<br />
																						{cover_comment_text}<br />
																					</td>
																				</tr>
																			</React.Fragment>
																		)
																	})}
																	{/* ////////////////////////////////////////////////////// */}
																	{/* <tr>
																		<td>
																			{backfillText}
																		</td>
																	</tr> */}
																</tbody>
															</table>
														</div>
														{/* END OF BACKFILL */}
														{/* COMMENTS */}
														<div className="standingTimeHolder w-full border-b border-l border-grey-mid">
															<table className="w-full align-top noTableBorder">
																<tbody>
																	<tr>
																		<td className="tableRow bg-grey-mid sectionTitle">Comments / Standing Time / Dayworks</td>
																	</tr>
																	<tr>
																		<td className="commentsInput">
																			{/* ////////////////////////////////////////////////////// */}

																			{CommentsData?.filter((action: any) => {
																				return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
																			}).map((valueComments: any, index: number) => {

																				let duration_formatted = ''
																				if (valueComments.duration !== null) {
																					duration_formatted = '(' + valueComments.duration + ')'
																				} else {
																					duration_formatted = ''
																				}

																				return (

																					// work out total standing time & dayworks and
																					// setTotalStandingTime()
																					// setTotalDayworks()
																					// based on duration


																					<React.Fragment key={index}>

																						{valueComments.comment_type} - {valueComments.general_remarks} {duration_formatted}.&nbsp;

																					</React.Fragment>
																				)
																			})}





																			{/* ////////////////////////////////////////////////////// */}
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
														{/* END OF COMMENTS */}
														{/* STANDING TIME / DAYWORKS TOTALS */}
														<div className="standingTimeTotalsHolder w-full border-b border-l border-grey-mid">
															<table className="standingTimeTable w-full text-center noTableBorder">
																<tbody>
																	<tr>
																		<td className="tableRow bg-grey-mid sectionTitle standingTimeCategories">Total Standing Time:</td>
																		{/* ////////////////////////////////////////////////////// */}
																		<td className="tableRow sectionTitle standingTimeInputs">{totalStandingTime}</td>
																		{/* ////////////////////////////////////////////////////// */}
																		<td className="tableRow bg-grey-mid sectionTitle standingTimeCategories">Total Dayworks:</td>
																		{/* ////////////////////////////////////////////////////// */}
																		<td className="tableRow sectionTitle standingTimeInputs">{totalDayworks}</td>
																		{/* ////////////////////////////////////////////////////// */}
																	</tr>
																</tbody>
															</table>
														</div>
														{/* END OF STANDING TIME / DAYWORKS TOTALS */}

													</td> {/* END OF LEFT SIDE */}





													<td className="w-2/4 align-top"> {/* LEFT SIDE */}

														{/* SAMPLING & TESTING */}
														<div className="">
															<div className="sampTableHolder border-r border-grey-mid">
																<table className="w-full text-center noTableBorder">
																	<tbody>
																		<tr>
																			<td colSpan={12} className="tableRow soilDepth bg-grey-mid sectionTitle">Samples</td>
																		</tr>
																		{/* ROW */}
																		<tr>
																			<td rowSpan={2} className="tableRowDouble pt-2 sampType bg-sptTB sectionTitle">Type/#</td>
																			<td colSpan={2} className="tableRow bg-sptTB  sampDepthTitle">Depth (m)</td>
																			<td rowSpan={2} className="tableRowDouble sampDepth  bg-sptTB">Casing Depth</td>
																			<td rowSpan={2} className="tableRowDouble bg-sptTB sampDepth">Water Depth</td>
																			<td rowSpan={2} className="tableRowDouble bg-sptTB textCenter" colSpan={7}>Notes</td>
																		</tr>
																		<tr>
																			<td className="tableRow bg-sptSB sampDepth">From</td>
																			<td className="tableRow bg-sptSB sampDepth">To</td>
																		</tr>
																		{/* ////////////////////////////////////////////////////// */}

																		{SamplesData?.filter((action: any) => {
																			return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
																		}).map((valueSamples: any, index: number) => {

																			// Replace depth_to_top = 0 with GL
																			let depth_to_top_temp, depth_to_base_temp, casing_depth_temp, water_depth_temp
																			if (valueSamples.depth_to_top !== undefined && typeof valueSamples.depth_to_top === 'string' && valueSamples.depth_to_top === '0.00' || valueSamples.depth_to_top === '0') {
																				depth_to_top_temp = 'GL'
																			} else if (valueSamples.depth_to_top !== undefined && typeof valueSamples.depth_to_top === 'number' && valueSamples.depth_to_top === 0.00 || valueSamples.depth_to_top === 0) {
																				depth_to_top_temp = 'GL'
																			} else {
																				depth_to_top_temp = valueSamples.depth_to_top
																			}

																			let casingDepth = ''
																			if (valueSamples.casing_depth === 'no casing') {
																				casingDepth = '-'
																			} else {
																				casingDepth = valueSamples.casing_depth
																			}

																			return (
																				<React.Fragment key={index}>

																					<tr className="border-b border-grey-mid">
																						<td className="tableRow sampType">{valueSamples.type}-{valueSamples.type_number}</td>
																						<td className="tableRow sampDepth">{depth_to_top_temp}</td>
																						<td className="tableRow sampDepth">{valueSamples.depth_to_base}</td>
																						<td className="tableRow sampDepth">{casingDepth}</td>
																						<td className="tableRow sampDepth">{valueSamples.water_depth}</td>
																						<td className="tableRow text-left pl-1" colSpan={7}>{valueSamples.general_remarks}</td>
																					</tr>

																				</React.Fragment>

																			)
																		})}

																	</tbody>
																</table>
															</div>
															<div className="testsTableHolder border-r border-grey-mid">

																<table className="noTableBorder w-full text-center align-top">
																	<tbody>
																		<tr>
																			<td colSpan={12} className="tableRow soilDepth bg-grey-mid sectionTitle">Tests</td>
																		</tr>
																		{/* ROW */}
																		<tr>
																			<td rowSpan={2} className="tableRowDouble sampType bg-sptTB sectionTitle">Type/#</td>
																			<td colSpan={2} className="tableRow bg-sptTB  sampDepthTitle">Depth (m)</td>
																			<td rowSpan={2} className="tableRowDouble sampDepth  bg-sptTB">Casing Depth</td>
																			<td rowSpan={2} className="tableRowDouble bg-sptTB sampDepth">Water Depth</td>
																			<td colSpan={2} className="tableRow bg-sptTB sampBlowsTitle">Blows</td>
																			<td colSpan={4} className="tableRow bg-sptTB sampDrivesTitle">Test Drives</td>
																			<td rowSpan={2} className="tableRowDouble bg-sptTB textCenter">Notes</td>
																		</tr>
																		<tr>
																			<td className="tableRow bg-sptSB sampDepth">From</td>
																			<td className="tableRow bg-sptSB sampDepth">To</td>
																			<td className="tableRow bg-sptSB sampBlows boldText">75</td>
																			<td className="tableRow bg-sptSB sampBlows boldText">75</td>
																			<td className="tableRow bg-sptSB sampDrives boldText">75</td>
																			<td className="tableRow bg-sptSB sampDrives boldText">75</td>
																			<td className="tableRow bg-sptSB sampDrives boldText">75</td>
																			<td className="tableRow bg-sptSB sampDrives boldText">75</td>
																		</tr>
																		{/* ////////////////////////////////////////////////////// */}

																		{InSituTestsData?.filter((action: any) => {
																			return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
																		}).map((valueInSituTest: any, index: number) => {

																			if (valueInSituTest.test_blows_1st_inc === '') { var blows1 = 0 } else { blows1 = valueInSituTest.test_blows_1st_inc }
																			if (valueInSituTest.test_blows_2nd_inc === '') { var blows2 = 0 } else { blows2 = valueInSituTest.test_blows_2nd_inc }
																			if (valueInSituTest.test_blows_3rd_inc === '') { var blows3 = 0 } else { blows3 = valueInSituTest.test_blows_3rd_inc }
																			if (valueInSituTest.test_blows_4th_inc === '') { var blows4 = 0 } else { blows4 = valueInSituTest.test_blows_4th_inc }

																			const totalBlows = (+blows1 + +blows2 + +blows3 + +blows4)
																			//console.log(totalBlows)

																			let depth_of_test_temp, depth_to_base_temp, casing_depth_temp, water_depth_temp
																			if (valueInSituTest.type === 'SV') {
																				// Replace depth_of_test = 0 with GL
																				if (valueInSituTest.depth_of_test !== undefined &&
																					valueInSituTest.depth_of_test === '0.00' || valueInSituTest.depth_of_test === '0' || valueInSituTest.depth_of_test === 0
																				) {
																					depth_of_test_temp = 'GL'
																				} else {
																					depth_of_test_temp = valueInSituTest.depth_to_top
																				}
																			} else {
																				// Replace depth_of_test = 0 with GL
																				if (valueInSituTest.depth_to_top !== undefined &&
																					valueInSituTest.depth_to_top === '0.00' || valueInSituTest.depth_to_top === '0' || valueInSituTest.depth_to_top === 0
																				) {
																					depth_of_test_temp = 'GL'
																				} else {
																					depth_of_test_temp = valueInSituTest.depth_to_top
																				}
																			}
																			let casingDepth = ''
																			if (valueInSituTest.casing_depth === 'no casing') {
																				casingDepth = '-'
																			} else {
																				casingDepth = valueInSituTest.casing_depth
																			}
																			const penetration = (+valueInSituTest.pen_1 + +valueInSituTest.pen_2 + +valueInSituTest.pen_3 + +valueInSituTest.pen_4 + +valueInSituTest.pen_5 + +valueInSituTest.pen_6)
																			
																			if (valueInSituTest.type === 'SV') {
																				return (
																					<React.Fragment key={index}>
																						<tr className="hidden invisible">
																							<td>{valueInSituTest.result1} | {valueInSituTest.result2} | {valueInSituTest.result3} | {valueInSituTest.result4} | {valueInSituTest.suitableForTesting}</td>
																						</tr>

																						<tr className="border-b border-grey-mid">
																							<td className="tableRow sampType">{valueInSituTest.type}-{valueInSituTest.type_number}</td>
																							<td className="tableRow sampDepth">{depth_of_test_temp}</td>
																							<td className="tableRow sampDepth text-left pl-1" colSpan={3}>Type: {valueInSituTest.test_type}</td>
																							<td className="tableRow sampBlows text-left pl-1" colSpan={6}>Size: {valueInSituTest.size}</td>
																							<td className="tableRow text-left pl-1">Average: {valueInSituTest.average} kPa</td>
																						</tr>

																					</React.Fragment>
																				)
																			} else if (valueInSituTest.type === 'U') {
																				return (
																					<React.Fragment key={index}>

																						<tr className="border-b border-grey-mid">
																							<td className="tableRow sampType">{valueInSituTest.type}{valueInSituTest.utype}-{valueInSituTest.type_number}</td>
																							<td className="tableRow sampDepth">{depth_of_test_temp}</td>
																							<td className="tableRow sampDepth">{valueInSituTest.depth_to_base}</td>
																							<td className="tableRow sampDepth">{casingDepth}</td>
																							<td className="tableRow sampDepth">{valueInSituTest.depth_of_water}</td>
																							<td className="tableRow sampBlows text-left pl-1" colSpan={6}>Blows: {valueInSituTest.ublows}</td>
																							<td className="tableRow text-left pl-1">Recovery: {valueInSituTest.recovery}mm</td>
																						</tr>

																					</React.Fragment>
																				)
																			} else if (valueInSituTest.type === 'PS') {
																				return (
																					<React.Fragment key={index}>

																						<tr className="border-b border-grey-mid">
																							<td className="tableRow sampType">{valueInSituTest.type}-{valueInSituTest.type_number}</td>
																							<td className="tableRow sampDepth">{depth_of_test_temp}</td>
																							<td className="tableRow sampDepth">{valueInSituTest.depth_to_base}</td>
																							<td className="tableRow sampDepth">{casingDepth}</td>
																							<td className="tableRow sampDepth">{valueInSituTest.depth_of_water}</td>
																							<td className="tableRow sampBlows text-left pl-1" colSpan={7}>Recovery: {valueInSituTest.recovery}mm</td>
																						</tr>

																					</React.Fragment>
																				)
																			} else {
																				return (
																					<React.Fragment key={index}>

																						<tr className="border-b border-grey-mid">
																							<td className="tableRow sampType">{valueInSituTest.type}-{valueInSituTest.type_number}</td>
																							<td className="tableRow sampDepth">{depth_of_test_temp}</td>
																							<td className="tableRow sampDepth">{valueInSituTest.depth_to_base}</td>
																							<td className="tableRow sampDepth">{casingDepth}</td>
																							<td className="tableRow sampDepth">{valueInSituTest.depth_of_water}</td>
																							<td className="tableRow sampBlows">{valueInSituTest.seating_blows_1st_inc}</td>
																							<td className="tableRow sampBlows">{valueInSituTest.seating_blows_2nd_inc}</td>
																							<td className="tableRow sampDrives">{valueInSituTest.test_blows_1st_inc}</td>
																							<td className="tableRow sampDrives">{valueInSituTest.test_blows_2nd_inc}</td>
																							<td className="tableRow sampDrives">{valueInSituTest.test_blows_3rd_inc}</td>
																							<td className="tableRow sampDrives">{valueInSituTest.test_blows_4th_inc}</td>
																							{/* <td className="tableRow text-left pl-1"><span className="font-bold">{totalBlows} blows</span> ({valueInSituTest.seating_blows_1st_inc},{valueInSituTest.seating_blows_2nd_inc}/{blows1},{blows2},{blows3},{blows4} N={totalBlows})</td> */}
																							<td className="tableRow text-left pl-1"><span className="font-bold">N={totalBlows}</span> (Penetration: {penetration}mm)</td>
																						</tr>

																					</React.Fragment>
																				)
																			}

																		})}

																	</tbody>
																</table>
															</div>
														</div>
														{/* END OF SAMPLING & TESTING */}


														{/* DRIVE RECORDS */}
														<div className="w-full driveRecordsHolder border-r border-grey-mid">
															<table className="w-full text-center noTableBorder">
																<tbody>
																	<tr>
																		<td colSpan={6} className="tableRow bg-grey-mid sectionTitle">Drive Records</td>
																	</tr>
																	<tr>
																		<td className="tableRow bg-sptTB driveRecordNum">#</td>
																		<td className="tableRow bg-sptTB driveRecordDepth">From (m)</td>
																		<td className="tableRow bg-sptTB driveRecordDepth">To (m)</td>
																		<td className="tableRow bg-sptTB driveRecordHoleDepth">Hole Depth (m)</td>
																		<td className="tableRow bg-sptTB driveRecordSamp">Sample Length (m)</td>
																		<td className="tableRow bg-sptTB driveRecordDiam">Diameter (mm)</td>
																	</tr>
																	{/* ////////////////////////////////////////////////////// */}
																	{DriveRecordData?.filter((action: any) => {
																		return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
																	}).map((valueDriveRecords: any, index: number) => {
																		return (
																			<React.Fragment key={index}>
																				<tr>
																					<td className={`tableRow bg-sptTB driveRecordNum font-bold`}>L-{valueDriveRecords.drive_number}</td>
																					<td className={`tableRow driveRecordDepth`}>{valueDriveRecords.depth_from}</td>
																					<td className={`tableRow driveRecordDepth`}>{valueDriveRecords.depth_to}</td>
																					<td className={`tableRow driveRecordHoleDepth`}>{valueDriveRecords.hole_depth}</td>
																					<td className={`tableRow driveRecordDiam`}>{valueDriveRecords.sample_length}</td>
																					<td className={`tableRow driveRecordSamp`}>{valueDriveRecords.liner_diameter}</td>
																				</tr>
																			</React.Fragment>
																		)
																	})}
																	{/* ////////////////////////////////////////////////////// */}
																</tbody>
															</table>
														</div>
														{/* END OF DRIVE RECORDS */}

														{/* BH TOTALS */}
														<div className="completedTotalsHolder border-r border-grey-mid">
															<table className="w-full text-center noTableBorder">
																<tbody>
																	<tr>
																		<td colSpan={10} className="tableRow bg-grey-mid sectionTitle">Completed Totals for Borehole</td>
																	</tr>
																	<tr>
																		<td className="tableRow bg-sptTB sectionTitle bhTotalsDepth">Depth (m)</td>
																		<td className="tableRow bg-sptTB sectionTitle bhTotalsSamples">D</td>
																		<td className="tableRow bg-sptTB sectionTitle bhTotalsSamples">B</td>
																		<td className="tableRow bg-sptTB sectionTitle bhTotalsSamples">ES</td>
																		<td className="tableRow bg-sptTB sectionTitle bhTotalsSamples">WS</td>
																		<td className="tableRow bg-sptTB sectionTitle bhTotalsSamples">U</td>
																		<td className="tableRow bg-sptTB sectionTitle bhTotalsSPT">SPT/CPT</td>
																		<td className="tableRow bg-sptTB sectionTitle bhTotalsSamples">PS</td>
																		<td className="tableRow bg-sptTB sectionTitle bhTotalsSamples">SV</td>
																		<td className="tableRow bg-sptTB sectionTitle">LINERS</td>
																	</tr>
																	{/* ////////////////////////////////////////////////////// */}
																	<tr>
																		<td className="tableRow sectionTitle bhTotalsDepth">{borehole_depth ? borehole_depth : null}</td>
																		<td className="tableRow sectionTitle bhTotalsSamples">{numberOfSamplesD ? numberOfSamplesD : null}</td>
																		<td className="tableRow sectionTitle bhTotalsSamples">{numberOfSamplesB ? numberOfSamplesB : null}</td>
																		<td className="tableRow sectionTitle bhTotalsSamples">{numberOfSamplesES ? numberOfSamplesES : null}</td>
																		<td className="tableRow sectionTitle bhTotalsSamples">{numberOfSamplesWS ? numberOfSamplesWS : null}</td>
																		<td className="tableRow sectionTitle bhTotalsSamples">{numberOfUSamples ? numberOfUSamples : null}</td>
																		<td className="tableRow sectionTitle bhTotalsSPT">{numberOfSPTsCPTs ? numberOfSPTsCPTs : null}</td>
																		<td className="tableRow sectionTitle bhTotalsSamples">{numberOfPSSamples ? numberOfPSSamples : null}</td>
																		<td className="tableRow sectionTitle bhTotalsSamples">{numberOfShearVane ? numberOfShearVane : null}</td>
																		<td className="tableRow sectionTitle">{numberOfDriveRecords ? numberOfDriveRecords : null}</td>
																	</tr>
																	{/* ////////////////////////////////////////////////////// */}
																</tbody>
															</table>
														</div>
														{/* END OF BH TOTALS */}
														<table className="border-b border-grey-mid">
															<tbody>
																<tr>
																	<td>
																		{/* PLANT */}
																		<div className="plantHolder border-r border-grey-mid">
																			<table className="w-full text-center noTableBorder">
																				<tbody>
																					<tr>
																						<td colSpan={2} className="tableRow bg-grey-mid sectionTitle">Plant Details</td>
																					</tr>
																					{/* ////////////////////////////////////////////////////// */}
																					<tr>
																						{/* <td className="tableRow bg-sptTB plantCategories">Plant Type:</td>
																						<td className="tableRow  plantInputs">{plantType}</td> */}
																						<td className="tableRow bg-sptTB plantCategories">Plant Make/Model:</td>
																						<td className="tableRow  plantInputs">{plantMakeModel}</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB plantCategories">Plant ID:</td>
																						<td className="tableRow plantInputs">{plantID}</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB plantCategories">Plant Test Date:</td>
																						<td className="tableRow plantInputs">{plantTestDate}</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB plantCategories">SPT ID#:</td>
																						<td className="tableRow plantInputs">{sptID}</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB plantCategories">SPT Rod Type:</td>
																						<td className="tableRow plantInputs">{sptRodType}</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB plantCategories">SPT Callib. Date:</td>
																						<td className="tableRow plantInputs">{sptCalibrationDate}</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB plantCategories">SPT Energy Ratio:</td>
																						<td className="tableRow plantInputs">{sptEnergyRatio}</td>
																					</tr>
																					{/* ////////////////////////////////////////////////////// */}
																				</tbody>
																			</table>
																		</div>
																		{/* END OF PLANT    */}
																	</td>
																	<td>
																		{/* PERSONNEL */}
																		<div className="personnelHolder border-r border-grey-mid">
																			<table className="w-full text-center noTableBorder">
																				<tbody>
																					<tr>
																						<td colSpan={2} className="tableRow bg-grey-mid sectionTitle">Personnel Details</td>
																					</tr>
																					{/* ////////////////////////////////////////////////////// */}
																					<tr>
																						<td className="tableRow bg-sptTB personnelCategories">Lead Driller:</td>
																						<td className="tableRow  personnelInputs">{leadDriller}</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB personnelCategories">CSCS:</td>
																						<td className="tableRow personnelInputs">{leadDrillerCSCS}</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB personnelCategories">2nd Operative: </td>
																						<td className="tableRow personnelInputs">{secondMan}</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB personnelCategories">CSCS:</td>
																						<td className="tableRow personnelInputs">{secondManCSCS}</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB personnelCategories">Site Engineer:</td>
																						<td className="tableRow personnelInputs">{engineerName}</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB personnelCategories">&nbsp;</td>
																						<td className="tableRow personnelInputs">&nbsp;</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB personnelCategories">&nbsp;</td>
																						<td className="tableRow personnelInputs">&nbsp;</td>
																					</tr>
																					{/* ////////////////////////////////////////////////////// */}
																				</tbody>
																			</table>
																		</div>
																		{/* END OF PERSONNEL    */}
																	</td>
																</tr>
															</tbody>
														</table>
														{/* CLIENT INFO FOOTER */}
														<div className="w-full border-b border-r border-grey-mid">
															<table className="clientInfoTable noTableBorder">
																<tbody>
																	<tr>
																		{/* ////////////////////////////////////////////////////// */}
																		<td className="tableRow clientInfoContact">
																			{companyName}<br />
																			{companyAddress}<br />
																			Telephone: {companyTelephone}<br />
																			Email: {companyEmail}
																		</td>
																		{/* ////////////////////////////////////////////////////// */}
																		<td className="tableRow clientInfoDrillitLogs"></td>
																	</tr>
																</tbody>
															</table>
														</div>
														{/* END OF CLIENT INFO FOOTER */}
													</td>
												</tr>
											</tbody>
											{/* END OF tableHolderTable */}
										</table>

										{/* END OF mainContainer */}
									</div>

								</div>

							</div>

						</div>

					</>

				}














				{/* //..........................................................................
				//.LLLL..........AAAAA.....BBBBBBBBBB...EEEEEEEEEEE.LLLL........SSSSSSS.....
				//.LLLL..........AAAAA.....BBBBBBBBBBB..EEEEEEEEEEE.LLLL.......SSSSSSSSS....
				//.LLLL.........AAAAAA.....BBBBBBBBBBB..EEEEEEEEEEE.LLLL.......SSSSSSSSSS...
				//.LLLL.........AAAAAAA....BBBB...BBBB..EEEE........LLLL......LSSSS..SSSS...
				//.LLLL........AAAAAAAA....BBBB...BBBB..EEEE........LLLL......LSSSS.........
				//.LLLL........AAAAAAAA....BBBBBBBBBBB..EEEEEEEEEE..LLLL.......SSSSSSS......
				//.LLLL........AAAA.AAAA...BBBBBBBBBB...EEEEEEEEEE..LLLL........SSSSSSSSS...
				//.LLLL.......AAAAAAAAAA...BBBBBBBBBBB..EEEEEEEEEE..LLLL..........SSSSSSS...
				//.LLLL.......AAAAAAAAAAA..BBBB....BBBB.EEEE........LLLL.............SSSSS..
				//.LLLL.......AAAAAAAAAAA..BBBB....BBBB.EEEE........LLLL......LSSS....SSSS..
				//.LLLLLLLLLLLAAA....AAAA..BBBBBBBBBBBB.EEEEEEEEEEE.LLLLLLLLLLLSSSSSSSSSSS..
				//.LLLLLLLLLLLAAA.....AAAA.BBBBBBBBBBB..EEEEEEEEEEE.LLLLLLLLLL.SSSSSSSSSS...
				//.LLLLLLLLLLLAAA.....AAAA.BBBBBBBBBB...EEEEEEEEEEE.LLLLLLLLLL..SSSSSSSS....
				//.......................................................................... */}

				{labelsVisible && <>

					{/* LABELS */}

					<div className="flex flex-col rounded-lg border border-orange bg-modal-bg px-1 sm:px-2 md:px-2 modalshadow previewLogsheet pb-20 z-10">

						<div className="flex items-center border-b border-grey-dark py-2">

							<div className="flex-1 flex items-left">

							</div>

							<div className="flex-1 flex justify-center text-lg sm:text-xl font-bold text-text leading-6 text-center mx-auto">
								Labels
							</div>

							<div className="flex-1 flex justify-center">
								<span className="ml-auto">
									<button className="flex items-center justify-center w-12 h-12 text-4xl bold text-soil-grey bg-white border border-orange rounded" onClick={labelsClick} ><img src={closeIcon} width="32" alt="" /></button>
								</span>
							</div>
						</div>



						<div className="text-lg w-full text-center pt-2">
							Liners
						</div>

						<ul className="flex flex-wrap p-0 mb-2 list-none justify-center text-center"> {/* START LABELS HOLDER */}


							{/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
							// LINERS
							/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

							{DriveRecordData?.filter((action: any) => {
								return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
							}).map((valueDriveRecords: any, index: number) => {

								// let printSample = valueSamples.type
								let depthFrom = ''
								if (valueDriveRecords.depth_from === '0' || valueDriveRecords.depth_from === 0 ||
									valueDriveRecords.depth_from === '0.00' || valueDriveRecords.depth_from === 0.00
								) {
									depthFrom = 'GL'
								} else {
									depthFrom = valueDriveRecords.depth_from
								}


								return (
									<React.Fragment key={index}>

										<li className="p-1 z-10">
											<div onClick={() => {
												setLabelSample('L-' + valueDriveRecords.drive_number)
												setLabelDepth(depthFrom + '-' + valueDriveRecords.depth_to)
												setShowPrintLabel(true)
											}}>
												<Label
													borehole={borehole_name}
													sample={'L-' + valueDriveRecords.drive_number}
													depth={depthFrom + '-' + valueDriveRecords.depth_to}
													size='smallLabel'
													user={leadDriller}
												/>
											</div>
										</li>

									</React.Fragment>
								)
							})}

							{/* ////////////////////////////////////////////////////// */}

						</ul> {/* END LABELS HOLDER */}

						{/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
						// PRINT ALL LINER LABELS
						/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

						<div className="pt-1" style={{ height: '1px', overflow: 'hidden' }}>


							{/* START LABELS HOLDER */}

							<ul ref={printAllLinerLabelsArea}
								className="flex flex-wrap p-0 mb-2 list-none justify-center text-center ">

								{DriveRecordData?.filter((action: any) => {
									return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
								}).map((valueDriveRecords: any, index: number) => {

									// let printSample = valueSamples.type
									let depthFrom = ''
									if (valueDriveRecords.depth_from === '0' || valueDriveRecords.depth_from === 0) {
										depthFrom = 'GL'
									} else {
										depthFrom = valueDriveRecords.depth_from
									}

									return (
										<>

											<li className="p-1 z-10">
												<LabelBatchPrint
													borehole={borehole_name}
													sample={'L-' + valueDriveRecords.drive_number}
													depth={depthFrom + '-' + valueDriveRecords.depth_to}
													user={leadDriller}
												/>
											</li>

										</>
									)

								})}

							</ul> {/* END LABELS HOLDER */}

						</div>

						{showPrintAllLinerLabelsButton &&
							<>
								<div className="mt-2">
									<ReactToPrint  //FUNCTION CODE
										trigger={() => <Button theme="primary" size="lg" className="btn-lg m-auto " >Print All Liner Labels</Button>}
										content={() => printAllLinerLabelsArea.current}
									/>
								</div>
							</>
						}


						{/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
						// SAMPLES
						/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}


						<div className="text-lg w-full text-center border-t border-grey-mid mt-4 pt-2">
							Samples
						</div>

						<ul className="flex flex-wrap p-0 mb-2 list-none justify-center text-center"> {/* START LABELS HOLDER */}

							{SamplesData?.filter((action: any) => {
								return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
							}).map((valueSamples: any, index: number) => {

								// let printSample = valueSamples.type
								let depthFrom = ''
								if (valueSamples.depth_to_top === '0' || valueSamples.depth_to_top === 0 ||
									valueSamples.depth_to_top === '0.00' || valueSamples.depth_to_top === 0.00
								) {
									depthFrom = 'GL'
								} else {
									depthFrom = valueSamples.depth_to_top
								}

								let depthTo = ''
								if (valueSamples.depth_to_base === '' || valueSamples.depth_to_base === null) {
									depthTo = ''
								} else {
									depthTo = ' - ' + valueSamples.depth_to_base
								}


								return (
									<React.Fragment key={index}>

										<li className="p-1 z-10">
											<div onClick={() => {
												setLabelSample(valueSamples.type + '-' + valueSamples.type_number)
												setLabelDepth(depthFrom + depthTo)
												setShowPrintLabel(true)
											}}>
												<Label
													borehole={borehole_name}
													sample={valueSamples.type + '-' + valueSamples.type_number}
													depth={depthFrom + depthTo}
													size='smallLabel'
													user={leadDriller}
												/>
											</div>
										</li>

									</React.Fragment>
								)
							})}

							{/* ////////////////////////////////////////////////////// */}

						</ul> {/* END LABELS HOLDER */}

						{/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
						// PRINT ALL SAMPLE LABELS
						/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

						<div className="pt-1" style={{ height: '1px', overflow: 'hidden' }}>


							{/* START LABELS HOLDER */}

							<ul ref={printAllSampleLabelsArea}
								className="flex flex-wrap p-0 mb-2 list-none justify-center text-center ">

								{SamplesData?.filter((action: any) => {
									return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
								}).map((valueSamples: any, index: number) => {

									// let printSample = valueSamples.type
									let depthFrom = ''
									if (valueSamples.depth_to_top === '0' || valueSamples.depth_to_top === 0) {
										depthFrom = 'GL'
									} else {
										depthFrom = valueSamples.depth_to_top
									}

									let depthTo = ''
									if (valueSamples.depth_to_base === '' || valueSamples.depth_to_base === null) {
										depthTo = ''
									} else {
										depthTo = ' - ' + valueSamples.depth_to_base
									}

									return (
										<>

											<li className="p-1 z-10">
												<LabelBatchPrint
													borehole={borehole_name}
													sample={valueSamples.type + '-' + valueSamples.type_number}
													depth={depthFrom + depthTo}
													user={leadDriller}
												/>
											</li>

										</>
									)

								})}

							</ul> {/* END LABELS HOLDER */}

						</div>

						{showPrintAllSampleLabelsButton &&
							<>
								<div className="mt-2">
									<ReactToPrint  //FUNCTION CODE
										trigger={() => <Button theme="primary" size="lg" className="btn-lg m-auto " >Print All Sample Labels</Button>}
										content={() => printAllSampleLabelsArea.current}
									/>
								</div>
							</>
						}


						{/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
						// INSITU TESTS
						/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}


						<div className="text-lg w-full text-center border-t border-grey-mid mt-4 pt-2">
							Insitu Tests
						</div>

						<ul className="flex flex-wrap p-0 mb-2 list-none justify-center text-center"> {/* START LABELS HOLDER */}

							{InSituTestsData?.filter((action: any) => {
								return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
							}).map((valueInsituTests: any, index: number) => {

								// let printSample = valueSamples.type
								let depthFrom = ''
								if (valueInsituTests.depth_to_top === '0' || valueInsituTests.depth_to_top === 0) {
									depthFrom = 'GL'
								} else {
									depthFrom = valueInsituTests.depth_to_top
								}

								if (valueInsituTests.type === 'U') {

									let uLabelType = 'U' + valueInsituTests.utype

									return (
										<React.Fragment key={index}>

											<li className="p-1 z-10">
												<div onClick={() => {
													setLabelSample(uLabelType)
													setLabelDepth(depthFrom + ' - ' + valueInsituTests.depth_to_base)
													setShowPrintLabel(true)
												}}>
													<Label
														borehole={borehole_name}
														sample={uLabelType}
														depth={depthFrom + ' - ' + valueInsituTests.depth_to_base}
														size='smallLabel'
													user={leadDriller}
													/>
												</div>
											</li>

										</React.Fragment>
									)
								} else if (valueInsituTests.type === 'PS') {
									return (
										<React.Fragment key={index}>

											<li className="p-1 z-10">
												<div onClick={() => {
													setLabelSample(valueInsituTests.type)
													setLabelDepth(depthFrom + ' - ' + valueInsituTests.depth_to_base)
													setShowPrintLabel(true)
												}}>
													<Label
														borehole={borehole_name}
														sample={valueInsituTests.type}
														depth={depthFrom + ' - ' + valueInsituTests.depth_to_base}
														size='smallLabel'
														user={leadDriller}
													/>
												</div>
											</li>

										</React.Fragment>
									)
								} else {
									return (
										<React.Fragment key={index}>

										</React.Fragment>
									)
								}

							})}

							{/* ////////////////////////////////////////////////////// */}


						</ul> {/* END LABELS HOLDER */}



						{/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
						// PRINT ALL TEST LABELS
						/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

						<div className="pt-1" style={{ height: '1px', overflow: 'hidden', display: 'none' }}>


							{/* START LABELS HOLDER */}

							<ul ref={printAllTestLabelsArea}
								className="flex flex-wrap p-0 mb-2 list-none justify-center text-center ">

								{InSituTestsData?.filter((action: any) => {
									return (
										datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)) &&
										action.type !== 'SPT' &&
										action.type !== 'CPT' &&
										action.type !== 'SV'
									);
								}).map((valueInsituTests: any, index: number) => {

									// let printSample = valueSamples.type
									let depthFrom = ''
									if (valueInsituTests.depth_to_top === '0' || valueInsituTests.depth_to_top === 0) {
										depthFrom = 'GL'
									} else {
										depthFrom = valueInsituTests.depth_to_top
									}

									if (valueInsituTests.type === 'U') {
	
										let uLabelType = 'U' + valueInsituTests.utype
	
										// console.log('uLabelType valueInsituTests.type_number:', uLabelType + '-' + valueInsituTests.type_number)
	
										return (
											<React.Fragment key={index}>
	
												<li className="p-1 z-10">
													<div onClick={() => {
														setLabelSample(uLabelType)
														setLabelDepth(depthFrom + ' - ' + valueInsituTests.depth_to_base)
														setShowPrintLabel(true)
													}}>
														<LabelBatchPrint
															borehole={boreholeName}
															sample={uLabelType + '-' + valueInsituTests.type_number}
															depth={depthFrom + ' - ' + valueInsituTests.depth_to_base}
															size='smallLabel'
															user={leadDriller}
														/>
													</div>
												</li>
	
											</React.Fragment>
										)
									} else if (valueInsituTests.type === 'PS') {
										return (
											<React.Fragment key={index}>
	
												<li className="p-1 z-10">
													<div onClick={() => {
														setLabelSample(valueInsituTests.type)
														setLabelDepth(depthFrom + ' - ' + valueInsituTests.depth_to_base)
														setShowPrintLabel(true)
														// console.log('valueInsituTests.type valueInsituTests.type_number:', valueInsituTests.type + '-' + valueInsituTests.type_number)
													}}>
														<LabelBatchPrint
															borehole={boreholeName}
															sample={valueInsituTests.type + '-' + valueInsituTests.type_number}
															depth={depthFrom + ' - ' + valueInsituTests.depth_to_base}
															user={leadDriller}
														/>
													</div>
												</li>
	
											</React.Fragment>
										)
									} else {
										return (
											<></>
											// <React.Fragment key={index}>
	
											// </React.Fragment>
										)
									}

									// return (
									// 	<>

									// 		<li className="p-1 z-10">
									// 			<LabelBatchPrint
									// 				borehole={boreholeName}
									// 				sample={valueInsituTests.type + '-' + valueInsituTests.type_number}
									// 				depth={depthFrom + '-' + valueInsituTests.depth_to_base}
									// 				// user={loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name)}
									// 				user={leadDriller}
									// 			/>
									// 		</li>

									// 	</>
									// )

								})}

							</ul> {/* END LABELS HOLDER */}

						</div>



						{showPrintAllInsituTestLabelsButton &&
							<>

								{/* <Button theme="primary" size="lg" className="btn-lg m-auto" >Print All Test Labels</Button> */}
								<div className="mt-2">
									<ReactToPrint
										trigger={() => <Button theme="primary" size="lg" className="btn-lg m-auto " >
											Print All Test Labels
										</Button>}
										content={() => printAllTestLabelsArea.current}
									/>
								</div>
							</>
						}

					</div>





				</>}




			</Container>

			<br /><br />

			<SyncStatus synced />

		</>

	)
}

export default FunctionTemplate