import { get, del } from 'idb-keyval'

export const usePostToApi = (keyValueEndpoint: string, postOrPut: string, update?: boolean) => {
    const newKeyValue = `drillit-${keyValueEndpoint}-new`
    const updateKeyValue = `drillit-${keyValueEndpoint}-update`

    console.log('postToAPI - keyValueEndpoint, postOrPut, update?', keyValueEndpoint, postOrPut, update)
    // add in any routes that you want to use the noV1ApiRoute
    const noV1ApiRoutes = ['login']
    let apiPostAddr = ''
    if (noV1ApiRoutes.includes(keyValueEndpoint)) {
        apiPostAddr = `${process.env.REACT_APP_API_ADDR_NOV1}${keyValueEndpoint}`
    } else {
        apiPostAddr = `${process.env.REACT_APP_API_ADDR}${keyValueEndpoint}`
    }

    if (update) {
        console.log('postToAPI - UPDATE')
        const updatedObjects = getIDB(updateKeyValue).then(result => {
            if (result) {
                let removedItemsArray: any = result
                result.forEach((element: any) => {
                    // get the id of the current item and put it on the end of the apiAddress
                    const elId = element.guid
                    const apiPutAddr = `${apiPostAddr}/${elId}`
                    const putAttempt = makeRequest(apiPutAddr, JSON.stringify(element), postOrPut)
                    putAttempt
                        .then(posted => {
                            if (posted.status === 200) {
                                console.log('UPDATE SUCCESS!!!')
                                removedItemsArray = removedItemsArray.filter((item: any) => item !== element)
                            } else {
                                console.log('UPDATE FAILED!!!')
                            }
                            return posted
                        })
                        .then(endResult => {
                            if (removedItemsArray.length === 0) {
                                return del(updateKeyValue).then(res => res)
                                // return endResult
                            }
                            return endResult
                        })
                    return putAttempt
                })
            }
            return result
        })

        return updatedObjects
    }

    const newObjects = getIDB(newKeyValue).then(result => {
        console.log('postToAPI - NEW - newKeyValue', newKeyValue)
        if (result) {
            let removedItemsArray: any = result
            result.forEach((element: any, index: any) => {
                const postAttempt = makeRequest(apiPostAddr, JSON.stringify(element), postOrPut)
                console.log('**** postToApi - postToApi - postAttempt:', postAttempt)
                console.log('**** postToApi - postToApi - JSON.stringify(element):', JSON.stringify(element))
                postAttempt
                    .then(async posted => {
                        console.log('**** postToApi - posted.status:', posted.status, updateKeyValue)
                        if (posted.status === 200) {
                            console.log('**** postToApi - POSTED!!! Status: 200')
                            removedItemsArray = removedItemsArray.filter((item: any) => item !== element)
                        } else {
                            console.log('**** postToApi - RESPONSE ERROR:', posted.status)
                            // Check if the item exists on the server
                            const exists = await checkFilesExist(element.task_type, element.data.guid)
                            if (exists) {
                                removedItemsArray = removedItemsArray.filter((item: any) => item !== element)
                            } else {
                                console.log('Item does not exist on the server, will attempt repost')
                            }
                        }
                        return posted
                    })
                    .then(endResult => {
                        if (removedItemsArray.length === 0) {
                            return del(newKeyValue).then(res => res)
                        }
                        return endResult
                    })
                return postAttempt
            })
        }
        return result
    })

    return newObjects
}


// Function to check if files exist on the server
const checkFilesExist = async (taskType: string, guid: string) => {
    const endpoint = `${process.env.REACT_APP_API_ADDR}action/${taskType}/${guid}/exists`;
    try {
        const response = await fetch(endpoint);
        const data = await response.json();
        console.log(`File existence for ${taskType} with guid ${guid}: TRUE *************************************`);
        return data.exists;
    } catch (err) {
        console.error(`Error checking file existence for ${taskType} with guid ${guid}:`, err);
        return false; // Return false by default or handle the error case appropriately
    }
};

const makeRequest = (endpoint: string, data: string, postOrPut: string) => {
    const token = localStorage.getItem('drillit-token')

    console.log(`Making ${postOrPut} request to:`, endpoint);
    console.log('Request data:', data);

    return fetch(endpoint, {
        method: postOrPut,
        mode: 'cors', // Explicitly set the mode to CORS
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: data
    })
        .then(response => response)
        .catch(err => err)
}

const getIDB = (keyValue: string) => get(keyValue).then(data => data)
