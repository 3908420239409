import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

// import Button from '../form/Button'

import plus from '../../assets/icons/plus.svg'

type Props = {
	className?: string,
	[x: string]: any;
}

const defaultProps: Props = {
	className: '',
}

const AddProject: FunctionComponent<Props> = ({ className, ...rest }) => {
	return (
		<Link to="/projects/create">
		<div className={`rounded-md md:rounded-lg border-2 md:border-2 border-orange pt-0 md:pt-1.5 pb-0 md:pb-2.5 px-3 bg-white ${className}`} {...rest}>
			<img src={plus}  className="w-6 md:w-12 mx-auto mt-1 md:mt-4 mb-1 md:mb-3" alt="" />

			{/* <Link to="/projects/create"><Button size="xs" icon="" iconPosition="right" className="mx-auto mt-5">New Project</Button></Link> */}
		</div>
		</Link>
	)
}

AddProject.defaultProps = defaultProps

export default AddProject