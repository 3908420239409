import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import NumberInputBackfillLayers from '../../NumberInputBackfillLayers'
import infoIcon from '../../../assets/icons/info.png'
import closeIcon from '../../../assets/icons/close.svg'

// Define the type for the backfill layer
interface BackfillType {
  value: string;
  label: string;
}

// Define the type for the BackfillLayers component props
interface BackfillLayersProps {
  backfillLayers: any
  holeDepth: number;
  backfill_types: BackfillType[];
  onUpdateLayers: (selections: any) => void;
  pipe1solid: any
  pipe1slotted: any
  pipe2solid: any
  pipe2slotted: any
}

const BackfillLayers: React.FC<BackfillLayersProps> = ({ backfillLayers, holeDepth, backfill_types,
  pipe1solid, pipe1slotted, pipe2solid, pipe2slotted, onUpdateLayers }) => {



    
    console.log('pipe1solid:', pipe1solid, 'pipe1slotted', pipe1slotted)

  const [backfillSelections, setBackfillSelections] = useState<Array<{ backfillType: string; depth_from: number; depth: number; backfill_legend: string }>>([])

  interface BackfillLayer {
    backfillType: string;
    depth_from: string;
    depth: string;
    backfill_legend: string;
  }

  // Use useEffect to update the layers when backfillLayers changes
  useEffect(() => {
    // Check if backfillLayers has values
    if (backfillLayers && backfillLayers.length > 0) {
      // Filter out entries with backfillType === ''
      const filteredLayers = backfillLayers.filter((layer: BackfillLayer) => layer.backfillType !== null);

      // Parse depth_from and depth to numbers
      const numericLayers = filteredLayers.map((layer: BackfillLayer) => ({
        ...layer,
        depth_from: parseFloat(layer.depth_from),
        depth: parseFloat(layer.depth),
      }));

      setBackfillSelections(numericLayers);
    } else {
      // Your existing logic for handling the case when backfillLayers is empty
      // For example, set default backfillSelections as needed
      // setBackfillSelections([...]); // Your logic here
    }
  }, [backfillLayers]);


  // Initialize layers state with an empty array if defaultValues.layers is undefined
  const [layers, setLayers] = useState(backfillLayers || []);

  console.log('backfillLayers inside BackfillLayers:', backfillLayers)
  console.log('layers:', layers)

  // Function to handle layer updates
  const handleLayerUpdate = (index: any, field: any, value: any) => {
    const updatedLayers = [...layers];
    updatedLayers[index][field] = value;
    setLayers(updatedLayers);
  };

  // const initialBackfillSelections = Array.from({ length: 10 }, () => ({ backfillType: '', depth_from: 0, depth: 0, backfill_legend: '' }));
  // const [backfillSelections, setBackfillSelections] = useState(initialBackfillSelections);

  const [tempDepthValue, setTempDepthValue] = useState('')
  // console.log('tempDepthValue:', tempDepthValue)

  const [backfillDepth, setBackfillDepth] = useState(0)
  // console.log('backfillDepth:', backfillDepth)

  const [inputValue, setInputValue] = useState('')

  const [remainingDepthPercentage, setRemainingDepthPercentage] = useState(100)

  let totalHeight = 0; // Declare totalHeight in the outer scope
  let totalDepth = 0;

  const totalHeightRef = useRef(0);
  const totalDepthRef = useRef(0);

  // Create a ref with the correct type
  const numberInputRef = useRef<{ resetValue: () => void } | null>(null);

  const handleBackfillSelection = (backfillType: string, depth: number) => {
    // Check if depth is a valid number
    if (!isNaN(depth) && depth > 0) {
      setBackfillSelections((prevSelections) => {
        const newSelections = [...prevSelections, { backfillType, depth, depth_from: 0, backfill_legend: '' }];

        // Add depth_from to each element based on the previous depth
        newSelections.forEach((selection, index) => {
          if (index === 0) {
            selection.depth_from = 0;
          } else {
            selection.depth_from = parseFloat(newSelections[index - 1].depth.toFixed(2));
          }
          selection.depth = parseFloat(selection.depth.toFixed(2));

          // Add backfill_legend based on backfillType
          switch (selection.backfillType) {
            case 'Arisings/Spoil':
              selection.backfill_legend = '905';
              break;
            case 'Sand':
              selection.backfill_legend = '901';
              break;
            case 'Gravel':
              selection.backfill_legend = '902';
              break;
            case 'Asphalt':
              selection.backfill_legend = '907';
              break;
            case 'Paving Slab':
              selection.backfill_legend = '912';
              break;
            case 'Brick Weave':
              selection.backfill_legend = '912';
              break;
            case 'Postmix':
              selection.backfill_legend = '906';
              break;
            case 'Concrete':
              selection.backfill_legend = '906';
              break;
            case 'Bentonite Grout':
              selection.backfill_legend = '903';
              break;
            case 'Bentonite Granules':
              selection.backfill_legend = '903';
              break;
            case 'Bentonite Pellets':
              selection.backfill_legend = '903';
              break;
            case 'Bentonite Concrete':
              selection.backfill_legend = '903';
              break;
            case 'Ballast':
              selection.backfill_legend = '902';
              break;
            case 'Recycled Glass':
              selection.backfill_legend = '902';
              break;
            case 'Void':
              selection.backfill_legend = '999';
              break;
            default:
              selection.backfill_legend = '';
          }

        });

        // Reset the value of the number input
        setTempDepthValue('');

        // Lifted state update
        setInputValue('');

        // Reset the NumberInput value using the ref
        if (numberInputRef.current) {
          numberInputRef.current.resetValue();
        }

        // Filter out objects with empty backfillType and depth
        const filteredSelections = newSelections.filter(
          (selection) => selection.backfillType !== '' || selection.depth !== 0
        );

        // Add extra fields to newSelections after filtering
        const extraFields = Array.from({ length: 10 - filteredSelections.length }, () => ({
          backfillType: '',
          depth_from: 0,
          depth: 0,
          backfill_legend: '',
        }));

        // Concatenate extraFields to filteredSelections
        const updatedSelections = filteredSelections.concat(extraFields);

        // Update layers and convert to fixed decimals
        onUpdateLayers(
          updatedSelections.map(({ backfillType, depth, depth_from, backfill_legend }) => ({
            backfillType,
            depth: depth.toFixed(2),
            depth_from: depth_from.toFixed(2),
            backfill_legend,
          }))
        );

        // console.log('newSelections:', newSelections)
        // console.log('filteredSelections:', filteredSelections)
        // console.log('extraFields:', extraFields)

        return updatedSelections;
      })

    } else {
      // Handle the case where depth is not a valid number
      // console.error('Invalid depth value:', depth);
    }
  };

  // // Update the parent file with updated array after delete or move layer
  // const updateLayersFromSelections = (updatedSelections: YourSelectionType[]) => {
  //   // Filter out objects with empty backfillType and depth
  //   const filteredSelections = updatedSelections.filter(
  //     (selection) => selection.backfillType !== '' || selection.depth !== 0
  //   );

  //   // Add extra fields to newSelections after filtering
  //   const extraFields = Array.from({ length: 10 - filteredSelections.length }, () => ({
  //     backfillType: '',
  //     depth_from: 0,
  //     depth: 0,
  //     backfill_legend: '',
  //   }));

  //   // Concatenate extraFields to filteredSelections
  //   const finalSelections = filteredSelections.concat(extraFields);

  //   // Update the state
  //   setBackfillSelections(finalSelections);

  //   // Call parent component function to update layers
  //   onUpdateLayers(
  //     finalSelections.map(({ backfillType, depth, depth_from, backfill_legend }) => ({
  //       backfillType,
  //       depth: depth.toFixed(2),
  //       depth_from: depth_from.toFixed(2),
  //       backfill_legend,
  //     }))
  //   );
  // };


  const resetNumberInput = () => {
    setTempDepthValue('');
    // Lifted state update
    setInputValue('');
    if (numberInputRef.current) {
      numberInputRef.current.resetValue();
    }
  };

  // console.log('backfillSelections:', backfillSelections)



  // Function to handle layer deletion
  const handleDeleteLayer = (index: number) => {
    setBackfillSelections((prevSelections) => {
      const updatedSelections = [...prevSelections];
      updatedSelections.splice(index, 1);

      // Call parent component function to update layers
      onUpdateLayers(updatedSelections);

      return updatedSelections;
    });
  };

  // Function to handle moving layers up
  const handleMoveUp = (index: number) => {
    if (index > 0) {
      // Swap the current layer with the one above it
      const updatedSelections = [...backfillSelections];
      [updatedSelections[index], updatedSelections[index - 1]] = [
        updatedSelections[index - 1],
        updatedSelections[index],
      ];

      setBackfillSelections(updatedSelections);

      // Call parent component function to update layers
      onUpdateLayers(updatedSelections);
    }
  };

      console.log('PIPES in Backfill Layesr:', pipe1solid, pipe1slotted, pipe2solid, pipe2slotted)

      const [pipe1, setPipe1] = useState(false);
      const [pipe2, setPipe2] = useState(false);
  
      useEffect(() => {
        if(pipe1solid || pipe1slotted){setPipe1(true)}
        if(pipe2solid || pipe2slotted){setPipe2(true)}
        calculatePipeHeights()
      }, [pipe1solid, pipe1slotted, pipe2solid, pipe2slotted]);

      const [pipe1solidHeight, setPipe1solidHeight] = useState(0);
      const [pipe1slottedHeight, setPipe1slottedHeight] = useState(0);
      const [pipe2solidHeight, setPipe2solidHeight] = useState(0);
      const [pipe2slottedHeight, setPipe2slottedHeight] = useState(0);

  const calculatePipeHeights = () => { // pipe1solid, pipe1slotted, pipe2solid, pipe2slotted
    if(pipe1solid){setPipe1solidHeight((pipe1solid / holeDepth) * 100)}
    if(pipe1slotted){setPipe1slottedHeight((pipe1slotted / holeDepth) * 100)}
    if(pipe2solid){setPipe2solidHeight((pipe2solid / holeDepth) * 100)}
    if(pipe2slotted){setPipe2slottedHeight((pipe2slotted / holeDepth) * 100)}
  }

  console.log('PIPE heights (%):', pipe1solidHeight, pipe1slottedHeight, pipe2solidHeight, pipe2slottedHeight)

  const renderBackfill = () => {
    let totalDepthAsPercentage = 0;
    let localTotalDepth = 0; // Local variable to accumulate depth

    // Filter out layers with invalid backfillType or depth <= 0
    const filteredBackfillSelections = backfillSelections.filter(
      (selection) => selection.backfillType && selection.depth > 0
    );

    return (
      <div className="borehole flex flex-col items-center backfillLayersHolderInside " >  {/* style={{ height: '530px' }} */}
        {filteredBackfillSelections.map((selection, index) => {
          const depthAsPercentage = (selection.depth / holeDepth) * 100;
          let depthAsPercentageMin = 0
          const minHeight = 8;
          const adjustedHeight = Math.max(depthAsPercentage, minHeight); // Use Math.max to ensure the height is at least 20px

          totalDepthAsPercentage += depthAsPercentage;
          localTotalDepth += selection.depth; // Accumulate depth locally
          // console.log('depthAsPercentage:', depthAsPercentage);

          // Define class names based on backfillType
          const classNames = `backfill border-b border-grey-dark w-full flex items-center ${selection.backfillType === 'Arisings/Spoil' ? 'spoil' : // 905
            selection.backfillType === 'Sand' ? 'soilSand' : // 901
              selection.backfillType === 'Gravel' ? 'soilGravel' : // 902
                selection.backfillType === 'Asphalt' ? 'soilCoal' : // 907
                  selection.backfillType === 'Paving Slab' ? 'soilLimestone' : // 912
                    selection.backfillType === 'Brick Weave' ? 'soilLimestone' : // 912
                      selection.backfillType === 'Postmix' ? 'backfillPostmix' : // 906
                        selection.backfillType === 'Concrete' ? 'backfillConcrete' : // 906
                          selection.backfillType === 'Bentonite Grout' ? 'soilClay' : // 903
                            selection.backfillType === 'Bentonite Granules' ? 'soilClay' : // 903
                              selection.backfillType === 'Bentonite Pellets' ? 'soilClay' : // 903
                                selection.backfillType === 'Bentonite Concrete' ? 'soilClay' : // 903
                                  selection.backfillType === 'Ballast' ? 'soilSiltstone' : // 902
                                    selection.backfillType === 'Recycled Glass' ? 'soilSiltstone' : // 902
                                      selection.backfillType === 'Void' ? 'soilCoal' : // 999
                                        ''
            }`;

          return (
            <div
              key={index}
              className={classNames}
              style={{ height: `${adjustedHeight}%`, top: `${totalDepthAsPercentage}%` }}
            >

              <button
                // className="font-bold text-lg rounded-full bg-grey-dark text-white h-6 leading-4 p-2 pt-0.5 ml-1 mr-0.5"
                className="font-bold text-sm md:text-lg rounded-full bg-grey-dark text-white h-5 md:h-6 leading-0 md:leading-4 p-0 md:p-2 px-1.5 md:px-1.5 pt-0 md:pt-0.5 mr-0.5 ml-1"
                onClick={() => handleDeleteLayer(index)}
              >
                x
              </button>

              <div className="text-center flex-grow">
                <div className="py-0.5 px-2 bg-white border border-orange rounded backfillTextSize font-bold z-50 mx-auto w-11/12 md:w-1/2">
                  {/* Backfill {index + 1} - {selection.backfillType} - {selection.depth.toFixed(2)}m */}
                  {selection.backfillType} ({selection.depth.toFixed(2)}m)
                </div>
              </div>

              <button className="font-bold text-sm md:text-2xl rounded-full bg-orange text-white h-5 md:h-6 leading-0 md:leading-5 p-0 md:p-1.5 px-1.5 md:px-1 pt-0.5 md:pt-1.5 mr-1 ml-0.5"
                onClick={() => handleMoveUp(index)}>
                ^
              </button>
            </div>
          );
        })}

        {/* Update totalDepthRef with the locally accumulated depth */}
        {totalDepthRef.current !== localTotalDepth && (totalDepthRef.current = localTotalDepth)}

        {/* Conditionally render the remaining depth */}
        {parseFloat(remainingDepth) > 0 && (
          <div
            className="remaining-depth bg-grey-dark w-full text-xs md:text-sm font-bold pt-2"
            style={{ height: `${100 - totalDepthAsPercentage}%` }}
          >
            Remaining ({remainingDepth}m)
          </div>
        )}
      </div>
    );
  };

  // Reset totalDepthRef.current when component mounts
  useEffect(() => {
    totalDepthRef.current = 0;
  }, []);

  // Update the state based on accumulated depth
  useEffect(() => {
    setBackfillDepth(totalDepthRef.current);
    setRemainingDepthPercentage(100 - (totalDepthRef.current / holeDepth) * 100);
  }, [backfillSelections, holeDepth]);

  // console.log('backfillDepth:', backfillDepth);
  // console.log('remainingDepthPercentage:', remainingDepthPercentage);


  // const [remainingDepth, setRemainingDepth] = useState(holeDepth - backfillDepth)
  let remainingDepth = (holeDepth - backfillDepth).toFixed(2);
  // let remainingDepthPercentage =  (holeDepth / backfillDepth) * 100
  let remainingDepthLabel = remainingDepth + 'm'

  console.log('remainingDepthLabel:', remainingDepthLabel)


  const [showBackfillInfo, setShowBackfillInfo] = useState(false)

  return (
    <>

      {showBackfillInfo &&
        <div className="infoContainer">
          <div className="m-auto h-80 w-60 rounded-lg border border-orange bg-white modalshadow p-2">
            <div className="flex">
              <div className="info-icon-container">
                <img className="info-icon" src={infoIcon} alt="Info" />
              </div>
              <span className="font-bold text-lg">Information</span>
              <span className="ml-auto">
                <button
                  className="flex items-center justify-center w-8 h-8 text-4xl bold text-soil-grey bg-white border border-orange rounded"
                  onClick={() => setShowBackfillInfo(false)}
                >
                  <img src={closeIcon} width="20" alt="" />
                </button>
              </span>
            </div>

            <div className="mt-4">
              Add Layers of backfill starting at the top and work down.
            </div>

            <div className="mt-5">
              {/* {seatingBlowsInfoText} */}
              Firstly add the 'depth' and then select the backfill type. Click 'remaining' to auto fill the depth.
            </div>

            <div className="mt-5">
              {/* {seatingBlowsInfoText} */}
              You can delete a layer on the left or shuffle the order.
            </div>

          </div>
        </div>

      }

      <div className="grid grid-cols-7 md:grid-cols-11 gap-2 w-full sm:w-3/5 lg:w-3/5 mx-auto text-center">

        <div className="col-span-3 md:col-span-5 font-bold text-sm mt-2 bg-white rounded border border-grey-mid">
          <div className="font-bold text-sm">
            Hole Depth:
          </div>
          <div className="font-bold text-xl -mt-1">
            {holeDepth}m
          </div>
        </div>

        <div className="col-span-3 md:col-span-5 font-bold text-sm mt-2 bg-white rounded border border-grey-mid">
          <div className="font-bold text-sm">
            Backfill Depth:
          </div>
          <div className="font-bold text-xl -mt-1">
            {backfillDepth.toFixed(2)}m
          </div>
        </div>

        <div className="col-span-1" onClick={() => setShowBackfillInfo(true)}>
          <div className="flex items-center justify-center w-full text-center pt-1">
            <img className="pt-4" src={infoIcon} width="20"></img>
          </div>
        </div>

      </div>

      <div className="flex flex-grow flex-row w-full mt-2 rounded">

      <div className="flex-grow w-5/12 md:w-1/2 text-center border border-orange mb-2 p-1 backfillLayersHolder rounded">
        <div className="flex">

          {pipe1 &&
            <div className="w-5 bg-charcoal pipe1visibility">
                <div className="pipe_solid w-full" style={{ height: `${pipe1solidHeight}%`, top:0, fontSize: '8px' }} >
                  <div className="bg-charcoal text-white">{pipe1solid}m</div>
                </div>
                <div className="pipe_slotted w-full" style={{ height: `${pipe1slottedHeight}%`, top: `${pipe1solidHeight}%`, fontSize: '8px' }} >
                  <div className="bg-charcoal text-white">{pipe1slotted}m</div>
                </div>
            </div>
          }

          {/* Center div with flexible width */}
          <div className="flex-grow">
            <div className="w-full text-center border border-grey-dark">
              {renderBackfill()}
            </div>
          </div>


          {pipe2 &&
            <div className="w-5 bg-charcoal pipe2visibility">
                <div className="pipe_solid w-full" style={{ height: `${pipe2solidHeight}%`, top:0, fontSize: '8px' }} >
                  <div className="bg-charcoal text-white">{pipe2solid}m</div>
                </div>
                <div className="pipe_slotted w-full" style={{ height: `${pipe2slottedHeight}%`, top: `${pipe2solidHeight}%`, fontSize: '8px' }} >
                  <div className="bg-charcoal text-white">{pipe2slotted}m</div>
                </div>
            </div>
          }

        </div>

      </div>

        <div className="w-7/12 md:w-1/2 text-center mb-2">
          {/* <div className="text-xs md:text-sm font-bold">
            Enter the depth of the backfill layer, <span className="">THEN</span> select type!
          </div> */}

          <NumberInputBackfillLayers
            value={inputValue} // Pass the lifted state as prop
            setValue={setInputValue} // Pass the function to update the lifted state
            onUpdate={(value: any) => setTempDepthValue(value)}
            ref={numberInputRef}
            onReset={() => {
              // Your onReset logic goes here
              // For example, you can reset other states or perform additional actions
              // console.log('Resetting...');
            }}
            title=""
            defaultValue={''}
            className="mb-3 text-xl"
            presets={[{ value: remainingDepth, label: remainingDepthLabel }]}
          />

          <div className="flex flex-wrap mx-1.5 md:mx-7">

            {backfill_types.map((backfill_type, index, array) => (
              <button
                className={`flex-grow min-w-[20px] md:min-w-[48px] rounded border border-orange bg-white py-1 md:py-2 px-2 md:px-4 m-0.5 md:m-1 text-xs md:text-base`}
                key={backfill_type.value}
                onClick={() => handleBackfillSelection(backfill_type.value, parseFloat(tempDepthValue))}
              >
                {backfill_type.label}
              </button>
            ))}

          </div>

        </div>

      </div>
    </>
  );
};

export default BackfillLayers;
