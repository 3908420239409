import React from 'react'

import Modal from '../../components/Modal'
import WorkflowSteps from '../../components/WorkflowSteps'
import Table from '../../components/Table'
import InputGroup from '../../components/form/InputGroup'
import Button from '../../components/form/Button'
import WorkflowActions from '../../components/form/WorkflowActions'
import EditBoreholes from '../../components/project/EditBoreholes'

import Header from '../../layout/Header'

import stepStatus from '../../helpers/stepStatus'

import plusIcon from '../../assets/icons/plus-white.svg'
import { v4 as uuidv4 } from "uuid";
import { Redirect } from "react-router-dom";
import { get } from "idb-keyval";
import { useGetIDBValues } from "../../helpers/getIDBKey";
import { useSetIndexedDBValues } from "../../helpers/addToIDB";
import { updateProject } from "../../helpers/updateProject";
import { usePostToApi } from "../../helpers/postToAPI";

interface CustomCheckboxProps {
    checked: boolean;
    onChange: () => void;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ checked, onChange }) => {
    return (
        <div 
            className={`custom-checkbox ${checked ? 'checked' : ''} rounded w-8 h-8 border-2 border-blue`} 
            onClick={onChange}
            style={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                backgroundColor: checked ? '#2185D0' : '#fff',
                marginTop: checked ? '0px' : '5px',
                textAlign: 'center',
            }}
        >
            {checked && <span style={{ color: '#fff' }}>✔</span>}
        </div>
    );
};

interface Props {
	toggleSidebar: () => void,
	router: any,
}


interface State {
	projectCurrentStep: number,
	boreholes: any,
	clientName: string,
	clientContact: string,
	clientPhone: string,
	clientEmail: string,
	engineerName: string,
	engineerPhone: string,
	engineerEmail: string,
	siteLocation: string,
	startDate: string,
	endDate: string,
	startTime: string,
	projectNo: string,
	siteDescription: string,
	keySiteFeatures: string,
	siteHistory: string,
	keyHS: string,
	address1: string,
	address2: string,
	city: string,
	county: string,
	postcode: string,
	expectedStrata: string,
	notes: string,
	equipmentRequired: string,
	guid: string,
	company_guid: string,
	redirect: boolean,
	parked: number,
	company_id: number,
}

class ProjectsUpdate extends React.Component<Props, State> {
	constructor(props: any) {
		super(props)

		this.state = {
			projectCurrentStep: 1,
			boreholes: [],
			clientName: '',
			clientContact: '',
			clientPhone: '',
			clientEmail: '',
			engineerName: '',
			engineerPhone: '',
			engineerEmail: '',
			siteLocation: '',
			startDate: '',
			endDate: '',
			startTime: '',
			projectNo: '',
			siteDescription: '',
			keySiteFeatures: '',
			siteHistory: '',
			keyHS: '',
			address1: '',
			address2: '',
			city: '',
			county: '',
			postcode: '',
			expectedStrata: '',
			notes: '',
			equipmentRequired: '',
			guid: this.props.router.match.params.id,
			company_guid: '',
			redirect: false,
			parked: 0,
			company_id: 0,
		}
	}

	previous = () => {
		if (this.state.projectCurrentStep > 1)
			this.setState({ projectCurrentStep: this.state.projectCurrentStep - 1 })
	}

	navTabClick = (step: number) => {
		this.setState({ projectCurrentStep: step });

		// Customize button text and color based on the clicked tab
		if (step === 4) {
			//   this.setState({ buttonText: 'Save', buttonColor: 'success' });
		} else {
			//   this.setState({ buttonText: 'Continue', buttonColor: 'primary' });
		}
	};

	getCacheValues = () => {
		let cachedValues = useGetIDBValues
		cachedValues(`projects/${this.state.guid}`, (data: any) => {
			this.setState({
				clientName: data.client_name,
				clientContact: data.client_contact,
				clientPhone: data.client_phone,
				clientEmail: data.client_email,
				engineerName: data.engineer_name,
				engineerPhone: data.engineer_phone,
				engineerEmail: data.engineer_email,
				siteLocation: data.location,
				startDate: data.start_date,
				endDate: data.end_date,
				startTime: data.start_time,
				projectNo: data.project_identifier,
				siteDescription: data.site_description,
				keySiteFeatures: data.key_site_features,
				siteHistory: data.site_history,
				keyHS: data.key_hands,
				address1: data.address,
				address2: data.address2,
				city: data.town_city,
				county: data.county_state,
				postcode: data.postcode,
				expectedStrata: data.expected_strata,
				notes: data.notes,
				equipmentRequired: data.equipment_required,
				company_guid: data.company_guid,
				parked: data.parked,
			})
		}).then()
		// get cached hole locations
		cachedValues(`hole-locations/project/${this.state.guid}`, (data: any) => {
			this.setState({
				boreholes: data
			})
		})

	}

	componentDidMount() {
		this.getCacheValues()
	}

	// next = (event: any) => {
	// 	if (this.state.projectCurrentStep < 5) {
	// 		this.setState({ projectCurrentStep: this.state.projectCurrentStep + 1 })

	// 		return
	// 	}

	// 	// Form completed
	// 	event.preventDefault()
	// 	this.save()
	// }

	next = (event: React.FormEvent) => {
		event.preventDefault(); // Prevent form submission
	  
		if (this.state.projectCurrentStep < 4) {
		  this.setState({ projectCurrentStep: this.state.projectCurrentStep + 1 });
		} else {
			// Form completed
			event.preventDefault()
			this.save()
		}
	  };




	// save = () => { 

	// 	// Log the current state
	// 	console.log('Current state:', this.state);

	// 	// Log data sent to the API
	// 	console.log('Borehole Data sent to the API:', this.state.boreholes);

	// 	// if on final step and boreholes is not empty then save it
	// 	// if ((this.state.projectCurrentStep === 4) && (this.state.boreholes.length > 0)) {
	// 	if (this.state.projectCurrentStep === 4) {
	// 		const token = localStorage.getItem('drillit-token')

	// 		fetch(`${process.env.REACT_APP_API_ADDR}hole-locations/project/json`, {
	// 			method: 'POST',
	// 			headers: {
	// 				'Content-Type': 'application/json',
	// 				'Accept': 'application/json',
	// 				'Authorization': `Bearer ${token}`,
	// 			},
	// 			body: JSON.stringify(this.state.boreholes)
	// 		})
	// 			//return the status code here
	// 			.then(response => response)
	// 			.catch(err => err)
	// 	}

	// 	let saveToIDB = useSetIndexedDBValues
	// 	// console.log('saveToIDB:', saveToIDB)
	// 	saveToIDB({
	// 		guid: this.state.guid, //
	// 		company_guid: this.state.company_guid, //
	// 		project_identifier: this.state.projectNo, //
	// 		location: this.state.siteLocation, //
	// 		start_date: this.state.startDate, //
	// 		end_date: this.state.endDate, //
	// 		start_time: this.state.startTime, //
	// 		address: this.state.address1, //
	// 		address2: this.state.address2, //
	// 		town_city: this.state.city, //
	// 		county_state: this.state.county, //
	// 		postcode: this.state.postcode, //
	// 		site_description: this.state.siteDescription, //
	// 		key_site_features: this.state.keySiteFeatures, //
	// 		site_history: this.state.siteHistory, //
	// 		key_hands: this.state.keyHS, //
	// 		expected_strata: this.state.expectedStrata, //
	// 		notes: this.state.notes, //
	// 		equipment_required: this.state.equipmentRequired, //
	// 		engineer_name: this.state.engineerName, //
	// 		engineer_phone: this.state.engineerPhone, //
	// 		engineer_email: this.state.engineerPhone, //
	// 		client_name: this.state.clientName, //
	// 		client_contact: this.state.clientContact, //
	// 		client_phone: this.state.clientPhone, //
	// 		client_email: this.state.clientEmail, //
	// 		// boreholes: this.state.boreholes,
	// 		parked: this.state.parked, //
	// 	}, 'projects', false, 'PUT')
	// 		.then(() => this.setState({ redirect: true }))
	// }

	getCompanyID = () => {
		get('drillit-user').then(data => {
			console.log('drillit-user data:', data)
			this.setState({
				company_id: data.user?.company.id,
			})
		})
	}

	save = () => { 

		// Log the current state
		console.log('Current state:', this.state);

		updateProject(
			this.state.guid, 
			this.state.company_guid, 
			this.state.company_id, 
			this.state.projectNo, 
			this.state.siteLocation, 
			this.state.startDate, 
			this.state.endDate, 
			this.state.startTime, 
			this.state.address1, 
			this.state.address2, 
			this.state.city, 
			this.state.county, 
			this.state.postcode, 
			this.state.siteDescription, 
			this.state.keySiteFeatures, 
			this.state.siteHistory, 
			this.state.keyHS, 
			this.state.expectedStrata, 
			this.state.notes, 
			this.state.equipmentRequired, 
			this.state.engineerName, 
			this.state.engineerPhone, 
			this.state.engineerPhone, 
			this.state.clientName, 
			this.state.clientContact, 
			this.state.clientPhone, 
			this.state.clientEmail, 
			this.state.parked, 
		)
		.then(() => this.setState({ redirect: true }))

	}



    // Method to toggle the 'parked' state
    toggleParked = () => {
        this.setState(prevState => ({
            parked: prevState.parked === 0 ? 1 : 0
        }));
    }


	render() {

		const { redirect } = this.state

		console.log('this.state.parked:', this.state.parked)

		if (redirect) {
			return <Redirect to={`/projects/dashboard/${this.props.router.match.params.id}/company/${this.state.company_guid}`} />
		}

		// Determine archive button text based on 'parked' state
        const archiveButtonText = this.state.parked === 0 ? 'Archive Project' : 'Unarchive Project';


		return (
			<>
				<Header toggleSidebar={this.props.toggleSidebar} dark={true} />

				<Modal title="Edit Project" toggleModal={() => this.props.router.history.goBack()}>

					<div className="px-2 sm:px-10">

						<WorkflowSteps className="mt-0 mb-4" steps={[
							{
								label: 'Basics',
								status: stepStatus(1, this.state.projectCurrentStep),
								onClick: () => this.navTabClick(1),
							}, {
								label: 'Address',
								status: stepStatus(2, this.state.projectCurrentStep),
								onClick: () => this.navTabClick(2),
							}, {
								label: 'Site Details',
								status: stepStatus(3, this.state.projectCurrentStep),
								onClick: () => this.navTabClick(3),
							}, {
								label: 'Contacts',
								status: stepStatus(4, this.state.projectCurrentStep),
								onClick: () => this.navTabClick(4),
							}, 
							// {
							// 	label: 'Borehole Requirements',
							// 	status: stepStatus(5, this.state.projectCurrentStep),
							// 	onClick: () => this.navTabClick(5),
							// },
						]} />

<form onSubmit={this.next}>

						{this.state.projectCurrentStep === 1 &&

								<>
							 {/* <form onSubmit={this.next}> */}

								<div className="grid md:grid-cols-1 md:gap-8 w-full sm:w-3/5 mx-auto" >
									<div className="border border-grey-mid rounded px-2 pt-2 bg-sptTB mb-2">

										<div className="mb-1 text-sm sm:text-base md:text-base w-full">Site Location</div>
										<div className="mb-2 text-sm sm:text-xl md:text-xl w-full bg-grey-mid rounded py-1 px-2">{this.state.siteLocation}</div>

										<div className="grid md:grid-cols-2 md:gap-6">
											<div>
												<div className="mb-1 text-sm sm:text-base md:text-base w-full">Project ID</div>
												<div className="mb-2 text-sm sm:text-xl md:text-xl w-full bg-grey-mid rounded pt-1 pb-2 px-2">{this.state.projectNo}</div>
											</div>
											<InputGroup
												label="Start Time"
												type={'time'}
												value={this.state.startTime}
												onChange={(value) => this.setState({ startTime: value })}
												className="mb-2 text-sm sm:text-base md:text-base w-full"
												inputCSS="text-sm sm:text-lg md:text-xl w-full"
											/>

										</div>

										<div className="grid md:grid-cols-2 md:gap-6 pb-4">

											<InputGroup
												label="Start Date"
												type={'date'}
												value={this.state.startDate}
												onChange={(value) => this.setState({ startDate: value })}
												className="mb-2 text-sm sm:text-base md:text-base w-full"
												inputCSS="text-sm sm:text-lg md:text-xl w-full"
											/>

											<InputGroup
												label="End Date"
												type={'date'}
												value={this.state.endDate}
												onChange={(value) => this.setState({ endDate: value })}
												className="mb-2 text-sm sm:text-base md:text-base w-full"
												inputCSS="text-sm sm:text-lg md:text-xl w-full"
											/>

										</div>

									</div>
								</div>

								<div className="panelFooter pt-5 pb-5 sm:pb-4 md:pb-10 px-4 md:px-14 flex items-center justify-between">
									{/* <div className="w-36 bg-grey-mid rounded py-2 md:py-3 text-center text-xs md:text-base"
									onClick={this.toggleParked}>
										{archiveButtonText}
									</div> */}
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<CustomCheckbox
											checked={this.state.parked === 1} // Checkbox is checked if project is archived
											onChange={this.toggleParked} // Toggle 'parked' state on click
										/>
										<span style={{ marginLeft: '8px' }}>Archive</span>
									</div>
									<WorkflowActions onBack={() => this.previous()} canExit={false} />
								</div>			
							{/* </form> */}

						</>
						}



						{this.state.projectCurrentStep === 2 &&

						<>
							{/* <form onSubmit={this.next}> */}


								<div className="grid w-full sm:w-3/5 mx-auto" >

									<div className="border border-grey-mid rounded px-2 pt-2 bg-sptTB pb-4">

										<InputGroup label="Address"
											value={this.state.address1}
											onChange={(value) => this.setState({ address1: value })}
											className="mb-1 text-sm sm:text-base md:text-base w-full"
											inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
											autoComplete="new-password"
										/>

										<InputGroup label="Address 2"
											value={this.state.address2}
											onChange={(value) => this.setState({ address2: value })}
											className="mb-1 text-sm sm:text-base md:text-base w-full"
											inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
											autoComplete="new-password"
										/>

										<InputGroup label="Town/City"
											value={this.state.city}
											onChange={(value) => this.setState({ city: value })}
											className="mb-1 text-sm sm:text-base md:text-base w-full"
											inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
											autoComplete="new-password"
										/>

										<div className="grid grid-cols-2 sm:grid-cols-2 gap-2 sm:gap-6">
											<div>
												<InputGroup label="County/State"
													value={this.state.county}
													onChange={(value) => this.setState({ county: value })}
													className="mb-1 text-sm sm:text-base md:text-base w-full"
													inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
													autoComplete="new-password"
												/>
											</div>

											<div>
												<InputGroup label="Postcode/Zip"
													value={this.state.postcode}
													onChange={(value) => this.setState({ postcode: value })}
													className="mb-1 text-sm sm:text-base md:text-base w-full"
													inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
													autoComplete="new-password"
												/>
											</div>
										</div>
									</div>
								</div>

								<div className="panelFooter pt-5 pb-5 sm:pb-4 md:pb-10 px-4 md:px-14 flex items-center justify-between">
									{/* <div className="w-36 bg-grey-mid rounded py-2 md:py-3 text-center text-xs md:text-base"
									onClick={this.toggleParked}>
										{archiveButtonText}
									</div> */}
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<CustomCheckbox
											checked={this.state.parked === 1} // Checkbox is checked if project is archived
											onChange={this.toggleParked} // Toggle 'parked' state on click
										/>
										<span style={{ marginLeft: '8px' }}>Archive</span>
									</div>
									<WorkflowActions onBack={() => this.previous()} canExit={false} />
								</div>

							{/* </form> */}

							</>
						}



						{this.state.projectCurrentStep === 3 &&

						<>
							{/* <form onSubmit={this.next}> */}


								<div className="grid md:grid-cols-1 md:gap-8 w-full sm:w-3/5 mx-auto" >


									<div className="border border-grey-mid rounded px-2 pt-2 bg-sptTB pb-4">

										<InputGroup label="Site Description"
											value={this.state.siteDescription}
											onChange={(value) => this.setState({ siteDescription: value })}
											className="mb-1 text-sm sm:text-base md:text-base w-full"
											inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
											autoComplete="new-password"
										/>

										<InputGroup label="Key Site Features"
											value={this.state.keySiteFeatures}
											onChange={(value) => this.setState({ keySiteFeatures: value })}
											className="mb-1 text-sm sm:text-base md:text-base w-full"
											inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
											autoComplete="new-password"
										/>

										<InputGroup label="Site History"
											value={this.state.siteHistory}
											onChange={(value) => this.setState({ siteHistory: value })}
											className="mb-1 text-sm sm:text-base md:text-base w-full"
											inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
											autoComplete="new-password"
										/>

										<InputGroup label="Key H&amp;S:"
											value={this.state.keyHS}
											onChange={(value) => this.setState({ keyHS: value })}
											className="mb-1 text-sm sm:text-base md:text-base w-full"
											inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
											autoComplete="new-password"
										/>

										<InputGroup label="Expected Strata:"
											value={this.state.expectedStrata}
											onChange={(value) => this.setState({ expectedStrata: value })}
											className="mb-1 text-sm sm:text-base md:text-base w-full"
											inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
											autoComplete="new-password"
										/>

										<InputGroup label="Notes"
											value={this.state.notes}
											onChange={(value) => this.setState({ notes: value })}
											className="mb-1 text-sm sm:text-base md:text-base w-full"
											inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
											autoComplete="new-password"
										/>

										<InputGroup label="Equipment Required:"
											value={this.state.equipmentRequired}
											onChange={(value) => this.setState({ equipmentRequired: value })}
											className="mb-1 text-sm sm:text-base md:text-base w-full"
											inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
											autoComplete="new-password"
										/>

									</div>
								</div>

								<div className="panelFooter pt-5 pb-5 sm:pb-4 md:pb-10 px-4 md:px-14 flex items-center justify-between">
									{/* <div className="w-36 bg-grey-mid rounded py-2 md:py-3 text-center text-xs md:text-base"
									onClick={this.toggleParked}>
										{archiveButtonText}
									</div> */}
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<CustomCheckbox
											checked={this.state.parked === 1} // Checkbox is checked if project is archived
											onChange={this.toggleParked} // Toggle 'parked' state on click
										/>
										<span style={{ marginLeft: '8px' }}>Archive</span>
									</div>
									<WorkflowActions onBack={() => this.previous()} canExit={false} />
								</div>

							{/* </form> */}

							</>
						}



						{this.state.projectCurrentStep === 4 &&

						<>

							{/* <form onSubmit={this.next}> */}

								<div className="grid md:grid-cols-1 md:gap-8 w-full sm:w-3/5 mx-auto" >

									<div className="border border-grey-mid rounded px-2 pt-2 bg-sptTB pb-4">

										<InputGroup label="Engineer Name"
											value={this.state.engineerName}
											onChange={(value) => this.setState({ engineerName: value })}
											className="mb-1 text-sm sm:text-base md:text-base w-full"
											inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
											autoComplete="new-password"
										/>

										<InputGroup label="Engineer Phone"
											value={this.state.engineerPhone}
											onChange={(value) => this.setState({ engineerPhone: value })}
											className="mb-1 text-sm sm:text-base md:text-base w-full"
											inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
											autoComplete="new-password"
										/>

										<InputGroup label="Engineer Email"
											value={this.state.engineerEmail}
											onChange={(value) => this.setState({ engineerEmail: value })}
											className="mb-1 text-sm sm:text-base md:text-base w-full"
											inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
											autoComplete="new-password"
										/>

										<InputGroup label="Client (Company)"
											value={this.state.clientName}
											onChange={(value) => this.setState({ clientName: value })}
											className="mb-1 text-sm sm:text-base md:text-base w-full"
											inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
											autoComplete="new-password"
										/>

										<InputGroup label="Client Contact"
											value={this.state.clientContact}
											onChange={(value) => this.setState({ clientContact: value })}
											className="mb-1 text-sm sm:text-base md:text-base w-full"
											inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
											autoComplete="new-password"
										/>

										<InputGroup label="Client Phone"
											value={this.state.clientPhone}
											onChange={(value) => this.setState({ clientPhone: value })}
											className="mb-1 text-sm sm:text-base md:text-base w-full"
											inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
											autoComplete="new-password"
										/>

										<InputGroup label="Client Email"
											value={this.state.clientEmail}
											onChange={(value) => this.setState({ clientEmail: value })}
											className="mb-1 text-sm sm:text-base md:text-base w-full"
											inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
											autoComplete="new-password"
										/>

									</div>
								</div>

								<div className="panelFooter pt-5 pb-5 sm:pb-4 md:pb-10 px-4 md:px-14 flex items-center justify-between">
									{/* <div className="w-36 bg-grey-mid rounded py-2 md:py-3 text-center text-xs md:text-base"
									onClick={this.toggleParked}>
										{archiveButtonText}
									</div> */}
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<CustomCheckbox
											checked={this.state.parked === 1} // Checkbox is checked if project is archived
											onChange={this.toggleParked} // Toggle 'parked' state on click
										/>
										<span style={{ marginLeft: '8px' }}>Archive</span>
									</div>
									<WorkflowActions onBack={() => this.previous()} canExit={false} />
								</div>

							{/* </form> */}

							</>
						}
					</form>

						{this.state.projectCurrentStep === 5 &&
							<>
								<EditBoreholes
									defaultBoreholes={this.state.boreholes}
									projectId={this.state.guid}
									onChange={(boreholes: any[]) => { console.log(this.state.guid); this.setState({ boreholes: boreholes }) }} />
								<div className="h-20"></div>
								<form onSubmit={this.next}>
									<div className="panelFooter pt-5 pb-5 sm:pb-4 md:pb-10 px-4 md:px-14">
										<WorkflowActions canExit={false}
											onBack={() => this.previous()} />
									</div>
								</form>
							</>
						}
					</div>
				</Modal>
			</>
		)
	}
}

export default ProjectsUpdate
