import React, { useState, useRef } from 'react'

import Panel from '../../../components/Panel'
import Button from '../../../components/form/Button'
import Modal from '../../../components/Modal'

import ProjectOptionsBorehole from '../../../components/project/ProjectOptionsBorehole'
import ProjectHeader from '../../../components/project/ProjectHeader'

import SyncStatus from '../../../helpers/syncStatus'

import Samples from '../../../components/project/workflow/SamplesLogging'
import SoilDescription from '../../../components/project/workflow/SoilDescription'
import SoilDescriptionEditor from '../../../components/project/workflow/SoilDescriptionEditor'
import Comments from '../../../components/project/workflow/Comments'

import CBRPenetration from '../../../components/project/workflow/CBRPenetration'
import CBREquipment from '../../../components/project/workflow/CBREquipment'
import CBRConditions from '../../../components/project/workflow/CBRConditions'

import Labels from '../../../components/project/workflow/Labels'

import Header from '../../../layout/Header'
import Container from '../../../layout/Container'

import cablePercussiveIcon from '../../../assets/icons/cablepercussive-white.svg'


import '../../../LogsheetCSS.css';
import Barcode from 'react-jsbarcode'
import closeIcon from '../../../assets/icons/close.svg'
import previewIcon from '../../../assets/icons/preview.svg'
import labelIcon from '../../../assets/icons/label.svg'
import { Link } from 'react-router-dom'

import { useSetIndexedDBValues } from '../../../helpers/addToIDB'
import { useUpdateIndexedDBValues } from "../../../helpers/updateIDB"
import { useGetIDBValues } from "../../../helpers/getIDBKey";
import { usePostToApi } from "../../../helpers/postToAPI";
import { updateActions } from "../../../helpers/updateActions"
import CompactTableObject from "../../../components/CompactTableObject";
import getWeatherList from "../../../helpers/getWeatherList";
import { connect } from "react-redux";
import { get, set, update } from "idb-keyval";
import edit from "../../../assets/icons/edit.svg";
import { closeHole } from "../../../helpers/closeHole";
import { Redirect } from "react-router-dom";
import datesAreOnSameDay from "../../../helpers/datesAreOnSameDay";

import menubars from '../../../assets/menubars.svg'
// import Accordion from '../../../layout/Accordion'

import icon_ags from '../../../assets/icons/icon_ags.png'
import icon_preview from '../../../assets/icons/icon_preview.png'
import icon_savelog from '../../../assets/icons/icon_savelog.png'
import icon_complete from '../../../assets/icons/icon_complete.png'
import icon_project from '../../../assets/icons/icon_project.png'
import icon_email from '../../../assets/icons/icon_email.png'
import icon_labels from '../../../assets/icons/icon_labels.png'
import icon_newborehole from '../../../assets/icons/icon_newborehole.png'
import icon_plantcheck from '../../../assets/icons/icon_plantcheck.png'
import icon_editrig from '../../../assets/icons/icon_editrig.png'

import NewHole from '../../NewHole'
import AGS from '../../AGS'
import Email from '../../Email'
import SaveLog from '../../SaveLog'

import Label from '../../../components/Label'
import PrintLabel from '../../PrintLabelModal'

interface Props {
	router: any,
	toggleSidebar: () => void,
	selectedWeather: string,
	selectedDate: string,
}

interface State {
	project_guid: string,
	borehole: any,
	completingBorehole: boolean,

	showD: boolean,
	showDEdit: boolean,
	showB: boolean,
	showBEdit: boolean,
	showES: boolean,
	showESEdit: boolean,
	showWS: boolean,
	showWSEdit: boolean,
	showSoilDescription: boolean,
	showComments: boolean,
	showSoilDescriptionEdit: boolean,
	showCommentsEdit: boolean,

	showCBRPenetration: boolean,
	showCBREquipment: boolean,
	showCBRConditions: boolean,

	showCBRPenetrationEdit: boolean,
	showCBREquipmentEdit: boolean,
	showCBRConditionsEdit: boolean,

	// Labels: any,

	Samples: any,
	SoilDescription: any,
	SoilDescriptionEditor: any,
	Comments: any,
	editingHole: any,
	editingIndex: any,
	redirect: boolean,

	CBRPenetration: any,
	CBREquipment: any,
	CBRConditions: any,

	project: any,
	holeType: string,

	previewVisible: boolean,
	labelsVisible: boolean,
	bhMenuVisible: boolean,

	showHoleEdit: boolean,
	showNewHole: boolean,
	showEmail: boolean,
	showAGS: boolean,
	setShowNewHole: boolean,
	setShowEmail: boolean,
	setShowAGS: boolean,

	showSaveLog: boolean,
	setShowSaveLog: boolean,

	showPrintLabel: boolean,
	setShowPrintLabel: boolean,

	defaultValues: object,
	guid: string;
}

class ProjectsCablePercussiveBorehole extends React.Component<Props, State> {


	constructor(props: any) {
		super(props)

		this.state = {
			guid: this.props.router.match.params.id,
			project_guid: this.props.router.match.params.id,
			borehole: {
				guid: this.props.router.match.params.borehole,
			},
			completingBorehole: false,

			// showLabels: false,

			// showPreview: false,

			showD: false,
			showB: false,
			showES: false,
			showWS: false,
			showDEdit: false,
			showBEdit: false,
			showESEdit: false,
			showWSEdit: false,
			showSoilDescription: false,
			showComments: false,
			showSoilDescriptionEdit: false,
			showCommentsEdit: false,

			showCBRPenetration: false,
			showCBREquipment: false,
			showCBRConditions: false,

			showCBRPenetrationEdit: false,
			showCBREquipmentEdit: false,
			showCBRConditionsEdit: false,

			Samples: [],
			SoilDescription: [],
			SoilDescriptionEditor: [],
			Comments: [],

			CBRPenetration: [],
			CBREquipment: [],
			CBRConditions: [],

			editingHole: '',
			editingIndex: null,
			redirect: false,

			project: '',
			holeType: '',

			previewVisible: false,
			labelsVisible: false,
			bhMenuVisible: false,

			showHoleEdit: false,
			showNewHole: false,
			showEmail: false,
			showAGS: false,
			setShowNewHole: false,
			setShowEmail: false,
			setShowAGS: false,

			showSaveLog: false,
			setShowSaveLog: false,

			showPrintLabel: false,
			setShowPrintLabel: false,

			defaultValues: {},
		}

		// console.log('Project Guid: ', this.state.project_guid)

		this.previewClick = this.previewClick.bind(this);

		this.labelsClick = this.labelsClick.bind(this);

		this.bhMenuClick = this.bhMenuClick.bind(this);
	}

	previewClick() {
		this.setState({ previewVisible: !this.state.previewVisible });
	}

	labelsClick() {
		this.setState({ labelsVisible: !this.state.labelsVisible });
	}

	printLabelClick = () => {
		this.setState({ showPrintLabel: true });
	}

	bhMenuClick() {
		this.setState({ bhMenuVisible: !this.state.bhMenuVisible });
	}

	menuAndPreview = () => {
		this.setState({ bhMenuVisible: !this.state.bhMenuVisible });
		this.setState({ previewVisible: !this.state.previewVisible });
	}

	menuAndLabels = () => {
		this.setState({ bhMenuVisible: !this.state.bhMenuVisible });
		this.setState({ labelsVisible: !this.state.labelsVisible });
	}

	menuAndMarkAsComplete = () => {
		this.setState({ bhMenuVisible: !this.state.bhMenuVisible });
		this.setState({ completingBorehole: true });
	}

	menuAndNewHole = () => {
		this.setState({ bhMenuVisible: !this.state.bhMenuVisible });
		this.setState({ showNewHole: true });
	}

	menuAndEmail = () => {
		this.setState({ bhMenuVisible: !this.state.bhMenuVisible });
		this.setState({ showEmail: true });
	}

	menuAndAGS = () => {
		this.setState({ bhMenuVisible: !this.state.bhMenuVisible });
		this.setState({ showAGS: true });
	}

	menuAndSaveLog = () => {
		this.setState({ bhMenuVisible: !this.state.bhMenuVisible });
		this.setState({ showSaveLog: true });
	}

	getWeatherIcon = () => {
		let weatherIcon = ''

		getWeatherList().map((weather) => {
			if (weather.label === this.props.selectedWeather)
				weatherIcon = weather.icon
		})

		return weatherIcon
	}

	componentDidMount() {
		this.getCacheValues()
	}

	getCacheValues = () => {
		// get(`drillit-hole-actions/${this.props.router.match.params.borehole}`)
		// 	.then((data:any) => this.setState(data))
		let cachedValues = useGetIDBValues

		cachedValues(`actions/${this.props.router.match.params.borehole}`, (data: any) => {
			// TODO in situ are two separate tests
			console.log("this.state.samples:", data)
			this.setState({
				Samples: typeof data?.hole_data?.action_sample == 'undefined' ? [] : data?.hole_data?.action_sample,
				SoilDescription: typeof data?.hole_data?.action_soil_description == 'undefined' ? [] : data?.hole_data?.action_soil_description,
				Comments: typeof data?.hole_data?.action_comment == 'undefined' ? [] : data?.hole_data?.action_comment,

				// CBRPenetration: typeof data?.hole_data?.action_comment == 'undefined' ? [] : data?.hole_data?.action_cbr_penetration,
				// CBREquipment: typeof data?.hole_data?.action_comment == 'undefined' ? [] : data?.hole_data?.action_cbr_equipment,
				// CBRConditions: typeof data?.hole_data?.action_comment == 'undefined' ? [] : data?.hole_data?.action_cbr_conditions,
			})
		})

		cachedValues(`hole-locations/${this.props.router.match.params.borehole}`, (data: any) => {
			this.setState({
				borehole: data
			})
		})
		// get the project and the company guid from that
		cachedValues(`projects/${this.state.project_guid}`, (data: any) => {
			this.setState({
				project: data
			})
		})
	}

	setDefaultValues = (show: string) => {
		const thisHoleActions = get(`drillit-actions/${this.state.borehole.guid}`)
			.then(data => {
				const allActionSample = data.hole_data.action_sample
				const thisAction = allActionSample.filter((action: any) => action.guid.includes(this.state.editingIndex))
				this.setState({
					defaultValues: thisAction[0]
				})
				// this is horrible, dynamic key names for setState should be possible
				switch (show) {
					case 'D':
						this.setState({ showDEdit: true, })
						break
					case 'B':
						this.setState({ showBEdit: true, })
						break
					case 'WS':
						this.setState({ showWSEdit: true, })
						break
					case 'ES':
						this.setState({ showESEdit: true, })
						break
				}

				return thisAction
			})
	}

	saveAction = (value: any, type: string, hide: any) => {
		const saveToIDB = useSetIndexedDBValues
		const updateIDB = useUpdateIndexedDBValues

		value['borehole_guid'] = this.state.borehole.guid
		value['type'] = type

		switch (type) {
			case 'D':
			case 'B':
			case 'ES':
			case 'WS':
				const submitSampleData = {
					"task_type": "action_sample",
					"hole_type_id": this.state.borehole.starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value,
						"weather_details": this.props.selectedWeather,
					}
				}

				if (value['update']) {
					// updateIDB(value, `hole-actions/${value['borehole_guid']}`, 'Samples')
					// updateActions("sample", `${value['guid']}`, submitSampleData).then()
					const newState = this.state.Samples.map((obj: any) => obj.guid === value['guid'] ? value : obj)
					this.setState(prevState => {
						return {
							...prevState,
							Samples: newState
						}
					}, () => updateIDB(this.state, `actions/${value['guid']}`, 'Samples', value['guid'], 'sample', submitSampleData))
				} else {
					this.setState(prevState => {
						return {
							...prevState,
							Samples: [...prevState.Samples, value]
						}
					}, () => {
						update(`drillit-actions/${value['borehole_guid']}`, (val) => {
							val.hole_data.action_sample = this.state.Samples
							return val
						})
					})

					saveToIDB(submitSampleData, 'action', true)
				}

				break

			case 'SoilDescription':

				const submitSoilDescriptionData = {
					"task_type": "action_soil_description",
					"hole_type_id": this.state.borehole.starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value
					}
				}

				if (value['update']) {
					// updateIDB(value, `hole-actions/${value['borehole_guid']}`, 'SoilDescription')
					// updateActions("soil_description", `${value['guid']}`, submitSoilDescriptionData).then()
					const newState = this.state.SoilDescription.map((obj: any) => obj.guid === value['guid'] ? value : obj)
					this.setState(prevState => {
						return {
							...prevState,
							SoilDescription: newState
						}
					}, () => updateIDB(this.state, `actions/${value['guid']}`, 'SoilDescription', value['guid'], 'soil_description', submitSoilDescriptionData))

				} else {
					this.setState(prevState => {
						return {
							...prevState,
							SoilDescription: [...prevState.SoilDescription, value]
						}
					}, () => {
						update(`drillit-actions/${value['borehole_guid']}`, (val) => {
							val.hole_data.action_soil_description = this.state.SoilDescription
							return val
						})
					})

					saveToIDB(submitSoilDescriptionData, 'action', true)
				}


				break

			case 'Comments':
				const submitCommentsData = {
					"task_type": "action_comment",
					"hole_type_id": this.state.borehole.starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value,
						"type": value['comment_type'],
					}

				}

				if (value['update']) {
					// updateIDB(value, `hole-actions/${value['borehole_guid']}`, 'Comments')
					// updateActions("comment", `${value['guid']}`, submitCommentsData).then()
					const newState = this.state.Comments.map((obj: any) => obj.guid === value['guid'] ? value : obj)
					this.setState(prevState => {
						return {
							...prevState,
							Comments: newState
						}
					}, () => updateIDB(this.state, `actions/${value['guid']}`, 'Comments', value['guid'], 'comment', submitCommentsData))

				} else {
					this.setState(prevState => {
						return {
							...prevState,
							Comments: [...prevState.Comments, value]
						}
					}, () => {
						update(`drillit-actions/${value['borehole_guid']}`, (val) => {
							val.hole_data.action_comment = this.state.Comments
							return val
						})
					})

					saveToIDB(submitCommentsData, 'action', true)
				}

				break

			case 'CBRPenetration':
				const submitCBRPenetration = {
					"task_type": "action_comment",
					"hole_type_id": this.state.borehole.starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value,
						"type": value['comment_type'],
					}

				}

				if (value['update']) {
					updateIDB(value, `hole-actions/${value['borehole_guid']}`, 'Comments')
					updateActions("comment", `${value['guid']}`, submitCBRPenetration).then()
					const newState = this.state.Comments.map((obj: any) => obj.guid === value['guid'] ? value : obj)
					this.setState(prevState => {
						return {
							...prevState,
							Comments: newState
						}
					})

				} else {
					this.setState(prevState => {
						return {
							...prevState,
							Comments: [...prevState.Comments, value]
						}
					}, () => set(`drillit-hole-actions/${value['borehole_guid']}`, this.state))

					saveToIDB(submitCBRPenetration, 'action', true)
				}

				break

			case 'CBREquipment':
				const submitCBREquipment = {
					"task_type": "action_comment",
					"hole_type_id": this.state.borehole.starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value,
						"type": value['comment_type'],
					}

				}

				if (value['update']) {
					updateIDB(value, `hole-actions/${value['borehole_guid']}`, 'Comments')
					updateActions("comment", `${value['guid']}`, submitCBREquipment).then()
					const newState = this.state.Comments.map((obj: any) => obj.guid === value['guid'] ? value : obj)
					this.setState(prevState => {
						return {
							...prevState,
							Comments: newState
						}
					})

				} else {
					this.setState(prevState => {
						return {
							...prevState,
							Comments: [...prevState.Comments, value]
						}
					}, () => set(`drillit-hole-actions/${value['borehole_guid']}`, this.state))

					saveToIDB(submitCBREquipment, 'action', true)
				}

				break

			case 'CBRConditions':
				const submitCBRConditions = {
					"task_type": "action_comment",
					"hole_type_id": this.state.borehole.starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value,
						"type": value['comment_type'],
					}

				}

				if (value['update']) {
					updateIDB(value, `hole-actions/${value['borehole_guid']}`, 'Comments')
					updateActions("comment", `${value['guid']}`, submitCBRConditions).then()
					const newState = this.state.Comments.map((obj: any) => obj.guid === value['guid'] ? value : obj)
					this.setState(prevState => {
						return {
							...prevState,
							Comments: newState
						}
					})

				} else {
					this.setState(prevState => {
						return {
							...prevState,
							Comments: [...prevState.Comments, value]
						}
					}, () => set(`drillit-hole-actions/${value['borehole_guid']}`, this.state))

					saveToIDB(submitCBRConditions, 'action', true)
				}

				break

		}

	}

	getFromIdb = (type: any): any => {
		let cachedValues = useGetIDBValues

	}

	render() {

		const { redirect } = this.state

		if (redirect) {
			// return <Redirect to={`/projects`} />
			return <Redirect to={`/projects/dashboard/${this.state.project_guid}/cable-percussive`} />
		}


		const projectLocation = 'Norwich' // project_location
			let logsheetLocationStyle= "headerCellLocationInput"
			if(projectLocation.length <= 25){logsheetLocationStyle= "headerCellLocationInput"}
			if(projectLocation.length > 25 && projectLocation.length <= 60){logsheetLocationStyle= "headerCellLocationInputSmall"}
			if(projectLocation.length > 60){logsheetLocationStyle= "headerCellLocationInputXSmall"}
		const projectID = 'GN12345' // project_number
		const boreholeName = 'CBR001' // borehole_name
		const client = 'Bovis Homes' // project_client
		const sheetDate = '02/03/2023'

		let labelbarcodeVar = projectID + boreholeName

		const barcodeWidths = [
			3.3, 3.3, 3.3, 3.3, 3.3, 3.3, 3.0, 2.7, 2.5, 2.3, // 1 to 10
			2.1, 2.0, 1.85, 1.75, 1.65, 1.55, 1.5, 1.42, 1.36, 1.3, // 11 to 20
			1.25, 1.2, 1.15, 1.1, 1.05, 1.0, 1.0, 0.95, 0.93, 0.90, // 21 to 30
			0.88, 0.85, 0.83, 0.80, 0.80, 0.76, 0.75, 0.73, 0.72, 0.71 // 31 to 40
		];

		let barcodeWidth = barcodeWidths[labelbarcodeVar.length - 1]

		let plantType = 'Cable Percussive'
		let plantID = 'Robs Big Shiny Rig'
		let plantTestDate = '30/01/2023'
		let sptID = 'SPT001'
		let sptRodType = '1 1/2" Whitworth'
		let sptCalibrationDate = '30/12/2022'
		let sptEnergyRatio = '68'

		let testType = 'Superheavy DP'
		let hammerMass = '63.5kg'
		let standardDrop = '500mm'
		let rodDiameter = '32mm'
		let rodMass = '6kg/m'
		let testMethod = 'BS EN ISO 22476-2'
		let coneDiameter = '32mm'
		let anvilType = ''
		let anvilDampner = ''
		let accBodyRef = ''

		let leadDriller = 'Rob Clews'
		let leadDrillerCSCS = '01234567890'
		let operative = 'Rob Clews'
		let operativeCSCS = '01234567890'
		let secondMan = 'Rod Lifter'
		let secondManCSCS = '0987654321'

		let companyName = 'ACME Ground Investigation Ltd.'
		let companyAddress1 = '1 The Street,'
		let companyAddress2 = ''
		let companyTown = 'The Town'
		let companyCounty = 'County'
		let companyPostcode = 'POSTCODE'
		let companyTelephone = '01493 700803'
		let companyEmail = 'info@acmegroundinvestigation.co.uk'

		//temp values
		let boreholeVar = ''
		// console.log(this.state.borehole.hole_name)
		let labelSample = ''
		let labelDepth = ''

		// Info to pass into 'ProjectHeader' & 'ProjectOptionsBorehole'
		// Hole Name
		// Hole Driller
		// 2nd man
		// Rig
		// Daily Plant Check

		let borehole_depth = ''

		// let current_casing_depth = (6.00).toFixed(2)
		let current_casing_depth = '5.00'

		let last_soil_description_depth = ''

		let numberOfSamplesD = 1
		let numberOfSamplesB = 0
		let numberOfSamplesES = 1
		let numberOfSamplesWS = 0

		let numberOfSPTsCPTs = 2
		let numberOfUSamples = 3
		let numberOfPSSamples = 4
		let numberOfShearVane = 5

		let numberOfElements = 0
		let numberOfSamples = numberOfSamplesD + numberOfSamplesB + numberOfSamplesES + numberOfSamplesWS
		let numberOfCoreSamples = 0
		let numberOfSoilDescriptions = 0
		let numberOfComments = 0
		let numberOfInsituTests = numberOfSPTsCPTs + numberOfUSamples + numberOfPSSamples + numberOfShearVane
		let numberOfGroundWaters = 0
		let numberOfBackfills = 0
		let numberOfInspectionPits = 0
		let numberOfWaterAdded = 0
		let numberOfReDrills = 0
		let numberOfChiselling = 0
		let numberOfCasingRecords = 0
		let numberOfDrillCoreSizeRecords = 0
		let numberOfWaterLevels = 0
		let numberOfDriveRecords = 0
		let numberOfTestEquipments = 0
		let numberOfTestConditions = 0
		let numberOfPenetrationData = 0
		// None of the above works as this is a CLASS... Maybe with a little numberOfElementsHelper could do the trick???

		// ADD TODAYs DATE
		let todaysDate = (new Date()).toLocaleDateString()
		let active_dates = [todaysDate]

		const companyLogo = "https://drillitlogs.com/images/company/" + this.state.project.company_guid + ".png"

		// console.log('this.props.toggleSidebar: ', this.props.toggleSidebar)
		console.log('this.props.router.match.params.id: ', this.props.router.match.params.id)
		console.log('this.state.project_guid: ', this.state.project_guid)

		return (
			<>

				<Header toggleSidebar={this.props.toggleSidebar}
					middle={
						<ProjectHeader projectId={this.props.router.match.params.id}
							information_check={true}
							holeType="CB"
							holeName={this.state.borehole?.hole_name}
							// holeName="hole_name"
							companyGuid={this.state.project.company_guid}
							projectGuid={this.state.project_guid}
							activeDates={active_dates}
						
							project_start_date='04/18/2023'
							site_description='Open field with big fence around it'
							key_site_features='Large muddy area in the middle'
							site_history='Lots of stuff done here before'
							key_hands='Look out for the crazy bull'
							expected_strata='topsoil over sand and gravels over chalk'
							notes='these are some notes'
							equipment_required='breaker and shovel'
							project_client='Bovis Homes'
							client_contact='Big Trevor'
							client_phone='0123456789'
							client_email='bigtrevor@bovishomes.com'
							engineer_name='Little Nigel'
							engineer_phone='0987654321'
							engineer_email='littlenigel@acmegroundinvestigations.com'
							rig_name=''
						/>
					}
				/>

				{/* {this.state.completingBorehole &&
					<Modal title="Mark as Complete" toggleModal={() => this.setState({ completingBorehole: false })}>
						<div className="flex items-center flex-col pt-36">
							<div className="mb-14">
								Confirm you have completed activity on this CBR
							</div>

							<div className="flex">
								<Button theme="secondary" size="lg" className="mr-8" onClick={() => this.setState({ completingBorehole: false })}>Cancel</Button>

								<Button theme="primary" size="lg" onClick={() => {
									closeHole(this.props.router.match.params.borehole, '2024-02-09', project_guid).then(() => this.setState({ redirect: true }))
								}}>Confirm</Button>
							</div>
						</div>
					</Modal>
				} */}

				{this.state.showD &&
					<Samples
						onClose={() => { this.setState({ showD: false }) }}
						onComplete={(value: any) => this.saveAction(value, 'D', this.setState({ showD: false }))}
						borehole={this.state.borehole.hole_name}
						selectedDate={this.props.selectedDate}
						title="Disturbed Sample (D)"
						casing_preset={current_casing_depth}
					/>
				}

				{this.state.showDEdit &&
					<Samples
						update={true}
						onClose={() => { this.setState({ showDEdit: false }) }}
						onComplete={(value: any) => this.saveAction(value, 'D', this.setState({ showDEdit: false }))}
						borehole={this.state.borehole.hole_name}
						title="Disturbed Sample (D)"
						defaultValues={this.state.Samples[this.state.editingIndex]}
						casing_preset={current_casing_depth}
					/>
				}

				{this.state.showB &&
					<Samples
						onClose={() => { this.setState({ showB: false }) }}
						onComplete={(value: any) => this.saveAction(value, 'B', this.setState({ showB: false }))}
						borehole={this.state.borehole.hole_name}
						selectedDate={this.props.selectedDate}
						title="Bulk Sample (B)"
						casing_preset={current_casing_depth}
					/>
				}

				{this.state.showBEdit &&
					<Samples
						update={true}
						onClose={() => { this.setState({ showBEdit: false }) }}
						onComplete={(value: any) => this.saveAction(value, 'B', this.setState({ showBEdit: false }))}
						borehole={this.state.borehole.hole_name} title="Bulk Sample (B)"
						defaultValues={this.state.Samples[this.state.editingIndex]}
						casing_preset={current_casing_depth}
					/>
				}

				{this.state.showES &&
					<Samples
						onClose={() => { this.setState({ showES: false }) }}
						onComplete={(value: any) => this.saveAction(value, 'ES', this.setState({ showES: false }))}
						borehole={this.state.borehole.hole_name}
						selectedDate={this.props.selectedDate}
						title="Environmental Sample (ES)"
						casing_preset={current_casing_depth}
					/>
				}

				{this.state.showESEdit &&
					<Samples
						update={true}
						onClose={() => { this.setState({ showESEdit: false }) }}
						onComplete={(value: any) => this.saveAction(value, 'ES', this.setState({ showESEdit: false }))}
						borehole={this.state.borehole.hole_name} title="Environmental Sample (ES)"
						defaultValues={this.state.Samples[this.state.editingIndex]}
						casing_preset={current_casing_depth}
					/>
				}

				{this.state.showWS &&
					<Samples
						onClose={() => { this.setState({ showWS: false }) }}
						onComplete={(value: any) => this.saveAction(value, 'WS', this.setState({ showWS: false }))}
						borehole={this.state.borehole.hole_name}
						selectedDate={this.props.selectedDate}
						title="Water Sample (WS)"
						casing_preset={current_casing_depth}
					/>
				}

				{this.state.showWSEdit &&
					<Samples
						update={true}
						onClose={() => { this.setState({ showWSEdit: false }) }}
						onComplete={(value: any) => this.saveAction(value, 'WS', this.setState({ showWSEdit: false }))}
						borehole={this.state.borehole.hole_name} title="Water Sample (WS)"
						defaultValues={this.state.Samples[this.state.editingIndex]}
						casing_preset={current_casing_depth}
					/>
				}

				{this.state.showSoilDescription &&
					<SoilDescription onClose={() => { this.setState({ showSoilDescription: false }) }}
						onComplete={(value: any) => this.saveAction(value, 'SoilDescription', this.setState({ showSoilDescription: false }))}
						borehole={this.state.borehole.hole_name}
						selectedDate={this.props.selectedDate}
						lastSoilDescriptionDepth={last_soil_description_depth}
					/>
				}

				{this.state.showSoilDescriptionEdit &&
					<SoilDescriptionEditor update={true} onClose={() => { this.setState({ showSoilDescriptionEdit: false }) }}
						onComplete={(value: any) => this.saveAction(value, 'SoilDescription', this.setState({ showSoilDescriptionEdit: false }))}
						borehole={this.state.borehole.hole_name}
						defaultValues={this.state.SoilDescription[this.state.editingIndex]}
					/>
				}

				{this.state.showComments &&
					<Comments onClose={() => { this.setState({ showComments: false }) }}
						onComplete={(value: any) => this.saveAction(value, 'Comments', this.setState({ showComments: false }))}
						borehole={this.state.borehole.hole_name}
						selectedDate={this.props.selectedDate}
					/>
				}

				{this.state.showCommentsEdit &&
					<Comments update={true}
						onClose={() => { this.setState({ showCommentsEdit: false }) }}
						onComplete={(value: any) => this.saveAction(value, 'Comments', this.setState({ showCommentsEdit: false }))}
						borehole={this.state.borehole.hole_name}
						defaultValues={this.state.Comments[this.state.editingIndex]}
					/>
				}

				{this.state.showCBRPenetration &&
					<CBRPenetration onClose={() => { this.setState({ showCBRPenetration: false }) }}
						onComplete={(value: any) => this.saveAction(value, 'CBRPenetration', this.setState({ showCBRPenetration: false }))}
						borehole={this.state.borehole.hole_name}
						title="Penetration Data"
					/>
				}

				{this.state.showCBRPenetrationEdit &&
					<CBRPenetration update={true}
						onClose={() => { this.setState({ showCBRPenetrationEdit: false }) }}
						onComplete={(value: any) => this.saveAction(value, 'CBRPenetration', this.setState({ showCBRPenetrationEdit: false }))}
						borehole={this.state.borehole.hole_name}
						defaultValues={this.state.SoilDescription[this.state.editingIndex]}
						title="Penetration Data"
					/>
				}

				{this.state.showCBREquipment &&
					<CBREquipment onClose={() => { this.setState({ showCBREquipment: false }) }}
						onComplete={(value: any) => this.saveAction(value, 'CBREquipment', this.setState({ showCBREquipment: false }))}
						borehole={this.state.borehole.hole_name}
						// loadUnits='kN'
						defaultValues={this.state.CBREquipment[this.state.editingIndex]}
						options={{ loadUnits: 'T', inputOption1: 'kN', inputOption2: 'T' }} // get loadUnits from User pref
						title="CBR Equipment"
					/>
				}

				{this.state.showCBREquipmentEdit &&
					<CBREquipment update={true}
						onClose={() => { this.setState({ showCBRPenetrationEdit: false }) }}
						onComplete={(value: any) => this.saveAction(value, 'CBREquipment', this.setState({ showCBREquipmentEdit: false }))}
						borehole={this.state.borehole.hole_name} loadUnits='kN'
						defaultValues={this.state.CBREquipment[this.state.editingIndex]}
						options={{ loadUnits: 'kN', inputOption1: 'kN', inputOption2: 'T' }} // get loadUnits from User pref
						title="CBR Equipment"
					/>
				}

				{this.state.showCBRConditions &&
					<CBRConditions onClose={() => { this.setState({ showCBRConditions: false }) }}
						onComplete={(value: any) => this.saveAction(value, 'CBRConditions', this.setState({ showCBRConditions: false }))}
						borehole={this.state.borehole.hole_name}
						title="CBR Conditions"
					/>
				}

				{this.state.showCBRConditionsEdit &&
					<CBRConditions update={true}
						onClose={() => { this.setState({ showCBRConditionsEdit: false }) }}
						onComplete={(value: any) => this.saveAction(value, 'CBRConditions', this.setState({ showCBRConditionsEdit: false }))}
						borehole={this.state.borehole.hole_name} defaultValues={this.state.SoilDescription[this.state.editingIndex]}
						title="CBR Conditions"
					/>
				}


				{/* MENU STUFF */}

				{this.state.showNewHole &&
					<NewHole onClose={() => { this.setState({ showNewHole: false }) }}
						holeType="CP"
						defaultValues={{
							company_guid: this.state.project.company_guid,
							project_guid: this.state.project_guid,
						}}
					// onChange={(boreholes: any[]) => {this.setState({boreholes: boreholes})}}
					/>
				}

				{this.state.showEmail &&
					<Email onClose={() => { this.setState({ showEmail: false }) }}
					// emailSubject='test-project files: BH004, BH005, AGS.....'
					// emailMessage="Please find attached files for:\nLocation: Rob's Test Project\nProject: test-project\nFiles: BH004, BH005, AGS"
					/>
				}

				{this.state.showAGS &&
					<AGS onClose={() => { this.setState({ showAGS: false }) }}
					// defaultValues={{endDate:endDate, startDate:startDate}}
						project_start_date='09/25/2023'
					/>
				}

				{this.state.showSaveLog &&
					<SaveLog onClose={() => { this.setState({ showSaveLog: false }) }}
						boreholeName={this.state.borehole.hole_name}
						holeType="CB"
					/>
				}

				{this.state.showPrintLabel &&
					<PrintLabel onClose={() => { this.setState({ showPrintLabel: false }) }}
						borehole={boreholeVar}
						sample={labelSample}
						depth={labelDepth}
						size='printLabel'
					/>
				}





				<Container>
					<ProjectOptionsBorehole
						onMarkAsComplete={() => { this.setState({ completingBorehole: true }) }}
						boreholeName={this.state.borehole?.hole_name}
						// boreholeName="hole_name"
						company_guid={this.state.project.company_guid}
						project_guid={this.state.project_guid}
						boreholeType="CB"
						boreholeDepth={borehole_depth}
						boreholeRequiredText=""
						// boreholeRequiredDepth={this.state.borehole.depth}
						boreholeRequiredDepth=""
						// boreholeRequiredTests={this.state.borehole.samplesTests}
						boreholeRequiredTests=""
						editWeatherLink={'/projects/weather/' + this.state.project_guid}
						className="mt-1 mb-1 py-1"
					/>


					<div className="w-full py-2 bg-grey-vdark text-center mt-1 mb-0 text-sm text-white rounded-md">
						<div onClick={this.bhMenuClick} >
							CBR Menu <img src={menubars} className="h-3 w-3 mb-0.5 inline" alt="" />
						</div>
					</div>

					<div className="relative"> {/* menu container */}

						<div className="grid lg:grid-cols-5 lg:gap-6 mt-2">
							<div className="col-span-5 lg:col-span-3 mt-2">

								<Panel title="Samples" numberOfElements={numberOfSamples} options={[
									{
										name: 'D',
										onClick: () => { this.setState({ showD: true }) }
									}, {
										name: 'B',
										onClick: () => { this.setState({ showB: true }) }
									}, {
										name: 'ES',
										onClick: () => { this.setState({ showES: true }) }
									}, {
										name: 'WS',
										onClick: () => { this.setState({ showWS: true }) }
									}

								]}>
									<table className="table-auto w-full text-xs sm:text-sm text-center border-collapse overflow-hidden rounded-t-md">
										<thead className="font-bold">
											<tr>
												<td className={`px-1 sm:px-1 py-1 bg-grey-mid border-grey-mid rounded-tl-sm rounded-tr-sm border-l border-r border-grey-light`}>Type</td>
												<td className={`px-1 sm:px-1 py-1 bg-grey-mid border-grey-mid border-r border-grey-light`}>From (m)</td>
												<td className={`px-1 sm:px-1 py-1 bg-grey-mid border-grey-mid border-r border-grey-light`}>To (m)</td>
												<td className={`px-1 sm:px-1 py-1 bg-grey-mid border-grey-mid border-r border-grey-light`}>Casing</td>
												<td className={`px-1 sm:px-1 py-1 bg-grey-mid border-grey-mid border-r border-grey-light`}>Water (m)</td>
												<td className={`px-1 sm:px-1 py-1 bg-grey-mid border-grey-mid border-r border-grey-light`}>Notes</td>
												<td className={`px-1 sm:px-1 py-1 bg-grey-mid border-grey-mid rounded-tr-sm border-r border-grey-light`}></td>
											</tr>
										</thead>
										<tbody>
											{this.state.Samples?.filter((action: any) => {
												return (datesAreOnSameDay(new Date(action.time_of_action), new Date(this.props.selectedDate)))
											}).map((value: any, index: number) => {

												// var showUtype = showU
												// if (type == 'U') {showUtype = showU}

												// let maxDepthSample = value.depth_to_base
												// console.log('Max Depth Sample: ', value.depth_to_base)
												// const [maxDepthSample, setMaxDepthSample] = useState(value.depth_to_base)
												// console.log('maxDepthSample: ', maxDepthSample)
												return (
													<React.Fragment key={index}>

														{/* {console.log('index: ', index+1)} */}

														<tr className="border border-grey-mid bg-white mb-1">
															<td className={`px-1 sm:px-1 py-1 border-r border-grey-mid font-bold`}>{value.type}-#</td>
															<td className={`px-1 sm:px-1 py-1 border-r border-grey-mid`}>{value.depth_to_top}</td>
															<td className={`px-1 sm:px-1 py-1 border-r border-grey-mid`}>{value.depth_to_base}</td>
															<td className={`px-1 sm:px-1 py-1 border-r border-grey-mid`}>{value.casing_depth}</td>
															<td className={`px-1 sm:px-1 py-1 border-r border-grey-mid`}>{value.water_depth}</td>
															<td className={`px-1 sm:px-1 py-1 border-r border-grey-mid`}>{value.general_remarks}</td>
															<td className={`px-1 sm:px-1 py-1 border-r border-grey-mid w-8 md:w-12`}>
																<div className={"text-center px-1 sm:px-0 py-0"}>
																	<img onClick={() => {
																		const actualIndex = this.state.Samples.map((object: any) => object.guid).indexOf(value.guid)
																		this.setState({
																			editingHole: value.guid,
																			editingIndex: actualIndex
																		})
																		switch (value.type) {
																			case 'D':
																				this.setState({ showDEdit: true, })
																				break
																			case 'B':
																				this.setState({ showBEdit: true, })
																				break
																			case 'WS':
																				this.setState({ showWSEdit: true, })
																				break
																			case 'ES':
																				this.setState({ showESEdit: true, })
																				break
																		}
																	}} className="inline" width={"20"} src={edit} alt="" />
																</div>
															</td>
														</tr>
													</React.Fragment>
												)
											})}
										</tbody>
									</table>
								</Panel>

								<Panel title="Soil Description" numberOfElements={numberOfSoilDescriptions} onAdd={() => { this.setState({ showSoilDescription: true }) }}>
									<table className="table-auto w-full text-xs sm:text-sm text-center border-collapse overflow-hidden rounded-t-md">
										<thead className="font-bold">
											<tr>
												<td className={`px-1 py-1 bg-grey-mid border-grey-mid rounded-tl-sm rounded-tr-sm border-l  border-r border-grey-light`}>From</td>
												<td className={`px-1 py-1 bg-grey-mid border-grey-mid border-r border-grey-light`}>To</td>
												<td className={`px-1 py-1 bg-grey-mid border-grey-mid border-r border-grey-light`}>Soil Description</td>
												<td className={`px-1 py-1 bg-grey-mid border-grey-mid border-r border-grey-light`}></td>
											</tr>
										</thead>
										<tbody>
											{this.state.SoilDescription?.filter((action: any) => {
												return (datesAreOnSameDay(new Date(action.time_of_action), new Date(this.props.selectedDate)))
											}).map((soilDesc: any, index: number) => {
												return (
													<React.Fragment key={index}>
														<tr className="hidden invisible">
															<td>{soilDesc.stratum_type}</td>
														</tr>
														<tr className="border border-grey-mid bg-white mb-1">
															<td className={`px-1 py-1 border-r border-grey-mid align-top`}>{soilDesc.depth_to_top}</td>
															<td className={`px-1 py-1 border-r border-grey-mid align-top`}>{soilDesc.depth_to_base}</td>
															<td className={`px-1 py-1 border-r border-grey-mid text-left`}>{soilDesc.stratum_description}</td>
															<td className={`px-0 py-0 border-r border-grey-mid align-top w-8 md:w-12`}>
																<div className={"text-center px-0 py-0"}>
																	<img onClick={() => {
																		const actualIndex = this.state.SoilDescription.map((object: any) => object.guid).indexOf(soilDesc.guid)
																		this.setState({
																			editingHole: soilDesc.guid,
																			editingIndex: actualIndex,
																			showSoilDescriptionEdit: true,
																		})
																	}} className="inline" width={"20"} height={"20"} src={edit} alt="" />
																</div>
															</td>
														</tr>
													</React.Fragment>
												)
											})}

										</tbody>
									</table>
								</Panel>

								<Panel title="Test Equipment" numberOfElements={numberOfTestEquipments} onAdd={() => { this.setState({ showCBREquipment: true }) }}>

									<div className="grid grid-cols-4 p-2 bg-sptTB rounded mb-2" onClick={() => {
										this.setState({
											// editingHole: soilDesc.guid,
											// editingIndex: index,
											showCBREquipment: true,
										})
									}}>

										<div className="col-span-3 pr-2 pt-1 text-xs md:text-sm text-right">Deformation gauge reference:</div>
										<div className="text-sm md:text-base">
											<input type="text" className="border border-grey-mid rounded w-full text-center font-bold text-sm md:text-base" value="3kN" />
										</div>

										<div className="col-span-3 pr-2 pt-1 text-xs md:text-sm text-right">Force measuring device reference:</div>
										<div className="text-sm md:text-base">
											<input type="text" className="border border-grey-mid rounded w-full text-center font-bold text-sm md:text-base" />
										</div>

										<div className="col-span-3 pr-2 pt-1 text-xs md:text-sm text-right">Mean calibration (N/div):</div>
										<div className="text-sm md:text-base">
											<input type="text" className="border border-grey-mid rounded w-full text-center font-bold text-sm md:text-base" value="0.002636" />
										</div>

									</div>

								</Panel>

								<Panel title="Test Conditions" numberOfElements={numberOfTestConditions} onAdd={() => { this.setState({ showCBRConditions: true }) }}>

									<div className="grid grid-cols-4 p-2 bg-sptTB rounded mb-2" onClick={() => {
										this.setState({
											// editingHole: soilDesc.guid,
											// editingIndex: index,
											showCBRConditions: true,
										})
									}}>

										<div className="col-span-4 pr-1 pt-0 md:pt-1 text-xs md:text-sm font-bold text-center">Note: Test applicable only when maximum particle size beneath plunger does not exceed 20mm</div>

										<div className="col-span-3 pr-2 pt-1 text-xs md:text-sm text-right">Test Depth (m):</div>
										<div className="text-sm md:text-base">
											<input type="text" className="border border-grey-mid rounded w-full text-center font-bold text-sm md:text-base" />
										</div>

										<div className="col-span-3 pr-2 pt-1 text-xs md:text-sm text-right">Area of annulus of disc used (mm&#178;):</div>
										<div className="text-sm md:text-base">
											<input type="text" className="border border-grey-mid rounded w-full text-center font-bold text-sm md:text-base" value="" />
										</div>

										<div className="col-span-3 pr-2 pt-1 text-xs md:text-sm text-right">Mass of surcharge applied (kg):</div>
										<div className="text-sm md:text-base">
											<input type="text" className="border border-grey-mid rounded w-full text-center font-bold text-sm md:text-base" />
										</div>

										<div className="col-span-3 pr-2 pt-1 text-xs md:text-sm text-right">Equivilent overburden pressure (kPa):</div>
										<div className="text-sm md:text-base">
											<input type="text" className="border border-grey-mid rounded w-full text-center font-bold text-sm md:text-base" />
										</div>

										<div className="col-span-3 pr-2 pt-1 text-xs md:text-sm text-right">Seating Load (Div):</div>
										<div className="text-sm md:text-base">
											<input type="text" className="border border-grey-mid rounded w-full text-center font-bold text-sm md:text-base" value="10" />
										</div>

										<div className="col-span-3 pr-2 pt-1 text-xs md:text-sm text-right">Moisture Content (%):</div>
										<div className="text-sm md:text-base">
											<input type="text" className="border border-grey-mid rounded w-full text-center font-bold text-sm md:text-base" />
										</div>

									</div>

								</Panel>

								<Panel title="Comments / Standing Time / Day Works" numberOfElements={numberOfComments} onAdd={() => this.setState({ showComments: true })}>
									<table className="table-auto w-full text-xs sm:text-sm text-center border-collapse overflow-hidden rounded-t-md">
										<thead className="font-bold">
											<tr>
												<td className={`px-1 py-1 bg-grey-mid border-grey-mid rounded-tl-sm rounded-tr-sm border-l  border-r border-grey-light `}>Type</td>
												<td className={`px-1 py-1 bg-grey-mid border-grey-mid border-r border-grey-light`}>Time From</td>
												<td className={`px-1 py-1 bg-grey-mid border-grey-mid border-r border-grey-light`}>Duration</td>
												<td className={`px-1 py-1 bg-grey-mid border-grey-mid border-r border-grey-light`}>Comment</td>
												<td className={`px-1 py-1 bg-grey-mid border-grey-mid border-r border-grey-light`}></td>
											</tr>
										</thead>
										<tbody>
											{this.state.Comments?.filter((action: any) => {
												return (datesAreOnSameDay(new Date(action.time_of_action), new Date(this.props.selectedDate)))
											}).map((comment: any, index: number) => {




												return (
													<React.Fragment key={index}>
														<tr className="border border-grey-mid bg-white mb-1">
															<td className={`px-1 py-1 border-r border-grey-mid`}>{comment.comment_type}</td>
															<td className={`px-1 py-1 border-r border-grey-mid`}>{comment.start_time}</td>
															<td className={`px-1 py-1 border-r border-grey-mid`}>{comment.duration}</td>
															<td className={`px-1 py-1 border-r border-grey-mid text-left`}>{comment.remarks}</td>
															<td className={`px-1 py-1 border-r border-grey-mid w-8 md:w-12`}>
																<div className={"text-center px-0 py-0"}>
																	<img onClick={() => {
																		const actualIndex = this.state.Comments.map((object: any) => object.guid).indexOf(comment.guid)
																		this.setState({
																			editingHole: comment.guid,
																			editingIndex: actualIndex,
																			// editingIndex: index,
																			showCommentsEdit: true,
																		})
																	}} className="inline" width={"20"} src={edit} alt="" />
																</div>
															</td>
														</tr>
													</React.Fragment>
												)
											})}
											<tr>
												<td className="h-2"></td>
											</tr>
											<tr>
												<td colSpan={5} className="w-full border border-grey-mid">
													<div className="grid grid-cols-12 w-full">
														<div className="col-span-3 pr-2 py-1 border-r border-grey-mid text-right bg-sptTB">
															Total Standing:
														</div>
														<div className="col-span-3 py-1 border-r border-grey-mid text-center font-bold">
															hrs mins
														</div>
														<div className="col-span-3 pr-2 py-1 border-r border-grey-mid text-right bg-sptTB">
															Total Dayworks:
														</div>
														<div className="col-span-3 py-1 text-center font-bold">
															hrs mins
														</div>
													</div>
												</td>
											</tr>
										</tbody>
									</table>

								</Panel>

							</div>  {/* END OF LEFT SIDE */}

							<div className="col-span-5 lg:col-span-2 mt-0 md:mt-2"> {/* START OF RIGHT SIDE */}

								<Panel title="Penetration Data" numberOfElements={numberOfPenetrationData} onAdd={() => { this.setState({ showCBRPenetration: true }) }}>

									<div className="" onClick={() => {
										this.setState({
											// editingHole: soilDesc.guid,
											// editingIndex: index,
											showCBRPenetration: true,
										})
									}}>

										<div className="flex grid grid-cols-2 gap-0 font-bold text-center">

											<div className="grid grid-cols-2 gap-0 mb-4">

												<div className={`px-2 py-1 text-sm bg-sptTB `}>Penetration on Plunger</div>
												<div className={`px-2 py-1 text-sm bg-sptTB `}>Guage Reading</div>

												<div className={`border-b border-l border-r border-grey-mid bg-white h-6`}>0.00</div>
												<div className={`border-b border-l border-r border-grey-mid bg-white h-6`}>0</div>

												<div className={`border-b border-l border-r border-grey-mid bg-grey-light h-6`}>0.25</div>
												<div className={`border-b border-l border-r border-grey-mid bg-grey-light h-6`}></div>

												<div className={`border-b border-l border-r border-grey-mid bg-white h-6`}>0.50</div>
												<div className={`border-b border-l border-r border-grey-mid bg-white h-6`}></div>

												<div className={`border-b border-l border-r border-grey-mid bg-grey-light h-6`}>0.75</div>
												<div className={`border-b border-l border-r border-grey-mid bg-grey-light h-6`}></div>

												<div className={`border-b border-l border-r border-grey-mid bg-white h-6`}>1.00</div>
												<div className={`border-b border-l border-r border-grey-mid bg-white h-6`}></div>

												<div className={`border-b border-l border-r border-grey-mid bg-grey-light h-6`}>1.25</div>
												<div className={`border-b border-l border-r border-grey-mid bg-grey-light h-6`}></div>

												<div className={`border-b border-l border-r border-grey-mid bg-white h-6`}>1.50</div>
												<div className={`border-b border-l border-r border-grey-mid bg-white h-6`}></div>

												<div className={`border-b border-l border-r border-grey-mid bg-grey-light h-6`}>1.75</div>
												<div className={`border-b border-l border-r border-grey-mid bg-grey-light h-6`}></div>

												<div className={`border-b border-l border-r border-grey-mid bg-white h-6`}>2.00</div>
												<div className={`border-b border-l border-r border-grey-mid bg-white h-6`}></div>

												<div className={`border-b border-l border-r border-grey-mid bg-grey-light h-6`}>2.25</div>
												<div className={`border-b border-l border-r border-grey-mid bg-grey-light h-6`}></div>

												<div className={`border-b border-l border-r border-grey-mid bg-white h-6`}>2.50</div>
												<div className={`border-b border-l border-r border-grey-mid bg-white h-6`}></div>

												<div className={`border-b border-l border-r border-grey-mid bg-grey-light h-6`}>2.75</div>
												<div className={`border-b border-l border-r border-grey-mid bg-grey-light h-6`}></div>

												<div className={`border-b border-l border-r border-grey-mid bg-white h-6`}>3.00</div>
												<div className={`border-b border-l border-r border-grey-mid bg-white h-6`}></div>

												<div className={`border-b border-l border-r border-grey-mid bg-grey-light h-6`}>3.25</div>
												<div className={`border-b border-l border-r border-grey-mid bg-grey-light h-6`}></div>

												<div className={`border-b border-l border-r border-grey-mid bg-white h-6`}>3.50</div>
												<div className={`border-b border-l border-r border-grey-mid bg-white h-6`}></div>

												<div className={`border-b border-l border-r border-grey-mid bg-grey-light h-6`}>3.75</div>
												<div className={`border-b border-l border-r border-grey-mid bg-grey-light h-6`}></div>

											</div>

											<div className="grid grid-cols-2 gap-0 mb-4 border-l-4 border-sptTB">

												<div className={`px-2 py-1 text-sm bg-sptTB `}>Penetration on Plunger</div>
												<div className={`px-2 py-1 text-sm bg-sptTB `}>Guage Reading</div>

												<div className={`border-b border-l  border-r border-grey-mid bg-white h-6`}>4.00</div>
												<div className={`border-b border-l border-r border-grey-mid bg-white h-6`}></div>

												<div className={`border-b border-l border-r border-grey-mid bg-grey-light h-6`}>4.25</div>
												<div className={`border-b border-l border-r border-grey-mid bg-grey-light h-6`}></div>

												<div className={`border-b border-l border-r border-grey-mid bg-white h-6`}>4.50</div>
												<div className={`border-b border-r border-grey-mid bg-white h-6`}></div>

												<div className={`border-b border-l border-r border-grey-mid bg-grey-light h-6`}>4.75</div>
												<div className={`border-b border-l border-r border-grey-mid bg-grey-light h-6`}></div>

												<div className={`border-b border-l border-r border-grey-mid bg-white h-6`}>5.00</div>
												<div className={`border-b border-l border-r border-grey-mid bg-white h-6`}></div>

												<div className={`border-b border-l border-r border-grey-mid bg-grey-light h-6`}>5.25</div>
												<div className={`border-b border-l border-r border-grey-mid bg-grey-light h-6`}></div>

												<div className={`border-b border-l border-r border-grey-mid bg-white h-6`}>5.50</div>
												<div className={`border-b border-l border-r border-grey-mid bg-white h-6`}></div>

												<div className={`border-b border-l border-r border-grey-mid bg-grey-light h-6`}>5.75</div>
												<div className={`border-b border-l border-r border-grey-mid bg-grey-light h-6`}></div>

												<div className={`border-b border-l border-r border-grey-mid bg-white h-6`}>6.00</div>
												<div className={`border-b border-l border-r border-grey-mid bg-white h-6`}></div>

												<div className={`border-b border-l border-r border-grey-mid bg-grey-light h-6`}>6.25</div>
												<div className={`border-b border-l border-r border-grey-mid bg-grey-light h-6`}></div>

												<div className={`border-b border-l border-r border-grey-mid bg-white h-6`}>6.50</div>
												<div className={`border-b border-l border-r border-grey-mid bg-white h-6`}></div>

												<div className={`border-b border-l border-r border-grey-mid bg-grey-light h-6`}>6.75</div>
												<div className={`border-b border-l border-r border-grey-mid bg-grey-light h-6`}></div>

												<div className={`border-b border-l border-r border-grey-mid bg-white h-6`}>7.00</div>
												<div className={`border-b border-l border-r border-grey-mid bg-white h-6`}></div>

												<div className={`border-b border-l border-r border-grey-mid bg-grey-light h-6`}>7.25</div>
												<div className={`border-b border-l border-r border-grey-mid bg-grey-light h-6`}></div>

												<div className={`border-b border-l border-r border-grey-mid bg-white h-6`}>7.50</div>
												<div className={`border-b border-l border-r border-grey-mid bg-white h-6`}></div>

												<div className={`border-b border-l border-r border-grey-mid bg-grey-light h-6`}></div>
												<div className={`border-b border-l border-r border-grey-mid bg-grey-light h-6`}></div>
											</div>

										</div>

									</div>

								</Panel>


							</div>
						</div>


						<div style={{ display: this.state.bhMenuVisible ? 'block' : 'none' }}> {/* MENU */}

							<div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 sm:gap-3 md:gap-4 bg-grey-vdark w-full absolute z-99 top-0 left-0 -mt-3 text-center text-white text-xs md:text-sm pt-2 px-2 md:px-3 lg:px-4 pb-2 md:pb-3 lg:pb-4 rounded-b-lg modalshadow">

								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<Link to={`/projects/update/${this.props.router.match.params.id}`} onClick={this.bhMenuClick}>
										<img src={icon_project} className="h-12 w-12 mx-auto mb-1" alt="" />
										Edit Project
									</Link>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<div onClick={this.menuAndAGS}>
										<img src={icon_ags} className="h-12 w-12 mx-auto mb-1" alt="" />
										Output AGS
									</div>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<div onClick={this.menuAndEmail}>
										<img src={icon_email} className="h-12 w-12 mx-auto mb-1" alt="" />
										Send Email
									</div>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<button className="items-center justify-center px-2 bold mx-auto" onClick={this.menuAndLabels} >
										<img src={icon_labels} className="w-12 mx-auto" alt="" />
										View Labels
									</button>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<button className="items-center justify-center px-2 bold mx-auto" onClick={this.menuAndPreview} >
										<img src={icon_preview} className="h-12 w-12 mx-auto mb-1" alt="" />
										Preview Log
									</button>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<div onClick={this.menuAndSaveLog}>
										<img src={icon_savelog} className="h-12 w-12 mx-auto mb-1" alt="" />
										Save Log
									</div>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									{/* <Link to={`/#`} onClick={this.bhMenuClick}> */}
									<Link to={`#`} onClick={this.menuAndMarkAsComplete}>
										<img src={icon_complete} className="h-12 w-12 mx-auto mb-1" alt="" />
										Mark As Complete
									</Link>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									{/* <div onClick={this.menuAndNewHole}>
											<img src={icon_newborehole} className="h-12 w-12 mx-auto mb-1" alt="" />
											New CBR
										</div> */}
									<Link to={`/newhole/CB/${this.props.router.match.params.id}`} onClick={this.bhMenuClick}>
										<img src={icon_newborehole} className="h-12 w-12 mx-auto mb-1" alt="" />
										New CBR
									</Link>
								</div>

								<div className="col-span-3 md:col-span-4 lg:col-span-5">
									<div className="grid grid-cols-2 gap-2 sm:gap-3 md:gap-4 ">
										<div className="border border-grey-dark rounded py-2 text-lg">
											<Link to={`/projects/dashboard/${this.state.project_guid}/cbr`} onClick={this.bhMenuClick}>
												CBR Select
											</Link>
										</div>
										<div className="border border-grey-dark rounded py-2 text-lg">
											<Link to={`/projects/dashboard/${this.state.project_guid}/company/${this.state.project.company_guid}`} onClick={this.bhMenuClick}>
												Change Activity
											</Link>
										</div>
									</div>
								</div>

							</div>

						</div>

					</div> {/* end of menu container */}

					{/* LOGSHEET */}


					<div className="flex flex-col rounded-lg border border-orange bg-modal-bg px-2 sm:px-2 md:px-2 pb-8 modalshadow previewLogsheet" style={{ display: this.state.previewVisible ? 'block' : 'none' }}>

						<div className="flex items-center border-b border-grey-dark py-2">

							<div className="flex-1 flex items-left">

							</div>

							<div className="flex-1 flex justify-center text-lg sm:text-xl font-bold text-text leading-6 text-center mx-auto">
								Preview Logsheet
							</div>

							<div className="flex-1 flex justify-center">
								<span className="ml-auto">
									<button className="flex items-center justify-center w-12 h-12 text-4xl bold text-soil-grey bg-white border border-orange rounded" onClick={this.previewClick} ><img src={closeIcon} width="32" alt="" /></button>
								</span>
							</div>
						</div>






						<div className="w-full overflow-scroll">

							<div className="logsheetContainer mx-auto pb-6">

								<div className="mainContainer">

									<table className="w-full">
										<tbody>
											{/* HEADER TABLE */}
											<tr>
												<td colSpan={2}>
													<div className="headerTableHolder border border-grey-mid">
														<table className="noTableBorder text-center w-full">
															<tbody>
																<tr>
																	<td rowSpan={3} className="headerLeft">{/* LOGO */}<img className="mt-1" src={companyLogo} /></td>
																	<td colSpan={4} className="headerTitle">In-Situ California Bearing Ratio: BS1377</td>
																	<td rowSpan={2} colSpan={2} className="headerRight headerBarcodeHolder pt-3 pl-5 text-center">
																		{/* <svg id="barcode" className="barcodeCSS"></svg> */}
																		<Barcode value={labelbarcodeVar}
																			options={{
																				format: 'code128',
																				//width: 2,
																				width: barcodeWidth,
																				height: 60,
																				fontSize: 12,
																				//marginLeft: 80,
																				//text: barcodeVar
																				//background: "#ccffff"
																			}} />
																	</td>
																</tr>
																<tr>
																	<td className="headerCellTitleLeft">Location:</td>
																	<td className={`${logsheetLocationStyle}`}>{projectLocation}</td>
																	<td className="headerCellTitleRight">Client:</td>
																	<td className="headerCellInputRight">{client}</td>
																</tr>
																<tr>
																	<td className="headerCellTitleLeft">Project ID:</td>
																	<td id="projectID" className="headerCellInputLeft">{projectID}</td>
																	<td className="headerCellTitleRight">CBR ID:</td>
																	<td id="boreholeID" className="headerCellInputRight">{boreholeName}</td>
																	<td id="headerDate">{sheetDate}</td>
																	<td id="headerSheets">Sheet 1 of 1</td>
																</tr>
															</tbody>
														</table>
													</div>
												</td>
											</tr>
											{/* END OF HEADER TABLE */}

											<tr>
												<td className="align-top">
													<table className="w-full">
														<tr>
															<td colSpan={4} className="tableRow bg-grey-mid sectionTitle text-xs text-left px-4">Note: Test applicable only when maximum particle size beneath plunger does not exceed 20mm</td>
														</tr>
														<tr>
															<td colSpan={2} className="tableRow w-7/12 text-right pr-1 bg-sptTB border-b border-r border-grey-mid cbrHeadings">Reaction Load Type: </td>
															<td colSpan={2} className="tableRow w-5/12 border-b border-grey-mid cbrHeadings">Truck mounted / JCB mounted</td>
														</tr>
														<tr>
															<td colSpan={2} className="tableRow w-7/12 text-right pr-1 bg-sptTB border-b border-r border-grey-mid cbrHeadings">Reaction Load adequate: </td>
															<td colSpan={2} className="tableRow w-5/12 border-b border-grey-mid cbrHeadings">Yes</td>
														</tr>
														<tr>
															<td colSpan={2} className="tableRow w-7/12 text-right pr-1 bg-sptTB border-b border-r border-grey-mid cbrHeadings">Materials larger than 20mm present in test area: </td>
															<td colSpan={2} className="tableRow w-5/12 border-b border-grey-mid cbrHeadings">No</td>
														</tr>
														<tr>
															<td colSpan={2} className="tableRow w-7/12 text-right pr-1 bg-sptTB border-b border-r border-grey-mid cbrHeadings">Weather Conditions: </td>
															<td colSpan={2} className="tableRow w-5/12 border-b border-grey-mid cbrHeadings">Sunny and dry</td>
														</tr>
													</table>
													{/* SOIL DESCRIPTIONS */}
													<div className="soilDescriptionHolder w-full align-top border-b border-l border-grey-mid">
														{/* ////////////////////////////////////////////////////// */}
														<table className="soilTable noTableBorder">
															<tbody>
																<tr>
																	<td className="tableRow soilDepth bg-sptTB">From:</td>
																	<td className="tableRow soilDepth bg-sptTB">To:</td>
																	<td className="tableRow soilDescription bg-grey-mid sectionTitle text-center">Geology Description</td>
																	<td className="tableRow soilLegend bg-sptTB">Legend</td>
																</tr>
																{/* ////////////////////////////////////////////////////// */}
																{/* ROW */}
																<tr>
																	<td colSpan={4} className="soilDescriptionsRowsHolder" >

																		<table className="h-full w-full">

																			{this.state.SoilDescription?.filter((action: any) => {
																				return (datesAreOnSameDay(new Date(action.time_of_action), new Date(this.props.selectedDate)))
																			}).map((soilDesc: any, index: number) => {

																				let stratum_type_css = ''
																				if (soilDesc.stratum_type === 'TOPSOIL') { stratum_type_css = 'soilTopsoil' }
																				if (soilDesc.stratum_type === 'SAND') { stratum_type_css = 'soilSand' }
																				if (soilDesc.stratum_type === 'GRAVEL') { stratum_type_css = 'soilGravel' }
																				if (soilDesc.stratum_type === 'SANDGRAVEL') { stratum_type_css = 'soilGravel' }
																				if (soilDesc.stratum_type === 'CLAY') { stratum_type_css = 'soilClay' }
																				if (soilDesc.stratum_type === 'SILT') { stratum_type_css = 'soilSilt' }
																				if (soilDesc.stratum_type === 'PEAT') { stratum_type_css = 'soilPeat' }
																				if (soilDesc.stratum_type === 'CHALK') { stratum_type_css = 'soilChalk' }
																				if (soilDesc.stratum_type === 'MADEGROUND') { stratum_type_css = 'soilMadeground' }
																				if (soilDesc.stratum_type === 'LIMESTONE') { stratum_type_css = 'soilLimestone' }
																				if (soilDesc.stratum_type === 'DOLOMITE') { stratum_type_css = 'soilRock' }
																				if (soilDesc.stratum_type === 'SANDSTONE') { stratum_type_css = 'soilSandstone' }
																				if (soilDesc.stratum_type === 'SILTSTONE') { stratum_type_css = 'soilSiltstone' }
																				if (soilDesc.stratum_type === 'GYPSUM') { stratum_type_css = 'soilGypsum' }
																				if (soilDesc.stratum_type === 'MUDSTONE') { stratum_type_css = 'soilMudstone' }
																				if (soilDesc.stratum_type === 'CHERT') { stratum_type_css = 'soilRock' }
																				if (soilDesc.stratum_type === 'SHALE') { stratum_type_css = 'soilShale' }
																				if (soilDesc.stratum_type === 'GRANITE') { stratum_type_css = 'soilRock' }
																				if (soilDesc.stratum_type === 'DIORITE') { stratum_type_css = 'soilRock' }
																				if (soilDesc.stratum_type === 'RHYOLITE') { stratum_type_css = 'soilRock' }
																				if (soilDesc.stratum_type === 'ANDERSITE') { stratum_type_css = 'soilRock' }
																				if (soilDesc.stratum_type === 'BASALT') { stratum_type_css = 'soilRock' }
																				if (soilDesc.stratum_type === 'OBSIDIAN') { stratum_type_css = 'soilRock' }
																				if (soilDesc.stratum_type === 'MARBLE') { stratum_type_css = 'soilRock' }
																				if (soilDesc.stratum_type === 'QUARTZITE') { stratum_type_css = 'soilRock' }
																				if (soilDesc.stratum_type === 'SLATE') { stratum_type_css = 'soilRock' }
																				if (soilDesc.stratum_type === 'COAL') { stratum_type_css = 'soilCoal' }

																				return (
																					<React.Fragment key={index}>
																						<tr className="hidden invisible">
																							<td>{soilDesc.stratum_type}</td>
																						</tr>
																						<tr>
																							<td className="soilDepth">{soilDesc.depth_to_top}</td>
																							<td className="soilDepth">{soilDesc.depth_to_base}</td>
																							<td className="soilDescription">{soilDesc.stratum_description}</td>
																							<td className={`soilLegend ${stratum_type_css}`}></td>
																						</tr>
																					</React.Fragment>
																				)
																			})}

																		</table>

																	</td>
																</tr>

																{/* ////////////////////////////////////////////////////// */}
															</tbody>
														</table>
													</div>
													{/* END OF SOIL DESCRIPTIONS */}

													{/* SAMPLES */}

													<div className="sampTableHolder border-l border-b border-grey-mid">
														<table className="w-full text-center noTableBorder">
															<tbody>
																<tr>
																	<td colSpan={4} className="tableRow soilDepth bg-grey-mid sectionTitle">Samples</td>
																</tr>
																{/* ROW */}
																<tr>
																	<td className="tableRow bg-sptTB sectionTitle">Type/#</td>
																	<td className="tableRow bg-sptTB">Depth From (m)</td>
																	<td className="tableRow bg-sptTB">Depth To (m)</td>
																	<td className="tableRow bg-sptTB textCenter">Notes</td>
																</tr>
																{/* ////////////////////////////////////////////////////// */}
																{this.state.Samples?.filter((action: any) => {
																	return (datesAreOnSameDay(new Date(action.time_of_action), new Date(this.props.selectedDate)))
																}).map((value: any, index: number) => {

																	let logsheetCasing = ''
																	if (value.casing_depth === 'no casing') {
																		logsheetCasing = '-'
																	} else {
																		logsheetCasing = value.casing_depth
																	}

																	return (
																		<React.Fragment key={index}>

																			<tr className="border-b border-grey-mid">
																				<td className="tableRow sampType">{value.type}-#</td>
																				<td className="tableRow sampDepth">{value.depth_to_top}</td>
																				<td className="tableRow sampDepth">{value.depth_to_base}</td>
																				<td className="tableRow text-left pl-1" colSpan={7}>{value.remarks}</td>
																			</tr>

																		</React.Fragment>

																	)
																})}
															</tbody>
														</table>
													</div>

													{/* TEST EQUIPMENT */}
													<div className="grid grid-cols-4 border-l border-grey-mid">

														<div className="col-span-4 bg-grey-mid sectionTitle">Test Equipment</div>

														<div className="col-span-3 cbrHeadings pr-2 text-right border-r border-b border-grey-mid">Deformation gauge reference:</div>
														<div className="cbrReadings border-b border-grey-mid">3kN</div>

														<div className="col-span-3 cbrHeadings pr-2 text-right border-r border-b border-grey-mid">Force measuring device reference:</div>
														<div className="cbrReadings border-b border-grey-mid"></div>

														<div className="col-span-3 cbrHeadings pr-2 text-right border-r border-b border-grey-mid">Mean calibration (N/div):</div>
														<div className="cbrReadings border-b border-grey-mid">0.002636</div>

													</div>

													{/* TEST CONDITIONS */}

													<div className="grid grid-cols-4 border-l border-grey-mid">

														{/* <div className="col-span-4 pr-1 font-bold">Note: Test applicable only when maximum particle size beneath plunger does not exceed 20mm</div> */}

														<div className="col-span-4 bg-grey-mid sectionTitle">Test Conditions</div>

														<div className="col-span-3 cbrHeadings pr-2 text-right border-r border-b border-grey-mid">Test Depth (m):</div>
														<div className="cbrReadings border-b border-grey-mid"></div>

														<div className="col-span-3 cbrHeadings pr-2 text-right border-r border-b border-grey-mid">Area of annulus of disc used (mm&#178;):</div>
														<div className="cbrReadings border-b border-grey-mid"></div>

														<div className="col-span-3 cbrHeadings pr-2 text-right border-r border-b border-grey-mid">Mass of surcharge applied (kg):</div>
														<div className="cbrReadings border-b border-grey-mid"></div>

														<div className="col-span-3 cbrHeadings pr-2 text-right border-r border-b border-grey-mid">Equivilent overburden pressure (kPa):</div>
														<div className="cbrReadings border-b border-grey-mid"></div>

														<div className="col-span-3 cbrHeadings pr-2 text-right border-r border-b border-grey-mid">Seating Load (Div):</div>
														<div className="cbrReadings border-b border-grey-mid">10</div>

														<div className="col-span-3 cbrHeadings pr-2 text-right border-r border-b border-grey-mid">Moisture Content (%):</div>
														<div className="cbrReadings border-b border-grey-mid"></div>

													</div>


												</td>


												<td>

													<div className="grid grid-cols-3 gap-0 font-bold text-center w-full">

														<div className="col-span-3 bg-grey-mid sectionTitle">Penetration Data</div>

														<div className={`tableRow sectionTitle border-b border-l border-r border-grey-mid h-5`}>Penetration on Plunger</div>
														<div className={`tableRow sectionTitle border-b border-r border-grey-mid h-5`}>Guage Reading</div>
														<div className={`tableRow sectionTitle border-b border-r border-grey-mid h-5`}>Force on Plunger kN</div>

														<div className={`tableRow border-b border-l border-r border-grey-mid bg-white cbrReadings`}>0.00</div>
														<div className={`tableRow border-b border-r border-grey-mid bg-white cbrReadings`}>0</div>
														<div className={`tableRow border-b border-r border-grey-mid bg-white cbrReadings`}>0</div>

														<div className={`border-b border-l border-r border-grey-mid bg-grey-light cbrReadings`}>0.25</div>
														<div className={`border-b border-r border-grey-mid bg-grey-light cbrReadings`}></div>
														<div className={`border-b border-r border-grey-mid bg-grey-light cbrReadings`}></div>

														<div className={`border-b border-l border-r border-grey-mid bg-white cbrReadings`}>0.50</div>
														<div className={`border-b border-r border-grey-mid bg-white cbrReadings`}></div>
														<div className={`border-b border-r border-grey-mid bg-white cbrReadings`}></div>

														<div className={`border-b border-l border-r border-grey-mid bg-grey-light cbrReadings`}>0.75</div>
														<div className={`border-b border-r border-grey-mid bg-grey-light cbrReadings`}></div>
														<div className={`border-b border-r border-grey-mid bg-grey-light cbrReadings`}></div>

														<div className={`border-b border-l border-r border-grey-mid bg-white cbrReadings`}>1.00</div>
														<div className={`border-b border-r border-grey-mid bg-white cbrReadings`}></div>
														<div className={`border-b border-r border-grey-mid bg-white cbrReadings`}></div>

														<div className={`border-b border-l border-r border-grey-mid bg-grey-light cbrReadings`}>1.25</div>
														<div className={`border-b border-r border-grey-mid bg-grey-light cbrReadings`}></div>
														<div className={`border-b border-r border-grey-mid bg-grey-light cbrReadings`}></div>

														<div className={`border-b border-l border-r border-grey-mid bg-white cbrReadings`}>1.50</div>
														<div className={`border-b border-r border-grey-mid bg-white cbrReadings`}></div>
														<div className={`border-b border-r border-grey-mid bg-white cbrReadings`}></div>

														<div className={`border-b border-l border-r border-grey-mid bg-grey-light cbrReadings`}>1.75</div>
														<div className={`border-b border-r border-grey-mid bg-grey-light cbrReadings`}></div>
														<div className={`border-b border-r border-grey-mid bg-grey-light cbrReadings`}></div>

														<div className={`border-b border-l border-r border-grey-mid bg-white cbrReadings`}>2.00</div>
														<div className={`border-b border-r border-grey-mid bg-white cbrReadings`}></div>
														<div className={`border-b border-r border-grey-mid bg-white cbrReadings`}></div>

														<div className={`border-b border-l border-r border-grey-mid bg-grey-light cbrReadings`}>2.25</div>
														<div className={`border-b border-r border-grey-mid bg-grey-light cbrReadings`}></div>
														<div className={`border-b border-r border-grey-mid bg-grey-light cbrReadings`}></div>

														<div className={`border-b border-l border-r border-grey-mid bg-white cbrReadings`}>2.50</div>
														<div className={`border-b border-r border-grey-mid bg-white cbrReadings`}></div>
														<div className={`border-b border-r border-grey-mid bg-white cbrReadings`}></div>

														<div className={`border-b border-l border-r border-grey-mid bg-grey-light cbrReadings`}>2.75</div>
														<div className={`border-b border-r border-grey-mid bg-grey-light cbrReadings`}></div>
														<div className={`border-b border-r border-grey-mid bg-grey-light cbrReadings`}></div>

														<div className={`border-b border-l border-r border-grey-mid bg-white cbrReadings`}>3.00</div>
														<div className={`border-b border-r border-grey-mid bg-white cbrReadings`}></div>
														<div className={`border-b border-r border-grey-mid bg-white cbrReadings`}></div>

														<div className={`border-b border-l border-r border-grey-mid bg-grey-light cbrReadings`}>3.25</div>
														<div className={`border-b border-r border-grey-mid bg-grey-light cbrReadings`}></div>
														<div className={`border-b border-r border-grey-mid bg-grey-light cbrReadings`}></div>

														<div className={`border-b border-l border-r border-grey-mid bg-white cbrReadings`}>3.50</div>
														<div className={`border-b border-r border-grey-mid bg-white cbrReadings`}></div>
														<div className={`border-b border-r border-grey-mid bg-white cbrReadings`}></div>

														<div className={`border-b border-l border-r border-grey-mid bg-grey-light cbrReadings`}>3.75</div>
														<div className={`border-b border-r border-grey-mid bg-grey-light cbrReadings`}></div>
														<div className={`border-b border-r border-grey-mid bg-grey-light cbrReadings`}></div>

														<div className={`border-b border-l border-r border-grey-mid bg-white cbrReadings`}>4.00</div>
														<div className={`border-b border-r border-grey-mid bg-white cbrReadings`}></div>
														<div className={`border-b border-r border-grey-mid bg-white cbrReadings`}></div>

														<div className={`border-b border-l border-r border-grey-mid bg-grey-light cbrReadings`}>4.25</div>
														<div className={`border-b border-r border-grey-mid bg-grey-light cbrReadings`}></div>
														<div className={`border-b border-r border-grey-mid bg-grey-light cbrReadings`}></div>

														<div className={`border-b border-l border-r border-grey-mid bg-white cbrReadings`}>4.50</div>
														<div className={`border-b border-r border-grey-mid bg-white cbrReadings`}></div>
														<div className={`border-b border-r border-grey-mid bg-white cbrReadings`}></div>

														<div className={`border-b border-l border-r border-grey-mid bg-grey-light cbrReadings`}>4.75</div>
														<div className={`border-b border-r border-grey-mid bg-grey-light cbrReadings`}></div>
														<div className={`border-b border-r border-grey-mid bg-grey-light cbrReadings`}></div>

														<div className={`border-b border-l border-r border-grey-mid bg-white cbrReadings`}>5.00</div>
														<div className={`border-b border-r border-grey-mid bg-white cbrReadings`}></div>
														<div className={`border-b border-r border-grey-mid bg-white cbrReadings`}></div>

														<div className={`border-b border-l border-r border-grey-mid bg-grey-light cbrReadings`}>5.25</div>
														<div className={`border-b border-r border-grey-mid bg-grey-light cbrReadings`}></div>
														<div className={`border-b border-r border-grey-mid bg-grey-light cbrReadings`}></div>

														<div className={`border-b border-l border-r border-grey-mid bg-white cbrReadings`}>5.50</div>
														<div className={`border-b border-r border-grey-mid bg-white cbrReadings`}></div>
														<div className={`border-b border-r border-grey-mid bg-white cbrReadings`}></div>

														<div className={`border-b border-l border-r border-grey-mid bg-grey-light cbrReadings`}>5.75</div>
														<div className={`border-b border-r border-grey-mid bg-grey-light cbrReadings`}></div>
														<div className={`border-b border-r border-grey-mid bg-grey-light cbrReadings`}></div>

														<div className={`border-b border-l border-r border-grey-mid bg-white cbrReadings`}>6.00</div>
														<div className={`border-b border-r border-grey-mid bg-white cbrReadings`}></div>
														<div className={`border-b border-r border-grey-mid bg-white cbrReadings`}></div>

														<div className={`border-b border-l border-r border-grey-mid bg-grey-light cbrReadings`}>6.25</div>
														<div className={`border-b border-r border-grey-mid bg-grey-light cbrReadings`}></div>
														<div className={`border-b border-r border-grey-mid bg-grey-light cbrReadings`}></div>

														<div className={`border-b border-l border-r border-grey-mid bg-white cbrReadings`}>6.50</div>
														<div className={`border-b border-r border-grey-mid bg-white cbrReadings`}></div>
														<div className={`border-b border-r border-grey-mid bg-white cbrReadings`}></div>

														<div className={`border-b border-l border-r border-grey-mid bg-grey-light cbrReadings`}>6.75</div>
														<div className={`border-b border-r border-grey-mid bg-grey-light cbrReadings`}></div>
														<div className={`border-b border-r border-grey-mid bg-grey-light cbrReadings`}></div>

														<div className={`border-b border-l border-r border-grey-mid bg-white cbrReadings`}>7.00</div>
														<div className={`border-b border-r border-grey-mid bg-white cbrReadings`}></div>
														<div className={`border-b border-r border-grey-mid bg-white cbrReadings`}></div>

														<div className={`border-b border-l border-r border-grey-mid bg-grey-light cbrReadings`}>7.25</div>
														<div className={`border-b border-r border-grey-mid bg-grey-light cbrReadings`}></div>
														<div className={`border-b border-r border-grey-mid bg-grey-light cbrReadings`}></div>

														<div className={`border-b border-l border-r border-grey-mid bg-white cbrReadings`}>7.50</div>
														<div className={`border-b border-r border-grey-mid bg-white cbrReadings`}></div>
														<div className={`border-b border-r border-grey-mid bg-white cbrReadings`}></div>

													</div>

												</td>
											</tr>


											<tr>
												<td className="w-2/4 align-top border-r border-grey-mid"> {/* LEFT SIDE */}

													{/* COMMENTS */}
													<div className="commentsHolder_HT w-full border-b border-l border-grey-mid">
														<table className="w-full align-top noTableBorder">
															<tbody>
																<tr>
																	<td className="tableRow bg-grey-mid sectionTitle">Comments / Standing Time / Dayworks</td>
																</tr>
																<tr>
																	<td className="commentsInput">
																		{/* ////////////////////////////////////////////////////// */}
																		{this.state.Comments?.filter((action: any) => {
																			return (datesAreOnSameDay(new Date(action.time_of_action), new Date(this.props.selectedDate)))
																		}).map((comment: any, index: number) => {
																			return (
																				<React.Fragment key={index}>
																					{/* <tr>
										<td className="commentsInput">{comment.comment_type} ({comment.duration}) - {comment.remarks}</td>
									</tr> */}
																					{comment.comment_type} ({comment.duration}) - {comment.remarks}.&nbsp;
																				</React.Fragment>
																			)
																		})}
																		{/* ////////////////////////////////////////////////////// */}
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
													{/* END OF COMMENTS */}
													{/* STANDING TIME / DAYWORKS TOTALS */}
													<div className="standingTimeTotalsHolder w-full border-b border-l border-grey-mid">
														<table className="standingTimeTable w-full text-center noTableBorder">
															<tbody>
																<tr>
																	<td className="tableRow bg-grey-mid sectionTitle standingTimeCategories">Total Standing Time:</td>
																	{/* ////////////////////////////////////////////////////// */}
																	<td className="tableRow sectionTitle standingTimeInputs">hrs mins</td>
																	{/* ////////////////////////////////////////////////////// */}
																	<td className="tableRow bg-grey-mid sectionTitle standingTimeCategories">Total Dayworks:</td>
																	{/* ////////////////////////////////////////////////////// */}
																	<td className="tableRow sectionTitle standingTimeInputs">hrs mins</td>
																	{/* ////////////////////////////////////////////////////// */}
																</tr>
															</tbody>
														</table>
													</div>
													{/* END OF STANDING TIME / DAYWORKS TOTALS */}
												</td> {/* END OF LEFT SIDE */}


												<td className="w-2/4 align-top"> {/* RIGHT SIDE */}

													{/* PERSONNEL */}
													<div className="w-full border-b border-r border-grey-mid">
														<table className="w-full noTableBorder">
															<tbody>
																<tr>
																	<td colSpan={2} className="tableRow bg-grey-mid sectionTitle">Personnel Details</td>
																</tr>
																{/* ////////////////////////////////////////////////////// */}
																<tr>
																	<td className="tableRow bg-sptTB w-1/2">Name:</td>
																	<td className="tableRow bg-sptTB w-4/5">CSCS:</td>
																</tr>
																<tr>
																	<td className="tableRow w-1/2">{operative}</td>
																	<td className="tableRow w-4/5">{operativeCSCS}</td>
																</tr>
																{/* ////////////////////////////////////////////////////// */}
															</tbody>
														</table>
													</div>
													{/* END OF PERSONNEL */}
													{/* CLIENT INFO FOOTER */}
													<div className="w-full border-b border-r border-grey-mid">
														<table className="clientInfoTable noTableBorder">
															<tbody>
																<tr>
																	{/* ////////////////////////////////////////////////////// */}
																	<td className="tableRow clientInfoContact">
																		{companyName}<br />
																		{companyAddress1} {companyAddress2}, {companyTown}, {companyCounty}, {companyPostcode}<br />
																		Tel: {companyTelephone}<br />
																		Email: {companyEmail}
																	</td>
																	{/* ////////////////////////////////////////////////////// */}
																	<td className="tableRow clientInfoDrillitLogs"></td>
																</tr>
															</tbody>
														</table>
													</div>
													{/* END OF CLIENT INFO FOOTER */}
												</td>
											</tr>
										</tbody>
										{/* END OF tableHolderTable */}
									</table>

									{/* END OF mainContainer */}
								</div>

							</div>

						</div>

					</div>








					{/* LABELS */}

					<div className="flex flex-col rounded-lg border border-orange bg-modal-bg px-1 sm:px-2 md:px-2 modalshadow previewLogsheet min-h-full z-10" style={{ display: this.state.labelsVisible ? 'block' : 'none' }}>

						<div className="flex items-center border-b border-grey-dark py-2">

							<div className="flex-1 flex items-left">

							</div>

							<div className="flex-1 flex justify-center text-lg sm:text-2xl text-text leading-6 text-center mx-auto">
								Labels
							</div>

							<div className="flex-1 flex justify-center">
								<span className="ml-auto">
									<button className="flex items-center justify-center w-12 h-12 text-4xl bold text-soil-grey bg-white border border-orange rounded" onClick={this.labelsClick} ><img src={closeIcon} width="32" alt="" /></button>
								</span>
							</div>
						</div>



						<div className="text-lg w-full text-center pt-2">
							Samples
						</div>

						<ul className="flex flex-wrap p-0 mb-2 list-none justify-center text-center"> {/* START LABELS HOLDER */}


							{/* ////////////////////////////////////////////////////// */}
							{this.state.Samples?.filter((action: any) => {
								return (datesAreOnSameDay(new Date(action.time_of_action), new Date(this.props.selectedDate)))
							}).map((value: any, index: number) => {

								labelSample = value.type
								labelDepth = value.depth_to_top + '-' + value.depth_to_base

								return (
									<React.Fragment key={index}>

										<li className="p-1 z-10">
											<div onClick={() => { this.setState({ showPrintLabel: true }) }}>
												<Label
													borehole={boreholeName}
													sample={labelSample}
													depth={labelDepth}
													size='smallLabel'
												/>
											</div>
										</li>

									</React.Fragment>
								)
							})}
							{/* ////////////////////////////////////////////////////// */}

						</ul> {/* END LABELS HOLDER */}


						{/* <div className="w-30">
							<Button theme="primary" size="lg" className="btn-lg m-auto" >Print All Sample Labels</Button>
						</div> */}

					</div>

				</Container>

				<br /><br />

				<SyncStatus synced />
			</>
		)
	}
}

const mapStateToProps = (state: any) => ({
	selectedWeather: state.weather,
	selectedDate: state.date,
})

export default connect(mapStateToProps, null)(ProjectsCablePercussiveBorehole)
