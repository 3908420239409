const GetRequest = (endpoint: string) => {
    const token = localStorage.getItem('drillit-token')

    return fetch(endpoint, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        //return the status code here
        .then(response => response)
        .catch(err => err)
}

export default GetRequest
