import React from 'react';
import Header from '../layout/Header';
import Modal from '../components/Modal';

interface Props {
    toggleSidebar: () => void,
    router: any,
}

interface State {
    currentVersion: string;
    latestVersion: string;
    loading: boolean;
}

class Update extends React.Component<Props, State> {
    state: State = {
        currentVersion: '1.0.12',
        latestVersion: '',
        loading: true
    };

    async componentDidMount() {
        // Check if service worker is supported
        if ('serviceWorker' in navigator) {
            // Set up event listener for messages from the service worker
            navigator.serviceWorker.addEventListener('message', (event) => {
                if (event.data && event.data.type === 'RELOAD_PAGE') {
                    console.log('New service worker activated. Reloading the page...');
                    window.location.reload();
                }
            });

            try {
                // Fetch the latest version from the server
                const response = await fetch('https://app.drillitlogs.com/version.txt');
                if (!response.ok) {
                    throw new Error('Failed to fetch version');
                }
                const text = await response.text();
                console.log('Version:', text);
                this.setState({
                    latestVersion: text,
                    loading: false
                });
            } catch (error) {
                console.error('Error fetching version:', error);
                this.setState({ loading: false });
            }
        }
    }

    // handleHardRefreshClick = async () => {
    //     if ('serviceWorker' in navigator) {
    //         try {
    //             const registration = await navigator.serviceWorker.getRegistration();
    //             if (registration && registration.waiting) {
    //                 console.log('Service worker is in waiting state. Sending SKIP_WAITING message...');
    //                 registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    //             } else {
    //                 console.log('No service worker in waiting state.');
    //             }
    //         } catch (error) {
    //             console.error('Error fetching service worker registration:', error);
    //         }
    //     } else {
    //         console.log('Service worker not supported in this browser.');
    //     }
    // };

    handleHardRefreshClick = () => {
        // Append a unique query parameter to force refresh
        const newUrl = `${window.location.href}?_=${new Date().getTime()}`;
        window.location.href = newUrl;
    };

    render() {
        const { currentVersion, latestVersion, loading } = this.state;
        const isUpdateAvailable = currentVersion !== latestVersion;

        return (
            <>
                <Header toggleSidebar={this.props.toggleSidebar} />
                <Modal title="Update Drillit Logs" toggleModal={() => { this.props.router.history.goBack(-1) }} confirm={false}>

                <>
                    <div className="text-center text-base font-bold mt-4 w-full md:w-1/3 mx-auto rounded bg-blue text-white py-1">
                        You are running version: {currentVersion}
                    </div>
                    <div className="text-center text-base font-bold mt-4 w-full md:w-1/3 mx-auto rounded bg-orange text-white py-1">
                        Latest Version: {loading ? 'Loading...' : latestVersion}
                    </div>
                    {!loading && isUpdateAvailable && (
                        <div className="text-center">
                            <div onClick={this.handleHardRefreshClick}
                                className="text-center text-lg font-bold mt-4 w-full md:w-1/3 mx-auto rounded bg-green-checkmark text-white py-4">
                                Click to update to latest version
                            </div>
                        </div>
                    )}
                    {!loading && !isUpdateAvailable && (
                        <div onClick={this.handleHardRefreshClick} 
                            className="text-center text-lg font-bold mt-4 w-full md:w-1/3 mx-auto rounded bg-green-checkmark text-white py-4">
                            You are up to date!
                        </div>
                    )}



                    <div className="text-base w-full md:w-2/3 mx-auto mt-8">
                        17/09/2024 - Printer setup - fixed <br />
                        12/09/2024 - Update button - fixed <br />
                        11/09/2024 - Borehole status - fixed <br />
                        10/09/2024 - Labels now printing directly to Toshiba printers - fixed <br />
                        07/09/2024 - Log comments now displayed in a more controlled method - fixed <br />
                        26/08/2024 - Trialpit Preview, Detail Descriptions & Prelim Info - fixed <br />
                        26/08/2024 - Sequencial Numbering & Overall Numbering - fixed <br />
                        23/08/2024 - Edit Rig - fixed <br />
                        20/08/2024 - Profile Update - fixed (again) <br />
                        16/08/2024 - Delete hole - fixed <br />
                        15/08/2024 - Archive Project - pending (create 'archive' area in main page - todo) <br />
                        12/08/2024 - Delete hole - fixed <br />
                        11/08/2024 - Plant Checks offline - fixed??? <br />
                        08/08/2024 - Sync Status updates - fixed <br />
                        01/08/2024 - Logs now default to the date of last activity. If 'new', goes to 'today' - fixed <br />
                        01/08/2024 - New Log 'Date Navigation' - fixed <br />
                        28/07/2024 - Sync 'displayed' items - fixed??? <br />
                        28/07/2024 - Offline 'multi-action' updates - fixed <br />
                        28/07/2024 - IDB Values for rig data & plant check data - fixed <br />
                        24/07/2024 - Activate & Close Hole - fixed <br />
                        21/07/2024 - New Hole offline - fixed <br />
                        10/07/2024 - Sync Status (manual pushing of 'offline' Logs, Plant Checks, Contacts & Hole Information) - fixed <br />
                        10/07/2024 - Sync Status (manual pushing of 'offline' actions) - fixed <br />
                        10/07/2024 - Actions offline - adding, updating & deleting - fixed <br />
                        05/07/2024 - Sync Status (addressing -delete in IDB) - pending <br />
                        01/07/2024 - New Hole verification page (click to reload hole data) <br />
                        01/07/2024 - Dynamic Probe now saving & updating 'inside' module! <br />
                        30/06/2024 - HeadTest & Soakaway PDF Logs Charts working!!! - fixed <br />
                        24/06/2024 - Log Manager - downloads & view working!!! - fixed <br />
                        24/06/2024 - Logs updated (just Head Test & Soakaway charts to do) - fixed <br />
                        24/06/2024 - Delete Actions - operational!!! <br />
                        18/06/2024 - Re-formatting of labels and batch printing fixed <br />
                        14/06/2024 - Follow On Holes Depths (Cable & Rotary) - fixed <br />
                        11/06/2024 - Update of Action Counts - fixed <br />
                        11/06/2024 - First look at Follow On Holes (Cable & Rotary) - fixed <br />
                        19/05/2024 - SPT details saving properly to holeInformation <br />
                        19/05/2024 - Rig selector saving spt details to currentRig <br />
                        18/05/2024 - Capitalized & sanitized ProjectID <br />
                        18/05/2024 - Create Hole - Fixed <br />
                        06/05/2024 - Send Email Attachments - Fixed <br />
                        28/04/2024 - New hole - holename CAPITALIZED - fixed <br />
                        28/04/2024 - New hole - SAVE button now visible after CREATE - fixed <br />
                        28/04/2024 - Data input 'presets' - 1 second delay added onClick <br />
                        28/04/2024 - Plant Checks - 1st plant check for rig - fixed <br />
                        17/04/2024 - Send Email - verify email format fixed <br />
                        17/04/2024 - Project Log Manager (download logs) - fixed <br />
                        17/04/2024 - Project Log Manager (show logs) - fixed <br />
                        14/04/2024 - Send Email Attachments - pending - fixed <br />
                        14/04/2024 - Send Email - fixed <br />
                        12/04/2024 - Saving Logs to PDF on server - ALL fixed<br />
                        10/04/2024 - End shift re-worked 'inside' hole_function <br />
                        09/04/2024 - Update Plant Check PDF's - Fixed <br />
                        09/04/2024 - hole_function hole data loading - Fixed <br />
                        09/04/2024 - getRig in hole_function - Fixed <br />
                        09/04/2024 - Error logging of getFromAPI - Fixed <br />
                        08/04/2024 - Update Page - Get latest version - Fixed <br />
                        08/04/2024 - Update Profile - Fixed <br />
                    </div>

                </>

            </Modal>
        </>
    )
}
}

export default Update