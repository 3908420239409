import React, { FunctionComponent, useState } from 'react'

type Props = {
	className?: string,
	options: any[],
	defaultValue: string,
	title?: string,
	columns?: number,
	columnsMobile?: number,
	gap?: number,
	buttonHeight?: number,
	buttonHeightMobile?: number,
	onUpdate: (value: string) => void;
	[x: string]: any;
}

const defaultProps: Props = {
	className: '',
	options: [],
	defaultValue: '',
	title: '',
	columns: 3,
	columnsMobile: 2,
	gap: 2,
	buttonHeight: 20,
	buttonHeightMobile: 10,
	onUpdate: (value: any) => {},
}

const ButtonOptions: FunctionComponent<Props> = ({ className, options, defaultValue, title, columns, columnsMobile, gap, buttonHeight, buttonHeightMobile, next, onUpdate, ...rest }) => {
	const [value, setValue] = useState(defaultValue)

	const update = (option: any) => {
		setValue(option)

		onUpdate(option)
	}

	return (
			<div className={`w-full ${className}`}>
				{title ? <div className="text-base sm:text-xl mb-4 text-center">{title}</div> : <></>}

				{/* <div className={`grid grid-cols-${columnsMobile} sm:grid-cols-${columns} md:grid-cols-${columns} gap-${gap}`}> */}
				<div className="flex items-center justify-center">
				{/* <div className={`grid grid-cols-3 sm:grid-cols-5 gap-3`}> */}
					{options.map((option, index) => {
						let optionValue = ''
						let optionLabel = ''

						if(typeof option === 'object' && 'label' in option && option !== null) {
							optionValue = option.value
							optionLabel = option.label
						} else {
							optionValue = option
							optionLabel = option
						}

						return (
							<div className={` ${optionValue === value ? 'border-4 border-orange pt-1 md:pt-1.5' : 'border border-grey-mid pt-1.5 md:pt-2'} flex-item bg-white p-1 md:p-2 mx-1 md:mx-2 rounded text-center align-middle h-${buttonHeightMobile} md:h-${buttonHeight}`} 
							key={index} onClick={() => {
								update(optionValue)
								next?.()
							}}>
								{optionLabel}
							</div>
						)
					})}
				</div>
			</div>
	)
}

ButtonOptions.defaultProps = defaultProps

export default ButtonOptions
