import React, { FunctionComponent, useState, useEffect, useRef, Component } from 'react'

import Modal from '../components/Modal'
import { Link } from 'react-router-dom'

import warningIcon from '../assets/icons/warning.png'
import checkIcon from '../assets/icons/check.svg'
import Button from '../components/form/Button'
import { get, set } from "idb-keyval";
import PrintLabelHTML from './PrintLabelHTML';
import Barcode from 'react-jsbarcode';
import TopImage from '../assets/labelTop.jpg'
import ButtonOptions from '../components/form/ButtonOptions'
import ReactToPrint from 'react-to-print'
import NumberIncrement from '../components/form/NumberIncrement'

type Props = {
    onClose: any,
}

const defaultProps: Props = {
    onClose: () => { },
}


const PrinterSetup: FunctionComponent<Props> = ({ onClose, ...rest }) => {

    const location = 'Norwich Research Park'
    const project = 'DL12345'
    const borehole = 'BH01'
    const sample = 'D-1'
    const sampleTop = 'UT100-1'
    const depth = '1.20'
    const depthTop = '2.00 - 2.45'
    const todayDate = new Date().toLocaleDateString('en-GB');
    const labelbarcode = 'DL12345-BH01-D-1'
    const labelbarcodeTop = 'DL12345-BH01-UT100-1'
    const barcodeWidths = [
        3.3, 3.3, 3.3, 3.3, 3.3, 3.3, 3.0, 2.7, 2.5, 2.3, // 1 to 10
        2.1, 2.0, 1.85, 1.75, 1.65, 1.55, 1.5, 1.42, 1.36, 1.3, // 11 to 20
        1.25, 1.2, 1.15, 1.1, 1.05, 1.0, 1.0, 0.95, 0.93, 0.90, // 21 to 30
        0.88, 0.85, 0.83, 0.80, 0.80, 0.76, 0.75, 0.73, 0.72, 0.71 // 31 to 40
    ];
    var barcodeWidth = barcodeWidths[labelbarcode.length - 1]

    const [user, setUser] = useState('Levi Puller');

    useEffect(() => {
        get('drillit-user').then(data => {
            console.log('drillit-user data:', data)
            const logged_in_user = data.user?.first_name + ' ' + data.user?.last_name
            setUser(logged_in_user)
        })
    }, []);

    // State to hold debug information
    const [debugInfo, setDebugInfo] = useState<string[]>([]);

    const labelWidth = 630;  // 63mm
    const labelHeight = 500; // 50mm

    const printingArea = useRef(null);
    const printingArea_top = useRef(null);

    const [printerSelect, setPrinterSelect] = useState('');
    const [numberD, setNumberD] = useState(1);
    const [numberB, setNumberB] = useState(1);
    const [numberES, setNumberES] = useState(1);
    const [numberWS, setNumberWS] = useState(1);
    const [numberU, setNumberU] = useState(1);
    const [numberPS, setNumberPS] = useState(1);
    const [numberL, setNumberL] = useState(1);
    const [numberCS, setNumberCS] = useState(1);

    const [loadingSettings, setLoadingSettings] = useState(true); // New loading flag

    const printD = numberD.toString().padStart(4, '0');
    const printU = numberU.toString().padStart(4, '0');

    useEffect(() => {
        get('drillit-print-settings').then(data => {
            console.log('drillit-print-settings:', data);

            if (data) {
                // Make sure to properly set the values from data
                setPrinterSelect(data.print_type);
                setNumberD(data.numberD);
                setNumberB(data.numberB);
                setNumberES(data.numberES);
                setNumberWS(data.numberWS);
                setNumberU(data.numberU);
                setNumberPS(data.numberPS);
                setNumberL(data.numberL);
                setNumberCS(data.numberCS);
            }
            setLoadingSettings(false); // Set loading flag to false after data is loaded
        });
    }, []);

    console.log('printerSelect:', printerSelect);
    console.log('numberD:', numberD);

    const saveToIDB = () => {
        const defaultSettings = {
            print_type: printerSelect,
            numberD: numberD,
            numberB: numberB,
            numberES: numberES,
            numberWS: numberWS,
            numberU: numberU,
            numberPS: numberPS,
            numberL: numberL,
            numberCS: numberCS,
        };

        // Save default settings to IndexedDB
        set('drillit-print-settings', defaultSettings).then(() => {
            console.log('Default settings saved to IDB:', defaultSettings);
        });
    };

    // Only save settings to IDB after initial load
    useEffect(() => {
        if (!loadingSettings) {
            saveToIDB();
        }
    }, [numberD, numberB, numberES, numberWS, numberU, numberPS, numberL, numberCS, printerSelect]);

    const changePrintType = (val: string) => {
        if (loadingSettings) return;
        setPrinterSelect(val)
    };

    const changeDefaultD = (val: number) => {
        if (loadingSettings) return;
        setNumberD(val)
    };

    const changeDefaultB = (val: number) => {
        if (loadingSettings) return;
        setNumberB(val)
    };

    const changeDefaultES = (val: number) => {
        if (loadingSettings) return;
        setNumberES(val)
    };

    const changeDefaultWS = (val: number) => {
        if (loadingSettings) return;
        setNumberWS(val)
    };

    const changeDefaultU = (val: number) => {
        if (loadingSettings) return;
        setNumberU(val)
    };

    const changeDefaultPS = (val: number) => {
        if (loadingSettings) return;
        setNumberPS(val)
    };

    const changeDefaultL = (val: number) => {
        if (loadingSettings) return;
        setNumberL(val)
    };

    const changeDefaultCS = (val: number) => {
        if (loadingSettings) return;
        setNumberCS(val)
    };


    const encodeURIComponentSafe = (str: string) => {
        return encodeURIComponent(str).replace(/'/g, "%27").replace(/"/g, "%22");
    };

    const handleGeneralLabel = () => {
        const tpclCommand = `
        {C|}
        {D0660,0480,0630|}
        {AX;+000,+000,+00|}
        {AY;+00,1|}
        {C|}
        {PC000;0438,0137,05,05,J,-01,11,B,J0000,+0000000000,P3|}        <!--LOCATIONHEADER-->   <!-- ID; TOP, LEFT, WIDTH, HEIGHT -->
        {PC001;0437,0152,05,05,J,-02,11,B,J0000,+0000000000,P1|}        <!--LOCATIONVAR-->
        {PC002;0392,0137,05,05,J,-01,11,B,J0000,+0000000000,P3|}        <!--PROJHEADER-->
        {PC003;0385,0148,08,08,J,-04,11,B,J0000,+0000000000,P1|}        <!--PROJECTVAR-->
        {PC004;0318,0137,05,05,J,-01,11,B,J0000,+0000000000,P3|}        <!--HOLEIDHEADER-->
        {PC005;0298,0145,15,15,J,-07,11,B,J0000,+0000000000,P1|}        <!--HOLEIDVAR-->
        {PC006;0226,0137,05,05,J,-01,11,B,J0000,+0000000000,P3|}        <!--SAMPLEHEADER-->
        {PC007;0206,0148,15,15,J,-07,11,B,J0000,+0000000000,P1|}        <!--SAMPLEVAR-->
        {PC008;0154,0137,05,05,J,-01,11,B,J0000,+0000000000,P3|}        <!--DEPTHHEADER-->
        {PC009;0147,0147,08,08,J,-02,11,B,J0000,+0000000000,P1|}        <!--DEPTHVAR-->
        {PC010;0108,0137,05,05,J,-01,11,B,J0000,+0000000000,P3|}        <!--USERTEXT-->
        {PC011;0108,0152,05,05,J,-02,11,B,J0000,+0000000000,P1|}        <!--USERVAR-->
        {PC012;0108,0447,05,05,J,-01,11,B,J0000,+0000000000,P1|}        <!--DATEVAR-->
        {XB00;0091,0039,9,3,02,1,0048,+0000000000,048,0,00|}            <!--BARCODE-->
        {PC013;0017,0100,05,05,H,+00,11,B,J0000,+0000000000,P1|}        <!--FOOTERTEXT-->
        {PC014;0340,0014,10,10,H,+00,11,W0599,J0000,+0000000000,P1|}    <!--BACKBLACK-->
        {RC000;LOC:|}                                                   <!--LOCATIONHEADER-->
        {RC001;${location}|}                                            <!--LOCATIONVAR-->
        {RC002;PROJ:|}                                                  <!--PROJHEADER-->
        {RC003;${project}|}                                            <!--PROJECTVAR-->
        {RC004;HOLE:|}                                                  <!--HOLEIDHEADER-->
        {RC005;${borehole}|}                                            <!--HOLEIDVAR-->
        {RC006;SAMP:|}                                                  <!--SAMPLEHEADER-->
        {RC007;${sample}|}                                            <!--SAMPLEVAR-->
        {RC008;DEPTH:|}                                                 <!--DEPTHHEADER-->
        {RC009;${depth}|}                                            <!--DEPTHVAR-->
        {RC010;USER:|}                                                  <!--USERTEXT-->
        {RC011;${user}|}                                            <!--USERVAR-->
        {RC012;${todayDate}|}                                             <!--DATEVAR-->
        {RB00;${labelbarcode}|}                                             <!--BARCODE-->
        {RC013;PRODUCED BY DRILLITLOGS.COM|}                            <!--FOOTERTEXT-->
        {LC;0467,0617,0099,0022,1,2,000|}                               <!--RECTANGLE-->
        {LC;0428,0022,0428,0615,0,2,000|}                               <!--HORIZONTALLINE1-->
        {LC;0373,0022,0373,0616,0,2,000|}                               <!--HORIZONTALLINE2-->
        {LC;0280,0022,0280,0616,0,2,000|}                               <!--HORIZONTALLINE3-->
        {LC;0190,0022,0190,0615,0,2,000|}                               <!--HORIZONTALLINE4-->
        {LC;0136,0022,0136,0615,0,2,000|}                               <!--HORIZONTALLINE5-->
        {LC;0467,0144,0099,0144,0,2,000|}                               <!--VERTICALLINE1-->
        {LC;0137,0439,0100,0439,0,2,000|}                               <!--VERTICALLINE2-->
            `;
        const encodedTpclCommand = encodeURIComponent(tpclCommand);
        const skyPrintUrl = `tecbcp:///tpcl?printnumber=${printD};sensortype=2;data=${encodedTpclCommand};cutinterval=000;printspeed=2;autoclose=1;`; // printnumber=0001;
        window.open(skyPrintUrl, '_blank');
    }

    const handleTOPLabel = () => {
        const tpclCommand = `
        {C|}
        {D0660,0480,0630|}
        {AX;+000,+000,+00|}
        {AY;+00,1|}
        {C|}
        {PC000;0440,0116,05,05,G,+00,11,B,J0000,+0000000000,P2|}        <!--LOCATIONHEADER-->   <!-- ID; TOP, LEFT, WIDTH, HEIGHT -->
        {PC001;0437,0152,05,05,J,-02,11,B,J0000,+0000000000,P1|}        <!--LOCATIONVAR-->
        {PC002;0397,0138,05,05,G,+00,11,B,J0000,+0000000000,P3|}        <!--PROJHEADER-->
        {PC003;0385,0148,08,08,J,-04,11,B,J0000,+0000000000,P1|}        <!--PROJECTVAR-->
        {PC004;0320,0115,05,05,G,+00,11,B,J0000,+0000000000,P2|}        <!--HOLEIDHEADER-->
        {PC005;0298,0145,15,15,J,-07,11,B,J0000,+0000000000,P1|}        <!--HOLEIDVAR-->
        {PC006;0226,0114,05,05,G,+00,11,B,J0000,+0000000000,P2|}        <!--SAMPLEHEADER-->
        {PC007;0206,0148,15,15,J,-07,11,B,J0000,+0000000000,P1|}        <!--SAMPLEVAR-->
        {PC008;0157,0110,05,05,G,+00,11,B,J0000,+0000000000,P2|}        <!--DEPTHHEADER-->
        {PC009;0147,0147,08,08,J,-02,11,B,J0000,+0000000000,P1|}        <!--DEPTHVAR-->
        {PC010;0109,0114,05,05,G,+00,11,B,J0000,+0000000000,P2|}        <!--USERTEXT-->
        {PC011;0108,0152,05,05,J,-02,11,B,J0000,+0000000000,P1|}        <!--USERVAR-->
        {PC012;0108,0447,05,05,J,-01,11,B,J0000,+0000000000,P1|}        <!--DATEVAR-->
        {XB00;0091,0039,9,3,02,1,0048,+0000000000,048,0,00|}            <!--BARCODE-->
        {PC013;0017,0100,05,05,H,+00,11,B,J0000,+0000000000,P1|}        <!--FOOTERTEXT-->
        {PC014;0340,0014,10,10,H,+00,11,W0599,J0000,+0000000000,P1|}    <!--BACKBLACK-->
        {RC000;LOC:|}                                                   <!--LOCATIONHEADER-->
        {RC001;${location}|}                                            <!--LOCATIONVAR-->
        {RC002;PROJ:|}                                                  <!--PROJHEADER-->
        {RC003;${project}|}                                             <!--PROJECTVAR-->
        {RC004;HOLE:|}                                                  <!--HOLEIDHEADER-->
        {RC005;${borehole}|}                                            <!--HOLEIDVAR-->
        {RC006;SAMP:|}                                                  <!--SAMPLEHEADER-->
        {RC007;${sampleTop}|}                                              <!--SAMPLEVAR-->
        {RC008;DEPTH:|}                                                 <!--DEPTHHEADER-->
        {RC009;${depthTop}|}                                               <!--DEPTHVAR-->
        {RC010;USER:|}                                                  <!--USERTEXT-->
        {RC011;${user}|}                                                <!--USERVAR-->
        {RC012;${todayDate}|}                                           <!--DATEVAR-->
        {RB00;${labelbarcode}|}                                         <!--BARCODE-->
        {RC013;PRODUCED BY DRILLITLOGS.COM|}                            <!--FOOTERTEXT-->
        {LC;0467,0617,0099,0013,1,2,000|}                               <!--RECTANGLE-->
        {LC;0428,0012,0428,0615,0,2,000|}                               <!--HORIZONTALLINE1-->
        {LC;0373,0013,0373,0616,0,2,000|}                               <!--HORIZONTALLINE2-->
        {LC;0280,0015,0280,0616,0,2,000|}                               <!--HORIZONTALLINE3-->
        {LC;0190,0013,0190,0615,0,2,000|}                               <!--HORIZONTALLINE4-->
        {LC;0136,0013,0136,0615,0,2,000|}                               <!--HORIZONTALLINE5-->
        {LC;0467,0144,0099,0144,0,2,000|}                               <!--VERTICALLINE1-->
        {LC;0137,0439,0100,0439,0,2,000|}                               <!--VERTICALLINE2-->
        {SG;0100,0013,0240,0004,0,????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????|}
        {SG;0400,0013,0080,0004,0,?????????????8000000?????????????8000000?????????????8000000?????????????8000000|}
        {SG;0100,0018,0240,0004,0,????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????|}
        {SG;0400,0018,0080,0004,0,?????????????8000000?????????????8000000?????????????8000000?????????????8000000|}
        {SG;0100,0023,0240,0004,0,????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????|}
        {SG;0400,0023,0080,0004,0,?????????????8000000?????????????8000000?????????????8000000?????????????8000000|}
        {SG;0100,0028,0240,0004,0,???????????????????????????==<>95??803???;56;??????????????????????????????????????????<00000?<000???0000??????????????????????????????????????????800001?00003?>00001??????????????????????>??????????>???????<00001>00001??00001?????>3???????|}
        {SG;0400,0028,0080,0004,0,?????????????8000000?????????????8000000?????????????8000000??>7?????????8000000|}
        {SG;0100,0033,0240,0004,0,????????<3?????????87??????<40002<01?00?>05900?????>3???????????????81?????????83????????<1??<07?807?03?<0?????<1???????????????81?????????83????????<1??<07?807?03?<0?????<1???????????????81?????????03????????80??01??>07>07?<0?????80???????|}
        {SG;0400,0033,0080,0004,0,??<7?????????8000000??<3?????????8000000??<3?????????8000000??01?????????8000000|}
        {SG;0100,0038,0240,0004,0,????????00????????>01????????<1??03??>07?07?>07????80??????????????<007???????<00????????80??03??>07>03?<0?????007?????????????8007???????<00????????<1??03???07?07?<0????>003?????????????8003???????8003???????80?>07???03>07?80????<000??????|}
        {SG;0400,0038,0080,0004,0,??01?????????8000000?>007????????8000000?<003????????8000000?<001????????8000000|}
        {SG;0100,0043,0240,0004,0,???????0001??????>0001???????<1??03???03?00600????<000????????????>0001??????>0001???????80?>07???03>00001????00007???????????>0001??????>0001???????80?>07???03>00001????00007???????????>00007?????<0000???????<1??07???03?00003???>00003?????|}
        {SG;0400,0043,0080,0004,0,?0001????????8000000?0000????????8000000?0000????????8000000>00007???????8000000|}
        {SG;0100,0048,0240,0004,0,??????<00003?????800007??????80??03???07>0000????>00003???????????800003?????000003??????<0??03??>03?037?????<00001???????????800001?????000003??????81??01??>07>03??????800000??????????>000000????>000001??????<0??<1??>07?07??????800000?????|}
        {SG;0400,0048,0080,0004,0,>00007???????8000000800001???????8000000000000???????80000000000007??????8000000|}
        {SG;0100,0053,0240,0004,0,?????<000000????<000001??????81??<1??807>03??????0000003???>?????<000000????<000001??????81??<1??807>03??????0000003???>?????<0000003???<0000007?????<0??<01?00??07?????>0000001???8?????9888<207???<4260<23?????81??>00001??03?????>4021081???>|}
        {SG;0400,0053,0080,0004,0,0000007??????80000000000007??????80000000000003??????80000001020463??????8000000|}
        {SG;0100,0058,0240,0004,0,?????????????????????????????<0???00003?>07??????????????????????????????????????????????81???<000???03??????????????????????????????????????????????<4???>003???07?????????????????????????????????????????????????????????????????????????????|}
        {SG;0400,0058,0080,0004,0,?????????????8000000?????????????8000000?????????????8000000?????????????8000000|}
        {SG;0100,0063,0240,0004,0,????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????|}
        {SG;0400,0063,0080,0004,0,?????????????8000000?????????????8000000?????????????8000000?????????????8000000|}
        {SG;0100,0068,0240,0004,0,????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????|}
        {SG;0400,0068,0080,0004,0,?????????????8000000?????????????8000000?????????????8000000?????????????8000000|}
        {SG;0100,0073,0240,0004,0,????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????000000000000000000000000000000000000000000000000000000000000|}
        {SG;0400,0073,0080,0004,0,?????????????8000000?????????????8000000?????????????800000000000000000000000000|}
            `;
        const encodedTpclCommand = encodeURIComponent(tpclCommand);
        const skyPrintUrl = `tecbcp:///tpcl?printnumber=${printU};sensortype=2;data=${encodedTpclCommand};cutinterval=000;printspeed=2;autoclose=1;`; // printnumber=0001;
        window.open(skyPrintUrl, '_blank');
    }


    return (

        <>

            <Modal title="Printer Setup" subtitle="" toggleModal={onClose} confirm={false} >

                <div key={loadingSettings ? 'loading' : 'loaded'} className="z-50">

                    <div className="font-bold text-lg text-center mb-4">
                        Select Print Method
                    </div>

                    <div className="w-full md:w-1/2 mx-auto">
                        <ButtonOptions onUpdate={(value: any) => changePrintType(value)}
                            defaultValue={printerSelect}
                            title=""
                            columns={2}
                            columnsMobile={2}
                            buttonHeight={16}
                            buttonHeightMobile={20}
                            options={[
                                {
                                    value: 'Toshiba', label: <><div className="text-lg font-bold">Toshiba Printer</div>
                                        <div className="text-xs px-4">Prints directly to your Toshiba printer</div></>
                                },
                                {
                                    value: 'Print Manager', label: <><div className="text-lg font-bold">Print Manager</div>
                                        <div className="text-xs px-4">Print to printer you configured yourself</div></>
                                },
                            ]} className="mb-4" />
                    </div>

                    {/* <div className="w-30 mx-auto">
                <div className="mb-4">
                    <button onClick={handleGeneralLabel} className="w-40 bg-blue p-2 rounded text-white font-bold">Print General Label</button>
                </div>
                <div className="mb-4">
                    <button onClick={handleTOPLabel} className="w-40 bg-orange p-2 rounded text-white font-bold">Print TOP Label</button>
                </div>
                
                </div> */}

                    <div className="font-bold text-lg text-center">
                        Click the label below to test print
                    </div>



                    <div>

                        {/* START GENERAL LABEL */}

                        {printerSelect === 'Toshiba' && (

                            <div className="labelSetupZoom" onClick={handleTOPLabel} >

                                <div id="label" className=" bg-white w-72 border border-grey-mid rounded-lg mx-auto mt-4 mb-4 p-2 z-30 labelShadow">
                                    <div className="mt-1.5" >

                                        <table className="w-full border border-black p-0 text-left letterSpacing ">
                                            <tbody>
                                                <tr>
                                                    <td rowSpan={6} className="border border-black bg-black text-right pr-1 pt-0.5 font-bold" style={{ width: '18px', height: '100px' }}>
                                                        <img className='' src={TopImage} alt="TOP" />
                                                    </td>
                                                    <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.5rem', lineHeight: '1.1rem' }}>
                                                        Loc:
                                                    </td>
                                                    <td colSpan={3} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '0.8rem', lineHeight: '0.9rem' }}>
                                                        {location}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.6rem', lineHeight: '1.1rem' }}>
                                                        Proj:
                                                    </td>
                                                    <td colSpan={3} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.0rem', lineHeight: '1.3rem' }}>
                                                        {project}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.6rem', lineHeight: '1.1rem' }}>
                                                        ID:
                                                    </td>
                                                    <td colSpan={3} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.5rem', lineHeight: '1.6rem' }}>
                                                        {borehole}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.6rem', lineHeight: '1.1rem' }}>
                                                        Samp:
                                                    </td>
                                                    <td colSpan={3} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.5rem', lineHeight: '1.6rem' }}>
                                                        {sample}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.6rem', lineHeight: '1.1rem' }}>
                                                        Depth:
                                                    </td>
                                                    <td colSpan={3} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.0rem', lineHeight: '1.3rem' }}>
                                                        {depth}<span className="ml-0.5" style={{ fontSize: '1rem', lineHeight: '1.1rem' }}>m</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.6rem', lineHeight: '1.1rem' }}>
                                                        User:
                                                    </td>
                                                    <td className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '0.7rem', lineHeight: '0.8rem' }}>
                                                        {/* {loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name)} */}
                                                        {user}
                                                    </td>
                                                    <td className="border border-black pl-1 pt-0.5 font-bold text-left" style={{ width: '60px', fontSize: '0.7rem', lineHeight: '0.8rem' }}>
                                                        {todayDate}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>


                                        <div className="barcodeHolder overflow-hidden">
                                            <Barcode value={labelbarcode} options={{
                                                format: 'code128',
                                                width: barcodeWidth,
                                                height: 14,
                                                fontSize: 11,
                                                // text: labelbarcode,
                                                background: 'none'
                                            }} />

                                        </div>
                                        <div className="mx-auto text-center -mt-0.5 font-bold" style={{ fontSize: '0.45rem' }}>PRODUCED BY DRILLIT LOGS - DRILLITLOGS.COM</div>
                                    </div>
                                </div>
                            </div>
                        )}


                        {printerSelect === 'Print Manager' && (

                            <>

                                {/* //........................................................
                            //.PPPPPPPPP...PRRRRRRRRR..RRIII.INNN...NNNN..NTTTTTTTTT..
                            //.PPPPPPPPPP..PRRRRRRRRRR.RRIII.INNNN..NNNN..NTTTTTTTTT..
                            //.PPPPPPPPPPP.PRRRRRRRRRR.RRIII.INNNN..NNNN..NTTTTTTTTT..
                            //.PPPP...PPPP.PRRR...RRRRRRRIII.INNNNN.NNNN.....TTTT.....
                            //.PPPP...PPPP.PRRR...RRRRRRRIII.INNNNN.NNNN.....TTTT.....
                            //.PPPPPPPPPPP.PRRRRRRRRRR.RRIII.INNNNNNNNNN.....TTTT.....
                            //.PPPPPPPPPP..PRRRRRRRRRR.RRIII.INNNNNNNNNN.....TTTT.....
                            //.PPPPPPPPP...PRRRRRRR....RRIII.INNNNNNNNNN.....TTTT.....
                            //.PPPP........PRRR.RRRR...RRIII.INNNNNNNNNN.....TTTT.....
                            //.PPPP........PRRR..RRRR..RRIII.INNN.NNNNNN.....TTTT.....
                            //.PPPP........PRRR..RRRRR.RRIII.INNN..NNNNN.....TTTT.....
                            //.PPPP........PRRR...RRRRRRRIII.INNN..NNNNN.....TTTT.....
                            //.PPPP........PRRR....RRRRRRIII.INNN...NNNN.....TTTT.....
                            //........................................................ */}

                                <div className="flex hidden">

                                    <div id="label" className="flex printLabel labelArea border border-grey-mid rounded-lg mx-auto mt-4 mb-4 pt-4 z-30">
                                        <div className="labelBody mt-1.5" ref={printingArea} >

                                            <div className="w-full printArea">

                                                <table className="w-full border border-black p-0 text-left letterSpacing">
                                                    <tbody>
                                                        <tr>
                                                            <td rowSpan={6} className="border border-black bg-black text-right pr-1 pt-0.5 font-bold" style={{ width: '25px' }}>
                                                                <img className='topImg' src={TopImage} alt="TOP" />
                                                            </td>
                                                            <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                                Loc:
                                                            </td>
                                                            <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1rem', lineHeight: '1.3rem' }}>
                                                                {location}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                                Project:
                                                            </td>
                                                            <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.7rem', lineHeight: '2.0rem' }}>
                                                                {project}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                                ID:
                                                            </td>
                                                            <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold letterSpacingExtreme" style={{ fontSize: '2.4rem', lineHeight: '2.6rem' }}>
                                                                {borehole}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                                Type:
                                                            </td>
                                                            <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold letterSpacingExtreme" style={{ fontSize: '2.4rem', lineHeight: '2.6rem' }}>
                                                                {sample}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                                Depth:
                                                            </td>
                                                            <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold letterSpacingExtreme" style={{ fontSize: '1.6rem', lineHeight: '1.9rem' }}>
                                                                {depth}<span className="ml-0.5" style={{ fontSize: '1rem', lineHeight: '1.1rem' }}>m</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                                User:
                                                            </td>
                                                            <td className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '0.9rem', lineHeight: '1.2rem' }}>
                                                                {/* {loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name)} */}
                                                                {user}
                                                            </td>
                                                            <td className="border border-black pl-1 pt-0.5 font-bold" style={{ width: '80px', fontSize: '0.9rem', lineHeight: '1.2rem' }}>
                                                                {todayDate}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <div className="barcodeHolder">
                                                    <Barcode value={labelbarcode} options={{
                                                        format: 'code128',
                                                        width: barcodeWidth,
                                                        height: 20,
                                                        fontSize: 14,
                                                        // text: labelbarcode,
                                                        background: 'none'
                                                    }} />

                                                </div>

                                                <div className="mx-auto text-center mt-1 font-bold" style={{ fontSize: '0.65rem' }}>PRODUCED BY DRILLIT LOGS - DRILLITLOGS.COM</div>

                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <ReactToPrint
                                    trigger={() =>

                                        <div id="label" className="labelSetupZoom bg-white w-72 border border-grey-mid rounded-lg mx-auto mt-4 mb-4 p-2 z-30 labelShadow">
                                            <div className="mt-1.5" >

                                                <table className="w-full border border-black p-0 text-left letterSpacing ">
                                                    <tbody>
                                                        <tr>
                                                            <td rowSpan={6} className="border border-black bg-black text-right pr-1 pt-0.5 font-bold" style={{ width: '18px', height: '100px' }}>
                                                                <img className='' src={TopImage} alt="TOP" />
                                                            </td>
                                                            <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.5rem', lineHeight: '1.1rem' }}>
                                                                Loc:
                                                            </td>
                                                            <td colSpan={3} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '0.8rem', lineHeight: '0.9rem' }}>
                                                                {location}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.6rem', lineHeight: '1.1rem' }}>
                                                                Proj:
                                                            </td>
                                                            <td colSpan={3} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.0rem', lineHeight: '1.3rem' }}>
                                                                {project}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.6rem', lineHeight: '1.1rem' }}>
                                                                ID:
                                                            </td>
                                                            <td colSpan={3} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.5rem', lineHeight: '1.6rem' }}>
                                                                {borehole}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.6rem', lineHeight: '1.1rem' }}>
                                                                Samp:
                                                            </td>
                                                            <td colSpan={3} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.5rem', lineHeight: '1.6rem' }}>
                                                                {sample}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.6rem', lineHeight: '1.1rem' }}>
                                                                Depth:
                                                            </td>
                                                            <td colSpan={3} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.0rem', lineHeight: '1.3rem' }}>
                                                                {depth}<span className="ml-0.5" style={{ fontSize: '1rem', lineHeight: '1.1rem' }}>m</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.6rem', lineHeight: '1.1rem' }}>
                                                                User:
                                                            </td>
                                                            <td className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '0.7rem', lineHeight: '0.8rem' }}>
                                                                {/* {loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name)} */}
                                                                {user}
                                                            </td>
                                                            <td className="border border-black pl-1 pt-0.5 font-bold text-left" style={{ width: '60px', fontSize: '0.7rem', lineHeight: '0.8rem' }}>
                                                                {todayDate}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>


                                                <div className="barcodeHolder overflow-hidden">
                                                    <Barcode value={labelbarcode} options={{
                                                        format: 'code128',
                                                        width: barcodeWidth,
                                                        height: 14,
                                                        fontSize: 11,
                                                        // text: labelbarcode,
                                                        background: 'none'
                                                    }} />

                                                </div>
                                                <div className="mx-auto text-center -mt-0.5 font-bold" style={{ fontSize: '0.45rem' }}>PRODUCED BY DRILLIT LOGS - DRILLITLOGS.COM</div>
                                            </div>
                                        </div>
                                    }
                                    content={() => printingArea.current}
                                />

                            </>

                        )}



                    </div>

                    {printerSelect === 'Toshiba' && (

                        <>

                            <div className="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4 mt-4 rounded border border-grey-mid mx-auto text-base md:text-lg w-full md:w-4/6 text-center pt-3 py-1.5 md:py-3 bg-white">
                                <div className='col-span-2 md:col-span-4 text-center text-base md:text-lg font-bold'>
                                    Set Default Print Numbers
                                </div>
                                <div className=''>
                                    <div className=''>
                                        D Samples:
                                    </div>
                                    <div className=''>
                                        <NumberIncrement defaultNumber={numberD} onChange={changeDefaultD} />
                                    </div>
                                </div>
                                <div className=''>
                                    <div className=''>
                                        B Samples:
                                    </div>
                                    <div className=''>
                                        <NumberIncrement defaultNumber={numberB} onChange={changeDefaultB} />
                                    </div>
                                </div>
                                <div className=''>
                                    <div className=''>
                                        ES Samples:
                                    </div>
                                    <div className=''>
                                        <NumberIncrement defaultNumber={numberES} onChange={changeDefaultES} />
                                    </div>
                                </div>
                                <div className=''>
                                    <div className=''>
                                        WS Samples:
                                    </div>
                                    <div className=''>
                                        <NumberIncrement defaultNumber={numberWS} onChange={changeDefaultWS} />
                                    </div>
                                </div>
                                <div className=''>
                                    <div className=''>
                                        U Samples:
                                    </div>
                                    <div className=''>
                                        <NumberIncrement defaultNumber={numberU} onChange={changeDefaultU} />
                                    </div>
                                </div>
                                <div className=''>
                                    <div className=''>
                                        PS Samples:
                                    </div>
                                    <div className=''>
                                        <NumberIncrement defaultNumber={numberPS} onChange={changeDefaultPS} />
                                    </div>
                                </div>
                                <div className=''>
                                    <div className=''>
                                        Liners:
                                    </div>
                                    <div className=''>
                                        <NumberIncrement defaultNumber={numberL} onChange={changeDefaultL} />
                                    </div>
                                </div>
                                <div className=''>
                                    <div className=''>
                                        Core Samples:
                                    </div>
                                    <div className=''>
                                        <NumberIncrement defaultNumber={numberCS} onChange={changeDefaultCS} />
                                    </div>
                                </div>
                            </div>

                            {/* <table className="table-auto w-full md:w-2/5 text-sm my-2 text-center bg-white border border-grey-mid mx-auto text-base md:text-xl">
                            <tbody>
                                <tr>
                                    <td colSpan={2} className={`w-full p-1 pr-2 border-r border-b border-grey-mid text-center font-bold`}>
                                        Set Default Print Numbers
                                    </td>
                                </tr>
                                <tr>
                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                        D Samples:
                                    </td>
                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-left`}>
                                        <NumberIncrement defaultNumber={numberD} onChange={changeDefaultD} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                        B Samples:
                                    </td>
                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-left`}>
                                        <NumberIncrement defaultNumber={numberB} onChange={changeDefaultB} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                        ES Samples:
                                    </td>
                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-left`}>
                                        <NumberIncrement defaultNumber={numberES} onChange={changeDefaultES} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                        WS Samples:
                                    </td>
                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-left`}>
                                        <NumberIncrement defaultNumber={numberWS} onChange={changeDefaultWS} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                        U Samples:
                                    </td>
                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-left`}>
                                        <NumberIncrement defaultNumber={numberU} onChange={changeDefaultU} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                        PS Samples:
                                    </td>
                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-left`}>
                                        <NumberIncrement defaultNumber={numberPS} onChange={changeDefaultPS} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                        Liners:
                                    </td>
                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-left`}>
                                        <NumberIncrement defaultNumber={numberL} onChange={changeDefaultL} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                        Core Samples:
                                    </td>
                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-left`}>
                                        <NumberIncrement defaultNumber={numberCS} onChange={changeDefaultCS} />
                                    </td>
                                </tr>
                            </tbody>
                        </table> */}

                        </>

                    )}

                </div>

            </Modal >

        </>

    )
}

PrinterSetup.defaultProps = defaultProps

export default PrinterSetup