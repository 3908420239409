import React, { FunctionComponent, useState, useEffect } from 'react'

import WorkflowSteps from '../../WorkflowSteps'
import Modal from '../../Modal'
import Button from '../../form/Button'
// import NumberInput from '../../NumberInput'
import InputGroup from '../../form/InputGroup'
import Comment from './soil-description/Comment'
import ButtonOptions from '../../form/ButtonOptions'

import { v4 as uuidv4 } from 'uuid';
import stepStatus from '../../../helpers/stepStatus'
// import TimeInput from "../../TimeInput"
import DurationInput from "../../DurationInput"
import warningIcon from '../../../assets/icons/warning.png'

// import ImageUploading, { ImageListType, ImageType } from 'react-images-uploading'
import ImageUploading from 'react-images-uploading'

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: string,
	defaultValues?: any,
	selectedDate?: string,
	update?: boolean,
	[x: string]: any,
}

const defaultProps: Props = {
	onClose: () => { },
	onComplete: () => { },
	borehole: '',
	defaultValues: {
		guid: '',
		comment_type: '',
		start_time: '00:00',
		duration: '00:00',
		remarks: '',
		comment: '',
	},
	selectedDate: '',
}

const Comments: FunctionComponent<Props> = ({ onClose, update, onComplete, borehole, defaultValues, selectedDate, setShowCommentsEdit, ...rest }) => {

	console.log('defaultValues:', defaultValues)
	const [currentStep, setCurrentStep] = useState(1)

	const [guid, setGuid] = useState(defaultValues.guid)
	const [type, setType] = useState(defaultValues.comment_type)
	// console.log('Type: ', type)
	const [start_time, setStartTime] = useState(defaultValues.start_time)
	const [duration, setDuration] = useState(defaultValues.duration)
	const [remarks, setRemarks] = useState(defaultValues.general_remarks)
	const [comment, setComment] = useState(defaultValues.comment)

	const [saveButtonText, setButtonText] = useState('Continue')
	const [saveButtonColor, setButtonColor] = useState('primary')
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	// const maxNumberOfPhotos = 1
	const [images, setImages] = useState([]);
	console.log('images:', images)
	const [showWebcamUI, setShowWebcamUI] = useState(false);
	// console.log('showWebcamUI:', showWebcamUI)
	// const [webcamStream, setWebcamStream] = useState<MediaStream | null>(null);
	// console.log(webcamStream)
	const onChange = (imageList: any, addUpdateIndex: any) => {
		setImages(imageList);
	};

	useEffect(() => {
		const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			navigator.userAgent
		);

		if (!isMobile) {
			setShowWebcamUI(true);
		}
	}, [])

	// const handleWebcamCapture = () => {
	// 	navigator.mediaDevices
	// 		.getUserMedia({ video: true })
	// 		.then((stream) => {
	// 			setShowWebcamUI(false);
	// 			setWebcamStream(stream); // Set the webcam stream
	// 		})
	// 		.catch((error) => {
	// 			console.error("Error accessing webcam:", error);
	// 		});
	// };

	const DeleteButton = () => {
		if (!update) return null;

		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	// const deleteRecordConfirm = () => {
	// 	setDeleteConfirmOpen(false)
	// 	// onComplete()
	// }

	// const [time_of_action, setTimeOfAction] = useState(defaultValues.time_of_action)
	const time_of_action = defaultValues.time_of_action

	const isoDate = selectedDate ? `${new Date(selectedDate).toISOString().split('T')[0]} 09:00:00` : new Date().toISOString().split('.')[0]


	let remarks_temp = ''

	useEffect(() => {

		if (remarks === 'Waiting for engineer') {remarks_temp = remarks}
		else if (remarks === 'Awaiting access') {remarks_temp = remarks}
		else if (remarks === 'Awaiting permit') {remarks_temp = remarks}
		else if (remarks === 'Induction') {remarks_temp = remarks}
		else if (remarks === 'Bad Weather') {remarks_temp = remarks}
		else if (remarks === 'Awaiting position') {remarks_temp = remarks}
		else if (remarks === 'Awaiting install details') {remarks_temp = remarks}
		else if (remarks === 'Unforseen circumstances') {remarks_temp = remarks}
		else if (remarks === 'Filling water bowser') {remarks_temp = remarks}
		else if (remarks === 'Erecting fencing') {remarks_temp = remarks}
		else if (remarks === 'Bagging spoil') {remarks_temp = remarks}
		else if (remarks === 'Relocating equipment') {remarks_temp = remarks}
		else if (remarks === 'Getting install') {remarks_temp = remarks}
		else if (remarks === 'Laying matting') {remarks_temp = remarks}
		else if (remarks === 'Loading skip') {remarks_temp = remarks}
		else if (remarks === 'Washing equipment') {remarks_temp = remarks}
		else {setComment(remarks); setRemarks('Other Comment'); remarks_temp = comment} // OTHER COMMENT

	}, [])

	const previous = () => {
		if (currentStep > 1)
			setCurrentStep(currentStep - 1)
		setButtonText('Continue')
		setButtonColor('primary')
	}

	const next = () => {
		if (currentStep < workflowSteps().length) {
			setCurrentStep(currentStep + 1)

			if (type === 'Mobilisation' || type === 'Setup') {
				if (currentStep === 2) {
					setButtonText('Save')
					setButtonColor('success')
				} else {
					setButtonText('Continue')
					setButtonColor('primary')
				}
			} else if (type === 'Standing Time' || type === 'Dayworks') { // || type === 'Photo'
				if (currentStep === 3) {
					setButtonText('Save')
					setButtonColor('success')
				} else {
					setButtonText('Continue')
					setButtonColor('primary')
				}
			} else if (type === 'Comment' || type === 'Photo') {
				if (currentStep === 1) {
					setButtonText('Save')
					setButtonColor('success')
				} else {
					setButtonText('Continue')
					setButtonColor('primary')
				}
			}
			return
		}

		console.log('currentStep:', currentStep)

		console.log('comment & remarks:', comment, remarks)

		if ((type !== "Comment") && (!type || !start_time || !duration)) {
			//do nothing
		} else {

			// defaulting to the correct remark/comment
			
			// if (comment === 'undefined') {
			// 	remarks_temp = comment
			// } else if (comment === 'Other Comment') {
			// 	remarks_temp = remarks
			// } else {
			// 	remarks_temp = remarks
			// }

			if (remarks === 'Waiting for engineer') {remarks_temp = remarks}
			else if (remarks === 'Awaiting access') {remarks_temp = remarks}
			else if (remarks === 'Awaiting permit') {remarks_temp = remarks}
			else if (remarks === 'Induction') {remarks_temp = remarks}
			else if (remarks === 'Bad Weather') {remarks_temp = remarks}
			else if (remarks === 'Awaiting position') {remarks_temp = remarks}
			else if (remarks === 'Awaiting install details') {remarks_temp = remarks}
			else if (remarks === 'Unforseen circumstances') {remarks_temp = remarks}
			else if (remarks === 'Filling water bowser') {remarks_temp = remarks}
			else if (remarks === 'Erecting fencing') {remarks_temp = remarks}
			else if (remarks === 'Bagging spoil') {remarks_temp = remarks}
			else if (remarks === 'Relocating equipment') {remarks_temp = remarks}
			else if (remarks === 'Getting install') {remarks_temp = remarks}
			else if (remarks === 'Laying matting') {remarks_temp = remarks}
			else if (remarks === 'Loading skip') {remarks_temp = remarks}
			else if (remarks === 'Washing equipment') {remarks_temp = remarks}
			else {setComment(remarks); setRemarks('Other Comment'); remarks_temp = comment} // OTHER COMMENT

			// Start Time
			let start_time_temp
			if (start_time === '00:00') { start_time_temp = '' } else { start_time_temp = start_time }

			// Duration
			let duration_temp
			if (duration === '00:00') { duration_temp = '' } else { duration_temp = duration }



			if (currentStep !== 1 && (type === 'Mobilisation' || type === 'Setup') && (start_time === '00:00' || duration === '00:00')) {
				//do nothing
			} else if (currentStep !== 1 && (type === 'Standing Time' || type === 'Dayworks')
				&& (start_time === '00:00' || duration === '00:00' || remarks === '')) {
				//do nothing
			} else {

				onComplete({
					guid: update ? guid : uuidv4(),
					comment_type: type,
					// start_time: `${new Date().toISOString().substring(0, 10)} ${start_time}`,
					start_time: start_time_temp,
					duration: duration_temp,
					general_remarks: remarks_temp !== undefined ? remarks_temp : '',
					photo_type: '',
					time_of_action: time_of_action ? time_of_action : isoDate,
					update: update ? true : false,
				})
			}
		}
	}

	let requiredStartTimeText = ''
	let requiredDurationText = ''
	let requiredRemarksText = ''
	let requiredCommentText = ''

	if (start_time === '00:00') {
		requiredStartTimeText = "'Start Time' - "
	}

	if (duration === '00:00') {
		requiredDurationText = "'Duration'"
	}

	if ((remarks === 'Other Comment' && comment === '') || (remarks === '' && comment === '')) {
		requiredRemarksText = "'Comment'"
	}


	if (comment === '') {
		requiredCommentText = "'Comment'"
	}

	const MissingRequiredField = () => {
		if (currentStep !== 1 && (type === 'Mobilisation' || type === 'Setup') && (start_time === '00:00' || duration === '00:00')) return (
			<div className="text-center bg-sptSB border border-red font-bold rounded mb-2 py-1 text-xs md:text-sm lg:text-base">
				<img src={warningIcon} alt="info" width="20" className="inline-block mr-1 pb-1" />
				Required field: {requiredStartTimeText} {requiredDurationText}
			</div>
		)
		if (currentStep !== 1 && (type === 'Standing Time' || type === 'Dayworks')
			&& (start_time === '00:00' || duration === '00:00' ||
				((remarks === 'Other Comment' && comment === '') || (remarks === '' && comment === ''))
			)) return (
				<div className="text-center bg-sptSB border border-red font-bold rounded mb-2 py-1 text-xs md:text-sm lg:text-base">
					<img src={warningIcon} alt="info" width="20" className="inline-block mr-1 pb-1" />
					Required field: {requiredStartTimeText} {requiredDurationText} {requiredRemarksText}
				</div>
			)
		if (currentStep !== 1 && type === 'Comment' && (!comment)) return (
			<div className="text-center bg-sptSB border border-red font-bold rounded mb-2 py-1 text-xs md:text-sm lg:text-base">
				<img src={warningIcon} alt="info" width="20" className="inline-block mr-1 pb-1" />
				Required field: {requiredCommentText}
			</div>
		)

		return null
	}

	const workflowSteps = () => {
		let steps = [
			{
				label: 'Type',
				status: stepStatus(1, currentStep),
				onClick: () => {
				setCurrentStep(1)
				setButtonText('Continue')
				setButtonColor('primary')
			}
			},
		]

		if (type === 'Mobilisation' || type === 'Setup') {
			steps.push({
				label: 'Start Time',
				status: stepStatus(2, currentStep),
				onClick: () => {
					setCurrentStep(2)
					setButtonText('Continue')
					setButtonColor('primary')
				}
			}, {
				label: 'Duration',
				status: stepStatus(3, currentStep),
				onClick: () => {
					setCurrentStep(3)
					setButtonText('Save')
					setButtonColor('success')
				}
			})
		}

		if (type === 'Standing Time' || type === 'Dayworks') {
			steps.push({
				label: 'Start Time',
				status: stepStatus(2, currentStep),
				onClick: () => {
					setCurrentStep(2)
					setButtonText('Continue')
					setButtonColor('primary')
				}
			}, {
				label: 'Duration',
				status: stepStatus(3, currentStep),
				onClick: () => {
					setCurrentStep(3)
					setButtonText('Continue')
					setButtonColor('primary')
				}
			}, {
				label: 'Comment',
				status: stepStatus(4, currentStep),
				onClick: () => {
					setCurrentStep(4)
					setButtonText('Save')
					setButtonColor('success')
				}
			})
		}

		if (type === 'Comment') {
			steps.push({
				label: 'Comment',
				status: stepStatus(2, currentStep),
				onClick: () => {
					setCurrentStep(2)
					setButtonText('Save')
					setButtonColor('success')
				}
			})
		}

		if (type === 'Photo') {
			steps.push({
				label: 'Photo',
				status: stepStatus(2, currentStep),
				onClick: () => {
					setCurrentStep(2)
					setButtonText('Save')
					setButtonColor('success')
				}
			})
		}

		return steps
	}

	const currentWorkflow = (): any => {
		const workflow: any = {
			'Mobilisation': [
				// <TimeInput onUpdate={(value: any) => setStartTime(value)} 
				// title="Enter Start Time" 
				// defaultValue={start_time} 
				// className="mb-10" />,

				<InputGroup
					label="Enter Start Time"
					type={'time'}
					value={start_time}
					onChange={(value: any) => setStartTime(`${value}`)}
					className="text-base sm:text-base md:text-xl w-full text-center w-full flex flex-col flex-grow border border-grey-mid rounded px-2 mb-2 bg-sptTB"
					inputCSS="form-control-xxl text-center text-4xl w-60 mx-auto mt-3"
				/>,

				<DurationInput onUpdate={(value: any) => setDuration(value)}
					title="Enter Time Taken"
					defaultValue={duration}
					className="mb-10" />,
			],
			'Setup': [
				// <TimeInput onUpdate={(value: any) => setStartTime(value)} 
				// title="Enter Start Time" 
				// defaultValue={start_time} 
				// className="mb-10" />,

				<InputGroup
					label="Enter Start Time"
					type={'time'}
					value={start_time}
					onChange={(value: any) => setStartTime(`${value}`)}
					className="text-base sm:text-base md:text-xl w-full text-center w-full flex flex-col flex-grow border border-grey-mid rounded px-2 mb-2 bg-sptTB"
					inputCSS="form-control-xxl text-center text-4xl w-60 mx-auto mt-3"
				/>,

				<DurationInput onUpdate={(value: any) => setDuration(value)}
					title="Enter Time Taken"
					defaultValue={duration}
					className="mb-10" />,
			],
			'Standing Time': [
				// <TimeInput onUpdate={(value: any) => setStartTime(value)} 
				// title="Enter Start Time" 
				// defaultValue={start_time} 
				// className="mb-10" />,

				<InputGroup
					label="Enter Start Time"
					type={'time'}
					value={start_time}
					onChange={(value: any) => setStartTime(`${value}`)}
					className="text-base sm:text-base md:text-xl w-full text-center w-full flex flex-col flex-grow border border-grey-mid rounded px-2 mb-2 bg-sptTB"
					inputCSS="form-control-xxl text-center text-4xl w-60 mx-auto mt-3"
				/>,

				<DurationInput onUpdate={(value: any) => setDuration(value)}
					title="Enter Time Taken"
					defaultValue={duration}
					className="mb-10" />,

				<div className="flex flex-col w-full">
					<ButtonOptions onUpdate={(value: any) => setRemarks(value)}
						title="Select Reason"
						defaultValue={remarks}
						options={[
							'Waiting for engineer',
							'Awaiting access',
							'Awaiting permit',
							'Induction',
							'Bad Weather',
							'Awaiting position',
							'Awaiting install details',
							'Unforseen circumstances',
							'Other Comment',
						]}
						columns={3}
						columnsMobile={2}
						className="text-base sm:text-base md:text-xl w-full text-center w-full flex flex-col border border-grey-mid rounded px-2 mb-2 bg-sptTB pb-4" // flex-grow 
						buttonHeight={20}
						buttonHeightMobile={14}
					/>

					{remarks === 'Other Comment' &&
						<textarea className="p-2 rounded w-full mb-2 border-2 border-orange" // form-control
							placeholder="Add comments" 
							rows={3} 
							onChange={(event) => setComment(event.target.value)} 
							value={comment}>
						</textarea>
					}
				</div>,
			],
			'Dayworks': [
				// <TimeInput onUpdate={(value: any) => 
				// 	setStartTime(value)} 
				// 	title="Enter Start Time" 
				// 	defaultValue={start_time} 
				// 	className="mb-10" />,

				<InputGroup
					label="Enter Start Time"
					type={'time'}
					value={start_time}
					onChange={(value: any) => setStartTime(`${value}`)}
					className="text-base sm:text-base md:text-xl w-full text-center w-full flex flex-col flex-grow border border-grey-mid rounded px-2 mb-2 bg-sptTB"
					inputCSS="form-control-xxl text-center text-4xl w-60 mx-auto mt-3"
				/>,

				<DurationInput onUpdate={(value: any) => setDuration(value)}
					title="Enter Time Taken"
					defaultValue={duration}
					className="mb-10" />,

				<div className="flex flex-col w-full">
					<ButtonOptions onUpdate={(value: any) => setRemarks(value)}
						title="Select Reason"
						defaultValue={remarks}
						options={[
							'Filling water bowser',
							'Erecting fencing',
							'Bagging spoil',
							'Relocating equipment',
							'Getting install',
							'Laying matting',
							'Loading skip',
							'Washing equipment',
							'Other Comment',
						]}
						columns={3}
						columnsMobile={2}
						className="text-base sm:text-base md:text-xl w-full text-center w-full flex flex-col border border-grey-mid rounded px-2 mb-2 bg-sptTB pb-4" // flex-grow
						buttonHeight={20}
						buttonHeightMobile={14}
					/>

					{remarks === 'Other Comment' &&
						<textarea className="p-2 rounded w-full mb-2 border-2 border-orange" // form-control
						placeholder="Add comments" 
						rows={3} 
						onChange={(event) => (setComment(event.target.value))} 
						value={comment}>

						</textarea>
					}
				</div>,
			],
			'Comment': [
				<div className="flex flex-col w-full flex flex-col flex-grow border border-grey-mid rounded px-2 mb-2 bg-sptTB">
					<Comment comment={comment} numberOfRows={5} setComment={setComment} placeholder="Enter comments" title="Comments" />
				</div>,
			],
			'Photo': [
				<div className="flex flex-col w-full flex flex-col flex-grow border border-grey-mid rounded px-2 mb-2 bg-sptTB">
					<div className="w-full text-center text-xl">Photo</div>
					<div className="mx-auto text-center text-charcoal text-base">

						<ImageUploading value={images} onChange={onChange} maxNumber={1}>
							{({
								imageList,
								onImageUpload,
								onImageRemoveAll,
								onImageUpdate,
								onImageRemove,
								isDragging,
								dragProps,
							}) => (
								// write your building UI
								<div className="mb-2">
									<button
										className="bg-blue rounded-lg px-4 py-2 mb-3 text-white"
										style={isDragging ? { color: "red" } : undefined}
										onClick={onImageUpload}
										{...dragProps}
									>
										Click here to add photo
									</button>

									{imageList.map((image, index) => {

										console.log(`Processing image ${index + 1}`);
										const imageType = getImageType(image.dataURL);
										console.log(`Image ${index + 1} type: ${imageType}`);

										return (
										<div key={index} className="image-item">
											<img src={image.dataURL} alt="" className="mx-auto w-4/6" />
											<div className="image-item__btn-wrapper">
											<button
												className="mr-5 rounded-lg bg-grey-mid text-charcoal px-4 py-2 mt-2"
												onClick={() => onImageRemove(index)}
											>
												Remove
											</button>
											<button
												className="ml-5 rounded-lg bg-orange text-white px-4 py-2 mt-2"
												onClick={() => onImageUpdate(index)}
											>
												Update
											</button>
											</div>
										</div>
										);
									})}
									</div>
							)}
						</ImageUploading>

					</div>
					<Comment comment={comment} numberOfRows={2} setComment={setComment} title="Comments" />
				</div>
			],
		}

		return workflow[type]
	}



	// Function to get image type from dataURL
	function getImageType(dataURL:any) {
		try {
		// Extract the base64 part of the dataURL
		const base64Part = dataURL.split(',')[1];
	
		// Convert base64 to binary
		const binary = atob(base64Part);
	
		// Read the first byte to determine the image type
		const byte = binary.charCodeAt(0);
		switch (byte) {
			case 0xFF:
			return 'image/jpeg';
			case 0x89:
			return 'image/png';
			case 0x47:
			return 'image/gif';
			case 0x42:
			return 'image/bmp';
			// Add more cases for other image types if needed
			default:
			return 'unknown';
		}
		} catch (error) {
		console.error('Error getting image type:', error);
		return 'unknown';
		}
	}

	return (
		<Modal title="Comments/Standing Time/Day Works" subtitle={borehole} toggleModal={onClose}>

			<div className="flex flex-col flex-grow px-1 sm:px-2 md:px-10">
				<WorkflowSteps steps={workflowSteps()} className="mb-2 " />

				<div className="flex-1 flex ">
					{currentStep === 1 &&
						<ButtonOptions onUpdate={(value: any) => setType(value)}
							options={[
								'Mobilisation',
								'Setup',
								'Standing Time',
								'Dayworks',
								'Comment',
								'Photo',
							]}
							title="Select Type"
							defaultValue={type}
							columns={3}
							columnsMobile={2}
							className="text-base sm:text-base md:text-xl w-full text-center w-full flex flex-col flex-grow border border-grey-mid rounded px-2 mb-2 bg-sptTB"
							buttonHeight={20}
							buttonHeightMobile={14}
							next={next}
						/>
					}

					{currentStep !== 1 && currentWorkflow()[currentStep - 2]}
				</div>


				<MissingRequiredField />


				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">


							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?

								<div className="flex mt-4">
									<Button theme="secondary"
										className="mr-5 text-sm sm:text-base px-3.5 md:px-7"
										onClick={() => setDeleteConfirmOpen(false)}>
										Cancel
									</Button>

									<Button onClick={() => {
										onClose()
										onComplete({
											guid: guid,
											update: false,
											delete: true,
										})
										setShowCommentsEdit(false)
									}}
										className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">
										Delete
									</Button>
								</div>
							</div>


						</div>
					</div>
				}

				<div className="flex">
					{/* <Button theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button> */}
					<DeleteButton />

					<div className="flex ml-auto">
						<Button theme="primary" size="lg" className="px-4 sm:px-10" onClick={() => previous()}>Back</Button>
						<Button theme={saveButtonColor}
							size="lg"
							className="ml-4 px-8 sm:px-10 w-32 sm:w-60"
							onClick={() => next()}>{saveButtonText}
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

Comments.defaultProps = defaultProps

export default Comments
