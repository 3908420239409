import React, { FunctionComponent, useState, useEffect, useRef } from 'react'

import Button from './form/Button'

// import formatTime from '../helpers/formatTime'

type Props = {
	className?: string,
	onUpdate: (value: any) => void;
	// setParentDateTime: (value: any) => void;
	[x: string]: any;
}

const defaultProps: Props = {
	className: '',
	onUpdate: (value: any) => {},
	// setParentDateTime: (value: any) => {},
}

function getCurrentDateString() {
	const date = new Date().getDate() //current date
		var leadingZeroDate = ''
		if (date<10){leadingZeroDate = '0'}
	const month = new Date().getMonth() + 1 //current month
		var leadingZeroMonth = ''
		if (month<10){leadingZeroMonth = '0'}
	const year = new Date().getFullYear() //current year
	const hours = new Date().getHours() //current hours
		var leadingZeroHours = ''
		if (hours<10){leadingZeroHours = '0'}
	const min = new Date().getMinutes() //current minutes
		var leadingZeroMinutes = ''
		if (min<10){leadingZeroMinutes = '0'}
	const sec = new Date().getSeconds() //current seconds
		var leadingZeroSecs = ''
		if (sec<10){leadingZeroSecs = '0'}

	return year + '-' + leadingZeroMonth + month + '-' + leadingZeroDate + date + 'T' + leadingZeroHours + hours + ':' + leadingZeroMinutes + min + ':' + leadingZeroSecs + sec
}

const Timer: FunctionComponent<Props> = ({ className, onUpdate, start_time, status, setParentDateTime, setParentStatus, ...rest }) => {

	const [currentStatus, setCurrentStatus] = useState('')
	// console.log('status in timer:', status)

	useEffect(() => {
		setCurrentStatus(status)
	}, [status])

	// console.log('currentStatus in timer:', currentStatus)
	// const currentStatus = status

	const [startDateTime, setDateTime] = useState(start_time)

	useEffect(() => {
		setDateTime(start_time)
	}, [start_time])

	// console.log('startDateTime in timer:', startDateTime)

	const [currentdate, setCurrentdate] = useState(getCurrentDateString())

	// console.log('currentdate:', currentdate)

	// convert dates to timestamp
	const startDateTimeTimestamp = new Date(startDateTime).getTime()
	// console.log('startDateTimeTimestamp in timer:', startDateTimeTimestamp)

	const currentdateTimestamp = new Date(currentdate).getTime()
	// console.log('currentdateTimestamp in timer:', currentdateTimestamp)

	var timeElapsedSinceStart = 0
	if(startDateTimeTimestamp){
		timeElapsedSinceStart = (currentdateTimestamp - startDateTimeTimestamp)/1000
	}
	// console.log('timeElapsedSinceStart in timer:', timeElapsedSinceStart)

	const [timer, setTimer] = useState(0)

	useEffect(() => {
		setTimer(timeElapsedSinceStart)
		}, [timeElapsedSinceStart]
	)

	// console.log('timer', timer)

	const [isActive, setIsActive] = useState(false)
	const increment: any = useRef(null)

	useEffect(() => {
			onUpdate(timer)
		}, [timer, onUpdate]
	)







	const handleStart = () => {
		if (isActive) return;
	  
		setIsActive(true);
	  
		increment.current = setInterval(() => {
		  setTimer((timer: any) => timer + 1);
		}, 1000);

		// console.log('startDateTime in handleStart:', startDateTime)
		// console.log('getCurrentDateString() in handleStart:', getCurrentDateString())
	  
		setCurrentdate(getCurrentDateString());
		if (startDateTime === null || startDateTime === '') {
		  setParentDateTime(getCurrentDateString());
		}
	  
		setParentStatus('active');
		setCurrentStatus('active');
	  };





	useEffect(() => {
		if(currentStatus==='active'){
			handleStart() // if run is active, start the timer /// keeps re-loading so STOP button can't be pressed (if status == active)
			// console.log('active')
			}
		})

	
	if(currentStatus==='active'){

	}




	const handlePause = () => {

		setCurrentStatus('complete');
		setParentStatus('complete')
		clearInterval(increment.current)
		setIsActive(false)
	}





	const handleReset = () => {

		setCurrentStatus('inactive');
		setParentStatus('inactive')
		clearInterval(increment.current)
		setIsActive(false)
		setTimer(0)
	}






	const [stopConfirmOpen, setStopConfirmOpen] = useState<boolean>(false)

	const stopRecord = () => {
		setStopConfirmOpen(true)
	}

	const stopConfirm = () => {
		setStopConfirmOpen(false)
		handlePause()
	}

	const [resetConfirmOpen, setResetConfirmOpen] = useState<boolean>(false)

	const resetRecord = () => {
		setResetConfirmOpen(true)
	}

	const resetConfirm = () => {
		setResetConfirmOpen(false)
		handleReset()
	}

	return (
		<>
		<div className={`${className}`}>
			{/* current date time: {currentdate} - time elapsed: {timeElapsedSinceStart} */}
			<div className="flex-none md:flex justify-center">
				<Button theme="success" size="xl" 
					// className="mr-0 md:mr-3 mb-1 md:mb-0 h-8 md:h-14 w-24 md:w-36 py-5 md:py-4 text-xl font-bold" 
					className="mr-0 md:mr-3 mb-1 md:mb-0 h-8 md:h-14 w-full py-5 md:py-4 text-xl font-bold" 
					onClick={handleStart} disabled={isActive}>START
				</Button>

				<Button theme="danger" size="xl" 
					// className="mr-0 md:mr-3 mb-1 md:mb-0 h-6 md:h-14 w-24 md:w-36 py-3 md:py-4 text-base md:text-xl font-bold" 
					className="mr-0 md:mr-3 mb-1 md:mb-0 h-6 md:h-14 w-full py-3 md:py-4 text-base md:text-xl font-bold" 
					onClick={stopRecord} disabled={!isActive}>STOP
				</Button>

				<Button theme="secondary" size="sm" 
					// className="h-4 md:h-14 w-24 md:w-36 py-2 md:py-4 text-xs md:text-xl font-bold" 
					className="h-4 md:h-14 w-full py-2 md:py-4 text-xs md:text-xl font-bold" 
					onClick={resetRecord}>RESET
				</Button>
			</div>
		</div>
				

		{resetConfirmOpen &&
			<div className="fixed inset-0 z-50">
				<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">


					<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
						Are you sure you want to reset?

						<div className="flex mt-4">
							<Button theme="secondary" className="mr-5 text-sm sm:text-base px-3.5 md:px-7" onClick={() => setResetConfirmOpen(false)}>Cancel</Button>

							<Button onClick={resetConfirm} className="text-sm sm:text-base px-16 md:px-20 bg-red border-red">Reset</Button>
						</div>
					</div>


				</div>
			</div>
		}
				

		{stopConfirmOpen &&
			<div className="fixed inset-0 z-50">
				<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">


					<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
						Are you sure you want to stop?<br /><br/>
						This will complete this run!
						<div className="flex mt-4">
							<Button theme="secondary" className="mr-5 text-sm sm:text-base px-3.5 md:px-7" onClick={() => setStopConfirmOpen(false)}>Cancel</Button>

							<Button onClick={stopConfirm} className="text-sm sm:text-base px-16 md:px-20 bg-red border-red">Stop</Button>
						</div>
					</div>


				</div>
			</div>
		}

		</>
	)
}

Timer.defaultProps = defaultProps

export default Timer