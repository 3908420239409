import React, { FunctionComponent, useState } from 'react'

import WorkflowSteps from '../../WorkflowSteps'
import Modal from '../../Modal'
import Button from '../../form/Button'
// import NumberInput from '../../NumberInput'
import MultiNumberInput from '../../MultiNumberInput'

import { v4 as uuidv4 } from 'uuid';
import stepStatus from '../../../helpers/stepStatus'
//import TimeInput from "../../TimeInput";
import DurationInput from "../../DurationInput"
import warningIcon from '../../../assets/icons/warning.png'

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: string,
	defaultValues?: any,
	selectedDate?: string,
	update?: boolean,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => { },
	onComplete: () => { },
	borehole: '',
	defaultValues: {
		depth_at_start: '',
		depth_at_end: '',
		duration: '00:00:00',
		//start_time: '00:00:00',
	},
	selectedDate: '',
}

const Chiselling: FunctionComponent<Props> = ({ onClose, update, onComplete, borehole, defaultValues, selectedDate, setShowChisellingEdit, ...rest }) => {
	const [currentStep, setCurrentStep] = useState(1)

	const [guid, setGuid] = useState(defaultValues.guid)
	const [depth_at_start, setDepthFrom] = useState(defaultValues.depth_at_start)
	const [depth_at_end, setDepthTo] = useState(defaultValues.depth_at_end)
	// const [start_time, setStartTime] = useState(defaultValues.start_time)
	// const [duration, setTime] = useState(defaultValues.duration)
	const [duration, setDuration] = useState(defaultValues.duration)

	// const [time_of_action, setTimeOfAction] = useState(defaultValues.time_of_action)
	const time_of_action = defaultValues.time_of_action

	const [saveButtonText, setButtonText] = useState('Continue')
	const [saveButtonColor, setButtonColor] = useState('primary')
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	const DeleteButton = () => {
		if (!update) return null;

		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	// const deleteRecordConfirm = () => {
	// 	setDeleteConfirmOpen(false)
	// 	// onComplete()
	// }

	const isoDate = selectedDate ? `${new Date(selectedDate).toISOString().split('T')[0]} 09:00:00` : new Date().toISOString().split('.')[0]

	const previous = () => {
		if (currentStep > 1)
			setCurrentStep(currentStep - 1)
		setButtonText('Continue')
		setButtonColor('primary')
	}

	const next = () => {
		if (currentStep < 2) {
			setCurrentStep(currentStep + 1)
			if (currentStep === 1) {
				setButtonText('Save')
				setButtonColor('success')
			} else {
				setButtonText('Continue')
				setButtonColor('primary')
			}
			return
		}

		if (!depth_at_start || !depth_at_end || duration === '00:00') {
			//do nothing
		} else {

			onComplete({
				guid: update ? guid : uuidv4(),
				depth_at_start: depth_at_start2DP, //2DP,
				depth_at_end: depth_at_end2DP, //2DP,
				// start_time: `${new Date().toISOString().substring(0, 10)} ${duration}`,
				duration: duration +':00',
				general_remarks: '',
				time_of_action: time_of_action ? time_of_action : isoDate,
				update: update ? true : false,
				// delete: deleteRecord ? true : false,
			})
		}
	}

	console.log('duration:', duration)

	let requiredStartText = ''
	let requiredEndText = ''
	let durationText = ''

	if (!depth_at_start) {
		requiredStartText = "'Start Depth' - "
	}

	if (!depth_at_end) {
		requiredEndText = "'End Depth' - "
	}

	if (duration === '00:00') {
		durationText = "'Time Taken'"
	}

	const MissingRequiredField = () => {
		if (currentStep === 2 && (!depth_at_start || !depth_at_end || duration === '0:0')) return (
			<div className="text-center bg-sptSB border border-red font-bold rounded mb-2 py-1 text-xs md:text-sm lg:text-base">
				<img src={warningIcon} alt="info" width="20" className="inline-block mr-1 pb-1" />
				Required field: {requiredStartText} {requiredEndText} {durationText}
			</div>
		)
	
		return null
	}

	var depth_at_start2DP = parseFloat(depth_at_start).toFixed(2)
	var depth_at_end2DP = parseFloat(depth_at_end).toFixed(2)

	return (
		<Modal title="Chiselling" subtitle={borehole} toggleModal={onClose}>
			<div className="flex flex-col flex-grow px-1 sm:px-2 md:px-10">
				<WorkflowSteps steps={[
					{
						label: 'Depth',
						status: stepStatus(1, currentStep),
						onClick: () => {
							setCurrentStep(1)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Time Taken',
						status: stepStatus(2, currentStep),
						onClick: () => {
							setCurrentStep(2)
							setButtonText('Save')
							setButtonColor('success')
						}
					},
				]} className="mb-2" />

				<div className="flex-1 flex">
					{currentStep === 1 &&
						// <NumberInput onUpdate={(value: any) => setDepthFrom(value)} 
						// title="Enter Depth From (m)"  
						// defaultValue={start_depth} 
						// className="mb-10" />

						<MultiNumberInput
							title="Depth (m)"  
							values={
								[
									{ defaultValue: depth_at_start, onUpdate: setDepthFrom, label: 'Start Depth' },
									{ defaultValue: depth_at_end, onUpdate: setDepthTo, label: 'End Depth' },
								]
							}
							className="mb-2 text-base sm:text-xl md:text-xl"
							inputCSS="text-3xl sm:text-3xl md:text-3xl lg:text-4xl h-16 sm:h-16 mb-4"
						/>
					}

					{/* {currentStep === 2 &&
						<NumberInput onUpdate={(value: any) => setDepthTo(value)} 
						title="Enter Depth To (m)" 
						defaultValue={end_depth} 
						className="mb-10" />
					} */}

					{currentStep === 2 &&
						// <TimeInput onUpdate={(value: any) => setTime(value)} 
						<DurationInput onUpdate={(value: any) => setDuration(value)}
							title="Enter Time Taken (hh:mm)"
							defaultValue={duration}
							className="mb-10" />
					}
				</div>

				<MissingRequiredField />


				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">


							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?

								<div className="flex mt-4">
									<Button theme="secondary" 
										className="mr-5 text-sm sm:text-base px-3.5 md:px-7" 
										onClick={() => setDeleteConfirmOpen(false)}>
											Cancel
									</Button>

									<Button onClick={() => {
												onClose()
												onComplete({
													guid: guid,
													update: false,
													delete: true,
												})
												setShowChisellingEdit(false)
											}}
										className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">
										Delete
									</Button>
								</div>
							</div>


						</div>
					</div>
				}

				<div className="flex">
					{/* <Button theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button> */}
					<DeleteButton />

					<div className="flex ml-auto">
						<Button theme="primary" size="lg" className="px-4 sm:px-10" onClick={() => previous()}>Back</Button>
						<Button theme={saveButtonColor}
							size="lg"
							className="ml-4 px-8 sm:px-10 w-32 sm:w-60"
							onClick={() => next()}>{saveButtonText}
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

Chiselling.defaultProps = defaultProps

export default Chiselling
