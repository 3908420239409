import React, { FunctionComponent } from 'react'

// import cogIcon from '../assets/icons/cog.svg'
import checkIcon from '../assets/icons/check.svg'

type Props = {
	name: string,
	status?: string;
	complete?: any,
	[x: string]: any;
}

const defaultProps: Props = {
	name: '',
	status: '1',
}

const Borehole: FunctionComponent<Props> = ({ name, status, complete, drillerName, followOnType, ...rest }) => {
	
	let statusIcon = null;

	// if (status === '1') {
	//   statusIcon = <div className="rounded-full border-4 border-grey-mid w-7 h-7 mr-2"></div>;
	// } else if (status === '2') {
	//   statusIcon = <div className="rounded-full border-4 border-orange w-7 h-7 mr-2"></div>;
	// } else if (status === '3' || complete) {
	//   statusIcon = (
	// 	<div className="rounded-full border-4 border-green-checkmark text-green-checkmark w-7 h-7 mr-2 flex items-center justify-center">
	// 	  <img src={checkIcon} width="14" alt="" />
	// 	</div>
	//   );
	// } else {
	//   statusIcon = <div className="rounded-full border-4 border-grey-mid w-7 h-7 mr-2"></div>;
	// }

  
	switch (complete ? '3' : status) {
	  case '1':
		statusIcon = <div className="rounded-full border-4 border-grey-mid w-7 h-7 mr-2"></div>;
		break;
	  case '2':
		statusIcon = <div className="rounded-full border-4 border-orange w-7 h-7 mr-2"></div>;
		break;
	  case '3':
		statusIcon = (
		  <div className="rounded-full border-4 border-green-checkmark text-green-checkmark w-7 h-7 mr-2 flex items-center justify-center">
			<img src={checkIcon} width="14" alt="" />
		  </div>
		);
		break;
	  case null:
		statusIcon = <div className="rounded-full border-4 border-grey-mid w-7 h-7 mr-2"></div>;
		break;
	}
  
	return (
	  <div className={`flex items-center border md:border-2 ${followOnType ? 'border-blue' : 'border-orange'} relative px-2 pt-2 pb-1.5 text-lg leading-none bg-white rounded mb-2 md:mb-3`} {...rest}>
		
		{ followOnType &&
			<div className="absolute top-0 right-0">
				<div className="mt-0 ml-auto bg-white min-w-10 rounded-bl-md text-center text-charcoal h-3 col-span-2 md:col-span-1 pl-2 pr-1 pt-1 " style={{fontSize: '0.45rem'}}>
					{followOnType} follow on
				</div>
			</div>
		}
		{statusIcon}
  
		<div className="font-bold">{name}</div>
  
		{/* <div className="absolute inset-y-1 right-1"><img src={cogIcon} width="14" alt="" /></div> */}
		{ drillerName &&
			<div className="absolute bottom-0 right-0">
				<div className={`-mt-2 ml-auto  ${followOnType ? 'bg-blue' : 'bg-orange'} min-w-10 rounded-tl-md text-center text-white h-2.5 col-span-2 md:col-span-1 pl-2 pr-1 pt-0.5 pb-1`} style={{fontSize: '8px'}}>
					{drillerName}
				</div>
			</div>
		}
	  </div>
	);
  };
  
  Borehole.defaultProps = defaultProps;
  
  export default Borehole;
  
