import React, { FunctionComponent } from 'react'

import checkIcon from '../assets/icons/check.svg'

type Props = {
	steps: any[],
	nocheck?: boolean,
	className?: string;
	[x: string]: any;
}

const defaultProps: Props = {
	steps: [],
	nocheck: false,
	className: '',
}

const WorkflowSteps: FunctionComponent<Props> = ({ steps, nocheck, className, ...rest }) => {
	const Step = ({step, isFirst, isLast}: any): JSX.Element => {
		switch(step.status) {
			case 'done':
				return (
					<div className={`relative ${nocheck ? 'bg-green-selected text-xs sm:text-sm' : 'bg-white text-xs sm:text-sm'} flex-1 flex items-center justify-center text-center h-7 px-1 border-grey-dark ${isFirst ? '' : 'border-l'}`} onClick={step.onClick}>
						{!isLast && <div className={`workflow-arrow${nocheck ? '-green' : ''}`}></div>}
						{!nocheck && <img src={checkIcon}  className="mr-1 w-1 sm:w-5 invisible sm:visible" alt="" />}
						{step.label}
					</div>
				)
			case 'active':
				return (
					<div className={`relative ${nocheck ? 'text-xs sm:text-base md:text-base' : 'text-xs sm:text-sm md:text-sm'} flex-1 flex items-center justify-center text-center h-7 px-1 text-white bg-orange border-orange ${isFirst ? '' : 'border-l'}`} onClick={step.onClick}>
						{!isLast && <div className="workflow-arrow-orange"></div>}
						{step.label}
					</div>
				)
			case 'inactive':
				return (
					<div className={`relative ${nocheck ? 'text-xs sm:text-sm md:text-sm' : 'text-xs sm:text-sm md:text-sm'} bg-white flex-1 flex items-center justify-center text-center h-7 px-1 border-grey-dark ${isFirst ? '' : 'border-l'}`} onClick={step.onClick}>
						{!isLast && <div className="workflow-arrow"></div>}
						{step.label}
					</div>
				)
		}

		return (
			<div className={`relative bg-white flex-1 flex items-center justify-center text-center h-7 px-1 text-sm border-grey-mid ${isFirst ? '' : 'border-l'}`} onClick={step.onClick}>
				{!isLast && <div className="workflow-arrow"></div>}
				{step.label}
			</div>
		)
	}

	return (
		<div className={`flex border border-grey-mid rounded-sm text-xs sm:text-base md:text-lg ${className}`} {...rest}>
			{steps.map((step, index) => {
				return <Step step={step} isFirst={index === 0} isLast={index === steps.length - 1} key={index} />
			})}
		</div>
	)
}

WorkflowSteps.defaultProps = defaultProps

export default WorkflowSteps