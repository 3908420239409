import { INCREMENT_DATE, DECREMENT_DATE, SET_SELECTED_DATE } from '../action-types'

// const initialState = new Date(); // Set initial state to today's date

export const incrementDate = () => ({
	type: INCREMENT_DATE,
})

export const decrementDate = () => ({
	type: DECREMENT_DATE,
})

export const setSelectedDate = (date: Date) => ({
    type: SET_SELECTED_DATE,
    payload: date,
  });

// export const setSelectedDate = (date: string) => ({
//   type: SET_SELECTED_DATE,
//   payload: date,
// })

// const selectedDateReducer = (state = initialState, action: any) => {
// 	switch (action.type) {
// 	  case INCREMENT_DATE:
// 		const incrementDate = new Date(state);
// 		incrementDate.setDate(state.getDate() + 1); // Increment the date by 1 day
// 		return incrementDate;
  
// 	  case DECREMENT_DATE:
// 		const decrementDate = new Date(state);
// 		decrementDate.setDate(state.getDate() - 1); // Decrement the date by 1 day
// 		return decrementDate;
  
// 	  case SET_SELECTED_DATE:
// 		return new Date(action.payload); // Set the selected date from action.payload
  
// 	  default:
// 		return state;
// 	}
//   };
  
//   export default selectedDateReducer;