import React from 'react'
import { Link } from 'react-router-dom'

import Heading from '../../components/Heading'
import Table from '../../components/Table'
import Button from '../../components/form/Button'

import Header from '../../layout/Header'
import Modal from '../../components/Modal'

import { useGetIDBValues } from "../../helpers/getIDBKey";
import { setIDB } from "../../helpers/setIDBKey";
import edit from '../../assets/icons/edit.svg'
import SelectGroup from "../../components/form/SelectGroup";
import { get, set } from "idb-keyval";
import RigList from '../RigList'

interface Props {
	toggleSidebar: () => void,
	router: any,
}

interface State {
	project_guid: string,
	company_guid: string,
	search: string,
	rigs: any,
	spts: any,
	rigsSelect: any,
	currentRigValue: any,
	currentRigLabel: any,
	currentRigType: any,
	currentRigMakeModel: any,
	currentRigSPTGuid: any,
	currentRigSPTID: any,
	currentRigSTPEnergyRatio: any,
	current_rig_id: string,
    showRigList: boolean,
}

class Rigs extends React.Component<Props, State> {
	constructor(props: any) {
		super(props)

		this.state = {
			project_guid: this.props.router.match.params?.project,
			company_guid: this.props.router.match.params?.company,
			search: '',
			rigs: [],
			spts: [],
			rigsSelect: [],
			currentRigValue: '',
			currentRigLabel: '',
			currentRigType: '',
			currentRigMakeModel: '',
			currentRigSPTGuid: '',
			currentRigSPTID: '',
			currentRigSTPEnergyRatio: '',
			current_rig_id: this.props.router.match.params.id,
			showRigList: false,
		}
	}

	handleSearchUpdate = (value: string) => {
		this.setState({ search: value })

		// console.log(value)
	}

	componentDidMount() {
		// this.getCacheValues()
		this.getSPTdata()
		this.getRIGdata()
	}

	getSPTdata = () => {
		let cachedValues = useGetIDBValues
		// cachedValues(`spt-hammers/company/${this.props.router.match.params?.company}`, (data: any) => {
		cachedValues(`spt-hammers/company/${this.state.company_guid}`, (data: any) => {
			this.setState({
				spts: data
			})
		})
	}

	getRIGdata = () => {
		// const company_guid = this.props.router.match.params?.company;
		const company_guid = this.state.company_guid;
	
		if (!company_guid) {
			console.error('Company ID is undefined');
			// Handle the case where companyId is undefined
			return;
		}
	
		let cachedValues = useGetIDBValues;
	
		cachedValues('rigs/company/' + company_guid, (data: any) => {
			console.log('Company Rig Data:', data);
	
			this.setState({
				rigs: data
			});
	
			let selectValues: any = [];
			if (data) {
				for (const rig of data) {
					let { guid, name, type } = rig;
					const newValue = {
						value: guid,
						label: name,
						type: type
					};
					selectValues.push(newValue);
				}
			}
			// if (data) {
			// 	for (const rig of data) {
			// 		let { guid, name, type, sptID, sptGuid, sptEnergyRatio } = rig;
			// 		const newValue = {
			// 			value: guid,
			// 			label: name,
			// 			type: type,
			// 			sptGuid: sptGuid,
			// 			sptID: sptID,
			// 			sptEnergyRatio: sptEnergyRatio
			// 		};
			// 		selectValues.push(newValue);
			// 	}
			// }
			this.setState({
				rigsSelect: selectValues
			});
			console.log('selectValues:', selectValues)
		}).then()

		// get the current rig if it's set
		// get(`drillit-currentRig-${this.props.router.match.params.project}`)
		get(`drillit-currentRig`)
			.then(data => {
				if (data) {

					this.setState({
						currentRigValue: data.guid,
						currentRigLabel: data.name,
						currentRigType: data.type,
						currentRigSPTGuid: data.sptGuid,
						currentRigSPTID: data.sptID,
						currentRigSTPEnergyRatio: data.sptEnergyRatio,
					})

				}
			})
	}

	formatDate = (date: string) => {
		const formattedDate = new Date(date)
		return formattedDate.toLocaleDateString()
	}

	onCurrentRigSave = () => {
		const cR = {
			guid: this.state.currentRigValue,
			name: this.state.currentRigLabel,
			type: this.state.currentRigType,
			make_model: this.state.currentRigMakeModel,
			sptGuid: this.state.currentRigSPTGuid,
			sptID: this.state.currentRigSPTID,
			sptEnergyRatio: this.state.currentRigSTPEnergyRatio,
		}
		// return set(`drillit-currentRig-${this.props.router.match.params.project}`, cR).then(() => {
		return set(`drillit-currentRig`, cR).then(() => {
			// Additional actions after rig has been saved
			// console.log('Rig saved successfully');
			// Perform any other actions or state updates here
		  })
		  .catch((error) => {
			console.error('Error saving rig:', error);
			// Handle the error if needed
		  });
	}

	// Automatically save when the currentRigValue state changes
	componentDidUpdate(prevState: any) {
	  if (prevState.currentRigValue !== this.state.currentRigValue) {
		this.onCurrentRigSave();
	  }
	}

	

    handleRigListClose = () => {
        this.setState({ showRigList: false });
    };

    handleRigSelect = (selectedRig: any) => {
		console.log('selectedRig************************************:', selectedRig)

		const rigGuid = selectedRig?.guid;
		const rigName = selectedRig?.name;
		const rigType = selectedRig?.type;
		const rigMakeModel = selectedRig?.make_model;
		const sptGuid = selectedRig?.spt_guid;
		const sptLabel = selectedRig?.spt?.id_ref;
		const sptEnergyRatio = selectedRig?.spt?.energy_ratio;

            this.setState(
                { 
                    currentRigValue: rigGuid,
                    currentRigLabel: rigName,
                    currentRigType: rigType,
                    currentRigMakeModel: rigMakeModel,
                    currentRigSPTGuid: sptGuid,
                    currentRigSPTID: sptLabel,
                    currentRigSTPEnergyRatio: sptEnergyRatio,
                 })
                 
    }

	render() {
		const spts = ''
		console.log('Company Rigs:', this.state.rigs)
		console.log('rigsSelect:', this.state.rigsSelect)

	console.log('this.state', this.state)


		return (
			<>
				<Header toggleSidebar={this.props.toggleSidebar} />

				<Modal title="Rigs" toggleModal={() => { this.props.router.history.goBack() }} confirm={false}>

					


                {this.state.showRigList &&

					<RigList
						onClose={this.handleRigListClose}
						onSelectItem={this.handleRigSelect}
						// companyGuid={this.props.router.match.params?.company}
						companyGuid={this.state.company_guid}
					/>
				}


					<div className="border border-grey-mid rounded px-2 pb-1 bg-sptTB mb-2">
						<div className="text-lg py-2 text-center font-bold">Current Rig:</div>
						<div className="flex-auto text-center">

						<div className="w-full md:w-96 rounded border-2 border-blue text-2xl font-bold mx-auto bg-white"
							onClick={() => {
								this.setState({
									showRigList: true,
								})
							}}>
								{this.state.currentRigLabel !== '' ? (
									<div className="text-charcoal">
										{/* Content to render when this.state.currentRigLabel is set */}
										{this.state.currentRigLabel}
									</div>
									) : (
									<div className="text-blue">
										{/* Loading indicator while waiting for this.state.currentRigLabel */}
										Select Rig
										{/* <LoadingIndicator /> */}
									</div>
								)}
							</div>


							{/* <select
								id="currentRig"
								className="form-control text-lg mr-2 font-bold h-10 w-52 md:w-60"
								value={this.state.currentRigValue}
								onChange={(e) => {
									this.setState({
									currentRigValue: e.target.value,
									currentRigLabel: e.target.options[e.target.selectedIndex].textContent,
									currentRigType: e.target.options[e.target.selectedIndex].textContent,
									// currentRigSPTGuid: e.target.options[e.target.selectedIndex].textContent,
									// currentRigSPTID: e.target.options[e.target.selectedIndex].textContent,
									// currentRigSTPEnergyRatio: e.target.options[e.target.selectedIndex].textContent,
									});
									this.onCurrentRigSave(); // Call the function when an option is selected
								}}> */}
								{/* <select
									id="currentRig"
									className="form-control text-lg mr-2 font-bold h-10 w-52 md:w-60"
									value={this.state.currentRigValue}
									onChange={(e) => {
										const selectedIndex = e.target.selectedIndex;
										const selectedRigIndex = selectedIndex - 1;
										const selectedRig = this.state.rigsSelect[selectedRigIndex]; // Access the selected rig object from the rigsSelect array
										this.setState({
											currentRigValue: e.target.value,
											currentRigLabel: selectedRig.label, // Set currentRigLabel to the selected rig's label
											currentRigType: selectedRig.type, // Set currentRigType to the selected rig's type
										});
										this.onCurrentRigSave(); // Call the function when an option is selected
									}}
								> */}
								{/* <option value="null">Select Current Rig</option>
								{this.state.rigsSelect?.map((value: any, index: string) => {
									return <option value={value.value} key={index}>{value.label}</option>
								})}
							</select> */}
							{/* <button className="btn btn-primary text-base font-bold text-center w-32 md:w-40 rounded px-0" onClick={this.onCurrentRigSave}>&lt; Use This Rig</button> */}
						
						</div>
					</div>

					{/* SPT HAMMERS */}
					<div className="border border-grey-mid rounded px-1 pt-1 bg-sptTB mb-4 text-xs sm:text-sm md:text-base">

						<div className="text-center text-lg font-bold mb-2">SPT Hammers</div>

						<table className="table-auto w-full mb-4 text-center">
							<thead className="">
								<tr>
									<td className="px-0.5 py-1 border-r border-grey-mid bg-charcoal text-white rounded-l">
										SPT ID
									</td>
									<td className="px-0.5 py-1 border-r border-grey-mid bg-charcoal text-white null null">
										Rod Type
									</td>
									<td className="px-0.5 py-1 border-r border-grey-mid bg-charcoal text-white null null">
										Energy Ratio
									</td>
									<td className="px-0.5 py-1 bg-charcoal text-white rounded-r">
										Calibration Date
									</td>
									<td className="p-0 pl-1 null w-16 md:w-28">
										{/* <div className="px-0 py-0 null"></div> */}
										{/* <Link to={`/spt/create/${this.props.router.match.params.company}/${this.props.router.match.params.project}`}> */}
										<Link to={`/spt/create/${this.state.company_guid}/${this.state.project_guid}`}>
											<Button theme="primary" className="mx-auto text-xs sm:text-sm md:text-base px-1 md:px-2 w-full font-bold rounded py-3 md:py-1">
												Add SPT
											</Button>
										</Link>
									</td>
								</tr>
							</thead>
							<tbody>
								{Array.isArray(this.state?.spts) && this.state.spts
									.sort((a, b) => a.id_ref.localeCompare(b.id_ref)) // Sort spts by id_ref alphabetically
									.map((spt: any) => {
										return (
											<React.Fragment key={spt?.guid}>
												<tr className="border border-grey-mid bg-white mb-1">
													<td className="p-0 border-r border-grey-mid">
														<div className="px-0.5 py-1">{spt?.id_ref}</div>  {/* id */}
													</td>
													<td className="p-0 border-r border-grey-mid">
														<div className="px-0.5 py-1">{spt?.rod_type}</div>
													</td>
													<td className="p-0 border-r border-grey-mid">
														<div className="px-0.5 py-1">{spt?.energy_ratio}</div>
													</td>
													<td className="p-0 border-r border-grey-mid">
														<div className="px-0.5 py-1">{this.formatDate(spt?.calibration_date)}</div>
													</td>
													<td className="p-0 border-r border-grey-mid text-center w-16 md:w-28">
														<div className="px-0 py-0">
															{/* <a href={`/spt/update/${spt.guid}/${this.props.router.match.params?.company}/${this.props.router.match.params?.project}`}> */}
															{/* <a href={`/spt/update/${spt.guid}/${this.state.company_guid}/${this.state.project_guid}`}>
																<img className="inline" src={edit} width="20" alt="" />
															</a> */}
															
															<Link to={`/spt/update/${spt.guid}/${this.state.company_guid}/${this.state.project_guid}`}>
																<img className="inline" src={edit} width="20" alt="" />
															</Link>
														</div>
													</td>
												</tr>
											</React.Fragment>
										)
									})
								}
							</tbody>
						</table>
					</div>

					{/* RIGS */}
					<div className="border border-grey-mid rounded px-1 pt-1 bg-sptTB mb-4 text-xs sm:text-sm md:text-base">

						<div className="text-center text-lg font-bold mb-2">Rigs</div>

						<table className="table-auto w-full mb-2 text-center">
							<thead className="">
								<tr>
									<td className="px-0.5 py-1 border-r border-t border-grey-mid bg-charcoal text-white rounded-l">
										Name
									</td>
									<td className="px-0.5 py-1 border-r border-t border-grey-mid bg-charcoal text-white null null">
										Plant Type
									</td>
									<td className="px-0.5 py-1 border-r border-t border-grey-mid bg-charcoal text-white null null">
										Plant ID
									</td>
									<td className="px-0.5 py-1 border-r border-t border-grey-mid bg-charcoal text-white null null">
										Test Expiry
									</td>
									<td className="px-0.5 py-1 border-t border-grey-mid bg-charcoal text-white rounded-r">
										SPT ID
									</td>
									<td className="p-0 pl-1 null w-16 md:w-28">
										{/* <div className="px-0 py-0 null"></div> */}
										{/* <Link to={`/rigs/create/${this.props.router.match.params?.company}/${this.props.router.match.params?.project}`}> */}
										<Link to={`/rigs/create/${this.state.company_guid}/${this.state.project_guid}`}>
											<Button theme="primary" className="mx-auto text-xs sm:text-sm md:text-base px-1 md:px-2 w-full font-bold rounded">
												Add Rig
											</Button>
										</Link>
									</td>
								</tr>
							</thead>
							<tbody>
								{/* {this.state.rigs?.map((rig: any) => {
									return (
										<React.Fragment key={rig?.guid}>
											<tr className="border border-grey-mid bg-white mb-1">
												<td className="p-0 border-r border-grey-mid">
													<div className="px-0.5 py-1">{rig?.name}</div>
												</td>
												<td className="p-0 border-r border-grey-mid">
													<div className="px-0.5 py-1">{rig?.type}</div>
												</td>
												<td className="p-0 border-r border-grey-mid">
													<div className="px-0.5 py-1">{rig?.plant_id}</div>
												</td>
												<td className="p-0 border-r border-grey-mid">
													<div className="px-0.5 py-1">{this.formatDate(rig?.test_date_expiry)}</div>
												</td>
												<td className="p-0 border-r border-grey-mid">
													<div className="px-0.5 py-1">{rig.spt?.id_ref}</div>
												</td>
												<td className="p-0 border-r border-grey-mid text-center w-16 md:w-28">
													<div className="px-0 py-0"><a
														href={`/rigs/update/${rig.guid}/${this.props.router.match.params.company}/${this.props.router.match.params.project}`}><img
															className="inline" src={edit} width="20" alt="" /></a>
													</div>
												</td>
											</tr>
										</React.Fragment>
									)
								})} */}
								{this.state.rigs
									?.sort((a:any, b:any) => a.name.localeCompare(b.name)) // Sort rigs by name alphabetically
									.map((rig: any) => {
										return (
											<React.Fragment key={rig?.guid}>
												<tr className="border border-grey-mid bg-white mb-1">
													<td className="p-0 border-r border-grey-mid">
														<div className="px-0.5 py-1">{rig?.name}</div>
													</td>
													<td className="p-0 border-r border-grey-mid">
														<div className="px-0.5 py-1">{rig?.type}</div>
													</td>
													<td className="p-0 border-r border-grey-mid">
														<div className="px-0.5 py-1">{rig?.plant_id}</div>
													</td>
													<td className="p-0 border-r border-grey-mid">
														<div className="px-0.5 py-1">{this.formatDate(rig?.test_date_expiry)}</div>
													</td>
													<td className="p-0 border-r border-grey-mid">
														<div className="px-0.5 py-1">{rig.spt?.id_ref}</div>
													</td>
													<td className="p-0 border-r border-grey-mid text-center w-16 md:w-28">
														<div className="px-0 py-0">
															{/* <a href={`/rigs/update/${rig.guid}/${this.props.router.match.params.company}/${this.props.router.match.params.project}`}> */}
															{/* <a href={`/rigs/update/${rig.guid}/${this.state.company_guid}/${this.state.project_guid}`}>
																<img className="inline" src={edit} width="20" alt="" />
															</a> */}
															
															<Link to={`/rigs/update/${rig.guid}/${this.state.company_guid}/${this.state.project_guid}`}>
																<img className="inline" src={edit} width="20" alt="" />
															</Link>
														</div>
													</td>
												</tr>
											</React.Fragment>
										);
									})
								}


							</tbody>
						</table>
					</div>

					<div className="border border-grey-mid rounded px-1 pt-1 bg-sptTB mb-2 text-xs sm:text-sm md:text-base">

						<div className="text-center text-lg font-bold">Plant Checks</div>

						<div className="grid grid-cols-1 md:grid-cols-3">

							{/* <div className="flex items-center px-4 my-1">
								<Link className="btn btn-primary mx-auto w-full text-center py-2 rounded" to={`/daily-plant-check-cable-percussive/${this.props.router.match.params.company}/${this.props.router.match.params.project}`}>Daily Cable Plant Check </Link>
							</div>

							<div className="flex items-center px-4 my-1">
								<Link className="btn btn-primary mx-auto w-full text-center py-2 rounded" to={`/daily-plant-check-rotary/${this.props.router.match.params.company}/${this.props.router.match.params.project}`}>Daily Rotary Plant Check</Link>
							</div>

							<div className="flex items-center px-4 my-1">
								<Link className="btn btn-primary mx-auto w-full text-center py-2 rounded" to={`/daily-plant-check-window-sample/${this.props.router.match.params.company}/${this.props.router.match.params.project}`}>Daily Window Sample Plant Check</Link>
							</div>

							<div className="col-span-1 md:col-span-3 flex items-center px-4 mt-3 mb-4">
								<Link className="btn btn-orange mx-auto w-full text-center py-2 rounded" to={`/daily-plant-check-list/${this.props.router.match.params.company}/${this.props.router.match.params.project}`}>View Plant Checks for Current Rig</Link>
							</div> */}

							<div className="flex items-center px-4 mt-3 mb-4">
								<Link className="btn btn-orange mx-auto w-full text-center py-2 rounded" 
								to={`/daily-plant-check-list-cable-percussive/${this.props.router.match.params?.company}/${this.props.router.match.params?.project}`}
								>
									Daily Cable Percussive Plant Check
								</Link>
							</div>

							<div className="flex items-center px-4 mt-3 mb-4">
								<Link className="btn btn-orange mx-auto w-full text-center py-2 rounded" 
								to={`/daily-plant-check-list-rotary/${this.props.router.match.params?.company}/${this.props.router.match.params?.project}`}
								>
									Daily Rotary Plant Check
								</Link>
							</div>

							<div className="flex items-center px-4 mt-3 mb-4">
								<Link className="btn btn-orange mx-auto w-full text-center py-2 rounded" 
								to={`/daily-plant-check-list-window-sample/${this.props.router.match.params?.company}/${this.props.router.match.params?.project}`}
								>
									Daily Window Sample Plant Check
								</Link>
							</div>

						</div>

					</div>

				</Modal>
			</>
		)
	}
}

export default Rigs
