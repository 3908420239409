import { set } from 'idb-keyval'

export const setIDB = (newData: object, idbKey: string, oldData?: any) => {
    let newArr = [] as any
    if (oldData) { newArr = oldData }
    newArr.push(newData)
    set(idbKey, newArr)
        .then(result => result)
        .catch(error => error)
}
