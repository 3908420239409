import React, { FunctionComponent, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import WorkflowSteps from '../../WorkflowSteps'
import InputGroup from '../../form/InputGroup'
import ColorType from './soil-description/ColorType'
import ConsistencyTypes from './soil-description/ConsistencyTypes'
import ExclusiveSelect from './soil-description/ExclusiveSelect'
import RangeSelect from './soil-description/RangeSelect'
import MultiSelect from './soil-description/MultiSelect'
import Comment from './soil-description/Comment'

import stepStatus from '../../../helpers/stepStatus'
import rangeDescription from '../../../helpers/rangeDescription'

import { editSoilDescriptionLength } from '../../../actions/SoilDescriptionLength'

type Props = {
	startingStep: number,
	step: number,
	onChange: any,
	[x: string]: any;
}

const defaultProps: Props = {
	startingStep: 1,
	step: 1,
	onChange: () => {},
}

const soilTypes = [
	'',
	'Topsoil',
	'Sand',
	'Sand & Gravel',
	'Gravel',
	'Clay',
	'Silt',
	'Peat',
	'Chalk (SILT)',
	'Chalk (GRAVEL)',
	'Chalk (ROCK)',
	'Rock',
]

const workflowSteps = (currentStep: number, workflows: any) => {
	let steps = []
	let start = 4

	steps.push({
		label: 'Soil Type',
		status: stepStatus(3, currentStep),
	})

	for(const step of workflows) {
		if(step.show) {
			steps.push({
				label: step.tab,
				status: stepStatus(start++, currentStep),
			})
		}
	}

	return steps
}

const workflowComponents = (currentStep: number, workflows: any) => {
	let steps = []
	let start = 0

	for(const step of workflows) {
		if(step.show) {
			steps.push(step.component)
		}
	}

	return steps[currentStep - 4]
}

const workflowLength = (workflows: any) => {
	let steps = 0

	for(const step of workflows) {
		if(step.show)
			steps++
	}

	return steps + 3
}

const SoilDescriptionGenerator: FunctionComponent<Props> = ({ startingStep, step, onChange, ...rest }) => {
	const dispatch = useDispatch()
	// const [currentStep, setCurrentStep] = useState(1)

	const setSoilDescriptionLength = (length: number) => {
		dispatch(editSoilDescriptionLength(length))
	}

	const currentStep = step
	const [soilType, setSoilType] = useState('')
	const [madeground, setMadeground] = useState(false)
	const [consistencyType, setConsistencyType] = useState<any[]>([])
	const [colorType, setColorType] = useState([])
	const [chalkGravelMatrixColor, setChalkGravelMatrixColor] = useState([])
	const [primaryConstituent, setPrimaryConstituent] = useState('')
	const [texture, setTexture] = useState<any[]>([])
	const [moisture, setMoisture] = useState<any[]>([])
	const [friability, setFriability] = useState('')
	const [makeup, setMakeup] = useState('')
	const [roots, setRoots] = useState('')
	const [secondaryCompositionClayey, setSecondaryCompositionClayey] = useState('')
	const [secondaryCompositionSilty, setSecondaryCompositionSilty] = useState('')
	const [secondaryCompositionSandy, setSecondaryCompositionSandy] = useState('')
	const [secondaryCompositionGravelly, setSecondaryCompositionGravelly] = useState('')

	const [minorConstituentsCobbleContent, setMinorConstituentsCobbleContent] = useState([])
	const [minorConstituentsMinorFrequency, setMinorConstituentsMinorFrequency] = useState('')
	const [minorConstituentsMinorItems, setMinorConstituentsMinorItems] = useState<any[]>([])
	const [minorConstituentsMinorItemsKeyboard, setMinorConstituentsMinorItemsKeyboard] = useState('')

	const [minorConstituentsChalkFlints, setMinorConstituentsChalkFlints] = useState<any[]>([])
	const [minorConstituentsChalkMinorFrequency, setMinorConstituentsChalkMinorFrequency] = useState('')
	const [minorConstituentsChalkMinorItems, setMinorConstituentsChalkMinorItems] = useState('')
	const [minorConstituentsChalkMinorItemsKeyboard, setMinorConstituentsChalkMinorItemsKeyboard] = useState('')

	const [clasts, setClasts] = useState<any[]>([])
	const [sandGrading, setSandGrading] = useState<any[]>([])
	const [gravelSize, setGravelSize] = useState<any[]>([])
	const [gravelAngularity, setGravelAngularity] = useState<any[]>([])
	const [gravelForm, setGravelForm] = useState<any[]>([])
	const [gravelComposition, setGravelComposition] = useState<any[]>([])
	const [gravelCompositionKeyboard, setGravelCompositionKeyboard] = useState('')
	const [relativeDensity, setRelativeDensity] = useState<any[]>([])
	const [peatConsistency, setPeatConsistency] = useState<any[]>([])
	const [peatDescription, setPeatDescription] = useState<any[]>([])
	const [peatSmell, setPeatSmell] = useState<any[]>([])
	const [chalkGravelComposition, setChalkGravelComposition] = useState('')
	const [chalkRockDensity, setChalkRockDensity] = useState<any[]>([])
	const [staining, setStaining] = useState('')
	const [infillAperture, setInfillAperture] = useState('')
	const [infillMin, setInfillMin] = useState('')
	const [infillTypical, setInfillTypical] = useState('')
	const [infillMax, setInfillMax] = useState('')

	const [rockType, setRockType] = useState<any>('')
	const [compressiveStrength, setCompressiveStrength] = useState<any[]>([])
	const [madegroundComposition, setMadegroundComposition] = useState<any[]>([])
	const [madegroundMaterials, setMadegroundMaterials] = useState<any[]>([])

	const [fractureSpacing, setFractureSpacing] = useState<any[]>([])
	const [fractureMin, setFractureMin] = useState('')
	const [fractureTypical, setFractureTypical] = useState('')
	const [fractureMax, setFractureMax] = useState('')

	const [comment, setComment] = useState('')

	useEffect(() => {
		onChange(generateDescription)
	})
	const resetState = () => {
		setMadeground(false)
		setConsistencyType([])
		setColorType([])
		setChalkGravelMatrixColor([])
		setPrimaryConstituent('')
		setTexture([])
		setMoisture([])
		setFriability('')
		setMakeup('')
		setRoots('')
		setSecondaryCompositionClayey('')
		setSecondaryCompositionSilty('')
		setSecondaryCompositionSandy('')
		setSecondaryCompositionGravelly('')

		setMinorConstituentsCobbleContent([])
		setMinorConstituentsMinorFrequency('')
		setMinorConstituentsMinorItems([])

		setMinorConstituentsChalkFlints([])
		setMinorConstituentsChalkMinorFrequency('')
		setMinorConstituentsChalkMinorItems('')
		setMinorConstituentsChalkMinorItemsKeyboard('')

		setClasts([])
		setSandGrading([])
		setGravelSize([])
		setGravelAngularity([])
		setGravelForm([])
		setGravelComposition([])
		setRelativeDensity([])
		setPeatConsistency([])
		setPeatDescription([])
		setPeatSmell([])
		setChalkGravelComposition('')
		setChalkRockDensity([])
		setStaining('')
		setInfillAperture('')
		setInfillMin('')
		setInfillTypical('')
		setInfillMax('')

		setRockType('')
		setCompressiveStrength([])
		setMadegroundComposition([])
		setMadegroundMaterials([])

		setFractureSpacing([])
		setFractureMin('')
		setFractureTypical('')
		setFractureMax('')
	}

	const changeSoilType = (soilType: string) => {
		resetState()

		setSoilType(soilType)
	}

	const capitalizeFirstLetter = (sentence: string) => {
		return sentence.charAt(0).toUpperCase() + sentence.slice(1)
	}

	const cleanSentence = (sentence: string) => {
		return sentence.replace(/\ +/g, ' ').replace(/\ \.+/g, '.').replace(/\.+/g, '.')
	}

	const getSubDescription = (gravelText: string, minorText: string) => {
		if(secondaryCompositionGravelly === '' && minorText === ' ' && minorConstituentsCobbleContent.join(' ') === '')
			return '.'

		if(secondaryCompositionGravelly !== '' && minorText === ' ' && minorConstituentsCobbleContent.join(' ') === '')
			return gravelText

		if(secondaryCompositionGravelly === '' && minorText === ' ' && minorConstituentsCobbleContent.join(' ') !== '')
			return ' with ' + rangeDescription(minorConstituentsCobbleContent) + ' cobble content'

		if(secondaryCompositionGravelly === '' && minorText !== ' ' && minorConstituentsCobbleContent.join(' ') === '')
			return ' with ' + minorText

		if(secondaryCompositionGravelly !== '' && minorText === ' ' && minorConstituentsCobbleContent.join(' ') !== '')
			return gravelText + ' with ' + rangeDescription(minorConstituentsCobbleContent) + ' cobble content'

		if(secondaryCompositionGravelly !== '' && minorText !== ' ' && minorConstituentsCobbleContent.join(' ') === '')
			return gravelText + ' with ' + minorText

		if(secondaryCompositionGravelly === '' && minorText !== ' ' && minorConstituentsCobbleContent.join(' ') !== '')
			return ' with ' + rangeDescription(minorConstituentsCobbleContent) + ' cobble content and ' + minorText

		if(secondaryCompositionGravelly !== '' && minorText !== ' ' && minorConstituentsCobbleContent.join(' ') !== '')
			return gravelText + ' with ' + rangeDescription(minorConstituentsCobbleContent) + ' cobble content and ' + minorText
			
	}

	const chalkRockGrade = (fractureStr: string, infillStr: string) => {
		const fracture = parseInt(fractureStr ? fractureStr : '0')
		const infill = parseInt(infillStr)

		let fractureGrade = ''
		let infillGrade = ''

		if(fracture < 20) {
			fractureGrade = '5'
		} else if(fracture < 60) {
			fractureGrade = '4'
		} else if(fracture < 200) {
			fractureGrade = '3'
		} else if(fracture < 600) {
			fractureGrade = '2'
		} else {
			fractureGrade = '1'
		}

		// if(infill <= 0) { 
		// 	infillGrade = 'A'
		// } else if (infill < 3) {
		// 	infillGrade = 'B'
		// } else {
		// 	infillGrade = 'C'
		// }

		if(infill <= 0) { 
			infillGrade = 'A'
		} else if (infill < 3) {
			infillGrade = 'B'
		} else {
			infillGrade = 'C'
		}

		return infillGrade + fractureGrade
	}

	const generateDescription = () => {
		let beforeMadeground = ''

		let gravelCompositionArray = gravelComposition.includes('other (keyboard)') ? gravelComposition.concat(gravelCompositionKeyboard) : gravelComposition
		let gravelCompositionFull = gravelCompositionArray.filter((val => val !== 'other (keyboard)')).filter((val => val)).join(', ')
		let gravelText = [rangeDescription(gravelSize), rangeDescription(gravelForm), rangeDescription(gravelAngularity), gravelCompositionFull].filter((val => val)).join(' ')
		let gravelTextFull = (gravelText ? '. Gravel is composed of ' + gravelText + ' ' : '')
		let sandText = (sandGrading ? 'Sand is ' + rangeDescription(sandGrading) + ' ' : '')
		let sandTextGRAVEL = (rangeDescription(sandGrading) ? '. Sand is ' + rangeDescription(sandGrading) + ' ' : '')
		let sandTextCLAY = (rangeDescription(sandGrading) ? '. Sand is ' + rangeDescription(sandGrading) + ' ' : '')
		let rootsText = (roots ? ' with ' + roots : '')
		let secondaryComposition = [secondaryCompositionClayey, secondaryCompositionSilty, secondaryCompositionSandy, secondaryCompositionGravelly].filter((val => val)).join(' ')
		let minorText = [
			minorConstituentsMinorFrequency,
			minorConstituentsMinorItems
				.concat(minorConstituentsMinorItemsKeyboard)
				.filter((val => val))
				.filter((val => val !== 'other (keyboard)'))
				.join(', ')
		].join(' ')

		// let minorItems = minorConstituentsMinorItems
		// 	.concat(minorConstituentsMinorItemsKeyboard)
		// 	.filter(val => val)
		// 	.filter(val => val !== 'other (keyboard)');

		// let minorText = '';
		// if (minorItems.length === 1) {
		// 	minorText = `${minorConstituentsMinorFrequency} ${minorItems[0]}`;
		// } else if (minorItems.length > 1) {
		// 	let lastItem = minorItems.pop();
		// 	minorText = `${minorConstituentsMinorFrequency} ${minorItems.join(', ')} and ${lastItem}`;
		// } else {
		// 	minorText = minorConstituentsMinorFrequency;
		// }

		let subDescription = getSubDescription(gravelTextFull, minorText)
		
		console.log('gravelText:', gravelText)
		console.log('sandText:', sandText)

		console.log('secondaryCompositionGravelly:', secondaryCompositionGravelly)
		console.log('minorText:', minorText)
		console.log('minorConstituentsCobbleContent:', minorConstituentsCobbleContent)
		console.log('subDescription:', subDescription)

		/// TOPSOIL
		if(soilType === 'Topsoil') {
			beforeMadeground = [
				rangeDescription(consistencyType),
				colorType.join(' ').toLowerCase(),
				secondaryComposition,
				rangeDescription(texture),
				rangeDescription(moisture),
				friability,
				makeup,
				secondaryCompositionSandy,
				primaryConstituent,
				rootsText,
			].filter((val => val)).join(' ')

			// console.log(soilType)

			let betweenBrackets = beforeMadeground +
				(secondaryCompositionSandy ? '. ' + sandText : '') +
				(secondaryCompositionGravelly ? gravelTextFull : '')

			beforeMadeground = soilType.toUpperCase() +
				(betweenBrackets ? ' (' + betweenBrackets.trim() + ')' : '')
		}

		/// SAND
		if(soilType === 'Sand') {
			beforeMadeground = [
				rangeDescription(relativeDensity),
				colorType.join(' ').toLowerCase(),
				secondaryComposition,
				rangeDescription(sandGrading),
				soilType.toUpperCase(),
			].filter((val => val)).join(' ')

			beforeMadeground = beforeMadeground + subDescription
		}

		/// SAND & GRAVEL
		if(soilType === 'Sand & Gravel') {
			beforeMadeground = [
				rangeDescription(relativeDensity),
				colorType.join(' ').toLowerCase(),
				secondaryComposition,
				rangeDescription(sandGrading),
				'SANDGRAVEL',
				gravelTextFull,
			].filter((val => val)).join(' ')

			beforeMadeground = beforeMadeground + subDescription
		}

		/// GRAVEL
		if(soilType === 'Gravel') {
			gravelTextFull =  gravelText ? ' composed of ' + gravelText + ' ' : ''

			beforeMadeground = [
				rangeDescription(relativeDensity),
				colorType.join(' ').toLowerCase(),
				secondaryComposition,
				soilType.toUpperCase(),
				gravelTextFull,
				// sandTextGRAVEL,
			].filter((val => val)).join(' ')

			beforeMadeground = beforeMadeground + subDescription + sandTextGRAVEL
		}

		/// CLAY
		if(soilType === 'Clay') {

			beforeMadeground = [
				rangeDescription(consistencyType),
				colorType.join(' ').toLowerCase(),
				secondaryComposition,
				soilType.toUpperCase(),
				sandTextCLAY,
				// gravelText,
			].filter((val => val)).join(' ')

			beforeMadeground = beforeMadeground + subDescription
		}

		/// SILT
		if(soilType === 'Silt') {
			beforeMadeground = [
				rangeDescription(consistencyType),
				colorType.join(' ').toLowerCase(),
				secondaryComposition,
				soilType.toUpperCase(),
				// gravelText,
			].filter((val => val)).join(' ')

			beforeMadeground = beforeMadeground + subDescription
		}

		/// PEAT
		if(soilType === 'Peat') {
			let peatOdour = (peatSmell.length ? ' with a ' + rangeDescription(peatSmell) + ' odour' : '')

			beforeMadeground = [
				rangeDescription(peatConsistency),
				colorType.join(' ').toLowerCase(),
				rangeDescription(peatDescription),
				secondaryComposition,
				soilType.toUpperCase(),
				peatOdour,
			].filter((val => val)).join(' ')

			beforeMadeground = beforeMadeground + subDescription
		}

		/// CHALK SILT
		if(soilType === 'Chalk (SILT)') {
			const s1 = [
				'Structureless CHALK composed of',
				colorType.join(' ').toLowerCase(),
				secondaryComposition,
				'SILT',
			].filter((val => val)).join(' ')

			const s2 = [
				(clasts.length ? 'Gravel is ' + rangeDescription(clasts) : '') + (gravelText ? ',' : ''),
				chalkGravelMatrixColor.join(' ').toLowerCase(),
				gravelText,
				minorConstituentsChalkMinorFrequency + ' ' + (minorConstituentsChalkMinorItems === 'other (keyboard)' ? minorConstituentsChalkMinorItemsKeyboard : minorConstituentsChalkMinorItems),
			].filter((val => val)).join(' ')

			beforeMadeground = s1 + '. ' + s2 + '. ' +
				(minorConstituentsChalkFlints.length ? capitalizeFirstLetter(rangeDescription(minorConstituentsChalkFlints) + ' flint content. ') : '')
		}

		/// CHALK GRAVEL
		if(soilType === 'Chalk (GRAVEL)') {
			const s1 = [
				'Structureless CHALK composed of',
				secondaryComposition,
				gravelText,
				chalkGravelComposition,
			].filter((val => val)).join(' ')

			const s2 = [
				clasts.length ? 'Clasts are ' + colorType.join(' ').toLowerCase() + ' ' + rangeDescription(clasts) : '',
				minorConstituentsChalkMinorFrequency + ' ' + (minorConstituentsChalkMinorItems === 'other (keyboard)' ? minorConstituentsChalkMinorItemsKeyboard : minorConstituentsChalkMinorItems),
			].filter((val => val)).join(' ')

			beforeMadeground = s1 + '. ' + s2 + '. ' +
				(chalkGravelMatrixColor.length ? 'Matrix is ' + chalkGravelMatrixColor.join(' ') + '. ' : '') +
				(minorConstituentsChalkFlints.length ? capitalizeFirstLetter(rangeDescription(minorConstituentsChalkFlints) + ' flint content. ') : '')
		}

		/// CHALK ROCK
		if(soilType === 'Chalk (ROCK)') {
			beforeMadeground = [
				compressiveStrength.length ? rangeDescription(compressiveStrength) + ',' : '',
				// chalkRockDensity,
				chalkRockDensity.length ? rangeDescription(chalkRockDensity) + ',' : '',
				colorType.join(' ').toLowerCase(),
				'CHALK',
				minorConstituentsChalkMinorFrequency + ' ' + (minorConstituentsChalkMinorItems === 'other (keyboard)' ? minorConstituentsChalkMinorItemsKeyboard : minorConstituentsChalkMinorItems),
			].filter((val => val)).join(' ')

			beforeMadeground = beforeMadeground + '. ' +
				(fractureSpacing.length ? 'Fractures are ' + rangeDescription(fractureSpacing) + ' spaced (' + [fractureMin, fractureTypical, fractureMax].filter((val => val)).join('/') + '), ' : '') +
				(infillAperture ? infillAperture + ' (' + [infillMin, infillTypical, infillMax].filter((val => val)).join('/') + ')' : '') +
				(staining ? (staining == 'unstained' ? ' unstained' : ' with ' + staining) + '. ' : '') +
				(minorConstituentsChalkFlints.length ? capitalizeFirstLetter(rangeDescription(minorConstituentsChalkFlints) + ' flint content. ') : '') +
				((fractureTypical || infillTypical) ? '(Grade ' + chalkRockGrade(fractureTypical, infillTypical) +')' : '')
		}

		/// ROCK
		if(soilType === 'Rock') {
			beforeMadeground = [
				rangeDescription(compressiveStrength),
				colorType.join(' ').toLowerCase(),
				rockType.toUpperCase(),
			].filter((val => val)).join(' ')
		}

		// Add comment to end
		beforeMadeground = beforeMadeground + (comment ? '. ' + capitalizeFirstLetter(comment) + '.' : '. ')

		if(soilType === 'Chalk (SILT)')
			beforeMadeground = beforeMadeground + ' (Grade Dm).'

		if(soilType === 'Chalk (GRAVEL)')
			beforeMadeground = beforeMadeground + ' (Grade Dc).'

		beforeMadeground = cleanSentence(capitalizeFirstLetter(beforeMadeground))

		if(!madeground)
			return beforeMadeground

		const madegroundDescription = madegroundMaterials.join(', ')

		return 'MADEGROUND (' + beforeMadeground + (madegroundDescription !== '' ? ' with ' + madegroundDescription : '') + ')'
	}

	const currentWorkflow = () => {
		const workflows: any = {
			'': [],
			'Topsoil': [
				{
					tab: 'Consistency',
					component: 
					<ConsistencyTypes consistencyType={consistencyType} setConsistencyType={setConsistencyType} />,
					show: true,
					// <>
					// <RangeSelect title="Consistancy" selectedValues={consistencyType} setSelectedValue={setConsistencyType} values={[
					// 	{title: 'very soft', order: 1},
					// 	{title: 'soft', order: 2},
					// 	{title: 'firm', order: 3},
					// 	{title: 'stiff', order: 4},
					// 	{title: 'very stiff', order: 5},
					// 	{title: 'hard', order: 6},
					// ]}
					// columns={3}
					// columnsMobile={1}
					// className="text-sm sm:text-lg mx-1 py-0 px-0"
					// />
					// show: true,
					
					// </>
				}, {
					tab: 'Content',
					component: <>
					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Primary constituent" selectedValue={primaryConstituent} setSelectedValue={setPrimaryConstituent} values={[
							'CLAY',
							'SILT',
							'SAND',
							'PEAT',
						]} 
						columns={4}
						columnsMobile={4}
						className="text-base sm:text-lg mx-1 py-2 sm:py-4 md:py-2 px-0"
						 />
					</div>

					<div className="grid grid-cols-2 gap-2">

						<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
							<RangeSelect title="Texture" selectedValues={texture} setSelectedValue={setTexture} values={[
								{title: 'fine', order: 1},
								{title: 'medium', order: 2},
								{title: 'coarse', order: 3},
							]}
							columns={3}
							columnsMobile={1}
							className="text-sm sm:text-lg mx-1 py-0 px-0"
							/>
						</div>

						<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
							<RangeSelect title="Moisture" selectedValues={moisture} setSelectedValue={setMoisture} values={[
								{title: 'dry', order: 1},
								{title: 'slightly moist', order: 2},
								{title: 'damp', order: 3},
							]}
							columns={3}
							columnsMobile={1}
							className="text-sm sm:text-lg mx-1 py-0 px-0"
							/>
						</div>

					</div>

					<div className="grid grid-cols-3 gap-2">

						<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
							<ExclusiveSelect title="Friability" selectedValue={friability} setSelectedValue={setFriability} values={[
								'friable',
								'indurate',
							]}
							columns={1}
							columnsMobile={1}
							className="text-sm sm:text-lg mx-1 py-0 px-0"
							/>
						</div>

						<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
							<ExclusiveSelect title="Makeup" selectedValue={makeup} setSelectedValue={setMakeup} values={[
								'organic',
								'chalky',
							]}
							columns={1}
							columnsMobile={1}
							className="text-sm sm:text-lg mx-1 py-0 px-0"
							/>
						</div>
						<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
							<ExclusiveSelect title="Roots" selectedValue={roots} setSelectedValue={setRoots} values={[
								'ocassional roots',
								'many roots',
							]}
							columns={1}
							columnsMobile={1}
							className="text-xs sm:text-lg mx-1 py-0 px-0"
							/>
						</div>
					</div>
					</>,
					show: true
				}, {
					tab: 'Colour',
					component: <ColorType colorType={colorType} setColorType={setColorType} />,
					show: true
				}, {
					tab: 'Secondary',
					component: <>
						{primaryConstituent !== 'CLAY' &&
							<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
								<ExclusiveSelect title="Clayey" selectedValue={secondaryCompositionClayey} setSelectedValue={setSecondaryCompositionClayey} values={[
									'slightly clayey',
									'clayey',
									'very clayey',
								]}
								columns={3}
								columnsMobile={3}
								className="text-xs sm:text-lg mx-1 py-0 sm:py-3 md:py-2 px-0"
								/>
							</div>
						}

						{primaryConstituent !== 'SILT' &&
							<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
							<ExclusiveSelect title="Silty" selectedValue={secondaryCompositionSilty} setSelectedValue={setSecondaryCompositionSilty} values={[
								'slightly silty',
								'silty',
								'very silty',
							]}
							columns={3}
							columnsMobile={3}
							className="text-xs sm:text-lg mx-1 py-0 sm:py-3 md:py-2 px-0"
							/>
							</div>
						}

						{primaryConstituent !== 'SAND' &&
							<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
							<ExclusiveSelect title="Sandy" selectedValue={secondaryCompositionSandy} setSelectedValue={setSecondaryCompositionSandy} values={[
								'slightly sandy',
								'sandy',
								'very sandy',
							]}
							columns={3}
							columnsMobile={3}
							className="text-xs sm:text-lg mx-1 py-0 sm:py-3 md:py-2 px-0"
							/>
							</div>
						}

						<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
							<ExclusiveSelect title="Gravelly" selectedValue={secondaryCompositionGravelly} setSelectedValue={setSecondaryCompositionGravelly} values={[
								'slightly gravelly',
								'gravelly',
								'very gravelly',
							]}
							columns={3}
							columnsMobile={3}
							className="text-xs sm:text-lg mx-1 py-0 sm:py-3 md:py-2 px-0"
							/>
						</div>
					</>,
					show: true
				}, {
					tab: 'Sand',
					component: <>
					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<RangeSelect title="Sand Grading" selectedValues={sandGrading} setSelectedValue={setSandGrading} values={[
							{title: 'fine', order: 1},
							{title: 'medium', order: 2},
							{title: 'coarse', order: 3},
						]}
						columns={3}
						columnsMobile={1}
						className="text-xs sm:text-lg mx-1 py-0 px-0"
						/>
						</div>
					</>,
					show: (secondaryCompositionSandy !== '')
				}, {
					tab: 'Gravel',
					component: <>

					<div className="grid grid-cols-2 gap-2">
					
						<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
							<RangeSelect title="Size" selectedValues={gravelSize} setSelectedValue={setGravelSize} values={[
								{title: 'fine', order: 1},
								{title: 'medium', order: 2},
								{title: 'coarse', order: 3},
							]}
							columns={1}
							columnsMobile={1}
							className="text-xs sm:text-lg mx-1 py-0 px-0"
							/>
						</div>

						<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
							<RangeSelect title="Form" selectedValues={gravelForm} setSelectedValue={setGravelForm} values={[
								{title: 'cubic', order: 1},
								{title: 'flat', order: 2},
								{title: 'elongated', order: 3},
							]}
							columns={1}
							columnsMobile={1}
							className="text-xs sm:text-lg mx-1 py-0 px-0"
							/>
						</div>

					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<RangeSelect title="Angularity" selectedValues={gravelAngularity} setSelectedValue={setGravelAngularity} values={[
							{title: 'very angular', order: 1},
							{title: 'angular', order: 2},
							{title: 'sub-angular', order: 3},
							{title: 'sub-rounded', order: 4},
							{title: 'rounded', order: 5},
							{title: 'well rounded', order: 6},
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-0 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<MultiSelect title="Composition" selectedValues={gravelComposition} setSelectedValue={setGravelComposition} values={[
							'flint',
							'chalk',
							'chert',
							'claystone',
							'granite',
							'limestone',
							'sandstone',
							'siltstone',
							'ironstone',
							'quartz',
							'various lithiology',
							'other (keyboard)',
						]}
						columns={6}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-0 px-0"
						/>
					</div>

						{gravelComposition.includes('other (keyboard)') && (
							<InputGroup label={''} onChange={(value) => setGravelCompositionKeyboard(value)} value={gravelCompositionKeyboard} placeholder='Enter gravel composition here' />
						)}
					</>,
					show: (secondaryCompositionGravelly !== '')
				}, {
					tab: 'Comments',
					component: <Comment comment={comment} numberOfRows={5} setComment={setComment} title="Comments" />,
					show: true
				},
			],





			'Sand': [
				{
					tab: 'Density',
					component: <>
						<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
							<RangeSelect title={<><div className="mb-2">Relative Density<br /><span className="text-xs">Select multiple criteria to create range</span></div></>} selectedValues={relativeDensity} setSelectedValue={setRelativeDensity} values={[
								{title: 'very loose', order: 1},
								{title: 'loose', order: 2},
								{title: 'medium dense', order: 3},
								{title: 'dense', order: 4},
								{title: 'very dense', order: 5},
							]}
							columns={3}
							columnsMobile={2}
							className="text-xs sm:text-lg mx-1 py-1 sm:py-3 md:py-2 px-0"
							/>
						</div>
					</>,
					show: true,
				}, {
					tab: 'Colour',
					component: <ColorType colorType={colorType} setColorType={setColorType} />,
					show: true
				}, {
					tab: 'Size/Grading',
					component: <>
						<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
							<RangeSelect title={<><div className="mb-2">Sand Grading<br /><span className="text-xs">Select multiple criteria to create range</span></div></>} selectedValues={sandGrading} setSelectedValue={setSandGrading} values={[
								{title: 'fine', order: 1},
								{title: 'medium', order: 2},
								{title: 'coarse', order: 3},
							]}
							columns={3}
							columnsMobile={3}
							className="text-xs sm:text-lg mx-1 py-1 sm:py-3 md:py-2 px-0"
							/>
						</div>
					</>,
					show: true
				}, {
					tab: 'Secondary',
					component: <>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Clayey" selectedValue={secondaryCompositionClayey} setSelectedValue={setSecondaryCompositionClayey} values={[
							'slightly clayey',
							'clayey',
							'very clayey',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-2 md:py-2 px-0 "
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Silty" selectedValue={secondaryCompositionSilty} setSelectedValue={setSecondaryCompositionSilty} values={[
							'slightly silty',
							'silty',
							'very silty',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-2 md:py-2 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Gravelly" selectedValue={secondaryCompositionGravelly} setSelectedValue={setSecondaryCompositionGravelly} values={[
							'slightly gravelly',
							'gravelly',
							'very gravelly',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-2 md:py-2 px-0"
						/>
					</div>
					</>,
					show: true
				}, {
					tab: 'Gravel',
					component: <>

					<div className="grid grid-cols-2 gap-2">
					
						<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
							<RangeSelect title="Size" selectedValues={gravelSize} setSelectedValue={setGravelSize} values={[
								{title: 'fine', order: 1},
								{title: 'medium', order: 2},
								{title: 'coarse', order: 3},
							]}
							columns={1}
							columnsMobile={1}
							className="text-xs sm:text-lg mx-1 py-1 sm:py-1 md:py-0 px-0"
							/>
						</div>

						<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
							<RangeSelect title="Form" selectedValues={gravelForm} setSelectedValue={setGravelForm} values={[
								{title: 'cubic', order: 1},
								{title: 'flat', order: 2},
								{title: 'elongated', order: 3},
							]}
							columns={1}
							columnsMobile={1}
							className="text-xs sm:text-lg mx-1 py-1 sm:py-1 md:py-0 px-0"
							/>
						</div>

					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<RangeSelect title="Angularity" selectedValues={gravelAngularity} setSelectedValue={setGravelAngularity} values={[
							{title: 'very angular', order: 1},
							{title: 'angular', order: 2},
							{title: 'sub-angular', order: 3},
							{title: 'sub-rounded', order: 4},
							{title: 'rounded', order: 5},
							{title: 'well rounded', order: 6},
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-1 md:py-0 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<MultiSelect title="Composition" selectedValues={gravelComposition} setSelectedValue={setGravelComposition} values={[
							'flint',
							'chalk',
							'chert',
							'claystone',
							'granite',
							'limestone',
							'sandstone',
							'siltstone',
							'ironstone',
							'quartz',
							'various lithiology',
							'other (keyboard)',
						]}
						columns={6}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-0 px-0"
						/>
					</div>

						{gravelComposition.includes('other (keyboard)') && (
							<InputGroup label={''} onChange={(value) => setGravelCompositionKeyboard(value)} value={gravelCompositionKeyboard} placeholder='Enter gravel composition here' />
						)}
					</>,
					show: (secondaryCompositionGravelly !== '')
				}, {
					tab: 'Minor',
					component: <>
					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<RangeSelect title="Cobble Content" selectedValues={minorConstituentsCobbleContent} setSelectedValue={setMinorConstituentsCobbleContent} values={[
							{title: 'low', order: 1},
							{title: 'medium', order: 2},
							{title: 'high', order: 3},
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-1 md:py-1 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Minor Frequency" selectedValue={minorConstituentsMinorFrequency} setSelectedValue={setMinorConstituentsMinorFrequency} values={[
							'with rare',
							'with occasional',
							'with numerous',
							'with frequent',
							'with abundant',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-1 md:py-1 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<MultiSelect title="Minor Items" selectedValues={minorConstituentsMinorItems} setSelectedValue={setMinorConstituentsMinorItems} values={[
							'shell fragments',
							'pockets of peat',
							'gypsum crystals',
							'pyrite nodules',
							'calcareous concretions',
							'chalk gravel',
							'organic matter',
							'flint gravel',
							'other (keyboard)',
						]}
						columns={5}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-1 px-0"
						/>
					</div>

						{minorConstituentsMinorItems.includes('other (keyboard)') && (
							<InputGroup label={''} onChange={(value) => setMinorConstituentsMinorItemsKeyboard(value)} value={minorConstituentsMinorItemsKeyboard} placeholder='Enter minor items here' />
						)}
					</>,
					show: true
				}, {
					tab: 'Comments',
					component: <Comment comment={comment} numberOfRows={5} setComment={setComment} title="Comments" />,
					show: true
				},
			],






			'Sand & Gravel': [
				{
					tab: 'Density',
					component: <>
					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<RangeSelect title="Relative Density" selectedValues={relativeDensity} setSelectedValue={setRelativeDensity} values={[
							{title: 'very loose', order: 1},
							{title: 'loose', order: 2},
							{title: 'medium dense', order: 3},
							{title: 'dense', order: 4},
							{title: 'very dense', order: 5},
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-2 md:py-2 px-0"
						/>
					</div>
					</>,
					show: true,
				}, {
					tab: 'Colour',
					component: <ColorType colorType={colorType} setColorType={setColorType} />,
					show: true
				}, {
					tab: 'Secondary',
					component: <>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Clayey" selectedValue={secondaryCompositionClayey} setSelectedValue={setSecondaryCompositionClayey} values={[
							'slightly clayey',
							'clayey',
							'very clayey',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-2 md:py-2 px-0 "
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Silty" selectedValue={secondaryCompositionSilty} setSelectedValue={setSecondaryCompositionSilty} values={[
							'slightly silty',
							'silty',
							'very silty',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-2 md:py-2 px-0"
						/>
					</div>
					</>,
					show: true
				}, {
					tab: 'Sand Grading',
					component: <>
					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<RangeSelect title="Sand Grading" selectedValues={sandGrading} setSelectedValue={setSandGrading} values={[
							{title: 'fine', order: 1},
							{title: 'medium', order: 2},
							{title: 'coarse', order: 3},
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-2 md:py-2 px-0"
						/>
					</div>
					</>,
					show: true
				}, {
					tab: 'Gravel',
					component: <>

					<div className="grid grid-cols-2 gap-2">
					
						<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
							<RangeSelect title="Size" selectedValues={gravelSize} setSelectedValue={setGravelSize} values={[
								{title: 'fine', order: 1},
								{title: 'medium', order: 2},
								{title: 'coarse', order: 3},
							]}
							columns={1}
							columnsMobile={1}
							className="text-xs sm:text-lg mx-1 py-0 px-0"
							/>
						</div>

						<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
							<RangeSelect title="Form" selectedValues={gravelForm} setSelectedValue={setGravelForm} values={[
								{title: 'cubic', order: 1},
								{title: 'flat', order: 2},
								{title: 'elongated', order: 3},
							]}
							columns={1}
							columnsMobile={1}
							className="text-xs sm:text-lg mx-1 py-0 px-0"
							/>
						</div>

					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<RangeSelect title="Angularity" selectedValues={gravelAngularity} setSelectedValue={setGravelAngularity} values={[
							{title: 'very angular', order: 1},
							{title: 'angular', order: 2},
							{title: 'sub-angular', order: 3},
							{title: 'sub-rounded', order: 4},
							{title: 'rounded', order: 5},
							{title: 'well rounded', order: 6},
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-0 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<MultiSelect title="Composition" selectedValues={gravelComposition} setSelectedValue={setGravelComposition} values={[
							'flint',
							'chalk',
							'chert',
							'claystone',
							'granite',
							'limestone',
							'sandstone',
							'siltstone',
							'ironstone',
							'quartz',
							'various lithiology',
							'other (keyboard)',
						]}
						columns={6}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-0 px-0"
						/>
					</div>

						{gravelComposition.includes('other (keyboard)') && (
							<InputGroup label={''} onChange={(value) => setGravelCompositionKeyboard(value)} value={gravelCompositionKeyboard} placeholder='Enter gravel composition here' />
						)}
					</>,
					show: true
				}, {
					tab: 'Minor Constituents',
					component: <>
					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<RangeSelect title="Cobble Content" selectedValues={minorConstituentsCobbleContent} setSelectedValue={setMinorConstituentsCobbleContent} values={[
							{title: 'low', order: 1},
							{title: 'medium', order: 2},
							{title: 'high', order: 3},
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-1 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Minor Frequency" selectedValue={minorConstituentsMinorFrequency} setSelectedValue={setMinorConstituentsMinorFrequency} values={[
							'with rare',
							'with occasional',
							'with numerous',
							'with frequent',
							'with abundant',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-1 md:py-1 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<MultiSelect title="Minor Items" selectedValues={minorConstituentsMinorItems} setSelectedValue={setMinorConstituentsMinorItems} values={[
							'shell fragments',
							'pockets of peat',
							'gypsum crystals',
							'pyrite nodules',
							'calcareous concretions',
							'chalk gravel',
							'organic matter',
							'flint gravel',
							'other (keyboard)',
						]}
						columns={5}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-1 px-0"
						/>
					</div>

						{minorConstituentsMinorItems.includes('other (keyboard)') && (
							<InputGroup label={''} onChange={(value) => setMinorConstituentsMinorItemsKeyboard(value)} value={minorConstituentsMinorItemsKeyboard} placeholder='Enter minor items here' />
						)}
					</>,
					show: true
				}, {
					tab: 'Comments',
					component: <Comment comment={comment} numberOfRows={5} setComment={setComment} title="Comments" />,
					show: true
				},
			],






			'Gravel': [
				{
					tab: 'Relative Density',
					component: <>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<RangeSelect title="Relative Density" selectedValues={relativeDensity} setSelectedValue={setRelativeDensity} values={[
							{title: 'very loose', order: 1},
							{title: 'loose', order: 2},
							{title: 'medium dense', order: 3},
							{title: 'dense', order: 4},
							{title: 'very dense', order: 5},
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-2 md:py-2 px-0 "
						/>
					</div>
					</>,
					show: true,
				}, {
					tab: 'Colour',
					component: <ColorType colorType={colorType} setColorType={setColorType} />,
					show: true
				}, {
					tab: 'Secondary',
					component: <>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Clayey" selectedValue={secondaryCompositionClayey} setSelectedValue={setSecondaryCompositionClayey} values={[
							'slightly clayey',
							'clayey',
							'very clayey',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-2 md:py-2 px-0 "
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Silty" selectedValue={secondaryCompositionSilty} setSelectedValue={setSecondaryCompositionSilty} values={[
							'slightly silty',
							'silty',
							'very silty',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-2 md:py-2 px-0 "
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Sandy" selectedValue={secondaryCompositionSandy} setSelectedValue={setSecondaryCompositionSandy} values={[
							'slightly sandy',
							'sandy',
							'very sandy',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-2 md:py-2 px-0 "
						/>
					</div>
					</>,
					show: true
				}, {
					tab: 'Gravel', // Composition
					component: <>

					<div className="grid grid-cols-2 gap-2">
					
						<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
							<RangeSelect title="Size" selectedValues={gravelSize} setSelectedValue={setGravelSize} values={[
								{title: 'fine', order: 1},
								{title: 'medium', order: 2},
								{title: 'coarse', order: 3},
							]}
							columns={1}
							columnsMobile={1}
							className="text-xs sm:text-lg mx-1 py-0 px-0"
							/>
						</div>

						<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
							<RangeSelect title="Form" selectedValues={gravelForm} setSelectedValue={setGravelForm} values={[
								{title: 'cubic', order: 1},
								{title: 'flat', order: 2},
								{title: 'elongated', order: 3},
							]}
							columns={1}
							columnsMobile={1}
							className="text-xs sm:text-lg mx-1 py-0 px-0"
							/>
						</div>

					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<RangeSelect title="Angularity" selectedValues={gravelAngularity} setSelectedValue={setGravelAngularity} values={[
							{title: 'very angular', order: 1},
							{title: 'angular', order: 2},
							{title: 'sub-angular', order: 3},
							{title: 'sub-rounded', order: 4},
							{title: 'rounded', order: 5},
							{title: 'well rounded', order: 6},
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-0 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<MultiSelect title="Composition" selectedValues={gravelComposition} setSelectedValue={setGravelComposition} values={[
							'flint',
							'chalk',
							'chert',
							'claystone',
							'granite',
							'limestone',
							'sandstone',
							'siltstone',
							'ironstone',
							'quartz',
							'various lithiology',
							'other (keyboard)',
						]}
						columns={6}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-0 px-0"
						/>
					</div>

						{gravelComposition.includes('other (keyboard)') && (
							<InputGroup label={''} onChange={(value) => setGravelCompositionKeyboard(value)} value={gravelCompositionKeyboard} placeholder='Enter gravel composition here' />
						)}
					</>,
					show: true
				}, {
					tab: 'Sand Grading',
					component: <>
					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<RangeSelect title="Sand Grading" selectedValues={sandGrading} setSelectedValue={setSandGrading} values={[
							{title: 'fine', order: 1},
							{title: 'medium', order: 2},
							{title: 'coarse', order: 3},
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-2 md:py-2 px-0"
						/>
					</div>
					</>,
					show: (secondaryCompositionSandy !== '')
				}, {
					tab: 'Minor',
					component: <>
					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<RangeSelect title="Cobble Content" selectedValues={minorConstituentsCobbleContent} setSelectedValue={setMinorConstituentsCobbleContent} values={[
							{title: 'low', order: 1},
							{title: 'medium', order: 2},
							{title: 'high', order: 3},
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-1 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Minor Frequency" selectedValue={minorConstituentsMinorFrequency} setSelectedValue={setMinorConstituentsMinorFrequency} values={[
							'with rare',
							'with occasional',
							'with numerous',
							'with frequent',
							'with abundant',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-1 md:py-1 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<MultiSelect title="Minor Items" selectedValues={minorConstituentsMinorItems} setSelectedValue={setMinorConstituentsMinorItems} values={[
							'shell fragments',
							'pockets of peat',
							'gypsum crystals',
							'pyrite nodules',
							'calcareous concretions',
							'chalk gravel',
							'organic matter',
							'flint gravel',
							'other (keyboard)',
						]}
						columns={5}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-1 px-0"
						/>
					</div>

						{minorConstituentsMinorItems.includes('other (keyboard)') && (
							<InputGroup label={''} onChange={(value) => setMinorConstituentsMinorItemsKeyboard(value)} value={minorConstituentsMinorItemsKeyboard} placeholder='Enter minor items here' />
						)}
					</>,
					show: true
				}, {
					tab: 'Comments',
					component: <Comment comment={comment} numberOfRows={5} setComment={setComment} title="Comments" />,
					show: true
				},
			],






			'Clay': [
				{
					tab: 'Consistency',
					component: <ConsistencyTypes consistencyType={consistencyType} setConsistencyType={setConsistencyType} />,
					show: true,
				}, {
					tab: 'Colour',
					component: <ColorType colorType={colorType} setColorType={setColorType} />,
					show: true
				}, {
					tab: 'Secondary',
					component: <>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Silty" selectedValue={secondaryCompositionSilty} setSelectedValue={setSecondaryCompositionSilty} values={[
							'slightly silty',
							'silty',
							'very silty',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-2 md:py-2 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Sandy" selectedValue={secondaryCompositionSandy} setSelectedValue={setSecondaryCompositionSandy} values={[
							'slightly sandy',
							'sandy',
							'very sandy',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-2 md:py-2 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Gravelly" selectedValue={secondaryCompositionGravelly} setSelectedValue={setSecondaryCompositionGravelly} values={[
							'slightly gravelly',
							'gravelly',
							'very gravelly',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-2 md:py-2 px-0"
						/>
					</div>

					</>,
					show: true
				}, {
					tab: 'Sand Grading',
					component: <>
					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<RangeSelect title="Sand Grading" selectedValues={sandGrading} setSelectedValue={setSandGrading} values={[
							{title: 'fine', order: 1},
							{title: 'medium', order: 2},
							{title: 'coarse', order: 3},
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-2 md:py-2 px-0"
						/>
					</div>
					</>,
					show: (secondaryCompositionSandy !== '')
				}, {
					tab: 'Gravel',
					component: <>

					<div className="grid grid-cols-2 gap-2">
					
						<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
							<RangeSelect title="Size" selectedValues={gravelSize} setSelectedValue={setGravelSize} values={[
								{title: 'fine', order: 1},
								{title: 'medium', order: 2},
								{title: 'coarse', order: 3},
							]}
							columns={1}
							columnsMobile={1}
							className="text-xs sm:text-lg mx-1 py-0 px-0"
							/>
						</div>

						<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
							<RangeSelect title="Form" selectedValues={gravelForm} setSelectedValue={setGravelForm} values={[
								{title: 'cubic', order: 1},
								{title: 'flat', order: 2},
								{title: 'elongated', order: 3},
							]}
							columns={1}
							columnsMobile={1}
							className="text-xs sm:text-lg mx-1 py-0 px-0"
							/>
						</div>

					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<RangeSelect title="Angularity" selectedValues={gravelAngularity} setSelectedValue={setGravelAngularity} values={[
							{title: 'very angular', order: 1},
							{title: 'angular', order: 2},
							{title: 'sub-angular', order: 3},
							{title: 'sub-rounded', order: 4},
							{title: 'rounded', order: 5},
							{title: 'well rounded', order: 6},
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-0 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<MultiSelect title="Composition" selectedValues={gravelComposition} setSelectedValue={setGravelComposition} values={[
							'flint',
							'chalk',
							'chert',
							'claystone',
							'granite',
							'limestone',
							'sandstone',
							'siltstone',
							'ironstone',
							'quartz',
							'various lithiology',
							'other (keyboard)',
						]}
						columns={6}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-0 px-0"
						/>
					</div>

						{gravelComposition.includes('other (keyboard)') && (
							<InputGroup label={''} onChange={(value) => setGravelCompositionKeyboard(value)} value={gravelCompositionKeyboard} placeholder='Enter gravel composition here' />
						)}
					</>,
					show: (secondaryCompositionGravelly !== '')
				}, {
					tab: 'Minor',
					component: <>
					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<RangeSelect title="Cobble Content" selectedValues={minorConstituentsCobbleContent} setSelectedValue={setMinorConstituentsCobbleContent} values={[
							{title: 'low', order: 1},
							{title: 'medium', order: 2},
							{title: 'high', order: 3},
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-1 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Minor Frequency" selectedValue={minorConstituentsMinorFrequency} setSelectedValue={setMinorConstituentsMinorFrequency} values={[
							'with rare',
							'with occasional',
							'with numerous',
							'with frequent',
							'with abundant',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-1 md:py-1 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<MultiSelect title="Minor Items" selectedValues={minorConstituentsMinorItems} setSelectedValue={setMinorConstituentsMinorItems} values={[
							'shell fragments',
							'pockets of peat',
							'gypsum crystals',
							'pyrite nodules',
							'calcareous concretions',
							'chalk gravel',
							'organic matter',
							'flint gravel',
							'other (keyboard)',
						]}
						columns={5}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-1 px-0"
						/>
					</div>

						{minorConstituentsMinorItems.includes('other (keyboard)') && (
							<InputGroup label={''} onChange={(value) => setMinorConstituentsMinorItemsKeyboard(value)} value={minorConstituentsMinorItemsKeyboard} placeholder='Enter minor items here' />
						)}
					</>,
					show: true
				}, {
					tab: 'Comments',
					component: <Comment comment={comment} numberOfRows={5} setComment={setComment} title="Comments" />,
					show: true
				},
			],






			'Silt': [
				{
					tab: 'Consistency',
					component: <ConsistencyTypes consistencyType={consistencyType} setConsistencyType={setConsistencyType} />,
					show: true,
				}, {
					tab: 'Colour',
					component: <ColorType colorType={colorType} setColorType={setColorType} />,
					show: true
				}, {
					tab: 'Secondary',
					component: <>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Clayey" selectedValue={secondaryCompositionClayey} setSelectedValue={setSecondaryCompositionClayey} values={[
							'slightly clayey',
							'clayey',
							'very clayey',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-2 md:py-2 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Sandy" selectedValue={secondaryCompositionSandy} setSelectedValue={setSecondaryCompositionSandy} values={[
							'slightly sandy',
							'sandy',
							'very sandy',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-2 md:py-2 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Gravelly" selectedValue={secondaryCompositionGravelly} setSelectedValue={setSecondaryCompositionGravelly} values={[
							'slightly gravelly',
							'gravelly',
							'very gravelly',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-2 md:py-2 px-0"
						/>
					</div>

					</>,
					show: true
				}, {
					tab: 'Gravel',
					component: <>

					<div className="grid grid-cols-2 gap-2">
					
						<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
							<RangeSelect title="Size" selectedValues={gravelSize} setSelectedValue={setGravelSize} values={[
								{title: 'fine', order: 1},
								{title: 'medium', order: 2},
								{title: 'coarse', order: 3},
							]}
							columns={1}
							columnsMobile={1}
							className="text-xs sm:text-lg mx-1 py-0 px-0"
							/>
						</div>

						<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
							<RangeSelect title="Form" selectedValues={gravelForm} setSelectedValue={setGravelForm} values={[
								{title: 'cubic', order: 1},
								{title: 'flat', order: 2},
								{title: 'elongated', order: 3},
							]}
							columns={1}
							columnsMobile={1}
							className="text-xs sm:text-lg mx-1 py-0 px-0"
							/>
						</div>

					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<RangeSelect title="Angularity" selectedValues={gravelAngularity} setSelectedValue={setGravelAngularity} values={[
							{title: 'very angular', order: 1},
							{title: 'angular', order: 2},
							{title: 'sub-angular', order: 3},
							{title: 'sub-rounded', order: 4},
							{title: 'rounded', order: 5},
							{title: 'well rounded', order: 6},
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-0 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<MultiSelect title="Composition" selectedValues={gravelComposition} setSelectedValue={setGravelComposition} values={[
							'flint',
							'chalk',
							'chert',
							'claystone',
							'granite',
							'limestone',
							'sandstone',
							'siltstone',
							'ironstone',
							'quartz',
							'various lithiology',
							'other (keyboard)',
						]}
						columns={6}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-0 px-0"
						/>
					</div>

						{gravelComposition.includes('other (keyboard)') && (
							<InputGroup label={''} onChange={(value) => setGravelCompositionKeyboard(value)} value={gravelCompositionKeyboard} placeholder='Enter gravel composition here' />
						)}
					</>,
					show: (secondaryCompositionGravelly !== '')
				}, {
					tab: 'Sand Grading',
					component: <>
					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<RangeSelect title="Sand Grading" selectedValues={sandGrading} setSelectedValue={setSandGrading} values={[
							{title: 'fine', order: 1},
							{title: 'medium', order: 2},
							{title: 'coarse', order: 3},
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-2 md:py-2 px-0"
						/>
					</div>
					</>,
					show: (secondaryCompositionSandy !== '')
				}, {
					tab: 'Minor',
					component: <>
					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<RangeSelect title="Cobble Content" selectedValues={minorConstituentsCobbleContent} setSelectedValue={setMinorConstituentsCobbleContent} values={[
							{title: 'low', order: 1},
							{title: 'medium', order: 2},
							{title: 'high', order: 3},
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-1 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Minor Frequency" selectedValue={minorConstituentsMinorFrequency} setSelectedValue={setMinorConstituentsMinorFrequency} values={[
							'with rare',
							'with occasional',
							'with numerous',
							'with frequent',
							'with abundant',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-1 md:py-1 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<MultiSelect title="Minor Items" selectedValues={minorConstituentsMinorItems} setSelectedValue={setMinorConstituentsMinorItems} values={[
							'shell fragments',
							'pockets of peat',
							'gypsum crystals',
							'pyrite nodules',
							'calcareous concretions',
							'chalk gravel',
							'organic matter',
							'flint gravel',
							'other (keyboard)',
						]}
						columns={5}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-1 px-0"
						/>
					</div>

						{minorConstituentsMinorItems.includes('other (keyboard)') && (
							<InputGroup label={''} onChange={(value) => setMinorConstituentsMinorItemsKeyboard(value)} value={minorConstituentsMinorItemsKeyboard} placeholder='Enter minor items here' />
						)}
					</>,
					show: true
				}, {
					tab: 'Comments',
					component: <Comment comment={comment} numberOfRows={5} setComment={setComment} title="Comments" />,
					show: true
				},
			],






			'Peat': [
				{
					tab: 'Consistency',
					component: <>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<RangeSelect title="Consistancy" selectedValues={peatConsistency} setSelectedValue={setPeatConsistency} values={[
							{title: 'firm', order: 1, description: 'Fibres already compressed together'},
							{title: 'spongy', order: 2, description: 'Very compressible and open structure'},
							{title: 'plastic', order: 3, description: 'Can be moulded in hand and smears fingers'},
						]}
						columns={3}
						columnsMobile={1}
						className="text-lg sm:text-lg md:text-2xl mx-1 py-1 sm:py-4 md:py-4 px-0"
						/>
					</div>
					</>,
					show: true,
				}, {
					tab: 'Composition',
					component: <>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<RangeSelect title="Description" selectedValues={peatDescription} setSelectedValue={setPeatDescription} values={[
							{title: 'fibrous', order: 1, description: 'Contains recognisable plant remains and retains some strength'},
							{title: 'pseudo-fibrous', order: 2, description: 'Contains recognisable plant remains but strength is lost'},
							{title: 'amorphous', order: 3, description: 'Has no recognisable plant remains and often appears granular in nature'},
						]}
						columns={3}
						columnsMobile={1}
						className="text-lg sm:text-lg md:text-2xl mx-1 py-1 sm:py-4 md:py-4 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<RangeSelect title="Odour / Smell" selectedValues={peatSmell} setSelectedValue={setPeatSmell} values={[
							{title: 'faint', order: 1},
							{title: 'mild', order: 2},
							{title: 'strong', order: 3},
						]}
						columns={3}
						columnsMobile={1}
						className="text-base sm:text-lg md:text-2xl mx-1 py-1 sm:py-1 md:py-1 px-0"
						/>
					</div>

					</>,
					show: true
				}, {
					tab: 'Colour',
					component: <ColorType colorType={colorType} setColorType={setColorType} />,
					show: true
				}, {
					tab: 'Secondary',
					component: <>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Clayey" selectedValue={secondaryCompositionClayey} setSelectedValue={setSecondaryCompositionClayey} values={[
							'slightly clayey',
							'clayey',
							'very clayey',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-1 md:py-1 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Silty" selectedValue={secondaryCompositionSilty} setSelectedValue={setSecondaryCompositionSilty} values={[
							'slightly silty',
							'silty',
							'very silty',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-1 md:py-1 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Sandy" selectedValue={secondaryCompositionSandy} setSelectedValue={setSecondaryCompositionSandy} values={[
							'slightly sandy',
							'sandy',
							'very sandy',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-1 md:py-1 px-0"
						/>
					</div>

					</>,
					show: true
				}, {
					tab: 'Minor Constituents',
					component: <>
					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<RangeSelect title="Cobble Content" selectedValues={minorConstituentsCobbleContent} setSelectedValue={setMinorConstituentsCobbleContent} values={[
							{title: 'low', order: 1},
							{title: 'medium', order: 2},
							{title: 'high', order: 3},
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-1 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Minor Frequency" selectedValue={minorConstituentsMinorFrequency} setSelectedValue={setMinorConstituentsMinorFrequency} values={[
							'with rare',
							'with occasional',
							'with numerous',
							'with frequent',
							'with abundant',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-1 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<MultiSelect title="Minor Items" selectedValues={minorConstituentsMinorItems} setSelectedValue={setMinorConstituentsMinorItems} values={[
							'shell fragments',
							'gypsum crystals',
							'pyrite nodules',
							'calcareous concretions',
							'chalk gravel',
							'organic matter',
							'flint gravel',
							'other (keyboard)',
						]}
						columns={4}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-1 px-0"
						/>
					</div>

						{minorConstituentsMinorItems.includes('other (keyboard)') && (
							<InputGroup label={''} onChange={(value) => setMinorConstituentsMinorItemsKeyboard(value)} value={minorConstituentsMinorItemsKeyboard} placeholder='Enter minor items here' />
						)}
					</>,
					show: true
				}, {
					tab: 'Comments',
					component: <Comment comment={comment} numberOfRows={5} setComment={setComment} title="Comments" />,
					show: true
				},
			],






			'Chalk (SILT)': [
				{
					tab: 'Colour',
					component: <ColorType colorType={colorType} setColorType={setColorType} />,
					show: true
				}, {
					tab: 'Secondary',
					component: <>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Clayey" selectedValue={secondaryCompositionClayey} setSelectedValue={setSecondaryCompositionClayey} values={[
							'slightly clayey',
							'clayey',
							'very clayey',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-1 md:py-1 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Sandy" selectedValue={secondaryCompositionSandy} setSelectedValue={setSecondaryCompositionSandy} values={[
							'slightly sandy',
							'sandy',
							'very sandy',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-1 md:py-1 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Gravelly" selectedValue={secondaryCompositionGravelly} setSelectedValue={setSecondaryCompositionGravelly} values={[
							'slightly gravelly',
							'gravelly',
							'very gravelly',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-1 md:py-1 px-0"
						/>
					</div>

					</>,
					show: true
				}, {
					tab: 'Clasts',
					component: <>

						<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
							<RangeSelect title="Clasts" selectedValues={clasts} setSelectedValue={setClasts} values={[
								{title: 'very weak', order: 1, description: '30-40mm thick fragments can be crushed between finger & thumb. Remould to putty'},
								{title: 'weak', order: 2, description: '30-40mm thick fragments can be broken in two using both hands, but cannot be crushed between finger & thumb'},
								{title: 'moderately weak', order: 3, description: '30-40mm thick fragments cannot be broken in two. Only thin slabs (<10mm thick) can be broken with difficulty using both hands'},
								{title: 'moderately strong', order: 4, description: 'Cannot be broken by hand. 100mm can be broken by a single hammer blow when held in palm of the hand'},
							]} 
							columns={2}
							columnsMobile={1}
							className="text-lg sm:text-lg md:text-2xl mx-1 py-1 sm:py-4 md:py-4 px-3"
							/>
						</div>
					</>,
					show: (secondaryCompositionGravelly !== '')
				}, {
					tab: 'Gravel',
					component: <>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<RangeSelect title="Size" selectedValues={gravelSize} setSelectedValue={setGravelSize} values={[
							{title: 'fine', order: 1},
							{title: 'medium', order: 2},
							{title: 'coarse', order: 3},
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-1 md:py-1 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<RangeSelect title="Angularity" selectedValues={gravelAngularity} setSelectedValue={setGravelAngularity} values={[
							{title: 'very angular', order: 1},
							{title: 'angular', order: 2},
							{title: 'sub-angular', order: 3},
							{title: 'sub-rounded', order: 4},
							{title: 'rounded', order: 5},
							{title: 'well rounded', order: 6},
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-1 md:py-1 px-0"
						/>
					</div>

						{gravelComposition.includes('other (keyboard)') && (
							<InputGroup label={''} onChange={(value) => setGravelCompositionKeyboard(value)} value={gravelCompositionKeyboard} placeholder='Enter gravel composition here' />
						)}
					</>,
					show: (secondaryCompositionGravelly !== '')
				}, {
					tab: 'Gravel Colour',
					component: <ColorType colorType={chalkGravelMatrixColor} setColorType={setChalkGravelMatrixColor} />,
					show: (secondaryCompositionGravelly !== '')
				}, {
					tab: 'Minor',
					component: <>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<RangeSelect title="Flint content" selectedValues={minorConstituentsChalkFlints} setSelectedValue={setMinorConstituentsChalkFlints} values={[
							{title: 'low', order: 1},
							{title: 'medium', order: 2},
							{title: 'high', order: 3},
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-1 md:py-1 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Minor Frequency" selectedValue={minorConstituentsChalkMinorFrequency} setSelectedValue={setMinorConstituentsChalkMinorFrequency} values={[
							'with rare',
							'with occasional',
							'with numerous',
							'with frequent',
							'with abundant',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-1 md:py-1 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Minor Items" selectedValue={minorConstituentsChalkMinorItems} setSelectedValue={setMinorConstituentsChalkMinorItems} values={[
							'black specks',
							'other (keyboard)',
						]}
						columns={2}
						columnsMobile={2}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-1 md:py-1 px-0"
						/>
					</div>

						{minorConstituentsChalkMinorItems === 'other (keyboard)' && (
							<InputGroup label={''} onChange={(value) => setMinorConstituentsChalkMinorItemsKeyboard(value)} value={minorConstituentsChalkMinorItemsKeyboard} placeholder='Enter here' />
						)}
					</>,
					show: true
				}, {
					tab: 'Comments',
					component: <Comment comment={comment} numberOfRows={5} setComment={setComment} title="Comments" />,
					show: true
				},
			],






			'Chalk (GRAVEL)': [
				{
					tab: 'Composition',
					component: <>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Composition" selectedValue={chalkGravelComposition} setSelectedValue={setChalkGravelComposition} values={[
							'GRAVEL',
							'COBBLES',
							'GRAVEL and COBBLES',
						]}
						columns={3}
						columnsMobile={1}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-1 md:py-1 px-0"
						/>
					</div>
					</>,
					show: true
				}, {
					tab: 'Clasts',
					component: <>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<RangeSelect title="Clasts" selectedValues={clasts} setSelectedValue={setClasts} values={[
							{title: 'very weak', order: 1, description: '30-40mm thick fragments can be crushed between finger & thumb. Remould to putty'},
							{title: 'weak', order: 2, description: '30-40mm thick fragments can be broken in two using both hands, but cannot be crushed between finger & thumb'},
							{title: 'moderately weak', order: 3, description: '30-40mm thick fragments cannot be broken in two. Only thin slabs (<10mm thick) can be broken with difficulty using both hands'},
							{title: 'moderately strong', order: 4, description: 'Cannot be broken by hand. 100mm can be broken by a single hammer blow when held in palm of the hand'},
						]} 
						columns={2}
						columnsMobile={1}
						className="text-lg sm:text-lg md:text-2xl mx-1 py-1 sm:py-4 md:py-4 px-3"
						/>
					</div>
					</>,
					show: true
				}, {
					tab: 'Colour',
					component: <ColorType colorType={colorType} setColorType={setColorType} />,
					show: true
				}, {
					tab: 'Secondary',
					component: <>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Clayey" selectedValue={secondaryCompositionClayey} setSelectedValue={setSecondaryCompositionClayey} values={[
							'slightly clayey',
							'clayey',
							'very clayey',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-1 md:py-1 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Silty" selectedValue={secondaryCompositionSilty} setSelectedValue={setSecondaryCompositionSilty} values={[
							'slightly silty',
							'silty',
							'very silty',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-1 md:py-1 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Sandy" selectedValue={secondaryCompositionSandy} setSelectedValue={setSecondaryCompositionSandy} values={[
							'slightly sandy',
							'sandy',
							'very sandy',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-1 md:py-1 px-0"
						/>
					</div>
					</>,
					show: true
				}, {
					tab: 'Chalk Gravel',
					component: <>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<RangeSelect title="Size" selectedValues={gravelSize} setSelectedValue={setGravelSize} values={[
							{title: 'fine', order: 1},
							{title: 'medium', order: 2},
							{title: 'coarse', order: 3},
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-1 md:py-1 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<RangeSelect title="Angularity" selectedValues={gravelAngularity} setSelectedValue={setGravelAngularity} values={[
							{title: 'very angular', order: 1},
							{title: 'angular', order: 2},
							{title: 'sub-angular', order: 3},
							{title: 'sub-rounded', order: 4},
							{title: 'rounded', order: 5},
							{title: 'well rounded', order: 6},
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-1 md:py-1 px-0"
						/>
					</div>
					</>,
					show: true
				}, {
					tab: 'Matrix Colour',
					component: <ColorType colorType={chalkGravelMatrixColor} setColorType={setChalkGravelMatrixColor} />,
					show: true
				}, {
					tab: 'Minor',
					component: <>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<RangeSelect title="Flint content" selectedValues={minorConstituentsChalkFlints} setSelectedValue={setMinorConstituentsChalkFlints} values={[
							{title: 'low', order: 1},
							{title: 'medium', order: 2},
							{title: 'high', order: 3},
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-1 md:py-1 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Minor Frequency" selectedValue={minorConstituentsChalkMinorFrequency} setSelectedValue={setMinorConstituentsChalkMinorFrequency} values={[
							'with rare',
							'with occasional',
							'with numerous',
							'with frequent',
							'with abundant',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-1 md:py-1 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Minor Items" selectedValue={minorConstituentsChalkMinorItems} setSelectedValue={setMinorConstituentsChalkMinorItems} values={[
							'black specks',
							'other (keyboard)',
						]}
						columns={2}
						columnsMobile={2}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-1 md:py-1 px-0"
						/>
					</div>

						{minorConstituentsChalkMinorItems === 'other (keyboard)' && (
							<InputGroup label={''} onChange={(value) => setMinorConstituentsChalkMinorItemsKeyboard(value)} value={minorConstituentsChalkMinorItemsKeyboard} placeholder='Enter here' />
						)}
					</>,
					show: true
				}, {
					tab: 'Comments',
					component: <Comment comment={comment} numberOfRows={5} setComment={setComment} title="Comments" />,
					show: true
				},
			],






			'Chalk (ROCK)': [
				{
					tab: 'Compressive Strength',
					component: <>

						<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
							<RangeSelect title="Compressive Strength (Range)" selectedValues={compressiveStrength} setSelectedValue={setCompressiveStrength} values={[
								{title: 'extremely weak', order: 1, description: 'Indented by thumbnail'},
								{title: 'very weak', order: 2, description: 'crumbles under firm blows with point of geological hammer'},
								{title: 'weak', order: 3, description: 'Shallow indentations made by firm blow with point of geological hammer'},
								{title: 'medium strong', order: 4, description: 'Specimen can be fractured with single blow of geological hammer'},
								{title: 'strong', order: 5, description: 'Specimen requires more than one blow of geological hammer to fracture'},
								{title: 'very strong', order: 6, description: 'Specimen can only be chipped with geological hammer'},
							]}
							columns={2}
							columnsMobile={2}
							className="text-base sm:text-lg md:text-2xl mx-1 py-1 sm:py-4 md:py-4 px-3"
							/>
						</div>
					</>,
					show: true
				}, {
					tab: 'Density',
					component: <>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						{/* <ExclusiveSelect title="Chalk Rock Density (Range)" selectedValue={chalkRockDensity} setSelectedValue={setChalkRockDensity} values={[
							{value: "low density", label: <>low density<div className="text-xs">30-40mm thick fragments crush by finger pressure and remould to putty</div></>},
							{value: "medium density", label: <>medium density<div className="text-xs">30-40mm thick fragments break with both hands. Will not crush with finger pressure</div></>},
							{value: "high density", label: <>high density<div className="text-xs">Cannot break 30-40mm thick fragments in two. Difficult to break slabs less than 10mm thick and corners/edges of lumps...</div></>},
							{value: "very high density", label: <>very high density<div className="text-xs">Unable to break by hand: 100mm diameter lump held in hand broken by single hammer blow</div></>},
						]}
						columns={2}
						columnsMobile={1}
						className="text-base sm:text-lg md:text-2xl mx-1 py-1 sm:py-4 md:py-4 px-3"
						/> */}
						<RangeSelect title="Chalk Rock Density (Range)" selectedValues={chalkRockDensity}  setSelectedValue = {setChalkRockDensity} values={[
							{title: 'low density', order: 1, description: '30-40mm thick fragments crush by finger pressure and remould to putty'},
							{title: 'medium density', order: 2, description: '30-40mm thick fragments break with both hands. Will not crush with finger pressure'},
							{title: 'high density', order: 3, description: 'Cannot break 30-40mm thick fragments in two. Difficult to break slabs less than 10mm thick and corners/edges of lumps...'},
							{title: 'very high density', order: 4, description: 'Unable to break by hand: 100mm diameter lump held in hand broken by single hammer blow'},
						]}
						columns={2}
						columnsMobile={2}
						className="text-base sm:text-lg md:text-2xl mx-1 py-1 sm:py-4 md:py-4 px-3"
						/>
					</div>
					</>,
					show: true
				}, {
					tab: 'Colour',
					component: <ColorType colorType={colorType} setColorType={setColorType} />,
					show: true
				}, {
					tab: 'Discontinuites',
					component: <>
						
					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2 text-center">
						<ExclusiveSelect title="Infill/Aperture" selectedValue={infillAperture} setSelectedValue={setInfillAperture} values={[
							'clean',
							'clean or infilled',
							'infilled',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-1 md:py-1 px-0"
						/>

						<div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 gap-3">
							<div className="">
								<InputGroup type="number" 
								label="Min (mm)" 
								value={infillMin} 
								onChange={(value) => setInfillMin(value)}
								className="mb-2 text-sm sm:text-base md:text-base w-full"
								inputCSS="text-sm sm:text-lg md:text-lg w-full text-center" 
								/>
							</div>

							<div className="">
								<InputGroup type="number" 
								label="Typical (mm)" 
								value={infillTypical} 
								onChange={(value) => setInfillTypical(value)}
								className="mb-2 text-sm sm:text-base md:text-base w-full"
								inputCSS="text-sm sm:text-lg md:text-lg w-full text-center" 
								/>
							</div>

							<div className="">
								<InputGroup type="number" 
								label="Max (mm)" 
								value={infillMax} 
								onChange={(value) => setInfillMax(value)}
								className="mb-2 text-sm sm:text-base md:text-base w-full"
								inputCSS="text-sm sm:text-lg md:text-lg w-full text-center" 
								/>
							</div>
						</div>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2 text-center">
						<RangeSelect title="Fracture Spacing" selectedValues={fractureSpacing} setSelectedValue={setFractureSpacing} values={[
							{title: 'very widely', order: 1, description: '>2000mm'},
							{title: 'widely', order: 2, description: '2000mm - 600mm'},
							{title: 'medium', order: 3, description: '600mm -200mm'},
							{title: 'closely', order: 4, description: '200mm - 60mm'},
							{title: 'very closely', order: 5, description: '60mm - 20mm'},
							{title: 'extremely closely', order: 6, description: '<20mm'},
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-1 md:py-1 px-0"
						/>

						<div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 gap-3">
							<div className="">
								<InputGroup type="number" 
								label="Min (mm)" value={fractureMin} 
								onChange={(value) => setFractureMin(value)}
								className="mb-2 text-sm sm:text-base md:text-base w-full"
								inputCSS="text-sm sm:text-lg md:text-lg w-full text-center" 
								/>
							</div>

							<div className="">
								<InputGroup type="number" 
								label="Typical (mm)" 
								value={fractureTypical} 
								onChange={(value) => setFractureTypical(value)} 
								className="mb-2 text-sm sm:text-base md:text-base w-full"
								inputCSS="text-sm sm:text-lg md:text-lg w-full text-center" 
								/>
							</div>

							<div className="">
								<InputGroup type="number" 
								label="Max (mm)" 
								value={fractureMax} 
								onChange={(value) => setFractureMax(value)} 
								className="mb-2 text-sm sm:text-base md:text-base w-full"
								inputCSS="text-sm sm:text-lg md:text-lg w-full text-center" 
								/>
							</div>
						</div>
					</div>
					</>,
					show: true
				}, {
					tab: 'Staining',
					component: <>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2 text-center">
						<ExclusiveSelect title="Staining" selectedValue={staining} setSelectedValue={setStaining} values={[
							'unstained',
							'slight staining',
							'moderate staining',
							'heavy staining',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-1 sm:py-1 md:py-1 px-0 h-10"
						/>
					</div>
					</>,
					show: true
				}, {
					tab: 'Minor',
					component: <>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<RangeSelect title="Flint content" selectedValues={minorConstituentsChalkFlints} setSelectedValue={setMinorConstituentsChalkFlints} values={[
							{title: 'low', order: 1},
							{title: 'medium', order: 2},
							{title: 'high', order: 3},
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-1 md:py-1 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Minor Frequency" selectedValue={minorConstituentsChalkMinorFrequency} setSelectedValue={setMinorConstituentsChalkMinorFrequency} values={[
							'with rare',
							'with occasional',
							'with numerous',
							'with frequent',
							'with abundant',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-1 md:py-1 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Minor Items" selectedValue={minorConstituentsChalkMinorItems} setSelectedValue={setMinorConstituentsChalkMinorItems} values={[
							'black specks',
							'other (keyboard)',
						]}
						columns={2}
						columnsMobile={2}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-1 md:py-1 px-0"
						/>
					</div>

						{minorConstituentsChalkMinorItems === 'other (keyboard)' && (
							<InputGroup label={''} onChange={(value) => setMinorConstituentsChalkMinorItemsKeyboard(value)} value={minorConstituentsChalkMinorItemsKeyboard} placeholder='Enter here' />
						)}
					</>,
					show: true
				}, {
					tab: 'Comments',
					component: <Comment comment={comment} numberOfRows={5} setComment={setComment} title="Comments" />,
					show: true
				},
			],






			'Rock': [
				{
					tab: 'Type',
					component: <>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2 pt-0">
						<ExclusiveSelect title="Sedimentary" selectedValue={rockType} setSelectedValue={setRockType} values={[
							'Limestone',
							'Dolomite',
							'Sandstone',
							'Siltstone',
							'Gypsum',
							'Mudstone',
							'Chert',
							'Shale',
						]}
						columns={4}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-1 md:py-1 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Igneous" selectedValue={rockType} setSelectedValue={setRockType} values={[
							'Granite',
							'Diorite',
							'Dolerite',
							'Rhyolite',
							'Andersite',
							'Basalt',
							'Obsidian',
						]}
						columns={4}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-1 md:py-1 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Metamorphic" selectedValue={rockType} setSelectedValue={setRockType} values={[
							'Marble',
							'Quartzite',
							'Slate',
						]}
						columns={3}
						columnsMobile={3}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-1 md:py-1 px-0"
						/>
					</div>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
						<ExclusiveSelect title="Other" selectedValue={rockType} setSelectedValue={setRockType} values={[
							'Coal',
						]}
						columns={1}
						columnsMobile={1}
						className="text-xs sm:text-lg mx-1 py-2 sm:py-1 md:py-1 px-0 w-1/5 mx-auto"
						/>
					</div>

					</>,
					show: true
				}, {
					tab: 'Compressive Strength',
					component: <>

					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2 pt-0">
						<RangeSelect title="Compressive Strength" selectedValues={compressiveStrength} setSelectedValue={setCompressiveStrength} values={[
							{title: 'extremely weak', order: 1, description: 'Indented by thumbnail'},
							{title: 'very weak', order: 2, description: 'crumbles under firm blows with point of geological hammer'},
							{title: 'weak', order: 3, description: 'Shallow indentations made by firm blow with point of geological hammer'},
							{title: 'medium strong', order: 4, description: 'Specimen can be fractured with single blow of geological hammer'},
							{title: 'strong', order: 5, description: 'Specimen requires more than one blow of geological hammer to fracture'},
							{title: 'very strong', order: 6, description: 'Specimen can only be chipped with geological hammer'},
						]}
						columns={3}
						columnsMobile={1}
						className="text-base sm:text-lg md:text-2xl mx-1 py-1 sm:py-1 md:py-1 px-0"
						/>
					</div>
					</>,
					show: true
				}, {
					tab: 'Colour',
					component: <ColorType colorType={colorType} setColorType={setColorType} />,
					show: true
				}, {
					tab: 'Comments',
					component: <Comment comment={comment} numberOfRows={5} setComment={setComment} title="Comments" />,
					show: true
				},
			]
		}

		workflows[soilType].push({
			tab: 'Madeground',
			component: <>

			{/* <div className="border border-grey-mid rounded px-2 bg-sptTB mb-2 pt-0">
				<MultiSelect title="Madeground Composition" selectedValues={madegroundComposition} setSelectedValue={setMadegroundComposition} values={[
					'Topsoil with materials',
					'Sand with materials',
					'Gravel with materials',
					'S&G with materials',
					'Silt with materials',
					'Clay with materials',
					'Just materials',
				]}
				columns={4}
				columnsMobile={2}
				className="text-xs sm:text-lg mx-1 py-1 sm:py-0 px-0"
				/>
			</div> */}

			<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2 pt-0">
				<MultiSelect title="Materials" selectedValues={madegroundMaterials} setSelectedValue={setMadegroundMaterials} values={[
					'concrete',
					'clinker',
					'effluent waste',
					'asphalt',
					'tile fragments',
					'possible effluent waste',
					'masonry',
					'glass',
					'fabric',
					'asbestos',
					'paper',
					'organic matter',
					'possible asbestos',
					'plastics',
					'possible organic matter',
					'granular fill',
					'metal',
					'odorous smell',
					'household waste',
					'voids',
					'chemical waste',
					'possible household waste',
					'ash',
					'possible chemical waste',
					'brick fragments',
					'wood',
					'other',
				]}
				columns={4}
				columnsMobile={3}
				className="text-xs sm:text-lg mx-1 py-1 sm:py-0 px-0"
				/>
			</div>
			</>,
			show: madeground,
		})

		setSoilDescriptionLength(workflowLength(workflows[soilType]))

		return workflows[soilType]
	}

	return (
		<>
			<div className="z-10">
			{soilType === '' ?
				<WorkflowSteps nocheck={true} steps={workflowSteps(currentStep, currentWorkflow())} className="mb-2 w-36" />
				:
				<WorkflowSteps nocheck={true} steps={workflowSteps(currentStep, currentWorkflow())} className="mb-2" />
			}
			</div>
			<div className="bg-white w-full border border-grey-mid py-2 px-2 mb-2 shadow-sm text-sm sm:text-base md:text-xl">
				{generateDescription()}
			</div>

			{currentStep === 3 &&
				<>
					<div className="mb-2 text-center text-xs md:text-base lg:text-base">Proceed through these steps to automatically generate a BS5930 compliant description.</div>

					<div className="grid grid-cols-2 md:grid-cols-5 gap-2 md:gap-3 mb-4">
						{soilTypes.filter((st) => st).map((soilTypeLabel, index) =>
							<div className={`md:col-span-1 h-10 md:h-16 px-2 flex justify-center text-center items-center text-base md:text-lg border border-orange rounded ${soilType === soilTypeLabel ? 'bg-orange text-white' : 'bg-white'}`} onClick={() => changeSoilType(soilTypeLabel)} key={index}>
								{soilTypeLabel}
							</div>
						)}

						<div className={`md:col-span-1 h-10 md:h-16 px-2 flex justify-center text-center items-center text-base md:text-lg border border-orange rounded ${madeground ? 'bg-blue text-white' : 'bg-white'}`} onClick={() => setMadeground(!madeground)}>
							Madeground
						</div>
						<div className="col-span-2 text-sm text-center">For 'Madeground', select main Soil Type, then click 'Madeground'.<br/>If it's just materials, just select 'Madeground'.</div>
					</div>
				</>
			}

			{workflowComponents(currentStep, currentWorkflow())}

		</>
	)
}

SoilDescriptionGenerator.defaultProps = defaultProps

export default SoilDescriptionGenerator
