import React, { FunctionComponent, useState, Component } from 'react'
import { render } from "react-dom";
import { useLocation } from "react-router-dom";
import ReactDOMServer from 'react-dom/server';

import Header from '../layout/Header'

import Modal from '../components/Modal'
import WorkflowSteps from '../components/WorkflowSteps'
import NumberInput from '../components/NumberInput'
import Button from '../components/form/Button'
import InputGroup from '../components/form/InputGroup'
import ButtonOptions from '../components/form/ButtonOptions'
import WorkflowActions from '../components/form/WorkflowActions'
import CrewList from './CrewList'
import warningIcon from '../assets/icons/warning.png'
import RigSelect from './rigs/RigsSelect'

import { useGetLoggedInUser } from '../helpers/getLoggedInUser'

import stepStatus from '../helpers/stepStatus'

import Label from '../components/Label'
import { Link } from 'react-router-dom'
import RadioSelect from '../components/form/RadioSelect'

type Props = {
	onClose: any,
	// onComplete: any,
	borehole?: string,
	defaultValues?: any,
	projectId?: string,
	holeType: string,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => {},
	// onComplete: () => {},
	borehole: '',
	projectId: '',
	defaultValues: {
		guid: '',
		holeName: '',
		newHoleName: '',
		grid_northing: '',
		grid_easting: '',
	},
	holeType: '',
}



const NewHole: FunctionComponent<Props> = ({ onClose, borehole, defaultValues, holeType,  ...rest }) => {
	

	const loggedInUser = useGetLoggedInUser()
	// const holeType = defaultValues.holeType
	const [holeName, setHoleName] = useState(defaultValues.holeName)
	const [followOnHole, setFollowOnHole] = useState(defaultValues.followOnHole)
	const [newHoleName, setNewHoleName] = useState(defaultValues.newHoleName)
	const [gridNorthing, setGridNorthing] = useState(defaultValues.gridNorthing)
	const [gridEasting, setGridEasting] = useState(defaultValues.gridEasting)
	const [required_depth, setRequired_depth] = useState(defaultValues.boreholeRequiredDepth)
	const [samples_tests, setSamples_tests] = useState(defaultValues.boreholeRequiredTests)
	const [company_guid, setCompany_guid] = useState(defaultValues.company_guid)
	const [project_guid, setProject_guid] = useState(defaultValues.project_guid)

	// console.log(company_guid)
	// console.log(project_guid)
	

	let activity_type = ''
	let hole = ''
	let plantCheck = false
	let requiredDepth = false
	let otherRequirements = false
    let rig = false
	let userType = ''
    let secondMan = false
    let plantCheckCP =false
    let plantCheckRT =false
    let plantCheckWSDP =false
    let plantCheckLink = ''
	if (holeType ==='CP') {
		activity_type = 'Cable Percussive'
        hole = 'Cable Borehole'; plantCheck=true; rig=true; 
        userType = 'Driller'; secondMan=true; requiredDepth=true; otherRequirements=true;
        plantCheckCP = true;
        plantCheckLink ='/daily-plant-check-cable-percussive'
    }
	else if (holeType ==='RT') {
		activity_type = 'Rotary'
        hole = 'Rotary Borehole'; plantCheck=true; rig=true; 
        userType = 'Driller'; secondMan=true; requiredDepth=true; otherRequirements=true;
        plantCheckRT = true;
        plantCheckLink ='/daily-plant-check-rotary'
    }
	else if (holeType ==='WS') {
		activity_type = 'Window Sample'
        hole = 'Window Sample'; plantCheck=true; rig=true; 
        userType = 'Driller'; secondMan=true; requiredDepth=true; otherRequirements=true;
        plantCheckWSDP = true;
        plantCheckLink ='/daily-plant-check-window-sample'
    }
	else if (holeType ==='TP') {
		activity_type = 'Trialpit'
        userType = 'Engineer'; hole = 'Trialpit'; requiredDepth=true; otherRequirements=true;
    }
	else if (holeType ==='DP') {
		activity_type = 'Dynamic Probe';
        hole = 'Dynamic Probe'; plantCheck=true; rig=true; 
        userType = 'Driller'; secondMan=true; requiredDepth=true;
        plantCheckWSDP = true;
        plantCheckLink ='/daily-plant-check-window-sample'
    }
	else if (holeType ==='HT') {activity_type = 'Head Test'; hole = 'Head Test'; userType = 'Engineer'; }
	else if (holeType ==='LG') {activity_type = 'Logging'; hole = 'Borehole'; userType = 'Engineer';}
	else if (holeType ==='SA') {activity_type = 'Soakaway'; hole = 'Soakaway'; userType = 'Engineer';; requiredDepth=true}
	else if (holeType ==='CB') {activity_type = 'CBR'; hole = 'CBR'; userType = 'Engineer';}
	else if (holeType ==='PT') {activity_type = 'Plate Test'; hole = 'Plate Test'; userType = 'Engineer';}
	else if (holeType ==='TR') {activity_type = 'TRL'; hole = 'TRL'; userType = 'Engineer';}

    const [showPlantCheck, setShowPlantCheck] = useState(plantCheck)
    const [showRig, setShowRig] = useState(rig)
    const [showSecondMan, setShowSecondMan] = useState(secondMan)
    const [showRequiredDepth, setShowRequiredDepth] = useState(requiredDepth)
    const [showOtherRequirements, setShowOtherRequirements] = useState(otherRequirements)

    const [showCrewList, setShowCrewList] = useState(false)
    const openCrewList = () => setShowCrewList(true)

	const inputLabel = hole + ' Name (req)'

	const title = 'New ' + hole

	// console.log('Hole Type: ', holeType)
	// console.log('onClose: ', onClose)

	let isCable = false
	let isRotary = false
	let isHeadTest = false
	let isSoakaway = false
		if (holeType =='CP') {
			isCable = true
		} else if (holeType =='RT') {
			isRotary = true
		} else if (holeType =='HT') {
			isHeadTest = true
		} else if (holeType =='SA') {
			isSoakaway = true
		}

		const [showFollowOnCable, setShowFollowOnCable] = useState(isCable)
		const [showFollowOnRotary, setShowFollowOnRotary] = useState(isRotary)
		const [showHeadTestInfo, setShowHeadTestInfo] = useState(isHeadTest)
		const [showSoakawayInfo, setSoakawayInfo] = useState(isSoakaway)

		// console.log('showFollowOnCable: ', isCable)
		// console.log('showFollowOnRotary: ', isRotary)

	return (

		<Modal title={title} subtitle={borehole} toggleModal={onClose} >
				
		{showCrewList && 
			<CrewList onClose={() => { setShowCrewList(false) }} />
		}
		
		{/* {showPlantCheckCP && 
			<DailyPlantCheckCablePercussive  />
		}
		
		{showPlantCheckRT && 
			<DailyPlantCheckRotary onClose={() => { setShowPlantCheckRT(false) }} />
		}
		
		{showPlantCheckWSDP && 
			<DailyPlantCheckWindowSample onClose={() => { setShowPlantCheckWSDP(false) }} />
		} */}

				<div className="border border-grey-mid rounded px-2 pt-2 bg-sptTB mb-4 w-full sm:w-5/6 mx-auto text-center">
                    <div className="">
                        <InputGroup label= {inputLabel} required
                        value={newHoleName}
                        className="w-2/3 md:w-2/3 lg:w-1/3 mx-auto mb-2 text-xl"
                        onChange={(value: any) => setNewHoleName(value)} 
						inputCSS="text-2xl text-center"
                        />
                    </div>
				</div>

                <div className="border border-grey-mid rounded px-2 py-2 bg-sptTB mb-4 w-full sm:w-5/6 mx-auto">
                    {/* <div className="grid grid-cols-2 gap-2 mb-2 text-sm text-center">

                        <div className="grid grid-cols-3 gap-2 border border-grey-mid">
							<div className="text-right text-lg">Driller:</div>
							<div className="col-span-2 text-left text-lg">Rob Clews</div>
						</div>
						
                        <div className="grid grid-cols-3 gap-2 border border-grey-mid">
							<div className="text-right text-lg">Second Man:</div>
							<div className="col-span-2 text-left text-lg">Rod Lifter</div>
						</div>

                    </div> */}

					<table className="table-auto w-full md:w-5/6 text-sm text-center bg-white border border-grey-mid mx-auto text-base md:text-xl">

						<tr>
							<td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>{userType}:</td>
							<td className={`w-8/12 md:w-8/12 lg:w-1/2 p-1 pl-2 border-r border-b border-grey-mid text-left`}>
								<div onClick={openCrewList}>
								{loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name) }
								</div>
							</td>
						</tr>

						{showSecondMan &&
							<tr>
								<td className={`p-1 pr-2 border-r border-b border-grey-mid text-right`}>
									<img src={warningIcon} alt="Warning" width="20" className="inline-block mr-1 pb-1" />
									2nd Man:
								</td>
								<td className={`p-1 pl-2 border-r border-b border-grey-mid text-left`}>
								
								<div onClick={openCrewList}>
								(Select)
								</div>

								</td>
							</tr>
						}

						{showRig &&
							<tr>
								<td className={`p-1 pr-2 border-r border-b border-grey-mid text-right`}>
									<img src={warningIcon} alt="Warning" width="20" className="inline-block mr-1 pb-1" />
									Rig:
									</td>
								<td className={`pr-1 border-r border-b border-grey-mid text-left`}>

								<RigSelect 
									company={company_guid}
									project={project_guid}
									/>

								</td>
							</tr>
						}

						{showPlantCheck &&
							<tr>
								<td className={`px-0 py-1  border-r border-grey-mid text-center text-base md:text-xl font-bold`} colSpan={2}>
									<img src={warningIcon} alt="Warning" width="20" className="inline-block mr-2 pb-2" />
									<Link to={`${plantCheckLink}/${company_guid}/${project_guid}`}>
									{/* <Link to={plantCheckLink}> */}
										Daily Plant Check Not Completed Today!
									</Link>
								</td>
							</tr>
						}

					</table>
                </div>

				<div className="border border-grey-mid rounded px-2 py-2 bg-sptSB mb-4 w-full sm:w-5/6 mx-auto hidden">
					<div className="textCenter text-sm">Hidden Values</div>
					<div className="grid grid-cols-2 md:grid-cols-4 gap-1 mb-2 text-sm text-center">
						<div className="">
							<InputGroup label="Required Depth" 
							value={required_depth}
							className="w-full md:w-4/5 mx-auto"
							// onChange={(value: any) => setEditingBorehole({...editingBorehole, 'required_depth': value.target.value})}
							onChange={(value: any) => setRequired_depth(value)} 
							inputCSS="text-sm md:text-lg text-center"
							type="number" 
							/>
						</div>
						<div className="">
							<InputGroup label="Required Samples and Tests" 
							value={samples_tests}
							className="w-full md:w-4/5 mx-auto"
							// onChange={(value: any) => setEditingBorehole({...editingBorehole, 'samples': value.target.value})} 
							onChange={(value: any) => setSamples_tests(value)}  
							inputCSS="text-sm md:text-lg text-center" 
							/>
						</div>
						<div className="">
                            <InputGroup label="Geo North (optional)" 
                            // value={editingBorehole['grid_northing']}
                            value={gridNorthing}
                            className="w-full md:w-4/5 mx-auto"
                            // onChange={(value: any) => setEditingBorehole({...editingBorehole, 'grid_northing': value.target.value})}
                            onChange={(value: any) => setGridNorthing(value)} 
							inputCSS="text-lg text-center" 
							type="number"
                            />
                        </div>
                        <div className="">
                            <InputGroup label="Geo East (optional)"  
                            // value={editingBorehole['grid_easting']} 
                            value={gridEasting}
                            className="w-full md:w-4/5 mx-auto"
                            // onChange={(value: any) => setEditingBorehole({...editingBorehole, 'grid_easting': value.target.value})} 
                            onChange={(value: any) => setGridEasting(value)}   
							inputCSS="text-lg text-center" 
							type="number"
                            />
                        </div>
					</div>
				</div>

                <div className="mb-6">
					<Button theme="primary" size="lg" className="btn-lg m-auto w-44" >Save</Button>
				</div>




				{/* Cable Follow On */}

				{showFollowOnCable &&

					<div className="border border-grey-mid rounded px-2 py-2 bg-sptTB mb-2 w-full sm:w-5/6 mx-auto">

						<div className="text-center mt-2 mb-1 text-xl">
							Cable Percussive Follow On
						</div>

						<div className="text-center mb-1 italic text-xs">
							Continue an existing Rotary or Window Sample borehole. Depths and sample numbering will continue from where the previous borehole was completed!
						</div>
						
						<div className="w-full rounded px-4 bg-modal-bg">
						<ButtonOptions onUpdate={(value: any) => setFollowOnHole(value)} 
								title="" 
								defaultValue={followOnHole} 
								options={['RT001', 'RT002', 'RT003', 'RT004', 'RT005', 'WS001', 'WS002', 'WS003', 'WS004']} 
								columns={6} 
								columnsMobile={4} 
								className="mb-4 w-20 w-3/5 md:w-full text-sm md:text-lg py-3" 
								buttonHeight={12}
								buttonHeightMobile={10}
								/>
						</div>


						<div className="w-30">
							<Button theme="primary" size="lg" className="btn-lg m-auto" >Create Follow On</Button>
						</div>

					</div>

				}


				{/* Rotary Follow On */}

				{showFollowOnRotary &&

					<div className="border border-grey-mid rounded px-2 py-2 bg-sptTB mb-2 w-full sm:w-5/6 mx-auto">

						<div className="text-center mt-2 mb-1 text-xl">
							Rotary Follow On
						</div>

						<div className="text-center mb-1 italic text-xs">
							Continue an existing Cable Percussive or Window Sample borehole. Depths and sample numbering will continue from where the previous borehole was completed!
						</div>
						
						<div className="w-full rounded px-4 bg-modal-bg">
						<ButtonOptions onUpdate={(value: any) => setFollowOnHole(value)} 
								title="" 
								defaultValue={followOnHole} 
								options={['BH001', 'BH002', 'BH003', 'BH004', 'BH005', 'WS001', 'WS002', 'WS003', 'WS004']} 
								columns={6} 
								columnsMobile={4} 
								className="mb-4 w-20 w-3/5 md:w-full text-sm md:text-lg py-4" 
								buttonHeight={12}
								buttonHeightMobile={10}
								/>
						</div>


						<div className="w-30">
							<Button theme="primary" size="lg" className="btn-lg m-auto" >Create Follow On</Button>
						</div>

					</div>

				}


				{/* Head Test */}

				{showHeadTestInfo &&

					<div className="border border-grey-mid rounded px-2 py-2 bg-sptTB mb-2 w-full sm:w-5/6 mx-auto">

						<div className="text-center mt-2 mb-1 text-xl">
							Base Head Test on existing Borehole
						</div>

						<div className="text-center mb-1 italic text-xs">
							Populate the dimensions of the Head Test from an existing borehole.
						</div>
						
						<div className="w-full rounded px-4 bg-modal-bg">
						<ButtonOptions onUpdate={(value: any) => setFollowOnHole(value)} 
								title="" 
								defaultValue={followOnHole} 
								options={['BH001', 'BH002', 'BH003', 'BH004', 'BH005', 'RT001', 'RT002', 'RT003', 'RT004', 'RT005', 'WS001', 'WS002', 'WS003', 'WS004']} 
								columns={6} 
								columnsMobile={4} 
								className="mb-4 w-20 w-3/5 md:w-full text-sm md:text-lg py-4" 
								buttonHeight={12}
								buttonHeightMobile={10}
								/>
						</div>


						<div className="w-30">
							<Button theme="primary" size="lg" className="btn-lg m-auto" >Create Head Test</Button>
						</div>

					</div>

				}


				{/* Soakaway */}

				{showSoakawayInfo &&

					<div className="border border-grey-mid rounded px-2 py-2 bg-sptTB mb-2 w-full sm:w-5/6 mx-auto">

						<div className="text-center mt-2 mb-1 text-xl">
							Base Soakaway on existing Trialpit
						</div>

						<div className="text-center mb-1 italic text-xs">
							Populate the dimensions of the Soakaway from an existing trialpit.
						</div>
						
						<div className="w-full rounded px-4 bg-modal-bg">
						<ButtonOptions onUpdate={(value: any) => setFollowOnHole(value)} 
								title="" 
								defaultValue={followOnHole} 
								options={['TP001', 'TP002', 'TP003', 'TP004', 'TP005']} 
								columns={6} 
								columnsMobile={4} 
								className="mb-4 w-20 w-3/5 md:w-full text-sm md:text-lg py-4" 
								buttonHeight={12}
								buttonHeightMobile={10}
								/>
						</div>


						<div className="w-30">
							<Button theme="primary" size="lg" className="btn-lg m-auto" >Create Soakaway</Button>
						</div>

					</div>

				}

		</Modal>

	)
}

NewHole.defaultProps = defaultProps

export default NewHole