import React, { FunctionComponent, useRef, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useGetLoggedInUser } from '../helpers/getLoggedInUser'
import userIcon from '../assets/icons/user_icon.png'
import { get } from "idb-keyval"

type Props = {
	toggleSidebar: () => void
	items?: any[],
	children?: string,
}

const defaultProps: Props = {
	toggleSidebar: () => {},
	items: [
		{ label: "Projects", link: "/projects" },
		{ label: "My Profile", link: "/profile" },
		{ label: "divider" },
		{ label: "Log Out", link: "/logout" },
		{ label: "divider" },
		{ label: "Update", link: "/update" }
	],
	children: '',
}

const Sidebar: FunctionComponent<Props> = ({ toggleSidebar, items, children }) => {
	const node = useRef<HTMLDivElement | null>(null)
	const loggedInUser = useGetLoggedInUser()

	const handleClick = (event: MouseEvent) => {
		if (node.current && node.current.contains(event.target as Node)) {
			return
		}
		toggleSidebar()
	}

	useEffect(() => {
		document.addEventListener("mousedown", handleClick)
		return () => {
			document.removeEventListener("mousedown", handleClick)
		}
	}, [])

	const [companyInfo, setCompanyInfo] = useState({
		companyName: ''
	})

	useEffect(() => {
		const getCompanyInfoFromCache = async () => {
			const response = await get('drillit-user')
			setCompanyInfo({
				companyName: response?.user?.company?.name || ''
			})
		}
		getCompanyInfoFromCache()
	}, [])

	const companyName = companyInfo.companyName

	return (
		<div className="inset-0 fixed z-50">
			<div className="modalshadow bg-menu-bg text-menu-text fixed inset-y-0 right-0 width-sidebar py-2 flex flex-col" ref={node}>
				<div className="flex justify-end mb-2 px-5">
					<button className="text-4xl font-bold" onClick={toggleSidebar}>&times;</button>
				</div>

				<div className="flex flex-col flex-grow">
					{/* User Information Section */}
					<div className="flex items-center sidebar-user-info px-5 mb-4">
						<img src={userIcon} className="rounded-full shadow-xl sidebar-user-icon" alt="" />
						<div className="ml-3">
							<div className="text-lg leading-5 text-white sidebar-user-name">
								{loggedInUser && `${loggedInUser.user.first_name} ${loggedInUser.user.last_name}`}
							</div>
							<div className="text-xs leading-4 uppercase text-grey-dark sidebar-user-company">
								{companyName}
							</div>
						</div>
					</div>

					{/* Navigation Links */}
					<div className="flex-grow overflow-auto px-5">
						{items?.map((item: any, index: number) => {
							if (item.label === 'divider') {
								return <hr className="border-menu-line my-1 mx-0" key={index} />
							}
							return (
								<Link
									className="block bold py-2 px-0 w-full text-left focus:outline-none"
									to={item.link}
									key={index}
									onClick={toggleSidebar}
								>
									{item.label}
								</Link>
							)
						})}
					</div>

					{/* Footer Section */}
					<div className="py-4 text-right bg-gray-800 text-charcoal">
						<Link to={`/system`}>
							*
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

Sidebar.defaultProps = defaultProps

export default Sidebar

