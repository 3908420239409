import React, { FunctionComponent, useState, useEffect } from 'react'
// import {Link, useParams, useLocation} from 'react-router-dom'

import Button from '../form/Button'
// import Table from '../Table'
// import WorkflowActions from '../form/WorkflowActions'

import edit from '../../assets/icons/edit.svg'
import plusIcon from '../../assets/icons/plus-white.svg'
import { v4 as uuidv4 } from 'uuid'
import {useGetIDBValues} from "../../helpers/getIDBKey";

type Props = {
	defaultBoreholes: any[],
	projectId?: string,
	onChange: any,
	[x: string]: any;
}

const defaultProps: Props = {
	defaultBoreholes: [],
	projectId: '',
	onChange: () => {},
}

const EditBoreholes: FunctionComponent<Props> = ({ defaultBoreholes, projectId, onChange, ...rest }) => {
	const [boreholes, setBoreholes] = useState(defaultBoreholes)

	const [holeTypes, setHoleTypes] = useState([])

	const emptyBorehole = {
		'guid': uuidv4(),
		'project_guid': projectId,
		'hole_name': '',
		'depth': '',
		'activity_type': '',
		'starting_hole_type_id': '',
		'samples': '',
		'grid_northing': '',
		'grid_easting': '',
	}

	useEffect(() => {
		let cachedValues = useGetIDBValues
		cachedValues(`hole-types`, (data:any) => {
			setHoleTypes(data?.map((data:any) => ({ id: data.id, name: data.name })))
		}).then()
	}, [])

	const [boreholeFormOpen, setBoreholeFormOpen] = useState(true)

	const [editingBoreholeIndex, setEditingBoreholeIndex] = useState(-1)
	const [editingBorehole, setEditingBorehole] = useState(emptyBorehole)

	const editBorehole = (index: number) => {
		setEditingBoreholeIndex(index)
		setEditingBorehole(boreholes[index])
		setBoreholeFormOpen(true)
	}

	const addBorehole = () => {
		setEditingBoreholeIndex(-1)
		setEditingBorehole(emptyBorehole)
		setBoreholeFormOpen(true)
	}

	const saveForm = (event: any) => {
		event.preventDefault()

		// If adding
		if(editingBoreholeIndex === -1) {
			setBoreholes([...boreholes, editingBorehole])
		} else {
			let newBoreholes = [...boreholes]
			newBoreholes[editingBoreholeIndex] = editingBorehole
			setBoreholes(newBoreholes)
		}

		setBoreholeFormOpen(false)
		setEditingBorehole(emptyBorehole)
	}

	useEffect(() => {
		onChange(boreholes)
	}, [boreholes, onChange])

	return (
		<form onSubmit={saveForm}>

		<div>
			<Button theme="primary" className="mx-auto mb-6 mt-4" icon={plusIcon} onClick={() => addBorehole()}>Add Borehole</Button>
		</div>

		{boreholeFormOpen &&
			<div className="flex items-center border border-grey-mid p-1 mb-4">
				<div className="flex-1 px-1 w-5/12">
					<input type="text" className="form-control w-full" placeholder="Hole ID" value={editingBorehole['hole_name']} onChange={(value: any) => setEditingBorehole({...editingBorehole, 'hole_name': value.target.value})} required />
				</div>

				{/* <div className="flex-1 px-1">
					<input type="number" className="form-control w-full" placeholder="Depth (m)" />
				</div> */}

				<div className="flex-1 px-1 w-5/12">
					<select
						name="holeType"
						id="holeType"
						className="form-control w-full"
						// value={editingBorehole['type_id']}
						value={editingBorehole['starting_hole_type_id']}
						onChange={(value: any) => setEditingBorehole({...editingBorehole, 'activity_type': value.target.options[value.target.selectedIndex].textContent, 'starting_hole_type_id': value.target.value})}
					>
						<option value="null">-- select hole type --</option>
						{holeTypes.map(({ id, name }) => (
							<option key={id} value={id}>
								{name}
							</option>
						))}
					</select>
					<input type="hidden" value={editingBorehole['activity_type']}/>
				</div>

				{/* <div className="flex-3 px-1">
					<input type="text" className="form-control w-full" placeholder="Samples and Tests" value={editingBorehole['samples']} onChange={(value: any) => setEditingBorehole({...editingBorehole, 'samples': value.target.value})} />
				</div> */}

				{/* <div className="flex-1 px-1">
					<input type="text" className="form-control w-full" placeholder="Geo North" value={editingBorehole['grid_northing']} onChange={(value: any) => setEditingBorehole({...editingBorehole, 'grid_northing': value.target.value})} />
				</div> */}

				{/* <div className="flex-1 px-1">
					<input type="text" className="form-control w-full" placeholder="Geo East" value={editingBorehole['grid_easting']} onChange={(value: any) => setEditingBorehole({...editingBorehole, 'grid_easting': value.target.value})} />
				</div> */}

				<div className="px-1 w-2/12">
					<Button size="xs" theme="primary" type="submit" className="w-full">Save</Button>
				</div>
			</div>
		}

			<div className={`text-sm rounded-t overflow-hidden`}>
				<table className="table-auto w-full">
					<thead className="font-bold">
						<tr>
							<td className={`p-0 bg-grey-mid border border-grey-mid rounded-tl-sm w-5/12`}>
								<div className={`px-2 py-2`}>Hole ID</div>
							</td>

							{/* <td className={`p-0 bg-grey-mid border border-grey-mid`}>
								<div className={`px-2 py-2`}>Depth (m)</div>
							</td> */}

							<td className={`p-0 bg-grey-mid border border-grey-mid w-5/12`}>
								<div className={`px-2 py-2`}>Type</div>
							</td>

							{/* <td className={`p-0 bg-grey-mid border border-grey-mid`}>
								<div className={`px-2 py-2`}>Samples and Tests</div>
							</td> */}

							{/* <td className={`p-0 bg-grey-mid border border-grey-mid`}>
								<div className={`px-2 py-2`}>Geo North</div>
							</td> */}

							{/* <td className={`p-0 bg-grey-mid border border-grey-mid rounded-tr-sm`}>
								<div className={`px-2 py-2 border-r border-grey-light`}>Geo East</div>
							</td> */}

							<td className={`p-0 bg-grey-mid border border-grey-mid rounded-tr-sm w-2/12`}>
								<div className={`px-2 py-2 border-r border-grey-light`}></div>
							</td>
						</tr>
						<tr className="h-2"></tr>
					</thead>

					<tbody>
					{[...boreholes].reverse().map((borehole, index) => {

						// console.log('***************boreholes**************', boreholes)

						const originalIndex = boreholes.length - 1 - index; // Calculate the original index

						return (
							<React.Fragment key={index}>
								<tr className="border border-grey-mid bg-white mb-1">
									<td className="p-0 border-r border-grey-mid">
										<div className={`px-2 py-2`}>{borehole['hole_name']}</div>
									</td>

									{/* <td className="p-0 border-r border-grey-mid">
										<div className={`px-2 py-2`}></div>
									</td> */}

									<td className="p-0 border-r border-grey-mid">
										<div className={`px-2 py-2`}>{borehole['activity_type']}</div>
									</td>

									{/* <td className="p-0 border-r border-grey-mid">
										<div className={`px-2 py-2`}></div>
									</td> */}

									{/* <td className="p-0 border-r border-grey-mid">
										<div className={`px-2 py-2`}>{borehole['grid_northing']}</div>
									</td> */}

									{/* <td className="p-0 border-r border-grey-mid">
										<div className={`px-2 py-2`}>{borehole['grid_easting']}</div>
									</td> */}

									<td className="p-0 border-r border-grey-mid text-center">
										<div className={`px-2 py-2`}><img className={`inline`} 
											src={edit} width="20" alt="" 
											onClick={() => editBorehole(originalIndex)} />
										</div>
									</td>
								</tr>

								<tr className="h-2"></tr>
							</React.Fragment>
						)
					})}
					</tbody>
				</table>
			</div>
		</form>
	)
}

EditBoreholes.defaultProps = defaultProps

export default EditBoreholes
