import React, { FunctionComponent, useState , useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import Modal from '../components/Modal'

import logoOuter from '../assets/logo-icon-outer.svg'
import logoCenter from '../assets/logo-icon-center.svg'
import check from '../assets/icons/check.svg'
import Button from '../components/form/Button'
import { useGetIDBValues } from "../helpers/getIDBKey"
import { updateSelectedProject } from '../actions/SelectedProject'
import {v4 as uuidv4} from 'uuid';
import { useGetLoggedInUser } from '../helpers/getLoggedInUser'
import { useSetIndexedDBValues } from '../helpers/addToIDB'
import {del} from "idb-keyval/dist/cjs";


type Props = {
	onClose: any,
	project_location?: string,
	project_id?: string,
	company_guid?: string,
	company_id?: string,
	project_name?: string,
	project_guid?: string,
}

const defaultProps: Props = {
	onClose: () => { },
	project_location: '',
	project_id: '',
	company_guid: '',
	company_id: '',
	project_name: '',
	project_guid: '',
}

const RiskAssessment: FunctionComponent<Props> = ({ onClose, company_guid, company_id, project_location, project_id, project_guid, ...rest
}) => {
    

console.log('project_location:', project_location)
console.log('project_id:', project_id)
console.log('company_guid:', company_guid)
console.log('project_guid:', project_guid)

const selectedProject = useSelector((state: any) => state.selectedProject)
const dispatch = useDispatch()

// useEffect(() => {
//     updateEmptyProject()
// }, [projectID]);

    const loggedInUser = useGetLoggedInUser();
	const [loggedInDriller, setLoggedInDriller] = useState('')
	const [loggedInDrillerCSCS, setLoggedInDrillerCSCS] = useState('')
	const [loggedInDrillerID, setLoggedInDrillerID] = useState('')
	useEffect(() => {
		if (loggedInUser && loggedInUser.user) {
			setLoggedInDriller(`${loggedInUser.user.first_name} ${loggedInUser.user.last_name}`);
			setLoggedInDrillerCSCS(`${(loggedInUser.user as any).cscs_no}`);
			setLoggedInDrillerID(`${(loggedInUser.user as any).id}`);
		}
	}, [loggedInUser]);
    console.log('loggedInUser:', loggedInUser)

    const [risk_assessment_date, set_risk_assessment_date] = useState(new Date().toISOString().split('T')[0]);
    console.log('risk_assessment_date:', risk_assessment_date)
    
    const [correct_ppe, set_correct_ppe] = useState(null);
    const [additional_ppe, set_additional_ppe] = useState(null);
    const [site_specific_instruction, set_site_specific_instruction] = useState(null);
    const [main_contractor_instruction, set_main_contractor_instruction] = useState(null);
    const [ms_ra, set_ms_ra] = useState(null);
    const [safety_fencing_required, set_safety_fencing_required] = useState(null);
    const [hole_locations, set_hole_locations] = useState(null);
    const [safety_fencing_in_place, set_safety_fencing_in_place] = useState(null);
    const [work_details_methods, set_work_details_methods] = useState(null);
    const [access_suitable_for_support_vehicle, set_access_suitable_for_support_vehicle] = useState(null);
    const [restricted_working_hours, set_restricted_working_hours] = useState(null);
    const [environmental_issues, set_environmental_issues] = useState(null);
    const [hole_safety_assessible, set_hole_safety_assessible] = useState(null);
    const [safe_to_setup, set_safe_to_setup] = useState(null);
    const [safe_to_unload, set_safe_to_unload] = useState(null);
    const [safe_working_environment, set_safe_working_environment] = useState(null);
    const [other_issues, set_other_issues] = useState(null);
    const [other_issues_text, set_other_issues_text] = useState('');
    const [service_plans_available, set_service_plans_available] = useState(null);
    const [cat_in_date, set_cat_in_date] = useState(null);
    const [inspection_pit_dug, set_inspection_pit_dug] = useState(null);
    const [cat_survey_done, set_cat_survey_done] = useState(null);
    const [telephone, set_telephone] = useState(null);
    const [electricity, set_electricity] = useState(null);
    const [gas, set_gas] = useState(null);
    const [water, set_water] = useState(null);
    const [cable_tv, set_cable_tv] = useState(null);
    const [national_grid, set_national_grid] = useState(null);
    const [overhead_cables, set_overhead_cables] = useState(null);
    const [foul_drainage, set_foul_drainage] = useState(null);
    const [underground_structures, set_underground_structures] = useState(null);
    const [other_services_comments, set_other_services_comments] = useState('');
    const [others_marked_positions_of_underground_services, set_others_marked_positions_of_underground_services] = useState(null);
    const [signs_of_underground_services, set_signs_of_underground_services] = useState(null);
    const [lifted_services_covers, set_lifted_services_covers] = useState(null);
    const [cat_scanner_checked, set_cat_scanner_checked] = useState(null);
    const [area_cat_scanned_services_marked, set_area_cat_scanned_services_marked] = useState(null);
    const [safe_distance_overhead_cables, set_safe_distance_overhead_cables] = useState(null);
    const [high_pressure_gas_main, set_high_pressure_gas_main] = useState(null);
    const [undergound_high_voltage_main, set_undergound_high_voltage_main] = useState(null);
    const [known_services_in_hole_location, set_known_services_in_hole_location] = useState(null);
    const [any_known_site_hazards, set_any_known_site_hazards] = useState('');

    const handle_correct_ppe = (option:any) => {set_correct_ppe(option);};
    const handle_additional_ppe = (option:any) => {set_additional_ppe(option);};
    const handle_site_specific_instruction = (option:any) => {set_site_specific_instruction(option);};
    const handle_main_contractor_instruction = (option:any) => {set_main_contractor_instruction(option);};
    const handle_ms_ra = (option:any) => {set_ms_ra(option);};
    const handle_safety_fencing_required = (option:any) => {set_safety_fencing_required(option);};
    const handle_hole_locations = (option:any) => {set_hole_locations(option);};
    const handle_safety_fencing_in_place = (option:any) => {set_safety_fencing_in_place(option);};
    const handle_work_details_methods = (option:any) => {set_work_details_methods(option);};
    const handle_access_suitable_for_support_vehicle = (option:any) => {set_access_suitable_for_support_vehicle(option);};
    const handle_restricted_working_hours = (option:any) => {set_restricted_working_hours(option);};
    const handle_environmental_issues = (option:any) => {set_environmental_issues(option);};
    const handle_hole_safety_assessible = (option:any) => {set_hole_safety_assessible(option);};
    const handle_safe_to_setup = (option:any) => {set_safe_to_setup(option);};
    const handle_safe_to_unload = (option:any) => {set_safe_to_unload(option);};
    const handle_safe_working_environment = (option:any) => {set_safe_working_environment(option);};
    const handle_other_issues = (option:any) => {set_other_issues(option);};
    const handle_service_plans_available = (option:any) => {set_service_plans_available(option);};
    const handle_cat_in_date = (option:any) => {set_cat_in_date(option);};
    const handle_inspection_pit_dug = (option:any) => {set_inspection_pit_dug(option);};
    const handle_cat_survey_done = (option:any) => {set_cat_survey_done(option);};
    const handle_telephone = (option:any) => {set_telephone(option);};
    const handle_electricity = (option:any) => {set_electricity(option);};
    const handle_gas = (option:any) => {set_gas(option);};
    const handle_water = (option:any) => {set_water(option);};
    const handle_cable_tv = (option:any) => {set_cable_tv(option);};
    const handle_national_grid = (option:any) => {set_national_grid(option);};
    const handle_overhead_cables = (option:any) => {set_overhead_cables(option);};
    const handle_foul_drainage = (option:any) => {set_foul_drainage(option);};
    const handle_underground_structures = (option:any) => {set_underground_structures(option);};
    const handle_other_services_comments = (option:any) => {set_other_services_comments(option);};
    const handle_others_marked_positions_of_underground_services = (option:any) => {set_others_marked_positions_of_underground_services(option);};
    const handle_signs_of_underground_services = (option:any) => {set_signs_of_underground_services(option);};
    const handle_lifted_services_covers = (option:any) => {set_lifted_services_covers(option);};
    const handle_cat_scanner_checked = (option:any) => {set_cat_scanner_checked(option);};
    const handle_area_cat_scanned_services_marked = (option:any) => {set_area_cat_scanned_services_marked(option);};
    const handle_safe_distance_overhead_cables = (option:any) => {set_safe_distance_overhead_cables(option);};
    const handle_high_pressure_gas_main = (option:any) => {set_high_pressure_gas_main(option);};
    const handle_undergound_high_voltage_main = (option:any) => {set_undergound_high_voltage_main(option);};
    const handle_known_services_in_hole_location = (option:any) => {set_known_services_in_hole_location(option);};
    const handle_any_known_site_hazards = (option:any) => {set_any_known_site_hazards(option);};


    const newRiskAssessment = {
        guid: uuidv4(),
        project_guid: project_guid,
        company_guid: company_guid,
        company_id: company_id,
        project_id: project_id,
        project_name: project_location,
        project_location: project_location,
        risk_assessment_date: risk_assessment_date,
        driller_id: loggedInDrillerID,
        driller_name: loggedInDriller,
        driller_cscs: loggedInDrillerCSCS,

        correct_ppe: correct_ppe,
        additional_ppe: additional_ppe,
        site_specific_instruction: site_specific_instruction,
        main_contractor_instruction: main_contractor_instruction,
        ms_ra: ms_ra,
        safety_fencing_required: safety_fencing_required,
        hole_locations: hole_locations,
        safety_fencing_in_place: safety_fencing_in_place,
        work_details_methods: work_details_methods,
        access_suitable_for_support_vehicle: access_suitable_for_support_vehicle,
        restricted_working_hours: restricted_working_hours,
        environmental_issues: environmental_issues,
        hole_safety_assessible: hole_safety_assessible,
        safe_to_setup: safe_to_setup,
        safe_to_unload: safe_to_unload,
        safe_working_environment: safe_working_environment,
        other_issues: other_issues_text,
        service_plans_available: service_plans_available,
        cat_in_date: cat_in_date,
        inspection_pit_dug: inspection_pit_dug,
        cat_survey_done: cat_survey_done,
        telephone: telephone,
        electricity: electricity,
        gas: gas,
        water: water,
        cable_tv: cable_tv,
        national_grid: national_grid,
        overhead_cables: overhead_cables,
        foul_drainage: foul_drainage,
        underground_structures: underground_structures,
        other_services_comments: other_services_comments,
        others_marked_positions_of_underground_services: others_marked_positions_of_underground_services,
        signs_of_underground_services: signs_of_underground_services,
        lifted_services_covers: lifted_services_covers,
        cat_scanner_checked: cat_scanner_checked,
        area_cat_scanned_services_marked: area_cat_scanned_services_marked,
        safe_distance_overhead_cables: safe_distance_overhead_cables,
        high_pressure_gas_main: high_pressure_gas_main,
        undergound_high_voltage_main: undergound_high_voltage_main,
        known_services_in_hole_location: known_services_in_hole_location,
        any_known_site_hazards: any_known_site_hazards,
    }

    console.log('newRiskAssessment:', newRiskAssessment)

    const newRiskAssessmentToSave = [newRiskAssessment]

    const save = () => {
        console.log('newRiskAssessment:', newRiskAssessment);
    
        // Delete any existing -new records
        del(`drillit-risk-assessment/project/${project_guid}-new`).then(() => {
            console.log('Deleted existing -new record successfully');
        }).catch(error => {
            // console.error('Error deleting existing -new record:', error);
        });
    
        let saveToIDB = useSetIndexedDBValues;
    
        saveToIDB(newRiskAssessment, `risk-assessment/project/${project_guid}`, true);
    
        // got to look for the response - TODO
    
        // Delete the '-new' record from IndexedDB
        // del(`drillit-risk-assessment/project/${project_guid}-new`).then(() => {
        //     console.log('New risk assessment deleted successfully from IndexedDB');
        // }).catch(error => {
        //     console.error('Error deleting new risk assessment from IndexedDB:', error);
        // });
    
        onClose();
    }

    // const save = () => {
    //     saveRAToIDB(newRiskAssessment, project_guid, true);
    // }

    function saveRAToIDB(newRiskAssessment:any, project_guid:any, shouldDelete:any) {
        // Perform the API call to save the new risk assessment
        fetch(`/risk-assessment/project/${project_guid}`, {
            method: 'POST',
            body: JSON.stringify(newRiskAssessment),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            // Check if the response status is 200
            if (response.status === 200 && shouldDelete) {
                // If status is 200 and shouldDelete is true, delete the request
                console.log('Status 200.... cleanup in progress')
                // del(`drillit-hole-locations/project/json-updateHoles`)
                //     .then(() => console.log('Request deleted successfully'))
                //     .catch(err => console.error('Error deleting request:', err));
            }
            // Return the response
            return response;
        })
        .then(response => {
            // Handle the response as needed
            // For example, you can return a resolved promise with the response
            if (response.status === 200) {
                console.log('Request was successful');
                // Perform additional actions if needed
            }
            return Promise.resolve(response);
        })
        .catch(err => {
            // Handle any errors
            console.error('Error saving to IDB:', err);
            // Return a rejected promise with the error
            return Promise.reject(err);
        });
    }

    
  
    return ( 
        
        <>
    
                <Modal title='Site Specific Risk Assessment' subtitle='' toggleModal={onClose} confirm={false} >

					
                <form onSubmit={(e) => {
						e.preventDefault(); // Prevent the default form submission
						save();
					}}>

                    <div className="bg-sptTB p-2 mx-2 mb-2 rounded text-center text-sm md:text-base">
                        <div>
                            <span className="font-bold">Instruction for completion:</span> Person allocated as the onsite lead carries out a risk assessment, 
                            referring to and completing this form before starting work. 
                        </div>
                        <div>
                            (Key: <span className="font-bold">M/S</span>=Method Statement, 
                            <span className="font-bold"> U/H</span>=Underground <span className="font-bold"></span> O/H=Overhead, 
                            <span className="font-bold"> CAT</span>=Cable Avoidance Tool, <span className="font-bold"> PPE</span>=Personal Protective Equipment).
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 bg-sptTB p-2 mx-2 mb-2 rounded text-center text-lg">
                        <div className="flex">
                            <div className="font-bold text-right py-1 pr-1 w-1/3">
                                Project ID:
                            </div>
                            <div className="border border-grey-mid py-1 w-2/3 bg-white">
                                {project_id}
                            </div>
                        </div>
                        <div className="flex">
                            <div className="font-bold text-right py-1 pr-1 w-1/3">
                                Location:
                            </div>
                            <div className="border border-grey-mid py-1 w-2/3 bg-white">
                                {project_location}
                            </div>
                        </div>
                    </div>

                    <div className="bg-sptTB p-2 mx-2 mb-2 rounded text-center text-sm md:text-base">
                        <span className="font-bold">Mandatory PPE minimum requirement: </span> Hard hat, Hi Vis top, Hi Vis trousers, Safety boots, Gloves & Safety glasses.
                        <div>
                            <span className="font-bold mt-2">Items to be briefed before work start: </span>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-3 gap-0 mt-1">
                            
                            <div className="grid grid-cols-9">
                                <div className="col-span-7 text-base text-right pr-1 pt-0.5 mb-1">
                                    Correct PPE available & worn
                                </div>
                                <div className={`h-8 border ${correct_ppe === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${correct_ppe === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_correct_ppe('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${correct_ppe === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${correct_ppe === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_correct_ppe('N')}>
                                    N
                                </div>
                            </div>

                            <div className="grid grid-cols-9">
                                <div className="col-span-6 text-base text-right pr-1 pt-0.5 mb-1">
                                    Additional PPE required
                                </div>
                                <div className={`h-8 border ${additional_ppe === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${additional_ppe === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_additional_ppe('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${additional_ppe === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${additional_ppe === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_additional_ppe('N')}>
                                    N
                                </div>
                                <div className={`h-8 border ${additional_ppe === 'N/A' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${additional_ppe === 'N/A' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_additional_ppe('N/A')}>
                                    N/A
                                </div>
                            </div>
                            
                            <div className="grid grid-cols-9">
                                <div className="col-span-6 text-base text-right pr-1 pt-0.5 mb-1">
                                    Specific site instruction
                                </div>
                                <div className={`h-8 border ${site_specific_instruction === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${site_specific_instruction === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_site_specific_instruction('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${site_specific_instruction === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${site_specific_instruction === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_site_specific_instruction('N')}>
                                    N
                                </div>
                                <div className={`h-8 border ${site_specific_instruction === 'N/A' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${site_specific_instruction === 'N/A' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_site_specific_instruction('N/A')}>
                                    N/A
                                </div>
                            </div>

                            <div className="grid grid-cols-9">
                                <div className="col-span-6 text-base text-right pr-1 pt-0.5 mb-1">
                                    Main contractor given instruction
                                </div>
                                <div className={`h-8 border ${main_contractor_instruction === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${main_contractor_instruction === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_main_contractor_instruction('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${main_contractor_instruction === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${main_contractor_instruction === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_main_contractor_instruction('N')}>
                                    N
                                </div>
                                <div className={`h-8 border ${main_contractor_instruction === 'N/A' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${main_contractor_instruction === 'N/A' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_main_contractor_instruction('N/A')}>
                                    N/A
                                </div>
                            </div>

                            <div className="grid grid-cols-9">
                                <div className="col-span-6 text-base text-right pr-1 pt-0.5 mb-1">
                                    Method Statement & Risk Assessment
                                </div>
                                <div className={`h-8 border ${ms_ra === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${ms_ra === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_ms_ra('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${ms_ra === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${ms_ra === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_ms_ra('N')}>
                                    N
                                </div>
                                <div className={`h-8 border ${ms_ra === 'N/A' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${ms_ra === 'N/A' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_ms_ra('N/A')}>
                                    N/A
                                </div>
                            </div>
                            
                            <div className="grid grid-cols-9">
                                <div className="col-span-6 text-base text-right pr-1 pt-0.5 mb-1">
                                    Safety fencing required
                                </div>
                                <div className={`h-8 border ${safety_fencing_required === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${safety_fencing_required === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_safety_fencing_required('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${safety_fencing_required === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${safety_fencing_required === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_safety_fencing_required('N')}>
                                    N
                                </div>
                                <div className={`h-8 border ${safety_fencing_required === 'N/A' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${safety_fencing_required === 'N/A' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_safety_fencing_required('N/A')}>
                                    N/A
                                </div>
                            </div>

                            <div className="grid grid-cols-9">
                                <div className="col-span-6 text-base text-right pr-1 pt-0.5 mb-1">
                                    Safety fencing available & in place
                                </div>
                                <div className={`h-8 border ${safety_fencing_in_place === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${safety_fencing_in_place === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_safety_fencing_in_place('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${safety_fencing_in_place === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${safety_fencing_in_place === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_safety_fencing_in_place('N')}>
                                    N
                                </div>
                                <div className={`h-8 border ${safety_fencing_in_place === 'N/A' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${safety_fencing_in_place === 'N/A' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_safety_fencing_in_place('N/A')}>
                                    N/A
                                </div>
                            </div>
                            
                            <div className="grid grid-cols-9">
                                <div className="col-span-6 text-base text-right pr-1 pt-0.5 mb-1">
                                    Hole locations
                                </div>
                                <div className={`h-8 border ${hole_locations === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${hole_locations === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_hole_locations('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${hole_locations === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${hole_locations === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_hole_locations('N')}>
                                    N
                                </div>
                                <div className={`h-8 border ${hole_locations === 'N/A' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${hole_locations === 'N/A' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_hole_locations('N/A')}>
                                    N/A
                                </div>
                            </div>

                            <div className="grid grid-cols-9">
                                <div className="col-span-6 text-base text-right pr-1 pt-0.5 mb-1">
                                    Work details & methods
                                </div>
                                <div className={`h-8 border ${work_details_methods === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${work_details_methods === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_work_details_methods('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${work_details_methods === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${work_details_methods === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_work_details_methods('N')}>
                                    N
                                </div>
                                <div className={`h-8 border ${work_details_methods === 'N/A' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${work_details_methods === 'N/A' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_work_details_methods('N/A')}>
                                    N/A
                                </div>
                            </div>
                            
                            <div className="grid grid-cols-9">
                                <div className="col-span-6 text-base text-right pr-1 pt-0.5 mb-1">
                                    Access suitable for support vehicle
                                </div>
                                <div className={`h-8 border ${access_suitable_for_support_vehicle === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${access_suitable_for_support_vehicle === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_access_suitable_for_support_vehicle('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${access_suitable_for_support_vehicle === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${access_suitable_for_support_vehicle === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_access_suitable_for_support_vehicle('N')}>
                                    N
                                </div>
                                <div className={`h-8 border ${access_suitable_for_support_vehicle === 'N/A' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${access_suitable_for_support_vehicle === 'N/A' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_access_suitable_for_support_vehicle('N/A')}>
                                    N/A
                                </div>
                            </div>

                            <div className="grid grid-cols-9">
                                <div className="col-span-6 text-base text-right pr-1 pt-0.5 mb-1">
                                    Restricted working hours
                                </div>
                                <div className={`h-8 border ${restricted_working_hours === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${restricted_working_hours === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_restricted_working_hours('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${restricted_working_hours === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${restricted_working_hours === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_restricted_working_hours('N')}>
                                    N
                                </div>
                                <div className={`h-8 border ${restricted_working_hours === 'N/A' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${restricted_working_hours === 'N/A' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_restricted_working_hours('N/A')}>
                                    N/A
                                </div>
                            </div>

                            <div className="grid grid-cols-9">
                                <div className="col-span-6 text-base text-right pr-1 pt-0.5 mb-1">
                                    Are there any Environmental issues <span className="italic">(tree roots, nesting birds, water etc.)</span>
                                </div>
                                <div className={`h-8 border ${environmental_issues === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${environmental_issues === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_environmental_issues('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${environmental_issues === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${environmental_issues === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_environmental_issues('N')}>
                                    N
                                </div>
                                <div className={`h-8 border ${environmental_issues === 'N/A' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${environmental_issues === 'N/A' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_environmental_issues('N/A')}>
                                    N/A
                                </div>
                            </div>
                            
                            <div className="grid grid-cols-9">
                                <div className="col-span-6 text-base text-right pr-1 pt-0.5 mb-1">
                                    Hole safety assessible
                                </div>
                                <div className={`h-8 border ${hole_safety_assessible === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${hole_safety_assessible === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_hole_safety_assessible('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${hole_safety_assessible === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${hole_safety_assessible === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_hole_safety_assessible('N')}>
                                    N
                                </div>
                                <div className={`h-8 border ${hole_safety_assessible === 'N/A' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${hole_safety_assessible === 'N/A' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_hole_safety_assessible('N/A')}>
                                    N/A
                                </div>
                            </div>

                            <div className="grid grid-cols-9">
                                <div className="col-span-6 text-base text-right pr-1 pt-0.5 mb-1">
                                    Safe place to unload
                                </div>
                                <div className={`h-8 border ${safe_to_unload === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${safe_to_unload === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_safe_to_unload('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${safe_to_unload === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${safe_to_unload === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_safe_to_unload('N')}>
                                    N
                                </div>
                                <div className={`h-8 border ${safe_to_unload === 'N/A' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${safe_to_unload === 'N/A' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_safe_to_unload('N/A')}>
                                    N/A
                                </div>
                            </div>

                            <div className="grid grid-cols-9">
                                <div className="col-span-6 text-base text-right pr-1 pt-0.5 mb-1">
                                    Safe to set up
                                </div>
                                <div className={`h-8 border ${safe_to_setup === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${safe_to_setup === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_safe_to_setup('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${safe_to_setup === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${safe_to_setup === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_safe_to_setup('N')}>
                                    N
                                </div>
                                <div className={`h-8 border ${safe_to_setup === 'N/A' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${safe_to_setup === 'N/A' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_safe_to_setup('N/A')}>
                                    N/A
                                </div>
                            </div>
                            
                            <div className="grid grid-cols-9">
                                <div className="col-span-6 text-base text-right pr-1 pt-0.5 mb-1">
                                    Safe working environment
                                </div>
                                <div className={`h-8 border ${safe_working_environment === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${safe_working_environment === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_safe_working_environment('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${safe_working_environment === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${safe_working_environment === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_safe_working_environment('N')}>
                                    N
                                </div>
                                <div className={`h-8 border ${safe_working_environment === 'N/A' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${safe_working_environment === 'N/A' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_safe_working_environment('N/A')}>
                                    N/A
                                </div>
                            </div>
                            
                            <div className="grid grid-cols-9">
                                <div className="col-span-7 text-base text-right pr-1 pt-0.5 mb-1">
                                    Other issues (state below)
                                </div>
                                <div className={`h-8 border ${other_issues === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${other_issues === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_other_issues('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${other_issues === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${other_issues === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_other_issues('N')}>
                                    N
                                </div>
                            </div>

                        </div>

                        
                        <div className="font-bold">
                            Please state comments or actions to be taken if any of the above are not initially accepted:
                            </div>
                        
                        <div className="border border-grey-mid bg-white h-20 rounded text-sm md:text-base">
                            <textarea
                                value={other_issues_text}
                                onChange={(e) => set_other_issues_text(e.target.value)}
                                className="w-full h-full rounded px-2 py-2"
                                placeholder="Enter any other issues..."
                                rows={4}
                            />
                        </div>

                    </div>
*
                    <div className="bg-sptTB p-2 mx-2 mb-2 rounded text-center text-sm md:text-base">
                        

                        <div className="grid grid-cols-1 md:grid-cols-3 gap-0 mt-1">

                            <div className="col-span-1 md:col-span-3 text-lg">
                                <span className="font-bold mt-2">Permit to dig</span>
                            </div>
                            
                            <div className="grid grid-cols-9 mt-1">
                                <div className="col-span-7 text-right pr-1 pt-0.5 mb-1">
                                    Are service plans available
                                </div>
                                <div className={`h-8 border ${service_plans_available === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${service_plans_available === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_service_plans_available('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${service_plans_available === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${service_plans_available === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_service_plans_available('N')}>
                                    N
                                </div>
                            </div>
                            
                            <div className="grid grid-cols-9 mt-1">
                                <div className="col-span-7 text-right pr-1 pt-0.5 mb-1">
                                    CAT in date
                                </div>
                                <div className={`h-8 border ${cat_in_date === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${cat_in_date === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_cat_in_date('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${cat_in_date === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${cat_in_date === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_cat_in_date('N')}>
                                    N
                                </div>
                            </div>
                            
                            <div className="grid grid-cols-9 mt-1">
                                <div className="col-span-7 text-right pr-1 pt-0.5 mb-1">
                                    Inspection pit dug to 1.2m
                                </div>
                                <div className={`h-8 border ${inspection_pit_dug === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${inspection_pit_dug === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_inspection_pit_dug('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${inspection_pit_dug === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${inspection_pit_dug === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_inspection_pit_dug('N')}>
                                    N
                                </div>
                            </div>
                            
                            <div className="grid grid-cols-9 mt-1">
                                <div className="col-span-8 text-right pr-1 pt-0.5 mb-1">
                                    Tick when CAT Survey done
                                </div>
                                <div className={`text-lg h-8 border ${cat_survey_done === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-white bg-white mb-1 ${cat_survey_done === 'Y' ? 'text-blue RAselected' : ''}`} onClick={() => handle_cat_survey_done('Y')}>
                                    &#10003;
                                </div>
                            </div>
                            
                            <div className="col-span-1 md:col-span-2 mt-1">

                                <div className="text-base text-center md:text-left pl-1 pt-0.5 mb-1 font-bold">
                                    MUST BE TICKED BEFORE SITE WORK CAN START
                                </div>

                            </div>

                            <div className="col-span-1 md:col-span-3 text-base text-center md:text-left pr-1 pt-0.5 mb-1 font-bold">
                                Evidence of the following on site?
                            </div>

                            <div className="grid grid-cols-9 mt-1">
                                <div className="col-span-7 text-sm md:text-base text-right pr-1 pt-0.5 mb-1">
                                    Telephone
                                </div>
                                <div className={`h-8 border ${telephone === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${telephone === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_telephone('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${telephone === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${telephone === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_telephone('N')}>
                                    N
                                </div>
                            </div>

                            <div className="grid grid-cols-9 mt-1">
                                <div className="col-span-7 text-sm md:text-base text-right pr-1 pt-0.5 mb-1">
                                    Electricity
                                </div>
                                <div className={`h-8 border ${electricity === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${electricity === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_electricity('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${electricity === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${electricity === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_electricity('N')}>
                                    N
                                </div>
                            </div>

                            <div className="grid grid-cols-9 mt-1">
                                <div className="col-span-7 text-sm md:text-base text-right pr-1 pt-0.5 mb-1">
                                    Gas
                                </div>
                                <div className={`h-8 border ${gas === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${gas === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_gas('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${gas === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${gas === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_gas('N')}>
                                    N
                                </div>
                            </div>

                            <div className="grid grid-cols-9 mt-1">
                                <div className="col-span-7 text-sm md:text-base text-right pr-1 pt-0.5 mb-1">
                                    Water
                                </div>
                                <div className={`h-8 border ${water === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${water === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_water('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${water === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${water === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_water('N')}>
                                    N
                                </div>
                            </div>


                            <div className="grid grid-cols-9 mt-1">
                                <div className="col-span-7 text-sm md:text-base text-right pr-1 pt-0.5 mb-1">
                                    Cable TV
                                </div>
                                <div className={`h-8 border ${cable_tv === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${cable_tv === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_cable_tv('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${cable_tv === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${cable_tv === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_cable_tv('N')}>
                                    N
                                </div>
                            </div>

                            <div className="grid grid-cols-9 mt-1">
                                <div className="col-span-7 text-sm md:text-base text-right pr-1 pt-0.5 mb-1">
                                    National Grid
                                </div>
                                <div className={`h-8 border ${national_grid === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${national_grid === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_national_grid('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${national_grid === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${national_grid === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_national_grid('N')}>
                                    N
                                </div>
                            </div>

                            <div className="grid grid-cols-9 mt-1">
                                <div className="col-span-7 text-sm md:text-base text-right pr-1 pt-0.5 mb-1">
                                    Overhead Cables
                                </div>
                                <div className={`h-8 border ${overhead_cables === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${overhead_cables === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_overhead_cables('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${overhead_cables === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${overhead_cables === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_overhead_cables('N')}>
                                    N
                                </div>
                            </div>

                            <div className="grid grid-cols-9 mt-1">
                                <div className="col-span-7 text-sm md:text-base text-right pr-1 pt-0.5 mb-1">
                                    Foul drainage
                                </div>
                                <div className={`h-8 border ${foul_drainage === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${foul_drainage === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_foul_drainage('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${foul_drainage === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${foul_drainage === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_foul_drainage('N')}>
                                    N
                                </div>
                            </div>

                            <div className="grid grid-cols-9 mt-1">
                                <div className="col-span-7 text-sm md:text-base text-right pr-1 pt-0.5 mb-1">
                                    Underground structures
                                </div>
                                <div className={`h-8 border ${underground_structures === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${underground_structures === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_underground_structures('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${underground_structures === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${underground_structures === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_underground_structures('N')}>
                                    N
                                </div>
                            </div>

                        </div>
                        
                        <div className="text-base text-left pl-1 pt-0.5 mb-1 font-bold">
                            Please state other services or comments (state nil if none)
                        </div>

                        <div className="col-span-1 md:col-span-3 h-20 border border-grey-mid bg-white ">
                        <textarea
                            value={other_services_comments}
                            onChange={(e) => set_other_services_comments(e.target.value)}
                            className="w-full h-full rounded px-2 py-2"
                            placeholder="Other services or comments..."
                            rows={4}
                        />
                        </div>



                    </div>

                    

                    <div className="bg-sptTB p-2 mx-2 mb-2 rounded text-center text-sm md:text-base">
                        
                        {/* <div className="grid grid-cols-1 gap-1 mt-1"> */}

                            <div className="grid grid-cols-9 md:grid-cols-12 mt-1">
                                <div className="col-span-6 md:col-span-9 text-right pr-1 pt-0.5 mb-1">
                                Have others (client) marked positions of Underground services on site?
                                </div>
                                <div className={`h-8 border ${others_marked_positions_of_underground_services === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${others_marked_positions_of_underground_services === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_others_marked_positions_of_underground_services('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${others_marked_positions_of_underground_services === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${others_marked_positions_of_underground_services === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_others_marked_positions_of_underground_services('N')}>
                                    N
                                </div>
                                <div className={`h-8 border ${others_marked_positions_of_underground_services === 'N/A' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${others_marked_positions_of_underground_services === 'N/A' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_others_marked_positions_of_underground_services('N/A')}>
                                    N/A
                                </div>
                            </div>

                            <div className="grid grid-cols-9 md:grid-cols-12 mt-1">
                                <div className="col-span-6 md:col-span-9 text-right pr-1 pt-0.5 mb-1">
                                Are there signs of U/G services, cover plates, distribution boxes, marker posts, lights, cameras etc?
                                </div>
                                <div className={`h-8 border ${signs_of_underground_services === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${signs_of_underground_services === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_signs_of_underground_services('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${signs_of_underground_services === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${signs_of_underground_services === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_signs_of_underground_services('N')}>
                                    N
                                </div>
                                <div className={`h-8 border ${signs_of_underground_services === 'N/A' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${signs_of_underground_services === 'N/A' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_signs_of_underground_services('N/A')}>
                                    N/A
                                </div>
                            </div>

                            <div className="grid grid-cols-9 md:grid-cols-12 mt-1">
                                <div className="col-span-6 md:col-span-9 text-right pr-1 pt-0.5 mb-1">
                                Have you lifted service covers to check direction of services?
                                </div>
                                <div className={`h-8 border ${lifted_services_covers === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${lifted_services_covers === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_lifted_services_covers('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${lifted_services_covers === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${lifted_services_covers === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_lifted_services_covers('N')}>
                                    N
                                </div>
                                <div className={`h-8 border ${lifted_services_covers === 'N/A' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${lifted_services_covers === 'N/A' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_lifted_services_covers('N/A')}>
                                    N/A
                                </div>
                            </div>

                            <div className="grid grid-cols-9 md:grid-cols-12 mt-1">
                                <div className="col-span-6 md:col-span-9 text-right pr-1 pt-0.5 mb-1">
                                Has CAT scanner been checked to confirm it is working?
                                </div>
                                <div className={`h-8 border ${cat_scanner_checked === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${cat_scanner_checked === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_cat_scanner_checked('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${cat_scanner_checked === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${cat_scanner_checked === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_cat_scanner_checked('N')}>
                                    N
                                </div>
                                <div className={`h-8 border ${cat_scanner_checked === 'N/A' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${cat_scanner_checked === 'N/A' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_cat_scanner_checked('N/A')}>
                                    N/A
                                </div>
                            </div>

                            <div className="grid grid-cols-9 md:grid-cols-12 mt-1">
                                <div className="col-span-6 md:col-span-9 text-right pr-1 pt-0.5 mb-1">
                                Has area been CAT scanned and located services marked on the ground?
                                </div>
                                <div className={`h-8 border ${area_cat_scanned_services_marked === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${area_cat_scanned_services_marked === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_area_cat_scanned_services_marked('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${area_cat_scanned_services_marked === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${area_cat_scanned_services_marked === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_area_cat_scanned_services_marked('N')}>
                                    N
                                </div>
                                <div className={`h-8 border ${area_cat_scanned_services_marked === 'N/A' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${area_cat_scanned_services_marked === 'N/A' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_area_cat_scanned_services_marked('N/A')}>
                                    N/A
                                </div>
                            </div>                  

                            <div className="grid grid-cols-9 md:grid-cols-12 mt-1">
                                <div className="col-span-6 md:col-span-9 text-right pr-1 pt-0.5 mb-1">
                                Will work be a safe distance from Overhead services?
                                </div>
                                <div className={`h-8 border ${safe_distance_overhead_cables === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${safe_distance_overhead_cables === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_safe_distance_overhead_cables('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${safe_distance_overhead_cables === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${safe_distance_overhead_cables === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_safe_distance_overhead_cables('N')}>
                                    N
                                </div>
                                <div className={`h-8 border ${safe_distance_overhead_cables === 'N/A' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${safe_distance_overhead_cables === 'N/A' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_safe_distance_overhead_cables('N/A')}>
                                    N/A
                                </div>
                            </div>

                            <div className="grid grid-cols-9 md:grid-cols-12 mt-1">
                                <div className="col-span-6 md:col-span-9 text-right pr-1 pt-0.5 mb-1">
                                If a high pressure gas main is within 5m of work, has TRANSCO given permission to commence works?
                                </div>
                                <div className={`h-8 border ${high_pressure_gas_main === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${high_pressure_gas_main === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_high_pressure_gas_main('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${high_pressure_gas_main === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${high_pressure_gas_main === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_high_pressure_gas_main('N')}>
                                    N
                                </div>
                                <div className={`h-8 border ${high_pressure_gas_main === 'N/A' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${high_pressure_gas_main === 'N/A' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_high_pressure_gas_main('N/A')}>
                                    N/A
                                </div>
                            </div>

                            <div className="grid grid-cols-9 md:grid-cols-12 mt-1">
                                <div className="col-span-6 md:col-span-9 text-right pr-1 pt-0.5 mb-1">
                                If U/G high voltage electricity main within 5m of work, have trial holes been dug to confirm location?
                                </div>
                                <div className={`h-8 border ${undergound_high_voltage_main === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${undergound_high_voltage_main === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_undergound_high_voltage_main('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${undergound_high_voltage_main === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${undergound_high_voltage_main === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_undergound_high_voltage_main('N')}>
                                    N
                                </div>
                                <div className={`h-8 border ${undergound_high_voltage_main === 'N/A' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${undergound_high_voltage_main === 'N/A' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_undergound_high_voltage_main('N/A')}>
                                    N/A
                                </div>
                            </div>

                            <div className="grid grid-cols-9 md:grid-cols-12 mt-1">
                                <div className="col-span-6 md:col-span-9 text-right pr-1 pt-0.5 mb-1">
                                    Are known services within 1m of proposed hole location, if so, hand excavate trial hole to confirm location of service
                                </div>
                                <div className={`h-8 border ${known_services_in_hole_location === 'Y' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${known_services_in_hole_location === 'Y' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_known_services_in_hole_location('Y')}>
                                    Y
                                </div>
                                <div className={`h-8 border ${known_services_in_hole_location === 'N' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${known_services_in_hole_location === 'N' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_known_services_in_hole_location('N')}>
                                    N
                                </div>
                                <div className={`h-8 border ${known_services_in_hole_location === 'N/A' ? 'border-orange py-0' : 'border-grey-mid py-0.5'} text-grey-mid bg-white mb-1 ${known_services_in_hole_location === 'N/A' ? 'text-charcoal RAselected' : ''}`} onClick={() => handle_known_services_in_hole_location('N/A')}>
                                    N/A
                                </div>
                            </div>

                            <div className="mt-1">
                                <div className="text-sm md:text-base text-right pr-1 pt-0.5 mb-1">
                                Any Known site specific hazards? Include environmental hazards or additional controls/measures taken on site and comments (state NIL if no hazards):
                                </div>
                            </div>

                            <div className="col-span-1 md:col-span-2 bg-white rounded h-20 md:h-24 mt-0 md:mt-2">
                            <textarea
                                value={any_known_site_hazards}
                                onChange={(e) => set_any_known_site_hazards(e.target.value)}
                                className="w-full h-full border border-grey-mid rounded px-2 py-2"
                                placeholder="Enter any known site hazards..."
                                rows={4}
                            />
                            </div>

                        {/* </div> */}


                    </div>

                    <div className="w-60 mb-4 mx-auto">
                        <Button type="submit" theme="primary" size="lg" className="btn-lg m-auto text-lg font-bold w-full" >Save</Button>
                    </div>

					</form>

                </Modal>
    
        </>

    )        
}                 
RiskAssessment.defaultProps = defaultProps
export default RiskAssessment