import React, { FunctionComponent, useState } from 'react'

import Header from '../layout/Header'

import Modal from '../components/Modal'
import PrintLabel from '../components/PrintLabel'
import WorkflowSteps from '../components/WorkflowSteps'
import NumberInput from '../components/NumberInput'
import Button from '../components/form/Button'
import InputGroup from '../components/form/InputGroup'
import WorkflowActions from '../components/form/WorkflowActions'

import stepStatus from '../helpers/stepStatus'

import Label from '../components/Label'
import { Link } from 'react-router-dom'

//temp values
var locationVar = 'Reedham Village Hall 2' 
var projectVar = 'GN12345' 
var boreholeVar = 'BH001' 
var labelDateVar = '23/06/2022' 
var labelUser = "Rod 'The Big Man' Lifter - Oh Yeah!" 
var printlink = "/printlabel"


type Props = {
	title: string,
	subtitle?: string,
	bg?: string,
	// toggleModal: () => void,
	confirm?: false,
	children?: React.ReactNode,
	router: any,
    printlink: "/printlabel",
}

interface State {
    location: any,
    project: any,
    borehole: any,
    sampledate: any,
    user: any,
    sample: any,
    depth: any,
}

class Labels extends React.Component<Props, State> {



	
	
	render() {
		return (
			<>
				{/* <Header toggleSidebar={this.props.toggleSidebar} /> */}

				<Modal title="Labels" toggleModal={() => {this.props.router.history.goBack()}} confirm={false}>


                <div className="text-center text-lg">Samples</div>  

                <div className="text-center text-xs">(Cable Percussive | Rotary | Window Sample | Trialpit - if any)</div>       

                    <ul className="flex flex-wrap p-0 mb-2 list-none justify-center text-center"> {/* START LABELS HOLDER */}

                    
                    <Link to={{  // send details to printLabel
                        pathname: printlink, 
                        state: {
                            location: {locationVar},
                            project: {projectVar},
                            borehole: {boreholeVar},
                            sampledate: {labelDateVar},
                            user: {labelUser},
                            sample: "D-1",
                            depth: "2.00",
                            showCancel:true,
                        } 
                    }} >
                                <li className="p-1">
                                <Label 
                                    location={locationVar}
                                    project={projectVar}
                                    borehole={boreholeVar} 
                                    sampledate={labelDateVar}  
                                    user={labelUser} 
                                    sample="D-1" 
                                    depth="2.00" 
                                    size='smallLabel'
                                />
                                </li>
                    </Link>

                    
                    <Link to={{pathname: printlink}}>
                                <li className="p-1">
                                <Label 
                                    location={locationVar}
                                    project={projectVar}
                                    borehole={boreholeVar} 
                                    sampledate={labelDateVar}  
                                    user={labelUser} 
                                    sample="B-1" 
                                    depth="2.00 - 2.20" 
                                    size='smallLabel' 
                                />
                                </li>
                    </Link>

                    
                    <Link to={{pathname: printlink}}>
                                <li className="p-1">
                                <Label 
                                    location={locationVar}
                                    project={projectVar}
                                    borehole={boreholeVar} 
                                    sampledate={labelDateVar}  
                                    user={labelUser} 
                                    sample="B-2" 
                                    depth="3.00 - 3.50" 
                                    size='smallLabel' 
                                />
                                </li>
                    </Link>

                    
                    <Link to={{pathname: printlink}}>
                                <li className="p-1">
                                <Label 
                                    location={locationVar}
                                    project={projectVar}
                                    borehole={boreholeVar} 
                                    sampledate={labelDateVar}  
                                    user={labelUser} 
                                    sample="WS-1" 
                                    depth="4.00" 
                                    size='smallLabel' 
                                />
                                </li>
                    </Link>

                    </ul> {/* END LABELS HOLDER */}




                    <div className="w-30">
                        <Button theme="primary" size="lg" className="btn-lg m-auto" >Print All Sample Labels</Button>
                    </div>


                    <div className="text-center text-xl">Liner Labels</div>    

                    <div className="text-center text-xs">(Window Sample only - if any)</div>     

                    <ul className="flex flex-wrap p-0 mb-2 list-none justify-center text-center"> {/* START LABELS HOLDER */}

                    <Link to={{pathname: printlink}}>
                        <li className="p-1">
                        <Label 
                            location={locationVar}
                            project={projectVar}
                            borehole={boreholeVar} 
                            sampledate={labelDateVar}  
                            user={labelUser} 
                            sample="L-1" 
                            depth="2.00 - 3.00" 
                            size='smallLabel'
                        />
                        </li>
                    </Link>

                    <Link to={{pathname: printlink}}>
                        <li className="p-1">
                        <Label 
                            location={locationVar}
                            project={projectVar}
                            borehole={boreholeVar} 
                            sampledate={labelDateVar}  
                            user={labelUser} 
                            sample="L-2" 
                            depth="3.00 - 4.00" 
                            size='smallLabel'
                        />
                        </li>
                    </Link>

                    <Link to={{pathname: printlink}}>

                    <li className="p-1">
                        <Label 
                            location={locationVar}
                            project={projectVar}
                            borehole={boreholeVar} 
                            sampledate={labelDateVar}  
                            user={labelUser} 
                            sample="L-3" 
                            depth="4.00 - 5.00" 
                            size='smallLabel'
                        />
                        </li>
                    </Link>

                    </ul> {/* END LABELS HOLDER */}

                    <div className="w-30">
                        <Button theme="primary" size="lg" className="btn-lg m-auto" >Print All Liner Labels</Button>
                    </div>





                    <div className="text-center text-xl">Core Labels</div>    

                    <div className="text-center text-xs">(Rotary only - if any)</div>       

                    <ul className="flex flex-wrap p-0 mb-2 list-none justify-center text-center"> {/* START LABELS HOLDER */}


                    <Link to={{pathname: printlink}}>
                    <li className="p-1">
                    <Label 
                        location={locationVar}
                        project={projectVar}
                        borehole={boreholeVar} 
                        sampledate={labelDateVar}  
                        user={labelUser} 
                        sample="C-1" 
                        depth="2.00 - 3.50" 
                        size='smallLabel'
                    />
                    </li>
                    </Link>

                    <Link to={{pathname: printlink}}>
                        <li className="p-1">
                        <Label 
                            location={locationVar}
                            project={projectVar}
                            borehole={boreholeVar} 
                            sampledate={labelDateVar}  
                            user={labelUser} 
                            sample="C-2" 
                            depth="3.50 - 5.00" 
                            size='smallLabel'
                        />
                        </li>
                    </Link>

                    <Link to={{pathname: printlink}}>
                        <li className="p-1">
                        <Label 
                            location={locationVar}
                            project={projectVar}
                            borehole={boreholeVar} 
                            sampledate={labelDateVar}  
                            user={labelUser} 
                            sample="C-3" 
                            depth="5.00 - 6.50" 
                            size='smallLabel'
                        />
                        </li>
                    </Link>

                    <Link to={{pathname: printlink}}>
                        <li className="p-1">
                        <Label 
                            location={locationVar}
                            project={projectVar}
                            borehole={boreholeVar} 
                            sampledate={labelDateVar}  
                            user={labelUser} 
                            sample="C-4" 
                            depth="6.50 - 8.00" 
                            size='smallLabel'
                        />
                        </li>
                    </Link>

                    </ul> {/* END LABELS HOLDER */}

                    <div className="w-30">
                        <Button theme="primary" size="lg" className="btn-lg m-auto" >Print All Core Labels</Button>
                    </div>




                    {/* START PRINT LABELS HOLDER */}

                    {/* <Label 
                        location={locationVar}
                        project={projectVar}
                        borehole={boreholeVar} 
                        sampledate={labelDateVar}  
                        user={labelUser} 
                        sample="L-6" 
                        depth="44.20 - 44.30" 
                        size='printLabel'
                    /> */}

                    {/* END PRINT LABELS HOLDER */}



                
                    <PrintLabel 
                        location={locationVar}
                        project={projectVar}
                        borehole={boreholeVar} 
                        sampledate={labelDateVar}  
                        user={labelUser} 
                        sample="C-4" 
                        depth="6.50 - 8.00" 
                        size='printLabel'
                    />

				</Modal>

            

			{/* {printWindow &&
				
                <ModalPrintLabel 
                        location={locationVar}
                        project={projectVar}
                        borehole={boreholeVar} 
                        sampledate={labelDateVar}  
                        user={labelUser} 
                        sample="C-4" 
                        depth="6.50 - 8.00" 
                        size='printLabel'
                />

			} */}



			</>
		)
	}
}

export default Labels