import React, { FunctionComponent, useState, useEffect, Component } from 'react'

import { useLocation } from "react-router-dom";

import Header from '../layout/Header'

import Modal from '../components/Modal'
import ModalSmall from '../components/ModalSmall'
import WorkflowSteps from '../components/WorkflowSteps'
import NumberInput from '../components/NumberInput'
import Button from '../components/form/Button'
import InputGroup from '../components/form/InputGroup'
import TextArea from '../components/form/TextArea'
import WorkflowActions from '../components/form/WorkflowActions'
import infoIcon from '../assets/icons/info.png'
import warningIcon from '../assets/icons/warning.png'

import { useGetLoggedInUser } from '../helpers/getLoggedInUser'
import { v4 as uuidv4 } from 'uuid';
import { useSetIndexedDBValues } from '../helpers/addToIDB'
import { updatePlantCheckRotary } from "../helpers/updatePlantCheckRotary";

import Label from '../components/Label'
import { Link } from 'react-router-dom'
import RadioSelect from '../components/form/RadioSelect'

import { useGetIDBValues } from "../helpers/getIDBKey";
import { get, set, del, update } from 'idb-keyval'

type Props = {
	onClose: any,
	projectId?: string,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => { },
	// onComplete: () => {},
	projectId: '',
	defaultValues: {
		guid: '',
	},
}

interface State {
	generalConditionOfBodyCracksDamage: number,
	chainTensionAndLubrication: number,
	chainPulleysCleanAndLubricate: number,
	lubricateHeadSlides: number,
	lubricateMastSlides: number,
	securityOfBolts: number,
	pinsGrease: number,
	conditionOfHydraulicHammer: number,
	engineOilLevel: number,
	headOilLevel: number,
	hydraulicOilLevel: number,
	fuelLevel: number,
	coolantLevel: number,
	checkWarningLights: number,
	conditionOfControlPanel: number,
	remoteControlWorking: number,
	greaseHeadShaft: number,
	securityOfFlushSwivelAndGrease: number,
	hydraulicCircuitLeaksDamage: number,
	emergencyStopTest: number,
	fixedGuardTest: number,
	rigAndHeatExchangerClean: number,
	checkBatterySecurityAndLevel: number,
	conditionOfWirelineWinch: number,
	conditionOfServiceWinch: number,
	compressorEngineOil: number,
	compressorHydraulicOil: number,
	compressorCoolantLevel: number,
	compressorAirLines: number,
	airlineSafetyConnections: number,
	conditionOfShackles: number,
	conditionOfSwivels: number,
	conditionOfCasingDriveHead: number,
	conditionOfHoistingPlugs: number,
	conditionOfSPTLiftingPoint: number,
	conditionOfFlushPump: number,
	conditionOfMistPump: number,
	fireExtinguisherGoodCondition: number,

	engineHours: string,
	compressorRunHours: string,
	issuesMaintenance: string,

	guid: string,
	company_guid: string,
	project_guid: string,
	project_location: string,
	project_id: string,
	company_id: string,

	plant_id: string,
	plant_guid: string,
	plant_name: string,
	plant_check_date: string,
	plantcheck_url: string,
	driller_name: string,
	driller_cscs: string,
}

// Define a type or interface for currentRigPlantChecks
interface PlantCheck {
	plantCheck: {
		id?: number;
		generalConditionOfBodyCracksDamage: number,
		chainTensionAndLubrication: number,
		chainPulleysCleanAndLubricate: number,
		lubricateHeadSlides: number,
		lubricateMastSlides: number,
		securityOfBolts: number,
		pinsGrease: number,
		conditionOfHydraulicHammer: number,
		engineOilLevel: number,
		headOilLevel: number,
		hydraulicOilLevel: number,
		fuelLevel: number,
		coolantLevel: number,
		checkWarningLights: number,
		conditionOfControlPanel: number,
		remoteControlWorking: number,
		greaseHeadShaft: number,
		securityOfFlushSwivelAndGrease: number,
		hydraulicCircuitLeaksDamage: number,
		emergencyStopTest: number,
		fixedGuardTest: number,
		rigAndHeatExchangerClean: number,
		checkBatterySecurityAndLevel: number,
		conditionOfWirelineWinch: number,
		conditionOfServiceWinch: number,
		compressorEngineOil: number,
		compressorHydraulicOil: number,
		compressorCoolantLevel: number,
		compressorAirLines: number,
		airlineSafetyConnections: number,
		conditionOfShackles: number,
		conditionOfSwivels: number,
		conditionOfCasingDriveHead: number,
		conditionOfHoistingPlugs: number,
		conditionOfSPTLiftingPoint: number,
		conditionOfFlushPump: number,
		conditionOfMistPump: number,
		fireExtinguisherGoodCondition: number,

		engineHours: string,
		compressorRunHours: string,
		issuesMaintenance: string,

		guid: string,
		company_guid: string,
		project_guid: string,
		project_location: string,
		project_id: string,
		company_id: string,

		plant_id: string,
		plant_guid: string,
		plant_name: string,
		plant_check_date: string,
		plantcheck_url: string,
		driller_name: string,
		driller_cscs: string,
	}[];
	// Define other properties if there are any
}



const PlantCheck: FunctionComponent<Props> = ({ onClose, update, companyGuid, defaultValues,
	project_guid, testDate, currentRigTestExpiryDate, plantType, plantID, plantName, companyID, plantGUID, plantChecks,
	currentRigSPTRodType, currentRigSPTIDRef, currentRigSPTEnergyRatio, currentRigSPTCalibrationDate, rigInformation,
	...rest }) => {


	console.log('defaultValues:', defaultValues)

	const [driller_name, setLeadDriller] = useState(defaultValues.driller_name)
	const loggedInUser = useGetLoggedInUser();
	const [loggedInDriller, setLoggedInDriller] = useState('')
	const [loggedInDrillerCSCS, setLoggedInDrillerCSCS] = useState('')
	useEffect(() => {
		if (loggedInUser && loggedInUser.user) {
			setLoggedInDriller(`${loggedInUser.user.first_name} ${loggedInUser.user.last_name}`);
			setLoggedInDrillerCSCS(`${(loggedInUser.user as any).cscs_no}`);
		}
	}, [loggedInUser]);
	console.log('update:', update)
	const [guid, setGuid] = useState(defaultValues.guid)
	const [project_id, setProject_id] = useState(defaultValues.project_id)
	const [plant_guid, setPlant_guid] = useState(defaultValues.plant_guid)
	// const [project_guid, setProject_guid] = useState(defaultValues.project_guid)
	const [projectGUID, setProjectGUID] = useState(project_guid)
	const [project_name, setProject_name] = useState(defaultValues.project_name)
	const [project_location, setProject_location] = useState(defaultValues.project_location)
	const [plant_id, setPlant_id] = useState(defaultValues.plant_id)
	const [plant_name, setPlant_name] = useState(defaultValues.plant_name)
	const [plant_check_date, setPlant_check_date] = useState(defaultValues.plant_check_date)
	const [spt_rod_type, set_spt_rod_type] = useState(defaultValues.spt_rod_type)
	const [spt_id_ref, set_spt_id_ref] = useState(defaultValues.spt_id_ref)
	const [spt_energy_ratio, set_spt_energy_ratio] = useState(defaultValues.spt_energy_ratio)
	const [spt_calibration_date, set_spt_calibration_date] = useState(defaultValues.spt_calibration_date)
	// const company_id = 1
	const [company_id, setCompany_id] = useState(defaultValues.company_id)
	const [company_guid, setCompany_guid] = useState(companyGuid)
	const [current_rig_test_expiry_date, set_current_rig_test_expiry_date] = useState(defaultValues.plant_test_date_expiry)
	const [plant_type, set_plant_type] = useState(plantType)


	const [generalConditionOfBodyCracksDamage, set_generalConditionOfBodyCracksDamage] = useState(defaultValues.generalConditionOfBodyCracksDamage === 1 ? true : false);
	const [chainTensionAndLubrication, set_chainTensionAndLubrication] = useState(defaultValues.chainTensionAndLubrication === 1 ? true : false);
	const [chainPulleysCleanAndLubricate, set_chainPulleysCleanAndLubricate] = useState(defaultValues.chainPulleysCleanAndLubricate === 1 ? true : false);
	const [lubricateHeadSlides, set_lubricateHeadSlides] = useState(defaultValues.lubricateHeadSlides === 1 ? true : false);
	const [lubricateMastSlides, set_lubricateMastSlides] = useState(defaultValues.lubricateMastSlides === 1 ? true : false);
	const [securityOfBolts, set_securityOfBolts] = useState(defaultValues.securityOfBolts === 1 ? true : false);
	const [pinsGrease, set_pinsGrease] = useState(defaultValues.pinsGrease === 1 ? true : false);
	const [conditionOfHydraulicHammer, set_conditionOfHydraulicHammer] = useState(defaultValues.conditionOfHydraulicHammer === 1 ? true : false);
	const [engineOilLevel, set_engineOilLevel] = useState(defaultValues.engineOilLevel === 1 ? true : false);
	const [headOilLevel, set_headOilLevel] = useState(defaultValues.headOilLevel === 1 ? true : false);
	const [hydraulicOilLevel, set_hydraulicOilLevel] = useState(defaultValues.hydraulicOilLevel === 1 ? true : false);
	const [fuelLevel, set_fuelLevel] = useState(defaultValues.fuelLevel === 1 ? true : false);
	const [coolantLevel, set_coolantLevel] = useState(defaultValues.coolantLevel === 1 ? true : false);
	const [checkWarningLights, set_checkWarningLights] = useState(defaultValues.checkWarningLights === 1 ? true : false);
	const [conditionOfControlPanel, set_conditionOfControlPanel] = useState(defaultValues.conditionOfControlPanel === 1 ? true : false);
	const [remoteControlWorking, set_remoteControlWorking] = useState(defaultValues.remoteControlWorking === 1 ? true : false);
	const [greaseHeadShaft, set_greaseHeadShaft] = useState(defaultValues.greaseHeadShaft === 1 ? true : false);
	const [securityOfFlushSwivelAndGrease, set_securityOfFlushSwivelAndGrease] = useState(defaultValues.securityOfFlushSwivelAndGrease === 1 ? true : false);
	const [hydraulicCircuitLeaksDamage, set_hydraulicCircuitLeaksDamage] = useState(defaultValues.hydraulicCircuitLeaksDamage === 1 ? true : false);
	const [emergencyStopTest, set_emergencyStopTest] = useState(defaultValues.emergencyStopTest === 1 ? true : false);
	const [fixedGuardTest, set_fixedGuardTest] = useState(defaultValues.fixedGuardTest === 1 ? true : false);
	const [rigAndHeatExchangerClean, set_rigAndHeatExchangerClean] = useState(defaultValues.rigAndHeatExchangerClean === 1 ? true : false);
	const [checkBatterySecurityAndLevel, set_checkBatterySecurityAndLevel] = useState(defaultValues.checkBatterySecurityAndLevel === 1 ? true : false);
	const [conditionOfWirelineWinch, set_conditionOfWirelineWinch] = useState(defaultValues.conditionOfWirelineWinch === 1 ? true : false);
	const [conditionOfServiceWinch, set_conditionOfServiceWinch] = useState(defaultValues.conditionOfServiceWinch === 1 ? true : false);
	const [compressorEngineOil, set_compressorEngineOil] = useState(defaultValues.compressorEngineOil === 1 ? true : false);
	const [compressorHydraulicOil, set_compressorHydraulicOil] = useState(defaultValues.compressorHydraulicOil === 1 ? true : false);
	const [compressorCoolantLevel, set_compressorCoolantLevel] = useState(defaultValues.compressorCoolantLevel === 1 ? true : false);
	const [compressorAirLines, set_compressorAirLines] = useState(defaultValues.compressorAirLines === 1 ? true : false);
	const [airlineSafetyConnections, set_airlineSafetyConnections] = useState(defaultValues.airlineSafetyConnections === 1 ? true : false);
	const [conditionOfShackles, set_conditionOfShackles] = useState(defaultValues.conditionOfShackles === 1 ? true : false);
	const [conditionOfSwivels, set_conditionOfSwivels] = useState(defaultValues.conditionOfSwivels === 1 ? true : false);
	const [conditionOfCasingDriveHead, set_conditionOfCasingDriveHead] = useState(defaultValues.conditionOfCasingDriveHead === 1 ? true : false);
	const [conditionOfHoistingPlugs, set_conditionOfHoistingPlugs] = useState(defaultValues.conditionOfHoistingPlugs === 1 ? true : false);
	const [conditionOfSPTLiftingPoint, set_conditionOfSPTLiftingPoint] = useState(defaultValues.conditionOfSPTLiftingPoint === 1 ? true : false);
	const [conditionOfFlushPump, set_conditionOfFlushPump] = useState(defaultValues.conditionOfFlushPump === 1 ? true : false);
	const [conditionOfMistPump, set_conditionOfMistPump] = useState(defaultValues.conditionOfMistPump === 1 ? true : false);
	const [fireExtinguisherGoodCondition, set_fireExtinguisherGoodCondition] = useState(defaultValues.fireExtinguisherGoodCondition === 1 ? true : false);

	const [engineHours, set_engineHours] = useState(defaultValues.engineHours);
	const [compressorRunHours, set_compressorRunHours] = useState(defaultValues.compressorRunHours);
	const [issuesMaintenance, set_issuesMaintenance] = useState(defaultValues.issuesMaintenance);

	const [sheetDate, set_sheetDate] = useState('');
	const [sheetUpdate, set_sheetUpdate] = useState('');

	useEffect(() => {
		if (update) {
			set_sheetDate(plant_check_date)
			set_sheetUpdate('(update)')
		} else {
			set_sheetDate(testDate)
			set_sheetUpdate('(new)')
		}
	}, [update, plant_check_date, testDate]);

	useEffect(() => {
		console.log('currentRigTestExpiryDate:', currentRigTestExpiryDate)
		console.log('testDate:', testDate)
	}, [currentRigTestExpiryDate, testDate]);


	// GET EXISTING PLANT CHECKS FOR SELECTED RIG

	// const [currentRigPlantChecks, setCurrentRigPlantChecks] = useState<PlantCheck[]>([]);

	// useEffect(() => {
	// 	if(rigInformation.guid){
	// 		let cachedValues = useGetIDBValues;
	// 		const rigPlantChecks = 'plant-check-rotary/' + rigInformation.guid;

	// 		cachedValues(rigPlantChecks, (data: any) => {
	// 			// console.log('rigPlantChecks:', data)

	// 			if (data) {
	// 				setCurrentRigPlantChecks(data)
	// 			}

	// 		});
	// 	}
	// }, [rigInformation.guid]);

	/////////////////////////////////////////
	// SAVE NEW LOG INFO
	/////////////////////////////////////////   

	// PlantID string
	let logPlantIDString = plantID.replace(/\s/g, '');
	// Date string
	// Split the date string into day, month, and year
	let parts = testDate.split('-');
	let year = parseInt(parts[0]);
	let month = parseInt(parts[1]);
	let day = parseInt(parts[2]);

	// Create a new Date object with the parts
	let dateObject = new Date(year, month - 1, day);

	// Get the year, month, and day in YYYY, MM, and DD format respectively
	let YYYY = dateObject.getFullYear();
	let MM = String(dateObject.getMonth() + 1).padStart(2, '0'); // Adding 1 because month is zero-based
	let DD = String(dateObject.getDate()).padStart(2, '0');

	// Concatenate them in YYYYMMDD format
	let logDateString = `${YYYY}${MM}${DD}`;

	let plantcheck_url = logPlantIDString + '-' + logDateString + '.pdf'




	const newPlantCheck = {
		generalConditionOfBodyCracksDamage: generalConditionOfBodyCracksDamage ? 1 : 0,
		chainTensionAndLubrication: chainTensionAndLubrication ? 1 : 0,
		chainPulleysCleanAndLubricate: chainPulleysCleanAndLubricate ? 1 : 0,
		lubricateHeadSlides: lubricateHeadSlides ? 1 : 0,
		lubricateMastSlides: lubricateMastSlides ? 1 : 0,
		securityOfBolts: securityOfBolts ? 1 : 0,
		pinsGrease: pinsGrease ? 1 : 0,
		conditionOfHydraulicHammer: conditionOfHydraulicHammer ? 1 : 0,
		engineOilLevel: engineOilLevel ? 1 : 0,
		headOilLevel: headOilLevel ? 1 : 0,
		hydraulicOilLevel: hydraulicOilLevel ? 1 : 0,
		fuelLevel: fuelLevel ? 1 : 0,
		coolantLevel: coolantLevel ? 1 : 0,
		checkWarningLights: checkWarningLights ? 1 : 0,
		conditionOfControlPanel: conditionOfControlPanel ? 1 : 0,
		remoteControlWorking: remoteControlWorking ? 1 : 0,
		greaseHeadShaft: greaseHeadShaft ? 1 : 0,
		securityOfFlushSwivelAndGrease: securityOfFlushSwivelAndGrease ? 1 : 0,
		hydraulicCircuitLeaksDamage: hydraulicCircuitLeaksDamage ? 1 : 0,
		emergencyStopTest: emergencyStopTest ? 1 : 0,
		fixedGuardTest: fixedGuardTest ? 1 : 0,
		rigAndHeatExchangerClean: rigAndHeatExchangerClean ? 1 : 0,
		checkBatterySecurityAndLevel: checkBatterySecurityAndLevel ? 1 : 0,
		conditionOfWirelineWinch: conditionOfWirelineWinch ? 1 : 0,
		conditionOfServiceWinch: conditionOfServiceWinch ? 1 : 0,
		compressorEngineOil: compressorEngineOil ? 1 : 0,
		compressorHydraulicOil: compressorHydraulicOil ? 1 : 0,
		compressorCoolantLevel: compressorCoolantLevel ? 1 : 0,
		compressorAirLines: compressorAirLines ? 1 : 0,
		airlineSafetyConnections: airlineSafetyConnections ? 1 : 0,
		conditionOfShackles: conditionOfShackles ? 1 : 0,
		conditionOfSwivels: conditionOfSwivels ? 1 : 0,
		conditionOfCasingDriveHead: conditionOfCasingDriveHead ? 1 : 0,
		conditionOfHoistingPlugs: conditionOfHoistingPlugs ? 1 : 0,
		conditionOfSPTLiftingPoint: conditionOfSPTLiftingPoint ? 1 : 0,
		conditionOfFlushPump: conditionOfFlushPump ? 1 : 0,
		conditionOfMistPump: conditionOfMistPump ? 1 : 0,
		fireExtinguisherGoodCondition: fireExtinguisherGoodCondition ? 1 : 0,

		engineHours: engineHours || '',
		compressorRunHours: compressorRunHours || '',
		issuesMaintenance: issuesMaintenance || '',

		company_guid: company_guid || '',
		company_id: update ? company_id : companyID,
		project_guid: projectGUID,
		driller_name: update ? driller_name : loggedInDriller,
		driller_cscs: update ? loggedInDrillerCSCS : loggedInDrillerCSCS,
		guid: update ? guid : uuidv4(),
		plant_guid: update ? plant_guid : plantGUID,
		plant_id: update ? plant_id : plantID,
		plant_name: update ? plant_name : plantName,
		spt_rod_type: update ? spt_rod_type : currentRigSPTRodType,
		spt_id_ref: update ? spt_id_ref : currentRigSPTIDRef,
		spt_energy_ratio: update ? parseFloat(spt_energy_ratio).toString() : parseFloat(currentRigSPTEnergyRatio).toString(),
		spt_calibration_date: update ? spt_calibration_date : currentRigSPTCalibrationDate,
		plant_test_date_expiry: update ? current_rig_test_expiry_date : currentRigTestExpiryDate,
		plant_check_date: update ? plant_check_date : testDate,
		plantcheck_url: plantcheck_url,
	}

	const newPlantCheckToSave = [newPlantCheck]

	console.log('plantChecks in PlantCheck:', plantChecks)
	console.log('newPlantCheck in PlantCheck:', newPlantCheck)
	console.log('newPlantCheckToSave in PlantCheck:', newPlantCheckToSave)



	// const mergedPlantChecks = {
	// 	...currentRigPlantChecks,
	// 	...newPlantCheckToSave,
	// };

	const [mergedRigPlantChecks, setMergedRigPlantChecks] = useState<PlantCheck[]>([]);

	const mergeAllPlantChecks = () => {
		const mergedPlantChecks = [...plantChecks, newPlantCheck];
		setMergedRigPlantChecks(mergedPlantChecks);
	};

	console.log('mergedRigPlantChecks:', mergedRigPlantChecks);

	const [mergedRigPlantChecksUpdated, setMergedRigPlantChecksUpdated] = useState<PlantCheck[]>([]);

	const updatePlantChecks = () => {
		if (!newPlantCheck) return;

		const updatedPlantChecks = plantChecks.map((check: any) =>
			check.guid === newPlantCheck.guid ? newPlantCheck : check
		);

		setMergedRigPlantChecksUpdated(updatedPlantChecks);
	};

	console.log('mergedRigPlantChecksUpdated:', mergedRigPlantChecksUpdated);





	const save = () => {

		console.log('newPlantCheckToSave:', newPlantCheckToSave)

		if (!update) {
			let saveToIDB = useSetIndexedDBValues;

			console.log('newPlantCheck:', newPlantCheck);

			const endPoint = 'plant-check-rotary';

			console.log('endPoint:', endPoint);

			// Save the new plant check to IndexedDB
			saveToIDB(newPlantCheck, endPoint, true).then(async () => {
				console.log('Plant check saving to IndexedDB');

				// mergeAllPlantChecks()
				const mergedPlantChecks = [...plantChecks, newPlantCheck];
				setMergedRigPlantChecks(mergedPlantChecks);

				// Ensure the structure is maintained
				const updatedData = {
					plantCheck: mergedPlantChecks
				};

				console.log('updatedData on save:', updatedData)

				try {
					// Update the currentRigPlantChecks state in IndexedDB
					// await set('drillit-plant-check-rotary/' + rigInformation.guid, mergedRigPlantChecks);
					await set('drillit-plant-check-rotary/project/' + project_guid, updatedData);
					console.log('Updating drillit-plant-check-rotary/project');
				} catch (error) {
					console.error('Error updating current rig plant checks:', error);
				}
			});

			setTimeout(() => onClose(), 2000);

		} else {
			console.log('UPDATING PLANT CHECK!!!')

			updatePlantChecks()

			updatePlantCheckRotary(
				newPlantCheck.generalConditionOfBodyCracksDamage,
				newPlantCheck.chainTensionAndLubrication,
				newPlantCheck.chainPulleysCleanAndLubricate,
				newPlantCheck.lubricateHeadSlides,
				newPlantCheck.lubricateMastSlides,
				newPlantCheck.securityOfBolts,
				newPlantCheck.pinsGrease,
				newPlantCheck.conditionOfHydraulicHammer,
				newPlantCheck.engineOilLevel,
				newPlantCheck.headOilLevel,
				newPlantCheck.hydraulicOilLevel,
				newPlantCheck.fuelLevel,
				newPlantCheck.coolantLevel,
				newPlantCheck.checkWarningLights,
				newPlantCheck.conditionOfControlPanel,
				newPlantCheck.remoteControlWorking,
				newPlantCheck.greaseHeadShaft,
				newPlantCheck.securityOfFlushSwivelAndGrease,
				newPlantCheck.hydraulicCircuitLeaksDamage,
				newPlantCheck.emergencyStopTest,
				newPlantCheck.fixedGuardTest,
				newPlantCheck.rigAndHeatExchangerClean,
				newPlantCheck.checkBatterySecurityAndLevel,
				newPlantCheck.conditionOfWirelineWinch,
				newPlantCheck.conditionOfServiceWinch,
				newPlantCheck.compressorEngineOil,
				newPlantCheck.compressorHydraulicOil,
				newPlantCheck.compressorCoolantLevel,
				newPlantCheck.compressorAirLines,
				newPlantCheck.airlineSafetyConnections,
				newPlantCheck.conditionOfShackles,
				newPlantCheck.conditionOfSwivels,
				newPlantCheck.conditionOfCasingDriveHead,
				newPlantCheck.conditionOfHoistingPlugs,
				newPlantCheck.conditionOfSPTLiftingPoint,
				newPlantCheck.conditionOfFlushPump,
				newPlantCheck.conditionOfMistPump,
				newPlantCheck.fireExtinguisherGoodCondition,
				newPlantCheck.engineHours,
				newPlantCheck.compressorRunHours,
				newPlantCheck.issuesMaintenance,
				newPlantCheck.company_guid,
				newPlantCheck.company_id,
				newPlantCheck.driller_name,
				newPlantCheck.driller_cscs,
				newPlantCheck.guid,
				newPlantCheck.plant_guid,
				newPlantCheck.plant_id,
				newPlantCheck.plant_name,
				newPlantCheck.spt_rod_type,
				newPlantCheck.spt_rod_type,
				newPlantCheck.spt_energy_ratio,
				newPlantCheck.spt_calibration_date,
				newPlantCheck.plant_test_date_expiry,
				newPlantCheck.plant_check_date,
				newPlantCheck.plantcheck_url,
				newPlantCheck.project_guid,
				mergedRigPlantChecksUpdated
			)
				.then(() => setTimeout(() => onClose(), 3000));
		}
	}


	const handleCloseModal = () => {
		console.log('handleCloseModal triggered')
		onClose(); // Call the onClose function passed as prop
	};


	function checkAll() {
		set_generalConditionOfBodyCracksDamage(true)
		set_chainTensionAndLubrication(true)
		set_chainPulleysCleanAndLubricate(true)
		set_lubricateHeadSlides(true)
		set_lubricateMastSlides(true)
		set_securityOfBolts(true)
		set_pinsGrease(true)
		set_conditionOfHydraulicHammer(true)
		set_engineOilLevel(true)
		set_headOilLevel(true)
		set_hydraulicOilLevel(true)
		set_fuelLevel(true)
		set_coolantLevel(true)
		set_checkWarningLights(true)
		set_conditionOfControlPanel(true)
		set_remoteControlWorking(true)
		set_greaseHeadShaft(true)
		set_securityOfFlushSwivelAndGrease(true)
		set_hydraulicCircuitLeaksDamage(true)
		set_emergencyStopTest(true)
		set_fixedGuardTest(true)
		set_rigAndHeatExchangerClean(true)
		set_checkBatterySecurityAndLevel(true)
		set_conditionOfWirelineWinch(true)
		set_conditionOfServiceWinch(true)
		set_compressorEngineOil(true)
		set_compressorHydraulicOil(true)
		set_compressorCoolantLevel(true)
		set_compressorAirLines(true)
		set_airlineSafetyConnections(true)
		set_conditionOfShackles(true)
		set_conditionOfSwivels(true)
		set_conditionOfCasingDriveHead(true)
		set_conditionOfHoistingPlugs(true)
		set_conditionOfSPTLiftingPoint(true)
		set_conditionOfFlushPump(true)
		set_conditionOfMistPump(true)
		set_fireExtinguisherGoodCondition(true)
	}

	function uncheckAll() {
		set_generalConditionOfBodyCracksDamage(false)
		set_chainTensionAndLubrication(false)
		set_chainPulleysCleanAndLubricate(false)
		set_lubricateHeadSlides(false)
		set_lubricateMastSlides(false)
		set_securityOfBolts(false)
		set_pinsGrease(false)
		set_conditionOfHydraulicHammer(false)
		set_engineOilLevel(false)
		set_headOilLevel(false)
		set_hydraulicOilLevel(false)
		set_fuelLevel(false)
		set_coolantLevel(false)
		set_checkWarningLights(false)
		set_conditionOfControlPanel(false)
		set_remoteControlWorking(false)
		set_greaseHeadShaft(false)
		set_securityOfFlushSwivelAndGrease(false)
		set_hydraulicCircuitLeaksDamage(false)
		set_emergencyStopTest(false)
		set_fixedGuardTest(false)
		set_rigAndHeatExchangerClean(false)
		set_checkBatterySecurityAndLevel(false)
		set_conditionOfWirelineWinch(false)
		set_conditionOfServiceWinch(false)
		set_compressorEngineOil(false)
		set_compressorHydraulicOil(false)
		set_compressorCoolantLevel(false)
		set_compressorAirLines(false)
		set_airlineSafetyConnections(false)
		set_conditionOfShackles(false)
		set_conditionOfSwivels(false)
		set_conditionOfCasingDriveHead(false)
		set_conditionOfHoistingPlugs(false)
		set_conditionOfSPTLiftingPoint(false)
		set_conditionOfFlushPump(false)
		set_conditionOfMistPump(false)
		set_fireExtinguisherGoodCondition(false)
	}


	// RIG INFORMATION

	const [showRigInformation, setShowRigInformation] = useState(false)
	const [information_check, setInformation_check] = useState(false)
	// console.log('rigInformation:', rigInformation)
	const [rigName, setRigName] = useState(rigInformation.name)
	const [rigType, setRigType] = useState(rigInformation.type)
	const [rigTestExpiry, setRigTestExpiry] = useState(rigInformation.test_date_expiry)
	const [rigSPT, setRigSPT] = useState(rigInformation.spt.id_ref)
	const [rigSPTEnergy, setRigSPTEnergy] = useState(rigInformation.spt.energy_ratio)
	const [rigSPTCalibrationDate, setRigSPTCalibrationDate] = useState(rigInformation.spt.calibration_date)

	// console.log(sheetDate, typeof sheetDate, rigTestExpiry, typeof rigTestExpiry, rigSPTCalibrationDate, typeof rigSPTCalibrationDate)

	const sheetDateObj = new Date(sheetDate);
	const rigTestExpiryObj = new Date(rigTestExpiry);
	const rigSPTCalibrationDateObj = new Date(rigSPTCalibrationDate);
	const currentDate = new Date();

	// Calculate the difference in milliseconds between the rig test expiry date and the current date
	const timeDiffRig = rigTestExpiryObj.getTime() - currentDate.getTime();
	// Convert milliseconds to days
	const daysDiffRig = Math.ceil(timeDiffRig / (1000 * 3600 * 24));

	// Calculate the difference in milliseconds between the rig test expiry date and the current date
	const timeDiffSPT = currentDate.getTime() - rigSPTCalibrationDateObj.getTime();
	// Convert milliseconds to days
	const daysDiffSPT = Math.ceil(timeDiffSPT / (1000 * 3600 * 24));

	const [rigTestExpiryText, setRigTestExpiryText] = useState('The Rig Test has expired!!!')
	const [rigSPTCalibrationDateText, setRigSPTCalibrationDateText] = useState('The SPT Calibration has expired!!!')

	const [showRigTypeConflict, setShowRigTypeConflict] = useState(false)
	const [showRigTypeConflictText, setShowRigTypeConflictText] = useState('')

	useEffect(() => {

		if (rigType !== 'Rotary') {
			setShowRigTypeConflict(true)
			setShowRigTypeConflictText('Incorect type of rig for plant check!!!')
		} else {
			setShowRigTypeConflict(false)
			setShowRigTypeConflictText('')
		}

	}, [rigType]);

	const [showTestExpiryInfo, setShowTestExpiryInfo] = useState(true)
	const [showSPTExpiryInfo, setShowSPTExpiryInfo] = useState(true)

	useEffect(() => {

		// Compare the timestamps of the Date objects
		if (sheetDateObj.getTime() < rigTestExpiryObj.getTime()) {
			if (daysDiffRig <= 10 && daysDiffRig > 0) {
				setRigTestExpiryText(`The Rig Test will expire in ${daysDiffRig} days!!!`);
				setShowTestExpiryInfo(true)
			} else if (daysDiffRig === 0) {
				setRigTestExpiryText(`The Rig Test expires today!`);
				setShowTestExpiryInfo(true)
			} else if (daysDiffRig < 0) {
				setRigTestExpiryText(`The Rig Test expired on ${rigTestExpiry}`);
				setShowTestExpiryInfo(true)
			} else {
				setRigTestExpiryText(`The Rig Test is still valid`);
				setShowTestExpiryInfo(false)
			}
			// console.log("Sheet date is before rig test expiry date");
		} else if (sheetDateObj.getTime() > rigTestExpiryObj.getTime()) {
			setRigTestExpiryText('The Rig Test has expired!!!')
			setShowTestExpiryInfo(true)
			// console.log("Sheet date is after rig test expiry date");
		} else {
			// console.log("Sheet date is equal to rig test expiry date");
		}

	}, [sheetDate, rigTestExpiry]);

	useEffect(() => {

		// Compare the timestamps of the Date objects
		if (rigSPTCalibrationDateObj.getTime() < sheetDateObj.getTime()) {
			if (daysDiffSPT <= 10 && daysDiffSPT > 0) {
				setRigSPTCalibrationDateText(`The SPT calibration will expire in ${daysDiffSPT} days!!!`);
				setShowSPTExpiryInfo(true)
			} else if (daysDiffSPT === 0) {
				setRigSPTCalibrationDateText(`The SPT calibration expires today!`);
				setShowSPTExpiryInfo(true)
			} else if (daysDiffSPT < 0) {
				setRigSPTCalibrationDateText(`The SPT calibration was ${rigSPTCalibrationDate}`);
				setShowSPTExpiryInfo(true)
			} else {
				setRigSPTCalibrationDateText(`The SPT calibration is still valid`);
				setShowSPTExpiryInfo(false)
			}
			// console.log("Sheet date is before rig test expiry date");
		} else if (sheetDateObj.getTime() > rigTestExpiryObj.getTime()) {
			setRigSPTCalibrationDateText('The SPT calibration has expired!!!')
			setShowSPTExpiryInfo(true)
			// console.log("Sheet date is after rig test expiry date");
		} else {
			// console.log("Sheet date is equal to rig test expiry date");
		}

	}, [sheetDate, rigTestExpiry]);



	useEffect(() => {

		if (showTestExpiryInfo === true || showSPTExpiryInfo === true) {
			setInformation_check(false)
		} else {
			setInformation_check(true)
		}

	}, [showTestExpiryInfo, showSPTExpiryInfo]);



	let infoWarningIcon = ''

	if (information_check === false) {
		infoWarningIcon = warningIcon
	} else {
		infoWarningIcon = infoIcon
	}

	console.log('newPlantCheck:', newPlantCheck)

	return (

		<>

			{/* <Modal title={`Daily Plant Check (${plant_check_date})`}  toggleModal={onClose}> */}
			<Modal title={`Daily Rotary Plant Check`} toggleModal={onClose} confirm={true}>



				{showRigInformation &&
					<ModalSmall title="Rig Information" toggleModal={() => { setShowRigInformation(false) }} confirm={false}>

						<table className="table-auto w-5/6 text-sm text-center bg-white border border-grey-mid mt-4 mx-auto text-xl">
							<tbody>
								<tr >
									<td className="px-4 py-2 border-r border-b border-grey-mid text-center">
										Conducting Plant Check: <span className="font-bold">{loggedInDriller}</span>
									</td>
								</tr>
								<tr >
									<td className="px-4 py-2 border-r border-b border-grey-mid text-center">
										Date of Plant Check: <span className="font-bold">{sheetDate}</span>
									</td>
								</tr>
								<tr >
									<td className="px-4 py-2 border-r border-b border-grey-mid text-center">
										Rig: <span className="font-bold">{rigName}</span>
										{showRigTypeConflict &&
											<div className="text-base text-red font-bold">
												<img src={warningIcon} width="15" className="mr-1 inline-block pb-0.5" alt="" />
												This Rig is a {rigType} rig!!! Please select correct rig!
											</div>
										}
									</td>
								</tr>
								<tr >
									<td className="px-4 py-2 border-r border-b border-grey-mid text-center">
										Rig Test Expiry: <span className="font-bold">{rigTestExpiry}</span>
										{showTestExpiryInfo &&
											<div className="text-base text-red font-bold">
												<img src={warningIcon} width="15" className="mr-1 inline-block pb-0.5" alt="" />
												{rigTestExpiryText}
											</div>
										}
									</td>
								</tr>
								<tr >
									<td className="px-4 py-2 border-r border-b border-grey-mid text-center">
										SPT Hammer: <span className="font-bold">{rigSPT}</span>
									</td>
								</tr>
								<tr >
									<td className="px-4 py-2 border-r border-b border-grey-mid text-center">
										SPT Hammer Energy Ratio: <span className="font-bold">{rigSPTEnergy}</span>
									</td>
								</tr>
								<tr >
									<td className="px-4 py-2 border-r border-b border-grey-mid text-center">
										SPT Calibration Date: <span className="font-bold">{rigSPTCalibrationDate}</span>
										{showSPTExpiryInfo &&
											<div className="text-base text-red font-bold">
												<img src={warningIcon} width="15" className="mr-1 inline-block pb-0.5" alt="" />
												{rigSPTCalibrationDateText}
											</div>
										}
									</td>
								</tr>
							</tbody>
						</table>

						<div className="items-center mt-6 w-full">
							<Button theme="primary" size="lg" className="mb-8 mx-auto" onClick={() => { setShowRigInformation(false) }}>OK</Button>
						</div>

					</ModalSmall>
				}

				<div onClick={() => { setShowRigInformation(true) }} className="text-center text-lg md:text-2xl font-bold -mt-2 mb-2">
					{sheetDate} {sheetUpdate} <img src={infoWarningIcon} width="25" className="ml-1 inline-block pb-0.5" alt="" />
				</div>



				{/* <button onClick={addNewPlantCheck}>Merge Plant Check</button> */}

				<form onSubmit={(e) => {
					e.preventDefault(); // Prevent the default form submission
					save();
				}}>
					{/* <Heading left={['Phase 1: Pre-Start Checks']} className="pt-0" /> */}

					<div className="border border-grey-mid rounded px-2 pb-1 bg-sptTB">

						<div className="text-lg pb-0 text-center">Mast & Base Frame</div>

						<div className="grid grid-cols-1 md:grid-cols-3 rounded-sm text-sm border-l border-t border-grey-mid">

							<div className="flex border-b  border-r border-grey-mid bg-white">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									General condition of body/cracks damage:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={generalConditionOfBodyCracksDamage} onChange={(value: any) => set_generalConditionOfBodyCracksDamage(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Chain tension and lubrication:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={chainTensionAndLubrication} onChange={(value: any) => set_chainTensionAndLubrication(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Chain pulleys clean and lubricate:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={chainPulleysCleanAndLubricate} onChange={(value: any) => set_chainPulleysCleanAndLubricate(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Lubricate head slides:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={lubricateHeadSlides} onChange={(value: any) => set_lubricateHeadSlides(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Lubricate mast slides:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={lubricateMastSlides} onChange={(value: any) => set_lubricateMastSlides(value.target.checked)} />
								</div>
							</div>


							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Security of bolts:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={securityOfBolts} onChange={(value: any) => set_securityOfBolts(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Pins grease:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={pinsGrease} onChange={(value: any) => set_pinsGrease(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Condition of hydraulic hammer:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={conditionOfHydraulicHammer} onChange={(value: any) => set_conditionOfHydraulicHammer(value.target.checked)} />
								</div>
							</div>

						</div>

					</div>

					{/* <Heading left={['Engine / Chassis / Accessories']} className="pt-0" /> */}

					<div className="border border-grey-mid rounded px-2 pb-1 bg-sptTB mt-2">

						<div className="text-lg pb-0 text-center">Engine / Chassis / Accessories</div>

						<div className="grid md:grid-cols-3 bg-white border-l border-t border-grey-mid rounded-sm text-sm">

							<div className="flex border-b border-r border-grey-mid">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Engine oil level:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={engineOilLevel} onChange={(value: any) => set_engineOilLevel(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Head oil level:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={headOilLevel} onChange={(value: any) => set_headOilLevel(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Hydraulic oil level:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={hydraulicOilLevel} onChange={(value: any) => set_hydraulicOilLevel(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Fuel level:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={fuelLevel} onChange={(value: any) => set_fuelLevel(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Coolant level:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={coolantLevel} onChange={(value: any) => set_coolantLevel(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Check warning lights:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={checkWarningLights} onChange={(value: any) => set_checkWarningLights(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Condition of control panel:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={conditionOfControlPanel} onChange={(value: any) => set_conditionOfControlPanel(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Remote control working:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={remoteControlWorking} onChange={(value: any) => set_remoteControlWorking(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Grease head shaft:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={greaseHeadShaft} onChange={(value: any) => set_greaseHeadShaft(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Security of flush swivel and grease:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={securityOfFlushSwivelAndGrease} onChange={(value: any) => set_securityOfFlushSwivelAndGrease(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Hydraulic circuit leaks / damage:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={hydraulicCircuitLeaksDamage} onChange={(value: any) => set_hydraulicCircuitLeaksDamage(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Emergency stop test:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={emergencyStopTest} onChange={(value: any) => set_emergencyStopTest(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Fixed guard test:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={fixedGuardTest} onChange={(value: any) => set_fixedGuardTest(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Is rig and heat exchanger clean?:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={rigAndHeatExchangerClean} onChange={(value: any) => set_rigAndHeatExchangerClean(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Check battery security and level:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={checkBatterySecurityAndLevel} onChange={(value: any) => set_checkBatterySecurityAndLevel(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Condition of wireline winch:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={conditionOfWirelineWinch} onChange={(value: any) => set_conditionOfWirelineWinch(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Condition of service winch:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={conditionOfServiceWinch} onChange={(value: any) => set_conditionOfServiceWinch(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2 w-2/3 font-bold">
									Engine hours:
								</div>

								<div className="plantCheck text-center w-1/3">
									<input type="number" className="text-lg font-bold text-center h-full w-full" value={engineHours} onChange={(value) => set_engineHours(value.target.value)} />
								</div>
							</div>


						</div>
					</div>



					<div className="border border-grey-mid rounded px-2 pb-1 bg-sptTB mt-2">

						<div className="text-lg pb-0 text-center">Compressor</div>

						<div className="grid grid-cols-1 md:grid-cols-4 border-l border-t border-grey-mid rounded-sm text-sm">

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Compressor engine oil:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={compressorEngineOil} onChange={(value: any) => set_compressorEngineOil(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Compressor hydraulic oil:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={compressorHydraulicOil} onChange={(value: any) => set_compressorHydraulicOil(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Compressor coolant level:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={compressorCoolantLevel} onChange={(value: any) => set_compressorCoolantLevel(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Compressor air lines:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={compressorAirLines} onChange={(value: any) => set_compressorAirLines(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b md:border-b-none border-r border-grey-mid bg-white">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Airline safety connections:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={airlineSafetyConnections} onChange={(value: any) => set_airlineSafetyConnections(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2 w-3/4 font-bold">
									Compressor run hours:
								</div>

								<div className="plantCheck text-center w-1/4">
									<input type="number" className="text-lg font-bold text-center h-full w-full" value={compressorRunHours} onChange={(value) => set_compressorRunHours(value.target.value)} />
								</div>
							</div>

						</div>

					</div>

					{/* <Heading left={['Tools & Equipment']} className="pt-0" /> */}

					<div className="border border-grey-mid rounded px-2 pb-1 bg-sptTB mt-2">

						<div className="text-lg pb-0 text-center">Tools & Equipment</div>

						<div className="grid grid-cols-1 md:grid-cols-4 border-l border-t border-grey-mid rounded-sm text-sm">

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Condition of shackles:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={conditionOfShackles} onChange={(value: any) => set_conditionOfShackles(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Condition of swivels:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={conditionOfSwivels} onChange={(value: any) => set_conditionOfSwivels(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Condition of casing drive head:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={conditionOfCasingDriveHead} onChange={(value: any) => set_conditionOfCasingDriveHead(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Condition of hoisting plugs:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={conditionOfHoistingPlugs} onChange={(value: any) => set_conditionOfHoistingPlugs(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b md:border-b-none border-r border-grey-mid bg-white">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Condition of SPT lifting point:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={conditionOfSPTLiftingPoint} onChange={(value: any) => set_conditionOfSPTLiftingPoint(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Condition of flush pump:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={conditionOfFlushPump} onChange={(value: any) => set_conditionOfFlushPump(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Condition of mist pump:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={conditionOfMistPump} onChange={(value: any) => set_conditionOfMistPump(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b md:border-b-none border-r border-grey-mid bg-white">
								<div className="pt-0.5 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Fire extinguisher good condition:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={fireExtinguisherGoodCondition} onChange={(value: any) => set_fireExtinguisherGoodCondition(value.target.checked)} />
								</div>
							</div>

						</div>

					</div>

					<div className="border border-grey-mid rounded px-2 py-2 bg-sptTB mt-2 mb-2">
						<div className="flex items-center mt-1 mb-1">
							<div className="">
								Issues/Maintenance:
							</div>

							<div className="flex-1 ml-4">
								<input type="text" className="form-control w-full" value={issuesMaintenance} onChange={(value) => set_issuesMaintenance(value.target.value)} />
							</div>
						</div>
					</div>

					{/* <div className="grid grid-cols-2 items-center"> */}
					<div className="w-full flex items-center justify-content-center mb-4">
						<Button type="button" theme="primary" onClick={() => checkAll()} className="mr-2 text-sm px-2 h-12 md:h-10 ml-auto" >Check All</Button>
						<Button type="button" theme="primary" onClick={() => uncheckAll()} className="text-sm px-2 h-12 md:h-10 mr-auto" >Uncheck All</Button>
					</div>

					<div className="w-full">
						{showRigTypeConflictText !== '' && (
							<div className="text-red font-bold w-60 md:w-96 text-base ml-auto mr-0 text-center">{showRigTypeConflictText}</div>
						)}
						{!showRigTypeConflict &&
							<Button type="submit" theme="success" className="px-10 h-12 md:h-16 w-60 md:w-96 text-lg font-bold ml-auto -mt-0 md:-mt-8">Save</Button>
						}
					</div>
					{/* </div> */}

				</form>

			</Modal>
		</>

	)
}

PlantCheck.defaultProps = defaultProps

export default PlantCheck