import React, { FunctionComponent, useState } from 'react'

import WorkflowSteps from '../../WorkflowSteps'
import Modal from '../../Modal'
import Button from '../../form/Button'
import NumberInput from '../../NumberInput'
// import MultiNumberInput from '../../MultiNumberInput'

import { v4 as uuidv4 } from 'uuid';
import stepStatus from '../../../helpers/stepStatus'
//import TimeInput from "../../TimeInput";
import DurationInput from "../../DurationInput"
import warningIcon from '../../../assets/icons/warning.png'

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: string,
	defaultValues?: any,
	selectedDate?: string,
	update?: boolean,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => { },
	onComplete: () => { },
	borehole: '',
	defaultValues: {
		guid: '',
		depth_to_top: '',
		depth_to_base: '',
		//duration: '',
		duration: '00:00',
	},
	selectedDate: '',
}

const InspectionPit: FunctionComponent<Props> = ({ onClose, update, onComplete, borehole, defaultValues, selectedDate, setShowInspectionPitEdit, ...rest }) => {
	const [currentStep, setCurrentStep] = useState(1)

	// const [guid, setGuid] = useState(defaultValues.guid)
	const guid = defaultValues.guid
	const [depth_to_top, setDepthFrom] = useState(defaultValues.depth_to_top)
	const [depth_to_base, setDepthTo] = useState(defaultValues.depth_to_base)
	//const [duration, setTime] = useState(defaultValues.duration)
	const [duration, setDuration] = useState(defaultValues.duration)
	console.log('duration on load:', defaultValues.duration)
	const [saveButtonText, setButtonText] = useState('Continue')
	const [saveButtonColor, setButtonColor] = useState('primary')
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	const DeleteButton = () => {
		if (!update) return null;

		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	// const deleteRecordConfirm = () => {
	// 	setDeleteConfirmOpen(false)
	// 	// onComplete()
	// }

	// const [time_of_action, setTimeOfAction] = useState(defaultValues.time_of_action)
	const time_of_action = defaultValues.time_of_action

	const isoDate = selectedDate ? `${new Date(selectedDate).toISOString().split('T')[0]} 09:00:00` : new Date().toISOString().split('.')[0]

	const previous = () => {
		if (currentStep > 1)
			setCurrentStep(currentStep - 1)
		setButtonText('Continue')
		setButtonColor('primary')
	}

	const next = () => {
		if (currentStep < 3) {
			setCurrentStep(currentStep + 1)
			if (currentStep === 2) {
				setButtonText('Save')
				setButtonColor('success')
			} else {
				setButtonText('Continue')
				setButtonColor('primary')
			}
			return
		}

		if (!depth_to_top || !depth_to_base || duration === '0:0') {
			//do nothing
		} else {

			onComplete({
				guid: update ? guid : uuidv4(),
				depth_to_top: depth_to_top2DP, //2DP,
				depth_to_base: depth_to_base2DP, //2DP,
				duration: duration, // duration_temp, // + ':00', //duration + ':00',
				// general_remarks: '#', /////////////////////////////////////////////////////////
				time_of_action: time_of_action ? time_of_action : isoDate,
				update: update ? true : false,
			})
		}
	}

	let duration_temp = ''
	if(update){
		duration_temp = duration
	} else {
		duration_temp = duration + ':00'
	}

	console.log('duration:', duration)

	let requiredFromText = ''
	let requiredToText = ''
	let requiredDurationText = ''

	if (!depth_to_top) {
		requiredFromText = "'Depth From' - "
	}

	if (!depth_to_base) {
		requiredToText = "'Depth To' - "
	}

	if (duration === '00:00') {
		requiredDurationText = "'Time Taken'"
	}

	const MissingRequiredField = () => {
		if (currentStep === 3 && (!depth_to_top || !depth_to_base || duration === '0:0')) return (
			<div className="text-center bg-sptSB border border-red font-bold rounded mb-2 py-1 text-xs md:text-sm lg:text-base">
				<img src={warningIcon} alt="info" width="20" className="inline-block mr-1 pb-1" />
				Required field: {requiredFromText} {requiredToText} {requiredDurationText}
			</div>
		)

		return null
	}

	// console.log('depth_to_top: ', depth_to_top)
	let depth_to_top2DP = ''
	if (!depth_to_top) {
		depth_to_top2DP = ''
	} else if (depth_to_top === 'GL' || depth_to_top === 'G') {
		depth_to_top2DP = '0'
	} else {
		depth_to_top2DP = parseFloat(depth_to_top).toFixed(2)
	}

	let depth_to_base2DP = ''
	if (!depth_to_base) {
		depth_to_base2DP = ''
	} else {
		depth_to_base2DP = parseFloat(depth_to_base).toFixed(2)
	}

	return (
		<Modal title="Inspection Pit" subtitle={borehole} toggleModal={onClose}>
			<div className="flex flex-col flex-grow px-1 sm:px-2 md:px-10">
				<WorkflowSteps steps={[
					{
						label: 'Depth From',
						status: stepStatus(1, currentStep),
						onClick: () => {
							setCurrentStep(1)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Depth To',
						status: stepStatus(2, currentStep),
						onClick: () => {
							setCurrentStep(2)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Time Taken',
						status: stepStatus(3, currentStep),
						onClick: () => {
							setCurrentStep(3)
							setButtonText('Save')
							setButtonColor('success')
						}
					},
				]} className="mb-2" />

				<div className="flex-1 flex">
					{currentStep === 1 &&
						<NumberInput onUpdate={(value: any) => setDepthFrom(value)}
							title="Enter Depth From (m)"
							defaultValue={depth_to_top}
							className="mb-10 text-2xl"
							presets={[
								{ value: '0', label: 'GL' },
							]}
							next={next}
						/>

						// <MultiNumberInput values={
						// 	[
						// 		{defaultValue: depth_to_top , onUpdate: setDepthFrom, label: 'Depth From (m)'},
						// 		{defaultValue: depth_to_base , onUpdate: setDepthTo, label: 'Depth To (m)'},
						// 	]
						// } className="mb-2 text-xl" />
					}

					{currentStep === 2 &&
						<NumberInput onUpdate={(value: any) => setDepthTo(value)}
							title="Enter Depth To (m)"
							defaultValue={depth_to_base}
							className="mb-10 text-2xl"
							presets={[
								{ value: '1.20', label: '1.20' },
							]}
							next={next}
						/>
					}

					{currentStep === 3 &&
						//<TimeInput onUpdate={(value: any) => setTime(value)} defaultValue={duration} className="mb-10" />
						<DurationInput onUpdate={(value: any) => setDuration(value)}
							title="Enter Time Taken (hh:mm)"
							defaultValue={duration}
							className="mb-10" />
					}
				</div>

				<MissingRequiredField />


				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">


							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?

								<div className="flex mt-4">
									<Button theme="secondary" 
										className="mr-5 text-sm sm:text-base px-3.5 md:px-7" 
										onClick={() => setDeleteConfirmOpen(false)}>
											Cancel
									</Button>

									<Button onClick={() => {
												onClose()
												onComplete({
													guid: guid,
													update: false,
													delete: true,
												})
												setShowInspectionPitEdit(false)
											}}
										className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">
										Delete
									</Button>
								</div>
							</div>


						</div>
					</div>
				}

				<div className="flex">
					{/* <Button theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button> */}
					<DeleteButton />

					<div className="flex ml-auto">
						<Button theme="primary" size="lg" className="px-4 sm:px-10" onClick={() => previous()}>Back</Button>
						<Button theme={saveButtonColor}
							size="lg"
							className="ml-4 px-8 sm:px-10 w-32 sm:w-60"
							onClick={() => next()}>{saveButtonText}
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

InspectionPit.defaultProps = defaultProps

export default InspectionPit
