import React, { FunctionComponent, useState, useEffect, Component } from 'react'

import { useLocation } from "react-router-dom";

import Header from '../layout/Header'

import Modal from '../components/Modal'
import WorkflowSteps from '../components/WorkflowSteps'
import NumberInput from '../components/NumberInput'
import Button from '../components/form/Button'
import MultiSelect from '../components/project/workflow/soil-description/MultiSelect'
import MultiSelectEmailAttachments from '../components/MultiSelectEmailAttachments'
import ButtonOptionsNoGrid from '../components/form/ButtonOptionsNoGrid'
import InputGroup from '../components/form/InputGroup'
import TextArea from '../components/form/TextArea'
import WorkflowActions from '../components/form/WorkflowActions'
import edit from "../assets/icons/edit.svg";
import sortBoth from "../assets/icons/sort_both.svg";
import sortUp from "../assets/icons/sort_up.svg";
import sortDown from "../assets/icons/sort_down.svg";

import stepStatus from '../helpers/stepStatus'

import Label from '../components/Label'
import { Link } from 'react-router-dom'
import RadioSelect from '../components/form/RadioSelect'

import { useGetIDBValues } from "../helpers/getIDBKey";
import { useSetIndexedDBValues } from '../helpers/addToIDB'
import { saveContactList } from '../helpers/saveContactList'
import { get, set } from 'idb-keyval'
import { v4 as uuidv4 } from 'uuid';
import { updateContactInformation } from "../helpers/updateContactInformation";
import { useGetLoggedInUser } from '../helpers/getLoggedInUser'
import Checkbox from "../components/form/Checkbox";

type Props = {
    onClose: any,
    // onComplete: any,
    defaultValues?: any,
    projectId?: string,
    [x: string]: any;
}

const defaultProps: Props = {
    onClose: () => { },
    // onComplete: () => {},
    projectId: '',
    defaultValues: {
        guid: '',
    },
}

interface user {
    id: number;
}
interface UserLog {
    id: number;
    log_date: string;
    hole_name: string;
    project_guid: string;
    // Add other properties here as needed
}



const Attachments: FunctionComponent<Props> = ({ onClose, defaultValues, companyID, onSelectItem, projectId, projectGUID,
    sanitizedProjectLocation, sanitizedProjectName, selected_date, ...rest }) => {


    const [company_id, setCompany_id] = useState(companyID);
    console.log('company_id:', company_id);
    console.log('selected_date:', selected_date);
    // Extract year, month, and day from the selectedDate
    const year = selected_date.getFullYear();
    // Months are zero-indexed, so add 1 to get the correct month
    const month = String(selected_date.getMonth() + 1).padStart(2, '0');
    const day = String(selected_date.getDate()).padStart(2, '0');

    // Concatenate year, month, and day with hyphens
    const formattedSelectedDate = `${year}-${month}-${day}`;

    const [rig_guid, set_rig_guid] = useState('')
    const [rig_type, set_rig_type] = useState('')
    const [plantCheck_type, set_plantCheck_type] = useState('')
    const [userId, set_userId] = useState(0)
    const [userLogs, set_userLogs] = useState<UserLog[]>([]);
    const [currentProjectLogs, set_currentProjectLogs] = useState([])
    const [ags, set_ags] = useState(true)
    const [userRiskAssessments, set_userRiskAssessments] = useState([])
	const [logsToAttach, setLogsToAttach] = useState<any[]>([])
    console.log('logsToAttach:', logsToAttach)
    console.log('rig_guid:', rig_guid)
    console.log('rig_type:', rig_type)
    console.log('plantCheck_type:', plantCheck_type)

    useEffect(() => {
        getCurrentRig()
    }, []);

    const getCurrentRig = () => {
        // get(`drillit-currentRig-${this.props.router.match.params?.project}`)
        get(`drillit-currentRig`)
			.then(data => {

				console.log('current rig data:', data)

				if (data) {
                    console.log('SET RIG DATA!!!')
					set_rig_guid(data?.guid)
                    set_rig_type(data?.type)
				}
			})
			.catch(error => {
				console.error("Error fetching data:", error);
			})
    }

    // PLANTCHECK
    interface PlantCheck {
        plant_check_date: string;
        plant_id: string;
        plantcheck_url: string;
    }
    const [plantChecks, setPlantChecks] = useState<PlantCheck[]>([]);


    useEffect(() => {
        if (rig_type === 'Cable Percussive') {
            set_plantCheck_type('drillit-plant-check-cable-percussive');
        } else if (rig_type === 'Rotary') {
            set_plantCheck_type('drillit-plant-check-rotary');
        } else if (rig_type === 'Window Sample') {
            set_plantCheck_type('drillit-plant-check-window-sample');
        }
    }, [rig_type]);

    useEffect(() => {
        let cachedValues = useGetIDBValues;
        if (rig_guid) {
            console.log('Rig GUID good! Get Plant Checks')
            // get(`$(plantCheck_type)/$(rig_guid)`)
            get(plantCheck_type+'/'+rig_guid)
			.then(data => {

				console.log('current rig data:', data)

                if (data) {
                    const plantChecks = data.plantCheck;
                    // Set the state with the plant checks
                    setPlantChecks(plantChecks)

                } else {
                    setPlantChecks([])
                    // Handle error case if needed
                    console.error('Error fetching plant checks');
                }
			})
			.catch(error => {
				console.error("Error fetching plant checks:", error);
			})
        }
    }, [rig_guid, plantCheck_type]);

    console.log('plantChecks:', plantChecks)

    const loggedInUser = useGetLoggedInUser()
    console.log('loggedInUser:', loggedInUser)


    useEffect(() => {
        if (loggedInUser && loggedInUser.user) {
            const userId = loggedInUser.user.id
            set_userId(userId);
        }
    }, [loggedInUser]);
    // console.log('userId:', userId)


    useEffect(() => {
        let cachedValues = useGetIDBValues;
        if (userId) {
            const userLogs = 'logs/user/' + userId;
            // cachedValues(userLogs, (data: any) => {

            //     // console.log('userLogs:', data)

            //     if (data) {
            //         const userLogs = data.log;
            //         // Set the state with the user logs
            //         set_userLogs(userLogs)

            //     } else {
            //         set_userLogs([])
            //         // Handle error case if needed
            //         console.error('Error fetching user logs');
            //     }

            // });
            get('drillit-logs')
			.then(data => {

				console.log('current log data:', data)

                if (data) {
                    const userLogs = data;
                    // Set the state with the logs
                    set_userLogs(userLogs)

                } else {
                    set_userLogs([])
                    // Handle error case if needed
                    console.error('Error fetching logs');
                }
			})
			.catch(error => {
				console.error("Error fetching logs:", error);
			})
        }
    }, [userId]);

    console.log('userLogs:', userLogs)

    interface Log {
        id: number;
        project_name: string;
        log_date: string;
        log_save_date_time: string;
        log_url: string;
        hole_name: string;
    }

    // Function to filter logs by project_name
    const filterLogsByProjectName = (logs:any, projectName:any) => {
        return logs.filter((log: Log) => log.project_name === projectId);
    };

    // Usage: Set currentProjectLogs by filtering userLogs by project_name
    useEffect(() => {
        // Filter userLogs by project_name
        const logsForProject = filterLogsByProjectName(userLogs, projectId);
        // Set currentProjectLogs state with filtered logs
        set_currentProjectLogs(logsForProject);
    }, [userLogs, projectId]);

    console.log('currentProjectLogs:', currentProjectLogs)


    useEffect(() => {
        let cachedValues = useGetIDBValues;
        console.log('projectGUID:', projectGUID, 'userId:', userId)
        if (userId && projectGUID) {
            const userRAs = 'risk-assessment/project/' + projectGUID + '/user/' + userId;
            const userRAsIDB = 'drillit-risk-assessment/project/' + projectGUID;
            // cachedValues(userRAs, (data: any) => {

            //     console.log('userRAs:', data)

            //     if (data) {
            //         const userRAs = data.riskAssessment;
            //         // Set the state with the user logs
            //         set_userRiskAssessments(userRAs)

            //     } else {
            //         set_userRiskAssessments([])
            //         // Handle error case if needed
            //         console.error('Error fetching user Risk Assessments');
            //     }

            // });
            get(userRAsIDB)
			.then(data => {

				console.log('current log data:', data)

                if (data) {
                    const userRAs = data;
                    // Set the state with the logs
                    set_userRiskAssessments(userRAs)

                } else {
                    set_userRiskAssessments([])
                    // Handle error case if needed
                    console.error('Error fetching RiskAssessments');
                }
			})
			.catch(error => {
				console.error("Error fetching RiskAssessments:", error);
			})
        }
    }, [userId, projectGUID]);

    console.log('userRiskAssessments:', userRiskAssessments)


    // LOGS
    // Filter userLogs based on project_guid
    const filteredLogs = userLogs.filter(log => log.project_guid === projectGUID);
    // Extract hole_name values from filteredLogs array
    const logNames = filteredLogs.map(log => log.hole_name);
    // Extract log_date values from filteredLogs array
    const logDates = filteredLogs.map(log => log.log_date);
    // Extract log_date and hole_name values from userLogs array
    const logDatesAndHoleNames = userLogs.map(log => ({
        log_date: log.log_date,
        hole_name: log.hole_name
    }));
    // Extract unique logs based on hole_name and log_date
    const uniqueLogs: Record<string, Log> = filteredLogs.reduce((unique:any, log:any) => {
        const key = log.hole_name + log.log_date;
        if (!unique[key]) {
            unique[key] = log;
        }
        return unique;
    }, {});
    
    const logUniqueIdentifiers = Object.values(uniqueLogs).map(log => ({
        identifier: `${log.hole_name}`,
        hole_name: log.hole_name,
        log_date: log.log_date,
        log_url: company_id+'/logs/'+log.log_url
    }));




    // AGS
    // DataTown-AG666666.ags

    // const agsValue = sanitizedProjectLocation +'-'+sanitizedProjectName
    const agsValue = 'Full Project AGS'
    const agsSubtitle = 'AGS'
    const agsLogURL = company_id+'/ags/'+sanitizedProjectLocation +'-'+sanitizedProjectName+'.ags'

    console.log('agsValue:', agsValue)


    // PLANT CHECKS

    const [displayedPlantChecks, setDisplayedPlantChecks] = useState<PlantCheck[]>([]);
    const [displayCount, setDisplayCount] = useState<number>(5);

    // Effect to update displayed checks when plant checks change
    useEffect(() => {
        // Initial sorting of plant checks by date in descending order
        const sortedChecks = plantChecks.sort((a, b) => new Date(b.plant_check_date).getTime() - new Date(a.plant_check_date).getTime());
        // Set initial displayed plant checks
        const sortedDisplayedChecks = sortedChecks.slice(0, displayCount).sort((a, b) => new Date(b.plant_check_date).getTime() - new Date(a.plant_check_date).getTime());
        setDisplayedPlantChecks(sortedDisplayedChecks);
    }, [plantChecks, displayCount]);

    // Function to handle loading more plant checks
    const loadMorePlantChecks = () => {
        // Increase display count by 5
        setDisplayCount(prevCount => prevCount + 5);
    };
    // Extracting values for the multi-select options
    const plantCheckDates = displayedPlantChecks.map(plant_check => plant_check.plant_check_date);
    const plantCheckIds = displayedPlantChecks.map(plant_check => plant_check.plant_id);
    const plantCheckURL = displayedPlantChecks.map(plant_check => `${company_id}/plant-checks/${plant_check.plantcheck_url}`);






    // RISK ASSESSMENT
    interface RiskAssessment {
        project_location: string;
        project_name: string;
        project_id: string;
        risk_assessment_date: string;
        driller_id: string;
        log_url: string;
        // Add other properties if needed
    }


    const riskAssessmentUniqueIdentifiers = (userRiskAssessments as RiskAssessment[]).map(assessment => {
        // Remove spaces from project_name and project_location
        const sanitizedProjectLocationRA = assessment.project_name.replace(/[ -]+/g, '');
        const sanitizedProjectNameRA = assessment.project_id.replace(/[ -]+/g, '');
    
        return {
            identifier: `${assessment.project_location} - ${assessment.risk_assessment_date}`,
            project_location: assessment.project_location,
            risk_assessment_date: assessment.risk_assessment_date,
            log_url: `${company_id}/risk_assessments/${sanitizedProjectLocationRA}-${sanitizedProjectNameRA}-${assessment.driller_id}.pdf`
        };
    });

    

    const handleSendToSelected = () => {
        onSelectItem(logsToAttach);
        onClose()
    };

    // // Function to handle the click event of the button
    // const handleSelectAllLogs = () => {
    //     // Filter the userLogs array to select only the logs with the same log_date as formattedSelectedDate
    //     const logsToAttach = userLogs.filter(log => log.log_date === formattedSelectedDate);

    //     // Set the selected logs to logsToAttach
    //     setLogsToAttach(logsToAttach);
    // };

    // const [selectedLogs, setSelectedLogs] = useState<string[]>([]); // State to hold selected logs

    // // Function to simulate clicks on each log button
    // const selectAllLogs = (logURLs: string[]) => {
    //     const logsToSelect = logURLs.filter(logURL => !selectedLogs.includes(logURL)); // Filter out already selected logs
    //     setSelectedLogs([...selectedLogs, ...logsToSelect]); // Add logs to selected logs state
    // };

    console.log('logsToAttach:', logsToAttach)




    return (

        <>

            <Modal title="Attachments" subtitle="" toggleModal={onClose} confirm={false} >
                <>

                    <div className="border border-grey-mid rounded p-2 bg-sptTB w-full md:w-11/12 justify-center mx-auto mb-2 text-sm md:text-base">

                        <div className="text-lg font-bold text-center">Logs</div>
                        
						<MultiSelectEmailAttachments title="" 
                        selectedValues={logsToAttach} 
                        setSelectedValue={setLogsToAttach}
                        values={logUniqueIdentifiers.map(log => log.identifier)}
                        subtitle={logUniqueIdentifiers.map(log => log.log_date)}
                        dates={logUniqueIdentifiers.map(log => log.log_date)}
						columns={6}
						columnsMobile={3}
                        logURL={logUniqueIdentifiers.map(log => log.log_url)}
                        selectedDate={formattedSelectedDate}
                        type='logs'
						className="text-base sm:text-lg m-1 py-1"
						/>

                    </div>

                    <div className="border border-grey-mid rounded p-2 bg-sptTB w-full md:w-11/12 justify-center mx-auto mb-2 text-sm md:text-base">

                        <div className="text-lg font-bold text-center">AGS Data</div>

                        {/* <div className="text-xs font-bold text-center flex justify-center ">
                                <input type="checkbox" checked={ags}
    								onChange={(e) => set_ags(e.target.checked)}
                                    className="scale-150 transform-gpu mx-auto pl-2 mt-1.5 form-control-checkbox" />
                        </div> */}
                        
                        <MultiSelectEmailAttachments
                            title=""
                            selectedValues={logsToAttach}
                            setSelectedValue={setLogsToAttach}
                            values={[agsValue]}
                            subtitle={[agsSubtitle]}
                            columns={6}
                            columnsMobile={3}
                            logURL={[agsLogURL]}
                            selectedDate={formattedSelectedDate}
                            type='ags'
                            className="text-base sm:text-lg m-1 py-1"
                        />

                    </div>

                    <div className="border border-grey-mid rounded p-2 bg-sptTB w-full md:w-11/12 justify-center mx-auto mb-2 text-sm md:text-base">

                        <div className="text-lg font-bold text-center">Plant Check</div>
                        
                        <MultiSelectEmailAttachments
                            title=""
                            selectedValues={logsToAttach}
                            setSelectedValue={setLogsToAttach}
                            values={plantCheckDates}
                            subtitle={plantCheckIds}
                            dates={plantCheckDates}
                            columns={6}
                            columnsMobile={3}
                            logURL={plantCheckURL}
                            selectedDate={formattedSelectedDate}
                            type='plantChecks'
                            className="text-base sm:text-lg m-1 py-1"
                        />

                        <div onClick={loadMorePlantChecks} className="text-sm text-center">Click to load more</div>

                    </div>

                    <div className="border border-grey-mid rounded p-2 bg-sptTB w-full md:w-11/12 justify-center mx-auto mb-2 text-sm md:text-base">

                        <div className="text-lg font-bold text-center">Risk Assessment</div>
                        
						<MultiSelectEmailAttachments title="" 
                        selectedValues={logsToAttach} 
                        setSelectedValue={setLogsToAttach} 
                        values={riskAssessmentUniqueIdentifiers.map(assessment => assessment.project_location)}
                        subtitle={riskAssessmentUniqueIdentifiers.map(assessment => assessment.risk_assessment_date)}
                        dates={riskAssessmentUniqueIdentifiers.map(assessment => assessment.risk_assessment_date)}
						columns={6}
						columnsMobile={3}
                        logURL={riskAssessmentUniqueIdentifiers.map(assessment => assessment.log_url)}
                        selectedDate={formattedSelectedDate}
                        type='riskAssessments'
						className="text-base sm:text-lg m-1 py-1"
						/>

                    </div>

                    <div className="text-center">
                        <Button type="submit" theme="primary" className="w-60 py-4 text-lg font-bold mx-auto"
                        onClick={handleSendToSelected} >Attach Selected</Button>
                    </div>

                </>

            </Modal >

        </>

    )
}

Attachments.defaultProps = defaultProps

export default Attachments