import React, { FunctionComponent, useState } from 'react'

import WorkflowSteps from '../../WorkflowSteps'
import Modal from '../../Modal'
import Button from '../../form/Button'
import NumberInput from '../../NumberInput'
import MultiNumberInput from '../../MultiNumberInput'

import {v4 as uuidv4} from 'uuid';
import stepStatus from '../../../helpers/stepStatus'
import warningIcon from '../../../assets/icons/warning.png'

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: string,
	defaultValues?: any,
	update?: any,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => {},
	onComplete: () => {},
	borehole: '',
	defaultValues: {
		guid: '',
		start_boring: '',
		end_boring: '',
	}
}

const WaterLevel: FunctionComponent<Props> = ({ onClose, update, onComplete, borehole, defaultValues, selectedDate, setShowDailyWaterLevelsEdit, ...rest }) => {
	//const [drill_core_size_id, setDrillCoreSize] = useState(defaultValues.drill_core_size_id)
	const [start_boring, setDepthStart] = useState(defaultValues.start_boring ?? '')
	// const [end_boring, setDepthEnd] = useState(defaultValues.end_boring)
	const [end_boring, setDepthEnd] = useState(defaultValues.end_boring ?? '');
	const [guid, setGuid] = useState(defaultValues.guid)
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	const DeleteButton = () => {
		if (!update) return null;
	
		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	const deleteRecordConfirm = () => {
		setDeleteConfirmOpen(false)
		// onComplete()
	}
	const time_of_action = defaultValues.time_of_action

	const isoDate = selectedDate ? `${new Date(selectedDate).toISOString().split('T')[0]} 09:00:00` : new Date().toISOString().split('.')[0]

	const save = () => {

		if (!start_boring && !end_boring) {
			//do nothing
		} else {

		onComplete({
			guid: guid ? guid : uuidv4(),
			//drill_core_size_id: drill_core_size_id,
			// start_boring: parseFloat(start_boring).toFixed(2), //2DP,
			// start_boring: start_boring !== '' ? parseFloat(start_boring).toFixed(2) : '',
			start_boring: start_boring !== '' && start_boring !== 'dry' ? parseFloat(start_boring).toFixed(2) : start_boring, // If end_boring is 'dry', keep it as 'dry'
			// end_boring: parseFloat(end_boring).toFixed(2), //2DP,
			// end_boring: end_boring !== '' ? parseFloat(end_boring).toFixed(2) : '',
			end_boring: end_boring !== '' && end_boring !== 'dry' ? parseFloat(end_boring).toFixed(2) : end_boring, // If end_boring is 'dry', keep it as 'dry'
			general_remarks: '',
			// time_of_action: new Date().toISOString().split('.')[0],
			time_of_action: time_of_action ? time_of_action : isoDate,
			update: update ? true : false,
		})
		}
	}

	const MissingRequiredField = () => {
		if (!start_boring && !end_boring) return (
			<div className="text-center bg-sptSB border border-red font-bold rounded mb-2 py-1 text-xs md:text-sm lg:text-base">
				<img src={warningIcon} alt="info" width="20" className="inline-block mr-1 pb-1" />
				Required field: 'Water Levels'
			</div>
		)

		return null
	}

	// var depth_start2DP = parseFloat(start_boring).toFixed(2)
	// var depth_end2DP = parseFloat(end_boring).toFixed(2)

	return (
		<Modal title="Start &amp; End Day Water Level" subtitle={borehole} toggleModal={onClose}>
			<div className="flex flex-col flex-grow px-1 sm:px-2 md:px-10">
				
				<div className="text-xl text-center mb-2">
					Enter Water Level (m)
				</div>

				<div className="flex-1 flex text-center">
					<MultiNumberInput values={
							[
								{defaultValue: start_boring , onUpdate: setDepthStart, label: 'Start Boring (m)'},
								{defaultValue: end_boring , onUpdate: setDepthEnd, label: 'End Boring (m)'},
							]
						}
						className="mb-2 text-base sm:text-xl md:text-xl" 
						inputCSS="text-4xl sm:text-4xl md:text-3xl lg:text-4xl h-16 sm:h-16 mb-2" 
						dryButton={true}
						/>
				</div>

<MissingRequiredField />
				

				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">
	
	
							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?
	
								<div className="flex mt-4">
									<Button theme="secondary" 
										className="mr-5 text-sm sm:text-base px-3.5 md:px-7" 
										onClick={() => setDeleteConfirmOpen(false)}>
											Cancel
									</Button>

									<Button onClick={() => {
												onClose()
												onComplete({
													guid: guid,
													update: false,
													delete: true,
												})
												setShowDailyWaterLevelsEdit(false)
											}}
										className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">
										Delete
									</Button>
								</div>
							</div>
	
	
						</div>
					</div>
				}

				<div className="flex">
					{/* <Button theme="secondary" size="lg">Delete</Button> */}

					<DeleteButton/>

					<div className="flex ml-auto">
						<Button theme="success" size="lg" className="ml-4" onClick={() => save()}>Save &amp; Continue</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

WaterLevel.defaultProps = defaultProps

export default WaterLevel
