import React, { FunctionComponent } from 'react'

type Props = {
	type?: string,
	label: string,
	value: string,
	placeholder?: string,
	required?: boolean,
	className?: string,
	inputCSS?: string,
	autoComplete?: string,
	onChange: (value: any) => void;
	[x: string]: any;
}

const defaultProps: Props = {
	type: 'textarea',
	label: '',
	value: '',
	placeholder: '',
	required: false,
	className: '',
	inputCSS: '',
	autoComplete: '',
	onChange: (value) => {},
}

const InputGroup: FunctionComponent<Props> = ({ type, label, value, placeholder, required, className, inputCSS, onChange, ...rest }) => {
	const handleChange = (event: any) => {
		onChange(event.target.value)
	}

	return (
		<div className={`flex flex-col ${className}`}>
			{/* {label && <label className="w-full text-charcoal mb-1">{label}</label>} */}
			{label && <label className="w-full mb-1">{label}</label>}

		<input type={type} 
		className={`form-control ${inputCSS}`} 
		placeholder={placeholder} 
		value={value?value:''} 
		required={required} 
		autoComplete="off" // Disable autocomplete
		onChange={handleChange} 
		{...rest} />
		</div>
	)
}

InputGroup.defaultProps = defaultProps

export default InputGroup
