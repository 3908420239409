import { del, get, set, keys } from 'idb-keyval';

export const updatePlantCheckCable = (
    towHitchHandbrakeSafteyCable: number,
    allDrillingEquipmentGood: number,
    wireRopeInDateGoodLoop: number,
    fuelOilLevelsLeaks: number,
    footBrakeInGoodOrder: number,
    fireExtinguisherFirstAid: number,
    plantNappyRequired: number,
    spillKit: number,
    safteyGuards: number,
    wheelsTyres: number,
    cluchInGoodOrder: number,
    allLiftingPointsGood: number,
    rigFrameRetainingPins: number,
    engineKillStop: number,
    drillingArea: number,
    electricWinchRemote: number,
    ignitionSystem: number,
    mainWinchOperation: number,
    footBrake: number,
    engineOperation: number,
    rigFrame: number,
    issuesMaintenance: string,
    company_guid: string,
    company_id: number,
    driller_name: string,
    driller_cscs: string,
    guid: string,
    plant_guid: string,
    plant_id: string,
    plant_name: string,
    spt_rod_type: string,
    spt_id_ref: string,
    spt_energy_ratio: string,
    spt_calibration_date: string,
    plant_test_date_expiry: string,
    plant_check_date: string,
    plantcheck_url: string,
    projectGUID: string,
    mergedRigPlantChecksUpdated: any
) => {
    return new Promise(async (resolve, reject) => {
        const endpoint = `${process.env.REACT_APP_API_ADDR}plant-check-cable-percussive/${guid}` // update record with [guid]
        console.log('endpoint:', endpoint);
        
        const updateData = {
            'towHitchHandbrakeSafteyCable': towHitchHandbrakeSafteyCable ? 1 : 0,
            'allDrillingEquipmentGood': allDrillingEquipmentGood ? 1 : 0,
            'wireRopeInDateGoodLoop': wireRopeInDateGoodLoop ? 1 : 0,
            'fuelOilLevelsLeaks': fuelOilLevelsLeaks ? 1 : 0,
            'footBrakeInGoodOrder': footBrakeInGoodOrder ? 1 : 0,
            'fireExtinguisherFirstAid': fireExtinguisherFirstAid ? 1 : 0,
            'plantNappyRequired': plantNappyRequired ? 1 : 0,
            'spillKit': spillKit ? 1 : 0,
            'safteyGuards': safteyGuards ? 1 : 0,
            'wheelsTyres': wheelsTyres ? 1 : 0,
            'cluchInGoodOrder': cluchInGoodOrder ? 1 : 0,
            'allLiftingPointsGood': allLiftingPointsGood ? 1 : 0,
            'rigFrameRetainingPins': rigFrameRetainingPins ? 1 : 0,
            'engineKillStop': engineKillStop ? 1 : 0,
            'drillingArea': drillingArea ? 1 : 0,
            'electricWinchRemote': electricWinchRemote ? 1 : 0,
            'ignitionSystem': ignitionSystem ? 1 : 0,
            'mainWinchOperation': mainWinchOperation ? 1 : 0,
            'footBrake': footBrake ? 1 : 0,
            'engineOperation': engineOperation ? 1 : 0,
            'rigFrame': rigFrame ? 1 : 0,
            'issuesMaintenance': issuesMaintenance,
            'company_guid': company_guid,
            'company_id': company_id,
            'driller_name': driller_name,
            'driller_cscs': driller_cscs,
            'guid': guid,
            'plant_guid': plant_guid,
            'plant_id': plant_id,
            'plant_name': plant_name,
            'spt_rod_type': spt_rod_type,
            'spt_id_ref': spt_id_ref,
            'spt_energy_ratio': spt_energy_ratio,
            'spt_calibration_date': spt_calibration_date,
            'plant_test_date_expiry': plant_test_date_expiry,
            'plant_check_date': plant_check_date,
            'plantcheck_url': plantcheck_url,
            'project_guid': projectGUID,
        };

        // Delete any existing records
        const offlineKey = `drillit-plant-check-cable-percussive/${plant_guid}-update`;
        try {
            await del(offlineKey);
            console.log(`Deleted existing record: ${offlineKey}`);
        } catch (error) {
            console.error('Error deleting existing record:', error);
        }

        // Make the request and handle response
        makeRequest(endpoint, JSON.stringify(updateData), 'PUT')
            .then(response => {
                if (response.status !== 200) {
                    saveToIndexedDB(offlineKey, updateData);
                }
                resolve(true);
            })
            .catch(error => {
                console.error('Error making request:', error);
                saveToIndexedDB(offlineKey, updateData);
                resolve(false);
            });

        // Update the currentRigPlantChecks state in IndexedDB
        try {
            set(`drillit-plant-check-cable-percussive/${plant_guid}`, mergedRigPlantChecksUpdated);
            console.log('Updating drillit-plant-check-cable-percussive/');
        } catch (error) {
            console.error('Error updating current rig plant checks:', error);
        }
    });
};

const makeRequest = (endpoint: string, data: string, postOrPut: string) => {
    const token = localStorage.getItem('drillit-token');

    console.log('MAKING REQUEST:', data);

    return fetch(endpoint, {
        method: postOrPut,
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: data,
    })
        .then(response => response)
        .catch(err => err);
};

const saveToIndexedDB = async (key:any, data:any) => {
    try {
        await set(key, data);
        console.log(`Data saved to IndexedDB with key: ${key}`);
    } catch (error) {
        console.error('Error saving data to IndexedDB:', error);
    }
};
