import React from "react"
import {del, get, set} from "idb-keyval/dist/cjs";
import GetRequest from "./getRequest";
import syncStatus, {SyncStatus} from "./syncStatus";

type theKeys = {}

export const sortCurrentUdpatesAndNew = (theKeys: theKeys): string => {
    let companyGuid: string = '';
    const userCompany = async () => {
        return await get('drillit-user').then(data => {
            companyGuid = data.user.company.guid;
            return companyGuid;
        });
    };

    userCompany().then(() => {
        if (!theKeys || Object.keys(theKeys).length === 0) {
            console.warn('No keys provided or theKeys is empty.');
            return 'checked'; // Return or handle as needed
        }

        // Proceed with processing the keys
        let apiGetAddr: string = `${process.env.REACT_APP_API_ADDR}companies/${companyGuid}`;
        const checkOnline = GetRequest(apiGetAddr);

        checkOnline.then(data => {
            if (data.status === 404) {
                alert("Can't connect, check you are online");
                return data;
            } else {
                const endpoint = `${process.env.REACT_APP_API_ADDR}`;
                let action: string = '';
                let postOrPut: string = '';

                // Process the keys based on their values
                if (Object.values(theKeys).includes('drillit-hole-locations/project/json-updateHoles') && Object.keys(theKeys).length >= 1) {
                    console.log('its a hole!');
                    const token = localStorage.getItem('drillit-token');

                    const promiseExistingHoles = get(`drillit-hole-locations/project/json-updateHoles`)
                        .then(res => {
                            return fetch(`${process.env.REACT_APP_API_ADDR}hole-locations/project/json`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Accept': 'application/json',
                                    'Authorization': `Bearer ${token}`,
                                },
                                body: JSON.stringify(res)
                            }).then(res => {
                                if (res.status === 200) {
                                    del(`drillit-hole-locations/project/json-updateHoles`)
                                        .then(() => {
                                            // Handle additional actions if needed
                                            processRemainingKeys(theKeys, endpoint);
                                        });
                                }
                            });
                        });

                } else {
                    // Handle other types of actions (-new, -update, -delete)
                    processRemainingKeys(theKeys, endpoint);
                }

                return data;
            }
        });
    }).catch(error => {
        console.error("Error in sortCurrentUdpatesAndNew: ", error);
    });

    return 'checked';
};

const processRemainingKeys = (theKeys: theKeys, endpoint: string) => {
    Object.entries(theKeys).forEach(([key, value]) => {
        let action: string = '';
        let postOrPut: string = '';

        if (key.endsWith('-new') && Object.values(theKeys).includes('drillit-action-update')) {
            console.log('new and update tests');
            currentKey(key, 'POST', endpoint);
            currentKey('drillit-action-update', 'PUT', endpoint);
        } else if (key.endsWith('-update') && Object.values(theKeys).includes('drillit-action-new')) {
            console.log('update and new tests');
            currentKey(key, 'PUT', endpoint);
            currentKey('drillit-action-new', 'POST', endpoint);
        } else if (key.endsWith('-delete') && (Object.keys(theKeys).length === 1)) {
            console.log('delete test');
            currentKey(key, 'DELETE', endpoint);
        } else if (key.endsWith('-new') && (Object.keys(theKeys).length === 1)) {
            console.log('new test');
            currentKey(key, 'POST', endpoint);
        } else if (key.endsWith('-update') && (Object.keys(theKeys).length === 1)) {
            console.log('update test');
            currentKey(key, 'PUT', endpoint);
        } else if (key.endsWith('-new') && Object.keys(theKeys).length === 2) {
            console.log('new tests');
            currentKey(key, 'POST', endpoint);
        } else if (key.endsWith('-update') && Object.keys(theKeys).length === 2) {
            console.log('update tests');
            currentKey(key, 'PUT', endpoint);
        } else if (key.endsWith('-delete') && Object.keys(theKeys).length === 2) {
            console.log('delete tests');
            currentKey(key, 'DELETE', endpoint);
        } else if (key.endsWith('-new') && Object.values(theKeys).includes('drillit-action-update') && Object.keys(theKeys).length === 3) {
            console.log('new, update, and delete tests');
            currentKey(key, 'POST', endpoint);
            currentKey('drillit-action-update', 'PUT', endpoint);
            currentKey('drillit-action-delete', 'DELETE', endpoint);
        } else if (key.endsWith('-update') && Object.values(theKeys).includes('drillit-action-new') && Object.keys(theKeys).length === 3) {
            console.log('update, new, and delete tests');
            currentKey(key, 'PUT', endpoint);
            currentKey('drillit-action-new', 'POST', endpoint);
            currentKey('drillit-action-delete', 'DELETE', endpoint);
        } else if (key.endsWith('-delete') && Object.values(theKeys).includes('drillit-action-new') && Object.keys(theKeys).length === 3) {
            console.log('delete, new, and update tests');
            currentKey(key, 'DELETE', endpoint);
            currentKey('drillit-action-new', 'POST', endpoint);
            currentKey('drillit-action-update', 'PUT', endpoint);
        } else {
            console.log('Unhandled key:', key);
        }
    });
};


export default sortCurrentUdpatesAndNew
// get all of the updates and new inserts waiting to sync with a map/filter
// or do we just pass them from sync button
// check for A 404 response first, as if we get that then a connection can't be made for whatever reason
// use get a company as the company guid is in the user details

const currentKey = (key: string, postOrPut: string, endpoint: string) => {
    get(key)
        .then((res) => {
            if (Object.keys(res).length > 0) {
                let removedItemsArray: any = res;

                Object.values(res).forEach((upd: any) => {
                    let fullEndpoint = '';
                    if (postOrPut === 'POST') {
                        fullEndpoint = `${endpoint}action`;
                    } else if (postOrPut === 'PUT') {
                        fullEndpoint = `${endpoint}action/${upd.data.guid}`;
                    } else if (postOrPut === 'DELETE') {
                        fullEndpoint = `${endpoint}action/${upd.data.guid}`;
                    }

                    const requestAttempt = makeRequest(fullEndpoint, JSON.stringify(upd), postOrPut);
                    requestAttempt.then(posted => {
                        if (posted.status === 200) {
                            removedItemsArray = removedItemsArray.filter((item: any) => item !== upd);
                            console.log(removedItemsArray);
                        }
                        return posted;
                    }).then(endResult => {
                        if (removedItemsArray.length === 0) {
                            return del(key).then(res => res);
                        }
                        return endResult;
                    });
                });
            }
        });
};

const makeRequest = (endpoint: string, data: string, postOrPutOrDelete: string) => {
    const token = localStorage.getItem('drillit-token')

    return fetch(endpoint, {
        method: postOrPutOrDelete,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: data
    })
        //return the status code here
        .then(response => response)
        .catch(err => err)
}
const getIDB = (keyValue: string) => get(keyValue).then(data => data)