import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

import Button from '../../../form/Button'

import properCapitalization from '../../../../helpers/properCapitalization'

type Props = {
	consistencyType: object[],
	setConsistencyType: any;
	[x: string]: any;
}

const defaultProps: Props = {
	consistencyType: [],
	setConsistencyType: () => {},
}

const consistencyTypes = [
	{title: 'very soft', description: 'Finger easily pushed in up to 25mm. Exudes between fingers.', order: 1},
	{title: 'soft', description: 'Finger pushed in up to 10mm. Moulds by light finger pressure.', order: 2},
	{title: 'firm', description: 'Thumb makes impression easily. Cannot be moulded by fingers. Rolls to thread.', order: 3},
	{title: 'stiff', description: 'Can be indented slightly by thumb. Crumbles in rolling thread. Remoulds.', order: 4},
	{title: 'very stiff', description: 'Can be indented by thumbnail. Cannot be moulded, crumbles.', order: 5},
	{title: 'hard', description: 'Can be scratched by thumbnail.', order: 6},
]

const ConsistencyTypes: FunctionComponent<Props> = ({ consistencyType, setConsistencyType, ...rest }) => {
	const toggleConsistancyType = (consistency: object) => {
		if(consistencyType.includes(consistency)) {
			setConsistencyType(consistencyType.filter(item => item !== consistency))
		} else {
			setConsistencyType([...consistencyType, consistency])
		}
	}

	return (
		<div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3 md:gap-3 mt-4 mb-4">
			{consistencyTypes.map((consistencyTypeLabel, index) =>
				<div className={`md:col-span-1 text-charcoal h-16 px-1 py-1 mb-2 flex flex-col text-center justify-center items-center text-base md:text-lg bg-white ${consistencyType.includes(consistencyTypeLabel) ? 'border-4' : 'border'} border-orange rounded`} onClick={() => toggleConsistancyType(consistencyTypeLabel)} key={index}>
					{properCapitalization(consistencyTypeLabel.title)}
					<div className="text-xs">{consistencyTypeLabel.description}</div>
				</div>
			)}
		</div>
	)
}

ConsistencyTypes.defaultProps = defaultProps

export default ConsistencyTypes