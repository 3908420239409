import React, { FunctionComponent, useState } from 'react'

import WorkflowSteps from '../../WorkflowSteps'
import Modal from '../../Modal'
import Button from '../../form/Button'
import NumberInput from '../../NumberInput'
import ButtonOptions from '../../form/ButtonOptions'
import {v4 as uuidv4} from 'uuid';

import stepStatus from '../../../helpers/stepStatus'
import warningIcon from '../../../assets/icons/warning.png'

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: string,
	defaultValues?: any,
    selectedDate?: string
	update?: boolean,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => {},
	onComplete: () => {},
	borehole: '',
	defaultValues: {
		casing_diameter: '',
		depth_to_top: '',
		depth_to_base: '',
		depth_of_borehole: '',
	},
    selectedDate: '',
}

const CasingRecordWindowSample: FunctionComponent<Props> = ({ onClose, update, onComplete, borehole, defaultValues, selectedDate, holeDepth, setShowCasingRecordEdit, ...rest }) => {
	const [currentStep, setCurrentStep] = useState(1)

	const [guid, setGuid] = useState(defaultValues.guid)
	const [casing_diameter, setSize] = useState(defaultValues.casing_diameter)
	// const [depth_to_top, setDepthFrom] = useState(defaultValues.depth_to_top)
	const depth_to_top = defaultValues.depth_to_top
	const [depth_to_base, setDepthTo] = useState(defaultValues.depth_to_base)
	const [depth_of_borehole, setBHDepth] = useState(defaultValues.depth_of_borehole)
	
	const [saveButtonText, setButtonText] = useState('Continue')
	const [saveButtonColor, setButtonColor] = useState('primary')
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	// const [hole_depth, setHoleDepth] = useState(parseFloat(holeDepth).toFixed(2))
	// const hole_depth= parseFloat(holeDepth).toFixed(2)

	const DeleteButton = () => {
		if (!update) return null;
	
		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	// const deleteRecordConfirm = () => {
	// 	setDeleteConfirmOpen(false)
	// 	// onComplete()
	// }

    // const [time_of_action, setTimeOfAction] = useState(defaultValues.time_of_action)
    const time_of_action = defaultValues.time_of_action

    const isoDate = selectedDate ? `${new Date(selectedDate).toISOString().split('T')[0]} 09:00:00` : new Date().toISOString().split('.')[0]

    const previous = () => {
		if(currentStep > 1)
			setCurrentStep(currentStep - 1)
			setButtonText('Continue')
			setButtonColor('primary')
	}

	const next = () => {
		if(currentStep < 3) {
			setCurrentStep(currentStep + 1)
			if(currentStep === 2) {
				setButtonText('Save') 
				setButtonColor('success')
			}else{
				setButtonText('Continue')
				setButtonColor('primary')
			}
			return
		}

		if (depth_to_base === '' || casing_diameter === '') {
			//do nothing
		} else {

		onComplete({
			guid: update ? guid : uuidv4(),
			// casing_type: 'notonform', // we don't have this on the form - not needed for Windows Sample Casing
			casing_type: '',
			casing_diameter: casing_diameter,
			depth_to_top: '0.00', // depth_to_top2DP, //2DP,
			depth_to_base: depth_to_base2DP, //2DP,
			depth_of_borehole: depth_of_borehole2DP, //2DP,
			general_remarks: '',
			time_of_action: time_of_action ? time_of_action : isoDate,
			update: update ? true : false,
		})
	}
	}

	let requiredCasingText = ''
	let requiredDepthText = ''

	if (!casing_diameter) {
		requiredCasingText = "'Casing Size' - "
	}

	if (!depth_to_base) {
		requiredDepthText = "'Depth To'"
	}

	const MissingRequiredField = () => {
		if (currentStep === 3 && (!depth_to_base || !casing_diameter)) return (
			<div className="text-center bg-sptSB border border-red font-bold rounded mb-2 py-1 text-xs md:text-sm lg:text-base">
				<img src={warningIcon} alt="info" width="20" className="inline-block mr-1 pb-1" />
				Required field: {requiredCasingText} {requiredDepthText}
			</div>
		)
	
		return null
	}
	
	let depth_to_top2DP = ''
	if(!depth_to_top){
		depth_to_top2DP = ''
	}else{
		depth_to_top2DP = parseFloat(depth_to_top).toFixed(2)
	}

	let depth_to_base2DP = ''
	if(!depth_to_base){
		depth_to_base2DP = ''
	}else{
		depth_to_base2DP = parseFloat(depth_to_base).toFixed(2)
	}

	let depth_of_borehole2DP = ''
	if(!depth_of_borehole){
		depth_of_borehole2DP = ''
	}else{
		depth_of_borehole2DP = parseFloat(depth_of_borehole).toFixed(2)
	}

	return (
		<Modal title="Casing Record" subtitle={borehole} toggleModal={onClose}>
			<div className="flex flex-col flex-grow px-1 sm:px-2 md:px-10 text-center">
				<WorkflowSteps steps={[
					{
						label: 'Size',
						status: stepStatus(1, currentStep),
						onClick: () => {
							setCurrentStep(1)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Depth To',
						status: stepStatus(2, currentStep),
						onClick: () => {
							setCurrentStep(2)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'BH Depth',
						status: stepStatus(3, currentStep),
						onClick: () => {
							setCurrentStep(3)
							setButtonText('Save')
							setButtonColor('success')
						}
					},
				]} className="mb-2" />

				<div className="flex-1 flex">
					{currentStep === 1 &&
						<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2 w-full pb-56 md:pb-60">
							<div className="w-full">
								<ButtonOptions onUpdate={(value: any) => setSize(value)} 
								defaultValue={casing_diameter} 
								title="Select Casing Size (mm)" 
								columns={5} 
								columnsMobile={2} 
								buttonHeight={20} 
								buttonHeightMobile={16} 
								next={next}
								options={[
									{value: '88', label: <><div className="pt-3 text-2xl sm:text-xl md:text-3xl">88</div></>},
									{value: '98', label: <><div className="pt-3 text-2xl sm:text-xl md:text-3xl">98</div></>},
									{value: '103', label: <><div className="pt-3 text-2xl sm:text-xl md:text-3xl">103</div></>},
									{value: '113', label: <><div className="pt-3 text-2xl sm:text-xl md:text-3xl">113</div></>},
									{value: '128', label: <><div className="pt-3 text-2xl sm:text-xl md:text-3xl">128</div></>},
								]} className="mb-10" />
							</div>
						</div>
					}

					{/* {currentStep === 2 &&
						<NumberInput onUpdate={(value: any) => setDepthFrom(value)} 
						defaultValue={depth_to_top} 
						title="Enter Casing Depth From (m)" 
						className="mb-10" />
					} */}

					{currentStep === 2 &&
						<NumberInput onUpdate={(value: any) => setDepthTo(value)} 
						defaultValue={depth_to_base} 
						title="Enter Casing Depth To (m)" 
						className="mb-10" />
					}

					{currentStep === 3 &&
						<NumberInput onUpdate={(value: any) => setBHDepth(value)} 
						defaultValue={depth_of_borehole} 
						title="Borehole Depth (m)" 
						className="mb-10" />
					}
				</div>

<MissingRequiredField />
				

				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">
	
	
							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?
	
								<div className="flex mt-4">
									<Button theme="secondary" 
										className="mr-5 text-sm sm:text-base px-3.5 md:px-7" 
										onClick={() => setDeleteConfirmOpen(false)}>
											Cancel
									</Button>

									<Button onClick={() => {
												onClose()
												onComplete({
													guid: guid,
													update: false,
													delete: true,
												})
												setShowCasingRecordEdit(false)
											}}
										className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">
										Delete
									</Button>
								</div>
							</div>
	
	
						</div>
					</div>
				}

				<div className="flex">
					{/* <Button theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button> */}
					<DeleteButton/>

					<div className="flex ml-auto">
						<Button theme="primary" size="lg" className="px-4 sm:px-10" onClick={() => previous()}>Back</Button>
						<Button theme={saveButtonColor} 
							size="lg" 
							className="ml-4 px-8 sm:px-10 w-32 sm:w-60" 
							onClick={() => next()}>{saveButtonText}
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

CasingRecordWindowSample.defaultProps = defaultProps

export default CasingRecordWindowSample
