import React, { FunctionComponent } from 'react'

type Props = {
	children: React.ReactNode,
	className?: string;
	[x: string]: any;
}

const defaultProps: Props = {
	children: [],
	className: '',
}

const Container: FunctionComponent<Props> = ({ children, className, ...rest }) => {
	return (
		<div className={`px-3 md:px-10 text-text  ${className}`} {...rest}>
			{children}
		</div>
	)
}

Container.defaultProps = defaultProps

export default Container