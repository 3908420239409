import React, { FunctionComponent, useState, useEffect, Component } from 'react'

import { useLocation } from "react-router-dom";

import Header from '../layout/Header'

import Modal from '../components/Modal'
import ModalSmall from '../components/ModalSmall'
import WorkflowSteps from '../components/WorkflowSteps'
import NumberInput from '../components/NumberInput'
import Button from '../components/form/Button'
import InputGroup from '../components/form/InputGroup'
import TextArea from '../components/form/TextArea'
import WorkflowActions from '../components/form/WorkflowActions'
import infoIcon from '../assets/icons/info.png'
import warningIcon from '../assets/icons/warning.png'

import { useGetLoggedInUser } from '../helpers/getLoggedInUser'
import { v4 as uuidv4 } from 'uuid';
import { useSetIndexedDBValues } from '../helpers/addToIDB'
import { updatePlantCheckWindowSample } from "../helpers/updatePlantCheckWindowSample";

import Label from '../components/Label'
import { Link } from 'react-router-dom'
import RadioSelect from '../components/form/RadioSelect'

import { useGetIDBValues } from "../helpers/getIDBKey";
import { get, set } from 'idb-keyval'
import { del } from "idb-keyval/dist/cjs";

type Props = {
	onClose: any,
	projectId?: string,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => { },
	// onComplete: () => {},
	projectId: '',
	defaultValues: {
		guid: '',
	},
}

interface State {
	towingEyeHandbrakeJockeyWheelBreakawayCable: number,
	rigTracks: number,
	generalEngineCheck: number,
	fuelEngineOilLevelsFilters: number,
	hydraulicSystem: number,
	rigFrame: number,
	guardFitted: number,
	chalwynValveSparkArrestorFitted: number,
	drillingTools: number,
	fireExtinguisher: number,
	spillKitDrillTrays: number,
	ignitionSystemElectronicSystem: number,
	mainChainFunction: number,
	rigFrameFunction: number,
	engineOperation: number,
	emergencyStop: number,
	leverFunction: number,
	issuesMaintenance: string,

	guid: string,
	company_guid: string,
	project_guid: string,
	project_location: string,
	project_id: string,
	company_id: string,

	plant_id: string,
	plant_guid: string,
	plant_name: string,
	plant_check_date: string,
	plant_check_url: string,
	driller_name: string,
	driller_cscs: string,
}

interface PlantCheck {
	plantCheck: {
		towingEyeHandbrakeJockeyWheelBreakawayCable: number,
		rigTracks: number,
		generalEngineCheck: number,
		fuelEngineOilLevelsFilters: number,
		hydraulicSystem: number,
		rigFrame: number,
		guardFitted: number,
		chalwynValveSparkArrestorFitted: number,
		drillingTools: number,
		fireExtinguisher: number,
		spillKitDrillTrays: number,
		ignitionSystemElectronicSystem: number,
		mainChainFunction: number,
		rigFrameFunction: number,
		engineOperation: number,
		emergencyStop: number,
		leverFunction: number,
		issuesMaintenance: string,

		guid: string,
		company_guid: string,
		project_guid: string,
		project_location: string,
		project_id: string,
		company_id: string,

		plant_id: string,
		plant_guid: string,
		plant_name: string,
		plant_check_date: string,
		plant_check_url: string,
		driller_name: string,
		driller_cscs: string,
	}[];
}



const PlantCheck: FunctionComponent<Props> = ({ onClose, update, companyGuid, defaultValues,
	project_guid, testDate, currentRigTestExpiryDate, plantType, plantID, plantName, companyID, plantGUID, plantChecks,
	currentRigSPTRodType, currentRigSPTIDRef, currentRigSPTEnergyRatio, currentRigSPTCalibrationDate, rigInformation,
	...rest }) => {


	console.log('defaultValues:', defaultValues)

	const [driller_name, setLeadDriller] = useState(defaultValues.driller_name)
	const loggedInUser = useGetLoggedInUser();
	const [loggedInDriller, setLoggedInDriller] = useState('')
	const [loggedInDrillerCSCS, setLoggedInDrillerCSCS] = useState('')
	useEffect(() => {
		if (loggedInUser && loggedInUser.user) {
			setLoggedInDriller(`${loggedInUser.user.first_name} ${loggedInUser.user.last_name}`);
			setLoggedInDrillerCSCS(`${(loggedInUser.user as any).cscs_no}`);
		}
	}, [loggedInUser]);
	console.log('update:', update)
	const [guid, setGuid] = useState(defaultValues.guid)
	const [project_id, setProject_id] = useState(defaultValues.project_id)
	const [plant_guid, setPlant_guid] = useState(defaultValues.plant_guid)
	// const [project_guid, setProject_guid] = useState(defaultValues.project_guid)
	const [projectGUID, setProjectGUID] = useState(project_guid)
	const [project_name, setProject_name] = useState(defaultValues.project_name)
	const [project_location, setProject_location] = useState(defaultValues.project_location)
	const [plant_id, setPlant_id] = useState(defaultValues.plant_id)
	const [plant_name, setPlant_name] = useState(defaultValues.plant_name)
	const [plant_check_date, setPlant_check_date] = useState(defaultValues.plant_check_date)
	const [spt_rod_type, set_spt_rod_type] = useState(defaultValues.spt_rod_type)
	const [spt_id_ref, set_spt_id_ref] = useState(defaultValues.spt_id_ref)
	const [spt_energy_ratio, set_spt_energy_ratio] = useState(defaultValues.spt_energy_ratio)
	const [spt_calibration_date, set_spt_calibration_date] = useState(defaultValues.spt_calibration_date)
	// const company_id = 1
	const [company_id, setCompany_id] = useState(defaultValues.company_id)
	const [company_guid, setCompany_guid] = useState(companyGuid)
	const [current_rig_test_expiry_date, set_current_rig_test_expiry_date] = useState(defaultValues.plant_test_date_expiry)
	const [plant_type, set_plant_type] = useState(plantType)


	const [towingEyeHandbrakeJockeyWheelBreakawayCable, set_towingEyeHandbrakeJockeyWheelBreakawayCable] = useState(defaultValues.towingEyeHandbrakeJockeyWheelBreakawayCable === 1 ? true : false);
	const [rigTracks, set_rigTracks] = useState(defaultValues.rigTracks === 1 ? true : false);
	const [generalEngineCheck, set_generalEngineCheck] = useState(defaultValues.generalEngineCheck === 1 ? true : false);
	const [fuelEngineOilLevelsFilters, set_fuelEngineOilLevelsFilters] = useState(defaultValues.fuelEngineOilLevelsFilters === 1 ? true : false);
	const [hydraulicSystem, set_hydraulicSystem] = useState(defaultValues.hydraulicSystem === 1 ? true : false);
	const [rigFrame, set_rigFrame] = useState(defaultValues.rigFrame === 1 ? true : false);
	const [guardFitted, set_guardFitted] = useState(defaultValues.guardFitted === 1 ? true : false);
	const [chalwynValveSparkArrestorFitted, set_chalwynValveSparkArrestorFitted] = useState(defaultValues.chalwynValveSparkArrestorFitted === 1 ? true : false);
	const [drillingTools, set_drillingTools] = useState(defaultValues.drillingTools === 1 ? true : false);
	const [fireExtinguisher, set_fireExtinguisher] = useState(defaultValues.fireExtinguisher === 1 ? true : false);
	const [spillKitDrillTrays, set_spillKitDrillTrays] = useState(defaultValues.spillKitDrillTrays === 1 ? true : false);
	const [ignitionSystemElectronicSystem, set_ignitionSystemElectronicSystem] = useState(defaultValues.ignitionSystemElectronicSystem === 1 ? true : false);
	const [mainChainFunction, set_mainChainFunction] = useState(defaultValues.mainChainFunction === 1 ? true : false);
	const [rigFrameFunction, set_rigFrameFunction] = useState(defaultValues.rigFrameFunction === 1 ? true : false);
	const [engineOperation, set_engineOperation] = useState(defaultValues.engineOperation === 1 ? true : false);
	const [emergencyStop, set_emergencyStop] = useState(defaultValues.emergencyStop === 1 ? true : false);
	const [leverFunction, set_leverFunction] = useState(defaultValues.leverFunction === 1 ? true : false);
	const [issuesMaintenance, set_issuesMaintenance] = useState(defaultValues.issuesMaintenance);

	const [sheetDate, set_sheetDate] = useState('');
	const [sheetUpdate, set_sheetUpdate] = useState('');

	useEffect(() => {
		if (update) {
			set_sheetDate(plant_check_date)
			set_sheetUpdate('(update)')
		} else {
			set_sheetDate(testDate)
			set_sheetUpdate('(new)')
		}
	}, [update, plant_check_date, testDate]);

	useEffect(() => {
		console.log('currentRigTestExpiryDate:', currentRigTestExpiryDate)
		console.log('testDate:', testDate)
	}, [currentRigTestExpiryDate, testDate]);


	// // GET EXISTING PLANT CHECKS FOR SELECTED RIG

	// const [currentRigPlantChecks, setCurrentRigPlantChecks] = useState<PlantCheck[]>([]);

	// useEffect(() => {
	// 	if(rigInformation.guid){
	// 		let cachedValues = useGetIDBValues;
	// 		const rigPlantChecks = 'plant-check-window-sample/' + rigInformation.guid;

	// 		cachedValues(rigPlantChecks, (data: any) => {
	// 			// console.log('rigPlantChecks:', data)

	// 			if (data) {
	// 				setCurrentRigPlantChecks(data)
	// 			}

	// 		});
	// 	}
	// }, [rigInformation.guid]);

	/////////////////////////////////////////
	// SAVE NEW LOG INFO
	/////////////////////////////////////////   

	// PlantID string
	let logPlantIDString = plantID.replace(/\s/g, '');
	// Date string
	// Split the date string into day, month, and year
	let parts = testDate.split('-');
	let year = parseInt(parts[0]);
	let month = parseInt(parts[1]);
	let day = parseInt(parts[2]);

	// Create a new Date object with the parts
	let dateObject = new Date(year, month - 1, day);

	// Get the year, month, and day in YYYY, MM, and DD format respectively
	let YYYY = dateObject.getFullYear();
	let MM = String(dateObject.getMonth() + 1).padStart(2, '0'); // Adding 1 because month is zero-based
	let DD = String(dateObject.getDate()).padStart(2, '0');

	// Concatenate them in YYYYMMDD format
	let logDateString = `${YYYY}${MM}${DD}`;

	let plantcheck_url = logPlantIDString + '-' + logDateString + '.pdf'




	const newPlantCheck = {
		towingEyeHandbrakeJockeyWheelBreakawayCable: towingEyeHandbrakeJockeyWheelBreakawayCable ? 1 : 0,
		rigTracks: rigTracks ? 1 : 0,
		generalEngineCheck: generalEngineCheck ? 1 : 0,
		fuelEngineOilLevelsFilters: fuelEngineOilLevelsFilters ? 1 : 0,
		hydraulicSystem: hydraulicSystem ? 1 : 0,
		rigFrame: rigFrame ? 1 : 0,
		guardFitted: guardFitted ? 1 : 0,
		chalwynValveSparkArrestorFitted: chalwynValveSparkArrestorFitted ? 1 : 0,
		drillingTools: drillingTools ? 1 : 0,
		fireExtinguisher: fireExtinguisher ? 1 : 0,
		spillKitDrillTrays: spillKitDrillTrays ? 1 : 0,
		ignitionSystemElectronicSystem: ignitionSystemElectronicSystem ? 1 : 0,
		mainChainFunction: mainChainFunction ? 1 : 0,
		rigFrameFunction: rigFrameFunction ? 1 : 0,
		engineOperation: engineOperation ? 1 : 0,
		emergencyStop: emergencyStop ? 1 : 0,
		leverFunction: leverFunction ? 1 : 0,
		issuesMaintenance: issuesMaintenance || '',

		company_guid: company_guid || '',
		company_id: update ? company_id : companyID,
		project_guid: projectGUID,
		driller_name: update ? driller_name : loggedInDriller,
		driller_cscs: update ? loggedInDrillerCSCS : loggedInDrillerCSCS,
		guid: update ? guid : uuidv4(),
		plant_guid: update ? plant_guid : plantGUID,
		plant_id: update ? plant_id : plantID,
		plant_name: update ? plant_name : plantName,
		spt_rod_type: update ? spt_rod_type : currentRigSPTRodType,
		spt_id_ref: update ? spt_id_ref : currentRigSPTIDRef,
		spt_energy_ratio: update ? parseFloat(spt_energy_ratio).toString() : parseFloat(currentRigSPTEnergyRatio).toString(),
		spt_calibration_date: update ? spt_calibration_date : currentRigSPTCalibrationDate,
		plant_test_date_expiry: update ? current_rig_test_expiry_date : currentRigTestExpiryDate,
		plant_check_date: update ? plant_check_date : testDate,
		plantcheck_url: plantcheck_url,
	}

	const newPlantCheckToSave = [newPlantCheck]

	console.log('plantChecks in PlantCheck:', plantChecks)
	console.log('newPlantCheck in PlantCheck:', newPlantCheck)
	console.log('newPlantCheckToSave in PlantCheck:', newPlantCheckToSave)



	// const mergedPlantChecks = {
	// 	...currentRigPlantChecks,
	// 	...newPlantCheckToSave,
	// };

	const [mergedRigPlantChecks, setMergedRigPlantChecks] = useState<PlantCheck[]>([]);

	const mergeAllPlantChecks = () => {
		const mergedPlantChecks = [...plantChecks, newPlantCheck];
		setMergedRigPlantChecks(mergedPlantChecks);
	};

	console.log('mergedRigPlantChecks:', mergedRigPlantChecks);

	const [mergedRigPlantChecksUpdated, setMergedRigPlantChecksUpdated] = useState<PlantCheck[]>([]);

	const updatePlantChecks = () => {
		if (!newPlantCheck) return;

		const updatedPlantChecks = plantChecks.map((check: any) =>
			check.guid === newPlantCheck.guid ? newPlantCheck : check
		);

		setMergedRigPlantChecksUpdated(updatedPlantChecks);
	};

	console.log('mergedRigPlantChecksUpdated:', mergedRigPlantChecksUpdated);





	const save = () => {

		console.log('newPlantCheckToSave:', newPlantCheckToSave)

		if (!update) {
			let saveToIDB = useSetIndexedDBValues;

			console.log('newPlantCheck:', newPlantCheck);

			const endPoint = 'plant-check-window-sample';

			console.log('endPoint:', endPoint);

			// Save the new plant check to IndexedDB
			saveToIDB(newPlantCheck, endPoint, true).then(async () => {
				console.log('Plant check saving to IndexedDB');

				// mergeAllPlantChecks()
				const mergedPlantChecks = [...plantChecks, newPlantCheck];
				setMergedRigPlantChecks(mergedPlantChecks);

				// Ensure the structure is maintained
				const updatedData = {
					plantCheck: mergedPlantChecks
				};

				console.log('updatedData on save:', updatedData)

				try {
					// Update the currentRigPlantChecks state in IndexedDB
					// await set('drillit-plant-check-window-sample/' + rigInformation.guid, mergedRigPlantChecks);
					await set('drillit-plant-check-window-sample/project/' + project_guid, updatedData);
					console.log('Updating drillit-plant-check-window-sample/project');
				} catch (error) {
					console.error('Error updating current rig plant checks:', error);
				}
			});

			setTimeout(() => onClose(), 2000);

		} else {
			console.log('UPDATING PLANT CHECK!!!')

			updatePlantChecks()

			updatePlantCheckWindowSample(
				newPlantCheck.towingEyeHandbrakeJockeyWheelBreakawayCable,
				newPlantCheck.rigTracks,
				newPlantCheck.generalEngineCheck,
				newPlantCheck.fuelEngineOilLevelsFilters,
				newPlantCheck.hydraulicSystem,
				newPlantCheck.rigFrame,
				newPlantCheck.guardFitted,
				newPlantCheck.chalwynValveSparkArrestorFitted,
				newPlantCheck.drillingTools,
				newPlantCheck.fireExtinguisher,
				newPlantCheck.spillKitDrillTrays,
				newPlantCheck.ignitionSystemElectronicSystem,
				newPlantCheck.mainChainFunction,
				newPlantCheck.rigFrameFunction,
				newPlantCheck.engineOperation,
				newPlantCheck.emergencyStop,
				newPlantCheck.leverFunction,
				newPlantCheck.issuesMaintenance,
				newPlantCheck.company_guid,
				newPlantCheck.company_id,
				newPlantCheck.driller_name,
				newPlantCheck.driller_cscs,
				newPlantCheck.guid,
				newPlantCheck.plant_guid,
				newPlantCheck.plant_id,
				newPlantCheck.plant_name,
				newPlantCheck.spt_rod_type,
				newPlantCheck.spt_rod_type,
				newPlantCheck.spt_energy_ratio,
				newPlantCheck.spt_calibration_date,
				newPlantCheck.plant_test_date_expiry,
				newPlantCheck.plant_check_date,
				newPlantCheck.plantcheck_url,
				newPlantCheck.project_guid,
				mergedRigPlantChecksUpdated
			)
				.then(() => setTimeout(() => onClose(), 3000));
		}
	}


	const handleCloseModal = () => {
		console.log('handleCloseModal triggered')
		onClose(); // Call the onClose function passed as prop
	};


	function checkAll() {
		set_towingEyeHandbrakeJockeyWheelBreakawayCable(true)
		set_rigTracks(true)
		set_generalEngineCheck(true)
		set_fuelEngineOilLevelsFilters(true)
		set_hydraulicSystem(true)
		set_rigFrame(true)
		set_guardFitted(true)
		set_chalwynValveSparkArrestorFitted(true)
		set_drillingTools(true)
		set_fireExtinguisher(true)
		set_spillKitDrillTrays(true)
		set_ignitionSystemElectronicSystem(true)
		set_mainChainFunction(true)
		set_rigFrameFunction(true)
		set_engineOperation(true)
		set_emergencyStop(true)
		set_leverFunction(true)
	}

	function uncheckAll() {
		set_towingEyeHandbrakeJockeyWheelBreakawayCable(false)
		set_rigTracks(false)
		set_generalEngineCheck(false)
		set_fuelEngineOilLevelsFilters(false)
		set_hydraulicSystem(false)
		set_rigFrame(false)
		set_guardFitted(false)
		set_chalwynValveSparkArrestorFitted(false)
		set_drillingTools(false)
		set_fireExtinguisher(false)
		set_spillKitDrillTrays(false)
		set_ignitionSystemElectronicSystem(false)
		set_mainChainFunction(false)
		set_rigFrameFunction(false)
		set_engineOperation(false)
		set_emergencyStop(false)
		set_leverFunction(false)
	}


	// RIG INFORMATION

	const [showRigInformation, setShowRigInformation] = useState(false)
	const [information_check, setInformation_check] = useState(false)
	// console.log('rigInformation:', rigInformation)
	const [rigName, setRigName] = useState(rigInformation.name)
	const [rigType, setRigType] = useState(rigInformation.type)
	const [rigTestExpiry, setRigTestExpiry] = useState(rigInformation.test_date_expiry)
	const [rigSPT, setRigSPT] = useState(rigInformation.spt.id_ref)
	const [rigSPTEnergy, setRigSPTEnergy] = useState(rigInformation.spt.energy_ratio)
	const [rigSPTCalibrationDate, setRigSPTCalibrationDate] = useState(rigInformation.spt.calibration_date)

	// console.log(sheetDate, typeof sheetDate, rigTestExpiry, typeof rigTestExpiry, rigSPTCalibrationDate, typeof rigSPTCalibrationDate)

	const sheetDateObj = new Date(sheetDate);
	const rigTestExpiryObj = new Date(rigTestExpiry);
	const rigSPTCalibrationDateObj = new Date(rigSPTCalibrationDate);
	const currentDate = new Date();

	// Calculate the difference in milliseconds between the rig test expiry date and the current date
	const timeDiffRig = rigTestExpiryObj.getTime() - currentDate.getTime();
	// Convert milliseconds to days
	const daysDiffRig = Math.ceil(timeDiffRig / (1000 * 3600 * 24));

	// Calculate the difference in milliseconds between the rig test expiry date and the current date
	const timeDiffSPT = currentDate.getTime() - rigSPTCalibrationDateObj.getTime();
	// Convert milliseconds to days
	const daysDiffSPT = Math.ceil(timeDiffSPT / (1000 * 3600 * 24));

	const [rigTestExpiryText, setRigTestExpiryText] = useState('The Rig Test has expired!!!')
	const [rigSPTCalibrationDateText, setRigSPTCalibrationDateText] = useState('The SPT Calibration has expired!!!')

	const [showRigTypeConflict, setShowRigTypeConflict] = useState(false)
	const [showRigTypeConflictText, setShowRigTypeConflictText] = useState('')

	useEffect(() => {

		if (rigType !== 'Window Sample') {
			setShowRigTypeConflict(true)
			setShowRigTypeConflictText('Incorect type of rig for plant check!!!')
		} else {
			setShowRigTypeConflict(false)
			setShowRigTypeConflictText('')
		}

	}, [rigType]);

	const [showTestExpiryInfo, setShowTestExpiryInfo] = useState(true)
	const [showSPTExpiryInfo, setShowSPTExpiryInfo] = useState(true)

	useEffect(() => {

		// Compare the timestamps of the Date objects
		if (sheetDateObj.getTime() < rigTestExpiryObj.getTime()) {
			if (daysDiffRig <= 10 && daysDiffRig > 0) {
				setRigTestExpiryText(`The Rig Test will expire in ${daysDiffRig} days!!!`);
				setShowTestExpiryInfo(true)
			} else if (daysDiffRig === 0) {
				setRigTestExpiryText(`The Rig Test expires today!`);
				setShowTestExpiryInfo(true)
			} else if (daysDiffRig < 0) {
				setRigTestExpiryText(`The Rig Test expired on ${rigTestExpiry}`);
				setShowTestExpiryInfo(true)
			} else {
				setRigTestExpiryText(`The Rig Test is still valid`);
				setShowTestExpiryInfo(false)
			}
			// console.log("Sheet date is before rig test expiry date");
		} else if (sheetDateObj.getTime() > rigTestExpiryObj.getTime()) {
			setRigTestExpiryText('The Rig Test has expired!!!')
			setShowTestExpiryInfo(true)
			// console.log("Sheet date is after rig test expiry date");
		} else {
			// console.log("Sheet date is equal to rig test expiry date");
		}

	}, [sheetDate, rigTestExpiry]);

	useEffect(() => {

		// Compare the timestamps of the Date objects
		if (rigSPTCalibrationDateObj.getTime() < sheetDateObj.getTime()) {
			if (daysDiffSPT <= 10 && daysDiffSPT > 0) {
				setRigSPTCalibrationDateText(`The SPT calibration will expire in ${daysDiffSPT} days!!!`);
				setShowSPTExpiryInfo(true)
			} else if (daysDiffSPT === 0) {
				setRigSPTCalibrationDateText(`The SPT calibration expires today!`);
				setShowSPTExpiryInfo(true)
			} else if (daysDiffSPT < 0) {
				setRigSPTCalibrationDateText(`The SPT calibration was ${rigSPTCalibrationDate}`);
				setShowSPTExpiryInfo(true)
			} else {
				setRigSPTCalibrationDateText(`The SPT calibration is still valid`);
				setShowSPTExpiryInfo(false)
			}
			// console.log("Sheet date is before rig test expiry date");
		} else if (sheetDateObj.getTime() > rigTestExpiryObj.getTime()) {
			setRigSPTCalibrationDateText('The SPT calibration has expired!!!')
			setShowSPTExpiryInfo(true)
			// console.log("Sheet date is after rig test expiry date");
		} else {
			// console.log("Sheet date is equal to rig test expiry date");
		}

	}, [sheetDate, rigTestExpiry]);



	useEffect(() => {

		if (showTestExpiryInfo === true || showSPTExpiryInfo === true) {
			setInformation_check(false)
		} else {
			setInformation_check(true)
		}

	}, [showTestExpiryInfo, showSPTExpiryInfo]);



	let infoWarningIcon = ''

	if (information_check === false) {
		infoWarningIcon = warningIcon
	} else {
		infoWarningIcon = infoIcon
	}

	console.log('newPlantCheck:', newPlantCheck)

	return (

		<>

			{/* <Modal title={`Daily Plant Check (${plant_check_date})`}  toggleModal={onClose}> */}
			<Modal title={`Daily Window Sample Plant Check`} toggleModal={onClose} confirm={true}>



				{showRigInformation &&
					<ModalSmall title="Rig Information" toggleModal={() => { setShowRigInformation(false) }} confirm={false}>

						<table className="table-auto w-5/6 text-sm text-center bg-white border border-grey-mid mt-4 mx-auto text-xl">
							<tbody>
								<tr >
									<td className="px-4 py-2 border-r border-b border-grey-mid text-center">
										Conducting Plant Check: <span className="font-bold">{loggedInDriller}</span>
									</td>
								</tr>
								<tr >
									<td className="px-4 py-2 border-r border-b border-grey-mid text-center">
										Date of Plant Check: <span className="font-bold">{sheetDate}</span>
									</td>
								</tr>
								<tr >
									<td className="px-4 py-2 border-r border-b border-grey-mid text-center">
										Rig: <span className="font-bold">{rigName}</span>
										{showRigTypeConflict &&
											<div className="text-base text-red font-bold">
												<img src={warningIcon} width="15" className="mr-1 inline-block pb-0.5" alt="" />
												This Rig is a {rigType} rig!!! Please select correct rig!
											</div>
										}
									</td>
								</tr>
								<tr >
									<td className="px-4 py-2 border-r border-b border-grey-mid text-center">
										Rig Test Expiry: <span className="font-bold">{rigTestExpiry}</span>
										{showTestExpiryInfo &&
											<div className="text-base text-red font-bold">
												<img src={warningIcon} width="15" className="mr-1 inline-block pb-0.5" alt="" />
												{rigTestExpiryText}
											</div>
										}
									</td>
								</tr>
								<tr >
									<td className="px-4 py-2 border-r border-b border-grey-mid text-center">
										SPT Hammer: <span className="font-bold">{rigSPT}</span>
									</td>
								</tr>
								<tr >
									<td className="px-4 py-2 border-r border-b border-grey-mid text-center">
										SPT Hammer Energy Ratio: <span className="font-bold">{rigSPTEnergy}</span>
									</td>
								</tr>
								<tr >
									<td className="px-4 py-2 border-r border-b border-grey-mid text-center">
										SPT Calibration Date: <span className="font-bold">{rigSPTCalibrationDate}</span>
										{showSPTExpiryInfo &&
											<div className="text-base text-red font-bold">
												<img src={warningIcon} width="15" className="mr-1 inline-block pb-0.5" alt="" />
												{rigSPTCalibrationDateText}
											</div>
										}
									</td>
								</tr>
							</tbody>
						</table>

						<div className="items-center mt-6 w-full">
							<Button theme="primary" size="lg" className="mb-8 mx-auto" onClick={() => { setShowRigInformation(false) }}>OK</Button>
						</div>

					</ModalSmall>
				}

				<div onClick={() => { setShowRigInformation(true) }} className="text-center text-lg md:text-2xl font-bold -mt-2 mb-2">
					{sheetDate} {sheetUpdate} <img src={infoWarningIcon} width="25" className="ml-1 inline-block pb-0.5" alt="" />
				</div>

				<form onSubmit={(e) => {
					e.preventDefault(); // Prevent the default form submission
					save();
				}}>
					{/* <Heading left={['Phase 1: Pre-Start Checks']} className="pt-0" /> */}

					<div className="border border-grey-mid rounded px-2 pb-1 bg-sptTB mb-2">

						<div className="text-lg py-2 text-center">Phase 1: Pre-Start Checks</div>

						<div className="grid md:grid-cols-2 bg-white border border-grey-mid rounded-sm text-sm md:text-base">



							<div className="flex border-b border-r-none md:border-r border-grey-mid">
								<div className="py-1 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Towing eye, handbrake, jockey wheel &amp; break away cable:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={towingEyeHandbrakeJockeyWheelBreakawayCable} onChange={(value: any) => set_towingEyeHandbrakeJockeyWheelBreakawayCable(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-grey-mid">
								<div className="py-1 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Rig tracks:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={rigTracks} onChange={(value: any) => set_rigTracks(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r-none md:border-r border-grey-mid">
								<div className="py-1 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									General engine check:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={generalEngineCheck} onChange={(value: any) => set_generalEngineCheck(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-grey-mid">
								<div className="py-1 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Fuel &amp; engine oil levels &amp; filters:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={fuelEngineOilLevelsFilters} onChange={(value: any) => set_fuelEngineOilLevelsFilters(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r-none md:border-r border-grey-mid">
								<div className="py-1 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Hydraulic system:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={hydraulicSystem} onChange={(value: any) => set_hydraulicSystem(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-grey-mid">
								<div className="py-1 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Rig frame including stay bars, pins &amp; cross brace:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={rigFrame} onChange={(value: any) => set_rigFrame(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r-none md:border-r border-grey-mid">
								<div className="py-1 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Guard fitted and complete:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={guardFitted} onChange={(value: any) => set_guardFitted(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-grey-mid">
								<div className="py-1 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Chalwyn valve &amp; spark arrestor fitted:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={chalwynValveSparkArrestorFitted} onChange={(value: any) => set_chalwynValveSparkArrestorFitted(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r-none md:border-r border-grey-mid">
								<div className="py-1 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Drilling tools - OK to use?:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={drillingTools} onChange={(value: any) => set_drillingTools(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-grey-mid">
								<div className="py-1 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Fire extinguisher:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={fireExtinguisher} onChange={(value: any) => set_fireExtinguisher(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b-none border-r-none md:border-r border-grey-mid">
								<div className="py-1 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Spill kit &amp; drill trays:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={spillKitDrillTrays} onChange={(value: any) => set_spillKitDrillTrays(value.target.checked)} />
								</div>
							</div>

						</div>
					</div>

					{/* <Heading left={['Phase 2: Run/Post Erection Checks']} /> */}

					<div className="border border-grey-mid rounded px-2 pb-1 bg-sptTB mb-2">

						<div className="text-lg py-2 text-center">Phase 2: Run/Post Erection Checks</div>

						<div className="grid md:grid-cols-2 bg-white border border-grey-mid rounded-sm text-sm md:text-base">


							<div className="flex border-b border-r-none md:border-r border-grey-mid">
								<div className="py-1 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Iginition system &amp; electronic system:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={ignitionSystemElectronicSystem} onChange={(value: any) => set_ignitionSystemElectronicSystem(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-grey-mid">
								<div className="py-2 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Main chain function:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={mainChainFunction} onChange={(value: any) => set_mainChainFunction(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r-none md:border-r border-grey-mid">
								<div className="py-1 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Rig frame function:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={rigFrameFunction} onChange={(value: any) => set_rigFrameFunction(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-grey-mid">
								<div className="py-1 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Engine operation:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={engineOperation} onChange={(value: any) => set_engineOperation(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b md:border-b-none border-r-none md:border-r border-grey-mid">
								<div className="py-1 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Emergency stop:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={emergencyStop} onChange={(value: any) => set_emergencyStop(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b-none border-grey-mid">
								<div className="py-1 pl-3 flex-1 border-r border-grey-mid text-right pr-2">
									Lever function:
								</div>

								<div className="plantCheck text-center">
									<input type="checkbox" className="form-control-checkbox" checked={leverFunction} onChange={(value: any) => set_leverFunction(value.target.checked)} />
								</div>
							</div>

						</div>
					</div>

					<div className="border border-grey-mid rounded px-2 py-2 bg-sptTB mb-4">
						<div className="flex items-center mt-4 mb-5">
							<div className="">
								Issues/Maintenance:
							</div>

							<div className="flex-1 ml-4">
								<input type="text" className="form-control w-full" value={issuesMaintenance} onChange={(value) => set_issuesMaintenance(value.target.value)} />
							</div>
						</div>
					</div>

					{/* <div className="grid grid-cols-2 items-center"> */}
					<div className="w-full flex items-center justify-content-center mb-4">
						<Button type="button" theme="primary" onClick={() => checkAll()} className="mr-2 text-sm px-2 h-12 md:h-10 ml-auto" >Check All</Button>
						<Button type="button" theme="primary" onClick={() => uncheckAll()} className="text-sm px-2 h-12 md:h-10 mr-auto" >Uncheck All</Button>
					</div>

					<div className="w-full">
						{showRigTypeConflictText !== '' && (
							<div className="text-red font-bold w-60 md:w-96 text-base ml-auto mr-0 text-center">{showRigTypeConflictText}</div>
						)}
						{!showRigTypeConflict &&
							<Button type="submit" theme="success" className="px-10 h-12 md:h-16 w-60 md:w-96 text-lg font-bold ml-auto -mt-0 md:-mt-8">Save</Button>
						}
					</div>
					{/* </div> */}

				</form>

			</Modal>
		</>

	)
}

PlantCheck.defaultProps = defaultProps

export default PlantCheck