import React, { FunctionComponent } from 'react';

type Props = {
	type?: "button" | "submit" | "reset",
	theme?: string,
	size?: string,
	icon?: string,
	iconPosition?: string,
	className?: string,
	children: React.ReactNode;
	[x: string]: any;
}

const defaultProps: Props = {
	type: 'button',
	theme: 'primary',
	size: 'base',
	icon: '',
	iconPosition: 'left',
	className: '',
	children: []
}

const Button: FunctionComponent<Props> = ({ type, theme, size, icon, iconPosition, className, children, ...rest }) => {
	let iconStyles = ''
	let themeStyles = ''
	let sizeStyles = ''
	let iconPositionStyles = ''

	switch(iconPosition) {
		case "left":
			iconStyles = 'pl-14'
			iconPositionStyles = 'left-0'
			break
		case "right":
			iconStyles = 'pr-14'
			iconPositionStyles = 'right-0'
			break
	}

	switch(theme) {
		case "success":
			themeStyles = 'btn-success'
			break
		case "danger":
			themeStyles = 'btn-danger'
			break
		case "primary":
			themeStyles = 'btn-primary'
			break
		case "secondary":
			themeStyles = 'btn-secondary'
			break
		case "orange":
			themeStyles = 'btn-orange'
			break
		case "save":
			themeStyles = 'btn-save'
			break
		case "outline":
			themeStyles = 'btn-outline'
			break
	}

	switch(size) {
		case "xs":
			sizeStyles = 'btn-xs'
			break
		case "sm":
			sizeStyles = 'btn-sm'
			break
		case "md":
			sizeStyles = 'btn-md'
			break
		case "lg":
			sizeStyles = 'btn-lg'
			break
		case "xl":
			sizeStyles = 'btn-xl'
			break
	}

	const Icon = (): JSX.Element => {
		return <div className={`absolute ${iconPositionStyles} top-0 bottom-0 bg-black bg-opacity-10 px-3 flex items-center`}><img src={icon} width="16" alt="" /></div>
	}

	return (
		<button type={type} className={`btn ${themeStyles} ${sizeStyles} focus:outline-none flex items-center relative justify-center ${icon ? iconStyles : null} ${className}`} {...rest}>
			{(iconPosition === 'left' && icon !== '') ? <Icon /> : null}

			{children}

			{(iconPosition === 'right' && icon !== '') ? <Icon /> : null}
		</button>
	)
}

Button.defaultProps = defaultProps

export default Button