import React from 'react'
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom"

import routes from './routes'
//import Sidebar from './layout/Sidebar'
import Sidebar from './layout/SidebarGeneral'
//import Sidebar from './layout/SidebarCablePercussive'
// import { get, set } from "idb-keyval";

class App extends React.Component<{}, { sidebarVisible: boolean, token: any, thisLocation: string, onLogin: boolean }> {
	constructor(props: any) {
		super(props)


		this.state = {
			sidebarVisible: false,
			token: this.getLocalToken(),
			thisLocation: this.getCurrentRoute(),
			onLogin: this.currentLoginRoute(),
			// companyName: ''
		}
	}

	componentDidMount() {
		// get('drillit-user')
		// 	.then(response => this.getIDBToken(response))
		// 	.catch(err => {
		// 		console.log(err)
		// 	})
		// this.getCacheValuesCompany()
	}


    // getCacheValuesCompany = () => {
    //     // get the user from cache
    //     const getIDB: any = async () => {
    //         const response = await get('drillit-user')
    //         this.setState({
    //             companyName: response.user.company.name,
    //         })
    //     }
    //     getIDB()

    // }

	getIDBToken = (tokenData: any): void => {
		const userToken = tokenData
		this.setState({token: userToken.user.token})
	}

	getLocalToken = (): string => {
		const token = localStorage.getItem('drillit-token')
		if (token) return token
		return ''
	}

	toggleSidebar = () => {
		this.setState({
			sidebarVisible: !this.state.sidebarVisible
		})
	}

	globalProps: object = {
		toggleSidebar: this.toggleSidebar
	}

	getCurrentRoute = (): string => {
		return window.location.pathname
	}

	currentLoginRoute = (): boolean => {
		return this.getCurrentRoute() === '/login';
	}


	render() {

		// console.log('companyName App: ', this.state.companyName)

		return (
			<Router>
				{this.state.sidebarVisible && 
					<Sidebar toggleSidebar={this.toggleSidebar} 
					// companyName={this.state.companyName}
					></Sidebar>}
				{(!this.state.token && !this.state.onLogin) ? <Redirect to="/login" /> : ''}
				{(this.state.token) && (this.state.thisLocation === '/login') ? <Redirect to="/" /> : ''}

				<Switch>
					{routes(this.globalProps).map((route, index) => {
						return (
							<Route exact path={route.path} render={(props) => React.cloneElement(route.view, { router: props })} key={index} />
						)
					})}
				</Switch>
			</Router>
		)
	}
}

export default App
