import React, { useState, useRef, useEffect } from 'react'

import Barcode from 'react-jsbarcode'
import Button from './form/Button'
// import NumberIncrement from './form/NumberIncrement'
import TopImage from '../assets/labelTop.jpg'
import NumberIncrement from '../components/form/NumberIncrement'

import ReactToPrint from 'react-to-print'
import { useSelector } from 'react-redux'
import { useGetLoggedInUser } from '../helpers/getLoggedInUser'
import { get, set } from "idb-keyval";
import PrinterSetup from '../views/PrinterSetup'
import systemIcon from '../assets/icons/system-grey.svg'


interface CustomCheckboxProps {
    checked: boolean;
    onChange: () => void;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ checked, onChange }) => {
    return (
        <div 
            className={`custom-checkbox ${checked ? 'checked' : ''} rounded w-6 h-6 border-2 border-blue`} 
            onClick={onChange}
            style={{
                display: 'inline-block',
                cursor: 'pointer',
                backgroundColor: checked ? '#2185D0' : '#fff',
                marginTop: checked ? '0px' : '0px',
                paddingTop: '0px',
                paddingBottom: '3px',
            }}
        >
            {checked && <span style={{ color: '#fff' }}>✔</span>}
        </div>
    );
};

function Label(props: any) {

    const [operatingSystem, setOperatingSystem] = useState('');
    const [device, setDevice] = useState('');
    const [userAgent, setUserAgent] = useState('');

    useEffect(() => {
        const userAgent = navigator.userAgent;
        const platform = navigator.platform;
    
        // Detect Operating System
        let os = 'Unknown OS';
        if (/Win/i.test(platform)) os = 'Windows';
        else if (/Mac/i.test(platform)) os = 'MacOS';
        else if (/Linux/i.test(platform)) os = 'Linux';
        else if (/Android/i.test(userAgent)) os = 'Android';
        else if (/iPhone|iPad|iPod/i.test(userAgent)) os = 'iOS';
    
        // Detect Device Type
        let device = 'Desktop';
        if (/Mobi/i.test(userAgent)) device = 'Mobile';
        else if (/Tablet|iPad/i.test(userAgent)) device = 'Tablet';
    
        setOperatingSystem(os);
        setDevice(device);
        setUserAgent(userAgent);
      }, []);

    const selectedProject = useSelector((state: any) => state.selectedProject)

    const loggedInUser = useGetLoggedInUser()

    const date = useSelector((state: any) => state.date)
    var sampleDate = date.toLocaleDateString("en-UK")

    let projectLocation = selectedProject.location
    let projectID = selectedProject.number
    let boreholeName = props.borehole
    let sampleID = props.sample
    let sampleDepth = props.depth
    let sampler = props.sampled_by

    projectID = projectID.toUpperCase();
    boreholeName = boreholeName.toUpperCase();

    projectID = projectID.replace(/[`¬!"£$%^&*()_\-+=<>,./|?#~;:[\]{}]/g, "")

    //label barcode
    var labelbarcode = projectID + '-' + boreholeName + '-' + sampleID;

    labelbarcode = labelbarcode.replaceAll(' ', '');

    const barcodeWidths = [
        3.3, 3.3, 3.3, 3.3, 3.3, 3.3, 3.0, 2.7, 2.5, 2.3, // 1 to 10
        2.1, 2.0, 1.85, 1.75, 1.65, 1.55, 1.5, 1.42, 1.36, 1.3, // 11 to 20
        1.25, 1.2, 1.15, 1.1, 1.05, 1.0, 1.0, 0.95, 0.93, 0.90, // 21 to 30
        0.88, 0.85, 0.83, 0.80, 0.80, 0.76, 0.75, 0.73, 0.72, 0.71 // 31 to 40
    ];

    var barcodeWidth = barcodeWidths[labelbarcode.length - 1]

    // var labelAreaClass = "labelArea " + (props.size)
    var labelType, defaultLabelType

    // get default print values from somewhere
    // temp values: defaultPrint

    const printingArea = useRef(null);
    const printingArea_top = useRef(null);

    const [printerSelect, setPrinterSelect] = useState('');
    const [numberD, setNumberD] = useState(1);
    const [numberB, setNumberB] = useState(1);
    const [numberES, setNumberES] = useState(1);
    const [numberWS, setNumberWS] = useState(1);
    const [numberU, setNumberU] = useState(1);
    const [numberPS, setNumberPS] = useState(1);
    const [numberL, setNumberL] = useState(1);
    const [numberCS, setNumberCS] = useState(1);
    
    const [loadingSettings, setLoadingSettings] = useState(true); // New loading flag
    const [saveDefault, setSaveDefault] = useState(false);
    const [showPrinterSetup, setShowPrinterSetup] = useState(false);

    const [defaultPrint, setDefaultPrint] = useState(1)
    const defaultPrintToshiba = defaultPrint.toString().padStart(4, '0');

    const printButtonText = 'Print Label'

    var sampleInitial = sampleID.charAt(0);

    if (sampleInitial === 'D') { defaultLabelType = "D"; labelType = 'general'; } // copiesD
    else if (sampleInitial === 'B') { defaultLabelType = "B"; labelType = 'general'; } // copiesB
    else if (sampleInitial === 'E') { defaultLabelType = "ES"; labelType = 'general'; } // copiesES
    else if (sampleInitial === 'W') { defaultLabelType = "WS"; labelType = 'general'; } // copiesWS
    else if (sampleInitial === 'U') { defaultLabelType = "U"; labelType = 'topImage'; } // copiesU
    else if (sampleInitial === 'P') { defaultLabelType = "PS"; labelType = 'topImage'; } // copiesPS
    else if (sampleInitial === 'L') { defaultLabelType = "LINER"; labelType = 'topImage'; } // copiesL
    else if (sampleInitial === 'C') { defaultLabelType = "CORE"; labelType = 'topImage'; } // copiesC
    else { defaultLabelType = "XXX"; labelType = 'general'; }

    console.log('Label Type:', defaultLabelType);
    console.log('printerSelect Type:', printerSelect);

    useEffect(() => {
        get('drillit-print-settings').then(data => {
            console.log('drillit-print-settings:', data);
    
            if (data) {
                // Make sure to properly set the values from data
                setPrinterSelect(data.print_type);
                setNumberD(data.numberD);
                setNumberB(data.numberB);
                setNumberES(data.numberES);
                setNumberWS(data.numberWS);
                setNumberU(data.numberU);
                setNumberPS(data.numberPS);
                setNumberL(data.numberL);
                setNumberCS(data.numberCS);
            }
            setLoadingSettings(false); // Set loading flag to false after data is loaded
        });
    }, []);

    const reloadPrintSettings = () => {
        get('drillit-print-settings').then(data => {
            // console.log('drillit-print-settings:', data);
            if (data) {
                // Make sure to properly set the values from data
                setPrinterSelect(data.print_type);
                setNumberD(data.numberD);
                setNumberB(data.numberB);
                setNumberES(data.numberES);
                setNumberWS(data.numberWS);
                setNumberU(data.numberU);
                setNumberPS(data.numberPS);
                setNumberL(data.numberL);
                setNumberCS(data.numberCS);
            }
            setLoadingSettings(false); // Set loading flag to false after data is loaded
        });
    }

    // Set default print based on sampleInitial and after settings are loaded
    useEffect(() => {
        if (!loadingSettings) {
            let newDefaultPrint = 1; // Default value if sampleInitial doesn't match
            switch (sampleInitial) {
                case 'D':
                    newDefaultPrint = numberD;
                    break;
                case 'B':
                    newDefaultPrint = numberB;
                    break;
                case 'E':
                    newDefaultPrint = numberES;
                    break;
                case 'W':
                    newDefaultPrint = numberWS;
                    break;
                case 'U':
                    newDefaultPrint = numberU;
                    break;
                case 'P':
                    newDefaultPrint = numberPS;
                    break;
                case 'L':
                    newDefaultPrint = numberL;
                    break;
                case 'C':
                    newDefaultPrint = numberCS;
                    break;
                default:
                    newDefaultPrint = 1; // Default fallback
            }
            setDefaultPrint(newDefaultPrint);
            console.log(`*Type: ${sampleInitial} Number: ${newDefaultPrint}`);
        }
    }, [loadingSettings, sampleInitial, numberD, numberB, numberES, numberWS, numberU, numberPS, numberL, numberCS]);

    useEffect(() => {
        console.log('defaultPrint:', defaultPrint);
    }, [defaultPrint]);

    const setPrintNumber  = (val: number) => {

        if (loadingSettings) return;

        console.log('^Type:', sampleInitial, 'Number:', val)
        if (sampleInitial === 'D') { setNumberD(val); setDefaultPrint(val); } // copiesD
        else if (sampleInitial === 'B') { setNumberB(val); setDefaultPrint(val); } // copiesB
        else if (sampleInitial === 'E') { setNumberES(val); setDefaultPrint(val); } // copiesES
        else if (sampleInitial === 'W') { setNumberWS(val); setDefaultPrint(val); } // copiesWS
        else if (sampleInitial === 'U') { setNumberU(val); setDefaultPrint(val); } // copiesU
        else if (sampleInitial === 'P') { setNumberPS(val); setDefaultPrint(val); } // copiesPS
        else if (sampleInitial === 'L') { setNumberL(val); setDefaultPrint(val); } // copiesL
        else if (sampleInitial === 'C') { setNumberCS(val); setDefaultPrint(val); } // copiesC
        
        console.log('saveDefault:', saveDefault);

        if (saveDefault === true) {
            saveToIDB()
        }
    };

    const toggleCheckbox = () => {
        setSaveDefault(prevValue => !prevValue) // Toggles between true and false
    };

    useEffect(() => {
        console.log('saveDefault:', saveDefault);
        if (saveDefault) {
            saveToIDB()
        }
    }, [saveDefault]);

    useEffect(() => {
        if (saveDefault) {
            saveToIDB()
        }
    }, [defaultPrint]);

    const saveToIDB = () => {
        console.log('numberD on saveToIDB:', numberD);
        const defaultSettings = {
            print_type: printerSelect,
            numberD: numberD,
            numberB: numberB,
            numberES: numberES,
            numberWS: numberWS,
            numberU: numberU,
            numberPS: numberPS,
            numberL: numberL,
            numberCS: numberCS,
        };

        // Save default settings to IndexedDB
        set('drillit-print-settings', defaultSettings).then(() => {
            console.log('Default settings saved to IDB:', defaultSettings);
        });
    }

    const handleGeneralLabel = () => {
        const tpclCommand = `
        {C|}
        {D0660,0480,0630|}
        {AX;+000,+000,+00|}
        {AY;+00,1|}
        {C|}
        {PC000;0438,0137,05,05,J,-01,11,B,J0000,+0000000000,P3|}        <!--LOCATIONHEADER-->   <!-- ID; TOP, LEFT, WIDTH, HEIGHT -->
        {PC001;0437,0152,05,05,J,-02,11,B,J0000,+0000000000,P1|}        <!--LOCATIONVAR-->
        {PC002;0392,0137,05,05,J,-01,11,B,J0000,+0000000000,P3|}        <!--PROJHEADER-->
        {PC003;0385,0148,08,08,J,-04,11,B,J0000,+0000000000,P1|}        <!--PROJECTVAR-->
        {PC004;0318,0137,05,05,J,-01,11,B,J0000,+0000000000,P3|}        <!--HOLEIDHEADER-->
        {PC005;0298,0145,15,15,J,-07,11,B,J0000,+0000000000,P1|}        <!--HOLEIDVAR-->
        {PC006;0226,0137,05,05,J,-01,11,B,J0000,+0000000000,P3|}        <!--SAMPLEHEADER-->
        {PC007;0206,0148,15,15,J,-07,11,B,J0000,+0000000000,P1|}        <!--SAMPLEVAR-->
        {PC008;0154,0137,05,05,J,-01,11,B,J0000,+0000000000,P3|}        <!--DEPTHHEADER-->
        {PC009;0147,0147,08,08,J,-02,11,B,J0000,+0000000000,P1|}        <!--DEPTHVAR-->
        {PC010;0108,0137,05,05,J,-01,11,B,J0000,+0000000000,P3|}        <!--USERTEXT-->
        {PC011;0108,0152,05,05,J,-02,11,B,J0000,+0000000000,P1|}        <!--USERVAR-->
        {PC012;0108,0447,05,05,J,-01,11,B,J0000,+0000000000,P1|}        <!--DATEVAR-->
        {XB00;0091,0039,9,3,02,1,0048,+0000000000,048,0,00|}            <!--BARCODE-->
        {PC013;0017,0100,05,05,H,+00,11,B,J0000,+0000000000,P1|}        <!--FOOTERTEXT-->
        {PC014;0340,0014,10,10,H,+00,11,W0599,J0000,+0000000000,P1|}    <!--BACKBLACK-->
        {RC000;LOC:|}                                                   <!--LOCATIONHEADER-->
        {RC001;${projectLocation}|}                                     <!--LOCATIONVAR-->
        {RC002;PROJ:|}                                                  <!--PROJHEADER-->
        {RC003;${projectID}|}                                           <!--PROJECTVAR-->
        {RC004;HOLE:|}                                                  <!--HOLEIDHEADER-->
        {RC005;${boreholeName}|}                                        <!--HOLEIDVAR-->
        {RC006;SAMP:|}                                                  <!--SAMPLEHEADER-->
        {RC007;${sampleID}|}                                            <!--SAMPLEVAR-->
        {RC008;DEPTH:|}                                                 <!--DEPTHHEADER-->
        {RC009;${sampleDepth}|}                                         <!--DEPTHVAR-->
        {RC010;USER:|}                                                  <!--USERTEXT-->
        {RC011;${sampler}|}                                             <!--USERVAR-->
        {RC012;${sampleDate}|}                                          <!--DATEVAR-->
        {RB00;${labelbarcode}|}                                         <!--BARCODE-->
        {RC013;PRODUCED BY DRILLITLOGS.COM|}                            <!--FOOTERTEXT-->
        {LC;0467,0617,0099,0022,1,2,000|}                               <!--RECTANGLE-->
        {LC;0428,0022,0428,0615,0,2,000|}                               <!--HORIZONTALLINE1-->
        {LC;0373,0022,0373,0616,0,2,000|}                               <!--HORIZONTALLINE2-->
        {LC;0280,0022,0280,0616,0,2,000|}                               <!--HORIZONTALLINE3-->
        {LC;0190,0022,0190,0615,0,2,000|}                               <!--HORIZONTALLINE4-->
        {LC;0136,0022,0136,0615,0,2,000|}                               <!--HORIZONTALLINE5-->
        {LC;0467,0144,0099,0144,0,2,000|}                               <!--VERTICALLINE1-->
        {LC;0137,0439,0100,0439,0,2,000|}                               <!--VERTICALLINE2-->
            `;
        const encodedTpclCommand = encodeURIComponent(tpclCommand);
        const skyPrintUrl = `tecbcp:///tpcl?printnumber=${defaultPrintToshiba};sensortype=2;data=${encodedTpclCommand};cutinterval=000;printspeed=2;autoclose=1;`; // printnumber=0001;callback=https://app.drillitlogs.com;
        window.open(skyPrintUrl, '_blank');
    }

    const handleTOPLabel = () => {
        const tpclCommand = `
        {C|}
        {D0660,0480,0630|}
        {AX;+000,+000,+00|}
        {AY;+00,1|}
        {C|}
        {PC000;0440,0116,05,05,G,+00,11,B,J0000,+0000000000,P2|}        <!--LOCATIONHEADER-->   <!-- ID; TOP, LEFT, WIDTH, HEIGHT -->
        {PC001;0437,0152,05,05,J,-02,11,B,J0000,+0000000000,P1|}        <!--LOCATIONVAR-->
        {PC002;0397,0138,05,05,G,+00,11,B,J0000,+0000000000,P3|}        <!--PROJHEADER-->
        {PC003;0385,0148,08,08,J,-04,11,B,J0000,+0000000000,P1|}        <!--PROJECTVAR-->
        {PC004;0320,0115,05,05,G,+00,11,B,J0000,+0000000000,P2|}        <!--HOLEIDHEADER-->
        {PC005;0298,0145,15,15,J,-07,11,B,J0000,+0000000000,P1|}        <!--HOLEIDVAR-->
        {PC006;0226,0114,05,05,G,+00,11,B,J0000,+0000000000,P2|}        <!--SAMPLEHEADER-->
        {PC007;0206,0148,15,15,J,-07,11,B,J0000,+0000000000,P1|}        <!--SAMPLEVAR-->
        {PC008;0157,0110,05,05,G,+00,11,B,J0000,+0000000000,P2|}        <!--DEPTHHEADER-->
        {PC009;0147,0147,08,08,J,-02,11,B,J0000,+0000000000,P1|}        <!--DEPTHVAR-->
        {PC010;0109,0114,05,05,G,+00,11,B,J0000,+0000000000,P2|}        <!--USERTEXT-->
        {PC011;0108,0152,05,05,J,-02,11,B,J0000,+0000000000,P1|}        <!--USERVAR-->
        {PC012;0108,0447,05,05,J,-01,11,B,J0000,+0000000000,P1|}        <!--DATEVAR-->
        {XB00;0091,0039,9,3,02,1,0048,+0000000000,048,0,00|}            <!--BARCODE-->
        {PC013;0017,0100,05,05,H,+00,11,B,J0000,+0000000000,P1|}        <!--FOOTERTEXT-->
        {PC014;0340,0014,10,10,H,+00,11,W0599,J0000,+0000000000,P1|}    <!--BACKBLACK-->
        {RC000;LOC:|}                                                   <!--LOCATIONHEADER-->
        {RC001;${projectLocation}|}                                     <!--LOCATIONVAR-->
        {RC002;PROJ:|}                                                  <!--PROJHEADER-->
        {RC003;${projectID}|}                                           <!--PROJECTVAR-->
        {RC004;HOLE:|}                                                  <!--HOLEIDHEADER-->
        {RC005;${boreholeName}|}                                        <!--HOLEIDVAR-->
        {RC006;SAMP:|}                                                  <!--SAMPLEHEADER-->
        {RC007;${sampleID}|}                                            <!--SAMPLEVAR-->
        {RC008;DEPTH:|}                                                 <!--DEPTHHEADER-->
        {RC009;${sampleDepth}|}                                         <!--DEPTHVAR-->
        {RC010;USER:|}                                                  <!--USERTEXT-->
        {RC011;${sampler}|}                                             <!--USERVAR-->
        {RC012;${sampleDate}|}                                          <!--DATEVAR-->
        {RB00;${labelbarcode}|}                                         <!--BARCODE-->
        {RC013;PRODUCED BY DRILLITLOGS.COM|}                            <!--FOOTERTEXT-->
        {LC;0467,0617,0099,0013,1,2,000|}                               <!--RECTANGLE-->
        {LC;0428,0012,0428,0615,0,2,000|}                               <!--HORIZONTALLINE1-->
        {LC;0373,0013,0373,0616,0,2,000|}                               <!--HORIZONTALLINE2-->
        {LC;0280,0015,0280,0616,0,2,000|}                               <!--HORIZONTALLINE3-->
        {LC;0190,0013,0190,0615,0,2,000|}                               <!--HORIZONTALLINE4-->
        {LC;0136,0013,0136,0615,0,2,000|}                               <!--HORIZONTALLINE5-->
        {LC;0467,0144,0099,0144,0,2,000|}                               <!--VERTICALLINE1-->
        {LC;0137,0439,0100,0439,0,2,000|}                               <!--VERTICALLINE2-->
        {SG;0100,0013,0240,0004,0,????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????|}
        {SG;0400,0013,0080,0004,0,?????????????8000000?????????????8000000?????????????8000000?????????????8000000|}
        {SG;0100,0018,0240,0004,0,????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????|}
        {SG;0400,0018,0080,0004,0,?????????????8000000?????????????8000000?????????????8000000?????????????8000000|}
        {SG;0100,0023,0240,0004,0,????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????|}
        {SG;0400,0023,0080,0004,0,?????????????8000000?????????????8000000?????????????8000000?????????????8000000|}
        {SG;0100,0028,0240,0004,0,???????????????????????????==<>95??803???;56;??????????????????????????????????????????<00000?<000???0000??????????????????????????????????????????800001?00003?>00001??????????????????????>??????????>???????<00001>00001??00001?????>3???????|}
        {SG;0400,0028,0080,0004,0,?????????????8000000?????????????8000000?????????????8000000??>7?????????8000000|}
        {SG;0100,0033,0240,0004,0,????????<3?????????87??????<40002<01?00?>05900?????>3???????????????81?????????83????????<1??<07?807?03?<0?????<1???????????????81?????????83????????<1??<07?807?03?<0?????<1???????????????81?????????03????????80??01??>07>07?<0?????80???????|}
        {SG;0400,0033,0080,0004,0,??<7?????????8000000??<3?????????8000000??<3?????????8000000??01?????????8000000|}
        {SG;0100,0038,0240,0004,0,????????00????????>01????????<1??03??>07?07?>07????80??????????????<007???????<00????????80??03??>07>03?<0?????007?????????????8007???????<00????????<1??03???07?07?<0????>003?????????????8003???????8003???????80?>07???03>07?80????<000??????|}
        {SG;0400,0038,0080,0004,0,??01?????????8000000?>007????????8000000?<003????????8000000?<001????????8000000|}
        {SG;0100,0043,0240,0004,0,???????0001??????>0001???????<1??03???03?00600????<000????????????>0001??????>0001???????80?>07???03>00001????00007???????????>0001??????>0001???????80?>07???03>00001????00007???????????>00007?????<0000???????<1??07???03?00003???>00003?????|}
        {SG;0400,0043,0080,0004,0,?0001????????8000000?0000????????8000000?0000????????8000000>00007???????8000000|}
        {SG;0100,0048,0240,0004,0,??????<00003?????800007??????80??03???07>0000????>00003???????????800003?????000003??????<0??03??>03?037?????<00001???????????800001?????000003??????81??01??>07>03??????800000??????????>000000????>000001??????<0??<1??>07?07??????800000?????|}
        {SG;0400,0048,0080,0004,0,>00007???????8000000800001???????8000000000000???????80000000000007??????8000000|}
        {SG;0100,0053,0240,0004,0,?????<000000????<000001??????81??<1??807>03??????0000003???>?????<000000????<000001??????81??<1??807>03??????0000003???>?????<0000003???<0000007?????<0??<01?00??07?????>0000001???8?????9888<207???<4260<23?????81??>00001??03?????>4021081???>|}
        {SG;0400,0053,0080,0004,0,0000007??????80000000000007??????80000000000003??????80000001020463??????8000000|}
        {SG;0100,0058,0240,0004,0,?????????????????????????????<0???00003?>07??????????????????????????????????????????????81???<000???03??????????????????????????????????????????????<4???>003???07?????????????????????????????????????????????????????????????????????????????|}
        {SG;0400,0058,0080,0004,0,?????????????8000000?????????????8000000?????????????8000000?????????????8000000|}
        {SG;0100,0063,0240,0004,0,????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????|}
        {SG;0400,0063,0080,0004,0,?????????????8000000?????????????8000000?????????????8000000?????????????8000000|}
        {SG;0100,0068,0240,0004,0,????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????|}
        {SG;0400,0068,0080,0004,0,?????????????8000000?????????????8000000?????????????8000000?????????????8000000|}
        {SG;0100,0073,0240,0004,0,????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????????000000000000000000000000000000000000000000000000000000000000|}
        {SG;0400,0073,0080,0004,0,?????????????8000000?????????????8000000?????????????800000000000000000000000000|}
            `;
        const encodedTpclCommand = encodeURIComponent(tpclCommand);
        const skyPrintUrl = `tecbcp:///tpcl?printnumber=${defaultPrintToshiba};sensortype=2;data=${encodedTpclCommand};cutinterval=000;printspeed=2;autoclose=1;`; // printnumber=0001;callback=https://app.drillitlogs.com;
        window.open(skyPrintUrl, '_blank');
    }

    //...........................................................................................
//.....GGGGGGG....EEEEEEEEEEE.NNNN...NNNN..EEEEEEEEEEE.RRRRRRRRRR......AAAAA.....LLLL........
//...GGGGGGGGGG...EEEEEEEEEEE.NNNNN..NNNN..EEEEEEEEEEE.RRRRRRRRRRR.....AAAAA.....LLLL........
//..GGGGGGGGGGGG..EEEEEEEEEEE.NNNNN..NNNN..EEEEEEEEEEE.RRRRRRRRRRR....AAAAAA.....LLLL........
//..GGGGG..GGGGG..EEEE........NNNNNN.NNNN..EEEE........RRRR...RRRRR...AAAAAAA....LLLL........
//.GGGGG....GGG...EEEE........NNNNNN.NNNN..EEEE........RRRR...RRRRR..AAAAAAAA....LLLL........
//.GGGG...........EEEEEEEEEE..NNNNNNNNNNN..EEEEEEEEEE..RRRRRRRRRRR...AAAAAAAA....LLLL........
//.GGGG..GGGGGGGG.EEEEEEEEEE..NNNNNNNNNNN..EEEEEEEEEE..RRRRRRRRRRR...AAAA.AAAA...LLLL........
//.GGGG..GGGGGGGG.EEEEEEEEEE..NNNNNNNNNNN..EEEEEEEEEE..RRRRRRRR.....AAAAAAAAAA...LLLL........
//.GGGGG.GGGGGGGG.EEEE........NNNNNNNNNNN..EEEE........RRRR.RRRR....AAAAAAAAAAA..LLLL........
//..GGGGG....GGGG.EEEE........NNNN.NNNNNN..EEEE........RRRR..RRRR...AAAAAAAAAAA..LLLL........
//..GGGGGGGGGGGG..EEEEEEEEEEE.NNNN..NNNNN..EEEEEEEEEEE.RRRR..RRRRR.RAAA....AAAA..LLLLLLLLLL..
//...GGGGGGGGGG...EEEEEEEEEEE.NNNN..NNNNN..EEEEEEEEEEE.RRRR...RRRRRRAAA.....AAAA.LLLLLLLLLL..
//.....GGGGGGG....EEEEEEEEEEE.NNNN...NNNN..EEEEEEEEEEE.RRRR....RRRRRAAA.....AAAA.LLLLLLLLLL..
//...........................................................................................

    if (labelType === 'general') {

        return ( // general label PRINT (D B WS ES)

            <>

            {/* <div className="text-xs">
                <p>Operating System: {operatingSystem}</p>
                <p>Device Type: {device}</p>
                <p>User Agent: {userAgent}</p>
            </div> */}

            {showPrinterSetup && (
                <PrinterSetup onClose={() => { 
                    setShowPrinterSetup(false)
                    reloadPrintSettings()
                }}/>
            )}

                <div className="hidden">{defaultPrint}{defaultLabelType}</div>

                <div className="text-center w-full flex-grow border border-grey-mid rounded px-2 mb-2 bg-sptTB pb-4">  {/* START PRINT LABELS HOLDER */}

                    <div className="text-xl text-center w-full mt-2">Print Label for {sampleID}?</div>

                    <div onClick ={() => {setShowPrinterSetup(true)}} className="w-10 ml-auto -mt-6 -mb-2 text-center">
                        <div><img className="mx-auto" src={systemIcon} width="32" alt="" /></div>
                        {/* <div className="text-xs">Settings</div> */}
                    </div>

                    {printerSelect === 'Print Manager' && (

                        <>

                        {/* //................................................................................................................................
                            //.PPPPPPPPP...PRRRRRRRRR..RRIII.INNN...NNNN..NTTTTTTTTTT.... MMMM...MMMMMM....AAAAA.....NNNN...NNNN.....AAAAA........GGGGGGG....
                            //.PPPPPPPPPP..PRRRRRRRRRR.RRIII.INNNN..NNNN..NTTTTTTTTTT.... MMMM...MMMMMM....AAAAA.....NNNNN..NNNN.....AAAAA......GGGGGGGGGG...
                            //.PPPPPPPPPPP.PRRRRRRRRRR.RRIII.INNNN..NNNN..NTTTTTTTTTT.... MMMM...MMMMMM...AAAAAA.....NNNNN..NNNN....AAAAAA.....GGGGGGGGGGGG..
                            //.PPPP...PPPP.PRRR...RRRRRRRIII.INNNNN.NNNN.....TTTT........ MMMMM.MMMMMMM...AAAAAAA....NNNNNN.NNNN....AAAAAAA....GGGGG..GGGGG..
                            //.PPPP...PPPP.PRRR...RRRRRRRIII.INNNNN.NNNN.....TTTT........ MMMMM.MMMMMMM..AAAAAAAA....NNNNNN.NNNN...AAAAAAAA...AGGGG....GGG...
                            //.PPPPPPPPPPP.PRRRRRRRRRR.RRIII.INNNNNNNNNN.....TTTT........ MMMMM.MMMMMMM..AAAAAAAA....NNNNNNNNNNN...AAAAAAAA...AGGG...........
                            //.PPPPPPPPPP..PRRRRRRRRRR.RRIII.INNNNNNNNNN.....TTTT........ MMMMMMMMMMMMM..AAAA.AAAA...NNNNNNNNNNN...AAAA.AAAA..AGGG..GGGGGGG..
                            //.PPPPPPPPP...PRRRRRRR....RRIII.INNNNNNNNNN.....TTTT........ MMMMMMMMMMMMM.AAAAAAAAAA...NNNNNNNNNNN..AAAAAAAAAA..AGGG..GGGGGGG..
                            //.PPPP........PRRR.RRRR...RRIII.INNNNNNNNNN.....TTTT........ MMMMMMMMMMMMM.AAAAAAAAAAA..NNNNNNNNNNN..AAAAAAAAAAA.AGGGG.GGGGGGG..
                            //.PPPP........PRRR..RRRR..RRIII.INNN.NNNNNN.....TTTT........ MM.MMMMM.MMMM.AAAAAAAAAAA..NNNN.NNNNNN..AAAAAAAAAAA..GGGGG....GGG..
                            //.PPPP........PRRR..RRRRR.RRIII.INNN..NNNNN.....TTTT........ MM.MMMMM.MMMMMAAA....AAAA..NNNN..NNNNN.NAAA....AAAA..GGGGGGGGGGGG..
                            //.PPPP........PRRR...RRRRRRRIII.INNN..NNNNN.....TTTT........ MM.MMMMM.MMMMMAAA.....AAAA.NNNN..NNNNN.NAAA.....AAAA..GGGGGGGGGG...
                            //.PPPP........PRRR....RRRRRRIII.INNN...NNNN.....TTTT........ MM.MMMMM.MMMMMAAA.....AAAA.NNNN...NNNNNNAAA.....AAAA....GGGGGGG....
                            //................................................................................................................................ */}
    

                            {/* START LABEL */}

                            {/* //........................................................
                                //.PPPPPPPPP...PRRRRRRRRR..RRIII.INNN...NNNN..NTTTTTTTTT..
                                //.PPPPPPPPPP..PRRRRRRRRRR.RRIII.INNNN..NNNN..NTTTTTTTTT..
                                //.PPPPPPPPPPP.PRRRRRRRRRR.RRIII.INNNN..NNNN..NTTTTTTTTT..
                                //.PPPP...PPPP.PRRR...RRRRRRRIII.INNNNN.NNNN.....TTTT.....
                                //.PPPP...PPPP.PRRR...RRRRRRRIII.INNNNN.NNNN.....TTTT.....
                                //.PPPPPPPPPPP.PRRRRRRRRRR.RRIII.INNNNNNNNNN.....TTTT.....
                                //.PPPPPPPPPP..PRRRRRRRRRR.RRIII.INNNNNNNNNN.....TTTT.....
                                //.PPPPPPPPP...PRRRRRRR....RRIII.INNNNNNNNNN.....TTTT.....
                                //.PPPP........PRRR.RRRR...RRIII.INNNNNNNNNN.....TTTT.....
                                //.PPPP........PRRR..RRRR..RRIII.INNN.NNNNNN.....TTTT.....
                                //.PPPP........PRRR..RRRRR.RRIII.INNN..NNNNN.....TTTT.....
                                //.PPPP........PRRR...RRRRRRRIII.INNN..NNNNN.....TTTT.....
                                //.PPPP........PRRR....RRRRRRIII.INNN...NNNN.....TTTT.....
                                //........................................................ */}

                            <div className="hidden">

                                <div id="label" className="labelArea border border-grey-mid rounded-lg mx-auto mt-4 mb-4 pt-2 z-30">

                                    <div className="labelBody mt-1.5 " ref={printingArea} >

                                        <div className="w-full printArea">

                                            <table className="w-full border border-black p-0 text-left letterSpacing ">
                                                <tbody>
                                                    <tr>
                                                        <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                            Loc:
                                                        </td>
                                                        <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1rem', lineHeight: '1.3rem' }}>
                                                            {projectLocation}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                            Project:
                                                        </td>
                                                        <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.7rem', lineHeight: '2.0rem' }}>
                                                            {projectID}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                            ID:
                                                        </td>
                                                        <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold letterSpacingExtreme" style={{ fontSize: '2.4rem', lineHeight: '2.6rem' }}>
                                                            {boreholeName}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                            Type:
                                                        </td>
                                                        <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold letterSpacingExtreme" style={{ fontSize: '2.4rem', lineHeight: '2.6rem' }}>
                                                            {sampleID}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                            Depth:
                                                        </td>
                                                        <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.6rem', lineHeight: '1.8rem' }}>
                                                            {sampleDepth}<span className="ml-0.5" style={{ fontSize: '1rem', lineHeight: '1.1rem' }}>m</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                            User:
                                                        </td>
                                                        <td className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '0.9rem', lineHeight: '1.2rem' }}>
                                                            {/* {loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name)} */}
                                                            {sampler}
                                                        </td>
                                                        <td className="border border-black pl-1 pt-0.5 font-bold" style={{ width: '80px', fontSize: '0.9rem', lineHeight: '1.2rem' }}>
                                                            {sampleDate}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <div className="barcodeHolder">
                                                <Barcode value={labelbarcode} options={{
                                                    format: 'code128',
                                                    width: barcodeWidth,
                                                    height: 20,
                                                    fontSize: 14,
                                                    // text: labelbarcode,
                                                    background: 'none'
                                                }} />

                                            </div>

                                            {/* <div className="drillitLogsText mx-auto text-center mt-1 font-bold text-xs">PRODUCED BY DRILLIT LOGS - DRILLITLOGS.COM</div> */}
                                            <div className="mx-auto text-center mt-1 font-bold" style={{ fontSize: '0.65rem' }}>PRODUCED BY DRILLIT LOGS - DRILLITLOGS.COM</div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/* //.............................................................................
                                //...SSSSSSS......CCCCCCC....RRRRRRRRRR...EEEEEEEEEEE.EEEEEEEEEEE.NNNN...NNNN..
                                //..SSSSSSSSS....CCCCCCCCC...RRRRRRRRRRR..EEEEEEEEEEE.EEEEEEEEEEE.NNNNN..NNNN..
                                //..SSSSSSSSSS..CCCCCCCCCCC..RRRRRRRRRRR..EEEEEEEEEEE.EEEEEEEEEEE.NNNNN..NNNN..
                                //.SSSSS..SSSS..CCCC...CCCCC.RRRR...RRRRR.EEEE........EEEE........NNNNNN.NNNN..
                                //.SSSSS.......SCCC.....CCC..RRRR...RRRRR.EEEE........EEEE........NNNNNN.NNNN..
                                //..SSSSSSS....SCCC..........RRRRRRRRRRR..EEEEEEEEEE..EEEEEEEEEE..NNNNNNNNNNN..
                                //...SSSSSSSSS.SCCC..........RRRRRRRRRRR..EEEEEEEEEE..EEEEEEEEEE..NNNNNNNNNNN..
                                //.....SSSSSSS.SCCC..........RRRRRRRR.....EEEEEEEEEE..EEEEEEEEEE..NNNNNNNNNNN..
                                //........SSSSSSCCC.....CCC..RRRR.RRRR....EEEE........EEEE........NNNNNNNNNNN..
                                //.SSSS....SSSS.CCCC...CCCCC.RRRR..RRRR...EEEE........EEEE........NNNN.NNNNNN..
                                //.SSSSSSSSSSSS.CCCCCCCCCCC..RRRR..RRRRR..EEEEEEEEEEE.EEEEEEEEEEE.NNNN..NNNNN..
                                //..SSSSSSSSSS...CCCCCCCCCC..RRRR...RRRRR.EEEEEEEEEEE.EEEEEEEEEEE.NNNN..NNNNN..
                                //...SSSSSSSS.....CCCCCCC....RRRR....RRRR.EEEEEEEEEEE.EEEEEEEEEEE.NNNN...NNNN..
                                //.............................................................................     */}

                            <div id="label" className="bg-white w-72 border border-grey-mid rounded-lg mx-auto mt-4 mb-4 p-2 z-30 labelShadow">
                                <div className="mt-1.5" >

                                    <table className="w-full border border-black p-0 text-left letterSpacing ">
                                        <tbody>
                                            <tr>
                                                <td colSpan={2} className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.5rem', lineHeight: '1.1rem' }}>
                                                    Loc:
                                                </td>
                                                <td colSpan={3} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '0.8rem', lineHeight: '0.9rem' }}>
                                                    {projectLocation}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2} className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.6rem', lineHeight: '1.1rem' }}>
                                                    Proj:
                                                </td>
                                                <td colSpan={3} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.0rem', lineHeight: '1.3rem' }}>
                                                    {projectID}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2} className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.6rem', lineHeight: '1.1rem' }}>
                                                    ID:
                                                </td>
                                                <td colSpan={3} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.5rem', lineHeight: '1.6rem' }}>
                                                    {boreholeName}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2} className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.6rem', lineHeight: '1.1rem' }}>
                                                    Samp:
                                                </td>
                                                <td colSpan={3} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.5rem', lineHeight: '1.6rem' }}>
                                                    {sampleID}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2} className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.6rem', lineHeight: '1.1rem' }}>
                                                    Depth:
                                                </td>
                                                <td colSpan={3} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.0rem', lineHeight: '1.3rem' }}>
                                                    {sampleDepth}<span className="ml-0.5" style={{ fontSize: '1rem', lineHeight: '1.1rem' }}>m</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2} className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.6rem', lineHeight: '1.1rem' }}>
                                                    User:
                                                </td>
                                                <td className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '0.7rem', lineHeight: '0.8rem' }}>
                                                    {/* {loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name)} */}
                                                    {sampler}
                                                </td>
                                                <td className="border border-black pl-1 pt-0.5 font-bold text-left" style={{ width: '60px', fontSize: '0.7rem', lineHeight: '0.8rem' }}>
                                                    {sampleDate}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>


                                    <div className="barcodeHolder overflow-hidden">
                                        <Barcode value={labelbarcode} options={{
                                            format: 'code128',
                                            width: barcodeWidth,
                                            height: 14,
                                            fontSize: 11,
                                            // text: labelbarcode,
                                            background: 'none'
                                        }} />

                                    </div>
                                    <div className="mx-auto text-center -mt-0.5 font-bold" style={{ fontSize: '0.45rem' }}>PRODUCED BY DRILLIT LOGS - DRILLITLOGS.COM</div>
                                </div>
                            </div>

                            {/* END LABEL */}

                            <ReactToPrint
                                trigger={() => <Button theme="primary" size="lg" className="mt-0 mb-1 px-8 w-40 mx-auto" >{printButtonText}</Button>}
                                content={() => printingArea.current}
                            />

                        </>

                    )}

                    {printerSelect === 'Toshiba' && (

                        <>

                        {/* //..................................................................................
                            //.TTTTTTTTTTT..OOOOOOO......SSSSSSS...SSHH....HHHH.HHII.IIBBBBBBBBB......AAAA......
                            //.TTTTTTTTTTT.OOOOOOOOOO...SSSSSSSSS..SSHH....HHHH.HHII.IIBBBBBBBBBB....AAAAAA.....
                            //.TTTTTTTTTTTOOOOOOOOOOOO.OSSSSSSSSSS.SSHH....HHHH.HHII.IIBBBBBBBBBB....AAAAAA.....
                            //....TTTT...TOOOO...OOOOO.OSSS...SSSS.SSHH....HHHH.HHII.IIBB....BBBB....AAAAAAA....
                            //....TTTT...TOOO.....OOOOOOSSSS.......SSHH....HHHH.HHII.IIBB....BBBB...AAAAAAAA....
                            //....TTTT...TOOO......OOOO.SSSSSSS....SSHHHHHHHHHH.HHII.IIBBBBBBBBBB...AAAAAAAA....
                            //....TTTT...TOOO......OOOO..SSSSSSSS..SSHHHHHHHHHH.HHII.IIBBBBBBBBB....AAAA.AAAA...
                            //....TTTT...TOOO......OOOO....SSSSSSS.SSHHHHHHHHHH.HHII.IIBBBBBBBBBB..BAAAAAAAAA...
                            //....TTTT...TOOO.....OOOOO.......SSSSSSSHH....HHHH.HHII.IIBB.....BBBB.BAAAAAAAAAA..
                            //....TTTT...TOOOOO..OOOOO.OSSS...SSSSSSSHH....HHHH.HHII.IIBB.....BBBBBBAAAAAAAAAA..
                            //....TTTT....OOOOOOOOOOOO.OSSSSSSSSSS.SSHH....HHHH.HHII.IIBBBBBBBBBBBBBAA....AAAA..
                            //....TTTT.....OOOOOOOOOO...SSSSSSSSSS.SSHH....HHHH.HHII.IIBBBBBBBBBB.BBAA....AAAA..
                            //....TTTT......OOOOOOO......SSSSSSS...SSHH....HHHH.HHII.IIBBBBBBBBB.BBBAA.....AAA..
                            //.................................................................................. */}

                            {/* START LABEL */}

                            {/* //........................................................
                                //.PPPPPPPPP...PRRRRRRRRR..RRIII.INNN...NNNN..NTTTTTTTTT..
                                //.PPPPPPPPPP..PRRRRRRRRRR.RRIII.INNNN..NNNN..NTTTTTTTTT..
                                //.PPPPPPPPPPP.PRRRRRRRRRR.RRIII.INNNN..NNNN..NTTTTTTTTT..
                                //.PPPP...PPPP.PRRR...RRRRRRRIII.INNNNN.NNNN.....TTTT.....
                                //.PPPP...PPPP.PRRR...RRRRRRRIII.INNNNN.NNNN.....TTTT.....
                                //.PPPPPPPPPPP.PRRRRRRRRRR.RRIII.INNNNNNNNNN.....TTTT.....
                                //.PPPPPPPPPP..PRRRRRRRRRR.RRIII.INNNNNNNNNN.....TTTT.....
                                //.PPPPPPPPP...PRRRRRRR....RRIII.INNNNNNNNNN.....TTTT.....
                                //.PPPP........PRRR.RRRR...RRIII.INNNNNNNNNN.....TTTT.....
                                //.PPPP........PRRR..RRRR..RRIII.INNN.NNNNNN.....TTTT.....
                                //.PPPP........PRRR..RRRRR.RRIII.INNN..NNNNN.....TTTT.....
                                //.PPPP........PRRR...RRRRRRRIII.INNN..NNNNN.....TTTT.....
                                //.PPPP........PRRR....RRRRRRIII.INNN...NNNN.....TTTT.....
                                //........................................................ */}

                            <div className="hidden">

                                <div id="label" className="labelArea border border-grey-mid rounded-lg mx-auto mt-4 mb-4 pt-2 z-30">

                                    <div className="labelBody mt-1.5" ref={printingArea} >

                                        <div className="w-full printArea">

                                            <table className="w-full border border-black p-0 text-left letterSpacing ">
                                                <tbody>
                                                    <tr>
                                                        <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                            Loc:
                                                        </td>
                                                        <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1rem', lineHeight: '1.3rem' }}>
                                                            {projectLocation}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                            Project:
                                                        </td>
                                                        <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.7rem', lineHeight: '2.0rem' }}>
                                                            {projectID}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                            ID:
                                                        </td>
                                                        <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold letterSpacingExtreme" style={{ fontSize: '2.4rem', lineHeight: '2.6rem' }}>
                                                            {boreholeName}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                            Type:
                                                        </td>
                                                        <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold letterSpacingExtreme" style={{ fontSize: '2.4rem', lineHeight: '2.6rem' }}>
                                                            {sampleID}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                            Depth:
                                                        </td>
                                                        <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.6rem', lineHeight: '1.8rem' }}>
                                                            {sampleDepth}<span className="ml-0.5" style={{ fontSize: '1rem', lineHeight: '1.1rem' }}>m</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                            User:
                                                        </td>
                                                        <td className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '0.9rem', lineHeight: '1.2rem' }}>
                                                            {/* {loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name)} */}
                                                            {sampler}
                                                        </td>
                                                        <td className="border border-black pl-1 pt-0.5 font-bold" style={{ width: '80px', fontSize: '0.9rem', lineHeight: '1.2rem' }}>
                                                            {sampleDate}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <div className="barcodeHolder">
                                                <Barcode value={labelbarcode} options={{
                                                    format: 'code128',
                                                    width: barcodeWidth,
                                                    height: 20,
                                                    fontSize: 14,
                                                    // text: labelbarcode,
                                                    background: 'none'
                                                }} />

                                            </div>

                                            {/* <div className="drillitLogsText mx-auto text-center mt-1 font-bold text-xs">PRODUCED BY DRILLIT LOGS - DRILLITLOGS.COM</div> */}
                                            <div className="mx-auto text-center mt-1 font-bold" style={{ fontSize: '0.65rem' }}>PRODUCED BY DRILLIT LOGS - DRILLITLOGS.COM</div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/* //.............................................................................
                                //...SSSSSSS......CCCCCCC....RRRRRRRRRR...EEEEEEEEEEE.EEEEEEEEEEE.NNNN...NNNN..
                                //..SSSSSSSSS....CCCCCCCCC...RRRRRRRRRRR..EEEEEEEEEEE.EEEEEEEEEEE.NNNNN..NNNN..
                                //..SSSSSSSSSS..CCCCCCCCCCC..RRRRRRRRRRR..EEEEEEEEEEE.EEEEEEEEEEE.NNNNN..NNNN..
                                //.SSSSS..SSSS..CCCC...CCCCC.RRRR...RRRRR.EEEE........EEEE........NNNNNN.NNNN..
                                //.SSSSS.......SCCC.....CCC..RRRR...RRRRR.EEEE........EEEE........NNNNNN.NNNN..
                                //..SSSSSSS....SCCC..........RRRRRRRRRRR..EEEEEEEEEE..EEEEEEEEEE..NNNNNNNNNNN..
                                //...SSSSSSSSS.SCCC..........RRRRRRRRRRR..EEEEEEEEEE..EEEEEEEEEE..NNNNNNNNNNN..
                                //.....SSSSSSS.SCCC..........RRRRRRRR.....EEEEEEEEEE..EEEEEEEEEE..NNNNNNNNNNN..
                                //........SSSSSSCCC.....CCC..RRRR.RRRR....EEEE........EEEE........NNNNNNNNNNN..
                                //.SSSS....SSSS.CCCC...CCCCC.RRRR..RRRR...EEEE........EEEE........NNNN.NNNNNN..
                                //.SSSSSSSSSSSS.CCCCCCCCCCC..RRRR..RRRRR..EEEEEEEEEEE.EEEEEEEEEEE.NNNN..NNNNN..
                                //..SSSSSSSSSS...CCCCCCCCCC..RRRR...RRRRR.EEEEEEEEEEE.EEEEEEEEEEE.NNNN..NNNNN..
                                //...SSSSSSSS.....CCCCCCC....RRRR....RRRR.EEEEEEEEEEE.EEEEEEEEEEE.NNNN...NNNN..
                                //.............................................................................     */}

                            <div id="label" className="bg-white w-72 border border-grey-mid rounded-lg mx-auto mt-4 mb-4 p-2 z-30 labelShadow">
                                <div className="mt-1.5" >

                                    <table className="w-full border border-black p-0 text-left letterSpacing ">
                                        <tbody>
                                            <tr>
                                                <td colSpan={2} className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.5rem', lineHeight: '1.1rem' }}>
                                                    Loc:
                                                </td>
                                                <td colSpan={3} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '0.8rem', lineHeight: '0.9rem' }}>
                                                    {projectLocation}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2} className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.6rem', lineHeight: '1.1rem' }}>
                                                    Proj:
                                                </td>
                                                <td colSpan={3} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.0rem', lineHeight: '1.3rem' }}>
                                                    {projectID}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2} className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.6rem', lineHeight: '1.1rem' }}>
                                                    ID:
                                                </td>
                                                <td colSpan={3} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.5rem', lineHeight: '1.6rem' }}>
                                                    {boreholeName}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2} className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.6rem', lineHeight: '1.1rem' }}>
                                                    Samp:
                                                </td>
                                                <td colSpan={3} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.5rem', lineHeight: '1.6rem' }}>
                                                    {sampleID}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2} className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.6rem', lineHeight: '1.1rem' }}>
                                                    Depth:
                                                </td>
                                                <td colSpan={3} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.0rem', lineHeight: '1.3rem' }}>
                                                    {sampleDepth}<span className="ml-0.5" style={{ fontSize: '1rem', lineHeight: '1.1rem' }}>m</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2} className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.6rem', lineHeight: '1.1rem' }}>
                                                    User:
                                                </td>
                                                <td className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '0.7rem', lineHeight: '0.8rem' }}>
                                                    {/* {loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name)} */}
                                                    {sampler}
                                                </td>
                                                <td className="border border-black pl-1 pt-0.5 font-bold text-left" style={{ width: '60px', fontSize: '0.7rem', lineHeight: '0.8rem' }}>
                                                    {sampleDate}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>


                                    <div className="barcodeHolder overflow-hidden">
                                        <Barcode value={labelbarcode} options={{
                                            format: 'code128',
                                            width: barcodeWidth,
                                            height: 14,
                                            fontSize: 11,
                                            // text: labelbarcode,
                                            background: 'none'
                                        }} />

                                    </div>
                                    <div className="mx-auto text-center -mt-0.5 font-bold" style={{ fontSize: '0.45rem' }}>PRODUCED BY DRILLIT LOGS - DRILLITLOGS.COM</div>
                                </div>
                            </div>
                            

                            <div key={defaultPrint}>
                                <NumberIncrement defaultNumber={defaultPrint} onChange={setPrintNumber} />
                            </div>

                            <div className="printCopiesDefault mt-2 mb-2">

                                <div className="grid grid-cols-7 h-8 w-60 mx-auto">
                                    <div className="col-span-6 pr-1 text-sm text-right pt-0.5">
                                        Save as default for {defaultLabelType} Labels
                                    </div>
                                    <div className="col-span-1 pl-1 items-start">
                                        <CustomCheckbox
                                            checked={saveDefault}
                                            onChange={toggleCheckbox}
                                        />
                                    </div>
                                </div>
                                
                            </div>

                            <Button onClick={handleGeneralLabel}
                                theme="primary" size="lg"
                                className="mt-0 mb-1 px-8 w-40 mx-auto" >
                                {printButtonText}
                            </Button>

                        </>
                    )}

                </div> {/* END PRINT LABELS HOLDER */}

            </>
        )

    } else {

//.......................................................................................................
//.TTTTTTTTTTT..OOOOOOO....OPPPPPPPPP....... LL...........AAAA.....ABBBBBBBBBB..BEEEEEEEEEE.EELL........
//.TTTTTTTTTTT.OOOOOOOOOO..OPPPPPPPPPP...... LL..........AAAAAA....ABBBBBBBBBBB.BEEEEEEEEEE.EELL........
//.TTTTTTTTTTTOOOOOOOOOOOO.OPPPPPPPPPPP..... LL..........AAAAAA....ABBBBBBBBBBB.BEEEEEEEEEE.EELL........
//....TTTT...TOOOO...OOOOO.OPPP....PPPP..... LL..........AAAAAAA...ABBB....BBBB.BEEE........EELL........
//....TTTT...TOOO.....OOOOOOPPP....PPPP..... LL.........AAAAAAAA...ABBB....BBBB.BEEE........EELL........
//....TTTT...TOOO......OOOOOPPPPPPPPPPP..... LL.........AAAAAAAA...ABBBBBBBBBBB.BEEEEEEEEEE.EELL........
//....TTTT...TOOO......OOOOOPPPPPPPPPP...... LL.........AAAA.AAAA..ABBBBBBBBBB..BEEEEEEEEEE.EELL........
//....TTTT...TOOO......OOOOOPPPPPPPPP....... LL........AAAAAAAAAA..ABBBBBBBBBBB.BEEEEEEEEEE.EELL........
//....TTTT...TOOO.....OOOOOOPPP............. LL........AAAAAAAAAAA.ABBB.....BBBBBEEE........EELL........
//....TTTT...TOOOOO..OOOOO.OPPP............. LL.......LAAAAAAAAAAA.ABBB.....BBBBBEEE........EELL........
//....TTTT....OOOOOOOOOOOO.OPPP............. LLLLLLLLLLAAA....AAAA.ABBBBBBBBBBBBBEEEEEEEEEEEEELLLLLLLL..
//....TTTT.....OOOOOOOOOO..OPPP............. LLLLLLLLLLAAA....AAAAAABBBBBBBBBBB.BEEEEEEEEEEEEELLLLLLLL..
//....TTTT......OOOOOOO....OPPP............. LLLLLLLLLLAAA.....AAAAABBBBBBBBBB..BEEEEEEEEEEEEELLLLLLLL..
//.......................................................................................................


        return ( // TOPIMAGE LABEL PRINT (U CORE LINER) //////////////////////////

            <>

                <div className="text-center w-full flex-grow border border-grey-mid rounded px-2 mb-2 bg-sptTB pb-4">  {/* START PRINT LABELS HOLDER */}

                    <div className="text-xl text-center w-full mt-2">Print Label for {sampleID}?</div>

                    <div onClick ={() => {setShowPrinterSetup(true)}} className="w-10 ml-auto -mt-6 -mb-2 text-center">
                        <div><img className="mx-auto" src={systemIcon} width="32" alt="" /></div>
                        {/* <div className="text-xs">Settings</div> */}
                    </div>

                    {printerSelect === 'Print Manager' && (

                        <>

                        {/* //................................................................................................................................
                            //.PPPPPPPPP...PRRRRRRRRR..RRIII.INNN...NNNN..NTTTTTTTTTT.... MMMM...MMMMMM....AAAAA.....NNNN...NNNN.....AAAAA........GGGGGGG....
                            //.PPPPPPPPPP..PRRRRRRRRRR.RRIII.INNNN..NNNN..NTTTTTTTTTT.... MMMM...MMMMMM....AAAAA.....NNNNN..NNNN.....AAAAA......GGGGGGGGGG...
                            //.PPPPPPPPPPP.PRRRRRRRRRR.RRIII.INNNN..NNNN..NTTTTTTTTTT.... MMMM...MMMMMM...AAAAAA.....NNNNN..NNNN....AAAAAA.....GGGGGGGGGGGG..
                            //.PPPP...PPPP.PRRR...RRRRRRRIII.INNNNN.NNNN.....TTTT........ MMMMM.MMMMMMM...AAAAAAA....NNNNNN.NNNN....AAAAAAA....GGGGG..GGGGG..
                            //.PPPP...PPPP.PRRR...RRRRRRRIII.INNNNN.NNNN.....TTTT........ MMMMM.MMMMMMM..AAAAAAAA....NNNNNN.NNNN...AAAAAAAA...AGGGG....GGG...
                            //.PPPPPPPPPPP.PRRRRRRRRRR.RRIII.INNNNNNNNNN.....TTTT........ MMMMM.MMMMMMM..AAAAAAAA....NNNNNNNNNNN...AAAAAAAA...AGGG...........
                            //.PPPPPPPPPP..PRRRRRRRRRR.RRIII.INNNNNNNNNN.....TTTT........ MMMMMMMMMMMMM..AAAA.AAAA...NNNNNNNNNNN...AAAA.AAAA..AGGG..GGGGGGG..
                            //.PPPPPPPPP...PRRRRRRR....RRIII.INNNNNNNNNN.....TTTT........ MMMMMMMMMMMMM.AAAAAAAAAA...NNNNNNNNNNN..AAAAAAAAAA..AGGG..GGGGGGG..
                            //.PPPP........PRRR.RRRR...RRIII.INNNNNNNNNN.....TTTT........ MMMMMMMMMMMMM.AAAAAAAAAAA..NNNNNNNNNNN..AAAAAAAAAAA.AGGGG.GGGGGGG..
                            //.PPPP........PRRR..RRRR..RRIII.INNN.NNNNNN.....TTTT........ MM.MMMMM.MMMM.AAAAAAAAAAA..NNNN.NNNNNN..AAAAAAAAAAA..GGGGG....GGG..
                            //.PPPP........PRRR..RRRRR.RRIII.INNN..NNNNN.....TTTT........ MM.MMMMM.MMMMMAAA....AAAA..NNNN..NNNNN.NAAA....AAAA..GGGGGGGGGGGG..
                            //.PPPP........PRRR...RRRRRRRIII.INNN..NNNNN.....TTTT........ MM.MMMMM.MMMMMAAA.....AAAA.NNNN..NNNNN.NAAA.....AAAA..GGGGGGGGGG...
                            //.PPPP........PRRR....RRRRRRIII.INNN...NNNN.....TTTT........ MM.MMMMM.MMMMMAAA.....AAAA.NNNN...NNNNNNAAA.....AAAA....GGGGGGG....
                            //................................................................................................................................ */}

                            {/* START LABEL */}

                            {/* //........................................................
                                //.PPPPPPPPP...PRRRRRRRRR..RRIII.INNN...NNNN..NTTTTTTTTT..
                                //.PPPPPPPPPP..PRRRRRRRRRR.RRIII.INNNN..NNNN..NTTTTTTTTT..
                                //.PPPPPPPPPPP.PRRRRRRRRRR.RRIII.INNNN..NNNN..NTTTTTTTTT..
                                //.PPPP...PPPP.PRRR...RRRRRRRIII.INNNNN.NNNN.....TTTT.....
                                //.PPPP...PPPP.PRRR...RRRRRRRIII.INNNNN.NNNN.....TTTT.....
                                //.PPPPPPPPPPP.PRRRRRRRRRR.RRIII.INNNNNNNNNN.....TTTT.....
                                //.PPPPPPPPPP..PRRRRRRRRRR.RRIII.INNNNNNNNNN.....TTTT.....
                                //.PPPPPPPPP...PRRRRRRR....RRIII.INNNNNNNNNN.....TTTT.....
                                //.PPPP........PRRR.RRRR...RRIII.INNNNNNNNNN.....TTTT.....
                                //.PPPP........PRRR..RRRR..RRIII.INNN.NNNNNN.....TTTT.....
                                //.PPPP........PRRR..RRRRR.RRIII.INNN..NNNNN.....TTTT.....
                                //.PPPP........PRRR...RRRRRRRIII.INNN..NNNNN.....TTTT.....
                                //.PPPP........PRRR....RRRRRRIII.INNN...NNNN.....TTTT.....
                                //........................................................ */}

                            <div className="flex hidden">

                                <div id="label" className="flex printLabel labelArea border border-grey-mid rounded-lg mx-auto mt-4 mb-4 pt-4 z-30">
                                    <div className="labelBody mt-1.5" ref={printingArea_top} >

                                        <div className="w-full printArea">

                                            <table className="w-full border border-black p-0 text-left letterSpacing">
                                                <tbody>
                                                    <tr>
                                                        <td rowSpan={6} className="border border-black bg-black text-right pr-1 pt-0.5 font-bold" style={{ width: '25px' }}>
                                                            <img className='topImg' src={TopImage} alt="TOP" />
                                                        </td>
                                                        <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                            Loc:
                                                        </td>
                                                        <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1rem', lineHeight: '1.3rem' }}>
                                                            {projectLocation}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                            Project:
                                                        </td>
                                                        <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.7rem', lineHeight: '2.0rem' }}>
                                                            {projectID}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                            ID:
                                                        </td>
                                                        <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold letterSpacingExtreme" style={{ fontSize: '2.4rem', lineHeight: '2.6rem' }}>
                                                            {boreholeName}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                            Type:
                                                        </td>
                                                        <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold letterSpacingExtreme" style={{ fontSize: '2.4rem', lineHeight: '2.6rem' }}>
                                                            {sampleID}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                            Depth:
                                                        </td>
                                                        <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold letterSpacingExtreme" style={{ fontSize: '1.6rem', lineHeight: '1.9rem' }}>
                                                            {sampleDepth}<span className="ml-0.5" style={{ fontSize: '1rem', lineHeight: '1.1rem' }}>m</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                            User:
                                                        </td>
                                                        <td className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '0.9rem', lineHeight: '1.2rem' }}>
                                                            {/* {loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name)} */}
                                                            {sampler}
                                                        </td>
                                                        <td className="border border-black pl-1 pt-0.5 font-bold" style={{ width: '80px', fontSize: '0.9rem', lineHeight: '1.2rem' }}>
                                                            {sampleDate}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <div className="barcodeHolder">
                                                <Barcode value={labelbarcode} options={{
                                                    format: 'code128',
                                                    width: barcodeWidth,
                                                    height: 20,
                                                    fontSize: 14,
                                                    // text: labelbarcode,
                                                    background: 'none'
                                                }} />

                                            </div>

                                            <div className="mx-auto text-center mt-1 font-bold" style={{ fontSize: '0.65rem' }}>PRODUCED BY DRILLIT LOGS - DRILLITLOGS.COM</div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/* END LABEL */}

                            {/* //.............................................................................
                                //...SSSSSSS......CCCCCCC....RRRRRRRRRR...EEEEEEEEEEE.EEEEEEEEEEE.NNNN...NNNN..
                                //..SSSSSSSSS....CCCCCCCCC...RRRRRRRRRRR..EEEEEEEEEEE.EEEEEEEEEEE.NNNNN..NNNN..
                                //..SSSSSSSSSS..CCCCCCCCCCC..RRRRRRRRRRR..EEEEEEEEEEE.EEEEEEEEEEE.NNNNN..NNNN..
                                //.SSSSS..SSSS..CCCC...CCCCC.RRRR...RRRRR.EEEE........EEEE........NNNNNN.NNNN..
                                //.SSSSS.......SCCC.....CCC..RRRR...RRRRR.EEEE........EEEE........NNNNNN.NNNN..
                                //..SSSSSSS....SCCC..........RRRRRRRRRRR..EEEEEEEEEE..EEEEEEEEEE..NNNNNNNNNNN..
                                //...SSSSSSSSS.SCCC..........RRRRRRRRRRR..EEEEEEEEEE..EEEEEEEEEE..NNNNNNNNNNN..
                                //.....SSSSSSS.SCCC..........RRRRRRRR.....EEEEEEEEEE..EEEEEEEEEE..NNNNNNNNNNN..
                                //........SSSSSSCCC.....CCC..RRRR.RRRR....EEEE........EEEE........NNNNNNNNNNN..
                                //.SSSS....SSSS.CCCC...CCCCC.RRRR..RRRR...EEEE........EEEE........NNNN.NNNNNN..
                                //.SSSSSSSSSSSS.CCCCCCCCCCC..RRRR..RRRRR..EEEEEEEEEEE.EEEEEEEEEEE.NNNN..NNNNN..
                                //..SSSSSSSSSS...CCCCCCCCCC..RRRR...RRRRR.EEEEEEEEEEE.EEEEEEEEEEE.NNNN..NNNNN..
                                //...SSSSSSSS.....CCCCCCC....RRRR....RRRR.EEEEEEEEEEE.EEEEEEEEEEE.NNNN...NNNN..
                                //.............................................................................     */}

                            <div id="label" className="bg-white w-72 border border-grey-mid rounded-lg mx-auto mt-4 mb-4 p-2 z-30 labelShadow">
                                <div className="mt-1.5" >

                                    <table className="w-full border border-black p-0 text-left letterSpacing ">
                                        <tbody>
                                            <tr>
                                                <td rowSpan={6} className="border border-black bg-black text-right pr-1 pt-0.5 font-bold" style={{ width: '18px', height: '100px' }}>
                                                    <img className='' src={TopImage} alt="TOP" />
                                                </td>
                                                <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.5rem', lineHeight: '1.1rem' }}>
                                                    Loc:
                                                </td>
                                                <td colSpan={3} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '0.8rem', lineHeight: '0.9rem' }}>
                                                    {projectLocation}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.6rem', lineHeight: '1.1rem' }}>
                                                    Proj:
                                                </td>
                                                <td colSpan={3} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.0rem', lineHeight: '1.3rem' }}>
                                                    {projectID}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.6rem', lineHeight: '1.1rem' }}>
                                                    ID:
                                                </td>
                                                <td colSpan={3} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.5rem', lineHeight: '1.6rem' }}>
                                                    {boreholeName}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.6rem', lineHeight: '1.1rem' }}>
                                                    Samp:
                                                </td>
                                                <td colSpan={3} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.5rem', lineHeight: '1.6rem' }}>
                                                    {sampleID}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.6rem', lineHeight: '1.1rem' }}>
                                                    Depth:
                                                </td>
                                                <td colSpan={3} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.0rem', lineHeight: '1.3rem' }}>
                                                    {sampleDepth}<span className="ml-0.5" style={{ fontSize: '1rem', lineHeight: '1.1rem' }}>m</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.6rem', lineHeight: '1.1rem' }}>
                                                    User:
                                                </td>
                                                <td className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '0.7rem', lineHeight: '0.8rem' }}>
                                                    {/* {loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name)} */}
                                                    {sampler}
                                                </td>
                                                <td className="border border-black pl-1 pt-0.5 font-bold text-left" style={{ width: '60px', fontSize: '0.7rem', lineHeight: '0.8rem' }}>
                                                    {sampleDate}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>


                                    <div className="barcodeHolder overflow-hidden">
                                        <Barcode value={labelbarcode} options={{
                                            format: 'code128',
                                            width: barcodeWidth,
                                            height: 14,
                                            fontSize: 11,
                                            // text: labelbarcode,
                                            background: 'none'
                                        }} />

                                    </div>
                                    <div className="mx-auto text-center -mt-0.5 font-bold" style={{ fontSize: '0.45rem' }}>PRODUCED BY DRILLIT LOGS - DRILLITLOGS.COM</div>
                                </div>
                            </div>

                            <ReactToPrint
                                trigger={() => <Button theme="primary" size="lg" className="mt-4 px-8 w-40 mx-auto" >{printButtonText}</Button>}
                                content={() => printingArea_top.current}
                            />

                        </>

                    )}

                    {printerSelect === 'Toshiba' && (

                        <>

                        {/* //..................................................................................
                            //.TTTTTTTTTTT..OOOOOOO......SSSSSSS...SSHH....HHHH.HHII.IIBBBBBBBBB......AAAA......
                            //.TTTTTTTTTTT.OOOOOOOOOO...SSSSSSSSS..SSHH....HHHH.HHII.IIBBBBBBBBBB....AAAAAA.....
                            //.TTTTTTTTTTTOOOOOOOOOOOO.OSSSSSSSSSS.SSHH....HHHH.HHII.IIBBBBBBBBBB....AAAAAA.....
                            //....TTTT...TOOOO...OOOOO.OSSS...SSSS.SSHH....HHHH.HHII.IIBB....BBBB....AAAAAAA....
                            //....TTTT...TOOO.....OOOOOOSSSS.......SSHH....HHHH.HHII.IIBB....BBBB...AAAAAAAA....
                            //....TTTT...TOOO......OOOO.SSSSSSS....SSHHHHHHHHHH.HHII.IIBBBBBBBBBB...AAAAAAAA....
                            //....TTTT...TOOO......OOOO..SSSSSSSS..SSHHHHHHHHHH.HHII.IIBBBBBBBBB....AAAA.AAAA...
                            //....TTTT...TOOO......OOOO....SSSSSSS.SSHHHHHHHHHH.HHII.IIBBBBBBBBBB..BAAAAAAAAA...
                            //....TTTT...TOOO.....OOOOO.......SSSSSSSHH....HHHH.HHII.IIBB.....BBBB.BAAAAAAAAAA..
                            //....TTTT...TOOOOO..OOOOO.OSSS...SSSSSSSHH....HHHH.HHII.IIBB.....BBBBBBAAAAAAAAAA..
                            //....TTTT....OOOOOOOOOOOO.OSSSSSSSSSS.SSHH....HHHH.HHII.IIBBBBBBBBBBBBBAA....AAAA..
                            //....TTTT.....OOOOOOOOOO...SSSSSSSSSS.SSHH....HHHH.HHII.IIBBBBBBBBBB.BBAA....AAAA..
                            //....TTTT......OOOOOOO......SSSSSSS...SSHH....HHHH.HHII.IIBBBBBBBBB.BBBAA.....AAA..
                            //.................................................................................. */}

                            {/* START LABEL */}

                            {/* //........................................................
                                //.PPPPPPPPP...PRRRRRRRRR..RRIII.INNN...NNNN..NTTTTTTTTT..
                                //.PPPPPPPPPP..PRRRRRRRRRR.RRIII.INNNN..NNNN..NTTTTTTTTT..
                                //.PPPPPPPPPPP.PRRRRRRRRRR.RRIII.INNNN..NNNN..NTTTTTTTTT..
                                //.PPPP...PPPP.PRRR...RRRRRRRIII.INNNNN.NNNN.....TTTT.....
                                //.PPPP...PPPP.PRRR...RRRRRRRIII.INNNNN.NNNN.....TTTT.....
                                //.PPPPPPPPPPP.PRRRRRRRRRR.RRIII.INNNNNNNNNN.....TTTT.....
                                //.PPPPPPPPPP..PRRRRRRRRRR.RRIII.INNNNNNNNNN.....TTTT.....
                                //.PPPPPPPPP...PRRRRRRR....RRIII.INNNNNNNNNN.....TTTT.....
                                //.PPPP........PRRR.RRRR...RRIII.INNNNNNNNNN.....TTTT.....
                                //.PPPP........PRRR..RRRR..RRIII.INNN.NNNNNN.....TTTT.....
                                //.PPPP........PRRR..RRRRR.RRIII.INNN..NNNNN.....TTTT.....
                                //.PPPP........PRRR...RRRRRRRIII.INNN..NNNNN.....TTTT.....
                                //.PPPP........PRRR....RRRRRRIII.INNN...NNNN.....TTTT.....
                                //........................................................ */}

                            <div className="flex hidden">

                                <div id="label" className="flex printLabel labelArea border border-grey-mid rounded-lg mx-auto mt-4 mb-4 pt-4 z-30">
                                    <div className="labelBody mt-1.5" ref={printingArea_top} >

                                        <div className="w-full printArea">

                                            <table className="w-full border border-black p-0 text-left letterSpacing">
                                                <tbody>
                                                    <tr>
                                                        <td rowSpan={6} className="border border-black bg-black text-right pr-1 pt-0.5 font-bold" style={{ width: '25px' }}>
                                                            <img className='topImg' src={TopImage} alt="TOP" />
                                                        </td>
                                                        <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                            Loc:
                                                        </td>
                                                        <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1rem', lineHeight: '1.3rem' }}>
                                                            {projectLocation}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                            Project:
                                                        </td>
                                                        <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.7rem', lineHeight: '2.0rem' }}>
                                                            {projectID}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                            ID:
                                                        </td>
                                                        <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold letterSpacingExtreme" style={{ fontSize: '2.4rem', lineHeight: '2.6rem' }}>
                                                            {boreholeName}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                            Type:
                                                        </td>
                                                        <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold letterSpacingExtreme" style={{ fontSize: '2.4rem', lineHeight: '2.6rem' }}>
                                                            {sampleID}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                            Depth:
                                                        </td>
                                                        <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold letterSpacingExtreme" style={{ fontSize: '1.6rem', lineHeight: '1.9rem' }}>
                                                            {sampleDepth}<span className="ml-0.5" style={{ fontSize: '1rem', lineHeight: '1.1rem' }}>m</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                            User:
                                                        </td>
                                                        <td className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '0.9rem', lineHeight: '1.2rem' }}>
                                                            {/* {loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name)} */}
                                                            {sampler}
                                                        </td>
                                                        <td className="border border-black pl-1 pt-0.5 font-bold" style={{ width: '80px', fontSize: '0.9rem', lineHeight: '1.2rem' }}>
                                                            {sampleDate}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <div className="barcodeHolder">
                                                <Barcode value={labelbarcode} options={{
                                                    format: 'code128',
                                                    width: barcodeWidth,
                                                    height: 20,
                                                    fontSize: 14,
                                                    // text: labelbarcode,
                                                    background: 'none'
                                                }} />

                                            </div>

                                            <div className="mx-auto text-center mt-1 font-bold" style={{ fontSize: '0.65rem' }}>PRODUCED BY DRILLIT LOGS - DRILLITLOGS.COM</div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/* //.............................................................................
                                //...SSSSSSS......CCCCCCC....RRRRRRRRRR...EEEEEEEEEEE.EEEEEEEEEEE.NNNN...NNNN..
                                //..SSSSSSSSS....CCCCCCCCC...RRRRRRRRRRR..EEEEEEEEEEE.EEEEEEEEEEE.NNNNN..NNNN..
                                //..SSSSSSSSSS..CCCCCCCCCCC..RRRRRRRRRRR..EEEEEEEEEEE.EEEEEEEEEEE.NNNNN..NNNN..
                                //.SSSSS..SSSS..CCCC...CCCCC.RRRR...RRRRR.EEEE........EEEE........NNNNNN.NNNN..
                                //.SSSSS.......SCCC.....CCC..RRRR...RRRRR.EEEE........EEEE........NNNNNN.NNNN..
                                //..SSSSSSS....SCCC..........RRRRRRRRRRR..EEEEEEEEEE..EEEEEEEEEE..NNNNNNNNNNN..
                                //...SSSSSSSSS.SCCC..........RRRRRRRRRRR..EEEEEEEEEE..EEEEEEEEEE..NNNNNNNNNNN..
                                //.....SSSSSSS.SCCC..........RRRRRRRR.....EEEEEEEEEE..EEEEEEEEEE..NNNNNNNNNNN..
                                //........SSSSSSCCC.....CCC..RRRR.RRRR....EEEE........EEEE........NNNNNNNNNNN..
                                //.SSSS....SSSS.CCCC...CCCCC.RRRR..RRRR...EEEE........EEEE........NNNN.NNNNNN..
                                //.SSSSSSSSSSSS.CCCCCCCCCCC..RRRR..RRRRR..EEEEEEEEEEE.EEEEEEEEEEE.NNNN..NNNNN..
                                //..SSSSSSSSSS...CCCCCCCCCC..RRRR...RRRRR.EEEEEEEEEEE.EEEEEEEEEEE.NNNN..NNNNN..
                                //...SSSSSSSS.....CCCCCCC....RRRR....RRRR.EEEEEEEEEEE.EEEEEEEEEEE.NNNN...NNNN..
                                //.............................................................................     */}

                            <div id="label" className="bg-white w-72 border border-grey-mid rounded-lg mx-auto mt-4 mb-4 p-2 z-30 labelShadow">
                                <div className="mt-1.5" >

                                    <table className="w-full border border-black p-0 text-left letterSpacing ">
                                        <tbody>
                                            <tr>
                                                <td rowSpan={6} className="border border-black bg-black text-right pr-1 pt-0.5 font-bold" style={{ width: '18px', height: '100px' }}>
                                                    <img className='' src={TopImage} alt="TOP" />
                                                </td>
                                                <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.5rem', lineHeight: '1.1rem' }}>
                                                    Loc:
                                                </td>
                                                <td colSpan={3} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '0.8rem', lineHeight: '0.9rem' }}>
                                                    {projectLocation}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.6rem', lineHeight: '1.1rem' }}>
                                                    Proj:
                                                </td>
                                                <td colSpan={3} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.0rem', lineHeight: '1.3rem' }}>
                                                    {projectID}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.6rem', lineHeight: '1.1rem' }}>
                                                    ID:
                                                </td>
                                                <td colSpan={3} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.5rem', lineHeight: '1.6rem' }}>
                                                    {boreholeName}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.6rem', lineHeight: '1.1rem' }}>
                                                    Samp:
                                                </td>
                                                <td colSpan={3} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.5rem', lineHeight: '1.6rem' }}>
                                                    {sampleID}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.6rem', lineHeight: '1.1rem' }}>
                                                    Depth:
                                                </td>
                                                <td colSpan={3} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.0rem', lineHeight: '1.3rem' }}>
                                                    {sampleDepth}<span className="ml-0.5" style={{ fontSize: '1rem', lineHeight: '1.1rem' }}>m</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '35px', fontSize: '0.6rem', lineHeight: '1.1rem' }}>
                                                    User:
                                                </td>
                                                <td className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '0.7rem', lineHeight: '0.8rem' }}>
                                                    {/* {loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name)} */}
                                                    {sampler}
                                                </td>
                                                <td className="border border-black pl-1 pt-0.5 font-bold text-left" style={{ width: '60px', fontSize: '0.7rem', lineHeight: '0.8rem' }}>
                                                    {sampleDate}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>


                                    <div className="barcodeHolder overflow-hidden">
                                        <Barcode value={labelbarcode} options={{
                                            format: 'code128',
                                            width: barcodeWidth,
                                            height: 14,
                                            fontSize: 11,
                                            // text: labelbarcode,
                                            background: 'none'
                                        }} />

                                    </div>
                                    <div className="mx-auto text-center -mt-0.5 font-bold" style={{ fontSize: '0.45rem' }}>PRODUCED BY DRILLIT LOGS - DRILLITLOGS.COM</div>
                                </div>
                            </div>
                            

                            <div key={defaultPrint}>
                                <NumberIncrement defaultNumber={defaultPrint} onChange={setPrintNumber} />
                            </div>

                            <div className="printCopiesDefault mt-2 mb-2">

                                <div className="grid grid-cols-7 h-8 w-64 mx-auto">
                                    <div className="col-span-6 pr-1 text-sm text-right pt-0.5">
                                        Save as default for {defaultLabelType} Labels
                                    </div>
                                    <div className="col-span-1 pl-1 items-start">
                                        <CustomCheckbox
                                            checked={saveDefault}
                                            onChange={toggleCheckbox}
                                        />
                                    </div>
                                </div>
                                
                            </div>

                            <Button onClick={handleGeneralLabel}
                                theme="primary" size="lg"
                                className="mt-0 mb-1 px-8 w-40 mx-auto" >
                                {printButtonText}
                            </Button>

                        </>

                    )}
                </div> {/* END PRINT LABELS HOLDER */}

                {showPrinterSetup && (
                    <PrinterSetup onClose={() => { 
                        setShowPrinterSetup(false)
                        reloadPrintSettings()
                    }}/>
                )}

            </>
        )


    } // END OF PRINT LABELS
}

export default Label;