import React, { FunctionComponent } from 'react'

import Button from './Button'

type Props = {
	className?: string,
	canExit?: boolean,
	onExit?: (value: any) => void,
	onBack?: (value: any) => void,
	onContinue?: (value: any) => void;
	[x: string]: any;
}

const defaultProps: Props = {
	className: '',
    canExit: true,
	onExit: (value) => {},
	onBack: (value) => {},
	onContinue: (value) => {},
}

const WorkflowActions: FunctionComponent<Props> = ({ canExit, onExit, onBack, onContinue, className, button_text, ...rest }) => {

    const save_button_text = button_text

	return (
		<div className={`flex ${className}`}>
			{/* {canExit && <Button type="submit" theme="primary" size="lg" onClick={onExit}>Save &amp; Exit</Button>} */}

			<div className="flex m-auto">
				{/* <Button theme="primary" size="lg" onClick={onBack}>Back</Button> */}
				<Button theme="success" type="submit" size="lg" className="w-72" onClick={onContinue}>{save_button_text}</Button>
			</div>
		</div>
	)
}

WorkflowActions.defaultProps = defaultProps

export default WorkflowActions
