import { get, set } from 'idb-keyval';

export const updateProject = (
    guid: string, 
    company_guid: string, 
    company_id: number,
    project_identifier: string, 
    location: string, 
    start_date: string, 
    end_date: string, 
    start_time: string, 
    address: string, 
    address2: string, 
    town_city: string, 
    county_state: string, 
    postcode: string, 
    site_description: string, 
    key_site_features: string, 
    site_history: string, 
    key_hands: string, 
    expected_strata: string, 
    notes: string, 
    equipment_required: string, 
    engineer_name: string, 
    engineer_phone: string, 
    engineer_email: string, 
    client_name: string, 
    client_contact: string,
    client_phone: string,
    client_email: string, 
    parked: number, 
): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        const endpoint = `${process.env.REACT_APP_API_ADDR}projects/update/${guid}`;

        // make a date string
        const updateData = {
            'guid': guid, 
            'company_guid': company_guid, 
            'project_identifier': project_identifier, 
            'location': location, 
            'start_date': start_date, 
            'end_date': end_date, 
            'start_time': start_time, 
            'address': address, 
            'address2': address2, 
            'town_city': town_city, 
            'county_state': county_state, 
            'postcode': postcode, 
            'site_description': site_description, 
            'key_site_features': key_site_features, 
            'site_history': site_history, 
            'key_hands': key_hands, 
            'expected_strata': expected_strata, 
            'notes': notes, 
            'equipment_required': equipment_required, 
            'engineer_name': engineer_name, 
            'engineer_phone': engineer_phone, 
            'engineer_email': engineer_email, 
            'client_name': client_name, 
            'client_contact': client_contact,
            'client_phone': client_phone,
            'client_email': client_email, 
            'parked': parked, 
        };

        console.log('Project updateData:', updateData);

        makeRequest(endpoint, JSON.stringify(updateData), 'PUT')
            .then(response => {
                if (response.ok) {
                    console.log('Hole information updated successfully.');
                    updateProjectIDB(guid, updateData);
                    updateCompanyProjectIDB(guid, company_id, updateData); // Update project Company
                    resolve(true);
                } else {
                    console.error('Failed to update hole information.');
                    updateProjectIDB(guid, updateData); // create IDB -update key
                    updateCompanyProjectIDB(guid, company_id, updateData); // Update project Company
                    resolve(false);
                }
            })
            .catch(err => {
                console.error('Error updating hole information:', err);
                updateProjectIDB(guid, updateData); // create IDB -update key
                updateCompanyProjectIDB(guid, company_id, updateData); // Update project Company
                resolve(false);
            });
    });
};

const makeRequest = (endpoint: string, data: string, postOrPut: string): Promise<Response> => {
    const token = localStorage.getItem('drillit-token');

    return fetch(endpoint, {
        method: postOrPut,
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: data
    });
};

const updateProjectIDB = (guid: string, updateData: any) => {
    const idbKey = `drillit-projects/${guid}`;

    get(idbKey)
        .then(existingRecord => {
            if (existingRecord) {
                const updatedRecord = { ...existingRecord, ...updateData };
                return set(idbKey, updatedRecord);
            } else {
                console.error(`Project with GUID ${guid} not found in IndexedDB.`);
            }
        })
        .then(() => {
            console.log(`Updated Project for ${guid} in IndexedDB.`);
        })
        .catch(err => {
            console.error(`Error updating Project in IndexedDB for ${guid}:`, err);
        });
};

const updateCompanyProjectIDB = (guid: string, company_id: number, updateData: any) => {
    const idbKey = `drillit-projects/comapny/${company_id}`;

    get(idbKey)
        .then(existingProject => {
            if (existingProject && Array.isArray(existingProject)) {
                const updatedProject = existingProject.map(project => {
                    if (project.guid === guid) {
                        return { ...project, ...updateData };
                    }
                    return project;
                });

                return set(idbKey, updatedProject);
            } else {
                console.error(`Project record with GUID ${guid} not found in IndexedDB.`);
            }
        })
        .then(() => {
            console.log(`Updated Project Company for ${guid} in IndexedDB.`);
        })
        .catch(err => {
            console.error(`Error updating Project Company in IndexedDB for ${guid}:`, err);
        });
};
