import React from 'react'

import {useGetIDBValues} from "../../helpers/getIDBKey";
import {get,set} from "idb-keyval";

interface Props {
    company: any,
    project: any,
}

interface State {
	rigs: any,
	rigsSelect: any,
	currentRigValue: any,
	currentRigLabel: any,
}

class Rigs extends React.Component<Props, State> {
	constructor(props: any) {
		super(props)

		this.state = {
			rigs: [],
			rigsSelect: [],
			currentRigValue: '',
			currentRigLabel: '',
		}
	}

	componentDidMount() {
		// check for a current rig for this project
		this.getCacheValues();
	}

	getCacheValues = () => {

		let cachedValues = useGetIDBValues

		cachedValues('rigs/company/'+ this.props.company, (data:any) => {
			this.setState({
				rigs: data
			})
			console.log('ALL RIGS:', this.state.rigs)
			let selectValues: any = []
			if (data) {

				for (const rig of data) {
					let {guid, name} = rig
					const newValue = {
						value: guid,
						label: name
					}
					selectValues.push(newValue)
				}

			}
			this.setState({
				rigsSelect: selectValues
			})

		}).then()

		// get the current rig if it's set
		// console.log('this.props.project: ', this.props.project)
		get(`drillit-currentRig-${this.props?.project}`)
			.then(data => {
				if (data) {

					this.setState({
						currentRigValue: data.guid,
						currentRigLabel: data.label,
					})

				}
		})
	}

	onCurrentRigSave = () => {
		const cR = {
			guid: this.state.currentRigValue,
			label: this.state.currentRigLabel,
		}
		return set(`drillit-currentRig-${this.props?.project}`, cR).then(() => {
			// Additional actions after rig has been saved
			console.log('Rig saved successfully');
			// Perform any other actions or state updates here
		  })
		  .catch((error) => {
			console.error('Error saving rig:', error);
			// Handle the error if needed
		  });
	}

	// Automatically save when the currentRigValue state changes
	componentDidUpdate(prevState: any) {
	  if (prevState.currentRigValue !== this.state.currentRigValue) {
		this.onCurrentRigSave();
	  }
	}

	render() {

		if (!this.state.currentRigValue){
		  // Render loading state while data is being fetched
        return <div className="flex items-center justify-center h-screen">  
					<span className="pt-24 text-sm">LOADING</span>
				</div>
		}

		return (
			<>
            <div className="justify-items">
				<div className="inline">
				<select
				id="currentRig"
				className="form-control text-base md:text-xl w-full border-none pl-0"
				value={this.state.currentRigValue}
				onChange={(e) => {
					this.setState({
					currentRigValue: e.target.value,
					currentRigLabel: e.target.options[e.target.selectedIndex].textContent,
					});
					this.onCurrentRigSave(); // Call the function when an option is selected
				}}
				>
				<option value="null">Select Current Rig</option>
				{this.state.rigsSelect?.map((value: any, index: string) => {
					return <option value={value.value} key={index}>{value.label}</option>;
				})}
				</select>
                </div>
            </div>
			
            </>
		)
	}
}

export default Rigs
