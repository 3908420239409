import { get, set } from 'idb-keyval';

export const updateHoleInformation = (
    hole_guid: string, 
    borehole_name: string,
    leadDriller: string,
    leadDrillerID: any,
    leadDrillerCSCS: string,
    secondMan: string,
    secondManCSCS: string,
    plantGuid: string,
    plantName: string,
    plantMakeModel: string,
    sptGUID: string,
    hole_barcode: string,
    borehole_depthOverall: string,
    activity_termination_reason: string,
    project_guid: string,  // Added project_guid
    status: string,
    numberingType: string
): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        const endpoint = `${process.env.REACT_APP_API_ADDR}hole-locations/updateInformation/${hole_guid}`;

        // make a date string
        const updateData = {
            'hole_name': borehole_name,
            'location_identifier': borehole_name,
            'driller': leadDriller,
            'driller_id': leadDrillerID,
            'driller_cscs': leadDrillerCSCS,
            'second_man': secondMan,
            'second_man_cscs': secondManCSCS,
            'rig_id': plantGuid,
            'rig_name': plantName,
            'make_model': plantMakeModel,
            'spt_id': sptGUID,
            'hole_barcode': hole_barcode,
            'final_hole_depth': borehole_depthOverall,
            'activity_termination_reason': activity_termination_reason,
            'status': status,
            'numberingType': numberingType,
        };

        console.log('updateData:', updateData);

        makeRequest(endpoint, JSON.stringify(updateData), 'PUT')
            .then(response => {
                if (response.ok) {
                    console.log('Hole information updated successfully.');
                    updateHoleInIDB(hole_guid, updateData);
                    updateHoleInProjectIDB(project_guid, hole_guid, updateData); // Update project
                    resolve(true);
                } else {
                    console.error('Failed to update hole information.');
                    saveUpdateToIDB(hole_guid, updateData); // create IDB -update key
                    updateHoleInIDB(hole_guid, updateData); // update hole
                    updateHoleInProjectIDB(project_guid, hole_guid, updateData); // Update project
                    resolve(false);
                }
            })
            .catch(err => {
                console.error('Error updating hole information:', err);
                saveUpdateToIDB(hole_guid, updateData); // create IDB -update key
                updateHoleInIDB(hole_guid, updateData); // update hole
                updateHoleInProjectIDB(project_guid, hole_guid, updateData); // Update project
                resolve(false);
            });
    });
};

const makeRequest = (endpoint: string, data: string, postOrPut: string): Promise<Response> => {
    const token = localStorage.getItem('drillit-token');

    return fetch(endpoint, {
        method: postOrPut,
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: data
    });
};

const saveUpdateToIDB = (hole_guid: string, updateData: any) => {
    const idbKey = `drillit-hole-locations/updateInformation/${hole_guid}-update`;

    get(idbKey)
        .then(existingRecord => {
            if (existingRecord) {
                // If a record already exists, merge the new data with the existing data
                const updatedRecord = { ...existingRecord, ...updateData };
                return set(idbKey, updatedRecord);
            } else {
                // If no existing record, create a new one
                return set(idbKey, updateData);
            }
        })
        .then(() => {
            console.log(`Saved or updated hole information for ${hole_guid} in IndexedDB.`);
        })
        .catch(err => {
            console.error(`Error saving hole information to IndexedDB for ${hole_guid}:`, err);
        });
};

const updateHoleInIDB = (hole_guid: string, updateData: any) => {
    const idbKey = `drillit-hole-locations/${hole_guid}`;

    get(idbKey)
        .then(existingRecord => {
            if (existingRecord) {
                const updatedRecord = { ...existingRecord, ...updateData };
                return set(idbKey, updatedRecord);
            } else {
                console.error(`Hole record with GUID ${hole_guid} not found in IndexedDB.`);
            }
        })
        .then(() => {
            console.log(`Updated hole information for ${hole_guid} in IndexedDB.`);
        })
        .catch(err => {
            console.error(`Error updating hole information in IndexedDB for ${hole_guid}:`, err);
        });
};

const updateHoleInProjectIDB = (project_guid: string, hole_guid: string, updateData: any) => {
    const idbKey = `drillit-hole-locations/project/${project_guid}`;

    get(idbKey)
        .then(existingProject => {
            if (existingProject && Array.isArray(existingProject)) {
                const updatedProject = existingProject.map(hole => {
                    if (hole.guid === hole_guid) {
                        return { ...hole, ...updateData };
                    }
                    return hole;
                });

                return set(idbKey, updatedProject);
            } else {
                console.error(`Project record with GUID ${project_guid} not found in IndexedDB.`);
            }
        })
        .then(() => {
            console.log(`Updated hole information in project for ${project_guid} in IndexedDB.`);
        })
        .catch(err => {
            console.error(`Error updating project information in IndexedDB for ${project_guid}:`, err);
        });
};
