import React, { FunctionComponent, useState } from 'react'

import WorkflowSteps from '../../WorkflowSteps'
import Modal from '../../Modal'
import Button from '../../form/Button'
import NumberInput from '../../NumberInput'
import ButtonOptions from '../../form/ButtonOptions'
import { v4 as uuidv4 } from 'uuid';
import stepStatus from '../../../helpers/stepStatus'
// import TimeInput from "../../TimeInput";
import DurationInput from "../../DurationInput"
// import MultiNumberInput from '../../MultiNumberInput'

import PrintLabel from '../../../components/PrintLabel'
// import { updateSelectedProject } from '../../../actions/SelectedProject'
import { useSelector } from 'react-redux'
// import { useGetIDBValues } from "../../../helpers/getIDBKey"
import { useGetLoggedInUser } from '../../../helpers/getLoggedInUser'
import warningIcon from '../../../assets/icons/warning.png'

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: string,
	projectId?: string,
	defaultValues?: any,
	selectedDate?: string,
	update?: any,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => { },
	onComplete: () => { },
	borehole: '',
	projectId: '',
	defaultValues: {
		guid: '',
		depth_to_top: '',
		depth_to_base: '',
		core_diameter: '',
		// time_taken: '00:00:00',
		duration: '00:00',
		core_recovery: '',
		hole_diameter: '',
		flush_return_min: '',
		flush_composition: '',
	},
	selectedDate: '',
}

const CoreSamples: FunctionComponent<Props> = ({ onClose, update, onComplete, borehole, defaultValues, sampled_by,
	selectedDate, current_core_diameter, current_hole_diameter, setShowCoreSamplesEdit, new_type_number, holeDepth,
	 ...rest }) => {
	const [currentStep, setCurrentStep] = useState(1)
	const date = useSelector((state: any) => state.date)
	var labelDate = date.toLocaleDateString("en-UK")

	const [guid, setGuid] = useState(defaultValues.guid)
	const [depth_to_top, setDepthFrom] = useState(defaultValues.depth_to_top)
	const [depth_to_base, setDepthTo] = useState(defaultValues.depth_to_base)
	const [core_diameter, setCoreDiameter] = useState(defaultValues.core_diameter)
	const [time_taken, setDuration] = useState(defaultValues.time_taken)
	// const [duration, setDuration] = useState(defaultValues.duration)
	const [core_recovery, setRecovery] = useState(defaultValues.core_recovery)
	const [hole_diameter, setHoleDiameter] = useState(defaultValues.hole_diameter)
	const [flush_return_min, setFlush] = useState(defaultValues.flush_return_min)
	const [flush_composition, setFlushComposition] = useState(defaultValues.type_of_flush)
	// const [time_of_action, setTimeOfAction] = useState(defaultValues.time_of_action)
	const time_of_action = defaultValues.time_of_action
	const [sampler, setSampler] = useState(sampled_by)

	const [saveButtonText, setButtonText] = useState('Continue')
	const [saveButtonColor, setButtonColor] = useState('primary')

	const loggedInUser = useGetLoggedInUser()

	const selectedProject = useSelector((state: any) => state.selectedProject)
	// const dispatch = useDispatch()

	// const updateEmptyProject = () => {
	// 	let cachedValues = useGetIDBValues
	// 	cachedValues('projects', (data:any) => {
	// 		for (const project of data) {
	// 			if (project.guid === projectId) {
	// 				dispatch(updateSelectedProject({
	// 					guid: project.guid,
	// 					name: project.name,
	// 					location: project.location,
	// 					number: project.project_identifier,
	// 				}))
	// 			}
	// 		}
	// 	}).then()
	// }

	// if(selectedProject.guid === '')
	// 	updateEmptyProject()

	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	const DeleteButton = () => {
		if (!update) return null;

		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	// const deleteRecordConfirm = () => {
	// 	setDeleteConfirmOpen(false)
	// 	// onComplete()
	// }

	const isoDate = selectedDate ? `${new Date(selectedDate).toISOString().split('T')[0]} 09:00:00` : new Date().toISOString().split('.')[0]

	const previous = () => {
		if (currentStep > 1)
			setCurrentStep(currentStep - 1)
		setButtonText('Continue')
		setButtonColor('primary')
	}

	const next = () => {
		if (currentStep < 8) {
			setCurrentStep(currentStep + 1)
			if (currentStep === 7) {
				setButtonText('Save')
				setButtonColor('success')
			} else {
				setButtonText('Continue')
				setButtonColor('primary')
			}
			return
		}

		if (!depth_to_top ) {
			//do nothing
		} else {

			onComplete({
				guid: guid ? guid : uuidv4(),
				core_number: type_number_output,
				depth_to_top: depth_to_top2DP, //2DP,
				depth_to_base: depth_to_base2DP, //2DP,
				core_diameter: String(parseFloat(core_diameter)), // parseFloat(core_diameter), //2DP,
				time_taken: time_taken_temp,
				core_recovery: parseFloat(core_recovery).toFixed(2), //2DP,
				hole_diameter: String(parseFloat(hole_diameter0DP)),
				type_of_flush: flush_composition,
				flush_return_min: flush_return_min0DP,
				general_remarks: '',
				time_of_action: time_of_action ? time_of_action : isoDate,
				update: update ? true : false
			})
		}
	}

	let time_taken_temp = ''
	if(update){
		time_taken_temp = time_taken + ':00'
	} else {
		time_taken_temp = time_taken + ':00'
	}

	const MissingRequiredField = () => {
		if (currentStep === 8 && !depth_to_top ) return (
			<div className="text-center bg-sptSB border border-red font-bold rounded mb-2 py-1 text-xs md:text-sm lg:text-base">
				<img src={warningIcon} alt="info" width="20" className="inline-block mr-1 pb-1" />
				Required field: 'Depth From'
			</div>
		)
	
		return null
	}
	var depth_to_base2DP = ''
	if (depth_to_base === '') {
		depth_to_base2DP = ''
	} else {
		depth_to_base2DP = parseFloat(depth_to_base).toFixed(2)
	}
	var core_diameter2DP = ''
	if (core_diameter === '') {
		core_diameter2DP = ''
	} else {
		core_diameter2DP = parseFloat(core_diameter).toFixed(1)
	}
	var core_recovery2DP = ''
	if (core_recovery === '') {
		core_recovery2DP = ''
	} else {
		core_recovery2DP = parseFloat(core_recovery).toFixed(2)
	}
	var hole_diameter0DP = ''
	if (hole_diameter === '') {
		hole_diameter0DP = ''
	} else {
		hole_diameter0DP = parseFloat(hole_diameter).toFixed(1)
	}
	var flush_return_min0DP = ''
	if (flush_return_min === '') {
		flush_return_min0DP = ''
	} else {
		flush_return_min0DP = parseFloat(flush_return_min).toFixed(0)
	}

	let depth_to_top2DP = parseFloat(depth_to_top).toFixed(2)
	let depth_to_base_calc = 0
	let depth_to_base_calc2DP = ''
	let depth_to_preset = '1.50'
	let core_recovery_preset = '1.50'
	let depth_to_label = ''
	if (!depth_to_top) {
		depth_to_base_calc = 0
	} else {
		if (depth_to_top === 'GL' || depth_to_top === 'G' || depth_to_top === 0 || depth_to_top === '0' || depth_to_top === 0.00 || depth_to_top === '0.00') { // console.log('GL')
			depth_to_top2DP = '0'
			depth_to_preset = '1.50'
			depth_to_base_calc = 1.50
			depth_to_base_calc2DP = (Math.round(depth_to_base_calc * 100) / 100).toFixed(2) // depth to calcs
			depth_to_label = 'GL'
		} else {
			depth_to_top2DP = parseFloat(depth_to_top).toFixed(2)
			depth_to_base_calc = +depth_to_top + 1.50
			depth_to_base_calc2DP = (Math.round(depth_to_base_calc * 100) / 100).toFixed(2) // depth to calcs
			depth_to_preset = depth_to_base_calc2DP
			depth_to_label = depth_to_top2DP
		}
	}

	console.log('holeDepth:', holeDepth)
	let depth_from_preset_label = ''
	let depth_from_preset_value = ''
	if (!holeDepth || holeDepth === '0.00') {
		depth_from_preset_label = 'GL'
		depth_from_preset_value = '0'
	} else {
		depth_from_preset_label = holeDepth
		depth_from_preset_value = holeDepth
	}

	let core_diameter_preset = current_core_diameter

	let hole_diameter_preset = current_hole_diameter


	// if(depth_to_top === ''){
	// 	var depth_to_top2DP = ''
	// 	var depth_to_base_calc = ''
	// } else {
	// 	var depth_to_top2DP = parseFloat(depth_to_top).toFixed(2)
	// 	var depth_to_base_calc = (parseFloat(depth_to_top) +1.50).toFixed(2)
	// }

	var printDepth = depth_to_label + '-' + depth_to_base

	var sampleID = ''
	var type_number_output = 0
	if (update) {
		// console.log('update?:', update, defaultValues.type_number)
		sampleID = "C-" + defaultValues.core_number
		type_number_output = defaultValues.core_number
	} else {
		sampleID = "C-" + new_type_number
		type_number_output = new_type_number
	}

	// console.log('new_type_number:', new_type_number)

	return (
		<Modal title="Core Samples" subtitle={borehole} toggleModal={onClose}>
			<div className="flex flex-col flex-grow px-1 sm:px-2 md:px-10">
				<WorkflowSteps steps={[
					{
						label: 'Depth From',
						status: stepStatus(1, currentStep),
						onClick: () => {
							setCurrentStep(1)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Depth To',
						status: stepStatus(2, currentStep),
						onClick: () => {
							setCurrentStep(2)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Core Dia',
						status: stepStatus(3, currentStep),
						onClick: () => {
							setCurrentStep(3)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Duration',
						status: stepStatus(4, currentStep),
						onClick: () => {
							setCurrentStep(4)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Recovery',
						status: stepStatus(5, currentStep),
						onClick: () => {
							setCurrentStep(5)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Hole Dia',
						status: stepStatus(6, currentStep),
						onClick: () => {
							setCurrentStep(6)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Flush',
						status: stepStatus(7, currentStep),
						onClick: () => {
							setCurrentStep(7)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Label',
						status: stepStatus(8, currentStep),
						onClick: () => {
							setCurrentStep(8)
							setButtonText('Save')
							setButtonColor('success')
						}
					},
				]} className="mb-2" />

				<div className="flex-1 flex">
					{currentStep === 1 &&
						<NumberInput onUpdate={(value: any) => setDepthFrom(value)}
							title="Enter Depth From (m)"
							defaultValue={depth_to_top}
							className="mb-10 text-2xl"
							presets={[
								{ value: depth_from_preset_value, label: depth_from_preset_label },
							]}
							next={next}
						/>

						// <MultiNumberInput values={
						// 	[
						// 		{defaultValue: depth_to_top , onUpdate: setDepthFrom, label: 'Enter Depth From (m)'},
						// 		{defaultValue: depth_to_base , onUpdate: setDepthTo, label: 'Enter Depth To (m)'},
						// 	]
						// } 
						// className="mb-2 text-base sm:text-xl md:text-xl" 
						// inputCSS="text-4xl sm:text-4xl md:text-4xl lg:text-4xl h-16 sm:h-16" 
						// />
					}

					{currentStep === 2 &&
						<NumberInput onUpdate={(value: any) => setDepthTo(value)}
							title="Enter Depth To (m)"
							defaultValue={depth_to_base}
							className="mb-10 text-2xl"
							presets={[
								{ value: depth_to_preset, label: depth_to_preset },
							]}
							next={next}
						/>
					}

					{currentStep === 3 &&
						<NumberInput onUpdate={(value: any) => setCoreDiameter(value)}
							title="Enter Core Diameter (mm)"
							defaultValue={core_diameter}
							className="mb-10 text-2xl"
							presets={[
								{ value: core_diameter_preset, label: core_diameter_preset }
							]}
							next={next}
						/>
					}

					{currentStep === 4 &&
						// <TimeInput onUpdate={(value: any) => setDuration(value)} 
						<DurationInput onUpdate={(value: any) => setDuration(value)}
							title="Enter Time Taken (hrs)"
							// defaultValue={time_taken} 
							defaultValue={time_taken}
							className="mb-10" />
					}

					{currentStep === 5 &&
						<NumberInput onUpdate={(value: any) => setRecovery(value)}
							title="Enter Core Recovery (m)"
							defaultValue={core_recovery}
							className="mb-10 text-2xl"
							presets={[
								{ value: core_recovery_preset, label: core_recovery_preset },
							]}
							next={next}
						/>
					}

					{currentStep === 6 &&
						<NumberInput onUpdate={(value: any) => setHoleDiameter(value)}
							title="Enter Hole Diameter (mm)"
							defaultValue={hole_diameter}
							className="mb-10 text-2xl"
							presets={[
								{ value: hole_diameter_preset, label: hole_diameter_preset },
							]}
							next={next}
						/>
					}

					{currentStep === 7 &&
						<>

							<NumberInput onUpdate={(value: any) => setFlush(value)}
								title="Enter Flush Return (%)"
								defaultValue={flush_return_min}
								className="mb-2 text-2xl"
								presets={[
									{ value: '100', label: '100%' },
									// {value: '75', label: '75%'},
									{ value: '50', label: '50%' },
								]}
								aboveInput={
									<>
										<div className="border border-grey-mid rounded px-2 bg-sptSB mb-3 text-center">
											<div className="text-center">
												Flush Composition:
											</div>
											<ButtonOptions onUpdate={(value) => setFlushComposition(value)}
												defaultValue={flush_composition}
												columns={5}
												columnsMobile={3}
												className="mb-2 w-20 text-lg"
												buttonHeight={12}
												buttonHeightMobile={12}
												options={
													['water', 'air', 'air mist', 'mud', 'polymer']
												} />
										</div>
									</>
								} />
						</>
					}

					{currentStep === 8 &&

						<PrintLabel
							location={selectedProject.location}
							project={selectedProject.number}
							borehole={borehole}
							sampledate={labelDate}
							// user={loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name)}
							sampled_by={sampler}
							sample={sampleID}
							depth={printDepth}
							size='printLabel'
						/>

					}

				</div>

				<MissingRequiredField />


				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">


							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?

								<div className="flex mt-4">
									<Button theme="secondary" 
										className="mr-5 text-sm sm:text-base px-3.5 md:px-7" 
										onClick={() => setDeleteConfirmOpen(false)}>
											Cancel
									</Button>

									<Button onClick={() => {
												onClose()
												onComplete({
													guid: guid,
													update: false,
													delete: true,
												})
												setShowCoreSamplesEdit(false)
											}}
										className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">
										Delete
									</Button>
								</div>
							</div>


						</div>
					</div>
				}

				<div className="flex">
					{/* <Button theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button> */}
					<DeleteButton />

					<div className="flex ml-auto">
						<Button theme="primary" size="lg" className="px-4 sm:px-10" onClick={() => previous()}>Back</Button>
						<Button theme={saveButtonColor}
							size="lg"
							className="ml-4 px-8 sm:px-10 w-32 sm:w-60"
							onClick={() => next()}>{saveButtonText}
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

CoreSamples.defaultProps = defaultProps

export default CoreSamples
