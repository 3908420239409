import React from 'react'

import Button from '../components/form/Button'
import InputGroup from '../components/form/InputGroup'
import Heading from '../components/Heading'
import ProgressBar from '../components/ProgressBar'
import SelectGroup from "../components/form/SelectGroup"

import Header from '../layout/Header'
import Container from '../layout/Container'
import {useGetLoggedInUser} from "../helpers/getLoggedInUser";
import {get} from "idb-keyval";

interface Props {
    toggleSidebar: () => void
}

interface State {
    companyGuid: string,
    companyName: string,
    contactEmail: string,
    contactTelephone: string,
    contactName: string,
    noLicences: string,
    companyAddress1: string,
    companyAddress2: string,
    companyAddress3: string,
    companyCounty: string,
    companyCountry: string,
    companyPostcode: string,
}


class Company extends React.Component<Props, State> {
    constructor(props: any) {
        super(props)

        this.state = {
            companyGuid: '',
            companyName: '',
            contactEmail: '',
            contactTelephone: '',
            contactName: '',
            noLicences: '',
            companyAddress1: '',
            companyAddress2: '',
            companyAddress3: '',
            companyCounty: '',
            companyCountry: '',
            companyPostcode: '',
        }
    }

    handleSubmit = (event: any) => {
        event.preventDefault()

    }

    componentDidMount() {
        this.getCacheValues()
    }


    getCacheValues = () => {
        // get the user from cache
        const getIDB: any = async () => {
            const response = await get('drillit-user')
            // console.log(response.user.company)
            this.setState({
                companyGuid: response.user.company.companyGuid,
                companyName: response.user.company.name,
                contactEmail: response.user.company.email,
                contactTelephone: response.user.company.phone,
                companyAddress1: response.user.company.address1,
                companyAddress2: response.user.company.address2,
                companyAddress3: response.user.company.address3,
                companyCounty: response.user.company.county,
                companyCountry: response.user.company.country,
                companyPostcode: response.user.company.postcode,
            })
        }
        getIDB()

    }

    render() {

        return (
            <>
                <Header toggleSidebar={this.props.toggleSidebar} />

                <Container className="mt-4">
                    <Heading left={[this.state.companyName]} className="mb-8" />

                    <form onSubmit={this.handleSubmit}>
                        <div className="grid md:grid-cols-2 gap-x-10">
                            <div className="col-span-2 lg:col-span-1">
                                <InputGroup value={this.state.companyName} label="Company Name" onChange={(value) => this.setState({companyName: value})} className="mb-1" />

                                <InputGroup value={this.state.contactEmail} label="Contact Email" onChange={(value) => this.setState({contactEmail: value})} className="mb-1" />

                                <InputGroup value={this.state.contactTelephone} label="Contact Telephone" onChange={(value) => this.setState({contactTelephone: value})} className="mb-1" />

                                <InputGroup value={this.state.contactName} label="Contact Name" onChange={(value) => this.setState({contactName: value})} className="mb-1" />

                                <SelectGroup selected={this.state.noLicences} label="Number of Licences" values={[
                                    {
                                        value: '10',
                                        label: '10',
                                    },
                                    {
                                        value: '25',
                                        label: '25',
                                    },
                                    {
                                        value: '50',
                                        label: '50',
                                    },
                                    {
                                        value: '75',
                                        label: '75',
                                    },
                                    {
                                        value: '100',
                                        label: '100',
                                    }

                                ]} onChange={(value) => this.setState({noLicences: value})} />

                                <div className="flex flex-col mb-10">
                                	<div className="">Licenses</div>

                                    <ProgressBar value={50} max={100} />

                                    <p>**note** Removed the save buttons below so company info can't be changed directly from the app, done from admin side instead. May also want to remove licences from this screen? Depends on who you want to see this screen. Derek</p>
                                </div>


                            </div>


                            <div className="col-span-2 lg:col-span-1">
                                <InputGroup value={this.state.companyAddress1} label="Address1" onChange={(value) => this.setState({companyAddress1: value})} className="mb-1" />
                                <InputGroup value={this.state.companyAddress2} label="Address2" onChange={(value) => this.setState({companyAddress2: value})} className="mb-1" />
                                <InputGroup value={this.state.companyAddress3} label="Address3" onChange={(value) => this.setState({companyAddress3: value})} className="mb-1" />
                                <InputGroup value={this.state.companyCounty} label="County" onChange={(value) => this.setState({companyCounty: value})} className="mb-1" />
                                <InputGroup value={this.state.companyCountry} label="Country" onChange={(value) => this.setState({companyCountry: value})} className="mb-1" />
                                <InputGroup value={this.state.companyPostcode} label="Postcode" onChange={(value) => this.setState({companyPostcode: value})} className="mb-1" />


                            </div>
                           

                        </div>
                    </form>

                </Container>
            </>
        )
    }
}

export default Company
