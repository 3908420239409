import React, { FunctionComponent, useState, useEffect } from 'react'

// import WorkflowSteps from '../../WorkflowSteps'
import Modal from '../../Modal'
import Button from '../../form/Button'
import SelectGroup from '../../form/SelectGroup'
// import ExclusiveSelect from './soil-description/ExclusiveSelect'
import NumberInputBackfill from '../../NumberInputBackfill'
// import MultiNumberInput from '../../MultiNumberInput'

import { v4 as uuidv4 } from 'uuid';

// import stepStatus from '../../../helpers/stepStatus'
// import Checkbox from "../../form/Checkbox";
// import Comment from './soil-description/Comment'
import edit from '../../../assets/icons/edit.svg'
import plusIcon from '../../../assets/icons/plus-white.svg'

import BackfillLayers from './BackfillLayers'
// import getBackfillSelections from './BackfillLayers';

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: string,
	defaultValues?: any,
	selectedDate?: string,
	[x: string]: any;
	update?: boolean,
	// holeDepth: number,
}

const defaultProps: Props = {
	onClose: () => { },
	onComplete: () => { },
	borehole: '',
	defaultValues: {
		layers: [],
		comment: '',
		saveButtonText: 'Continue',
		backfillTextOutput: '',
	},
	selectedDate: '',
}

const Backfill: FunctionComponent<Props> = ({ onClose, update, onComplete, borehole, defaultValues, selectedDate, 
	holeDepth, setShowBackfillEdit, pipe1solid, pipe1slotted, pipe2solid, pipe2slotted,  ...rest }) => {
	
	// console.log('PIPES in Backfill:', pipe1solid, pipe1slotted, pipe2solid, pipe2slotted)

    // console.log('defaultValues.layers:', defaultValues.layers)
	
	// const [currentStep, setCurrentStep] = useState(1)

	// const [comment, setComment] = useState(defaultValues.comment)

	// const [saveButtonText, setButtonText] = useState('Continue')
	// const [saveButtonColor, setButtonColor] = useState('primary')
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	const [hole_depth, setHoleDepth] = useState(holeDepth)
	// const hole_depth = holeDepth

	const guid = defaultValues.guid

	const DeleteButton = () => {
		if (!update) return null;

		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	// const deleteRecordConfirm = () => {
	// 	setDeleteConfirmOpen(false)
	// 	// onComplete()
	// }

	// const [time_of_action, setTimeOfAction] = useState(defaultValues.time_of_action)
	const time_of_action = defaultValues.time_of_action

	const isoDate = selectedDate ? `${new Date(selectedDate).toISOString().split('T')[0]} 09:00:00` : new Date().toISOString().split('.')[0]


    
    const save = () => {

		const calculatedBackfillText = calculateBackfillTextOutput(backfillLayers)

			onComplete({
				guid: update ? guid : uuidv4(),
                layers: flatToMultiDimensional(backfillLayers,10), // flatToMultiDimensional(backfillLayers,10), // backfillLayers, // layers
				general_remarks: '',
				backfill_identifier: borehole,
				backfillText: calculatedBackfillText,
				time_of_action: time_of_action ? time_of_action : isoDate,
				update: update ? true : false
			})
	}

	// convert array into multidimensional array
	function flatToMultiDimensional(arr: any, subarrayLength: number) {
		const result = [];
		for (let i = 0; i < arr.length; i += subarrayLength) {
			result.push(arr.slice(i, i + subarrayLength));
		}
		return result;
	}

	

	// Define an interface for the layer structure
	interface Layer {
		backfillType: string;
		depth: string;
		depth_from: string;
		// Add more properties if needed
	}

	const [backfillLayers, setBackfillLayers] = useState([]);

	// Use useEffect to update backfillLayers when defaultValues.layers changes
	useEffect(() => {
	  // Check if defaultValues.layers has values
	  if (defaultValues.layers && defaultValues.layers.length > 0) {
		// Check if the array is multidimensional
		if (Array.isArray(defaultValues.layers[0])) {
		  // Flatten the multidimensional array
		  const flatLayers = defaultValues.layers.flat();
		  
		  // Convert depth and depth_from to numbers
		  const numericLayers = flatLayers.map((layer: Layer) => ({
			...layer,
			depth: parseFloat(layer.depth),
			depth_from: parseFloat(layer.depth_from),
		  }));
		  
		  setBackfillLayers(numericLayers);
		} else {
		  // Convert depth and depth_from to numbers
		  const numericLayers = defaultValues.layers.map((layer: Layer) => ({
			...layer,
			depth: parseFloat(layer.depth),
			depth_from: parseFloat(layer.depth_from),
		  }));
		  
		  setBackfillLayers(numericLayers);
		}
	  } else {
		// Set backfillLayers as an empty array if defaultValues.layers is empty
		setBackfillLayers([]);
	  }
	}, [defaultValues.layers]);

	const handleBackfillLayersUpdate = (selections:any) => {
		setBackfillLayers(selections);
	};

    console.log('backfillLayers from MAIN Backfill module:', backfillLayers)
	
	// Update the function to use the Layer interface
	const calculateBackfillTextOutput = (layers: Layer[][]) => {
		const flattenedLayers = layers.flat(); // Flatten the multidimensional array
	
		const validLayers = flattenedLayers.filter(
			(layer) => layer.backfillType !== '' && layer.backfillType !== null && layer.depth !== '0.00'
		);
	
		const backfillTextOutput = validLayers
		.map((layer) => `${layer.backfillType} (${layer.depth}m)`)
		.join(', ');
	
		return 'Backfill (top to bottom): ' + backfillTextOutput;
	};

	/////////////////////////////////

	const [layers, setLayers] = useState(defaultValues.layers)

    console.log('layers array:', layers)

	const emptyLayer = {
		'guid': uuidv4(),
		'material': '',
		'depth': '',
	}

	const [layerFormOpen, setLayerFormOpen] = useState(false)

	const [editingLayerIndex, setEditingLayerIndex] = useState(-1)
	const [editingLayer, setEditingLayer] = useState(emptyLayer)

	const editLayer = (index: number) => {
		setEditingLayerIndex(index)
		setEditingLayer(layers[index])
		setLayerFormOpen(true)
	}

	const addLayer = () => {
		setEditingLayerIndex(-1)
		setEditingLayer(emptyLayer)
		setLayerFormOpen(true)
	}

	const saveForm = (event: any) => {
		event.preventDefault()

		// If adding
		if (editingLayerIndex === -1) {
			setLayers([...layers, editingLayer])
		} else {
			let newLayers = [...layers]
			newLayers[editingLayerIndex] = editingLayer
			setLayers(newLayers)
		}

		setLayerFormOpen(false)
		setEditingLayer(emptyLayer)
	}
    console.log('editingLayerIndex:', editingLayerIndex)

    const deleteLayer = () => {
        if (editingLayerIndex !== -1) {
          const newLayers = [...layers];
          newLayers.splice(editingLayerIndex, 1);
          setLayers(newLayers);
          setEditingLayerIndex(-1);
          setEditingLayer(emptyLayer);
          setLayerFormOpen(false);
        }
      }

      const resetFormFields = () => {
        setEditingLayer(emptyLayer);
        // Reset any other form fields here
      };

      const cancelLayer = () => {
        resetFormFields();
        setLayerFormOpen(false);
        setEditingLayerIndex(-1);
    }
      
      
   
      
      
      

	//console.info({layers})
	// let hole_depth = 10.45
	//hole_depth = Math.round(hole_depth*100)/100
	let backfillDepth = 0
	let backfillText = ''

	for (let index = 0; index < layers.length; index++) {
		backfillDepth += parseFloat(layers[index].depth)
		backfillText += (layers[index].material) + " (" + (layers[index].depth) + "m), " // OUTPUT TO LOGSHEET
	}

	let backfillDepth2DP = backfillDepth.toFixed(2)
	let remainingDepth = hole_depth - backfillDepth
	let remainingDepth2DP = parseFloat((Math.round(remainingDepth * 100) / 100).toFixed(2))
	let remainingDepthString = '' + remainingDepth2DP

    // console.log('backfillText:', backfillText)

	const [view, setView] = useState('experimental')

	// const toggleView = () => {
	//   setView((prevView) => (prevView === 'traditional' ? 'experimental' : 'traditional'));
	// };

	const backfill_layer_types = [
		{ value: 'Asphalt', label: 'Asphalt' },
		{ value: 'Paving Slab', label: 'Paving Slab' },
		{ value: 'Brick Weave', label: 'Brick Weave' },
		{ value: 'Postmix', label: 'Postmix' },
		{ value: 'Concrete', label: 'Concrete' },
		{ value: 'Bentonite Grout', label: 'Bentonite Grout' },
		{ value: 'Bentonite Granules', label: 'Bentonite Granules' },
		{ value: 'Bentonite Pellets', label: 'Bentonite Pellets' },
		{ value: 'Bentonite Concrete', label: 'Bentonite Concrete' },
		{ value: 'Sand', label: 'Sand' },
		{ value: 'Gravel', label: 'Gravel' },
		{ value: 'Ballast', label: 'Ballast' },
		{ value: 'Recycled Glass', label: 'Recycled Glass' },
		{ value: 'Void', label: 'Void' },
		{ value: 'Arisings/Spoil', label: 'Arisings/Spoil' },
	  ];

	return (
		<Modal title="Backfill" subtitle={borehole} toggleModal={onClose}>

			<div className="flex flex-col flex-grow px-1 sm:px-2 md:px-10">

				{view === 'traditional' && (

					<div className="flex-1 flex">
					
					{/* Content for the 'experimental' view */}

							<div className="border border-grey-mid rounded px-2 bg-sptTB mb-4 text-center w-full lg:w-3/5 bg-sptTB mx-auto">

								<div className="grid grid-cols-2 gap-2 w-full sm:w-3/5 lg:w-3/5 mx-auto">

									<div className="font-bold text-sm mt-2 bg-white rounded">
										<div className="font-bold text-sm">
											Hole Depth:
										</div>
										<div className="font-bold text-xl -mt-1">
											{hole_depth}m
										</div>
									</div>

									<div className="font-bold text-sm mt-2 bg-white rounded">
										<div className="font-bold text-sm">
											Backfill Depth:
										</div>
										<div className="font-bold text-xl -mt-1">
											{parseInt(backfillDepth2DP).toFixed(2)}m*
										</div>
									</div>

									{/* <div className="font-bold text-xl -mt-1">
										{hole_depth}m
									</div>

									<div className="font-bold text-xl -mt-1">
										{backfillDepth2DP}m
									</div> */}

								</div>

								<div className={`text-sm rounded-t overflow-hidden w-full md:w-4/5 lg:w-3/5 mx-auto`}>

									<div className="w-full flex justify-center mb-1">
										<Button theme="primary"
											className="mx-auto mb-2 mt-2"
											icon={plusIcon} onClick={() => addLayer()}>Add Layer
										</Button>
									</div>

									<div className={`px-0 py-0 border-r border-grey-light text-xs text-center`}>(from top to bottom)</div>
									<div className={`px-0 py-0 border-r border-grey-light text-xs text-center`}>{backfillText}</div>

									<table className="table-auto w-full sm:w-3/5 md:w-full lg:w-full mx-auto">
										<thead className="font-normal">
											<tr>
												<td className={`p-0 bg-grey-mid border border-grey-mid rounded-tl-sm`}>
													<div className={`px-1 pt-1 border-r border-grey-light font-bold`}>Backfill Material</div>
												</td>

												<td className={`p-0 bg-grey-mid border border-grey-mid font-bold`}>
													<div className={`px-1 pt-1`}>Depth (m)</div>
												</td>

												<td className={`p-0 bg-grey-mid border border-grey-mid`}>
													<div className={`px-1 py-0 border-r border-grey-light`}></div>
												</td>
											</tr>
											<tr className="h-2"></tr>
										</thead>

										<tbody>
											{layers.map((layer: any, index: number) => {
												console.log('layers:', layers)
												return <tr className="border border-grey-mid bg-white mb-1" key={index}>
													<td className="p-0 border-r border-grey-mid">
														<div className={`px-0.5 py-0 text-base`}>{layer['material']}</div>
													</td>

													<td className="p-0 border-r border-grey-mid">
														<div className={`px-1 py-0 text-base`}>{layer['depth']}</div>
													</td>

													<td className="p-0 border-r border-grey-mid w-8 md:w-12">
														<div className={`px-1 py-0`}>
															<img className={`inline`} src={edit} width="20" alt="" onClick={() => editLayer(index)} />
														</div>
													</td>
												</tr>
											})}
											<tr className="h-2"></tr>
										</tbody>
									</table>

								</div>

								{layerFormOpen &&
									<form onSubmit={saveForm}>
										{/* <div className="border border-grey-mid py-2 px-3 flex items-center"> */}
										<div className="w-full sm:w-3/5 md:w-4/5 lg:w-full bg-sptTB mx-auto">

											{/* <div className="font-bold text-lg border border-orange text-charcoal mb-2 w-full sm:w-3/5 md:w-2/5 mx-auto">
											Click for remaining {remainingDepth}m
										</div> */}

											<NumberInputBackfill onUpdate={(value: any) => setEditingLayer({ ...editingLayer, 'depth': value })}
												title=""
												defaultValue={editingLayer['depth']}
												remainingDepth={remainingDepth2DP}
												className="mb-8"
												presets={[
													{ value: remainingDepthString, label: remainingDepthString + 'm' },
												]}
											/>

											<div className="py-2 px-0 flex -mt-64 md:-mt-96 lg:-mt-64 ml-0 lg:ml-32">
												<SelectGroup values={[
													{ value: 'Asphalt', label: 'Asphalt' },
													{ value: 'Paving Slab', label: 'Paving Slab' },
													{ value: 'Brick Weave', label: 'Brick Weave' },
													{ value: 'Postmix', label: 'Postmix' },
													{ value: 'Concrete', label: 'Concrete' },
													{ value: 'Bentonite Grout', label: 'Bentonite Grout' },
													{ value: 'Bentonite Granules', label: 'Bentonite Granules' },
													{ value: 'Bentonite Pellets', label: 'Bentonite Pellets' },
													{ value: 'Bentonite Concrete', label: 'Bentonite Concrete' },
													{ value: 'Sand', label: 'Sand' },
													{ value: 'Gravel', label: 'Gravel' },
													{ value: 'Ballast', label: 'Ballast' },
													{ value: 'Recycled Glass', label: 'Recycled Glass' },
													{ value: 'Arisings/Spoil', label: 'Arisings/Spoil' },
													{ value: 'Void', label: 'Void' },
												]} onChange={(value) => setEditingLayer({ ...editingLayer, 'material': value.value })}
													label=""
													selected={editingLayer['material']}
													className="md:mb-0 w-40 md:w-52 text-base md:text-lg h-4 " />

												
											</div>

											<div className="flex justify-center mt-48 md:mt-96 lg:mt-48 mb-4">
											{editingLayerIndex !== -1 && (
												<div className="flex">
													<Button size="xs" onClick={deleteLayer} theme="secondary" className="p-2 md:p-6 lg:py-2 mt-2">Delete</Button>
												</div>
											)}
												<div className="flex ml-4">
													<Button size="xs" onClick={cancelLayer} theme="primary" className="w-24 p-2 md:p-6 lg:py-2 mt-2">Cancel</Button>
												</div>

												<div className="flex ml-2">
													<Button size="xs" theme="success" type="submit" className="w-32 p-2 md:p-6 lg:py-2 mt-2">Save</Button>
												</div>

											</div>

										</div>
									</form>
								}

							</div>

					</div>
		
				)}

				{view === 'experimental' && (
				<div className="flex-grow border border-grey-mid rounded px-1 md:px-4 bg-sptTB w-full md:w-11/12 justify-center mx-auto mb-4 pt-0">
					{/* Content for the 'experimental' view */}

					<BackfillLayers 
					holeDepth={holeDepth} 
					backfill_types={backfill_layer_types} 
					onUpdateLayers={handleBackfillLayersUpdate}
					backfillLayers={backfillLayers}
					pipe1solid={pipe1solid}
					pipe1slotted={pipe1slotted}
					pipe2solid={pipe2solid}
					pipe2slotted={pipe2slotted}
					/>

				</div>
				)}

				{/* <button onClick={toggleView}>Toggle View</button> */}


				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">


							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?

								<div className="flex mt-4">
									<Button theme="secondary" 
										className="mr-5 text-sm sm:text-base px-3.5 md:px-7" 
										onClick={() => setDeleteConfirmOpen(false)}>
											Cancel
									</Button>

									<Button onClick={() => {
												onClose()
												onComplete({
													guid: guid,
													update: false,
													delete: true,
												})
												setShowBackfillEdit(false)
											}}
										className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">
										Delete
									</Button>
								</div>
							</div>


						</div>
					</div>
				}

				<div className="flex mt-auto">
					{/* <Button theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button> */}
					<DeleteButton />

					<div className="flex ml-auto">
						<Button theme="success" size="lg"
							className="ml-4 px-8 sm:px-10 w-32 sm:w-60"
							onClick={() => save()}>Save</Button>
					</div>
				</div>

			</div>

		</Modal>
	)
}

Backfill.defaultProps = defaultProps

export default Backfill
