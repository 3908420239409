import React, { useState, Component, FC } from 'react'
import { Link, useParams } from 'react-router-dom'
import { connect } from 'react-redux'

import Button from '../../components/form/Button'

import ProjectType from '../../components/project/ProjectType'
import ProjectHeaderNoDate from '../../components/project/ProjectHeaderNoDate'
import logoOuter from '../../assets/logo-icon-outer.svg'

import Header from '../../layout/Header'
import Container from '../../layout/Container'

import editIcon from '../../assets/icons/edit-white.svg'
import cablePercussiveIcon from '../../assets/icons/cablepercussive.svg'
import windowSampleIcon from '../../assets/icons/windowsample.svg'
import rotaryIcon from '../../assets/icons/rotary.svg'
import trailpitIcon from '../../assets/icons/trialpit.svg'
import dynamicProbeIcon from '../../assets/icons/dynamicprobe.svg'
import headTestIcon from '../../assets/icons/headtest.svg'
import loggingIcon from '../../assets/icons/logging.svg'
import trlIcon from '../../assets/icons/trl.svg'
import plateTestIcon from '../../assets/icons/platetest.svg'
import soakawayIcon from '../../assets/icons/soakaway.svg'
import cbrIcon from '../../assets/icons/cbr.svg'

import getWeatherList from '../../helpers/getWeatherList'
import { useGetIDBValues } from "../../helpers/getIDBKey";
import SyncStatus from '../../helpers/syncStatus'
import { get, set, del } from 'idb-keyval'

import RiskAssessment from '../RiskAssessment'
import Logs from '../Logs'

import menubars from '../../assets/menubars.svg'

import icon_ags from '../../assets/icons/icon_ags.png'
import icon_project from '../../assets/icons/icon_project.png'
import icon_email from '../../assets/icons/icon_email.png'
import icon_logs from '../../assets/icons/icon_logs.png'

import AGS from '../AGS'
import Email from '../Email'

interface Props {
	router: any,
	toggleSidebar: () => void,
	selectedWeather: string,
}

interface State {
	project_guid: string,
	company_guid: string,
	project: any,
	guid: string,
	holeCounts: any,

	bhMenuVisible: boolean,
	showEmail: boolean,
	showAGS: boolean,
	setShowEmail: boolean,
	setShowAGS: boolean,
	project_logs: any[],
	plantChecks_CP: any[],
	plantChecks_RT: any[],
	plantChecks_WS: any[],
	boreholes: any[],

	riskAssessmentVisible: boolean,
	logsVisible: boolean,
	access_level: number,
}



interface Log {
	id: number;
	guid: string;
	project_id: number;
	project_guid: string;
	// Add more properties as needed
}

class ProjectsDashboard extends React.Component<Props, State> {



	// Declare the menuRef property
	menuRef = React.createRef<HTMLDivElement>();

	constructor(props: any) {
		super(props)

		this.state = {
			guid: this.props.router.match.params?.id,
			project_guid: this.props.router.match.params?.id,
			company_guid: this.props.router.match.params?.company,
			project: {
				guid: '',
				company_guid: '',
				site_description: '',
				key_hands: '',
				expected_strata: '',
				notes: '',
				equipment_required: '',
				client_name: '',
				client_contact: '',
				client_phone: '',
				client_email: '',
				contractor_name: '',
				engineer_name: '',
				engineer_phone: '',
				engineer_email: '',
			},
			holeCounts: {},

			bhMenuVisible: false,
			showEmail: false,
			showAGS: false,
			setShowEmail: false,
			setShowAGS: false,
			riskAssessmentVisible: false,
			logsVisible: false,
			project_logs: [],
			plantChecks_CP: [],
			plantChecks_RT: [],
			plantChecks_WS: [],
			boreholes: [],
			access_level: 0,
		}

		this.bhMenuClick = this.bhMenuClick.bind(this);
		this.menuOutsideClick = this.menuOutsideClick.bind(this)
	}



	menuOutsideClick = (event: MouseEvent) => {
		if (
			this.menuRef.current &&
			!this.menuRef.current.contains(event.target as Node)
		) {
			this.setState({ bhMenuVisible: false });
		}
	}

	toggleBhMenu = () => {
		this.setState((prevState) => ({
			bhMenuVisible: !prevState.bhMenuVisible,
		}))
	}

	bhMenuClick() {
		this.setState({ bhMenuVisible: !this.state.bhMenuVisible });
	}

	menuAndEmail = () => {
		this.setState({ bhMenuVisible: !this.state.bhMenuVisible });
		this.setState({ showEmail: true });
	}

	menuAndAGS = () => {
		this.setState({ bhMenuVisible: !this.state.bhMenuVisible });
		this.setState({ showAGS: true });
	}

	menuAndLogs = () => {
		this.setState({ bhMenuVisible: !this.state.bhMenuVisible });
		this.setState({ logsVisible: true });
	}

	getWeatherIcon = () => {
		let weatherIcon = ''

		getWeatherList().map((weather) => {
			if (weather.label === this.props.selectedWeather)
				weatherIcon = weather.icon
		})

		return weatherIcon
	}

	getHoleCounts = (data: any) => {
		const holes = data?.hole_locations
		console.log('holes:', holes)

		if (holes) {
			const holeCounts = holes.reduce((holeTypes: any, hole: any) => {
				const {
					// trl = 0,
					// cbr = 0,
					// plate_test = 0,
					soakaway = 0,
					head_test = 0,
					dynamic_probe = 0,
					trialpit = 0,
					rotary = 0,
					window_sample = 0,
					cable_percussive = 0,
					logging = 0,
				} = holeTypes

				switch (hole.activity_type) {
					case 'Cable Percussive':
						return { ...holeTypes, cable_percussive: cable_percussive + 1 }
					case 'Window Sample':
						return { ...holeTypes, window_sample: window_sample + 1 }
					case 'Rotary':
						return { ...holeTypes, rotary: rotary + 1 }
					case 'Trialpit':
						return { ...holeTypes, trialpit: trialpit + 1 }
					case 'Dynamic Probe':
						return { ...holeTypes, dynamic_probe: dynamic_probe + 1 }
					case 'Head Test':
						return { ...holeTypes, head_test: head_test + 1 }
					case 'Soakaway':
						return { ...holeTypes, soakaway: soakaway + 1 }
					// case 'Plate Test':
					// 	return {...holeTypes, plate_test: plate_test + 1}
					// case 'CBR':
					// 	return {...holeTypes, cbr: cbr + 1}
					// case 'TRL':
					// 	return {...holeTypes, trl: trl + 1}
					case 'Logging':
						return { ...holeTypes, logging: logging + 1 }
					default:
						break
				}
			}, {})

			this.setState({
				holeCounts: holeCounts
			})
		}
	}

	_isMounted = false;

	componentDidMount() {
		this._isMounted = true;
		this.getUserDetails()
		this.getCacheValues()
		// console.log('PROPS', this.props.router.match.params.id)
		document.addEventListener('click', this.menuOutsideClick, true);
		this.getLogs()
		this.getActionCounts()
		this.getPlantChecks_CP()
		this.getPlantChecks_RT()
		this.getPlantChecks_WS()
	}

	componentWillUnmount() {
		this._isMounted = false;
		document.removeEventListener('click', this.menuOutsideClick, true);
	}

	getUserDetails = () => {
		get('drillit-user').then(data => {
			console.log('drillit-user data:', data);
			this.setState({
				access_level: data.user?.access_level,
			});
		});
	}

	getCacheValues = () => {
		let cachedValues = useGetIDBValues;
	
		console.log('this.state?.guid:', this.state?.guid);
	
		// Fetch project data from IndexedDB
		cachedValues(`projects/` + this.state?.guid, (data: any) => {
			this.setState({
				project: data
			}, () => {
				console.log('Project:', this.state.project);
				this.getHoleCounts(data);
			});
		});
	
		// Fetch boreholes data from IndexedDB
		const projectGuid = this.state?.project_guid;
		cachedValues(`hole-locations/project/${projectGuid}`, (data: any) => {
			console.log(`Fetched boreholes data from IndexedDB:`, data);
			if (data) {
				this.setState({ boreholes: data });
			} else {
				console.log(`No boreholes data found for key: hole-locations/project/${projectGuid}`);
			}
		});
	};

	

	getActionCounts = () => {
		let cachedValues = useGetIDBValues;
		cachedValues(`action_counts/project/` + this.state?.guid, (data:any) => {
			console.log('actionCount data:', data);
	
			if (data && data.actionCounts) {
				// Extract the individual action count entries
				const actionCountsArray = data.actionCounts;
	
				// Iterate through the array and set each entry in IndexedDB
				// actionCountsArray.forEach((actionCount:any) => {
				// 	const endPoint = `action_counts/${actionCount.guid}`;
				// 	set(endPoint, actionCount);
				// });
	
				// Optionally, if you want to store the entire array as a single key-value pair
				set('drillit-action_counts', actionCountsArray);
			}
		});
	};



	getLogs = () => {
		const cachedValues = useGetIDBValues;
		const projectLogsKey = `logs/project/${this.state?.project_guid}`;
	
		// Fetch project logs from the API
		cachedValues(projectLogsKey, (data: any) => {
			console.log('Project Logs:', data);
			
			// Check if data is not null or undefined and if it has the 'log' property
			if (data && data.log) {
				const newLogs: Log[] = data.log; // Use the Log interface
				
				// Fetch existing logs from IndexedDB
				get('drillit-logs').then((existingLogs: Log[]) => {
					console.log('Existing Logs from IndexedDB:', existingLogs);
					
					let mergedLogs: Log[];
					if (existingLogs && Array.isArray(existingLogs)) {
						// Merge new logs with existing logs and remove duplicates
						const logMap = new Map<string, Log>();
						
						// Add existing logs to the map
						existingLogs.forEach(log => {
							logMap.set(log.guid, log); // Assuming 'guid' is unique identifier
						});
						
						// Add new logs to the map, overriding existing logs with the same id
						newLogs.forEach(log => {
							logMap.set(log.guid, log);
						});
						
						// Convert map values back to array
						mergedLogs = Array.from(logMap.values());
					} else {
						// If no existing logs or not an array, use new logs as is
						mergedLogs = newLogs;
					}
					
					// Update IndexedDB with merged logs
					set('drillit-logs', mergedLogs).then(() => {
						console.log('IndexedDB updated with merged logs:', mergedLogs);

						this.setState({
							project_logs: mergedLogs
						})
						
						// Delete project logs request from IndexedDB
						del(`drillit-logs/project/${this.state?.project_guid}`).then(() => {
							console.log('Project logs request deleted from IndexedDB');
						}).catch(error => {
							console.error('Error deleting project logs request from IndexedDB:', error);
						});
					}).catch(error => {
						console.error('Error updating IndexedDB:', error);
					});
				}).catch(error => {
					console.error('Error fetching existing logs from IndexedDB:', error);
				});
			} else {
				console.error('Project logs data or log property is undefined or null.');
			}
		});
	};

	getPlantChecks_CP = () => {
		let cachedValues = useGetIDBValues
		cachedValues(`plant-check-cable-percussive/project/` + this.state?.guid, (data: any) => {
			this.setState({
				plantChecks_CP: data
			})
			console.log('plantChecks_CP:', this.state.plantChecks_CP)
		}).then()
	}

	getPlantChecks_RT = () => {
		let cachedValues = useGetIDBValues
		cachedValues(`plant-check-rotary/project/` + this.state?.guid, (data: any) => {
			this.setState({
				plantChecks_RT: data
			})
			console.log('plantChecks_RT:', this.state.plantChecks_CP)
		}).then()
	}

	getPlantChecks_WS = () => {
		let cachedValues = useGetIDBValues
		cachedValues(`plant-check-window-sample/project/` + this.state?.guid, (data: any) => {
			this.setState({
				plantChecks_WS: data
			})
			console.log('plantChecks_WS:', this.state.plantChecks_CP)
		}).then()
	}



	render() {

		// console.log('this.state?.project:', this.state?.project)
		// console.log('this.state?.project_logs:', this.state?.project_logs)
		console.log('this.state.access_level:', this.state.access_level)

		const { riskAssessmentVisible } = this.state;
		const { logsVisible } = this.state;

		// if (this.state?.project
		// 	&& this.state?.project !== undefined
		// 	&& this.state?.project.length === 0
		// 	&& this.state?.company_guid
		// 	&& this.state?.company_guid !== undefined
		// 	&& this.state?.company_guid.length === 0
		// ) {
		// 	return <div className="flex items-center justify-center h-screen">
		// 		<img src={logoOuter} className="logo-spin-sync absolute h-20" alt="logo gear" />
		// 		<img src={logoOuter} className="logo-spin-sync-left absolute h-12" alt="logo gear" />
		// 		<span className="pt-24 text-sm">INITIALIZING</span>
		// 	</div>
		// }

		const { project, project_logs, company_guid, boreholes } = this.state;

		if (!project || project.length === 0 || !company_guid || company_guid.length === 0 || !project_logs) {
			// Check if project data, logs, and company GUID are not yet loaded
			return (
				<>
					<div className="flex items-center justify-center h-screen">
						<img src={logoOuter} className="logo-spin-sync absolute h-20" alt="logo gear" />
						<img src={logoOuter} className="logo-spin-sync-left absolute h-12" alt="logo gear" />
						<span className="pt-24 text-sm">LOADING PROJECT</span>
					</div>
					<div className="-mt-10 clear-both text-grey-mid text-sm text-center w-full" onClick={() => {this.props.router.history.goBack(-1)}}>back</div>
				</>
			);
		}

		// Check if boreholes are loaded
		const areBoreholesLoaded = Array.isArray(boreholes);
	
		if (!areBoreholesLoaded) {
			// If logs are not yet loaded, display a loading message or spinner
			return (
				<>
					<div className="flex items-center justify-center h-screen">
						<img src={logoOuter} className="logo-spin-sync absolute h-20" alt="logo gear" />
						<img src={logoOuter} className="logo-spin-sync-left absolute h-12" alt="logo gear" />
						<span className="pt-24 text-sm">LOADING BOREHOLES</span>
					</div>
					<div className="-mt-10 clear-both text-grey-mid text-sm text-center w-full" onClick={() => {this.props.router.history.goBack(-1)}}>back</div>
				</>
			);
		}
	
		// Check if project logs are loaded
		const areLogsLoaded = Array.isArray(project_logs);
	
		if (!areLogsLoaded) {
			// If logs are not yet loaded, display a loading message or spinner
			return (
				<>
					<div className="flex items-center justify-center h-screen">
						<img src={logoOuter} className="logo-spin-sync absolute h-20" alt="logo gear" />
						<img src={logoOuter} className="logo-spin-sync-left absolute h-12" alt="logo gear" />
						<span className="pt-24 text-sm">LOADING PROJECT LOGS</span>
					</div>
					<div className="-mt-10 clear-both text-grey-mid text-sm text-center w-full" onClick={() => {this.props.router.history.goBack(-1)}}>back</div>
				</>
			);
		}

		return (
			<>

				{riskAssessmentVisible &&
					<RiskAssessment onClose={() => { this.setState({ riskAssessmentVisible: false }) }}
						project_location={this.state?.project?.location}
						project_id={this.state?.project?.project_identifier}
						company_guid={this.state?.company_guid}
						company_id={this.state?.project?.company?.id}
						project_guid={this.state?.project?.guid}
					/>
				}

				{logsVisible &&
					<Logs onClose={() => { this.setState({ logsVisible: false }) }}
						project_location={this.state?.project?.location}
						project_id={this.state?.project?.project_identifier}
						company_guid={this.state?.company_guid}
						// company_id={this.state?.project.company.id}
						project_guid={this.state?.project?.guid}
					/>
				}

				{/* MENU STUFF */}

				{this.state.showEmail &&
					<Email onClose={() => { this.setState({ showEmail: false }) }}
						company_id={this.state?.project?.company.id}
						project_location={this.state?.project?.location}
						project_id={this.state?.project?.project_identifier}
						project_guid={this.state?.project?.guid}
					/>
				}

				{this.state.showAGS &&
					<AGS onClose={() => { this.setState({ showAGS: false }) }}
						project_start_date='09/25/2023'
					/>
				}
				{/* <Header toggleSidebar={this.props.toggleSidebar} middle={this.projectDateSwitcher} /> */}
				<Header toggleSidebar={this.props.toggleSidebar} middle={
					<ProjectHeaderNoDate projectId={this.state?.project_guid}
						site_description={this.state.project?.site_description}
						key_site_features={this.state.project?.key_site_features}
						site_history={this.state.project?.site_history}
						key_hands={this.state.project?.key_hands}
						expected_strata={this.state.project?.expected_strata}
						notes={this.state.project?.notes}
						equipment_required={this.state.project?.equipment_required}
						client_name={this.state.project?.client_name}
						client_contact={this.state.project?.client_contact}
						client_phone={this.state.project?.client_phone}
						client_email={this.state.project?.client_email}
						engineer_name={this.state.project?.engineer_name}
						engineer_phone={this.state.project?.engineer_phone}
						engineer_email={this.state.project?.engineer_email}
					/>
				} />

				<Container>
					<div className={`flex items-center justify-end mb-2 mt-1 pt-1 text-xl`}>

						<div className={`mr-0 text-center`}>
							<Link to={'/projects/weather/' + this.props.router.match.params.id}><i className={"text-3xl md:text-4xl m-0 pt-1 " + this.getWeatherIcon()}></i></Link>
						</div>
					</div>

					<div className="w-full py-2 bg-grey-vdark text-center mb-0 mt-1 text-sm text-white rounded-md">
						<div onClick={this.toggleBhMenu} >
							Dashboard Menu <img src={menubars} className="h-3 w-3 mb-0.5 inline" alt="" />
						</div>
					</div>

					<div className="relative"> {/* menu container */}

						<div onClick={() => { this.setState({ riskAssessmentVisible: true }) }}
							className="w-64 rounded bg-blue px-5 py-2 mx-auto text lg font-bold text-white text-center mt-4">
							Create Risk Assessment
						</div>

						{/* <div className="px-1 lg:px-56"> */}
						<div className="px-1 lg:px-28">

							<div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-2 sm:gap-3 md:gap-4 mt-4 z-98">

								{[1, 2, 3].includes(this.state.access_level) &&
									<ProjectType title={'Cable Percussive'}
										count={this.state?.holeCounts?.cable_percussive}
										icon={cablePercussiveIcon}
										iconWidth={50}
										iconWidthCSS={'mt-2 md:mt-2 w-6 sm:w-10 md:w-11'}
										// link={`/projects/dashboard/${this.state.company_guid}/${this.props.router.match.params.id}/cable-percussive`} 
										link={`/projects/dashboard/company/${this.state?.company_guid}/${this.props.router.match.params.id}/cable-percussive`}
									/>
								}

								{[1, 2, 3].includes(this.state.access_level) &&
									<ProjectType title={'Window Sample'}
										count={this.state?.holeCounts?.window_sample}
										icon={windowSampleIcon}
										iconWidth={35}
										iconWidthCSS={'mt-1 md:mt-2 w-5 sm:w-8 md:w-8'}
										link={`/projects/dashboard/company/${this.state?.company_guid}/${this.props.router.match.params.id}/window-sample`}
									/>
								}

								{[1, 2, 3].includes(this.state.access_level) &&
									<ProjectType title={'Rotary'}
										count={this.state?.holeCounts?.rotary}
										icon={rotaryIcon}
										iconWidth={30}
										iconWidthCSS={'mt-2 md:mt-2 w-4 sm:w-6 md:w-7'}
										link={`/projects/dashboard/company/${this.state?.company_guid}/${this.props.router.match.params.id}/rotary`}
									/>
								}

								{[1, 2, 5].includes(this.state.access_level) &&
									<ProjectType title={'Trialpit'}
										count={this.state?.holeCounts?.trialpit}
										icon={trailpitIcon}
										iconWidth={100}
										iconWidthCSS={'mt-2 md:mt-1 w-14 sm:w-20 md:w-24'}
										link={`/projects/dashboard/company/${this.state?.company_guid}/${this.props.router.match.params.id}/trialpit`}
									/>
								}

								{[1, 2, 3].includes(this.state.access_level) &&
									<ProjectType title={'Dynamic Probe'}
										count={this.state?.holeCounts?.dynamic_probe}
										icon={dynamicProbeIcon}
										iconWidth={50}
										iconWidthCSS={'w-16 sm:w-20 md:w-28'}
										link={`/projects/dashboard/company/${this.state?.company_guid}/${this.props.router.match.params.id}/dynamic-probe`}
									/>
								}
								{/* <ProjectType title={'Logging'}
									// count={this.state.holeCounts.cable_percussive+this.state.holeCounts.window_sample+this.state.holeCounts.rotary} 
									count={this.state.holeCounts.logging}
									icon={loggingIcon}
									iconWidth={80}
									iconWidthCSS={'mt-2 md:mt-1 w-10 sm:w-18 md:w-20 pt-1'}
									link={`/projects/dashboard/company/${this.state?.company_guid}/${this.props.router.match.params.id}/logging`}
								/> */}

								{[1, 2, 5].includes(this.state.access_level) &&
									<ProjectType title={'Head Test'}
										count={this.state?.holeCounts?.head_test}
										icon={headTestIcon}
										iconWidth={80}
										iconWidthCSS={'mt-2 md:mt-2 w-12 sm:w-16 md:w-20'}
										link={`/projects/dashboard/company/${this.state?.company_guid}/${this.props.router.match.params.id}/head-test`}
									/>
								}

								{[1, 2, 5].includes(this.state.access_level) &&
									<ProjectType title={'Soakaway'}
										count={this.state?.holeCounts?.soakaway}  // Soakaway
										icon={soakawayIcon}
										iconWidth={120}
										iconWidthCSS={'mt-1 md:mt-2 w-16 sm:w-20 md:w-24'}
										link={`/projects/dashboard/company/${this.state?.company_guid}/${this.props.router.match.params.id}/soakaway`}
									/>
								}
								{/* <ProjectType title={'Plate Test'} 
								count={this.state.holeCounts.plate_test} 
								icon={plateTestIcon} 
								iconWidth={120} 
								iconWidthCSS={'mt-2 md:mt-2 w-14 sm:w-20 md:w-24'}
								link={`/projects/dashboard/company/${this.state?.company_guid}/${this.props.router.match.params.id}/plate-test`} 
							/>
							<ProjectType title={'CBR'} 
								count={this.state.holeCounts.cbr} 
								icon={cbrIcon} 
								iconWidth={120} 
								iconWidthCSS={'mt-2 md:mt-2 w-16 sm:w-20 md:w-24'}
								link={`/projects/dashboard/company/${this.state?.company_guid}/${this.props.router.match.params.id}/cbr`} 
							/>
							<ProjectType title={'TRL'} 
								count={this.state.holeCounts.trl} // TRL
								icon={trlIcon} 
								iconWidth={80} 
								iconWidthCSS={'mt-2 md:mt-2 w-14 sm:w-20 md:w-20'}
								link={`/projects/dashboard/company/${this.state?.company_guid}/${this.props.router.match.params.id}/trl`} 
							/> */}
							</div>

						</div>

						<div ref={this.menuRef} className={`menu ${this.state.bhMenuVisible ? 'visible' : 'hidden'}`}>

							<div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-2 sm:gap-3 md:gap-4 bg-grey-vdark w-full absolute z-99 top-0 left-0 -mt-5 text-center text-white text-sm pt-2 px-2 md:px-3 lg:px-4 pb-2 md:pb-3 lg:pb-4 rounded-b-lg modalshadow">

								{/* <div className="hidden md:hidden lg:block"></div> */}
								<div className="block"></div>
								<div className="border border-grey-dark rounded py-3">
									<Link to={`/projects/update/${this.props.router.match.params.id}`}>
										<img src={icon_project} className="h-12 w-12 mx-auto mb-1" alt="" />
										Edit Project
									</Link>
								</div>
								<div className="border border-grey-dark rounded py-3">
									<div onClick={this.menuAndLogs}>
										<img src={icon_logs} className="h-12 w-12 mx-auto mb-1" alt="" />
										Log Manager
									</div>
								</div>
								{/* <div className="border border-grey-dark rounded py-3">
									<div onClick={this.menuAndAGS}>
										<img src={icon_ags} className="h-12 w-12 mx-auto mb-1" alt="" />
										Output AGS
									</div>
								</div>
								<div className="border border-grey-dark rounded py-3">
									<div onClick={this.menuAndEmail}>
										<img src={icon_email} className="h-12 w-12 mx-auto mb-1" alt="" />
										Send Email
									</div>
								</div> */}

							</div>

						</div>
						
						<div className="">
							<div onClick={() => { this.setState({ logsVisible: true }) }}
								className="w-64 rounded bg-blue px-5 py-2 mx-auto text lg font-bold text-white text-center mt-4">
								Log Manager
							</div>
						</div>

					</div> {/* end of menu container */}

				</Container>

				<br /><br />



				<SyncStatus synced />
			</>
		)
	}
}

const mapStateToProps = (state: any) => ({
	selectedWeather: state.weather
})

export default connect(mapStateToProps)(ProjectsDashboard)
