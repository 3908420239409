import React, { FunctionComponent, useState } from 'react'

import WorkflowSteps from '../../../WorkflowSteps'
import Modal from '../../../Modal'
import Button from '../../../form/Button'
import NumberInput from '../../../NumberInput'
import MultiNumberInput from '../../../MultiNumberInput'
import { v4 as uuidv4 } from 'uuid';

import stepStatus from '../../../../helpers/stepStatus'

import PrintLabel from '../../../../components/PrintLabel'
// import { updateSelectedProject } from '../../../../actions/SelectedProject'
import { useSelector, useDispatch } from 'react-redux'
// import { useGetIDBValues } from "../../../../helpers/getIDBKey"
import { useGetLoggedInUser } from '../../../../helpers/getLoggedInUser'
import warningIcon from '../../../../assets/icons/warning.png'

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: string,
	defaultValues?: any,
	selectedDate?: string,
	update?: boolean,
	projectId?: string,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => { },
	onComplete: () => { },
	borehole: '',
	projectId: '',
	defaultValues: {
		guid: '',
		depth_to_top: '',
		depth_to_base: '',
		casing_depth: '',
		depth_of_water: '',
		blows: '',
		recovery: '',
	},
	selectedDate: '',
}

const PS: FunctionComponent<Props> = ({ onClose, update, onComplete, borehole, defaultValues, selectedDate, sampled_by,
	casing_preset, setShowPSEdit, new_type_number, boreholeDepth, 
	...rest }) => {
	const [sampler, setSampler] = useState(sampled_by)
	// console.log('defaultValues:', defaultValues)
	const [currentStep, setCurrentStep] = useState(1)
	const date = useSelector((state: any) => state.date)
	var labelDate = date.toLocaleDateString("en-UK")

	const [guid, setGuid] = useState(defaultValues.guid)
	const [depth_to_top, setDepthFrom] = useState(defaultValues.depth_to_top)
	const [depth_to_base, setDepthTo] = useState(defaultValues.depth_to_base)
	const [casing_depth, setCasing] = useState(defaultValues.casing_depth)
	const [depth_of_water, setWater] = useState(defaultValues.depth_of_water)
	// const [blows, setBlows] = useState(defaultValues.blows)
	const [recovery, setRecovery] = useState(defaultValues.recovery)

	const [saveButtonText, setButtonText] = useState('Continue')
	const [saveButtonColor, setButtonColor] = useState('primary')

	const loggedInUser = useGetLoggedInUser()

	const selectedProject = useSelector((state: any) => state.selectedProject)
	// const dispatch = useDispatch()
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	const DeleteButton = () => {
		if (!update) return null;

		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	const deleteRecordConfirm = () => {
		setDeleteConfirmOpen(false)
		// onComplete()
	}


	const [time_of_action, setTimeOfAction] = useState(defaultValues.time_of_action)

	const isoDate = selectedDate ? `${new Date(selectedDate).toISOString().split('T')[0]} 09:00:00` : new Date().toISOString().split('.')[0]

	const previous = () => {
		if (currentStep > 1)
			setCurrentStep(currentStep - 1)
		setButtonText('Continue')
	}

	const next = () => {
		if (currentStep < 5) {
			setCurrentStep(currentStep + 1)
			if (currentStep === 4) {
				setButtonText('Save')
				setButtonColor('success')
			} else {
				setButtonText('Continue')
				setButtonColor('primary')
			}
			return
		}

		if (!depth_to_top) {
			//do nothing
		} else {

			onComplete({
				guid: update ? guid : uuidv4(),
				depth_to_top: depth_to_top2DP,
				casing_depth: casing_depth2DP,
				depth_of_water: water_depth2DP,
				depth_to_base: depth_to_base2DP,
				seating_blows_1st_inc: 0,
				seating_blows_2nd_inc: 0,
				mm_over: 0,
				mm_overSB: 0,
				test_blows_1st_inc: 0,
				test_blows_2nd_inc: 0,
				test_blows_3rd_inc: 0,
				test_blows_4th_inc: 0,
				self_weight_penetration: '', 
				spt_in_soft_rock: '', 
				general_remarks: '',
				pen_1: 0,
				pen_2: 0,
				pen_3: 0,
				pen_4: 0,
				pen_5: 0,
				pen_6: 0,
				seating_blows: 0,
				total_blows: 0,
				reported_result: '',
				type: 'PS',
				type_number: type_number_output,
				utype: '',
				ublows: 0,
				sample_preparation: 0,
				recovery: ''+ recovery,
				size: '',
				test_type: '',
				sv_result1: '',
				sv_result2: '',
				sv_result3: '',
				sv_result4: '',
				average: '',
				suitableForTesting: '',
				time_of_action: time_of_action ? time_of_action : isoDate,
				update: update ? true : false,
				create: update ? false : true,
			})
		}
	}

	const MissingRequiredField = () => {
		if (currentStep === 5 && !depth_to_top) return (
			<div className="text-center bg-sptSB border border-red font-bold rounded mb-2 py-1 text-xs md:text-sm lg:text-base">
				<img src={warningIcon} alt="info" width="20" className="inline-block mr-1 pb-1" />
				Required field: 'Depth From'
			</div>
		)
	
		return null
	}

	var depth_to_top2DP = parseFloat(depth_to_top).toFixed(2)
	let depth_to_base2DP = ''

	if(!depth_to_base){
		depth_to_base2DP = ''
	} else {depth_to_base2DP = parseFloat(depth_to_base).toFixed(2)}

	let recovery2DP = ''
	if(!recovery){
		recovery2DP = ''
	} else {recovery2DP = parseFloat(recovery).toFixed(2)}

	var printDepth = depth_to_top2DP + ' - ' + depth_to_base2DP
	
	var sampleID = ''
	var type_number_output = 0
	if (update) {
		console.log('update?:', update, defaultValues.type_number)
		sampleID = "PS-" + defaultValues.type_number
		type_number_output = defaultValues.type_number
	} else {
		sampleID = "PS-" + new_type_number
		type_number_output = new_type_number
	}

	let title_to_send_for_PSsample = 'Print Label for PS Sample?'

	if (casing_depth === '') { var casing_depth2DP = '' }
	else if (casing_depth === 'no casing') { casing_depth2DP = 'no casing' }
	else { casing_depth2DP = parseFloat(casing_depth).toFixed(2) }

	if (!casing_preset) { var casing_preset_value = 'no casing' }
	else { casing_preset_value = parseFloat(casing_preset).toFixed(2)}

	if (depth_of_water === '') { var depth_of_water2DP = '' } else { depth_of_water2DP = parseFloat(depth_of_water).toFixed(2) }

	if (depth_of_water === '') { var water_depth2DP = '' }
	else if (depth_of_water === 'dry') { water_depth2DP = 'dry' }
	else if (depth_of_water === 'damp') { water_depth2DP = 'damp' }
	else { water_depth2DP = parseFloat(depth_of_water).toFixed(2) }

	return (
		<Modal title="Piston Sample" subtitle={borehole} toggleModal={onClose}>
			<div className="flex flex-col flex-grow px-1 sm:px-2 md:px-10">
				<WorkflowSteps steps={[
					{
						label: 'Depth',
						status: stepStatus(1, currentStep),
						onClick: () => (setCurrentStep(1), setButtonText('Continue'), setButtonColor('primary'))
					}, {
						label: 'Casing',
						status: stepStatus(2, currentStep),
						onClick: () => (setCurrentStep(2), setButtonText('Continue'), setButtonColor('primary'))
					}, {
						label: 'Water',
						status: stepStatus(3, currentStep),
						onClick: () => (setCurrentStep(3), setButtonText('Continue'), setButtonColor('primary'))
					}, {
						label: 'Recovery',
						status: stepStatus(4, currentStep),
						onClick: () => (setCurrentStep(4), setButtonText('Continue'), setButtonColor('primary'))
					}, {
						label: 'Label',
						status: stepStatus(5, currentStep),
						onClick: () => (setCurrentStep(5), setButtonText('Save'), setButtonColor('success'))
					},
				]} className="mb-2" />

				<div className="flex-1 flex">
					{currentStep === 1 &&
						// <NumberInput onUpdate={(value: any) => setDepthFrom(value)} 
						// defaultValue={depth_to_top} 
						// title="Depth From (m)" 
						// className="mb-10" />

						<MultiNumberInput values={
							[
								{ defaultValue: depth_to_top, onUpdate: setDepthFrom, label: 'Depth From (m)' },
								{ defaultValue: depth_to_base, onUpdate: setDepthTo, label: 'Depth To (m)' },
							]
						}
						// title="Depth"	
						className="mb-2 text-base sm:text-xl md:text-xl"
						inputCSS="text-2xl sm:text-2xl md:text-3xl lg:text-4xl h-16 sm:h-16 my-4"
						/>
					}

					{/* {currentStep === 2 &&
						<NumberInput onUpdate={(value: any) => setDepthTo(value)} 
						defaultValue={depth_to_base} 
						title="Depth To (m)" 
						className="mb-10" />
					} */}

					{currentStep === 2 &&
						<NumberInput onUpdate={(value: any) => setCasing(value)}
							defaultValue={casing_depth}
							title="Enter Casing Depth (m)"
							className="text-xl leading-6"
							// Load in latest casing depth
							presets={[
								{ value: casing_preset_value, label: casing_preset_value },
							]}
							next={next}
						/>
					}

					{currentStep === 3 &&
						<NumberInput onUpdate={(value: any) => setWater(value)}
							defaultValue={depth_of_water}
							title="Enter Water Depth (m)"
							className="text-2xl"
							presets={[
								{ value: 'dry', label: 'dry' },
								{ value: 'damp', label: 'damp' },
							]}
							next={next}
						/>
					}

					{/* {currentStep === 5 &&
						<NumberInput onUpdate={(value: any) => setBlows(value)} 
						defaultValue={blows} 
						title="Number of Blows" 
						className="mb-10" />
					} */}

					{currentStep === 4 &&
						<NumberInput onUpdate={(value: any) => setRecovery(value)}
							defaultValue={recovery}
							title="Length Recovered (mm):"
							className="mb-10" />
					}

					{currentStep === 5 &&

						<PrintLabel
							location={selectedProject.location}
							project={selectedProject.number}
							borehole={borehole}
							sampledate={labelDate}
							// user={loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name)}
							sampled_by={sampler}
							sample={sampleID}
							depth={printDepth}
							size='printLabel'
							title={title_to_send_for_PSsample}
						/>
					}
				</div>

				<MissingRequiredField />


				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">


							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?

								<div className="flex mt-4">
									<Button theme="secondary" 
										className="mr-5 text-sm sm:text-base px-3.5 md:px-7" 
										onClick={() => setDeleteConfirmOpen(false)}>
											Cancel
									</Button>

									<Button onClick={() => {
												onClose()
												onComplete({
													guid: guid,
													update: false,
													delete: true,
												})
												setShowPSEdit(false)
											}}
										className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">
										Delete
									</Button>
								</div>
							</div>


						</div>
					</div>
				}

				<div className="flex">
					{/* <Button theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button> */}
					<DeleteButton />

					<div className="flex ml-auto">
						<Button theme="primary" size="lg" className="px-4 sm:px-10" onClick={() => previous()}>Back</Button>
						<Button theme={saveButtonColor} size="lg"
							className="ml-4 px-8 sm:px-10 w-32 sm:w-60"
							onClick={() => next()}>{saveButtonText}
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

PS.defaultProps = defaultProps

export default PS
