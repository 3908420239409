import React, { FunctionComponent, useState } from 'react'

import closeIcon from '../assets/icons/close.svg'

import Button from './form/Button'

// import ModalDate from './ModalDate'

type Props = {
	title: string,
	subtitle?: string,
	bg?: string,
	toggleModal: () => void,
	confirm?: boolean,
	children?: React.ReactNode,
}

const defaultProps: Props = {
	title: '',
	subtitle: '',
	bg: 'bg-modal-bg',
	toggleModal: () => {},
	confirm: true,
	children: [],
}

const Modal: FunctionComponent<Props> = ({ title, subtitle, bg, toggleModal, confirm, children,  }) => {
	
	const [closeConfirmOpen, setCloseConfirmOpen] = useState<boolean>(false)

	const closeModal = () => {
		if(confirm) {
			setCloseConfirmOpen(true)
		} else {
			toggleModal()
		}
	}

	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	// const deleteRecord = () => {
	// 	setDeleteConfirmOpen(true)
	// }

	const deleteRecordConfirm = () => {
		setDeleteConfirmOpen(false)
		// onComplete()
	}

	const closeModalOnDelete = () => {
		toggleModal()
	}

	return (
		<>
			<div className="fixed inset-0 px-8 sm:px-8 md:px-20 pt-20 md:pt-32 z-40 overflow-y-auto" style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)' }}>
				<div className={`flex flex-col rounded-lg border border-orange ${bg} px-2 sm:px-2 md:px-2 pb-2 modalshadow`}>
					<div className="flex items-center border-b border-grey-mid py-1">

						<div className="flex-none flex items-left w-8">
							<div className="flex-none">

								{/* <span className="mr-auto">{subtitle}</span> */}
								<div className="flex-none text-base font-bold text-center">
									{subtitle}
								</div>

								<div className="flex-none">
									{/* <ModalDate/>  // ONLY SHOW IF WORKFLOW STEP */}
									
								</div>

							</div>
						</div>

						<div className="flex-1 flex justify-center text-lg md:text-xl font-bold text-text leading-6 text-center mx-auto">
							{title}
						</div>

						<div className="flex-none flex justify-center">
							{/* <span className="ml-auto"> */}
							<span className="">
								<button className="flex items-center justify-center min-w-9 h-8 text-4xl bold text-soil-grey bg-white border border-orange rounded" onClick={() => closeModal()}>
									<img className="w-8 h-8" src={closeIcon}  alt="" />
								</button>
							</span>
						</div>
					</div>

					{/* <div onClick={() => closeModalOnDelete()}>close</div> */}

					<div className="flex flex-col mt-2 flex-grow">
						{children}
					</div>
				</div>
			</div>

			{closeConfirmOpen &&
				<div className="fixed inset-0 z-50">
					<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">


						<div className="bg-white rounded-lg border-2 border-orange px-8 py-6 text-center modalshadow">
							Are you sure you want to close without saving?

							<div className="flex mt-4">
								<Button theme="secondary" className="mr-5 text-sm sm:text-base px-3 sm:px-7" onClick={() => setCloseConfirmOpen(false)}>Cancel</Button>

								<Button  onClick={() => closeModalOnDelete()} className="text-sm sm:text-base px-3 sm:px-7">Close without saving</Button>
							</div>
						</div>


					</div>
				</div>
			}

			
				

				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">
	
	
							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?
	
								<div className="flex mt-4">
									<Button theme="secondary" className="mr-5 text-sm sm:text-base px-3.5 md:px-7" onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
	
									<Button onClick={deleteRecordConfirm} className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">Delete</Button>
								</div>
							</div>
	
	
						</div>
					</div>
				}
		</>
	)
}

Modal.defaultProps = defaultProps

export default Modal
