import React, { FunctionComponent, useState, useRef, useEffect } from 'react'

import WorkflowSteps from '../../WorkflowSteps'
import Modal from '../../Modal'
import Button from '../../form/Button'
import TextArea from '../../form/TextArea'

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: any,
	borehole_guid?: string,
	title: string,
	// torquetext1: string,
	// torquetext2: string,
	rows?: number,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => {},
	onComplete: () => {},
	borehole: '',
	borehole_guid: '',
	title: '',
	// torquetext1: '',
	// torquetext2: '',
	rows: 160,
	defaultValues: {
		remarks: '',
	},
}

const initializeCells = (rows: number): string[] => {
	let cells: any = {}

	for(const row in [...Array(rows)]) {
		for(const column in [...Array(1)]) {
			cells[parseInt(row + '' + column) + 0] = ''
		}
	}

	return cells
}

const keys = [
	'1', '2', '3',
	'4', '5', '6',
	'7', '8', '9',
	'.', '0',
]
	


const TRL: FunctionComponent<Props> = ({ onClose, onComplete, borehole, borehole_guid, defaultValues, title, rows = 160, ...rest }) => {
	
	const [activeCell, setActiveCell] = useState<number>(0)
	const [activeBlowCell, setActiveBlowCell] = useState<number>(0)
	const [cellNotTorque, setCellNotTorque] = useState<boolean>(true)
	const [numRows, setNumRows] = useState<number>(rows)

	const [cells, setCells] = useState<string[]>(initializeCells(numRows))

	const [remarks, setComment] = useState(defaultValues.remarks)
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	const DeleteButton = () => {
		// if (!update) return null;
	
		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	const deleteRecordConfirm = () => {
		setDeleteConfirmOpen(false)
		// onComplete()
	}

	const addCharacterCell = (character: string) => {
		let newValue = cells[activeCell]

		if(cells[activeCell] === '0')
			newValue = character
		else
			newValue = cells[activeCell] + character

		let newCells = {...cells}
		newCells[activeCell] = newValue

		setCells(newCells)
	}

	const addCharacter = (character: string) => {
		addCharacterCell(character)
		// console.log(character)
	}

	const removeCharacterCell = () => {
		let newValue = cells[activeCell]

		if(cells[activeCell] !== '') {
			if(cells[activeCell].length === 1)
				newValue = ''
			else
				newValue = cells[activeCell].slice(0, -1)
		}

		let newCells = {...cells}
		newCells[activeCell] = newValue

		setCells(newCells)
	}

	const removeCharacter = () => {
		removeCharacterCell()
	}

	const clearEntryCell = () => {
		let newCells = {...cells}
		newCells[activeCell] = ''

		setCells(newCells)
	}

	const clearEntry = () => {
		clearEntryCell()
	}

	const save = () => {
		const holeId = borehole_guid
		
		onComplete({
			cells,
			holeId,
		})
	}

	console.log('Rows: ', rows)

	

	return (
		<Modal title={title} subtitle={borehole} toggleModal={onClose}>

			<div className="flex flex-col">

				<div className="grid grid-cols-12 gap-2">

					<div className="col-span-5 mb-0">   {/* LEFT SIDE - INPUTS */}
					

						<div className="">
							<div className="grid grid-cols-2 gap-0 font-bold text-center bg-grey-mid ">
								{/* <div className={`col-span-1 px-0.5 py-1 text-xs md:text-sm `}>#</div> */}
								<div className={`col-span-1 px-0.5 py-1 text-xs md:text-sm `}>Blows</div>
								<div className={`col-span-1 px-0.5 py-1 text-xs md:text-sm `}>Depth (mm)</div>
							</div>
						</div>

						<div className="">
							<div className="grid grid-cols-2 gap-0 text-center overflow-auto border-l border-b border-grey-mid trl_inputs">
								
                                {/* NUMBERS */}
                                {/* <div className={`col-span-1 px-0 text-base`}> */}
									{/* {[...Array(numRows+1)].map((_, row) => {
										return <div className={`flex items-center justify-center py-0 border-l border-b border-r border-grey-mid`} 
											style={{'height': '30px'}} key={'rowl' + row}>{row++}</div>
									})} */}
									{/* {[...Array(1)].map((_, column) => */}
											{/* <div className={`col-span-1`} key={'column' + column}>

												{[...Array(rows+1)].map((_, row) =>
													<React.Fragment key={'row' + row} >
														
														<div className={`w-full`}>
															<div
																className={`border-b border-r border-grey-mid h-7 ${row % 2 ? 'bg-grey-light' : 'bg-white'} `}
																>
																{row++}
															</div>
														</div>

													</React.Fragment>
												)}
											</div> */}
										{/* )} */}
								{/* </div> */}



                                {/* BLOWS */}
								<div className={`col-span-1 px-0 text-base`}>
									{/* {[...Array(numRows+1)].map((_, row) => {
										return <div className={`flex items-center justify-center py-0 border-l border-b border-r border-grey-mid`} 
											style={{'height': '30px'}} key={'rowl' + row}>{row++}</div>
									})} */}
									{[...Array(1)].map((_, column) =>
											<div className={`col-span-1`} key={'column' + column}>

												{[...Array(rows+1)].map((_, row) =>
													<React.Fragment key={'row' + row} >
														<div className={`w-full text-base`}>
															<div
																className={`border-b border-r border-grey-mid h-7 ${row % 2 ? 'bg-grey-light' : 'bg-white'} `}
																>
																
															</div>
														</div>
													</React.Fragment>
												)}
											</div>
										)}
								</div>

								{/* DEPTH */}
								<div className={`col-span-1 px-0 text-base`}>
									{/* {[...Array(numRows+1)].map((_, row) => {
										return <div className={`flex items-center justify-center py-0 border-l border-b border-r border-grey-mid`} 
											style={{'height': '30px'}} key={'rowl' + row}>{row++}</div>
									})} */}
									{[...Array(1)].map((_, column) =>
											<div className={`col-span-1`} key={'column' + column}>

												{[...Array(rows+1)].map((_, row) =>
													<React.Fragment key={'row' + row}>
														<div className={`w-full border-r border-grey-mid`}>
															<div
																className={`border-b border-grey-mid h-7 ${row % 2 ? 'bg-grey-light' : 'bg-white'} ${(activeCell == parseInt(row + '') + 0) ? 'bg-blue text-white incrementHeight' : ''} `}
																
																onClick={() => {
																	setActiveCell(parseInt(row + ''))
																}}
																tabIndex={parseInt(row + '')} 
																>
																
																{cells[parseInt(row + '')]}
															</div>
														</div>
													</React.Fragment>
												)}
											</div>
										)}
								</div>
								
							</div>

						</div>

					</div>
					
					{/* BUTTONS ETC */}




					<div className="col-span-7"> {/* BUTTONS GRID */}

						<div className="">
							
							<div className="w-full">

								<div className="grid grid-cols-3 gap-1 lg:gap-2">

									<div className="col-span-1"> {/* REMOVE ROD BUTTON */}
										<Button theme="outline" type="button" className="h-10 w-full text-base md:text-2xl lg:text-xl py-3 md:py-8 lg:py-2  mr-1 lg:mr-2 mb-1 lg:mb-2" 
										onClick={() => {
											// setActiveCell(activeCell == 1 ? activeCell : activeCell - 1)
										}}>- Rod</Button>
									</div>

									<div className="col-span-2"> {/* ADD ROD BUTTON */}
										<Button theme="outline" type="button" className="h-10 w-full text-base md:text-2xl lg:text-xl py-3 md:py-8 lg:py-2  mr-1 lg:mr-2 mb-1 lg:mb-2" 
										onClick={() => {
											// setActiveCell(activeCell == rows ? activeCell : activeCell + 1)
										}}>Add Rod</Button>
									</div>

								</div>

									<div className="w-full border border-grey-mid rounded mt-1"> {/* INFO BOX */}

										<div className="grid grid-cols-3">

										<div className="col-span-2 text-xs md:text-lg lg:text-lg pr-2 text-right h-10 pt-3 md:pt-1.5 lg:pt-1.5">
												Number of Rods:
											</div>
											<div className="col-span-1 text-lg font-bold text-center border-l border-grey-mid pt-1.5">
												0
											</div>
											<div className="col-span-3 border-b border-grey-mid"></div>

											<div className="col-span-2 text-xs md:text-lg lg:text-lg pr-2 text-right h-10 pt-3 md:pt-1.5 lg:pt-1.5">
												Cumulative Blows:
											</div>
											<div className="col-span-1 text-lg font-bold text-center border-l border-grey-mid pt-1.5">
												4
											</div>
											<div className="col-span-3 border-b border-grey-mid"></div>

											<div className="col-span-2 text-xs md:text-lg lg:text-lg pr-2 text-right h-10 pt-3 md:pt-1.5 lg:pt-1.5">
												Total Depth (mm):
											</div>
											<div className="col-span-1 text-lg font-bold text-center border-l border-grey-mid pt-1.5">
												98
											</div>

										</div>

									</div>

							</div>
							

							<div className="w-full mt-2">

									<div className="w-1/3 inline-flex"> {/* REMOVE BLOW BUTTON */}
										<Button theme="outline" type="button" className="w-full text-base md:text-2xl lg:text-xl py-3 md:py-8 lg:py-2 mr-1 lg:mr-2 mb-1 lg:mb-2" 
										onClick={() => {
											setActiveCell(activeCell == 0 ? activeCell : activeCell - 1)
											console.log('Active Cell;', activeCell+1)
										}}>- Blow</Button>
									</div>

									<div className="w-2/3 inline-flex"> {/* ADD BLOW BUTTON */}
										<Button theme="outline" type="button" className="w-full text-base md:text-2xl lg:text-xl py-3 md:py-8 lg:py-2 mb-1 lg:mb-2" 
										onClick={() => {
											setActiveCell(activeCell == 0 ? activeCell : activeCell - 1)
											console.log('Active Cell;', activeCell+1)
										}}>Add Blow</Button>
									</div>

									<div className="w-1/3 inline-flex"> {/* BACK BUTTON */}
										<Button theme="outline" type="button" className="w-full text-base md:text-2xl lg:text-xl py-3 md:py-8 lg:py-2 mr-1 lg:mr-2 mb-1 lg:mb-2" 
										onClick={() => {
											setActiveCell(activeCell == 0 ? activeCell : activeCell - 1)
										}}>&lt;</Button>
									</div>

									<div className="w-2/3 inline-flex"> {/* NEXT BUTTON */}
										<Button theme="outline" type="button" className="w-full text-base md:text-2xl lg:text-xl py-3 md:py-8 lg:py-2 mb-1 lg:mb-2" onClick={() => {
											setActiveCell(activeCell == rows ? activeCell : activeCell + 1)
											console.log('Active Cell;', activeCell+1)
										}}>Next &gt;</Button>
									</div>

							</div>

							<div className="w-full mt-2">
								<div className="grid grid-cols-3 gap-1 lg:gap-2">  {/* NUMBER BUTTONS */}
									{keys.map((key, index) => {
										return <div className="border border-orange rounded py-2.5 md:py-8 lg:py-2 text-2xl md:text-4xl text-center" key={index} onClick={() => addCharacter(key)}>{key}</div>
									})}

									<div className="border border-orange rounded py-2.5 md:py-8 lg:py-2 text-2xl md:text-4xl text-center" onClick={() => removeCharacter()}>⌫</div>
								</div>
							</div>

						</div>

					</div>


											
                <div className="col-span-12 w-full h-10">
                    <TextArea 
                    label="" 
                    value={remarks}
                    placeholder="Comments"
                    onChange={(value: any) => setComment(value)} 
                    className="w-full mx-auto mb-2 mt-0 md:mt-2 lg:mt-0 align-text-top"
                    inputCSS="overflow-x-auto text-sm h-12"
                    numberOfRows={2}
                    />
                </div>



				</div>
				

				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">
	
	
							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?
	
								<div className="flex mt-4">
									<Button theme="secondary" className="mr-5 text-sm sm:text-base px-3.5 md:px-7" onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
	
									<Button onClick={deleteRecordConfirm} className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">Delete</Button>
								</div>
							</div>
	
	
						</div>
					</div>
				}

				{/*<div className="flex items-center mt-10">
					<Button theme="primary" type="button" onClick={() => }>Comments</Button>
				</div>*/}

				<div className="flex items-center mt-5 md:mt-12 lg:mt-5">
					{/* <Button theme="secondary" type="button" size="lg">Delete TRL</Button> */}

					<DeleteButton/>

					<Button theme="primary" type="button" size="lg" className="ml-auto w-48" onClick={()=>save()}>Save</Button>
				</div>
			</div>
		</Modal>
	)
}

TRL.defaultProps = defaultProps

export default TRL
