import React, { FunctionComponent, useState } from 'react'

import WorkflowSteps from '../../WorkflowSteps'
import Modal from '../../Modal'
import Button from '../../form/Button'
import NumberInput from '../../NumberInput'
import MultiNumberInput from '../../MultiNumberInput'

import { v4 as uuidv4 } from 'uuid';
import stepStatus from '../../../helpers/stepStatus'
// import TimeInput from "../../TimeInput";
import InputGroup from '../../form/InputGroup'
import warningIcon from '../../../assets/icons/warning.png'

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: string,
	defaultValues?: any,
	selectedDate?: string,
	update?: any,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => { },
	onComplete: () => { },
	borehole: '',
	defaultValues: {
		guid: '',
		depth_to_top: '',
		depth_to_base: '',
		amount_added: '',
		start_time: '00:00',
	},
	selectedDate: '',
}

const WaterAdded: FunctionComponent<Props> = ({ onClose, update, onComplete, borehole, defaultValues, selectedDate, setShowWaterAddedEdit, ...rest }) => {
	const [currentStep, setCurrentStep] = useState(1)

	const [guid, setGuid] = useState(defaultValues.guid)
	const [depth_to_top, setDepthFrom] = useState(defaultValues.depth_to_top)
	const [depth_to_base, setDepthTo] = useState(defaultValues.depth_to_base)
	const [amount_added, setVolume] = useState(defaultValues.amount_added)
	const [start_time, setTime] = useState(defaultValues.start_time)

	const [saveButtonText, setButtonText] = useState('Continue')
	const [saveButtonColor, setButtonColor] = useState('primary')
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	const DeleteButton = () => {
		if (!update) return null;

		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	const deleteRecordConfirm = () => {
		setDeleteConfirmOpen(false)
		// onComplete()
	}

	const [time_of_action, setTimeOfAction] = useState(defaultValues.time_of_action)

	const isoDate = selectedDate ? `${new Date(selectedDate).toISOString().split('T')[0]} 09:00:00` : new Date().toISOString().split('.')[0]

	const previous = () => {
		if (currentStep > 1)
			setCurrentStep(currentStep - 1)
		setButtonText('Continue')
		setButtonColor('primary')
	}

	const next = () => {
		if (currentStep < 3) {
			setCurrentStep(currentStep + 1)
			if (currentStep === 2) {
				setButtonText('Save')
				setButtonColor('success')
			} else {
				setButtonText('Continue')
				setButtonColor('primary')
			}
			return
		}

		if (!depth_to_top) {
			//do nothing
		} else {

			onComplete({
				guid: update ? guid : uuidv4(),
				depth_to_top: depth_to_top2DP, //2DP,
				depth_to_base: depth_to_base2DP, //2DP,
				amount_added: amount_added, //0DP,
				start_time: start_time_temp, //start_time +':00', // start_time ? start_time : `${new Date().toISOString().substring(0, 10)} ${start_time}`,
				general_remarks: '',
				time_of_action: time_of_action ? time_of_action : isoDate,
				update: update ? true : false,

			})
		}
	}

	let start_time_temp = ''
	if(update){
		start_time_temp = start_time
	} else {
		start_time_temp = start_time + ':00'
	}

	let requiredFromText = ''
	let requiredVolumeText = ''

	if (!depth_to_top) {
		requiredFromText = "'Depth From' - "
	}

	if (!amount_added) {
		requiredVolumeText = "'Volume'"
	}

	const MissingRequiredField = () => {
		if (currentStep === 3 && (!depth_to_top || !amount_added)) return (
			<div className="text-center bg-sptSB border border-red font-bold rounded mb-2 py-1 text-xs md:text-sm lg:text-base">
				<img src={warningIcon} alt="info" width="20" className="inline-block mr-1 pb-1" />
				Required field: {requiredFromText} {requiredVolumeText}
			</div>
		)

		return null
	}

	var depth_to_top2DP = parseFloat(depth_to_top).toFixed(2)
	var depth_to_base2DP = parseFloat(depth_to_base).toFixed(2)
	var amount_added0DP = Math.round(amount_added)

	return (
		<Modal title="Water Added" subtitle={borehole} toggleModal={onClose}>
			<div className="flex flex-col flex-grow px-1 sm:px-2 md:px-10">
				<WorkflowSteps steps={[
					{
						label: 'Depth',
						status: stepStatus(1, currentStep),
						onClick: () => (setCurrentStep(1), setButtonText('Continue'), setButtonColor('primary'))
					}, {
						label: 'Volume',
						status: stepStatus(2, currentStep),
						onClick: () => (setCurrentStep(2), setButtonText('Continue'), setButtonColor('primary'))
					}, {
						label: 'Time Added',
						status: stepStatus(3, currentStep),
						onClick: () => (setCurrentStep(3), setButtonText('Save'), setButtonColor('success'))
					},
				]} className="mb-2" />

				<div className="flex-1 flex">
					{currentStep === 1 &&
						// <NumberInput onUpdate={(value: any) => setDepthFrom(value)} 
						// defaultValue={depth_to_top} 
						// title="Enter Depth From (m)" 
						// className="mb-10" />

						<MultiNumberInput
							title="Depth (m)"  
							values={
								[
									{ defaultValue: depth_to_top, onUpdate: setDepthFrom, label: 'From' },
									{ defaultValue: depth_to_base, onUpdate: setDepthTo, label: 'To' },
								]
							}
							className="text-base sm:text-xl md:text-xl"
							inputCSS="text-3xl sm:text-3xl md:text-3xl lg:text-4xl h-16 sm:h-16 mb-4"
						/>
					}

					{/* {currentStep === 2 &&
						<NumberInput onUpdate={(value: any) => setDepthTo(value)} 
						defaultValue={depth_to_base} 
						title="Enter Depth To (m)" 
						className="mb-10" />
					} */}

					{currentStep === 2 &&
						<NumberInput onUpdate={(value: any) => setVolume(value)}
							defaultValue={amount_added}
							title="Volume Added (litres)"
							className="mb-10" />
					}

					{currentStep === 3 &&
						<div className="w-full text-center flex flex-col flex-grow border border-grey-mid rounded px-2 bg-sptTB mb-2 w-full pb-2">
							<InputGroup label="Start Time"
								type={'time'}
								value={start_time}
								onChange={(value: any) => setTime(value)}
								className="text-base sm:text-base md:text-xl w-full text-center"
								inputCSS="form-control-xxl text-center text-4xl w-60 mx-auto mt-3"
							/>
						</div>
					}

				</div>

				<MissingRequiredField />


				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">


							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?

								<div className="flex mt-4">
									<Button theme="secondary" 
										className="mr-5 text-sm sm:text-base px-3.5 md:px-7" 
										onClick={() => setDeleteConfirmOpen(false)}>
											Cancel
									</Button>

									<Button onClick={() => {
												onClose()
												onComplete({
													guid: guid,
													update: false,
													delete: true,
												})
												setShowWaterAddedEdit(false)
											}}
										className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">
										Delete
									</Button>
								</div>
							</div>


						</div>
					</div>
				}

				<div className="flex">
					{/* <Button theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button> */}
					<DeleteButton />

					<div className="flex ml-auto">
						<Button theme="primary" size="lg" className="px-4 sm:px-10" onClick={() => previous()}>Back</Button>
						<Button theme={saveButtonColor}
							size="lg"
							className="ml-4 px-8 sm:px-10 w-32 sm:w-60"
							onClick={() => next()}>{saveButtonText}
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

WaterAdded.defaultProps = defaultProps

export default WaterAdded
