import { UPDATE_SELECTED_PROJECT } from '../action-types'

const defaultState: object = {guid: '', name: '', location: '', number: '',}

const SelectedProjectReducer = (state = defaultState, action: any) => {
	switch (action.type) {
		case UPDATE_SELECTED_PROJECT: {
			return {
				guid: action.payload.project.guid,
				name: action.payload.project.name,
				location: action.payload.project.location,
				number: action.payload.project.number,
			}
		}
		default: {
			return state
		}
	}
}

export default SelectedProjectReducer