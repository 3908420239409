import { get, del, set } from 'idb-keyval';

export const activateHole = (hole_guid: string, project_guid: string): Promise<boolean> => {
    console.log('activateHole helper');
    
    return new Promise(async (resolve, reject) => {
        const endpoint = `${process.env.REACT_APP_API_ADDR}hole-locations/activate/${hole_guid}`;
        
        const updateData = {
            'status': '2'
        };

        try {
            const response = await makeRequest(endpoint, JSON.stringify(updateData), 'PUT');
            if (response.ok) {
                console.log('Hole information updated successfully.');
                await updateHoleInIDB(hole_guid, updateData);
                await updateHoleInProjectIDB(project_guid, hole_guid, updateData); // Update project
                resolve(true);
            } else {
                console.error('Failed to update hole information.');
                await saveUpdateToIDB(hole_guid, updateData); // create IDB -update key
                await updateHoleInIDB(hole_guid, updateData); // update hole
                await updateHoleInProjectIDB(project_guid, hole_guid, updateData); // Update project
                resolve(false);
            }
        } catch (err) {
            console.error('Error updating hole information:', err);
            await saveUpdateToIDB(hole_guid, updateData); // create IDB -update key
            await updateHoleInIDB(hole_guid, updateData); // update hole
            await updateHoleInProjectIDB(project_guid, hole_guid, updateData); // Update project
            resolve(false);
        }
    });
}


const makeRequest = (endpoint: string, data: string, postOrPut: string): Promise<Response> => {
    const token = localStorage.getItem('drillit-token');

    return fetch(endpoint, {
        method: postOrPut,
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: data
    });
}

const saveUpdateToIDB = async (hole_guid: string, updateData: any) => {
    const idbKey = `drillit-hole-locations/activate/${hole_guid}-update`;

    try {
        const existingRecord = await get(idbKey);
        if (existingRecord) {
            // If a record already exists, merge the new data with the existing data
            const updatedRecord = { ...existingRecord, ...updateData };
            await set(idbKey, updatedRecord);
        } else {
            // If no existing record, create a new one
            await set(idbKey, updateData);
        }
        console.log(`Saved or updated hole information for ${hole_guid} in IndexedDB.`);
    } catch (err) {
        console.error(`Error saving hole information to IndexedDB for ${hole_guid}:`, err);
    }
};

const updateHoleInIDB = async (hole_guid: string, updateData: any) => {
    const idbKey = `drillit-hole-locations/${hole_guid}`;

    try {
        const existingRecord = await get(idbKey);
        if (existingRecord) {
            const updatedRecord = { ...existingRecord, ...updateData };
            await set(idbKey, updatedRecord);
            console.log(`Updated hole information for ${hole_guid} in IndexedDB.`);
        } else {
            console.error(`Hole record with GUID ${hole_guid} not found in IndexedDB.`);
        }
    } catch (err) {
        console.error(`Error updating hole information in IndexedDB for ${hole_guid}:`, err);
    }
};



const updateHoleInProjectIDB = async (project_guid: string, hole_guid: string, updateData: any) => {
    const idbKey = `drillit-hole-locations/project/${project_guid}`;

    console.log('*************** updateHoleInProjectIDB ***************')

    try {
        // Retrieve the entire project object from IndexedDB
        const projectObj = await get(idbKey);
        console.log('projectObj', projectObj)
        if (!projectObj) {
            console.error(`Project record with GUID ${project_guid} not found in IndexedDB.`);
            return;
        }

        // Ensure the holes array exists within the project object and is an array
        if (!projectObj.holes || !Array.isArray(projectObj.holes)) {
            console.error(`Project record with GUID ${project_guid} does not have a valid 'holes' array.`);
            return;
        }

        // Find the index of the hole to update within the holes array
        const indexToUpdate = projectObj.holes.findIndex((hole: any) => hole.guid === hole_guid);
        console.log('indexToUpdate', indexToUpdate)

        if (indexToUpdate === -1) {
            console.error(`Hole with GUID ${hole_guid} not found in project record.`);
            return;
        }

        // Update the specific hole with the new data
        const updatedHoles = [...projectObj.holes];
        updatedHoles[indexToUpdate] = { ...updatedHoles[indexToUpdate], ...updateData };
        console.log('updatedHoles', updatedHoles)

        // Update the project object with the modified holes array
        const updatedProjectObj = { ...projectObj, holes: updatedHoles };

        console.log('updatedProjectObj:', updatedProjectObj)

        // Save the updated project object back to IndexedDB
        await set(idbKey, updatedProjectObj);
        console.log(`Updated hole information in project for ${project_guid} in IndexedDB.`);
    } catch (err) {
        console.error(`Error updating project information in IndexedDB for ${project_guid}:`, err);
    }
};


// const updateHoleInProjectIDB = async (project_guid: string, hole_guid: string, updateData: any) => {
//     const idbKey = `drillit-hole-locations/project/${project_guid}`;

//     try {
//         const existingProject = await get(idbKey);
//         if (existingProject && Array.isArray(existingProject)) {
//             const updatedProject = existingProject.map(hole => {
//                 if (hole.guid === hole_guid) {
//                     return { ...hole, ...updateData };
//                 }
//                 return hole;
//             });

//             await set(idbKey, updatedProject);
//             console.log(`Updated hole information in project for ${project_guid} in IndexedDB.`);
//         } else {
//             console.error(`Project record with GUID ${project_guid} not found in IndexedDB.`);
//         }
//     } catch (err) {
//         console.error(`Error updating project information in IndexedDB for ${project_guid}:`, err);
//     }
// };


// import { del } from 'idb-keyval';

// export const activateHole = (action_guid: string) => {
//     console.log('close hole helper')
//     return new Promise((resolve, reject) => {
//         const endpoint = `${process.env.REACT_APP_API_ADDR}hole-locations/activate/${action_guid}`
//         // make a date string
//         const activatedAt = {
//             'status': '2'
//         }

//         makeRequest(endpoint, JSON.stringify(activatedAt), 'PUT')

//         console.log('STATUS: ACTIVE')

//             // .then(() => {
//             //     del(`drillit-hole-actions/${action_guid}`)
//             //     console.log('cleanup on aisle 9')
//             // })

//         return resolve(true)
//     })
// }

// const makeRequest = (endpoint: string, data: string, postOrPut: string) => {
//     const token = localStorage.getItem('drillit-token')

//     return fetch(endpoint, {
//         method: postOrPut,
//         mode: 'cors',
//         headers: {
//             'Content-Type': 'application/json',
//             'Accept': 'application/json',
//             'Authorization': `Bearer ${token}`,
//         },
//         body: data
//     })
//         //return the status code here
//         .then(response => response)
//         .catch(err => err)
// }
