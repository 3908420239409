import React, { FunctionComponent, useState } from 'react'
import ButtonOptions from './form/ButtonOptions'

type Props = {
	className?: string,
	aboveInput?: JSX.Element,
	defaultValues: any,
	buttonoptions: any,
	title?: string,
	presets?: any[],
	// buttonOption: string,
	onUpdate: (value: string) => void,
	[x: string]: any,
	
}

const defaultProps: Props = {
	className: '',
	title: '',
	aboveInput: <></>,
	presets: [],
	// buttonOption: '',
	onUpdate: (value: any) => {},
	defaultValues: {
	},
	buttonoptions: {
		loadUnits: '',
		inputOption1: '',
		inputOption2: '',
		inputOption1Title: '',
		inputOption2Title: '',
	},
}

const keys = [
	'1', '2', '3',
	'4', '5', '6',
	'7', '8', '9',
	'.', '0',
]

const NumberInputOptions: FunctionComponent<Props> = ({ className, defaultValues, buttonoptions, aboveInput, presets, title,  onUpdate, ...rest }) => {

	const [value, setValue] = useState(defaultValues.numericValue)

	const [loadUnits, setLoadUnits] = useState(buttonoptions.loadUnits)
	// const [inputOption1, setInputOption1] = useState(buttonoptions.inputOption1)
	// const [inputOption2, setInputOption2] = useState(buttonoptions.inputOption2)

	// const units = buttonoptions.loadUnits
	// console.log('Number Input loadUnits: ', loadUnits)

	const inputOption1 = buttonoptions.inputOption1
	const inputOption2 = buttonoptions.inputOption2
	const inputOption1Title = buttonoptions.inputOption1Title
	const inputOption2Title = buttonoptions.inputOption2Title
	const keypadVisible = buttonoptions.keyboard

	// const defaultLoadUnit = buttonoptions.loadUnits

	// console.log('defaultValues: ', defaultValues)

	// console.log('buttonoptions: ', buttonoptions)
	// console.log('buttonoptions.loadUnits: ', buttonoptions.loadUnits)
	// console.log('buttonoptions.inputOption1: ', inputOption1)
	// console.log('buttonoptions.inputOption2: ', inputOption2)
	// console.log('buttonoptions.inputOption1: ', inputOption1Title)
	// console.log('buttonoptions.inputOption2: ', inputOption2Title)
	// console.log('defaultLoadUnit: ', defaultLoadUnit)

	const addCharacter = (character: string) => {
		let newValue = value

		if(value === '')
			newValue = character
		else
			newValue = value + character

		setValue(newValue)
		onUpdate(newValue)
	}

	const removeCharacter = () => {
		let newValue = value

		if(value !== '') {
			if(value.length === 1)
				newValue = ''
			else
				newValue = value.slice(0, -1)
		}

		setValue(newValue)
		onUpdate(newValue)
	}

	// const presetClick = (value: string) => {
	// 	setValue(value)
	// }

	return (
		<div className={`w-full ${className}`}>
			{title ? <div className="text-base sm:text-xl mb-4 text-center">{title}</div> : <></>}

			{(presets && presets.length) ?
				<>
					<div className="flex mx-auto">

						<div className="border border-orange rounded pt-3 text-center text-4xl bg-white h-16 mx-auto w-1/2 mr-2">
							{value ? value : ''}
						</div>

						<div className="mx-auto w-60 sm:w-60">

						<ButtonOptions onUpdate={(value: any) => setLoadUnits(loadUnits)} 
								defaultValue={loadUnits}
								title="" 
								columns={2} 
								columnsMobile={2} 
								buttonHeight={12}
								buttonHeightMobile={12}
								className="w-20 text-2xl font-bold" 
								options={[
									// 'kN',
									// 'T',
									inputOption1,
									inputOption2,
								]}  
								/>

								<div className="grid grid-cols-2">
									<div className="text-xs text-center">{inputOption1Title}</div>
									<div className="text-xs text-center">{inputOption2Title}</div>
								</div>

						</div>

					</div>
				</>
				:
				<>
				
					<div className="flex mx-auto">

						<div className="border border-orange rounded pt-3 text-center text-4xl bg-white h-16 mx-auto w-1/2 mr-2">
							{value ? value : ''}
						</div>

						<div className="mx-auto w-1/2">

							<ButtonOptions onUpdate={(value: any) => setLoadUnits(loadUnits)} 
								defaultValue={loadUnits}
								title="" 
								columns={2} 
								columnsMobile={2} 
								buttonHeight={12}
								buttonHeightMobile={12}
								className="w-20 text-2xl font-bold" 
								options={[
									// 'kN',
									// 'T',
									inputOption1,
									inputOption2,
								]}  
								/>

								<div className="grid grid-cols-2">
									<div className="text-xs text-center">{inputOption1Title}</div>
									<div className="text-xs text-center">{inputOption2Title}</div>
								</div>

						</div>

					</div>
				</>
			}

			{aboveInput}

{keypadVisible &&

			<div className="mx-auto w-full">
				<div className="grid grid-cols-3 gap-3 mx-0 mt-8">
					{keys.map((key, index) => {
						return <div className="border border-orange bg-white rounded py-3 text-center text-2xl" key={index} onClick={() => addCharacter(key)}>{key}</div>
					})}

					<div className="border border-orange bg-white rounded pt-4 text-center text-base" onClick={() => removeCharacter()}>DEL</div>
				</div>
			</div>
}

		</div>
	)
}

NumberInputOptions.defaultProps = defaultProps

export default NumberInputOptions