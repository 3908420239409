import React, { FunctionComponent, useState, useEffect } from 'react'

type Props = {
    className?: string,
    inputCSS?: string,
    labelCSS?: string,
    title?: string,
    aboveInput?: JSX.Element,
    values: any[];
    [x: string]: any;
}

const defaultProps: Props = {
    className: '',
    inputCSS: '',
    labelCSS: '',
    values: [],
    aboveInput: <></>,
}

const keys = [
    '1', '2', '3',
    '4', '5', '6',
    '7', '8', '9',
    '.', '0',
]

const MultiNumberInput: FunctionComponent<Props> = ({ values, aboveInput, title, className, inputCSS, labelCSS, dryButton, ...rest }) => {
    // const [value, setValue] = useState(defaultValue)
    const [active, setActive] = useState(0)
    // console.log("title: ", title)
    const addCharacter = (character: string) => {
        let newValue = values[active].defaultValue

        // if(values[active].defaultValue === '0')
        //     newValue = character
        // else
        //     newValue = values[active].defaultValue + character

        // Special case for handling '0.'
        if (character === '.' && newValue === '0') {
            newValue = '0.';
        } else {
            // Regular case for appending the character
            newValue = newValue + character;
        }

        values[active].onUpdate(newValue)
    }

    const removeCharacter = () => {
        let newValue = values[active].defaultValue

        if(values[active].defaultValue !== '') {
            if(values[active].defaultValue.length === 1)
                newValue = ''
            else
                newValue = values[active].defaultValue.slice(0, -1)
        }

        values[active].onUpdate(newValue)
    }
    const addDry = () => {
        let newValue = 'dry'

        values[active].onUpdate(newValue)
    }

    const [showDryButton, setShowDryButton] = useState(false)

	useEffect(() => {

		if (dryButton === true) {
			setShowDryButton(true)
		} else {
			setShowDryButton(false)
		}
		
	  }, [dryButton]);

    return (
        <div className="border border-grey-mid rounded px-2 bg-sptTB mb-2 w-full">
            <div className={`w-full ${className}`}>
				{title ? <div className="text-base sm:text-xl text-center">{title}</div> : <></>}
                <div className="flex justify-between mx-auto w-full sm:w-4/5 md:w-4/5 lg:w-3/5">
                    {values.map((value, index) => (
                        <div className="flex-1 px-1 md:px-2 text-center" key={index}>
                            <p className={`${labelCSS}`}>{value.label}</p>

                            <div className={`border ${active === index ? 'bg-blue text-white' : 'bg-white'} border-orange rounded py-3 text-center ${inputCSS}`} onClick={() => setActive(index)}>
                                {value.defaultValue ? value.defaultValue : ''}
                            </div>
                        </div>
                    ))}
                </div>

                {aboveInput}

                <div className="grid grid-cols-3 gap-3 mx-0 sm:mx-20 md:mx-20 lg:mx-40">
                    {keys.map((key, index) => (
                        <div className="border border-orange bg-white rounded py-2 text-center text-2xl" key={key} onClick={() => addCharacter(key)}>{key}</div>
                    ))}

                    {/* <div className="border border-orange bg-white rounded py-2 text-center text-2xl" onClick={() => removeCharacter()}>⌫</div> */}
                    <div className="border border-orange bg-white rounded py-3 text-center text-base" onClick={() => removeCharacter()}>DEL</div>
                </div>

                {showDryButton &&
                    <div className="mx-0 sm:mx-20 md:mx-20 lg:mx-40 mt-4" onClick={() => addDry()}>
                        {/* <div className="border border-orange bg-white rounded py-1 text-center text-2xl"> */}
                        <div className="border border-blue bg-blue rounded py-1 text-center text-2xl text-white">
                            dry
                        </div>
                    </div>
                }

            </div>
        </div>
    )
}

MultiNumberInput.defaultProps = defaultProps

export default MultiNumberInput
