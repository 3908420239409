import React from 'react'
import {
	Link, Redirect
} from "react-router-dom"

import { get, set } from 'idb-keyval'

import Button from '../components/form/Button'
import InputGroup from '../components/form/InputGroup'
import Checkbox from '../components/form/Checkbox'

// import logo from '../assets/logo.svg'
import logoOuter from '../assets/logo-icon-outer.svg'
import logoCenter from '../assets/logo-icon-center.svg'
import logoText from '../assets/logo-text.svg'
// import background from '../assets/login-bg.png'
import background from '../assets/login-bg-new.jpg'

interface Props {
	
}

interface State {
	email: string,
	password: string,
	remember: boolean,
	loggedStatus: number,
	failedLogin: string,
	redirectAfterAuthentication: boolean,
}

class Login extends React.Component<Props, State> {
	constructor(props: any) {
		super(props)

		this.state = {
			email: '',
			password: '',
			remember: false,
			loggedStatus: 0,
			failedLogin: '',
			redirectAfterAuthentication: false,
		}
	}

	// loginUser = async (email: string, password: string) => {
	// 	const credentials = {email, password}
	// 	return fetch(`${process.env.REACT_APP_API_ADDR_NOV1}login`, {
	// 		method: 'POST',
	// 		headers: {
	// 			'Content-Type': 'application/json',
	// 			'Accept': 'application/json',
	// 		},
	// 		body: JSON.stringify(credentials)
	// 	})
	// 	.then((response) => {
	// 		this.setState({
	// 			loggedStatus: response.status
	// 		})
	// 		return response.json()
	// 	})
	// }

	loginUser = async (email: string, password: string) => {
		const credentials = { email, password };
		try {
		  const response = await fetch(`${process.env.REACT_APP_API_ADDR_NOV1}login`, {
			method: 'POST',
			headers: {
			  'Content-Type': 'application/json',
			  'Accept': 'application/json',
			},
			body: JSON.stringify(credentials)
		  });
	  
		  this.setState({
			loggedStatus: response.status
		  });
	  
		  if (!response.ok) {
			// If the response is not ok, log the status and message
			console.error(`Error: ${response.status} - ${response.statusText}`);
			return null;
		  }
	  
		  return await response.json();
		} catch (error) {
		  // Log any errors that occur during the fetch
		  console.error('There was an error!', error);
		  return null;
		}
	  }

	// handleSubmit = async (event: any) => {
	// 	event.preventDefault()

	// 	const token = await this.loginUser(
	// 		this.state.email,
	// 		this.state.password
	// 	)

	// 	if ((this.state.loggedStatus === 200) && (token.user.token)) {
	// 		// put the user data into IndexedDB
	// 		await set('drillit-user', token)
	// 		localStorage.setItem('drillit-token', token.user.token)
	// 		this.setState({redirectAfterAuthentication: true})
	// 	}

	// 	if (this.state.loggedStatus === 401) {
	// 		this.setState({
	// 			failedLogin: 'Your username or password were not recognised'
	// 		})
	// 	}
	// }

	handleSubmit = async (event: any) => {
		event.preventDefault();
	  
		try {
		  const token = await this.loginUser(
			this.state.email,
			this.state.password
		  );
	  
		  if (!token) {
			// If token is null, the login failed due to some error
			this.setState({
			  failedLogin: 'There was an error logging in. Please try again.'
			});
			return;
		  }
	  
		  if (this.state.loggedStatus === 200 && token.user.token) {
			// Put the user data into IndexedDB
			await set('drillit-user', token);
			localStorage.setItem('drillit-token', token.user.token);
			this.setState({ redirectAfterAuthentication: true });
		  } else if (this.state.loggedStatus === 401) {
			this.setState({
			  failedLogin: 'Your username or password were not recognised'
			});
		  } else {
			// Handle other potential statuses
			this.setState({
			  failedLogin: 'An unexpected error occurred. Please try again.'
			});
		  }
		} catch (error) {
		  // Log any errors that occur during the submission process
		  console.error('Error during handleSubmit:', error);
		  this.setState({
			failedLogin: 'An unexpected error occurred. Please try again.'
		  });
		}
	  }
	  

	render() {	

		return (

			

			<div className="absolute inset-0 bg-no-repeat bg-center loginBG" style={{backgroundImage: `url(${background})`}}>
				{this.state.redirectAfterAuthentication ? <Redirect to="/" /> : ''}
				{/* <div className="absolute inset-0 bg-grey-mid bg-opacity-95"> */}
				<div className="absolute inset-0">
					{/* <div className="flex justify-center w-full py-6 bg-opacity-50 border-t-8 border-orange bg-grey-dark"> */}
					<div className=" w-full mx-auto py-6 border-t-8 border-orange">
						
						<div className="w-24 sm:w-10 md:w-24 mx-auto">
						
							<img src={logoOuter} className="logo-spin absolute w-24 sm:w-10 md:w-24 mx-auto" alt="logo" />
							<img src={logoCenter} className="absolute w-24 sm:w-10 md:w-24 mx-auto" alt="logo inner" />

						</div>	

							<div className="w-64 sm:w-32 md:w-64 mt-24 sm:mt-10 md:mt-24 mb-5 mx-auto text-center"> 
								<img src={logoText} className="w-64 sm:w-32 md:w-64 mx-auto absolute" alt="drillitlogs" />
							</div>

					</div>

					{/* <div className="absolute inset-0 flex items-center justify-center"> */}
					<div className="mt-10 sm:mt-4 md:mt-10 inset-0 flex items-center justify-center" style={{ fontFamily: 'Roboto' }}>
						<div className="w-80 modalshadow">
							<div className="px-4 py-1 text-lg text-white rounded-t white bg-orange">
								Login 
							</div>

							<div className="px-4 py-2 bg-white rounded-b text-base">
								{this.state.failedLogin && <div>{this.state.failedLogin}</div>}

								<form onSubmit={this.handleSubmit}>
									<InputGroup label="Username" inputCSS="text-base" placeholder="name@domain.com" value={this.state.email} onChange={(value) => this.setState({email: value})} />

									<InputGroup type="password" label="Password" value={this.state.password} onChange={(value) => this.setState({password: value})} />

									<div className="flex justify-between mb-5">
										<Checkbox label="Remember me" checked={this.state.remember} onChange={(value) => this.setState({remember: value})} />

										{/* <div className="">
											<Link to="/forgot" className="link">Forgot password</Link>
										</div> */}
									</div>

									<div className="flex justify-between">
										<Button type="submit" theme="primary">Log in</Button>
									</div>
								</form>	

	


							</div>
						</div>
					</div>

					<div className="text-xs text-center mt-10 font-bold">
						Version upload date: 10/09/2024 @ 14:00
					</div>

				</div>
			</div>




			
		)
	}
}



export default Login


 
