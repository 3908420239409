import React, { FunctionComponent, useState } from 'react'

import Modal from '../../Modal'
import Button from '../../form/Button'
// import NumberInput from '../../NumberInput'
// import InputGroup from '../../form/InputGroup'
import TextArea from '../../form/TextArea'
// import warningIcon from '../../../assets/icons/warning.png'

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: any,
	borehole_guid?: string,
	title: string,
	// torquetext1: string,
	// torquetext2: string,
	rows?: number,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => {},
	onComplete: () => {},
	borehole: '',
	borehole_guid: '',
	title: '',
	// torquetext1: '',
	// torquetext2: '',
	rows: 150,
	defaultValues: {
		remarks: '',
	},
}

const initializeCells = (rows: number): string[] => {
	let cells: any = {}

	for(const row in [...Array(rows)]) {
		for(const column in [...Array(10)]) {
			cells[parseInt(row + '' + column) + 0] = ''
		}
	}

	return cells
}

const keys = [
	'1', '2', '3',
	'4', '5', '6',
	'7', '8', '9',
	'', '0',
]

const CBR: FunctionComponent<Props> = ({ onClose, onComplete, borehole, borehole_guid, defaultValues, title, rows = 50, ...rest }) => {
	const [activeCell, setActiveCell] = useState<number>(0)
	// const [numRows, setNumRows] = useState<number>(rows)
	const numRows = rows

	const [cells, setCells] = useState<string[]>(initializeCells(numRows))

	const [remarks, setComment] = useState(defaultValues.remarks)
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	const DeleteButton = () => {
		// if (!update) return null;
	
		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	const deleteRecordConfirm = () => {
		setDeleteConfirmOpen(false)
		// onComplete()
	}

	const addCharacterCell = (character: string) => {
		let newValue = cells[activeCell]

		if(cells[activeCell] === '0')
			newValue = character
		else
			newValue = cells[activeCell] + character

		let newCells = {...cells}
		newCells[activeCell] = newValue

		setCells(newCells)
	}

	const addCharacter = (character: string) => {
		addCharacterCell(character)
	}

	const removeCharacterCell = () => {
		let newValue = cells[activeCell]

		if(cells[activeCell] !== '') {
			if(cells[activeCell].length === 1)
				newValue = ''
			else
				newValue = cells[activeCell].slice(0, -1)
		}

		let newCells = {...cells}
		newCells[activeCell] = newValue

		setCells(newCells)
	}

	const removeCharacter = () => {
		removeCharacterCell()
	}

	const save = () => {
		const holeId = borehole_guid
		
		onComplete({
			cells,
			holeId,
		})
	}

	

	return (
		<Modal title={title} subtitle={borehole} toggleModal={onClose}>

			<div className="flex flex-col">
						<div className="mb-2 pt-2">

				<div className="grid grid-cols-12 gap-2">

					<div className="col-span-6 mb-2">   {/* LEFT SIDE - INPUTS */}
					

						<div className="">
							<div className="grid grid-cols-2 gap-0 font-bold text-center bg-grey-mid ">
								<div className={`col-span-1 px-2 py-1 text-sm `}>Penetration on Plunger</div>
								<div className={`col-span-1 px-2 py-1 text-sm `}>Guage Reading</div>
								{/* <div className={`col-span-1 px-2 py-0 text-left text-sm mt-6`}><p className="transform -rotate-90">+Rod</p></div> */}
							</div>
						</div>

						<div className="">
							{/* <div className="grid grid-cols-14 gap-0 text-center overflow-auto" style={{'height': '470px'}}> */}
							<div className="text-center overflow-auto border-b border-grey-mid cbr_penetration_data_height">
								
                                {/* NUMBERS */}
                                {/* <div className={`col-span-1 px-0 text-xs`}>
									{[...Array(numRows+1)].map((_, row) => {
										return <div className={`flex items-center justify-center py-0 border-l border-b border-r border-grey-mid`} 
											style={{'height': '30px'}} key={'rowl' + row}>{row++}</div>
									})}
								</div> */}


								<div className="grid grid-cols-2 gap-0 text-center text-sm md:text-base">
										{[...Array(1)].map((_, column) =>
											<div className={`col-span-1`} key={'column' + column}>

												{[...Array(30+1)].map((_, row) =>
													<React.Fragment key={'row' + row} >
														<div className={`w-full border-l border-r border-grey-mid`}>
															<div
																className={`border-b border-grey-mid h-6 py-0.5 md:py-0 ${row % 2 ? 'bg-grey-light' : 'bg-white'} `}
																>
																{((row++)/4).toFixed(2)}
															</div>
														</div>
													</React.Fragment>
												)}
											</div>
										)}

										
										{[...Array(1)].map((_, column) =>
											<div className={`col-span-1`} key={'column' + column}>

												{[...Array(30+1)].map((_, row) =>
													<React.Fragment key={'row' + row}>
														<div className={`w-full border-r border-grey-mid text-sm md:text-base`}>
															<div
																className={`border-b border-grey-mid h-6 py-0.5 md:py-0 ${row % 2 ? 'bg-grey-light' : 'bg-white'} ${(activeCell === parseInt(row + '') + 0) ? 'bg-blue text-white incrementHeight' : ''} `}
																
																onClick={() => {
																	setActiveCell(parseInt(row + ''))
																}}
																tabIndex={parseInt(row + '')} 
																>
																
																{cells[parseInt(row + '')]}
															</div>
														</div>
													</React.Fragment>
												)}
											</div>
										)}
									</div>
								
                            </div>

						</div>
					</div>
					
					{/* BUTTONS ETC */}

					<div className="col-span-6"> {/* BUTTONS GRID */}

						<div className="bg-sptTB rounded p-2">							

							<div className="w-full">

									<div className="w-1/3 inline-flex"> {/* BACK BUTTON */}
										<Button theme="outline" type="button" className="w-full text-base md:text-2xl lg:text-xl py-7 md:py-10 lg:py-5  mr-1 lg:mr-2 mb-1 lg:mb-2" onClick={() => {
											setActiveCell(activeCell === 1 ? activeCell : activeCell - 1)
										}}>&lt;</Button>
									</div>

									<div className="w-2/3 inline-flex"> {/* NEXT BUTTON */}
										<Button theme="outline" type="button" className="w-full text-base md:text-2xl lg:text-xl py-7 md:py-10 lg:py-5 mb-1 lg:mb-2" onClick={() => {
											setActiveCell(activeCell === (numRows * 10) + 9 ? activeCell : activeCell + 1)
										}}>Next &gt;</Button>
									</div>

							</div>

							<div className="w-full mt-4">
								<div className="grid grid-cols-3 gap-1 lg:gap-2">  {/* NUMBER BUTTONS */}
									{keys.map((key, index) => {
										return <div className="border border-orange rounded py-4 md:py-6 lg:py-4 text-2xl md:text-4xl text-center bg-white" key={index} onClick={() => addCharacter(key)}>{key}</div>
									})}

									<div className="border border-orange rounded pt-5 md:py-6 lg:py-4 text-base text-center bg-white" onClick={() => removeCharacter()}>DEL</div>
								</div>
							</div>
											
							<div className="col-span-12 w-full h-20 mt-5">
								<TextArea 
								label="" 
								value={remarks}
								placeholder="Comments"
								onChange={(value: any) => setComment(value)} 
								className="w-full mx-auto align-text-top"
								inputCSS="overflow-x-auto text-sm h-20"
								numberOfRows={2}
								/>
							</div>

						</div>

					</div>



				</div>
				</div>

				{/*<div className="flex items-center mt-10">
					<Button theme="primary" type="button" onClick={() => }>Comments</Button>
				</div>*/}
				

				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">
	
	
							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?
	
								<div className="flex mt-4">
									<Button theme="secondary" className="mr-5 text-sm sm:text-base px-3.5 md:px-7" onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
	
									<Button onClick={deleteRecordConfirm} className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">Delete</Button>
								</div>
							</div>
	
	
						</div>
					</div>
				}

				<div className="flex items-center mt-2">
					{/* <Button theme="secondary" type="button" size="lg">Delete CBR</Button> */}

					<DeleteButton/>

					<Button theme="primary" type="button" size="lg" className="ml-auto w-48" onClick={()=>save()}>Save</Button>
				</div>
			</div>
		</Modal>
	)
}

CBR.defaultProps = defaultProps

export default CBR
