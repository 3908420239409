import React from 'react'

import Header from '../../layout/Header'

import Modal from '../../components/Modal'
import Button from '../../components/form/Button'
import InputGroup from '../../components/form/InputGroup'

import SelectGroup from "../../components/form/SelectGroup";
import { useGetIDBValues } from "../../helpers/getIDBKey";
import { useSetIndexedDBValues } from "../../helpers/addToIDB";
import { updateRig } from "../../helpers/updateRig";
import { Redirect } from "react-router-dom";
import { get, set } from "idb-keyval";

interface Props {
	toggleSidebar: () => void,
	router: any,
}

interface State {
	name: string,
	type: string,
	plant_id: string,
	make_model: string,
	test_date_expiry: string,
	hammers: any,
	allSPThammers: any,
	spt_guid: '',
	spt: any,
	test_method: string,
	guid: string,
	company_guid: string,
	redirect: boolean,
	companyRigs: any,

	dp_test_type: string,
	dp_test_ref: string,
	dprg_anvil_type: string,
	dprg_anvil_dampner: string,
	dprg_type: string,
	dprg_hammer_mass: string,
	dprg_standard_drop: string,
	dprg_cone_diameter: string,
	dprg_rod_diameter: string,
	dprg_rod_mass: string,
	torque_default: string,
	deleteConfirmOpen: boolean,
	deleteRecordConfirm: boolean,
}

interface SaveState {
	name: string;
	type: string;
	plant_id: string;
	make_model: string;
	test_date_expiry: string;
	guid: string;
	test_method: string;
	company_guid: string;
	spt_guid: string;
	spt: {
		spt_guid: string;
		test_date_expiry: string;
		test_method: string;
		torque_default: string;
		type: string;
	};

	dp_test_type: string;
	dp_test_ref: string;
	dprg_anvil_type: string;
	dprg_anvil_dampner: string;
	dprg_type: string;
	dprg_hammer_mass: number;
	dprg_standard_drop: number;
	dprg_cone_diameter: number;
	dprg_rod_diameter: number;
	dprg_rod_mass: number;
	torque_default: string;
	redirect: boolean;
}

class RigsUpdate extends React.Component<Props, State> {
	constructor(props: any) {
		super(props)

		this.state = {
			name: '',
			type: '',
			plant_id: '',
			make_model: '',
			test_date_expiry: '',
			hammers: [],
			allSPThammers: [],
			spt_guid: '',
			spt: {
				spt_guid: '',
				test_date_expiry: '',
				test_method: '',
				torque_default: '',
				type: '',
			},
			test_method: '',
			guid: this.props.router.match.params?.id,
			company_guid: this.props.router.match.params?.company,
			redirect: false,
			companyRigs: [],

			dp_test_type: '',
			dp_test_ref: '',
			dprg_anvil_type: '',
			dprg_anvil_dampner: '',
			dprg_type: '',
			dprg_hammer_mass: '',
			dprg_standard_drop: '',
			dprg_cone_diameter: '',
			dprg_rod_diameter: '',
			dprg_rod_mass: '',
			torque_default: '',
			deleteConfirmOpen: false,
			deleteRecordConfirm: false,
		}
	}

	// save = (event: any) => {
	// 	event.preventDefault()
	// 	const saveToIDB = useSetIndexedDBValues
	// 	this.setState({ spt_guid: this.state.spt.guid }, () => {
	// 		console.log('this.state Rig:', this.state)
	// 		// saveToIDB(this.state, 'rigs', false, 'PUT')
	// 		updateRig(
	// 			this.state.guid,
	// 			this.state.name,
	// 			this.state.type,
	// 			this.state.plant_id,
	// 			this.state.make_model,
	// 			this.state.test_date_expiry,
	// 			this.state.spt.guid,
	// 			this.state.company_guid,
	// 			this.state.test_method,
	// 			this.state.dprg_type,
	// 			this.state.dprg_hammer_mass,
	// 			this.state.dprg_standard_drop,
	// 			this.state.dprg_cone_diameter,
	// 			this.state.dprg_rod_diameter,
	// 			this.state.dprg_rod_mass,
	// 			this.state.dprg_anvil_type,
	// 			this.state.dprg_anvil_dampner,
	// 			this.state.torque_default,
	// 			this.state.dp_test_ref,
	// 			this.state.dp_test_type,
	// 		)
	// 			.then(() => {
	// 				// update the rig-for-project key for this value only
	// 				// put this one back in after API updated
	// 				// get(`drillit-rigs/company/${this.props.router.match.params.company}`).then(result => {
	// 				get(`drillit-rigs/company/${this.state.company_guid}`).then(result => {
	// 					// get(`drillit-rigs`).then(result => {
	// 					console.log('Company Rigs:', result)
	// 					if (result) {
	// 						// find rig with guid 
	// 						// update rig with guid updated rig data
	// 					}
	// 				}).then(result => {
	// 					// save company rigs to the IDB
	// 					set(`drillit-rigs/company/${this.state.company_guid}`, this.state)
	// 				})
	// 			})
	// 			.then(() => setTimeout(() => this.setState({ redirect: true }), 1000))
	// 	})
	// }

	// save = (event: any) => {
	// 	event.preventDefault();
	
	// 	this.setState({ spt_guid: this.state.spt.guid }, () => {
	// 		console.log('this.state Rig:', this.state);
	
	// 		updateRig(
	// 			this.state.guid,
	// 			this.state.name,
	// 			this.state.type,
	// 			this.state.plant_id,
	// 			this.state.make_model,
	// 			this.state.test_date_expiry,
	// 			this.state.spt.guid,
	// 			this.state.company_guid,
	// 			this.state.test_method,
	// 			this.state.dprg_type,
	// 			this.state.dprg_hammer_mass,
	// 			this.state.dprg_standard_drop,
	// 			this.state.dprg_cone_diameter,
	// 			this.state.dprg_rod_diameter,
	// 			this.state.dprg_rod_mass,
	// 			this.state.dprg_anvil_type,
	// 			this.state.dprg_anvil_dampner,
	// 			this.state.torque_default,
	// 			this.state.dp_test_ref,
	// 			this.state.dp_test_type
	// 		)
	// 		.then(() => {
	// 			// Fetch the rigs associated with the company
	// 			return get(`drillit-rigs/company/${this.state.company_guid}`);
	// 		})
	// 		.then((result) => {
	// 			console.log('Company Rigs:', result);
	
	// 			if (result && Array.isArray(result)) {
	// 				// Find the index of the rig with the matching guid
	// 				const rigIndex = result.findIndex(rig => rig.guid === this.state.guid);
	
	// 				console.log('RIG UPDATING this.state:', this.state)

	// 				if (rigIndex !== -1) {
	// 					// // Update the rig with new values from this.state
	// 					// result[rigIndex] = { ...result[rigIndex], ...this.state };
	// 					// // Save the updated rigs array back to the IDB
	// 					// set(`drillit-rigs/company/${this.state.company_guid}`, result);
	// 				}
	// 			}
	// 		})
	// 		.then(() => setTimeout(() => this.setState({ redirect: true }), 1000))
	// 		.catch(error => {
	// 			console.error('Error updating rig:', error);
	// 		});
	// 	});
	// }

	save = async (event: any) => {
		event.preventDefault();
	
		this.setState({ spt_guid: this.state.spt.guid }, async () => {
			console.log('this.state Rig before update:', this.state);
	
			try {
				// Call API to update rig
				const updateSuccess = await updateRig(
					this.state.guid,
					this.state.name,
					this.state.type,
					this.state.plant_id,
					this.state.make_model,
					this.state.test_date_expiry,
					this.state.spt.guid,
					this.state.company_guid,
					this.state.test_method,
					this.state.dprg_type,
					this.state.dprg_hammer_mass,
					this.state.dprg_standard_drop,
					this.state.dprg_cone_diameter,
					this.state.dprg_rod_diameter,
					this.state.dprg_rod_mass,
					this.state.dprg_anvil_type,
					this.state.dprg_anvil_dampner,
					this.state.torque_default,
					this.state.dp_test_ref,
					this.state.dp_test_type
				);
	
				if (!updateSuccess) {
					throw new Error('Failed to update rig');
				}
	
				console.log('UpdateRig successful, fetching company rigs...');
	
				// Manually build the updated rig object
				const updatedRig = {
					guid: this.state.guid,
					name: this.state.name,
					type: this.state.type,
					plant_id: this.state.plant_id,
					make_model: this.state.make_model,
					test_date_expiry: this.state.test_date_expiry,
					spt: {
						calibration_date: this.state.spt.calibration_date,
						company_id: this.state.spt.company_id,
						energy_ratio: this.state.spt.energy_ratio,
						guid: this.state.spt.guid,
						id: this.state.spt.id,
						id_ref: this.state.spt.id_ref,
						rod_type: this.state.spt.rod_type
					},
					spt_guid: this.state.spt.guid,
					company_guid: this.state.company_guid,
					test_method: this.state.test_method,
					dprg_type: this.state.dprg_type,
					dprg_hammer_mass: this.state.dprg_hammer_mass,
					dprg_standard_drop: this.state.dprg_standard_drop,
					dprg_cone_diameter: this.state.dprg_cone_diameter,
					dprg_rod_diameter: this.state.dprg_rod_diameter,
					dprg_rod_mass: this.state.dprg_rod_mass,
					dprg_anvil_type: this.state.dprg_anvil_type,
					dprg_anvil_dampner: this.state.dprg_anvil_dampner,
					torque_default: this.state.torque_default,
					dp_test_ref: this.state.dp_test_ref,
					dp_test_type: this.state.dp_test_type
				};
	
				// Update the companyRigs array with the updated rig
				if (this.state.companyRigs && Array.isArray(this.state.companyRigs)) {
					const rigIndex = this.state.companyRigs.findIndex(rig => rig.guid === this.state.guid);
					
					if (rigIndex !== -1) {
						this.state.companyRigs[rigIndex] = updatedRig;
	
						// Save the updated rigs array back to the IndexedDB
						await set(`drillit-rigs/company/${this.state.company_guid}`, this.state.companyRigs);
					}
				}
	
				// Redirect after updating the IndexedDB
				// setTimeout(() => this.setState({ redirect: true }), 1000);
				this.setState({ redirect: true })
			} catch (error) {
				console.error('Error during save process:', error);
			}
		});
	};
	
	
	
	
	
	

	handleRigTypeChange = (value: any) => {
		this.setState({ type: value.value }, () => {
			console.log('Rig type after change:', this.state.type);
		});
	};

	handleSPTChange = (value: any) => {
		// Find the selected SPT in the allSPThammers array
		const selectedSPT = this.state.allSPThammers.find(
			(spt: any) => spt.guid === value.value
		);
	
		// Update the state with the selected SPT details
		if (selectedSPT) {
			this.setState({ spt: selectedSPT }, () => {
				console.log('SPT after change:', this.state.spt);
			});
		}
	};

	fetchAndSetCompanyRigs = async () => {
		try {
			const companyRigs = await get(`drillit-rigs/company/${this.state.company_guid}`);
			if (companyRigs && Array.isArray(companyRigs)) {
				this.setState({ companyRigs });
				console.log('Company rigs loaded:', companyRigs);
			} else {
				console.error('Failed to load company rigs.');
			}
		} catch (error) {
			console.error('Error fetching company rigs:', error);
		}
	};
	
	

	async componentDidMount() {
		try {
			// Load cache data and set state
			await this.getCacheValues();

			// Fetch and set company rigs
			await this.fetchAndSetCompanyRigs();

			// Check for dp_test_type and handle accordingly
			if (this.state.dp_test_type) {
				if (this.state.dp_test_type === 'DPSH-B') {
					this.handleTestTypeChange('Superheavy - 750mm drop');
				} else if (this.state.dp_test_type === 'DPL') {
					this.handleTestTypeChange('Light - 500mm drop');
				} else if (this.state.dp_test_type === 'DPM') {
					this.handleTestTypeChange('Medium - 500mm drop');
				} else if (this.state.dp_test_type === 'DPH') {
					this.handleTestTypeChange('Heavy - 500mm drop');
				} else if (this.state.dp_test_type === 'DPSH-A') {
					this.handleTestTypeChange('Superheavy - 500mm drop');
				}
			}

			// Any other logic you want to perform after data is loaded
		} catch (error) {
			console.error("Error loading data:", error);
		}
	}

	// Modify getCacheValues to use async/await
	async getCacheValues() {
		try {
			const cachedValues = useGetIDBValues;

			// Load SPT-hammers cache data
			const sptHammerData = await cachedValues('spt-hammers/company/' + this.state?.company_guid, (data: any) => {
				// Update state with the retrieved data
				console.log('hammers cachedValues:', data)
				let selectValues = [];
				for (const hammer of data) {
					let { guid, id_ref } = hammer;
					const newValue = {
						value: guid,
						label: id_ref
					};
					selectValues.push(newValue);
				}
				this.setState({
					hammers: selectValues,
					allSPThammers: data,
				});
			});

			// Load rigs cache data
			const rigData = await cachedValues('rigs/' + this.props.router.match.params?.id, (data: any) => {
				if (data) {
					console.log('Rig Data:', data)
					// Update state with the retrieved data
					this.setState({
						name: data.name || '',
						type: data.type || '',
						plant_id: data.plant_id || '',
						make_model: data.make_model || '',
						test_date_expiry: data.test_date_expiry || '',
						spt_guid: data.spt_guid || '',
						spt: data.spt || '',
						test_method: data.test_method || '',
						dprg_anvil_type: data.dprg_anvil_type || '',
						dprg_anvil_dampner: data.dprg_anvil_dampner || '',
						dprg_type: data.dprg_type || '',
						dprg_hammer_mass: data.dprg_hammer_mass || '',
						dprg_standard_drop: data.dprg_standard_drop || '',
						dprg_cone_diameter: data.dprg_cone_diameter || '',
						dprg_rod_diameter: data.dprg_rod_diameter || '',
						dprg_rod_mass: data.dprg_rod_mass || '',
						torque_default: data.torque_default || '',
						dp_test_type: data.dp_test_type || '',
						dp_test_ref: data.dp_test_ref || '',
					});
				} else {
					console.warn('No rig data found in cache');
				}
			});
		} catch (error) {
			console.error("Error loading data:", error);
		}
	}

	setDeleteConfirmOpen() {
		this.setState({ deleteConfirmOpen: !this.state.deleteConfirmOpen });
	}

	setDeleteRecordConfirm() {
		this.setState({ deleteRecordConfirm: !this.state.deleteRecordConfirm });
	}

	handleTestTypeChange = (value: any) => {
		this.setState({ dp_test_type: value.value }, () => {
			// Handle additional state updates based on dp_test_type
			if (value.value === 'Superheavy - 750mm drop') {
				this.setState({
					dprg_type: 'DPSH-B',
					dprg_hammer_mass: '63.5',
					dprg_standard_drop: '750',
					dprg_cone_diameter: '49',
					dprg_rod_diameter: '32',
					dprg_rod_mass: '8',
				});
			} else if (value.value === 'Light - 500mm drop') {
				this.setState({
					dprg_type: 'DPL',
					dprg_hammer_mass: '10',
					dprg_standard_drop: '500',
					dprg_cone_diameter: '34',
					dprg_rod_diameter: '22',
					dprg_rod_mass: '3',
				});
			} else if (value.value === 'Medium - 500mm drop') {
				this.setState({
					dprg_type: 'DPM',
					dprg_hammer_mass: '30',
					dprg_standard_drop: '500',
					dprg_cone_diameter: '42',
					dprg_rod_diameter: '32',
					dprg_rod_mass: '6',
				});
			} else if (value.value === 'Heavy - 500mm drop') {
				this.setState({
					dprg_type: 'DPH',
					dprg_hammer_mass: '50',
					dprg_standard_drop: '500',
					dprg_cone_diameter: '42',
					dprg_rod_diameter: '32',
					dprg_rod_mass: '6',
				});
			} else if (value.value === 'Superheavy - 500mm drop') {
				this.setState({
					dprg_type: 'DPSH-A',
					dprg_hammer_mass: '63.5',
					dprg_standard_drop: '500',
					dprg_cone_diameter: '43',
					dprg_rod_diameter: '32',
					dprg_rod_mass: '6',
				});
			}
		});
	};



	handleCancelClick = () => {
		window.history.back();
	};

	render() {
		const { redirect } = this.state

		if (redirect) {
			return <Redirect to={`/rigs/${this.props.router.match.params?.company}/${this.props.router.match.params?.project}`} />
		}

		// console.log(this.state.dp_test_type)
		// let dp_test_type_selected = this.state.dp_test_type
		// console.log('dp_test_type_selected:', this.state.dp_test_type)
		console.log('this.state.spt_guid:', this.state.spt_guid)
		console.log('this.state.spt:', this.state.spt)
		console.log('this.state:', this.state)
		console.log('this.state.hammers:', this.state.hammers)
		console.log('this.state.allSPThammers:', this.state.allSPThammers)

		let dp_type = ''
		var dp_hammer = 0
		let dp_drop = 0
		let dp_cone = 0
		let dp_rod_diameter = 0
		let dp_rod_mass = 0
		let dp_test_type = ''
		if (this.state.dprg_type === 'DPSH-B') {
			dp_type = 'DPSH-B'
			dp_hammer = 63.5
			dp_drop = 750
			dp_cone = 49
			dp_rod_diameter = 32
			dp_rod_mass = 8
			dp_test_type = 'Superheavy - 750mm drop'
		} else if (this.state.dprg_type === 'DPL') {
			dp_type = 'DPL'
			dp_hammer = 10
			dp_drop = 500
			dp_cone = 34
			dp_rod_diameter = 22
			dp_rod_mass = 3
			dp_test_type = 'Light - 500mm drop'
		} else if (this.state.dprg_type === 'DPM') {
			dp_type = 'DPM'
			dp_hammer = 30
			dp_drop = 500
			dp_cone = 42
			dp_rod_diameter = 32
			dp_rod_mass = 6
			dp_test_type = 'Medium - 500mm drop'
		} else if (this.state.dprg_type === 'DPH') {
			dp_type = 'DPH'
			dp_hammer = 50
			dp_drop = 500
			dp_cone = 42
			dp_rod_diameter = 32
			dp_rod_mass = 6
			dp_test_type = 'Heavy - 500mm drop'
		} else if (this.state.dprg_type === 'DPSH-A') {
			dp_type = 'DPSH-A'
			dp_hammer = 63.5
			dp_drop = 500
			dp_cone = 43
			dp_rod_diameter = 32
			dp_rod_mass = 6
			dp_test_type = 'Superheavy - 500mm drop'
		}

		var dp_hammer_text = ''
		var dp_drop_text = ''
		var dp_cone_text = ''
		var dp_rod_diameter_text = ''
		var dp_rod_mass_text = ''
		if (this.state.dprg_type) {
			dp_hammer_text = dp_hammer + 'kg'
			dp_drop_text = dp_drop + 'mm'
			dp_cone_text = dp_cone + 'mm'
			dp_rod_diameter_text = dp_rod_diameter + 'mm'
			dp_rod_mass_text = dp_rod_mass + 'kg'
		}

		console.log('this.state.dprg_type:', this.state.dprg_type)

		return (
			<>

				{this.state.deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">


							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this rig?

								<div className="flex mt-4">
									<Button theme="secondary" className="mr-5 text-sm sm:text-base px-3.5 md:px-7" onClick={() => this.setState({ deleteConfirmOpen: false })}>Cancel</Button>

									<Button onClick={() => this.setState({ deleteRecordConfirm: false })} className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">Delete</Button>
								</div>
							</div>


						</div>
					</div>
				}
				<Header toggleSidebar={this.props.toggleSidebar} />

				<Modal title="Edit Rig" toggleModal={() => this.props.router.history.goBack()}>

					<form onSubmit={this.save}>
						<div className="lg:flex mb-10">
							<div className="w-full lg:w-3/5 pl-1 lg:pl-2 pr-1">
								<div className="border border-grey-mid rounded px-2 pt-2 pb-1 lg:pb-2 bg-sptTB mb-4">

									<div className="text-center text-lg font-bold mb-2">General Settings</div>
									<InputGroup
										label="Rig Name"
										value={this.state.name}
										onChange={(value) => this.setState({ name: value })}
										className="font-bold md:text-lg"
									/>

									{/* <SelectGroup
										label="Plant Type"
										values={[
											{ value: 'Cable Percussive', label: 'Cable Percussive' },
											{ value: 'Rotary', label: 'Rotary' },
											{ value: 'Window Sample', label: 'Window Sample' },
										]}
										onChange={(value) => this.setState({ type: value })}
										selected={this.state.type}
										className="mb-16 w-full h-4 md:text-lg pt-2" /> */}

										{/* <SelectGroup
											label="Plant Type"
											values={[
												{ value: 'Cable Percussive', label: 'Cable Percussive' },
												{ value: 'Rotary', label: 'Rotary' },
												{ value: 'Window Sample', label: 'Window Sample' },
											]}
											onChange={this.handleRigTypeChange}
											selected={this.state.type}
											className="mb-16 w-full h-4 md:text-lg pt-2"
										/> */}

									<div className="font-bold text-lg py-1">Plant Type:</div>
									<div className="text-lg pl-1 bg-white rounded text-grey-dark py-1 border border-grey-mid">{this.state.type}</div>

									{/* <InputGroup label="Plant Type" value={this.state.type} onChange={(value) => this.setState({type: value})} /> */}

									<InputGroup
										label="Plant ID"
										value={this.state.plant_id}
										onChange={(value) => this.setState({ plant_id: value })}
										className="font-bold md:text-lg pt-2"
									/>

									<InputGroup
										label="Make / Model"
										value={this.state.make_model}
										onChange={(value) => this.setState({ make_model: value })}
										className="font-bold md:text-lg pt-2"
									/>

									{/* <SelectGroup
										label="SPT ID"
										values={this.state.hammers}
										selected={this.state.spt.guid}
										onChange={(value) => this.setState(prevState => ({
											spt: {
												...prevState.spt,
												guid: value.value
											}
										}))}
										className="md:text-lg pt-2"
									/> */}

									<SelectGroup
										label="SPT ID"
										values={this.state.hammers}
										selected={this.state.spt.guid}
										onChange={this.handleSPTChange}
										className="md:text-lg pt-2"
									/>

									<InputGroup type="date"
										label="Test Date Expiry"
										value={this.state.test_date_expiry}
										onChange={(value) => this.setState({ test_date_expiry: value })}
										className="mb-4 font-bold md:text-lg"
									/>

									{/* <InputGroup label="Test Method" value={this.state.test_method} onChange={(value) => this.setState({test_method: value})} className="mb-10" /> */}


								</div>
							</div>

							<div className="w-full lg:w-2/5 pl-1 lg:pl-1 pr-1 lg:pr-1">
								<div className="border border-grey-mid rounded px-2 pt-2 bg-sptTB mb-2">
									<div className="text-center text-lg font-bold">Dynamic Probe Settings</div>
									<div className="text-center text-sm mb-2">(if applicable)</div>
									<SelectGroup
										label="Test Type"
										values={[
											{ value: 'Superheavy - 750mm drop', label: 'Superheavy - 750mm drop' },
											{ value: 'Light - 500mm drop', label: 'Light - 500mm drop' },
											{ value: 'Medium - 500mm drop', label: 'Medium - 500mm drop' },
											{ value: 'Heavy - 500mm drop', label: 'Heavy - 500mm drop' },
											{ value: 'Superheavy - 500mm drop', label: 'Superheavy - 500mm drop' },
										]}
										// onChange={(value) => this.setState({
										//     dp_test_type: value.value,
										//     dp_test_ref: value.label,
										// })} 
										onChange={this.handleTestTypeChange}
										selected={dp_test_type}
										className="mb-16 w-full h-4 md:text-lg" />

									<table className="table-auto w-full border border-grey-mid rounded mb-4 text-xs sm:text-base bg-sptSB">
										<tbody>
											<tr className={`border border-grey-mid`}>
												<td className={`px-1 py-1 border border-grey-mid border-r w-2/6`}>Hammer Mass:</td>
												<td className={`px-1 py-1 border border-grey-mid border-r w-1/6`}>{dp_hammer_text} </td>
												<td className={`px-1 py-1 border border-grey-mid border-r w-2/6`}>Standard Drop:</td>
												<td className={`px-1 py-1 w-1/6`}>{dp_drop_text} </td>
											</tr>
											<tr className={`border border-grey-mid`}>
												<td className={`px-1 py-1 border border-grey-mid border-r w-2/6`}>Cone Diameter:</td>
												<td className={`px-1 py-1 border border-grey-mid border-r w-1/6`}>{dp_cone_text} </td>
												<td className={`px-1 py-1 border border-grey-mid border-r w-2/6`}>Rod Diameter:</td>
												<td className={`px-1 py-1 w-1/6`}>{dp_rod_diameter_text} </td>
											</tr>
											<tr>
												<td className={`px-1 py-1 border border-grey-mid border-r w-2/6`}>Rod Mass:</td>
												<td className={`px-1 py-1 border border-grey-mid border-r w-1/6`}>{dp_rod_mass_text} </td>
												<td className={`px-1 py-1 border border-grey-mid border-r w-2/6`}></td>
												<td className={`px-1 py-1 w-1/6`}></td>
											</tr>
										</tbody>
									</table>

									<SelectGroup
										label="Torque Meter Type"
										values={[
											{ value: '(kg/m)', label: 'Kilograms per metre (kg/m)' },
											{ value: '(Nm)', label: 'Newton Metre (Nm)' }
										]}
										onChange={(selectedOption) => this.setState({ torque_default: selectedOption.value })}
										selected={this.state.torque_default}
										className="mb-16 w-full h-4 md:text-lg"
									/>


									<InputGroup label="Test Method" value={this.state.test_method}
										onChange={(value) => this.setState({ test_method: value })}
										className="font-bold md:text-lg"
									/>

									<div className="grid grid-cols-2 gap-2">

										<InputGroup label="Anvil Type" value={this.state.dprg_anvil_type}
											onChange={(value) => this.setState({ dprg_anvil_type: value })}
											className="font-bold md:text-lg pt-2"
										/>

										<InputGroup label="Anvil Dampner" value={this.state.dprg_anvil_dampner}
											onChange={(value) => this.setState({ dprg_anvil_dampner: value })}
											className="font-bold md:text-lg mb-4 pt-2"
										/>
									</div>

								</div>
							</div>

						</div>






						<div className="flex justify-between">
							<Button onClick={() => this.setState({ deleteConfirmOpen: true })} className="ml-1 text-sm w-16 md:w-32" theme="secondary">Delete</Button>
							<div className="flex">
								<Button onClick={this.handleCancelClick} theme="secondary" className="mr-2 md:mr-4 h-12 w-20 md:w-32 text-sm md:text-base">Cancel</Button>
								<Button type="submit" theme="primary" className="mr-1 h-12 w-32 md:w-40 text-base">Save</Button>
							</div>
						</div>

					</form>

				</Modal>
			</>
		)
	}
}

export default RigsUpdate
