import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

import edit from '../assets/icons/edit.svg'

type Props = {
	header: string[],
	items: any[],
	isObject?: boolean,
	objectColumns?: string[],
	editingUrl?: string,
	className?: string,
}

const defaultProps: Props = {
	header: [],
	items: [],
	isObject: false,
	objectColumns: [],
	editingUrl: '',
	className: '',
}

const Row = ({values, isObject, columns, editingUrl}: any) => {
	if(isObject) {
		return (
			<>
				<tr className="border border-grey-mid bg-white mb-1">
					{columns.map((value: string, index: number) => {
						return <td className="p-0 border-r border-grey-mid" key={index}>
							<div className={`px-2 py-2`}>{values[value] !== '' ? values[value] : <>&nbsp;</>}</div>
						</td>
					})}

					{editingUrl &&
						<td className="p-0 border-r border-grey-mid text-center">
							<div className={`px-2 py-2`}><Link to={editingUrl + '/' + values['guid']}><img className={`inline`} src={edit} width="20" alt="" /></Link></div>
						</td>
					}
				</tr>
				<tr className="h-2"></tr>
			</>
		)
	}

	return (
		<>
			<tr className="border border-grey-mid bg-white mb-1">
				{values.map((value: string, index: number) => {
					return <td className="p-0 border-r border-grey-mid" key={index}>
						<div className={`px-2 py-2`}>{value}</div>
					</td>
				})}
			</tr>
			<tr className="h-2"></tr>
		</>
	)
}

const Table: FunctionComponent<Props> = ({ header, items, isObject, objectColumns, editingUrl, className }) => {
	return (
		<div className={`text-sm rounded-t overflow-hidden ${className}`}>
			<table className="table-auto w-full">
				<thead className="font-bold">
					<tr>
						{header.map((title, index) => {
							return <td className={`p-0 bg-grey-mid border border-grey-mid ${index === 0 ? 'rounded-tl-sm' : null} ${index === header.length - 1 ? 'rounded-tr-sm' : null}`} key={index}>
								<div className={`px-2 py-2 ${index !== header.length-1 ? 'border-r border-grey-light' : null}`}>{title}</div>
							</td>
						})}
					</tr>
					<tr className="h-2"></tr>
				</thead>

				<tbody>
				{items.map((values, index) => {
					return (
						<Row values={values} key={index} isObject={isObject} columns={objectColumns} editingUrl={editingUrl} />
					)
				})}
				</tbody>
			</table>
		</div>
	)
}

Table.defaultProps = defaultProps

export default Table
