import React, { FunctionComponent, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
// import { get, set, update } from "idb-keyval";

import getWeatherList from '../../helpers/getWeatherList'

import cablePercussiveIcon from '../../assets/icons/cablepercussive-white.svg'
import rotaryIcon from '../../assets/icons/rotary-white.svg'
import windowSampleIcon from '../../assets/icons/windowsample-white.svg'
import trialpitIcon from '../../assets/icons/trialpit-white.svg'
import dynamicProbeIcon from '../../assets/icons/dynamicprobe-white.svg'
import headTestIcon from '../../assets/icons/headtest-white.svg'
import loggingIcon from '../../assets/icons/logging-white.svg'
import soakawayIcon from '../../assets/icons/soakaway-white.svg'
import cbrIcon from '../../assets/icons/cbr-white.svg'
import platetestIcon from '../../assets/icons/platetest-white.svg'
import trlIcon from '../../assets/icons/trl-white.svg'

import editIconOrange from '../../assets/icons/edit-orange.svg'

import EditHole from '../../views/EditHole'

// import EditHole from '../../views/EditHole'

type Props = {
	onMarkAsComplete: any,
	// labelsLink: string,
	boreholeName: string,
	boreholeType: string,
	boreholeDepth: string,
	boreholeRequiredDepth: string,
	boreholeRequiredTests: string,
	editWeatherLink: string,
	className?: string;
	[x: string]: any;
	
}

const defaultProps: Props = {
	onMarkAsComplete: () => {},
	boreholeName: '',
	boreholeType: '',
	boreholeDepth: '',
	boreholeRequiredDepth: '',
	boreholeRequiredTests: '',
	boreholeRequiredText: '',
	editWeatherLink: '',
	className: '',
}

	const ProjectOptionsBorehole: FunctionComponent<Props> = ({ onMarkAsComplete, company_guid, project_guid, project_id, 
		boreholeName, borehole_guid, boreholeType, boreholeDepth, boreholeRequiredDepth, boreholeRequiredTests, 
		boreholeRequiredText, editWeatherLink, rig_guid, driller, second_man, rig_name, rig_make_model, project_identifier, driller_id,
		driller_cscs, second_man_cscs, spt_guid, borehole_depthOverall, companyID, getHoleData, activity_termination_reason,
		plantCheck, holeStatus, numberingType, className, ...rest }) => {

	const selectedWeather = useSelector((state: any) => state.weather)

	const [showEditHole, setShowEditHole] = useState(false)

	const projectName = project_identifier

	console.log('rig_guid:', rig_guid)

	let iconType = ''
	let iconWidth  = 12
      if (boreholeType ==='CP') {iconType = cablePercussiveIcon; iconWidth = 12}
      else if (boreholeType ==='RT') {iconType = rotaryIcon; iconWidth = 9}
      else if (boreholeType ==='WS') {iconType = windowSampleIcon; iconWidth = 10}
      else if (boreholeType ==='TP') {iconType = trialpitIcon; iconWidth = 24}
      else if (boreholeType ==='DP') {iconType = dynamicProbeIcon; iconWidth = 30}
      else if (boreholeType ==='HT') {iconType = headTestIcon; iconWidth = 20}
      else if (boreholeType ==='LG') {iconType = loggingIcon; iconWidth = 25}
      else if (boreholeType ==='SA') {iconType = soakawayIcon; iconWidth = 25}
      else if (boreholeType ==='CB') {iconType = cbrIcon; iconWidth = 30}
      else if (boreholeType ==='PT') {iconType = platetestIcon; iconWidth = 30}
      else if (boreholeType ==='TR') {iconType = trlIcon; iconWidth = 25}
      else {iconType = "cablePercussiveIcon"}

	let weatherIcon = ''

	console.log('rig_make_model:', rig_make_model)

	// getWeatherList().map((weather) => {
	// 	if(weather.label === selectedWeather)
	// 		weatherIcon = weather.icon
	// })

	const selectedWeatherItem = getWeatherList().find((weather) => weather.label === selectedWeather);

	if (selectedWeatherItem) {
	weatherIcon = selectedWeatherItem.icon;
	} else {
	// Handle the case when no matching weather is found
	}

	let currentDepthText = ''
	let currentDepthTextM = ''
	if(!boreholeDepth){currentDepthText = ''; currentDepthTextM = ''}
	else {currentDepthText = 'Current Depth:'; currentDepthTextM = 'm'}
	
	// const [showEditHole, setShowEditHole] = React.useState(false);

	// const showEditHoleClick = () => {
	// 	setShowEditHole((prev) => !prev)
	// }

	// console.log('rig_name:', rig_name)

	// let currentRigText = ''
	// if (boreholeType ==='CP' || boreholeType ==='WS' || boreholeType ==='RT' || boreholeType ==='DP'){
	// 	if(rig_name === 'Select Current Rig' || rig_name === ''){currentRigText = ''}else{currentRigText = 'Rig: ' + rig_name}
	// }
	
	// const [rigGuid, setRigGuid] = useState('');

	// useEffect(() => {
	// 	if(rig_guid==='undefined' || rig_guid==='null' || rig_guid===''){
	// 		setRigGuid('0000')
	// 		// currentRigText = ''
	// 	} else {
	// 		setRigGuid(rig_guid)
	// 		// currentRigText = 'Current Rig: ' + rig_name
	// 	}
	// }, [rig_guid, setRigGuid]);




	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// RIG DATA
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	// const [currentRigValue, setCurrentRigValue] = useState('');
	// const [currentRigLabel, setCurrentRigLabel] = useState('');
  
	// useEffect(() => {
	//   const fetchData = async () => {
	// 	try {
	// 	  const response = await get(`drillit-currentRig-${currentRigValue}`);
	// 	  if (response) {
	// 		setCurrentRigValue(response.guid);
	// 		setCurrentRigLabel(response.label);
	// 	  }
	// 	} catch (error) {
	// 	  // Handle any errors here
	// 	  console.error('Error fetching data:', error);
	// 	}
	//   };
  
	//   fetchData();
	// }, [currentRigValue]); // Dependency array includes currentRigValue to trigger effect when it changes


	// console.log('rig_name in PROJECT OPTIONS BOREHOLE: ', currentRigLabel)
	// console.log('rig_id in PROJECT OPTIONS BOREHOLE: ', currentRigValue)
	console.log('plantCheck: ', plantCheck)

	return (

		<>

		{showEditHole && (
			<EditHole onClose={() => { 
				setShowEditHole(false)
				getHoleData() 
			}} 
				company_guid={company_guid}  
				company_id={companyID} 
				holeType={boreholeType} 
				// onChange={(boreholes: any[]) => {setState({boreholes: boreholes})}} 
				onChange={(boreholes: any[]) => {}} 
				project_name={projectName} 
				borehole_guid={borehole_guid} 
				borehole_name={boreholeName} 
				leadDriller={driller} 
				leadDrillerID={driller_id} 
				leadDrillerCSCS={driller_cscs}
				secondMan={second_man}
				secondManCSCS={second_man_cscs}
				plantGuid={rig_guid}
				plantName={rig_name}
				plantMakeModel={rig_make_model}
				sptGUID={spt_guid}
				borehole_depthOverall={borehole_depthOverall}
				activity_termination_reason={activity_termination_reason}
				plantCheck={plantCheck}
				project_guid={project_guid}
				holeStatus={holeStatus}
				numberingType={numberingType}
			/>
			)
		}

		<div className={`flex items-center justify-between mt-0 text-xl pb-1 ${className}`} {...rest}>



			<div className="flex items-center w-screen">
				<div className={`flex items-center justify-center bg-orange rounded h-9 w-9 mr-1`}><img src={iconType} width={iconWidth} alt="" /></div>

					<div className="leading-5 text-base md:text-lg font-bold ml-1" onClick={() => { setShowEditHole(true) }} >

						{/* <Link to={`/projects/edit_borehole/${project_guid}/${boreholeType}/${borehole_guid}/${boreholeName}/${rig_name}/${driller}/${second_man}`}>  */}
						{/* <Link to={`/projects/edit_borehole/${project_id}/${boreholeType}/${borehole_guid}`}> */}
						{/* <Link to={`/projects/edit_borehole/${project_guid}/${boreholeType}/${borehole_guid}`}>  */}
							{boreholeName} <img src={editIconOrange} alt="edit" width="15" className="inline pb-1 text-xs"></img>
						{/* </Link> */}

					</div>
			</div>

			<div className="text-center w-screen pt-1">
				<div className="font-bold mb-0.5" style={{fontSize:'0.6rem', lineHeight:'0.3rem'}}>
					{currentDepthText}
				</div>
				{/* <div className="text-lg font-bold -mt-0.5"> */}
				<div className="text-xl font-bold -mb-1">
					{boreholeDepth}{currentDepthTextM}
				</div>
				{/* <div className="font-bold text-grey-dark mt-0.5" style={{fontSize:'0.6rem', lineHeight:'0.4rem'}}>
					{currentRigText}
				</div> */}
			</div>

			<div className={`w-screen text-right`}>
				<Link to={editWeatherLink}><i className={"text-2xl md:text-3xl m-0 pt-1 " + weatherIcon}></i></Link>
			</div>
		</div>

		</>
	)
}

ProjectOptionsBorehole.defaultProps = defaultProps

export default ProjectOptionsBorehole