import React, { FunctionComponent } from 'react'

type Props = {
	left?: any[],
	right?: any[],
	className?: string;
	[x: string]: any;
}

const defaultProps: Props = {
	left: [],
	right: [],
	className: '',
}

const Heading: FunctionComponent<Props> = ({ left, right, className, ...rest }) => {
	return (
		<div className={`flex items-center mb-2 pt-2 text-lg pb-3 ${className}`} {...rest}> {/* border-b border-grey-dark */}
		
			<div className="flex items-center">
				{left?.map((item, index) => {
					return <div className="mr-3" key={index}>{item}</div>
				})}
			</div>

			<div className="flex items-center ml-auto">
				{right?.map((item, index) => {
					return <div className="ml-3" key={index}>{item}</div>
				})}
			</div>
		</div>
	)
}

Heading.defaultProps = defaultProps

export default Heading