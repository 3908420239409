import React, { FunctionComponent, useState } from 'react'
import ButtonOptions from './form/ButtonOptions'

type Props = {
	className?: string,
	aboveInput?: JSX.Element,
	defaultValues: any,
	buttonoptions: any,
	title?: string,
	presets?: any[],
	// buttonOption: string,
	onUpdate: (value: string) => void,
	[x: string]: any,
	
}

const defaultProps: Props = {
	className: '',
	title: '',
	aboveInput: <></>,
	presets: [],
	// buttonOption: '',
	onUpdate: (value: any) => {},
	defaultValues: {
	},
	buttonoptions: {
		userPref: '',
		inputOption1: '',
		inputOption2: '',
		inputOption1Title: '',
		inputOption2Title: '',
	},
}

// const keys = [
// 	'1', '2', '3',
// 	'4', '5', '6',
// 	'7', '8', '9',
// 	'.', '0',
// ]

const ButtonOptionsUserPref: FunctionComponent<Props> = ({ className, defaultValues, buttonoptions, aboveInput, presets, title,  onUpdate, ...rest }) => {

	// const [value, setValue] = useState(defaultValues.numericValue)

	const [userPref, setUserPref] = useState(buttonoptions.userPref)
	// const [inputOption1, setInputOption1] = useState(buttonoptions.inputOption1)
	// const [inputOption2, setInputOption2] = useState(buttonoptions.inputOption2)

	// const units = buttonoptions.loadUnits
	// console.log('Number Input loadUnits: ', loadUnits)

	const inputOption1 = buttonoptions.inputOption1
	const inputOption2 = buttonoptions.inputOption2
	const inputOption1Title = buttonoptions.inputOption1Title
	const inputOption2Title = buttonoptions.inputOption2Title
	// const keypadVisible = buttonoptions.keyboard

	const defaultLoadUnit = buttonoptions.loadUnits

	console.log('defaultValues: ', defaultValues)

	console.log('buttonoptions: ', buttonoptions)
	console.log('buttonoptions.loadUnits: ', buttonoptions.loadUnits)
	console.log('buttonoptions.inputOption1: ', inputOption1)
	console.log('buttonoptions.inputOption2: ', inputOption2)
	console.log('buttonoptions.inputOption1: ', inputOption1Title)
	console.log('buttonoptions.inputOption2: ', inputOption2Title)
	console.log('defaultLoadUnit: ', defaultLoadUnit)

	return (
            <>

                <ButtonOptions onUpdate={(value: any) => setUserPref(userPref)} 
                    defaultValue={userPref}
                    title="" 
                    columns={2} 
                    columnsMobile={2} 
                    buttonHeight={12}
                    buttonHeightMobile={12}
                    className="w-20 text-2xl font-bold" 
                    options={[
                        // 'kN',
                        // 'T',
                        inputOption1,
                        inputOption2,
                    ]}  
                    />

                    <div className="grid grid-cols-2 mt-1">
                        <div className="text-xs text-center">{inputOption1Title}</div>
                        <div className="text-xs text-center">{inputOption2Title}</div>
                    </div>

			</>
	        )
}

ButtonOptionsUserPref.defaultProps = defaultProps

export default ButtonOptionsUserPref