import { get, set } from 'idb-keyval';

interface ApiResponse {
    success: boolean;
    status?: number;
}

export const deleteContactInformation = (guid: string, company_id: number): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        const endpoint = `${process.env.REACT_APP_API_ADDR}contacts/delete/${guid}`;

        console.log('Deleting contact:', guid);

        makeRequest(endpoint, 'DELETE')
            .then((response: ApiResponse) => {
                console.log('API response:', response); // Log the API response

                if (response.success) {
                    console.log('Contact deleted successfully from API.');

                    // Delete the contact from IndexedDB drillit-contacts
                    deleteContactFromDrillitContacts(guid)
                        .then(() => {
                            return deleteContactFromCompanyContacts(company_id, guid);
                        })
                        .then(() => {
                            resolve(true);
                        })
                        .catch(err => {
                            console.error('Error deleting contact from IndexedDB:', err);
                            reject(false);
                        });
                } else {
                    console.error('Failed to delete contact from API.');

                    // Save the delete request to IndexedDB for retrying later
                    const idbKey = `drillit-contacts/${guid}-delete`;
                    const contactToDelete = { guid, company_id, first_name: '', last_name: '', email: '', telephone: '' }; // Add other fields as necessary
                    set(idbKey, contactToDelete)
                        .then(() => {
                            console.log(`Saved delete request for ${guid} to IndexedDB for retry.`);
                        })
                        .catch(err => {
                            console.error(`Error saving delete request to IndexedDB for ${guid}:`, err);
                        });

                    // Delete the contact from IndexedDB drillit-contacts regardless
                    deleteContactFromDrillitContacts(guid)
                        .then(() => {
                            return deleteContactFromCompanyContacts(company_id, guid);
                        })
                        .then(() => {
                            reject(false);
                        })
                        .catch(err => {
                            console.error('Error deleting contact from IndexedDB:', err);
                            reject(false);
                        });
                }
            })
            .catch(error => {
                console.error('Error deleting contact:', error);

                // Save the delete request to IndexedDB for retrying later
                const idbKey = `drillit-contacts/${guid}-delete`;
                const contactToDelete = { guid, company_id, first_name: '', last_name: '', email: '', telephone: '' }; // Add other fields as necessary
                set(idbKey, contactToDelete)
                    .then(() => {
                        console.log(`Saved delete request for ${guid} to IndexedDB for retry.`);
                    })
                    .catch(err => {
                        console.error(`Error saving delete request to IndexedDB for ${guid}:`, err);
                    });

                // Delete the contact from IndexedDB drillit-contacts regardless
                deleteContactFromDrillitContacts(guid)
                    .then(() => {
                        return deleteContactFromCompanyContacts(company_id, guid);
                    })
                    .then(() => {
                        reject(false);
                    })
                    .catch(err => {
                        console.error('Error deleting contact from IndexedDB:', err);
                        reject(false);
                    });
            });
    });
};

const makeRequest = (endpoint: string, method: string): Promise<any> => {
    const token = localStorage.getItem('drillit-token');

    return fetch(endpoint, {
        method: method,
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
    .then(response => {
        if (!response.ok) {
            throw new Error(`Failed to delete contact. Status: ${response.status}`);
        }

        return response.json(); // Parse JSON response
    })
    .catch(err => {
        console.error('Fetch error:', err);
        throw err; // Propagate the error for further handling
    });
};

const deleteContactFromDrillitContacts = (guid: string): Promise<void> => {
    return new Promise((resolve, reject) => {
        get('drillit-contacts')
            .then((contacts: any[]) => {
                if (!Array.isArray(contacts)) {
                    contacts = []; // Ensure contacts is an array
                }

                const updatedContacts = contacts.filter(contact => contact.guid !== guid);

                set('drillit-contacts', updatedContacts)
                    .then(() => {
                        console.log(`Deleted contact ${guid} from drillit-contacts in IndexedDB.`);
                        resolve();
                    })
                    .catch(err => {
                        console.error(`Error deleting contact from drillit-contacts in IndexedDB:`, err);
                        reject(err);
                    });
            })
            .catch(err => {
                console.error(`Error retrieving drillit-contacts from IndexedDB:`, err);
                reject(err);
            });
    });
};

const deleteContactFromCompanyContacts = (company_id: number, guid: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
        const companyContactsIDB = `drillit-contacts/company/${company_id}`;

        get(companyContactsIDB)
            .then((companyContacts: any) => {
                if (companyContacts && companyContacts.contacts) {
                    const updatedCompanyContacts = companyContacts.contacts.filter((contact: any) => contact.guid !== guid);
                    companyContacts.contacts = updatedCompanyContacts;

                    set(companyContactsIDB, companyContacts)
                        .then(() => {
                            console.log(`Deleted contact ${guid} from company contacts in IndexedDB for company ID ${company_id}.`);
                            resolve();
                        })
                        .catch(err => {
                            console.error(`Error deleting contact from company contacts in IndexedDB:`, err);
                            reject(err);
                        });
                } else {
                    console.error(`Company contacts not found in IndexedDB for company ID ${company_id}.`);
                    resolve(); // Resolve even if company contacts are not found
                }
            })
            .catch(err => {
                console.error(`Error retrieving company contacts from IndexedDB for company ID ${company_id}:`, err);
                reject(err);
            });
    });
};
