import React, { FunctionComponent, useState, Component, useRef, useEffect, } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateSelectedProject } from '../../../actions/SelectedProject'

import Panel from '../../../components/Panel'
import PanelSingleUse from '../../../components/PanelSingleUse'
import PanelBackfillInstall from '../../../components/PanelBackfillInstall'
import Button from '../../../components/form/Button'
import Modal from '../../../components/Modal'
import ModalSmall from '../../../components/ModalSmall'

import ProjectOptionsBorehole from '../../../components/project/ProjectOptionsBorehole'
import ProjectHeader from '../../../components/project/ProjectHeader'

import SyncStatus from '../../../helpers/syncStatus'

import Samples from '../../../components/project/workflow/SamplesLogging'
import SamplesCreate from '../../../components/project/workflow/SamplesCreate'
import SPT from '../../../components/project/workflow/in-situ-tests/SPT'
import CPT from '../../../components/project/workflow/in-situ-tests/CPT'
import U from '../../../components/project/workflow/in-situ-tests/U'
import PS from '../../../components/project/workflow/in-situ-tests/PS'
import SV from '../../../components/project/workflow/in-situ-tests/SV'
import SoilDescription from '../../../components/project/workflow/SoilDescription'
import SoilDescriptionEditor from '../../../components/project/workflow/SoilDescriptionEditor'
import GroundWater from '../../../components/project/workflow/GroundWaterTrialpit'
import Backfill from '../../../components/project/workflow/Backfill'
import Install from '../../../components/project/workflow/Install'
import InspectionPit from '../../../components/project/workflow/InspectionPit'
import CasingRecord from '../../../components/project/workflow/CasingRecord'
import Chiselling from '../../../components/project/workflow/Chiselling'
import WaterAdded from '../../../components/project/workflow/WaterAdded'
import DailyWaterLevels from '../../../components/project/workflow/WaterLevel'
import ReDrill from '../../../components/project/workflow/ReDrill'
import Comments from '../../../components/project/workflow/Comments'
import DetailDescriptions from '../../../components/project/workflow/DetailDescriptions'
import TrialpitDetails from '../../../components/project/workflow/TrialpitDetails'

import Labels from '../../../components/project/workflow/Labels'

import Header from '../../../layout/Header'
import Container from '../../../layout/Container'

import cablePercussiveIcon from '../../../assets/icons/cablepercussive-white.svg'


import '../../../LogsheetCSS.css';
import Barcode from 'react-jsbarcode'
import closeIcon from '../../../assets/icons/close.svg'
import previewIcon from '../../../assets/icons/preview.svg'
import labelIcon from '../../../assets/icons/label.svg'
import { Link } from 'react-router-dom'

import { useSetIndexedDBValues } from '../../../helpers/addToIDB'
import { useUpdateIndexedDBValues } from "../../../helpers/updateIDB"
import { useDeleteIndexedDBValue } from "../../../helpers/deleteFromIDB"
import { useGetIDBValues } from "../../../helpers/getIDBKey";
import { usePostToApi } from "../../../helpers/postToAPI";
import { updateActions } from "../../../helpers/updateActions"
import CompactTableObject from "../../../components/CompactTableObject";
import getWeatherList from "../../../helpers/getWeatherList";
import { connect } from "react-redux";
import { get, set, update, del } from "idb-keyval";
import edit from "../../../assets/icons/edit.svg";
import { activateHole } from "../../../helpers/activateHole";
import { closeHole } from "../../../helpers/closeHole";
import { updateHoleInformation } from "../../../helpers/updateHoleInformation";
import { Redirect, useHistory } from "react-router-dom";
import datesAreOnSameDay from "../../../helpers/datesAreOnSameDay";
import { updateWeather } from "../../../helpers/updateWeather";

import menubars from '../../../assets/menubars.svg'

import icon_ags from '../../../assets/icons/icon_ags.png'
import icon_preview from '../../../assets/icons/icon_preview.png'
import icon_savelog from '../../../assets/icons/icon_savelog.png'
import icon_complete from '../../../assets/icons/icon_complete.png'
import icon_project from '../../../assets/icons/icon_project.png'
import icon_email from '../../../assets/icons/icon_email.png'
import icon_labels from '../../../assets/icons/icon_labels.png'
import icon_newborehole from '../../../assets/icons/icon_newborehole.png'
import icon_plantcheck from '../../../assets/icons/icon_plantcheck.png'
import icon_editrig from '../../../assets/icons/icon_editrig.png'
import icon_reload from '../../../assets/icons/reload.png'

import NewHole from '../../NewHole'
import AGS from '../../AGS'
import Email from '../../Email'
import SaveLog from '../../SaveLog'

import ReactToPrint from 'react-to-print'
import Label from '../../../components/Label'
import PrintLabel from '../../PrintLabelModal'
import LabelBatchPrint from '../../../components/LabelBatchPrint'
import TopImage from '../../../assets/labelTop.jpg'
import { useGetLoggedInUser } from '../../../helpers/getLoggedInUser'
import warningIcon from '../../../assets/icons/warning.png'

interface Props {
	// onClose: any,
	// router: any,
	toggleSidebar: () => void,
	company_guid: string,
	project_guid: string,
	project_id: string,
	project_name: any,
	starting_hole_type_id: string,
	borehole_guid: string,
	selectedWeather: string,
	selectedDate: string,
	lastActiveDate: string,
	borehole_id: string,
	borehole_name: string,
	// rig_name: string,
	// rig_id: string,

	// HOLE DATA
	AllHoleData: any,
	activeDates: any,

	reloadData: any,
}


interface State {
	// onClose: () => {},
	project_guid: string,
	borehole: any,
	completingBorehole: boolean,

	showD: boolean,
	showSamplesCreateD: boolean,
	showSamplesCreateB: boolean,
	showSamplesCreateWS: boolean,
	showDEdit: boolean,
	showB: boolean,
	showBEdit: boolean,
	showES: boolean,
	showESEdit: boolean,
	showWS: boolean,
	showWSEdit: boolean,
	showSPT: boolean,
	showCPT: boolean,
	showU: boolean,
	showPS: boolean,
	showSV: boolean,
	showSoilDescription: boolean,
	showGroundWater: boolean,
	showInspectionPit: boolean,
	showCasingRecord: boolean,
	showChiselling: boolean,
	showWaterAdded: boolean,
	showDailyWaterLevels: boolean,
	showReDrill: boolean,
	showComments: boolean,
	showSPTEdit: boolean,
	showCPTEdit: boolean,
	showUEdit: boolean,
	showPSEdit: boolean,
	showSVEdit: boolean,
	showSoilDescriptionEdit: boolean,
	showGroundWaterEdit: boolean,
	showInspectionPitEdit: boolean,
	showCasingRecordEdit: boolean,
	showChisellingEdit: boolean,
	showWaterAddedEdit: boolean,
	showDailyWaterLevelsEdit: boolean,
	showReDrillEdit: boolean,
	showCommentsEdit: boolean,

	showInstall: boolean,
	showInstallEdit: boolean,
	showBackfill: boolean,
	showBackfillEdit: boolean,
	TrialpitDetails: any,
	DetailDescriptions: any,

	InSituTests: any,
	GroundWaterTests: any,
	Samples: any,
	InspectionPit: any,
	Casing: any,
	Chiselling: any,
	WaterAdded: any,
	DailyWaterLevels: any,
	ReDrill: any,
	SoilDescription: any,
	SoilDescriptionEditor: any,
	// BackfillInstall: any,
	Comments: any,
	Install: any,
	Backfill: any,

	addSampleData: any,
	fromType: any,

	redirect: boolean,

	project: any,
	holeType: string,

	previewVisible: boolean,
	labelsVisible: boolean,
	bhMenuVisible: boolean,

	showHoleEdit: boolean,
	showNewHole: boolean,
	showEmail: boolean,
	showAGS: boolean,
	setShowNewHole: boolean,
	setShowEmail: boolean,
	setShowAGS: boolean,

	showSaveLog: boolean,
	setShowSaveLog: boolean,

	showPrintLabel: boolean,
	setShowPrintLabel: boolean,

	defaultValues: object,
	guid: string;

	todaySoilDescriptionsVisible: boolean,
	allSoilDescriptionsVisible: boolean,

	todayCasingVisible: boolean,
	allCasingVisible: boolean,
	todayReDrillVisible: boolean,
	allReDrillVisible: boolean,
}



const FunctionTemplate: FunctionComponent<Props> = ({
	company_guid, project_guid, project_id, starting_hole_type_id, borehole_guid, selectedWeather, selectedDate, lastActiveDate,
	borehole_id, borehole_name, reloadData, project_name,
	//HOLE DATA
	AllHoleData, activeDates,
	toggleSidebar, ...rest }) => {

	// const hole_data = AllHoleData?.hole_data;
	const [hole_data, set_hole_data] = useState(AllHoleData?.hole_data)
	const [hole_guid, set_hole_guid] = useState(AllHoleData?.hole_guid || '')

	const [borehole, setBorehole] = useState(null)
	const [project, setProject] = useState(null)

	const [state, setState] = useState({
		completingBorehole: false,

		InSituTests: [],
		GroundWaterTests: [],
		SamplesData: [],
		InspectionPit: [],
		Casing: [],
		Chiselling: [],
		WaterAdded: [],
		DailyWaterLevels: [],
		ReDrill: [],
		SoilDescription: [],
		SoilDescriptionEditor: [],
		Comments: [],
		Install: [],
		Backfill: [],
		TrialpitDetails: [],
		DetailDescriptions: [],

		addSampleData: [],
		fromType: '',

		// ************************************************
		// editingGuid: '',
		// editingIndex: 1, // there is something wrong here - editingIndex won't update null
		redirect: false,

		project: '',
		holeType: '',

		defaultValues: {},

		// currentRigValue: '',
		// currentRigLabel: '',
		// rig: [],
		// rigs: [],
		// currentRig: [],
		// spts: [],
		// rigsSelect: [],
		// currentRig_name: '', //same as Label
		// currentRig_type: '',
		// currentRig_plant_id: '',
		// currentRig_test_date_expiry: '',
		// hammers: [],
		// currentRig_spt_guid: '',
		// currentRig_spt: {
		// 	guid: '',
		// },
	})

	// const [editingGuid, setEditingGuid] = useState('')
	const [editingGuid, setEditingGuid] = useState([])
	const [editingIndex, setEditingIndex] = useState(0)
	const [defaultValues, setDefaultValues] = useState([])

	// console.log('editingGuid from root', editingGuid)
	// console.log('editingIndex from root', editingIndex)
	// console.log('defaultValues from root', defaultValues)

	const [addSampleData, setAddSampleData] = useState([])
	const [fromType, setFromType] = useState('')

	// console.log('addSampleData:', addSampleData)

	const [showD, setShowD] = useState(false)
	const [showSamplesCreateD, setShowSamplesCreateD] = useState(false)
	const [showSamplesCreateB, setShowSamplesCreateB] = useState(false)
	const [showSamplesCreateWS, setShowSamplesCreateWS] = useState(false)
	const [showB, setShowB] = useState(false)
	const [showES, setShowES] = useState(false)
	const [showWS, setShowWS] = useState(false)
	const [showDEdit, setShowDEdit] = useState(false)
	const [showBEdit, setShowBEdit] = useState(false)
	const [showESEdit, setShowESEdit] = useState(false)
	const [showWSEdit, setShowWSEdit] = useState(false)
	const [showSPT, setShowSPT] = useState(false)
	const [showSPTEdit, setShowSPTEdit] = useState(false)
	const [showCPT, setShowCPT] = useState(false)
	const [showCPTEdit, setShowCPTEdit] = useState(false)
	const [showU, setShowU] = useState(false)
	const [showUEdit, setShowUEdit] = useState(false)
	const [showPS, setShowPS] = useState(false)
	const [showPSEdit, setShowPSEdit] = useState(false)
	const [showSV, setShowSV] = useState(false)
	const [showSVEdit, setShowSVEdit] = useState(false)
	const [showSoilDescription, setShowSoilDescription] = useState(false)
	const [showSoilDescriptionEdit, setShowSoilDescriptionEdit] = useState(false)
	const [showGroundWater, setShowGroundWater] = useState(false)
	const [showGroundWaterEdit, setShowGroundWaterEdit] = useState(false)
	const [showInspectionPit, setShowInspectionPit] = useState(false)
	const [showInspectionPitEdit, setShowInspectionPitEdit] = useState(false)
	const [showCasingRecord, setShowCasingRecord] = useState(false)
	const [showCasingRecordEdit, setShowCasingRecordEdit] = useState(false)
	const [showChiselling, setShowChiselling] = useState(false)
	const [showChisellingEdit, setShowChisellingEdit] = useState(false)
	const [showWaterAdded, setShowWaterAdded] = useState(false)
	const [showWaterAddedEdit, setShowWaterAddedEdit] = useState(false)
	const [showDailyWaterLevels, setShowDailyWaterLevels] = useState(false)
	const [showDailyWaterLevelsEdit, setShowDailyWaterLevelsEdit] = useState(false)
	const [showReDrill, setShowReDrill] = useState(false)
	const [showReDrillEdit, setShowReDrillEdit] = useState(false)
	const [showComments, setShowComments] = useState(false)
	const [showCommentsEdit, setShowCommentsEdit] = useState(false)

	const [showTrialpit, setShowTrialpit] = useState(false)
	const [showTrialpitEdit, setShowTrialpitEdit] = useState(false)

	const [showDetailDescription, setShowDetailDescription] = useState(false)
	const [showDetailDescriptionEdit, setShowDetailDescriptionEdit] = useState(false)

	const [showInstall, setShowInstall] = useState(false)
	const [showInstallEdit, setShowInstallEdit] = useState(false)
	const [showBackfill, setShowBackfill] = useState(false)
	const [showBackfillEdit, setShowBackfillEdit] = useState(false)



















	//.......................................................
	//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNN...NNNN..UUUU...UUUU..
	//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNNN..NNNN..UUUU...UUUU..
	//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNNN..NNNN..UUUU...UUUU..
	//.MMMMMMM.MMMMMMM.EEEE........NNNNNN.NNNN..UUUU...UUUU..
	//.MMMMMMM.MMMMMMM.EEEE........NNNNNN.NNNN..UUUU...UUUU..
	//.MMMMMMM.MMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU..
	//.MMMMMMMMMMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU..
	//.MMMMMMMMMMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU..
	//.MMMMMMMMMMMMMMM.EEEE........NNNNNNNNNNN..UUUU...UUUU..
	//.MMMM.MMMMM.MMMM.EEEE........NNNN.NNNNNN..UUUU...UUUU..
	//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN..NNNNN..UUUUUUUUUUU..
	//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN..NNNNN...UUUUUUUUU...
	//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN...NNNN....UUUUUUU....
	//.......................................................

	const [bhMenuVisible, setBhMenuVisible] = useState(false)
	const popupRef = useRef<HTMLDivElement | null>(null); // Specify the type explicitly
	// Event listener to close the menu when clicked outside
	const toggleMenu = (event: MouseEvent) => {
		if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
			setBhMenuVisible(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', toggleMenu);
		return () => {
			document.removeEventListener('click', toggleMenu);
		};
	}, []);


	const handleReloadClick = () => {
		// Call the reload function passed as a prop
		reloadData();
	};



















	//.........................................................................
	//.PPPPPPPPP.....AAAA.....AAANN...NNNN.NNNEEEEEEEE.EEEL.........LSSSSSS....
	//.PPPPPPPPPP...AAAAAA....AAANN...NNNN.NNNEEEEEEEE.EEEL........LLSSSSSSS...
	//.PPPPPPPPPPP..AAAAAA....AAANNN..NNNN.NNNEEEEEEEE.EEEL.......LLLSSSSSSSS..
	//.PPPP...PPPP..AAAAAAA...AAANNNN.NNNN.NNNE........EEEL.......LLLS...SSSS..
	//.PPPP...PPPP.PAAAAAAA...AAANNNN.NNNN.NNNE........EEEL.......LLLSS........
	//.PPPPPPPPPPP.PAAAAAAA...AAANNNNNNNNN.NNNEEEEEEEE.EEEL........LLSSSSS.....
	//.PPPPPPPPPP..PAAA.AAAA..AAANNNNNNNNN.NNNEEEEEEEE.EEEL.........LSSSSSSS...
	//.PPPPPPPPP..PPAAAAAAAA..AAAN.NNNNNNN.NNNEEEEEEEE.EEEL...........SSSSSSS..
	//.PPPP.......PPAAAAAAAAA.AAAN.NNNNNNN.NNNE........EEEL..............SSSS..
	//.PPPP......PPPAAAAAAAAA.AAAN..NNNNNN.NNNE........EEEL.......LLLS...SSSS..
	//.PPPP......PPPA....AAAA.AAAN..NNNNNN.NNNEEEEEEEEEEEELLLLLLLLLLLSSSSSSSS..
	//.PPPP......PPPA....AAAAAAAAN...NNNNN.NNNEEEEEEEEEEEELLLLLLLL.LLSSSSSSSS..
	//.PPPP.....PPPPA.....AAAAAAAN....NNNN.NNNEEEEEEEEEEEELLLLLLLL..LSSSSSS....
	//.........................................................................

	const [todaySoilDescriptionsVisible, setTodaySoilDescriptionsVisible] = useState(true)
	const [allSoilDescriptionsVisible, setAllSoilDescriptionsVisible] = useState(false)

	const [todayCasingVisible, setTodayCasingVisible] = useState(true)
	const [allCasingVisible, setAllCasingVisible] = useState(false)

	const [todayReDrillVisible, setTodayReDrillVisible] = useState(true)
	const [allReDrillVisible, setAllReDrillVisible] = useState(false)


	const [completingBorehole, setCompletingBorehole] = useState(false)
	const menuAndMarkAsComplete = () => {
		setBhMenuVisible(value => !value)
		setCompletingBorehole(value => !value)
	}

	const [previewVisible, setPreviewVisible] = useState(false)
	const previewClick = () => setPreviewVisible(value => !value)
	const menuAndPreview = () => {
		setBhMenuVisible(value => !value)
		setPreviewVisible(value => !value)
	}
	const [labelsVisible, setLabelsVisible] = useState(false)
	const labelsClick = () => setLabelsVisible(value => !value)
	const menuAndLabels = () => {
		setBhMenuVisible(value => !value)
		setLabelsVisible(value => !value)
	}
	const [showHoleEdit, setShowHoleEdit] = useState(false)
	const [showNewHole, setShowNewHole] = useState(false)
	const menuAndNewHole = () => {
		setBhMenuVisible(value => !value)
		setShowNewHole(value => !value)
	}
	const [showEmail, setShowEmail] = useState(false)
	const menuAndEmail = () => {
		setBhMenuVisible(value => !value)
		setShowEmail(value => !value)
	}
	const [showAGS, setShowAGS] = useState(false)
	const menuAndAGS = () => {
		setBhMenuVisible(value => !value)
		setShowAGS(value => !value)
	}
	const [showSaveLog, setShowSaveLog] = useState(false)
	const menuAndSaveLog = () => {
		setBhMenuVisible(value => !value)
		setShowSaveLog(value => !value)
	}
	const [showPrintLabel, setShowPrintLabel] = useState(false)
	const printLabelClick = () => setShowPrintLabel(value => !value)
	const menuAndShowLabels = () => {
		setBhMenuVisible(value => !value)
		setShowPrintLabel(value => !value)
	}

	const [labelSample, setLabelSample] = useState('')
	const [labelDepth, setLabelDepth] = useState('')

	// const [redirect, setRedirect] = useState(false)
	// if (redirect) {
	// 	return <Redirect to={`/projects/dashboard/${project_guid}/logging`} />
	// }
	
	const history = useHistory();
	const [redirect, setRedirect] = React.useState(false);

	// const handleConfirmClick = () => {
	// 	closeHole(borehole_guid);
	// 	setRedirect(true);
	// };

	// Redirect manually using history.push
	React.useEffect(() => {
		if (redirect) {
		history.push(`/projects/dashboard/${project_guid}/logging`);
		}
	}, [redirect, history, project_guid]);

	const printAllSampleLabelsArea = useRef(null)
	const printAllTestLabelsArea = useRef(null)

	const [informationCheck, setInformationCheck] = useState(true)



















	//.............................................................................................................................
	//.PPPPPPPPP.....AAAA.....AAANN...NNNN.NNNEEEEEEEE.EEEL............ TTTTTTTTTTTY....YYYYYYYPPPPPPP..PPPEEEEEEEE...ESSSSSS....
	//.PPPPPPPPPP...AAAAAA....AAANN...NNNN.NNNEEEEEEEE.EEEL............ TTTTTTTTTTTY...YYYYYYYYPPPPPPPP.PPPEEEEEEEE..EESSSSSSS...
	//.PPPPPPPPPPP..AAAAAA....AAANNN..NNNN.NNNEEEEEEEE.EEEL............ TTTTTTTTTTTYY..YYYY.YYYPPPPPPPPPPPPEEEEEEEE.EEESSSSSSSS..
	//.PPPP...PPPP..AAAAAAA...AAANNNN.NNNN.NNNE........EEEL................TTTT....TTYYYYYYY..YYYP....PPPPPPPE........EEES...SSSS..
	//.PPPP...PPPP.PAAAAAAA...AAANNNN.NNNN.NNNE........EEEL................TTTT.....TYYYYYYY..YYYP....PPPPPPPE........EEESS........
	//.PPPPPPPPPPP.PAAAAAAA...AAANNNNNNNNN.NNNEEEEEEEE.EEEL................TTTT.....TYYYYYY...YYYPPPPPPPPPPPPEEEEEEEE..EESSSSS.....
	//.PPPPPPPPPP..PAAA.AAAA..AAANNNNNNNNN.NNNEEEEEEEE.EEEL................TTTT......YYYYY....YYYPPPPPPPP.PPPEEEEEEEE...ESSSSSSS...
	//.PPPPPPPPP..PPAAAAAAAA..AAAN.NNNNNNN.NNNEEEEEEEE.EEEL................TTTT.......YYYY....YYYPPPPPPP..PPPEEEEEEEE.....SSSSSSS..
	//.PPPP.......PPAAAAAAAAA.AAAN.NNNNNNN.NNNE........EEEL................TTTT.......YYYY....YYYP........PPPE...............SSSS..
	//.PPPP......PPPAAAAAAAAA.AAAN..NNNNNN.NNNE........EEEL................TTTT.......YYYY....YYYP........PPPE........EEES...SSSS..
	//.PPPP......PPPA....AAAA.AAAN..NNNNNN.NNNEEEEEEEEEEEELLLLLLLL.........TTTT.......YYYY....YYYP........PPPEEEEEEEEEEEESSSSSSSS..
	//.PPPP......PPPA....AAAAAAAAN...NNNNN.NNNEEEEEEEEEEEELLLLLLLL.........TTTT.......YYYY....YYYP........PPPEEEEEEEEE.EESSSSSSSS..
	//.PPPP.....PPPPA.....AAAAAAAN....NNNN.NNNEEEEEEEEEEEELLLLLLLL.........TTTT.......YYYY....YYYP........PPPEEEEEEEEE..ESSSSSS....
	//.............................................................................................................................

	type SampleType = {
		guid: string
		id: number
		depth_to_base: any
		depth_to_top: any
		// description: any
		// duration: any
		general_remarks: any
		casing_depth: any
		// prep_details: any
		// sample_length: any
		type: any
		water_depth: any
		weather_details: any
		sampler: any
		time_of_action: any
	}
	type InSituTestsType = {
		id: number
		guid: string
		type: any
		type_number: any
		depth_to_top: any
		casing_depth: any
		depth_of_water: any
		seating_blows_1st_inc: any
		seating_blows_2nd_inc: any
		self_weight_penetration: any
		spt_in_soft_rock: any
		test_blows_1st_inc: any
		test_blows_2nd_inc: any
		test_blows_3rd_inc: any
		test_blows_4th_inc: any
		mm_over: any
		mm_overSB: any
		depth_to_base: any
		pen_1: any
		pen_2: any
		pen_3: any
		pen_4: any
		pen_5: any
		pen_6: any
		seating_blows: any
		total_blows: any
		reported_result: any
		weather_details: string
		general_remarks: string
		utype: any
		ublows: any
		sample_preparation: any
		spt_hammer_id: any
		spt_energy_ratio: any
		recovery: any
		// Shear Vane Stuff
		size: any
		test_type: any
		sv_result1: any
		sv_result2: any
		sv_result3: any
		sv_result4: any
		average: any
		suitableForTesting: any
		time_of_action: string

	}
	type InSituVaneDataType = {
		average: string
		depth_of_test: any
		general_remarks: string
		guid: string
		id: number
		size: string
		test_type: string
		time_of_action: string
		weather_details: string
	}
	type SoilDescriptionDataType = {
		depth_to_base: any
		depth_to_top: any
		general_remarks: string
		guid: string
		id: string
		stratum_type: string
		stratum_description: string
		time_of_action: string
	}
	type GroundWaterTestsDataType = {
		casing_depth: any
		depth_after_5: any
		depth_after_10: any
		depth_after_15: any
		depth_after_20: any
		depth_to_water: any
		depth_sealed: any
		sample_id: any
		general_remarks: string
		guid: string
		id: number
		rate_of_flow: string
		time_of_action: string
		time_of_strike: string
	}
	type InstallationDataType = {
		pipe_solid_1: any
		pipe_slotted_1: any
		pipe_width_1: any
		pipe_geosock_1: any
		gas_tap_1: any
		pipe_solid_2: any
		pipe_slotted_2: any
		pipe_width_2: any
		pipe_geosock_2: any
		gas_tap_2: any
		general_remarks: any
		cover_size: any
		cover_type: any
		cover_comment_text: any
		backfill_text_output: any
		guid: any
		id: any
		time_of_action: any
	}
	type BackfillDataType = {
		depth_to_base: any
		depth_to_top: any
		description: any
		backfillText: any
		layers: any
		general_remarks: any
		type: any
		guid: any
		id: any
		time_of_action: any
	}
	type CommentsDataType = {
		id: string
		guid: string
		comment_type: string
		start_time: any
		duration: string
		general_remarks: string
		time_of_action: string
	}
	type InspectionPitDataType = {
		id: number
		guid: string
		depth_to_top: any
		depth_to_base: any
		duration: any
		weather_details: string
		general_remarks: string
		time_of_action: string
	}
	type CasingDataType = {
		id: number
		guid: string
		casing_type: any
		depth_to_top: any
		casing_diameter: any
		depth_to_base: any
		depth_of_borehole: any
		general_remarks: string
		time_of_action: string
	}
	type ChisellingDataType = {
		id: number
		guid: string
		depth_at_start: any
		depth_at_end: any
		// start_time: any
		duration: any
		general_remarks: any
		time_of_action: any
	}
	type WaterAddedDataType = {
		id: number
		guid: string
		depth_to_top: any
		depth_to_base: any
		amount_added: any
		start_time: any
		general_remarks: any
		time_of_action: any
	}
	type DailyWaterLevelsDataType = {
		id: number
		guid: string
		start_boring: any
		end_boring: any
		general_remarks: any
		time_of_action: any
	}
	type ReDrillDataType = {
		base_of_redrill: any
		casing_size: any
		general_remarks: any
		guid: string
		id: number
		time_of_action: any
		top_of_redrill: any
	}
	type TrialpitDataType = {
		id: any
		guid: any
		pit_set_out_by: any
		cat_sweep: any
		excavation_type: any
		pit_length: any
		pit_width: any
		pit_depth: any
		pit_continued: any
		pit_sides: any
		slight_instability_from: any
		slight_instability_to: any
		collapsed_completely_from: any
		collapsed_completely_to: any
		general_remarks: any
		time_of_action: any
	}
	type DetailDescriptionDataType = {
		depth_from: any
		depth_to: any
		detail_description: any
		general_remarks: any
		guid: any
		id: number
		time_of_action: any
	}

	const [SamplesData, setSamplesData] = useState<SampleType[]>([]);
	const [InSituTestsData, setInSituTestsData] = useState<InSituTestsType[]>([]);
	const [InSituVaneData, setInSituVaneData] = useState<InSituVaneDataType[]>([]);
	const [SoilDescriptionData, setSoilDescriptionData] = useState<SoilDescriptionDataType[]>([]);
	const [GroundWaterTestsData, setGroundWaterTestsData] = useState<GroundWaterTestsDataType[]>([]);
	const [BackfillData, setBackfillData] = useState<BackfillDataType[]>([]);
	const [InstallationData, setInstallationData] = useState<InstallationDataType[]>([]);
	const [CommentsData, setCommentsData] = useState<CommentsDataType[]>([]);
	const [InspectionPitData, setInspectionPitData] = useState<InspectionPitDataType[]>([]);
	const [CasingData, setCasingData] = useState<CasingDataType[]>([]);
	const [ChisellingData, setChisellingData] = useState<ChisellingDataType[]>([]);
	const [WaterAddedData, setWaterAddedData] = useState<WaterAddedDataType[]>([]);
	const [DailyWaterLevelsData, setDailyWaterLevelsData] = useState<DailyWaterLevelsDataType[]>([]);
	const [ReDrillData, setReDrillData] = useState<ReDrillDataType[]>([]);
	const [TrialpitData, setTrialpitData] = useState<TrialpitDataType[]>([]);
	const [DetailDescriptionData, setDetailDescriptionData] = useState<DetailDescriptionDataType[]>([]);




















	//.......................................................................................................
	//.PPPPPPPPP...PRRRRRRRRR.....OOOOOOO.........JJJJ....... DDDDDDDD.......AAAAA...AAATTTTTTTTT.TAAAA......
	//.PPPPPPPPPP..PRRRRRRRRRR...OOOOOOOOOO.......JJJJ....... DDDDDDDDD......AAAAA...AAATTTTTTTTT.TAAAA......
	//.PPPPPPPPPPP.PRRRRRRRRRR..ROOOOOOOOOOO......JJJJ....... DDDDDDDDDD....AAAAAA...AAATTTTTTTTT.TAAAAA.....
	//.PPPP...PPPP.PRRR...RRRRR.ROOOO..OOOOO......JJJJ....... DDD...DDDD....AAAAAAA......TTTT....TTAAAAA.....
	//.PPPP...PPPP.PRRR...RRRRRRROOO....OOOOO.....JJJJ....... DDD....DDDD..AAAAAAAA......TTTT....TTAAAAA.....
	//.PPPPPPPPPPP.PRRRRRRRRRR.RROO......OOOO.....JJJJ....... DDD....DDDD..AAAAAAAA......TTTT...TTTA.AAAA....
	//.PPPPPPPPPP..PRRRRRRRRRR.RROO......OOOO.....JJJJ....... DDD....DDDD..AAAA.AAAA.....TTTT...TTTA.AAAA....
	//.PPPPPPPPP...PRRRRRRR....RROO......OOOO.....JJJJ....... DDD....DDDD.DAAAAAAAAA.....TTTT...TTTAAAAAAA...
	//.PPPP........PRRR.RRRR...RROOO....OOOOOOOJJ.JJJJ....... DDD....DDDD.DAAAAAAAAAA....TTTT..TTTTAAAAAAA...
	//.PPPP........PRRR..RRRR...ROOOO..OOOOO.OOJJ.JJJJ....... DDD...DDDDD.DAAAAAAAAAA....TTTT..TTTTAAAAAAA...
	//.PPPP........PRRR..RRRRR..ROOOOOOOOOOO.OOJJJJJJJ....... DDDDDDDDDD.DDAA....AAAA....TTTT..TTTT....AAAA..
	//.PPPP........PRRR...RRRRR..OOOOOOOOOO..OOJJJJJJ........ DDDDDDDDD..DDAA.....AAAA...TTTT.TTTT.....AAAA..
	//.PPPP........PRRR....RRRR....OOOOOO.....OJJJJJ......... DDDDDDDD..DDDAA.....AAAA...TTTT.TTTT.....AAAA..
	//.......................................................................................................


	type ProjectData = {
		location?: string;
		project_identifier?: string;
		start_date?: string;
		site_description?: string;
		key_site_features?: string;
		site_history?: string;
		key_hands?: string;
		expected_strata?: string;
		comments?: string; // MISSING
		equipment_required?: string;
		client_name?: string;
		client_contact?: string;
		client_phone?: string;
		client_email?: string;
		engineer_name?: string;
		engineer_phone?: string;
		engineer_email?: string;
	}

	const cachedProjectValues = useGetIDBValues;

	const [projectData, setProjectData] = useState<ProjectData | null>(null);

	useEffect(() => {
		cachedProjectValues(`projects/${project_guid}`, (data: any) => {
			// console.log('Retrieved project data:', data); // Log the retrieved data
			setProjectData(data);
		}).then();
	}, [project_guid]);

	const project_location = projectData?.location ?? ''
	const projectID = projectData?.project_identifier ?? ''
	const project_start_date = projectData?.start_date ?? ''
	const site_description = projectData?.site_description ?? ''
	const key_site_features = projectData?.key_site_features ?? ''
	const site_history = projectData?.site_history ?? ''
	const key_hands = projectData?.key_hands ?? ''
	const expected_strata = projectData?.expected_strata ?? ''
	const notes = projectData?.comments ?? ''
	const equipment_required = projectData?.equipment_required ?? ''
	const client = projectData?.client_name ?? '' // client_name
	const client_contact = projectData?.client_contact ?? '' //client_contact
	const client_phone = projectData?.client_phone ?? '' // client_phone
	const client_email = projectData?.client_email ?? '' //client_email
	const engineer_name = projectData?.engineer_name ?? '' // engineer_name
	const engineer_phone = projectData?.engineer_phone ?? '' //engineer_phone
	const engineer_email = projectData?.engineer_email ?? '' //engineer_email

	const [engineerName, setEngineerName] = useState(engineer_name)

	if (engineer_name !== engineerName) {
		setEngineerName(engineer_name)
	}




















	//.........................................................
	//....CCCCCCC....RRRRRRRRRR...EEEEEEEEEEEEWWW..WWWWW...WW..
	//...CCCCCCCCC...RRRRRRRRRRR..EEEEEEEEEEEEWWW..WWWWW..WWW..
	//..CCCCCCCCCCC..RRRRRRRRRRR..EEEEEEEEEEEEWWW..WWWWWW.WWW..
	//..CCCC...CCCCC.RRRR...RRRRR.EEEE.......EWWW.WWWWWWW.WWW..
	//.CCCC.....CCC..RRRR...RRRRR.EEEE.......EWWW.WWWWWWW.WWW..
	//.CCCC..........RRRRRRRRRRR..EEEEEEEEEE..WWWWWWWWWWW.WWW..
	//.CCCC..........RRRRRRRRRRR..EEEEEEEEEE..WWWWWWW.WWWWWWW..
	//.CCCC..........RRRRRRRR.....EEEEEEEEEE..WWWWWWW.WWWWWWW..
	//.CCCC.....CCC..RRRR.RRRR....EEEE........WWWWWWW.WWWWWWW..
	//..CCCC...CCCCC.RRRR..RRRR...EEEE........WWWWWWW.WWWWWWW..
	//..CCCCCCCCCCC..RRRR..RRRRR..EEEEEEEEEEE..WWWWW...WWWWW...
	//...CCCCCCCCCC..RRRR...RRRRR.EEEEEEEEEEE..WWWWW...WWWWW...
	//....CCCCCCC....RRRR....RRRR.EEEEEEEEEEE..WWWWW...WWWWW...
	//.........................................................

	const loggedInUser = useGetLoggedInUser()
	// const [User, setUser] = useState(loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name))
	const User = loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name)
	const [userWarning, setUserWarning] = useState(false)
	
	const negPhrases = [
		"You shall not pass!",
		"None shall pass!",
		"Access denied!",
		"No trespassing!",
		"Entry prohibited!",
		"Stay out!",
		"Get yer stinkin' mitts off!",
		"These aren't the droids you're looking for!"
	  ];
	
	const randomNegPhrases = negPhrases[Math.floor(Math.random() * negPhrases.length)];
	
	const [leadDriller, setLeadDriller] = useState('')
	const [leadDrillerID, setLeadDrillerID] = useState(0)
	const [leadDrillerCSCS, setLeadDrillerCSCS] = useState('')
	const [secondMan, setSecondMan] = useState('')
	const [secondManCSCS, setSecondManCSCS] = useState('')
	const [hole_barcode, setHole_barcode] = useState('')
	const [hole_status, setHole_status] = useState('')
	const [numberingType, setNumberingType] = useState('')

	const [savedRigGuid, setSavedRigValue] = useState('')
	const [plantName, setPlantName] = useState('')
	const [sptGUID, setSptGUID] = useState('')
	const [activityTerminationReason, setActivityTerminationReason] = useState('')

	const [boreholeName, setBoreholeName] = useState(borehole_name)

	const cachedHoleInformation = useGetIDBValues

	useEffect(() => {
		if(borehole_guid){
			cachedHoleInformation(`hole-locations/${borehole_guid}`, (holeData: any) => {
				if (holeData) {
					console.log('holeData in PERSONNEL:', holeData)
					setLeadDriller(holeData.driller)
					setLeadDrillerID(holeData.driller_id)
					setLeadDrillerCSCS(holeData.driller_cscs);
					setSecondMan(holeData.second_man);
					setSecondManCSCS(holeData.second_man_cscs);
					setHole_barcode(holeData.hole_barcode);
					setSavedRigValue(holeData.rig_id); // RIG GUID
					setPlantName(holeData.rig_name); // RIG NAME
					setSptGUID(holeData.spt_id); // SPT ID
					setActivityTerminationReason(holeData.activity_termination_reason); // TERMINATION REASON
					setBoreholeName(holeData.hole_name); // HOLE NAME
					setHole_status(holeData.status); // HOLE STATUS
					setNumberingType(holeData.numberingType); // NUMBERING TYPE
				
					console.log('PERSONNEL*********************************************')
					console.log('leadDriller:', leadDriller)
					console.log('leadDrillerID:', leadDrillerID)
					console.log('leadDrillerCSCS:', leadDrillerCSCS)
					console.log('secondMan:', secondMan)
					console.log('secondManCSCS:', secondManCSCS)
					console.log('hole_barcode:', hole_barcode)
					console.log('savedRigGuid:', savedRigGuid)
					console.log('plantName:', plantName)
					console.log('sptGUID:', sptGUID)
					console.log('boreholeName:', boreholeName)
					console.log('hole_status:', hole_status)
					console.log('selectedDate:', selectedDate)
					console.log('activityTerminationReason:', activityTerminationReason)
					console.log('******************************************************')
				} else {
					// // console.log(`**ERROR** HOLE DATA NOT LOADED. ID: ${borehole_guid}`);
				}
				// // console.log('holeData:', holeData);

			});
		}
	}, [borehole_guid]);




















	//.............................................................................................
	//....CCCCCCC......OOOOOOO....MMMMMM...MMMMMM.PPPPPPPPP.....AAAA.....ANNNN...NNNNNNYYY....YYY..
	//...CCCCCCCCC....OOOOOOOOOO..MMMMMM...MMMMMM.PPPPPPPPPP...AAAAAA....ANNNN...NNNN.NYYY...YYYY..
	//..CCCCCCCCCCC..OOOOOOOOOOOO.MMMMMM...MMMMMM.PPPPPPPPPPP..AAAAAA....ANNNNN..NNNN..YYYY..YYYY..
	//..CCCC...CCCCC.OOOOO..OOOOO.MMMMMMM.MMMMMMM.PPPP...PPPP..AAAAAAA...ANNNNNN.NNNN..YYYYYYYYY...
	//.CCCC.....CCC.OOOOO....OOOOOMMMMMMM.MMMMMMM.PPPP...PPPP.AAAAAAAA...ANNNNNN.NNNN...YYYYYYYY...
	//.CCCC.........OOOO......OOOOMMMMMMM.MMMMMMM.PPPPPPPPPPP.AAAAAAAA...ANNNNNNNNNNN...YYYYYYY....
	//.CCCC.........OOOO......OOOOMMMMMMMMMMMMMMM.PPPPPPPPPP..AAAA.AAAA..ANNNNNNNNNNN....YYYYY.....
	//.CCCC.........OOOO......OOOOMMMMMMMMMMMMMMM.PPPPPPPPP..AAAAAAAAAA..ANNN.NNNNNNN.....YYYY.....
	//.CCCC.....CCC.OOOOO....OOOOOMMMMMMMMMMMMMMM.PPPP.......AAAAAAAAAAA.ANNN.NNNNNNN.....YYYY.....
	//..CCCC...CCCCC.OOOOO..OOOOO.MMMM.MMMMM.MMMM.PPPP......PAAAAAAAAAAA.ANNN..NNNNNN.....YYYY.....
	//..CCCCCCCCCCC..OOOOOOOOOOOO.MMMM.MMMMM.MMMM.PPPP......PAAA....AAAA.ANNN..NNNNNN.....YYYY.....
	//...CCCCCCCCCC...OOOOOOOOOO..MMMM.MMMMM.MMMM.PPPP......PAAA....AAAAAANNN...NNNNN.....YYYY.....
	//....CCCCCCC.......OOOOOO....MMMM.MMMMM.MMMM.PPPP.....PPAAA.....AAAAANNN....NNNN.....YYYY.....
	//.............................................................................................

	const [companyInfo, setCompanyInfo] = useState({
		companyID: '',
		companyName: '',
		contactEmail: '',
		contactTelephone: '',
		companyAddress1: '',
		companyAddress2: '',
		companyAddress3: '',
		companyCounty: '',
		companyCountry: '',
		companyPostcode: '',
	});

	useEffect(() => {
		const getCompanyInfoFromCache = async () => {
			const response = await get('drillit-user');
			console.log(response)
			setCompanyInfo({
				companyID: response.user.company.id,
				companyName: response.user.company.name,
				contactEmail: response.user.company.email,
				contactTelephone: response.user.company.phone,
				companyAddress1: response.user.company.address1,
				companyAddress2: response.user.company.address2,
				companyAddress3: response.user.company.address3,
				companyCounty: response.user.company.county,
				companyCountry: response.user.company.country,
				companyPostcode: response.user.company.postcode,
			});
		};

		getCompanyInfoFromCache();
	}, []);

	const companyID = companyInfo.companyID
	const companyName = companyInfo.companyName
	const companyTelephone = companyInfo.contactTelephone
	const companyEmail = companyInfo.contactEmail

	let companyAddress = companyInfo.companyAddress1
	if (companyInfo.companyAddress2) { companyAddress = companyAddress + ', ' + companyInfo.companyAddress2 }
	if (companyInfo.companyAddress3) { companyAddress = companyAddress + ', ' + companyInfo.companyAddress3 }
	if (companyInfo.companyCounty) { companyAddress = companyAddress + ', ' + companyInfo.companyCounty }
	if (companyInfo.companyCountry) { companyAddress = companyAddress + ', ' + companyInfo.companyCountry }
	if (companyInfo.companyPostcode) { companyAddress = companyAddress + ', ' + companyInfo.companyPostcode }

	const companyLogo = "https://drillitlogs.com/images/company/" + companyID + ".png"











	// Info to pass into 'ProjectHeader' & 'ProjectOptionsBorehole'
	// Hole Name
	// Hole Driller
	// 2nd man
	// Rig
	// Daily Plant Check




















	//..........................................................................
	//.LLLL..........AAAAA.....BBBBBBBBBB...EEEEEEEEEEE.LLLL........SSSSSSS.....
	//.LLLL..........AAAAA.....BBBBBBBBBBB..EEEEEEEEEEE.LLLL.......SSSSSSSSS....
	//.LLLL.........AAAAAA.....BBBBBBBBBBB..EEEEEEEEEEE.LLLL.......SSSSSSSSSS...
	//.LLLL.........AAAAAAA....BBBB...BBBB..EEEE........LLLL......LSSSS..SSSS...
	//.LLLL........AAAAAAAA....BBBB...BBBB..EEEE........LLLL......LSSSS.........
	//.LLLL........AAAAAAAA....BBBBBBBBBBB..EEEEEEEEEE..LLLL.......SSSSSSS......
	//.LLLL........AAAA.AAAA...BBBBBBBBBB...EEEEEEEEEE..LLLL........SSSSSSSSS...
	//.LLLL.......AAAAAAAAAA...BBBBBBBBBBB..EEEEEEEEEE..LLLL..........SSSSSSS...
	//.LLLL.......AAAAAAAAAAA..BBBB....BBBB.EEEE........LLLL.............SSSSS..
	//.LLLL.......AAAAAAAAAAA..BBBB....BBBB.EEEE........LLLL......LSSS....SSSS..
	//.LLLLLLLLLLLAAA....AAAA..BBBBBBBBBBBB.EEEEEEEEEEE.LLLLLLLLLLLSSSSSSSSSSS..
	//.LLLLLLLLLLLAAA.....AAAA.BBBBBBBBBBB..EEEEEEEEEEE.LLLLLLLLLL.SSSSSSSSSS...
	//.LLLLLLLLLLLAAA.....AAAA.BBBBBBBBBB...EEEEEEEEEEE.LLLLLLLLLL..SSSSSSSS....
	//..........................................................................

	const projectLocation = project_location
	let logsheetLocationStyle = "headerCellLocationInput"
	if (projectLocation) {
		if (projectLocation.length <= 25) { logsheetLocationStyle = "headerCellLocationInput" }
		if (projectLocation.length > 25 && projectLocation.length <= 60) { logsheetLocationStyle = "headerCellLocationInputSmall" }
		if (projectLocation.length > 60) { logsheetLocationStyle = "headerCellLocationInputXSmall" }
	}

	let sheetDateObject = new Date(selectedDate)
	const sheetDate = sheetDateObject.toLocaleDateString()

	const labelbarcodeVar = projectID + '-' + borehole_name

	const barcodeWidths = [
		3.3, 3.3, 3.3, 3.3, 3.3, 3.3, 3.0, 2.7, 2.5, 2.3, // 1 to 10
		2.1, 2.0, 1.85, 1.75, 1.65, 1.55, 1.5, 1.42, 1.36, 1.3, // 11 to 20
		1.25, 1.2, 1.15, 1.1, 1.05, 1.0, 1.0, 0.95, 0.93, 0.90, // 21 to 30
		0.88, 0.85, 0.83, 0.80, 0.80, 0.76, 0.75, 0.73, 0.72, 0.71 // 31 to 40
	];

	let barcodeWidth = barcodeWidths[labelbarcodeVar.length - 1]





















	//.............................................................
	//.DDDDDDDDD.......AAAAA...AATTTTTTTTTTTEEEEEEEEE...SSSSSSS....
	//.DDDDDDDDDD......AAAAA...AATTTTTTTTTTTEEEEEEEEE..ESSSSSSSS...
	//.DDDDDDDDDDD....AAAAAA...AATTTTTTTTTTTEEEEEEEEE.EESSSSSSSSS..
	//.DDDD...DDDD....AAAAAAA......TTTT...TTEE........EESS...SSSS..
	//.DDDD....DDDD..AAAAAAAA......TTTT...TTEE........EESSS........
	//.DDDD....DDDD..AAAAAAAA......TTTT...TTEEEEEEEEE..ESSSSSS.....
	//.DDDD....DDDD..AAAA.AAAA.....TTTT...TTEEEEEEEEE...SSSSSSSS...
	//.DDDD....DDDD.AAAAAAAAAA.....TTTT...TTEEEEEEEEE.....SSSSSSS..
	//.DDDD....DDDD.AAAAAAAAAAA....TTTT...TTEE...............SSSS..
	//.DDDD...DDDDD.AAAAAAAAAAA....TTTT...TTEE........EESS...SSSS..
	//.DDDDDDDDDDD.DAAA....AAAA....TTTT...TTEEEEEEEEEEEESSSSSSSSS..
	//.DDDDDDDDDD..DAAA.....AAAA...TTTT...TTEEEEEEEEEE.ESSSSSSSSS..
	//.DDDDDDDDD..DDAAA.....AAAA...TTTT...TTEEEEEEEEEE..SSSSSSS....
	//.............................................................

	const [active_dates, setActive_dates] = useState(['06/04/2023', '05/04/2023'])

	function setAllActiveDates() {
		setActive_dates(activeDates)
	}

	useEffect(() => {
		setAllActiveDates()
	}, [activeDates])




















	//........................................................................................................
	//.HHHH...HHHH....OOOOOOO.....LLLL.......EEEEEEEEEEE...... DDDDDDDD.......AAAAA...AATTTTTTTTTT.AAAAA......
	//.HHHH...HHHH...OOOOOOOOOO...LLLL.......EEEEEEEEEEE...... DDDDDDDDD......AAAAA...AATTTTTTTTTT.AAAAA......
	//.HHHH...HHHH..OOOOOOOOOOOO..LLLL.......EEEEEEEEEEE...... DDDDDDDDDD....AAAAAA...AATTTTTTTTTT.AAAAAA.....
	//.HHHH...HHHH..OOOOO..OOOOO..LLLL.......EEEE............. DDD...DDDD....AAAAAAA......TTTT....TAAAAAA.....
	//.HHHH...HHHH.HOOOO....OOOOO.LLLL.......EEEE............. DDD....DDDD..AAAAAAAA......TTTT....TAAAAAA.....
	//.HHHHHHHHHHH.HOOO......OOOO.LLLL.......EEEEEEEEEE....... DDD....DDDD..AAAAAAAA......TTTT...TTAA.AAAA....
	//.HHHHHHHHHHH.HOOO......OOOO.LLLL.......EEEEEEEEEE....... DDD....DDDD..AAAA.AAAA.....TTTT...TTAA.AAAA....
	//.HHHHHHHHHHH.HOOO......OOOO.LLLL.......EEEEEEEEEE....... DDD....DDDD.DAAAAAAAAA.....TTTT...TTAAAAAAAA...
	//.HHHH...HHHH.HOOOO....OOOOO.LLLL.......EEEE............. DDD....DDDD.DAAAAAAAAAA....TTTT..TTTAAAAAAAA...
	//.HHHH...HHHH..OOOOO..OOOOO..LLLL.......EEEE............. DDD...DDDDD.DAAAAAAAAAA....TTTT..TTTAAAAAAAA...
	//.HHHH...HHHH..OOOOOOOOOOOO..LLLLLLLLLL.EEEEEEEEEEE...... DDDDDDDDDD.DDAA....AAAA....TTTT..TTTA....AAAA..
	//.HHHH...HHHH...OOOOOOOOOO...LLLLLLLLLL.EEEEEEEEEEE...... DDDDDDDDD..DDAA.....AAAA...TTTT.TTTT.....AAAA..
	//.HHHH...HHHH.....OOOOOO.....LLLLLLLLLL.EEEEEEEEEEE...... DDDDDDDD..DDDAA.....AAAA...TTTT.TTTT.....AAAA..
	//........................................................................................................

	function getAllHoleData() { // split out the HOLE DATA and SET CONSTs

		// console.log("getAllHoleData function run! THIS ONLY RUNS ONCE!!!***************************************************************")

		const SampleArray = hole_data?.action_sample
		const unpackedSamples = SampleArray?.filter((sample: any) => sample) || [];
		setSamplesData(unpackedSamples)
		// console.log("Action Sample Array:", AllHoleData?.hole_data?.action_sample)
		// console.log("Unpacked Samples:", unpackedSamples)
		// console.log("samplesData:", SamplesData)

		const InsituTestsArray = hole_data?.action_in_situ_spt;
		const unpackedInsituTests = InsituTestsArray?.filter((sample: any) => sample) || [];
		setInSituTestsData(unpackedInsituTests);

		const SoilDescriptionsArray = hole_data?.action_soil_description;
		const unpackedSoilDescriptions = SoilDescriptionsArray?.filter((sample: any) => sample) || [];
		setSoilDescriptionData(unpackedSoilDescriptions);

		const GroundWaterTestsArray = hole_data?.action_groundwater;
		const unpackedGroundWaterTests = GroundWaterTestsArray?.filter((sample: any) => sample) || [];
		setGroundWaterTestsData(unpackedGroundWaterTests);

		const BackfillArray = hole_data?.action_backfills;
		const unpackedBackfill = BackfillArray?.filter((sample: any) => sample) || [];
		setBackfillData(unpackedBackfill);

		const InstallArray = hole_data?.action_installation;
		const unpackedInstall = InstallArray?.filter((sample: any) => sample) || [];
		setInstallationData(unpackedInstall);

		const CommentsArray = hole_data?.action_comment;
		const unpackedComments = CommentsArray?.filter((sample: any) => sample) || [];
		setCommentsData(unpackedComments);

		const InspectionPitArray = hole_data?.action_inspection_pit;
		const unpackedInspectionPit = InspectionPitArray?.filter((sample: any) => sample) || [];
		setInspectionPitData(unpackedInspectionPit);

		const CasingArray = hole_data?.action_casing;
		const unpackedCasing = CasingArray?.filter((sample: any) => sample) || [];
		setCasingData(unpackedCasing);

		const ChisellingArray = hole_data?.action_chiselling;
		const unpackedChiselling = ChisellingArray?.filter((sample: any) => sample) || [];
		setChisellingData(unpackedChiselling);

		const WaterAddedArray = hole_data?.action_water_added;
		const unpackedWaterAdded = WaterAddedArray?.filter((sample: any) => sample) || [];
		setWaterAddedData(unpackedWaterAdded);

		const ReDrillArray = hole_data?.action_redrill;
		const unpackedReDrill = ReDrillArray?.filter((sample: any) => sample) || [];
		setReDrillData(unpackedReDrill);

		// console.log('unpackedReDrill:', unpackedReDrill)

		const DailyWaterLevelsArray = hole_data?.action_daily_water_levels;
		const unpackedDailyWaterLevels = DailyWaterLevelsArray?.filter((sample: any) => sample) || [];
		setDailyWaterLevelsData(unpackedDailyWaterLevels);

		const TrialpitArray = AllHoleData?.hole_data?.action_trialpit;
		const unpackedTrialpit = TrialpitArray?.filter((sample: any) => sample) || [];
		setTrialpitData(unpackedTrialpit);

		const DetailDescriptionArray = AllHoleData?.hole_data?.action_detail_description;
		const unpackedDetailDescription = DetailDescriptionArray?.filter((sample: any) => sample) || [];
		setDetailDescriptionData(unpackedDetailDescription);
	}

	// console.log('AllHoleData: ', AllHoleData) // use this to check hole data arrays

	useEffect(() => {
		getAllHoleData()
	}, []
	)




















	//.......................................................................................................
	//...SSSSSSS.......AAAAA..AAAVV....VVVVVVEEEEEEEEE...... DDDDDDDD.......AAAA....AAATTTTTTTTT.TAAAA......
	//..SSSSSSSSS......AAAAA...AAVV....VVVVVVEEEEEEEEE...... DDDDDDDDD.....AAAAAA...AAATTTTTTTTT.TAAAA......
	//..SSSSSSSSSS....AAAAAA...AAVV...VVVVVVVEEEEEEEEE...... DDDDDDDDDD....AAAAAA...AAATTTTTTTTTTTAAAA......
	//.SSSSS..SSSS....AAAAAAA..AAVVV..VVVV.VVEE............. DD....DDDD....AAAAAAA......TTTT....TTAAAAA.....
	//.SSSSS.........AAAAAAAA...AVVV..VVVV.VVEE............. DD....DDDDD..AAAAAAAA......TTTT...TTTAAAAA.....
	//..SSSSSSS......AAAAAAAA...AVVV..VVVV.VVEEEEEEEEE...... DD.....DDDD..AAAAAAAA......TTTT...TTTAAAAA.....
	//...SSSSSSSSS...AAAA.AAAA..AVVV.VVVV..VVEEEEEEEEE...... DD.....DDDD..AAAA.AAAA.....TTTT...TTTA.AAAA....
	//.....SSSSSSS..AAAAAAAAAA...VVVVVVVV..VVEEEEEEEEE...... DD.....DDDD.DAAAAAAAAA.....TTTT..TTTTAAAAAA....
	//........SSSSS.AAAAAAAAAAA..VVVVVVVV..VVEE............. DD....DDDDD.DAAAAAAAAAA....TTTT..TTTTAAAAAAA...
	//.SSSS....SSSS.AAAAAAAAAAA..VVVVVVV...VVEE............. DD....DDDD.DDAAAAAAAAAA....TTTT..TTTTAAAAAAA...
	//.SSSSSSSSSSSSSAAA....AAAA...VVVVVV...VVEEEEEEEEEE..... DDDDDDDDDD.DDAA....AAAA....TTTT.TTTT....AAAA...
	//..SSSSSSSSSS.SAAA.....AAAA..VVVVVV...VVEEEEEEEEEE..... DDDDDDDDD..DDAA....AAAAA...TTTT.TTTT.....AAAA..
	//...SSSSSSSS.SSAAA.....AAAA..VVVVV....VVEEEEEEEEEE..... DDDDDDDD..DDDAA.....AAAA...TTTTTTTTT.....AAAA..
	//.......................................................................................................

	function saveAction(value: any, type: string, hide: any) {

		const saveToIDB = useSetIndexedDBValues
		const updateIDB = useUpdateIndexedDBValues
		const deleteFromIDB = useDeleteIndexedDBValue

		value['borehole_guid'] = borehole_guid;
		value['type'] = type;

		switch (type) {

			case 'D':
			case 'B':
			case 'ES':
			case 'WS':
				const submitSamplesData = {
					"task_type": "action_sample",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value,
						"weather_details": selectedWeather,
						"sampler": leadDriller,
					}
				}

				if (value['update']) {
					const newState = SamplesData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setSamplesData(newState)
					updateIDB(state, `actions/${value['guid']}`, 'SamplesData', value['guid'], 'sample', submitSamplesData)
				} else if (value['delete']) {
					const newState = SamplesData.filter((obj) => obj.guid !== value['guid']);
					setSamplesData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'SamplesData', value['guid'], 'sample', submitSamplesData)
				} else {
					setSamplesData(prevState => [...prevState, value])
					saveToIDB(submitSamplesData, 'action', true)
				}

				break;


			case 'SPT':
			case 'CPT':
			case 'U':
			case 'PS':
			case 'SV':
				const submitInSituData = {
					"task_type": "action_in_situ_spt",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value,
						"weather_details": selectedWeather,
						"spt_hammer_id": '',
						"spt_energy_ratio": '',
						"sampler": leadDriller,
					}
				}

				if (value['update']) {
					const newState = InSituTestsData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setInSituTestsData(newState)
					updateIDB(state, `actions/${value['guid']}`, 'InSituTestsData', value['guid'], 'in_situ_spt', submitInSituData)
				} else if (value['delete']) {
					const newState = InSituTestsData.filter((obj) => obj.guid !== value['guid']);
					setInSituTestsData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'InSituTestsData', value['guid'], 'in_situ_spt', submitInSituData)
				} else {
					setInSituTestsData(prevState => [...prevState, value])
					saveToIDB(submitInSituData, 'action', true)
				}

				break;


			case 'SoilDescription':
				const submitSoilDescriptionData = {
					"task_type": "action_soil_description",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value
					}
				}

				if (value['update']) {
					const newState = SoilDescriptionData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setSoilDescriptionData(newState)
					updateIDB(state, `actions/${value['guid']}`, 'SoilDescriptionData', value['guid'], 'soil_description', submitSoilDescriptionData)
				} else if (value['delete']) {
					const newState = SoilDescriptionData.filter((obj) => obj.guid !== value['guid']);
					setSoilDescriptionData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'SoilDescriptionData', value['guid'], 'soil_description', submitSoilDescriptionData)
				} else {
					setSoilDescriptionData(prevState => [...prevState, value])
					saveToIDB(submitSoilDescriptionData, 'action', true)
				}

				break;

			case 'GroundWater':
				const submitGroundWaterData = {
					"task_type": "action_groundwater",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value
					}
				}

				if (value['update']) {
					const newState = GroundWaterTestsData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setGroundWaterTestsData(newState)
					updateIDB(state, `actions/${value['guid']}`, 'GroundWaterTestsData', value['guid'], 'groundwater', submitGroundWaterData)
				} else if (value['delete']) {
					const newState = GroundWaterTestsData.filter((obj) => obj.guid !== value['guid']);
					setGroundWaterTestsData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'GroundWaterTestsData', value['guid'], 'groundwater', submitGroundWaterData)
				} else {
					setGroundWaterTestsData(prevState => [...prevState, value])
					saveToIDB(submitGroundWaterData, 'action', true)
				}

				break;

			case 'Installation':
				const submitInstallationData = {
					"task_type": "action_installation",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value
					}
				}

				if (value['update']) {
					const newState = InstallationData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setInstallationData(newState)
					updateIDB(state, `actions/${value['guid']}`, 'InstallationData', value['guid'], 'installation', submitInstallationData)
				} else if (value['delete']) {
					const newState = InstallationData.filter((obj) => obj.guid !== value['guid']);
					setInstallationData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'InstallationData', value['guid'], 'installation', submitInstallationData)
				} else {
					// console.log('install values:', value)
					setInstallationData(prevState => [...prevState, value])
					saveToIDB(submitInstallationData, 'action', true)
				}

				break;

			case 'Backfill':
				const submitBackfillData = {
					"task_type": "action_backfills",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value,
						"weather_details": selectedWeather,
						"hole_location_id": borehole_id,
					}
				}

				if (value['update']) {
					const newState = BackfillData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setBackfillData(newState)
					updateIDB(state, `actions/${value['guid']}`, 'BackfillData', value['guid'], 'backfills', submitBackfillData)
				} else if (value['delete']) {
					const newState = BackfillData.filter((obj) => obj.guid !== value['guid']);
					setBackfillData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'BackfillData', value['guid'], 'backfills', submitBackfillData)
				} else {
					setBackfillData(prevState => [...prevState, value])
					saveToIDB(submitBackfillData, 'action', true)
				}

				break;

			case 'Comments':
				const submitCommentsData = {
					"task_type": "action_comment",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value
					}
				}

				if (value['update']) {
					const newState = CommentsData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setCommentsData(newState)
					updateIDB(state, `actions/${value['guid']}`, 'CommentsData', value['guid'], 'comment', submitCommentsData)
				} else if (value['delete']) {
					const newState = CommentsData.filter((obj) => obj.guid !== value['guid']);
					setCommentsData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'CommentsData', value['guid'], 'comment', submitCommentsData)
				} else {
					setCommentsData(prevState => [...prevState, value])
					saveToIDB(submitCommentsData, 'action', true)
				}

				break;

			case 'InspectionPit':
				const submitInspectionPitData = {
					"task_type": "action_inspection_pit",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						"weather_details": selectedWeather,
						...value
					}
				}

				if (value['update']) {
					const newState = InspectionPitData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setInspectionPitData(newState)
					updateIDB(state, `actions/${value['guid']}`, 'InspectionPitData', value['guid'], 'inspection_pit', submitInspectionPitData)
				} else if (value['delete']) {
					const newState = InspectionPitData.filter((obj) => obj.guid !== value['guid']);
					setInspectionPitData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'InspectionPitData', value['guid'], 'inspection_pit', submitInspectionPitData)
				} else {
					setInspectionPitData(prevState => [...prevState, value])
					saveToIDB(submitInspectionPitData, 'action', true)
				}

				break;

			case 'Casing':
				const submitCasingData = {
					"task_type": "action_casing",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value
					}
				}

				if (value['update']) {
					const newState = CasingData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setCasingData(newState)
					updateIDB(state, `actions/${value['guid']}`, 'CasingData', value['guid'], 'casing', submitCasingData)
				} else if (value['delete']) {
					const newState = CasingData.filter((obj) => obj.guid !== value['guid']);
					setCasingData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'CasingData', value['guid'], 'casing', submitCasingData)
				} else {
					setCasingData(prevState => [...prevState, value])
					saveToIDB(submitCasingData, 'action', true)
				}

				break;

			case 'Chiselling':
				const submitChisellingData = {
					"task_type": "action_chiselling",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value
					}
				}

				if (value['update']) {
					const newState = ChisellingData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setChisellingData(newState)
					updateIDB(state, `actions/${value['guid']}`, 'ChisellingData', value['guid'], 'chiselling', submitChisellingData)
				} else if (value['delete']) {
					const newState = ChisellingData.filter((obj) => obj.guid !== value['guid']);
					setChisellingData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'ChisellingData', value['guid'], 'chiselling', submitChisellingData)
				} else {
					setChisellingData(prevState => [...prevState, value])
					saveToIDB(submitChisellingData, 'action', true)
				}

				break;

			case 'WaterAdded':
				const submitWaterAddedData = {
					"task_type": "action_water_added",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value
					}
				}

				if (value['update']) {
					const newState = WaterAddedData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setWaterAddedData(newState)
					updateIDB(state, `actions/${value['guid']}`, 'WaterAddedData', value['guid'], 'water_added', submitWaterAddedData)
				} else if (value['delete']) {
					const newState = WaterAddedData.filter((obj) => obj.guid !== value['guid']);
					setWaterAddedData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'WaterAddedData', value['guid'], 'water_added', submitWaterAddedData)
				} else {
					setWaterAddedData(prevState => [...prevState, value])
					saveToIDB(submitWaterAddedData, 'action', true)
				}

				break;

			case 'DailyWaterLevels':
				const submitDailyWaterLevelsData = {
					"task_type": "action_daily_water_levels",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value
					}
				}

				if (value['update']) {
					const newState = DailyWaterLevelsData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setDailyWaterLevelsData(newState)
					updateIDB(state, `actions/${value['guid']}`, 'DailyWaterLevelsData', value['guid'], 'daily_water_levels', submitDailyWaterLevelsData)
				} else if (value['delete']) {
					const newState = DailyWaterLevelsData.filter((obj) => obj.guid !== value['guid']);
					setDailyWaterLevelsData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'DailyWaterLevelsData', value['guid'], 'daily_water_levels', submitDailyWaterLevelsData)
				} else {
					setDailyWaterLevelsData(prevState => [...prevState, value])
					saveToIDB(submitDailyWaterLevelsData, 'action', true)
				}

				break;

			case 'ReDrill':
				const submitReDrillData = {
					"task_type": "action_redrill",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value
					}
				}

				if (value['update']) {
					const newState = ReDrillData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setReDrillData(newState)
					updateIDB(state, `actions/${value['guid']}`, 'ReDrillData', value['guid'], 'redrill', submitReDrillData)
				} else if (value['delete']) {
					const newState = ReDrillData.filter((obj) => obj.guid !== value['guid']);
					setReDrillData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'ReDrillData', value['guid'], 'redrill', submitReDrillData)
				} else {
					setReDrillData(prevState => [...prevState, value])
					saveToIDB(submitReDrillData, 'action', true)
				}

				break;

			case 'Trialpit':
				const submitTrialpitData = {
					"task_type": "action_trialpit",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value
					}
				}

				if (value['update']) {
					const newState = TrialpitData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setTrialpitData(newState)
					updateIDB(state, `actions/${value['guid']}`, 'TrialpitData', value['guid'], 'trialpit', submitTrialpitData)
				} else if (value['delete']) {
					const newState = TrialpitData.filter((obj) => obj.guid !== value['guid']);
					setTrialpitData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'TrialpitData', value['guid'], 'trialpit', submitTrialpitData)
				} else {
					setTrialpitData(prevState => [...prevState, value])
					saveToIDB(submitTrialpitData, 'action', true)
				}

				break;

			case 'DetailDescription':
				const submitDetailDescriptionData = {
					"task_type": "action_detail_description",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value
					}
				}

				if (value['update']) {
					const newState = DetailDescriptionData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setDetailDescriptionData(newState)
					updateIDB(state, `actions/${value['guid']}`, 'DetailDescriptionData', value['guid'], 'detail_description', submitDetailDescriptionData)
				} else if (value['delete']) {
					const newState = DetailDescriptionData.filter((obj) => obj.guid !== value['guid']);
					setDetailDescriptionData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'DetailDescriptionData', value['guid'], 'detail_description', submitDetailDescriptionData)
				} else {
					setDetailDescriptionData(prevState => [...prevState, value])
					saveToIDB(submitDetailDescriptionData, 'action', true)
				}

				break;
		}

		// // Set hole to active if not already
		if (hole_status === '1') {
			activateHole(borehole_guid, project_guid)
			getHoleData()
		}
	}

	//...............................................................................................................
	//.IIIII.DDDDDDDDD....BBBBBBBBBB........UUUU...UUUU..PPPPPPPPP...DDDDDDDDD.......AAAAA...AATTTTTTTTTTEEEEEEEEEE..
	//.IIIII.DDDDDDDDDD...BBBBBBBBBBB.......UUUU...UUUU..PPPPPPPPPP..DDDDDDDDDD......AAAAA...AATTTTTTTTTTEEEEEEEEEE..
	//.IIIII.DDDDDDDDDDD..BBBBBBBBBBB.......UUUU...UUUU..PPPPPPPPPPP.DDDDDDDDDDD....AAAAAA...AATTTTTTTTTTEEEEEEEEEE..
	//.IIIII.DDDD...DDDD..BBBB...BBBB.......UUUU...UUUU..PPPP...PPPP.DDDD...DDDD....AAAAAAA......TTTT...TEEE.........
	//.IIIII.DDDD....DDDD.BBBB...BBBB.......UUUU...UUUU..PPPP...PPPP.DDDD....DDDD..AAAAAAAA......TTTT...TEEE.........
	//.IIIII.DDDD....DDDD.BBBBBBBBBBB.......UUUU...UUUU..PPPPPPPPPPP.DDDD....DDDD..AAAAAAAA......TTTT...TEEEEEEEEEE..
	//.IIIII.DDDD....DDDD.BBBBBBBBBB........UUUU...UUUU..PPPPPPPPPP..DDDD....DDDD..AAAA.AAAA.....TTTT...TEEEEEEEEEE..
	//.IIIII.DDDD....DDDD.BBBBBBBBBBB.......UUUU...UUUU..PPPPPPPPP...DDDD....DDDD.AAAAAAAAAA.....TTTT...TEEEEEEEEEE..
	//.IIIII.DDDD....DDDD.BBBB....BBBB......UUUU...UUUU..PPPP........DDDD....DDDD.AAAAAAAAAAA....TTTT...TEEE.........
	//.IIIII.DDDD...DDDDD.BBBB....BBBB......UUUU...UUUU..PPPP........DDDD...DDDDD.AAAAAAAAAAA....TTTT...TEEE.........
	//.IIIII.DDDDDDDDDDD..BBBBBBBBBBBB......UUUUUUUUUUU..PPPP........DDDDDDDDDDD.DAAA....AAAA....TTTT...TEEEEEEEEEE..
	//.IIIII.DDDDDDDDDD...BBBBBBBBBBB........UUUUUUUUU...PPPP........DDDDDDDDDD..DAAA.....AAAA...TTTT...TEEEEEEEEEE..
	//.IIIII.DDDDDDDDD....BBBBBBBBBB..........UUUUUUU....PPPP........DDDDDDDDD..DDAAA.....AAAA...TTTT...TEEEEEEEEEE..
	//...............................................................................................................

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_sample = SamplesData;
				return val
			})
		}
	}, [SamplesData])

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_in_situ_spt = InSituTestsData;
				return val
			})
		}
	}, [InSituTestsData])

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_soil_description = SoilDescriptionData;
				return val
			})
		}
	}, [SoilDescriptionData])

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_groundwater = GroundWaterTestsData;
				return val
			})
		}
	}, [GroundWaterTestsData])

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_installation = InstallationData;
				return val
			})
		}
	}, [InstallationData])

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_backfills = BackfillData;
				return val
			})
		}
	}, [BackfillData])

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_comment = CommentsData;
				return val
			})
		}
	}, [CommentsData])

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_inspection_pit = InspectionPitData;
				return val
			})
		}
	}, [InspectionPitData])

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_casing = CasingData;
				return val
			})
		}
	}, [CasingData])

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_chiselling = ChisellingData;
				return val
			})
		}
	}, [ChisellingData])

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_water_added = WaterAddedData;
				return val
			})
		}
	}, [WaterAddedData])

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_daily_water_levels = DailyWaterLevelsData;
				return val
			})
		}
	}, [DailyWaterLevelsData])

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_redrill = ReDrillData;
				return val
			})
		}
	}, [ReDrillData])

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_trialpit = TrialpitData;
				return val
			})
		}
	}, [TrialpitData])

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_detail_description = DetailDescriptionData;
				return val
			})
		}
	}, [DetailDescriptionData])
































	//...................................................................................................
	//.HHHH...HHHH....OOOOOOO.....LLLL.......EEEEEEEEEEE..... III.INNN...NNNN..NFFFFFFFFF...OOOOOOO.....
	//.HHHH...HHHH...OOOOOOOOOO...LLLL.......EEEEEEEEEEE..... III.INNNN..NNNN..NFFFFFFFFF..OOOOOOOOOO...
	//.HHHH...HHHH..OOOOOOOOOOOO..LLLL.......EEEEEEEEEEE..... III.INNNN..NNNN..NFFFFFFFFF.OOOOOOOOOOOO..
	//.HHHH...HHHH..OOOOO..OOOOO..LLLL.......EEEE............ III.INNNNN.NNNN..NFFF.......OOOOO..OOOOO..
	//.HHHH...HHHH.HOOOO....OOOOO.LLLL.......EEEE............ III.INNNNN.NNNN..NFFF......FOOOO....OOOO..
	//.HHHHHHHHHHH.HOOO......OOOO.LLLL.......EEEEEEEEEE...... III.INNNNNNNNNN..NFFFFFFFF.FOOO......OOO..
	//.HHHHHHHHHHH.HOOO......OOOO.LLLL.......EEEEEEEEEE...... III.INNNNNNNNNN..NFFFFFFFF.FOOO......OOO..
	//.HHHHHHHHHHH.HOOO......OOOO.LLLL.......EEEEEEEEEE...... III.INNNNNNNNNN..NFFFFFFFF.FOOO......OOO..
	//.HHHH...HHHH.HOOOO....OOOOO.LLLL.......EEEE............ III.INNNNNNNNNN..NFFF......FOOOO....OOOO..
	//.HHHH...HHHH..OOOOO..OOOOO..LLLL.......EEEE............ III.INNN.NNNNNN..NFFF.......OOOOO..OOOOO..
	//.HHHH...HHHH..OOOOOOOOOOOO..LLLLLLLLLL.EEEEEEEEEEE..... III.INNN..NNNNN..NFFF.......OOOOOOOOOOOO..
	//.HHHH...HHHH...OOOOOOOOOO...LLLLLLLLLL.EEEEEEEEEEE..... III.INNN..NNNNN..NFFF........OOOOOOOOOO...
	//.HHHH...HHHH.....OOOOOO.....LLLLLLLLLL.EEEEEEEEEEE..... III.INNN...NNNN..NFFF..........OOOOOO.....
	//...................................................................................................

	//number of elements
	const [numberOfSamplesD, setNumberOfSamplesD] = useState(0)
	const [numberOfSamplesB, setNumberOfSamplesB] = useState(0)
	const [numberOfSamplesES, setNumberOfSamplesES] = useState(0)
	const [numberOfSamplesWS, setNumberOfSamplesWS] = useState(0)
	const [numberOfSamples, setNumberOfSamples] = useState(numberOfSamplesD + numberOfSamplesB + numberOfSamplesES + numberOfSamplesWS)

	const [numberOfSamplesD_TOTAL, setNumberOfSamplesD_TOTAL] = useState(0)
	const [numberOfSamplesB_TOTAL, setNumberOfSamplesB_TOTAL] = useState(0)
	const [numberOfSamplesES_TOTAL, setNumberOfSamplesES_TOTAL] = useState(0)
	const [numberOfSamplesWS_TOTAL, setNumberOfSamplesWS_TOTAL] = useState(0)
	const [numberOfSamples_TOTAL, setNumberOfSamples_TOTAL] = useState(numberOfSamplesD_TOTAL + numberOfSamplesB_TOTAL + numberOfSamplesES_TOTAL + numberOfSamplesWS_TOTAL)
	console.log('numberOfSamples_TOTAL:', numberOfSamples_TOTAL)

	const [numberOfSPTs, setNumberOfSPTs] = useState(0)
	const [numberOfCPTs, setNumberOfCPTs] = useState(0)
	const [numberOfSPTsCPTs, setNumberOfSPTsCPTs] = useState(numberOfSPTs + numberOfCPTs)
	const [numberOfUSamples, setNumberOfUSamples] = useState(0)
	const [numberOfPSSamples, setNumberOfPSSamples] = useState(0)
	const [numberOfShearVane, setNumberOfShearVane] = useState(0)
	const [numberOfInsituTests, setNumberOfInsituTests] = useState(numberOfSPTsCPTs + numberOfUSamples + numberOfPSSamples + numberOfShearVane)

	const [numberOfSPTs_TOTAL, setNumberOfSPTs_TOTAL] = useState(0)
	const [numberOfCPTs_TOTAL, setNumberOfCPTs_TOTAL] = useState(0)
	const [numberOfSPTsCPTs_TOTAL, setNumberOfSPTsCPTs_TOTAL] = useState(numberOfSPTs_TOTAL + numberOfCPTs_TOTAL)
	const [numberOfUSamples_TOTAL, setNumberOfUSamples_TOTAL] = useState(0)
	const [numberOfPSSamples_TOTAL, setNumberOfPSSamples_TOTAL] = useState(0)
	const [numberOfShearVane_TOTAL, setNumberOfShearVane_TOTAL] = useState(0)

	const [numberOfSoilDescriptions, setNumberOfSoilDescriptions] = useState(0)
	const [numberOfComments, setNumberOfComments] = useState(0)
	const [numberOfGroundWaters, setNumberOfGroundWaters] = useState(0)
	const [numberOfBackfills, setNumberOfBackfills] = useState(0)
	const [numberOfInstalls, setNumberOfInstalls] = useState(0)
	const [numberOfInspectionPits, setNumberOfInspectionPits] = useState(0)
	const [numberOfWaterAdded, setNumberOfWaterAdded] = useState(0)
	const [numberOfReDrills, setNumberOfReDrills] = useState(0)
	const [numberOfChiselling, setNumberOfChiselling] = useState(0)
	const [numberOfCasingRecords, setNumberOfCasingRecords] = useState(0)
	const [numberOfDailyWaterLevels, setNumberOfDailyWaterLevelss] = useState(0)
	const [numberOfTrialpits, setNumberOfTrialpits] = useState(0)
	const [numberOfDetailDescriptions, setNumberOfDetailDescriptions] = useState(0)

	//depths of elements
	const [depthSamples, setDepthSamples] = useState(0)
	const [depthInsituTests, setDepthInsituTests] = useState(0)
	const [depthSoilDescriptions, setDepthSoilDescriptions] = useState(0)
	const [depthInspectionPit, setDepthInspectionPit] = useState(0)
	const [depthCasing, setDepthCasing] = useState(0)
	const [depthGroundwater, setDepthGroundwater] = useState(0)
	const [depthChiselling, setDepthChiselling] = useState(0)
	const [depthWaterAdded, setDepthWaterAdded] = useState(0)
	const [depthRedrill, setDepthRedrill] = useState(0)
	const [depthTrialpit, setDepthTrialpit] = useState(0)
	const [depthDetailDescription, setDepthDetailDescription] = useState(0)
	const [allDepths, setAllDepths] = useState([depthSamples, depthInsituTests, depthSoilDescriptions, depthTrialpit, depthDetailDescription])
	const [borehole_depth, setBorehole_depth] = useState('' + (Math.max(...allDepths)).toFixed(2))

	const [current_casing_depth, setCurrent_casing_depth] = useState(depthCasing)
	const [last_soil_description_depth, setLast_soil_description_depth] = useState(depthSoilDescriptions)

	const [last_soil_description_depth_from, setLast_soil_description_depth_from] = useState(0)
	const [last_soil_description_depth_to, setLast_soil_description_depth_to] = useState(0)
	const [last_soil_description_depth_text, setLast_soil_description_depth_text] = useState('text')


	//..............................................................................
	//....CCCCCCC......OOOOOOO.....UUUU...UUUU..NNNN...NNNN..TTTTTTTTTTS.SSSSSSS....
	//...CCCCCCCCC....OOOOOOOOOO...UUUU...UUUU..NNNNN..NNNN..TTTTTTTTTTSSSSSSSSSS...
	//..CCCCCCCCCCC..OOOOOOOOOOOO..UUUU...UUUU..NNNNN..NNNN..TTTTTTTTTTSSSSSSSSSSS..
	//..CCCC...CCCCC.OOOOO..OOOOO..UUUU...UUUU..NNNNNN.NNNN.....TTTT...SSSSS..SSSS..
	//.CCCC.....CCC.OOOOO....OOOOO.UUUU...UUUU..NNNNNN.NNNN.....TTTT...SSSSS........
	//.CCCC.........OOOO......OOOO.UUUU...UUUU..NNNNNNNNNNN.....TTTT....SSSSSSS.....
	//.CCCC.........OOOO......OOOO.UUUU...UUUU..NNNNNNNNNNN.....TTTT.....SSSSSSSSS..
	//.CCCC.........OOOO......OOOO.UUUU...UUUU..NNNNNNNNNNN.....TTTT.......SSSSSSS..
	//.CCCC.....CCC.OOOOO....OOOOO.UUUU...UUUU..NNNNNNNNNNN.....TTTT..........SSSS..
	//..CCCC...CCCCC.OOOOO..OOOOO..UUUU...UUUU..NNNN.NNNNNN.....TTTT...SSSS....SSS..
	//..CCCCCCCCCCC..OOOOOOOOOOOO..UUUUUUUUUUU..NNNN..NNNNN.....TTTT...SSSSSSSSSSS..
	//...CCCCCCCCCC...OOOOOOOOOO....UUUUUUUUU...NNNN..NNNNN.....TTTT....SSSSSSSSSS..
	//....CCCCCCC.......OOOOOO.......UUUUUUU....NNNN...NNNN.....TTTT.....SSSSSSSS...
	//..............................................................................

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the type counts for the filtered data - Samples
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateTypeCounts_Samples = (filteredData: any[]) => {

		let totalD = 0; let totalB = 0; let totalES = 0; let totalWS = 0

		filteredDataSamples.forEach((valueSamples: any) => {
			const type = valueSamples.type
			if (type) {
				if (type.includes('D')) { totalD++ }
				if (type.includes('B')) { totalB++ }
				if (type.includes('ES')) { totalES++ }
				if (type.includes('WS')) { totalWS++ }
			}
		});

		return { totalD, totalB, totalES, totalWS }
	}

	// Filter the data
	const filteredDataSamples = SamplesData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	// Calculate type counts for the filtered data
	const typeCountsSamples = calculateTypeCounts_Samples(filteredDataSamples);
	const { totalD, totalB, totalES, totalWS } = typeCountsSamples;

	if (totalD !== numberOfSamplesD) { setNumberOfSamplesD(totalD) }
	if (totalB !== numberOfSamplesB) { setNumberOfSamplesB(totalB) }
	if (totalWS !== numberOfSamplesWS) { setNumberOfSamplesWS(totalWS) }
	if (totalES !== numberOfSamplesES) { setNumberOfSamplesES(totalES) }

	let totalSamplesCount = numberOfSamplesD + numberOfSamplesB + numberOfSamplesES + numberOfSamplesWS
	if (totalSamplesCount !== numberOfSamples) { setNumberOfSamples(totalSamplesCount) }

	// console.log('numberOfSamplesD:', numberOfSamplesD)
	// console.log('numberOfSamplesB:', numberOfSamplesB)

	const calculateTypeCounts_Samples_TOTAL = (filteredData: any[]) => {

		let totalD_TOTAL = 0; let totalB_TOTAL = 0; let totalES_TOTAL = 0; let totalWS_TOTAL = 0

		filteredDataSamples_TOTAL.forEach((valueSamples: any) => {
			const type = valueSamples.type
			if (type) {
				if (type.includes('D')) { totalD_TOTAL++ }
				if (type.includes('B')) { totalB_TOTAL++ }
				if (type.includes('ES')) { totalES_TOTAL++ }
				if (type.includes('WS')) { totalWS_TOTAL++ }
			}
		});

		return { totalD_TOTAL, totalB_TOTAL, totalES_TOTAL, totalWS_TOTAL }
	}

	// TOTAL SAMPLES ////////////////////////////////////////////////////////////////////////////////

	// Filter the data
	const filteredDataSamples_TOTAL = SamplesData || []

	// Calculate type counts for the filtered data
	const typeCountsSamples_TOTAL = calculateTypeCounts_Samples_TOTAL(filteredDataSamples_TOTAL);
	const { totalD_TOTAL, totalB_TOTAL, totalES_TOTAL, totalWS_TOTAL } = typeCountsSamples_TOTAL;

	if (totalD_TOTAL !== numberOfSamplesD_TOTAL) { setNumberOfSamplesD_TOTAL(totalD_TOTAL) }
	if (totalB_TOTAL !== numberOfSamplesB_TOTAL) { setNumberOfSamplesB_TOTAL(totalB_TOTAL) }
	if (totalWS_TOTAL !== numberOfSamplesWS_TOTAL) { setNumberOfSamplesWS_TOTAL(totalWS_TOTAL) }
	if (totalES_TOTAL !== numberOfSamplesES_TOTAL) { setNumberOfSamplesES_TOTAL(totalES_TOTAL) }
	let totalSamplesALL = totalD_TOTAL + totalB_TOTAL + totalWS_TOTAL + totalES_TOTAL
	if (totalSamplesALL !== numberOfSamples_TOTAL) { setNumberOfSamples_TOTAL(totalD_TOTAL + totalB_TOTAL + totalWS_TOTAL + totalES_TOTAL) }

	// console.log('totalWS_TOTAL:', totalWS_TOTAL)

	const [showPrintAllSampleLabelsButton, setShowPrintAllSampleLabelsButton] = useState(true)

	useEffect(() => {
		if (numberOfSamples > 1) {
			setShowPrintAllSampleLabelsButton(true)
		} else {
			setShowPrintAllSampleLabelsButton(false)
		}

	}, [numberOfSamples]
	)

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the type counts for the filtered data - InsituTests
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateTypeCounts_InsituTests = (filteredData: any[]) => {

		let totalSPT = 0; let totalCPT = 0; let totalU = 0; let totalPS = 0; let totalSV = 0

		filteredDataInsituTests.forEach((valueInsituTests: any) => {
			const type = valueInsituTests.type
			if (type) {
				if (type.includes('SPT')) { totalSPT++ }
				if (type.includes('CPT')) { totalCPT++ }
				if (type.includes('U')) { totalU++ }
				if (type.includes('PS')) { totalPS++ }
				if (type.includes('SV')) { totalSV++ }
			}
		});

		return { totalSPT, totalCPT, totalU, totalPS, totalSV }
	}

	// Filter the data
	const filteredDataInsituTests = InSituTestsData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	// Calculate type counts for the filtered data
	const typeCountsInsituTests = calculateTypeCounts_InsituTests(filteredDataInsituTests);
	const { totalSPT, totalCPT, totalU, totalPS, totalSV } = typeCountsInsituTests;

	if (totalSPT !== numberOfSPTs) { setNumberOfSPTs(totalSPT) }
	if (totalCPT !== numberOfCPTs) { setNumberOfCPTs(totalCPT) }
	if (totalU !== numberOfUSamples) { setNumberOfUSamples(totalU) }
	if (totalPS !== numberOfPSSamples) { setNumberOfPSSamples(totalPS) }
	if (totalSV !== numberOfShearVane) { setNumberOfShearVane(totalSV) }

	let TotalIntitusTestsCount = numberOfSPTs + numberOfCPTs + numberOfUSamples + numberOfPSSamples + numberOfShearVane
	if (TotalIntitusTestsCount !== numberOfInsituTests) { setNumberOfInsituTests(TotalIntitusTestsCount) }

	let TotalIntitusTestsCountSPT_CPT = numberOfSPTs + numberOfCPTs
	if (TotalIntitusTestsCountSPT_CPT !== numberOfSPTsCPTs) { setNumberOfSPTsCPTs(TotalIntitusTestsCountSPT_CPT) }

	// console.log('numberOfSPTs:', numberOfSPTs)
	// console.log('numberOfCPTs:', numberOfCPTs)
	// console.log('numberOfUSamples:', numberOfUSamples)
	// console.log('numberOfPSSamples:', numberOfPSSamples)
	// console.log('numberOfShearVane:', numberOfShearVane)

	// TOTAL INSITU TESTS ////////////////////////////////////////////////////////////////////////////////

	const calculateTypeCounts_InsituTests_TOTAL = (filteredData: any[]) => {

		let totalSPT_TOTAL = 0; let totalCPT_TOTAL = 0; let totalU_TOTAL = 0; let totalPS_TOTAL = 0; let totalSV_TOTAL = 0

		filteredDataInsituTests_TOTAL.forEach((valueInsituTests: any) => {
			const type = valueInsituTests.type
			if (type) {
				if (type.includes('SPT')) { totalSPT_TOTAL++ }
				if (type.includes('CPT')) { totalCPT_TOTAL++ }
				if (type.includes('U')) { totalU_TOTAL++ }
				if (type.includes('PS')) { totalPS_TOTAL++ }
				if (type.includes('SV')) { totalSV_TOTAL++ }
			}
		});

		return { totalSPT_TOTAL, totalCPT_TOTAL, totalU_TOTAL, totalPS_TOTAL, totalSV_TOTAL }
	}

	// Filter the data
	const filteredDataInsituTests_TOTAL = InSituTestsData || []

	// Calculate type counts for the filtered data
	const typeCountsInsituTests_TOTAL = calculateTypeCounts_InsituTests_TOTAL(filteredDataInsituTests_TOTAL);
	const { totalSPT_TOTAL, totalCPT_TOTAL, totalU_TOTAL, totalPS_TOTAL, totalSV_TOTAL } = typeCountsInsituTests_TOTAL;

	if (totalSPT_TOTAL !== numberOfSPTs_TOTAL) { setNumberOfSPTs_TOTAL(totalSPT_TOTAL) }
	if (totalCPT_TOTAL !== numberOfCPTs_TOTAL) { setNumberOfCPTs_TOTAL(totalCPT_TOTAL) }
	if (totalU_TOTAL !== numberOfUSamples_TOTAL) { setNumberOfUSamples_TOTAL(totalU_TOTAL) }
	if (totalPS_TOTAL !== numberOfPSSamples_TOTAL) { setNumberOfPSSamples_TOTAL(totalPS_TOTAL) }
	if (totalSV_TOTAL !== numberOfShearVane_TOTAL) { setNumberOfShearVane_TOTAL(totalSV_TOTAL) }
	let totalSPTsCPTsALL = totalSPT_TOTAL + totalCPT_TOTAL
	if (totalSPTsCPTsALL !== numberOfSPTsCPTs_TOTAL) { setNumberOfSPTsCPTs_TOTAL(totalSPTsCPTsALL) }

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - InsituTests
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	// const calculateCounts_InsituTests = (filteredData: any[]) => {

	// 	let totalInsituTests = 0

	// 	filteredDataInsituTests.forEach((valueInsituTests: any) => {
	// 		const guid = valueInsituTests.guid
	// 		if (guid) { totalInsituTests++ }
	// 	});

	// 	return { totalInsituTests }
	// }

	// // Filter the data
	// const filteredDataInsituTests = InSituTestsData?.filter((action: any) => {
	// 	return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	// }) || []

	// // Calculate type counts for the filtered data
	// const countInsituTests = calculateCounts_InsituTests(filteredDataInsituTests);
	// const { totalInsituTests } = countInsituTests

	// if (totalInsituTests !== numberOfInsituTests) { setNumberOfInsituTests(totalInsituTests) }


	const [showPrintAllInsituTestLabelsButton, setShowPrintAllInsituTestsLabelButton] = useState(true)




	// if (valueInsituTests.length > 1) {
	// 	setShowPrintAllInsituTestsLabelButton(true)
	// } else {
	// 	setShowPrintAllInsituTestsLabelButton(false)
	// }

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - Soil Descriptions
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateCounts_SoilDescriptions = (filteredData: any[]) => {

		let totalSoilDescriptions = 0

		filteredDataSoilDescription.forEach((valueSoilDescriptions: any) => {
			const stratum_description = valueSoilDescriptions.stratum_description
			if (stratum_description) { totalSoilDescriptions++ }
		});

		return { totalSoilDescriptions }
	}

	// Filter the data
	const filteredDataSoilDescription = SoilDescriptionData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	// Calculate type counts for the filtered data
	const countSoilDescriptions = calculateCounts_SoilDescriptions(filteredDataSoilDescription);
	const { totalSoilDescriptions } = countSoilDescriptions

	if (totalSoilDescriptions !== numberOfSoilDescriptions) { setNumberOfSoilDescriptions(totalSoilDescriptions) }


	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - Groundwater
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateCounts_Groundwater = (filteredData: any[]) => {

		let totalGroundwater = 0

		filteredDataGroundwater.forEach((valueGroundwater: any) => {
			const guid = valueGroundwater.guid
			if (guid) { totalGroundwater++ }
		});

		return { totalGroundwater }
	}

	// Filter the data
	const filteredDataGroundwater = GroundWaterTestsData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	// Calculate type counts for the filtered data
	const countGroundwater = calculateCounts_Groundwater(filteredDataGroundwater);
	const { totalGroundwater } = countGroundwater

	if (totalGroundwater !== numberOfGroundWaters) { setNumberOfGroundWaters(totalGroundwater) }

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - Backfills
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateCounts_Backfill = (filteredData: any[]) => {

		let totalBackfill = 0

		filteredDataBackfill.forEach((valueBackfill: any) => {
			const guid = valueBackfill.guid
			if (guid) { totalBackfill++ }
		});

		return { totalBackfill }
	}

	// Filter the data
	const filteredDataBackfill = BackfillData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	// Calculate type counts for the filtered data
	const countBackfill = calculateCounts_Backfill(filteredDataBackfill);
	const { totalBackfill } = countBackfill

	if (totalBackfill !== numberOfBackfills) { setNumberOfBackfills(totalBackfill) }

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - Installs
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateCounts_Install = (filteredData: any[]) => {

		let totalInstall = 0

		filteredDataInstall.forEach((valueInstall: any) => {
			const guid = valueInstall.guid
			if (guid) { totalInstall++ }
		});

		return { totalInstall }
	}

	// Filter the data
	const filteredDataInstall = InstallationData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	// Calculate type counts for the filtered data
	const countInstall = calculateCounts_Install(filteredDataInstall);
	const { totalInstall } = countInstall

	if (totalInstall !== numberOfInstalls) { setNumberOfInstalls(totalInstall) }

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - Comments
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateCounts_Comments = (filteredData: any[]) => {

		let totalComments = 0

		filteredDataComments.forEach((valueComments: any) => {
			const guid = valueComments.guid
			if (guid) { totalComments++ }
		});

		return { totalComments }
	}

	// Filter the data
	const filteredDataComments = CommentsData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	// Calculate type counts for the filtered data
	const countComments = calculateCounts_Comments(filteredDataComments);
	const { totalComments } = countComments

	if (totalComments !== numberOfComments) { setNumberOfComments(totalComments) }

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - InspectionPit
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateCounts_InspectionPit = (filteredData: any[]) => {

		let totalInspectionPit = 0

		filteredDataInspectionPit.forEach((valueInspectionPit: any) => {
			const guid = valueInspectionPit.guid
			if (guid) { totalInspectionPit++ }
		});

		return { totalInspectionPit }
	}

	// Filter the data
	const filteredDataInspectionPit = InspectionPitData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	// Calculate type counts for the filtered data
	const countInspectionPit = calculateCounts_InspectionPit(filteredDataInspectionPit);
	const { totalInspectionPit } = countInspectionPit

	if (totalInspectionPit !== numberOfInspectionPits) { setNumberOfInspectionPits(totalInspectionPit) }

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - CasingRecord
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateCounts_CasingRecord = (filteredData: any[]) => {

		let totalCasingRecord = 0

		filteredDataCasingRecord.forEach((valueCasingRecord: any) => {
			const guid = valueCasingRecord.guid
			if (guid) { totalCasingRecord++ }
		});

		return { totalCasingRecord }
	}

	// Filter the data
	const filteredDataCasingRecord = CasingData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []
	// const filteredDataCasingRecord = CasingData // All casing records

	// Calculate type counts for the filtered data
	const countCasingRecord = calculateCounts_CasingRecord(filteredDataCasingRecord);
	const { totalCasingRecord } = countCasingRecord

	if (totalCasingRecord !== numberOfCasingRecords) { setNumberOfCasingRecords(totalCasingRecord) }

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - Chiselling
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateCounts_Chiselling = (filteredData: any[]) => {

		let totalChiselling = 0

		filteredDataChiselling.forEach((valueChiselling: any) => {
			const guid = valueChiselling.guid
			if (guid) { totalChiselling++ }
		});

		return { totalChiselling }
	}

	// Filter the data
	const filteredDataChiselling = ChisellingData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	// Calculate type counts for the filtered data
	const countChiselling = calculateCounts_Chiselling(filteredDataChiselling);
	const { totalChiselling } = countChiselling

	if (totalChiselling !== numberOfChiselling) { setNumberOfChiselling(totalChiselling) }

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - WaterAdded
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateCounts_WaterAdded = (filteredData: any[]) => {

		let totalWaterAdded = 0

		filteredDataWaterAdded.forEach((valueWaterAdded: any) => {
			const guid = valueWaterAdded.guid
			if (guid) { totalWaterAdded++ }
		});

		return { totalWaterAdded }
	}

	// Filter the data
	const filteredDataWaterAdded = WaterAddedData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	// Calculate type counts for the filtered data
	const countWaterAdded = calculateCounts_WaterAdded(filteredDataWaterAdded);
	const { totalWaterAdded } = countWaterAdded

	if (totalWaterAdded !== numberOfWaterAdded) { setNumberOfWaterAdded(totalWaterAdded) }

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - ReDrill
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateCounts_ReDrill = (filteredData: any[]) => {

		let totalReDrill = 0

		filteredDataReDrill.forEach((valueReDrill: any) => {
			const guid = valueReDrill.guid
			if (guid) { totalReDrill++ }
		});

		return { totalReDrill }
	}

	// Filter the data
	const filteredDataReDrill = ReDrillData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	// Calculate type counts for the filtered data
	const countReDrill = calculateCounts_ReDrill(filteredDataReDrill);
	const { totalReDrill } = countReDrill

	if (totalReDrill !== numberOfReDrills) { setNumberOfReDrills(totalReDrill) }

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - Trialpit
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateCounts_Trialpit = (filteredData: any[]) => {

		let totalTrialpit = 0

		filteredDataTrialpit.forEach((valueTrialpit: any) => {
			const guid = valueTrialpit.guid
			if (guid) { totalTrialpit++ }
		});

		return { totalTrialpit }
	}

	// Filter the data
	const filteredDataTrialpit = TrialpitData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	// Calculate type counts for the filtered data
	const countTrialpit = calculateCounts_Trialpit(filteredDataTrialpit);
	const { totalTrialpit } = countTrialpit

	if (totalTrialpit !== numberOfTrialpits) { setNumberOfTrialpits(totalTrialpit) }

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - DetailDescription
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateCounts_DetailDescription = (filteredData: any[]) => {

		let totalDetailDescription = 0

		filteredDataDetailDescription.forEach((valueDetailDescription: any) => {
			const guid = valueDetailDescription.guid
			if (guid) { totalDetailDescription++ }
		});

		return { totalDetailDescription }
	}

	// Filter the data
	const filteredDataDetailDescription = DetailDescriptionData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	// Calculate type counts for the filtered data
	const countDetailDescription = calculateCounts_DetailDescription(filteredDataDetailDescription);
	const { totalDetailDescription } = countDetailDescription

	if (totalDetailDescription !== numberOfDetailDescriptions) { setNumberOfDetailDescriptions(totalDetailDescription) }

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - WaterLevels
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateCounts_DailyWaterLevels = (filteredData: any[]) => {

		let totalDailyWaterLevels = 0

		filteredDataDailyWaterLevels.forEach((valueDailyWaterLevels: any) => {
			const guid = valueDailyWaterLevels.guid
			if (guid) { totalDailyWaterLevels++ }
		});

		return { totalDailyWaterLevels }
	}

	// Filter the data
	const filteredDataDailyWaterLevels = DailyWaterLevelsData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	// Calculate type counts for the filtered data
	const countDailyWaterLevelss = calculateCounts_DailyWaterLevels(filteredDataDailyWaterLevels);
	const { totalDailyWaterLevels } = countDailyWaterLevelss

	if (totalDailyWaterLevels !== numberOfDailyWaterLevels) { setNumberOfDailyWaterLevelss(totalDailyWaterLevels) }






	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - LINERS
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const [showPrintAllLinerLabelsButton, setShowPrintAllLinerLabelsButton] = useState(false)












	//...........................................................................
	//.DDDDDDDDD....EEEEEEEEEEE.EPPPPPPPP...PTTTTTTTTTTTHHH...HHHH...SSSSSSS.....
	//.DDDDDDDDDD...EEEEEEEEEEE.EPPPPPPPPP..PTTTTTTTTTTTHHH...HHHH..HSSSSSSSS....
	//.DDDDDDDDDDD..EEEEEEEEEEE.EPPPPPPPPPP.PTTTTTTTTTTTHHH...HHHH..HSSSSSSSSS...
	//.DDDD...DDDD..EEEE........EPPP...PPPP....TTTT....THHH...HHHH.HHSSS..SSSS...
	//.DDDD....DDDD.EEEE........EPPP...PPPP....TTTT....THHH...HHHH.HHSSS.........
	//.DDDD....DDDD.EEEEEEEEEE..EPPPPPPPPPP....TTTT....THHHHHHHHHH..HSSSSSS......
	//.DDDD....DDDD.EEEEEEEEEE..EPPPPPPPPP.....TTTT....THHHHHHHHHH...SSSSSSSSS...
	//.DDDD....DDDD.EEEEEEEEEE..EPPPPPPPP......TTTT....THHHHHHHHHH.....SSSSSSS...
	//.DDDD....DDDD.EEEE........EPPP...........TTTT....THHH...HHHH........SSSSS..
	//.DDDD...DDDDD.EEEE........EPPP...........TTTT....THHH...HHHH.HHSS....SSSS..
	//.DDDDDDDDDDD..EEEEEEEEEEE.EPPP...........TTTT....THHH...HHHH.HHSSSSSSSSSS..
	//.DDDDDDDDDD...EEEEEEEEEEE.EPPP...........TTTT....THHH...HHHH..HSSSSSSSSS...
	//.DDDDDDDDD....EEEEEEEEEEE.EPPP...........TTTT....THHH...HHHH...SSSSSSSS....
	//...........................................................................


	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// CALCULATE MAX DEPTHS
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	/////////////////////////////////////////////////////////
	// SamplesData
	const filteredSamplesData = SamplesData?.filter((action: any) => {
		const actionDate = new Date(action.time_of_action);
		const endOfDaySelectedDate = new Date(selectedDate);
		endOfDaySelectedDate.setHours(23, 59, 59, 999); // Set to end of the day
		return actionDate <= endOfDaySelectedDate;
	}) || []

	let maxSamplesDepth
	if (filteredSamplesData.length > 0) {

		const maxDepthBase = filteredSamplesData.reduce((maxDepth, action) => {
			return Math.max(maxDepth, action.depth_to_base)
		}, -Infinity);

		const maxDepthTop = filteredSamplesData.reduce((maxDepth, action) => {
			return Math.max(maxDepth, action.depth_to_top)
		}, -Infinity)

		maxSamplesDepth = Math.max(maxDepthBase, maxDepthTop)

	} else {
		maxSamplesDepth = 0 // Set to 0 when there's no data
	}

	if (maxSamplesDepth !== depthSamples) {
		setDepthSamples(maxSamplesDepth)
	}

	/////////////////////////////////////////////////////////
	// InSituTestsData
	// const filteredInSituTestsData = InSituTestsData?.filter((action: any) => {
	// const actionDate = new Date(action.time_of_action);
	// const endOfDaySelectedDate = new Date(selectedDate);
	// endOfDaySelectedDate.setHours(23, 59, 59, 999); // Set to end of the day
	// return actionDate <= endOfDaySelectedDate;
	// }) || []

	// let maxInSituTestsDepth
	// if (filteredInSituTestsData.length > 0) {
	// 	const maxInSituTestsDepthFiltered = filteredInSituTestsData.reduce((maxDepth, action) => {
	// 		return Math.max(maxDepth, action.depth_to_base)
	// 	}, -Infinity)
	// 	maxInSituTestsDepth = maxInSituTestsDepthFiltered
	// } else {
	// 	maxInSituTestsDepth = 0 // Set to 0 when there's no data
	// }

	// console.log('Max InSituTests Depth:', maxInSituTestsDepth)
	// if (maxInSituTestsDepth !== depthInsituTests) { 
	// 	setDepthInsituTests(maxInSituTestsDepth)
	// }

	/////////////////////////////////////////////////////////
	// SoilDescriptionData
	const filteredSoilDescriptionData = SoilDescriptionData?.filter((action: any) => {
		const actionDate = new Date(action.time_of_action);
		const endOfDaySelectedDate = new Date(selectedDate);
		endOfDaySelectedDate.setHours(23, 59, 59, 999); // Set to end of the day
		return actionDate <= endOfDaySelectedDate;
	}) || []

	let maxSoilDescriptionDepth
	if (filteredSoilDescriptionData.length > 0) {
		const maxSoilDescriptionDepthFiltered = filteredSoilDescriptionData.reduce((maxDepth, action) => {
			return Math.max(maxDepth, action.depth_to_base)
		}, -Infinity)
		maxSoilDescriptionDepth = maxSoilDescriptionDepthFiltered
	} else {
		maxSoilDescriptionDepth = 0 // Set to 0 when there's no data
	}

	if (maxSoilDescriptionDepth !== depthSoilDescriptions) {
		setDepthSoilDescriptions(maxSoilDescriptionDepth)
	}
	//// SET LAST SOIL DESCRIPTION DEPTH
	if (maxSoilDescriptionDepth !== last_soil_description_depth) {
		setLast_soil_description_depth(maxSoilDescriptionDepth)
	}

	/////////////////////////////////////////////////////////
	// CasingData
	const filteredCasingData = CasingData?.filter((action: any) => {
		const actionDate = new Date(action.time_of_action);
		const endOfDaySelectedDate = new Date(selectedDate);
		endOfDaySelectedDate.setHours(23, 59, 59, 999); // Set to end of the day
		return actionDate <= endOfDaySelectedDate;
	}) || []

	let maxCasingDepth
	if (filteredCasingData.length > 0) {
		const maxCasingDepthFiltered = filteredCasingData.reduce((maxDepth, action) => {
			return Math.max(maxDepth, action.depth_to_base)
		}, -Infinity)
		maxCasingDepth = maxCasingDepthFiltered
	} else {
		maxCasingDepth = 0 // Set to 0 when there's no data
	}

	if (maxCasingDepth !== depthCasing) {
		setDepthCasing(maxCasingDepth)
	}
	if (maxCasingDepth !== current_casing_depth) { /// SET CURRENT CASING DEPTH FOR PANELS
		setCurrent_casing_depth(maxCasingDepth)
	}

	// console.log('Casing:', depthCasing, current_casing_depth, maxCasingDepth)

	/////////////////////////////////////////////////////////
	// InspectionPitData
	const filteredInspectionPitData = InspectionPitData?.filter((action: any) => {
		const actionDate = new Date(action.time_of_action);
		const endOfDaySelectedDate = new Date(selectedDate);
		endOfDaySelectedDate.setHours(23, 59, 59, 999); // Set to end of the day
		return actionDate <= endOfDaySelectedDate;
	}) || []

	let maxInspectionPitDepth
	if (filteredInspectionPitData.length > 0) {
		const maxInspectionPitDepthFiltered = filteredInspectionPitData.reduce((maxDepth, action) => {
			return Math.max(maxDepth, action.depth_to_base)
		}, -Infinity)
		maxInspectionPitDepth = maxInspectionPitDepthFiltered
	} else {
		maxInspectionPitDepth = 0 // Set to 0 when there's no data
	}

	if (maxInspectionPitDepth !== depthInspectionPit) {
		setDepthInspectionPit(maxInspectionPitDepth)
	}

	// console.log('InspectionPitData:', depthInspectionPit, filteredInspectionPitData, maxInspectionPitDepth)

	/////////////////////////////////////////////////////////
	// // GroundWaterTestsData
	// const filteredGroundWaterTestsData = GroundWaterTestsData?.filter((action: any) => {
	// 	const actionDate = new Date(action.time_of_action);
	// 	const endOfDaySelectedDate = new Date(selectedDate);
	// 	endOfDaySelectedDate.setHours(23, 59, 59, 999); // Set to end of the day
	// 	return actionDate <= endOfDaySelectedDate;
	// }) || []

	// let maxGroundWaterTestsDepth
	// if (filteredGroundWaterTestsData.length > 0) {
	// 	const maxGroundWaterTestsDepthFiltered = filteredGroundWaterTestsData.reduce((maxDepth, action) => {
	// 		return Math.max(maxDepth, action.depth_to_water)
	// 	}, -Infinity)
	// 	maxGroundWaterTestsDepth = maxGroundWaterTestsDepthFiltered
	// } else {
	// 	maxGroundWaterTestsDepth = 0 // Set to 0 when there's no data
	// }

	// console.log('Max GroundWater Depth:', maxGroundWaterTestsDepth)
	// if (maxGroundWaterTestsDepth !== depthGroundwater) {
	// 	setDepthSamples(maxGroundWaterTestsDepth)
	// }

	// /////////////////////////////////////////////////////////
	// // ChisellingData
	// const filteredChisellingData = ChisellingData?.filter((action: any) => {
	// 	const actionDate = new Date(action.time_of_action);
	// 	const endOfDaySelectedDate = new Date(selectedDate);
	// 	endOfDaySelectedDate.setHours(23, 59, 59, 999); // Set to end of the day
	// 	return actionDate <= endOfDaySelectedDate;
	// }) || []

	// let maxChisellingDepth
	// if (filteredChisellingData.length > 0) {
	// 	const maxChisellingDepthFiltered = filteredChisellingData.reduce((maxDepth, action) => {
	// 		return Math.max(maxDepth, action.depth_at_end)
	// 	}, -Infinity)
	// 	maxChisellingDepth = maxChisellingDepthFiltered
	// } else {
	// 	maxChisellingDepth = 0 // Set to 0 when there's no data
	// }

	// if (maxChisellingDepth !== depthChiselling) {
	// 	setDepthChiselling(maxChisellingDepth)
	// }

	/////////////////////////////////////////////////////////
	// ReDrillData
	const filteredReDrillData = ReDrillData?.filter((action: any) => {
		const actionDate = new Date(action.time_of_action);
		const endOfDaySelectedDate = new Date(selectedDate);
		endOfDaySelectedDate.setHours(23, 59, 59, 999); // Set to end of the day
		return actionDate <= endOfDaySelectedDate;
	}) || []

	let maxReDrillDepth
	if (filteredReDrillData.length > 0) {
		const maxReDrillDepthFiltered = filteredReDrillData.reduce((maxDepth, action) => {
			return Math.max(maxDepth, action.base_of_redrill)
		}, -Infinity)
		maxReDrillDepth = maxReDrillDepthFiltered
	} else {
		maxReDrillDepth = 0 // Set to 0 when there's no data
	}

	if (maxReDrillDepth !== depthRedrill) {
		setDepthRedrill(maxReDrillDepth)
	}

	/////////////////////////////////////////////////////////
	// TrialpitData
	const filteredTrialpitData = TrialpitData?.filter((action: any) => {
		const actionDate = new Date(action.time_of_action);
		const endOfDaySelectedDate = new Date(selectedDate);
		endOfDaySelectedDate.setHours(23, 59, 59, 999); // Set to end of the day
		return actionDate <= endOfDaySelectedDate;
	}) || []

	let maxTrialpitDepth
	if (filteredTrialpitData.length > 0) {
		const maxTrialpitDepthFiltered = filteredTrialpitData.reduce((maxDepth, action) => {
			return Math.max(maxDepth, action.pit_depth)
		}, -Infinity)
		maxTrialpitDepth = maxTrialpitDepthFiltered
	} else {
		maxTrialpitDepth = 0 // Set to 0 when there's no data
	}

	// if (maxTrialpitDepth !== depthTrialpit) {
	// 	setDepthTrialpit(maxTrialpitDepth)
	// }
	// if (maxTrialpitDepth !== current_casing_depth) { /// SET CURRENT CASING DEPTH FOR PANELS
	// 	setCurrent_casing_depth(maxTrialpitDepth)
	// }

	// console.log('Trialpit:', depthTrialpit, current_casing_depth, maxTrialpitDepth)

	/////////////////////////////////////////////////////////
	// DetailDescriptionData
	const filteredDetailDescriptionData = DetailDescriptionData?.filter((action: any) => {
		const actionDate = new Date(action.time_of_action);
		const endOfDaySelectedDate = new Date(selectedDate);
		endOfDaySelectedDate.setHours(23, 59, 59, 999); // Set to end of the day
		return actionDate <= endOfDaySelectedDate;
	}) || []

	let maxDetailDescriptionDepth
	if (filteredDetailDescriptionData.length > 0) {
		const maxDetailDescriptionDepthFiltered = filteredDetailDescriptionData.reduce((maxDepth, action) => {
			return Math.max(maxDepth, action.depth_to)
		}, -Infinity)
		maxDetailDescriptionDepth = maxDetailDescriptionDepthFiltered
	} else {
		maxDetailDescriptionDepth = 0 // Set to 0 when there's no data
	}

	// useEffect(() => {
	// if (maxDetailDescriptionDepth !== depthDetailDescription) {
	//     setDepthDetailDescription(maxDetailDescriptionDepth)
	// }
	// }, [])


	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// SET ALL DEPTHS
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const allCalculatedDepths = [depthSamples, depthSoilDescriptions, depthTrialpit, depthDetailDescription]
	// if (allCalculatedDepths !== allDepths) {setAllDepths(allCalculatedDepths)}
	const calculatedBoreholeDepth = ('' + (Math.max(...allCalculatedDepths)).toFixed(2))
	if (calculatedBoreholeDepth !== borehole_depth) { setBorehole_depth(calculatedBoreholeDepth) }






	//.......................................................
	//.PPPPPPPPP..PPIII.IPPPPPPPP...PEEEEEEEEEE..SSSSSSS.....
	//.PPPPPPPPPP.PPIII.IPPPPPPPPP..PEEEEEEEEEE.ESSSSSSSS....
	//.PPPPPPPPPPPPPIII.IPPPPPPPPPP.PEEEEEEEEEE.ESSSSSSSSS...
	//.PPPP...PPPPPPIII.IPPP...PPPP.PEEE.......EESSS..SSSS...
	//.PPPP...PPPPPPIII.IPPP...PPPP.PEEE.......EESSS.........
	//.PPPPPPPPPPPPPIII.IPPPPPPPPPP.PEEEEEEEEE..ESSSSSS......
	//.PPPPPPPPPP.PPIII.IPPPPPPPPP..PEEEEEEEEE...SSSSSSSSS...
	//.PPPPPPPPP..PPIII.IPPPPPPPP...PEEEEEEEEE.....SSSSSSS...
	//.PPPP.......PPIII.IPPP........PEEE..............SSSSS..
	//.PPPP.......PPIII.IPPP........PEEE.......EESS....SSSS..
	//.PPPP.......PPIII.IPPP........PEEEEEEEEEEEESSSSSSSSSS..
	//.PPPP.......PPIII.IPPP........PEEEEEEEEEE.ESSSSSSSSS...
	//.PPPP.......PPIII.IPPP........PEEEEEEEEEE..SSSSSSSS....
	//.......................................................

	const filteredInstallationData = InstallationData?.filter((action: any) => {
		const actionDate = new Date(action.time_of_action);
		const endOfDaySelectedDate = new Date(selectedDate);
		endOfDaySelectedDate.setHours(23, 59, 59, 999); // Set to end of the day
		return actionDate <= endOfDaySelectedDate;
	}) || []

	// console.log('filteredInstallationData', filteredInstallationData)

	const [pipe1solid, setPipe1solid] = useState('')
	const [pipe1slotted, setPipe1slotted] = useState('')
	const [pipe2solid, setPipe2solid] = useState('')
	const [pipe2slotted, setPipe2slotted] = useState('')
	if (filteredInstallationData.length > 0) {
		const installationPipeData = filteredInstallationData[0]; // Access the first element of the array
		if (installationPipeData.pipe_solid_1 !== pipe1solid) { setPipe1solid(installationPipeData.pipe_solid_1); }
		if (installationPipeData.pipe_slotted_1 !== pipe1slotted) { setPipe1slotted(installationPipeData.pipe_slotted_1); }
		if (installationPipeData.pipe_solid_2 !== pipe2solid) { setPipe2solid(installationPipeData.pipe_solid_2); }
		if (installationPipeData.pipe_slotted_2 !== pipe2slotted) { setPipe2slotted(installationPipeData.pipe_slotted_2); }
	}

	// console.log('PIPES:', pipe1solid, pipe1slotted, pipe2solid, pipe2slotted)















	//....................................................................................................
	//...SSSSSSS......OOOOOOO....OIIII.LLLL............DDDDDDDDD....EEEEEEEEEEE..SSSSSSS......CCCCCCC.....
	//..SSSSSSSSS....OOOOOOOOOO..OIIII.LLLL............DDDDDDDDDD...EEEEEEEEEEE.SSSSSSSSS....CCCCCCCCC....
	//..SSSSSSSSSS..OOOOOOOOOOOO.OIIII.LLLL............DDDDDDDDDDD..EEEEEEEEEEE.SSSSSSSSSS..CCCCCCCCCCC...
	//.SSSSS..SSSS..OOOOO..OOOOO.OIIII.LLLL............DDDD...DDDD..EEEE.......ESSSS..SSSS..CCCC...CCCCC..
	//.SSSSS.......SOOOO....OOOOOOIIII.LLLL............DDDD....DDDD.EEEE.......ESSSS.......SCCC.....CCC...
	//..SSSSSSS....SOOO......OOOOOIIII.LLLL............DDDD....DDDD.EEEEEEEEEE..SSSSSSS....SCCC...........
	//...SSSSSSSSS.SOOO......OOOOOIIII.LLLL............DDDD....DDDD.EEEEEEEEEE...SSSSSSSSS.SCCC...........
	//.....SSSSSSS.SOOO......OOOOOIIII.LLLL............DDDD....DDDD.EEEEEEEEEE.....SSSSSSS.SCCC...........
	//........SSSSSSOOOO....OOOOOOIIII.LLLL............DDDD....DDDD.EEEE..............SSSSSSCCC.....CCC...
	//.SSSS....SSSS.OOOOO..OOOOO.OIIII.LLLL............DDDD...DDDDD.EEEE.......ESSS....SSSS.CCCC...CCCCC..
	//.SSSSSSSSSSSS.OOOOOOOOOOOO.OIIII.LLLLLLLLLL......DDDDDDDDDDD..EEEEEEEEEEEESSSSSSSSSSS.CCCCCCCCCCC...
	//..SSSSSSSSSS...OOOOOOOOOO..OIIII.LLLLLLLLLL......DDDDDDDDDD...EEEEEEEEEEE.SSSSSSSSSS...CCCCCCCCCC...
	//...SSSSSSSS......OOOOOO....OIIII.LLLLLLLLLL......DDDDDDDDD....EEEEEEEEEEE..SSSSSSSS.....CCCCCCC.....
	//....................................................................................................



	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// GET LAST SOIL SESCRIPTION DETAILS
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const filteredSoilDescriptionDataDetail = SoilDescriptionData?.filter((action: any) => {
		const actionDate = new Date(action.time_of_action);
		const endOfDaySelectedDate = new Date(selectedDate);
		endOfDaySelectedDate.setHours(23, 59, 59, 999); // Set to end of the day
		return actionDate <= endOfDaySelectedDate;
	}) || [];

	const validSoilDescriptionRecords = filteredSoilDescriptionDataDetail
		.filter(action => action.depth_to_base !== 0); // Filter out records with depth_to_base = 0

	const lastValidSoilDescriptionRecord = validSoilDescriptionRecords
		.reduce((latestRecord, action) => {
			return action.depth_to_base > latestRecord.depth_to_base ? action : latestRecord;
		}, { depth_to_base: -Infinity, depth_to_top: 0, stratum_description: '' });

	if (lastValidSoilDescriptionRecord.depth_to_base !== -Infinity) {
		const lastSoilDescriptionTop = lastValidSoilDescriptionRecord.depth_to_top;
		const lastSoilDescriptionBase = lastValidSoilDescriptionRecord.depth_to_base;
		const lastSoilDescriptionStratum = lastValidSoilDescriptionRecord.stratum_description;

		if (lastSoilDescriptionTop !== last_soil_description_depth_from) {
			setLast_soil_description_depth_from(lastSoilDescriptionTop);
		}
		if (lastSoilDescriptionBase !== last_soil_description_depth_to) {
			setLast_soil_description_depth_to(lastSoilDescriptionBase);
		}
		if (lastSoilDescriptionStratum !== last_soil_description_depth_text) {
			setLast_soil_description_depth_text(lastSoilDescriptionStratum);
		}
	}







	//.................................................
	//.BBBBBBBBBB...KKKK...KKKKK.FFFFFFFFFF.LLLL.......
	//.BBBBBBBBBBB..KKKK..KKKKK..FFFFFFFFFF.LLLL.......
	//.BBBBBBBBBBB..KKKK.KKKKK...FFFFFFFFFF.LLLL.......
	//.BBBB...BBBB..KKKKKKKKK....FFFF.......LLLL.......
	//.BBBB...BBBB..KKKKKKKK.....FFFF.......LLLL.......
	//.BBBBBBBBBBB..KKKKKKKK.....FFFFFFFFF..LLLL.......
	//.BBBBBBBBBB...KKKKKKKK.....FFFFFFFFF..LLLL.......
	//.BBBBBBBBBBB..KKKKKKKKK....FFFFFFFFF..LLLL.......
	//.BBBB....BBBB.KKKK.KKKKK...FFFF.......LLLL.......
	//.BBBB....BBBB.KKKK..KKKK...FFFF.......LLLL.......
	//.BBBBBBBBBBBB.KKKK..KKKKK..FFFF.......LLLLLLLLL..
	//.BBBBBBBBBBB..KKKK...KKKKK.FFFF.......LLLLLLLLL..
	//.BBBBBBBBBB...KKKK...KKKKK.FFFF.......LLLLLLLLL..
	//.................................................


	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// GET Backfill & Install GUIDs
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



	const [backfillGuid, setBackfillGuid] = useState([])
	const [installGuid, setInstallGuid] = useState([])
	const [backfillText, setBackfillText] = useState('')

	const backfill_guid = filteredDataBackfill[0]?.guid || null;

	if (backfill_guid !== backfillGuid) { setBackfillGuid(backfill_guid) }

	const install_guid = filteredDataInstall[0]?.guid || null;

	if (install_guid !== installGuid) { setInstallGuid(install_guid) }

	useEffect(() => {
		const backfillTextOutput = BackfillData
			// .filter((entry: any) => entry.type === 'Backfill' && Array.isArray(entry.layers) && entry.layers.length > 0)
			.filter((entry: any) => Array.isArray(entry.layers) && entry.layers.length > 0)
			.map((valueBackfill: any) => {
				const layersText = valueBackfill.layers.map((layer: any) => `${layer.backfillType} (${layer.depth}m)`).join(', ');
				return `${layersText}`;
			})
			.join(', ');

		if (backfillTextOutput) {
			setBackfillText('Backfill (top to bottom): ' + backfillTextOutput);
		}
	}, []);

	// // Define a function to calculate and update backfillText // STANDARD ARRAY
	// const updateBackfillText = (backfillData: any) => {
	// 	// Check if backfillData has a layers property and it is an array
	// 	if (backfillData.layers && Array.isArray(backfillData.layers)) {
	// 	  const backfillTextOutput = backfillData.layers
	// 		.filter((entry: any) => entry.backfillType !== '' || entry.depth !== '0.00')
	// 		.map((layer: any) => `${layer.backfillType} (${layer.depth}m)`)
	// 		.join(', ');

	// 	  if (backfillTextOutput) {
	// 		setBackfillText('Backfill (top to bottom): ' + backfillTextOutput);
	// 	  }
	// 	} else {
	// 	  console.error('Invalid backfillData format:', backfillData);
	// 	}
	//   };

	// Define a function to calculate and update backfillText // MULTI DIMENSIONAL ARRAY
	const updateBackfillText = (backfillData: any) => {
		// Check if backfillData has a layers property and it is an array
		if (backfillData.layers && Array.isArray(backfillData.layers)) {
			const backfillTextOutput = backfillData.layers
				.flatMap((layerArray: any) => {
					// Check if layerArray is an array before processing
					if (Array.isArray(layerArray)) {
						return layerArray
							.filter((entry: any) => entry.backfillType !== '' || entry.depth !== '0.00')
							.map((layer: any) => `${layer.backfillType} (${layer.depth}m)`);
					} else {
						return [];
					}
				})
				.join(', ');

			if (backfillTextOutput) {
				setBackfillText('Backfill (top to bottom): ' + backfillTextOutput);
			}
		} else {
			console.error('Invalid backfillData format:', backfillData);
		}
	};


	console.log('backfillText:', backfillText)






	//...........................................................................................................
	//....CCCCCCC......OOOOOOO....MMMMMM...MMMMMMMMMMMM...MMMMMM.EEEEEEEEEEE.NNNN...NNNN..TTTTTTTTTTS.SSSSSSS....
	//...CCCCCCCCC....OOOOOOOOOO..MMMMMM...MMMMMMMMMMMM...MMMMMM.EEEEEEEEEEE.NNNNN..NNNN..TTTTTTTTTTSSSSSSSSSS...
	//..CCCCCCCCCCC..OOOOOOOOOOOO.MMMMMM...MMMMMMMMMMMM...MMMMMM.EEEEEEEEEEE.NNNNN..NNNN..TTTTTTTTTTSSSSSSSSSSS..
	//..CCCC...CCCCC.OOOOO..OOOOO.MMMMMMM.MMMMMMMMMMMMMM.MMMMMMM.EEEE........NNNNNN.NNNN.....TTTT...SSSSS..SSSS..
	//.CCCC.....CCC.OOOOO....OOOOOMMMMMMM.MMMMMMMMMMMMMM.MMMMMMM.EEEE........NNNNNN.NNNN.....TTTT...SSSSS........
	//.CCCC.........OOOO......OOOOMMMMMMM.MMMMMMMMMMMMMM.MMMMMMM.EEEEEEEEEE..NNNNNNNNNNN.....TTTT....SSSSSSS.....
	//.CCCC.........OOOO......OOOOMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM.EEEEEEEEEE..NNNNNNNNNNN.....TTTT.....SSSSSSSSS..
	//.CCCC.........OOOO......OOOOMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM.EEEEEEEEEE..NNNNNNNNNNN.....TTTT.......SSSSSSS..
	//.CCCC.....CCC.OOOOO....OOOOOMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM.EEEE........NNNNNNNNNNN.....TTTT..........SSSS..
	//..CCCC...CCCCC.OOOOO..OOOOO.MMMM.MMMMM.MMMMMMMM.MMMMM.MMMM.EEEE........NNNN.NNNNNN.....TTTT...SSSS....SSS..
	//..CCCCCCCCCCC..OOOOOOOOOOOO.MMMM.MMMMM.MMMMMMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN..NNNNN.....TTTT...SSSSSSSSSSS..
	//...CCCCCCCCCC...OOOOOOOOOO..MMMM.MMMMM.MMMMMMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN..NNNNN.....TTTT....SSSSSSSSSS..
	//....CCCCCCC.......OOOOOO....MMMM.MMMMM.MMMMMMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN...NNNN.....TTTT.....SSSSSSSS...
	//...........................................................................................................



	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Standing Time & Dayworks TOTAL TIMES
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



	// Filter the data
	const filteredComments = CommentsData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// STANDING TIME

	const standingTimeComments = filteredComments.filter(comment => comment.comment_type === 'Standing Time');

	// Calculate total duration
	const totalDuration_ST = standingTimeComments.reduce((total, comment) => {
		const [hours_ST, mins_ST] = comment.duration.split(':').map(Number);
		return {
			hours_ST: total.hours_ST + hours_ST,
			mins_ST: total.mins_ST + mins_ST,
		};
	}, { hours_ST: 0, mins_ST: 0 });

	// Format the total duration
	// just numbers
	// const totalStandingTime = `${String(totalDuration_ST.hours_ST).padStart(2, '0')}:${String(totalDuration_ST.mins_ST).padStart(2, '0')}`;
	// hrs & mins
	const totalStandingTime = `${String(totalDuration_ST.hours_ST).padStart(2, '0')} hrs ${String(totalDuration_ST.mins_ST).padStart(2, '0')} mins`;
	// console.log('Total Standing Time Duration:', totalStandingTime);

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// DAYWORKS

	const dayworksComments = filteredComments.filter(comment => comment.comment_type === 'Dayworks');

	// Calculate total duration
	const totalDuration_DW = dayworksComments.reduce((total, comment) => {
		const [hours_DW, mins_DW] = comment.duration.split(':').map(Number);
		return {
			hours_DW: total.hours_DW + hours_DW,
			mins_DW: total.mins_DW + mins_DW,
		};
	}, { hours_DW: 0, mins_DW: 0 });

	// Format the total duration
	// just numbers
	// const totalDayworks = `${String(totalDuration_DW.hours_DW).padStart(2, '0')}:${String(totalDuration_DW.mins_DW).padStart(2, '0')}`;
	// hrs & mins
	const totalDayworks = `${String(totalDuration_DW.hours_DW).padStart(2, '0')} hrs ${String(totalDuration_DW.mins_DW).padStart(2, '0')} mins`;

	// console.log('Total Dayworks Duration:', totalDayworks);













	//............................................................................................
	//.WWWW..WWWWW...WWWEEEEEEEEEEEE....AAAAA...ATTTTTTTTTTTHHH....HHHH.HEEEEEEEEEE.ERRRRRRRRRR...
	//.WWWW..WWWWW..WWWW.EEEEEEEEEEE....AAAAA...ATTTTTTTTTTTHHH....HHHH.HEEEEEEEEEE.ERRRRRRRRRRR..
	//.WWWW..WWWWWW.WWWW.EEEEEEEEEEE...AAAAAA...ATTTTTTTTTTTHHH....HHHH.HEEEEEEEEEE.ERRRRRRRRRRR..
	//.WWWW.WWWWWWW.WWWW.EEEE..........AAAAAAA......TTTT...THHH....HHHH.HEEE........ERRR....RRRR..
	//.WWWW.WWWWWWW.WWWW.EEEE.........AAAAAAAA......TTTT...THHH....HHHH.HEEE........ERRR....RRRR..
	//..WWWWWWWWWWW.WWW..EEEEEEEEEE...AAAAAAAA......TTTT...THHHHHHHHHHH.HEEEEEEEEEE.ERRRRRRRRRRR..
	//..WWWWWWW.WWWWWWW..EEEEEEEEEE...AAAA.AAAA.....TTTT...THHHHHHHHHHH.HEEEEEEEEEE.ERRRRRRRRRR...
	//..WWWWWWW.WWWWWWW..EEEEEEEEEE..AAAAAAAAAA.....TTTT...THHHHHHHHHHH.HEEEEEEEEEE.ERRRRRRRR.....
	//..WWWWWWW.WWWWWWW..EEEE........AAAAAAAAAAA....TTTT...THHH....HHHH.HEEE........ERRR.RRRRR....
	//..WWWWWWW.WWWWWWW..EEEE........AAAAAAAAAAA....TTTT...THHH....HHHH.HEEE........ERRR..RRRRR...
	//...WWWWW...WWWWW...EEEEEEEEEEEAAAA....AAAA....TTTT...THHH....HHHH.HEEEEEEEEEEEERRR...RRRRR..
	//...WWWWW...WWWWW...EEEEEEEEEEEAAAA.....AAAA...TTTT...THHH....HHHH.HEEEEEEEEEEEERRR....RRRR..
	//...WWWWW...WWWWW...EEEEEEEEEEEAAAA.....AAAA...TTTT...THHH....HHHH.HEEEEEEEEEEEERRR.....RRR..
	//............................................................................................

	let weatherIcon = ''
	getWeatherList().map((weather) => {
		if (weather.label === selectedWeather)
			weatherIcon = weather.icon
	})
	

	const [boreholeWeather, setBoreholeWeather] = useState(selectedWeather)

	useEffect(() => {
		if(borehole_guid){
			cachedHoleInformation(`hole-locations/${borehole_guid}`, (holeData: any) => {
				if (holeData) {
					setBoreholeWeather(holeData.weather); // HOLE WEATHER
				}
			});
		}
	}, [borehole_guid]);

	useEffect(() => {
		if(borehole_guid){
			updateWeather(
				borehole_guid,
				selectedWeather
			)
		}
	}, [selectedWeather, borehole_guid]);





















	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// TEMP VALUES
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	// let labelSample = ''
	// let labelDepth = ''



















	//..................................................................................................................
	//.IIIII.NNNN...NNNN..FFFFFFFFFF...OOOOOOO............CCCCCCC....HHHH...HHHH..EEEEEEEEEEE...CCCCCCC....KKKK...KKKK..
	//.IIIII.NNNNN..NNNN..FFFFFFFFFF..OOOOOOOOOO.........CCCCCCCCC...HHHH...HHHH..EEEEEEEEEEE..CCCCCCCCC...KKKK..KKKKK..
	//.IIIII.NNNNN..NNNN..FFFFFFFFFF.OOOOOOOOOOOO.......CCCCCCCCCCC..HHHH...HHHH..EEEEEEEEEEE.CCCCCCCCCCC..KKKK.KKKKK...
	//.IIIII.NNNNNN.NNNN..FFFF.......OOOOO..OOOOO.......CCCC...CCCCC.HHHH...HHHH..EEEE........CCCC...CCCCC.KKKKKKKKK....
	//.IIIII.NNNNNN.NNNN..FFFF......OOOOO....OOOOO.....CCCC.....CCC..HHHH...HHHH..EEEE.......ECCC.....CCC..KKKKKKKK.....
	//.IIIII.NNNNNNNNNNN..FFFFFFFFF.OOOO......OOOO.....CCCC..........HHHHHHHHHHH..EEEEEEEEEE.ECCC..........KKKKKKKK.....
	//.IIIII.NNNNNNNNNNN..FFFFFFFFF.OOOO......OOOO.....CCCC..........HHHHHHHHHHH..EEEEEEEEEE.ECCC..........KKKKKKKK.....
	//.IIIII.NNNNNNNNNNN..FFFFFFFFF.OOOO......OOOO.....CCCC..........HHHHHHHHHHH..EEEEEEEEEE.ECCC..........KKKKKKKKK....
	//.IIIII.NNNNNNNNNNN..FFFF......OOOOO....OOOOO.....CCCC.....CCC..HHHH...HHHH..EEEE.......ECCC.....CCC..KKKK.KKKKK...
	//.IIIII.NNNN.NNNNNN..FFFF.......OOOOO..OOOOO.......CCCC...CCCCC.HHHH...HHHH..EEEE........CCCC...CCCCC.KKKK..KKKK...
	//.IIIII.NNNN..NNNNN..FFFF.......OOOOOOOOOOOO.......CCCCCCCCCCC..HHHH...HHHH..EEEEEEEEEEE.CCCCCCCCCCC..KKKK..KKKKK..
	//.IIIII.NNNN..NNNNN..FFFF........OOOOOOOOOO.........CCCCCCCCCC..HHHH...HHHH..EEEEEEEEEEE..CCCCCCCCCC..KKKK...KKKK..
	//.IIIII.NNNN...NNNN..FFFF..........OOOOOO............CCCCCCC....HHHH...HHHH..EEEEEEEEEEE...CCCCCCC....KKKK...KKKK..
	//..................................................................................................................

	useEffect(() => {
		// if (rig_name === 'Select Current Rig' || rig_name === '') {
		// 	setInformationCheck(false)
		// } else {
		// 	setInformationCheck(true)
		// }
		setInformationCheck(true)
	})







	//..........................................................................................................................
	//.UUUU...UUUU..PPPPPPPPP...PDDDDDDDD.......AAAAA...AAATTTTTTTTTTEEEEEEEEE...... I.IIINN...NNNN.NNNFFFFFFFF...OOOOOOO.....
	//.UUUU...UUUU..PPPPPPPPPP..PDDDDDDDDD......AAAAA...AAATTTTTTTTTTEEEEEEEEE...... I.IIINN...NNNN.NNNFFFFFFFF..OOOOOOOOOO...
	//.UUUU...UUUU..PPPPPPPPPPP.PDDDDDDDDDD....AAAAAA...AAATTTTTTTTTTEEEEEEEEE...... I.IIINNN..NNNN.NNNFFFFFFFF.FOOOOOOOOOOO..
	//.UUUU...UUUU..PPPP...PPPP.PDDD...DDDD....AAAAAAA......TTTT...TTEE............. I.IIINNNN.NNNN.NNNF.......FFOOO...OOOOO..
	//.UUUU...UUUU..PPPP...PPPP.PDDD....DDDD..AAAAAAAA......TTTT...TTEE............. I.IIINNNN.NNNN.NNNF.......FFOO.....OOOO..
	//.UUUU...UUUU..PPPPPPPPPPP.PDDD....DDDD..AAAAAAAA......TTTT...TTEEEEEEEEE...... I.IIINNNNNNNNN.NNNFFFFFFF.FFOO......OOO..
	//.UUUU...UUUU..PPPPPPPPPP..PDDD....DDDD..AAAA.AAAA.....TTTT...TTEEEEEEEEE...... I.IIINNNNNNNNN.NNNFFFFFFF.FFOO......OOO..
	//.UUUU...UUUU..PPPPPPPPP...PDDD....DDDD.DAAAAAAAAA.....TTTT...TTEEEEEEEEE...... I.IIIN.NNNNNNN.NNNFFFFFFF.FFOO......OOO..
	//.UUUU...UUUU..PPPP........PDDD....DDDD.DAAAAAAAAAA....TTTT...TTEE............. I.IIIN.NNNNNNN.NNNF.......FFOO.....OOOO..
	//.UUUU...UUUU..PPPP........PDDD...DDDDD.DAAAAAAAAAA....TTTT...TTEE............. I.IIIN..NNNNNN.NNNF.......FFOOOO..OOOOO..
	//.UUUUUUUUUUU..PPPP........PDDDDDDDDDD.DDAA....AAAA....TTTT...TTEEEEEEEEEE..... I.IIIN..NNNNNN.NNNF........FOOOOOOOOOOO..
	//..UUUUUUUUU...PPPP........PDDDDDDDDD..DDAA.....AAAA...TTTT...TTEEEEEEEEEE..... I.IIIN...NNNNN.NNNF.........OOOOOOOOOO...
	//...UUUUUUU....PPPP........PDDDDDDDD..DDDAA.....AAAA...TTTT...TTEEEEEEEEEE..... I.IIIN....NNNN.NNNF..........OOOOOOO.....
	//..........................................................................................................................


	function getHoleData() {


		cachedHoleInformation(`hole-locations/${borehole_guid}`, (holeData: any) => {
			if (holeData) {
				console.log('holeData in getHoleData:', holeData)
				setLeadDriller(holeData.driller)
				setLeadDrillerID(holeData.driller_id)
				setLeadDrillerCSCS(holeData.driller_cscs);
				setSecondMan(holeData.second_man);
				setSecondManCSCS(holeData.second_man_cscs);
				setHole_barcode(holeData.hole_barcode);
				setActivityTerminationReason(holeData.activity_termination_reason); // TERMINATION REASON
				setHole_status(holeData.status); // HOLE STATUS
			} else {
				// // console.log(`**ERROR** HOLE DATA NOT LOADED. ID: ${borehole_guid}`);
			}
			// // console.log('holeData:', holeData);
		})

	}

	function saveHoleData() {
	
		// Attempt to delete the outdated hole information
		del(`drillit-hole-locations/updateInformation/${borehole_guid}-update`)
			.catch(error => {
				console.warn('Failed to delete outdated hole information (may not exist):', error);
			})
			.finally(() => {
				let hole_status_temp = '1'
				if (hole_status !== '3') {
					hole_status_temp = '2'
				} else {
					hole_status_temp = '3'
				}
				// Update the hole information
				updateHoleInformation(
					borehole_guid,
					boreholeName,
					leadDriller,
					leadDrillerID,
					leadDrillerCSCS,
					secondMan,
					secondManCSCS,
					'', // plantGuid || '',
					'', // plantName,
					'', //plantMakeModel || '',
					'', // sptGUID || '',
					hole_barcode,
					borehole_depth,
					activityTerminationReason,
					project_guid,
					hole_status_temp, // hole_status
					numberingType
				)
				.then(() => {
					// Fetch the updated hole data
					getHoleData();
				})
				.catch(error => {
					console.error('Failed to update hole information:', error);
				});
			});
	}










	return (

		<>

			{/* //..............................................................................
//.HHHH...HHHH..EEEEEEEEEEE....AAAAA.....ADDDDDDDD....DEEEEEEEEEE.ERRRRRRRRR....
//.HHHH...HHHH..EEEEEEEEEEE....AAAAA.....ADDDDDDDDD...DEEEEEEEEEE.ERRRRRRRRRR...
//.HHHH...HHHH..EEEEEEEEEEE...AAAAAA.....ADDDDDDDDDD..DEEEEEEEEEE.ERRRRRRRRRR...
//.HHHH...HHHH..EEEE..........AAAAAAA....ADDD...DDDD..DEEE........ERRR...RRRRR..
//.HHHH...HHHH..EEEE.........AAAAAAAA....ADDD....DDDD.DEEE........ERRR...RRRRR..
//.HHHHHHHHHHH..EEEEEEEEEE...AAAAAAAA....ADDD....DDDD.DEEEEEEEEE..ERRRRRRRRRR...
//.HHHHHHHHHHH..EEEEEEEEEE...AAAA.AAAA...ADDD....DDDD.DEEEEEEEEE..ERRRRRRRRRR...
//.HHHHHHHHHHH..EEEEEEEEEE..EAAAAAAAAA...ADDD....DDDD.DEEEEEEEEE..ERRRRRRR......
//.HHHH...HHHH..EEEE........EAAAAAAAAAA..ADDD....DDDD.DEEE........ERRR.RRRR.....
//.HHHH...HHHH..EEEE........EAAAAAAAAAA..ADDD...DDDDD.DEEE........ERRR..RRRR....
//.HHHH...HHHH..EEEEEEEEEEEEEAA....AAAA..ADDDDDDDDDD..DEEEEEEEEEE.ERRR..RRRRR...
//.HHHH...HHHH..EEEEEEEEEEEEEAA.....AAAA.ADDDDDDDDD...DEEEEEEEEEE.ERRR...RRRRR..
//.HHHH...HHHH..EEEEEEEEEEEEEAA.....AAAA.ADDDDDDDD....DEEEEEEEEEE.ERRR....RRRR..
//.............................................................................. */}

			<Header toggleSidebar={toggleSidebar}
				middle={
					<ProjectHeader projectId={project_guid}
						information_check={informationCheck}
						holeType="LG"
						holeName={borehole_name}
						companyGuid={company_guid}
						company_id={companyID}
						projectGuid={project_guid}
						activeDates={active_dates}
						lastActiveDate={lastActiveDate}

						project_start_date={project_start_date}
						site_description={site_description}
						key_site_features={key_site_features}
						site_history={site_history}
						key_hands={key_hands}
						expected_strata={expected_strata}
						notes={notes}
						equipment_required={equipment_required}
						project_client={client}
						client_contact={client_contact}
						client_phone={client_phone}
						client_email={client_email}
						engineer_name={engineer_name}
						engineer_phone={engineer_phone}
						engineer_email={engineer_email}
						rig_name='' // {rig_name}
						driller={leadDriller}
						second_man='' // {secondMan}
						plantCheck=''
					/>
				}
			// companyName={company_name}
			/>








			{/* //.........................................................................
			//.PPPPPPPPP.....AAAA.....AAANN...NNNN.NNNEEEEEEEE.EEEL.........LSSSSSS....
			//.PPPPPPPPPP...AAAAAA....AAANN...NNNN.NNNEEEEEEEE.EEEL........LLSSSSSSS...
			//.PPPPPPPPPPP..AAAAAA....AAANNN..NNNN.NNNEEEEEEEE.EEEL.......LLLSSSSSSSS..
			//.PPPP...PPPP..AAAAAAA...AAANNNN.NNNN.NNNE........EEEL.......LLLS...SSSS..
			//.PPPP...PPPP.PAAAAAAA...AAANNNN.NNNN.NNNE........EEEL.......LLLSS........
			//.PPPPPPPPPPP.PAAAAAAA...AAANNNNNNNNN.NNNEEEEEEEE.EEEL........LLSSSSS.....
			//.PPPPPPPPPP..PAAA.AAAA..AAANNNNNNNNN.NNNEEEEEEEE.EEEL.........LSSSSSSS...
			//.PPPPPPPPP..PPAAAAAAAA..AAAN.NNNNNNN.NNNEEEEEEEE.EEEL...........SSSSSSS..
			//.PPPP.......PPAAAAAAAAA.AAAN.NNNNNNN.NNNE........EEEL..............SSSS..
			//.PPPP......PPPAAAAAAAAA.AAAN..NNNNNN.NNNE........EEEL.......LLLS...SSSS..
			//.PPPP......PPPA....AAAA.AAAN..NNNNNN.NNNEEEEEEEEEEEELLLLLLLLLLLSSSSSSSS..
			//.PPPP......PPPA....AAAAAAAAN...NNNNN.NNNEEEEEEEEEEEELLLLLLLL.LLSSSSSSSS..
			//.PPPP.....PPPPA.....AAAAAAAN....NNNN.NNNEEEEEEEEEEEELLLLLLLL..LSSSSSS....
			//......................................................................... */}


			{completingBorehole &&
				<Modal title="Mark as Complete" toggleModal={() => { setCompletingBorehole(false) }}>
					<div className="flex items-center flex-col pt-36">
						<div className="mb-14">
							Confirm you have completed activity on this borehole
						</div>

						<div className="flex">
							<Button theme="secondary" size="lg" className="mr-8" onClick={() => { setCompletingBorehole(false) }}>Cancel</Button>

							<Button theme="primary" size="lg" onClick={() => {
								closeHole(borehole_guid, '2024-02-09', project_guid).then(() => { setRedirect(true) })
							}}>Confirm</Button>
						</div>
					</div>
				</Modal>
			}

			{showSamplesCreateD &&
				<SamplesCreate
					onClose={() => { setShowSamplesCreateD(false) }}
					setShowSamplesCreateD={setShowSamplesCreateD}
					onComplete={(value: any) => saveAction(value, 'D', setShowSamplesCreateD(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
					title="Disturbed Sample (D)"
					casing_preset={current_casing_depth}
					addSampleData={addSampleData}
					from_type={fromType}
					new_type_number={numberOfSamplesD_TOTAL + 1}
					new_type_number_sequencial={numberOfSamples_TOTAL + 1}
					numbering_type={numberingType}
					spt_number={numberOfSPTs_TOTAL}
					spt_number_sequencial={numberOfSPTsCPTs_TOTAL}
					u_number={numberOfUSamples_TOTAL}
					sampled_by={leadDriller}
				/>
			}

			{showSamplesCreateB &&
				<SamplesCreate
					onClose={() => { setShowSamplesCreateB(false) }}
					setShowSamplesCreateB={setShowSamplesCreateB}
					onComplete={(value: any) => saveAction(value, 'B', setShowSamplesCreateB(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
					title="Bulk Sample (B)"
					casing_preset={current_casing_depth}
					addSampleData={addSampleData}
					from_type={fromType}
					new_type_number={numberOfSamplesB_TOTAL + 1}
					new_type_number_sequencial={numberOfSamples_TOTAL + 1}
					numbering_type={numberingType}
					cpt_number={numberOfCPTs_TOTAL}
					cpt_number_sequencial={numberOfSPTsCPTs_TOTAL}
					sampled_by={leadDriller}
				/>
			}

			{showSamplesCreateWS &&
				<SamplesCreate
					onClose={() => { setShowSamplesCreateWS(false) }}
					setShowSamplesCreateWS={setShowSamplesCreateWS}
					onComplete={(value: any) => saveAction(value, 'WS', setShowSamplesCreateWS(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
					title="Water Sample (WS)"
					casing_preset={current_casing_depth}
					addSampleData={addSampleData}
					from_type={fromType}
					new_type_number={numberOfSamplesWS_TOTAL + 1}
					new_type_number_sequencial={numberOfSamples_TOTAL + 1}
					numbering_type={numberingType}
					sampled_by={leadDriller}
				/>
			}

			{showD &&
				<Samples
					onClose={() => { setShowD(false) }}
					onCompleteNumbering={(value: any) => {
						setNumberingType(value)
						saveHoleData()
					}}
					onComplete={(value: any) => saveAction(value, 'D', setShowD(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
					title="Disturbed Sample (D)"
					casing_preset={current_casing_depth}
					new_type_number={numberOfSamplesD_TOTAL + 1}
					new_type_number_sequencial={numberOfSamples_TOTAL + 1}
					numbering_type={numberingType}
					sampleType='D'
					sampled_by={leadDriller}
				/>
			}

			{showDEdit &&
				<Samples
					update={true}
					onClose={() => { setShowDEdit(false) }}
					setShowDEdit={setShowDEdit}
					onCompleteNumbering={(value: any) => {
						setNumberingType(value)
						saveHoleData()
					}}
					onComplete={(value: any) => {
						saveAction(value, 'D', setShowDEdit(false))
						// console.log('SAMPLES DATA LOOKING FOR HIDDEN:', value)
					}}
					borehole={borehole_name}
					title="Disturbed Sample (D)"
					casing_preset={current_casing_depth}
					defaultValues={SamplesData[editingIndex]}
					numbering_type={numberingType}
					sampleType='D'
					sampled_by={leadDriller}
				/>
			}

			{showB &&
				<Samples
					onClose={() => { setShowB(false) }}
					onCompleteNumbering={(value: any) => {
						setNumberingType(value)
						saveHoleData()
					}}
					onComplete={(value: any) => saveAction(value, 'B', setShowB(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
					title="Bulk Sample (B)"
					casing_preset={current_casing_depth}
					new_type_number={numberOfSamplesB_TOTAL + 1}
					new_type_number_sequencial={numberOfSamples_TOTAL + 1}
					numbering_type={numberingType}
					sampleType='B'
					sampled_by={leadDriller}
				/>
			}

			{showBEdit &&
				<Samples
					update={true}
					onClose={() => { setShowBEdit(false) }}
					setShowBEdit={setShowBEdit}
					onCompleteNumbering={(value: any) => {
						setNumberingType(value)
						saveHoleData()
					}}
					onComplete={(value: any) => saveAction(value, 'B', setShowBEdit(false))}
					borehole={borehole_name}
					title="Bulk Sample (B)"
					casing_preset={current_casing_depth}
					defaultValues={SamplesData[editingIndex]}
					numbering_type={numberingType}
					sampleType='B'
					sampled_by={leadDriller}
				/>
			}

			{showES &&
				<Samples
					onClose={() => { setShowES(false) }}
					onCompleteNumbering={(value: any) => {
						setNumberingType(value)
						saveHoleData()
					}}
					onComplete={(value: any) => saveAction(value, 'ES', setShowES(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
					title="Environmental Sample (ES)"
					casing_preset={current_casing_depth}
					new_type_number={numberOfSamplesES_TOTAL + 1}
					new_type_number_sequencial={numberOfSamples_TOTAL + 1}
					numbering_type={numberingType}
					sampleType='ES'
					sampled_by={leadDriller}
				/>
			}

			{showESEdit &&
				<Samples
					update={true}
					onClose={() => { setShowESEdit(false) }}
					setShowESEdit={setShowESEdit}
					onCompleteNumbering={(value: any) => {
						setNumberingType(value)
						saveHoleData()
					}}
					onComplete={(value: any) => saveAction(value, 'ES', setShowESEdit(false))}
					borehole={borehole_name}
					title="Environmental Sample (ES)"
					casing_preset={current_casing_depth}
					defaultValues={SamplesData[editingIndex]}
					numbering_type={numberingType}
					sampleType='ES'
					sampled_by={leadDriller}
				/>
			}

			{showWS &&
				<Samples
					onClose={() => { setShowWS(false) }}
					onCompleteNumbering={(value: any) => {
						setNumberingType(value)
						saveHoleData()
					}}
					onComplete={(value: any) => saveAction(value, 'WS', setShowWS(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
					title="Water Sample (WS)"
					casing_preset={current_casing_depth}
					new_type_number={numberOfSamplesWS_TOTAL + 1}
					new_type_number_sequencial={numberOfSamples_TOTAL + 1}
					numbering_type={numberingType}
					sampleType='WS'
					sampled_by={leadDriller}
				/>
			}

			{showWSEdit &&
				<Samples
					update={true}
					onClose={() => { setShowWSEdit(false) }}
					setShowWSEdit={setShowWSEdit}
					onCompleteNumbering={(value: any) => {
						setNumberingType(value)
						saveHoleData()
					}}
					onComplete={(value: any) => saveAction(value, 'WS', setShowWSEdit(false))}
					borehole={borehole_name}
					title="Water Sample (WS)"
					casing_preset={current_casing_depth}
					defaultValues={SamplesData[editingIndex]}
					numbering_type={numberingType}
					sampleType='WS'
					sampled_by={leadDriller}
				/>
			}

			{showSPT &&
				<SPT
					onClose={() => { setShowSPT(false) }}
					onCompleteNumbering={(value: any) => {
						setNumberingType(value)
						saveHoleData()
					}}
					onComplete={(value: any) => {
						if (value !== addSampleData) { setAddSampleData(value) }
						if (fromType !== 'SPT') { setFromType('SPT') }
						saveAction(value, 'SPT', setShowSPT(false))
						setShowSamplesCreateD(true)
						// console.log('setAddSampleData value:', value)
					}}
					borehole={borehole_name}
					selectedDate={selectedDate}
					casing_preset={current_casing_depth}
					new_type_number={numberOfSPTs_TOTAL + 1}
					new_type_number_sequencial={numberOfSPTsCPTs_TOTAL + 1}
					numbering_type={numberingType}
					boreholeDepth={borehole_depth}
					sampled_by={leadDriller}
				/>
			}

			{showSPTEdit &&
				<SPT
					update={true}
					onClose={() => { setShowSPTEdit(false) }}
					setShowSPTEdit={setShowSPTEdit}
					onCompleteNumbering={(value: any) => {
						setNumberingType(value)
						saveHoleData()
					}}
					onComplete={(value: any) => saveAction(value, 'SPT', setShowSPTEdit(false))}
					borehole={borehole_name}
					casing_preset={current_casing_depth}
					defaultValues={InSituTestsData[editingIndex]}
					numbering_type={numberingType}
					boreholeDepth={borehole_depth}
					sampled_by={leadDriller}
				/>
			}

			{showCPT &&
				<CPT
					onClose={() => { setShowCPT(false) }}
					onCompleteNumbering={(value: any) => {
						setNumberingType(value)
						saveHoleData()
					}}
					onComplete={(value: any) => {
						if (value !== addSampleData) { setAddSampleData(value) }
						if (fromType !== 'CPT') { setFromType('CPT') }
						saveAction(value, 'CPT', setShowCPT(false))
						setShowSamplesCreateB(true)
						// console.log('CPT values:', value)
					}}
					borehole={borehole_name}
					selectedDate={selectedDate}
					casing_preset={current_casing_depth}
					new_type_number={numberOfCPTs_TOTAL + 1}
					new_type_number_sequencial={numberOfSPTsCPTs_TOTAL + 1}
					numbering_type={numberingType}
					boreholeDepth={borehole_depth}
					sampled_by={leadDriller}
				/>
			}

			{showCPTEdit &&
				<CPT
					update={true}
					onClose={() => { setShowCPTEdit(false) }}
					setShowCPTEdit={setShowCPTEdit}
					onCompleteNumbering={(value: any) => {
						setNumberingType(value)
						saveHoleData()
					}}
					onComplete={(value: any) => saveAction(value, 'CPT', setShowCPTEdit(false))}
					borehole={borehole_name}
					casing_preset={current_casing_depth}
					defaultValues={InSituTestsData[editingIndex]}
					numbering_type={numberingType}
					boreholeDepth={borehole_depth}
					sampled_by={leadDriller}
				/>
			}

			{showU &&
				<U
					onClose={() => { setShowU(false) }}
					// onComplete={(value: any) => saveAction(value, 'U', setShowU(false))}
					onComplete={(value: any) => {
						if (value !== addSampleData) { setAddSampleData(value) }
						if (fromType !== 'U') { setFromType('U') }
						saveAction(value, 'U', setShowU(false))
						setShowSamplesCreateD(true)
					}}
					borehole={borehole_name}
					selectedDate={selectedDate}
					utype='100'
					casing_preset={current_casing_depth}
					new_type_number={numberOfUSamples_TOTAL + 1}
					boreholeDepth={borehole_depth}
					sampled_by={leadDriller}
				/>
			}

			{showUEdit &&
				<U
					update={true}
					onClose={() => { setShowUEdit(false) }}
					setShowUEdit={setShowUEdit}
					onComplete={(value: any) => saveAction(value, 'U', setShowUEdit(false))}
					borehole={borehole_name}
					casing_preset={current_casing_depth}
					utype='100'
					defaultValues={InSituTestsData[editingIndex]}
					boreholeDepth={borehole_depth}
					sampled_by={leadDriller}
				/>
			}

			{showPS &&
				<PS
					onClose={() => { setShowPS(false) }}
					onComplete={(value: any) => saveAction(value, 'PS', setShowPS(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
					casing_preset={current_casing_depth}
					new_type_number={numberOfPSSamples_TOTAL + 1}
					boreholeDepth={borehole_depth}
					sampled_by={leadDriller}
				/>
			}

			{showPSEdit &&
				<PS
					update={true}
					onClose={() => { setShowPSEdit(false) }}
					setShowPSEdit={setShowPSEdit}
					onComplete={(value: any) => saveAction(value, 'PS', setShowPSEdit(false))}
					borehole={borehole_name}
					casing_preset={current_casing_depth}
					defaultValues={InSituTestsData[editingIndex]}
					boreholeDepth={borehole_depth}
					sampled_by={leadDriller}
				/>
			}

			{showSV &&
				<SV
					onClose={() => { setShowSV(false) }}
					onComplete={(value: any) => saveAction(value, 'SV', setShowSV(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
					new_type_number={numberOfShearVane_TOTAL + 1}
					boreholeDepth={borehole_depth}
				/>
			}

			{showSVEdit &&
				<SV
					update={true}
					onClose={() => { setShowSVEdit(false) }}
					setShowSVEdit={setShowSVEdit}
					onComplete={(value: any) => saveAction(value, 'SV', setShowSVEdit(false))}
					borehole={borehole_name}
					defaultValues={InSituTestsData[editingIndex]}
					boreholeDepth={borehole_depth}
				/>
			}

			{showSoilDescription &&
				<SoilDescription
					onClose={() => { setShowSoilDescription(false) }}
					onComplete={(value: any) => {
						// console.log('SoilDescription Value on Complete:', value)
						saveAction(value, 'SoilDescription', setShowSoilDescription(false))
					}}
					borehole={borehole_name}
					selectedDate={selectedDate}
					lastSoilDescriptionDepth={last_soil_description_depth_to}
					lastSoilDescriptionDepthToTop={last_soil_description_depth_from}
					lastSoilDescriptionDepthToBase={last_soil_description_depth_to}
					lastSoilDescriptionStratum={last_soil_description_depth_text}
				/>
			}

			{showSoilDescriptionEdit &&
				<SoilDescriptionEditor
					update={true}
					onClose={() => { setShowSoilDescriptionEdit(false) }}
					setShowSoilDescriptionEdit={setShowSoilDescriptionEdit}
					onComplete={(value: any) => saveAction(value, 'SoilDescription', setShowSoilDescriptionEdit(false))}
					borehole={borehole_name}
					// defaultValues={defaultValues}
					defaultValues={SoilDescriptionData[editingIndex]}
				/>
			}

			{showGroundWater &&
				<GroundWater
					onClose={() => { setShowGroundWater(false) }}
					// onComplete={(value: any) => saveAction(value, 'GroundWater', setShowGroundWater(false))}
					onComplete={(value: any) => {
						if (value !== addSampleData) { setAddSampleData(value) }
						if (fromType !== 'GroundWater') { setFromType('GroundWater') }
						saveAction(value, 'GroundWater', setShowGroundWater(false))
						setShowSamplesCreateWS(true)
						// console.log('GroundWater values:', value)
					}}
					borehole={borehole_name}
					selectedDate={selectedDate}
					casing_preset={current_casing_depth}
				/>
			}

			{showGroundWaterEdit &&
				<GroundWater
					update={true}
					onClose={() => { setShowGroundWaterEdit(false) }}
					setShowGroundWaterEdit={setShowGroundWaterEdit}
					onComplete={(value: any) => saveAction(value, 'GroundWater', setShowGroundWaterEdit(false))}
					borehole={borehole_name}
					defaultValues={GroundWaterTestsData[editingIndex]}
					selectedDate={selectedDate}
				/>
			}

			{showInstall &&
				<Install
					onClose={() => { setShowInstall(false) }}
					onComplete={(value: any) => {
						saveAction(value, 'Installation', setShowInstall(false))
						setShowBackfill(true)
						// console.log('Installation values:', value)
					}}
					borehole={borehole_name}
					selectedDate={selectedDate}
					holeDepth={borehole_depth}
					pipe1solid={pipe1solid}
					pipe1slotted={pipe1slotted}
					pipe2solid={pipe2solid}
					pipe2slotted={pipe2slotted}
				/>
			}

			{showInstallEdit &&
				<Install
					update={true}
					onClose={() => { setShowInstallEdit(false) }}
					setShowInstallEdit={setShowInstallEdit}
					onComplete={(value: any) => {
						saveAction(value, 'Installation', setShowInstallEdit(false))
						// console.log('Installation values:', value)
					}}
					borehole={borehole_name}
					holeDepth={borehole_depth}
					pipe1solid={pipe1solid}
					pipe1slotted={pipe1slotted}
					pipe2solid={pipe2solid}
					pipe2slotted={pipe2slotted}
					defaultValues={InstallationData[editingIndex]}
				/>
			}

			{showBackfill &&
				<Backfill
					onClose={() => { setShowBackfill(false) }}
					onComplete={(value: any) => {
						saveAction(value, 'Backfill', setShowBackfill(false))
						// console.log('Backfill values:', value)
						// Call the function to update backfillText
						// updateBackfillText(value)
					}}
					borehole={borehole_name}
					selectedDate={selectedDate}
					holeDepth={borehole_depth}
				/>
			}

			{showBackfillEdit &&
				<Backfill
					update={true}
					onClose={() => { setShowBackfillEdit(false) }}
					setShowBackfillEdit={setShowBackfillEdit}
					onComplete={(value: any) => {
						saveAction(value, 'Backfill', setShowBackfillEdit(false))
						// Call the function to update backfillText
						// updateBackfillText(value)
					}}
					borehole={borehole_name}
					holeDepth={borehole_depth}
					defaultValues={BackfillData[editingIndex]}
				/>
			}

			{showInspectionPit &&
				<InspectionPit
					onClose={() => { setShowInspectionPit(false) }}
					onComplete={(value: any) => saveAction(value, 'InspectionPit', setShowInspectionPit(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
				/>
			}

			{showInspectionPitEdit &&
				<InspectionPit
					update={true}
					onClose={() => { setShowInspectionPitEdit(false) }}
					setShowInspectionPitEdit={setShowInspectionPitEdit}
					onComplete={(value: any) => saveAction(value, 'InspectionPit', setShowInspectionPitEdit(false))}
					borehole={borehole_name}
					defaultValues={InspectionPitData[editingIndex]}
				/>
			}

			{showCasingRecord &&
				<CasingRecord
					onClose={() => { setShowCasingRecord(false) }}
					onComplete={(value: any) => saveAction(value, 'Casing', setShowCasingRecord(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
					holeDepth={borehole_depth}
				/>
			}

			{showCasingRecordEdit &&
				<CasingRecord
					update={true}
					onClose={() => { setShowCasingRecordEdit(false) }}
					setShowCasingRecordEdit={setShowCasingRecordEdit}
					onComplete={(value: any) => saveAction(value, 'Casing', setShowCasingRecordEdit(false))}
					borehole={borehole_name}
					holeDepth={borehole_depth}
					defaultValues={CasingData[editingIndex]}
				/>
			}

			{showChiselling &&
				<Chiselling
					onClose={() => { setShowChiselling(false) }}
					onComplete={(value: any) => saveAction(value, 'Chiselling', setShowChiselling(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
				/>
			}

			{showChisellingEdit &&
				<Chiselling
					update={true}
					onClose={() => { setShowChisellingEdit(false) }}
					setShowChisellingEdit={setShowChisellingEdit}
					onComplete={(value: any) => saveAction(value, 'Chiselling', setShowChisellingEdit(false))}
					borehole={borehole_name}
					defaultValues={ChisellingData[editingIndex]}
				/>
			}

			{showWaterAdded &&
				<WaterAdded
					onClose={() => { setShowWaterAdded(false) }}
					onComplete={(value: any) => saveAction(value, 'WaterAdded', setShowWaterAdded(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
				/>
			}

			{showWaterAddedEdit &&
				<WaterAdded
					update={true}
					onClose={() => { setShowWaterAddedEdit(false) }}
					setShowWaterAddedEdit={setShowWaterAddedEdit}
					onComplete={(value: any) => saveAction(value, 'WaterAdded', setShowWaterAddedEdit(false))}
					borehole={borehole_name}
					defaultValues={WaterAddedData[editingIndex]}
				/>
			}

			{showDailyWaterLevels &&
				<DailyWaterLevels
					onClose={() => { setShowDailyWaterLevels(false) }}
					onComplete={(value: any) => saveAction(value, 'DailyWaterLevels', setShowDailyWaterLevels(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
				/>
			}

			{showDailyWaterLevelsEdit &&
				<DailyWaterLevels
					update={true}
					onClose={() => { setShowDailyWaterLevelsEdit(false) }}
					setShowDailyWaterLevelsEdit={setShowDailyWaterLevelsEdit}
					onComplete={(value: any) => saveAction(value, 'DailyWaterLevels', setShowDailyWaterLevelsEdit(false))}
					borehole={borehole_name}
					defaultValues={DailyWaterLevelsData[editingIndex]}
				/>
			}

			{showReDrill &&
				<ReDrill
					onClose={() => { setShowReDrill(false) }}
					onComplete={(value: any) => saveAction(value, 'ReDrill', setShowReDrill(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
				/>
			}

			{showReDrillEdit &&
				<ReDrill
					update={true}
					onClose={() => { setShowReDrillEdit(false) }}
					setShowReDrillEdit={setShowReDrillEdit}
					onComplete={(value: any) => saveAction(value, 'ReDrill', setShowReDrillEdit(false))}
					borehole={borehole_name}
					defaultValues={ReDrillData[editingIndex]}
				/>
			}

			{showComments &&
				<Comments
					onClose={() => { setShowComments(false) }}
					onComplete={(value: any) => saveAction(value, 'Comments', setShowComments(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
				/>
			}

			{showCommentsEdit &&
				<Comments
					update={true}
					onClose={() => { setShowCommentsEdit(false) }}
					setShowCommentsEdit={setShowCommentsEdit}
					onComplete={(value: any) => saveAction(value, 'Comments', setShowCommentsEdit(false))}
					borehole={borehole_name}
					defaultValues={CommentsData[editingIndex]}
				/>
			}

			{showTrialpit &&
				<TrialpitDetails
					onClose={() => { setShowTrialpit(false) }}
					onComplete={(value: any) => saveAction(value, 'Trialpit', setShowTrialpit(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
					client={client}
					company_name={companyName}
				/>
			}

			{showTrialpitEdit &&
				<TrialpitDetails
					update={true}
					onClose={() => { setShowTrialpitEdit(false) }}
					setShowTrialpitEdit={setShowTrialpitEdit}
					onComplete={(value: any) => saveAction(value, 'Trialpit', setShowTrialpitEdit(false))}
					borehole={borehole_name}
					defaultValues={TrialpitData[editingIndex]}
					client={client}
					company_name={companyName}
				/>
			}

			{showDetailDescription &&
				<DetailDescriptions
					onClose={() => { setShowDetailDescription(false) }}
					onComplete={(value: any) => saveAction(value, 'DetailDescription', setShowDetailDescription(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
					holeDepth={borehole_depth}
				/>
			}

			{showDetailDescriptionEdit &&
				<DetailDescriptions
					update={true}
					onClose={() => { setShowDetailDescriptionEdit(false) }}
					setShowDetailDescriptionEdit={setShowDetailDescriptionEdit}
					onComplete={(value: any) => saveAction(value, 'DetailDescription', setShowDetailDescriptionEdit(false))}
					borehole={borehole_name}
					holeDepth={borehole_depth}
					defaultValues={DetailDescriptionData[editingIndex]}
				/>
			}















			{/* //....................................................................................................................................
			//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNN...NNNN..UUUU...UUUU.......PPPPPPPPP.....AAAA.....AANNN...NNNN.NNEEEEEEEEE.EELL.........SSSSSSS....
			//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNNN..NNNN..UUUU...UUUU.......PPPPPPPPPP...AAAAAA....AANNN...NNNN.NNEEEEEEEEE.EELL........LSSSSSSSS...
			//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNNN..NNNN..UUUU...UUUU.......PPPPPPPPPPP..AAAAAA....AANNNN..NNNN.NNEEEEEEEEE.EELL.......LLSSSSSSSSS..
			//.MMMMMMM.MMMMMMM.EEEE........NNNNNN.NNNN..UUUU...UUUU.......PPPP...PPPP..AAAAAAA...AANNNNN.NNNN.NNEE........EELL.......LLSS...SSSS..
			//.MMMMMMM.MMMMMMM.EEEE........NNNNNN.NNNN..UUUU...UUUU.......PPPP...PPPP.AAAAAAAA...AANNNNN.NNNN.NNEE........EELL.......LLSSS........
			//.MMMMMMM.MMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU.......PPPPPPPPPPP.AAAAAAAA...AANNNNNNNNNN.NNEEEEEEEEE.EELL........LSSSSSS.....
			//.MMMMMMMMMMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU.......PPPPPPPPPP..AAAA.AAAA..AANNNNNNNNNN.NNEEEEEEEEE.EELL.........SSSSSSSS...
			//.MMMMMMMMMMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU.......PPPPPPPPP..PAAAAAAAAA..AANN.NNNNNNN.NNEEEEEEEEE.EELL...........SSSSSSS..
			//.MMMMMMMMMMMMMMM.EEEE........NNNNNNNNNNN..UUUU...UUUU.......PPPP.......PAAAAAAAAAA.AANN.NNNNNNN.NNEE........EELL..............SSSS..
			//.MMMM.MMMMM.MMMM.EEEE........NNNN.NNNNNN..UUUU...UUUU.......PPPP......PPAAAAAAAAAA.AANN..NNNNNN.NNEE........EELL.......LLSS...SSSS..
			//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN..NNNNN..UUUUUUUUUUU.......PPPP......PPAA....AAAA.AANN..NNNNNN.NNEEEEEEEEEEEELLLLLLLLLLLSSSSSSSSS..
			//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN..NNNNN...UUUUUUUUU........PPPP......PPAA....AAAAAAANN...NNNNN.NNEEEEEEEEEEEELLLLLLLLL.LSSSSSSSSS..
			//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN...NNNN....UUUUUUU.........PPPP.....PPPAA.....AAAAAANN....NNNN.NNEEEEEEEEEEEELLLLLLLLL..SSSSSSS....
			//.................................................................................................................................... */}

			{showNewHole &&
				<NewHole onClose={() => { setShowNewHole(false) }}
					holeType="CP"
					defaultValues={{
						// company_guid: project.company_guid,
						// project_guid: project_guid,
					}}
				/>
			}

			{showEmail &&
				<Email onClose={() => { setShowEmail(false) }}
					company_id={companyID}
					project_location={project_location}
					project_id={projectID}
					project_guid={project_guid}
					selected_date={selectedDate}
				/>
			}

			{showAGS &&
				<AGS onClose={() => { setShowAGS(false) }}
					project_start_date={project_start_date}
					project_id={project_id}
					company_id={companyID}
				/>
			}

			{showSaveLog &&
				<SaveLog onClose={() => { setShowSaveLog(false) }}
					boreholeName={borehole_name}
					holeType="LG"
					project_id={project_id}
					project_guid={project_guid}
					project_name={project_name}
					project_location={project_location}
					hole_type_id={starting_hole_type_id}
					driller_id={leadDrillerID}
					company_id={companyID}
					company_guid={company_guid}
					logDate={sheetDate}
					activeDates={active_dates}

					boreholeDepth={borehole_depth}
					hole_guid={hole_guid}
					numberingType={numberingType}
				/>
			}

			{showPrintLabel &&
				<PrintLabel onClose={() => { setShowPrintLabel(false) }}
					borehole={borehole_name}
					sample={labelSample}
					depth={labelDepth}
					size='printLabel'
					sampled_by={leadDriller}
				/>
			}


			{userWarning &&
				<ModalSmall title="Log Information" toggleModal={() => { setUserWarning(false) }} confirm={false}>
					<div className="flex items-center flex-col pt-16">
						<div className="text-lg">
							This log belongs to:
						</div>
						<div className="text-3xl font-bold">
							{leadDriller}
						</div>
						<div className="text-lg mb-8">
							{randomNegPhrases}
						</div>

						<div className="flex">
							<Button theme="primary" size="lg" className="mb-16" onClick={() => { setUserWarning(false) }}>OK</Button>
						</div>
					</div>
				</ModalSmall>
			}











			{/* //....................................................................................
			//....OOOOOOO.....PPPPPPPPP...TTTTTTTTTTTIIII...OOOOOOO.....NNNN...NNNN...SSSSSSS.....
			//...OOOOOOOOOO...PPPPPPPPPP..TTTTTTTTTTTIIII..OOOOOOOOOO...NNNNN..NNNN..SSSSSSSSS....
			//..OOOOOOOOOOOO..PPPPPPPPPPP.TTTTTTTTTTTIIII.OOOOOOOOOOOO..NNNNN..NNNN..SSSSSSSSSS...
			//..OOOOO..OOOOO..PPPP...PPPP....TTTT...TIIII.OOOOO..OOOOO..NNNNNN.NNNN.NSSSS..SSSS...
			//.OOOOO....OOOOO.PPPP...PPPP....TTTT...TIIIIIOOOO....OOOOO.NNNNNN.NNNN.NSSSS.........
			//.OOOO......OOOO.PPPPPPPPPPP....TTTT...TIIIIIOOO......OOOO.NNNNNNNNNNN..SSSSSSS......
			//.OOOO......OOOO.PPPPPPPPPP.....TTTT...TIIIIIOOO......OOOO.NNNNNNNNNNN...SSSSSSSSS...
			//.OOOO......OOOO.PPPPPPPPP......TTTT...TIIIIIOOO......OOOO.NNNNNNNNNNN.....SSSSSSS...
			//.OOOOO....OOOOO.PPPP...........TTTT...TIIIIIOOOO....OOOOO.NNNNNNNNNNN........SSSSS..
			//..OOOOO..OOOOO..PPPP...........TTTT...TIIII.OOOOO..OOOOO..NNNN.NNNNNN.NSSS....SSSS..
			//..OOOOOOOOOOOO..PPPP...........TTTT...TIIII.OOOOOOOOOOOO..NNNN..NNNNN.NSSSSSSSSSSS..
			//...OOOOOOOOOO...PPPP...........TTTT...TIIII..OOOOOOOOOO...NNNN..NNNNN..SSSSSSSSSS...
			//.....OOOOOO.....PPPP...........TTTT...TIIII....OOOOOO.....NNNN...NNNN...SSSSSSSS....
			//.................................................................................... */}

			<Container>

				<ProjectOptionsBorehole
					onMarkAsComplete={() => { setCompletingBorehole(true) }}
					company_guid={company_guid}
					project_guid={project_guid}
					project_id={project_id}
					boreholeName={borehole_name}
					borehole_guid={borehole_guid}
					boreholeType="LG"
					boreholeDepth={borehole_depth}
					boreholeRequiredText=""
					// boreholeRequiredDepth={this.state.borehole.depth}
					boreholeRequiredDepth=""
					// boreholeRequiredTests={this.state.borehole.samplesTests}
					boreholeRequiredTests=""
					editWeatherLink={'/projects/weather/' + project_guid}
					className="mt-1 mb-1 py-1"
					// rig_guid={rig_id}
					driller={leadDriller}
					driller_id={leadDrillerID}
				// second_man={secondMan}
				// rig_name={plantName}
					getHoleData={getHoleData}
					activity_termination_reason={activityTerminationReason}
					numberingType={numberingType}
				/>

				{/* {!informationCheck && (
					<div className="bg-yellow w-64 text-center text-xs font-bold mx-auto -mb-1 -mt-4">
						<Link to={`/rigs/${company_guid}/${project_guid}`}>
							<img src={warningIcon} width="12" className="ml-1 inline-block pb-0.5" alt="" />&nbsp;
							Rig Not Selected
						</Link>
					</div>
				)} */}


				{/* <div className="w-full py-2 bg-grey-vdark text-center mt-1 mb-0 text-sm text-white rounded-md">
					<div onClick={() => setBhMenuVisible(!bhMenuVisible)} ref={popupRef}>
						Logging Menu <img src={menubars} className="h-3 w-3 mb-0.5 inline" alt="" />
					</div>
				</div> */}


				<div className="flex w-full py-2 bg-grey-vdark text-center mt-1 mb-0 text-sm text-white rounded-md">
					<div className="flex-none ml-0 w-6 pl-3 pt-0.5">
						<img onClick={handleReloadClick} src={icon_reload} className="ml-0 w-3.5 h-3.5" alt="" />
					</div>
					<div onClick={() => setBhMenuVisible(!bhMenuVisible)} ref={popupRef} className="flex-1">
						Logging Menu <img src={menubars} className="h-3 w-3 mb-0.5 inline" alt="" />
					</div>
					<div className="flex-none mr-0 w-6">

					</div>
				</div>


				<div className="relative"> {/* menu container */}

					<div className="grid lg:grid-cols-5 lg:gap-6 mt-2">
						<div className="col-span-5 lg:col-span-3 mt-2">





















							{/* LEFT SIDE PANELS GO HERE */}

							{/*//.......................................................
							 //...SSSSSSS.......AAAAA....AMMMMM...MMMMMM.PPPPPPPPP....
							//..SSSSSSSSS......AAAAA....AMMMMM...MMMMMM.PPPPPPPPPP...
							//..SSSSSSSSSS....AAAAAA....AMMMMM...MMMMMM.PPPPPPPPPPP..
							//.SSSSS..SSSS....AAAAAAA...AMMMMMM.MMMMMMM.PPPP...PPPP..
							//.SSSSS.........AAAAAAAA...AMMMMMM.MMMMMMM.PPPP...PPPP..
							//..SSSSSSS......AAAAAAAA...AMMMMMM.MMMMMMM.PPPPPPPPPPP..
							//...SSSSSSSSS...AAAA.AAAA..AMMMMMMMMMMMMMM.PPPPPPPPPP...
							//.....SSSSSSS..AAAAAAAAAA..AMMMMMMMMMMMMMM.PPPPPPPPP....
							//........SSSSS.AAAAAAAAAAA.AMMMMMMMMMMMMMM.PPPP.........
							//.SSSS....SSSS.AAAAAAAAAAA.AMMM.MMMMM.MMMM.PPPP.........
							//.SSSSSSSSSSSSSAAA....AAAA.AMMM.MMMMM.MMMM.PPPP.........
							//..SSSSSSSSSS.SAAA.....AAAAAMMM.MMMMM.MMMM.PPPP.........
							//...SSSSSSSS.SSAAA.....AAAAAMMM.MMMMM.MMMM.PPPP.........
							//....................................................... */}

							<Panel title="Samples" numberOfElements={numberOfSamples} options={[
								{
									name: 'D',
									onClick: () => { setShowD(true) }
								}, {
									name: 'B',
									onClick: () => { setShowB(true) }
								}, {
									name: 'ES',
									onClick: () => { setShowES(true) }
								}, {
									name: 'WS',
									onClick: () => { setShowWS(true) }
								}

							]}>
								<table className="table-auto w-full text-xs sm:text-sm text-center border-collapse overflow-hidden rounded-t-md">
									<thead className="font-bold">
										<tr>
											<td className={`px-1 sm:px-1 py-0 bg-grey-mid border-grey-mid rounded-tl-sm rounded-tr-sm border-l border-r border-grey-light`}>Type</td>
											<td className={`px-1 sm:px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>From (m)</td>
											<td className={`px-1 sm:px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>To (m)</td>
											{/* <td className={`px-1 sm:px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>Casing</td>
											<td className={`px-1 sm:px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>Water (m)</td> */}
											<td className={`px-1 sm:px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light text-left`}>Notes</td>
											<td className={`px-1 sm:px-1 py-0 bg-grey-mid border-grey-mid rounded-tr-sm border-r border-grey-light`}></td>
										</tr>
									</thead>
									<tbody>

										{SamplesData?.filter((action: any) => {
											return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
										}).map((valueSamples: any, index: number) => {

											// Replace depth_to_top = 0 with GL
											let depth_to_top_temp, depth_to_base_temp, casing_depth_temp, water_depth_temp
											if (valueSamples.depth_to_top !== undefined && typeof valueSamples.depth_to_top === 'string' && valueSamples.depth_to_top === '0.00' || valueSamples.depth_to_top === '0') {
												depth_to_top_temp = 'GL'
											} else if (valueSamples.depth_to_top !== undefined && typeof valueSamples.depth_to_top === 'number' && valueSamples.depth_to_top === 0.00 || valueSamples.depth_to_top === 0) {
												depth_to_top_temp = 'GL'
											} else {
												depth_to_top_temp = valueSamples.depth_to_top
											}

											//Get actualIndex
											const actualIndex = SamplesData.map((object: any) => object.guid).indexOf(valueSamples.guid)

											return (
												<React.Fragment key={index}>

													<tr className="border border-grey-mid bg-white mb-1">
														<td className={`px-1 sm:px-1 pt-0.5 border-r border-grey-mid font-bold`}>{valueSamples.type}-{valueSamples.type_number}</td>
														<td className={`px-1 sm:px-1 pt-0.5 border-r border-grey-mid`}>{depth_to_top_temp}</td>
														<td className={`px-1 sm:px-1 pt-0.5 border-r border-grey-mid`}>{valueSamples.depth_to_base}</td>
														{/* <td className={`px-1 sm:px-1 pt-0.5 border-r border-grey-mid`}>{valueSamples.casing_depth}</td>
														<td className={`px-1 sm:px-1 pt-0.5 border-r border-grey-mid`}>{valueSamples.water_depth}</td> */}
														<td className={`px-1 sm:px-1 pt-0.5 border-r border-grey-mid text-left`}>{valueSamples.general_remarks}</td>
														<td className={`px-1 sm:px-1 py-0 border-r border-grey-mid w-8 md:w-12`}>
															<div className={"text-center px-1 sm:px-0 pb-0.5"}>
																<img onClick={() => {
																	setEditingGuid(valueSamples.guid)
																	setEditingIndex(actualIndex)

																	switch (valueSamples.type) {
																		case 'D':
																			setShowDEdit(true)
																			// console.log('Sample Edit:', actualIndex, valueSamples.guid, editingGuid, editingIndex)
																			break
																		case 'B':
																			setShowBEdit(true)
																			break
																		case 'WS':
																			setShowWSEdit(true)
																			break
																		case 'ES':
																			setShowESEdit(true)
																			break
																	}
																}}
																	className="inline" width={"16"} src={edit} alt="" />
															</div>
														</td>
													</tr>
												</React.Fragment>
											)
										})}

									</tbody>
								</table>
							</Panel>














							{/* //...........................................
							//.IIIII..SSSSSSS....PPPPPPPPP...TTTTTTTTTT..
							//.IIIII.SSSSSSSSS...PPPPPPPPPP..TTTTTTTTTT..
							//.IIIII.SSSSSSSSSS..PPPPPPPPPPP.TTTTTTTTTT..
							//.IIIIIISSSS..SSSS..PPPP...PPPP....TTTT.....
							//.IIIIIISSSS........PPPP...PPPP....TTTT.....
							//.IIIII.SSSSSSS.....PPPPPPPPPPP....TTTT.....
							//.IIIII..SSSSSSSSS..PPPPPPPPPP.....TTTT.....
							//.IIIII....SSSSSSS..PPPPPPPPP......TTTT.....
							//.IIIII.......SSSSS.PPPP...........TTTT.....
							//.IIIIIISSS....SSSS.PPPP...........TTTT.....
							//.IIIIIISSSSSSSSSSS.PPPP...........TTTT.....
							//.IIIII.SSSSSSSSSS..PPPP...........TTTT.....
							//.IIIII..SSSSSSSS...PPPP...........TTTT.....
							//........................................... */}

							<Panel title="In Situ Shear Vane" numberOfElements={numberOfShearVane} onAdd={() => { setShowSV(true) }}>

								<table className="table-auto w-full text-xs sm:text-sm text-center border-collapse overflow-hidden rounded-t-md">
									<thead className="font-bold">
										<tr>
											<td className={`px-1 py-1 bg-grey-mid boreder border-grey-mid rounded-tl-sm rounded-tr-sm border-l  border-r border-grey-light`}>Shear Vane</td>
											<td className={`px-1 py-1 bg-grey-mid boreder border-grey-mid border-r border-grey-light`}>Depth (m)</td>
											<td className={`px-1 py-1 bg-grey-mid boreder border-grey-mid border-r border-grey-light`}>Vane Type</td>
											<td className={`px-1 py-1 bg-grey-mid boreder border-grey-mid border-r border-grey-light`}>Vane Size</td>
											<td className={`px-1 py-1 bg-grey-mid boreder border-grey-mid border-r border-grey-light`}>Average (kPa)</td>
											<td className={`px-1 py-1 bg-grey-mid boreder border-grey-mid rounded-tr-sm border-r border-grey-light`}></td>
										</tr>
									</thead>

									<tbody>

										{InSituTestsData?.filter((action: any) => {
											return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
										}).map((valueInSituTest: any, index: number) => {

											let depth_of_test_temp
											if (valueInSituTest.type === 'SV') {
												// Replace depth_of_test = 0 with GL
												if (valueInSituTest.depth_to_top !== undefined &&
													valueInSituTest.depth_to_top === '0.00' || valueInSituTest.depth_to_top === '0' || valueInSituTest.depth_to_top === 0
												) {
													depth_of_test_temp = 'GL'
												} else {
													depth_of_test_temp = valueInSituTest.depth_of_test
												}
											} else {
												// Replace depth_of_test = 0 with GL
												if (valueInSituTest.depth_to_top !== undefined &&
													valueInSituTest.depth_to_top === '0.00' || valueInSituTest.depth_to_top === '0' || valueInSituTest.depth_to_top === 0
												) {
													depth_of_test_temp = 'GL'
												} else {
													depth_of_test_temp = valueInSituTest.depth_to_top
												}
											}

											let svPrefix = 'Ave: '
											let svSuffix = 'kPa'
											if (valueInSituTest.average === 'Not suitable for testing') { svPrefix = ''; svSuffix = '' }

											//Get actualIndex
											const actualIndex = InSituTestsData.map((object: any) => object.guid).indexOf(valueInSituTest.guid)

											return (
												<React.Fragment key={index}>
													<tr className="hidden invisible">
														{/* <tr> */}
														<td>{valueInSituTest.sv_result1} | {valueInSituTest.sv_result2} | {valueInSituTest.sv_result3} | {valueInSituTest.sv_result4} | {valueInSituTest.suitableForTesting}</td>
													</tr>
													<tr className="border border-grey-mid bg-white mb-1">
														<td className={`px-1 py-1 border-r border-grey-mid font-bold`}>{valueInSituTest.type}#</td>
														<td className={`px-1 py-1 border-r border-grey-mid`}>{depth_of_test_temp}</td>
														<td className={`px-1 py-1 border-r border-grey-mid`}>{valueInSituTest.test_type}</td>
														<td className={`px-1 py-1 border-r border-grey-mid`}>{valueInSituTest.size}</td>
														<td className={`px-1 py-1 border-r border-grey-mid`}>{valueInSituTest.average}</td>
														<td className={`px-1 sm:px-1 py-1 border-r border-grey-mid w-8 md:w-12`}>
															<div className={"text-center px-1 sm:px-0 py-0"}>
																<img
																	onClick={() => {
																		setEditingGuid(valueInSituTest.guid)
																		setEditingIndex(actualIndex)
																		setShowSVEdit(true)
																	}
																	} className="inline" width={"20"} src={edit} alt="" />
															</div>
														</td>
													</tr>
												</React.Fragment>
											)
										})}

									</tbody>
								</table>
							</Panel>














							{/* //......................................................
							//.....GGGGGGG....EEEEEEEEEEE...OOOOOOO.....LLLL........
							//...GGGGGGGGGG...EEEEEEEEEEE..OOOOOOOOOO...LLLL........
							//..GGGGGGGGGGGG..EEEEEEEEEEE.OOOOOOOOOOOO..LLLL........
							//..GGGGG..GGGGG..EEEE........OOOOO..OOOOO..LLLL........
							//.GGGGG....GGG...EEEE.......EOOOO....OOOOO.LLLL........
							//.GGGG...........EEEEEEEEEE.EOOO......OOOO.LLLL........
							//.GGGG..GGGGGGGG.EEEEEEEEEE.EOOO......OOOO.LLLL........
							//.GGGG..GGGGGGGG.EEEEEEEEEE.EOOO......OOOO.LLLL........
							//.GGGGG.GGGGGGGG.EEEE.......EOOOO....OOOOO.LLLL........
							//..GGGGG....GGGG.EEEE........OOOOO..OOOOO..LLLL........
							//..GGGGGGGGGGGG..EEEEEEEEEEE.OOOOOOOOOOOO..LLLLLLLLLL..
							//...GGGGGGGGGG...EEEEEEEEEEE..OOOOOOOOOO...LLLLLLLLLL..
							//.....GGGGGGG....EEEEEEEEEEE....OOOOOO.....LLLLLLLLLL..
							//...................................................... */}

							<Panel title="Soil Description" numberOfElements={numberOfSoilDescriptions} onAdd={() => { setShowSoilDescription(true) }}>

								<table className="table-auto w-full text-xs sm:text-sm text-center border-collapse overflow-hidden rounded-t-md">
									<thead className="font-bold">
										<tr>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid rounded-tl-sm rounded-tr-sm border-l  border-r border-grey-light`}>From</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>To</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>Soil Description</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light w-8`}></td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}></td>
										</tr>
									</thead>
									<tbody>



										{todaySoilDescriptionsVisible &&

											<>

												{SoilDescriptionData?.filter((action: any) => {
													return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
												}).map((valueSoilDesc: any, index: number) => {

													// Replace depth_of_test = 0 with GL
													let depth_to_top_temp
													if (valueSoilDesc.depth_to_top !== undefined && valueSoilDesc.depth_to_top === '0.00' || valueSoilDesc.depth_to_top === '0') {
														depth_to_top_temp = 'GL'
													} else {
														depth_to_top_temp = valueSoilDesc.depth_to_top
													}

													// console.log('valueSoilDesc.stratum_type:', valueSoilDesc.stratum_type)
													// console.log('valueSoilDesc.depth_to_top:', valueSoilDesc.depth_to_top)
													// console.log('valueSoilDesc.depth_to_base:', valueSoilDesc.depth_to_base)

													let stratum_type_css = ''
													if (valueSoilDesc.stratum_type === 'TOPSOIL') { stratum_type_css = 'soilTopsoil' }
													if (valueSoilDesc.stratum_type === 'SAND') { stratum_type_css = 'soilSand' }
													if (valueSoilDesc.stratum_type === 'GRAVEL') { stratum_type_css = 'soilGravel' }
													if (valueSoilDesc.stratum_type === 'SANDGRAVEL') { stratum_type_css = 'soilGravel' }
													if (valueSoilDesc.stratum_type === 'CLAY') { stratum_type_css = 'soilClay' }
													if (valueSoilDesc.stratum_type === 'SILT') { stratum_type_css = 'soilSilt' }
													if (valueSoilDesc.stratum_type === 'PEAT') { stratum_type_css = 'soilPeat' }
													if (valueSoilDesc.stratum_type === 'CHALK') { stratum_type_css = 'soilChalk' }
													if (valueSoilDesc.stratum_type === 'MADEGROUND') { stratum_type_css = 'soilMadeground' }
													if (valueSoilDesc.stratum_type === 'LIMESTONE') { stratum_type_css = 'soilLimestone' }
													if (valueSoilDesc.stratum_type === 'DOLOMITE') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'SANDSTONE') { stratum_type_css = 'soilSandstone' }
													if (valueSoilDesc.stratum_type === 'SILTSTONE') { stratum_type_css = 'soilSiltstone' }
													if (valueSoilDesc.stratum_type === 'GYPSUM') { stratum_type_css = 'soilGypsum' }
													if (valueSoilDesc.stratum_type === 'MUDSTONE') { stratum_type_css = 'soilMudstone' }
													if (valueSoilDesc.stratum_type === 'CHERT') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'SHALE') { stratum_type_css = 'soilShale' }
													if (valueSoilDesc.stratum_type === 'GRANITE') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'DIORITE') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'RHYOLITE') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'ANDERSITE') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'BASALT') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'OBSIDIAN') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'MARBLE') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'QUARTZITE') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'SLATE') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'COAL') { stratum_type_css = 'soilCoal' }

													//Get actualIndex
													const actualIndex = SoilDescriptionData.map((object: any) => object.guid).indexOf(valueSoilDesc.guid)

													return (
														<React.Fragment key={index}>
															<tr className="hidden invisible">
																<td>{valueSoilDesc.stratum_type}</td>
															</tr>

															<tr className="border border-grey-mid bg-white mb-1">
																<td className={`px-1 pt-0.5 border-r border-grey-mid align-top`}>{depth_to_top_temp}</td>
																<td className={`px-1 pt-0.5 border-r border-grey-mid align-top`}>{valueSoilDesc.depth_to_base}</td>
																<td className={`px-1 pt-0.5 border-r border-grey-mid text-left`}>{valueSoilDesc.stratum_description}</td>
																<td className={`${stratum_type_css} border-r border-grey-mid`}></td>
																<td className={`px-0 py-0 border-r border-grey-mid align-top w-8 md:w-12`}>
																	<div className={"text-center px-0 pb-0.5"}>
																		<img
																			onClick={() => {
																				setEditingGuid(valueSoilDesc.guid)
																				setEditingIndex(actualIndex)
																				setShowSoilDescriptionEdit(true)
																			}}
																			className="inline" width={"16"} height={"16"} src={edit} alt="" />
																	</div>
																</td>
															</tr>
														</React.Fragment>
													);
												})}

												{/* Add the static row for viewing all */}
												<tr>
													<td colSpan={4}>
														<div className="font-bold text-charcoal text-left mt-1 w-20" onClick={() => {
															setTodaySoilDescriptionsVisible(false);
															setAllSoilDescriptionsVisible(true);
														}}>
															<div className="bg-grey-mid py-0.5 text-center rounded-bl-md w-full" style={{ fontSize: '9px', lineHeight: '9px' }}>
																View All
															</div>
														</div>
													</td>
												</tr>
											</>

										}



										{allSoilDescriptionsVisible &&

											<>

												{SoilDescriptionData?.map((valueSoilDesc: any, index: number) => {

													// Replace depth_to_top = 0 with GL
													let depth_to_top_temp
													if (valueSoilDesc.depth_to_top !== undefined && valueSoilDesc.depth_to_top === '0.00' || valueSoilDesc.depth_to_top === '0') {
														depth_to_top_temp = 'GL'
													} else {
														depth_to_top_temp = valueSoilDesc.depth_to_top
													}

													// change background color of records NOT today
													let bg_date_responsive
													const valueSoilDescTime = new Date(valueSoilDesc.time_of_action);
													const selectedDateObj = new Date(selectedDate);

													const valueSoilDescDate = new Date(valueSoilDescTime.getFullYear(), valueSoilDescTime.getMonth(), valueSoilDescTime.getDate());
													const selectedDateOnly = new Date(selectedDateObj.getFullYear(), selectedDateObj.getMonth(), selectedDateObj.getDate());

													if (valueSoilDescDate.getTime() === selectedDateOnly.getTime()) {
														bg_date_responsive = 'bg-white'
													} else {
														bg_date_responsive = 'bg-sptTB'
													}
													//Get actualIndex
													const actualIndex = SoilDescriptionData.map((object: any) => object.guid).indexOf(valueSoilDesc.guid)

													let stratum_type_css = ''
													if (valueSoilDesc.stratum_type === 'TOPSOIL') { stratum_type_css = 'soilTopsoil' }
													if (valueSoilDesc.stratum_type === 'SAND') { stratum_type_css = 'soilSand' }
													if (valueSoilDesc.stratum_type === 'GRAVEL') { stratum_type_css = 'soilGravel' }
													if (valueSoilDesc.stratum_type === 'SANDGRAVEL') { stratum_type_css = 'soilGravel' }
													if (valueSoilDesc.stratum_type === 'CLAY') { stratum_type_css = 'soilClay' }
													if (valueSoilDesc.stratum_type === 'SILT') { stratum_type_css = 'soilSilt' }
													if (valueSoilDesc.stratum_type === 'PEAT') { stratum_type_css = 'soilPeat' }
													if (valueSoilDesc.stratum_type === 'CHALK') { stratum_type_css = 'soilChalk' }
													if (valueSoilDesc.stratum_type === 'MADEGROUND') { stratum_type_css = 'soilMadeground' }
													if (valueSoilDesc.stratum_type === 'LIMESTONE') { stratum_type_css = 'soilLimestone' }
													if (valueSoilDesc.stratum_type === 'DOLOMITE') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'SANDSTONE') { stratum_type_css = 'soilSandstone' }
													if (valueSoilDesc.stratum_type === 'SILTSTONE') { stratum_type_css = 'soilSiltstone' }
													if (valueSoilDesc.stratum_type === 'GYPSUM') { stratum_type_css = 'soilGypsum' }
													if (valueSoilDesc.stratum_type === 'MUDSTONE') { stratum_type_css = 'soilMudstone' }
													if (valueSoilDesc.stratum_type === 'CHERT') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'SHALE') { stratum_type_css = 'soilShale' }
													if (valueSoilDesc.stratum_type === 'GRANITE') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'DIORITE') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'RHYOLITE') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'ANDERSITE') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'BASALT') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'OBSIDIAN') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'MARBLE') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'QUARTZITE') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'SLATE') { stratum_type_css = 'soilRock' }
													if (valueSoilDesc.stratum_type === 'COAL') { stratum_type_css = 'soilCoal' }

													// console.log('valueSoilDesc.stratum_type:', valueSoilDesc.stratum_type)

													return (
														<React.Fragment key={index}>
															<tr className="hidden invisible">
																<td>{valueSoilDesc.stratum_type}</td>
															</tr>
															<tr className={`border border-grey-mid ${bg_date_responsive} mb-1`}>
																<td className={`px-1 pt-0.5 border-r border-grey-mid align-top`}>{depth_to_top_temp}</td>
																<td className={`px-1 pt-0.5 border-r border-grey-mid align-top`}>{valueSoilDesc.depth_to_base}</td>
																<td className={`px-1 pt-0.5 border-r border-grey-mid text-left`}>{valueSoilDesc.stratum_description}</td>
																<td className={`${stratum_type_css} border-r border-grey-mid`}></td>
																<td className={`px-0 py-0 border-r border-grey-mid align-top w-8 md:w-12`}>
																	<div className={"text-center px-0 pb-0.5"}>
																		<img
																			onClick={() => {
																				setEditingGuid(valueSoilDesc.guid);
																				setEditingIndex(actualIndex);
																				setShowSoilDescriptionEdit(true);
																				// console.log('SoilDesc Edit:', actualIndex, valueSoilDesc.guid, editingGuid, editingIndex)
																			}}
																			className="inline" width={"16"} height={"16"} src={edit} alt="" />
																	</div>
																</td>
															</tr>
															<tr>
																{/* <td style={{ fontSize: '9px'}}>
																id: {valueSoilDesc.id}
															</td> */}
																<td colSpan={3} className="text-right h-1">
																	<div className="-mt-5 pr-1" style={{ fontSize: '9px' }}>
																		{new Date(valueSoilDesc.time_of_action).toLocaleDateString('en-UK')}
																	</div>
																</td>
															</tr>
														</React.Fragment>
													);
												})}

												{/* Add the static row for viewing today */}
												<tr>
													<td colSpan={4}>
														<div className="font-bold text-charcoal text-left w-20 mt-1" onClick={() => {
															setTodaySoilDescriptionsVisible(true);
															setAllSoilDescriptionsVisible(false);
														}}>
															<div className="bg-grey-mid py-0.5 text-center rounded-bl-md w-full" style={{ fontSize: '9px', lineHeight: '9px' }}>
																View Today
															</div>
														</div>
													</td>
												</tr>
											</>
										}
									</tbody>
								</table>
							</Panel>







						</div>  {/* END OF LEFT SIDE */}

						<div className="col-span-5 lg:col-span-2 mt-0 md:mt-2"> {/* START OF RIGHT SIDE */}

							{/* RIGHT SIDE PANELS GO HERE */}














							{/* //................................................
							//.DDDDDDDDD....EEEEEEEEEEE.ETTTTTTTTTTLLLL.......
							//.DDDDDDDDDD...EEEEEEEEEEE.ETTTTTTTTTTLLLL.......
							//.DDDDDDDDDDD..EEEEEEEEEEE.ETTTTTTTTTTLLLL.......
							//.DDDD...DDDD..EEEE...........TTTT....LLLL.......
							//.DDDD....DDDD.EEEE...........TTTT....LLLL.......
							//.DDDD....DDDD.EEEEEEEEEE.....TTTT....LLLL.......
							//.DDDD....DDDD.EEEEEEEEEE.....TTTT....LLLL.......
							//.DDDD....DDDD.EEEEEEEEEE.....TTTT....LLLL.......
							//.DDDD....DDDD.EEEE...........TTTT....LLLL.......
							//.DDDD...DDDDD.EEEE...........TTTT....LLLL.......
							//.DDDDDDDDDDD..EEEEEEEEEEE....TTTT....LLLLLLLLL..
							//.DDDDDDDDDD...EEEEEEEEEEE....TTTT....LLLLLLLLL..
							//.DDDDDDDDD....EEEEEEEEEEE....TTTT....LLLLLLLLL..
							//................................................ */}

							<Panel title="Detail Descriptions" numberOfElements={numberOfDetailDescriptions} onAdd={() => setShowDetailDescription(true)}>
								<table className="table-auto w-full text-xs sm:text-sm text-center border-collapse overflow-hidden rounded-t-md">
									<thead className="font-bold">
										<tr>
											<td className={`px-1 py-1 bg-grey-mid border-grey-mid rounded-tl-sm rounded-tr-sm border-l  border-r border-grey-light`}>From (m)</td>
											<td className={`px-1 py-1 bg-grey-mid border-grey-mid border-r border-grey-light`}>To (m)</td>
											<td className={`px-1 py-1 bg-grey-mid border-grey-mid border-r border-grey-light text-left`}>Description</td>
											<td className={`px-1 py-1 bg-grey-mid border-grey-mid border-r border-grey-light`}></td>
										</tr>
									</thead>
									<tbody>
										{DetailDescriptionData?.filter((action: any) => {
											return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
										}).map((valueDetailDescription: any, index: number) => {

											//Get actualIndex
											const actualIndex = DetailDescriptionData.map((object: any) => object.guid).indexOf(valueDetailDescription.guid)
											// console.log('typeof actualIndex: ', actualIndex, typeof actualIndex)

											return (
												<React.Fragment key={index}>
													<tr className="border border-grey-mid bg-white mb-1">
														<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{valueDetailDescription.depth_from}</td>
														<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{valueDetailDescription.depth_to}</td>
														<td className={`px-1 pt-0.5 border-r border-grey-mid text-left`}>{valueDetailDescription.detail_description}</td>
														<td className={`px-1 py-0 border-r border-grey-mid w-8 md:w-12`}>
															<div className={"text-center px-0 pb-0.5"}>
																<img
																	onClick={() => {
																		// const actualIndex = CasingData.map((object: any) => object.guid).indexOf(valueCasing.guid)
																		setEditingGuid(valueDetailDescription.guid)
																		setEditingIndex(index)
																		setShowDetailDescriptionEdit(true)
																	}}
																	className="inline" width={"16"} src={edit} alt="" />
															</div>
														</td>
													</tr>
												</React.Fragment>
											)
										}
										)}
									</tbody>
								</table>

							</Panel>














							{/* //....................................................
							//.TTTTTTTTTTTRRRRRRRRRR...EEEEEEEEEEEEMMMMM...MMMMM..
							//.TTTTTTTTTTTRRRRRRRRRRR..EEEEEEEEEEEEMMMMM...MMMMM..
							//.TTTTTTTTTTTRRRRRRRRRRR..EEEEEEEEEEEEMMMMM...MMMMM..
							//....TTTT....RRRR...RRRRR.EEEE.......EMMMMMM.MMMMMM..
							//....TTTT....RRRR...RRRRR.EEEE.......EMMMMMM.MMMMMM..
							//....TTTT....RRRRRRRRRRR..EEEEEEEEEE.EMMMMMM.MMMMMM..
							//....TTTT....RRRRRRRRRRR..EEEEEEEEEE.EMMMMMMMMMMMMM..
							//....TTTT....RRRRRRRR.....EEEEEEEEEE.EMMMMMMMMMMMMM..
							//....TTTT....RRRR.RRRR....EEEE.......EMMMMMMMMMMMMM..
							//....TTTT....RRRR..RRRR...EEEE.......EMMM.MMMMM.MMM..
							//....TTTT....RRRR..RRRRR..EEEEEEEEEEEEMMM.MMMMM.MMM..
							//....TTTT....RRRR...RRRRR.EEEEEEEEEEEEMMM.MMMMM.MMM..
							//....TTTT....RRRR....RRRR.EEEEEEEEEEEEMMM.MMMMM.MMM..
							//.................................................... */}

							<Panel title="Comments / Standing Time / Day Works" numberOfElements={numberOfComments} onAdd={() => setShowComments(true)}>
								<table className="table-auto w-full text-xs sm:text-sm text-center border-collapse overflow-hidden rounded-t-md">
									<thead className="font-bold">
										<tr>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid rounded-tl-sm rounded-tr-sm border-l  border-r border-grey-light `}>Type</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>Time From</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>Duration</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>Comment</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}></td>
										</tr>
									</thead>
									<tbody>

										{CommentsData?.filter((action: any) => {
											return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
										}).map((valueComment: any, index: number) => {

											//Get actualIndex
											const actualIndex = CommentsData.map((object: any) => object.guid).indexOf(valueComment.guid)
											// console.log('typeof actualIndex: ', actualIndex, typeof actualIndex)

											return (
												<React.Fragment key={index}>
													<tr className="border border-grey-mid bg-white mb-1">
														<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{valueComment.comment_type}</td>
														<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{valueComment.start_time}</td>
														<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{valueComment.duration}</td>
														<td className={`px-1 pt-0.5 border-r border-grey-mid text-left`}>{valueComment.general_remarks}</td>
														<td className={`px-1 py-0 border-r border-grey-mid w-8 md:w-12`}>
															<div className={"text-center px-0 pb-0.5"}>
																<img
																	onClick={() => {
																		const actualIndex = CommentsData.map((object: any) => object.guid).indexOf(valueComment.guid)
																		setEditingGuid(valueComment.guid)
																		setEditingIndex(actualIndex)
																		setShowCommentsEdit(true)
																	}}
																	className="inline" width={"16"} src={edit} alt="" />
															</div>
														</td>
													</tr>
												</React.Fragment>
											)
										})}

										{/* <tr>
											<td className="h-2"></td>
										</tr>
										<tr>
											<td colSpan={5} className="w-full border border-grey-mid">
												<div className="grid grid-cols-12 w-full">
													<div className="col-span-3 pr-2 py-1 border-r border-grey-mid text-right bg-sptTB">
														Total Standing:
													</div>
													<div className="col-span-3 py-1 border-r border-grey-mid text-center font-bold">
														hrs mins
													</div>
													<div className="col-span-3 pr-2 py-1 border-r border-grey-mid text-right bg-sptTB">
														Total Dayworks:
													</div>
													<div className="col-span-3 py-1 text-center font-bold">
														hrs mins
													</div>
												</div>
											</td>
										</tr> */}
									</tbody>
								</table>

							</Panel>







						</div>  {/* END OF RIGHT SIDE */}

					</div>














					{/* //.................................................................................................................
					//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNN...NNNN..UUUU...UUUU.......BBBBBBBBBB.....OOOOOOO.....DDDDDDDDD...YYYY....YYYY..
					//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNNN..NNNN..UUUU...UUUU.......BBBBBBBBBBB...OOOOOOOOOO...DDDDDDDDDD..YYYYY..YYYYY..
					//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNNN..NNNN..UUUU...UUUU.......BBBBBBBBBBB..OOOOOOOOOOOO..DDDDDDDDDDD..YYYY..YYYY...
					//.MMMMMMM.MMMMMMM.EEEE........NNNNNN.NNNN..UUUU...UUUU.......BBBB...BBBB..OOOOO..OOOOO..DDDD...DDDD..YYYYYYYYY....
					//.MMMMMMM.MMMMMMM.EEEE........NNNNNN.NNNN..UUUU...UUUU.......BBBB...BBBB.OOOOO....OOOOO.DDDD....DDDD..YYYYYYYY....
					//.MMMMMMM.MMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU.......BBBBBBBBBBB.OOOO......OOOO.DDDD....DDDD...YYYYYY.....
					//.MMMMMMMMMMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU.......BBBBBBBBBB..OOOO......OOOO.DDDD....DDDD...YYYYYY.....
					//.MMMMMMMMMMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU.......BBBBBBBBBBB.OOOO......OOOO.DDDD....DDDD....YYYY......
					//.MMMMMMMMMMMMMMM.EEEE........NNNNNNNNNNN..UUUU...UUUU.......BBBB....BBBBOOOOO....OOOOO.DDDD....DDDD....YYYY......
					//.MMMM.MMMMM.MMMM.EEEE........NNNN.NNNNNN..UUUU...UUUU.......BBBB....BBBB.OOOOO..OOOOO..DDDD...DDDDD....YYYY......
					//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN..NNNNN..UUUUUUUUUUU.......BBBBBBBBBBBB.OOOOOOOOOOOO..DDDDDDDDDDD.....YYYY......
					//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN..NNNNN...UUUUUUUUU........BBBBBBBBBBB...OOOOOOOOOO...DDDDDDDDDD......YYYY......
					//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN...NNNN....UUUUUUU.........BBBBBBBBBB......OOOOOO.....DDDDDDDDD.......YYYY......
					//................................................................................................................. */}

					{bhMenuVisible &&

						// <div style={{ display: bhMenuVisible ? 'block' : 'none' }}>
						<div>

							<div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 sm:gap-3 md:gap-4 bg-grey-vdark w-full absolute z-99 top-0 left-0 -mt-3 text-center text-white text-xs md:text-sm pt-2 px-2 md:px-3 lg:px-4 pb-2 md:pb-3 lg:pb-4 rounded-b-lg modalshadow">

								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<Link to={`/projects/update/${project_id}`}>
										<img src={icon_project} className="h-12 w-12 mx-auto mb-1" alt="" />
										Edit Project
									</Link>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<div onClick={menuAndAGS}>
										<img src={icon_ags} className="h-12 w-12 mx-auto mb-1" alt="" />
										Output AGS
									</div>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<div onClick={menuAndEmail}>
										<img src={icon_email} className="h-12 w-12 mx-auto mb-1" alt="" />
										Send Email
									</div>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<button className="items-center justify-center px-2 bold mx-auto" onClick={menuAndLabels} >
										<img src={icon_labels} className="w-12 mx-auto" alt="" />
										View Labels
									</button>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<button className="items-center justify-center px-2 bold mx-auto" onClick={menuAndPreview} >
										<img src={icon_preview} className="h-12 w-12 mx-auto mb-1" alt="" />
										Preview Log
									</button>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<div onClick={menuAndSaveLog}>
										<img src={icon_savelog} className="h-12 w-12 mx-auto mb-1" alt="" />
										Save Log
									</div>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									{/* <Link to={`/#`} onClick={this.bhMenuClick}> */}
									<Link to={`#`} onClick={menuAndMarkAsComplete}>
										<img src={icon_complete} className="h-12 w-12 mx-auto mb-1" alt="" />
										Mark As Complete
									</Link>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									{/* <Link to={`/newhole/TP/${project_id}`}> */}
									<Link to={`/newhole/LG/${project_id}`}>
										<img src={icon_newborehole} className="h-12 w-12 mx-auto mb-1" alt="" />
										New Logging
									</Link>
								</div>

								<div className="col-span-3 md:col-span-4 lg:col-span-5">
									<div className="grid grid-cols-2 gap-2 sm:gap-3 md:gap-4 ">
										<div className="border border-grey-dark rounded py-2 text-lg">
											<Link to={`/projects/dashboard/company/${company_guid}/${project_guid}/logging`}>
												Logging Select
											</Link>
										</div>
										<div className="border border-grey-dark rounded py-2 text-lg">
											<Link to={`/projects/dashboard/${project_guid}/company/${company_guid}`}>
												Change Activity
											</Link>
										</div>
									</div>
								</div>

							</div>

						</div>

					}

				</div> {/* end of menu container */}














				{/* //.....................................................................................
				//.PPPPPPPPP...PRRRRRRRRR...REEEEEEEEEEEEVV....VVVVVVIII.IEEEEEEEEEEEEWW..WWWWW...WWW..
				//.PPPPPPPPPP..PRRRRRRRRRR..REEEEEEEEEEEEVV....VVVVVVIII.IEEEEEEEEEEEEWW..WWWWW..WWWW..
				//.PPPPPPPPPPP.PRRRRRRRRRR..REEEEEEEEEEEEVV....VVVVVVIII.IEEEEEEEEEEEEWW..WWWWWW.WWWW..
				//.PPPP...PPPP.PRRR...RRRRR.REEE.......EEVVV..VVVV.VVIII.IEEE.......EEWW.WWWWWWW.WWWW..
				//.PPPP...PPPP.PRRR...RRRRR.REEE........EVVV..VVVV.VVIII.IEEE.......EEWW.WWWWWWW.WWWW..
				//.PPPPPPPPPPP.PRRRRRRRRRR..REEEEEEEEE..EVVV..VVVV.VVIII.IEEEEEEEEE..EWWWWWWWWWW.WWW...
				//.PPPPPPPPPP..PRRRRRRRRRR..REEEEEEEEE..EVVVVVVVV..VVIII.IEEEEEEEEE..EWWWWWW.WWWWWWW...
				//.PPPPPPPPP...PRRRRRRR.....REEEEEEEEE...VVVVVVVV..VVIII.IEEEEEEEEE..EWWWWWW.WWWWWWW...
				//.PPPP........PRRR.RRRR....REEE.........VVVVVVVV..VVIII.IEEE........EWWWWWW.WWWWWWW...
				//.PPPP........PRRR..RRRR...REEE.........VVVVVVV...VVIII.IEEE........EWWWWWW.WWWWWWW...
				//.PPPP........PRRR..RRRRR..REEEEEEEEEE...VVVVVV...VVIII.IEEEEEEEEEE..WWWWW...WWWWW....
				//.PPPP........PRRR...RRRRR.REEEEEEEEEE...VVVVVV...VVIII.IEEEEEEEEEE..WWWWW...WWWWW....
				//.PPPP........PRRR....RRRR.REEEEEEEEEE...VVVVV....VVIII.IEEEEEEEEEE..WWWWW...WWWWW....
				//..................................................................................... */}

				{previewVisible &&

					<>

						{/* LOGSHEET */}

						<div className="flex flex-col rounded-lg border border-orange bg-modal-bg px-2 sm:px-2 md:px-2 pb-6 modalshadow previewLogsheet z-50" >

							<div className="flex items-center border-b border-grey-dark py-2">

								<div className="flex-1 flex items-left">

								</div>

								<div className="flex-1 flex justify-center text-lg sm:text-xl font-bold text-text leading-6 text-center mx-auto">
									Preview Logsheet
								</div>

								<div className="flex-1 flex justify-center">
									<span className="ml-auto">
										<button className="flex items-center justify-center w-12 h-12 text-4xl bold text-soil-grey bg-white border border-orange rounded" onClick={previewClick} ><img src={closeIcon} width="32" alt="" /></button>
									</span>
								</div>
							</div>

							<div className="w-full overflow-scroll">

								<div className="logsheetContainer mx-auto pb-6">

									<div className="mainContainer">

										<table className="w-full">
											<tbody>
												{/* HEADER TABLE */}
												<tr>
													<td colSpan={2}>
														<div className="headerTableHolder border border-grey-mid">
															<table className="noTableBorder text-center w-full">
																<tbody>
																	<tr>
																		<td rowSpan={3} className="headerLeft">{/* LOGO */}<img className="mt-1" src={companyLogo} /></td>
																		<td colSpan={4} className="headerTitle">Logging Record</td>
																		<td rowSpan={2} colSpan={2} className="headerRight headerBarcodeHolder pt-3 pl-5 text-center">
																			{/* <svg id="barcode" className="barcodeCSS"></svg> */}
																			<Barcode value={labelbarcodeVar}
																				options={{
																					format: 'code128',
																					//width: 2,
																					width: barcodeWidth,
																					height: 60,
																					fontSize: 12,
																					//marginLeft: 80,
																					//text: barcodeVar
																					//background: "#ccffff"
																				}} />
																		</td>
																	</tr>
																	<tr>
																		<td className="headerCellTitleLeft bg-sptTB">Location:</td>
																		<td className={`${logsheetLocationStyle}`}>{projectLocation}</td>
																		<td className="headerCellTitleRight bg-sptTB">Client:</td>
																		<td className="headerCellInputRight">{client}</td>
																	</tr>
																	<tr>
																		<td className="headerCellTitleLeft bg-sptTB">Project ID:</td>
																		<td id="projectID" className="headerCellInputLeft">{projectID}</td>
																		<td className="headerCellTitleRight bg-sptTB">Hole:</td>
																		<td id="boreholeID" className="headerCellInputRight">{borehole_name}</td>
																		<td id="headerDate">{sheetDate}</td>
																		<td id="headerSheets">Sheet 1 of 1</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</td>
												</tr>
												{/* END OF HEADER TABLE */}
												<tr>
													<td className="w-2/4 align-top border-r border-grey-mid"> {/* LEFT SIDE */}
														{/* SOIL DESCRIPTIONS */}
														<div className="soilDescriptionHolder w-full align-top border-b border-l border-grey-mid">
															{/* ////////////////////////////////////////////////////// */}
															<table className="soilTable noTableBorder">
																<tbody>
																	<tr>
																		<td className="tableRow soilDepth bg-sptTB">From:</td>
																		<td className="tableRow soilDepth bg-sptTB">To:</td>
																		<td className="tableRow soilDescription bg-grey-mid sectionTitle text-center">Geology Description</td>
																		<td className="tableRow soilLegend bg-sptTB">Legend</td>
																	</tr>
																	{/* ////////////////////////////////////////////////////// */}
																	<tr>
																		<td colSpan={4} className="soilDescriptionsRowsHolder" >
																			<table className="h-full w-full">
																				{SoilDescriptionData?.filter((action: any) => {
																					return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
																				}).map((valueSoilDesc: any, index: number) => {

																					let stratum_type_css = ''
																					if (valueSoilDesc.stratum_type === 'TOPSOIL') { stratum_type_css = 'soilTopsoil' }
																					if (valueSoilDesc.stratum_type === 'SAND') { stratum_type_css = 'soilSand' }
																					if (valueSoilDesc.stratum_type === 'GRAVEL') { stratum_type_css = 'soilGravel' }
																					if (valueSoilDesc.stratum_type === 'SANDGRAVEL') { stratum_type_css = 'soilGravel' }
																					if (valueSoilDesc.stratum_type === 'CLAY') { stratum_type_css = 'soilClay' }
																					if (valueSoilDesc.stratum_type === 'SILT') { stratum_type_css = 'soilSilt' }
																					if (valueSoilDesc.stratum_type === 'PEAT') { stratum_type_css = 'soilPeat' }
																					if (valueSoilDesc.stratum_type === 'CHALK') { stratum_type_css = 'soilChalk' }
																					if (valueSoilDesc.stratum_type === 'MADEGROUND') { stratum_type_css = 'soilMadeground' }
																					if (valueSoilDesc.stratum_type === 'LIMESTONE') { stratum_type_css = 'soilLimestone' }
																					if (valueSoilDesc.stratum_type === 'DOLOMITE') { stratum_type_css = 'soilRock' }
																					if (valueSoilDesc.stratum_type === 'SANDSTONE') { stratum_type_css = 'soilSandstone' }
																					if (valueSoilDesc.stratum_type === 'SILTSTONE') { stratum_type_css = 'soilSiltstone' }
																					if (valueSoilDesc.stratum_type === 'GYPSUM') { stratum_type_css = 'soilGypsum' }
																					if (valueSoilDesc.stratum_type === 'MUDSTONE') { stratum_type_css = 'soilMudstone' }
																					if (valueSoilDesc.stratum_type === 'CHERT') { stratum_type_css = 'soilRock' }
																					if (valueSoilDesc.stratum_type === 'SHALE') { stratum_type_css = 'soilShale' }
																					if (valueSoilDesc.stratum_type === 'GRANITE') { stratum_type_css = 'soilRock' }
																					if (valueSoilDesc.stratum_type === 'DIORITE') { stratum_type_css = 'soilRock' }
																					if (valueSoilDesc.stratum_type === 'RHYOLITE') { stratum_type_css = 'soilRock' }
																					if (valueSoilDesc.stratum_type === 'ANDERSITE') { stratum_type_css = 'soilRock' }
																					if (valueSoilDesc.stratum_type === 'BASALT') { stratum_type_css = 'soilRock' }
																					if (valueSoilDesc.stratum_type === 'OBSIDIAN') { stratum_type_css = 'soilRock' }
																					if (valueSoilDesc.stratum_type === 'MARBLE') { stratum_type_css = 'soilRock' }
																					if (valueSoilDesc.stratum_type === 'QUARTZITE') { stratum_type_css = 'soilRock' }
																					if (valueSoilDesc.stratum_type === 'SLATE') { stratum_type_css = 'soilRock' }
																					if (valueSoilDesc.stratum_type === 'COAL') { stratum_type_css = 'soilCoal' }

																					return (
																						<React.Fragment key={index}>
																							<tr className="hidden invisible">
																								<td>{valueSoilDesc.stratum_type}</td>
																							</tr>
																							<tr>
																								<td className="soilDepth">{valueSoilDesc.depth_to_top}</td>
																								<td className="soilDepth">{valueSoilDesc.depth_to_base}</td>
																								<td className="soilDescription">{valueSoilDesc.stratum_description}</td>
																								<td className={`soilLegend ${stratum_type_css}`}></td>
																							</tr>
																						</React.Fragment>
																					)
																				})}
																			</table>
																		</td>
																	</tr>
																	{/* ////////////////////////////////////////////////////// */}
																</tbody>
															</table>
														</div>
														{/* END OF SOIL DESCRIPTIONS */}
														{/* DETAIL DESCRIPTIONS */}
														<div className="detailSoilDescriptionHolder border-b border-l border-grey-mid">
															{/* ////////////////////////////////////////////////////// */}
															<table className="w-full text-center noTableBorder">
																<tbody>
																	<tr>
																		<td className="tableRow soilDepth bg-sptTB">From:</td>
																		<td className="tableRow soilDepth bg-sptTB">To:</td>
																		<td className="tableRow soilDetailDescription bg-grey-mid sectionTitle text-left">Detail Description</td>
																	</tr>
																	{/* ////////////////////////////////////////////////////// */}
																	{DetailDescriptionData?.filter((action: any) => {
																		return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
																	}).map((valueDetailDescription: any, index: number) => {
																		return (
																			<React.Fragment key={index}>
																				<tr className="border-b border-grey-mid">
																					<td className={`tableRow soilDepth`}>{valueDetailDescription.depth_from}</td>
																					<td className={`tableRow soilDepth`}>{valueDetailDescription.depth_to}</td>
																					<td className={`tableRow soilDetailDescription`}>{valueDetailDescription.detail_description}</td>
																				</tr>
																			</React.Fragment>
																		)
																	})}
																	{/* ////////////////////////////////////////////////////// */}
																</tbody>
															</table>
														</div>
														{/* END OF DETAIL DESCRIPTIONS */}
														<table className="trialpitLeftBlock align-top h-full">
															<tbody className="align-top">
																<tr className="align-top">
																	<td className="trialpitLeftBlock_Left align-top">
																		{/* WEATHER */}
																		<div className="weatherHolder_TP border-b border-l border-grey-mid align-top">
																			<table className="w-full h-full text-center align-top noTableBorder">
																				<tbody className="align-top">
																					<tr className="align-top">
																						<td className="tableRow weatherTitle_TP bg-sptTB sectionTitle pt-1.5 align-top">Weather:</td>
																						<td className="tableRow weatherInput_TP">
																							<div>
																								<i className={"text-xl m-0 pt-0 w-6 " + weatherIcon}></i>
																							</div>
																							<div className="-mt-2">
																								{selectedWeather}
																							</div>
																						</td>
																					</tr>
																				</tbody>
																			</table>
																		</div>
																		{/* END OF WEATHER */}
																		{/* GROUNDWATER */}
																		<div className="groundwaterHolder_TP border-r border-l border-grey-mid">
																			<table className="groundwaterTable_TP noTableBorder h-full">
																				<tbody>
																					<tr>
																						<td colSpan={2} className="tableRow bg-grey-mid sectionTitle">Groundwater</td>
																					</tr>
																					<tr>
																						<td>
																							<div className="flex flex-row">
																								<div className="w-7/12">
																									<table>
																										<tr>
																											<td className="tableRow bg-sptTB groundwaterTitle_TP">Time Struck:</td>
																										</tr>
																										<tr>
																											<td className="tableRow bg-sptTB groundwaterTitle_TP">Depth Struck (m):</td>
																										</tr>
																										<tr>
																											<td className="tableRow bg-sptTB groundwaterTitle_TP">Inflow Rate:</td>
																										</tr>
																										<tr>
																											<td className="tableRow bg-sptTB groundwaterTitle_TP">Depth after 5 mins:</td>
																										</tr>
																										<tr>
																											<td className="tableRow bg-sptTB groundwaterTitle_TP">Depth after 10 mins:</td>
																										</tr>
																										<tr>
																											<td className="tableRow bg-sptTB groundwaterTitle_TP">Depth after 15 mins:</td>
																										</tr>
																										<tr>
																											<td className="tableRow bg-sptTB groundwaterTitle_TP">Depth after 20 mins:</td>
																										</tr>
																									</table>
																								</div>
																								<div className="w-5/12">
																									{GroundWaterTestsData?.filter((action: any) => {
																										return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
																									}).map((valueGroundwater: any, index: number) => {

																										// Time
																										const formattedTime = valueGroundwater.time_of_strike.split(' ')[1].substring(0, 5);

																										return (
																											<React.Fragment key={index}>
																												<table className="w-full">
																													<tr>
																														<td className="tableRow w-full">{formattedTime}</td>
																													</tr>
																													<tr>
																														<td className="tableRow w-full">{valueGroundwater.depth_to_water}</td>
																													</tr>
																													<tr>
																														<td className="tableRow w-full">{valueGroundwater.rate_of_flow}</td>
																													</tr>
																													<tr>
																														<td className="tableRow w-full">{valueGroundwater.depth_after_5}</td>
																													</tr>
																													<tr>
																														<td className="tableRow w-full">{valueGroundwater.depth_after_10}</td>
																													</tr>
																													<tr>
																														<td className="tableRow w-full">{valueGroundwater.depth_after_15}</td>
																													</tr>
																													<tr>
																														<td className="tableRow w-full">{valueGroundwater.depth_after_20}</td>
																													</tr>
																												</table>
																											</React.Fragment>
																										)
																									})}
																								</div>
																							</div>
																						</td>
																					</tr>
																				</tbody>
																			</table>
																		</div>
																		{/* END OF GROUNDWATER */}
																	</td>
																	<td className="trialpitLeftBlock_Right border-t border-grey-mid align-top">
																		{/* PRELIMINARY INFO */}
																		<div className="trialpitPrelimInfoHolder">
																			<table className="trialpitPrelimInfoTable noTableBorder">
																				<tbody>
																					<tr>
																						<td colSpan={2} className="tableRow bg-grey-mid sectionTitle">Preliminary Information</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB trialpitPrelimInfoTitle">Pit set out by:</td>
																						<td className="tableRow trialpitPrelimInfoInput_TP"></td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB trialpitPrelimInfoTitle">CAT Sweep:</td>
																						<td className="tableRow trialpitPrelimInfoInput_TP"></td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB trialpitPrelimInfoTitle">Excavation Method:</td>
																						<td className="tableRow trialpitPrelimInfoInput"></td>
																					</tr>
																				</tbody>
																			</table>
																		</div>
																		{/* END OF PRELIMINARY INFO */}
																		{/* TRIALPIT DETAILS */}
																		<div className="trialpitDetailsHolder">
																			<table className="trialpitDetailsTable noTableBorder">
																				<tbody>
																					<tr>
																						<td colSpan={2} className="tableRow bg-grey-mid sectionTitle">Trialpit Information</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB trialpitDetailsTitle">Dimensions (m):</td>
																						<td className="tableRow trialpitDetailsInput">L: &nbsp; &nbsp; W: &nbsp; &nbsp; D:</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB trialpitDetailsTitle">Continued/complete at (m):</td>
																						<td className="tableRow trialpitDetailsInput"></td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB trialpitDetailsTitle">Pit sides:</td>
																						<td className="tableRow trialpitDetailsInput">Stable / Unstable</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB trialpitDetailsTitle">Slight Instability in/at (m):</td>
																						<td className="tableRow trialpitDetailsInput2">From: &nbsp; &nbsp; To:</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB trialpitDetailsTitle">Collapsed Completely in/at (m):</td>
																						<td className="tableRow trialpitDetailsInput2">From: &nbsp; &nbsp; To:</td>
																					</tr>
																				</tbody>
																			</table>
																		</div>
																		{/* END OF TRIALPIT DETAILS */}
																	</td>
																</tr>
															</tbody>
														</table>
														{/* COMMENTS */}
														<div className="standingTimeHolder w-full border-b border-l border-grey-mid">
															<table className="w-full align-top noTableBorder">
																<tbody>
																	<tr>
																		<td className="tableRow bg-grey-mid sectionTitle">Comments / Standing Time / Dayworks</td>
																	</tr>
																	<tr>
																		<td className="commentsInput">
																			{/* ////////////////////////////////////////////////////// */}
																			{CommentsData?.filter((action: any) => {
																				return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
																			}).map((valueComments: any, index: number) => {

																				let duration_formatted = ''
																				if (valueComments.duration !== null) {
																					duration_formatted = '(' + valueComments.duration + ')'
																				} else {
																					duration_formatted = ''
																				}

																				return (

																					<React.Fragment key={index}>

																						{valueComments.comment_type} - {valueComments.general_remarks} {duration_formatted}.&nbsp;

																					</React.Fragment>
																				)
																			})}
																			{/* ////////////////////////////////////////////////////// */}
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
														{/* END OF COMMENTS */}
														{/* STANDING TIME / DAYWORKS TOTALS */}
														<div className="standingTimeTotalsHolder w-full border-b border-l border-grey-mid">
															<table className="standingTimeTable w-full text-center noTableBorder">
																<tbody>
																	<tr>
																		<td className="tableRow bg-grey-mid sectionTitle standingTimeCategories">Total Standing Time:</td>
																		{/* ////////////////////////////////////////////////////// */}
																		<td className="tableRow sectionTitle standingTimeInputs">{totalStandingTime}</td>
																		{/* ////////////////////////////////////////////////////// */}
																		<td className="tableRow bg-grey-mid sectionTitle standingTimeCategories">Total Dayworks:</td>
																		{/* ////////////////////////////////////////////////////// */}
																		<td className="tableRow sectionTitle standingTimeInputs">{totalDayworks}</td>
																		{/* ////////////////////////////////////////////////////// */}
																	</tr>
																</tbody>
															</table>
														</div>
														{/* END OF STANDING TIME / DAYWORKS TOTALS */}
													</td> {/* END OF LEFT SIDE */}
													<td className="w-2/4 align-top"> {/* RIGHT SIDE */}
														{/* SAMPLES */}
														<div className="">
															<div className="sampTableHolder border-r border-grey-mid">
																<table className="w-full noTableBorder">
																	<tbody>
																		<tr>
																			<td colSpan={12} className="tableRow soilDepth bg-grey-mid sectionTitle">Samples</td>
																		</tr>
																		{/* ROW  */}
																		<tr>
																			<td rowSpan={2} className="tableRowDouble sampType bg-sptTB sectionTitle">Type/#</td>
																			<td colSpan={2} className="tableRow bg-sptTB  sampDepthTitle">Depth (m)</td>
																			<td rowSpan={2} className="tableRowDouble bg-sptTB sampWaterDepth_TP">Water Depth (m)</td>
																			<td rowSpan={2} className="tableRowDouble bg-sptTB sampNotes_TP textCenter">Notes</td>
																		</tr>
																		<tr>
																			<td className="tableRow bg-sptSB sampDepth">From</td>
																			<td className="tableRow bg-sptSB sampDepth">To</td>
																		</tr>
																		{/* ///////////////////////////////////////////////////////////////////////////// */}

																		{SamplesData?.filter((action: any) => {
																			return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
																		}).map((valueSamples: any, index: number) => {

																			return (
																				<React.Fragment key={index}>
																					<tr className="border-b border-grey-mid">
																						<td className={`tableRow sampType font-bold`}>{valueSamples.type}-{valueSamples.type_number}</td>
																						<td className={`tableRow sampDepth`}>{valueSamples.depth_to_top}</td>
																						<td className={`tableRow sampDepth`}>{valueSamples.depth_to_base}</td>
																						<td className={`tableRow sampWaterDepth_TP`}>{valueSamples.water_depth}</td>
																						<td className={`tableRow sampNotes_TP`}>{valueSamples.general_remarks}</td>
																					</tr>
																				</React.Fragment>

																			)
																		})}

																		{/* ///////////////////////////////////////////////////////////////////////////// */}
																	</tbody>
																</table>
															</div>
															{/* END OF SAMPLES */}
															{/* TESTS */}
															<div className="testsTableHolderTP border-r border-grey-mid">
																<table className="noTableBorder w-full text-center align-top">
																	<tbody>
																		<tr>
																			<td colSpan={12} className="tableRow soilDepth bg-grey-mid sectionTitle">Tests</td>
																		</tr>
																		{/* ROW */}
																		<tr>
																			<td className="tableRow bg-sptTB sectionTitle sampType">#</td>
																			<td className="tableRow bg-sptTB ">Depth (m)</td>
																			<td className="tableRow bg-sptTB">Vane Type</td>
																			<td className="tableRow bg-sptTB ">Vane Size</td>
																			<td className="tableRow bg-sptTB">Results</td>
																		</tr>
																		{/* ////////////////////////////////////////////////////// */}
																		{InSituTestsData?.filter((action: any) => {
																			return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
																		}).map((valueInSituTest: any, index: number) => {

																			return (
																				<React.Fragment key={index}>
																					<tr>
																						<td className={`tableRow font-bold`}>{valueInSituTest.type}-#</td>
																						<td className={`tableRow`}>{valueInSituTest.depth_of_test}</td>
																						<td className={`tableRow`}>{valueInSituTest.test_type}</td>
																						<td className={`tableRow`}>{valueInSituTest.size}</td>
																						<td className={`tableRow`}>{valueInSituTest.average}</td>
																					</tr>
																				</React.Fragment>
																			)
																		})}
																		{/* ////////////////////////////////////////////////////// */}
																	</tbody>
																</table>
															</div>
														</div>
														{/* END OF TESTS */}
														{/* BACKFILL */}
														<div className="backfillHolder w-full border-l border-grey-mid">
															<table className="w-full noTableBorder">
																<tbody>
																	<tr>
																		<td className="tableRow bg-grey-mid sectionTitle backfillTitle">Backfill / Standpipe Details</td>
																	</tr>
																	{/* ////////////////////////////////////////////////////// */}
																	{InstallationData?.filter((action: any) => {
																		return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
																	}).map((valueInstall: any, index: number) => {
																		let install_1_text
																		if (valueInstall.pipe_width_1 || valueInstall.pipe_solid_1 || valueInstall.pipe_slotted_1) {
																			if (valueInstall.pipe_width_1) { install_1_text = '#1: ' + valueInstall.pipe_width_1 + 'mm pipe to ' }
																			else { install_1_text = '#1: Pipe to ' }
																			if (!valueInstall.pipe_solid_1 && !valueInstall.pipe_slotted_1) { install_1_text = install_1_text }
																			if (valueInstall.pipe_solid_1 && !valueInstall.pipe_slotted_1) { install_1_text = install_1_text + (valueInstall.pipe_solid_1) + 'm (' + valueInstall.pipe_solid_1 + 'm solid)' }
																			if (!valueInstall.pipe_solid_1 && valueInstall.pipe_slotted_1) { install_1_text = install_1_text + (valueInstall.pipe_slotted_1) + 'm (' + valueInstall.pipe_slotted_1 + 'm slotted)' }
																			if (valueInstall.pipe_solid_1 && valueInstall.pipe_slotted_1) { install_1_text = install_1_text + (+valueInstall.pipe_solid_1 + +valueInstall.pipe_slotted_1) + 'm (' + valueInstall.pipe_solid_1 + 'm solid & ' + valueInstall.pipe_slotted_1 + 'm slotted)' }
																			if (valueInstall.pipe_geosock_1) { install_1_text = install_1_text + ' + geosock' }
																			if (valueInstall.gas_tap_1) { install_1_text = install_1_text + ' & gastap' }
																		} else { install_1_text = '' }
																		// INSTALL #2
																		let install_2_text
																		if (valueInstall.pipe_width_2 || valueInstall.pipe_solid_2 || valueInstall.pipe_slotted_2) {
																			if (valueInstall.pipe_width_2) { install_2_text = '#2: ' + valueInstall.pipe_width_2 + 'mm pipe to ' }
																			else { install_2_text = '#2: Pipe to ' }
																			if (!valueInstall.pipe_solid_2 && !valueInstall.pipe_slotted_2) { install_2_text = install_2_text }
																			if (valueInstall.pipe_solid_2 && !valueInstall.pipe_slotted_2) { install_2_text = install_2_text + (valueInstall.pipe_solid_2) + 'm (' + valueInstall.pipe_solid_2 + 'm solid)' }
																			if (!valueInstall.pipe_solid_2 && valueInstall.pipe_slotted_2) { install_2_text = install_2_text + (valueInstall.pipe_slotted_2) + 'm (' + valueInstall.pipe_slotted_2 + 'm slotted)' }
																			if (valueInstall.pipe_solid_2 && valueInstall.pipe_slotted_2) { install_2_text = install_2_text + (+valueInstall.pipe_solid_2 + +valueInstall.pipe_slotted_2) + 'm (' + valueInstall.pipe_solid_2 + 'm solid & ' + valueInstall.pipe_slotted_2 + 'm slotted)' }
																			if (valueInstall.pipe_geosock_2) { install_2_text = install_2_text + ' + geosock' }
																			if (valueInstall.gas_tap_2) { install_2_text = install_2_text + ' & gastap' }
																		} else { install_2_text = '' }

																		let cover_comment_text = ''
																		if (valueInstall.cover_size) {
																			cover_comment_text = valueInstall.cover_size + ' - ' + valueInstall.cover_type
																		} else {
																			cover_comment_text = valueInstall.cover_type
																		}
																		if (valueInstall.general_remarks) { cover_comment_text = cover_comment_text + ' - ' + valueInstall.general_remarks }


																		return (
																			<React.Fragment key={index}>
																				<tr>
																					<td className="w-full align-top text-left pl-2 pt-1 pb-1">
																						{install_1_text}<br />
																						{install_2_text}<br />
																						{backfillText}<br />
																						{cover_comment_text}<br />
																					</td>
																				</tr>
																			</React.Fragment>
																		)
																	})}
																	{/* ////////////////////////////////////////////////////// */}
																</tbody>
															</table>
														</div>
														{/* END OF BACKFILL */}
														{/* TP TOTALS */}
														<div className="bhTotalsHolder">
															<table className="bhTotalsTable noTableBorder">
																<tbody>
																	<tr>
																		<td colSpan={10} className="tableRow bg-grey-mid  sectionTitle">Completed Totals for Trialpit</td>
																	</tr>
																	<tr className="border-grey-mid border-r">
																		<td className="tableRow bg-sptTB sectionTitle bhTotalsDepth">Depth (m)</td>
																		<td className="tableRow bg-sptTB sectionTitle bhTotalsSamples">D</td>
																		<td className="tableRow bg-sptTB sectionTitle bhTotalsSamples">B</td>
																		<td className="tableRow bg-sptTB sectionTitle bhTotalsSamples">ES</td>
																		<td className="tableRow bg-sptTB sectionTitle bhTotalsSamples">WS</td>
																		<td className="tableRow bg-sptTB sectionTitle bhTotalsSamples"></td>
																		<td className="tableRow bg-sptTB sectionTitle bhTotalsSPT"></td>
																		<td className="tableRow bg-sptTB sectionTitle bhTotalsSamples"></td>
																		<td className="tableRow bg-sptTB sectionTitle bhTotalsSamples">SV</td>
																		<td className="tableRow bg-sptTB sectionTitle "></td>
																	</tr>
																	{/* ////////////////////////////////////////////////////// */}
																	<tr className="border-grey-mid border-r">
																		<td className="tableRow sectionTitle bhTotalsDepth">{borehole_depth ? borehole_depth : null}</td>
																		<td className="tableRow sectionTitle bhTotalsSamples">{numberOfSamplesD ? numberOfSamplesD : null}</td>
																		<td className="tableRow sectionTitle bhTotalsSamples">{numberOfSamplesB ? numberOfSamplesB : null}</td>
																		<td className="tableRow sectionTitle bhTotalsSamples">{numberOfSamplesES ? numberOfSamplesES : null}</td>
																		<td className="tableRow sectionTitle bhTotalsSamples">{numberOfSamplesWS ? numberOfSamplesWS : null}</td>
																		<td className="tableRow sectionTitle bhTotalsSamples">&nbsp;</td>
																		<td className="tableRow sectionTitle bhTotalsSPT">&nbsp;</td>
																		<td className="tableRow sectionTitle bhTotalsSamples">&nbsp;</td>
																		<td className="tableRow sectionTitle bhTotalsSamples">{numberOfShearVane ? numberOfShearVane : null}</td>
																		<td className="tableRow sectionTitle ">&nbsp;</td>
																	</tr>
																	{/* ////////////////////////////////////////////////////// */}
																</tbody>
															</table>
														</div>
														{/* END OF TP TOTALS */}
														{/* PERSONNEL */}
														<div className="w-full border-b border-r border-grey-mid">
															<table className="w-full noTableBorder">
																<tbody>
																	<tr>
																		<td colSpan={2} className="tableRow bg-grey-mid sectionTitle">Personnel Details</td>
																	</tr>
																	{/* ////////////////////////////////////////////////////// */}
																	<tr>
																		<td className="tableRow bg-sptTB text-right pr-1 w-1/5">Operative:</td>
																		<td className="tableRow text-left pl-1 w-4/5">{leadDriller}</td>
																	</tr>
																	<tr>
																		<td className="tableRow bg-sptTB text-right pr-1 w-1/5">CSCS:</td>
																		<td className="tableRow text-left pl-1 w-4/5">{leadDrillerCSCS}</td>
																	</tr>
																	<tr>
																		<td className="tableRow bg-sptTB text-right pr-1 w-1/5">2nd Operative: </td>
																		<td className="tableRow text-left pl-1 w-4/5">{secondMan}</td>
																	</tr>
																	<tr>
																		<td className="tableRow bg-sptTB text-right pr-1 w-1/5">CSCS:</td>
																		<td className="tableRow text-left pl-1 w-4/5">{secondManCSCS}</td>
																	</tr>
																	<tr>
																		<td className="tableRow bg-sptTB text-right pr-1 w-1/5">Site Engineer:</td>
																		<td className="tableRow text-left pl-1 w-4/5">{engineerName}</td>
																	</tr>
																	<tr>
																		<td className="tableRow bg-sptTB text-right pr-1 w-1/5"></td>
																		<td className="tableRow text-left pl-1 w-4/5">&nbsp;</td>
																	</tr>
																	{/* ////////////////////////////////////////////////////// */}
																</tbody>
															</table>
														</div>
														{/* END OF PERSONNEL */}
														{/* CLIENT INFO FOOTER */}
														<div className="w-full border-b border-r border-grey-mid">
															<table className="clientInfoTable noTableBorder">
																<tbody>
																	<tr>
																		{/* ////////////////////////////////////////////////////// */}
																		<td className="tableRow clientInfoContact">
																			{companyName}<br />
																			{companyAddress}<br />
																			Telephone: {companyTelephone}<br />
																			Email: {companyEmail}
																		</td>
																		{/* ////////////////////////////////////////////////////// */}
																		<td className="tableRow clientInfoDrillitLogs"></td>
																	</tr>
																</tbody>
															</table>
														</div>
														{/* END OF CLIENT INFO FOOTER */}
													</td>
												</tr>
											</tbody>
											{/* END OF tableHolderTable */}
										</table>

										{/* END OF mainContainer */}
									</div>

								</div>

							</div>

						</div>

					</>

				}














				{/* //..........................................................................
				//.LLLL..........AAAAA.....BBBBBBBBBB...EEEEEEEEEEE.LLLL........SSSSSSS.....
				//.LLLL..........AAAAA.....BBBBBBBBBBB..EEEEEEEEEEE.LLLL.......SSSSSSSSS....
				//.LLLL.........AAAAAA.....BBBBBBBBBBB..EEEEEEEEEEE.LLLL.......SSSSSSSSSS...
				//.LLLL.........AAAAAAA....BBBB...BBBB..EEEE........LLLL......LSSSS..SSSS...
				//.LLLL........AAAAAAAA....BBBB...BBBB..EEEE........LLLL......LSSSS.........
				//.LLLL........AAAAAAAA....BBBBBBBBBBB..EEEEEEEEEE..LLLL.......SSSSSSS......
				//.LLLL........AAAA.AAAA...BBBBBBBBBB...EEEEEEEEEE..LLLL........SSSSSSSSS...
				//.LLLL.......AAAAAAAAAA...BBBBBBBBBBB..EEEEEEEEEE..LLLL..........SSSSSSS...
				//.LLLL.......AAAAAAAAAAA..BBBB....BBBB.EEEE........LLLL.............SSSSS..
				//.LLLL.......AAAAAAAAAAA..BBBB....BBBB.EEEE........LLLL......LSSS....SSSS..
				//.LLLLLLLLLLLAAA....AAAA..BBBBBBBBBBBB.EEEEEEEEEEE.LLLLLLLLLLLSSSSSSSSSSS..
				//.LLLLLLLLLLLAAA.....AAAA.BBBBBBBBBBB..EEEEEEEEEEE.LLLLLLLLLL.SSSSSSSSSS...
				//.LLLLLLLLLLLAAA.....AAAA.BBBBBBBBBB...EEEEEEEEEEE.LLLLLLLLLL..SSSSSSSS....
				//.......................................................................... */}

				{labelsVisible && <>

					{/* LABELS */}

					<div className="flex flex-col rounded-lg border border-orange bg-modal-bg px-1 sm:px-2 md:px-2 modalshadow previewLogsheet pb-20 z-10">

						<div className="flex items-center border-b border-grey-dark py-2">

							<div className="flex-1 flex items-left">

							</div>

							<div className="flex-1 flex justify-center text-lg sm:text-xl font-bold text-text leading-6 text-center mx-auto">
								Labels
							</div>

							<div className="flex-1 flex justify-center">
								<span className="ml-auto">
									<button className="flex items-center justify-center w-12 h-12 text-4xl bold text-soil-grey bg-white border border-orange rounded" onClick={labelsClick} ><img src={closeIcon} width="32" alt="" /></button>
								</span>
							</div>
						</div>


						{/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
						// SAMPLES
						/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}


						<div className="text-lg w-full text-center pt-2">
							Samples
						</div>

						<ul className="flex flex-wrap p-0 mb-2 list-none justify-center text-center"> {/* START LABELS HOLDER */}


							{SamplesData?.filter((action: any) => {
								return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
							}).map((valueSamples: any, index: number) => {

								// let printSample = valueSamples.type
								let depthFrom = ''
								if (valueSamples.depth_to_top === '0' || valueSamples.depth_to_top === 0 ||
									valueSamples.depth_to_top === '0.00' || valueSamples.depth_to_top === 0.00
								) {
									depthFrom = 'GL'
								} else {
									depthFrom = valueSamples.depth_to_top
								}

								let depthTo = ''
								if (valueSamples.depth_to_base === '' || valueSamples.depth_to_base === null) {
									depthTo = ''
								} else {
									depthTo = '-' + valueSamples.depth_to_base
								}

								return (
									<React.Fragment key={index}>

										<li className="p-1 z-10">
											<div onClick={() => {
												setLabelSample(valueSamples.type + '-' + valueSamples.type_number)
												setLabelDepth(depthFrom + depthTo)
												setShowPrintLabel(true)
											}}>
												<Label
													borehole={borehole_name}
													sample={valueSamples.type + '-' + valueSamples.type_number}
													depth={depthFrom + depthTo}
													size='smallLabel'
													user={leadDriller}
												/>
											</div>
										</li>

									</React.Fragment>
								)
							})}

							{/* ////////////////////////////////////////////////////// */}

						</ul> {/* END LABELS HOLDER */}

						{/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
						// PRINT ALL SAMPLE LABELS
						/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

						<div className="pt-1" style={{ height: '1px', overflow: 'hidden' }}>


							{/* START LABELS HOLDER */}

							<ul ref={printAllSampleLabelsArea}
								className="flex flex-wrap p-0 mb-2 list-none justify-center text-center ">

								{SamplesData?.filter((action: any) => {
									return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
								}).map((valueSamples: any, index: number) => {

									// let printSample = valueSamples.type
									let depthFrom = ''
									if (valueSamples.depth_to_top === '0' || valueSamples.depth_to_top === 0) {
										depthFrom = 'GL'
									} else {
										depthFrom = valueSamples.depth_to_top
									}

									let depthTo = ''
									if (valueSamples.depth_to_base === '' || valueSamples.depth_to_base === null) {
										depthTo = ''
									} else {
										depthTo = '-' + valueSamples.depth_to_base
									}

									return (
										<>

											<li className="p-1 z-10">
												<LabelBatchPrint
													borehole={borehole_name}
													sample={valueSamples.type + '-' + valueSamples.type_number}
													depth={depthFrom + depthTo}
													user={leadDriller}
												/>
											</li>

										</>
									)

								})}

							</ul> {/* END LABELS HOLDER */}

						</div>

						{showPrintAllSampleLabelsButton &&
							<>
								<div className="mt-2">
									<ReactToPrint  //FUNCTION CODE
										trigger={() => <Button theme="primary" size="lg" className="btn-lg m-auto " >Print All Sample Labels</Button>}
										content={() => printAllSampleLabelsArea.current}
									/>
								</div>
							</>
						}

					</div>





				</>}




			</Container>

			<br /><br />

			<SyncStatus synced />

		</>

	)
}

export default FunctionTemplate