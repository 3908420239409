import { get, set, del } from 'idb-keyval';

interface ActionCount {
    guid: string;
    hole_guid: string;
    hole_name: string;
    count_D: number;
    count_B: number;
    count_ES: number;
    count_WS: number;
    count_SPT: number;
    count_CPT: number;
    count_SPTCPT: number;
    count_U: number;
    count_PS: number;
    count_SV: number;
    total_depth: number;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string | null;
}

export const updateActionCounts = (
    hole_guid: string, 
    hole_type: string,
    hole_name: string,
    count_D: number,
    count_B: number,
    count_ES: number,
    count_WS: number,
    count_SPT: number,
    count_CPT: number,
    count_SPTCPT: number,
    count_U: number,
    count_PS: number,
    count_SV: number,
    total_depth: number,
    ) => {
    return new Promise((resolve, reject) => {
        const endpoint = `${process.env.REACT_APP_API_ADDR}action_counts/guid/${hole_guid}`
        console.log('action_counts update endpoint:', endpoint)
        // make a date string
        const updateData = {
            'hole_type': hole_type,
            'hole_name': hole_name,
            'count_D': count_D,
            'count_B': count_B,
            'count_ES': count_ES,
            'count_WS': count_WS,
            'count_SPT': count_SPT,
            'count_CPT': count_CPT,
            'count_SPTCPT': count_SPTCPT,
            'count_U': count_U,
            'count_PS': count_PS,
            'count_SV': count_SV,
            'total_depth': total_depth,
        }
        const updateIDB = {
            'guid': hole_guid,
            'hole_type': hole_type,
            'hole_name': hole_name,
            'count_D': count_D,
            'count_B': count_B,
            'count_ES': count_ES,
            'count_WS': count_WS,
            'count_SPT': count_SPT,
            'count_CPT': count_CPT,
            'count_SPTCPT': count_SPTCPT,
            'count_U': count_U,
            'count_PS': count_PS,
            'count_SV': count_SV,
            'total_depth': total_depth,
        }

        console.log('updateData:', updateData)

        makeRequest(endpoint, JSON.stringify(updateData), 'PUT')
            .then(() => {
                // Get the action counts from IndexedDB
                get('drillit-action_counts').then(actionCountsIDB => {
                    // Find the index of the record with the correct GUID
                    const indexToUpdate = actionCountsIDB.findIndex((record: ActionCount) => record.guid === hole_guid);
                    console.log('COUNTS indexToUpdate:', indexToUpdate)

                // If the record exists in the array, update it
                if (indexToUpdate !== -1) {
                    const existingRecord = actionCountsIDB[indexToUpdate];
                    const updateIDB = {
                        ...updateData,
                        'guid': hole_guid,
                        'id': existingRecord.id, // Retain the existing ID
                    };
                    console.log('updateIDB values:', updateIDB)
                    actionCountsIDB[indexToUpdate] = updateIDB; // Replace the old record with the updated one
                } else {
                    // If the record does not exist, create it
                    const newID = actionCountsIDB.length > 0 ? actionCountsIDB[actionCountsIDB.length - 1].id + 1 : 1;
                    const updateIDB = {
                        ...updateData,
                        'guid': hole_guid,
                        'id': newID, // Assign a new ID if the record is new
                    };
                    actionCountsIDB.push(updateIDB);
                }

                    // // Store the updated array back to IndexedDB
                    // set('drillit-action_counts', actionCountsIDB);

                // Cleanup: Remove any records with undefined guid
                const cleanedActionCountsIDB = actionCountsIDB.filter((record: ActionCount) => record.guid !== undefined);
                console.log('cleanedActionCountsIDB:', cleanedActionCountsIDB);

                // Store the updated array back to IndexedDB
                set('drillit-action_counts', cleanedActionCountsIDB);
                });
            })
            .catch(error => {
                console.error("An error occurred while updating action counts:", error);
                // Handle the error, if needed
            });

        return resolve(true)
    })
}

const makeRequest = (endpoint: string, data: string, postOrPut: string) => {
    const token = localStorage.getItem('drillit-token')

    return fetch(endpoint, {
        method: postOrPut,
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: data
    })
        //return the status code here
        .then(response => response)
        .catch(err => err)
}
