import React from 'react'

import Header from '../../layout/Header'

import Modal from '../../components/Modal'
import Button from '../../components/form/Button'
import InputGroup from '../../components/form/InputGroup'

import { v4 as uuidv4} from 'uuid';
import {useSetIndexedDBValues} from "../../helpers/addToIDB";
import {Redirect} from "react-router-dom";

interface Props {
	toggleSidebar: () => void,
	router: any,
}

interface State {
	id_ref: string,
	rod_type: string,
	calibration_date: string,
	energy_ratio: string,
	guid: string,
	company_guid: string,
	project_guid: string,
	redirect: boolean,
}

class RigsSptCreate extends React.Component<Props, State> {
	constructor(props: any) {
		super(props)

		this.state = {
			id_ref: '',
			rod_type: '1 1/2 Whitworth',
			calibration_date: '',
			energy_ratio: '',
			guid: uuidv4(),
			company_guid: this.props.router.match.params.company,
			project_guid: this.props.router.match.params.project,
			redirect: false,
		}
	}

	save = (event: any) => {
		event.preventDefault()
		let saveToIDB = useSetIndexedDBValues
		saveToIDB(this.state, 'spt-hammers', true)
			.then(() => setTimeout(() => this.setState({redirect: true}), 1000))

	}



	handleCancelClick = () => {
		window.history.back();
	};

	render() {
		const { redirect } = this.state

		if (redirect) {
			return <Redirect to={`/rigs/${this.state.company_guid}/${this.state.project_guid}`}/>
		}

		return (
			<>
				<Header toggleSidebar={this.props.toggleSidebar} />

				<Modal title="Add SPT" toggleModal={() => this.props.router.history.goBack()}>
				<div className="w-full lg:w-3/5 pl-2 pr-2 mx-auto">
				<form onSubmit={this.save}>
                        <div className="border border-grey-mid rounded px-2 pt-2 pb-1 lg:pb-8 bg-sptTB mb-4">
								<InputGroup label="SPT ID" 
								value={this.state.id_ref} onChange={(value) => this.setState({id_ref: value})} 
								className="mt-8 md:text-lg pt-2" 
								/>

								<InputGroup label="Rod Type" 
								value={this.state.rod_type} 
								onChange={(value) => this.setState({rod_type: value})}   
								className="md:text-lg pt-2" 
								/>

								<InputGroup type="date" label="Calibration Date" 
								value={this.state.calibration_date} 
								onChange={(value) => this.setState({calibration_date: value})}  
								className="md:text-lg pt-2" 
								/>

								<InputGroup type="number" label="Energy Ratio" value={this.state.energy_ratio} 
								onChange={(value) => this.setState({energy_ratio: value})} 
								className="md:text-lg pt-2 mb-10" />
						</div>

						<div className="flex justify-between">
                            <div></div>
                            <div className="flex">
                                <Button onClick={this.handleCancelClick} theme="secondary" className="mr-2 md:mr-4 h-12 w-20 md:w-32 text-sm md:text-base">Cancel</Button>
                                <Button type="submit" theme="primary" className="mr-1 h-12 w-32 md:w-40 text-base">Save</Button>
                            </div>
                        </div>

						</form>
					</div>
				</Modal>
			</>
		)
	}
}

export default RigsSptCreate
