import {useEffect, useState} from "react";
import User from "../types/User";
import {get} from "idb-keyval";


export function useGetLoggedInUser() {
    const [loggedInUser, setLoggedInUser] = useState<{ user: User } | undefined>(undefined)

    useEffect(() => {
        const getIDB: any = async () => {
            const response = await get('drillit-user')
            setLoggedInUser(response)
        }
        getIDB()
    }, [])

    return loggedInUser
}
