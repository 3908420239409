import React, { FunctionComponent, useState } from 'react'

import WorkflowSteps from '../../WorkflowSteps'
import Modal from '../../Modal'
import Button from '../../form/Button'
import NumberInput from '../../NumberInput'
// import InputGroup from '../../form/InputGroup'
import TextArea from '../../form/TextArea'
import ButtonOptions from '../../form/ButtonOptions'
import MultiNumberInput from "../../MultiNumberInput";
import {v4 as uuidv4} from 'uuid';
import stepStatus from '../../../helpers/stepStatus'
import warningIcon from '../../../assets/icons/warning.png'

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: string,
	defaultValues?: any,
	update?: any,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => {},
	onComplete: () => {},
	borehole: '',
	defaultValues: {
		guid: '',
        
        
        length_of_pit: '',
        depth_of_pit: '',
        width_of_pit: '',
        height_of_standpipe: '',
        depth_of_standpipe: '',
        // depth_of_borehole: '',
        // diameter_of_borehole: '',
        // depth_of_casing: '',
        // height_of_casing: '',
        water_level_before_test: '',
        gravel_backfill: '',
        soil_type: '',
        general_remarks: '',
	},
    selectedDate: '',
}

const HeadTestDetails: FunctionComponent<Props> = ({ onClose, update, onComplete, borehole, defaultValues, setShowHeadTestDetailEdit, selectedDate, ...rest }) => {
	const [currentStep, setCurrentStep] = useState(1)

	// const [guid, setGuid] = useState(defaultValues.guid)
	const guid = defaultValues.guid

	console.log('update:', update)
	console.log('defaultValues:', defaultValues)
    
	const [pitLength, setPitLength] = useState(defaultValues.length_of_pit)
	const [pitDepth, setPitDepth] = useState(defaultValues.depth_of_pit)
	const [pitWidth, setPitWidth] = useState(defaultValues.width_of_pit)
	const [standpipeHeight, setStandpipeHeight] = useState(defaultValues.height_of_standpipe)
	const [standpipeDepth, setStandpipeDepth] = useState(defaultValues.depth_of_standpipe)
    
	// const [boreholeDepth, setBoreholeDepth] = useState(defaultValues.depth_of_borehole)
	// const [boreholeDiameter, setBoreholeDiameter] = useState(defaultValues.diameter_of_borehole)
	// const [casingDepth, setCasingDepth] = useState(defaultValues.depth_of_casing)
	// const [casingHeight, setcasingHeight] = useState(defaultValues.height_of_casing)
	const [waterLevel, setWaterLevel] = useState(defaultValues.water_level_before_test)
	const [gravel, setGravel] = useState(defaultValues.gravel_backfill)
	// console.log('gravel:', gravel)
	const [soilType, setSoilType] = useState(defaultValues.soil_type)
	const [comment, setComment] = useState(defaultValues.general_remarks)
	
	const [saveButtonText, setButtonText] = useState('Continue')
	const [saveButtonColor, setButtonColor] = useState('primary')
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	const [time_of_action, setTimeOfAction] = useState(defaultValues.time_of_action)

    const isoDate = selectedDate ? `${new Date(selectedDate).toISOString().split('T')[0]} 09:00:00` : new Date().toISOString().split('.')[0]

	console.log('**************************Inside SOAKAWAY DETAILS Panel*****************************')
	console.log('guid: ', defaultValues.guid, guid)
	console.log('pitLength: ', defaultValues.pitLength, pitLength, typeof pitLength)
	console.log('pitDepth: ', defaultValues.pitDepth, pitDepth, typeof pitDepth)
	console.log('pitWidth: ', defaultValues.pitWidth, pitWidth, typeof pitWidth)
	console.log('general_remarks: ', defaultValues.general_remarks, comment, typeof comment)
	console.log('standpipeHeight: ', defaultValues.standpipeHeight, standpipeHeight, typeof standpipeHeight)
	console.log('standpipeDepth: ', defaultValues.standpipeDepth, standpipeDepth, typeof standpipeDepth)
	console.log('soil_type: ', defaultValues.soil_type, soilType, typeof soilType)
	console.log('************************************************************************************')

	const DeleteButton = () => {
		if (!update) return null;
	
		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	// const deleteRecordConfirm = () => {
	// 	setDeleteConfirmOpen(false)
	// 	// onComplete()
	// }

	const previous = () => {
		if(currentStep > 1)
			setCurrentStep(currentStep - 1)
			setButtonText('Continue')
			setButtonColor('primary')
	}

	const next = () => {
		if(currentStep < 5) {
			setCurrentStep(currentStep + 1)
			if(currentStep === 4) {
				setButtonText('Save') 
				setButtonColor('success')
			}else{
				setButtonText('Continue')
				setButtonColor('primary')
			}
			return
		}

		if (!pitLength || !pitDepth || !pitWidth || !gravel || !soilType) {
			//do nothing
		} else {

			onComplete({
				guid: guid ? guid : uuidv4(),

				length_of_pit: parseFloat(pitLength).toFixed(2),
				depth_of_pit: parseFloat(pitDepth).toFixed(2),
				width_of_pit: parseFloat(pitWidth).toFixed(2),
				height_of_standpipe: isNaN(parseFloat(standpipeHeight)) ? '0.00' : parseFloat(standpipeHeight).toFixed(2),
				depth_of_standpipe: isNaN(parseFloat(standpipeDepth)) ? '0.00' : parseFloat(standpipeDepth).toFixed(2),

				// depth_of_borehole: parseFloat(boreholeDepth).toFixed(2),
				// diameter_of_borehole: boreholeDiameter, // parseFloat(boreholeDiameter).toFixed(2),
				// depth_of_casing: isNaN(parseFloat(casingDepth)) ? '0.00' : parseFloat(casingDepth).toFixed(2),
				// height_of_casing: isNaN(parseFloat(casingHeight)) ? '0.00' : parseFloat(casingHeight).toFixed(2),
				// water_level_before_test: isNaN(parseFloat(waterLevel)) ? '' : parseFloat(waterLevel).toFixed(2),
				water_level_before_test: waterLevel === 'dry'
				? 'dry'
				: waterLevel === ''
					? 'dry'
					: isNaN(parseFloat(waterLevel))
						? ''
						: parseFloat(waterLevel).toFixed(2),
				gravel_backfill: gravel || "No",
				soil_type: soilType,
				general_remarks: comment !== null ? comment : '',
				time_of_action: time_of_action ? time_of_action : isoDate,
				update: update ? true : false,
				runs: update ? runsDataOnEdit : runsDataOnCreate,
			})
		}
	}

	const runsDataOnCreate = [
		{
			run: 1,
			start_time: "",
			data_type: "",
			status: "inactive",
			results: {
				"00:00:00": null
				// "00:00:00": 1,
				// "00:00:01": 1.5
				// "00:00:02": 2,
				// "00:00:04": 3,
			},
		},
		{
			run: 2,
			start_time: "",
			data_type: "",
			status: "inactive",
			results: {
				"00:00:00": null
				// "00:00:00": 4, // "00:00:00": null
				// "00:00:02": 5,
				// "00:00:07": 6,
			},
		},
		{
			run: 3,
			start_time: "",
			data_type: "",
			status: "inactive",
			results: {
				"00:00:00": null
				// "00:00:05": 7,
				// "00:00:07": 8,
				// "00:00:09": 9,
			},
		},
	]

	const runsDataOnEdit = defaultValues.runs

	console.log('runsDataOnCreate:', runsDataOnCreate)
	console.log('runsDataOnEdit:', runsDataOnEdit)

	// console.log('gravel:', gravel)

	let requiredLengthText = ''
	let requiredDepthText = ''
	let requiredWidthText = ''
	let gravelText = ''
	let soilTypeText = ''

	if (!pitLength) {
		requiredLengthText = "'Pit Length' - "
	}

	if (!pitDepth) {
		requiredDepthText = "'Pit Depth' - "
	}

	if (!pitWidth) {
		requiredWidthText = "'Pit Width' - "
	}

	if (!gravel) {
		gravelText = "'Gravel Backfill' - "
	}

	if (!soilType) {
		soilTypeText = "'Soil Type'"
	}

	const MissingRequiredField = () => {
		if (currentStep === 5 && (!pitLength || !pitDepth || !pitWidth || !gravel || !soilType)) return (
			<div className="text-center bg-sptSB border border-red font-bold rounded mb-2 py-1 text-xs md:text-sm lg:text-base">
				<img src={warningIcon} alt="info" width="20" className="inline-block mr-1 pb-1" />
				Required field: {requiredLengthText} {requiredDepthText} {requiredWidthText} {gravelText} {soilTypeText}
			</div>
		)
	
		return null
	}

	return (
		<Modal title="Soakaway Pit Details" subtitle={borehole} toggleModal={onClose}>
			<div className="flex flex-col flex-grow px-1 sm:px-2 md:px-10">
				<WorkflowSteps steps={[
					{
						label: 'Details',
						status: stepStatus(1, currentStep),
						onClick: () => {
							setCurrentStep(1)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Standpipe',
						status: stepStatus(2, currentStep),
						onClick: () => {
							setCurrentStep(2)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Water Level',
						status: stepStatus(3, currentStep),
						onClick: () => {
							setCurrentStep(3)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Gravel',
						status: stepStatus(4, currentStep),
						onClick: () => {
							setCurrentStep(4)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Soil Type',
						status: stepStatus(5, currentStep),
						onClick: () => {
							setCurrentStep(5)
							setButtonText('Save')
							setButtonColor('success')
						}
					},
				]} className="mb-2" />

				<div className="flex-1 flex text-center">

					{currentStep === 1 &&
						<>
                            
                            <MultiNumberInput
                                title="Pit Dimensions" 
                                values={
                                    [
                                        {defaultValue: pitLength, onUpdate: setPitLength, label: 'Length (m)'},
                                        {defaultValue: pitDepth, onUpdate: setPitDepth, label: 'Depth (m)'},
                                        {defaultValue: pitWidth, onUpdate: setPitWidth, label: 'Width (m)'},
                                    ]
                                } 
                                className="text-lg sm:text-lg md:text-lg" 
                                inputCSS="text-3xl sm:text-3xl md:text-3xl lg:text-4xl h-14 sm:h-16 mb-2" 
                                labelCSS="text-base" 
                                />
						</>
					}

					{currentStep === 2 &&
						<>
                                <MultiNumberInput
                                title="Standpipe" 
                                values={
                                    [
                                        {defaultValue: standpipeHeight, onUpdate: setStandpipeHeight, label: 'Height (m)'},
                                        {defaultValue: standpipeDepth, onUpdate: setStandpipeDepth, label: 'Depth (m)'},
                                    ]
                                } 
                                className="text-lg sm:text-lg md:text-lg" 
                                inputCSS="text-3xl sm:text-3xl md:text-3xl lg:text-4xl h-14 sm:h-16 mb-2" 
                                labelCSS="text-base" 
                                />
						</>
					}

					{currentStep === 3 &&
						<>

								<div className="flex-1 items-center">
                                    <NumberInput onUpdate={(value: any) => setWaterLevel(value)}
                                    title="Water level before test (mbgl)" 
                                    defaultValue={waterLevel} 
									presets={[
										{value: 'dry', label: 'dry'},
										]} 
                                    className="mb-10"
									next={next}
                                    />
								</div>
						</>
					}

					{currentStep === 4 &&
						<>

							<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2 w-full mx-auto pb-6">
								<div className="font-bold text-center mb-0 text-lg pb-2">
									Gravel?
								</div>
                                <ButtonOptions onUpdate={(value: any) => setGravel(value)} 
									defaultValue={gravel} 
									columns={2} 
									buttonHeight={12}
									buttonHeightMobile={12}
									gap={4}
									next={next}
									options={[
										'Yes',
										'No',
									]} className=" w-full sm:w-3/5 mx-auto text-lg font-bold pb-1" />
                            </div>
						</>
					}

					{currentStep === 5 &&
						<>
							<div className="w-full">
								<div className="border border-grey-mid rounded px-2 bg-sptTB mb-4 w-full mx-auto pb-6 flex-none">
									<div className="text-center mb-3 text-base">
										Main Soil Type
									</div>

									<ButtonOptions onUpdate={(value: any) => setSoilType(value)} 
										defaultValue={soilType} 
										columns={3} 
										buttonHeight={12}
										buttonHeightMobile={12}
										gap={3}
										options={[
											'Topsoil',
											'Madeground',
											'Sand',
											'Sand & Gravel',
											'Gravel',
											'Clay',
											'Silt',
											'Peat',
											'Chalk (Silt)',
										]}
										className=" w-full mx-auto text-lg font-bold pb-0"
										/>
								</div>

								<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2 w-full mx-auto pb-2 flex-none clear-both">

									<div className="text-center mb-1 text-base">
										Comments
									</div>

									<TextArea 
										label="" 
										value={comment}
										placeholder="Comments"
										onChange={(value: any) => setComment(value)} 
										className="w-full mx-auto mb-2 align-text-top"
										inputCSS="overflow-x-auto text-sm h-12"
										numberOfRows={2}
										/>

								</div>

							</div>
						</>
					}

				</div>

				<MissingRequiredField />
				

				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">
	
	
							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?
	
								<div className="flex mt-4">
									<Button theme="secondary" 
										className="mr-5 text-sm sm:text-base px-3.5 md:px-7" 
										onClick={() => setDeleteConfirmOpen(false)}>
											Cancel
									</Button>

									<Button onClick={() => {
												onClose()
												onComplete({
													guid: guid,
													update: false,
													delete: true,
												})
												setShowHeadTestDetailEdit(false)
											}}
										className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">
										Delete
									</Button>
								</div>
							</div>
	
	
						</div>
					</div>
				}

				<div className="flex mb-4">
					{/* <Button theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button> */}
					<DeleteButton/>

					<div className="flex ml-auto">
						<Button theme="primary" size="lg" className="px-4 sm:px-10" onClick={() => previous()}>Back</Button>
						<Button theme={saveButtonColor} 
							size="lg" 
							className="ml-4 px-8 sm:px-10 w-32 sm:w-60" 
							onClick={() => next()}>{saveButtonText}
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

HeadTestDetails.defaultProps = defaultProps

export default HeadTestDetails
