import React from 'react'

import Button from '../components/form/Button'
import SelectGroup from '../components/form/SelectGroup'
import InputGroup from '../components/form/InputGroup'
import Heading from '../components/Heading'
import { getUserDetails, fullName } from '../helpers/getUserDetails'

import Header from '../layout/Header'
import Container from '../layout/Container'
import { useSetIndexedDBValues } from "../helpers/addToIDB";
import ImageUploading, { ImageListType, ImageType } from 'react-images-uploading'
import { useGetIDBValues } from "../helpers/getIDBKey";
import { updateUserProfile } from "../helpers/updateUserProfile";
import { withRouter, useHistory, RouteComponentProps } from 'react-router-dom';
import { get, set, update } from 'idb-keyval'

import closeIcon from '../assets/icons/close.svg'
import showPasswordIcon from '../assets/icons/eye-open-icon.png'
import hidePasswordIcon from '../assets/icons/eye-closed-icon.png'

interface Props {
	router: any,
	toggleSidebar: () => void
}

interface State {
	id: string,
	guid: string,
	firstname: string,
	lastname: string,
	phone: string,
	email: string,
	password: string,
	passwordconfirm: string,
	passwordVisible: boolean,
	passwordConfirmVisible: boolean,
	// fullName: string,
	cscs: string,
	// images: any,
	// is_active: boolean,
	// company: string,
	// dpTorqueUnit: string,
	// ptLoadUnit: string,
	// cbrGuageRefUnit: string,
	incorrectPasswordText: string,
	company_id: any,
}


class Profile extends React.Component<Props, State> {

	constructor(props: any) {
		super(props)

		this.state = {
			id: '',
			guid: '',
			firstname: '',
			lastname: '',
			phone: '',
			email: '',
			cscs: '',
			password: '',
			passwordconfirm: '',
            passwordVisible: false,
            passwordConfirmVisible: false,
			incorrectPasswordText: '',
			company_id: 0,
			// images: [],

		}

	}

	maxNumber = 69

	componentDidMount() {
		getUserDetails((userDetails: any): any => {
			console.log('userDetails:', userDetails)
			this.setState({
				id: userDetails.user.id,
				guid: userDetails.user.guid,
				firstname: userDetails.user.first_name,
				lastname: userDetails.user.last_name,
				phone: userDetails.user.telephone,
				email: userDetails.user.email,
				cscs: userDetails.user.cscs_no,
				company_id: userDetails.user.company_id
			})


			let cachedValues = useGetIDBValues;
			const user = 'user/' + userDetails.user.id;

			console.log('user:', user)

			cachedValues(user, (data: any) => {
				console.log('user data:', data);
				if (data) {
					// Extract users from the response
					const userGUID = data.user[0].guid;

					// Set the state with the users guid
					this.setState({
						guid: userGUID
					});

					console.log('userGUID:', userGUID);
				} else {
					// Handle error case if needed
					// console.error('Error fetching users:', data.message);
				}
			});

			// console.log('cscs: ', userDetails.user.cscs_no)
			// console.log('this.state.id: ', this.state.id)
			// console.log('this.state.guid: ', this.state.guid)
		})
	}

	handleSubmit = (event: any) => {
		event.preventDefault()

		if (this.state.password === this.state.passwordconfirm) {
			// const saveToIDB = useSetIndexedDBValues
			// console.log('this.state.guid:', this.state.guid);
			// // saveToIDB(this.state, `user-profile/${this.state.guid}`, false, 'PUT')
			// saveToIDB(this.state, `user/${this.state.guid}`, false, 'PUT')
			//     .then(() => {
			//          // return the user to the previous page
			// 		 this.props.router.history.goBack();
			//     })
			updateUserProfile(
				this.state.guid,
				this.state.firstname,
				this.state.lastname,
				this.state.phone,
				// this.state.email,
				this.state.cscs,
				this.state.password
			)
			.then(() => {
				// update the IDB with new values
				// update('drillit-user', (first_name) => (this.state.firstname))
				// and all the other values

				getUserDetails((userData: any) => {
					console.log('userData on save BEFORE merge', userData)
					// Merge the updated values into the existing user data using spread operator
					const updatedUserData = {
						...userData,
						user: {
							...userData.user,
							first_name: this.state.firstname,
							last_name: this.state.lastname,
							telephone: this.state.phone,
							email: this.state.email,
							cscs_no: this.state.cscs,
							// Add more fields as needed
						}
					};
					console.log('userData on save AFTER merge', updatedUserData)
					// Update the user data in IndexedDB
					set('drillit-user', updatedUserData)
				});
			})
			.then(() => {
				// return the user to the previous page
				this.props.router.history.goBack();
			})

		} else {
			this.setState({
				incorrectPasswordText: 'The passwords do NOT match!'
			});
		}
	}

    togglePasswordVisibility = () => {
        this.setState((prevState) => ({
            passwordVisible: !prevState.passwordVisible,
        }));
    };

    togglePasswordConfirmVisibility = () => {
        this.setState((prevState) => ({
            passwordConfirmVisible: !prevState.passwordConfirmVisible,
        }));
    };

	// onChange = (imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
	//     console.log(imageList, addUpdateIndex)
	//     this.setState({
	//         images: imageList as never[],
	//     })
	// }

	render() {

		console.log('Profile - this.state', this.state)

		const companyLogo = "https://drillitlogs.com/images/company/" + this.state.company_id + ".png"

		return (
			<>
				<Header toggleSidebar={this.props.toggleSidebar} />

				<Container className="mt-10">
					{/* <Heading left={[this.state.firstname]} /> */}

					<form onSubmit={this.handleSubmit}>

						<div className="fixed inset-0 px-1 sm:px-4 md:px-8 py-2 sm:py-4 z-40 overflow-y-auto" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
							<div className={`flex flex-col rounded-lg border border-orange px-2 sm:px-2 md:px-2 pb-2 min-h-full modalshadow`} style={{ backgroundColor: '#FBFCFC' }}>
								<div className="flex items-center border-b border-grey-mid py-1">

									<div className="flex-none flex items-left w-8">
										<div className="flex-none">

										</div>
									</div>

									<div className="flex-1 flex justify-center text-lg md:text-xl font-bold text-text leading-6 text-center mx-auto">
										Edit Profile
									</div>

									<div className="flex-none flex justify-center">
										<span className="">
											<button className="flex items-center justify-center min-w-9 h-8 text-4xl bold text-soil-grey bg-white border border-orange rounded" onClick={() => this.props.router.history.goBack()}>
												<img className="w-8 h-8" src={closeIcon} alt="" />
											</button>
										</span>
									</div>
								</div>

								{/* <div onClick={() => closeModalOnDelete()}>close</div> */}

								<div className="flex flex-col mt-2 flex-grow">

									<div className="w-full md:w-2/3 rounded bg-sptTB mx-auto p-1.5 md:p-4">

										<div className="w-full text-charcoal text-center justify-center font-bold" style={{ fontSize: '0.65rem' }}>
											<img className="h-10 w-auto border border-grey-charcoal rounded-md mx-auto" src={companyLogo} alt="logo" /> {/* {middle} */}
											{/* {companyName} */}
										</div>

										<div className="grid grid-cols-3 gap-1 mt-2">

											<div className="text-right pt-1">First Name:</div>
											<div className="col-span-2">
												<InputGroup label="" value={this.state.firstname} required onChange={(value) => this.setState({ firstname: value })}
													className="mb-1" />
											</div>

											<div className="text-right pt-1">Last Name:</div>
											<div className="col-span-2">
												<InputGroup label="" value={this.state.lastname} required onChange={(value) => this.setState({ lastname: value })}
													className="mb-1" />
											</div>

											<div className="text-right py-1.5">Email:</div>
											<div className="col-span-2 py-1.5">
												{this.state.email}
											</div>

											<div className="text-right pt-1">Telephone:</div>
											<div className="col-span-2">
												<InputGroup label="" value={this.state.phone} onChange={(value) => this.setState({ phone: value })}
													className="mb-1" />
											</div>

											<div className="text-right pt-1">CSCS:</div>
											<div className="col-span-2">
												<InputGroup label="" value={this.state.cscs} onChange={(value) => this.setState({ cscs: value })}
													className="mb-1" />
											</div>

											<div className="text-right pt-1"></div>
											<div className="col-span-2 text-sm">
												If changing your password, enter your 'new' password twice below.
											</div>

											{this.state.incorrectPasswordText !== '' && (
												<>
													<div className="text-right pt-1"></div>
													<div className="col-span-2 bg-red text-white text-center w-full py-1 px-2">{this.state.incorrectPasswordText}</div>
												</>
											)}

											<div className="text-right pt-1">Password*:</div>
											<div className="col-span-2">
												{/* <InputGroup label="" value={this.state.password} type='password' required onChange={(value) => this.setState({ password: value })}
													className="mb-1" /> */}
													<div className="relative mb-1">
														<InputGroup
															label=""
															// value={this.state.password}
															value=""
															type={this.state.passwordVisible ? 'text' : 'password'} // Toggle between 'text' and 'password'
															required
															onChange={(value) => this.setState({ password: value })}
															className="w-full pr-10" // Make room for the eye icon
														/>
														<div
															className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
															onClick={this.togglePasswordVisibility}
														>
															<img
																src={this.state.passwordVisible ? showPasswordIcon : hidePasswordIcon} // Replace with your eye icon paths
																alt="Toggle Password Visibility"
																className="w-5"
															/>
														</div>
													</div>
											</div>

											<div className="text-right pt-1">Confirm Password*:</div>
											<div className="col-span-2">
												{/* <InputGroup label="" value={this.state.passwordconfirm} type='password' required onChange={(value) => this.setState({ passwordconfirm: value })}
													className="mb-2" /> */}
													<div className="relative mb-1">
														<InputGroup
															label=""
															// value={this.state.passwordconfirm}
															value=""
															type={this.state.passwordConfirmVisible ? 'text' : 'password'} // Toggle between 'text' and 'password'
															required
															onChange={(value) => this.setState({ passwordconfirm: value })}
															className="w-full pr-10" // Make room for the eye icon
														/>
														<div
															className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
															onClick={this.togglePasswordConfirmVisibility}
														>
															<img
																src={this.state.passwordConfirmVisible ? showPasswordIcon : hidePasswordIcon} // Replace with your eye icon paths
																alt="Toggle Password Visibility"
																className="w-5"
															/>
														</div>
													</div>
											</div>

											<div className="text-right pt-1"></div>
											<div className="col-span-2 mt-2">
												<div>
													{/* <p>We don't know the users guid here as it was created elsewhere, minor change on API, but means we can't save</p> */}
													<div className="flex justify-between">

														<Button theme="secondary" className="py-2" onClick={this.props.router.history.goBack}>Cancel</Button>

														<Button type="submit" theme="primary" className="w-60 py-2 text-lg font-bold">Save</Button>

													</div>
												</div>
											</div>

										</div>

									</div>

								</div>
							</div>
						</div>



						{/* <div className="flex items-center justify-between border border-grey-mid rounded bg-grey-light px-6 py-4 mb-4">

									<div className="">

                                        <ImageUploading
                                            value={this.state.images}
                                            onChange={this.onChange}
                                            maxNumber={this.maxNumber}
                                        >
                                            {({
                                                  imageList,
                                                  onImageUpload,
                                                  onImageRemoveAll,
                                                  onImageUpdate,
                                                  onImageRemove,
                                                  isDragging,
                                                  dragProps
                                              }) => (
                                                // write your building UI
                                                <div className="upload__image-wrapper">
                                                    <button
                                                        style={isDragging ? { color: "red" } : undefined}
                                                        onClick={onImageUpload}
                                                        {...dragProps}
                                                    >
                                                        Click or Drop here to add a profile image
                                                    </button>

                                                    {imageList.map((image, index) => (
                                                        <div key={index} className="image-item">
                                                            <img src={image.dataURL} alt="" width="100" className="rounded-full" />
                                                            <div className="image-item__btn-wrapper">
                                                                <button onClick={() => onImageUpdate(index)}>Update</button>
                                                                <button onClick={() => onImageRemove(index)}>Remove</button>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </ImageUploading>
									</div>
								</div>*/}

					</form>
				</Container>
			</>
		)
	}
}

export default Profile
