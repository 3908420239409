import { get, del } from 'idb-keyval';

export const usePostToApi = (keyValueEndpoint: string, postOrPut: string, update?: boolean) => {
    const newKeyValue = `drillit-${keyValueEndpoint}-new`;
    const updateKeyValue = `drillit-${keyValueEndpoint}-update`;

    // console.log('postToApi_Counts - keyValueEndpoint, postOrPut, update?', keyValueEndpoint, postOrPut, update);

    const noV1ApiRoutes = ['login'];
    const apiPostAddr = noV1ApiRoutes.includes(keyValueEndpoint)
        ? `${process.env.REACT_APP_API_ADDR_NOV1}${keyValueEndpoint}`
        : `${process.env.REACT_APP_API_ADDR}${keyValueEndpoint}`;

    if (update) {
        return handleUpdate(updateKeyValue, apiPostAddr, postOrPut);
    }

    return handleNew(newKeyValue, apiPostAddr, postOrPut);
};

const handleUpdate = (updateKeyValue: string, apiPostAddr: string, postOrPut: string) => {
    return getIDB(updateKeyValue).then(result => {
        if (result) {
            let removedItemsArray = result;
            result.forEach((element: any) => {
                const elId = element.guid;
                const apiPutAddr = `${apiPostAddr}/${elId}`;
                makeRequest(apiPutAddr, JSON.stringify(element), postOrPut)
                    .then(posted => {
                        if (posted.status === 200) {
                            removedItemsArray = removedItemsArray.filter((item: any) => item.guid !== element.guid);
                        }
                        return posted;
                    })
                    .then(endResult => {
                        if (removedItemsArray.length === 0) {
                            return del(updateKeyValue).then(res => res);
                        }
                        return endResult;
                    });
            });
        }
        return result;
    });
};

const handleNew = (newKeyValue: string, apiPostAddr: string, postOrPut: string) => {
    return getIDB(newKeyValue).then(result => {
        if (result) {
            let removedItemsArray = result;
            result.forEach(async (element: any) => {
                // console.log('**** postToApi_Counts - postToApi - JSON.stringify(element):', JSON.stringify(element));
                try {
                    const postAttempt = await makeRequest(apiPostAddr, JSON.stringify(element), postOrPut);
                    // console.log('**** postToApi_Counts - posted.status:', postAttempt.status, newKeyValue);
                    if (postAttempt.status === 200) {
                        removedItemsArray = removedItemsArray.filter((item: any) => item.guid !== element.guid);
                    } else {
                        console.log('**** postToApi_Counts - RESPONSE ERROR:', postAttempt.status);
                        // console.log('**** postToApi_Counts - element.data:', element);
                        console.log('**************************************************************** element.guid', element.guid)
                        const exists = await checkFilesExist('action_counts', element.guid);
                        if (exists) {
                            removedItemsArray = removedItemsArray.filter((item: any) => item.guid !== element.guid);
                        } else {
                            console.log('**** postToApi_Counts - Item does not exist on the server, will attempt repost');
                        }
                    }
                } catch (error) {
                    console.error('**** postToApi_Counts - Error in postAttempt:', error);
                }
                if (removedItemsArray.length === 0) {
                    await del(newKeyValue);
                }
            });
        }
        return result;
    });
};

// Function to check if files exist on the server
const checkFilesExist = async (taskType: string, guid: string) => {
    const endpoint = `${process.env.REACT_APP_API_ADDR}action/${taskType}/${guid}/exists`;
    try {
        const response = await fetch(endpoint);
        const data = await response.json();
        console.log(`**** postToApi_Counts - File existence for ${taskType} with guid ${guid}: ${data.exists}`);
        return data.exists;
    } catch (err) {
        console.error(`**** postToApi_Counts - Error checking file existence for ${taskType} with guid ${guid}:`, err);
        return false;
    }
};

const makeRequest = (endpoint: string, data: string, postOrPut: string) => {
    const token = localStorage.getItem('drillit-token');

    // console.log(`**** postToApi_Counts - Making ${postOrPut} request to:`, endpoint);
    // console.log('**** postToApi_Counts - Request data:', data);

    return fetch(endpoint, {
        method: postOrPut,
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: data,
    }).then(response => response).catch(err => err);
};

const getIDB = (keyValue: string) => get(keyValue).then(data => data);
