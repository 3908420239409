import React, { FunctionComponent } from 'react'

type Props = {
	className?: string,
	options: any[],
	defaultValue: string,
	radioCSS: string,
	columns?: number,
	onUpdate: (value: string) => void;
	[x: string]: any;
}

const defaultProps: Props = {
	className: '',
	options: [],
	defaultValue: '',
	radioCSS: '',
	columns: 3,
	onUpdate: (value: string) => {},
}

const RadioSelect: FunctionComponent<Props> = ({ className, options, defaultValue, columns, radioCSS, onUpdate, ...rest }) => {
	const update = (option: string) => {
		onUpdate(option)
	}

	return (
			<div className={`w-full ${className}`}>
				<div className={`grid grid-cols-${columns} gap-3`}>
					{options.map((option, index) => {
						return <div className={` ${option === defaultValue ? 'border-orange border-2' : 'border-grey-mid border'} bg-white rounded text-center  ${radioCSS}`} key={index} onClick={() => update(option)}>{option}</div>
					})}
				</div>
			</div>
	)
}

RadioSelect.defaultProps = defaultProps

export default RadioSelect