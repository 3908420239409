import React, { FunctionComponent, useState, Component } from 'react'

import { useLocation } from "react-router-dom";

import Header from '../layout/Header'

import Modal from '../components/Modal'
import WorkflowSteps from '../components/WorkflowSteps'
import NumberInput from '../components/NumberInput'
import Button from '../components/form/Button'
import InputGroup from '../components/form/InputGroup'
import TextArea from '../components/form/TextArea'
import WorkflowActions from '../components/form/WorkflowActions'

import stepStatus from '../helpers/stepStatus'
import useTodaysDate from '../helpers/todaysDate'

import { Link } from 'react-router-dom'
import RadioSelect from '../components/form/RadioSelect'
import todaysDate from '../helpers/todaysDate';
import { useSelector, useDispatch } from 'react-redux'

import { get, set } from 'idb-keyval'
import { useGetIDBValues } from "../helpers/getIDBKey";

type Props = {
	onClose: any,
	// onComplete: any,
	defaultValues?: any,
	projectId?: string,
	[x: string]: any;
	project_start_date: string,
}

const defaultProps: Props = {
	onClose: () => {},
	// onComplete: () => {},
	projectId: '',
	defaultValues: {
		guid: '',
        startDate: '',
        endDate: '',
	},
    project_start_date: '',
	project_id: '',
	company_id: '',
}



const AGS: FunctionComponent<Props> = ({ onClose, defaultValues, project_start_date, project_id, company_id, ...rest }) => {
	

	var todayDate = new Date().toISOString().slice(0, 10);
	// console.log(todayDate);
	const [startDate, setStartDate] = useState(project_start_date)
	const [endDate, setEndDate] = useState(todayDate)
	// console.log('AGS start date: ', project_start_date)

	// const createAGSX = () => {
	// 	let cachedValues = useGetIDBValues;

	// 	const companyKey = `ags/${company_id}/${project_id}`;

	// 	cachedValues(companyKey, (data: any) => {
	// 		// code goes here
	// 	});
	// };

	
	const [userToken, setUserToken] = useState('')

	const createAGSX = async () => {
		try {
			// Fetch required data from IndexedDB
			const cachedValues = useGetIDBValues;
			const companyKey = `ags/${project_id}/${company_id}`;
			// const companyKey = 'ags/' + project_id + '/' + company_id;

			get('drillit-user').then(data => {
				console.log('drillit-user data:', data)
				setUserToken(data.user.token)
			})
			
			console.log('userToken:', userToken)

			cachedValues(companyKey, async (data: any) => {
				try {
					console.log('Retrieved data from IndexedDB:', data);

					const token = userToken; // Adjust this based on your actual structure in IndexedDB
					const apiUrl = `/ags/${project_id}/${company_id}`;
					
					// Make a request to the API with the retrieved token
					const response = await fetch(apiUrl, {
						headers: {
							Authorization: `Bearer ${token}`,
							// Include any other headers if required
						},
					});
	
					if (!response.ok) {
						// Handle non-successful responses (e.g., show an error message)
						console.error('Failed to fetch AGS data:', response.statusText);
						return;
					}
	
					// Assuming the response is text data (modify as needed)
					const agsContent = await response.text();
	
					// Log or handle the AGS data as needed
					console.log('AGS file:', agsContent);
	
					// Create a Blob and open it in a new tab
					const blob = new Blob([agsContent], { type: 'text/plain' });
					const blobUrl = window.URL.createObjectURL(blob);
	
					const newTab = window.open(blobUrl, '_blank');
					if (newTab) {
						newTab.focus();
					}
				} catch (error) {
					console.error('Error creating AGS file:', error);
				}
			});
		} catch (error) {
			console.error('Error fetching data from IndexedDB:', error);
		}
	};

	const createAGS = async () => {
		try {
		  // Fetch required data from IndexedDB
		  const cachedValues = useGetIDBValues;
		  const companyKey = `ags/${project_id}/${company_id}`;
	  
		  // Fetch userToken from IndexedDB
		  get('drillit-user').then((data) => {
			console.log('drillit-user data:', data);
			const userToken = data.user.token;
	  
			// Use userToken directly inside the callback
			cachedValues(companyKey, async (data: any) => {
			  try {
				console.log('Retrieved data from IndexedDB:', data);
	  
				const token = userToken; // Use userToken directly here
				const apiUrl = `/ags/${project_id}/${company_id}`;
	  
				// Make a request to the API with the retrieved token
				const response = await fetch(apiUrl, {
				  headers: {
					Authorization: `Bearer ${token}`,
					// Include any other headers if required
				  },
				});
	  
				// if (!response.ok) {
				//   // Handle non-successful responses (e.g., show an error message)
				//   console.error('Failed to fetch AGS data:', response.statusText);
				//   return;
				// }
	  
				// // Assuming the response is text data (modify as needed)
				// const agsContent = await response.text();
	  
				// // Log or handle the AGS data as needed
				// console.log('AGS file:', agsContent);
	  
				// // Create a Blob and open it in a new tab
				// const blob = new Blob([agsContent], { type: 'text/plain' });
				// const blobUrl = window.URL.createObjectURL(blob);
	  
				// const newTab = window.open(blobUrl, '_blank');
				// if (newTab) {
				//   newTab.focus();
				// }
			  } catch (error) {
				console.error('Error creating AGS file:', error);
			  }
			});
		  });
		} catch (error) {
		  console.error('Error fetching data from IndexedDB:', error);
		}
	  };



	

	return (

        <>

		<Modal title="AGS" subtitle="" toggleModal={onClose} confirm={false} >

				
			<div className="border border-grey-mid rounded p-2 md:p-4 bg-sptTB w-full md:w-11/12 justify-center mx-auto mb-4 p-2 md"> 
											
				<div className="text-blue text-center mx-auto bg-modal-bg ags-item items-center rounded text-sm md:text-base font-bold px-4 md:px-5 py-1 md:py-2 w-full mb-4">
					AGS Data is currently automatically generated when 'Logs' are saved. No need to create AGS.<br />This page will be developed to generate AGS for specific holes or for certain date ranges (work in progress)
				</div>

				<div className="text-center mx-auto bg-modal-bg ags-item items-center rounded text-base md:text-lg font-bold px-4 md:px-5 py-1 md:py-2 w-full mb-4">
					Entire Project <input type="checkbox" className="form-control-checkbox" defaultChecked={true} />
				</div>

				<div className="text-center mx-auto bg-modal-bg ags-item items-center rounded text-base md:text-lg px-4 md:px-5 py-1 md:py-2 w-full">

					<div className="text-center text-base">or select locations manually</div>   

						<ul className="flex flex-wrap justify-center text-center mb-2 w-full"> 

							<li className="ags-item items-center border border-orange rounded text-sm md:text-lg pl-0.5 md:pl-2 py-1 m-1 bg-sptSB">
								BH001 <input type="checkbox" className="form-control-checkbox" defaultChecked={false} />
							</li>

							<li className="ags-item items-center border border-orange rounded text-sm md:text-lg pl-0.5 md:pl-2 py-1 m-1 bg-sptSB">
								BH002 <input type="checkbox" className="form-control-checkbox" defaultChecked={false} />
							</li>

							<li className="ags-item items-center border border-orange rounded text-sm md:text-lg pl-0.5 md:pl-2 py-1 m-1 bg-sptSB">
								BH003 <input type="checkbox" className="form-control-checkbox" defaultChecked={false} />
							</li>

							<li className="ags-item items-center border border-orange rounded text-sm md:text-lg pl-0.5 md:pl-2 py-1 m-1 bg-sptSB">
								BH004 <input type="checkbox" className="form-control-checkbox" defaultChecked={false} />
							</li>

							<li className="ags-item items-center border border-orange rounded text-sm md:text-lg pl-0.5 md:pl-2 py-1 m-1 bg-sptSB">
								BH005 <input type="checkbox" className="form-control-checkbox" defaultChecked={false} />
							</li>

						</ul>
				</div>
				
				<div className="text-center mx-auto bg-modal-bg ags-item items-center rounded text-base md:text-lg font-bold px-1 md:px-5 py-1 md:py-2 w-full mt-4 mb-4">

					<div className="text-center text-xl">Select Date Range</div> 
					<div className="text-center text-xs md:text-sm font-normal mb-2">(Entire project by default)</div> 

					<ul className="flex flex-wrap justify-center text-center mb-2 w-full"> 

					<li className="bg-sptSB ags-item items-center border border-grey-mid rounded text-sm md:text-lg px-1 md:px-5 py-1 md:py-2 m-1 md:m-2">
						Start Date 
						<InputGroup label="" 
							type={'date'} 
							value={startDate} 
							onChange={(value: any) => setStartDate(value)} 
						/>
					</li>

					<li className="bg-sptSB ags-item items-center border border-grey-mid rounded text-sm md:text-lg px-1 md:px-5 py-1 md:py-2 m-1 md:m-2">
						End Date 
						<InputGroup label="" 
							type={'date'} 
							value={endDate} 
							onChange={(value: any) => setEndDate(value)} 
						/>
					</li>

					<li className="bg-sptSB ags-item items-center border border-grey-mid rounded text-sm md:text-lg px-4 md:px-5 py-1 md:py-2 m-1 md:m-2">
						Today<br/><input type="checkbox" className="form-control-checkbox" defaultChecked={false} />
					</li>

					</ul>
					
				</div>
                        
						<div className="text-center mx-auto bg-modal-bg ags-item items-center rounded text-sm md:text-base px-4 md:px-5 py-1 md:py-2 w-full sm:w-3/5 mb-8">
							<div className="grid grid-cols-3 gap-1">
								<div className="col-span-3 font-bold">Required Info to send to createAGS function:</div>
								<div className="font-bold text-right">$projectId:</div>
								<div className="col-span-2 text-left">{project_id}</div>
								<div className="font-bold text-right">$companyId:</div>
								<div className="col-span-2 text-left">{company_id}</div>
							</div>
						</div>

				{/* <div className="text-center mx-auto bg-modal-bg ags-item items-center rounded text-base md:text-lg font-bold px-1 md:px-5 py-2 md:py-4 w-full mt-4 mb-4">
					<div className="bg-sptSB ags-item items-center border border-grey-mid rounded text-sm md:text-lg px-4 md:px-5 py-1 md:py-2 w-56 mx-auto font-bold">
						My data only<input type="checkbox" className="form-control-checkbox" defaultChecked={false} />
					</div>
				</div> */}

				<div className="w-30">
					<Button onClick={() => createAGS()}
					theme="primary" size="lg" className="btn-lg m-auto" >Generate AGS</Button>
				</div>

			</div>

			</Modal>

            </> 

	)
}

AGS.defaultProps = defaultProps

export default AGS