import React, { FunctionComponent } from 'react'

type Props = {
	value: number,
	max: number,
	[x: string]: any;
}

const ProgressBar: FunctionComponent<Props> = ({ value, max, ...rest }) => {
	return (
		<div className="flex justify-items-stretch w-full text-sm">
			<div className="bg-grey-selected rounded flex-1">
				<div className="bg-green-progress rounded text-white text-center py-1" style={{width: ((value / max) * 100) + "%"}}>{value}</div>
			</div>

			<div className="py-1 pl-2">
				{max}
			</div>
		</div>
	)
}

export default ProgressBar