import React, { useState, useRef } from 'react'

import ProjectsSoakaway_function from './ProjectsSoakaway_function'

import { useSelector, useDispatch } from 'react-redux'
import { useGetIDBValues } from "../../../helpers/getIDBKey";
import { connect } from "react-redux";
import { get, set, update } from "idb-keyval";
import logoOuter from '../../../assets/logo-icon-outer.svg'

interface Props {
	router: any,
	toggleSidebar: () => void,
	selectedWeather: string,
	selectedDate: string,
	company: any,
	project: any,
}

interface State {
	project_guid: string,
	borehole: any,
	completingBorehole: boolean,
	starting_hole_type_id: any,

	editingHole: any,
	editingIndex: any,
	redirect: boolean,

	project: any,
	holeType: string,

	defaultValues: object,
	guid: string,

	company_guid: string,

	companyName: string,
	contactEmail: string,
	contactTelephone: string,
	companyAddress1: string,
	companyAddress2: string,
	companyAddress3: string,
	companyCounty: string,
	companyCountry: string,
	companyPostcode: string,
	companyAddress: string,

	//HOLE DATA
	AllHoleData: any,
	active_dates: any,
	todaysDate: string,
	todaysDate_reverseFormat: string,
	lastActiveDate: any,
}







interface Result {
	time: string;
	depth: number;
  }
  
  interface Run {
	data_type: string | null;
	results: Result[];
	run: number;
	sa_id: number;
	start_time: string | null;
	status: string;
  }
  
  interface TransformedRun {
	data_type: string | null;
	results: { [key: string]: number };
	run: number;
	sa_id: number;
	start_time: string | null;
	status: string;
  }
  
  interface ActionSoakaway {
	depth_of_pit: string;
	depth_of_standpipe: string;
	general_remarks: string | null;
	gravel_backfill: string;
	guid: string;
	height_of_standpipe: string;
	id: number;
	length_of_pit: string;
	runs: Run[];
	sa_identifier: string | null;
	soil_type: string;
	time_of_action: string;
	water_level_before_test: string;
	weather_details: string;
	width_of_pit: string;
  }
  
  interface TransformedActionSoakaway {
	depth_of_pit: string;
	depth_of_standpipe: string;
	general_remarks: string | null;
	gravel_backfill: string;
	guid: string;
	height_of_standpipe: string;
	id: number;
	length_of_pit: string;
	runs: TransformedRun[];
	sa_identifier: string | null;
	soil_type: string;
	time_of_action: string;
	water_level_before_test: string;
	weather_details: string;
	width_of_pit: string;
  }
  
  interface HoleData {
	action_soakaway: ActionSoakaway[];
	// Add other properties if necessary
  }
  
  interface TransformedHoleData {
	action_soakaway: TransformedActionSoakaway[];
	// Add other properties if necessary
  }
  
  interface Data {
	hole_id: number;
	hole_guid: string;
	hole_data: HoleData;
  }
  
  interface TransformedData {
	hole_id: number;
	hole_guid: string;
	hole_data: TransformedHoleData;
  }
  
  
  

class ProjectsSoakawayBorehole extends React.Component<Props, State> {


	constructor(props: any) {
		super(props)
		const today = new Date();
		const day = today.getDate().toString().padStart(2, '0');
		const month = (today.getMonth() + 1).toString().padStart(2, '0');
		const year = today.getFullYear();

		this.state = {
			guid: this.props.router.match.params.id,
			project_guid: this.props.router.match.params.id,
			borehole: {
				project_guid: this.props.router.match.params.borehole,
			},
			completingBorehole: false,
			starting_hole_type_id: 7,

			editingHole: '',
			editingIndex: null,
			redirect: false,

			project: '',
			holeType: '',

			defaultValues: {},

			company_guid: '',

			companyName: '',
			contactEmail: '',
			contactTelephone: '',
			companyAddress1: '',
			companyAddress2: '',
			companyAddress3: '',
			companyCounty: '',
			companyCountry: '',
			companyPostcode: '',
			companyAddress: '',

			//HOLE DATA
			AllHoleData: [],
			active_dates: [],
			todaysDate: `${day}/${month}/${year}`,
			todaysDate_reverseFormat: `${year}-${month}-${day}`,
			lastActiveDate: `${day}/${month}/${year}`,
		}
	}

	generateUniqueDates = (holeData: any) => {
		if (holeData && holeData.hole_data) {
			const uniqueDatesArray = Object.values(holeData.hole_data)
				.flatMap((categoryData: any) =>
					categoryData.filter((action: any) => action !== null) // Filter out null actions
						.map((action: any) => new Date(action.time_of_action))
				);
	
			// Sort the array in descending order (newest first)
			uniqueDatesArray.sort((a: any, b: any) => {
				const dateA = new Date(b).getTime();
				const dateB = new Date(a).getTime();
				return dateA - dateB;
			});
	
			// Generate unique formatted dates from sorted raw dates
			const allUniqueDates = uniqueDatesArray.map((date: Date) => {
				const day = date.getDate().toString().padStart(2, '0');
				const month = (date.getMonth() + 1).toString().padStart(2, '0');
				const year = date.getFullYear();
				return `${day}/${month}/${year}`;
			});
	
			// Use Set to ensure unique values, then convert back to array
			const uniqueDatesArrayFiltered = [...new Set(allUniqueDates)];
	
			// Get the most recent date from uniqueDatesArray and format it as DD/MM/YYYY
			const mostRecentDateFormatted = uniqueDatesArrayFiltered.length > 0 ? uniqueDatesArrayFiltered[0] : null;
			console.log('mostRecentDate in CLASS:', mostRecentDateFormatted);
			if (mostRecentDateFormatted) {
				this.setState({ lastActiveDate: mostRecentDateFormatted });
			}
	
			// Update the state with the new unique dates
			this.setState({ active_dates: uniqueDatesArrayFiltered });
		} else {
			this.setState({ active_dates: [] });
		}
	}

	

	componentDidMount() {
		this.getBoreholeInfo()
		// this.getRigData()
		this.getHoleData()
	}

	getBoreholeInfo = () => {
		const boreholeId = this.props.router.match.params?.borehole;

		let cachedValues = useGetIDBValues;
		cachedValues(`hole-locations/${boreholeId}`, (data: any) => {
			this.setState({
				borehole: data
			});
		})
			.catch(error => {
				console.error('Failed to fetch from server, trying to get from IndexedDB:', error);

				// Fetch from IndexedDB if server request fails
				get(`drillit-hole-locations/${boreholeId}`)
					.then(data => {
						this.setState({
							borehole: data
						});
					})
					.catch(idbError => {
						console.error('Failed to fetch from IndexedDB:', idbError);
					});
			});
	}

	// getBoreholeInfo = () => {
	// 	const boreholeId = this.props.router.match.params?.borehole;
	
	// 	// Try to fetch from IndexedDB first
	// 	get(`drillit-hole-locations/${boreholeId}`)
	// 		.then(data => {
	// 			this.setState({
	// 				borehole: data
	// 			});
	// 		})
	// 		.catch(idbError => {
	// 			console.error('Failed to fetch from IndexedDB, trying to get from cachedValues:', idbError);
	
	// 			// If IndexedDB fetch fails, fetch from server using cachedValues
	// 			let cachedValues = useGetIDBValues;
	// 			cachedValues(`hole-locations/${boreholeId}`, (data: any) => {
	// 				this.setState({
	// 					borehole: data
	// 				});
	// 			})
	// 				.catch(error => {
	// 					console.error('Failed to fetch from server:', error);
	// 				});
	// 		});
	// }





	// getHoleData = () => {
	// 	const cachedValues = useGetIDBValues;
	  
	// 	// Function to convert depth values to numbers in the 'runs' array
	// 	// cachedValues(`actions/${this.props.router.match.params?.borehole}`, (data:any) => {
	// 	cachedValues(`actions/${this.state?.starting_hole_type_id}/${this.props.router.match.params?.borehole}`, (data: any) => {
			
	// 		// console.log('Soakaway data BEFORE transform:', data)
	// 		// const updatedData = this.transformResults(data);
	// 		// console.log('Soakaway data AFTER transform:', updatedData)

	// 		this.setState((prevState) => ({
	// 		  ...prevState,
	// 		//   AllHoleData: updatedData,
	// 		  AllHoleData: data,
	// 		}));
	  
	// 	  // Call the generateUniqueDates function with the fetched data
	// 	  this.generateUniqueDates(data);
	// 	}).then();
	  
	// 	// cachedValues(`hole-locations/${this.props.router.match.params?.borehole}`, (data:any) => {
	// 	//   this.setState({
	// 	// 	borehole: data,
	// 	//   });
	// 	// }).then();
	  
	// 	// get the project and the company guid from that
	// 	cachedValues(`projects/${this.state?.project_guid}`, (data:any) => {
	// 	  this.setState({
	// 		project: data,
	// 	  });
	// 	}).then();
	//   };

	  getHoleData = () => {

		let cachedValues = useGetIDBValues

		cachedValues(`actions/${this.state?.starting_hole_type_id}/${this.props.router.match.params?.borehole}`, (data: any) => {
			
			// console.log('Soakaway data BEFORE transform:', data)
			const updatedData = this.transformResults(data);
			// console.log('Soakaway data AFTER transform:', updatedData)

			this.setState((prevState) => ({
				...prevState,
			    AllHoleData: updatedData,
				// AllHoleData: data,
			  }));

			// Call the generateUniqueDates function with the fetched data
			this.generateUniqueDates(data);

			if (this.state.active_dates.length === 0) {
				// Create a copy of the active_dates array and push the new date to it
				const updatedActiveDates = [...this.state.active_dates];
				updatedActiveDates.push(this.state.todaysDate);

				// Update the state with the new active_dates
				this.setState({ active_dates: updatedActiveDates });
			}
		}).then()

		// get the project and the company guid from that
		cachedValues(`projects/${this.state.project_guid}`, (data: any) => {
			this.setState({
				project: data
			})
		}).then()
	}

	// getHoleData = () => {
	// 	const boreholeId = this.props.router.match.params?.borehole;
	// 	const holeTypeId = this.state?.starting_hole_type_id;
	// 	const projectGuid = this.state.project_guid;
	
	// 	// Try to fetch from IndexedDB first for actions
	// 	get(`drillit-actions/${holeTypeId}/${boreholeId}`)
	// 		.then(data => {
	// 			if (data) {
	// 				// Transform the results
	// 				const updatedData = this.transformResults(data);
	
	// 				this.setState(prevState => ({
	// 					...prevState,
	// 					AllHoleData: updatedData,
	// 				}));
	
	// 				// Call the generateUniqueDates function with the fetched data
	// 				this.generateUniqueDates(data);
	
	// 				if (this.state.active_dates.length === 0) {
	// 					// Create a copy of the active_dates array and push the new date to it
	// 					const updatedActiveDates = [...this.state.active_dates];
	// 					updatedActiveDates.push(this.state.todaysDate);
	
	// 					// Update the state with the new active_dates
	// 					this.setState({ active_dates: updatedActiveDates });
	// 				}
	// 			} else {
	// 				throw new Error('No data found in IndexedDB for actions');
	// 			}
	// 		})
	// 		.catch(idbError => {
	// 			console.error('Failed to fetch from IndexedDB for actions, trying to get from cachedValues:', idbError);
	
	// 			// If IndexedDB fetch fails, fetch from server using cachedValues
	// 			let cachedValues = useGetIDBValues;
	// 			cachedValues(`actions/${holeTypeId}/${boreholeId}`, (data: any) => {
	// 				if (data) {
	// 					// Transform the results
	// 					const updatedData = this.transformResults(data);
	
	// 					this.setState(prevState => ({
	// 						...prevState,
	// 						AllHoleData: updatedData,
	// 					}));
	
	// 					// Call the generateUniqueDates function with the fetched data
	// 					this.generateUniqueDates(data);
	
	// 					if (this.state.active_dates.length === 0) {
	// 						// Create a copy of the active_dates array and push the new date to it
	// 						const updatedActiveDates = [...this.state.active_dates];
	// 						updatedActiveDates.push(this.state.todaysDate);
	
	// 						// Update the state with the new active_dates
	// 						this.setState({ active_dates: updatedActiveDates });
	// 					}
	// 				}
	// 			}).catch(error => {
	// 				console.error('Failed to fetch actions from server:', error);
	// 			});
	// 		});
	
	// 	// Try to fetch the project data from IndexedDB first
	// 	get(`drillit-projects/${projectGuid}`)
	// 		.then(data => {
	// 			if (data) {
	// 				this.setState({
	// 					project: data
	// 				});
	// 			} else {
	// 				throw new Error('No data found in IndexedDB for project');
	// 			}
	// 		})
	// 		.catch(idbError => {
	// 			console.error('Failed to fetch project from IndexedDB, trying to get from cachedValues:', idbError);
	
	// 			// If IndexedDB fetch fails, fetch from server using cachedValues
	// 			let cachedValues = useGetIDBValues;
	// 			cachedValues(`projects/${projectGuid}`, (data: any) => {
	// 				if (data) {
	// 					this.setState({
	// 						project: data
	// 					});
	// 				}
	// 			}).catch(error => {
	// 				console.error('Failed to fetch project from server:', error);
	// 			});
	// 		});
	// }
	
	




	  
	//   transformResults = (data: Data): TransformedData => {
	// 	if (!data || !data.hole_data || !data.hole_data.action_soakaway) {
	// 	  console.error("Invalid data format. Expected the correct structure.");
	// 	  return data as unknown as TransformedData;
	// 	}
	  
	// 	const transformedData: TransformedData = {
	// 	  ...data,
	// 	  hole_data: {
	// 		...data.hole_data,
	// 		action_soakaway: data.hole_data.action_soakaway.map((action) => ({
	// 		  ...action,
	// 		  runs: action.runs.map((run) => ({
	// 			...run,
	// 			results: run.results.reduce((acc, { time, depth }) => {
	// 			  acc[time] = depth;
	// 			  return acc;
	// 			}, {} as { [key: string]: number })
	// 		  }))
	// 		}))
	// 	  }
	// 	};
	  
	// 	return transformedData;
	//   };

	transformResults = (data: Data): TransformedData => {
		if (!data || !data.hole_data || !data.hole_data.action_soakaway) {
			console.error("Invalid data format. Expected the correct structure.");
			return data as unknown as TransformedData;
		}
		console.log('data.hole_data.action_head_test:', data);
	
		const transformedData: TransformedData = {
			...data,
			hole_data: {
				...data.hole_data,
				action_soakaway: data.hole_data.action_soakaway.map((action) => ({
					...action,
					runs: action.runs.map((run) => ({
						...run,
						results: Array.isArray(run.results) 
							? run.results.reduce((acc, { time, depth }) => {
								acc[time] = depth;
								return acc;
							  }, {} as { [key: string]: number })
							: {} // If run.results is not an array, return an empty object
					}))
				}))
			}
		};
	
		console.log('transformedData:', transformedData);
	
		return transformedData;
	};

	
	  


	  convertDepthToNumber = (data: any) => {
		if (!data || !data.hole_data || !data.hole_data.action_soakaway) {
		  console.error("Invalid data format. Expected the correct structure.");
		  return data;
		}
	  
		const updatedData = {
		  ...data,
		  hole_data: {
			...data.hole_data,
			action_soakaway: data.hole_data.action_soakaway.map((action: any) => ({
			  ...action,
			  runs: action.runs.map((run: any) => ({
				...run,
				results: run.results[0] && typeof run.results[0] === 'object' ? Object.fromEntries(
					Object.entries(run.results[0]).map(([time, depth]) => [
					  time,
					  depth !== null ? Number(depth) : null,
					])
				  ) : {},
				  
			  })),
			})),
		  },
		};
	  
		return updatedData;
	  };

	  handleReloadClick = () => {
		  window.location.reload();
		};






	render() {

		console.log('***************************************************************')
		console.log('this.state.borehole:', this.state?.borehole)
		console.log('this.state.project:', this.state?.project)
		console.log('company_guid:', this.state.project?.company_guid)
		console.log('project_guid:', this.state?.project_guid)
		console.log('project_id:', this.state?.project.id)
		console.log('starting_hole_type_id:', this.state.borehole?.starting_hole_type_id)
		console.log('borehole_guid:', this.state.borehole?.guid)
		console.log('selectedWeather:', this.props.selectedWeather)
		console.log('selectedDate:', typeof this.props.selectedDate, this.props.selectedDate)
		console.log('borehole_id:', this.props.router.match.params.borehole)
		console.log('borehole_name:', this.state.borehole?.hole_name)
		console.log('AllHoleData in CLASS:', this.state?.AllHoleData)
		console.log('activeDates:', this.state.active_dates)
		console.log('lastActiveDate:', this.state.lastActiveDate)

		console.log('AllHoleData IN CLASS:', this.state?.AllHoleData)

		// Render loading state while data is being fetched
 
		if (this.state?.AllHoleData 
			&& this.state?.AllHoleData !== undefined 
			&& this.state?.AllHoleData.length === 0
			&& this.state?.active_dates && this.state?.active_dates.length === 0
		) {
        return <>
				<div className="flex items-center justify-center h-screen">  
					<img src={logoOuter} className="logo-spin-sync absolute h-20" alt="logo gear" />    
					<img src={logoOuter} className="logo-spin-sync-left absolute h-12" alt="logo gear" />
					<span className="pt-24 text-sm">INITIALIZING</span>
				</div>
				<div className="-mt-10 clear-both text-grey-mid text-sm text-center w-full" onClick={() => {this.props.router.history.goBack(-1)}}>back</div>
				</>
		}

		if (this.state?.AllHoleData === undefined) {

			return <>
					<div className="flex items-center justify-center h-screen">  
						<img src={logoOuter} className="logo-spin-sync absolute h-20" alt="logo gear" />    
						<img src={logoOuter} className="logo-spin-sync-left absolute h-12" alt="logo gear" />
						<span className="pt-24 text-sm">LOADING</span>
					</div>
					<div className="-mt-10 clear-both text-grey-mid text-sm text-center w-full" onClick={() => {this.props.router.history.goBack(-1)}}>back</div>
					</>
		}

		return (

			<ProjectsSoakaway_function
				company_guid={this.state.project?.company_guid}
				project_guid={this.state?.project_guid}
				project_id={this.state?.project.id}
				project_name={this.state.project.project_identifier}
				starting_hole_type_id='7' // HeadTest
				borehole_guid={this.state?.borehole?.guid}
				selectedWeather={this.props.selectedWeather}
				selectedDate={this.props.selectedDate}
				lastActiveDate={this.state?.lastActiveDate}
				borehole_id={this.state?.borehole?.id} // this.props.router.match.params.borehole
				borehole_name={this.state?.borehole?.hole_name}
				toggleSidebar={this.props.toggleSidebar}
				//HOLE DATA
				AllHoleData={this.state?.AllHoleData}
				activeDates={this.state?.active_dates}
				reloadData={this.handleReloadClick}
			/>
		)
	}
}

const mapStateToProps = (state: any) => ({
	selectedWeather: state.weather,
	selectedDate: state.date,
})

export default connect(mapStateToProps, null)(ProjectsSoakawayBorehole)