const getWeatherList = () => {
	return [
		{
			label: 'Hot & Dry',
			icon: 'wi wi-day-sunny',
		},
		{
			label: 'Mostly Sunny & Dry',
			icon: 'wi wi-day-sunny-overcast',
		},
		{
			label: 'Overcast',
			icon: 'wi wi-day-cloudy',
		},
		{
			label: 'Partly Cloudy & Dry',
			icon: 'wi wi-cloud',
		},
		{
			label: 'Cloudy & Dry',
			icon: 'wi wi-cloudy',
		},
		{
			label: 'Fog',
			icon: 'wi wi-day-fog',
		},
		{
			label: 'Partly Sunny with Showers',
			icon: 'wi wi-day-showers',
		},
		{
			label: 'Partly Sunny with Rain',
			icon: 'wi wi-day-rain',
		},
		{
			label: 'Cloudy Rain',
			icon: 'wi wi-rain',
		},
		{
			label: 'Rain with Standing Water',
			icon: 'wi wi-raindrops',
		},
		{
			label: 'Standing Water',
			icon: 'wi wi-flood',
		},
		{
			label: 'Hazy',
			icon: 'wi wi-day-haze',
		},
		{
			label: 'Freezing',
			icon: 'wi wi-snowflake-cold',
		},
		{
			label: 'Snow',
			icon: 'wi wi-day-snow',
		},
		{
			label: 'Cloudy Snow',
			icon: 'wi wi-snow',
		},
		{
			label: 'Sleet',
			icon: 'wi wi-sleet',
		},
		{
			label: 'Hail',
			icon: 'wi wi-hail',
		},
		{
			label: 'Windy',
			icon: 'wi wi-strong-wind',
		},
		{
			label: 'Cloudy Windy',
			icon: 'wi wi-cloudy-gusts',
		},
		{
			label: 'Storms',
			icon: 'wi wi-storm-showers',
		},
		{
			label: 'Thunderstorms',
			icon: 'wi wi-thunderstorm',
		},
		{
			label: 'Tornado',
			icon: 'wi wi-tornado',
		},
		{
			label: 'Smog',
			icon: 'wi wi-smog',
		},
		{
			label: 'Indoors',
			// icon: 'wi wi-volcano',
			icon: 'wi wi-na',
		},
	]
}

export default getWeatherList