import React, { FunctionComponent, useState, useEffect } from 'react'
import { Range } from 'react-range'

type Props = {
	className?: string,
	defaultValue: string,
	title?: string,
	onUpdate: (value: string) => void;
	[x: string]: any;
}

const defaultProps: Props = {
	className: '',
	defaultValue: '00:00',
	title: '',
	onUpdate: (value: any) => {},
}



const DurationInput: FunctionComponent<Props> = ({ className, defaultValue, title, onUpdate, ...rest }) => {

	const [hours, setHours] = useState([parseFloat(defaultValue.split(':')[0])])
	const [minutes, setMinutes] = useState([parseFloat(defaultValue.split(':')[1])])

	// var leadingZeroHour = ('0000'+[parseFloat(defaultValue.split(':')[0])]).slice(-4)
	// const [hours, setHours] = useState(parseFloat(leadingZeroHour))
	// var leadingZeroMin = ('0000'+[parseFloat(defaultValue.split(':')[1])]).slice(-4)
	// const [minutes, setMinutes] = useState(leadingZeroMin)

	// console.log(leadingZeroMin)

	var leadingZeroHour = ''
	if (hours[0]<10){leadingZeroHour = '0'}

	var leadingZeroMin = ''
	if (minutes[0]<10){leadingZeroMin = '0'}

	useEffect(() => {
		const duration = `${leadingZeroHour}${hours[0]}:${leadingZeroMin}${minutes[0]}`
		onUpdate(duration)
	}, [hours, minutes, leadingZeroHour, leadingZeroMin, onUpdate])

	return (
		<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2 w-full pb-2">
			<div className={`w-full ${className}`}>

				{title ? <div className="text-base sm:text-xl mb-4 text-center">{title}</div> : <></>}
				
				{/* <div className="grid grid-cols-3 gap-3 mb-8">
							<div></div> */}
						<div className="mx-auto w-40 sm:w-80 mb-8">

					<div className="border border-orange rounded py-3 text-center text-4xl bg-white">
						{leadingZeroHour}{hours}:{leadingZeroMin}{minutes}
					</div>

					<div></div>
				</div>

				<div className="mb-4 w-4/5 sm:w-3/5 mx-auto">
					<div className="font-bold text-center mb-6">Hours</div>

					<Range
						step={1}
						min={0}
						max={23}
						values={hours}
						onChange={(values) => setHours(values)}
						renderTrack={({ props, children }) => (
							<div
								{...props}
								className="bg-orange rounded-full"
								style={{
									...props.style,
									height: '6px',
									width: '100%',
								}}
							>
								{children}
							</div>
						)}
						renderThumb={({ props }) => (
							<div
								{...props}
								className="bg-white rounded-full border-4 border-orange"
								style={{
									...props.style,
									height: '42px',
									width: '42px',
								}}
							/>
						)}
					/>
				</div>

				<div className=" w-4/5 sm:w-3/5 mx-auto">
					<div className="font-bold text-center mb-6">Minutes</div>

					<Range
						step={5}
						min={0}
						max={59}
						values={minutes}
						onChange={(values) => setMinutes(values)}
						renderTrack={({ props, children }) => (
							<div
								{...props}
								className="bg-orange rounded-full"
								style={{
									...props.style,
									height: '6px',
									width: '100%',
								}}
							>
								{children}
							</div>
						)}
						renderThumb={({ props }) => (
							<div
								{...props}
								className="bg-white rounded-full border-4 border-orange"
								style={{
									...props.style,
									height: '42px',
									width: '42px',
								}}
							/>
						)}
					/>
				</div>
			</div>
		</div>
	)
}

DurationInput.defaultProps = defaultProps

export default DurationInput
