import { del, get, set, keys } from 'idb-keyval';

export const updatePlantCheckWindowSample = (
    towingEyeHandbrakeJockeyWheelBreakawayCable: number,
    rigTracks: number,
    generalEngineCheck: number,
    fuelEngineOilLevelsFilters: number,
    hydraulicSystem: number,
    rigFrame: number,
    guardFitted: number,
    chalwynValveSparkArrestorFitted: number,
    drillingTools: number,
    fireExtinguisher: number,
    spillKitDrillTrays: number,
    ignitionSystemElectronicSystem: number,
    mainChainFunction: number,
    rigFrameFunction: number,
    engineOperation: number,
    emergencyStop: number,
    leverFunction: number,
    issuesMaintenance: string,
    company_guid: string,
    company_id: number,
    driller_name: string,
    driller_cscs: string,
    guid: string,
    plant_guid: string,
    plant_id: string,
    plant_name: string,
    spt_rod_type: string,
    spt_id_ref: string,
    spt_energy_ratio: string,
    spt_calibration_date: string,
    plant_test_date_expiry: string,
    plant_check_date: string,
    plantcheck_url: string,
    projectGUID: string,
    mergedRigPlantChecksUpdated: any

    ) => {
    return new Promise(async (resolve, reject) => {
        const endpoint = `${process.env.REACT_APP_API_ADDR}plant-check-window-sample/${guid}`
        // console.log('endpoint:', endpoint)
        
        const updateData = {
            'towingEyeHandbrakeJockeyWheelBreakawayCable': towingEyeHandbrakeJockeyWheelBreakawayCable ? 1 : 0,
            'rigTracks': rigTracks ? 1 : 0,
            'generalEngineCheck': generalEngineCheck ? 1 : 0,
            'fuelEngineOilLevelsFilters': fuelEngineOilLevelsFilters ? 1 : 0,
            'hydraulicSystem': hydraulicSystem ? 1 : 0,
            'rigFrame': rigFrame ? 1 : 0,
            'guardFitted': guardFitted ? 1 : 0,
            'chalwynValveSparkArrestorFitted': chalwynValveSparkArrestorFitted ? 1 : 0,
            'drillingTools': drillingTools ? 1 : 0,
            'fireExtinguisher': fireExtinguisher ? 1 : 0,
            'spillKitDrillTrays': spillKitDrillTrays ? 1 : 0,
            'ignitionSystemElectronicSystem': ignitionSystemElectronicSystem ? 1 : 0,
            'mainChainFunction': mainChainFunction ? 1 : 0,
            'rigFrameFunction': rigFrameFunction ? 1 : 0,
            'engineOperation': engineOperation ? 1 : 0,
            'emergencyStop': emergencyStop ? 1 : 0,
            'leverFunction': leverFunction ? 1 : 0,
            'issuesMaintenance': issuesMaintenance,
            'company_guid': company_guid,
            'company_id': company_id,
            'driller_name': driller_name,
            'driller_cscs': driller_cscs,
            'guid': guid,
            'plant_guid': plant_guid,
            'plant_id': plant_id,
            'plant_name': plant_name,
            'spt_rod_type': spt_rod_type,
            'spt_id_ref': spt_rod_type,
            'spt_energy_ratio': spt_energy_ratio,
            'spt_calibration_date': spt_calibration_date,
            'plant_test_date_expiry': plant_test_date_expiry,
            'plant_check_date': plant_check_date,
            'plantcheck_url': plantcheck_url,
            'project_guid': projectGUID,
        }

        // Delete any existing records
        const offlineKey = `drillit-plant-check-window-sample/${plant_guid}-update`;
        try {
            await del(offlineKey);
            console.log(`Deleted existing record: ${offlineKey}`);
        } catch (error) {
            console.error('Error deleting existing record:', error);
        }

        // Make the request and handle response
        makeRequest(endpoint, JSON.stringify(updateData), 'PUT')
            .then(response => {
                if (response.status !== 200) {
                    saveToIndexedDB(offlineKey, updateData);
                }
                resolve(true);
            })
            .catch(error => {
                console.error('Error making request:', error);
                saveToIndexedDB(offlineKey, updateData);
                resolve(false);
            });

        // Update the currentRigPlantChecks state in IndexedDB
        try {
            set(`drillit-plant-check-window-sample/${plant_guid}`, mergedRigPlantChecksUpdated);
            console.log('Updating drillit-plant-check-window-sample/');
        } catch (error) {
            console.error('Error updating current rig plant checks:', error);
        }
    });
};

const makeRequest = (endpoint: string, data: string, postOrPut: string) => {
    const token = localStorage.getItem('drillit-token');

    console.log('MAKING REQUEST:', data);

    return fetch(endpoint, {
        method: postOrPut,
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: data,
    })
        .then(response => response)
        .catch(err => err);
};

const saveToIndexedDB = async (key:any, data:any) => {
    try {
        await set(key, data);
        console.log(`Data saved to IndexedDB with key: ${key}`);
    } catch (error) {
        console.error('Error saving data to IndexedDB:', error);
    }
};
