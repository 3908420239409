import React, { FunctionComponent, useState } from 'react'

import WorkflowSteps from '../../WorkflowSteps'
import Modal from '../../Modal'
import Button from '../../form/Button'
import NumberInputOptions from '../../NumberInputOptions'
import MultiNumberInput from "../../MultiNumberInput";
import { v4 as uuidv4 } from 'uuid';
import stepStatus from '../../../helpers/stepStatus'
import warningIcon from '../../../assets/icons/warning.png'

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: string,
	defaultValues?: any,
	update?: any,
	[x: string]: any;
	options: any,
}

const defaultProps: Props = {
	onClose: () => { },
	onComplete: () => { },
	borehole: '',
	defaultValues: {
		guid: '',
		deformationGaugeReference: '',
		forceMeasuringDeviceReference: '',
		meanCalibration: '',
	},
	options: {
		inputOption1: '',
		inputOption2: '',
		loadUnits: '',
	},
}

const CBREquipment: FunctionComponent<Props> = ({ onClose, update, onComplete, borehole, defaultValues, options, ...rest }) => {
	const [currentStep, setCurrentStep] = useState(1)

	const [guid, setGuid] = useState(defaultValues.guid)

	const [deformationGaugeReference, setDeformationGaugeReference] = useState(defaultValues.deformationGaugeReference)
	const [forceMeasuringDeviceReference, setForceMeasuringDeviceReference] = useState(defaultValues.forceMeasuringDeviceReference)
	const [meanCalibration, setMeanCalibration] = useState(defaultValues.meanCalibration)

	const loadUnits = options.loadUnits
	// const inputOption1 = options.inputOption1
	// const inputOption2 = options.inputOption2

	// console.log('options: ', options)
	// console.log('Equipment loadUnits: ', options.loadUnits)
	// console.log('Equipment inputOption1: ', options.inputOption1)
	// console.log('Equipment inputOption2: ', options.inputOption2)

	const [saveButtonText, setButtonText] = useState('Continue')
	const [saveButtonColor, setButtonColor] = useState('primary')
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	const DeleteButton = () => {
		if (!update) return null;

		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	const deleteRecordConfirm = () => {
		setDeleteConfirmOpen(false)
		// onComplete()
	}

	const previous = () => {
		if (currentStep > 1)
			setCurrentStep(currentStep - 1)
		setButtonText('Continue')
		setButtonColor('primary')
	}

	const next = () => {
		if (currentStep < 2) {
			setCurrentStep(currentStep + 1)
			if (currentStep === 2) {
				setButtonText('Save')
				setButtonColor('success')
			} else {
				setButtonText('Continue')
				setButtonColor('primary')
			}
			return
		}

		if (deformationGaugeReference === '' || forceMeasuringDeviceReference === '' || meanCalibration === '') {
			//do nothing
		} else {

			onComplete({
				guid: guid ? guid : uuidv4(),
				deformation_gauge_reference: deformationGaugeReference,
				load_units: loadUnits,
				force_measuring_device_reference: forceMeasuringDeviceReference,
				mean_calibration: meanCalibration,
				update: update ? true : false,
			})
		}
	}

	let requiredDeformationGaugeReferenceText = ''
	let requiredForceMeasuringDeviceReferenceText = ''
	let requiredMeanCalibrationText = ''

	if (!deformationGaugeReference) {
		requiredDeformationGaugeReferenceText = "'Gauge Reference' - "
	}

	if (!forceMeasuringDeviceReference) {
		requiredForceMeasuringDeviceReferenceText = "'Device Reference' - "
	}

	if (!meanCalibration) {
		requiredMeanCalibrationText = "'Mean Calibration'"
	}

	const MissingRequiredField = () => {
		if (currentStep === 2 && (!deformationGaugeReference || !forceMeasuringDeviceReference || !meanCalibration)) return (
			<div className="text-center bg-sptSB border border-red font-bold rounded mb-2 py-1 text-xs md:text-sm lg:text-base">
				<img src={warningIcon} alt="info" width="20" className="inline-block mr-1 pb-1" />
				Required field: {requiredDeformationGaugeReferenceText} {requiredForceMeasuringDeviceReferenceText} {requiredMeanCalibrationText}
			</div>
		)

		return null
	}

	// const inputOption1='kN...'
	// const inputOption2='T'

	return (
		<Modal title="CBR Equipment" subtitle={borehole} toggleModal={onClose}>
			<div className="flex flex-col flex-grow px-1 sm:px-2 md:px-10">
				<WorkflowSteps steps={[
					{
						label: 'Deformation gauge reference',
						status: stepStatus(1, currentStep),
						onClick: () => {
							setCurrentStep(1)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Force & Calibration',
						status: stepStatus(2, currentStep),
						onClick: () => {
							setCurrentStep(2)
							setButtonText('Save')
							setButtonColor('success')
						}
					},
				]} className="mb-2" />

				<div className="flex-1 flex text-center">

					{currentStep === 1 &&
						<>
							<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2 w-full">
								<div className="mx-auto w-full sm:w-4/5 md:w-4/5 lg:w-3/5">
									<NumberInputOptions onUpdate={(value: any) => setDeformationGaugeReference(value)}
										defaultValues={{ numericValue: deformationGaugeReference }}
										buttonoptions={{
											inputOption1: 'kN',
											inputOption2: 'T',
											loadUnits: loadUnits,
											inputOption1Title: 'Kilo Newtons',
											inputOption2Title: 'Tons',
											keyboard: true,
										}}
										title="Deformation gauge reference"
										className="mb-2" />
								</div>
							</div>
						</>
					}

					{currentStep === 2 &&
						<>
							<MultiNumberInput values={
								[
									{ defaultValue: forceMeasuringDeviceReference, onUpdate: setForceMeasuringDeviceReference, label: 'Force measuring device reference' },
									{ defaultValue: meanCalibration, onUpdate: setMeanCalibration, label: 'Mean calibration (N/div)' },
								]
							}
								title="Force & Calibration"
								className="mb-1 text-base sm:text-xl md:text-base pt-2 mb-4"
								inputCSS="text-4xl sm:text-4xl md:text-3xl lg:text-4xl h-16 sm:h-16 mb-2"
							/>
						</>
					}

				</div>


				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">


							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?

								<div className="flex mt-4">
									<Button theme="secondary" className="mr-5 text-sm sm:text-base px-3.5 md:px-7" onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>

									<Button onClick={deleteRecordConfirm} className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">Delete</Button>
								</div>
							</div>


						</div>
					</div>
				}

				<MissingRequiredField />

				<div className="flex ">
					{/* <Button theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button> */}
					<DeleteButton />

					<div className="flex ml-auto ">
						<Button theme="primary" size="lg" className="px-4 sm:px-10" onClick={() => previous()}>Back</Button>
						<Button theme={saveButtonColor}
							size="lg"
							className="ml-4 px-8 sm:px-10 w-32 sm:w-60"
							onClick={() => next()}>{saveButtonText}
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

CBREquipment.defaultProps = defaultProps

export default CBREquipment
