import React, { FunctionComponent } from 'react'

type Props = {
	label: string,
	checked?: boolean,
	layout?: string,
	onChange: (value: any) => void;
	[x: string]: any;
}

const defaultProps: Props = {
	label: '',
	checked: false,
	layout: 'left',
	onChange: (value) => {},
}

const Checkbox: FunctionComponent<Props> = ({ label, layout, checked, onChange, className, ...rest }) => {
	const handleChange = () => {
		onChange(!checked)
	}

	// console.log('Checkbox:', checked)

	return (
		<label className={`flex items-center ${layout === 'right' ? 'flex-row-reverse' : ''} ${className}`} {...rest}>
			<input type="checkbox" checked={checked} onChange={handleChange} />

			<span className={`${layout === 'right' ? 'mr-auto' : 'ml-2'}`}>{label}</span>
		</label>
	)
}

Checkbox.defaultProps = defaultProps

export default Checkbox
