import React, { FunctionComponent, useState } from 'react'

type Props = {
	className?: string,
	aboveInput?: JSX.Element,
	defaultValue: string,
	title?: string,
	presets?: any[],
	onUpdate: (value: string) => void;
	[x: string]: any;
}

const defaultProps: Props = {
	className: '',
	defaultValue: '',
	title: '',
	aboveInput: <></>,
	presets: [],
	onUpdate: (value: any) => {},
}

const keys = [
	'1', '2', '3',
	'4', '5', '6',
	'7', '8', '9',
	'.', '0',
]

const NumberInput: FunctionComponent<Props> = ({ className, defaultValue, aboveInput, presets, title, onUpdate, next, ...rest }) => {
	const [value, setValue] = useState(defaultValue)

	const addCharacter = (character: string) => {
		let newValue = value

		if(value === '')
			newValue = character
		else
			newValue = value + character

		setValue(newValue)
		onUpdate(newValue)
	}

	const removeCharacter = () => {
		let newValue = value

		if(value !== '') {
			if(value.length === 1)
				newValue = ''
			else
				newValue = value.slice(0, -1)
		}

		setValue(newValue)
		onUpdate(newValue)
	}

	const presetClick = (value: string) => {
		setValue(value)
		onUpdate(value)
	}

	return (
		<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2 w-full">
			<div className={`w-full ${className}`}>
				{title ? <div className="text-base sm:text-xl text-center">{title}</div> : <></>}

				{(presets && presets.length) ?
					<>
						<div className="grid grid-cols-5 gap-0 mt-4 mb-4 h-16">
							<div className="w-10 col-span-1"></div>

							<div className="col-span-3 flex mx-4 sm:m-auto items-center justify-center border border-orange rounded text-4xl bg-white h-16 m1-0 w-30 sm:w-60">
								{value ? value : ''}
							</div>

							<div className="col-span-1 text-right -mt-1">
								{presets.map((preset, index) => {
									return <button className="bg-blue text-white rounded pt-0 pt-1.0 pb-1 mb-0.5 w-full align-middle text-lg leading-6" 
									key={index}
									onClick={() => {
										presetClick(preset.value)
										// next?.()
										setTimeout(() => {
											next?.();
										}, 500); // Delay of 1/2 second (500 milliseconds)
									}}>
									{preset.label}
									</button>
								})}
							</div>
						</div>
					</>
					:
					<>
						{/* <div className="grid grid-cols-3 gap-3 mb-8">
							<div></div> */}
						<div className=" mb-4 mx-auto w-3/5 sm:w-2/5 mt-4 md:w-2/5 lg:w-2/5">
							<div></div>

							<div className="border border-orange rounded py-3 text-center text-4xl bg-white h-16 mx-auto w-44 sm:w-60 mb-4">
								{value ? value : ''}
							</div>

							<div></div>
						</div>
					</>
				}

				{aboveInput}

				<div className="grid grid-cols-3 gap-3 mx-0 sm:mx-20 md:mx-20 lg:mx-40">
					{keys.map((key, index) => {
						return <div className="border border-orange bg-white rounded py-2 text-center text-2xl" key={index} onClick={() => addCharacter(key)}>{key}</div>
					})}

					{/* <div className="border border-orange bg-white rounded py-2 text-center text-2xl" onClick={() => removeCharacter()}>⌫</div> */}
					<div className="border border-orange bg-white rounded py-3 text-center text-base" onClick={() => removeCharacter()}>DEL</div>
				</div>
			</div>
		</div>
	)
}

NumberInput.defaultProps = defaultProps

export default NumberInput