import React from 'react';

interface NumericInputGroupProps {
    label?: string;
    value: string | number;
    onChange: (value: string | number) => void;
    className?: string;
    inputCSS?: string;
}

const NumericInputGroup: React.FC<NumericInputGroupProps> = ({ label, value, onChange, className, inputCSS }) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        // Validate input to allow only numbers and at most one decimal point
        const regex = /^[0-9]*\.?[0-9]*$/;
        if (regex.test(value)) {
            onChange(value);
        }
    };

    return (
        <div className={`input-group ${className}`}>
            {label && <label>{label}</label>}
            <input
                type="text"
                value={value}
                onChange={handleChange}
                className={inputCSS}
            />
        </div>
    );
};

export default NumericInputGroup;
