import React from 'react'

import Modal from '../../components/Modal'
import Button from '../../components/form/Button'

import Header from '../../layout/Header'

interface Props {
	toggleSidebar: () => void,
	router: any,
}

interface State {
	project: any,
}

class ProjectsInfo extends React.Component<Props, State> {
	constructor(props: any) {
		super(props)

		this.state = {
			project: {
				id: this.props.router.match.params.id,
				location: 'Rackheath Common',
			},
		}
	}

	render() {
		return (
			<>
				<Header toggleSidebar={this.props.toggleSidebar} dark={true} />

				<Modal title="Project Information" toggleModal={() => {this.props.router.history.goBack()}}>
					<div className="px-10 mt-16">
						<div className="grid md:grid-cols-2 md:gap-8 mb-10 text-sm">
							<div className="">
								<div className="mb-8 text-soil-grey"><span className="text-text pr-3">Site Location:</span> {this.state.project.location}</div>

								<div className="grid md:grid-cols-2 md:gap-6">
									<div>
										<div className="mb-8 text-soil-grey"><span className="text-text pr-3">Start Date:</span> {this.state.project.location}</div>
									</div>

									<div>
										<div className="mb-8 text-soil-grey"><span className="text-text pr-3">End Date:</span> {this.state.project.location}</div>
									</div>
								</div>

								<div className="grid md:grid-cols-2 md:gap-6">
									<div>
										<div className="mb-8 text-soil-grey"><span className="text-text pr-3">Start Time:</span> {this.state.project.location}</div>
									</div>

									<div>
										<div className="mb-8 text-soil-grey"><span className="text-text pr-3">Project No:</span> {this.state.project.location}</div>
									</div>
								</div>

								<div className="mb-8 text-soil-grey"><span className="text-text pr-3">Site Description:</span> {this.state.project.location}</div>

								<div className="mb-8 text-soil-grey"><span className="text-text pr-3">Key Site Features:</span> {this.state.project.location}</div>

								<div className="mb-8 text-soil-grey"><span className="text-text pr-3">Site History:</span> {this.state.project.location}</div>

								<div className="mb-8 text-soil-grey"><span className="text-text pr-3">Key H&amp;S:</span> {this.state.project.location}</div>
							</div>

							<div className="">
								<div className="mb-8 text-soil-grey"><span className="text-text pr-3">Address:</span> {this.state.project.location}</div>

								<div className="mb-8 text-soil-grey"><span className="text-text pr-3">Address 2:</span> {this.state.project.location}</div>

								<div className="mb-8 text-soil-grey"><span className="text-text pr-3">Town/City:</span> {this.state.project.location}</div>

								<div className="grid md:grid-cols-2 md:gap-6">
									<div>
										<div className="mb-8 text-soil-grey"><span className="text-text pr-3">County/State:</span> {this.state.project.location}</div>
									</div>

									<div>
										<div className="mb-8 text-soil-grey"><span className="text-text pr-3">Postcode/Zip:</span> {this.state.project.location}</div>
									</div>
								</div>

								<div className="mb-8 text-soil-grey"><span className="text-text pr-3">Expected Strata:</span> {this.state.project.location}</div>

								<div className="mb-8 text-soil-grey"><span className="text-text pr-3">Notes:</span> {this.state.project.location}</div>

								<div className="mb-8 text-soil-grey"><span className="text-text pr-3">Equipment:</span> {this.state.project.location}</div>
							</div>
						</div>

						<div className="flex justify-center">
							<Button theme="primary" size="xl">Edit</Button>
						</div>
					</div>
				</Modal>
			</>
		)
	}
}

export default ProjectsInfo