const rangeDescription = (items: any) => {
	items.sort((i1: any, i2: any) => {
		if(i1.order < i2.order) return -1

		if(i1.order > i2.order) return 1

		return 0
	})

	if(items.length === 1) return items[0]?.title

	if(items.length === 0) return ''

	return items[0]?.title + ' to ' + items[items.length - 1]?.title
}

export default rangeDescription