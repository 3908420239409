import React, { FunctionComponent, useState } from 'react'

import { useSetIndexedDBValues } from '../../helpers/addToIDB'

import Modal from '../../components/Modal'
import WorkflowSteps from '../../components/WorkflowSteps'
import Table from '../../components/Table'
import InputGroup from '../../components/form/InputGroup'
import TextArea from '../../components/form/TextArea'
import Button from '../../components/form/Button'
import WorkflowActions from '../../components/form/WorkflowActions'
import EditBoreholes from '../../components/project/EditBoreholes'

import { v4 as uuidv4 } from 'uuid'
import { get, set } from 'idb-keyval'
import {Redirect} from "react-router-dom";

import Header from '../../layout/Header'

import stepStatus from '../../helpers/stepStatus'

import plusIcon from '../../assets/icons/plus-white.svg'
import {useGetIDBValues} from "../../helpers/getIDBKey";
import {del} from "idb-keyval/dist/cjs";

interface Props {
	toggleSidebar: () => void,
	router: any,
}

interface State {
	projectCurrentStep: number,
	boreholes: any,
	clientName: string,
	clientContact: string,
	clientPhone: string,
	clientEmail: string,
	engineerName: string,
	engineerPhone: string,
	engineerEmail: string,
	siteLocation: string,
	startDate: string,
	endDate: string,
	startTime: string,
	projectNo: string,
	siteDescription: string,
	keySiteFeatures: string,
	siteHistory: string,
	keyHS: string,
	address1: string,
	address2: string,
	city: string,
	county: string,
	postcode: string,
	expectedStrata: string,
	notes: string,
	equipmentRequired: string,
	guid: string,
    company_guid: string,
    redirect: boolean,
	hole_types: [],
    formExit: boolean,
    project_barcode: string,
    parked: number,
}

class ProjectsCreate extends React.Component<Props, State> {
	constructor(props: any) {
		super(props)

		this.state = {
			projectCurrentStep: 1,
			boreholes: [],
			clientName: '',
			clientContact: '',
			clientPhone: '',
			clientEmail: '',
			engineerName: '',
			engineerPhone: '',
			engineerEmail: '',
			siteLocation: '',
			startDate: '',
			endDate: '',
			startTime: '',
			projectNo: '',
			siteDescription: '',
			keySiteFeatures: '',
			siteHistory: '',
			keyHS: '',
			address1: '',
			address2: '',
			city: '',
			county: '',
			postcode: '',
			expectedStrata: '',
			notes: '',
			equipmentRequired: '',
			guid: uuidv4(),
            company_guid: '',
            redirect: false,
			hole_types: [],
            formExit: false,
            project_barcode: '',
            parked: 0,
		}
	}

	previous = () => {
		if(this.state.projectCurrentStep > 1)
			this.setState({projectCurrentStep: this.state.projectCurrentStep - 1})
	}

	navTabClick = (step: number) => {
		if (this.state.projectCurrentStep === 1 &&
			(this.state.siteLocation === '' || this.state.projectNo === '' || this.state.startDate === '') 
			) { // do nothing
		} else {
			this.setState({ projectCurrentStep: step })
		}
	  }

	next = (event: any) => {
	    if (this.state.formExit) {
            event.preventDefault()
            this.save()

            return
        }

		if(this.state.projectCurrentStep < 4) {
			this.setState({projectCurrentStep: this.state.projectCurrentStep + 1})

			return
		}

		// On complete
		event.preventDefault()
		this.save()

	}

    componentDidMount() {
		this.getCacheValues()
        const projectBarcode = this.generateProjectBarcode();
        if (this.state.project_barcode !== projectBarcode) {
            this.setState({ project_barcode: projectBarcode });
        }
	}

    saveAndContinue = () => {
	    this.setState({
            formExit: false
        })
    }

    saveAndExit = (event: any) => {
        this.setState({
            formExit: true
        })

    }

	getCacheValues = () => {
        get('drillit-user').then(data => {
            this.setState({
                company_guid: data.user.company.guid
            })
        })
		// get hole types
		let cachedValues = useGetIDBValues
		cachedValues('hole-types', (data:any) => {
			this.setState({
				hole_types: data
			})
		})
    }

	save = () => {

        // if on final step and boreholes is not empty then save it
        if ((this.state.projectCurrentStep === 4) && (this.state.boreholes.length > 0)) {
            const token = localStorage.getItem('drillit-token')

            // save the boreholes state to IDB -update key
            // always check for the existence of this key first, and merge in our new set
            // it's not the most efficient as if a hole has been modified multiple times all of those will be run against the api
            // should always end up with the latest modification though
            const promiseExistingHoles = get(`drillit-hole-locations/project/json-updateHoles`)
                .then(res => {
                    // doesn't want me to type this properly as string[], not sure why
                    let updatedHoles: any = []
                    if ((res) && (res.length > 0)) {
                        // ideally we want to run a filter on the second addition to the set below
                        // but you would have to check every variable on every array item
                        // so as it says above this isn't efficient but should work for what we need as long as there isn't a million rows between data syncs
                        updatedHoles = [...new Set([...res, ...this.state.boreholes])]
                        // update(`drillit-hole-locations/project/json-update`, updatedHoles).then()
                        // return updatedHoles
                    } else {
                        updatedHoles = this.state.boreholes
                        // return updatedHoles
                    }
                    set(`drillit-hole-locations/project/json-updateHoles`, updatedHoles).then()

                    return updatedHoles
                })
                .then((updatedHoles: string[]) => {
                    if (window.navigator.onLine) {

                        // do a basic fetch to check availability of the API
                        fetch(`${process.env.REACT_APP_API_ADDR_NOV1}health`, {
                            method: 'GET',
                            mode: 'no-cors',
                            headers: {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json',
                            }

                        })
                            .then((response: Response) => {
                                // we are online and have a connection to the API, do our thing
                                fetch(`${process.env.REACT_APP_API_ADDR}hole-locations/project/json`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Accept': 'application/json',
                                        'Authorization': `Bearer ${token}`,
                                    },
                                    body: JSON.stringify(updatedHoles)
                                })
                                    //return the status code here
                                    .then(response => {
                                        // if the response status is 200 we can delete the -new key
                                        // show me the response
                                        if (response.status === 200) {
                                            del(`drillit-hole-locations/project/json-updateHoles`)
                                        }
                                    })
                                    .catch(err => err)
                                return response

                            })
                            .catch((err: Error) => {
                                // online but the API is walkabout, keep our update holes array and move along
                                // no real need to do anything
                            })
                    }
                    return updatedHoles
                })
                .then(res => {
                    // console.log(res)
                    // get the current project details and update it's boreholes
                    const currentProject = get(`drillit-projects/${this.state.guid}`)
                        .then(res => {
                            let updatedProject = res
                            updatedProject.hole_locations = this.state.boreholes
                            set(`drillit-hole-locations/project/${this.state.guid}`, updatedProject.hole_locations).then()
                            set(`drillit-projects/${this.state.guid}`, updatedProject).then()
                        })
                })
        }

        let saveToIDB = useSetIndexedDBValues
        saveToIDB({
        	client_name: this.state.clientName, // check
			client_contact: this.state.clientContact, // check
			client_phone: this.state.clientPhone, // check
			client_email: this.state.clientEmail, // check
			engineer_name: this.state.engineerName, // check
			engineer_phone: this.state.engineerPhone, // check
			engineer_email: this.state.engineerEmail, // check
			location: this.state.siteLocation, // check
			start_date: this.state.startDate, // check
			end_date: this.state.endDate, // check
			start_time: this.state.startTime, // check
			project_identifier: this.state.projectNo.toUpperCase(), // check
			site_description: this.state.siteDescription, // check
			key_site_features: this.state.keySiteFeatures, // check
			site_history: this.state.siteHistory, // check
			key_hands: this.state.keyHS, // check
			address: this.state.address1, // check
			address2: this.state.address2, // check
			town_city: this.state.city, // check
			county_state: this.state.county, // check
			postcode: this.state.postcode, // check
			expected_strata: this.state.expectedStrata, // check
			notes: this.state.notes,
			equipment_required: this.state.equipmentRequired, // check
			boreholes: this.state.boreholes, // check
            guid: this.state.guid, // check
            company_guid: this.state.company_guid, // check
			project_barcode: this.state.project_barcode, // new field for barcode (see below)
			parked: this.state.parked // new field for barcode (see below)
        }, 'projects', true)
			.then(() => {
			    // TODO make this save the boreholes even if Save & Exit is pressed on step 2 ie they've gone back a step
					if ((this.state.projectCurrentStep === 5) && (this.state.boreholes.length > 0)) {
						const token = localStorage.getItem('drillit-token')

                        // save the boreholes state to IDB -new no need to use the -update
                        // check if we have a connection
                        // do a fetch with the json of all boreholes if we have the connection
                        // if the fetch returns a 200, delete the -new IDB key
                        // TODO this needs to go through the proper save & update process
						fetch(`${process.env.REACT_APP_API_ADDR}hole-locations/project/json`, {
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
								'Accept': 'application/json',
								'Authorization': `Bearer ${token}`,
							},
							body: JSON.stringify(this.state.boreholes)
						})
							//return the status code here
							.then(response => response)
							.catch(err => err)
					}
				}
			)
			.then(() => setTimeout(() => this.setState({redirect: true}), 1000))
	}

	generateProjectBarcode = () => {
        let projectBarcode = '';
        let project_barcode_location = (this.state.siteLocation).replace(/([`¬.*+?^=!:${}()|_\[\]\/\\£@~%-])/g, "");
        project_barcode_location = project_barcode_location.replace(/\s/g, '');
        project_barcode_location = project_barcode_location.toUpperCase();
        let project_barcode_projectNo = (this.state.projectNo).replace(/([`¬.*+?^=!:${}()|_\[\]\/\\£@~%-])/g, "");
        project_barcode_projectNo = project_barcode_projectNo.replace(/\s/g, '');
        project_barcode_projectNo = project_barcode_projectNo.toUpperCase();
        projectBarcode = project_barcode_location + '-' + project_barcode_projectNo;

        return projectBarcode;
    }

	render() {

		// console.log('this.state.startDate:', this.state.startDate)

		console.log('this.state.projectCurrentStep:', this.state.projectCurrentStep)
		
        const { redirect } = this.state

		if (redirect) {
			return <Redirect to={`/projects`}/>
		}

		return (
			<>
				<Header toggleSidebar={this.props.toggleSidebar} dark={true} />

				<Modal title="New Project" toggleModal={() => this.props.router.history.push('/projects')}>

						<div className="px-2 sm:px-10">
							
							<WorkflowSteps className="mt-0 mb-4" steps={[
								{
									label: 'Basics*',
									status: stepStatus(1, this.state.projectCurrentStep),
									onClick: () => this.navTabClick(1),
								}, {
									label: 'Address',
									status: stepStatus(2, this.state.projectCurrentStep),
									onClick: () => this.navTabClick(2),
								}, {
									label: 'Site Details',
									status: stepStatus(3, this.state.projectCurrentStep),
									onClick: () => this.navTabClick(3),
								}, {
									label: 'Contacts',
									status: stepStatus(4, this.state.projectCurrentStep),
									onClick: () => this.navTabClick(4),
								}, 
								// {
								// 	label: 'Hole Requirements',
								// 	status: stepStatus(5, this.state.projectCurrentStep),
								// 	onClick: () => this.navTabClick(5),
								// },
							]} />

						{this.state.projectCurrentStep === 1 &&
							<form onSubmit={this.next} autoComplete="off">
								
							<div className="grid md:grid-cols-1 md:gap-8 w-full md:w-4/5 mx-auto" >
								<div className="border border-grey-mid rounded px-2 pt-2 bg-sptTB mb-2">
									
									<div className="mb-2 text-base md:text-lg font-bold text-center">
										Basic Project Information
									</div>
								
									<InputGroup label="&nbsp;Site Location (required)*" 
									required 
									value={this.state.siteLocation} 
									onChange={(value) => this.setState({siteLocation: value})}
									className="mb-2 text-sm sm:text-base md:text-base w-full"
									inputCSS="text-sm sm:text-lg md:text-xl w-full"
									 />
	
									<div className="grid md:grid-cols-2 md:gap-6">
											
										<InputGroup label="&nbsp;Project ID (required)*" 
										required 
										value={this.state.projectNo.toUpperCase()} 
										// onChange={(value) => this.setState({projectNo: value})}
										onChange={(value) => {
											// Validate input using regex
											const regex = /^[a-zA-Z0-9_.-]*$/;
											if (regex.test(value)) {
												this.setState({ projectNo: value });
											}
										}}
										className="mb-2 text-sm sm:text-base md:text-base w-full"
										inputCSS="text-sm sm:text-lg md:text-xl w-full"
										 />
														
										<InputGroup 
										label="&nbsp;Start Time" 
										type={'time'} 
										value={this.state.startTime} 
										onChange={(value) => this.setState({startTime: value})}
										className="mb-2 text-sm sm:text-base md:text-base w-full"
										inputCSS="text-sm sm:text-lg md:text-xl w-full"
										 />
	
									</div>
	
									<div className="grid md:grid-cols-2 md:gap-6 pb-4">
										
										<InputGroup 
										label="&nbsp;Start Date (required)" required
										type={'date'} 
										value={this.state.startDate} 
										onChange={(value) => this.setState({startDate: value})}
										className="mb-2 text-sm sm:text-base md:text-base w-full"
										inputCSS="text-sm sm:text-lg md:text-xl w-full"
										 />
										
										<InputGroup 
										label="&nbsp;End Date" 
										type={'date'} 
										value={this.state.endDate} 
										onChange={(value) => this.setState({endDate: value})}
										className="mb-2 text-sm sm:text-base md:text-base w-full"
										inputCSS="text-sm sm:text-lg md:text-xl w-full"
										 />
	
									</div>

									<div className="mb-10 text-xs md:text-sm italic text-center">
										* Please note: 'Site location' & 'Project ID' are unchangeable.<br/>
										Make sure these fields are correct before proceeding.<br/><br/>
										'Start Date' is editable after setup, but still required here.
									</div>
								</div>
							</div>

							<div className="panelFooter pt-5 pb-5 sm:pb-4 md:pb-10 px-4 md:px-14">
								<WorkflowActions onExit={(event: any) => this.saveAndExit(event)} 
								onContinue={() => this.saveAndContinue()} 
								onBack={() => this.previous()} />
							</div>

							</form>
						}

						{this.state.projectCurrentStep === 2 &&
							<form onSubmit={this.next} autoComplete="new-password">

								
							<div className="grid w-full md:w-4/5 mx-auto" >
	
								<div className="border border-grey-mid rounded px-2 pt-2 bg-sptTB pb-4">
									
									<div className="mb-2 text-base md:text-lg font-bold text-center">
										Site Address
									</div>
	
									<InputGroup label="&nbsp;Address" 
									value={this.state.address1} 
									onChange={(value) => this.setState({address1: value})}
									className="mb-2 text-sm sm:text-base md:text-base w-full"
									inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
									autoComplete="new-password"
									/>
	
									<InputGroup label="&nbsp;Address 2" 
									value={this.state.address2} 
									onChange={(value) => this.setState({address2: value})}
									className="mb-2 text-sm sm:text-base md:text-base w-full"
									inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
									autoComplete="new-password"
									/>
	
									<InputGroup label="&nbsp;Town/City" 
									value={this.state.city} 
									onChange={(value) => this.setState({city: value})}
									className="mb-2 text-sm sm:text-base md:text-base w-full"
									inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
									autoComplete="new-password"
									/>
	
									<div className="grid grid-cols-2 sm:grid-cols-2 gap-2 sm:gap-6">
										<div>
											<InputGroup label="&nbsp;County/State" 
											value={this.state.county} 
											onChange={(value) => this.setState({county: value})}
											className="mb-2 text-sm sm:text-base md:text-base w-full"
											inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
											autoComplete="new-password"
											/>
										</div>
	
										<div>
											<InputGroup label="&nbsp;Postcode/Zip" 
											value={this.state.postcode} 
											onChange={(value) => this.setState({postcode: value})}
											className="mb-2 text-sm sm:text-base md:text-base w-full"
											inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
											autoComplete="new-password"
											/>
										</div>
									</div>
								</div>								
							</div>
							
							<div className="panelFooter pt-5 pb-5 sm:pb-4 md:pb-10 px-4 md:px-14">
								<WorkflowActions canExit={false} onBack={() => this.previous()} />
							</div>

							</form>
						}

						{this.state.projectCurrentStep === 3 &&
							<form onSubmit={this.next} autoComplete="new-password">

								
							<div className="grid md:grid-cols-1 md:gap-8 w-full md:w-4/5 mx-auto" >
								
	
								<div className="border border-grey-mid rounded px-2 pt-2 bg-sptTB pb-4">
									
									<div className="mb-2 text-base md:text-lg font-bold text-center">
										Site Details
									</div>

									<div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">

										<div>
	
											<TextArea label="&nbsp;Site Description" 
											value={this.state.siteDescription} 
											onChange={(value) => this.setState({siteDescription: value})}
											className="mb-2 text-sm sm:text-base md:text-base w-full"
											inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
											numberOfRows={2}
											autoComplete="new-password"
											/>
			
											<TextArea label="&nbsp;Key Site Features" 
											value={this.state.keySiteFeatures} 
											onChange={(value) => this.setState({keySiteFeatures: value})}
											className="mb-2 text-sm sm:text-base md:text-base w-full"
											inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
											numberOfRows={1}
											autoComplete="new-password"
											/>
			
											<TextArea label="&nbsp;Site History" 
											value={this.state.siteHistory} 
											onChange={(value) => this.setState({siteHistory: value})}
											className="mb-2 text-sm sm:text-base md:text-base w-full"
											inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
											numberOfRows={1}
											autoComplete="new-password"
											/>
			
											<TextArea label="&nbsp;Key H&amp;S:" 
											value={this.state.keyHS} 
											onChange={(value) => this.setState({keyHS: value})}
											className="mb-2 text-sm sm:text-base md:text-base w-full"
											inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
											numberOfRows={1}
											autoComplete="new-password"
											/>

										</div>

										<div>
		
											<TextArea label="&nbsp;Expected Strata:" 
											value={this.state.expectedStrata} 
											onChange={(value) => this.setState({expectedStrata: value})}
											className="mb-2 text-sm sm:text-base md:text-base w-full"
											inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
											numberOfRows={2}
											autoComplete="new-password"
											/>
			
											<TextArea label="&nbsp;Notes"
												value={this.state.notes}
												onChange={(value) => this.setState({ notes: value })}
												className="mb-2 text-sm sm:text-base md:text-base w-full"
												inputCSS="text-sm sm:text-base md:text-lg w-full py-0" 
												numberOfRows={2}
												autoComplete="new-password"
												/>
			
											<TextArea label="&nbsp;Equipment Required:" 
											value={this.state.equipmentRequired} 
											onChange={(value) => this.setState({equipmentRequired: value})}
											className="mb-2 text-sm sm:text-base md:text-base w-full"
											inputCSS="text-sm sm:text-base md:text-lg w-full py-0" 
											numberOfRows={2}
											autoComplete="new-password"
											/>

										</div>

									</div>
	
								</div>
							</div>

							<div className="panelFooter pt-5 pb-5 sm:pb-4 md:pb-10 px-4 md:px-14">
								<WorkflowActions canExit={false} onBack={() => this.previous()} />
							</div>

							</form>
						}

						{this.state.projectCurrentStep === 4 &&
							<form onSubmit={this.next} autoComplete="off">
								
							<div className="grid md:grid-cols-1 md:gap-8 w-full sm:w-4/5 mx-auto" >
	
								<div className="border border-grey-mid rounded px-2 pt-2 bg-sptTB pb-4">
									
									<div className="mb-2 text-base md:text-lg font-bold text-center">
										Project Contact Information
									</div>
										
										<InputGroup label="&nbsp;Engineer Name" 
										value={this.state.engineerName} 
										onChange={(value) => this.setState({engineerName: value})}
										className="mb-2 text-sm sm:text-base md:text-base w-full"
										inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
										autoComplete="new-password"
										/>

										<InputGroup label="&nbsp;Engineer Phone" 
										value={this.state.engineerPhone} 
										onChange={(value) => this.setState({engineerPhone: value})}
										className="mb-2 text-sm sm:text-base md:text-base w-full"
										inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
										autoComplete="new-password"
										/>

										<InputGroup label="&nbsp;Engineer Email" //FIX
										value={this.state.engineerEmail} 
										onChange={(value) => this.setState({engineerEmail: value})}
										className="mb-2 text-sm sm:text-base md:text-base w-full"
										inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
										autoComplete="new-password"
										/>

										<InputGroup label="&nbsp;Client (Company)" 
										value={this.state.clientName} 
										onChange={(value) => this.setState({clientName: value})}
										className="mb-2 text-sm sm:text-base md:text-base w-full"
										inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
										autoComplete="new-password"
										/>

										<InputGroup label="&nbsp;Client Contact" 
										value={this.state.clientContact} 
										onChange={(value) => this.setState({clientContact: value})}
										className="mb-2 text-sm sm:text-base md:text-base w-full"
										inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
										autoComplete="new-password"
										/>

										<InputGroup label="&nbsp;Client Phone" 
										value={this.state.clientPhone} 
										onChange={(value) => this.setState({clientPhone: value})}
										className="mb-2 text-sm sm:text-base md:text-base w-full"
										inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
										autoComplete="new-password"
										/>

										<InputGroup label="&nbsp;Client Email" 
										value={this.state.clientEmail} 
										onChange={(value) => this.setState({clientEmail: value})}
										className="mb-2 text-sm sm:text-base md:text-base w-full"
										inputCSS="text-sm sm:text-base md:text-lg w-full py-0"
										autoComplete="new-password"
										/>

								</div>
							</div>

							<div className="panelFooter pt-5 pb-5 sm:pb-4 md:pb-10 px-4 md:px-14">
								<WorkflowActions canExit={false} onBack={() => this.previous()} />
							</div>

							</form>
						}

						{this.state.projectCurrentStep === 5 &&
							<>
								<EditBoreholes defaultBoreholes={this.state.boreholes} 
								projectId={this.state.guid} 
								onChange={(boreholes: any[]) => {this.setState({boreholes: boreholes})}} />

								<div className="h-20"></div>
                                <form onSubmit={this.next}>
								<div className="panelFooter pt-5 pb-5 sm:pb-4 md:pb-10 px-4 md:px-14">
                                    <WorkflowActions canExit={false} onBack={() => this.previous()} />
								</div>
                                </form>

							</>
						}
					</div>
				</Modal>
			</>
		)
	}
}

export default ProjectsCreate
