import React, { useState } from 'react';

const BluetoothComponent: React.FC = () => {
  const [device, setDevice] = useState<BluetoothDevice | null>(null);
  const [error, setError] = useState<string | null>(null);

  const requestBluetoothDevice = async () => {
    try {
      const device = await navigator.bluetooth.requestDevice({
        acceptAllDevices: true, // Or use specific filters like [{ services: ['battery_service'] }]
      });
      setDevice(device);
      console.log(`Connected to device: ${device.name}`);
    } catch (error: any) {
      setError(error.message);
      console.error('Error: ', error);
    }
  };

  return (
    <div>
      <button onClick={requestBluetoothDevice}>Find Bluetooth Device</button>
      {device && <p>Connected to: {device.name}</p>}
      {error && <p>Error: {error}</p>}
    </div>
  );
};

export default BluetoothComponent;
