import { get, set } from 'idb-keyval';
import { setIDB } from './setIDBKey';
import { usePostToApi } from "./postToAPI_NewHole";

// Define the interface for values
interface HoleLocation {
  guid: string;
  [key: string]: any; // Allow other properties
}

interface HoleActions {
  hole_guid: string;
  hole_data: {
    action_backfills: any[];
    action_casing: any[];
    action_chiselling: any[];
    action_comment: any[];
    action_core_sample: any[];
    action_daily_water_levels: any[];
    action_detail_description: any[];
    action_drill_core_size: any[];
    action_drive_record: any[];
    action_dynamic_probe: any[];
    action_groundwater: any[];
    action_head_test: any[];
    action_head_test_detail: any[];
    action_in_situ_spt: any[];
    action_inspection_pit: any[];
    action_installation: any[];
    action_redrill: any[];
    action_sample: any[];
    action_soakaway: any[];
    action_soil_description: any[];
    action_trialpit: any[];
    action_water_added: any[];
  };
}

export const useSetIndexedDBValuesNewHole = (
  values: HoleLocation,
  idbKey: string,
  create?: boolean,
  update?: string
): Promise<void> => {
  console.log('New Hole addToIDB - PROPS: values:', values, 'idbKey', idbKey, 'create:', create, 'update:', update);

  const existingKey = `drillit-${idbKey}`;
  const newKey = `drillit-hole-locations-new`;
  const updateKey = `${idbKey}-update`;
  const projectKey = `drillit-hole-locations/project/${values.project_guid}`;
  const actionsKey = `drillit-actions/${values.starting_hole_type_id}/${values.guid}`;

  console.log('******************************************************************************');

  const getIDB = (keyValue: string) => get(keyValue).then(data => data);

  const createActionsRecord = (): HoleActions => ({
    hole_guid: values.guid,
    hole_data: {
      action_backfills: [],
      action_casing: [],
      action_chiselling: [],
      action_comment: [],
      action_core_sample: [],
      action_daily_water_levels: [],
      action_detail_description: [],
      action_drill_core_size: [],
      action_drive_record: [],
      action_dynamic_probe: [],
      action_groundwater: [],
      action_head_test: [],
      action_head_test_detail: [],
      action_in_situ_spt: [],
      action_inspection_pit: [],
      action_installation: [],
      action_redrill: [],
      action_sample: [],
      action_soakaway: [],
      action_soil_description: [],
      action_trialpit: [],
      action_water_added: []
    }
  });

  return new Promise((resolve, reject) => {
    // Fetch existing data
    getIDB(existingKey)
      .then(result => {
        console.log('New Hole addToIDB - getIDB(existingKey):', result);

        if (!update) {
          // Handle creation of new entries
          if (result) {
            console.log('New Hole addToIDB - result for existing key:', result);
            setIDB(values, existingKey, result as HoleLocation[]);
          } else {
            console.log('New Hole addToIDB - No result, creating new entry');
            setIDB(values, existingKey, [] as HoleLocation[]);
          }
        }

        // Create a new IDB record for the hole
        const newKeyToCreate = `drillit-hole-locations/${values.guid}`;
        console.log('New Hole addToIDB - Creating new record with key:', newKeyToCreate, values);
        return set(newKeyToCreate, values);
      })
      .then(() => {
        // Create a new actions record
        console.log('New Hole addToIDB - Creating new actions record with key:', actionsKey);
        return set(actionsKey, createActionsRecord());
      })
      .then(() => {
        // Update the `drillit-hole-locations-new` key if needed
        return getIDB(newKey);
      })
      .then((result: HoleLocation[] | undefined) => {
        if (create) {
          console.log('New Hole addToIDB - CREATE - Updating drillit-hole-locations-new');
          const newEntries = result ? [...result, values] : [values];
          return set(newKey, newEntries);
        }
        return; // Ensure this returns `void`
      })
      .then(() => {
        // Update the `projectKey` with the new or updated record
        if (create || update) { // Handle both create and update scenarios
          return getIDB(projectKey);
        }
        return; // Ensure this returns `void`
      })
      .then((existingBoreholes: HoleLocation[] | undefined) => {
        if (create || update) { // Merging new values into existing data
          console.log('Existing boreholes:', existingBoreholes);
          console.log('New values:', values);

          // Merge the new values into the existing array
          const updatedArray = existingBoreholes
            ? existingBoreholes.some(hole => hole.guid === values.guid)
              ? existingBoreholes.map((obj: HoleLocation) =>
                  obj.guid === values.guid ? values : obj
                )
              : [...existingBoreholes, values]
            : [values]; // If no existing data, start with the new value

          console.log('Updated array:', updatedArray);
          
          // Set the updated array back to the IndexedDB
          return set(projectKey, updatedArray);
        }
        return; // Ensure this returns `void`
      })
      .then(() => {
        if (update) {
          // Update the `updateKey` with the modified entries
          return getIDB(updateKey);
        }
        return; // Ensure this returns `void`
      })
      .then((result: HoleLocation[] | undefined) => {
        if (update) {
          const updatedEntries = (result || []).map((obj: HoleLocation) =>
            obj.guid === values.guid ? values : obj
          );
          console.log('New Hole addToIDB - UPDATE - Updated entries for updateKey:', updatedEntries);
          return set(updateKey, updatedEntries);
        }
        return; // Ensure this returns `void`
      })
      .then(result => {
        // Push to API if create
        if (create) {
          const postToApi = usePostToApi;
          console.log('New Hole addToIDB - CREATE - Push to API if create');
          console.log('New Hole addToIDB - idbKey:', idbKey);
          return postToApi(idbKey, 'POST');
        }
        return result; // Return result for further chaining if needed
      })
      .then(result => {
        // Push to API if update
        if (update) {
          const postToApi = usePostToApi;
          console.log('New Hole addToIDB - UPDATE - Push to API');
          return postToApi(idbKey, update, true);
        }
        return result; // Return result for further chaining if needed
      })
      .then(resolve)
      .catch(reject);
  });
};



// import { get, set } from 'idb-keyval';
// import { setIDB } from './setIDBKey';
// import { usePostToApi } from "./postToAPI_NewHole";

// // Define the interface for values
// interface HoleLocation {
//   guid: string;
//   [key: string]: any; // Allow other properties
// }

// interface HoleActions {
//   hole_guid: string;
//   hole_data: {
//     action_backfills: any[];
//     action_casing: any[];
//     action_chiselling: any[];
//     action_comment: any[];
//     action_core_sample: any[];
//     action_daily_water_levels: any[];
//     action_detail_description: any[];
//     action_drill_core_size: any[];
//     action_drive_record: any[];
//     action_dynamic_probe: any[];
//     action_groundwater: any[];
//     action_head_test: any[];
//     action_head_test_detail: any[];
//     action_in_situ_spt: any[];
//     action_inspection_pit: any[];
//     action_installation: any[];
//     action_redrill: any[];
//     action_sample: any[];
//     action_soakaway: any[];
//     action_soil_description: any[];
//     action_trialpit: any[];
//     action_water_added: any[];
//   };
// }

// export const useSetIndexedDBValuesNewHole = (
//   values: HoleLocation,
//   idbKey: string,
//   create?: boolean,
//   update?: string
// ): Promise<void> => {
//   console.log('New Hole addToIDB - PROPS: values:', values, 'idbKey', idbKey, 'create:', create, 'update:', update);

//   const existingKey = `drillit-${idbKey}`;
//   const newKey = `drillit-hole-locations-new`;
//   const updateKey = `${idbKey}-update`;
//   const projectKey = `drillit-hole-locations/project/${values.project_guid}`;
//   const actionsKey = `drillit-actions/${values.starting_hole_type_id}/${values.guid}`;

//   console.log('******************************************************************************');

//   const getIDB = (keyValue: string) => get(keyValue).then(data => data);

//   const createActionsRecord = (): HoleActions => ({
//     hole_guid: values.guid,
//     hole_data: {
//       action_backfills: [],
//       action_casing: [],
//       action_chiselling: [],
//       action_comment: [],
//       action_core_sample: [],
//       action_daily_water_levels: [],
//       action_detail_description: [],
//       action_drill_core_size: [],
//       action_drive_record: [],
//       action_dynamic_probe: [],
//       action_groundwater: [],
//       action_head_test: [],
//       action_head_test_detail: [],
//       action_in_situ_spt: [],
//       action_inspection_pit: [],
//       action_installation: [],
//       action_redrill: [],
//       action_sample: [],
//       action_soakaway: [],
//       action_soil_description: [],
//       action_trialpit: [],
//       action_water_added: []
//     }
//   });

//   return new Promise((resolve, reject) => {
//     // Fetch existing data
//     getIDB(existingKey)
//       .then(result => {
//         console.log('New Hole addToIDB - getIDB(existingKey):', result);

//         if (!update) {
//           // Handle creation of new entries
//           if (result) {
//             console.log('New Hole addToIDB - result for existing key:', result);
//             setIDB(values, existingKey, result as HoleLocation[]);
//           } else {
//             console.log('New Hole addToIDB - No result, creating new entry');
//             setIDB(values, existingKey, [] as HoleLocation[]);
//           }
//         }

//         // Create a new IDB record for the hole
//         const newKeyToCreate = `drillit-hole-locations/${values.guid}`;
//         console.log('New Hole addToIDB - Creating new record with key:', newKeyToCreate);
//         return set(newKeyToCreate, values);
//       })
//       .then(() => {
//         // Create a new actions record
//         console.log('New Hole addToIDB - Creating new actions record with key:', actionsKey);
//         return set(actionsKey, createActionsRecord());
//       })
//       .then(() => {
//         // Update the `drillit-hole-locations-new` key if needed
//         return getIDB(newKey);
//       })
//       .then((result: HoleLocation[] | undefined) => {
//         if (create) {
//           console.log('New Hole addToIDB - CREATE - Updating drillit-hole-locations-new');
//           const newEntries = result ? [...result, values] : [values];
//           return set(newKey, newEntries);
//         }
//         return; // Ensure this returns `void`
//       })
//       .then(() => {
//         // Update the `projectKey` with the new or updated record
//         if (create || update) { // Handle both create and update scenarios
//           return getIDB(projectKey);
//         }
//         return; // Ensure this returns `void`
//       })
//       .then((existingBoreholes: HoleLocation[] | undefined) => {
//         if (create || update) { // Merging new values into existing data
//           console.log('Existing boreholes:', existingBoreholes);
//           console.log('New values:', values);

//           // Merge the new values into the existing array
//           const updatedArray = existingBoreholes
//             ? existingBoreholes.some(hole => hole.guid === values.guid)
//               ? existingBoreholes.map((obj: HoleLocation) =>
//                   obj.guid === values.guid ? values : obj
//                 )
//               : [...existingBoreholes, values]
//             : [values]; // If no existing data, start with the new value

//           console.log('Updated array:', updatedArray);
          
//           // Set the updated array back to the IndexedDB
//           return set(projectKey, updatedArray);
//         }
//         return; // Ensure this returns `void`
//       })
//       .then(() => {
//         if (update) {
//           // Update the `updateKey` with the modified entries
//           return getIDB(updateKey);
//         }
//         return; // Ensure this returns `void`
//       })
//       .then((result: HoleLocation[] | undefined) => {
//         if (update) {
//           const updatedEntries = (result || []).map((obj: HoleLocation) =>
//             obj.guid === values.guid ? values : obj
//           );
//           console.log('New Hole addToIDB - UPDATE - Updated entries for updateKey:', updatedEntries);
//           return set(updateKey, updatedEntries);
//         }
//         return; // Ensure this returns `void`
//       })
//       .then(resolve)
//       .catch(reject);
//   });
// };



// import { get, set } from 'idb-keyval';
// import { setIDB } from './setIDBKey';
// import { usePostToApi } from "./postToAPI_NewHole";

// // Define the interface for values
// interface HoleLocation {
//   guid: string;
//   [key: string]: any; // Allow other properties
// }

// export const useSetIndexedDBValuesNewHole = (
//   values: HoleLocation,
//   idbKey: string,
//   create?: boolean,
//   update?: string
// ): Promise<void> => {
//   console.log('New Hole addToIDB - PROPS: values:', values, 'idbKey', idbKey, 'create:', create, 'update:', update);

//   const existingKey = `drillit-${idbKey}`;
//   const newKey = `drillit-hole-locations-new`;
//   const updateKey = `${idbKey}-update`;
//   const projectKey = `drillit-hole-locations/project/${values.project_guid}`;

//   console.log('******************************************************************************');

//   const getIDB = (keyValue: string) => get(keyValue).then(data => data);

//   return new Promise((resolve, reject) => {
//     // Fetch existing data
//     getIDB(existingKey)
//       .then(result => {
//         console.log('New Hole addToIDB - getIDB(existingKey):', result);

//         if (!update) {
//           // Handle creation of new entries
//           if (result) {
//             console.log('New Hole addToIDB - result for existing key:', result);
//             setIDB(values, existingKey, result as HoleLocation[]);
//           } else {
//             console.log('New Hole addToIDB - No result, creating new entry');
//             setIDB(values, existingKey, [] as HoleLocation[]);
//           }
//         }

//         // Create a new IDB record for the hole
//         const newKeyToCreate = `drillit-hole-locations/${values.guid}`;
//         console.log('New Hole addToIDB - Creating new record with key:', newKeyToCreate);
//         return set(newKeyToCreate, values);
//       })
//       .then(() => {
//         // Update the `drillit-hole-locations-new` key if needed
//         return getIDB(newKey);
//       })
//       .then((result: HoleLocation[] | undefined) => {
//         if (create) {
//           console.log('New Hole addToIDB - CREATE - Updating drillit-hole-locations-new');
//           const newEntries = result ? [...result, values] : [values];
//           return set(newKey, newEntries);
//         }
//         return; // Ensure this returns `void`
//       })
//       .then(() => {
//         // Update the `projectKey` with the new or updated record
//         if (create || update) { // Handle both create and update scenarios
//           return getIDB(projectKey);
//         }
//         return; // Ensure this returns `void`
//       })
//       .then((existingBoreholes: HoleLocation[] | undefined) => {
//         if (create || update) { // Merging new values into existing data
//           console.log('Existing boreholes:', existingBoreholes);
//           console.log('New values:', values);

//           // Merge the new values into the existing array
//           const updatedArray = existingBoreholes
//             ? existingBoreholes.some(hole => hole.guid === values.guid)
//               ? existingBoreholes.map((obj: HoleLocation) =>
//                   obj.guid === values.guid ? values : obj
//                 )
//               : [...existingBoreholes, values]
//             : [values]; // If no existing data, start with the new value

//           console.log('Updated array:', updatedArray);
          
//           // Set the updated array back to the IndexedDB
//           return set(projectKey, updatedArray);
//         }
//         return; // Ensure this returns `void`
//       })
//       .then(() => {
//         if (update) {
//           // Update the `updateKey` with the modified entries
//           return getIDB(updateKey);
//         }
//         return; // Ensure this returns `void`
//       })
//       .then((result: HoleLocation[] | undefined) => {
//         if (update) {
//           const updatedEntries = (result || []).map((obj: HoleLocation) =>
//             obj.guid === values.guid ? values : obj
//           );
//           console.log('New Hole addToIDB - UPDATE - Updated entries for updateKey:', updatedEntries);
//           return set(updateKey, updatedEntries);
//         }
//         return; // Ensure this returns `void`
//       })
//       .then(resolve)
//       .catch(reject);
//   });
// };
