import React, { FunctionComponent, useState, useEffect, Component } from 'react'

import { useLocation } from "react-router-dom";

import Header from '../layout/Header'

import ModalAutoClose from '../components/ModalAutoClose'
import WorkflowSteps from '../components/WorkflowSteps'
import NumberInput from '../components/NumberInput'
import Button from '../components/form/Button'
import InputGroup from '../components/form/InputGroup'
import TextArea from '../components/form/TextArea'
import WorkflowActions from '../components/form/WorkflowActions'

import { useGetLoggedInUser } from '../helpers/getLoggedInUser'

import Label from '../components/Label'
import { Link } from 'react-router-dom'
import RadioSelect from '../components/form/RadioSelect'

import { useGetIDBValues } from "../helpers/getIDBKey";
import { get, set } from 'idb-keyval'

type Props = {
	onClose: any,
	// onComplete: any,
	defaultValues?: any,
	projectId?: string,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => { },
	// onComplete: () => {},
	projectId: '',
	defaultValues: {
		guid: '',
	},
}

interface Rig {
	id: number;
	guid: string;
	name: string;
	company_id: number;
	// Add other properties as needed
}



const RigList: FunctionComponent<Props> = ({ onClose, defaultValues, onSelectItem, selectionType, companyGuid, ...rest }) => {

    const loggedInUser = useGetLoggedInUser();
    
	// const company_id = 1
	const [company_id, setCompany_id] = useState(0);
	const [company_guid, setCompany_guid] = useState(companyGuid);

    // if ((loggedInUser?.user as any)?.company_id !== company_id) {
    //     setCompany_id((loggedInUser?.user as any)?.company_id)
    //     setCompany_guid((loggedInUser?.user as any)?.company.guid)
    // }
    
    // console.log('company_id:', company_id);
    // console.log('company_guid:', company_guid);
    // console.log('loggedInUser:', loggedInUser);
    // console.log('loggedInUser company id:', (loggedInUser?.user as any)?.company_id);

	const [selectedRig, setSelectedRig] = useState('');

	// console.log('selectionType:', selectionType)

	const handleItemClick = ({ item, selectionType }: { item: string; selectionType: string }) => {
		// Find the selected rig based on the name
		const selectedRig = rigs.find(rig => `${rig.name}` === item);

        console.log('rig in handleItemClick:', item)

		// Check if selectedRig is defined before proceeding
		if (selectedRig) {
				set(`drillit-currentRig`, selectedRig)
					.then(() => {
						onSelectItem(selectedRig);
						onClose();
					})
					.catch((error) => {
						// console.error('Error saving rig details:', error);
						// Handle the error if needed
					});
						// onSelectItem(selectedRig);
						// onClose();

			// Add other conditions or actions based on selectionType if needed
			// ...
		} else {
			// console.error('Selected rig not found or undefined.');
			// Handle the case where the rig is not found
		}
	}





	useEffect(() => {
		// Fetch users data from IndexedDB or API and update state
		getRigs();
	}, []); 

	const [rigs, setRigs] = useState<Rig[]>([]);
    console.log('Company rigs:', rigs);

    const getRigs = () => {
		let cachedValues = useGetIDBValues;

		const companyRigs = `rigs/company/${company_guid}`;

		// Retrieve users for a specific company
		cachedValues(companyRigs, (data: any) => {

            console.log('Rigs:', data)
			if (data) {
				setRigs(data); // Update state with rig data
			} else {
				// console.error('Invalid user data structure:', data);
			}
		});
	};

	// // Extract rig names into a new array
	const rigNames = rigs.map(rig => `${rig.name}`);

	return (

		<>

			<ModalAutoClose title="Rig List" subtitle="" toggleModal={onClose} confirm={false} onSelect={(selection) => handleItemClick(selection)} >

				<table className="table-auto w-5/6 text-sm text-center bg-white border border-grey-mid mx-auto text-xl">
					<tbody>
                        
						{/* {rigNames.map((rigName, index) => (
							<tr key={index}>
								<td
									className={`px-4 py-2 border-r border-b border-grey-mid text-center cursor-pointer ${selectedRig === rigName ? 'bg-blue text-white' : ''
										}`}
									onClick={() => handleItemClick({ item: rigName, selectionType })} // Pass both item and selectionType
								>
									{rigName}
								</td>
							</tr>
						))} */}
						{rigNames
							.sort((a, b) => a.localeCompare(b)) // Sort rigNames alphabetically
							.map((rigName, index) => (
								<tr key={index}>
									<td
										className={`px-4 py-2 border-r border-b border-grey-mid text-center cursor-pointer ${selectedRig === rigName ? 'bg-blue text-white' : ''
											}`}
										onClick={() => handleItemClick({ item: rigName, selectionType })} // Pass both item and selectionType
									>
										{rigName}
									</td>
								</tr>
							))
						}
					</tbody>
				</table>

			</ModalAutoClose>

		</>

	)
}

RigList.defaultProps = defaultProps

export default RigList