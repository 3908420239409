import React, { FunctionComponent, useState } from 'react'

import WorkflowSteps from '../../WorkflowSteps'
import Modal from '../../Modal'
import Button from '../../form/Button'
// import NumberInput from '../../NumberInput'
// import InputGroup from '../../form/InputGroup'
import MultiNumberInput from '../../MultiNumberInput'
import { v4 as uuidv4 } from 'uuid';
import stepStatus from '../../../helpers/stepStatus'
import Comment from './soil-description/Comment'
import warningIcon from '../../../assets/icons/warning.png'

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: string,
	defaultValues?: any,
	selectedDate?: string,
	update?: any,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => { },
	onComplete: () => { },
	borehole: '',
	defaultValues: {
		guid: '',
		depth_from: '',
		depth_to: '',
		detail_description: '',
	},
	selectedDate: '',
}

const DetailDescription: FunctionComponent<Props> = ({ onClose, update, onComplete, borehole, defaultValues, selectedDate, setShowDetailDescriptionEdit, ...rest }) => {
	const [currentStep, setCurrentStep] = useState(1)
	const [guid, setGuid] = useState(defaultValues.guid)

	const [depthFrom, setDepthFrom] = useState(defaultValues.depth_from)
	const [depthTo, setDepthTo] = useState(defaultValues.depth_to)
	const [description, setComment] = useState(defaultValues.detail_description)

	const [saveButtonText, setButtonText] = useState('Continue')
	const [saveButtonColor, setButtonColor] = useState('primary')
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	const DeleteButton = () => {
		if (!update) return null;

		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	// const deleteRecordConfirm = () => {
	// 	setDeleteConfirmOpen(false)
	// 	// onComplete()
	// }

	// const [description, setDescription] = useState(defaultValues.description)
	// const [time_of_action, setTimeOfAction] = useState(defaultValues.time_of_action)
	const time_of_action = defaultValues.time_of_action

	const isoDate = selectedDate ? `${new Date(selectedDate).toISOString().split('T')[0]} 09:00:00` : new Date().toISOString().split('.')[0]

	const previous = () => {
		if (currentStep > 1)
			setCurrentStep(currentStep - 1)
		setButtonText('Continue')
		setButtonColor('primary')
	}

	const next = () => {
		if (currentStep < 2) {
			setCurrentStep(currentStep + 1)
			if (currentStep === 1) {
				setButtonText('Save')
				setButtonColor('success')
			} else {
				setButtonText('Continue')
				setButtonColor('primary')
			}
			return
		}

		if (!depthFrom || !depthTo || !description) {
			//do nothing
		} else {

			onComplete({
				guid: guid ? guid : uuidv4(),
				depth_from: parseFloat(depthFrom).toFixed(2), //depthFrom2DP, //2DP,
				depth_to: parseFloat(depthTo).toFixed(2), // depthTo2DP, //2DP,
				detail_description: description,
				// general_remarks: '',
				update: update ? true : false,
				time_of_action: time_of_action ? time_of_action : isoDate,
			})
		}
	}

	let requiredDepthFromText = ''
	let requiredDepthToText = ''
	let requiredDescriptionText = ''

	if (!depthFrom) {
		requiredDepthFromText = "'Depth From' - "
	}

	if (!description) {
		requiredDescriptionText = "'Description'"
	}

	if (!depthTo) {
		requiredDepthToText = "'Depth To' - "
	}

	const MissingRequiredField = () => {
		if (currentStep === 2 && (!depthFrom || !depthTo || !description )) return (
			<div className="text-center bg-sptSB border border-red font-bold rounded mb-2 py-1 text-xs md:text-sm lg:text-base">
				<img src={warningIcon} alt="info" width="20" className="inline-block mr-1 pb-1" />
				Required field: {requiredDepthFromText} {requiredDepthToText} {requiredDescriptionText}
			</div>
		)
	
		return null
	}

	var depthFrom2DP = parseFloat(depthFrom).toFixed(2)
	var depthTo2DP = parseFloat(depthTo).toFixed(2)

	return (
		<Modal title="Detail Description" subtitle={borehole} toggleModal={onClose}>
			<div className="flex flex-col flex-grow px-1 sm:px-2 md:px-10">
				<WorkflowSteps steps={[
					{
						label: 'Depth',
						status: stepStatus(1, currentStep),
						onClick: () => {
							setCurrentStep(1)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Description',
						status: stepStatus(2, currentStep),
						onClick: () => {
							setCurrentStep(2)
							setButtonText('Save')
							setButtonColor('success')
						}
					},
				]} className="mb-2" />

				<div className="flex-1 flex">
					{currentStep === 1 &&
						<>
							{/* // <NumberInput onUpdate={(value: any) => setDepthFrom(value)} 
							// title="Enter Depth From (m)" 
							// defaultValue={depthFrom} 
							// className="mb-10" /> */}

							<MultiNumberInput values={
								[
									{ defaultValue: depthFrom, onUpdate: setDepthFrom, label: 'From (m)' },
									{ defaultValue: depthTo, onUpdate: setDepthTo, label: 'To (m)' },
								]
							}
								title='Depth (m)'
								className="mb-2 text-base sm:text-xl md:text-xl"
								inputCSS="text-4xl sm:text-4xl md:text-3xl lg:text-4xl h-16 sm:h-16 mb-4"
							/>
						</>
					}

					{/* {currentStep === 2 &&
						<NumberInput onUpdate={(value: any) => setDepthTo(value)} 
						title="Enter Depth To (m)" 
						defaultValue={depthTo} 
						className="mb-10" />
					} */}

					{currentStep > 1 &&
						// <InputGroup label="Description" 
						// title="Description" 
						// onChange={(value: any) => setDescription(value)} 
						// value={description} 
						// className="mb-10 w-full text-center text-xl" />

						<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2 w-full pb-20">
							<Comment comment={description} numberOfRows={5} setComment={setComment} title="Description" />
						</div>
					}
				</div>

				<MissingRequiredField />


				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">


							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?

								<div className="flex mt-4">
									<Button theme="secondary" 
										className="mr-5 text-sm sm:text-base px-3.5 md:px-7" 
										onClick={() => setDeleteConfirmOpen(false)}>
											Cancel
									</Button>

									<Button onClick={() => {
												onClose()
												onComplete({
													guid: guid,
													update: false,
													delete: true,
												})
												setShowDetailDescriptionEdit(false)
											}}
										className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">
										Delete
									</Button>
								</div>
							</div>


						</div>
					</div>
				}

				<div className="flex">
					{/* <Button theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button> */}
					<DeleteButton />

					<div className="flex ml-auto">
						<Button theme="primary" size="lg" className="px-4 sm:px-10" onClick={() => previous()}>Back</Button>
						<Button theme={saveButtonColor}
							size="lg"
							className="ml-4 px-8 sm:px-10 w-32 sm:w-60"
							onClick={() => next()}>{saveButtonText}
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

DetailDescription.defaultProps = defaultProps

export default DetailDescription
