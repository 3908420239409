import React, { FunctionComponent, useState, useEffect, Component } from 'react'

import { useLocation } from "react-router-dom";

import Header from '../layout/Header'

import Modal from '../components/Modal'
import WorkflowSteps from '../components/WorkflowSteps'
import NumberInput from '../components/NumberInput'
import Button from '../components/form/Button'
import ButtonOptionsNoGrid from '../components/form/ButtonOptionsNoGrid'
import InputGroup from '../components/form/InputGroup'
import TextArea from '../components/form/TextArea'
import WorkflowActions from '../components/form/WorkflowActions'
import ProjectDate from '../components/project/ProjectDate'

import stepStatus from '../helpers/stepStatus'

import Label from '../components/Label'
import { Link } from 'react-router-dom'
import RadioSelect from '../components/form/RadioSelect'
import { v4 as uuidv4 } from 'uuid';
import { useSetIndexedDBValues } from '../helpers/addToIDB-LOGS'
import { useGetIDBValues } from "../helpers/getIDBKey";
import { get, set, del } from 'idb-keyval'
import { updateLogInformation } from "../helpers/updateLogInformation";
import { updateHoleInformation } from "../helpers/updateHoleInformation";
import { closeHole } from "../helpers/closeHole";
import { Redirect, useHistory } from "react-router-dom";


var crewlink = "/crew"

type Props = {
	onClose: any,
	// onComplete: any,
	borehole?: string,
	boreholeName: string,
	defaultValues?: any,
	projectId?: string,
	holeType: string,
	[x: string]: any;
}

interface Log {
	guid: string;
	project_id: number;
	project_name: string;
	project_location: string;
	hole_type_id: number;
	driller_id: number;
	company_id: number;
	hole_name: string;
	log_date: string;
	project_guid: string;
	hole_guid: string;
	log_url: string;
	log_save_date_time: string;
}

const defaultProps: Props = {
	onClose: () => { },
	// onComplete: () => {},
	borehole: '',
	boreholeName: '',
	projectId: '',
	defaultValues: {
		guid: '',
		holeName: '',
	},
	holeType: '',

	project_id: '',
	project_guid: '',
	hole_guid: '',
	logDate: '',
	boreholeDepth: '',

	activityTerminationReason: ''
}



const SaveLog: FunctionComponent<Props> = ({ onClose, borehole, defaultValues, boreholeName, holeType, project_guid,
	project_id, project_name, project_location, hole_type_id, hole_guid, logDate, boreholeDepth, driller_id,
	company_id, company_guid, activeDates, endShift, holeStatus, numberingType,
	...rest }) => {

	const [leadDriller, setLeadDriller] = useState('')
	const [leadDrillerID, setLeadDrillerID] = useState(0)
	const [leadDrillerCSCS, setLeadDrillerCSCS] = useState('')
	const [secondMan, setSecondMan] = useState('')
	const [secondManCSCS, setSecondManCSCS] = useState('')
	const [hole_barcode, setHole_barcode] = useState('')
	const [savedRigGuid, setSavedRigValue] = useState('')
	const [plantName, setPlantName] = useState('')
	const [plantMakeModel, setPlantMakeModel] = useState('')
	const [sptGUID, setSptGUID] = useState('')
	const [activityTerminationReason, setActivityTerminationReason] = useState('')
	const [active_dates, setActive_dates] = useState(activeDates)
	// console.log('active_dates:', active_dates)
	const [showSaveButton, setShowSaveButton] = useState(false)
	const [end_shift, setEnd_shift] = useState(endShift)
	// console.log('end_shift:', end_shift)
	const [hole_status, set_hole_status] = useState(holeStatus)
	const [numbering_type, set_numbering_type] = useState(numberingType)

	const cachedHoleInformation = useGetIDBValues

	useEffect(() => {
		cachedHoleInformation(`hole-locations/${hole_guid}`, (holeData: any) => {
			if (holeData) {
				// console.log('holeData in SAVE LOG:', holeData)
				setLeadDriller(holeData.driller)
				setLeadDrillerID(holeData.driller_id)
				setLeadDrillerCSCS(holeData.driller_cscs);
				setSecondMan(holeData.second_man);
				setSecondManCSCS(holeData.second_man_cscs);
				setHole_barcode(holeData.hole_barcode);
				setSavedRigValue(holeData.rig_id); // RIG GUID
				setPlantName(holeData.rig_name); // RIG NAME
				setPlantMakeModel(holeData.make_model); // RIG MAKE/MODEL
				setSptGUID(holeData.spt_id); // SPT ID
				setActivityTerminationReason(holeData.activity_termination_reason); // TERMINATION REASON
			} else {
				// // console.log(`**ERROR** HOLE DATA NOT LOADED. ID: ${borehole_guid}`);
			}
			// // console.log('holeData:', holeData);
		});
	}, [hole_guid]);


	// console.log('SAVE LOG *********************************************')
	// console.log('boreholeName:', boreholeName)
	// console.log('holeType:', holeType)
	// console.log('project_id:', project_id)
	// console.log('project_guid:', project_guid)
	// console.log('project_name:', project_name)
	// console.log('project_location:', project_location)
	// console.log('hole_type_id:', hole_type_id)
	// console.log('hole_guid:', hole_guid)
	// console.log('logDate:', logDate)
	// console.log('boreholeDepth:', boreholeDepth)
	// console.log('driller_id:', driller_id)
	// console.log('company_id:', company_id)
	// console.log('log_date:', logDate)
	// console.log('activityTerminationReason:', activityTerminationReason)
	// console.log('******************************************************')

	// const holeType = defaultValues.holeType
	// const [holeName, setHoleName] = useState(defaultValues.holeName)

	const [hole_type_ID, set_hole_type_ID] = useState(parseInt(hole_type_id))


	const [log_date, setLogDate] = useState(logDate);
	const [log_save_date, set_log_save_date] = useState(logDate);
	// Parse the date parts
	const dateParts = log_save_date.split('/');
	const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
	// console.log('formattedDate:', formattedDate)

	const [markAsComplete, setMarkAsComplete] = useState(false)


	const [hole, setHole] = useState('')
	const [typeId, setTypeId] = useState(1)
	const [redirectLocation, setRedirectLocation] = useState('')

	// console.log('holeType:', holeType)
	useEffect(() => {
		// console.log('useEffect triggered');
		if (holeType === 'CP') {
			setHole('Cable Borehole'); setTypeId(1);
			setRedirectLocation(`/projects/dashboard/company/${company_guid}/${project_guid}/cable-percussive`); setMarkAsComplete(false);
		}
		else if (holeType === 'WS') {
			setHole('Window Sample'); setTypeId(2);
			setRedirectLocation(`/projects/dashboard/company/${company_guid}/${project_guid}/window-sample`); setMarkAsComplete(true);
		}
		else if (holeType === 'RT') {
			setHole('Rotary Borehole'); setTypeId(3);
			setRedirectLocation(`/projects/dashboard/company/${company_guid}/${project_guid}/rotary`); setMarkAsComplete(false);
		}
		else if (holeType === 'TP') {
			setHole('Trialpit'); setTypeId(4);
			setRedirectLocation(`/projects/dashboard/company/${company_guid}/${project_guid}/trialpit`); setMarkAsComplete(true);
		}
		else if (holeType === 'DP') {
			setHole('Dynamic Probe'); setTypeId(5);
			setRedirectLocation(`/projects/dashboard/company/${company_guid}/${project_guid}/dynamic-probe`); setMarkAsComplete(true);
		}
		else if (holeType === 'HT') {
			setHole('Head Test'); setTypeId(6);
			setRedirectLocation(`/projects/dashboard/company/${company_guid}/${project_guid}/head-test`); setMarkAsComplete(true);
		}
		else if (holeType === 'SA') {
			setHole('Soakaway'); setTypeId(7);
			setRedirectLocation(`/projects/dashboard/company/${company_guid}/${project_guid}/soakaway`); setMarkAsComplete(true);
		}
		else if (holeType === 'CB') {
			setHole('CBR'); setTypeId(8);
			setRedirectLocation(`/projects/dashboard/company/${company_guid}/${project_guid}/cbr`); setMarkAsComplete(true);
		}
		else if (holeType === 'PT') {
			setHole('Plate Test'); setTypeId(9);
			setRedirectLocation(`/projects/dashboard/company/${company_guid}/${project_guid}/plate-test`); setMarkAsComplete(true);
		}
		else if (holeType === 'TR') {
			setHole('TRL'); setTypeId(10);
			setRedirectLocation(`/projects/dashboard/company/${company_guid}/${project_guid}/trl`); setMarkAsComplete(true);
		}
		else if (holeType === 'LG') {
			setHole('Logging'); setTypeId(11);
			setRedirectLocation(`/projects/dashboard/company/${company_guid}/${project_guid}/logging`); setMarkAsComplete(true);
		}
	}, [holeType, project_guid, setMarkAsComplete]);

	const inputLabel = hole + ' Name (req)'

	const title = 'Edit ' + hole

	const history = useHistory();
	const [redirect, setRedirect] = React.useState(false);

	// Redirect manually using history.push
	React.useEffect(() => {
		if (redirect) {
			history.push(redirectLocation);
		}
	}, [redirect, history, project_guid]);

	/////////////////////////////////////////
	// GET PROJECT LOGS
	/////////////////////////////////////////  


	const [projectLogs, setProjectLogs] = useState<Log[]>([]);
	// console.log("projectLogs:", projectLogs);

	const getProjectLogsIDB = () => {
		get('drillit-logs').then((existingLogs: Log[]) => {
			// console.log('Existing Logs from IndexedDB:', existingLogs);

			if (existingLogs) {
				setProjectLogs(existingLogs); // Update state with contact data
			} else {
				// console.error('Invalid logs data structure:', existingLogs);
			}
		})
	}

	useEffect(() => {
		// getProjectLogs();
		getProjectLogsIDB();
	}, []);

	useEffect(() => {
		findThisLog();
	}, [projectLogs]);

	// console.log('projectLogs:', projectLogs);

	const [existingGUID, setExistingGUID] = useState('')
	const [update, setUpdate] = useState(false)
	const [newUpdateLog, setNewUpdateLog] = useState('')

	const findThisLog = () => {

		const targetHoleName = boreholeName;
		const targetLogDate = formattedDate;
		const foundLog = projectLogs.find(
			log => log.hole_name === targetHoleName && log.log_date === targetLogDate
		);

		if (foundLog) {
			setUpdate(true)
			setExistingGUID(foundLog.guid)
			console.log("Found log with guid:", foundLog.guid);
			console.log("update:", update);
			setShowSaveButton(true)
			setNewUpdateLog('(update)')
		} else {
			setUpdate(false)
			console.log("Log not found");
			setShowSaveButton(true)
			setNewUpdateLog('(new)')
		}

	}

	useEffect(() => {
		findThisLog()
	}, [log_save_date]);

	/////////////////////////////////////////
	// SAVE NEW LOG INFO
	/////////////////////////////////////////   

	// Location string
	let logLocationString = project_location.replace(/[^a-zA-Z0-9-_]/g, '');
	// Location Name string
	let logLocationNameString = project_name.replace(/[^a-zA-Z0-9-_]/g, '');
	// Hole Name string
	let logHoleNameString = boreholeName.replace(/\s/g, '');
	// Date string
	// Split the date string into day, month, and year
	let parts = formattedDate.split('-');
	let year = parseInt(parts[0]);
	let month = parseInt(parts[1]);
	let day = parseInt(parts[2]);

	// Create a new Date object with the parts
	let dateObject = new Date(year, month - 1, day);

	// Get the year, month, and day in YYYY, MM, and DD format respectively
	let YYYY = dateObject.getFullYear();
	let MM = String(dateObject.getMonth() + 1).padStart(2, '0'); // Adding 1 because month is zero-based
	let DD = String(dateObject.getDate()).padStart(2, '0');

	// Concatenate them in YYYYMMDD format
	let logDateString = `${YYYY}${MM}${DD}`;

	let log_url = logLocationString + '-' + logLocationNameString + '-' + logHoleNameString + '-' + logDateString + '.pdf'

	// console.log('log_url:', log_url);

	// Create a new Date object which represents the current date and time
	let currentDate = new Date();

	// Format the date and time as desired
	let currentyear = currentDate.getFullYear();
	let currentmonth = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because month is zero-based
	let currentday = String(currentDate.getDate()).padStart(2, '0');
	let hours = String(currentDate.getHours()).padStart(2, '0');
	let minutes = String(currentDate.getMinutes()).padStart(2, '0');
	let seconds = String(currentDate.getSeconds()).padStart(2, '0');

	// Construct the date-time string
	let log_save_date_time = `${currentyear}-${currentmonth}-${currentday}T${hours}:${minutes}:${seconds}`;
	// console.log('log_save_date_time:', log_save_date_time)


	const [logInfoNEW, setLogInfoNEW] = useState<Log>({
		guid: uuidv4(),
		project_id: project_id,
		project_name: project_name,
		project_location: project_location,
		hole_type_id: hole_type_ID,
		driller_id: driller_id,
		company_id: company_id,
		hole_name: boreholeName,
		log_date: formattedDate,
		project_guid: project_guid,
		hole_guid: hole_guid,
		log_url: log_url,
		log_save_date_time: log_save_date_time,
	});

	// console.log('logInfoNEW:', logInfoNEW);
	// console.log('existingGUID:', existingGUID);

	const logInfoStringified = JSON.stringify(logInfoNEW);
	const logInfoParsed = JSON.parse(logInfoStringified);

	//..............................................................................................
	//...SSSSSSS.......AAAAA..AAAVV....VVVVVVEEEEEEEEE...... LL..........OOOOOOO.......GGGGGGGG....
	//..SSSSSSSSS......AAAAA...AAVV....VVVVVVEEEEEEEEE...... LL.........OOOOOOOOOO....GGGGGGGGGG...
	//..SSSSSSSSSS....AAAAAA...AAVV...VVVVVVVEEEEEEEEE...... LL........LOOOOOOOOOOO..OGGGGGGGGGGG..
	//.SSSSS..SSSS....AAAAAAA..AAVVV..VVVV.VVEE............. LL.......LLOOO...OOOOO.OOGGG...GGGGG..
	//.SSSSS.........AAAAAAAA...AVVV..VVVV.VVEE............. LL.......LLOO.....OOOOOOOGG.....GGG...
	//..SSSSSSS......AAAAAAAA...AVVV..VVVV.VVEEEEEEEEE...... LL.......LLOO......OOOOOOGG...........
	//...SSSSSSSSS...AAAA.AAAA..AVVV.VVVV..VVEEEEEEEEE...... LL.......LLOO......OOOOOOGG..GGGGGGG..
	//.....SSSSSSS..AAAAAAAAAA...VVVVVVVV..VVEEEEEEEEE...... LL.......LLOO......OOOOOOGG..GGGGGGG..
	//........SSSSS.AAAAAAAAAAA..VVVVVVVV..VVEE............. LL.......LLOO.....OOOOOOOGG..GGGGGGG..
	//.SSSS....SSSS.AAAAAAAAAAA..VVVVVVV...VVEE............. LL.......LLOOOO..OOOOO..OGGGG...GGGG..
	//.SSSSSSSSSSSSSAAA....AAAA...VVVVVV...VVEEEEEEEEEE..... LLLLLLLLL.LOOOOOOOOOOO..OGGGGGGGGGGG..
	//..SSSSSSSSSS.SAAA.....AAAA..VVVVVV...VVEEEEEEEEEE..... LLLLLLLLL..OOOOOOOOOO....GGGGGGGGGG...
	//...SSSSSSSS.SSAAA.....AAAA..VVVVV....VVEEEEEEEEEE..... LLLLLLLLL...OOOOOOO........GGGGGG.....
	//..............................................................................................

	const saveLog = () => {

		if (!update) {

			console.log('****SaveLog - logInfoParsed:', logInfoParsed)

			try {
				// Create Logs records
				let saveToIDB = useSetIndexedDBValues;
				saveToIDB(logInfoParsed, 'logs', true)

			} catch (error) {
				// console.error('Error creating Log file:', error);
			}

		} else {

			try {
				// Update Logs records
				if (existingGUID) {
					updateLogInformation(
						existingGUID,
						project_id,
						project_name,
						project_location,
						hole_type_ID,
						driller_id,
						company_id,
						boreholeName,
						formattedDate,
						project_guid,
						hole_guid,
						log_url,
						log_save_date_time,
					)
				}

			} catch (error) {
				// console.error('Error creating Log file:', error);
			}
		}

		// UPDATE HOLE INFORMATION

		// Delete hole information from IDB before re-saving with new values
		del(`drillit-hole-locations/updateInformation/${hole_guid}-update`)

		console.log('hole_status on save:', hole_status)

		const finalHoleStatus = markAsComplete ? '3' : hole_status;

		updateHoleInformation(
			hole_guid,
			boreholeName,
			leadDriller,
			leadDrillerID,
			leadDrillerCSCS,
			secondMan,
			secondManCSCS,
			savedRigGuid || '',
			plantName || '',
			plantMakeModel || '',
			sptGUID || '',
			hole_barcode,
			boreholeDepth,
			activityTerminationReason,
			project_guid,
			finalHoleStatus,
			numbering_type
		);

		if (end_shift === true) {
			if (markAsComplete) {
				// closeHole(hole_guid, formattedDate, project_guid)

				// Delete hole information from IDB before re-saving with new values
				del(`drillit-hole-locations/updateInformation/${hole_guid}-update`)

				updateHoleInformation(
					hole_guid,
					boreholeName,
					leadDriller,
					leadDrillerID,
					leadDrillerCSCS,
					secondMan,
					secondManCSCS,
					savedRigGuid || '',
					plantName || '',
					plantMakeModel || '',
					sptGUID || '',
					hole_barcode,
					boreholeDepth,
					activityTerminationReason,
					project_guid,
					'3', // hole_status,
					numbering_type
				)
				onClose()
			} else {
				onClose()
			}
		} else {
			if (markAsComplete) {
				// closeHole(hole_guid, formattedDate, project_guid)

				// Delete hole information from IDB before re-saving with new values
				del(`drillit-hole-locations/updateInformation/${hole_guid}-update`)

				updateHoleInformation(
					hole_guid,
					boreholeName,
					leadDriller,
					leadDrillerID,
					leadDrillerCSCS,
					secondMan,
					secondManCSCS,
					savedRigGuid || '',
					plantName || '',
					plantMakeModel || '',
					sptGUID || '',
					hole_barcode,
					boreholeDepth,
					activityTerminationReason,
					project_guid,
					'3', // hole_status,
					numbering_type
				)
				setRedirect(true)
			} else {
				onClose()
			}
		}
	};


	const createLog = async () => {
		try {
			// Fetch required data from IndexedDB
			const cachedValues = useGetIDBValues;
			const companyKey = `actions/${project_guid}/${hole_type_ID}/${hole_guid}?date=${formattedDate}`;

			// Fetch userToken from IndexedDB
			get('drillit-user').then((data) => {
				// console.log('drillit-user data:', data);
				const userToken = data.user.token;

				// Use userToken directly inside the callback
				cachedValues(companyKey, async (data: any) => {
					try {
						// console.log('Retrieved data from IndexedDB:', data);

						const token = userToken; // Use userToken directly here
						const saveLogEndpoint = `/actions/${project_guid}/${hole_type_ID}/${hole_guid}?date=${formattedDate}`;

						// console.log('saveLogEndpoint:', saveLogEndpoint)

						// Make a request to the API with the retrieved token
						const response = await fetch(saveLogEndpoint, {
							headers: {
								Authorization: `Bearer ${token}`,
								// Include any other headers if required
							},
						});
						// console.log('response:', response)

						// console.log('logInfoNEW:', logInfoNEW);
						let saveToIDB = useSetIndexedDBValues;
						saveToIDB(logInfoParsed, 'logs', true)

					} catch (error) {
						// console.error('Error creating Log file:', error);
					}
				});
			});
		} catch (error) {
			//   console.error('Error fetching data from IndexedDB:', error);
		}
	};




	return (
		<Modal title="Save Log" subtitle={borehole} toggleModal={onClose} >

			<div className="border border-grey-mid rounded px-2 py-4 bg-sptTB mb-6 w-full sm:w-5/6 mx-auto">

				<div className="mb-2 text-2xl font-bold text-center">
					{boreholeName}
				</div>

				<div className="mb-2 text-lg font-bold text-center">
					({hole}) {newUpdateLog}
				</div>

				<div className="text-center font-bold mx-auto bg-modal-bg ags-item items-center rounded text-sm md:text-lg px-4 md:px-5 py-1 md:py-2 w-full md:w-2/4 mb-8">
					Log date: {log_save_date}
				</div>


				<div className="flex w-full items-center">
					<ButtonOptionsNoGrid onUpdate={(value: any) => set_log_save_date(value)}
						defaultValue={logDate}
						title=""
						columns={6}
						columnsMobile={3}
						buttonHeight={11}
						buttonHeightMobile={10}
						options={active_dates}
						className="mb-6" />
				</div>

				<form onSubmit={(e) => {
					e.preventDefault(); // Prevent the default form submission
					saveLog(); // createLog();
				}}>

					<InputGroup
						label="Termination Reason (leave blank if none):"
						value={activityTerminationReason}
						onChange={(value: any) => setActivityTerminationReason(`${value}`)}
						className="text-base sm:text-sm md:text-base w-full text-center w-full"
						inputCSS="form-control-xl text-center text-base w-full md:w-3/4 mx-auto mt-1 mb-4"
					/>

					<div className="text-center mx-auto bg-modal-bg ags-item items-center rounded text-lg font-bold px-1 md:px-5 py-1 md:py-2 w-full md:w-2/4 mb-4">
						Mark hole as complete? <input type="checkbox" className="form-control-checkbox" checked={markAsComplete}
							onChange={(e) => setMarkAsComplete(e.target.checked)} />
					</div>

					{newUpdateLog !== '' && (
						<div className="w-30 mb-4">
							<Button type="submit" theme="primary" size="lg" className="btn-lg m-auto text-lg font-bold" >Save Logsheet</Button>
						</div>
					)}

				</form>

			</div>

		</Modal>

	)
}

SaveLog.defaultProps = defaultProps

export default SaveLog