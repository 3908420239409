import React, { FunctionComponent, useState, useEffect } from 'react'

import WorkflowSteps from '../../WorkflowSteps'
import Modal from '../../Modal'
import Button from '../../form/Button'
import NumberInput from '../../NumberInput'
import InputGroup from '../../form/InputGroup'
import MultiNumberInput from "../../MultiNumberInput";
// import SamplesCreate from './SamplesCreate'
// import TimeInput from "../../TimeInput";
import ButtonOptions from '../../form/ButtonOptions'
import { v4 as uuidv4 } from 'uuid';

import stepStatus from '../../../helpers/stepStatus'
import warningIcon from '../../../assets/icons/warning.png'

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: string,
	defaultValues?: any,
	// selectedDate?: string,
	update?: boolean,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => { },
	onComplete: () => { },
	borehole: '',
	defaultValues: {
		guid: '',
		// time_of_strike: new Date().toISOString().split('.')[0],
		time_of_strike: '00:00',
		depth_to_water: '',
		casing_depth: '',
		rate_of_flow: '',
		depth_after_5: '',
		depth_after_10: '',
		depth_after_15: '',
		depth_after_20: '',
		depth_sealed: '',
	},
	// selectedDate: '',
}

const GroundWaterWindowSample: FunctionComponent<Props> = ({ onClose, update, onComplete, borehole, defaultValues, 
	selectedDate, casing_preset, setShowGroundWaterEdit, ...rest }) => {
	const [currentStep, setCurrentStep] = useState(1)

	// const [guid, setGuid] = useState(defaultValues.guid)
	const guid = defaultValues.guid
	// const [time_of_strike, setTime] = useState(defaultValues.time_of_strike)
	const [depth_to_water, setDepthStruck] = useState(defaultValues.depth_to_water)
	const [casing_depth, setCasing] = useState(defaultValues.casing_depth)
	const [rate_of_flow, setInflow] = useState(defaultValues.rate_of_flow)
	const [depth_after_5, setHeight5] = useState(defaultValues.depth_after_5)
	const [depth_after_10, setHeight10] = useState(defaultValues.depth_after_10)
	const [depth_after_15, setHeight15] = useState(defaultValues.depth_after_15)
	const [depth_after_20, setHeight20] = useState(defaultValues.depth_after_20)
	// const [depth_sealed, setSeal] = useState(defaultValues.depth_sealed)
	const depth_sealed = defaultValues.depth_sealed

	const [saveButtonText, setButtonText] = useState('Continue')
	const [saveButtonColor, setButtonColor] = useState('primary')
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	const time_of_action = defaultValues.time_of_action

	// strip selectedDate
	console.log('selectedDate:', selectedDate)
	const originalDate = new Date(selectedDate)
	const year = originalDate.getFullYear()
	const month = String(originalDate.getMonth() + 1).padStart(2, '0') // Months are 0-indexed
	const day = String(originalDate.getDate()).padStart(2, '0')

	const formattedDate = `${year}-${month}-${day}`
	console.log('formattedDate:', formattedDate)

	// const time_of_strike = defaultValues.time_of_strike // 2023-11-03 07:06:53

	const [time_of_strike, setTime] = useState(defaultValues.time_of_strike)
	console.log('time_of_strike:', time_of_strike)

	const [new_time_of_strike, set_new_time_of_strike] = useState('00:00')

	useEffect(() => {
		if (time_of_strike !== '00:00') { // Existing time_of_strike from props
			// Extract the date part (2023-11-03) by splitting the string
			const dateTimeParts = time_of_strike.split(' ');
		
			if (dateTimeParts.length > 1) { // Ensure there's a time part
				set_new_time_of_strike(dateTimeParts[1].substring(0, 5))
			}
		}
	}, [time_of_strike]); // Include time_of_strike in the dependency array

	console.log('time_of_strike:',time_of_strike)
	console.log('new_time_of_strike:',new_time_of_strike)

	const DeleteButton = () => {
		if (!update) return null;

		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	// const deleteRecordConfirm = () => {
	// 	setDeleteConfirmOpen(false)
	// 	// onComplete()
	// }

	const isoDate = selectedDate ? `${new Date(selectedDate).toISOString().split('T')[0]} 09:00:00` : new Date().toISOString().split('.')[0]

	const previous = () => {
		if (currentStep > 1)
			setCurrentStep(currentStep - 1)
		setButtonText('Continue')
		setButtonColor('primary')
	}

	const next = () => {
		if (currentStep < 5) {
			setCurrentStep(currentStep + 1)
			if (currentStep === 4) {
				setButtonText('Save')
				setButtonColor('success')
			} else {
				setButtonText('Continue')
				setButtonColor('primary')
			}
			return
		}

		if (!depth_to_water) {
			//do nothing
		} else {

			onComplete({
				guid: update ? guid : uuidv4(),
				time_of_strike: formattedDate + ' ' + new_time_of_strike + ':00',
				depth_to_water: depth_to_water2DP, //2DP,
				casing_depth: casing_depth2DP, //2DP,
				rate_of_flow: rate_of_flow,
				depth_after_5: depth_after_52DP, //2DP,
				depth_after_10: depth_after_102DP, //2DP,
				depth_after_15: depth_after_152DP, //2DP,
				depth_after_20: depth_after_202DP, //2DP,
				depth_sealed: '', //depth_sealed2DP, //2DP,
				sample_id: '',
				general_remarks: '',
				time_of_action: time_of_action ? time_of_action : isoDate,
				update: update ? true : false,
			})
		}
	}

	const MissingRequiredField = () => {
		if (currentStep === 5 && !depth_to_water) return (
			<div className="text-center bg-sptSB border border-red font-bold rounded mb-2 py-1 text-xs md:text-sm lg:text-base">
				<img src={warningIcon} alt="info" width="20" className="inline-block mr-1 pb-1" />
				Required field: 'Depth Struck'
			</div>
		)

		return null
	}

	let depth_to_water2DP = ''
	if (!depth_to_water) {
		depth_to_water2DP = ''
	} else {
		depth_to_water2DP = parseFloat(depth_to_water).toFixed(2)
	}

	// let casing_depth2DP = ''
	// if(!casing_depth){
	// 	casing_depth2DP = ''
	// } else {
	// 	casing_depth2DP = parseFloat(casing_depth).toFixed(2)
	// }

	let casing_depth2DP = ''
	if (casing_depth === 'no casing') {
		casing_depth2DP = 'no casing'
	} else if (casing_depth) {
		casing_depth2DP = parseFloat(casing_depth).toFixed(2)
	} else {
		casing_depth2DP = ''
	}

	if (!casing_preset) { var casing_preset_value = 'no casing' }
	else { casing_preset_value = parseFloat(casing_preset).toFixed(2) }

	let depth_after_52DP = ''
	if (!depth_after_5) {
		depth_after_52DP = ''
	} else {
		depth_after_52DP = parseFloat(depth_after_5).toFixed(2)
	}

	let depth_after_102DP = ''
	if (!depth_after_10) {
		depth_after_102DP = ''
	} else {
		depth_after_102DP = parseFloat(depth_after_10).toFixed(2)
	}

	let depth_after_152DP = ''
	if (!depth_after_15) {
		depth_after_152DP = ''
	} else {
		depth_after_152DP = parseFloat(depth_after_15).toFixed(2)
	}

	let depth_after_202DP = ''
	if (!depth_after_20) {
		depth_after_202DP = ''
	} else {
		depth_after_202DP = parseFloat(depth_after_20).toFixed(2)
	}

	let depth_sealed2DP = ''
	if (!depth_sealed) {
		depth_sealed2DP = ''
	} else {
		depth_sealed2DP = parseFloat(depth_sealed).toFixed(2)
	}

	console.log('Date Time to save:', formattedDate + ' ' + new_time_of_strike + ':00')



	// if (!casing_preset) {var casing_preset_value = 'no casing'}
	// else {casing_preset_value = casing_preset}

	// let remarks_to_send_for_WSsample = 'Create WS Sample'

	return (
		<Modal title="Ground Water" subtitle={borehole} toggleModal={onClose}>
			<div className="flex flex-col flex-grow px-1 sm:px-2 md:px-10 text-center">
				<WorkflowSteps steps={[
					{
						label: 'Time',
						status: stepStatus(1, currentStep),
						onClick: () => {
							setCurrentStep(1)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Depth Struck',
						status: stepStatus(2, currentStep),
						onClick: () => {
							setCurrentStep(2)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Casing',
						status: stepStatus(3, currentStep),
						onClick: () => {
							setCurrentStep(3)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Inflow',
						status: stepStatus(4, currentStep),
						onClick: () => {
							setCurrentStep(4)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Height',
						status: stepStatus(5, currentStep),
						onClick: () => {
							setCurrentStep(5)
							setButtonText('Save')
							setButtonColor('success')
						}
					}, 
				]} className="mb-2" />

				<div className="flex-1 flex">
					{currentStep === 1 &&
						<>
							<div className="border border-grey-mid rounded px-2 bg-sptTB mb-4 w-full pb-2">
								<InputGroup
									label="Enter Time of Water Strike"
									type={'time'}
									value={new_time_of_strike}
									onChange={(value: any) => set_new_time_of_strike(`${value}`)}
									className="text-base sm:text-base md:text-xl w-full text-center"
									inputCSS="form-control-xxl text-center text-4xl w-60 mx-auto mt-3"
								/>
							</div>
						</>
					}

					{currentStep === 2 &&
						<NumberInput onUpdate={(value: any) => setDepthStruck(value)}
							title="Enter Depth Struck (m)"
							defaultValue={depth_to_water}
							className="mb-10" />
					}

					{currentStep === 3 &&
						<NumberInput onUpdate={(value: any) => setCasing(value)}
							title="Enter Casing Depth (m)"
							defaultValue={casing_depth}
							className="mb-10 text-xl md:text-2xl leading-5 md:leading-8"
							presets={[
								{ value: casing_preset_value, label: casing_preset_value },
							]}
							next={next}
						/>
					}

					{currentStep === 4 &&
						<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2 w-full pb-2">
							<ButtonOptions onUpdate={(value: any) => setInflow(value)}
								title="Select Inflow Rate"
								defaultValue={rate_of_flow}
								options={['Slow', 'Med', 'Fast']}
								columns={3}
								columnsMobile={1}
								className="mb-4 w-20 w-3/5 md:w-full text-xl md:text-2xl"
								buttonHeight={12}
								buttonHeightMobile={10}
								next={next}
							/>
						</div>
					}

					{currentStep === 5 &&
						<MultiNumberInput
							title="Depth after (m)" // 
							values={
								[
									{ defaultValue: depth_after_5, onUpdate: setHeight5, label: '5 mins' },
									{ defaultValue: depth_after_10, onUpdate: setHeight10, label: '10 mins' },
									{ defaultValue: depth_after_15, onUpdate: setHeight15, label: '15 mins' },
									{ defaultValue: depth_after_20, onUpdate: setHeight20, label: '20 mins' },
								]
							}
							className="mb-0 text-sm sm:text-xl md:text-xl"
							inputCSS="text-2xl sm:text-2xl md:text-3xl lg:text-4xl h-14 sm:h-16 mb-5"
						/>
					}

					{currentStep === 6 &&
						<div className="w-full flex flex-col flex-grow">
							{/* <div className="border border-grey-mid rounded px-2 bg-sptTB mb-4 w-full pb-2">
					
							<div className="text-xl text-center w-full">Add Recovery D Sample?</div>
							<div className="text-xs text-center">
								Sample: WS-## | Depth From: {depth_to_water2DP} | Depth To:  | Casing: {casing_depth2DP}  | 
								Water:  | Notes: 
							</div>

							<Button theme="primary" size="lg" className="px-4 sm:px-10 mx-auto">Create Sample</Button>
						</div>

						<PrintLabel 
						location={selectedProject.location}
						project={selectedProject.number}
						borehole={borehole} 
						sampledate={labelDate}  
						user={loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name) }
						sample={sampleID}
						depth={printDepth}
						size='printLabel'
						title={title_to_send_for_WSsample}
						/> */}

							{/* <SamplesCreate
								// onComplete={(value: any) => this.saveAction(value, 'B', this.setState({ showB: false }))}
								borehole={borehole}
								selectedDate=''
								title="Water Sample"
								casing_preset={casing_depth}
								defaultValues={{
									type: 'WS',
									remarks: `Groundwater Sample`,
									depth_to_top: depth_to_water2DP,
									depth_to_base: '',
									casing_depth: casing_depth2DP,
									water_depth: depth_to_water2DP,
									importSampleCreateText: remarks_to_send_for_WSsample,
								}}
							/> */}

						</div>
					}
				</div>

				<MissingRequiredField />


				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">


							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?

								<div className="flex mt-4">
									<Button theme="secondary" 
										className="mr-5 text-sm sm:text-base px-3.5 md:px-7" 
										onClick={() => setDeleteConfirmOpen(false)}>
											Cancel
									</Button>

									<Button onClick={() => {
												onClose()
												onComplete({
													guid: guid,
													update: false,
													delete: true,
												})
												setShowGroundWaterEdit(false)
											}}
										className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">
										Delete
									</Button>
								</div>
							</div>


						</div>
					</div>
				}

				<div className="flex">
					{/* <Button theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button> */}
					<DeleteButton />

					<div className="flex ml-auto">
						<Button theme="primary" size="lg" className="px-4 sm:px-10" onClick={() => previous()}>Back</Button>
						<Button theme={saveButtonColor}
							size="lg"
							className="ml-4 px-8 sm:px-10 w-32 sm:w-60"
							onClick={() => next()}>{saveButtonText}
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

GroundWaterWindowSample.defaultProps = defaultProps

export default GroundWaterWindowSample
