import React, { FunctionComponent } from 'react'

import icon from '../assets/icons/search.svg'

type Props = {
	className?: string,
	onChange: (value: any) => void,
	[x: string]: any;
}

const defaultProps: Props = {
	className: '',
	onChange: (value) => {},
}

const Search: FunctionComponent<Props> = ({ className, onChange, ...rest }) => {
	const handleChange = (event: any) => {
		onChange(event.target.value)
	}

	return (
		<div className={`relative ${className}`}>
			<input className="form-control form-control-sm text-sm w-60" placeholder="Search" {...rest} onChange={handleChange} />

			<img src={icon} width="16" className="absolute top-2 right-2 pointer-events-none" alt="" />
		</div>
	)
}

Search.defaultProps = defaultProps

export default Search