import React, { FunctionComponent, useState } from 'react'

type Props = {
	className?: string,
	aboveInput?: JSX.Element,
	defaultValue: string,
	title?: string,
	remainingDepth?: number,
	presets?: any[],
	onUpdate: (value: string) => void;
	[x: string]: any;
}

const defaultProps: Props = {
	className: '',
	defaultValue: '',
	title: '',
	remainingDepth: 0,
	aboveInput: <></>,
	presets: [],
	onUpdate: (value: any) => {},
}

const keys = [
	'1', '2', '3',
	'4', '5', '6',
	'7', '8', '9',
	'.', '0',
]

const NumberInputBackfill: FunctionComponent<Props> = ({ className, defaultValue, aboveInput, presets, title, remainingDepth, onUpdate, ...rest }) => {
	const [value, setValue] = useState(defaultValue)

	const addCharacter = (character: string) => {
		let newValue = value

		if(value === '')
			newValue = character
		else
			newValue = value + character

		setValue(newValue)
		onUpdate(newValue)
	}

	const removeCharacter = () => {
		let newValue = value

		if(value !== '') {
			if(value.length === 1)
				newValue = ''
			else
				newValue = value.slice(0, -1)
		}

		setValue(newValue)
		onUpdate(newValue)
	}

	const presetClick = (value: string) => {
		setValue(value)
		onUpdate(value)
	}

	// const remainingBackfillClick = (value: string) => {
	// 	setValue(value)
	// 	onUpdate(value)
	// }

	return (
		<div className={`w-full ${className}`}>
			{title ? <div className="text-base sm:text-xl mb-4 text-center">{title}</div> : <></>}

			{(presets && presets.length) ?
				<>
				

					<div className="grid grid-cols-4 gap-2 mb-2">
						<div className=""></div>
						<div className=""></div>

						<div className="border border-orange rounded mt-3 py-1 text-center text-2xl bg-white mx-auto w-11/12 md:w-11/12 lg:w-24 ml-3 md:ml-auto lg:mr-0 h-10 leading-7">
							{value ? value : ''}
						</div>

						<div className="col-span-1 text-center "><span className="text-xs leading-3">Remaining:</span>
							{presets.map((preset) => {
								return <button className="bg-blue text-white rounded py-0 mb-1 w-2/3 mx-auto h-7 align-middle text-sm font-bold" onClick={() => presetClick(preset.value)}>{preset.label}</button>
							})}
						</div>
					</div>
				</>
				:
				<>

				<div className="font-bold text-lg border border-orange text-charcoal mb-2 w-full sm:w-3/5 md:w-2/5 mx-auto">
					Click for remaining {remainingDepth}m
				</div>
					<div className="grid grid-cols-3 lg:grid-cols-4 gap-3 mb-2 w-full lg:3/5">

                        <div className="col-span-2 lg:col-span-2  text-right"></div>

					{/* <div className=" mb-2 ml-auto w-16 sm:w-2/5 md:w-2/5 lg:w-2/5">
						<div></div> */}

						<div className="border border-orange py-1 text-center text-2xl bg-blue mx-auto w-24 lg:w-36 ml-0 lg:ml-10 h-10 leading-7 text-white">
							{value ? value : ''}
						</div>
					</div>
				</>
			}

			{aboveInput}

			<div className="grid grid-cols-3 gap-2 mx-0 sm:mx-20 md:mx-20 lg:mx-32 mt-2">
				{keys.map((key, index) => {
					return <div className="border border-orange bg-white rounded py-0 md:py-4 lg:py-0 text-center text-2xl" key={index} onClick={() => addCharacter(key)}>{key}</div>
				})}

				<div className="border border-orange bg-white rounded py-1 md:py-5 lg:py-1 text-center text-base" onClick={() => removeCharacter()}>DEL</div>
			</div>
		</div>
	)
}

NumberInputBackfill.defaultProps = defaultProps

export default NumberInputBackfill