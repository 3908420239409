import React, { FunctionComponent, useState, Component } from 'react'

import Modal from '../components/Modal'
import { Link } from 'react-router-dom'

import warningIcon from '../assets/icons/warning.png'
import checkIcon from '../assets/icons/check.svg'
import CrewList from './CrewList'
import RigSelect from './rigs/RigsSelect'
import Button from '../components/form/Button'

import { useGetLoggedInUser } from '../helpers/getLoggedInUser'

import { setIDB } from "../helpers/setIDBKey";
import {get,set} from "idb-keyval";

type Props = {
	onClose: any,
}

const defaultProps: Props = {
	onClose: () => {},
}


const AdministrationPanel: FunctionComponent<Props> = ({ onClose, ...rest }) => {

    
	const loggedInUser = useGetLoggedInUser()
    // console.log('loggedInUser: ', loggedInUser)

   
	return (

        <>

            <Modal title="Drillit Logs Admin" subtitle="" toggleModal={onClose} confirm={false} >
				
                
            </Modal>

        </> 

	)
}

AdministrationPanel.defaultProps = defaultProps

export default AdministrationPanel