import React, { FunctionComponent, useState, useEffect } from 'react'

// import WorkflowSteps from '../../WorkflowSteps'
import Modal from '../../Modal'
import Button from '../../form/Button'
// import NumberInput from '../../NumberInput'
import ButtonOptions from '../../form/ButtonOptions'

import { v4 as uuidv4 } from 'uuid';
// import stepStatus from '../../../helpers/stepStatus'
import warningIcon from '../../../assets/icons/warning.png'

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: string,
	defaultValues?: any,
	selectedDate?: string,
	update?: any,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => { },
	onComplete: () => { },
	borehole: '',
	defaultValues: {
		guid: '',
		//drill_core_size_id: 0,
		drill_core_type: '',
		drill_core_size_id: 14,
		drill_core_diameter_inside: 0,
		drill_core_diameter_outside: 0,
		drill_core_text_output: '',
	},
	selectedDate: '',
}

const DrillCoreSize: FunctionComponent<Props> = ({ onClose, update, onComplete, borehole, defaultValues, selectedDate, setShowDrillCoreSizeRecordsEdit, ...rest }) => {
	
	// const [drill_core_size, setDrillCoreSize] = useState(defaultValues.drill_core_size)
	// const drill_core_diameter_inside = defaultValues.drill_core_diameter_inside
	// const drill_core_diameter_outside = defaultValues.drill_core_diameter_outside
	// const [drill_core_text_output, setDrill_core_text_output] = useState(defaultValues.drill_core_text_output)
	// const drill_core_text_output = defaultValues.drill_core_text_output
	const [guid, setGuid] = useState(defaultValues.guid)
	// const [drill_core_size_id, setDrillCoreSize] = useState(defaultValues.drill_core_size_id)
	const [time_of_action, setTimeOfAction] = useState(defaultValues.time_of_action)

	const isoDate = selectedDate ? `${new Date(selectedDate).toISOString().split('T')[0]} 09:00:00` : new Date().toISOString().split('.')[0]

	// const [saveButtonText, setButtonText] = useState('Continue')
	// const [saveButtonColor, setButtonColor] = useState('primary')
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	const DeleteButton = () => {
		if (!update) return null;

		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	// const deleteRecordConfirm = () => {
	// 	setDeleteConfirmOpen(false)
	// 	// onComplete()
	// }

	type DrillCoreSizeData = {
		[key: number]: {
		  type: string;
		  insideDiameter: number;
		  outsideDiameter: number;
		};
	  };

	const sizeData: DrillCoreSizeData = {
		0: { type: "AQ", insideDiameter: 35.5, outsideDiameter: 48.0 },
		1: { type: "BQ", insideDiameter: 36.4, outsideDiameter: 60.0 },
		2: { type: "BQ2", insideDiameter: 40.7, outsideDiameter: 60.0 },
		3: { type: "NQ", insideDiameter: 47.6, outsideDiameter: 75.7 },
		4: { type: "NQ2", insideDiameter: 50.6, outsideDiameter: 75.7 },
		5: { type: "NQ3", insideDiameter: 45.0, outsideDiameter: 75.7 },
		6: { type: "NX", insideDiameter: 54.7, outsideDiameter: 63.5 },
		7: { type: "HQ", insideDiameter: 63.5, outsideDiameter: 96.0 },
		8: { type: "HQ3", insideDiameter: 61.1, outsideDiameter: 96.0 },
		9: { type: "PQ", insideDiameter: 85.0, outsideDiameter: 122.6 },
		10: { type: "PQ3", insideDiameter: 83.0, outsideDiameter: 122.6 },
		11: { type: "T6H", insideDiameter: 80.5, outsideDiameter: 98.8 },
		12: { type: "PWF", insideDiameter: 92.3, outsideDiameter: 120.6 },
		13: { type: "Geobor", insideDiameter: 102.0, outsideDiameter: 146.0 },
		14: { type: "", insideDiameter: 0, outsideDiameter: 0 },
	  }

	const [drill_core_size_id, setDrillCoreSize] = useState(defaultValues.drill_core_size_id)
	const [drill_core_type, setDrillCoreType] = useState('')
	const [drill_core_diameter, setDrillCoreInsideDiameter] = useState(0)
	const [drill_core_hole_diameter, setDrillCoreOutsideDiameter] = useState(0)

	useEffect(() => {
		if (drill_core_size_id in sizeData) {
		  const data = sizeData[drill_core_size_id]
		  setDrillCoreType(data.type)
		  setDrillCoreInsideDiameter(data.insideDiameter)
		  setDrillCoreOutsideDiameter(data.outsideDiameter)
		}
	  }, [drill_core_size_id])

	  console.log('defaultValues.drill_core_size_id: ', defaultValues.drill_core_size_id)
	  console.log('Drill core size ID: ', drill_core_size_id)
	  console.log('Drill core type: ', drill_core_type)
	  console.log('Drill core inside diameter: ', drill_core_diameter)
	  console.log('Drill core outside diameter: ', drill_core_hole_diameter)

	const save = () => {

		if (drill_core_type ===  '') {
			//do nothing
		} else {

			onComplete({
				guid: guid ? guid : uuidv4(),
				//drill_core_size_id: drill_core_size_id,
				drill_core_size_id: parseFloat(drill_core_size_id), //drill_core_size,
				drill_core_type: drill_core_type.toString(),
				drill_core_diameter: drill_core_diameter.toString(), // drillcore_inside, // (drillcore_inside).toFixed(2),
				drill_core_hole_diameter: drill_core_hole_diameter.toString(), // drillcore_outside, // (drillcore_outside).toFixed(2),
				// drill_core_text_output: drill_core_type,
				general_remarks: '',
				time_of_action: time_of_action ? time_of_action : isoDate,
				update: update ? true : false
			})
		}
	}

	const MissingRequiredField = () => {
		if (drill_core_type ===  '') {
			return (
				<div className="text-center bg-sptSB border border-red font-bold rounded mb-2 py-1 text-xs md:text-sm lg:text-base">
					<img src={warningIcon} alt="info" width="20" className="inline-block mr-1 pb-1" />
					Required field: 'Drill Core Size'
				</div>
			);
		}
	
		return null;
	};





	return (
		<Modal title="Drill Core Size" subtitle={borehole} toggleModal={onClose}>
			<div className="flex flex-col flex-grow px-1 sm:px-2 md:px-10">
				{/* <WorkflowSteps steps={[
					{
						label: 'Drill Core Size',
						status: stepStatus(1, 1),
					}
				]} className="mb-6" /> */}

				<div className="flex flex-col flex-grow border border-grey-mid rounded px-2 py-2 bg-sptTB mb-2">

					<div className="text-xl text-center mb-2">
						Select Drill Core Size
					</div>
					<div className="text-xs sm:text-sm text-center mb-4">
						| Size | Core Diameter (mm) | Hole Diameter (mm) |
					</div>

					<div className="flex-1 flex text-center text-base sm:text-xl md:text-lg lg:text-2xl">
						<ButtonOptions onUpdate={(value: any) => setDrillCoreSize(value)}
							// title="Select Drill Core Size" 
							defaultValue={drill_core_size_id}
							// defaultValue={drill_core_diameter_inside} 
							// defaultValue={drill_core_diameter_outside} 
							options={[
								// TODO the values here should be id numbers?!?
								{
									value: 0, label: <>
										<div className="flex align-center justify-between px-3">
											<div className="font-bold">AQ</div>
											<div className="text-grey-mid">|</div>
											<div>35.5</div>
											<div className="text-grey-mid">|</div>
											<div>48.0</div>
										</div>
									</>,
								},
								{
									value: 1, label: <>
										<div className="flex align-center justify-between px-3">
											<div className="font-bold">BQ</div>
											<div className="text-grey-mid">|</div>
											<div>36.4</div>
											<div className="text-grey-mid">|</div>
											<div>60.0</div>
										</div>
									</>,
								},
								{
									value: 2, label: <>
										<div className="flex align-center justify-between px-3">
											<div className="font-bold">BQ2</div>
											<div className="text-grey-mid">|</div>
											<div>40.7</div>
											<div className="text-grey-mid">|</div>
											<div>60.0</div>
										</div>
									</>,
								},
								{
									value: 3, label: <>
										<div className="flex align-center justify-between px-3">
											<div className="font-bold">NQ</div>
											<div className="text-grey-mid">|</div>
											<div>47.6</div>
											<div className="text-grey-mid">|</div>
											<div>75.7</div>
										</div>
									</>,
								},
								{
									value: 4, label: <>
										<div className="flex align-center justify-between px-3">
											<div className="font-bold">NQ2</div>
											<div className="text-grey-mid">|</div>
											<div>50.6</div>
											<div className="text-grey-mid">|</div>
											<div>75.7</div>
										</div>
									</>,
								},
								{
									value: 5, label: <>
										<div className="flex align-center justify-between px-3">
											<div className="font-bold">NQ3</div>
											<div className="text-grey-mid">|</div>
											<div>45.0</div>
											<div className="text-grey-mid">|</div>
											<div>75.7</div>
										</div>
									</>,
								},
								{
									value: 6, label: <>
										<div className="flex align-center justify-between px-3">
											<div className="font-bold">NX</div>
											<div className="text-grey-mid">|</div>
											<div>54.7</div>
											<div className="text-grey-mid">|</div>
											<div>63.5</div>
										</div>
									</>,
								},
								{
									value: 7, label: <>
										<div className="flex align-center justify-between px-3">
											<div className="font-bold">HQ</div>
											<div className="text-grey-mid">|</div>
											<div>63.5</div>
											<div className="text-grey-mid">|</div>
											<div>96.0</div>
										</div>
									</>,
								},
								{
									value: 8, label: <>
										<div className="flex align-center justify-between px-3">
											<div className="font-bold">HQ3</div>
											<div className="text-grey-mid">|</div>
											<div>61.1</div>
											<div className="text-grey-mid">|</div>
											<div>96.0</div>
										</div>
									</>,
								},
								{
									value: 9, label: <>
										<div className="flex align-center justify-between px-3">
											<div className="font-bold">PQ</div>
											<div className="text-grey-mid">|</div>
											<div>85.0</div>
											<div className="text-grey-mid">|</div>
											<div>122.6</div>
										</div>
									</>,
								},
								{
									value: 10, label: <>
										<div className="flex align-center justify-between px-3">
											<div className="font-bold">PQ3</div>
											<div className="text-grey-mid">|</div>
											<div>83.0</div>
											<div className="text-grey-mid">|</div>
											<div>122.6</div>
										</div>
									</>,
								},
								{
									value: 11, label: <>
										<div className="flex align-center justify-between px-3">
											<div className="font-bold">T6H</div>
											<div className="text-grey-mid">|</div>
											<div>80.5</div>
											<div className="text-grey-mid">|</div>
											<div>98.8</div>
										</div>
									</>,
								},
								{
									value: 12, label: <>
										<div className="flex align-center justify-between px-3">
											<div className="font-bold">PWF</div>
											<div className="text-grey-mid">|</div>
											<div>92.3</div>
											<div className="text-grey-mid">|</div>
											<div>120.6</div>
										</div>
									</>,
								},
								{
									value: 13, label: <>
										<div className="flex align-center justify-between px-3 pt-0.5 sm:pt-0 text-sm sm:text-xl md:text-lg lg:text-2xl">
											<div className="font-bold">Geobor</div>
											<div className="text-grey-mid">|</div>
											<div>102.0</div>
											<div className="text-grey-mid">|</div>
											<div>146.0</div>
										</div>
									</>,
								},
							]}
							className="mb-6"
							buttonHeight={12}
							buttonHeightMobile={8}
							columns={3}
							columnsMobile={2}
							gap={3}
						/>
					</div>

				</div>

				<MissingRequiredField />


				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">


							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?

								<div className="flex mt-4">
									<Button theme="secondary" 
										className="mr-5 text-sm sm:text-base px-3.5 md:px-7" 
										onClick={() => setDeleteConfirmOpen(false)}>
											Cancel
									</Button>

									<Button onClick={() => {
												onClose()
												onComplete({
													guid: guid,
													update: false,
													delete: true,
												})
												setShowDrillCoreSizeRecordsEdit(false)
											}}
										className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">
										Delete
									</Button>
								</div>
							</div>


						</div>
					</div>
				}

				<div className="flex">

					{/* <Button theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button> */}
					<DeleteButton />
					<div className="flex ml-auto">
						<Button theme="success" size="lg"
							className="ml-4 px-8 sm:px-10 w-32 sm:w-60"
							onClick={() => save()}>Save</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

DrillCoreSize.defaultProps = defaultProps

export default DrillCoreSize
