import React, { useState, useRef } from 'react'

import ProjectsTrialpitBorehole_function from './ProjectsTrialpitBorehole_function'

import { useSelector, useDispatch } from 'react-redux'
import { useGetIDBValues } from "../../../helpers/getIDBKey";
import { connect } from "react-redux";
import { get, set, update } from "idb-keyval";
import logoOuter from '../../../assets/logo-icon-outer.svg'

interface Props {
	router: any,
	toggleSidebar: () => void,
	selectedWeather: string,
	selectedDate: string,
	company: any,
	project: any,
}

interface State {
	project_guid: string,
	borehole: any,
	completingBorehole: boolean,
	starting_hole_type_id: any,

	editingHole: any,
	editingIndex: any,
	redirect: boolean,

	project: any,
	holeType: string,

	defaultValues: object,
	guid: string,

	company_guid: string,

	companyName: string,
	contactEmail: string,
	contactTelephone: string,
	companyAddress1: string,
	companyAddress2: string,
	companyAddress3: string,
	companyCounty: string,
	companyCountry: string,
	companyPostcode: string,
	companyAddress: string,

	rig: any,
	rigs: any,
	currentRig: any,
	spts: any,
	rigsSelect: any,
	currentRigValue: any,
	currentRigLabel: any,
	currentRig_name: string,
	currentRig_type: string,
	currentRig_plant_id: string,
	currentRig_test_date_expiry: string,
	hammers: any,
	currentRig_spt_guid: '',
	currentRig_spt: any,
	
	//HOLE DATA
	AllHoleData: any,
	active_dates: any,
	todaysDate: string,
	todaysDate_reverseFormat: string,
	lastActiveDate: any,
}

class ProjectsTrialpitBorehole extends React.Component<Props, State> {


	constructor(props: any) {
		super(props)
		const today = new Date();
		const day = today.getDate().toString().padStart(2, '0');
		const month = (today.getMonth() + 1).toString().padStart(2, '0');
		const year = today.getFullYear();

		this.state = {
			guid: this.props.router.match.params.id, // project ID
			project_guid: this.props.router.match.params.id,
			borehole: {
				project_guid: this.props.router.match.params.borehole, // hole ID
			},
			completingBorehole: false,
			starting_hole_type_id: 4,

			editingHole: '',
			editingIndex: null,
			redirect: false,

			project: '',
			holeType: '',

			defaultValues: {},

			company_guid: '',

			companyName: '',
			contactEmail: '',
			contactTelephone: '',
			companyAddress1: '',
			companyAddress2: '',
			companyAddress3: '',
			companyCounty: '',
			companyCountry: '',
			companyPostcode: '',
			companyAddress: '',

			rig: [],
			rigs: [],
			currentRig: [],
			spts: [],
			rigsSelect: [],
			currentRigValue: '',
			currentRigLabel: '',
			currentRig_name: '', //same as Label
			currentRig_type: '',
			currentRig_plant_id: '',
			currentRig_test_date_expiry: '',
			hammers: [],
			currentRig_spt_guid: '',
			currentRig_spt: {
				guid: '',
			},

			//HOLE DATA
			AllHoleData: [],
			active_dates: [],
			todaysDate: `${day}/${month}/${year}`,
			todaysDate_reverseFormat: `${year}-${month}-${day}`,
			lastActiveDate: `${day}/${month}/${year}`,
		}
	}

	generateUniqueDates = (holeData: any) => {
		if (holeData && holeData.hole_data) {
			const uniqueDatesArray = Object.values(holeData.hole_data)
				.flatMap((categoryData: any) =>
					categoryData.filter((action: any) => action !== null) // Filter out null actions
						.map((action: any) => new Date(action.time_of_action))
				);
	
			// Sort the array in descending order (newest first)
			uniqueDatesArray.sort((a: any, b: any) => {
				const dateA = new Date(b).getTime();
				const dateB = new Date(a).getTime();
				return dateA - dateB;
			});
	
			// Generate unique formatted dates from sorted raw dates
			const allUniqueDates = uniqueDatesArray.map((date: Date) => {
				const day = date.getDate().toString().padStart(2, '0');
				const month = (date.getMonth() + 1).toString().padStart(2, '0');
				const year = date.getFullYear();
				return `${day}/${month}/${year}`;
			});
	
			// Use Set to ensure unique values, then convert back to array
			const uniqueDatesArrayFiltered = [...new Set(allUniqueDates)];
	
			// Get the most recent date from uniqueDatesArray and format it as DD/MM/YYYY
			const mostRecentDateFormatted = uniqueDatesArrayFiltered.length > 0 ? uniqueDatesArrayFiltered[0] : null;
			console.log('mostRecentDate in CLASS:', mostRecentDateFormatted);
			if (mostRecentDateFormatted) {
				this.setState({ lastActiveDate: mostRecentDateFormatted });
			}
	
			// Update the state with the new unique dates
			this.setState({ active_dates: uniqueDatesArrayFiltered });
		} else {
			this.setState({ active_dates: [] });
		}
	}

	

	componentDidMount() {
		this.getBoreholeInfo()
		// this.getRigData()
		this.getHoleData()
	}

	getBoreholeInfo = () => {
		const boreholeId = this.props.router.match.params?.borehole;

		let cachedValues = useGetIDBValues;
		cachedValues(`hole-locations/${boreholeId}`, (data: any) => {
			this.setState({
				borehole: data
			});
		})
			.catch(error => {
				console.error('Failed to fetch from server, trying to get from IndexedDB:', error);

				// Fetch from IndexedDB if server request fails
				get(`drillit-hole-locations/${boreholeId}`)
					.then(data => {
						this.setState({
							borehole: data
						});
					})
					.catch(idbError => {
						console.error('Failed to fetch from IndexedDB:', idbError);
					});
			});
	}

	// getBoreholeInfo = () => {
	// 	const boreholeId = this.props.router.match.params?.borehole;
	
	// 	// Check if the record exists in IndexedDB first
	// 	get(`drillit-hole-locations/${boreholeId}`)
	// 		.then(data => {
	// 			if (data) {
	// 				// If data exists, set the state with the data
	// 				this.setState({
	// 					borehole: data
	// 				});
	// 			} else {
	// 				throw new Error('No data found in IndexedDB');
	// 			}
	// 		})
	// 		.catch(idbError => {
	// 			console.error('Failed to fetch from IndexedDB, trying to get from cachedValues:', idbError);
	
	// 			// If IndexedDB fetch fails or no data found, fetch from server using cachedValues
	// 			let cachedValues = useGetIDBValues;
	// 			cachedValues(`hole-locations/${boreholeId}`, (data: any) => {
	// 				this.setState({
	// 					borehole: data
	// 				});
	// 			})
	// 				.catch(error => {
	// 					console.error('Failed to fetch from server:', error);
	// 				});
	// 		});
	// }

	// getBoreholeInfo = () => {
	// 	const boreholeId = this.props.router.match.params?.borehole;
	
	// 	// Check if the record exists in IndexedDB first
	// 	get(`drillit-hole-locations/${boreholeId}`)
	// 		.then(data => {
	// 			if (data) {
	// 				// If data exists, set the state with the data
	// 				this.setState({
	// 					borehole: data
	// 				});
	// 			} else {
	// 				throw new Error('No data found in IndexedDB');
	// 			}
	// 		})
	// 		.catch(idbError => {
	// 			console.error('Failed to fetch from IndexedDB, trying to get from cachedValues:', idbError);
	
	// 			// If IndexedDB fetch fails or no data found, fetch from server using cachedValues
	// 			let cachedValues = useGetIDBValues;
	// 			cachedValues(`hole-locations/${boreholeId}`, (data: any) => {
	// 				this.setState({
	// 					borehole: data
	// 				});
	// 			})
	// 				.catch(error => {
	// 					console.error('Failed to fetch from server:', error);
	// 				});
	// 		});
	// }

	getHoleData = () => {

		let cachedValues = useGetIDBValues

		cachedValues(`actions/${this.state?.starting_hole_type_id}/${this.props.router.match.params?.borehole}`, (data: any) => {
			this.setState({
				AllHoleData: data
			})

			// Call the generateUniqueDates function with the fetched data
			this.generateUniqueDates(data);
	  
			if (this.state.active_dates.length === 0) {
			  // Create a copy of the active_dates array and push the new date to it
			  const updatedActiveDates = [...this.state.active_dates];
			  updatedActiveDates.push(this.state.todaysDate);
			  
			  // Update the state with the new active_dates
			  this.setState({ active_dates: updatedActiveDates });
			}
		  }).then()

		// get the project and the company guid from that
		cachedValues(`projects/${this.state.project_guid}`, (data: any) => {
			this.setState({
				project: data
			})
		}).then()
	}

	// getHoleData = () => {
	// 	const boreholeId = this.props.router.match.params?.borehole;
	// 	const holeTypeId = this.state?.starting_hole_type_id;
	// 	const projectGuid = this.state.project_guid;
	
	// 	// Try to fetch from IndexedDB first for actions
	// 	get(`drillit-actions/${holeTypeId}/${boreholeId}`)
	// 		.then(data => {
	// 			if (data) {
	// 				this.setState({
	// 					AllHoleData: data
	// 				});
	
	// 				// Call the generateUniqueDates function with the fetched data
	// 				this.generateUniqueDates(data);
	
	// 				if (this.state.active_dates.length === 0) {
	// 					// Create a copy of the active_dates array and push the new date to it
	// 					const updatedActiveDates = [...this.state.active_dates];
	// 					updatedActiveDates.push(this.state.todaysDate);
	
	// 					// Update the state with the new active_dates
	// 					this.setState({ active_dates: updatedActiveDates });
	// 				}
	// 			} else {
	// 				throw new Error('No data found in IndexedDB for actions');
	// 			}
	// 		})
	// 		.catch(idbError => {
	// 			console.error('Failed to fetch from IndexedDB for actions, trying to get from cachedValues:', idbError);
	
	// 			// If IndexedDB fetch fails, fetch from server using cachedValues
	// 			let cachedValues = useGetIDBValues;
	// 			cachedValues(`actions/${holeTypeId}/${boreholeId}`, (data: any) => {
	// 				this.setState({
	// 					AllHoleData: data
	// 				});
	
	// 				// Call the generateUniqueDates function with the fetched data
	// 				this.generateUniqueDates(data);
	
	// 				if (this.state.active_dates.length === 0) {
	// 					// Create a copy of the active_dates array and push the new date to it
	// 					const updatedActiveDates = [...this.state.active_dates];
	// 					updatedActiveDates.push(this.state.todaysDate);
	
	// 					// Update the state with the new active_dates
	// 					this.setState({ active_dates: updatedActiveDates });
	// 				}
	// 			}).catch(error => {
	// 				console.error('Failed to fetch actions from server:', error);
	// 			});
	// 		});
	
	// 	// Try to fetch the project data from IndexedDB first
	// 	get(`drillit-projects/${projectGuid}`)
	// 		.then(data => {
	// 			if (data) {
	// 				this.setState({
	// 					project: data
	// 				});
	// 			} else {
	// 				throw new Error('No data found in IndexedDB for project');
	// 			}
	// 		})
	// 		.catch(idbError => {
	// 			console.error('Failed to fetch project from IndexedDB, trying to get from cachedValues:', idbError);
	
	// 			// If IndexedDB fetch fails, fetch from server using cachedValues
	// 			let cachedValues = useGetIDBValues;
	// 			cachedValues(`projects/${projectGuid}`, (data: any) => {
	// 				this.setState({
	// 					project: data
	// 				});
	// 			}).catch(error => {
	// 				console.error('Failed to fetch project from server:', error);
	// 			});
	// 		});
	// }

	handleReloadClick = () => {
		window.location.reload();
	  };

	render() {


		// console.log('company_guid:', this.state.project?.company_guid)
		// console.log('project_guid:', this.state.project_guid)
		// console.log('project_id:', this.props.router.match.params.id)
		// console.log('starting_hole_type_id:', this.state.borehole?.starting_hole_type_id)
		// console.log('borehole_guid:', this.state.borehole?.guid)
		// console.log('selectedWeather:', this.props.selectedWeather)
		// console.log('selectedDate:', this.props.selectedDate)
		// console.log('borehole_id:', this.props.router.match.params.borehole)
		// console.log('borehole_name:', this.state.borehole?.hole_name)
		// console.log('rig_name:', this.state.currentRigLabel)
		// console.log('rig_id:', this.state.currentRigValue)
		// console.log('AllHoleData:', this.state?.AllHoleData)
		// console.log('activeDates:', this.state.active_dates)

		// Render loading state while data is being fetched
 
		if (this.state?.AllHoleData === undefined) {

			return <>
				<div className="flex items-center justify-center h-screen">
					<img src={logoOuter} className="logo-spin-sync absolute h-20" alt="logo gear" />
					<img src={logoOuter} className="logo-spin-sync-left absolute h-12" alt="logo gear" />
					<span className="pt-24 text-sm">LOADING</span>
				</div>
				<div className="-mt-10 clear-both text-grey-mid text-sm text-center w-full" onClick={() => { this.props.router.history.goBack(-1) }}>back</div>
			</>
		}

		if (this.state?.AllHoleData
			&& this.state?.AllHoleData !== undefined
			&& this.state?.AllHoleData.length === 0
			&& this.state?.active_dates && this.state?.active_dates.length === 0
		) {
			return <>
				<div className="flex items-center justify-center h-screen">
					<img src={logoOuter} className="logo-spin-sync absolute h-20" alt="logo gear" />
					<img src={logoOuter} className="logo-spin-sync-left absolute h-12" alt="logo gear" />
					<span className="pt-24 text-sm">INITIALIZING</span>
				</div>
				<div className="-mt-10 clear-both text-grey-mid text-sm text-center w-full" onClick={() => { this.props.router.history.goBack(-1) }}>back</div>
			</>
		}

		return (

			
				<ProjectsTrialpitBorehole_function
					company_guid={this.state.project?.company_guid}
					project_guid={this.state.project_guid}
					project_id={this.state.project.id}
					project_name={this.state.project.project_identifier}
					starting_hole_type_id= '4' // {this.state.borehole?.starting_hole_type_id} //'4' // Trialpit
					borehole_guid={this.state.borehole?.guid}
					selectedWeather={this.props.selectedWeather}
					selectedDate={this.props.selectedDate}
					lastActiveDate={this.state.lastActiveDate}
					borehole_id={this.state.borehole?.id} // this.props.router.match.params.borehole
					borehole_name={this.state.borehole?.hole_name}
					toggleSidebar={this.props.toggleSidebar}
					// rig_name = {this.state.currentRigLabel}
					// rig_id = {this.state.currentRigValue}
					//HOLE DATA
					AllHoleData = {this.state.AllHoleData}
					activeDates = {this.state.active_dates}
					reloadData={this.handleReloadClick}
				/>
		)
	}
}

const mapStateToProps = (state: any) => ({
	selectedWeather: state.weather,
	selectedDate: state.date,
})

export default connect(mapStateToProps, null)(ProjectsTrialpitBorehole)
