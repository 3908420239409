import { INCREMENT_DATE, DECREMENT_DATE, SET_SELECTED_DATE } from '../action-types'

const addDay = (date: any) => {
    var result = new Date(date);
    result.setDate(result.getDate() + 1);
    return result;
  };
  
  const subDay = (date: any) => {
    var result = new Date(date);
    result.setDate(result.getDate() - 1);
    return result;
  };
  
  const defaultState = new Date();
  
  const DateReducer = (date = defaultState, action: any) => {
    switch (action.type) {
      case INCREMENT_DATE: {
        return addDay(date);
      }
      case DECREMENT_DATE: {
        return subDay(date);
      }
      case SET_SELECTED_DATE: {
        return action.payload; // Set the date to the payload value
      }
      default: {
        return date;
      }
    }
  };
  
  export default DateReducer;

// const addDay = (date: any) => {
// 	var result = new Date(date)
// 	result.setDate(result.getDate() + 1)

// 	return result
// }

// const subDay = (date: any) => {
// 	var result = new Date(date)
// 	result.setDate(result.getDate() - 1)

// 	return result
// }

// const defaultState = new Date()

// const DateReducer = (date = defaultState, action: any) => {
// 	switch (action.type) {
// 		case INCREMENT_DATE: {
// 			return addDay(date)
// 		}
// 		case DECREMENT_DATE: {
// 			return subDay(date)
// 		}
// 		default: {
// 			return date
// 		}
// 	}
// }

// export default DateReducer
