import React from 'react'

import Heading from '../../components/Heading'
import Container from '../../layout/Container'
import Search from '../../components/Search'

import Project from '../../components/project/Project'
import AddProject from '../../components/project/AddProject'
import AdminPanel from '../../views/AdministrationPanel'
import bars from '../../assets/bars.svg';

import Header from '../../layout/HeaderCompanyInfo'
import { useGetIDBValues } from "../../helpers/getIDBKey";
import { get, set } from 'idb-keyval'
import { Link, useParams } from 'react-router-dom'
import PrinterSetup from '../PrinterSetup'



import SyncStatus from "../../helpers/syncStatus";
import logoOuter from '../../assets/logo-icon-outer.svg'

interface Props {
	toggleSidebar: () => void,
	router: any,
}

interface State {
	projects: any[],
	filteredProjects: any[],
	activeProjects: any[],
	archivedProjects: any[],
	holeTypes: any[],
	users: any[],
	companyContacts: any[],
	company_guid: string,
	company_id: number,
	user: string,
	showInstallButton: boolean,
	deferredPrompt: Event | null;
	access_level: number,
	showAdminPanel: boolean,
	showArchivedProjects: boolean,
	showPrinterSetup: boolean,
}

class Projects extends React.Component<Props, State> {
	constructor(props: any) {
		super(props)

		this.state = {
			projects: [],
			filteredProjects: [],
			activeProjects: [],
			archivedProjects: [],
			holeTypes: [],
			users: [],
			companyContacts: [],
			company_guid: '',
			company_id: 999999,
			user: '',
			showInstallButton: false,
			deferredPrompt: null,
			access_level: 0,
			showAdminPanel: false,
			showArchivedProjects: false,
			showPrinterSetup: false,
		}
	}

	filterProjects = (search: string) => {
		if (search === '')
			return this.setState({ filteredProjects: this.state.projects })

		return this.setState({
			filteredProjects: this.state?.projects.filter((project: any) => {
				return (
					project.location.toLowerCase().includes(search.toLowerCase()) ||
					project.project_identifier.toLowerCase().includes(search.toLowerCase())
				)
			})
		})
	}

	componentDidMount() {
		this.getCompanyGuid()
		// this.getUsers()
		// this.getContacts()
		// this.getProjects()
		// this.getRigs()
		// Check if the app is running in standalone mode
		// Detect if the app is running in standalone mode
		// Check if the app is running in standalone mode
		// Check if the app is running in standalone mode
		const isInStandaloneMode = () => window.matchMedia('(display-mode: standalone)').matches;

		if (!isInStandaloneMode()) {
			window.addEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt);
		}
	}

	componentWillUnmount() {
		window.removeEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt);
	}

	handleBeforeInstallPrompt = (e: Event) => {
		e.preventDefault();
		console.log('beforeinstallprompt event fired');
		this.setState({ showInstallButton: true, deferredPrompt: e });
	};

	handleInstallClick = () => {
		const { deferredPrompt } = this.state;
		if (deferredPrompt) {
			(deferredPrompt as any).prompt();
			(deferredPrompt as any).userChoice.then((choiceResult: { outcome: string }) => {
				if (choiceResult.outcome === 'accepted') {
					console.log('User accepted the install prompt');
				} else {
					console.log('User dismissed the install prompt');
				}
				this.setState({ showInstallButton: false, deferredPrompt: null });
			});
		}
	};

	toggleArchivedProjects = () => {
		this.setState(prevState => ({
			showArchivedProjects: !prevState.showArchivedProjects
		}));
	};

	getProjects = () => {
		let cachedValues = useGetIDBValues

		cachedValues(`projects/company/${this.state?.company_id}`, (data: any) => {
			if (data) {
				// const filteredProjects = data.filter((project: any) => project.company && project.company.id === this.state.company_id);
				// this.setState({
				// 	projects: filteredProjects
				// }, () => {
				// 	this.filterProjects('')
				// })
				// console.log('project data:', data)

				// Filter projects based on company_id
				const filteredProjects = data.filter((project: any) => project.company && project.company.id === this.state.company_id);

				// Separate active and archived projects
				const activeProjects = filteredProjects.filter((project: any) => project.parked === 0);
				const archivedProjects = filteredProjects.filter((project: any) => project.parked === 1);

				// Update state with both active and archived projects
				this.setState({
					filteredProjects, // This will hold all projects (both active and archived)
					activeProjects,
					archivedProjects,
					showArchivedProjects: false // Default to showing active projects
				});
			}

		})


		cachedValues(`hole-types`, (data: any) => {
			// needs to set the state with the class method
			// setHoleTypes(data?.map((data:any) => ({ id: data.id, name: data.name })))
			if (data) {
				this.setState({
					holeTypes: data
				})
			}
		})
	}

	getCompanyGuid = () => {
		get('drillit-user').then(data => {
			console.log('drillit-user data:', data)
			this.setState({
				company_guid: data.user?.company.guid,
				company_id: data.user?.company.id,
				user: data.user?.first_name + ' ' + data.user?.last_name,
				access_level: data.user?.access_level,
			})
		}).then(() => {
			this.getUsers()
		}).then(() => {
			this.getRigs()
		}).then(() => {
			this.getProjects()
		}).then(() => {
			this.getContacts()
		}).then(() => {
			this.getPrintSettings()
		})
	}

	getUsers = () => {
		let cachedValues = useGetIDBValues;

		const companyUsers = `users/company/${this.state?.company_id}`;

		cachedValues(companyUsers, (data: any) => {
			if (data) {
				// Extract users from the response
				const users = data.users;

				// Set the state with the users
				this.setState({
					users: users
				});

				console.log('User data:', users);
			} else {
				// Handle error case if needed
				// console.error('Error fetching users:', data.message);
			}
		});

	};

	getPrintSettings = () => {
		get('drillit-print-settings').then(data => {
            console.log('drillit-print-settings:', data);
            if (!data) {
                // Set default settings if no data exists
                const defaultSettings = {
                    print_type: 'Print Manager',
                    numberD: 1,
                    numberB: 1,
                    numberES: 1,
                    numberWS: 1,
                    numberU: 1,
                    numberPS: 1,
                    numberL: 1,
                    numberCS: 1,
                };
                // Save default settings to IndexedDB
                set('drillit-print-settings', defaultSettings).then(() => {
                    console.log('Default settings saved to IDB:', defaultSettings);
                });
            }
        });
	};

	getContacts = () => {
		let cachedValues = useGetIDBValues;

		const companyContacts = 'contacts/company/' + this.state?.company_id;

		cachedValues(companyContacts, (data: any) => {
			if (data) {
				// Extract users from the response if data is not null or undefined
				const companyContacts = data.contacts;

				// Set the state with the users
				this.setState({
					companyContacts: companyContacts
				});

				console.log('companyContacts data:', companyContacts);
			} else {
				// Handle the case when there are no company contacts
				console.log('No company contacts found.');
				// Optionally, you can set the companyContacts state to an empty array or null
				this.setState({
					companyContacts: []
				});
			}
		});
	};

	handleAdminPanel = () => {

	}

	getRigs = () => {
		let cachedValues = useGetIDBValues;
		const companyRigs = 'rigs/company/' + this.state?.company_guid;

		cachedValues(companyRigs, (data: any) => {
			console.log('Company Rig data:', data);
			//   if (data) {
			// 	  // Extract users from the response
			// 	  const rigs = data.users;

			// 	  // Set the state with the users
			// 	  this.setState({
			// 		  users: users
			// 	  });

			// 	  console.log('User data:', users);
			//   } else {
			// 	  // Handle error case if needed
			// 	  // console.error('Error fetching users:', data.message);
			//   }
		})

	};



	render() {

		const { showInstallButton } = this.state;
		console.log('this.state:', this.state)

		// console.log('company_id:', this.state.company_id)
		// console.log('user:', this.state.user)
		// console.log('users:', this.state.users)
		// console.log('access_level:', this.state.access_level)
		// console.log('filteredProjects:', this.state.filteredProjects)

		// console.log('showInstallButton:', this.state.showInstallButton)

		if (!this.state.company_guid) {
			// Render loading state while data is being fetched
			return <div className="flex items-center justify-center h-screen">
				<img src={logoOuter} className="logo-spin-sync absolute h-20" alt="logo gear" />
				<img src={logoOuter} className="logo-spin-sync-left absolute h-12" alt="logo gear" />
				<span className="pt-24 text-sm">LOADING</span>
			</div>
		}

		// console.log('this.state.company_id:', this.state.company_id)

		const { showArchivedProjects, activeProjects, archivedProjects } = this.state;
		const projectsToDisplay = showArchivedProjects ? archivedProjects : activeProjects;

		return (
			<>

				<Header toggleSidebar={this.props.toggleSidebar} dark={true} company_id={this.state?.company_id} />

				{/* <Container className="bg-body"> */}
				<Container className="">

					{showInstallButton && (
						<button
							onClick={this.handleInstallClick}
							style={{
								padding: '20px',
								fontSize: '18px',
								backgroundColor: '#2185D0',
								color: 'white',
								border: 'none',
								borderRadius: '5px',
								cursor: 'pointer',
								display: 'block',
								margin: '20px auto',
								position: 'fixed',
								bottom: '20px',
								right: '20px',
								zIndex: 999
							}}
						>
							Install App
						</button>
					)}

					{this.state.showAdminPanel && (
						<AdminPanel onClose={() => {
							this.setState({
								showAdminPanel: false
							});
						}} />
					)}

					{this.state.showPrinterSetup && (
						<PrinterSetup onClose={() => { 
							this.setState({
								showPrinterSetup: false
							});
						}}/>
					)}

					<div className="text-center text-sm font-bold py-1.5 mx-auto mt-2 bg-blue rounded flex justify-between items-center">

						<div className="w-8 pl-1"></div>

						<span className="rounded py-0.5 px-2 bg-white mx-auto">Welcome {this.state.user}</span>

						<div className="w-8 pr-1">

							{this.state.access_level === 1 &&
								<>
									<div className="w-7"
										onClick={() => { this.setState({ showAdminPanel: true }); }}>
										<img src={bars} className="h-7 w-7" alt="" />
									</div>
								</>
							}

						</div>
					</div>





					<Heading left={['Projects']} right={[<Search onChange={(value: string) => {
						this.filterProjects(value)
					}} />]} className="pt-4 mb-0" />



					{/* {this.state.access_level === 1 &&
						<>						
							<div className="w-36 py-2 bg-grey-vdark -mt-14 mb-6 text-sm text-white rounded-md text-center mx-auto" 
							onClick={() => { this.setState({ showAdminPanel: true }); }}>
								Admin Panel <img src={bars} className="h-9 w-9" alt="" />
							</div>
						</>
					} */}

							{/* {this.state.access_level === 1 &&
								<>

									<div onClick={() => { 
											this.setState({
												showPrinterSetup: true
											})
										}}
										className="w-full text-center font-bold mb-4">
											PRINTER SETUP
									</div>
								</>
							} */}



					<div className="grid-cols-1 grid md:grid-cols-3 lg:grid-cols-4 gap-2 md:gap-6 pb-20">

						<AddProject />

						{/* {this.state.filteredProjects
							.slice() // Create a copy of the array before reversing
							.reverse() // Reverse the order of the copied array
							.map((project: any, index: number) => {
								return <Project location={project.location} company={project.company_guid} number={project.project_identifier} id={project.guid} name={project.name} startdate={project.start_date} router={this.props.router} key={index} />
							})
						} */}

						{projectsToDisplay
							.slice() // Create a copy of the array before reversing
							.reverse() // Reverse the order of the copied array
							.map((project, index) => (
								<Project
									location={project.location}
									company={project.company_guid}
									number={project.project_identifier}
									id={project.guid}
									name={project.name}
									startdate={project.start_date}
									router={this.props.router}
									key={index}
								/>
							))
						}

						{this.state.archivedProjects.length > 0 && (
							<div className={`flex justify-center items-center rounded-md md:rounded-lg border-2 md:border-2 border-blue pt-0 md:pt-1.5 pb-0 md:pb-2.5 px-3 bg-white`}>
								<button onClick={this.toggleArchivedProjects} className="text-center font-bold text-lg">
									{this.state.showArchivedProjects ? 'Unarchived Projects' : 'Archived Projects'}
								</button>
							</div>
						)}


					</div>



				</Container>



				{/* <div className="grid grid-cols-2 gap-1 projectsBottomButtons"> 

					<div>
						<div className="w-full md:w-1/2 ml-auto bg-blue text-white py-2 text-center text-sm md:text-lg font-bold rounded">
							RIGS
						</div>
					</div>

					<div>
						<div className="w-full md:w-1/2 mr-auto bg-blue text-white py-2 text-center text-sm md:text-lg font-bold rounded">
							DAILY PLANT CHECKS
						</div>
					</div>

				</div> */}



				{/* <div className="min-h-full">

	<div className="text-center">Welcome {this.state.user}</div>

	<div className="flex-grow">
	This is the main content
	</div>

	<div className="flex h-12">
		<div className="flex bg-blue text-white py-2 w-1/3 mx-auto text-center text-lg font-bold rounded">
			RIGS
		</div>

		<div className="flex bg-blue text-white py-2 w-1/3 mx-auto text-center text-lg font-bold rounded">
			DAILY PLANT CHECKS
		</div>
	</div>
</div> */}



				<SyncStatus synced />
			</>
		)
	}
}

export default Projects
