import React, { FunctionComponent, useState } from 'react'

import WorkflowSteps from '../../WorkflowSteps'
import Modal from '../../Modal'
import Button from '../../form/Button'
import NumberInput from '../../NumberInput'
import ButtonOptions from '../../form/ButtonOptions'
// import MultiNumberInput from '../../MultiNumberInput'

import { v4 as uuidv4 } from 'uuid';
import stepStatus from '../../../helpers/stepStatus'

import PrintLabel from '../../../components/PrintLabel'
// import { updateSelectedProject } from '../../../actions/SelectedProject'
// import { useSelector, useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
// import { useGetIDBValues } from "../../../helpers/getIDBKey"
import { useGetLoggedInUser } from '../../../helpers/getLoggedInUser'
import warningIcon from '../../../assets/icons/warning.png'

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: string,
	defaultValues?: any,
	selectedDate?: string,
	update?: any,
	projectId?: string,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => { },
	onComplete: () => { },
	borehole: '',
	projectId: '',
	defaultValues: {
		guid: '',
		top_of_run: '',
		base_of_run: '',
		internal_diameter: '',
		sample_recovery: '',
		hole_total_depth: '',
		liner: '',
	},
	selectedDate: '',
}

const DriveRecordsWindowSample: FunctionComponent<Props> = ({ onClose, update, onComplete, borehole, defaultValues, sampled_by,
	selectedDate, new_type_number, lastDriveRecord, setShowDriveRecordsEdit, holeDepth, ...rest }) => {

		console.log('defaultValues:', defaultValues)

	const [currentStep, setCurrentStep] = useState(1)
	const date = useSelector((state: any) => state.date)
	var labelDate = date.toLocaleDateString("en-UK")

	const [guid, setGuid] = useState(defaultValues.guid)
	const [depth_from, setDepthFrom] = useState(lastDriveRecord) // lastDriveReord depth - increments of 1m
	let [depth_to, setDepthTo] = useState(defaultValues.depth_to)
	let [hole_depth, setHoleDepth] = useState(defaultValues.hole_depth)
	const [sample_recovery, setRecovery] = useState(defaultValues.sample_length)
	const [liner, setLiner] = useState(defaultValues.liner_diameter)
	const last_drive_record = lastDriveRecord
	const [sampler, setSampler] = useState(sampled_by)

	console.log('lastDriveRecord:', typeof lastDriveRecord, lastDriveRecord)
	console.log('holeDepth:', typeof holeDepth, holeDepth)

	const [saveButtonText, setButtonText] = useState('Continue')
	const [saveButtonColor, setButtonColor] = useState('primary')

	const loggedInUser = useGetLoggedInUser()

	const selectedProject = useSelector((state: any) => state.selectedProject)
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	const DeleteButton = () => {
		if (!update) return null;

		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	const time_of_action = defaultValues.time_of_action

	const isoDate = selectedDate ? `${new Date(selectedDate).toISOString().split('T')[0]} 09:00:00` : new Date().toISOString().split('.')[0]

	const previous = () => {
		if (currentStep > 1)
			setCurrentStep(currentStep - 1)
		setButtonText('Continue')
		setButtonColor('primary')
	}

	const next = () => {
		if (currentStep < 6) {
			setCurrentStep(currentStep + 1)
			if (currentStep === 5) {
				setButtonText('Save')
				setButtonColor('success')
			} else {
				setButtonText('Continue')
				setButtonColor('primary')
			}
			return
		}

		if (depth_from === '' || liner === '') {
			//do nothing
		} else {

			onComplete({
				guid: guid ? guid : uuidv4(),
				depth_from: depth_to_top2DP, // depth_from, //depth_to_top2DP,
				depth_to: depth_to_base_calc2DP, // depth_to, //depth_to_base_calc2DP,
				hole_depth: hole_depth2DP, //2DP,
				liner_diameter: liner,
				sample_length: sample_recovery2DP, //2DP,
				drive_number: type_number_output,
				time_of_action: time_of_action ? time_of_action : isoDate,
				update: update ? true : false
			})
		}
	}

	let requiredDepthFromText = ''
	let requiredDiameterText = ''

	if (!depth_from) {
		requiredDepthFromText = "'Depth From' - "
	}

	if (!liner) {
		requiredDiameterText = "'Diameter'"
	}

	const MissingRequiredField = () => {
		if (currentStep === 6 && (!depth_from || !liner)) return (
			<div className="text-center bg-sptSB border border-red font-bold rounded mb-2 py-1 text-xs md:text-sm lg:text-base">
				<img src={warningIcon} alt="info" width="20" className="inline-block mr-1 pb-1" />
				Required field: {requiredDepthFromText} {requiredDiameterText}
			</div>
		)

		return null
	}

	
// console.log('holeDepth:', depth_from, typeof holeDepth, holeDepth)

	// let depth_to_top2DP = 0
	let depth_to_top2DP = parseFloat(depth_from).toFixed(2)
	let depth_to_base_calc = 0
	let depth_to_base_calc2DP = ''
	let depth_to_preset = '1.00'
	let hole_depth_preset = '1.00'
	let printDepthFrom = ''
	let depth_from_temp = ''
	if (update) {
		depth_from_temp = defaultValues.depth_from
	}
	// let sample_recovery_preset = '1.00'
	// console.log('depth_from:', depth_from)
	if (!depth_from && holeDepth === '0.00') {
		depth_to_base_calc = 0
	} else if (!depth_from && holeDepth !== '0.00') {
		depth_to_top2DP = parseFloat(holeDepth).toFixed(2)
		depth_to_base_calc = Math.floor(+parseFloat(holeDepth) + 1)
		// depth_to_base_calc2DP = (Math.round(depth_to_base_calc * 100) / 100).toFixed(2) // depth to calcs
		depth_to_preset = (Math.round(depth_to_base_calc * 100) / 100).toFixed(2)
		hole_depth_preset = depth_to_preset
	} else {

		if (!update) { // NEW RECORD

			if (depth_from === 'GL' || depth_from === 'G' || depth_from === 0 || depth_from === '0.00') { // console.log('GL')
				depth_to_top2DP = 'GL'
				printDepthFrom = 'GL'
				depth_to_preset = '1.00'
				depth_to_base_calc = 1.00
				hole_depth_preset = '1.00'
			} else {
				console.log('holeDepth***:', holeDepth)
				depth_to_top2DP = parseFloat(holeDepth).toFixed(2)
				console.log('depth_to_top2DP***:', depth_to_top2DP)
				printDepthFrom = depth_to_top2DP
				depth_to_base_calc = Math.floor(+parseFloat(holeDepth) + 1)
				// depth_to_base_calc2DP = (Math.round(depth_to_base_calc * 100) / 100).toFixed(2) // depth to calcs
				depth_to_preset = (Math.round(depth_to_base_calc * 100) / 100).toFixed(2)
				hole_depth_preset = depth_to_preset
			}
			if (last_drive_record !== 0){
				depth_to_top2DP = last_drive_record.toFixed(2)
				printDepthFrom = last_drive_record.toFixed(2)
				depth_to_preset = (last_drive_record + 1).toFixed(2)
				depth_to_base_calc = (last_drive_record + 1).toFixed(2)
				hole_depth_preset = parseFloat(last_drive_record + 1).toFixed(2)
			}

		} else { // EDIT RECORD

			if (last_drive_record !== 0){
				console.log('last_drive_record:', last_drive_record, parseFloat(last_drive_record).toFixed(2))
				depth_to_top2DP = parseFloat(last_drive_record).toFixed(2)
				printDepthFrom = last_drive_record.toFixed(2)
				depth_to_preset = (last_drive_record + 1).toFixed(2)
				depth_to_base_calc = (last_drive_record + 1).toFixed(2)
				hole_depth_preset = parseFloat(last_drive_record + 1).toFixed(2)
			} 
		}

		depth_to_base_calc2DP = (Math.round(depth_to * 100) / 100).toFixed(2)
	}

	if (printDepthFrom === '0' || printDepthFrom === '0.00') {printDepthFrom = 'GL'}

	var printDepth = printDepthFrom + ' - ' + depth_to_base_calc2DP
	console.log('depth_to_top2DP:', depth_to_top2DP)
	console.log('printDepth:', printDepth)


	console.log('new_type_number:', new_type_number)
	var sampleID = ''
	var type_number_output = 0
	if (update) {
		console.log('update?:', update, defaultValues.type_number)
		sampleID = "L-" + defaultValues.drive_number
		type_number_output = defaultValues.drive_number
	} else {
		sampleID = "L-" + new_type_number
		type_number_output = new_type_number
	}

	let hole_depth2DP = ''
	if (!hole_depth) {
		hole_depth2DP = ''
	} else {
		hole_depth2DP = parseFloat(hole_depth).toFixed(2)
	}

	let sample_recovery2DP = ''
	if (!sample_recovery) {
		sample_recovery2DP = ''
	} else {
		sample_recovery2DP = parseFloat(sample_recovery).toFixed(2)
	}

	let depth_from_preset_title, depth_from_preset_value
	if(!last_drive_record || last_drive_record === 0) {
		depth_from_preset_title = 'GL'
		depth_from_preset_value = '0'
	} else {
		depth_from_preset_title = parseFloat(last_drive_record).toFixed(2)
		depth_from_preset_value = parseFloat(last_drive_record).toFixed(2)
	}

	if (holeDepth !== '0.00' && !last_drive_record) {
		depth_from_preset_title = parseFloat(holeDepth).toFixed(2)
		depth_from_preset_value = parseFloat(holeDepth).toFixed(2)
	}

	// console.log('*****************************************')
	// console.log('depth_from:', depth_from)
	// console.log('depth_to:', depth_to)
	// console.log('hole_depth2DP:', hole_depth2DP)
	// console.log('liner:', liner)
	// console.log('sample_recovery2DP:', sample_recovery2DP)
	// console.log('*****************************************')

	return (
		<Modal title="Drive Records" subtitle={borehole} toggleModal={onClose}>
			<div className="flex flex-col flex-grow px-1 sm:px-2 md:px-10">
				<WorkflowSteps steps={[
					{
						label: 'Depth From',
						status: stepStatus(1, currentStep),
						onClick: () => {
							setCurrentStep(1)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Depth To',
						status: stepStatus(2, currentStep),
						onClick: () => {
							setCurrentStep(2)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Hole Depth',
						status: stepStatus(3, currentStep),
						onClick: () => {
							setCurrentStep(3)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Recovery',
						status: stepStatus(4, currentStep),
						onClick: () => {
							setCurrentStep(4)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Diameter',
						status: stepStatus(5, currentStep),
						onClick: () => {
							setCurrentStep(5)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Label',
						status: stepStatus(6, currentStep),
						onClick: () => {
							setCurrentStep(6)
							setButtonText('Save')
							setButtonColor('success')
						}
					},
				]} className="mb-2" />

				<div className="flex-1 flex">
					{currentStep === 1 &&

						<>
							<NumberInput onUpdate={(value: any) => setDepthFrom(value)}
								title="Enter Depth From (m)"
								defaultValue={depth_from_temp}
								className="mb-10 text-2xl"
								presets={[
									{ value: depth_from_preset_value, label: depth_from_preset_title },
								]}
								next={next}
							/>

							{/* <MultiNumberInput values={
								[
									{defaultValue: depth_from , onUpdate: setDepthFrom, label: 'Enter Depth From (m)'},
									{defaultValue: depth_to_base_calc2DP , onUpdate: setDepthTo, label: 'Enter Depth To (m)'},
								]
							} 
							className="mb-2 text-base sm:text-xl md:text-xl" 
							inputCSS="text-4xl sm:text-4xl md:text-3xl lg:text-4xl h-16 sm:h-16" 
							/> */}
						</>
					}

					{currentStep === 2 &&
						<>
							<NumberInput onUpdate={(value: any) => setDepthTo(value)}
								title="Enter Depth To (m)"
								defaultValue={depth_to}
								className="mb-10 text-2xl"
								presets={[
									{ value: depth_to_preset, label: depth_to_preset },
								]}
								next={next}
							/>
						</>
					}

					{currentStep === 3 &&
						<>
							<NumberInput onUpdate={(value: any) => setHoleDepth(value)}
								title="Enter Hole Depth (m)"
								defaultValue={hole_depth}
								className="mb-2 text-2xl"
								presets={[
									{ value: hole_depth_preset, label: hole_depth_preset },
								]}
								next={next}
							/>
						</>
					}

					{currentStep === 4 &&
						<>

							<NumberInput onUpdate={(value: any) => setRecovery(value)}
								title="Enter Sample Length (m)"
								defaultValue={sample_recovery}
								className="mb-2 text-2xl"
								presets={[
									{ value: '1.00', label: '1.00' },
									{ value: '0.80', label: '0.80' },
								]}
								next={next}
							/>
						</>
					}

					{currentStep === 5 &&

						<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2 w-full">
							<ButtonOptions onUpdate={(value: any) => setLiner(value)} defaultValue={liner} options={[
								{ value: '47', label: <><div className="text-2xl sm:text-3xl pb-2 pt-0 sm:pt-4">47mm</div></> },
								{ value: '55', label: <><div className="text-2xl sm:text-3xl pb-2 pt-0 sm:pt-4">55mm</div></> },
								{ value: '57', label: <><div className="text-2xl sm:text-3xl pb-2 pt-0 sm:pt-4">57mm</div></> },
								{ value: '65', label: <><div className="text-2xl sm:text-3xl pb-2 pt-0 sm:pt-4">65mm</div></> },
								{ value: '67', label: <><div className="text-2xl sm:text-3xl pb-2 pt-0 sm:pt-4">67mm</div></> },
								{ value: '75', label: <><div className="text-2xl sm:text-3xl pb-2 pt-0 sm:pt-4">75mm</div></> },
								{ value: '77', label: <><div className="text-2xl sm:text-3xl pb-2 pt-0 sm:pt-4">77mm</div></> },
								{ value: '87', label: <><div className="text-2xl sm:text-3xl pb-2 pt-0 sm:pt-4">87mm</div></> },
								{ value: '102', label: <><div className="text-2xl sm:text-3xl pb-2 pt-0 sm:pt-4">102mm</div></> },
								{ value: '117', label: <><div className="text-2xl sm:text-3xl pb-2 pt-0 sm:pt-4">117mm</div></> },
							]}
								columns={5}
								columnsMobile={2}
								className="w-20 text-base sm:text-lg"
								buttonHeight={20}
								buttonHeightMobile={12}
								title="Select Liner Diameter (mm)"
								next={next}
							/>
						</div>
					}

					{currentStep === 6 &&

						<PrintLabel
							location={selectedProject.location}
							project={selectedProject.number}
							borehole={borehole}
							sampledate={labelDate}
							// user={loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name)}
							sampled_by={sampler}
							sample={sampleID}
							depth={printDepth}
							size='printLabel'
						/>

					}

				</div>

				<MissingRequiredField />


				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">


							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?

								<div className="flex mt-4">
									<Button theme="secondary" 
										className="mr-5 text-sm sm:text-base px-3.5 md:px-7" 
										onClick={() => setDeleteConfirmOpen(false)}>
											Cancel
									</Button>

									<Button onClick={() => {
												onClose()
												onComplete({
													guid: guid,
													update: false,
													delete: true,
												})
												setShowDriveRecordsEdit(false)
											}}
										className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">
										Delete
									</Button>
								</div>
							</div>


						</div>
					</div>
				}

				<div className="flex">
					{/* <Button theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button> */}
					<DeleteButton />

					<div className="flex ml-auto">
						<Button theme="primary" size="lg" className="px-4 sm:px-10" onClick={() => previous()}>Back</Button>
						<Button theme={saveButtonColor}
							size="lg"
							className="ml-4 px-8 sm:px-10 w-32 sm:w-60"
							onClick={() => next()}>{saveButtonText}
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

DriveRecordsWindowSample.defaultProps = defaultProps

export default DriveRecordsWindowSample