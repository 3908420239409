import { get, set } from 'idb-keyval';

export const updateRig = (
    guid: any, 
    name: any,
    type: any,
    plant_id: any,
    make_model: any,
    test_date_expiry: any,
    spt_guid: any,
    company_guid: any,
    test_method: any,
    dprg_type: any,
    dprg_hammer_mass: any,
    dprg_standard_drop: any,
    dprg_cone_diameter: any,
    dprg_rod_diameter: any,
    dprg_rod_mass: any,
    dprg_anvil_type: any,
    dprg_anvil_dampner: any,
    torque_default: any,
    dp_test_ref: any,
    dp_test_type: any,
): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        const endpoint = `${process.env.REACT_APP_API_ADDR}rigs/update/${guid}`;

        // Construct the update data
        const updateData = {
            'guid': guid,
            'name': name,
            'type': type,
            'plant_id': plant_id,
            'make_model': make_model,
            'test_date_expiry': test_date_expiry,
            'spt_guid': spt_guid,
            'company_guid': company_guid,
            'test_method': test_method,
            'dprg_type': dprg_type,
            'dprg_hammer_mass': dprg_hammer_mass,
            'dprg_standard_drop': dprg_standard_drop,
            'dprg_cone_diameter': dprg_cone_diameter,
            'dprg_rod_diameter': dprg_rod_diameter,
            'dprg_rod_mass': dprg_rod_mass,
            'dprg_anvil_type': dprg_anvil_type,
            'dprg_anvil_dampner': dprg_anvil_dampner,
            'torque_default': torque_default,
            'dp_test_ref': dp_test_ref,
            'dp_test_type': dp_test_type,
        };

        console.log('updateData:', updateData);

        makeRequest(endpoint, JSON.stringify(updateData), 'PUT')
            .then(response => {
                if (response.ok) {
                    console.log('Rig information updated successfully.');
                    resolve(true);
                } else {
                    console.error('Failed to update Rig information.');
                    resolve(false);
                }
            })
            .catch(err => {
                console.error('Error updating Rig information:', err);
                resolve(false);
            });
    });
};

const makeRequest = (endpoint: string, data: string, postOrPut: string): Promise<Response> => {
    const token = localStorage.getItem('drillit-token');

    return fetch(endpoint, {
        method: postOrPut,
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: data
    });
};


const saveUpdateToIDB = (guid: string, updateData: any) => {
    const idbKey = `drillit-rig/${guid}-update`;

    get(idbKey)
        .then(existingRecord => {
            if (existingRecord) {
                // If a record already exists, merge the new data with the existing data
                const updatedRecord = { ...existingRecord, ...updateData };
                return set(idbKey, updatedRecord);
            } else {
                // If no existing record, create a new one
                return set(idbKey, updateData);
            }
        })
        .then(() => {
            console.log(`Saved or updated Rig information for ${guid} in IndexedDB.`);
        })
        .catch(err => {
            console.error(`Error saving Rig information to IndexedDB for ${guid}:`, err);
        });
};
