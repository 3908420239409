import { combineReducers } from 'redux'

import DateReducer from './Date'
import WeatherReducer from './Weather'
import SoilDescriptionLengthReducer from './SoilDescriptionLength'
import SelectedProjectReducer from './SelectedProject'

const rootReducer = combineReducers({
	date: DateReducer,
	weather: WeatherReducer,
	soilDescriptionLength: SoilDescriptionLengthReducer,
	selectedProject: SelectedProjectReducer,
})

export default rootReducer