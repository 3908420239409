import { get } from 'idb-keyval';
import { setIDB } from './setIDBKey';
import { usePostToApi } from "./postToAPI";

interface ImageData {
    guid: string;
    image: string; // Assuming image is a DataURL string
    photo_type?: string; // Optional property
}

// Define the type for submit comments data
interface SubmitCommentsData {
    task_type: string;
    hole_type_id: string;
    hole_location_guid: any;
    data: {
        guid: string; // Ensure these properties exist in data
        photo_type: string;
    };
}

export const useSetIndexedDBValues_Photos = (
    values: SubmitCommentsData, // Explicitly type the values parameter
    idbKey: string,
    create?: boolean,
    update?: string
): Promise<void> => {

    console.log('addToIDB - PROPS: values:', values, 'idbKey', idbKey, 'create:', create, 'update:', update);

    if (update) {
        console.log('addToIDB - UPDATE');
    }

    const existingKey = `drillit-${idbKey}`;
    const newKey = `${idbKey}-new`;
    const updateKey = `${idbKey}-update`;

    // Extract properties from values.data
    const guid = values.data.guid;
    
    const extractPhotoType = (photoType: string): string => {
        return photoType.replace('image/', ''); // Remove 'image/' prefix
    };
    
    const photoType = extractPhotoType(values.data.photo_type);

    return new Promise<void>((resolve, reject) => {
        getIDB(existingKey)
            .then(result => {
                if (!update) {
                    if (result) {
                        setIDB(values, existingKey, result as object[]);
                    } else {
                        setIDB(values, existingKey);
                    }
                }
                console.log('addToIDB - CREATE - setIDB(values, existingKey, result as object[]):', result);
                return result;
            })
            .then(result => {
                if (create && existingKey !== 'drillit-action_counts-new') {
                    const saveNewKey = useSetIndexedDBValues_Photos;
                    console.log('addToIDB - CREATE - we can add to another object of purely new entries by recursively calling this function');
                    return saveNewKey(values, newKey);
                }
                return result;
            })
            .then(result => {
                if (update) {
                    const newResult: any = result;
                    const newItem: any = values;
                    const updatedArray = newResult.map((obj: any) => obj.guid === newItem.guid ? newItem : obj);
                    console.log('addToIDB - UPDATE - setIDB ON UPDATE!!!');
                    console.log('addToIDB - updatedArray, existingKey: ', updatedArray, existingKey);
                    setIDB(updatedArray, existingKey);
                }
                return result;
            })
            .then(result => {
                if (update) {
                    const saveUpdateKey = useSetIndexedDBValues_Photos;
                    console.log('addToIDB - UPDATE - create a new object of updated values');
                    return saveUpdateKey(values, updateKey);
                }
                return result;
            })
            .then(result => {
                if (create) {
                    const postToApi = usePostToApi;
                    console.log('addToIDB - CREATE - Push to API if create');
                    console.log('addToIDB - idbKey:', idbKey);
                    return postToApi(idbKey, 'POST');
                }
                return result;
            })
            .then(result => {
                if (update) {
                    const postToApi = usePostToApi;
                    console.log('addToIDB - UPDATE - Push to API');
                    return postToApi(idbKey, update, true);
                }
                return result;
            })
            .then(result => {
                // Assuming guid and photoType are extracted earlier in the chain
                return getImageFromIDB(guid)
                    .then(imageDataURL => {
                        console.log('Image data:', guid, imageDataURL, photoType)
                        if (imageDataURL) {
                            return uploadImageToServer(guid, imageDataURL, photoType);
                        } else {
                            throw new Error('Image data not found in IndexedDB');
                        }
                    })
                    .then(() => result); // Return result after image upload
            })
            .then(resolve)
            .catch(reject);
    });
}

// Function to get image data from IndexedDB
const getImageFromIDB = async (guid: string): Promise<string | null> => {
    try {
        const db = await openIDB();
        console.log('******************* IDB db ***************', db)
        const tx = db.transaction('images', 'readonly');
        const store = tx.objectStore('images');
        const request = store.get(guid);

        return new Promise((resolve, reject) => {
            request.onsuccess = () => {
                const result = request.result;
                if (result) {
                    resolve(result.image);
                } else {
                    resolve(null); // Ensure resolve with null if not found
                }
            };
            request.onerror = () => {
                reject(request.error);
            };
        });
    } catch (error) {
        console.error('Error retrieving image from IndexedDB:', error);
        return null;
    }
};

// Function to upload image to server
const uploadImageToServer = async (guid: string, imageDataURL: string, photoType: string = 'default'): Promise<void> => {
    try {
        // Retrieve the company_id asynchronously
        const userData = await get('drillit-user');
        const company_id = userData?.user?.company?.id || 0;
        const token = localStorage.getItem('drillit-token')

        const endpoint = `${process.env.REACT_APP_API_ADDR}upload-image/company/${company_id}/photo_type/${photoType}`;
        const data = JSON.stringify({ guid, image: imageDataURL })
        const response = await fetch(endpoint, {
            method: 'POST',
            mode: 'cors', // Explicitly set the mode to CORS
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: data,
        });

        if (!response.ok) {
            throw new Error(`Failed to upload image: ${response.statusText}`);
        }

        console.log('Image uploaded successfully');
    } catch (error) {
        console.error('Error uploading image:', error);
    }
};

// Helper function to open IndexedDB
const openIDB = async (): Promise<IDBDatabase> => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('drillit', 2); // Ensure version is correct

        request.onupgradeneeded = (event) => {
            const db = (event.target as IDBOpenDBRequest).result;
            // Set up your object stores here
            if (!db.objectStoreNames.contains('images')) {
                db.createObjectStore('images', { keyPath: 'guid' });
            }
        };

        request.onsuccess = () => {
            resolve(request.result);
        };

        request.onerror = () => {
            reject(request.error);
        };
    });
};

const getIDB = (keyValue: string) => get(keyValue).then(data => data)
