import { get, set, del } from 'idb-keyval';
import { deleteActions } from './deleteActions';

export const useDeleteIndexedDBValue = (
  values: object,      // State containing the data to update in IndexedDB
  idbKey: string,      // IndexedDB key where data is stored
  update: string,      // Identifier for the type of update (e.g., 'SamplesData')
  actionGuid?: string, // GUID of the action to delete
  action?: string,     // Type of action (e.g., 'sample')
  actionData?: object  // Additional data related to the action
): Promise<any> => {
  const existingKey = `drillit-${idbKey}`;
  const updateKey = `${existingKey}-delete`;

  // Update the current IDB result shown in the browser
  set(existingKey, values);

  // Send the delete request to the API
  return deleteActions(actionGuid!)
    .then(response => {
      if (response.status === 200) {
        // If API delete is successful, delete from IDB
        return del(updateKey).then(() => {
          console.log(`Deleted ${action} ${actionGuid} from IDB`);
          return response;
        });
      } else {
        // If API delete fails, store the action data in the update array
        return get(updateKey).then(result => {
          const dataToUpdate = result ? [...(result as object[]), actionData] : [actionData];
          return set(updateKey, dataToUpdate).then(() => {
            console.log(`Stored DELETE ${action} ${actionGuid} in IDB for later sync`);
            return response;
          });
        });
      }
    })
    .catch(error => {
      console.error(`Error deleting ${action} ${actionGuid}:`, error);
      throw error; // Propagate error for handling in the calling function
    });
};

const updateIDB = (newData: object, idbKey: string, oldData?: object) => {
    let newArr = [] as any
    if (oldData) { newArr = oldData }
    newArr.push(newData)
    set(idbKey, newArr)
        .then(result => result)
        .catch(error => error)
}
