import React, { FunctionComponent, useState } from 'react'

import WorkflowSteps from '../../WorkflowSteps'
import Modal from '../../Modal'
import Button from '../../form/Button'
import NumberInput from '../../NumberInput'
import { v4 as uuidv4 } from 'uuid';
import stepStatus from '../../../helpers/stepStatus'
import warningIcon from '../../../assets/icons/warning.png'

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: string,
	defaultValues?: any,
	update?: any,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => { },
	onComplete: () => { },
	borehole: '',
	defaultValues: {
		guid: '',
		pitSetOutBy: '',
		catSweep: '',
		trialpit_type: '',
		pitDimensionsLength: '',
		pitDimensionsWidth: '',
		pitDimensionsDepth: '',
		pitContinued: '',
		pitSides: '',
		slightInstabilityFrom: '',
		slightInstabilityDepth: '',
		collapsedCompletelyFrom: '',
		collapsedCompletelyDepth: '',

		testDepth: '',
		discArea: '',
		massSurcharge: '',
		pressure: '',
		seatingLoad: '',
		moisture: '',
	},
}

const CBRConditions: FunctionComponent<Props> = ({ onClose, update, onComplete, borehole, defaultValues, ...rest }) => {
	const [currentStep, setCurrentStep] = useState(1)

	const [guid, setGuid] = useState(defaultValues.guid)

	const [testDepth, setTestDepth] = useState(defaultValues.testDepth)
	const [discArea, setDiscArea] = useState(defaultValues.discArea)
	const [massSurcharge, setMassSurcharge] = useState(defaultValues.massSurcharge)
	const [pressure, setPressure] = useState(defaultValues.pressure)
	const [seatingLoad, setSeatingLoad] = useState(defaultValues.seatingLoad)
	const [moisture, setMoisture] = useState(defaultValues.moisture)

	const [saveButtonText, setButtonText] = useState('Continue')
	const [saveButtonColor, setButtonColor] = useState('primary')
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	const DeleteButton = () => {
		if (!update) return null;

		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	const deleteRecordConfirm = () => {
		setDeleteConfirmOpen(false)
		// onComplete()
	}

	const previous = () => {
		if (currentStep > 1)
			setCurrentStep(currentStep - 1)
		setButtonText('Continue')
		setButtonColor('primary')
	}

	const next = () => {
		if (currentStep < 6) {
			setCurrentStep(currentStep + 1)
			if (currentStep === 5) {
				setButtonText('Save')
				setButtonColor('success')
			} else {
				setButtonText('Continue')
				setButtonColor('primary')
			}
			return
		}

		if (testDepth === '') {
			//do nothing
		} else {

			onComplete({
				guid: guid ? guid : uuidv4(),
				test_depth: testDepth,
				disc_area: discArea,
				mass_surcharge: massSurcharge,
				pressure: pressure,
				seating_load: seatingLoad,
				moisture: moisture,
				update: update ? true : false,
			})
		}
	}

	let requiredTestDepthText = ''

	if (!testDepth) {
		requiredTestDepthText = "'Test Depth'"
	}

	const MissingRequiredField = () => {
		if (currentStep === 6 && (!testDepth)) return (
			<div className="text-center bg-sptSB border border-red font-bold rounded mb-2 py-1 text-xs md:text-sm lg:text-base">
				<img src={warningIcon} alt="info" width="20" className="inline-block mr-1 pb-1" />
				Required field: {requiredTestDepthText}
			</div>
		)

		return null
	}

	return (
		<Modal title="CBR Conditions" subtitle={borehole} toggleModal={onClose}>
			<div className="flex flex-col flex-grow px-1 sm:px-2 md:px-10">
				<WorkflowSteps steps={[
					{
						label: 'Depth',
						status: stepStatus(1, currentStep),
						onClick: () => {
							setCurrentStep(1)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Area',
						status: stepStatus(2, currentStep),
						onClick: () => {
							setCurrentStep(2)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Mass',
						status: stepStatus(3, currentStep),
						onClick: () => {
							setCurrentStep(3)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Pressure',
						status: stepStatus(4, currentStep),
						onClick: () => {
							setCurrentStep(4)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Seating',
						status: stepStatus(5, currentStep),
						onClick: () => {
							setCurrentStep(5)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Moisture',
						status: stepStatus(6, currentStep),
						onClick: () => {
							setCurrentStep(6)
							setButtonText('Save')
							setButtonColor('success')
						}
					},
				]} className="mb-2" />

				<div className="flex-1 text-center">

					{currentStep === 1 &&
						<>

							<NumberInput onUpdate={(value: any) => setTestDepth(value)}
								title="Test Depth (m)"
								defaultValue={testDepth}
								className="mb-10"
							/>

						</>
					}

					{currentStep === 2 &&
						<>

							<NumberInput onUpdate={(value: any) => setDiscArea(value)}
								title="Area of annulus of disc used (mm&#178;)"
								defaultValue={discArea}
								className="mb-10"
							/>

						</>
					}

					{currentStep === 3 &&
						<>

							<NumberInput onUpdate={(value: any) => setMassSurcharge(value)}
								title="Mass of surcharge applied (kg)"
								defaultValue={massSurcharge}
								className="mb-10"
							/>

						</>
					}

					{currentStep === 4 &&
						<>

							<NumberInput onUpdate={(value: any) => setPressure(value)}
								title="Equivilent overburden pressure (kPa)"
								defaultValue={pressure}
								className="mb-10"
							/>


						</>
					}

					{currentStep === 5 &&
						<>

							<NumberInput onUpdate={(value: any) => setSeatingLoad(value)}
								title="Seating Load (Div)"
								defaultValue={seatingLoad}
								className="mb-10"
							/>

						</>
					}

					{currentStep === 6 &&
						<>

							<NumberInput onUpdate={(value: any) => setMoisture(value)}
								title="Moisture Content (%)"
								defaultValue={moisture}
								className="mb-10"
							/>

						</>
					}

				</div>

				<MissingRequiredField />


				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">


							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?

								<div className="flex mt-4">
									<Button theme="secondary" className="mr-5 text-sm sm:text-base px-3.5 md:px-7" onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>

									<Button onClick={deleteRecordConfirm} className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">Delete</Button>
								</div>
							</div>


						</div>
					</div>
				}

				<div className="flex">
					{/* <Button theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button> */}
					<DeleteButton />

					<div className="flex ml-auto">
						<Button theme="primary" size="lg" className="px-4 sm:px-10" onClick={() => previous()}>Back</Button>
						<Button theme={saveButtonColor}
							size="lg"
							className="ml-4 px-8 sm:px-10 w-32 sm:w-60"
							onClick={() => next()}>{saveButtonText}
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

CBRConditions.defaultProps = defaultProps

export default CBRConditions
