import React, { FunctionComponent, useState, Component, useRef, useEffect, } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateSelectedProject } from '../../../actions/SelectedProject'

import Panel from '../../../components/Panel'
import PanelSingleUse from '../../../components/PanelSingleUse'
import Button from '../../../components/form/Button'
import Modal from '../../../components/Modal'
import ModalSmall from '../../../components/ModalSmall'

import ProjectOptionsBorehole from '../../../components/project/ProjectOptionsBorehole'
import ProjectHeader from '../../../components/project/ProjectHeader'

import SyncStatus from '../../../helpers/syncStatus'

import Samples from '../../../components/project/workflow/Samples'
import InspectionPit from '../../../components/project/workflow/InspectionPit'
import Comments from '../../../components/project/workflow/Comments'
import DynamicProbe from '../../../components/project/workflow/DynamicProbe'
import CasingRecord from '../../../components/project/workflow/CasingRecordWindowSample'

import dp_chart_bg from '../../../assets/dp_chart_bg.jpg'

import Labels from '../../../components/project/workflow/Labels'

import Header from '../../../layout/Header'
import Container from '../../../layout/Container'

import cablePercussiveIcon from '../../../assets/icons/cablepercussive-white.svg'


import '../../../LogsheetCSS.css';
import Barcode from 'react-jsbarcode'
import closeIcon from '../../../assets/icons/close.svg'
import previewIcon from '../../../assets/icons/preview.svg'
import labelIcon from '../../../assets/icons/label.svg'
import { Link } from 'react-router-dom'

import { useSetIndexedDBValues } from '../../../helpers/addToIDB'
import { useUpdateIndexedDBValues } from "../../../helpers/updateIDB"
import { useDeleteIndexedDBValue } from "../../../helpers/deleteFromIDB"
import { useGetIDBValues } from "../../../helpers/getIDBKey";
import { usePostToApi } from "../../../helpers/postToAPI";
import { updateActions } from "../../../helpers/updateActions"
import CompactTableObject from "../../../components/CompactTableObject";
import getWeatherList from "../../../helpers/getWeatherList";
import { connect } from "react-redux";
import { get, set, update, del } from "idb-keyval";
import edit from "../../../assets/icons/edit.svg";
import { activateHole } from "../../../helpers/activateHole";
import { closeHole } from "../../../helpers/closeHole";
import { updateHoleInformation } from "../../../helpers/updateHoleInformation";
import { updateWeather } from "../../../helpers/updateWeather";
import { Redirect, useHistory } from "react-router-dom";
import datesAreOnSameDay from "../../../helpers/datesAreOnSameDay";

import menubars from '../../../assets/menubars.svg'

import icon_ags from '../../../assets/icons/icon_ags.png'
import icon_preview from '../../../assets/icons/icon_preview.png'
import icon_savelog from '../../../assets/icons/icon_savelog.png'
import icon_complete from '../../../assets/icons/icon_complete.png'
import icon_project from '../../../assets/icons/icon_project.png'
import icon_email from '../../../assets/icons/icon_email.png'
import icon_labels from '../../../assets/icons/icon_labels.png'
import icon_newborehole from '../../../assets/icons/icon_newborehole.png'
import icon_plantcheck from '../../../assets/icons/icon_plantcheck.png'
import icon_editrig from '../../../assets/icons/icon_editrig.png'
import warningIcon from '../../../assets/icons/warning.png'
import icon_reload from '../../../assets/icons/reload.png'
import icon_endshift from '../../../assets/icons/icon_endshift.png'

import NewHole from '../../NewHole'
import AGS from '../../AGS'
import Email from '../../Email'
import SaveLog from '../../SaveLog'

import ReactToPrint from 'react-to-print'
import Label from '../../../components/Label'
import PrintLabel from '../../PrintLabelModal'
import LabelBatchPrint from '../../../components/LabelBatchPrint'
import TopImage from '../../../assets/labelTop.jpg'
import { useGetLoggedInUser } from '../../../helpers/getLoggedInUser'

interface Props {
	// onClose: any,
	// router: any,
	toggleSidebar: () => void,
	company_guid: string,
	project_guid: string,
	project_id: string,
	project_name: any,
	starting_hole_type_id: string,
	borehole_guid: string,
	selectedWeather: string,
	selectedDate: string,
	lastActiveDate: string,
	borehole_id: number,
	borehole_name: string,
	rig_name: string,
	rig_id: string,

	// HOLE DATA
	AllHoleData: any,
	activeDates: any,

	reloadData: any,
}


interface State {
	// onClose: () => {},
	project_guid: string,
	borehole: any,
	completingBorehole: boolean,

	showD: boolean,
	showSamplesCreateD: boolean,
	showSamplesCreateB: boolean,
	showSamplesCreateWS: boolean,
	showDEdit: boolean,
	showB: boolean,
	showBEdit: boolean,
	showES: boolean,
	showESEdit: boolean,
	showWS: boolean,
	showWSEdit: boolean,
	showInspectionPit: boolean,
	showComments: boolean,
	showInspectionPitEdit: boolean,
	showCommentsEdit: boolean,
	showCasingRecord: boolean,

	showDynamicProbe: boolean,
	showDynamicProbeEdit: boolean,

	Samples: any,
	InspectionPit: any,
	Comments: any,
	Casing: any,

	DynamicProbe: any,
	DynamicProbeTorque: any,

	addSampleData: any,
	fromType: any,

	redirect: boolean,

	project: any,
	holeType: string,

	previewVisible: boolean,
	labelsVisible: boolean,
	bhMenuVisible: boolean,

	showHoleEdit: boolean,
	showNewHole: boolean,
	showEmail: boolean,
	showAGS: boolean,
	setShowNewHole: boolean,
	setShowEmail: boolean,
	setShowAGS: boolean,

	showSaveLog: boolean,
	setShowSaveLog: boolean,

	showPrintLabel: boolean,
	setShowPrintLabel: boolean,

	defaultValues: object,
	guid: string;

	todaySoilDescriptionsVisible: boolean,
	allSoilDescriptionsVisible: boolean,

	todayCasingVisible: boolean,
	allCasingVisible: boolean,

	// END SHIFT
	showEndShiftDailyWaterLevels: boolean,
	showEndShiftSaveLog: boolean,
	showEndShiftAGS: boolean,
	showEndShiftEmail: boolean,
	showEndShiftlogout: boolean,
}



const FunctionTemplate: FunctionComponent<Props> = ({
	company_guid, project_guid, project_id, starting_hole_type_id, borehole_guid, selectedWeather, selectedDate, lastActiveDate,
	borehole_id, borehole_name, project_name,
	rig_name, rig_id, reloadData,
	//HOLE DATA
	AllHoleData, activeDates,
	toggleSidebar, ...rest }) => {

	// console.log('AllHoleData:', AllHoleData)

	const [hole_data, set_hole_data] = useState(AllHoleData?.hole_data)
	const [hole_guid, set_hole_guid] = useState(AllHoleData?.hole_guid || '')

	const [borehole, setBorehole] = useState(null)
	const [project, setProject] = useState(null)

	const [state, setState] = useState({
		completingBorehole: false,

		SamplesData: [],
		InspectionPit: [],
		Comments: [],
		Casing: [],
		DynamicProbe: [],
		DynamicProbeTorque: [],

		addSampleData: [],
		fromType: '',

		// ************************************************
		// editingGuid: '',
		// editingIndex: 1, // there is something wrong here - editingIndex won't update null
		redirect: false,

		project: '',
		holeType: '',

		defaultValues: {},

		// currentRigValue: '',
		// currentRigLabel: '',
		rig: [],
		rigs: [],
		currentRig: [],
		spts: [],
		rigsSelect: [],
		currentRig_name: '', //same as Label
		currentRig_type: '',
		currentRig_plant_id: '',
		currentRig_test_date_expiry: '',
		hammers: [],
		currentRig_spt_guid: '',
		currentRig_spt: {
			guid: '',
		},
	})

	// const [editingGuid, setEditingGuid] = useState('')
	const [editingGuid, setEditingGuid] = useState([])
	const [editingIndex, setEditingIndex] = useState(0)
	const [defaultValues, setDefaultValues] = useState([])

	// console.log('editingGuid from root', editingGuid)
	// console.log('editingIndex from root', editingIndex)
	// console.log('defaultValues from root', defaultValues)

	const [addSampleData, setAddSampleData] = useState([])
	const [fromType, setFromType] = useState('')

	// console.log('addSampleData:', addSampleData)

	const [showD, setShowD] = useState(false)
	const [showSamplesCreateD, setShowSamplesCreateD] = useState(false)
	const [showSamplesCreateB, setShowSamplesCreateB] = useState(false)
	const [showSamplesCreateWS, setShowSamplesCreateWS] = useState(false)
	const [showB, setShowB] = useState(false)
	const [showES, setShowES] = useState(false)
	const [showWS, setShowWS] = useState(false)
	const [showDEdit, setShowDEdit] = useState(false)
	const [showBEdit, setShowBEdit] = useState(false)
	const [showESEdit, setShowESEdit] = useState(false)
	const [showWSEdit, setShowWSEdit] = useState(false)
	const [showInspectionPit, setShowInspectionPit] = useState(false)
	const [showInspectionPitEdit, setShowInspectionPitEdit] = useState(false)
	const [showCasingRecord, setShowCasingRecord] = useState(false)
	const [showCasingRecordEdit, setShowCasingRecordEdit] = useState(false)
	const [showComments, setShowComments] = useState(false)
	const [showCommentsEdit, setShowCommentsEdit] = useState(false)
	const [showDynamicProbe, setShowDynamicProbe] = useState(false)
	const [showDynamicProbeEdit, setShowDynamicProbeEdit] = useState(false)




















	//.......................................................
	//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNN...NNNN..UUUU...UUUU..
	//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNNN..NNNN..UUUU...UUUU..
	//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNNN..NNNN..UUUU...UUUU..
	//.MMMMMMM.MMMMMMM.EEEE........NNNNNN.NNNN..UUUU...UUUU..
	//.MMMMMMM.MMMMMMM.EEEE........NNNNNN.NNNN..UUUU...UUUU..
	//.MMMMMMM.MMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU..
	//.MMMMMMMMMMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU..
	//.MMMMMMMMMMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU..
	//.MMMMMMMMMMMMMMM.EEEE........NNNNNNNNNNN..UUUU...UUUU..
	//.MMMM.MMMMM.MMMM.EEEE........NNNN.NNNNNN..UUUU...UUUU..
	//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN..NNNNN..UUUUUUUUUUU..
	//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN..NNNNN...UUUUUUUUU...
	//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN...NNNN....UUUUUUU....
	//.......................................................

	const [bhMenuVisible, setBhMenuVisible] = useState(false)
	const popupRef = useRef<HTMLDivElement | null>(null); // Specify the type explicitly
	// Event listener to close the menu when clicked outside
	const toggleMenu = (event: MouseEvent) => {
		if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
			setBhMenuVisible(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', toggleMenu);
		return () => {
			document.removeEventListener('click', toggleMenu);
		};
	}, []);


	const handleReloadClick = () => {
		// Call the reload function passed as a prop
		reloadData();
	};




















	//.........................................................................
	//.PPPPPPPPP.....AAAA.....AAANN...NNNN.NNNEEEEEEEE.EEEL.........LSSSSSS....
	//.PPPPPPPPPP...AAAAAA....AAANN...NNNN.NNNEEEEEEEE.EEEL........LLSSSSSSS...
	//.PPPPPPPPPPP..AAAAAA....AAANNN..NNNN.NNNEEEEEEEE.EEEL.......LLLSSSSSSSS..
	//.PPPP...PPPP..AAAAAAA...AAANNNN.NNNN.NNNE........EEEL.......LLLS...SSSS..
	//.PPPP...PPPP.PAAAAAAA...AAANNNN.NNNN.NNNE........EEEL.......LLLSS........
	//.PPPPPPPPPPP.PAAAAAAA...AAANNNNNNNNN.NNNEEEEEEEE.EEEL........LLSSSSS.....
	//.PPPPPPPPPP..PAAA.AAAA..AAANNNNNNNNN.NNNEEEEEEEE.EEEL.........LSSSSSSS...
	//.PPPPPPPPP..PPAAAAAAAA..AAAN.NNNNNNN.NNNEEEEEEEE.EEEL...........SSSSSSS..
	//.PPPP.......PPAAAAAAAAA.AAAN.NNNNNNN.NNNE........EEEL..............SSSS..
	//.PPPP......PPPAAAAAAAAA.AAAN..NNNNNN.NNNE........EEEL.......LLLS...SSSS..
	//.PPPP......PPPA....AAAA.AAAN..NNNNNN.NNNEEEEEEEEEEEELLLLLLLLLLLSSSSSSSS..
	//.PPPP......PPPA....AAAAAAAAN...NNNNN.NNNEEEEEEEEEEEELLLLLLLL.LLSSSSSSSS..
	//.PPPP.....PPPPA.....AAAAAAAN....NNNN.NNNEEEEEEEEEEEELLLLLLLL..LSSSSSS....
	//.........................................................................

	const [todaySoilDescriptionsVisible, setTodaySoilDescriptionsVisible] = useState(true)
	const [allSoilDescriptionsVisible, setAllSoilDescriptionsVisible] = useState(false)

	const [todayCasingVisible, setTodayCasingVisible] = useState(true)
	const [allCasingVisible, setAllCasingVisible] = useState(false)

	const [completingBorehole, setCompletingBorehole] = useState(false)
	const menuAndMarkAsComplete = () => {
		setBhMenuVisible(value => !value)
		setCompletingBorehole(value => !value)
	}

	const [previewVisible, setPreviewVisible] = useState(false)
	const previewClick = () => setPreviewVisible(value => !value)
	const menuAndPreview = () => {
		setBhMenuVisible(value => !value)
		setPreviewVisible(value => !value)
	}
	const [labelsVisible, setLabelsVisible] = useState(false)
	const labelsClick = () => setLabelsVisible(value => !value)
	const menuAndLabels = () => {
		setBhMenuVisible(value => !value)
		setLabelsVisible(value => !value)
	}
	const [showHoleEdit, setShowHoleEdit] = useState(false)
	const [showNewHole, setShowNewHole] = useState(false)
	const menuAndNewHole = () => {
		setBhMenuVisible(value => !value)
		setShowNewHole(value => !value)
	}
	const [showEmail, setShowEmail] = useState(false)
	const menuAndEmail = () => {
		setBhMenuVisible(value => !value)
		setShowEmail(value => !value)
	}
	const [showAGS, setShowAGS] = useState(false)
	const menuAndAGS = () => {
		setBhMenuVisible(value => !value)
		setShowAGS(value => !value)
	}
	const [showSaveLog, setShowSaveLog] = useState(false)
	const menuAndSaveLog = () => {
		setBhMenuVisible(value => !value)
		setShowSaveLog(value => !value)
	}
	const [showPrintLabel, setShowPrintLabel] = useState(false)
	const printLabelClick = () => setShowPrintLabel(value => !value)
	const menuAndShowLabels = () => {
		setBhMenuVisible(value => !value)
		setShowPrintLabel(value => !value)
	}

	const [labelSample, setLabelSample] = useState('')
	const [labelDepth, setLabelDepth] = useState('')

	// const [redirect, setRedirect] = useState(false)
	// if (redirect) {
	// 	return <Redirect to={`/projects/dashboard/${project_guid}/dynamic-probe`} />
	// }
	
	const history = useHistory();
	const [redirect, setRedirect] = React.useState(false);

	// const handleConfirmClick = () => {
	// 	closeHole(borehole_guid);
	// 	setRedirect(true);
	// };

	// Redirect manually using history.push
	React.useEffect(() => {
		if (redirect) {
		history.push(`/projects/dashboard/${project_guid}/dynamic-probe`);
		}
	}, [redirect, history, project_guid]);

	const printAllSampleLabelsArea = useRef(null)
	const printAllTestLabelsArea = useRef(null)

	

	// END SHIFT
	const [showEndShiftDailyWaterLevels, setShowEndShiftDailyWaterLevels] = useState(false)
	const [showEndShiftSaveLog, setShowEndShiftSaveLog] = useState(false)
	const [showEndShiftAGS, setShowEndShiftAGS] = useState(false)
	const [showEndShiftEmail, setShowEndShiftEmail] = useState(false)
	const [showEndShiftLogout, setShowEndShiftLogout] = useState(false)
	
	const menuAndEndShift = () => {
		setBhMenuVisible(value => !value)
		setShowEndShiftSaveLog(value => !value)
	}



















	//.............................................................................................................................
	//.PPPPPPPPP.....AAAA.....AAANN...NNNN.NNNEEEEEEEE.EEEL............ TTTTTTTTTTTY....YYYYYYYPPPPPPP..PPPEEEEEEEE...ESSSSSS....
	//.PPPPPPPPPP...AAAAAA....AAANN...NNNN.NNNEEEEEEEE.EEEL............ TTTTTTTTTTTY...YYYYYYYYPPPPPPPP.PPPEEEEEEEE..EESSSSSSS...
	//.PPPPPPPPPPP..AAAAAA....AAANNN..NNNN.NNNEEEEEEEE.EEEL............ TTTTTTTTTTTYY..YYYY.YYYPPPPPPPPPPPPEEEEEEEE.EEESSSSSSSS..
	//.PPPP...PPPP..AAAAAAA...AAANNNN.NNNN.NNNE........EEEL................TTTT....TTYYYYYYY..YYYP....PPPPPPPE........EEES...SSSS..
	//.PPPP...PPPP.PAAAAAAA...AAANNNN.NNNN.NNNE........EEEL................TTTT.....TYYYYYYY..YYYP....PPPPPPPE........EEESS........
	//.PPPPPPPPPPP.PAAAAAAA...AAANNNNNNNNN.NNNEEEEEEEE.EEEL................TTTT.....TYYYYYY...YYYPPPPPPPPPPPPEEEEEEEE..EESSSSS.....
	//.PPPPPPPPPP..PAAA.AAAA..AAANNNNNNNNN.NNNEEEEEEEE.EEEL................TTTT......YYYYY....YYYPPPPPPPP.PPPEEEEEEEE...ESSSSSSS...
	//.PPPPPPPPP..PPAAAAAAAA..AAAN.NNNNNNN.NNNEEEEEEEE.EEEL................TTTT.......YYYY....YYYPPPPPPP..PPPEEEEEEEE.....SSSSSSS..
	//.PPPP.......PPAAAAAAAAA.AAAN.NNNNNNN.NNNE........EEEL................TTTT.......YYYY....YYYP........PPPE...............SSSS..
	//.PPPP......PPPAAAAAAAAA.AAAN..NNNNNN.NNNE........EEEL................TTTT.......YYYY....YYYP........PPPE........EEES...SSSS..
	//.PPPP......PPPA....AAAA.AAAN..NNNNNN.NNNEEEEEEEEEEEELLLLLLLL.........TTTT.......YYYY....YYYP........PPPEEEEEEEEEEEESSSSSSSS..
	//.PPPP......PPPA....AAAAAAAAN...NNNNN.NNNEEEEEEEEEEEELLLLLLLL.........TTTT.......YYYY....YYYP........PPPEEEEEEEEE.EESSSSSSSS..
	//.PPPP.....PPPPA.....AAAAAAAN....NNNN.NNNEEEEEEEEEEEELLLLLLLL.........TTTT.......YYYY....YYYP........PPPEEEEEEEEE..ESSSSSS....
	//.............................................................................................................................

	type SampleType = {
		depth_to_base: any
		depth_to_top: any
		description: any
		duration: any
		general_remarks: any
		guid: string
		id: number
		casing_depth: any
		prep_details: any
		sample_length: any
		time_of_action: any
		type: any
		water_depth: any
		weather_details: any
	}
	type CommentsDataType = {
		id: string
		guid: string
		comment_type: string
		start_time: any
		duration: string
		general_remarks: string
		time_of_action: string
	}
	type InspectionPitDataType = {
		id: number
		guid: string
		depth_to_top: any
		depth_to_base: any
		duration: any
		weather_details: string
		general_remarks: string
		time_of_action: string
	}
	type CasingDataType = {
		id: number
		guid: string
		casing_type: any
		depth_to_top: any
		casing_diameter: any
		depth_to_base: any
		depth_of_borehole: any
		general_remarks: string
		time_of_action: string
	}
	type DynamicProbeDataType = {
		dp_identifier: any
		hole_location_id: any
		dp_blow_results: any
		dp_torque_results: any
		blows: any
		torques: any
		dp_torque_unit: any
		// cumulative_blows: any
		// total_blows: any
		general_remarks: any
		final_depth: any
		weather_details: any
		guid: any
		id: any
		time_of_action: any
	}

	const [SamplesData, setSamplesData] = useState<SampleType[]>([]);
	const [CommentsData, setCommentsData] = useState<CommentsDataType[]>([]);
	const [InspectionPitData, setInspectionPitData] = useState<InspectionPitDataType[]>([]);
	const [CasingData, setCasingData] = useState<CasingDataType[]>([]);
	const [DynamicProbeData, setDynamicProbeData] = useState<DynamicProbeDataType[]>([]);
	// const [DynamicProbeTorqueData, setDynamicProbeTorqueData] = useState<DynamicProbeTorqueDataType[]>([]);




















	//.......................................................................................................
	//.PPPPPPPPP...PRRRRRRRRR.....OOOOOOO.........JJJJ....... DDDDDDDD.......AAAAA...AAATTTTTTTTT.TAAAA......
	//.PPPPPPPPPP..PRRRRRRRRRR...OOOOOOOOOO.......JJJJ....... DDDDDDDDD......AAAAA...AAATTTTTTTTT.TAAAA......
	//.PPPPPPPPPPP.PRRRRRRRRRR..ROOOOOOOOOOO......JJJJ....... DDDDDDDDDD....AAAAAA...AAATTTTTTTTT.TAAAAA.....
	//.PPPP...PPPP.PRRR...RRRRR.ROOOO..OOOOO......JJJJ....... DDD...DDDD....AAAAAAA......TTTT....TTAAAAA.....
	//.PPPP...PPPP.PRRR...RRRRRRROOO....OOOOO.....JJJJ....... DDD....DDDD..AAAAAAAA......TTTT....TTAAAAA.....
	//.PPPPPPPPPPP.PRRRRRRRRRR.RROO......OOOO.....JJJJ....... DDD....DDDD..AAAAAAAA......TTTT...TTTA.AAAA....
	//.PPPPPPPPPP..PRRRRRRRRRR.RROO......OOOO.....JJJJ....... DDD....DDDD..AAAA.AAAA.....TTTT...TTTA.AAAA....
	//.PPPPPPPPP...PRRRRRRR....RROO......OOOO.....JJJJ....... DDD....DDDD.DAAAAAAAAA.....TTTT...TTTAAAAAAA...
	//.PPPP........PRRR.RRRR...RROOO....OOOOOOOJJ.JJJJ....... DDD....DDDD.DAAAAAAAAAA....TTTT..TTTTAAAAAAA...
	//.PPPP........PRRR..RRRR...ROOOO..OOOOO.OOJJ.JJJJ....... DDD...DDDDD.DAAAAAAAAAA....TTTT..TTTTAAAAAAA...
	//.PPPP........PRRR..RRRRR..ROOOOOOOOOOO.OOJJJJJJJ....... DDDDDDDDDD.DDAA....AAAA....TTTT..TTTT....AAAA..
	//.PPPP........PRRR...RRRRR..OOOOOOOOOO..OOJJJJJJ........ DDDDDDDDD..DDAA.....AAAA...TTTT.TTTT.....AAAA..
	//.PPPP........PRRR....RRRR....OOOOOO.....OJJJJJ......... DDDDDDDD..DDDAA.....AAAA...TTTT.TTTT.....AAAA..
	//.......................................................................................................


	type ProjectData = {
		location?: string;
		project_identifier?: string;
		start_date?: string;
		site_description?: string;
		key_site_features?: string;
		site_history?: string;
		key_hands?: string;
		expected_strata?: string;
		comments?: string; // MISSING
		equipment_required?: string;
		client_name?: string;
		client_contact?: string;
		client_phone?: string;
		client_email?: string;
		engineer_name?: string;
		engineer_phone?: string;
		engineer_email?: string;
	}

	const cachedProjectValues = useGetIDBValues;

	const [projectData, setProjectData] = useState<ProjectData | null>(null);

	useEffect(() => {
		cachedProjectValues(`projects/${project_guid}`, (data: any) => {
			// console.log('Retrieved project data:', data); // Log the retrieved data
			setProjectData(data);
		}).then();
	}, [project_guid]);

	const project_location = projectData?.location ?? ''
	const projectID = projectData?.project_identifier ?? ''
	const project_start_date = projectData?.start_date ?? ''
	const site_description = projectData?.site_description ?? ''
	const key_site_features = projectData?.key_site_features ?? ''
	const site_history = projectData?.site_history ?? ''
	const key_hands = projectData?.key_hands ?? ''
	const expected_strata = projectData?.expected_strata ?? ''
	const notes = projectData?.comments ?? ''
	const equipment_required = projectData?.equipment_required ?? ''
	const client_name = projectData?.client_name ?? ''
	const client_contact = projectData?.client_contact ?? ''
	const client_phone = projectData?.client_phone ?? ''
	const client_email = projectData?.client_email ?? ''
	const engineer_name = projectData?.engineer_name ?? ''
	const engineer_phone = projectData?.engineer_phone ?? ''
	const engineer_email = projectData?.engineer_email ?? ''

	const [engineerName, setEngineerName] = useState(engineer_name)

	if (engineer_name !== engineerName) {
		setEngineerName(engineer_name)
	}




















	//.........................................................
	//....CCCCCCC....RRRRRRRRRR...EEEEEEEEEEEEWWW..WWWWW...WW..
	//...CCCCCCCCC...RRRRRRRRRRR..EEEEEEEEEEEEWWW..WWWWW..WWW..
	//..CCCCCCCCCCC..RRRRRRRRRRR..EEEEEEEEEEEEWWW..WWWWWW.WWW..
	//..CCCC...CCCCC.RRRR...RRRRR.EEEE.......EWWW.WWWWWWW.WWW..
	//.CCCC.....CCC..RRRR...RRRRR.EEEE.......EWWW.WWWWWWW.WWW..
	//.CCCC..........RRRRRRRRRRR..EEEEEEEEEE..WWWWWWWWWWW.WWW..
	//.CCCC..........RRRRRRRRRRR..EEEEEEEEEE..WWWWWWW.WWWWWWW..
	//.CCCC..........RRRRRRRR.....EEEEEEEEEE..WWWWWWW.WWWWWWW..
	//.CCCC.....CCC..RRRR.RRRR....EEEE........WWWWWWW.WWWWWWW..
	//..CCCC...CCCCC.RRRR..RRRR...EEEE........WWWWWWW.WWWWWWW..
	//..CCCCCCCCCCC..RRRR..RRRRR..EEEEEEEEEEE..WWWWW...WWWWW...
	//...CCCCCCCCCC..RRRR...RRRRR.EEEEEEEEEEE..WWWWW...WWWWW...
	//....CCCCCCC....RRRR....RRRR.EEEEEEEEEEE..WWWWW...WWWWW...
	//.........................................................

	const loggedInUser = useGetLoggedInUser()
	// const [User, setUser] = useState(loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name))
	const User = loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name)
	const [userWarning, setUserWarning] = useState(false)
	
	const negPhrases = [
		"You shall not pass!",
		"None shall pass!",
		"Access denied!",
		"No trespassing!",
		"Entry prohibited!",
		"Stay out!",
		"Get yer stinkin' mitts off!",
		"These aren't the droids you're looking for!"
	  ];
	
	const randomNegPhrases = negPhrases[Math.floor(Math.random() * negPhrases.length)];
	
	const [leadDriller, setLeadDriller] = useState('')
	const [leadDrillerID, setLeadDrillerID] = useState(0)
	const [leadDrillerCSCS, setLeadDrillerCSCS] = useState('')
	const [secondMan, setSecondMan] = useState('')
	const [secondManCSCS, setSecondManCSCS] = useState('')
	const [hole_barcode, setHole_barcode] = useState('')
	const [hole_status, setHole_status] = useState('')
	const [numberingType, setNumberingType] = useState('')

	const [savedRigGuid, setSavedRigValue] = useState('')
	const [plantName, setPlantName] = useState('')
	const [plantMakeModel, setPlantMakeModel] = useState('')
	const [sptGUID, setSptGUID] = useState('')
	const [activityTerminationReason, setActivityTerminationReason] = useState('')

	const [boreholeName, setBoreholeName] = useState(borehole_name)

	const cachedHoleInformation = useGetIDBValues

	useEffect(() => {
		if(borehole_guid){
			cachedHoleInformation(`hole-locations/${borehole_guid}`, (holeData: any) => {
				if (holeData) {
					console.log('holeData in PERSONNEL:', holeData)
					setLeadDriller(holeData.driller)
					setLeadDrillerID(holeData.driller_id)
					setLeadDrillerCSCS(holeData.driller_cscs);
					setSecondMan(holeData.second_man);
					setSecondManCSCS(holeData.second_man_cscs);
					setHole_barcode(holeData.hole_barcode);
					setSavedRigValue(holeData.rig_id); // RIG GUID
					setPlantName(holeData.rig_name); // RIG NAME
					setPlantMakeModel(holeData.make_model); // RIG MAKE/MODEL
					setSptGUID(holeData.spt_id); // SPT ID
					setActivityTerminationReason(holeData.activity_termination_reason); // TERMINATION REASON
					setBoreholeName(holeData.hole_name); // HOLE NAME
					setHole_status(holeData.status); // HOLE STATUS
					setNumberingType(holeData.numberingType); // NUMBERING TYPE
				
					console.log('PERSONNEL*********************************************')
					console.log('leadDriller:', leadDriller)
					console.log('leadDrillerID:', leadDrillerID)
					console.log('leadDrillerCSCS:', leadDrillerCSCS)
					console.log('secondMan:', secondMan)
					console.log('secondManCSCS:', secondManCSCS)
					console.log('hole_barcode:', hole_barcode)
					console.log('savedRigGuid:', savedRigGuid)
					console.log('plantName:', plantName)
					console.log('sptGUID:', sptGUID)
					console.log('boreholeName:', boreholeName)
					console.log('hole_status:', hole_status)
					console.log('selectedDate:', selectedDate)
					console.log('activityTerminationReason:', activityTerminationReason)
					console.log('******************************************************')
				} else {
					// // console.log(`**ERROR** HOLE DATA NOT LOADED. ID: ${borehole_guid}`);
				}
				// // console.log('holeData:', holeData);

			});
		}
	}, [borehole_guid]);




















	//.............................................................................................
	//....CCCCCCC......OOOOOOO....MMMMMM...MMMMMM.PPPPPPPPP.....AAAA.....ANNNN...NNNNNNYYY....YYY..
	//...CCCCCCCCC....OOOOOOOOOO..MMMMMM...MMMMMM.PPPPPPPPPP...AAAAAA....ANNNN...NNNN.NYYY...YYYY..
	//..CCCCCCCCCCC..OOOOOOOOOOOO.MMMMMM...MMMMMM.PPPPPPPPPPP..AAAAAA....ANNNNN..NNNN..YYYY..YYYY..
	//..CCCC...CCCCC.OOOOO..OOOOO.MMMMMMM.MMMMMMM.PPPP...PPPP..AAAAAAA...ANNNNNN.NNNN..YYYYYYYYY...
	//.CCCC.....CCC.OOOOO....OOOOOMMMMMMM.MMMMMMM.PPPP...PPPP.AAAAAAAA...ANNNNNN.NNNN...YYYYYYYY...
	//.CCCC.........OOOO......OOOOMMMMMMM.MMMMMMM.PPPPPPPPPPP.AAAAAAAA...ANNNNNNNNNNN...YYYYYYY....
	//.CCCC.........OOOO......OOOOMMMMMMMMMMMMMMM.PPPPPPPPPP..AAAA.AAAA..ANNNNNNNNNNN....YYYYY.....
	//.CCCC.........OOOO......OOOOMMMMMMMMMMMMMMM.PPPPPPPPP..AAAAAAAAAA..ANNN.NNNNNNN.....YYYY.....
	//.CCCC.....CCC.OOOOO....OOOOOMMMMMMMMMMMMMMM.PPPP.......AAAAAAAAAAA.ANNN.NNNNNNN.....YYYY.....
	//..CCCC...CCCCC.OOOOO..OOOOO.MMMM.MMMMM.MMMM.PPPP......PAAAAAAAAAAA.ANNN..NNNNNN.....YYYY.....
	//..CCCCCCCCCCC..OOOOOOOOOOOO.MMMM.MMMMM.MMMM.PPPP......PAAA....AAAA.ANNN..NNNNNN.....YYYY.....
	//...CCCCCCCCCC...OOOOOOOOOO..MMMM.MMMMM.MMMM.PPPP......PAAA....AAAAAANNN...NNNNN.....YYYY.....
	//....CCCCCCC.......OOOOOO....MMMM.MMMMM.MMMM.PPPP.....PPAAA.....AAAAANNN....NNNN.....YYYY.....
	//.............................................................................................

	const [companyInfo, setCompanyInfo] = useState({
		companyID: '',
		companyName: '',
		contactEmail: '',
		contactTelephone: '',
		companyAddress1: '',
		companyAddress2: '',
		companyAddress3: '',
		companyCounty: '',
		companyCountry: '',
		companyPostcode: '',
	});

	useEffect(() => {
		const getCompanyInfoFromCache = async () => {
			const response = await get('drillit-user');
			setCompanyInfo({
				companyID: response.user.company.id,
				companyName: response.user.company.name,
				contactEmail: response.user.company.email,
				contactTelephone: response.user.company.phone,
				companyAddress1: response.user.company.address1,
				companyAddress2: response.user.company.address2,
				companyAddress3: response.user.company.address3,
				companyCounty: response.user.company.county,
				companyCountry: response.user.company.country,
				companyPostcode: response.user.company.postcode,
			});
		};

		getCompanyInfoFromCache();
	}, []);

	const companyID = companyInfo.companyID
	const companyName = companyInfo.companyName
	const companyTelephone = companyInfo.contactTelephone
	const companyEmail = companyInfo.contactEmail

	let companyAddress = companyInfo.companyAddress1
	if (companyInfo.companyAddress2) { companyAddress = companyAddress + ', ' + companyInfo.companyAddress2 }
	if (companyInfo.companyAddress3) { companyAddress = companyAddress + ', ' + companyInfo.companyAddress3 }
	if (companyInfo.companyCounty) { companyAddress = companyAddress + ', ' + companyInfo.companyCounty }
	if (companyInfo.companyCountry) { companyAddress = companyAddress + ', ' + companyInfo.companyCountry }
	if (companyInfo.companyPostcode) { companyAddress = companyAddress + ', ' + companyInfo.companyPostcode }

	const companyLogo = "https://drillitlogs.com/images/company/" + companyID + ".png"
	const companyLogoWithCacheBusting = `${companyLogo}?${Date.now()}`;











	// Info to pass into 'ProjectHeader' & 'ProjectOptionsBorehole'
	// Hole Name
	// Hole Driller
	// 2nd man
	// Rig
	// Daily Plant Check




















	//..........................................................................
	//.LLLL..........AAAAA.....BBBBBBBBBB...EEEEEEEEEEE.LLLL........SSSSSSS.....
	//.LLLL..........AAAAA.....BBBBBBBBBBB..EEEEEEEEEEE.LLLL.......SSSSSSSSS....
	//.LLLL.........AAAAAA.....BBBBBBBBBBB..EEEEEEEEEEE.LLLL.......SSSSSSSSSS...
	//.LLLL.........AAAAAAA....BBBB...BBBB..EEEE........LLLL......LSSSS..SSSS...
	//.LLLL........AAAAAAAA....BBBB...BBBB..EEEE........LLLL......LSSSS.........
	//.LLLL........AAAAAAAA....BBBBBBBBBBB..EEEEEEEEEE..LLLL.......SSSSSSS......
	//.LLLL........AAAA.AAAA...BBBBBBBBBB...EEEEEEEEEE..LLLL........SSSSSSSSS...
	//.LLLL.......AAAAAAAAAA...BBBBBBBBBBB..EEEEEEEEEE..LLLL..........SSSSSSS...
	//.LLLL.......AAAAAAAAAAA..BBBB....BBBB.EEEE........LLLL.............SSSSS..
	//.LLLL.......AAAAAAAAAAA..BBBB....BBBB.EEEE........LLLL......LSSS....SSSS..
	//.LLLLLLLLLLLAAA....AAAA..BBBBBBBBBBBB.EEEEEEEEEEE.LLLLLLLLLLLSSSSSSSSSSS..
	//.LLLLLLLLLLLAAA.....AAAA.BBBBBBBBBBB..EEEEEEEEEEE.LLLLLLLLLL.SSSSSSSSSS...
	//.LLLLLLLLLLLAAA.....AAAA.BBBBBBBBBB...EEEEEEEEEEE.LLLLLLLLLL..SSSSSSSS....
	//..........................................................................

	const projectLocation = project_location
	let logsheetLocationStyle = "headerCellLocationInput"
	if (projectLocation) {
		if (projectLocation.length <= 25) { logsheetLocationStyle = "headerCellLocationInput" }
		if (projectLocation.length > 25 && projectLocation.length <= 60) { logsheetLocationStyle = "headerCellLocationInputSmall" }
		if (projectLocation.length > 60) { logsheetLocationStyle = "headerCellLocationInputXSmall" }
	}

	let sheetDateObject = new Date(selectedDate)
	const sheetDate = sheetDateObject.toLocaleDateString()

	const labelbarcodeVar = projectID + '-' + borehole_name // NEEDS TO BE ADDED TO hole_information table

	const barcodeWidths = [
		3.3, 3.3, 3.3, 3.3, 3.3, 3.3, 3.0, 2.7, 2.5, 2.3, // 1 to 10
		2.1, 2.0, 1.85, 1.75, 1.65, 1.55, 1.5, 1.42, 1.36, 1.3, // 11 to 20
		1.25, 1.2, 1.15, 1.1, 1.05, 1.0, 1.0, 0.95, 0.93, 0.90, // 21 to 30
		0.88, 0.85, 0.83, 0.80, 0.80, 0.76, 0.75, 0.73, 0.72, 0.71 // 31 to 40
	];

	let barcodeWidth = barcodeWidths[labelbarcodeVar.length - 1]





















	//.............................................................
	//.DDDDDDDDD.......AAAAA...AATTTTTTTTTTTEEEEEEEEE...SSSSSSS....
	//.DDDDDDDDDD......AAAAA...AATTTTTTTTTTTEEEEEEEEE..ESSSSSSSS...
	//.DDDDDDDDDDD....AAAAAA...AATTTTTTTTTTTEEEEEEEEE.EESSSSSSSSS..
	//.DDDD...DDDD....AAAAAAA......TTTT...TTEE........EESS...SSSS..
	//.DDDD....DDDD..AAAAAAAA......TTTT...TTEE........EESSS........
	//.DDDD....DDDD..AAAAAAAA......TTTT...TTEEEEEEEEE..ESSSSSS.....
	//.DDDD....DDDD..AAAA.AAAA.....TTTT...TTEEEEEEEEE...SSSSSSSS...
	//.DDDD....DDDD.AAAAAAAAAA.....TTTT...TTEEEEEEEEE.....SSSSSSS..
	//.DDDD....DDDD.AAAAAAAAAAA....TTTT...TTEE...............SSSS..
	//.DDDD...DDDDD.AAAAAAAAAAA....TTTT...TTEE........EESS...SSSS..
	//.DDDDDDDDDDD.DAAA....AAAA....TTTT...TTEEEEEEEEEEEESSSSSSSSS..
	//.DDDDDDDDDD..DAAA.....AAAA...TTTT...TTEEEEEEEEEE.ESSSSSSSSS..
	//.DDDDDDDDD..DDAAA.....AAAA...TTTT...TTEEEEEEEEEE..SSSSSSS....
	//.............................................................

	const [active_dates, setActive_dates] = useState(['06/04/2023', '05/04/2023'])

	function setAllActiveDates() {
		setActive_dates(activeDates)
	}

	useEffect(() => {
		setAllActiveDates()
	}, [activeDates])





	// Convert selectedDate in yyyy-mm-dd format
	// strip selectedDate
	const originalDate = new Date(selectedDate)
	const year = originalDate.getFullYear()
	const month = String(originalDate.getMonth() + 1).padStart(2, '0') // Months are 0-indexed
	const day = String(originalDate.getDate()).padStart(2, '0')

	const formattedDate = `${year}-${month}-${day}`

	// console.log('formattedDate:', formattedDate)




















	//........................................................................................................
	//.HHHH...HHHH....OOOOOOO.....LLLL.......EEEEEEEEEEE...... DDDDDDDD.......AAAAA...AATTTTTTTTTT.AAAAA......
	//.HHHH...HHHH...OOOOOOOOOO...LLLL.......EEEEEEEEEEE...... DDDDDDDDD......AAAAA...AATTTTTTTTTT.AAAAA......
	//.HHHH...HHHH..OOOOOOOOOOOO..LLLL.......EEEEEEEEEEE...... DDDDDDDDDD....AAAAAA...AATTTTTTTTTT.AAAAAA.....
	//.HHHH...HHHH..OOOOO..OOOOO..LLLL.......EEEE............. DDD...DDDD....AAAAAAA......TTTT....TAAAAAA.....
	//.HHHH...HHHH.HOOOO....OOOOO.LLLL.......EEEE............. DDD....DDDD..AAAAAAAA......TTTT....TAAAAAA.....
	//.HHHHHHHHHHH.HOOO......OOOO.LLLL.......EEEEEEEEEE....... DDD....DDDD..AAAAAAAA......TTTT...TTAA.AAAA....
	//.HHHHHHHHHHH.HOOO......OOOO.LLLL.......EEEEEEEEEE....... DDD....DDDD..AAAA.AAAA.....TTTT...TTAA.AAAA....
	//.HHHHHHHHHHH.HOOO......OOOO.LLLL.......EEEEEEEEEE....... DDD....DDDD.DAAAAAAAAA.....TTTT...TTAAAAAAAA...
	//.HHHH...HHHH.HOOOO....OOOOO.LLLL.......EEEE............. DDD....DDDD.DAAAAAAAAAA....TTTT..TTTAAAAAAAA...
	//.HHHH...HHHH..OOOOO..OOOOO..LLLL.......EEEE............. DDD...DDDDD.DAAAAAAAAAA....TTTT..TTTAAAAAAAA...
	//.HHHH...HHHH..OOOOOOOOOOOO..LLLLLLLLLL.EEEEEEEEEEE...... DDDDDDDDDD.DDAA....AAAA....TTTT..TTTA....AAAA..
	//.HHHH...HHHH...OOOOOOOOOO...LLLLLLLLLL.EEEEEEEEEEE...... DDDDDDDDD..DDAA.....AAAA...TTTT.TTTT.....AAAA..
	//.HHHH...HHHH.....OOOOOO.....LLLLLLLLLL.EEEEEEEEEEE...... DDDDDDDD..DDDAA.....AAAA...TTTT.TTTT.....AAAA..
	//........................................................................................................

	function getAllHoleData() { // split out the HOLE DATA and SET CONSTs

		const SampleArray = hole_data?.action_sample
		const unpackedSamples = SampleArray?.filter((sample: any) => sample) || [];
		setSamplesData(unpackedSamples)
		// console.log("Action Sample Array:", AllHoleData?.hole_data?.action_sample)
		// console.log("Unpacked Samples:", unpackedSamples)
		// console.log("samplesData:", SamplesData)
		// console.log("getAllHoleData function run! THIS ONLY RUNS ONCE!!!***************************************************************")

		const CommentsArray = hole_data?.action_comment;
		const unpackedComments = CommentsArray?.filter((sample: any) => sample) || [];
		setCommentsData(unpackedComments);

		const InspectionPitArray = hole_data?.action_inspection_pit;
		const unpackedInspectionPit = InspectionPitArray?.filter((sample: any) => sample) || [];
		setInspectionPitData(unpackedInspectionPit);

		const CasingArray = hole_data?.action_casing;
		const unpackedCasing = CasingArray?.filter((sample: any) => sample) || [];
		setCasingData(unpackedCasing);

		const DynamicProbeArray = hole_data?.action_dynamic_probe;
		const unpackedDynamicProbe = DynamicProbeArray?.filter((sample: any) => sample) || [];
		setDynamicProbeData(unpackedDynamicProbe);

		// const DynamicProbeTorqueArray = hole_data?.action_dynamic_probe_torque;
		// const unpackedDynamicProbeTorque = typeof DynamicProbeTorqueArray !== 'undefined' ? DynamicProbeTorqueArray : [];
		// setDynamicProbeTorqueData(unpackedDynamicProbeTorque);
	}

	// console.log('AllHoleData: ', AllHoleData) // use this to check hole data arrays

	useEffect(() => {
		getAllHoleData()
	}, []
	)


















	//.......................................................................................................
	//...SSSSSSS.......AAAAA..AAAVV....VVVVVVEEEEEEEEE...... DDDDDDDD.......AAAA....AAATTTTTTTTT.TAAAA......
	//..SSSSSSSSS......AAAAA...AAVV....VVVVVVEEEEEEEEE...... DDDDDDDDD.....AAAAAA...AAATTTTTTTTT.TAAAA......
	//..SSSSSSSSSS....AAAAAA...AAVV...VVVVVVVEEEEEEEEE...... DDDDDDDDDD....AAAAAA...AAATTTTTTTTTTTAAAA......
	//.SSSSS..SSSS....AAAAAAA..AAVVV..VVVV.VVEE............. DD....DDDD....AAAAAAA......TTTT....TTAAAAA.....
	//.SSSSS.........AAAAAAAA...AVVV..VVVV.VVEE............. DD....DDDDD..AAAAAAAA......TTTT...TTTAAAAA.....
	//..SSSSSSS......AAAAAAAA...AVVV..VVVV.VVEEEEEEEEE...... DD.....DDDD..AAAAAAAA......TTTT...TTTAAAAA.....
	//...SSSSSSSSS...AAAA.AAAA..AVVV.VVVV..VVEEEEEEEEE...... DD.....DDDD..AAAA.AAAA.....TTTT...TTTA.AAAA....
	//.....SSSSSSS..AAAAAAAAAA...VVVVVVVV..VVEEEEEEEEE...... DD.....DDDD.DAAAAAAAAA.....TTTT..TTTTAAAAAA....
	//........SSSSS.AAAAAAAAAAA..VVVVVVVV..VVEE............. DD....DDDDD.DAAAAAAAAAA....TTTT..TTTTAAAAAAA...
	//.SSSS....SSSS.AAAAAAAAAAA..VVVVVVV...VVEE............. DD....DDDD.DDAAAAAAAAAA....TTTT..TTTTAAAAAAA...
	//.SSSSSSSSSSSSSAAA....AAAA...VVVVVV...VVEEEEEEEEEE..... DDDDDDDDDD.DDAA....AAAA....TTTT.TTTT....AAAA...
	//..SSSSSSSSSS.SAAA.....AAAA..VVVVVV...VVEEEEEEEEEE..... DDDDDDDDD..DDAA....AAAAA...TTTT.TTTT.....AAAA..
	//...SSSSSSSS.SSAAA.....AAAA..VVVVV....VVEEEEEEEEEE..... DDDDDDDD..DDDAA.....AAAA...TTTTTTTTT.....AAAA..
	//.......................................................................................................

	function saveAction(value: any, type: string, hide: any) {

		const saveToIDB = useSetIndexedDBValues
		const updateIDB = useUpdateIndexedDBValues
		const deleteFromIDB = useDeleteIndexedDBValue

		value['borehole_guid'] = borehole_guid;
		value['type'] = type;

		switch (type) {

			case 'D':
			case 'B':
			case 'ES':
			case 'WS':
				const submitSamplesData = {
					"task_type": "action_sample",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value,
						"weather_details": selectedWeather,
						"sampler": leadDriller,
					}
				}

				if (value['update']) {
					const newState = SamplesData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setSamplesData(newState)
					del(`drillit-actions/${value['guid']}-update`)
					updateIDB(state, `actions/${value['guid']}`, 'SamplesData', value['guid'], 'sample', submitSamplesData)
				} else if (value['delete']) {
					const newState = SamplesData.filter((obj) => obj.guid !== value['guid']);
					setSamplesData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'SamplesData', value['guid'], 'sample', submitSamplesData)
				} else {
					setSamplesData(prevState => [...prevState, value])
					saveToIDB(submitSamplesData, 'action', true)
				}

				break;

			case 'Comments':
				const submitCommentsData = {
					"task_type": "action_comment",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value
					}
				}

				if (value['update']) {
					const newState = CommentsData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setCommentsData(newState)
					del(`drillit-actions/${value['guid']}-update`)
					updateIDB(state, `actions/${value['guid']}`, 'CommentsData', value['guid'], 'comment', submitCommentsData)
				} else if (value['delete']) {
					const newState = CommentsData.filter((obj) => obj.guid !== value['guid']);
					setCommentsData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'CommentsData', value['guid'], 'comment', submitCommentsData)
				} else {
					setCommentsData(prevState => [...prevState, value])
					saveToIDB(submitCommentsData, 'action', true)
				}

				break;

			case 'InspectionPit':
				const submitInspectionPitData = {
					"task_type": "action_inspection_pit",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						"weather_details": selectedWeather,
						...value
					}
				}

				if (value['update']) {
					const newState = InspectionPitData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setInspectionPitData(newState)
					del(`drillit-actions/${value['guid']}-update`)
					updateIDB(state, `actions/${value['guid']}`, 'InspectionPitData', value['guid'], 'inspection_pit', submitInspectionPitData)
				} else if (value['delete']) {
					const newState = InspectionPitData.filter((obj) => obj.guid !== value['guid']);
					setInspectionPitData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'InspectionPitData', value['guid'], 'inspection_pit', submitInspectionPitData)
				} else {
					setInspectionPitData(prevState => [...prevState, value])
					saveToIDB(submitInspectionPitData, 'action', true)
				}

				break;

			case 'Casing':
				const submitCasingData = {
					"task_type": "action_casing",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value
					}
				}

				if (value['update']) {
					const newState = CasingData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setCasingData(newState)
					del(`drillit-actions/${value['guid']}-update`)
					updateIDB(state, `actions/${value['guid']}`, 'CasingData', value['guid'], 'casing', submitCasingData)
				} else if (value['delete']) {
					const newState = CasingData.filter((obj) => obj.guid !== value['guid']);
					setCasingData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'CasingData', value['guid'], 'casing', submitCasingData)
				} else {
					setCasingData(prevState => [...prevState, value])
					saveToIDB(submitCasingData, 'action', true)
				}

				break;

			case 'DynamicProbe':
				const submitDynamicProbeData = {
					"task_type": "action_dynamic_probe",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						"weather_details": selectedWeather,
						"hole_location_id": borehole_id,
						"testType": testType,
						"testMethod": testMethod,
						"hammerMass": hammerMass,
						"standardDrop": standardDrop,
						"coneDiameter": coneDiameter,
						"rodDiameter": rodDiameter,
						"anvilType": anvilType,
						"anvilDampner": anvilDampner,
						"rodMass": rodMass,
						// "final_depth": borehole_depth,
						...value
					}
				}

				console.log('submitDynamicProbeData:', submitDynamicProbeData)

				if (value['update']) {
					const newState = DynamicProbeData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setDynamicProbeData(newState)
					// delete any existing IDB -update record
					del(`drillit-actions/${value['guid']}-update`)
					updateIDB(state, `actions/${value['guid']}`, 'DynamicProbeData', value['guid'], 'dynamic_probe', submitDynamicProbeData)
				} else if (value['delete']) {
					const newState = DynamicProbeData.filter((obj) => obj.guid !== value['guid']);
					setDynamicProbeData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'DynamicProbeData', value['guid'], 'dynamic_probe', submitDynamicProbeData)
				} else {
					setDynamicProbeData(prevState => [...prevState, value])
					saveToIDB(submitDynamicProbeData, 'action', true)
				}

				break;
		}

		saveHoleData()

		// // // Set hole to active if not already
		// if (hole_status === '1') {
		// 	// activateHole(borehole_guid, project_guid)
		// 	// activateHole(borehole_guid, project_guid)

		// 	updateHoleInformation(
		// 		borehole_guid,
		// 		boreholeName,
		// 		leadDriller,
		// 		leadDrillerID,
		// 		leadDrillerCSCS,
		// 		secondMan,
		// 		secondManCSCS,
		// 		plantGuid || '',
		// 		plantName,
		// 		sptGUID || '',
		// 		hole_barcode,
		// 		borehole_depthOverall,
		// 		activityTerminationReason,
		// 		project_guid,
		// 		'2' // hole_status
		// 	)
		// 	getHoleData()
		// }
	}

	//...............................................................................................................
	//.IIIII.DDDDDDDDD....BBBBBBBBBB........UUUU...UUUU..PPPPPPPPP...DDDDDDDDD.......AAAAA...AATTTTTTTTTTEEEEEEEEEE..
	//.IIIII.DDDDDDDDDD...BBBBBBBBBBB.......UUUU...UUUU..PPPPPPPPPP..DDDDDDDDDD......AAAAA...AATTTTTTTTTTEEEEEEEEEE..
	//.IIIII.DDDDDDDDDDD..BBBBBBBBBBB.......UUUU...UUUU..PPPPPPPPPPP.DDDDDDDDDDD....AAAAAA...AATTTTTTTTTTEEEEEEEEEE..
	//.IIIII.DDDD...DDDD..BBBB...BBBB.......UUUU...UUUU..PPPP...PPPP.DDDD...DDDD....AAAAAAA......TTTT...TEEE.........
	//.IIIII.DDDD....DDDD.BBBB...BBBB.......UUUU...UUUU..PPPP...PPPP.DDDD....DDDD..AAAAAAAA......TTTT...TEEE.........
	//.IIIII.DDDD....DDDD.BBBBBBBBBBB.......UUUU...UUUU..PPPPPPPPPPP.DDDD....DDDD..AAAAAAAA......TTTT...TEEEEEEEEEE..
	//.IIIII.DDDD....DDDD.BBBBBBBBBB........UUUU...UUUU..PPPPPPPPPP..DDDD....DDDD..AAAA.AAAA.....TTTT...TEEEEEEEEEE..
	//.IIIII.DDDD....DDDD.BBBBBBBBBBB.......UUUU...UUUU..PPPPPPPPP...DDDD....DDDD.AAAAAAAAAA.....TTTT...TEEEEEEEEEE..
	//.IIIII.DDDD....DDDD.BBBB....BBBB......UUUU...UUUU..PPPP........DDDD....DDDD.AAAAAAAAAAA....TTTT...TEEE.........
	//.IIIII.DDDD...DDDDD.BBBB....BBBB......UUUU...UUUU..PPPP........DDDD...DDDDD.AAAAAAAAAAA....TTTT...TEEE.........
	//.IIIII.DDDDDDDDDDD..BBBBBBBBBBBB......UUUUUUUUUUU..PPPP........DDDDDDDDDDD.DAAA....AAAA....TTTT...TEEEEEEEEEE..
	//.IIIII.DDDDDDDDDD...BBBBBBBBBBB........UUUUUUUUU...PPPP........DDDDDDDDDD..DAAA.....AAAA...TTTT...TEEEEEEEEEE..
	//.IIIII.DDDDDDDDD....BBBBBBBBBB..........UUUUUUU....PPPP........DDDDDDDDD..DDAAA.....AAAA...TTTT...TEEEEEEEEEE..
	//...............................................................................................................

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_sample = SamplesData;
				return val
			})
		}
	}, [SamplesData])

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_comment = CommentsData;
				return val
			})
		}
	}, [CommentsData])

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_inspection_pit = InspectionPitData;
				return val
			})
		}
	}, [InspectionPitData])

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_casing = CasingData;
				return val
			})
		}
	}, [CasingData])

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_dynamic_probe = DynamicProbeData;
				return val
			})
		}
	}, [DynamicProbeData])
























	//...................................................................................................
	//.HHHH...HHHH....OOOOOOO.....LLLL.......EEEEEEEEEEE..... III.INNN...NNNN..NFFFFFFFFF...OOOOOOO.....
	//.HHHH...HHHH...OOOOOOOOOO...LLLL.......EEEEEEEEEEE..... III.INNNN..NNNN..NFFFFFFFFF..OOOOOOOOOO...
	//.HHHH...HHHH..OOOOOOOOOOOO..LLLL.......EEEEEEEEEEE..... III.INNNN..NNNN..NFFFFFFFFF.OOOOOOOOOOOO..
	//.HHHH...HHHH..OOOOO..OOOOO..LLLL.......EEEE............ III.INNNNN.NNNN..NFFF.......OOOOO..OOOOO..
	//.HHHH...HHHH.HOOOO....OOOOO.LLLL.......EEEE............ III.INNNNN.NNNN..NFFF......FOOOO....OOOO..
	//.HHHHHHHHHHH.HOOO......OOOO.LLLL.......EEEEEEEEEE...... III.INNNNNNNNNN..NFFFFFFFF.FOOO......OOO..
	//.HHHHHHHHHHH.HOOO......OOOO.LLLL.......EEEEEEEEEE...... III.INNNNNNNNNN..NFFFFFFFF.FOOO......OOO..
	//.HHHHHHHHHHH.HOOO......OOOO.LLLL.......EEEEEEEEEE...... III.INNNNNNNNNN..NFFFFFFFF.FOOO......OOO..
	//.HHHH...HHHH.HOOOO....OOOOO.LLLL.......EEEE............ III.INNNNNNNNNN..NFFF......FOOOO....OOOO..
	//.HHHH...HHHH..OOOOO..OOOOO..LLLL.......EEEE............ III.INNN.NNNNNN..NFFF.......OOOOO..OOOOO..
	//.HHHH...HHHH..OOOOOOOOOOOO..LLLLLLLLLL.EEEEEEEEEEE..... III.INNN..NNNNN..NFFF.......OOOOOOOOOOOO..
	//.HHHH...HHHH...OOOOOOOOOO...LLLLLLLLLL.EEEEEEEEEEE..... III.INNN..NNNNN..NFFF........OOOOOOOOOO...
	//.HHHH...HHHH.....OOOOOO.....LLLLLLLLLL.EEEEEEEEEEE..... III.INNN...NNNN..NFFF..........OOOOOO.....
	//...................................................................................................

	//number of elements
	const [numberOfSamplesD, setNumberOfSamplesD] = useState(0)
	const [numberOfSamplesB, setNumberOfSamplesB] = useState(0)
	const [numberOfSamplesES, setNumberOfSamplesES] = useState(0)
	const [numberOfSamplesWS, setNumberOfSamplesWS] = useState(0)
	const [numberOfSamples, setNumberOfSamples] = useState(numberOfSamplesD + numberOfSamplesB + numberOfSamplesES + numberOfSamplesWS)

	const [numberOfSamplesD_TOTAL, setNumberOfSamplesD_TOTAL] = useState(0)
	const [numberOfSamplesB_TOTAL, setNumberOfSamplesB_TOTAL] = useState(0)
	const [numberOfSamplesES_TOTAL, setNumberOfSamplesES_TOTAL] = useState(0)
	const [numberOfSamplesWS_TOTAL, setNumberOfSamplesWS_TOTAL] = useState(0)
	const [numberOfSamples_TOTAL, setNumberOfSamples_TOTAL] = useState(numberOfSamplesD_TOTAL + numberOfSamplesB_TOTAL + numberOfSamplesES_TOTAL + numberOfSamplesWS_TOTAL)
	console.log('numberOfSamples_TOTAL:', numberOfSamples_TOTAL)

	const [numberOfComments, setNumberOfComments] = useState(0)
	const [numberOfInspectionPits, setNumberOfInspectionPits] = useState(0)
	const [numberOfCasingRecords, setNumberOfCasingRecords] = useState(0)
	const [numberOfDynamicProbes, setNumberOfDynamicProbes] = useState(0)

	//depths of elements
	const [depthSamples, setDepthSamples] = useState(0)
	const [depthOverallSamples, setDepthOverallSamples] = useState(0)
	const [depthInspectionPit, setDepthInspectionPit] = useState(0)
	const [depthOverallInspectionPit, setDepthOverallInspectionPit] = useState(0)
	const [depthCasing, setDepthCasing] = useState(0)
	const [depthOverallCasing, setDepthOverallCasing] = useState(0)
	const [depthDynamicProbe, setDepthDynamicProbe] = useState(0)
	const [depthOverallDynamicProbe, setDepthOverallDynamicProbe] = useState(0)
	const [allDepths, setAllDepths] = useState([depthDynamicProbe, depthSamples, depthInspectionPit, depthCasing])
	const [allDepthsOverall, setAllDepthsOverall] = useState([depthOverallDynamicProbe, depthOverallSamples, depthOverallInspectionPit, depthOverallCasing])
	const [borehole_depth, setBorehole_depth] = useState('' + (Math.max(...allDepths)).toFixed(2))
	const [borehole_depthOverall, setBorehole_depthOverall] = useState('' + (Math.max(...allDepthsOverall)).toFixed(2))

	const [current_casing_depth, setCurrent_casing_depth] = useState(depthCasing)

	//..............................................................................
	//....CCCCCCC......OOOOOOO.....UUUU...UUUU..NNNN...NNNN..TTTTTTTTTTS.SSSSSSS....
	//...CCCCCCCCC....OOOOOOOOOO...UUUU...UUUU..NNNNN..NNNN..TTTTTTTTTTSSSSSSSSSS...
	//..CCCCCCCCCCC..OOOOOOOOOOOO..UUUU...UUUU..NNNNN..NNNN..TTTTTTTTTTSSSSSSSSSSS..
	//..CCCC...CCCCC.OOOOO..OOOOO..UUUU...UUUU..NNNNNN.NNNN.....TTTT...SSSSS..SSSS..
	//.CCCC.....CCC.OOOOO....OOOOO.UUUU...UUUU..NNNNNN.NNNN.....TTTT...SSSSS........
	//.CCCC.........OOOO......OOOO.UUUU...UUUU..NNNNNNNNNNN.....TTTT....SSSSSSS.....
	//.CCCC.........OOOO......OOOO.UUUU...UUUU..NNNNNNNNNNN.....TTTT.....SSSSSSSSS..
	//.CCCC.........OOOO......OOOO.UUUU...UUUU..NNNNNNNNNNN.....TTTT.......SSSSSSS..
	//.CCCC.....CCC.OOOOO....OOOOO.UUUU...UUUU..NNNNNNNNNNN.....TTTT..........SSSS..
	//..CCCC...CCCCC.OOOOO..OOOOO..UUUU...UUUU..NNNN.NNNNNN.....TTTT...SSSS....SSS..
	//..CCCCCCCCCCC..OOOOOOOOOOOO..UUUUUUUUUUU..NNNN..NNNNN.....TTTT...SSSSSSSSSSS..
	//...CCCCCCCCCC...OOOOOOOOOO....UUUUUUUUU...NNNN..NNNNN.....TTTT....SSSSSSSSSS..
	//....CCCCCCC.......OOOOOO.......UUUUUUU....NNNN...NNNN.....TTTT.....SSSSSSSS...
	//..............................................................................

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the type counts for the filtered data - Samples
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateTypeCounts_Samples = (filteredData: any[]) => {

		let totalD = 0; let totalB = 0; let totalES = 0; let totalWS = 0

		filteredDataSamples.forEach((valueSamples: any) => {
			const type = valueSamples.type
			if (type) {
				if (type.includes('D')) { totalD++ }
				if (type.includes('B')) { totalB++ }
				if (type.includes('ES')) { totalES++ }
				if (type.includes('WS')) { totalWS++ }
			}
		});

		return { totalD, totalB, totalES, totalWS }
	}

	// Filter the data
	const filteredDataSamples = SamplesData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	// Calculate type counts for the filtered data
	const typeCountsSamples = calculateTypeCounts_Samples(filteredDataSamples);
	const { totalD, totalB, totalES, totalWS } = typeCountsSamples;

	if (totalD !== numberOfSamplesD) { setNumberOfSamplesD(totalD) }
	if (totalB !== numberOfSamplesB) { setNumberOfSamplesB(totalB) }
	if (totalWS !== numberOfSamplesWS) { setNumberOfSamplesWS(totalWS) }
	if (totalES !== numberOfSamplesES) { setNumberOfSamplesES(totalES) }

	let totalSamplesCount = numberOfSamplesD + numberOfSamplesB + numberOfSamplesES + numberOfSamplesWS
	if (totalSamplesCount !== numberOfSamples) { setNumberOfSamples(totalSamplesCount) }

	// // console.log('numberOfSamplesD:', numberOfSamplesD)
	// // console.log('numberOfSamplesB:', numberOfSamplesB)

	const calculateTypeCounts_Samples_TOTAL = (filteredData: any[]) => {

		let totalD_TOTAL = 0; let totalB_TOTAL = 0; let totalES_TOTAL = 0; let totalWS_TOTAL = 0

		filteredDataSamples_TOTAL.forEach((valueSamples: any) => {
			const type = valueSamples.type
			if (type) {
				if (type.includes('D')) { totalD_TOTAL++ }
				if (type.includes('B')) { totalB_TOTAL++ }
				if (type.includes('ES')) { totalES_TOTAL++ }
				if (type.includes('WS')) { totalWS_TOTAL++ }
			}
		});

		return { totalD_TOTAL, totalB_TOTAL, totalES_TOTAL, totalWS_TOTAL }
	}

	// TOTAL SAMPLES ////////////////////////////////////////////////////////////////////////////////

	// Filter the data
	const filteredDataSamples_TOTAL = SamplesData || []

	// Calculate type counts for the filtered data
	const typeCountsSamples_TOTAL = calculateTypeCounts_Samples_TOTAL(filteredDataSamples_TOTAL);
	const { totalD_TOTAL, totalB_TOTAL, totalES_TOTAL, totalWS_TOTAL } = typeCountsSamples_TOTAL;

	console.log('typeCountsSamples_TOTAL:', typeCountsSamples_TOTAL)

	if (totalD_TOTAL !== numberOfSamplesD_TOTAL) { setNumberOfSamplesD_TOTAL(totalD_TOTAL) }
	if (totalB_TOTAL !== numberOfSamplesB_TOTAL) { setNumberOfSamplesB_TOTAL(totalB_TOTAL) }
	if (totalWS_TOTAL !== numberOfSamplesWS_TOTAL) { setNumberOfSamplesWS_TOTAL(totalWS_TOTAL) }
	if (totalES_TOTAL !== numberOfSamplesES_TOTAL) { setNumberOfSamplesES_TOTAL(totalES_TOTAL) }
	let totalSamplesALL = totalD_TOTAL + totalB_TOTAL + totalWS_TOTAL + totalES_TOTAL
	if (totalSamplesALL !== numberOfSamples_TOTAL) { setNumberOfSamples_TOTAL(totalD_TOTAL + totalB_TOTAL + totalWS_TOTAL + totalES_TOTAL) }

	// // console.log('totalWS_TOTAL:', totalWS_TOTAL)

	const [showPrintAllSampleLabelsButton, setShowPrintAllSampleLabelsButton] = useState(true)

	// useEffect(() => {
	// 	if (numberOfSamples > 1) {
	// 	setShowPrintAllSampleLabelsButton(true)
	// } else {
	// 	setShowPrintAllSampleLabelsButton(false)
	// }
	// 	}, []
	// )

	// if (numberOfSamples === 1 || numberOfSamples === 0) {
	// 	setShowPrintAllSampleLabelsButton(false)
	// } 	
	// if (numberOfSamples > 1) {
	// 	setShowPrintAllSampleLabelsButton(true)
	// } 


	// if (numberOfSamples > 1) {
	// 	setShowPrintAllSampleLabelsButton(true)
	// } else {
	// 	setShowPrintAllSampleLabelsButton(false)
	// }
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - Comments
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateCounts_Comments = (filteredData: any[]) => {

		let totalComments = 0

		filteredDataComments.forEach((valueComments: any) => {
			const guid = valueComments.guid
			if (guid) { totalComments++ }
		});

		return { totalComments }
	}

	// Filter the data
	const filteredDataComments = CommentsData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	// Calculate type counts for the filtered data
	const countComments = calculateCounts_Comments(filteredDataComments);
	const { totalComments } = countComments

	if (totalComments !== numberOfComments) { setNumberOfComments(totalComments) }

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - InspectionPit
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateCounts_InspectionPit = (filteredData: any[]) => {

		let totalInspectionPit = 0

		filteredDataInspectionPit.forEach((valueInspectionPit: any) => {
			const guid = valueInspectionPit.guid
			if (guid) { totalInspectionPit++ }
		});

		return { totalInspectionPit }
	}

	// Filter the data
	const filteredDataInspectionPit = InspectionPitData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	// Calculate type counts for the filtered data
	const countInspectionPit = calculateCounts_InspectionPit(filteredDataInspectionPit);
	const { totalInspectionPit } = countInspectionPit

	if (totalInspectionPit !== numberOfInspectionPits) { setNumberOfInspectionPits(totalInspectionPit) }

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - CasingRecord
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateCounts_CasingRecord = (filteredData: any[]) => {

		let totalCasingRecord = 0

		filteredDataCasingRecord.forEach((valueCasingRecord: any) => {
			const guid = valueCasingRecord.guid
			if (guid) { totalCasingRecord++ }
		});

		return { totalCasingRecord }
	}

	// Filter the data
	const filteredDataCasingRecord = CasingData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []
	// const filteredDataCasingRecord = CasingData // All casing records

	// Calculate type counts for the filtered data
	const countCasingRecord = calculateCounts_CasingRecord(filteredDataCasingRecord);
	const { totalCasingRecord } = countCasingRecord

	if (totalCasingRecord !== numberOfCasingRecords) { setNumberOfCasingRecords(totalCasingRecord) }

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - DynamicProbe
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateCounts_DynamicProbe = (filteredData: any[]) => {

		let totalDynamicProbe = 0

		filteredDataDynamicProbe.forEach((valueDynamicProbe: any) => {
			const guid = valueDynamicProbe.guid
			if (guid) { 
			totalDynamicProbe++ 
			// setGuidOfDynamicProbe(guid)
			}
			// console.log('DP guid:', guid)
		});

		return { totalDynamicProbe }
	}

	// Filter the data
	const filteredDataDynamicProbe = DynamicProbeData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	// Calculate type counts for the filtered data
	const countDynamicProbe = calculateCounts_DynamicProbe(filteredDataDynamicProbe);
	const { totalDynamicProbe } = countDynamicProbe

	if (totalDynamicProbe!== numberOfDynamicProbes) { setNumberOfDynamicProbes(totalDynamicProbe) }

	// console.log('totalDynamicProbe / numberOfDynamicProbes:', totalDynamicProbe, numberOfDynamicProbes)


	// // if(DynamicProbeData && DynamicProbeData.length > 0){
	// // 	setNumberOfDynamicProbes(1)
	// // }











	//...........................................................................
	//.DDDDDDDDD....EEEEEEEEEEE.EPPPPPPPP...PTTTTTTTTTTTHHH...HHHH...SSSSSSS.....
	//.DDDDDDDDDD...EEEEEEEEEEE.EPPPPPPPPP..PTTTTTTTTTTTHHH...HHHH..HSSSSSSSS....
	//.DDDDDDDDDDD..EEEEEEEEEEE.EPPPPPPPPPP.PTTTTTTTTTTTHHH...HHHH..HSSSSSSSSS...
	//.DDDD...DDDD..EEEE........EPPP...PPPP....TTTT....THHH...HHHH.HHSSS..SSSS...
	//.DDDD....DDDD.EEEE........EPPP...PPPP....TTTT....THHH...HHHH.HHSSS.........
	//.DDDD....DDDD.EEEEEEEEEE..EPPPPPPPPPP....TTTT....THHHHHHHHHH..HSSSSSS......
	//.DDDD....DDDD.EEEEEEEEEE..EPPPPPPPPP.....TTTT....THHHHHHHHHH...SSSSSSSSS...
	//.DDDD....DDDD.EEEEEEEEEE..EPPPPPPPP......TTTT....THHHHHHHHHH.....SSSSSSS...
	//.DDDD....DDDD.EEEE........EPPP...........TTTT....THHH...HHHH........SSSSS..
	//.DDDD...DDDDD.EEEE........EPPP...........TTTT....THHH...HHHH.HHSS....SSSS..
	//.DDDDDDDDDDD..EEEEEEEEEEE.EPPP...........TTTT....THHH...HHHH.HHSSSSSSSSSS..
	//.DDDDDDDDDD...EEEEEEEEEEE.EPPP...........TTTT....THHH...HHHH..HSSSSSSSSS...
	//.DDDDDDDDD....EEEEEEEEEEE.EPPP...........TTTT....THHH...HHHH...SSSSSSSS....
	//...........................................................................


	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// CALCULATE MAX DEPTHS
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	/////////////////////////////////////////////////////////
	// SamplesData
	const filteredSamplesData = SamplesData?.filter((action: any) => {
		const actionDate = new Date(action.time_of_action);
		const endOfDaySelectedDate = new Date(selectedDate);
		endOfDaySelectedDate.setHours(23, 59, 59, 999); // Set to end of the day
		return actionDate <= endOfDaySelectedDate;
	}) || []

	let maxSamplesDepth;

	if (filteredSamplesData.length > 0) {
		const maxDepthFiltered = filteredSamplesData.reduce((maxDepth, action) => {
			const depthToBase = action.depth_to_base || 0; // Handle cases where depth_to_base is undefined or null
			const depthToTop = action.depth_to_top || 0; // Handle cases where depth_to_top is undefined or null

			// Find the maximum of depth_to_base and depth_to_top
			const maxDepthForAction = Math.max(depthToBase, depthToTop);

			// Find the maximum of the current maxDepth and the maxDepth for this action
			return Math.max(maxDepth, maxDepthForAction);
		}, -Infinity);

		maxSamplesDepth = maxDepthFiltered;
	} else {
		maxSamplesDepth = 0; // Set to 0 when there's no data
	}

	if (maxSamplesDepth !== depthSamples) {
		setDepthSamples(maxSamplesDepth)
	}

	/////////////////////////////////////////////////////////
	// MAX OVERALL SAMPLE DEPTH
	let OverallSamplesDepth
	if (SamplesData.length > 0) {
		const maxDepthBase = SamplesData.reduce((maxDepth, action) => {
			return Math.max(maxDepth, action.depth_to_base)
		}, -Infinity);
		const maxDepthTop = SamplesData.reduce((maxDepth, action) => {
			return Math.max(maxDepth, action.depth_to_top)
		}, -Infinity)
		OverallSamplesDepth = Math.max(maxDepthBase, maxDepthTop)
	} else {
		OverallSamplesDepth = 0 // Set to 0 when there's no data
	}
	if (OverallSamplesDepth !== depthOverallSamples) {
		setDepthOverallSamples(OverallSamplesDepth)
	}

	/////////////////////////////////////////////////////////
	// InspectionPitData
	const filteredInspectionPitData = InspectionPitData?.filter((action: any) => {
		const actionDate = new Date(action.time_of_action);
		const endOfDaySelectedDate = new Date(selectedDate);
		endOfDaySelectedDate.setHours(23, 59, 59, 999); // Set to end of the day
		return actionDate <= endOfDaySelectedDate;
	}) || []

	let maxInspectionPitDepth
	if (filteredInspectionPitData.length > 0) {
		const maxInspectionPitDepthFiltered = filteredInspectionPitData.reduce((maxDepth, action) => {
			return Math.max(maxDepth, action.depth_to_base)
		}, -Infinity)
		maxInspectionPitDepth = maxInspectionPitDepthFiltered
	} else {
		maxInspectionPitDepth = 0 // Set to 0 when there's no data
	}

	if (maxInspectionPitDepth !== depthInspectionPit) {
		setDepthInspectionPit(maxInspectionPitDepth)
	}


	/////////////////////////////////////////////////////////
	// MAX OVERALL INSPECTION PIT DEPTH
	let OverallInspectionPitDepth
	if (InspectionPitData.length > 0) {
		const maxInspectionPitDepthFiltered = InspectionPitData.reduce((maxDepth, action) => {
			return Math.max(maxDepth, action.depth_to_base)
		}, -Infinity)
		OverallInspectionPitDepth = maxInspectionPitDepthFiltered
	} else {
		OverallInspectionPitDepth = 0 // Set to 0 when there's no data
	}

	if (OverallInspectionPitDepth !== depthOverallInspectionPit) {
		setDepthOverallInspectionPit(OverallInspectionPitDepth)
	}

	/////////////////////////////////////////////////////////
	// CasingData
	const filteredCasingData = CasingData?.filter((action: any) => {
		const actionDate = new Date(action.time_of_action);
		const endOfDaySelectedDate = new Date(selectedDate);
		endOfDaySelectedDate.setHours(23, 59, 59, 999); // Set to end of the day
		return actionDate <= endOfDaySelectedDate;
	}) || []

	let maxCasingDepth
	if (filteredCasingData.length > 0) {
		const maxCasingDepthFiltered = filteredCasingData.reduce((maxDepth, action) => {
			return Math.max(maxDepth, action.depth_to_base)
		}, -Infinity)
		maxCasingDepth = maxCasingDepthFiltered
	} else {
		maxCasingDepth = 0 // Set to 0 when there's no data
	}

	if (maxCasingDepth !== depthCasing) {
		setDepthCasing(maxCasingDepth)
	}
	if (maxCasingDepth !== current_casing_depth) { /// SET CURRENT CASING DEPTH FOR PANELS
		setCurrent_casing_depth(maxCasingDepth)
	}


	/////////////////////////////////////////////////////////
	// MAX OVERALL CASING DEPTH
	// const filteredCasingDataOverall = CasingData || [];
	let OverallCasingDepth
	if (CasingData.length > 0) {
		const maxCasingDepthFiltered = CasingData.reduce((maxDepth, action) => {
			return Math.max(maxDepth, action.depth_to_base)
		}, -Infinity)
		OverallCasingDepth = maxCasingDepthFiltered
	} else {
		OverallCasingDepth = 0 // Set to 0 when there's no data
	}
	if (OverallCasingDepth !== depthOverallCasing) {
		setDepthOverallCasing(OverallCasingDepth)
	}

	/////////////////////////////////////////////////////////
	// Dynamic Probe

	let maxDynamicProbeDepth
	if (DynamicProbeData.length > 0) {
		maxDynamicProbeDepth = DynamicProbeData[0].final_depth
	} else {
		maxDynamicProbeDepth = 0 // Set to 0 when there's no data
	}

	if (maxDynamicProbeDepth !== depthOverallDynamicProbe) {
		setDepthDynamicProbe(maxDynamicProbeDepth)
		setDepthOverallDynamicProbe(maxDynamicProbeDepth)
	}


	// console.log('DynamicProbeData:', DynamicProbeData)
	// console.log('DynamicProbeData:', DynamicProbeData[0].final_depth)



	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// SET ALL DEPTHS
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const allCalculatedDepths = [depthSamples, depthInspectionPit, depthCasing, depthDynamicProbe]  
	const calculatedBoreholeDepth = ('' + (Math.max(...allCalculatedDepths)).toFixed(2))
	if (calculatedBoreholeDepth !== borehole_depth) { setBorehole_depth(calculatedBoreholeDepth) }

	const allCalculatedDepthsOverall = [depthOverallSamples, depthOverallInspectionPit, depthOverallCasing, depthOverallDynamicProbe]  
	const calculatedBoreholeDepthOverall = ('' + (Math.max(...allCalculatedDepthsOverall)).toFixed(2))
	if (calculatedBoreholeDepthOverall !== borehole_depthOverall) { setBorehole_depthOverall(calculatedBoreholeDepthOverall) }






	//...........................................................................................................
	//....CCCCCCC......OOOOOOO....MMMMMM...MMMMMMMMMMMM...MMMMMM.EEEEEEEEEEE.NNNN...NNNN..TTTTTTTTTTS.SSSSSSS....
	//...CCCCCCCCC....OOOOOOOOOO..MMMMMM...MMMMMMMMMMMM...MMMMMM.EEEEEEEEEEE.NNNNN..NNNN..TTTTTTTTTTSSSSSSSSSS...
	//..CCCCCCCCCCC..OOOOOOOOOOOO.MMMMMM...MMMMMMMMMMMM...MMMMMM.EEEEEEEEEEE.NNNNN..NNNN..TTTTTTTTTTSSSSSSSSSSS..
	//..CCCC...CCCCC.OOOOO..OOOOO.MMMMMMM.MMMMMMMMMMMMMM.MMMMMMM.EEEE........NNNNNN.NNNN.....TTTT...SSSSS..SSSS..
	//.CCCC.....CCC.OOOOO....OOOOOMMMMMMM.MMMMMMMMMMMMMM.MMMMMMM.EEEE........NNNNNN.NNNN.....TTTT...SSSSS........
	//.CCCC.........OOOO......OOOOMMMMMMM.MMMMMMMMMMMMMM.MMMMMMM.EEEEEEEEEE..NNNNNNNNNNN.....TTTT....SSSSSSS.....
	//.CCCC.........OOOO......OOOOMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM.EEEEEEEEEE..NNNNNNNNNNN.....TTTT.....SSSSSSSSS..
	//.CCCC.........OOOO......OOOOMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM.EEEEEEEEEE..NNNNNNNNNNN.....TTTT.......SSSSSSS..
	//.CCCC.....CCC.OOOOO....OOOOOMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM.EEEE........NNNNNNNNNNN.....TTTT..........SSSS..
	//..CCCC...CCCCC.OOOOO..OOOOO.MMMM.MMMMM.MMMMMMMM.MMMMM.MMMM.EEEE........NNNN.NNNNNN.....TTTT...SSSS....SSS..
	//..CCCCCCCCCCC..OOOOOOOOOOOO.MMMM.MMMMM.MMMMMMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN..NNNNN.....TTTT...SSSSSSSSSSS..
	//...CCCCCCCCCC...OOOOOOOOOO..MMMM.MMMMM.MMMMMMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN..NNNNN.....TTTT....SSSSSSSSSS..
	//....CCCCCCC.......OOOOOO....MMMM.MMMMM.MMMMMMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN...NNNN.....TTTT.....SSSSSSSS...
	//...........................................................................................................


	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Standing Time & Dayworks TOTAL TIMES
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



	// Filter the data
	const filteredComments = CommentsData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// STANDING TIME

	const standingTimeComments = filteredComments.filter(comment => comment.comment_type === 'Standing Time');

	// Calculate total duration
	const totalDuration_ST = standingTimeComments.reduce((total, comment) => {
		const [hours_ST, mins_ST] = comment.duration.split(':').map(Number);
		return {
			hours_ST: total.hours_ST + hours_ST,
			mins_ST: total.mins_ST + mins_ST,
		};
	}, { hours_ST: 0, mins_ST: 0 });

	// Format the total duration
	// just numbers
	// const totalStandingTime = `${String(totalDuration_ST.hours_ST).padStart(2, '0')}:${String(totalDuration_ST.mins_ST).padStart(2, '0')}`;
	// hrs & mins
	const totalStandingTime = `${String(totalDuration_ST.hours_ST).padStart(2, '0')} hrs ${String(totalDuration_ST.mins_ST).padStart(2, '0')} mins`;
	// console.log('Total Standing Time Duration:', totalStandingTime);

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// DAYWORKS

	const dayworksComments = filteredComments.filter(comment => comment.comment_type === 'Dayworks');

	// Calculate total duration
	const totalDuration_DW = dayworksComments.reduce((total, comment) => {
		const [hours_DW, mins_DW] = comment.duration.split(':').map(Number);
		return {
			hours_DW: total.hours_DW + hours_DW,
			mins_DW: total.mins_DW + mins_DW,
		};
	}, { hours_DW: 0, mins_DW: 0 });

	// Format the total duration
	// just numbers
	// const totalDayworks = `${String(totalDuration_DW.hours_DW).padStart(2, '0')}:${String(totalDuration_DW.mins_DW).padStart(2, '0')}`;
	// hrs & mins
	const totalDayworks = `${String(totalDuration_DW.hours_DW).padStart(2, '0')} hrs ${String(totalDuration_DW.mins_DW).padStart(2, '0')} mins`;

	// console.log('Total Dayworks Duration:', totalDayworks);


































	//............................................................................................
	//.WWWW..WWWWW...WWWEEEEEEEEEEEE....AAAAA...ATTTTTTTTTTTHHH....HHHH.HEEEEEEEEEE.ERRRRRRRRRR...
	//.WWWW..WWWWW..WWWW.EEEEEEEEEEE....AAAAA...ATTTTTTTTTTTHHH....HHHH.HEEEEEEEEEE.ERRRRRRRRRRR..
	//.WWWW..WWWWWW.WWWW.EEEEEEEEEEE...AAAAAA...ATTTTTTTTTTTHHH....HHHH.HEEEEEEEEEE.ERRRRRRRRRRR..
	//.WWWW.WWWWWWW.WWWW.EEEE..........AAAAAAA......TTTT...THHH....HHHH.HEEE........ERRR....RRRR..
	//.WWWW.WWWWWWW.WWWW.EEEE.........AAAAAAAA......TTTT...THHH....HHHH.HEEE........ERRR....RRRR..
	//..WWWWWWWWWWW.WWW..EEEEEEEEEE...AAAAAAAA......TTTT...THHHHHHHHHHH.HEEEEEEEEEE.ERRRRRRRRRRR..
	//..WWWWWWW.WWWWWWW..EEEEEEEEEE...AAAA.AAAA.....TTTT...THHHHHHHHHHH.HEEEEEEEEEE.ERRRRRRRRRR...
	//..WWWWWWW.WWWWWWW..EEEEEEEEEE..AAAAAAAAAA.....TTTT...THHHHHHHHHHH.HEEEEEEEEEE.ERRRRRRRR.....
	//..WWWWWWW.WWWWWWW..EEEE........AAAAAAAAAAA....TTTT...THHH....HHHH.HEEE........ERRR.RRRRR....
	//..WWWWWWW.WWWWWWW..EEEE........AAAAAAAAAAA....TTTT...THHH....HHHH.HEEE........ERRR..RRRRR...
	//...WWWWW...WWWWW...EEEEEEEEEEEAAAA....AAAA....TTTT...THHH....HHHH.HEEEEEEEEEEEERRR...RRRRR..
	//...WWWWW...WWWWW...EEEEEEEEEEEAAAA.....AAAA...TTTT...THHH....HHHH.HEEEEEEEEEEEERRR....RRRR..
	//...WWWWW...WWWWW...EEEEEEEEEEEAAAA.....AAAA...TTTT...THHH....HHHH.HEEEEEEEEEEEERRR.....RRR..
	//............................................................................................

	let weatherIcon = ''
	getWeatherList().map((weather) => {
		if (weather.label === selectedWeather)
			weatherIcon = weather.icon
	})
	

	const [boreholeWeather, setBoreholeWeather] = useState(selectedWeather)

	useEffect(() => {
		if(borehole_guid){
			cachedHoleInformation(`hole-locations/${borehole_guid}`, (holeData: any) => {
				if (holeData) {
					setBoreholeWeather(holeData.weather); // HOLE WEATHER
				}
			});
		}
	}, [borehole_guid]);

	useEffect(() => {
		if(borehole_guid){
			updateWeather(
				borehole_guid,
				selectedWeather
			)
		}
	}, [selectedWeather, borehole_guid]);




















	//....................................
	//.RRRRRRRRRR...RIIII.....GGGGGGG.....
	//.RRRRRRRRRRR..RIIII...GGGGGGGGGG....
	//.RRRRRRRRRRR..RIIII..GGGGGGGGGGGG...
	//.RRRR...RRRRR.RIIII..GGGGG..GGGGG...
	//.RRRR...RRRRR.RIIII.IGGGG....GGG....
	//.RRRRRRRRRRR..RIIII.IGGG............
	//.RRRRRRRRRRR..RIIII.IGGG..GGGGGGGG..
	//.RRRRRRRR.....RIIII.IGGG..GGGGGGGG..
	//.RRRR.RRRR....RIIII.IGGGG.GGGGGGGG..
	//.RRRR..RRRR...RIIII..GGGGG....GGGG..
	//.RRRR..RRRRR..RIIII..GGGGGGGGGGGG...
	//.RRRR...RRRRR.RIIII...GGGGGGGGGG....
	//.RRRR....RRRRR.IIII.....GGGGGGG.....
	//....................................

	type RigData = {
		currentRig_guid?: string;
		currentRig_type?: string;
		currentRig_plant_id?: string;
		currentRig_make_model?: string;
		currentRig_test_date_expiry?: string;
		currentRig_spt_guid?: string;
		currentRig_spt?: {
			id_ref: string;
			calibration_date: string;
			energy_ratio: string;
			rod_type: string;
		};
		currentRig_dprg_type?: string;
		currentRig_dprg_hammer_mass?: string;
		currentRig_dprg_standard_drop?: string;
		currentRig_dprg_cone_diameter?: string;
		currentRig_dprg_rod_diameter?: string;
		currentRig_dprg_rod_mass?: string;
		currentRig_dprg_anvil_type?: string;
		currentRig_dprg_anvil_dampner?: string;
		currentRig_torque_default?: string;
		currentRig_test_method?: string;
	};



	const [currentRigData, setCurrentRigData] = useState<RigData>({
		currentRig_guid: undefined,
		currentRig_type: undefined,
		currentRig_plant_id: undefined,
		currentRig_test_date_expiry: undefined,
		currentRig_spt_guid: undefined,
		currentRig_spt: undefined,
		currentRig_dprg_type: undefined,
		currentRig_dprg_hammer_mass: undefined,
		currentRig_dprg_standard_drop: undefined,
		currentRig_dprg_cone_diameter: undefined,
		currentRig_dprg_rod_diameter: undefined,
		currentRig_dprg_rod_mass: undefined,
		currentRig_dprg_anvil_type: undefined,
		currentRig_dprg_anvil_dampner: undefined,
		currentRig_torque_default: undefined,
		currentRig_test_method: undefined,
	});

	const cachedRigValues = useGetIDBValues


	const [plantGuid, setPlantGuid] = useState(currentRigData?.currentRig_guid)
	const [plantType, setPlantType] = useState(currentRigData?.currentRig_type)
	const [plantID, setPlantID] = useState(currentRigData?.currentRig_plant_id)
	const [plantTestDate, setPlantTestDate] = useState(currentRigData?.currentRig_test_date_expiry)

	// useEffect(() => {
	// 	if (savedRigGuid) {
	// 		cachedRigValues(`rigs/${savedRigGuid}`, (rigData: any) => {
	// 			if (rigData) {
	// 				console.log('rigData from HOLE INFORMATION:', rigData)
	// 				setCurrentRigData({
	// 					currentRig_guid: rigData?.guid,
	// 					currentRig_type: rigData?.type,
	// 					currentRig_plant_id: rigData?.plant_id,
	// 					currentRig_test_date_expiry: rigData?.test_date_expiry,
	// 					currentRig_spt_guid: rigData?.spt_guid,
	// 					currentRig_spt: rigData?.spt,
	// 					currentRig_dprg_type: rigData?.dprg_type,
	// 					currentRig_dprg_hammer_mass: rigData?.dprg_hammer_mass + 'kg',
	// 					currentRig_dprg_standard_drop: rigData?.dprg_standard_drop + 'mm',
	// 					currentRig_dprg_cone_diameter: rigData?.dprg_cone_diameter + 'mm',
	// 					currentRig_dprg_rod_diameter: rigData?.dprg_rod_diameter + 'mm',
	// 					currentRig_dprg_rod_mass: rigData?.dprg_rod_mass + 'kg',
	// 					currentRig_dprg_anvil_type: rigData?.dprg_anvil_type,
	// 					currentRig_dprg_anvil_dampner: rigData?.dprg_anvil_dampner,
	// 					currentRig_torque_default: rigData?.torque_default,
	// 					currentRig_test_method: rigData?.test_method,
	// 				});
	// 			} else {
	// 				// console.log(`**ERROR** RIG DATA NOT LOADED. ID: ${currentRigValue}`);
	// 			}
	// 			// console.log('rigData:', rigData);
	// 		});
	// 	}
	// }, [savedRigGuid, cachedRigValues]);

	useEffect(() => {
		if (savedRigGuid) {

			const key = `drillit-rigs/company/${company_guid}`;

			get(key).then((rigs) => {
				if (rigs && Array.isArray(rigs)) {
					const rigData = rigs.find(rig => rig.guid === savedRigGuid);

					if (rigData) {
						console.log('rigData from HOLE INFORMATION:', rigData);
						setCurrentRigData({
							currentRig_guid: rigData?.guid,
							currentRig_type: rigData?.type,
							currentRig_plant_id: rigData?.plant_id,
							currentRig_make_model: rigData?.make_model,
							currentRig_test_date_expiry: rigData?.test_date_expiry,
							currentRig_spt_guid: rigData?.spt_guid,
							currentRig_spt: rigData?.spt,
							currentRig_dprg_type: rigData?.dprg_type,
							currentRig_dprg_hammer_mass: rigData?.dprg_hammer_mass + 'kg',
							currentRig_dprg_standard_drop: rigData?.dprg_standard_drop + 'mm',
							currentRig_dprg_cone_diameter: rigData?.dprg_cone_diameter + 'mm',
							currentRig_dprg_rod_diameter: rigData?.dprg_rod_diameter + 'mm',
							currentRig_dprg_rod_mass: rigData?.dprg_rod_mass + 'kg',
							currentRig_dprg_anvil_type: rigData?.dprg_anvil_type,
							currentRig_dprg_anvil_dampner: rigData?.dprg_anvil_dampner,
							currentRig_torque_default: rigData?.torque_default,
							currentRig_test_method: rigData?.test_method,
						});
					} else {
						console.error('Rig with the specified GUID not found.');
					}
				} else {
					console.error('Invalid data structure or no rigs found.');
				}
			}).catch(error => {
				console.error('Error fetching data from IndexedDB:', error);
			});
		}
	}, [savedRigGuid, company_guid]);

	useEffect(() => {
		console.log('RIGDATA*********************************************')
		console.log('plantName:', plantName)
		console.log('plantGuid:', plantGuid)
		console.log('currentRigData.currentRig_type:', currentRigData?.currentRig_type)
		console.log('currentRigData.currentRig_plant_id:', currentRigData?.currentRig_plant_id)
		console.log('currentRigData.currentRig_test_date_expiry:', currentRigData?.currentRig_test_date_expiry)
		console.log('currentRigData.currentRig_spt_guid:', currentRigData?.currentRig_spt_guid)
		console.log('currentRigData.currentRig_spt:', currentRigData?.currentRig_spt)
		console.log('currentRigData.currentRig_dprg_type:', currentRigData?.currentRig_dprg_type)
		console.log('currentRigData.currentRig_dprg_hammer_mass:', currentRigData?.currentRig_dprg_hammer_mass)
		console.log('currentRigData.currentRig_dprg_standard_drop:', currentRigData?.currentRig_dprg_standard_drop)
		console.log('currentRigData.currentRig_dprg_cone_diameter:', currentRigData?.currentRig_dprg_cone_diameter)
		console.log('currentRigData.currentRig_dprg_rod_diameter:', currentRigData?.currentRig_dprg_rod_diameter)
		console.log('currentRigData.currentRig_dprg_rod_mass:', currentRigData?.currentRig_dprg_rod_mass)
		console.log('currentRigData.currentRig_dprg_anvil_type:', currentRigData?.currentRig_dprg_anvil_type)
		console.log('currentRigData.currentRig_dprg_anvil_dampner:', currentRigData?.currentRig_dprg_anvil_dampner)
		console.log('currentRigData.currentRig_torque_default:', currentRigData?.currentRig_torque_default)
		console.log('currentRigData.currentRig_test_method:', currentRigData?.currentRig_test_method)
		console.log('****************************************************')
	}, [plantGuid])

	if (currentRigData?.currentRig_guid !== plantGuid) {
		setPlantGuid(currentRigData?.currentRig_guid)
	}
	if (currentRigData?.currentRig_type !== plantType) {
		setPlantType(currentRigData?.currentRig_type)
	}
	if (currentRigData?.currentRig_plant_id !== plantID) {
		setPlantID(currentRigData?.currentRig_plant_id)
	}
	if (currentRigData?.currentRig_test_date_expiry !== plantTestDate) {
		setPlantTestDate(currentRigData?.currentRig_test_date_expiry)
	}

	const sptID = currentRigData.currentRig_spt?.id_ref
	const sptCalibrationDate = currentRigData.currentRig_spt?.calibration_date
	const sptEnergyRatio = currentRigData.currentRig_spt?.energy_ratio
	const sptRodType = currentRigData.currentRig_spt?.rod_type

	// const [plantType, setPlantType] = useState(currentRigData?.currentRig_type)
	// const [plantID, setPlantID] = useState(currentRigData?.currentRig_plant_id)
	// const [plantName, setPlantName] = useState(rig_name)
	// const [plantTestDate, setPlantTestDate] = useState(currentRigData?.currentRig_test_date_expiry)

	const [testType, setTestType] = useState(currentRigData?.currentRig_dprg_type)
	const [hammerMass, setHammerMass] = useState(currentRigData?.currentRig_dprg_hammer_mass)
	const [standardDrop, setStandardDrop] = useState(currentRigData?.currentRig_dprg_standard_drop)
	const [coneDiameter, setConeDiameter] = useState(currentRigData?.currentRig_dprg_cone_diameter)
	const [rodDiameter, setRodDiameter] = useState(currentRigData?.currentRig_dprg_rod_diameter)
	const [rodMass, setRodMass] = useState(currentRigData?.currentRig_dprg_rod_mass)
	const [anvilType, setAnvilType] = useState(currentRigData?.currentRig_dprg_anvil_type)
	const [anvilDampner, setAnvilDampner] = useState(currentRigData?.currentRig_dprg_anvil_dampner)
	const [torque_default, setTorque_default] = useState(currentRigData?.currentRig_torque_default)
	const [testMethod, setTestMethod] = useState(currentRigData?.currentRig_test_method)

	// const sptGUID = currentRigData?.currentRig_spt_guid

	if (currentRigData?.currentRig_dprg_type !== testType) {
		setTestType(currentRigData?.currentRig_dprg_type)
	}
	if (currentRigData?.currentRig_dprg_hammer_mass !== hammerMass) {
		setHammerMass(currentRigData?.currentRig_dprg_hammer_mass)
	}
	if (currentRigData?.currentRig_dprg_standard_drop !== standardDrop) {
		setStandardDrop(currentRigData?.currentRig_dprg_standard_drop)
	}
	if (currentRigData?.currentRig_dprg_cone_diameter !== coneDiameter) {
		setConeDiameter(currentRigData?.currentRig_dprg_cone_diameter)
	}
	if (currentRigData?.currentRig_dprg_rod_diameter !== rodDiameter) {
		setRodDiameter(currentRigData?.currentRig_dprg_rod_diameter)
	}
	if (currentRigData?.currentRig_dprg_rod_mass !== rodMass) {
		setRodMass(currentRigData?.currentRig_dprg_rod_mass)
	}
	if (currentRigData?.currentRig_dprg_anvil_type !== anvilType) {
		setAnvilType(currentRigData?.currentRig_dprg_anvil_type)
	}
	if (currentRigData?.currentRig_dprg_anvil_dampner !== anvilDampner) {
		setAnvilDampner(currentRigData?.currentRig_dprg_anvil_dampner)
	}
	if (currentRigData?.currentRig_test_method !== testMethod) {
		setTestMethod(currentRigData?.currentRig_test_method)
	}
	if (currentRigData?.currentRig_torque_default !== torque_default) {
		setTorque_default(currentRigData?.currentRig_torque_default)
	}




	// PLANT CHECKS

	
	const [plantCheck_type, set_plantCheck_type] = useState('')
	const [plantCheckDates, setPlantCheckDates] = useState<string[]>([]);
	const [selectedDate_PlantCheck, set_selectedDate_PlantCheck] = useState<boolean>(false);
	console.log('plantCheckDates:', typeof plantCheckDates, plantCheckDates)
	console.log('selectedDate:', typeof selectedDate, selectedDate)
	console.log('selectedDate_PlantCheck:', selectedDate_PlantCheck)

	useEffect(() => {

		if (savedRigGuid && plantType) {

			const cachedPlantChecks = useGetIDBValues

			console.log('plantType', plantType)

			if (plantType === 'Cable Percussive') {
				set_plantCheck_type('cable-percussive')
			} else if (plantType === 'Window Sample') {
				set_plantCheck_type('window-sample')
			} else if (plantType === 'Rotary') {
				set_plantCheck_type('rotary')
			}

			if (plantCheck_type) {
				
				cachedPlantChecks(`plant-check-${plantCheck_type}/${savedRigGuid}`, (plantCheckData: any) => {

					if (plantCheckData) {
						console.log('plantCheckData:', plantCheckData.plantCheck) 
						const dates: string[] = plantCheckData.plantCheck.map((check: any) => check.plant_check_date);
						setPlantCheckDates(dates);
					} else {
						// console.log(`**ERROR** PLANTCHECK DATA NOT LOADED: ${plantCheckData}`);
					}
					// console.log('plantCheckData:', plantCheckData);
				});
			}

		}

	}, [savedRigGuid, cachedRigValues, plantType, plantCheck_type]);

	useEffect(() => {

		if (savedRigGuid && plantType) {

			if (plantType === 'Cable Percussive') {
				set_plantCheck_type('cable-percussive')
			} else if (plantType === 'Window Sample') {
				set_plantCheck_type('window-sample')
			} else if (plantType === 'Rotary') {
				set_plantCheck_type('rotary')
			}

			if (plantCheck_type) {

				// const key = `drillit-plant-check-${plantCheck_type}/${savedRigGuid}`;
				const key = `drillit-plant-check-${plantCheck_type}/project/${project_guid}`;

				get(key).then((plantCheckData) => {
					if (plantCheckData && Array.isArray(plantCheckData.plantCheck)) {
						console.log('plantCheckData:', plantCheckData.plantCheck);
						const dates = plantCheckData.plantCheck.map((check:any) => check.plant_check_date);
						setPlantCheckDates(dates);
					} else {
						console.error(`**ERROR** PLANTCHECK DATA NOT LOADED: ${plantCheckData}`);
					}
				}).catch(error => {
					console.error('Error fetching data from IndexedDB:', error);
				});
			}

		}

	}, [savedRigGuid, plantType, plantCheck_type]);



	useEffect(() => {
		// Convert selectedDate string to a JavaScript Date object
		const selectedDateObject = new Date(selectedDate);
		
		// Format plantCheckDates strings into JavaScript Date objects
		const formattedPlantCheckDates = plantCheckDates.map(dateString => new Date(dateString));
		
		// Get selectedDate without the time component
		const selectedDateWithoutTime = new Date(selectedDateObject.getFullYear(), selectedDateObject.getMonth(), selectedDateObject.getDate());
	  
		// Check if any of the plantCheckDates match the selectedDate without time
		const selectedDateMatches = formattedPlantCheckDates.some(date => {
		  const formattedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
		  return formattedDate.getTime() === selectedDateWithoutTime.getTime();
		});
	  
		// Set selectedDate_PlantCheck based on the result of the comparison
		set_selectedDate_PlantCheck(selectedDateMatches);
	  }, [plantCheckDates]);



	  const [plantCheckWarning, setPlantCheckWarning] = useState(false)
  
		  // Universal Plant Check function
		  const panel_plantCheck = (callback: (value: boolean) => void) => {
			  if (selectedDate_PlantCheck) {
				  callback(true);
			  } else {
				  setPlantCheckWarning(true);
			  }
		  };






	












	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// TEMP VALUES
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	// let labelSample = ''
	// let labelDepth = ''









	//......................................................................................................................
	//.PPPPPPPPP...PRRRRRRRRR.....OOOOOOO.....OBBBBBBBBB...BEEEEEEEEEE...... DDDDDDDD.......AAAAA...AAATTTTTTTTT.TAAAA......
	//.PPPPPPPPPP..PRRRRRRRRRR...OOOOOOOOOO...OBBBBBBBBBB..BEEEEEEEEEE...... DDDDDDDDD......AAAAA...AAATTTTTTTTT.TAAAA......
	//.PPPPPPPPPPP.PRRRRRRRRRR..ROOOOOOOOOOO..OBBBBBBBBBB..BEEEEEEEEEE...... DDDDDDDDDD....AAAAAA...AAATTTTTTTTT.TAAAAA.....
	//.PPPP...PPPP.PRRR...RRRRR.ROOOO..OOOOO..OBBB...BBBB..BEEE............. DDD...DDDD....AAAAAAA......TTTT....TTAAAAA.....
	//.PPPP...PPPP.PRRR...RRRRRRROOO....OOOOO.OBBB...BBBB..BEEE............. DDD....DDDD..AAAAAAAA......TTTT....TTAAAAA.....
	//.PPPPPPPPPPP.PRRRRRRRRRR.RROO......OOOO.OBBBBBBBBBB..BEEEEEEEEE....... DDD....DDDD..AAAAAAAA......TTTT...TTTA.AAAA....
	//.PPPPPPPPPP..PRRRRRRRRRR.RROO......OOOO.OBBBBBBBBB...BEEEEEEEEE....... DDD....DDDD..AAAA.AAAA.....TTTT...TTTA.AAAA....
	//.PPPPPPPPP...PRRRRRRR....RROO......OOOO.OBBBBBBBBBB..BEEEEEEEEE....... DDD....DDDD.DAAAAAAAAA.....TTTT...TTTAAAAAAA...
	//.PPPP........PRRR.RRRR...RROOO....OOOOO.OBBB....BBBB.BEEE............. DDD....DDDD.DAAAAAAAAAA....TTTT..TTTTAAAAAAA...
	//.PPPP........PRRR..RRRR...ROOOO..OOOOO..OBBB....BBBB.BEEE............. DDD...DDDDD.DAAAAAAAAAA....TTTT..TTTTAAAAAAA...
	//.PPPP........PRRR..RRRRR..ROOOOOOOOOOO..OBBBBBBBBBBB.BEEEEEEEEEE...... DDDDDDDDDD.DDAA....AAAA....TTTT..TTTT....AAAA..
	//.PPPP........PRRR...RRRRR..OOOOOOOOOO...OBBBBBBBBBB..BEEEEEEEEEE...... DDDDDDDDD..DDAA.....AAAA...TTTT.TTTT.....AAAA..
	//.PPPP........PRRR....RRRR....OOOOOO.....OBBBBBBBBB...BEEEEEEEEEE...... DDDDDDDD..DDDAA.....AAAA...TTTT.TTTT.....AAAA..
	//......................................................................................................................

	// const [probeData, setProbeData] = useState([]) // value.blows
	const [probeData, setProbeData] = useState<{ blows: number[] }>({
		blows: [],
	});

	const [torqueData, setTorqueData] = useState<{ torques: number[] }>({
		torques: [],
	});
	const [cumulativeData, setCumulativeData] = useState<string[]>([]) // value.cumulative_blows
	const [torqueUnit, setTorqueUnit] = useState(torque_default) // useState('(kg/m)') // value.dp_torque_unit torque_default
	const [dp_guid, set_dp_guid] = useState(borehole_guid) // value.holeId
	const [dp_borehole, set_dp_borehole] = useState('') // value.holeId

	const [triggerRender, setTriggerRender] = useState(false);




	//////////////////////////////////////////////////////////////////////////////////////
	// Separate Probe Data onLoad
	//////////////////////////////////////////////////////////////////////////////////////

	// useEffect(() => {
	// 	const firstDynamicProbeData = DynamicProbeData[0];
	// 	// console.log('firstDynamicProbeData:', firstDynamicProbeData);

	// 	if (firstDynamicProbeData) {
	// 		const { blows, torques, ...rest } = firstDynamicProbeData;
	// 		const blowsArray = Array.isArray(blows) && blows.length > 0 ? blows.reduce((acc, val) => acc.concat(val), []).map(Number) : [];
	// 		const torquesArray = torques ? torques.reduce((acc: any, val: any) => acc.concat(Array.isArray(val) ? val : [val]), []).map(Number) : [];
	// 		// console.log('blows.length:', blows.length)
	// 		console.log('firstDynamicProbeData:', firstDynamicProbeData)
	// 		//set depth of Dynamic Probe
	// 		const blowsArrayFiltered: number[] = blowsArray.filter((value: number) => value !== -2);
	// 		// console.log('blowsArray.length:', blowsArray.length)
	// 		const DP_depth = parseFloat(((blowsArrayFiltered.length / 10) - 0.1).toFixed(2));
	// 		setDepthDynamicProbe(DP_depth)
	// 		console.log('blowsArray:', blowsArray)
	// 		console.log('blowsArray.length:', blowsArray.length)
	// 		console.log('(blowsArray.length / 10) - 0.1:', (blowsArray.length / 10) - 0.1)
	// 		console.log('((blowsArray.length / 10) - 0.1).toFixed(2):', ((blowsArray.length / 10) - 0.1).toFixed(2))
	// 		console.log('parseFloat(((blowsArray.length / 10) - 0.1).toFixed(2)):', parseFloat(((blowsArray.length / 10) - 0.1).toFixed(2)))
	// 		console.log('DP_depth:', DP_depth)
	// 		// Set the probeData and torqueData states
	// 		setProbeData({
	// 			blows: blowsArray as number[], // Explicitly cast to number[]
	// 			...rest, // Include other properties
	// 		});

	// 		setTorqueData({
	// 			torques: torquesArray as number[], // Explicitly cast to number[]
	// 			...rest, // Include other properties
	// 		})
	// 		setTorqueUnit(firstDynamicProbeData.dp_torque_unit)

	// 		if(DynamicProbeData && DynamicProbeData.length === 1){
	// 			setNumberOfDynamicProbes(1)
	// 		}

	// 	} else {
	// 		console.log('DynamicProbeData not available');
	// 	}
	// }, [DynamicProbeData])

	const configureDynamicProbeData = (dynamicProbeData: any) => {
		if (dynamicProbeData) {
			console.log('dynamicProbeData', dynamicProbeData)
			const firstDynamicProbeData = dynamicProbeData[0];

			if (firstDynamicProbeData) {
				const { blows, torques, ...rest } = firstDynamicProbeData;
				const blowsArray = Array.isArray(blows) && blows.length > 0 ? blows.reduce((acc, val) => acc.concat(val), []).map(Number) : [];
				const torquesArray = torques ? torques.reduce((acc: any, val: any) => acc.concat(Array.isArray(val) ? val : [val]), []).map(Number) : [];

				// Filter out -2 values from blows
				const blowsArrayFiltered: number[] = blowsArray.filter((value: number) => value !== -2);

				// Calculate DP_depth
				const DP_depth = parseFloat(((blowsArrayFiltered.length / 10) - 0.1).toFixed(2));

				// Set depth of Dynamic Probe
				setDepthDynamicProbe(DP_depth);

				// Set the probeData and torqueData states
				setProbeData({
					blows: blowsArray as number[], // Explicitly cast to number[]
					...rest, // Include other properties
				});

				setTorqueData({
					torques: torquesArray as number[], // Explicitly cast to number[]
					...rest, // Include other properties
				});

				setTorqueUnit(firstDynamicProbeData.dp_torque_unit);

				// // Convert selectedDate to a Date object
				// const selectedDateObj = new Date(selectedDate);

				// // Convert the time_of_action string to a Date object
				// const timeOfActionDate = new Date(firstDynamicProbeData.time_of_action);
				
				// // Extract the year, month, and day components from selectedDateObj
				// const selectedYear = selectedDateObj.getFullYear();
				// const selectedMonth = selectedDateObj.getMonth();
				// const selectedDay = selectedDateObj.getDate();
				
				// // Extract the year, month, and day components from timeOfActionDate
				// const actionYear = timeOfActionDate.getFullYear();
				// const actionMonth = timeOfActionDate.getMonth();
				// const actionDay = timeOfActionDate.getDate();

				// // Compare the dates
				// if (
				// 	actionYear === selectedYear &&
				// 	actionMonth === selectedMonth &&
				// 	actionDay === selectedDay
				//   ) {
				// 	console.log('The dates are the same.');
				//   } else {
				// 	console.log('The dates are different.');
				//   }

				// // Set the number of dynamic probes if there is only one
				// if (dynamicProbeData.length === 1) {
				// 	setNumberOfDynamicProbes(1);
				// } else {
				// 	setNumberOfDynamicProbes(0);
				// }
			} else {
				console.log('DynamicProbeData not available');
			}
		}
	};

	// useEffect to initialize
	useEffect(() => {
		configureDynamicProbeData(DynamicProbeData);
	}, [DynamicProbeData]);


	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// DYNAMIC PROBE GRAPH
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const maxValue = 50; // Maximum possible value

	// const calculateGraphWidths = () => {
	// 	// const widths = inputValues.map(value => {
	// 	const widths = probeData.map(value => {
	// 		// If the value is not a number or is negative, return 0
	// 		if (isNaN(value) || value < 0) {
	// 			return 0;
	// 		}
	// 		return (value / maxValue) * 100;
	// 	})
	// 	return widths;
	// }

	// const graphWidths = calculateGraphWidths();





	//......................................................................................................................................
	//.PPPPPPPPP...PRRRRRRRRR.....OOOOOOO.....OBBBBBBBBB...BEEEEEEEEEE........CCCCCCC....CHHH...HHHH.....AAAAA.....ARRRRRRRRR...RTTTTTTTTT..
	//.PPPPPPPPPP..PRRRRRRRRRR...OOOOOOOOOO...OBBBBBBBBBB..BEEEEEEEEEE.......CCCCCCCCC...CHHH...HHHH.....AAAAA.....ARRRRRRRRRR..RTTTTTTTTT..
	//.PPPPPPPPPPP.PRRRRRRRRRR..ROOOOOOOOOOO..OBBBBBBBBBB..BEEEEEEEEEE...... CCCCCCCCCC..CHHH...HHHH....AAAAAA.....ARRRRRRRRRR..RTTTTTTTTT..
	//.PPPP...PPPP.PRRR...RRRRR.ROOOO..OOOOO..OBBB...BBBB..BEEE............. CCC...CCCCC.CHHH...HHHH....AAAAAAA....ARRR...RRRRR....TTTT.....
	//.PPPP...PPPP.PRRR...RRRRRRROOO....OOOOO.OBBB...BBBB..BEEE............ CC.....CCC..CHHH...HHHH...AAAAAAAA....ARRR...RRRRR....TTTT.....
	//.PPPPPPPPPPP.PRRRRRRRRRR.RROO......OOOO.OBBBBBBBBBB..BEEEEEEEEE...... CC..........CHHHHHHHHHH...AAAAAAAA....ARRRRRRRRRR.....TTTT.....
	//.PPPPPPPPPP..PRRRRRRRRRR.RROO......OOOO.OBBBBBBBBB...BEEEEEEEEE...... CC..........CHHHHHHHHHH...AAAA.AAAA...ARRRRRRRRRR.....TTTT.....
	//.PPPPPPPPP...PRRRRRRR....RROO......OOOO.OBBBBBBBBBB..BEEEEEEEEE...... CC..........CHHHHHHHHHH..HAAAAAAAAA...ARRRRRRR........TTTT.....
	//.PPPP........PRRR.RRRR...RROOO....OOOOO.OBBB....BBBB.BEEE............ CC.....CCC..CHHH...HHHH..HAAAAAAAAAA..ARRR.RRRR.......TTTT.....
	//.PPPP........PRRR..RRRR...ROOOO..OOOOO..OBBB....BBBB.BEEE............. CCC...CCCCC.CHHH...HHHH..HAAAAAAAAAA..ARRR..RRRR......TTTT.....
	//.PPPP........PRRR..RRRRR..ROOOOOOOOOOO..OBBBBBBBBBBB.BEEEEEEEEEE...... CCCCCCCCCC..CHHH...HHHH.HHAA....AAAA..ARRR..RRRRR.....TTTT.....
	//.PPPP........PRRR...RRRRR..OOOOOOOOOO...OBBBBBBBBBB..BEEEEEEEEEE.......CCCCCCCCCC..CHHH...HHHH.HHAA.....AAAA.ARRR...RRRRR....TTTT.....
	//.PPPP........PRRR....RRRR....OOOOOO.....OBBBBBBBBB...BEEEEEEEEEE........CCCCCCC....CHHH...HHHHHHHAA.....AAAA.ARRR....RRRR....TTTT.....
	//......................................................................................................................................

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// DYNAMIC PROBE DATA & CHART SCROLLING
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	// Get references to the two div elements
	const dataScroll = document.getElementById('dataScroll')
	const chartScroll = document.getElementById('chartScroll')

	// Add null checks before adding scroll event listeners
	if (dataScroll !== null && chartScroll !== null) {
		// Add a scroll event listener to dataScroll
		dataScroll.addEventListener('scroll', () => {
			// Synchronize the scroll position of chartScroll with dataScroll
			if (chartScroll !== null) {
				chartScroll.scrollTop = dataScroll.scrollTop
			}
		})

		// Add a scroll event listener to chartScroll
		chartScroll.addEventListener('scroll', () => {
			// Synchronize the scroll position of dataScroll with chartScroll
			if (dataScroll !== null) {
				dataScroll.scrollTop = chartScroll.scrollTop
			}
		})
	}




	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

	const handleResize = () => {
		setIsMobile(window.innerWidth <= 768);
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);















	//..................................................................................................................
	//.IIIII.NNNN...NNNN..FFFFFFFFFF...OOOOOOO............CCCCCCC....HHHH...HHHH..EEEEEEEEEEE...CCCCCCC....KKKK...KKKK..
	//.IIIII.NNNNN..NNNN..FFFFFFFFFF..OOOOOOOOOO.........CCCCCCCCC...HHHH...HHHH..EEEEEEEEEEE..CCCCCCCCC...KKKK..KKKKK..
	//.IIIII.NNNNN..NNNN..FFFFFFFFFF.OOOOOOOOOOOO.......CCCCCCCCCCC..HHHH...HHHH..EEEEEEEEEEE.CCCCCCCCCCC..KKKK.KKKKK...
	//.IIIII.NNNNNN.NNNN..FFFF.......OOOOO..OOOOO.......CCCC...CCCCC.HHHH...HHHH..EEEE........CCCC...CCCCC.KKKKKKKKK....
	//.IIIII.NNNNNN.NNNN..FFFF......OOOOO....OOOOO.....CCCC.....CCC..HHHH...HHHH..EEEE.......ECCC.....CCC..KKKKKKKK.....
	//.IIIII.NNNNNNNNNNN..FFFFFFFFF.OOOO......OOOO.....CCCC..........HHHHHHHHHHH..EEEEEEEEEE.ECCC..........KKKKKKKK.....
	//.IIIII.NNNNNNNNNNN..FFFFFFFFF.OOOO......OOOO.....CCCC..........HHHHHHHHHHH..EEEEEEEEEE.ECCC..........KKKKKKKK.....
	//.IIIII.NNNNNNNNNNN..FFFFFFFFF.OOOO......OOOO.....CCCC..........HHHHHHHHHHH..EEEEEEEEEE.ECCC..........KKKKKKKKK....
	//.IIIII.NNNNNNNNNNN..FFFF......OOOOO....OOOOO.....CCCC.....CCC..HHHH...HHHH..EEEE.......ECCC.....CCC..KKKK.KKKKK...
	//.IIIII.NNNN.NNNNNN..FFFF.......OOOOO..OOOOO.......CCCC...CCCCC.HHHH...HHHH..EEEE........CCCC...CCCCC.KKKK..KKKK...
	//.IIIII.NNNN..NNNNN..FFFF.......OOOOOOOOOOOO.......CCCCCCCCCCC..HHHH...HHHH..EEEEEEEEEEE.CCCCCCCCCCC..KKKK..KKKKK..
	//.IIIII.NNNN..NNNNN..FFFF........OOOOOOOOOO.........CCCCCCCCCC..HHHH...HHHH..EEEEEEEEEEE..CCCCCCCCCC..KKKK...KKKK..
	//.IIIII.NNNN...NNNN..FFFF..........OOOOOO............CCCCCCC....HHHH...HHHH..EEEEEEEEEEE...CCCCCCC....KKKK...KKKK..
	//..................................................................................................................




	const [informationCheck, setInformationCheck] = useState(true)

	useEffect(() => {

		if (selectedDate_PlantCheck && secondMan && plantGuid) {
			setInformationCheck(true)
		} else {
			setInformationCheck(false)
		}
		
	  }, [selectedDate_PlantCheck, secondMan, plantGuid]);






	//..........................................................................................................................
	//.UUUU...UUUU..PPPPPPPPP...PDDDDDDDD.......AAAAA...AAATTTTTTTTTTEEEEEEEEE...... I.IIINN...NNNN.NNNFFFFFFFF...OOOOOOO.....
	//.UUUU...UUUU..PPPPPPPPPP..PDDDDDDDDD......AAAAA...AAATTTTTTTTTTEEEEEEEEE...... I.IIINN...NNNN.NNNFFFFFFFF..OOOOOOOOOO...
	//.UUUU...UUUU..PPPPPPPPPPP.PDDDDDDDDDD....AAAAAA...AAATTTTTTTTTTEEEEEEEEE...... I.IIINNN..NNNN.NNNFFFFFFFF.FOOOOOOOOOOO..
	//.UUUU...UUUU..PPPP...PPPP.PDDD...DDDD....AAAAAAA......TTTT...TTEE............. I.IIINNNN.NNNN.NNNF.......FFOOO...OOOOO..
	//.UUUU...UUUU..PPPP...PPPP.PDDD....DDDD..AAAAAAAA......TTTT...TTEE............. I.IIINNNN.NNNN.NNNF.......FFOO.....OOOO..
	//.UUUU...UUUU..PPPPPPPPPPP.PDDD....DDDD..AAAAAAAA......TTTT...TTEEEEEEEEE...... I.IIINNNNNNNNN.NNNFFFFFFF.FFOO......OOO..
	//.UUUU...UUUU..PPPPPPPPPP..PDDD....DDDD..AAAA.AAAA.....TTTT...TTEEEEEEEEE...... I.IIINNNNNNNNN.NNNFFFFFFF.FFOO......OOO..
	//.UUUU...UUUU..PPPPPPPPP...PDDD....DDDD.DAAAAAAAAA.....TTTT...TTEEEEEEEEE...... I.IIIN.NNNNNNN.NNNFFFFFFF.FFOO......OOO..
	//.UUUU...UUUU..PPPP........PDDD....DDDD.DAAAAAAAAAA....TTTT...TTEE............. I.IIIN.NNNNNNN.NNNF.......FFOO.....OOOO..
	//.UUUU...UUUU..PPPP........PDDD...DDDDD.DAAAAAAAAAA....TTTT...TTEE............. I.IIIN..NNNNNN.NNNF.......FFOOOO..OOOOO..
	//.UUUUUUUUUUU..PPPP........PDDDDDDDDDD.DDAA....AAAA....TTTT...TTEEEEEEEEEE..... I.IIIN..NNNNNN.NNNF........FOOOOOOOOOOO..
	//..UUUUUUUUU...PPPP........PDDDDDDDDD..DDAA.....AAAA...TTTT...TTEEEEEEEEEE..... I.IIIN...NNNNN.NNNF.........OOOOOOOOOO...
	//...UUUUUUU....PPPP........PDDDDDDDD..DDDAA.....AAAA...TTTT...TTEEEEEEEEEE..... I.IIIN....NNNN.NNNF..........OOOOOOO.....
	//..........................................................................................................................


	function getHoleData() {


		cachedHoleInformation(`hole-locations/${borehole_guid}`, (holeData: any) => {
			if (holeData) {
				console.log('holeData in getHoleData:', holeData)
				setLeadDriller(holeData.driller)
				setLeadDrillerID(holeData.driller_id)
				setLeadDrillerCSCS(holeData.driller_cscs);
				setSecondMan(holeData.second_man);
				setSecondManCSCS(holeData.second_man_cscs);
				
				setSavedRigValue(holeData.rig_id); // RIG GUID
				setPlantName(holeData.rig_name); // RIG NAME
				setSptGUID(holeData.spt_id); // SPT ID

				setBoreholeName(holeData.hole_name); // HOLE NAME
				setHole_barcode(holeData.hole_barcode); // BARCODE
				setActivityTerminationReason(holeData.activity_termination_reason); // TERMINATION REASON
				setHole_status(holeData.status); // HOLE STATUS
			} else {
				// // console.log(`**ERROR** HOLE DATA NOT LOADED. ID: ${borehole_guid}`);
			}
			console.log('holeData:', holeData);
		})

	}


	// function saveHoleData() {

	// 	if (leadDriller && plantGuid && plantName) {

	// 		updateHoleInformation(
	// 			borehole_guid,
	// 			boreholeName,
	// 			leadDriller,
	// 			leadDrillerID,
	// 			leadDrillerCSCS,
	// 			secondMan,
	// 			secondManCSCS,
	// 			plantGuid || '',
	// 			plantName,
	// 			sptGUID || '',
	// 			hole_barcode,
	// 			borehole_depthOverall,
	// 			activityTerminationReason,
	// 			project_guid,
	// 			hole_status
	// 		)

	// 		const cachedHoleInformation = useGetIDBValues

	// 		cachedHoleInformation(`hole-locations/${borehole_guid}`, (holeData: any) => {
	// 			if (holeData) {
	// 				console.log('holeData in saveHoleData:', holeData)
	// 				// setBoreholeName(holeData.hole_name)
	// 				// setLeadDriller(holeData.driller)
	// 				// setLeadDriller(holeData.driller)
	// 				// setLeadDrillerCSCS(holeData.driller_cscs);
	// 				// setSecondMan(holeData.second_man);
	// 				// setSecondManCSCS(holeData.second_man_cscs);
	// 				// setSavedRigValue(holeData.rig_id);
	// 				// setPlantName(holeData.rig_name);
	// 				// setSptGUID(holeData.spt_id);
	// 			} else {
	// 				// // console.log(`**ERROR** HOLE DATA NOT LOADED. ID: ${borehole_guid}`);
	// 			}
	// 			// // console.log('holeData:', holeData);
	// 		})


	// 	} else {
	// 		getHoleData()
	// 	}

	// }

	

	function saveHoleData() {
	
		// Attempt to delete the outdated hole information
		del(`drillit-hole-locations/updateInformation/${borehole_guid}-update`)
			.catch(error => {
				console.warn('Failed to delete outdated hole information (may not exist):', error);
			})
			.finally(() => {
				let hole_status_temp = '1'
				if (hole_status !== '3') {
					hole_status_temp = '2'
				} else {
					hole_status_temp = '3'
				}
				// Update the hole information
				updateHoleInformation(
					borehole_guid,
					boreholeName,
					leadDriller,
					leadDrillerID,
					leadDrillerCSCS,
					secondMan,
					secondManCSCS,
					plantGuid || '',
					plantName,
					plantMakeModel,
					sptGUID || '',
					hole_barcode,
					borehole_depthOverall,
					activityTerminationReason,
					project_guid,
					hole_status_temp, // hole_status
					numberingType
				)
				.then(() => {
					// Fetch the updated hole data
					getHoleData();
				})
				.catch(error => {
					console.error('Failed to update hole information:', error);
				});
			});
	}
	


















	return (

		<>

			{/* //..............................................................................
//.HHHH...HHHH..EEEEEEEEEEE....AAAAA.....ADDDDDDDD....DEEEEEEEEEE.ERRRRRRRRR....
//.HHHH...HHHH..EEEEEEEEEEE....AAAAA.....ADDDDDDDDD...DEEEEEEEEEE.ERRRRRRRRRR...
//.HHHH...HHHH..EEEEEEEEEEE...AAAAAA.....ADDDDDDDDDD..DEEEEEEEEEE.ERRRRRRRRRR...
//.HHHH...HHHH..EEEE..........AAAAAAA....ADDD...DDDD..DEEE........ERRR...RRRRR..
//.HHHH...HHHH..EEEE.........AAAAAAAA....ADDD....DDDD.DEEE........ERRR...RRRRR..
//.HHHHHHHHHHH..EEEEEEEEEE...AAAAAAAA....ADDD....DDDD.DEEEEEEEEE..ERRRRRRRRRR...
//.HHHHHHHHHHH..EEEEEEEEEE...AAAA.AAAA...ADDD....DDDD.DEEEEEEEEE..ERRRRRRRRRR...
//.HHHHHHHHHHH..EEEEEEEEEE..EAAAAAAAAA...ADDD....DDDD.DEEEEEEEEE..ERRRRRRR......
//.HHHH...HHHH..EEEE........EAAAAAAAAAA..ADDD....DDDD.DEEE........ERRR.RRRR.....
//.HHHH...HHHH..EEEE........EAAAAAAAAAA..ADDD...DDDDD.DEEE........ERRR..RRRR....
//.HHHH...HHHH..EEEEEEEEEEEEEAA....AAAA..ADDDDDDDDDD..DEEEEEEEEEE.ERRR..RRRRR...
//.HHHH...HHHH..EEEEEEEEEEEEEAA.....AAAA.ADDDDDDDDD...DEEEEEEEEEE.ERRR...RRRRR..
//.HHHH...HHHH..EEEEEEEEEEEEEAA.....AAAA.ADDDDDDDD....DEEEEEEEEEE.ERRR....RRRR..
//.............................................................................. */}

			<Header toggleSidebar={toggleSidebar}
				middle={
					<ProjectHeader projectId={project_guid}
						information_check={informationCheck}
						holeType="DP"
						holeName={borehole_name}
						companyGuid={company_guid}
						company_id={companyID}
						projectGuid={project_guid}
						activeDates={active_dates}
						lastActiveDate={lastActiveDate}

						project_start_date={project_start_date}
						site_description={site_description}
						key_site_features={key_site_features}
						site_history={site_history}
						key_hands={key_hands}
						expected_strata={expected_strata}
						notes={notes}
						equipment_required={equipment_required}
						project_client={client_name}
						client_contact={client_contact}
						client_phone={client_phone}
						client_email={client_email}
						engineer_name={engineer_name}
						engineer_phone={engineer_phone}
						engineer_email={engineer_email}
						rig_name={plantName}
						driller={leadDriller}
						second_man={secondMan}
						plantCheck={selectedDate_PlantCheck}
					/>
				}
			// companyName={company_name}
			/>








			{/* //.........................................................................
			//.PPPPPPPPP.....AAAA.....AAANN...NNNN.NNNEEEEEEEE.EEEL.........LSSSSSS....
			//.PPPPPPPPPP...AAAAAA....AAANN...NNNN.NNNEEEEEEEE.EEEL........LLSSSSSSS...
			//.PPPPPPPPPPP..AAAAAA....AAANNN..NNNN.NNNEEEEEEEE.EEEL.......LLLSSSSSSSS..
			//.PPPP...PPPP..AAAAAAA...AAANNNN.NNNN.NNNE........EEEL.......LLLS...SSSS..
			//.PPPP...PPPP.PAAAAAAA...AAANNNN.NNNN.NNNE........EEEL.......LLLSS........
			//.PPPPPPPPPPP.PAAAAAAA...AAANNNNNNNNN.NNNEEEEEEEE.EEEL........LLSSSSS.....
			//.PPPPPPPPPP..PAAA.AAAA..AAANNNNNNNNN.NNNEEEEEEEE.EEEL.........LSSSSSSS...
			//.PPPPPPPPP..PPAAAAAAAA..AAAN.NNNNNNN.NNNEEEEEEEE.EEEL...........SSSSSSS..
			//.PPPP.......PPAAAAAAAAA.AAAN.NNNNNNN.NNNE........EEEL..............SSSS..
			//.PPPP......PPPAAAAAAAAA.AAAN..NNNNNN.NNNE........EEEL.......LLLS...SSSS..
			//.PPPP......PPPA....AAAA.AAAN..NNNNNN.NNNEEEEEEEEEEEELLLLLLLLLLLSSSSSSSS..
			//.PPPP......PPPA....AAAAAAAAN...NNNNN.NNNEEEEEEEEEEEELLLLLLLL.LLSSSSSSSS..
			//.PPPP.....PPPPA.....AAAAAAAN....NNNN.NNNEEEEEEEEEEEELLLLLLLL..LSSSSSS....
			//......................................................................... */}


			{completingBorehole &&
				<Modal title="Mark as Complete" toggleModal={() => { setCompletingBorehole(false) }}>
					<div className="flex items-center flex-col pt-36">
						<div className="mb-14">
							Confirm you have completed activity on this borehole
						</div>

						<div className="flex">
							<Button theme="secondary" size="lg" className="mr-8" onClick={() => { setCompletingBorehole(false) }}>Cancel</Button>

							<Button theme="primary" size="lg" onClick={() => {
								closeHole(borehole_guid, formattedDate, project_guid).then(() => { setRedirect(true) })
							}}>Confirm</Button>
						</div>
					</div>
				</Modal>
			}

			{showD &&
				<Samples
					onClose={() => { setShowD(false) }}
					onCompleteNumbering={(value: any) => {
						setNumberingType(value)
						saveHoleData()
					}}
					onComplete={(value: any) => saveAction(value, 'D', setShowD(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
					title="Disturbed Sample (D)"
					casing_preset={current_casing_depth}
					new_type_number={numberOfSamplesD_TOTAL + 1}
					new_type_number_sequencial={numberOfSamples_TOTAL + 1}
					numbering_type={numberingType}
					sampleType='D'
					sampled_by={leadDriller}
				/>
			}

			{showDEdit &&
				<Samples
					update={true}
					onClose={() => { setShowDEdit(false) }}
					setShowDEdit={setShowDEdit}
					onCompleteNumbering={(value: any) => {
						setNumberingType(value)
						saveHoleData()
					}}
					onComplete={(value: any) => {
						saveAction(value, 'D', setShowDEdit(false))
						// console.log('SAMPLES DATA LOOKING FOR HIDDEN:', value)
					}}
					borehole={borehole_name}
					title="Disturbed Sample (D)"
					casing_preset={current_casing_depth}
					defaultValues={SamplesData[editingIndex]}
					numbering_type={numberingType}
					sampleType='D'
					sampled_by={leadDriller}
				/>
			}

			{showB &&
				<Samples
					onClose={() => { setShowB(false) }}
					onCompleteNumbering={(value: any) => {
						setNumberingType(value)
						saveHoleData()
					}}
					onComplete={(value: any) => saveAction(value, 'B', setShowB(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
					title="Bulk Sample (B)"
					casing_preset={current_casing_depth}
					new_type_number={numberOfSamplesB_TOTAL + 1}
					new_type_number_sequencial={numberOfSamples_TOTAL + 1}
					numbering_type={numberingType}
					sampleType='B'
					sampled_by={leadDriller}
				/>
			}

			{showBEdit &&
				<Samples
					update={true}
					onClose={() => { setShowBEdit(false) }}
					setShowBEdit={setShowBEdit}
					onCompleteNumbering={(value: any) => {
						setNumberingType(value)
						saveHoleData()
					}}
					onComplete={(value: any) => saveAction(value, 'B', setShowBEdit(false))}
					borehole={borehole_name}
					title="Bulk Sample (B)"
					casing_preset={current_casing_depth}
					defaultValues={SamplesData[editingIndex]}
					numbering_type={numberingType}
					sampleType='B'
					sampled_by={leadDriller}
				/>
			}

			{showES &&
				<Samples
					onClose={() => { setShowES(false) }}
					onCompleteNumbering={(value: any) => {
						setNumberingType(value)
						saveHoleData()
					}}
					onComplete={(value: any) => saveAction(value, 'ES', setShowES(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
					title="Environmental Sample (ES)"
					casing_preset={current_casing_depth}
					new_type_number={numberOfSamplesES_TOTAL + 1}
					new_type_number_sequencial={numberOfSamples_TOTAL + 1}
					numbering_type={numberingType}
					sampleType='ES'
					sampled_by={leadDriller}
				/>
			}

			{showESEdit &&
				<Samples
					update={true}
					onClose={() => { setShowESEdit(false) }}
					setShowESEdit={setShowESEdit}
					onCompleteNumbering={(value: any) => {
						setNumberingType(value)
						saveHoleData()
					}}
					onComplete={(value: any) => saveAction(value, 'ES', setShowESEdit(false))}
					borehole={borehole_name}
					title="Environmental Sample (ES)"
					casing_preset={current_casing_depth}
					defaultValues={SamplesData[editingIndex]}
					numbering_type={numberingType}
					sampleType='ES'
					sampled_by={leadDriller}
				/>
			}

			{showWS &&
				<Samples
					onClose={() => { setShowWS(false) }}
					onCompleteNumbering={(value: any) => {
						setNumberingType(value)
						saveHoleData()
					}}
					onComplete={(value: any) => saveAction(value, 'WS', setShowWS(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
					title="Water Sample (WS)"
					casing_preset={current_casing_depth}
					new_type_number={numberOfSamplesWS_TOTAL + 1}
					new_type_number_sequencial={numberOfSamples_TOTAL + 1}
					numbering_type={numberingType}
					sampleType='WS'
					sampled_by={leadDriller}
				/>
			}

			{showWSEdit &&
				<Samples
					update={true}
					onClose={() => { setShowWSEdit(false) }}
					setShowWSEdit={setShowWSEdit}
					onCompleteNumbering={(value: any) => {
						setNumberingType(value)
						saveHoleData()
					}}
					onComplete={(value: any) => saveAction(value, 'WS', setShowWSEdit(false))}
					borehole={borehole_name}
					title="Water Sample (WS)"
					casing_preset={current_casing_depth}
					defaultValues={SamplesData[editingIndex]}
					numbering_type={numberingType}
					sampleType='WS'
					sampled_by={leadDriller}
				/>
			}

			{showInspectionPit &&
				<InspectionPit
					onClose={() => { setShowInspectionPit(false) }}
					onComplete={(value: any) => saveAction(value, 'InspectionPit', setShowInspectionPit(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
				/>
			}

			{showInspectionPitEdit &&
				<InspectionPit
					update={true}
					onClose={() => { setShowInspectionPitEdit(false) }}
					setShowInspectionPitEdit={setShowInspectionPitEdit}
					onComplete={(value: any) => saveAction(value, 'InspectionPit', setShowInspectionPitEdit(false))}
					borehole={borehole_name}
					defaultValues={InspectionPitData[editingIndex]}
				/>
			}

			{showDynamicProbe &&
				<DynamicProbe
					title="Dynamic Probe"
					onClose={() => { setShowDynamicProbe(false) }}
					onComplete={(value: any) => {
						// separateProbeData(value)
						saveAction(value, 'DynamicProbe', setShowDynamicProbe(false))
						console.log('Returned DP data****************************', value)
					}}
					onUpdate={(value: any) => {
						// separateProbeData(value)
						saveAction(value, 'DynamicProbe', setShowDynamicProbe(true))
						console.log('UPDATE DP data****************************', value)
					}}
					borehole={borehole_name}
					selectedDate={selectedDate}
					holeDepth={borehole_depth}
					torque_default={torque_default}
				/>
			}

			{showDynamicProbeEdit &&
				<DynamicProbe
					title="Dynamic Probe"
					update={true}
					onClose={() => { setShowDynamicProbeEdit(false) }}
					setShowDynamicProbeEdit={setShowDynamicProbeEdit}
					onComplete={(value: any) => {
						saveAction(value, 'DynamicProbe', setShowDynamicProbeEdit(false))
						console.log('Returned DP data after EDIT****************************', value)
						configureDynamicProbeData([value]);
					}}
					onUpdate={(value: any) => {
						// separateProbeData(value)
						saveAction(value, 'DynamicProbe', setShowDynamicProbeEdit(true))
						console.log('UPDATE DP data****************************', value)
					}}
					borehole={borehole_name}
					holeDepth={borehole_depth}
					defaultValues={DynamicProbeData[editingIndex]}
					torque_default={torque_default}
				/>
			}

			{showComments &&
				<Comments
					onClose={() => { setShowComments(false) }}
					onComplete={(value: any) => saveAction(value, 'Comments', setShowComments(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
				/>
			}

			{showCommentsEdit &&
				<Comments
					update={true}
					onClose={() => { setShowCommentsEdit(false) }}
					setShowCommentsEdit={setShowCommentsEdit}
					onComplete={(value: any) => saveAction(value, 'Comments', setShowCommentsEdit(false))}
					borehole={borehole_name}
					defaultValues={CommentsData[editingIndex]}
				/>
			}

			{showCasingRecord &&
				<CasingRecord
					onClose={() => { setShowCasingRecord(false) }}
					onComplete={(value: any) => saveAction(value, 'Casing', setShowCasingRecord(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
					holeDepth={borehole_depth}
				/>
			}

			{showCasingRecordEdit &&
				<CasingRecord
					update={true}
					onClose={() => { setShowCasingRecordEdit(false) }}
					setShowCasingRecordEdit={setShowCasingRecordEdit}
					onComplete={(value: any) => saveAction(value, 'Casing', setShowCasingRecordEdit(false))}
					borehole={borehole_name}
					holeDepth={borehole_depth}
					defaultValues={CasingData[editingIndex]}
				/>
			}















			{/* //....................................................................................................................................
			//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNN...NNNN..UUUU...UUUU.......PPPPPPPPP.....AAAA.....AANNN...NNNN.NNEEEEEEEEE.EELL.........SSSSSSS....
			//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNNN..NNNN..UUUU...UUUU.......PPPPPPPPPP...AAAAAA....AANNN...NNNN.NNEEEEEEEEE.EELL........LSSSSSSSS...
			//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNNN..NNNN..UUUU...UUUU.......PPPPPPPPPPP..AAAAAA....AANNNN..NNNN.NNEEEEEEEEE.EELL.......LLSSSSSSSSS..
			//.MMMMMMM.MMMMMMM.EEEE........NNNNNN.NNNN..UUUU...UUUU.......PPPP...PPPP..AAAAAAA...AANNNNN.NNNN.NNEE........EELL.......LLSS...SSSS..
			//.MMMMMMM.MMMMMMM.EEEE........NNNNNN.NNNN..UUUU...UUUU.......PPPP...PPPP.AAAAAAAA...AANNNNN.NNNN.NNEE........EELL.......LLSSS........
			//.MMMMMMM.MMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU.......PPPPPPPPPPP.AAAAAAAA...AANNNNNNNNNN.NNEEEEEEEEE.EELL........LSSSSSS.....
			//.MMMMMMMMMMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU.......PPPPPPPPPP..AAAA.AAAA..AANNNNNNNNNN.NNEEEEEEEEE.EELL.........SSSSSSSS...
			//.MMMMMMMMMMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU.......PPPPPPPPP..PAAAAAAAAA..AANN.NNNNNNN.NNEEEEEEEEE.EELL...........SSSSSSS..
			//.MMMMMMMMMMMMMMM.EEEE........NNNNNNNNNNN..UUUU...UUUU.......PPPP.......PAAAAAAAAAA.AANN.NNNNNNN.NNEE........EELL..............SSSS..
			//.MMMM.MMMMM.MMMM.EEEE........NNNN.NNNNNN..UUUU...UUUU.......PPPP......PPAAAAAAAAAA.AANN..NNNNNN.NNEE........EELL.......LLSS...SSSS..
			//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN..NNNNN..UUUUUUUUUUU.......PPPP......PPAA....AAAA.AANN..NNNNNN.NNEEEEEEEEEEEELLLLLLLLLLLSSSSSSSSS..
			//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN..NNNNN...UUUUUUUUU........PPPP......PPAA....AAAAAAANN...NNNNN.NNEEEEEEEEEEEELLLLLLLLL.LSSSSSSSSS..
			//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN...NNNN....UUUUUUU.........PPPP.....PPPAA.....AAAAAANN....NNNN.NNEEEEEEEEEEEELLLLLLLLL..SSSSSSS....
			//.................................................................................................................................... */}

			{showNewHole &&
				<NewHole onClose={() => { setShowNewHole(false) }}
					holeType="CP"
					defaultValues={{
						// company_guid: project.company_guid,
						// project_guid: project_guid,
					}}
				/>
			}

			{showEmail &&
				<Email onClose={() => { setShowEmail(false) }}
					company_id={companyID}
					project_location={project_location}
					project_id={projectID}
					project_guid={project_guid}
					selected_date={selectedDate}
				/>
			}

			{showAGS &&
				<AGS onClose={() => { setShowAGS(false) }}
					project_start_date={project_start_date}
					project_id={project_id}
					company_id={companyID}
				/>
			}

			{showSaveLog &&
				<SaveLog onClose={() => { setShowSaveLog(false) }}
					boreholeName={borehole_name}
					holeType="DP"
					project_id={project_id}
					project_guid={project_guid}
					project_name={project_name}
					project_location={project_location}
					hole_type_id={starting_hole_type_id}
					driller_id={leadDrillerID}
					company_id={companyID}
					company_guid={company_guid}
					logDate={sheetDate}
					activeDates={active_dates}

					boreholeDepth={borehole_depth}
					hole_guid={hole_guid}
					endShift={false}
					holeStatus={hole_status}
					numberingType={numberingType}
				/>
			}

			{showPrintLabel &&
				<PrintLabel onClose={() => { setShowPrintLabel(false) }}
					borehole={borehole_name}
					sample={labelSample}
					depth={labelDepth}
					size='printLabel'
					sampled_by={leadDriller}
				/>
			}


			{userWarning &&
				<ModalSmall title="Log Information" toggleModal={() => { setUserWarning(false) }} confirm={false}>
					<div className="flex items-center flex-col pt-16">
						<div className="text-lg">
							This log belongs to:
						</div>
						<div className="text-3xl font-bold">
							{leadDriller}
						</div>
						<div className="text-lg mb-8">
							{randomNegPhrases}
						</div>

						<div className="flex">
							<Button theme="primary" size="lg" className="mb-16" onClick={() => { setUserWarning(false) }}>OK</Button>
						</div>
					</div>
				</ModalSmall>
			}

			{plantCheckWarning &&
				<ModalSmall title="Plant Check" toggleModal={() => { setPlantCheckWarning(false) }} confirm={false}>
					<div className="flex items-center flex-col pt-4">

						{/* <div className="bg-grey-vdark w-full py-1 mb-4">
							<img src={icon_plantcheck} className="h-12 w-12 mx-auto" alt="" />
						</div> */}
						<div className="text-lg mb-4">
							<img src={warningIcon} className="h-12 w-12 mx-auto" alt="" /> You have not performed a Plant Check for this day!
						</div>

						<div className="bg-blue w-full py-2 mb-4 text-center text-lg font-bold text-white mb-8">
							<Link to={`/daily-plant-check-list-window-sample/${company_guid}/${project_guid}`}>
								Click here for Daily Plant Check
							</Link>
						</div>

						{/* <div className="flex">
							<Button theme="primary" size="lg" className="mb-16" onClick={() => { setPlantCheckWarning(false) }}>OK</Button>
						</div> */}
					</div>
				</ModalSmall>
			}

			{/* //.................................................................................................
				//.EEEEEEEEEEE.ENNN...NNNN..NDDDDDDDD..........SSSSSSS....SHHH...HHHH.HHIII.IFFFFFFFFF.FTTTTTTTTT..
				//.EEEEEEEEEEE.ENNNN..NNNN..NDDDDDDDDD........ SSSSSSSS...SHHH...HHHH.HHIII.IFFFFFFFFF.FTTTTTTTTT..
				//.EEEEEEEEEEE.ENNNN..NNNN..NDDDDDDDDDD....... SSSSSSSSS..SHHH...HHHH.HHIII.IFFFFFFFFF.FTTTTTTTTT..
				//.EEEE........ENNNNN.NNNN..NDDD...DDDD...... SSS..SSSS..SHHH...HHHH.HHIII.IFFF..........TTTT.....
				//.EEEE........ENNNNN.NNNN..NDDD....DDDD..... SSS........SHHH...HHHH.HHIII.IFFF..........TTTT.....
				//.EEEEEEEEEE..ENNNNNNNNNN..NDDD....DDDD...... SSSSSS.....SHHHHHHHHHH.HHIII.IFFFFFFFF.....TTTT.....
				//.EEEEEEEEEE..ENNNNNNNNNN..NDDD....DDDD.......SSSSSSSSS..SHHHHHHHHHH.HHIII.IFFFFFFFF.....TTTT.....
				//.EEEEEEEEEE..ENNNNNNNNNN..NDDD....DDDD.........SSSSSSS..SHHHHHHHHHH.HHIII.IFFFFFFFF.....TTTT.....
				//.EEEE........ENNNNNNNNNN..NDDD....DDDD............SSSSS.SHHH...HHHH.HHIII.IFFF..........TTTT.....
				//.EEEE........ENNN.NNNNNN..NDDD...DDDDD..... SS....SSSS.SHHH...HHHH.HHIII.IFFF..........TTTT.....
				//.EEEEEEEEEEE.ENNN..NNNNN..NDDDDDDDDDD...... SSSSSSSSSS.SHHH...HHHH.HHIII.IFFF..........TTTT.....
				//.EEEEEEEEEEE.ENNN..NNNNN..NDDDDDDDDD........ SSSSSSSSS..SHHH...HHHH.HHIII.IFFF..........TTTT.....
				//.EEEEEEEEEEE.ENNN...NNNN..NDDDDDDDD..........SSSSSSSS...SHHH...HHHH.HHIII.IFFF..........TTTT.....
				//................................................................................................. */}
	
				{showEndShiftSaveLog &&
					<SaveLog onClose={() => { 
							setShowEndShiftSaveLog(false) 
							setShowEndShiftAGS(true) 
						}}
						boreholeName={boreholeName}
						holeType="DP"
						project_id={project_id}
						project_guid={project_guid}
						project_name={project_name}
						project_location={project_location}
						hole_type_id={starting_hole_type_id}
						driller_id={leadDrillerID}
						company_id={companyID}
						logDate={sheetDate}
						activeDates={active_dates}
	
						boreholeDepth={borehole_depth}
						hole_guid={hole_guid}
						endShift={true}
					/>
				}
	
				{showEndShiftAGS &&
					<AGS onClose={() => { 
							setShowEndShiftAGS(false)  
							setShowEndShiftEmail(true) 
						}}
						project_start_date={project_start_date}
						project_id={project_id}
						company_id={companyID}
					/>
				}
	
				{showEndShiftEmail &&
					<Email onClose={() => { 
							setShowEndShiftEmail(false) 
							setShowEndShiftLogout(true) 
						}}
						company_id={companyID}
						project_location={project_location}
						project_id={projectID}
						project_guid={project_guid}
						selected_date={selectedDate}
					/>
				}
	
				{showEndShiftLogout &&
					<ModalSmall title="Log Out" toggleModal={() => { setShowEndShiftLogout(false) }} confirm={false}>
						<div className="flex items-center flex-col pt-10">
							
							<Link to={`/logout`}>
								<div className="bg-blue rounded w-48 p-2 mb-10 text-center text-white font-bold text-lg">
									<img src={icon_endshift} className="h-12 w-12 mx-auto mb-1" alt="" />
									Log Out
								</div>
							</Link>
						</div>
					</ModalSmall>
				}











			{/* //....................................................................................
			//....OOOOOOO.....PPPPPPPPP...TTTTTTTTTTTIIII...OOOOOOO.....NNNN...NNNN...SSSSSSS.....
			//...OOOOOOOOOO...PPPPPPPPPP..TTTTTTTTTTTIIII..OOOOOOOOOO...NNNNN..NNNN..SSSSSSSSS....
			//..OOOOOOOOOOOO..PPPPPPPPPPP.TTTTTTTTTTTIIII.OOOOOOOOOOOO..NNNNN..NNNN..SSSSSSSSSS...
			//..OOOOO..OOOOO..PPPP...PPPP....TTTT...TIIII.OOOOO..OOOOO..NNNNNN.NNNN.NSSSS..SSSS...
			//.OOOOO....OOOOO.PPPP...PPPP....TTTT...TIIIIIOOOO....OOOOO.NNNNNN.NNNN.NSSSS.........
			//.OOOO......OOOO.PPPPPPPPPPP....TTTT...TIIIIIOOO......OOOO.NNNNNNNNNNN..SSSSSSS......
			//.OOOO......OOOO.PPPPPPPPPP.....TTTT...TIIIIIOOO......OOOO.NNNNNNNNNNN...SSSSSSSSS...
			//.OOOO......OOOO.PPPPPPPPP......TTTT...TIIIIIOOO......OOOO.NNNNNNNNNNN.....SSSSSSS...
			//.OOOOO....OOOOO.PPPP...........TTTT...TIIIIIOOOO....OOOOO.NNNNNNNNNNN........SSSSS..
			//..OOOOO..OOOOO..PPPP...........TTTT...TIIII.OOOOO..OOOOO..NNNN.NNNNNN.NSSS....SSSS..
			//..OOOOOOOOOOOO..PPPP...........TTTT...TIIII.OOOOOOOOOOOO..NNNN..NNNNN.NSSSSSSSSSSS..
			//...OOOOOOOOOO...PPPP...........TTTT...TIIII..OOOOOOOOOO...NNNN..NNNNN..SSSSSSSSSS...
			//.....OOOOOO.....PPPP...........TTTT...TIIII....OOOOOO.....NNNN...NNNN...SSSSSSSS....
			//.................................................................................... */}

			<Container>

				<ProjectOptionsBorehole
					onMarkAsComplete={() => { setCompletingBorehole(true) }}
					company_guid={company_guid}
					companyID={companyID}
					project_guid={project_guid}
					project_id={project_id}
					project_identifier={projectID}
					boreholeName={boreholeName}
					borehole_guid={borehole_guid}
					boreholeType="DP"
					boreholeDepth={borehole_depth}
					boreholeRequiredText=""
					// boreholeRequiredDepth={this.state.borehole.depth}
					boreholeRequiredDepth=""
					// boreholeRequiredTests={this.state.borehole.samplesTests}
					boreholeRequiredTests=""
					editWeatherLink={'/projects/weather/' + project_guid}
					rig_guid={savedRigGuid}
					driller={leadDriller}
					driller_id={leadDrillerID}
					driller_cscs={leadDrillerCSCS}
					second_man={secondMan}
					second_man_cscs={secondManCSCS}
					rig_name={plantName}
					rig_make_model={plantMakeModel}
					spt_guid={sptGUID}
					borehole_depthOverall={borehole_depthOverall}
					activity_termination_reason={activityTerminationReason}
					className="mt-1 mb-1 py-1"
					getHoleData={getHoleData}
					plantCheck={selectedDate_PlantCheck}
					holeStatus={hole_status}
					numberingType={numberingType}
				/>

				{/* {!informationCheck && (
					<div className="bg-yellow w-36 md:w-64 text-center text-xs font-bold mx-auto -mb-1 -mt-4 rounded-t">
						<Link to={`/rigs/${plantID}/${company_guid}/${project_guid}`}>
							<img src={warningIcon} width="12" className="ml-1 inline-block pb-0.5" alt="" />&nbsp;
							Rig Not Selected
						</Link>
					</div>
				)} */}


				{/* <div className="w-full py-2 bg-grey-vdark text-center mt-1 mb-0 text-sm text-white rounded-md">
					<div onClick={() => setBhMenuVisible(!bhMenuVisible)} ref={popupRef}>
						Dynamic Probe Menu <img src={menubars} className="h-3 w-3 mb-0.5 inline" alt="" />
					</div>
				</div> */}


				<div className="flex w-full py-2 bg-grey-vdark text-center mt-1 mb-0 text-sm text-white rounded-md">
					<div className="flex-none ml-0 w-6 pl-3 pt-0.5">
						<img onClick={handleReloadClick} src={icon_reload} className="ml-0 w-3.5 h-3.5" alt="" />
					</div>
					<div onClick={() => {
						setBhMenuVisible(!bhMenuVisible)
						// saveHoleData()
						}} 
						ref={popupRef} className="flex-1">
						Dynamic Probe Menu <img src={menubars} className="h-3 w-3 mb-0.5 inline" alt="" />
					</div>
					<div className="flex-none mr-0 w-6">

					</div>
				</div>

				{/* {isMobile ? <p>You are on a mobile device!</p> : <p>You are on a desktop device!</p>} */}


				<div className="relative"> {/* menu container */}

					{/* //..............................................................................
					//.PPPPPPPPP...PRRRRRRRRR.....OOOOOOO.....OBBBBBBBBB...BEEEEEEEEEE..SSSSSSS.....
					//.PPPPPPPPPP..PRRRRRRRRRR...OOOOOOOOOO...OBBBBBBBBBB..BEEEEEEEEEE.ESSSSSSSS....
					//.PPPPPPPPPPP.PRRRRRRRRRR..ROOOOOOOOOOO..OBBBBBBBBBB..BEEEEEEEEEE.ESSSSSSSSS...
					//.PPPP...PPPP.PRRR...RRRRR.ROOOO..OOOOO..OBBB...BBBB..BEEE.......EESSS..SSSS...
					//.PPPP...PPPP.PRRR...RRRRRRROOO....OOOOO.OBBB...BBBB..BEEE.......EESSS.........
					//.PPPPPPPPPPP.PRRRRRRRRRR.RROO......OOOO.OBBBBBBBBBB..BEEEEEEEEE..ESSSSSS......
					//.PPPPPPPPPP..PRRRRRRRRRR.RROO......OOOO.OBBBBBBBBB...BEEEEEEEEE...SSSSSSSSS...
					//.PPPPPPPPP...PRRRRRRR....RROO......OOOO.OBBBBBBBBBB..BEEEEEEEEE.....SSSSSSS...
					//.PPPP........PRRR.RRRR...RROOO....OOOOO.OBBB....BBBB.BEEE..............SSSSS..
					//.PPPP........PRRR..RRRR...ROOOO..OOOOO..OBBB....BBBB.BEEE.......EESS....SSSS..
					//.PPPP........PRRR..RRRRR..ROOOOOOOOOOO..OBBBBBBBBBBB.BEEEEEEEEEEEESSSSSSSSSS..
					//.PPPP........PRRR...RRRRR..OOOOOOOOOO...OBBBBBBBBBB..BEEEEEEEEEE.ESSSSSSSSS...
					//.PPPP........PRRR....RRRR....OOOOOO.....OBBBBBBBBB...BEEEEEEEEEE..SSSSSSSS....
					//.............................................................................. */}



					<div className="col-span-2 mt-4"> {/* FULL FOR DYNAMIC PROBE */}

						<PanelSingleUse title="Dynamic Probe Data"
							numberOfElements={numberOfDynamicProbes}
							// onAdd={() => setShowDynamicProbe(true)}
							onAdd={() => panel_plantCheck(setShowDynamicProbe)}
							// onEdit={() => setShowDynamicProbeEdit(true)}
							onEdit={() => panel_plantCheck(setShowDynamicProbeEdit)}
						>

							{/* {probeData} */}

							{DynamicProbeData && DynamicProbeData.length > 0 && (

								<div onClick={() => {
									// const actualIndex = DynamicProbeData.map((object: any) => object.guid).indexOf(valueDynamicProbe.guid)
									// setEditingGuid(guidOfDynamicProbe)
									setEditingIndex(0)
									setShowDynamicProbeEdit(true)
								}}
									className="grid lg:grid-cols-2 gap-3">

									<div className="lg:col-span-1 dp_container"> {/* LEFT SIDE - DATA */}

										<div className=""> {/* HEADER */}
											<div className="grid grid-cols-14 gap-0 font-bold text-sm text-center bg-grey-mid rounded-t-md">
												<div className={`col-span-2 px-0 pt-1.5 pb-1.5`}>(m)</div>
												<div className={`col-span-10 px-2 pt-1.5 pb-1.5`}>Blows</div>
												<div className={`col-span-2 px-0 pt-0.5 pb-0 `} style={{ fontSize: '0.65rem', lineHeight: '0.8rem' }}>
													Torque<br />{torqueUnit}
												</div>
											</div>
										</div>

										<React.Fragment>
											<div className="grid grid-cols-14 gap-0 text-center overflow-auto border-l border-b border-grey-mid text-xs" style={{ maxHeight: '457px' }} id='dataScroll'>
												<div className={`col-span-2 px-0 `}>
													{[...Array(30)].map((_, row) => (
														<div className={`bg-grey-mid border-t border-white py-2 text-xl font-bold`} style={{ height: '3.132rem' }} key={'rowl' + row}>
															{row++}
														</div>
													))}
												</div>

												<div className={`col-span-10 text-lg leading-5 font-bold`}>
													<div className="grid grid-cols-5 gap-0 text-center">
														{[...Array(5)].map((_, column) => (
															<div className={`col-span-1`} key={'column' + column}>
																<div className="w-full border-b border-grey-mid" style={{ 'height': (column * 5) + 'px' }}></div>
																{[...Array(30)].map((_, row) => (
																	<React.Fragment key={'row' + row}>
																		<div className={`w-full border-b border-r border-grey-mid`}>
																			<div className={`w-full py-0.5 text-center 
															${row % 2 ? 'bg-grey-light' : 'bg-white'} 
															${probeData.blows[parseInt(row + '' + column)] === -1 ? 'ownWeight_BG' : ''}
															${probeData.blows[parseInt(row + '' + column)] === -2 ? '' : ''}
														`}
																				style={{ height: '1.500rem' }}>
																				{/* {console.log('row:', row, 'column:', column, 'value:', probeData.blows[parseInt(row + '' + column)])} */}
																				{probeData.blows[parseInt(row + '' + column)] === 0
																					? 'O/W'
																					: probeData.blows[parseInt(row + '' + column)] === -1
																						? '' // Replace -1 with blank value
																						: (probeData.blows[parseInt(row + '' + column)] === -2)
																							? '' // Replace -2 with blank value
																							: probeData.blows[parseInt(row + '' + column)]
																				}
																			</div>
																		</div>

																		<div className={`w-full border-b border-r border-grey-mid`}>
																			<div className={`w-full py-0.5 text-center 
															${row % 2 ? 'bg-grey-light' : 'bg-white'} 
															${probeData.blows[parseInt(row + '' + column) + 5] === -1 ? 'ownWeight_BG' : ''}
															${probeData.blows[parseInt(row + '' + column) + 5] === -2 ? '' : ''}
															`}
																				style={{ height: '1.500rem' }}>
																				{probeData.blows[parseInt(row + '' + column) + 5] === 0
																					? 'O/W'
																					: probeData.blows[parseInt(row + '' + column) + 5] === -1
																						? '' // Replace -1 with blank value
																						: (probeData.blows[parseInt(row + '' + column) + 5] === -2)
																							? '' // Replace -2 with blank value
																							: probeData.blows[parseInt(row + '' + column) + 5]
																				}
																			</div>
																		</div>
																	</React.Fragment>
																))}
															</div>
														))}
													</div>
												</div>

												<div className={`col-span-2`}>
													<div className="w-full border-b border-grey-mid" style={{ 'height': '20px' }}></div>
													{[...Array(30)].map((_, row) => (
														<div className={`border-r border-b border-grey-mid py-2 text-2xl font-bold`} style={{ height: '3.132rem' }} key={'rowr' + row}>
															<div className={`p-0`}>
																{torqueData.torques[row] === -1 ? '' : torqueData.torques[row]}
															</div>
														</div>
													))}
												</div>
											</div>
										</React.Fragment>

									</div>

									<div className="lg:col-span-1 dp_container"> {/* RIGHT SIDE - CHART */}
										<div className=" bg-grey-mid h-8 w-full z-99 rounded-t-md"> {/* dp_chart_main_container */}

											<div className="text-left text-xs pl-9 sm:pl-16 lg:pl-10 font-bold">Blows</div>
											<div className="grid grid-cols-11 py-1 text-right text-charcoal text-xs -mt-2">
												<div className="col-span-1"><span className="font-bold pr-0 sm:pr-4 lg:pr-3">(m)</span> 0</div>
												<div className="col-span-1">5</div>
												<div className="col-span-1">10</div>
												<div className="col-span-1">15</div>
												<div className="col-span-1">20</div>
												<div className="col-span-1">25</div>
												<div className="col-span-1">30</div>
												<div className="col-span-1">35</div>
												<div className="col-span-1">40</div>
												<div className="col-span-1">45</div>
												<div className="col-span-1 pr-1">50</div>
											</div>



											<div className="grid grid-cols-11 text-right text-charcoal text-xs overflow-scroll bg-white"
												id='chartScroll'
												style={{ maxHeight: '457px' }}
											>
												<div className="col-span-1 w-full bgFullWidth dpchartSpacer">
													{[...Array(30)].map((_, row) => {
														return <div className={` bg-grey-mid border-white border-t border-l border-r modal-bg py-3 text-lg font-bold text-center`}
															style={{ height: '3.132rem' }}
															key={'rowl' + row}>{row++}</div>
													})}
												</div>

												<div className="col-span-10 w-full border-grey-mid border-t dpchartSpacer"
													style={{ 'background': `url(${dp_chart_bg})`, 'backgroundSize': '100%' }}
												>

													{probeData.blows.map((cellValue, index) => {
														// Perform your width calculation for each cellValue here
														let width = 0;
														if (!isNaN(cellValue) && cellValue !== -1 && cellValue !== -2) {
															width = (cellValue / 50) * 100;
														} else {
															width = 0;
														}

														return (
															<div
																className="bg-charcoal"
																style={{ width: `${width}%`, height: '0.25rem', marginBottom: '1px' }}
																key={`row${index}_cell${index}`}
															></div>
														);
													})}

												</div>
											</div>
										</div>
									</div>
								</div>

							)}

						</PanelSingleUse>

					</div>










					<div className="grid lg:grid-cols-5 lg:gap-6 mt-0">
						<div className="col-span-5 lg:col-span-3 mt-0">



							{/* LEFT SIDE PANELS GO HERE */}

















							{/*//.......................................................
							 //...SSSSSSS.......AAAAA....AMMMMM...MMMMMM.PPPPPPPPP....
							//..SSSSSSSSS......AAAAA....AMMMMM...MMMMMM.PPPPPPPPPP...
							//..SSSSSSSSSS....AAAAAA....AMMMMM...MMMMMM.PPPPPPPPPPP..
							//.SSSSS..SSSS....AAAAAAA...AMMMMMM.MMMMMMM.PPPP...PPPP..
							//.SSSSS.........AAAAAAAA...AMMMMMM.MMMMMMM.PPPP...PPPP..
							//..SSSSSSS......AAAAAAAA...AMMMMMM.MMMMMMM.PPPPPPPPPPP..
							//...SSSSSSSSS...AAAA.AAAA..AMMMMMMMMMMMMMM.PPPPPPPPPP...
							//.....SSSSSSS..AAAAAAAAAA..AMMMMMMMMMMMMMM.PPPPPPPPP....
							//........SSSSS.AAAAAAAAAAA.AMMMMMMMMMMMMMM.PPPP.........
							//.SSSS....SSSS.AAAAAAAAAAA.AMMM.MMMMM.MMMM.PPPP.........
							//.SSSSSSSSSSSSSAAA....AAAA.AMMM.MMMMM.MMMM.PPPP.........
							//..SSSSSSSSSS.SAAA.....AAAAAMMM.MMMMM.MMMM.PPPP.........
							//...SSSSSSSS.SSAAA.....AAAAAMMM.MMMMM.MMMM.PPPP.........
							//....................................................... */}

							<Panel title="Samples" numberOfElements={numberOfSamples} options={[
								{
									name: 'D',
									// onClick: () => { setShowD(true) }
									onClick: () => panel_plantCheck(setShowD)
								}, {
									name: 'B',
									// onClick: () => { setShowB(true) }
									onClick: () => panel_plantCheck(setShowB)
								}, {
									name: 'ES',
									// onClick: () => { setShowES(true) }
									onClick: () => panel_plantCheck(setShowES)
								}, {
									name: 'WS',
									// onClick: () => { setShowWS(true) }
									onClick: () => panel_plantCheck(setShowWS)
								}

							]}>
								<table className="table-auto w-full text-xs sm:text-sm text-center border-collapse overflow-hidden rounded-t-md">
									<thead className="font-bold">
										<tr>
											<td className={`px-1 sm:px-1 py-0 bg-grey-mid border-grey-mid rounded-tl-sm rounded-tr-sm border-l border-r border-grey-light`}>Type</td>
											<td className={`px-1 sm:px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>From (m)</td>
											<td className={`px-1 sm:px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>To (m)</td>
											<td className={`px-1 sm:px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>Casing</td>
											<td className={`px-1 sm:px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>Water (m)</td>
											<td className={`px-1 sm:px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light text-left`}>Notes</td>
											<td className={`px-1 sm:px-1 py-0 bg-grey-mid border-grey-mid rounded-tr-sm border-r border-grey-light`}></td>
										</tr>
									</thead>
									<tbody>

										{SamplesData?.filter((action: any) => {
											return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
										}).map((valueSamples: any, index: number) => {

											// Replace depth_to_top = 0 with GL
											let depth_to_top_temp, depth_to_base_temp, casing_depth_temp, water_depth_temp
											if (valueSamples.depth_to_top !== undefined && typeof valueSamples.depth_to_top === 'string' && valueSamples.depth_to_top === '0.00' || valueSamples.depth_to_top === '0') {
												depth_to_top_temp = 'GL'
											} else if (valueSamples.depth_to_top !== undefined && typeof valueSamples.depth_to_top === 'number' && valueSamples.depth_to_top === 0.00 || valueSamples.depth_to_top === 0) {
												depth_to_top_temp = 'GL'
											} else {
												depth_to_top_temp = valueSamples.depth_to_top
											}

											//Get actualIndex
											const actualIndex = SamplesData.map((object: any) => object.guid).indexOf(valueSamples.guid)

											return (
												<React.Fragment key={index}>

													<tr className="border border-grey-mid bg-white mb-1">
														<td className={`px-1 sm:px-1 pt-0.5 border-r border-grey-mid font-bold`}>{valueSamples.type}-{valueSamples.type_number}</td>
														<td className={`px-1 sm:px-1 pt-0.5 border-r border-grey-mid`}>{depth_to_top_temp}</td>
														<td className={`px-1 sm:px-1 pt-0.5 border-r border-grey-mid`}>{valueSamples.depth_to_base}</td>
														<td className={`px-1 sm:px-1 pt-0.5 border-r border-grey-mid`}>{valueSamples.casing_depth}</td>
														<td className={`px-1 sm:px-1 pt-0.5 border-r border-grey-mid`}>{valueSamples.water_depth}</td>
														<td className={`px-1 sm:px-1 pt-0.5 border-r border-grey-mid text-left`}>{valueSamples.general_remarks}</td>
														<td className={`px-1 sm:px-1 py-0 border-r border-grey-mid w-8 md:w-12`}>
															<div className={"text-center px-1 sm:px-0 pb-0.5 edit-background"}
																	onClick={() => {
																		setEditingGuid(valueSamples.guid)
																		setEditingIndex(actualIndex)

																		switch (valueSamples.type) {
																			case 'D':
																				setShowDEdit(true)
																				break
																			case 'B':
																				setShowBEdit(true)
																				break
																			case 'WS':
																				setShowWSEdit(true)
																				break
																			case 'ES':
																				setShowESEdit(true)
																				break
																		}
																	}}>
																<img className="inline z-10" width={"16"} src={edit} alt="" />
															</div>
														</td>
													</tr>
												</React.Fragment>
											)
										})}

									</tbody>
								</table>
							</Panel>














							{/* //....................................................
							//.TTTTTTTTTTTRRRRRRRRRR...EEEEEEEEEEEEMMMMM...MMMMM..
							//.TTTTTTTTTTTRRRRRRRRRRR..EEEEEEEEEEEEMMMMM...MMMMM..
							//.TTTTTTTTTTTRRRRRRRRRRR..EEEEEEEEEEEEMMMMM...MMMMM..
							//....TTTT....RRRR...RRRRR.EEEE.......EMMMMMM.MMMMMM..
							//....TTTT....RRRR...RRRRR.EEEE.......EMMMMMM.MMMMMM..
							//....TTTT....RRRRRRRRRRR..EEEEEEEEEE.EMMMMMM.MMMMMM..
							//....TTTT....RRRRRRRRRRR..EEEEEEEEEE.EMMMMMMMMMMMMM..
							//....TTTT....RRRRRRRR.....EEEEEEEEEE.EMMMMMMMMMMMMM..
							//....TTTT....RRRR.RRRR....EEEE.......EMMMMMMMMMMMMM..
							//....TTTT....RRRR..RRRR...EEEE.......EMMM.MMMMM.MMM..
							//....TTTT....RRRR..RRRRR..EEEEEEEEEEEEMMM.MMMMM.MMM..
							//....TTTT....RRRR...RRRRR.EEEEEEEEEEEEMMM.MMMMM.MMM..
							//....TTTT....RRRR....RRRR.EEEEEEEEEEEEMMM.MMMMM.MMM..
							//.................................................... */}

							<Panel title="Comments / Standing Time / Day Works" numberOfElements={numberOfComments} 
							// onAdd={() => setShowComments(true)}
							onAdd={() => panel_plantCheck(setShowComments)}
							>
								<table className="table-auto w-full text-xs sm:text-sm text-center border-collapse overflow-hidden rounded-t-md">
									<thead className="font-bold">
										<tr>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid rounded-tl-sm rounded-tr-sm border-l  border-r border-grey-light `}>Type</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>Time From</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>Duration</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>Comment</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}></td>
										</tr>
									</thead>
									<tbody>

										{CommentsData?.filter((action: any) => {
											return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
										}).map((valueComment: any, index: number) => {

											//Get actualIndex
											const actualIndex = CommentsData.map((object: any) => object.guid).indexOf(valueComment.guid)
											// console.log('typeof actualIndex: ', actualIndex, typeof actualIndex)
											console.log('CommentsData:', CommentsData)

											return (
												<React.Fragment key={index}>
													<tr className="border border-grey-mid bg-white mb-1">
														<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{valueComment.comment_type}</td>
														<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{valueComment.start_time}</td>
														<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{valueComment.duration}</td>
														<td className={`px-1 pt-0.5 border-r border-grey-mid text-left`}>{valueComment.general_remarks}</td>
														<td className={`px-1 py-0 border-r border-grey-mid w-8 md:w-12`}>
															<div className={"text-center px-0 pb-0.5 edit-background"}
																	onClick={() => {
																		const actualIndex = CommentsData.map((object: any) => object.guid).indexOf(valueComment.guid)
																		setEditingGuid(valueComment.guid)
																		setEditingIndex(actualIndex)
																		setShowCommentsEdit(true)
																	}}
																	>
																<img className="inline z-10" width={"16"} src={edit} alt="" />
															</div>
														</td>
													</tr>
												</React.Fragment>
											)
										})}
									</tbody>
								</table>

							</Panel>







						</div>  {/* END OF LEFT SIDE */}

						<div className="col-span-5 lg:col-span-2 mt-0 md:mt-0"> {/* START OF RIGHT SIDE */}

							{/* RIGHT SIDE PANELS GO HERE */}








							{/* //..............................................................................
							//.IIIII.NNNN...NNNN...SSSSSSS....PPPPPPPPP........PPPPPPPPP..PIIII.TTTTTTTTTT..
							//.IIIII.NNNNN..NNNN..SSSSSSSSS...PPPPPPPPPP.......PPPPPPPPPP.PIIII.TTTTTTTTTT..
							//.IIIII.NNNNN..NNNN..SSSSSSSSSS..PPPPPPPPPPP......PPPPPPPPPPPPIIII.TTTTTTTTTT..
							//.IIIII.NNNNNN.NNNN.SSSSS..SSSS..PPPP...PPPP......PPPP...PPPPPIIII....TTTT.....
							//.IIIII.NNNNNN.NNNN.SSSSS........PPPP...PPPP......PPPP...PPPPPIIII....TTTT.....
							//.IIIII.NNNNNNNNNNN..SSSSSSS.....PPPPPPPPPPP......PPPPPPPPPPPPIIII....TTTT.....
							//.IIIII.NNNNNNNNNNN...SSSSSSSSS..PPPPPPPPPP.......PPPPPPPPPP.PIIII....TTTT.....
							//.IIIII.NNNNNNNNNNN.....SSSSSSS..PPPPPPPPP........PPPPPPPPP..PIIII....TTTT.....
							//.IIIII.NNNNNNNNNNN........SSSSS.PPPP.............PPPP.......PIIII....TTTT.....
							//.IIIII.NNNN.NNNNNN.SSSS....SSSS.PPPP.............PPPP.......PIIII....TTTT.....
							//.IIIII.NNNN..NNNNN.SSSSSSSSSSSS.PPPP.............PPPP.......PIIII....TTTT.....
							//.IIIII.NNNN..NNNNN..SSSSSSSSSS..PPPP.............PPPP.......PIIII....TTTT.....
							//.IIIII.NNNN...NNNN...SSSSSSSS...PPPP.............PPPP.......PIIII....TTTT.....
							//.............................................................................. */}


							<PanelSingleUse title="Inspection Pit"
								numberOfElements={numberOfInspectionPits}
								// onAdd={() => setShowInspectionPit(true)}
								onAdd={() => panel_plantCheck(setShowInspectionPit)}
								// onEdit={() => setShowInspectionPitEdit(true)}
								onEdit={() => panel_plantCheck(setShowInspectionPitEdit)}
							>
								<table className="table-auto w-full text-xs sm:text-sm text-center border-collapse overflow-hidden rounded-t-md">
									<thead className="font-bold">
										<tr>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid rounded-tl-sm rounded-tr-sm border-l  border-r border-grey-light`}>Depth From (m)</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>Depth To (m)</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>Time Taken (hrs)</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}></td>
										</tr>
									</thead>
									<tbody>

										{InspectionPitData?.filter((action: any) => {
											return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
										}).map((valueInspectionPit: any, index: number) => {

											// Replace depth_to_top = 0 with GL
											let depth_to_top_temp
											if (valueInspectionPit.depth_to_top !== undefined && valueInspectionPit.depth_to_top === '0.00' || valueInspectionPit.depth_to_top === '0') {
												depth_to_top_temp = 'GL'
											} else {
												depth_to_top_temp = valueInspectionPit.depth_to_top
											}

											//Get actualIndex
											const actualIndex = InspectionPitData.map((object: any) => object.guid).indexOf(valueInspectionPit.guid)
											// console.log('typeof actualIndex: ', actualIndex, typeof actualIndex)

											// Time
											const timeString = valueInspectionPit.duration
											let formattedTime = '' //  timeString.slice(0, -3); // Removes the last 3 characters
											if (timeString.length === 8) {
												formattedTime = timeString.slice(0, -3); // Removes the last 3 characters
											} else {
												formattedTime = timeString
											}

											return (
												<React.Fragment key={index}>
													<tr className="border border-grey-mid bg-white mb-1">
														<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{depth_to_top_temp}</td>
														<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{valueInspectionPit.depth_to_base}</td>
														<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{formattedTime}</td>
														<td className={`px-1 py-0 border-r border-grey-mid w-8 md:w-12`}>
															<div className={"text-center px-0 pb-0.5 edit-background"}
																	onClick={() => {
																		// const actualIndex = InspectionPitData.map((object: any) => object.guid).indexOf(valueInspectionPit.guid)
																		setEditingGuid(valueInspectionPit.guid)
																		setEditingIndex(0)
																		setShowInspectionPitEdit(true)
																	}}
																	>
																<img className="inline z-10" width={"16"} src={edit} alt="" />
															</div>
														</td>
													</tr>
												</React.Fragment>
											)
										})}

									</tbody>
								</table>

							</PanelSingleUse>














							{/* //.........................................................................
							//....CCCCCCC.......AAAAA......SSSSSSS...SIIII.NNNN...NNNN.....GGGGGGG.....
							//...CCCCCCCCC......AAAAA.....SSSSSSSSS..SIIII.NNNNN..NNNN...GGGGGGGGGG....
							//..CCCCCCCCCCC....AAAAAA.....SSSSSSSSSS.SIIII.NNNNN..NNNN..GGGGGGGGGGGG...
							//..CCCC...CCCCC...AAAAAAA...SSSSS..SSSS.SIIII.NNNNNN.NNNN..GGGGG..GGGGG...
							//.CCCC.....CCC...AAAAAAAA...SSSSS.......SIIII.NNNNNN.NNNN.NGGGG....GGG....
							//.CCCC...........AAAAAAAA....SSSSSSS....SIIII.NNNNNNNNNNN.NGGG............
							//.CCCC...........AAAA.AAAA....SSSSSSSSS.SIIII.NNNNNNNNNNN.NGGG..GGGGGGGG..
							//.CCCC..........AAAAAAAAAA......SSSSSSS.SIIII.NNNNNNNNNNN.NGGG..GGGGGGGG..
							//.CCCC.....CCC..AAAAAAAAAAA........SSSSSSIIII.NNNNNNNNNNN.NGGGG.GGGGGGGG..
							//..CCCC...CCCCC.AAAAAAAAAAA.SSSS....SSSSSIIII.NNNN.NNNNNN..GGGGG....GGGG..
							//..CCCCCCCCCCC.AAAA....AAAA.SSSSSSSSSSSSSIIII.NNNN..NNNNN..GGGGGGGGGGGG...
							//...CCCCCCCCCC.AAAA.....AAAA.SSSSSSSSSS.SIIII.NNNN..NNNNN...GGGGGGGGGG....
							//....CCCCCCC..CAAAA.....AAAA..SSSSSSSS..SIIII.NNNN...NNNN.....GGGGGGG.....
							//......................................................................... */}

							<Panel title="Casing Record" numberOfElements={numberOfCasingRecords} 
							// onAdd={() => setShowCasingRecord(true)}
							onAdd={() => panel_plantCheck(setShowCasingRecord)}
							>
								<table className="table-auto w-full text-xs sm:text-sm text-center border-collapse overflow-hidden rounded-t-md">
									<thead className="font-bold">
										<tr>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid rounded-tl-sm rounded-tr-sm border-l  border-r border-grey-light`}>Casing Size (mm)</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>Depth To (m)</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>BH Depth (m)</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}></td>
										</tr>
									</thead>
									<tbody>


										{CasingData?.filter((action: any) => {
											return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
										}).map((valueCasing: any, index: number) => {

											//Get actualIndex
											const actualIndex = CasingData.map((object: any) => object.guid).indexOf(valueCasing.guid)
											console.log('typeof actualIndex: ', actualIndex, typeof actualIndex)

											return (
												<React.Fragment key={index}>
													<tr className="border border-grey-mid bg-white mb-1">
														<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{valueCasing.casing_diameter}</td>
														<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{valueCasing.depth_to_base}</td>
														<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{valueCasing.depth_of_borehole}</td>
														<td className={`px-1 py-0 border-r border-grey-mid w-8 md:w-12`}>
															<div className={"text-center px-0 pb-0.5 edit-background"}
																	onClick={() => {
																		setEditingGuid(valueCasing.guid)
																		setEditingIndex(actualIndex)
																		setShowCasingRecordEdit(true)
																	}}
																	>
																<img className="inline z-10" width={"16"} src={edit} alt="" />
															</div>
														</td>
													</tr>
												</React.Fragment>
											)
										}
										)}



									</tbody>
								</table>

							</Panel>





						</div>  {/* END OF RIGHT SIDE */}

					</div>














					{/* //.................................................................................................................
					//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNN...NNNN..UUUU...UUUU.......BBBBBBBBBB.....OOOOOOO.....DDDDDDDDD...YYYY....YYYY..
					//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNNN..NNNN..UUUU...UUUU.......BBBBBBBBBBB...OOOOOOOOOO...DDDDDDDDDD..YYYYY..YYYYY..
					//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNNN..NNNN..UUUU...UUUU.......BBBBBBBBBBB..OOOOOOOOOOOO..DDDDDDDDDDD..YYYY..YYYY...
					//.MMMMMMM.MMMMMMM.EEEE........NNNNNN.NNNN..UUUU...UUUU.......BBBB...BBBB..OOOOO..OOOOO..DDDD...DDDD..YYYYYYYYY....
					//.MMMMMMM.MMMMMMM.EEEE........NNNNNN.NNNN..UUUU...UUUU.......BBBB...BBBB.OOOOO....OOOOO.DDDD....DDDD..YYYYYYYY....
					//.MMMMMMM.MMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU.......BBBBBBBBBBB.OOOO......OOOO.DDDD....DDDD...YYYYYY.....
					//.MMMMMMMMMMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU.......BBBBBBBBBB..OOOO......OOOO.DDDD....DDDD...YYYYYY.....
					//.MMMMMMMMMMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU.......BBBBBBBBBBB.OOOO......OOOO.DDDD....DDDD....YYYY......
					//.MMMMMMMMMMMMMMM.EEEE........NNNNNNNNNNN..UUUU...UUUU.......BBBB....BBBBOOOOO....OOOOO.DDDD....DDDD....YYYY......
					//.MMMM.MMMMM.MMMM.EEEE........NNNN.NNNNNN..UUUU...UUUU.......BBBB....BBBB.OOOOO..OOOOO..DDDD...DDDDD....YYYY......
					//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN..NNNNN..UUUUUUUUUUU.......BBBBBBBBBBBB.OOOOOOOOOOOO..DDDDDDDDDDD.....YYYY......
					//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN..NNNNN...UUUUUUUUU........BBBBBBBBBBB...OOOOOOOOOO...DDDDDDDDDD......YYYY......
					//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN...NNNN....UUUUUUU.........BBBBBBBBBB......OOOOOO.....DDDDDDDDD.......YYYY......
					//................................................................................................................. */}

					{bhMenuVisible &&

						<div className={`menu-container ${bhMenuVisible ? 'active' : ''}`}>

							<div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 sm:gap-3 md:gap-4 bg-grey-vdark w-full absolute z-50 top-0 left-0 -mt-5 text-center text-white text-xs md:text-sm pt-2 px-2 md:px-3 lg:px-4 pb-2 md:pb-3 lg:pb-4 rounded-b-lg modalshadow">

								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<Link to={`/projects/update/${project_guid}`}>
										<img src={icon_project} className="h-12 w-12 mx-auto mb-1" alt="" />
										Edit Project
									</Link>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<div onClick={menuAndAGS}>
										<img src={icon_ags} className="h-12 w-12 mx-auto mb-1" alt="" />
										Output AGS
									</div>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<div onClick={menuAndEmail}>
										<img src={icon_email} className="h-12 w-12 mx-auto mb-1" alt="" />
										Send Email
									</div>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<Link to={`/rigs/${company_guid}/${project_guid}`}>
										<img src={icon_editrig} className="h-12 w-12 mx-auto mb-1" alt="" />
										Rigs
									</Link>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									{/* <Link to={`/daily-plant-check-list-window-sample/${plantID}/${company_guid}/${project_guid}`}> */}
									<Link to={`/daily-plant-check-list-window-sample/${company_guid}/${project_guid}`}>
										<img src={icon_plantcheck} className="h-12 w-12 mx-auto mb-1" alt="" />
										Daily Plant Check
									</Link>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<button className="items-center justify-center px-2 bold mx-auto" onClick={menuAndLabels} >
										<img src={icon_labels} className="w-12 mx-auto" alt="" />
										View Labels
									</button>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<button className="items-center justify-center px-2 bold mx-auto" onClick={menuAndPreview} >
										<img src={icon_preview} className="h-12 w-12 mx-auto mb-1" alt="" />
										Preview Log
									</button>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<div onClick={menuAndSaveLog}>
										<img src={icon_savelog} className="h-12 w-12 mx-auto mb-1" alt="" />
										Save Log
									</div>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									{/* <Link to={`/#`} onClick={this.bhMenuClick}> */}
									{/* <Link to={`#`} onClick={menuAndMarkAsComplete}>
										<img src={icon_complete} className="h-12 w-12 mx-auto mb-1" alt="" />
										Mark As Complete
									</Link> */}
									<div onClick={menuAndEndShift}>
										<img src={icon_endshift} className="h-12 w-12 mx-auto mb-1" alt="" />
										End Shift
									</div>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<Link to={`/newhole/DP/${project_guid}`}>
										<img src={icon_newborehole} className="h-12 w-12 mx-auto mb-1" alt="" />
										New Probe
									</Link>
								</div>

								<div className="col-span-3 md:col-span-4 lg:col-span-5">
									<div className="grid grid-cols-2 gap-2 sm:gap-3 md:gap-4 ">
										<div className="border border-grey-dark rounded py-2 text-lg">
											<Link to={`/projects/dashboard/company/${company_guid}/${project_guid}/dynamic-probe`}>
												Probe Select
											</Link>
										</div>
										<div className="border border-grey-dark rounded py-2 text-lg">
											<Link to={`/projects/dashboard/${project_guid}/company/${company_guid}`}>
												Change Activity
											</Link>
										</div>
									</div>
								</div>

							</div>

						</div>

					}

				</div> {/* end of menu container */}














				{/* //.....................................................................................
				//.PPPPPPPPP...PRRRRRRRRR...REEEEEEEEEEEEVV....VVVVVVIII.IEEEEEEEEEEEEWW..WWWWW...WWW..
				//.PPPPPPPPPP..PRRRRRRRRRR..REEEEEEEEEEEEVV....VVVVVVIII.IEEEEEEEEEEEEWW..WWWWW..WWWW..
				//.PPPPPPPPPPP.PRRRRRRRRRR..REEEEEEEEEEEEVV....VVVVVVIII.IEEEEEEEEEEEEWW..WWWWWW.WWWW..
				//.PPPP...PPPP.PRRR...RRRRR.REEE.......EEVVV..VVVV.VVIII.IEEE.......EEWW.WWWWWWW.WWWW..
				//.PPPP...PPPP.PRRR...RRRRR.REEE........EVVV..VVVV.VVIII.IEEE.......EEWW.WWWWWWW.WWWW..
				//.PPPPPPPPPPP.PRRRRRRRRRR..REEEEEEEEE..EVVV..VVVV.VVIII.IEEEEEEEEE..EWWWWWWWWWW.WWW...
				//.PPPPPPPPPP..PRRRRRRRRRR..REEEEEEEEE..EVVVVVVVV..VVIII.IEEEEEEEEE..EWWWWWW.WWWWWWW...
				//.PPPPPPPPP...PRRRRRRR.....REEEEEEEEE...VVVVVVVV..VVIII.IEEEEEEEEE..EWWWWWW.WWWWWWW...
				//.PPPP........PRRR.RRRR....REEE.........VVVVVVVV..VVIII.IEEE........EWWWWWW.WWWWWWW...
				//.PPPP........PRRR..RRRR...REEE.........VVVVVVV...VVIII.IEEE........EWWWWWW.WWWWWWW...
				//.PPPP........PRRR..RRRRR..REEEEEEEEEE...VVVVVV...VVIII.IEEEEEEEEEE..WWWWW...WWWWW....
				//.PPPP........PRRR...RRRRR.REEEEEEEEEE...VVVVVV...VVIII.IEEEEEEEEEE..WWWWW...WWWWW....
				//.PPPP........PRRR....RRRR.REEEEEEEEEE...VVVVV....VVIII.IEEEEEEEEEE..WWWWW...WWWWW....
				//..................................................................................... */}

				{previewVisible &&

					<>

						{/* LOGSHEET */}

						<div className="flex flex-col rounded-lg border border-orange bg-modal-bg px-2 sm:px-2 md:px-2 pb-8 modalshadow previewLogsheet z-50" style={{ display: previewVisible ? 'block' : 'none' }}>

							<div className="flex items-center border-b border-grey-dark py-2">

								<div className="flex-1 flex items-left">

								</div>

								<div className="flex-1 flex justify-center text-lg sm:text-xl font-bold text-text leading-6 text-center mx-auto">
									Preview Logsheet
								</div>

								<div className="flex-1 flex justify-center">
									<span className="ml-auto">
										<button className="flex items-center justify-center w-8 h-8 text-4xl bold text-soil-grey bg-white border border-orange rounded" onClick={previewClick} ><img src={closeIcon} width="32" alt="" /></button>
									</span>
								</div>
							</div>






							<div className="w-full overflow-scroll">

								<div className="logsheetContainer mx-auto pb-6">

									<div className="mainContainer">

										<table className="w-full">
											<tbody>
												{/* HEADER TABLE */}
												<tr>
													<td colSpan={2}>
														<div className="headerTableHolder border border-grey-mid">
															<table className="noTableBorder text-center w-full">
																<tbody>
																	<tr>
																		<td rowSpan={3} className="headerLeft">{/* LOGO */}<img className="mt-1" src={companyLogoWithCacheBusting} /></td>
																		<td colSpan={4} className="headerTitle">Daily Dynamic Probe Record</td>
																		<td rowSpan={2} colSpan={2} className="headerRight headerBarcodeHolder pt-3 pl-5 text-center">
																			{/* <svg id="barcode" className="barcodeCSS"></svg> */}
																			<Barcode value={labelbarcodeVar}
																				options={{
																					format: 'code128',
																					//width: 2,
																					width: barcodeWidth,
																					height: 60,
																					fontSize: 12,
																					//marginLeft: 80,
																					//text: barcodeVar
																					//background: "#ccffff"
																				}} />
																		</td>
																	</tr>
																	<tr>
																		<td className="headerCellTitleLeft bg-sptTB">Location:</td>
																		<td className="headerCellInputLeft">{projectLocation}</td>
																		<td className="headerCellTitleRight bg-sptTB">Client:</td>
																		<td className="headerCellInputRight">{client_name}</td>
																	</tr>
																	<tr>
																		<td className="headerCellTitleLeft bg-sptTB">Project ID:</td>
																		<td id="projectID" className="headerCellInputLeft">{projectID}</td>
																		<td className="headerCellTitleRight bg-sptTB">DP ID:</td>
																		<td id="boreholeID" className="headerCellInputRight">{borehole_name}</td>
																		<td id="headerDate">{sheetDate}</td>
																		<td id="headerSheets">Sheet 1 of 2</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</td>
												</tr>
												{/* END OF HEADER TABLE */}
												{/* CENTER STUFF */}
												<tr>
													<td colSpan={2} className="w-full">
														<div className="grid grid-cols-12">
															<div className="col-span-7">   {/* LEFT SIDE - DATA */}
																<div className=""> {/* HEADER */}
																	<div className="grid grid-cols-14 gap-0 font-bold text-xs text-center bg-grey-mid">
																		<div className={`col-span-2 px-0 pt-1.5 pb-1.5`}>(m)</div>
																		<div className={`col-span-10 px-2 pt-1.5 pb-1.5`}>Blows</div>
																		<div className={`col-span-2 px-0 pt-2 pb-1.5 `} style={{ fontSize: '0.5rem', lineHeight: '0.55rem' }}>
																			Torque<br />UNIT
																		</div>
																	</div>
																</div>
																<div>  {/* DATA */}
																	<div className="grid grid-cols-14 gap-0 text-center overflow-auto border-l border-b border-grey-mid text-sm">
																		<div className={`col-span-2 px-0 `}>
																			{[...Array(15)].map((_, row) => {
																				return <div className={`h-10 border-r border-b border-grey-mid py-2 text-lg font-bold`} style={{ height: '2.6255rem' }}
																					key={'rowl' + row}>{row++}</div>
																			})}
																		</div>
																		<div className={`col-span-10`}>
																			<div className="grid grid-cols-5 gap-0 text-center">
																				{[...Array(5)].map((_, column) =>
																					<div className={`col-span-1`} key={'column' + column}>
																						<div className="w-full border-b border-grey-mid" style={{ 'height': (column * 5) + 'px' }}></div>
																						{[...Array(15)].map((_, row) =>
																							<React.Fragment key={'row' + row}>
																								<div className={`w-full border-b border-r border-grey-mid`}>

																									<div className={`w-full text-center h-5 
																									${row % 2 ? 'bg-grey-light' : 'bg-white'} 
																									${probeData.blows[parseInt(row + '' + column)] === -1 ? 'ownWeight_BG' : ''}
																									${probeData.blows[parseInt(row + '' + column)] === -2 ? '' : ''}
																								`}
																										style={{ height: '1.25rem' }}>
																										{probeData.blows[parseInt(row + '' + column)] === 0
																											? 'O/W'
																											: probeData.blows[parseInt(row + '' + column)] === -1
																												? '' // Replace -1 with blank value
																												: probeData.blows[parseInt(row + '' + column)] === -2
																													? '' // Replace -2 with blank value
																													: probeData.blows[parseInt(row + '' + column)]
																										}
																									</div>

																								</div>
																								<div className={`w-full border-b border-r border-grey-mid`}>

																									<div className={`w-full text-center h-5 
																									${row % 2 ? 'bg-grey-light' : 'bg-white'} 
																									${probeData.blows[parseInt(row + '' + column) + 5] === -1 ? 'ownWeight_BG' : ''}
																									${probeData.blows[parseInt(row + '' + column) + 5] === -2 ? '' : ''}
																								`}
																										style={{ height: '1.25rem' }}>
																										{
																											probeData.blows[parseInt(row + '' + column) + 5] === 0
																												? 'O/W'
																												: probeData.blows[parseInt(row + '' + column) + 5] === -1
																													? '' // Replace -1 with blank value
																													: probeData.blows[parseInt(row + '' + column) + 5] === -2
																														? '' // Replace -2 with blank value
																														: probeData.blows[parseInt(row + '' + column) + 5]
																										}
																									</div>

																								</div>
																							</React.Fragment>
																						)}
																					</div>
																				)}
																			</div>
																		</div>
																		<div className={`col-span-2`}>
																			<div className="w-full border-b border-grey-mid" style={{ 'height': '20px' }}></div>

																			{[...Array(15)].map((_, row) => {
																				return <div className={`h-10 border-r border-b border-grey-mid py-2 text-lg font-bold`} style={{ height: '2.6255rem' }}
																					key={'rowr' + row}
																				>
																					<div className={`p-0`}>
																						{torqueData.torques[row] === -1 ? '' : torqueData.torques[row]}{/* {torques[row]} */}
																					</div>
																				</div>
																			})}
																		</div>
																	</div>
																</div>
															</div>    {/* END OF LEFT SIDE - DATA */}



															{/* <div className="col-span-12 lg:col-span-5 mb-2 bg-grey-mid invisible md:visible"  style={{ maxHeight: '493px' }}>   RIGHT SIDE - GRAPH */}
															<div className="col-span-5 bg-grey-mid ">   {/* RIGHT SIDE - GRAPH */}

																<div className="h-10 w-full z-99">

																	<div className="text-left text-xs pl-10 font-bold">Blows</div>
																	<div className="grid grid-cols-10 py-1 text-right text-charcoal text-xs -mt-2">
																		{/* <div className="col-span-1"><span className="font-bold pr-1 md:pr-4 lg:pr-1">(m)</span>0</div> */}
																		<div className="col-span-1">5</div>
																		<div className="col-span-1">10</div>
																		<div className="col-span-1">15</div>
																		<div className="col-span-1">20</div>
																		<div className="col-span-1">25</div>
																		<div className="col-span-1">30</div>
																		<div className="col-span-1">35</div>
																		<div className="col-span-1">40</div>
																		<div className="col-span-1">45</div>
																		<div className="col-span-1 pr-1">50</div>
																	</div>

																	<div className="grid grid-cols-10 text-right text-charcoal text-xs bg-white">

																		<div className="col-span-10 w-full bgFullWidth overflow-x-hidden h-full border-t border-grey-mid"
																			style={{ 'background': `url(${dp_chart_bg})`, marginTop: '19px', height: '629px' }}>

																			{probeData.blows.map((cellValue, index) => {
																				// Perform your width calculation for each cellValue here
																				let width = 0;
																				if (!isNaN(cellValue) && cellValue !== -1 && cellValue !== -2) {
																					width = (cellValue / 50) * 100;
																				} else {
																					width = 0;
																				}

																				return (
																					<div
																						className="bg-charcoal"
																						style={{ width: `${width}%`, height: '0.2rem', marginBottom: '1px' }}
																						key={`row${index}_cell${index}`}
																					></div>
																				);
																			})}

																		</div>

																	</div>

																</div>

															</div>    {/* END OF RIGHT SIDE - GRAPH */}

														</div>



													</td>
												</tr>
												<tr>
													<td className="w-2/4 align-top border-r border-grey-mid"> {/* LEFT SIDE */}



														<table className="border-b border-grey-mid">
															<tbody>
																<tr>
																	<td colSpan={2} className="tableRow bg-grey-mid sectionTitle border-b border-grey-mid">
																		Dynamic Probe Hardware
																	</td>
																</tr>
																<tr>
																	<td>
																		{/* HARDWARE */}
																		<div className="plantHolder border-r border-grey-mid">
																			<table className="w-full text-center noTableBorder">
																				<tbody>
																					{/* ////////////////////////////////////////////////////// */}
																					<tr>
																						<td className="dpHardwareCol1 tableRow bg-sptTB ">Test Type:</td>
																						<td className="dpHardwareCol2 tableRow pl-1.5">{testType}</td>
																					</tr>
																					<tr>
																						<td className="dpHardwareCol1 tableRow bg-sptTB ">Hammer Mass:</td>
																						<td className="dpHardwareCol2 tableRow pl-1.5">{hammerMass}</td>
																					</tr>
																					<tr>
																						<td className="dpHardwareCol1 tableRow bg-sptTB ">Standard Drop:</td>
																						<td className="dpHardwareCol2 tableRow pl-1.5">{standardDrop}</td>
																					</tr>
																					<tr>
																						<td className="dpHardwareCol1 tableRow bg-sptTB ">Rod Diameter:</td>
																						<td className="dpHardwareCol2 tableRow pl-1.5">{rodDiameter}</td>
																					</tr>
																					<tr>
																						<td className="dpHardwareCol1 tableRow bg-sptTB ">Rod Mass:</td>
																						<td className="dpHardwareCol2 tableRow pl-1.5">{rodMass}</td>
																					</tr>
																					{/* ////////////////////////////////////////////////////// */}
																				</tbody>
																			</table>
																		</div>
																	</td>
																	<td className="align-top">
																		<div className="personnelHolder align-top">
																			<table className="w-full text-center noTableBorder">
																				<tbody>
																					{/* ////////////////////////////////////////////////////// */}
																					<tr>
																						<td className="dpHardwareCol3 tableRow bg-sptTB ">Test Method:</td>
																						<td className="dpHardwareCol4 tableRow pl-1.5">{testMethod}</td>
																					</tr>
																					<tr>
																						<td className="dpHardwareCol3 tableRow bg-sptTB ">Cone Diameter:</td>
																						<td className="dpHardwareCol4 tableRow pl-1.5">{coneDiameter}</td>
																					</tr>
																					<tr>
																						<td className="dpHardwareCol3 tableRow bg-sptTB ">Anvil Type:</td>
																						<td className="dpHardwareCol4 tableRow pl-1.5">{anvilType}</td>
																					</tr>
																					<tr>
																						<td className="dpHardwareCol3 tableRow bg-sptTB ">Anvil Dampner:</td>
																						<td className="dpHardwareCol4 tableRow pl-1.5">{anvilDampner}</td>
																					</tr>
																					<tr>
																						<td className="dpHardwareCol3 tableRow bg-sptTB ">Acc. Body & Ref #:</td>
																						<td className="dpHardwareCol4 tableRow pl-1.5"></td>
																					</tr>
																					{/* ////////////////////////////////////////////////////// */}
																				</tbody>
																			</table>
																		</div>
																		{/* END OF HARDWARE */}
																	</td>
																</tr>
															</tbody>
														</table>


														{/* COMMENTS */}
														<div className="commentsHolder_DP w-full border-b border-l border-grey-mid">
															<table className="w-full align-top noTableBorder">
																<tbody>
																	<tr>
																		<td className="tableRow bg-grey-mid sectionTitle">Comments</td>
																	</tr>
																	<tr>
																		<td className="commentsInput">
																			{/* ////////////////////////////////////////////////////// */}

																			{/* ////////////////////////////////////////////////////// */}
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
														{/* END OF COMMENTS */}
													</td> {/* END OF LEFT SIDE */}
													<td className="w-2/4 align-top"> {/* RIGHT SIDE */}
														<table className="border-b border-r border-grey-mid">
															<tbody>
																<tr>
																	<td>
																		{/* PLANT */}
																		<div className="plantHolder border-r border-grey-mid">
																			<table className="w-full text-center noTableBorder">
																				<tbody>
																					<tr>
																						<td colSpan={2} className="tableRow bg-grey-mid sectionTitle">Plant Details</td>
																					</tr>
																					{/* ////////////////////////////////////////////////////// */}
																					<tr>
																						{/* <td className="tableRow bg-sptTB plantCategories">Plant Type:</td>
																						<td className="tableRow  plantInputs">{plantType}</td> */}
																						<td className="tableRow bg-sptTB plantCategories">Plant Make/Model:</td>
																						<td className="tableRow  plantInputs">{plantMakeModel}</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB plantCategories">Plant ID:</td>
																						<td className="tableRow plantInputs">{plantID}</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB plantCategories">Plant Test Date:</td>
																						<td className="tableRow plantInputs">{plantTestDate}</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB plantCategories">SPT ID#:</td>
																						<td className="tableRow plantInputs">{sptID}</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB plantCategories">SPT Rod Type:</td>
																						<td className="tableRow plantInputs">{sptRodType}</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB plantCategories">SPT Callib. Date:</td>
																						<td className="tableRow plantInputs">{sptCalibrationDate}</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB plantCategories">SPT Energy Ratio:</td>
																						<td className="tableRow plantInputs">{sptEnergyRatio}</td>
																					</tr>
																					{/* ////////////////////////////////////////////////////// */}
																				</tbody>
																			</table>
																		</div>
																		{/* END OF PLANT    */}
																	</td>
																	<td>
																		{/* PERSONNEL */}
																		<div className="personnelHolder">
																			<table className="w-full text-center noTableBorder">
																				<tbody>
																					<tr>
																						<td colSpan={2} className="tableRow bg-grey-mid sectionTitle">Personnel Details</td>
																					</tr>
																					{/* ////////////////////////////////////////////////////// */}
																					<tr>
																						<td className="tableRow bg-sptTB personnelCategories">Lead Driller:</td>
																						<td className="tableRow  personnelInputs">{leadDriller}</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB personnelCategories">CSCS:</td>
																						<td className="tableRow personnelInputs">{leadDrillerCSCS}</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB personnelCategories">2nd Operative: </td>
																						<td className="tableRow personnelInputs">{secondMan}</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB personnelCategories">CSCS:</td>
																						<td className="tableRow personnelInputs">{secondManCSCS}</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB personnelCategories">Site Engineer:</td>
																						<td className="tableRow personnelInputs"></td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB personnelCategories">&nbsp;</td>
																						<td className="tableRow personnelInputs">&nbsp;</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB personnelCategories">&nbsp;</td>
																						<td className="tableRow personnelInputs">&nbsp;</td>
																					</tr>
																					{/* ////////////////////////////////////////////////////// */}
																				</tbody>
																			</table>
																		</div>
																		{/* END OF PERSONNEL    */}
																	</td>
																</tr>
															</tbody>
														</table>
														{/* CLIENT INFO FOOTER */}
														<div className="w-full border-b border-r border-grey-mid">
															<table className="clientInfoTable noTableBorder">
																<tbody>
																	<tr>
																		{/* ////////////////////////////////////////////////////// */}
																		<td className="tableRow clientInfoContact">
																			{companyName}<br />
																			{companyAddress}<br />
																			Telephone: {companyTelephone}<br />
																			Email: {companyEmail}
																		</td>
																		{/* ////////////////////////////////////////////////////// */}
																		<td className="tableRow clientInfoDrillitLogs"></td>
																	</tr>
																</tbody>
															</table>
														</div>
														{/* END OF CLIENT INFO FOOTER */}
													</td>
												</tr>
											</tbody>
											{/* END OF tableHolderTable */}
										</table>

										{/* END OF mainContainer */}
									</div>







									<div className="mainContainer mt-4">

										<table className="w-full">
											<tbody>
												{/* HEADER TABLE */}
												<tr>
													<td colSpan={2}>
														<div className="headerTableHolder border border-grey-mid">
															<table className="noTableBorder text-center w-full">
																<tbody>
																	<tr>
																		<td rowSpan={3} className="headerLeft">{/* LOGO */}<img className="mt-1" src={companyLogoWithCacheBusting} /></td>
																		<td colSpan={4} className="headerTitle">Daily Dynamic Probe Record</td>
																		<td rowSpan={2} colSpan={2} className="headerRight headerBarcodeHolder pt-3 pl-5 text-center">
																			{/* <svg id="barcode" className="barcodeCSS"></svg> */}
																			<Barcode value={labelbarcodeVar}
																				options={{
																					format: 'code128',
																					//width: 2,
																					width: barcodeWidth,
																					height: 60,
																					fontSize: 12,
																					//marginLeft: 80,
																					//text: barcodeVar
																					//background: "#ccffff"
																				}} />
																		</td>
																	</tr>
																	<tr>
																		<td className="headerCellTitleLeft bg-sptTB">Location:</td>
																		<td className={`${logsheetLocationStyle}`}>{projectLocation}</td>
																		<td className="headerCellTitleRight bg-sptTB">Client:</td>
																		<td className="headerCellInputRight">{client_name}</td>
																	</tr>
																	<tr>
																		<td className="headerCellTitleLeft bg-sptTB">Project ID:</td>
																		<td id="projectID" className="headerCellInputLeft">{projectID}</td>
																		<td className="headerCellTitleRight bg-sptTB">DP ID:</td>
																		<td id="boreholeID" className="headerCellInputRight">{borehole_name}</td>
																		<td id="headerDate">{sheetDate}</td>
																		<td id="headerSheets">Sheet 2 of 2</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</td>
												</tr>
												{/* END OF HEADER TABLE */}
												{/* CENTER STUFF */}
												<tr>
													<td colSpan={2} className="w-full">

														<div className="grid grid-cols-12">

															<div className="col-span-7">   {/* LEFT SIDE - DATA */}

																<div className=""> {/* HEADER */}
																	<div className="grid grid-cols-14 gap-0 font-bold text-xs text-center bg-grey-mid">
																		<div className={`col-span-2 px-0 pt-1.5 pb-1.5`}>(m)</div>
																		<div className={`col-span-10 px-2 pt-1.5 pb-1.5`}>Blows</div>
																		<div className={`col-span-2 px-0 pt-2 pb-1.5 `} style={{ fontSize: '0.5rem', lineHeight: '0.55rem' }}>
																			Torque<br />UNIT
																		</div>
																	</div>
																</div>





																<div>  {/* DATA */}

																	<div className="grid grid-cols-14 gap-0 text-center overflow-auto border-l border-b border-grey-mid text-xs">

																		<div className={`col-span-2 px-0 `}>
																			{[...Array(15)].map((_, row) => {
																				return <div className={`h-10 border-r border-b border-grey-mid py-2 text-lg font-bold`} style={{ height: '2.6255rem' }}
																					key={'rowl' + row}>{row++ + 15}</div>
																			})}
																		</div>

																		<div className={`col-span-10`}>
																			<div className="grid grid-cols-5 gap-0 text-center">
																				{[...Array(5)].map((_, column) =>
																					<div className={`col-span-1`} key={'column' + column}>
																						<div className="w-full border-b border-grey-mid" style={{ 'height': (column * 5) + 'px' }}></div>

																						{[...Array(15)].map((_, row) =>
																							<React.Fragment key={'row' + row}>
																								<div className={`w-full border-b border-r border-grey-mid`}>

																									<div className={`w-full text-center h-5 
																									${row % 2 ? 'bg-grey-light' : 'bg-white'} 
																									${probeData.blows[parseInt(row + '' + column)] === -1 ? 'ownWeight_BG' : ''}
																									${probeData.blows[parseInt(row + '' + column)] === -2 ? '' : ''}
																								`}
																										style={{ height: '1.25rem' }}>
																										{probeData.blows[parseInt(row + '' + column)] === 0
																											? 'O/W'
																											: probeData.blows[parseInt(row + '' + column)] === -1
																												? '' // Replace -1 with blank value
																												: probeData.blows[parseInt(row + '' + column)] === -2
																													? '' // Replace -2 with blank value
																													: probeData.blows[parseInt(row + '' + column)]
																										}
																									</div>

																								</div>

																								<div className={`w-full border-b border-r border-grey-mid`}>

																									<div className={`w-full text-center h-5 
																									${row % 2 ? 'bg-grey-light' : 'bg-white'} 
																									${probeData.blows[parseInt(row + '' + column) + 5] === -1 ? 'ownWeight_BG' : ''}
																									${probeData.blows[parseInt(row + '' + column) + 5] === -2 ? '' : ''}
																								`}
																										style={{ height: '1.25rem' }}>
																										{probeData.blows[parseInt(row + '' + column) + 5] === 0
																											? 'O/W'
																											: probeData.blows[parseInt(row + '' + column) + 5] === -1
																												? '' // Replace -1 with blank value
																												: probeData.blows[parseInt(row + '' + column) + 5] === -2
																													? '' // Replace -2 with blank value
																													: probeData.blows[parseInt(row + '' + column) + 5]
																										}
																									</div>
																								</div>
																							</React.Fragment>
																						)}
																					</div>
																				)}
																			</div>
																		</div>

																		<div className={`col-span-2`}>
																			<div className="w-full border-b border-grey-mid" style={{ 'height': '20px' }}></div>

																			{[...Array(15)].map((_, row) => {
																				return <div className={`h-10 border-r border-b border-grey-mid py-2 text-lg font-bold`} style={{ height: '2.6255rem' }}
																					key={'rowr' + row}
																				>
																					<div className={`p-0`}>
																						{torqueData.torques[row] === -1 ? '' : torqueData.torques[row]}{/* {torques[row]} */}
																					</div>
																				</div>
																			})}
																		</div>

																	</div>

																</div>

															</div>    {/* END OF LEFT SIDE - DATA */}



															{/* <div className="col-span-12 lg:col-span-5 mb-2 bg-grey-mid invisible md:visible"  style={{ maxHeight: '493px' }}>   RIGHT SIDE - GRAPH */}
															<div className="col-span-5 bg-grey-mid ">   {/* RIGHT SIDE - GRAPH */}

																<div className="h-10 w-full z-99">

																	<div className="text-left text-xs pl-10 font-bold">Blows</div>
																	<div className="grid grid-cols-10 py-1 text-right text-charcoal text-xs -mt-2">
																		{/* <div className="col-span-1"><span className="font-bold pr-1 md:pr-4 lg:pr-1">(m)</span>0</div> */}
																		<div className="col-span-1">5</div>
																		<div className="col-span-1">10</div>
																		<div className="col-span-1">15</div>
																		<div className="col-span-1">20</div>
																		<div className="col-span-1">25</div>
																		<div className="col-span-1">30</div>
																		<div className="col-span-1">35</div>
																		<div className="col-span-1">40</div>
																		<div className="col-span-1">45</div>
																		<div className="col-span-1 pr-1">50</div>
																	</div>

																	<div className="grid grid-cols-10 text-right text-charcoal text-xs ">

																		<div className="col-span-10 w-full bgFullWidth overflow-x-hidden h-full border-t border-grey-mid"
																			style={{ 'background': `url(${dp_chart_bg})`, marginTop: '19px', height: '629px' }}>

																			{probeData.blows.map((cellValue, index) => {
																				// Perform your width calculation for each cellValue here
																				let width = 0;
																				if (!isNaN(cellValue) && cellValue !== -1 && cellValue !== -2) {
																					width = (cellValue / 50) * 100;
																				} else {
																					width = 0;
																				}

																				return (
																					<div
																						className="bg-charcoal"
																						style={{ width: `${width}%`, height: '0.2rem', marginBottom: '1px' }}
																						key={`row${index}_cell${index}`}
																					></div>
																				);
																			})}
																		</div>

																	</div>

																</div>

															</div>    {/* END OF RIGHT SIDE - GRAPH */}

														</div>



													</td>
												</tr>
												<tr>
													<td className="w-2/4 align-top border-r border-grey-mid"> {/* LEFT SIDE */}



														<table className="border-b border-grey-mid">
															<tbody>
																<tr>
																	<td colSpan={2} className="tableRow bg-grey-mid sectionTitle border-b border-grey-mid">
																		Dynamic Probe Hardware
																	</td>
																</tr>
																<tr>
																	<td>
																		{/* HARDWARE */}
																		<div className="plantHolder border-r border-grey-mid">
																			<table className="w-full text-center noTableBorder">
																				<tbody>
																					{/* ////////////////////////////////////////////////////// */}
																					<tr>
																						<td className="dpHardwareCol1 tableRow bg-sptTB ">Test Type:</td>
																						<td className="dpHardwareCol2 tableRow pl-1.5">{testType}</td>
																					</tr>
																					<tr>
																						<td className="dpHardwareCol1 tableRow bg-sptTB ">Hammer Mass:</td>
																						<td className="dpHardwareCol2 tableRow pl-1.5">{hammerMass}</td>
																					</tr>
																					<tr>
																						<td className="dpHardwareCol1 tableRow bg-sptTB ">Standard Drop:</td>
																						<td className="dpHardwareCol2 tableRow pl-1.5">{standardDrop}</td>
																					</tr>
																					<tr>
																						<td className="dpHardwareCol1 tableRow bg-sptTB ">Rod Diameter:</td>
																						<td className="dpHardwareCol2 tableRow pl-1.5">{rodDiameter}</td>
																					</tr>
																					<tr>
																						<td className="dpHardwareCol1 tableRow bg-sptTB ">Rod Mass:</td>
																						<td className="dpHardwareCol2 tableRow pl-1.5">{rodMass}</td>
																					</tr>
																					{/* ////////////////////////////////////////////////////// */}
																				</tbody>
																			</table>
																		</div>
																	</td>
																	<td className="align-top">
																		<div className="personnelHolder align-top">
																			<table className="w-full text-center noTableBorder">
																				<tbody>
																					{/* ////////////////////////////////////////////////////// */}
																					<tr>
																						<td className="dpHardwareCol3 tableRow bg-sptTB ">Test Method:</td>
																						<td className="dpHardwareCol4 tableRow pl-1.5">{testMethod}</td>
																					</tr>
																					<tr>
																						<td className="dpHardwareCol3 tableRow bg-sptTB ">Cone Diameter:</td>
																						<td className="dpHardwareCol4 tableRow pl-1.5">{coneDiameter}</td>
																					</tr>
																					<tr>
																						<td className="dpHardwareCol3 tableRow bg-sptTB ">Anvil Type:</td>
																						<td className="dpHardwareCol4 tableRow pl-1.5">{anvilType}</td>
																					</tr>
																					<tr>
																						<td className="dpHardwareCol3 tableRow bg-sptTB ">Anvil Dampner:</td>
																						<td className="dpHardwareCol4 tableRow pl-1.5">{anvilDampner}</td>
																					</tr>
																					<tr>
																						<td className="dpHardwareCol3 tableRow bg-sptTB ">Acc. Body & Ref #:</td>
																						<td className="dpHardwareCol4 tableRow pl-1.5"></td>
																					</tr>
																					{/* ////////////////////////////////////////////////////// */}
																				</tbody>
																			</table>
																		</div>
																		{/* END OF HARDWARE */}
																	</td>
																</tr>
															</tbody>
														</table>


														{/* COMMENTS */}
														<div className="commentsHolder_DP w-full border-b border-l border-grey-mid">
															<table className="w-full align-top noTableBorder">
																<tbody>
																	<tr>
																		<td className="tableRow bg-grey-mid sectionTitle">Comments</td>
																	</tr>
																	<tr>
																		<td className="commentsInput">
																			{/* ////////////////////////////////////////////////////// */}

																			{/* ////////////////////////////////////////////////////// */}
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
														{/* END OF COMMENTS */}
													</td> {/* END OF LEFT SIDE */}



													<td className="w-2/4 align-top"> {/* RIGHT SIDE */}
														<table className="border-b border-r border-grey-mid">
															<tbody>
																<tr>
																	<td>
																		{/* PLANT */}
																		<div className="plantHolder border-r border-grey-mid">
																			<table className="w-full text-center noTableBorder">
																				<tbody>
																					<tr>
																						<td colSpan={2} className="tableRow bg-grey-mid sectionTitle">Plant Details</td>
																					</tr>
																					{/* ////////////////////////////////////////////////////// */}
																					<tr>
																						<td className="tableRow bg-sptTB plantCategories">Plant Type:</td>
																						<td className="tableRow  plantInputs">{plantType}</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB plantCategories">Plant ID:</td>
																						<td className="tableRow plantInputs">{plantID}</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB plantCategories">Plant Test Date:</td>
																						<td className="tableRow plantInputs">{plantTestDate}</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB plantCategories">SPT ID#:</td>
																						<td className="tableRow plantInputs">{sptID}</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB plantCategories">SPT Rod Type:</td>
																						<td className="tableRow plantInputs">{sptRodType}</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB plantCategories">SPT Callib. Date:</td>
																						<td className="tableRow plantInputs">{sptCalibrationDate}</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB plantCategories">SPT Energy Ratio:</td>
																						<td className="tableRow plantInputs">{sptEnergyRatio}</td>
																					</tr>
																					{/* ////////////////////////////////////////////////////// */}
																				</tbody>
																			</table>
																		</div>
																		{/* END OF PLANT    */}
																	</td>
																	<td>
																		{/* PERSONNEL */}
																		<div className="personnelHolder">
																			<table className="w-full text-center noTableBorder">
																				<tbody>
																					<tr>
																						<td colSpan={2} className="tableRow bg-grey-mid sectionTitle">Personnel Details</td>
																					</tr>
																					{/* ////////////////////////////////////////////////////// */}
																					<tr>
																						<td className="tableRow bg-sptTB personnelCategories">Lead Driller:</td>
																						<td className="tableRow  personnelInputs">{leadDriller}</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB personnelCategories">CSCS:</td>
																						<td className="tableRow personnelInputs">{leadDrillerCSCS}</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB personnelCategories">2nd Operative: </td>
																						<td className="tableRow personnelInputs">{secondMan}</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB personnelCategories">CSCS:</td>
																						<td className="tableRow personnelInputs">{secondManCSCS}</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB personnelCategories">Site Engineer:</td>
																						<td className="tableRow personnelInputs"></td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB personnelCategories">&nbsp;</td>
																						<td className="tableRow personnelInputs">&nbsp;</td>
																					</tr>
																					<tr>
																						<td className="tableRow bg-sptTB personnelCategories">&nbsp;</td>
																						<td className="tableRow personnelInputs">&nbsp;</td>
																					</tr>
																					{/* ////////////////////////////////////////////////////// */}
																				</tbody>
																			</table>
																		</div>
																		{/* END OF PERSONNEL    */}
																	</td>
																</tr>
															</tbody>
														</table>
														{/* CLIENT INFO FOOTER */}
														<div className="w-full border-b border-r border-grey-mid">
															<table className="clientInfoTable noTableBorder">
																<tbody>
																	<tr>
																		{/* ////////////////////////////////////////////////////// */}
																		<td className="tableRow clientInfoContact">
																			{companyName}<br />
																			{companyAddress}<br />
																			Telephone: {companyTelephone}<br />
																			Email: {companyEmail}
																		</td>
																		{/* ////////////////////////////////////////////////////// */}
																		<td className="tableRow clientInfoDrillitLogs"></td>
																	</tr>
																</tbody>
															</table>
														</div>
														{/* END OF CLIENT INFO FOOTER */}
													</td>
												</tr>
											</tbody>
											{/* END OF tableHolderTable */}
										</table>

										{/* END OF mainContainer */}
									</div>






								</div>

							</div>

						</div>

					</>

				}














				{/* //..........................................................................
				//.LLLL..........AAAAA.....BBBBBBBBBB...EEEEEEEEEEE.LLLL........SSSSSSS.....
				//.LLLL..........AAAAA.....BBBBBBBBBBB..EEEEEEEEEEE.LLLL.......SSSSSSSSS....
				//.LLLL.........AAAAAA.....BBBBBBBBBBB..EEEEEEEEEEE.LLLL.......SSSSSSSSSS...
				//.LLLL.........AAAAAAA....BBBB...BBBB..EEEE........LLLL......LSSSS..SSSS...
				//.LLLL........AAAAAAAA....BBBB...BBBB..EEEE........LLLL......LSSSS.........
				//.LLLL........AAAAAAAA....BBBBBBBBBBB..EEEEEEEEEE..LLLL.......SSSSSSS......
				//.LLLL........AAAA.AAAA...BBBBBBBBBB...EEEEEEEEEE..LLLL........SSSSSSSSS...
				//.LLLL.......AAAAAAAAAA...BBBBBBBBBBB..EEEEEEEEEE..LLLL..........SSSSSSS...
				//.LLLL.......AAAAAAAAAAA..BBBB....BBBB.EEEE........LLLL.............SSSSS..
				//.LLLL.......AAAAAAAAAAA..BBBB....BBBB.EEEE........LLLL......LSSS....SSSS..
				//.LLLLLLLLLLLAAA....AAAA..BBBBBBBBBBBB.EEEEEEEEEEE.LLLLLLLLLLLSSSSSSSSSSS..
				//.LLLLLLLLLLLAAA.....AAAA.BBBBBBBBBBB..EEEEEEEEEEE.LLLLLLLLLL.SSSSSSSSSS...
				//.LLLLLLLLLLLAAA.....AAAA.BBBBBBBBBB...EEEEEEEEEEE.LLLLLLLLLL..SSSSSSSS....
				//.......................................................................... */}

				{labelsVisible && <>

					{/* LABELS */}

					<div className="flex flex-col rounded-lg border border-orange bg-modal-bg px-1 sm:px-2 md:px-2 modalshadow previewLogsheet pb-20 z-10">

						<div className="flex items-center border-b border-grey-dark py-2">

							<div className="flex-1 flex items-left">

							</div>

							<div className="flex-1 flex justify-center text-lg sm:text-xl font-bold text-text leading-6 text-center mx-auto">
								Labels
							</div>

							<div className="flex-1 flex justify-center">
								<span className="ml-auto">
									<button className="flex items-center justify-center w-12 h-12 text-4xl bold text-soil-grey bg-white border border-orange rounded" onClick={labelsClick} ><img src={closeIcon} width="32" alt="" /></button>
								</span>
							</div>
						</div>



						<div className="text-lg w-full text-center pt-2">
							Samples
						</div>

						<ul className="flex flex-wrap p-0 mb-2 list-none justify-center text-center"> {/* START LABELS HOLDER */}


							{/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
							// SAMPLES
							/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

							{SamplesData?.filter((action: any) => {
								return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
							}).map((valueSamples: any, index: number) => {

								// let printSample = valueSamples.type
								let depthFrom = ''
								if (valueSamples.depth_to_top === '0' || valueSamples.depth_to_top === 0 ||
									valueSamples.depth_to_top === '0.00' || valueSamples.depth_to_top === 0.00
								) {
									depthFrom = 'GL'
								} else {
									depthFrom = valueSamples.depth_to_top
								}


								return (
									<React.Fragment key={index}>

										<li className="p-1 z-10">
											<div onClick={() => {
												setLabelSample(valueSamples.type)
												setLabelDepth(depthFrom + '-' + valueSamples.depth_to_base)
												setShowPrintLabel(true)
											}}>
												<Label
													borehole={borehole_name}
													sample={valueSamples.type}
													depth={depthFrom + '-' + valueSamples.depth_to_base}
													size='smallLabel'
													user={leadDriller}
												/>
											</div>
										</li>

									</React.Fragment>
								)
							})}

							{/* ////////////////////////////////////////////////////// */}

						</ul> {/* END LABELS HOLDER */}

						{/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
						// PRINT ALL SAMPLE LABELS
						/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

						<div className="pt-1" style={{ height: '1px', overflow: 'hidden' }}>


							{/* START LABELS HOLDER */}

							<ul ref={printAllSampleLabelsArea}
								className="flex flex-wrap p-0 mb-2 list-none justify-center text-center ">

								{SamplesData?.filter((action: any) => {
									return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
								}).map((valueSamples: any, index: number) => {

									// let printSample = valueSamples.type
									let depthFrom = ''
									if (valueSamples.depth_to_top === '0' || valueSamples.depth_to_top === 0) {
										depthFrom = 'GL'
									} else {
										depthFrom = valueSamples.depth_to_top
									}

									return (
										<>

											<li className="p-1 z-10">
												<LabelBatchPrint
													borehole={borehole_name}
													sample={valueSamples.type}
													depth={depthFrom + '-' + valueSamples.depth_to_base}
													user={leadDriller}
												/>
											</li>

										</>
									)

								})}

							</ul> {/* END LABELS HOLDER */}

						</div>

						{showPrintAllSampleLabelsButton &&
							<>
								<div className="mt-2">
									<ReactToPrint  //FUNCTION CODE
										trigger={() => <Button theme="primary" size="lg" className="btn-lg m-auto " >Print All Sample Labels</Button>}
										content={() => printAllSampleLabelsArea.current}
									/>
								</div>
							</>
						}

					</div>
				</>}

			</Container>

			<br /><br />

			<SyncStatus synced />

		</>

	)
}

export default FunctionTemplate