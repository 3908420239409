import React, { FunctionComponent, useState, useRef, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { incrementDate, decrementDate, setSelectedDate } from '../../actions/DateSelector'
import { updateSelectedProject } from '../../actions/SelectedProject'

import dateChangeIcon from '../../assets/icons/date-change.svg'
import infoIcon from '../../assets/icons/info.png'
import warningIcon from '../../assets/icons/warning.png'
import { useGetIDBValues } from "../../helpers/getIDBKey"
import { get, set, update, del } from "idb-keyval";
import Modal from '../../components/Modal'
import ModalSmall from '../../components/ModalSmall'

import Information from '../../views/Information'
import InputGroup from '../../components/form/InputGroup'
import Button from '../../components/form/Button'

type Props = {
	className?: string,
	projectId?: string,
	information_check?: boolean,
	[x: string]: any;
	holeType: string,
	holeName: string,

	project_start_date: string,
	site_description: string,
	key_site_features: string,
	site_history: string,
	key_hands: string,
	expected_strata: string,
	notes: string,
	equipment_required: string,
	project_client: string,
	client_contact: string,
	client_phone: string,
	client_email: string,
	engineer_name: string,
	engineer_phone: string,
	engineer_email: string,
	rig_name: string,
}

const defaultProps: Props = {
	className: '',
	projectId: '',
	information_check: false,
	holeType: '',
	holeName: '',

	project_start_date: '',
	site_description: '',
	key_site_features: '',
	site_history: '',
	key_hands: '',
	expected_strata: '',
	notes: '',
	equipment_required: '',
	project_client: '',
	client_contact: '',
	client_phone: '',
	client_email: '',
	engineer_name: '',
	engineer_phone: '',
	engineer_email: '',
	rig_name: '',
}

const ProjectHeader: FunctionComponent<Props> = ({ className, company_id, projectId, information_check, holeType, holeName, companyGuid, projectGuid,
	activeDates, lastActiveDate, project_start_date, site_description, key_site_features, site_history, key_hands, expected_strata, notes,
	equipment_required, project_client, client_contact, client_phone, client_email, engineer_name, engineer_phone, engineer_email,
	rig_name, second_man, plantCheck,
	...rest }) => {

	const rigs_link = '/rigs/' + companyGuid + '/' + projectGuid

	let infoWarningIcon = ''
	if (information_check === false) {
		infoWarningIcon = warningIcon
	} else {
		infoWarningIcon = infoIcon
	}

	console.log('lastActiveDate in header:', lastActiveDate)

	const dispatch = useDispatch();
	const selectedProject = useSelector((state: any) => state.selectedProject);
	const date = useSelector((state: any) => state.date);
	const formattedDate = new Date(date).toLocaleDateString("en-UK");

	const today = new Date().toLocaleDateString("en-UK");

	const showDate = (formattedDate === today) ? 'TODAY' : formattedDate;
	const [showAllDates, setShowAllDates] = useState(false);
	const [showSelectedDate, setShowSelectedDate] = useState(showDate);
	const popupRef = useRef<HTMLDivElement | null>(null);

	const formatDate = (dateToFormat: Date) => {
		console.log('dateToFormat:', dateToFormat)
		return dateToFormat.toLocaleDateString('en-UK', {
			year: 'numeric',
			day: '2-digit',
			month: '2-digit',
		});
	};

	const backhandler = () => {
		dispatch(decrementDate());
	};

	const forwardhandler = () => {
		dispatch(incrementDate());
	};

	const handleDateClick = (clickedDate: Date) => {
		console.log('********************clickedDate:', clickedDate)
		const formattedDate = formatDate(clickedDate);
		setShowSelectedDate(formattedDate);
		dispatch(setSelectedDate(clickedDate));
	};

	const handleDateMenuClick = (event: any) => {
		if (popupRef.current && !popupRef.current.contains(event.target)) {
			setShowAllDates(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleDateMenuClick);
		return () => {
			document.removeEventListener('click', handleDateMenuClick);
		};
	}, []);

	// useEffect(() => {
	// 	if (lastActiveDate) {
	// 		// Convert lastActiveDate from DD/MM/YYYY to Date object
	// 		const [day, month, year] = lastActiveDate.split('/').map(Number);
	// 		const lastActiveDateObj = new Date(year, month - 1, day);
	// 		const formattedDate = lastActiveDateObj.toLocaleDateString("en-UK");
	// 		console.log('lastActiveDateObj:', lastActiveDateObj)
	// 		console.log('formattedDate:', formattedDate)
	// 		setShowSelectedDate(formattedDate);
	// 		dispatch(setSelectedDate(lastActiveDateObj));
	// 	}
	// }, [lastActiveDate, dispatch]);

	useEffect(() => {
		if (lastActiveDate) {
			// Convert lastActiveDate from DD/MM/YYYY to Date object
			const [day, month, year] = lastActiveDate.split('/').map(Number);
			const lastActiveDateObj = new Date(year, month - 1, day, 9, 0, 0); // Set time to 09:00:00 to avoid GMT SUMMERTIME HOUR DIFFERENCE
			const formattedDate = lastActiveDateObj.toLocaleDateString("en-UK");
			console.log('lastActiveDateObj:', lastActiveDateObj);
			console.log('formattedDate:', formattedDate);
			setShowSelectedDate(formattedDate);
			dispatch(setSelectedDate(lastActiveDateObj));
		}
	}, [lastActiveDate, dispatch]);

	const updateEmptyProject = useCallback(async () => {
		if (company_id) {
			const data = await get(`drillit-projects/company/${company_id}`);
			for (const project of data) {
				if (project.guid === projectId) {
					dispatch(updateSelectedProject({
						guid: project.guid,
						name: project.name,
						location: project.location,
						number: project.project_identifier,
					}));
				}
			}
		}
	}, [company_id, projectId, dispatch]);

	useEffect(() => {
		updateEmptyProject();
	}, [updateEmptyProject]);

	useEffect(() => {
		if (selectedProject.guid === '') {
			updateEmptyProject();
		}
	}, [selectedProject, updateEmptyProject]);


	const [showInformation, setShowInformation] = useState(false);

	const showInformationClick = () => {
		setShowInformation((prev) => !prev)
	}

	const projectNumber = selectedProject.number
	const projectName = selectedProject.location



	// console.log('lastActiveDate:', typeof lastActiveDate, lastActiveDate)

	// useEffect(() => {
	// 	dispatch(setSelectedDate(lastActiveDate))
	// }, [lastActiveDate]);

	// is mobile?
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

	const handleResize = () => {
		setIsMobile(window.innerWidth <= 768);
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	// console.log('selectedProject.location:', selectedProject.location.length)

	const renderLocation = () => {
		const { location } = selectedProject;

		if (isMobile && location.length > 20) { // was 15 before removing projectID from header
			return `${location.substring(0, 20)}...`;
		} else {
			return location;
		}
	};
	//   console.log('renderLocation:', renderLocation())



	const [showDateSelector, setShowDateSelector] = useState(false)

	// useEffect(() => {

	// 	if (activeDates) {

	// 		if (activeDates.length === 0) {
	// 			console.log('activeDates EMPTY')
	// 			setShowDateSelector(true)
	// 		} else {
	// 			console.log('activeDates AVAILABLE')
	// 		}
	// 	}

	// }, [activeDates]);

	// NEW DATE
	const handleDateSelection = (dateString: any) => {
		const [day, month, year] = dateString.split('/'); // Split the date string
		const clickedDate = new Date(`${year}-${month}-${day}`); // Create Date object
		setShowSelectedDate(dateString);
		handleDateClick(clickedDate);
		dispatch(setSelectedDate(clickedDate));
	  };
	
	  const handleInputChange = (event: any) => {
		const dateString = event.target.value; // Format: yyyy-mm-dd
		const [year, month, day] = dateString.split('-');
		const formattedDate = `${day}/${month}/${year}`; // Convert to the desired format
		setShowSelectedDate(formattedDate);
		const clickedDate = new Date(`${year}-${month}-${day}`);
		handleDateClick(clickedDate);
		dispatch(setSelectedDate(clickedDate));
	  };
	

	return (

		<>

			{showInformation && (
				<Information onClose={() => { setShowInformation(false) }}
					holeType={holeType}
					holeName={holeName}
					defaultValues={{
						project: projectNumber,
						location: projectName,
						rigs_link: rigs_link,
						companyGuid: companyGuid,
						projectGuid: projectGuid,
						project_start_date: project_start_date,
						site_description: site_description,
						key_site_features: key_site_features,
						site_history: site_history,
						key_hands: key_hands,
						expected_strata: expected_strata,
						notes: notes,
						equipment_required: equipment_required,
						project_client: project_client,
						client_contact: client_contact,
						client_phone: client_phone,
						client_email: client_email,
						engineer_name: engineer_name,
						engineer_phone: engineer_phone,
						engineer_email: engineer_email,
						rig_name: rig_name,
						second_man: second_man,
						plantCheck: plantCheck,
					}}
				/>
			)
			}




			{showDateSelector && (
				<>
					<ModalSmall title="Log Dates" toggleModal={() => { setShowDateSelector(false) }} confirm={false}>

						<div className="my-2 text-base text-center">
							Select the date for the log
						</div>

						<div className="w-full rounded bg-sptTB py-2 mt-2">

							<div className="text-center text-base font-bold">
								+ Add New:
							</div>

							<div className="flex mx-auto justify-center mt-1 mb-2">

								<div className="flex px-2 md:px-4 bg-soil-greyish h-9 rounded mr-2 mt-1" onClick={backhandler}>
									<img src={dateChangeIcon} className="w-4 transform rotate-180" alt="" />
								</div>

								<div className="w-48 relative">
									
									<input
										type="date"
										value={
											showDate && showDate !== 'TODAY'
											? showDate.split('/').reverse().join('-') // Convert to yyyy-mm-dd for input value
											: ''
										}
										onChange={handleInputChange}
										className={`mb-2 py-1 pl-4 pr-1 w-full border border-orange text-2xl font-bold rounded ${
											showDate === 'TODAY' ? 'text-transparent' : ''
										}`}
										/>
										{showDate === 'TODAY' && (
										<div className="absolute inset-0 flex items-center justify-center pointer-events-none">
											<span className="text-2xl font-bold text-charcoal -mt-1.5">TODAY</span>
										</div>
										)}
								</div>

								<div className="flex px-2 md:px-4 bg-soil-greyish h-9 rounded ml-2 mt-1" onClick={forwardhandler}>
									<img src={dateChangeIcon} className="w-4" alt="" />
								</div>

							</div>

							

						</div>

						<div className="w-full rounded bg-sptTB py-2 mt-4 mb-4">

						<div className="text-center text-base font-bold">
								Existing Dates:
							</div>
							<div className="flex flex-wrap justify-center">
								{activeDates.map((showSelectedDate: string) => {
									const [day, month, year] = showSelectedDate.split('/'); // Split the date string
									const clickedDate = new Date(`${year}-${month}-${day}`); // Create Date object
									console.log('clickedDate:', typeof clickedDate, clickedDate);
									return (
									<div
										key={showSelectedDate}
										className="py-2 px-3 text-lg bg-orange text-white font-bold m-2 rounded" // Tailwind classes for spacing and styling
										onClick={() => {
										setShowSelectedDate(showSelectedDate);
										handleDateClick(clickedDate); // Pass the Date object
										setShowDateSelector(false)
										}}
									>
										{showSelectedDate}
									</div>
									);
								})}
							</div>

						</div>

						<div className="w-30 mb-8">
							<Button type="submit" theme="primary" size="lg" className="btn-lg m-auto text-lg font-bold" 
							onClick={() => { setShowDateSelector(false) }}>
								OK
							</Button>
						</div>

					</ModalSmall>
				</>
			)}

			<div className={`flex items-start ${className}`} {...rest}>
				<div className="flex justify-center flex-col">

					<div className="text-center mb-0.5 ">
						<div onClick={showInformationClick}>
							<div className="align-bottom inline-block text-sm md:text-base font-bold pb-0.5 md:pb-0"> {/* style={{lineHeight: 1.2}} */}
								{/* {selectedProject.number} - {renderLocation()} selectedProject.location */}
								{renderLocation()} {/* selectedProject.location */}

							</div>
							<img src={infoWarningIcon} width="20" className="ml-1 inline-block pb-0.5" alt="" />
						</div>
					</div>

					{/* <div className="flex mx-auto">
						<div className="flex px-2 bg-soil-greyish h-7 rounded-l" onClick={backhandler}>
							<img src={dateChangeIcon} width="12" className="transform rotate-180" alt="" />
						</div>

						<div className="bg-white pt-1 text-sm font-bold text-lg leading-5 text-center w-36">
							<div className="bg-white h-6 z-50"
								onClick={() => setShowAllDates(!showAllDates)}
								ref={popupRef}
							>
								{showDate}
							</div>
							<div className="text-xs bg-header-light pt-0.5 hidden" style={{ fontSize: '0.5rem', lineHeight: '0.7rem' }}>
								{showSelectedDate}
							</div>

							{showAllDates &&
								<div className="rounded-b modalshadow bg-white sticky z-40">

									{activeDates.map((showSelectedDate: string) => {
										const [day, month, year] = showSelectedDate.split('/'); // Split the date string
										const clickedDate = new Date(`${year}-${month}-${day}`); // Create Date object
										console.log('clickedDate:', typeof clickedDate, clickedDate)
										return (
											<div
												key={showSelectedDate}
												className="py-2 border-t border-grey-mid"
												onClick={() => {
													setShowSelectedDate(showSelectedDate);
													handleDateClick(clickedDate); // Pass the Date object
												}}
											>
												{showSelectedDate}
											</div>
										);
									})}

								</div>
							}
						</div>

						<div className="flex px-2 bg-soil-greyish h-7 rounded-r" onClick={forwardhandler}>
							<img src={dateChangeIcon} width="12" alt="" />
						</div>
					</div> */}

					<div className="text-xs text-center" onClick={() => setShowDateSelector(true)}>
						<div className="bg-white z-50 text-xl leading-5 pt-1.5 pb-1.5 text-xs font-bold text-center w-36 mx-auto rounded border border-grey-dark">
							{showDate}
						</div>
					</div>

				</div>

			</div>

		</>
	)
}

ProjectHeader.defaultProps = defaultProps

export default ProjectHeader
