import React from 'react'

import Header from '../../layout/Header'

import Modal from '../../components/Modal'
import Button from '../../components/form/Button'
import InputGroup from '../../components/form/InputGroup'

import { v4 as uuidv4 } from 'uuid';
import SelectGroup from "../../components/form/SelectGroup";
import { useGetIDBValues } from "../../helpers/getIDBKey";
import { useSetIndexedDBValues } from "../../helpers/addToIDB";
import { Redirect } from "react-router-dom";

interface Props {
    toggleSidebar: () => void,
    router: any,
}

interface State {
    name: string,
    type: string,
    plant_id: string,
    make_model: string,
    spt_guid: string,
    test_date_expiry: string,
    hammers: any,
    guid: string,
    test_method: string,
    company_guid: string,
    project_guid: string,
    redirect: boolean,
    spt_id_ref: string,

    dp_test_type: any,
    dp_test_ref: string,
    dprg_anvil_type: string,
    dprg_anvil_dampner: string,
    dprg_type: string,
    dprg_hammer_mass: number,
    dprg_standard_drop: number,
    dprg_cone_diameter: number,
    dprg_rod_diameter: number,
    dprg_rod_mass: number,
    torque_default: string,
}

interface SaveState {
    name: string;
    type: string;
    plant_id: string;
    make_model: string;
    test_date_expiry: string;
    hammers: any[]; // You can replace 'any[]' with a more specific type if needed
    guid: string;
    test_method: string;
    company_guid: string;
    spt_id_ref: string;
    spt_guid: string;
    dp_test_type: string;
    dp_test_ref: string;
    dprg_anvil_type: string;
    dprg_anvil_dampner: string;
    dprg_type: string;
    dprg_hammer_mass: number;
    dprg_standard_drop: number;
    dprg_cone_diameter: number;
    dprg_rod_diameter: number;
    dprg_rod_mass: number;
    torque_default: string;
    redirect: boolean;
}

class RigsCreate extends React.Component<Props, State, SaveState> {
    constructor(props: any) {
        super(props)

        this.state = {
            name: '',
            type: '',
            plant_id: '',
            make_model: '',
            test_date_expiry: '',
            hammers: [],
            guid: uuidv4(),
            test_method: 'BS EN ISO 22476-2',
            company_guid: this.props.router.match.params.company,
            project_guid: this.props.router.match.params.project,
            redirect: false,
            spt_id_ref: '',
            spt_guid: '',

            dp_test_type: '',
            dp_test_ref: '',
            dprg_anvil_type: '',
            dprg_anvil_dampner: '',
            dprg_type: '',
            dprg_hammer_mass: 0,
            dprg_standard_drop: 0,
            dprg_cone_diameter: 0,
            dprg_rod_diameter: 0,
            dprg_rod_mass: 0,
            torque_default: '(kg/m)',
        }
    }

    // save = (event: any) => {
    //     event.preventDefault()
    //     let saveToIDB = useSetIndexedDBValues
    //     saveToIDB(this.state, 'rigs', true)
    //         .then(() => setTimeout(() => this.setState({redirect: true}), 1000))
    // }

    save = (event: any) => {
        event.preventDefault();

        // Define an array of property names to be saved
        const propertiesToSave: (keyof SaveState)[] = [
            'name',
            'type',
            'plant_id',
            'make_model',
            'test_date_expiry',
            'hammers',
            'guid',
            'test_method',
            'company_guid',
            'spt_id_ref',
            'spt_guid',
            'dp_test_type',
            'dp_test_ref',
            'dprg_anvil_type',
            'dprg_anvil_dampner',
            'dprg_type',
            'dprg_hammer_mass',
            'dprg_standard_drop',
            'dprg_cone_diameter',
            'dprg_rod_diameter',
            'dprg_rod_mass',
            'torque_default',
        ];

        // Create a new object with only the specified properties
        const saveState: Partial<SaveState> = {};
        propertiesToSave.forEach((property) => {
            saveState[property] = this.state[property];
        });

        // Add additional properties if needed
        saveState.redirect = true;

        let saveToIDB = useSetIndexedDBValues;
        saveToIDB(saveState as SaveState, 'rigs', true)
            .then(() => setTimeout(() => this.setState({ redirect: true }), 1000));
    }

    componentDidMount() {
        this.getCacheValues()
    }

    // get cache values for SPT-hammers
    getCacheValues = () => {
        let cachedValues = useGetIDBValues
        cachedValues('spt-hammers/company/' + this.state.company_guid, (data: any) => {
            let selectValues: any = []
            for (const hammer of data) {
                let { guid, id_ref } = hammer
                const newValue = {
                    value: guid,
                    label: id_ref
                }
                selectValues.push(newValue)
            }
            this.setState({
                hammers: selectValues
            })
        }).then()
    }

    handleTestTypeChange = (value: any) => {
        this.setState({ dp_test_type: value.value }, () => {
            // Handle additional state updates based on dp_test_type
            if (value.value === 'Superheavy - 750mm drop') {
                this.setState({
                    dprg_type: 'DPSH-B',
                    dprg_hammer_mass: 63.5,
                    dprg_standard_drop: 750,
                    dprg_cone_diameter: 49,
                    dprg_rod_diameter: 32,
                    dprg_rod_mass: 8,
                });
            } else if (value.value === 'Light - 500mm drop') {
                this.setState({
                    dprg_type: 'DPL',
                    dprg_hammer_mass: 10,
                    dprg_standard_drop: 500,
                    dprg_cone_diameter: 34,
                    dprg_rod_diameter: 22,
                    dprg_rod_mass: 3,
                });
            } else if (value.value === 'Medium - 500mm drop') {
                this.setState({
                    dprg_type: 'DPM',
                    dprg_hammer_mass: 30,
                    dprg_standard_drop: 500,
                    dprg_cone_diameter: 42,
                    dprg_rod_diameter: 32,
                    dprg_rod_mass: 6,
                });
            } else if (value.value === 'Heavy - 500mm drop') {
                this.setState({
                    dprg_type: 'DDPHPL',
                    dprg_hammer_mass: 50,
                    dprg_standard_drop: 500,
                    dprg_cone_diameter: 42,
                    dprg_rod_diameter: 32,
                    dprg_rod_mass: 6,
                });
            } else if (value.value === 'Superheavy - 500mm drop') {
                this.setState({
                    dprg_type: 'DPSH-A',
                    dprg_hammer_mass: 63.5,
                    dprg_standard_drop: 500,
                    dprg_cone_diameter: 43,
                    dprg_rod_diameter: 32,
                    dprg_rod_mass: 6,
                });
            }
        });
    };



    handleCancelClick = () => {
        window.history.back();
    };

    render() {

        console.log('this.state', this.state)

        const { redirect } = this.state

        if (redirect) {
            return <Redirect to={`/rigs/${this.state.company_guid}/${this.state.project_guid}`} />
        }

        console.log('torque_default', this.state.torque_default)
        let dp_test_type_selected = this.state.dp_test_type
        let dp_type = ''
        var dp_hammer = 0
        let dp_drop = 0
        let dp_cone = 0
        let dp_rod_diameter = 0
        let dp_rod_mass = 0
        if (dp_test_type_selected === 'Superheavy - 750mm drop') {
            dp_type = 'DPSH-B'
            dp_hammer = 63.5
            dp_drop = 750
            dp_cone = 49
            dp_rod_diameter = 32
            dp_rod_mass = 8
        } else if (dp_test_type_selected === 'Light - 500mm drop') {
            dp_type = 'DPL'
            dp_hammer = 10
            dp_drop = 500
            dp_cone = 34
            dp_rod_diameter = 22
            dp_rod_mass = 3
        } else if (dp_test_type_selected === 'Medium - 500mm drop') {
            dp_type = 'DPM'
            dp_hammer = 30
            dp_drop = 500
            dp_cone = 42
            dp_rod_diameter = 32
            dp_rod_mass = 6
        } else if (dp_test_type_selected === 'Heavy - 500mm drop') {
            dp_type = 'DPH'
            dp_hammer = 50
            dp_drop = 500
            dp_cone = 42
            dp_rod_diameter = 32
            dp_rod_mass = 6
        } else if (dp_test_type_selected === 'Superheavy - 500mm drop') {
            dp_type = 'DPSH-A'
            dp_hammer = 63.5
            dp_drop = 500
            dp_cone = 43
            dp_rod_diameter = 32
            dp_rod_mass = 6
        }

        // console.log('dp_test_type_selected: ', dp_test_type_selected)

        var dp_hammer_text = ''
        var dp_drop_text = ''
        var dp_cone_text = ''
        var dp_rod_diameter_text = ''
        var dp_rod_mass_text = ''
        if (dp_test_type_selected) {
            dp_hammer_text = dp_hammer + 'kg'
            dp_drop_text = dp_drop + 'mm'
            dp_cone_text = dp_cone + 'mm'
            dp_rod_diameter_text = dp_rod_diameter + 'mm'
            dp_rod_mass_text = dp_rod_mass + 'kg'
        }

        return (
            <>
                <Header toggleSidebar={this.props.toggleSidebar} />

                <Modal title="Add Rig" toggleModal={() => this.props.router.history.goBack()}>

                    <form onSubmit={this.save}>
                        <div className="w-full md:w-1/2 px-2 mx-auto">
                            <div className="text-center text-sm md:text-base mb-3 bg-orange text-white py-1 rounded">
                                *Note: Create any required SPT hammers before creating new rig!
                            </div>
                        </div>
                        <div className="lg:flex mb-10">
                            <div className="w-full lg:w-3/5 pl-1 lg:pl-2 pr-1">
                                <div className="border border-grey-mid rounded px-2 pt-2 pb-1 lg:pb-2 bg-sptTB mb-4">

                                    <div className="text-center text-lg font-bold mb-2">General Settings</div>

                                    <InputGroup label="Rig Name" value={this.state.name} required
                                        onChange={(value) => this.setState({ name: value })} className="font-bold md:text-lg" />

                                    <SelectGroup
                                        label="Plant Type"
                                        required
                                        values={[
                                            { value: 'Cable Percussive', label: 'Cable Percussive' },
                                            { value: 'Rotary', label: 'Rotary' },
                                            { value: 'Window Sample', label: 'Window Sample' },
                                        ]}
                                        onChange={(value) => this.setState({
                                            type: value.value,
                                        })}
                                        selected={this.state.type}
                                        className="mb-16 w-full h-4 md:text-lg pt-2" />

                                    {/* <InputGroup label="Plant Type" value={this.state.type}
                                                onChange={(value) => this.setState({type: value})} className="font-bold md:text-lg pt-2"/> */}

                                    <InputGroup label="Plant ID" value={this.state.plant_id} required
                                        onChange={(value) => this.setState({ plant_id: value })} className="font-bold md:text-lg pt-2" />

                                    <InputGroup label="Make / Model" value={this.state.make_model} required
                                        onChange={(value) => this.setState({ make_model: value })} className="font-bold md:text-lg pt-2" />

                                    <SelectGroup label="SPT ID" required
                                        selected={this.state.spt_guid}
                                        values={this.state.hammers}
                                        onChange={(value) => this.setState({
                                            spt_guid: value.value,
                                            spt_id_ref: value.label,
                                        })}
                                        className="md:text-lg pt-2"
                                    />


                                    <InputGroup type="date" label="Test Date Expiry"
                                        value={this.state.test_date_expiry}
                                        onChange={(value) => this.setState({ test_date_expiry: value })}
                                        className="mb-4 font-bold md:text-lg"
                                    />

                                    {/* <InputGroup label="Test Method" value={this.state.test_method}
                                                onChange={(value) => this.setState({test_method: value})}
                                                className="mb-10"/> */}
                                </div>
                            </div>

                            <div className="w-full lg:w-2/5 pl-1 lg:pl-1 pr-1 lg:pr-1">
                                <div className="border border-grey-mid rounded px-2 pt-2 bg-sptTB mb-2">
                                    <div className="text-center text-lg font-bold">Dynamic Probe Settings</div>
                                    <div className="text-center text-sm mb-2">(if applicable)</div>
                                    <SelectGroup
                                        label="Test Type"
                                        values={[
                                            { value: 'Superheavy - 750mm drop', label: 'Superheavy - 750mm drop' },
                                            { value: 'Light - 500mm drop', label: 'Light - 500mm drop' },
                                            { value: 'Medium - 500mm drop', label: 'Medium - 500mm drop' },
                                            { value: 'Heavy - 500mm drop', label: 'Heavy - 500mm drop' },
                                            { value: 'Superheavy - 500mm drop', label: 'Superheavy - 500mm drop' },
                                        ]}
                                        // onChange={(value) => this.setState({
                                        //     dp_test_type: value.value,
                                        //     dp_test_ref: value.label,
                                        // })} 
                                        onChange={this.handleTestTypeChange}
                                        selected={this.state.dp_test_type}
                                        className="mb-16 w-full h-4 md:text-lg" />

                                    <table className="table-auto w-full border border-grey-mid rounded mb-4 text-xs sm:text-base bg-sptSB">
                                        <tbody>
                                            <tr className={`border border-grey-mid`}>
                                                <td className={`px-1 py-1 border border-grey-mid border-r w-2/6`}>Hammer Mass:</td>
                                                <td className={`px-1 py-1 border border-grey-mid border-r w-1/6`}>{dp_hammer_text} </td>
                                                <td className={`px-1 py-1 border border-grey-mid border-r w-2/6`}>Standard Drop:</td>
                                                <td className={`px-1 py-1 w-1/6`}>{dp_drop_text} </td>
                                            </tr>
                                            <tr className={`border border-grey-mid`}>
                                                <td className={`px-1 py-1 border border-grey-mid border-r w-2/6`}>Cone Diameter:</td>
                                                <td className={`px-1 py-1 border border-grey-mid border-r w-1/6`}>{dp_cone_text} </td>
                                                <td className={`px-1 py-1 border border-grey-mid border-r w-2/6`}>Rod Diameter:</td>
                                                <td className={`px-1 py-1 w-1/6`}>{dp_rod_diameter_text} </td>
                                            </tr>
                                            <tr>
                                                <td className={`px-1 py-1 border border-grey-mid border-r w-2/6`}>Rod Mass:</td>
                                                <td className={`px-1 py-1 border border-grey-mid border-r w-1/6`}>{dp_rod_mass_text} </td>
                                                <td className={`px-1 py-1 border border-grey-mid border-r w-2/6`}></td>
                                                <td className={`px-1 py-1 w-1/6`}></td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <SelectGroup
                                        label="Torque Meter Type"
                                        values={[
                                            { value: '(kg/m)', label: 'Kilograms per metre (kg/m)' },
                                            { value: '(Nm)', label: 'Newton Metre (Nm)' }
                                        ]}
                                        onChange={(selectedOption) => this.setState({ torque_default: selectedOption.value })}
                                        selected={this.state.torque_default}
                                        className="mb-16 w-full h-4 md:text-lg"
                                    />


                                    <InputGroup label="Test Method" value={this.state.test_method} // {this.state.test_method}
                                        onChange={(value) => this.setState({ test_method: value })}
                                        className="font-bold md:text-lg" // placeholder="BS EN ISO 22476-2"
                                    />

                                    <div className="grid grid-cols-2 gap-2">

                                        <InputGroup label="Anvil Type" value={this.state.dprg_anvil_type}
                                            onChange={(value) => this.setState({ dprg_anvil_type: value })}
                                            className="font-bold md:text-lg pt-2"
                                        />

                                        <InputGroup label="Anvil Dampner" value={this.state.dprg_anvil_dampner}
                                            onChange={(value) => this.setState({ dprg_anvil_dampner: value })}
                                            className="font-bold md:text-lg mb-4 pt-2"
                                        />
                                    </div>

                                </div>
                            </div>

                        </div>

                        {/* <div className="col-span-full flex justify-center">
                            <Button type="submit" theme="primary" size="xl">Save</Button>
                        </div> */}

                        <div className="flex justify-between">
                            <div></div>
                            <div className="flex">
                                <Button onClick={this.handleCancelClick} theme="secondary" className="mr-2 md:mr-4 h-12 w-20 md:w-32 text-sm md:text-base">Cancel</Button>
                                <Button type="submit" theme="primary" className="mr-1 h-12 w-32 md:w-40 text-base">Save</Button>
                            </div>
                        </div>

                    </form>

                </Modal>
            </>
        )
    }
}

export default RigsCreate
