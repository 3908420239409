import React, { FunctionComponent, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Modal from '../../components/Modal'

import Header from '../../layout/Header'

import { editWeather } from '../../actions/Weather'

import getWeatherList from '../../helpers/getWeatherList'

import icon_indoors from '../../assets/icons/icon_indoors.svg'

interface Props {
	toggleSidebar: () => void,
	router: any,
}

const ProjectsWeather: FunctionComponent<Props> = ({ toggleSidebar, router }) => {
	const selectedWeather = useSelector((state: any) => state.weather)
	const dispatch = useDispatch()

	const selectWeather = (weather: string) => {
		dispatch(editWeather(weather))

		router.history.goBack()
	}

	return (
		<>
			<Header toggleSidebar={toggleSidebar} dark={true} />

			<Modal title="Edit Weather" toggleModal={() => {router.history.goBack()}} confirm={false}>
				<div className="px-4 md:px-10">
					<div className="grid grid-cols-3 sm:grid-cols-6 md:grid-cols-5 lg:grid-cols-8 gap-2 bg-sptTB rounded p-4 mt-2">
						{getWeatherList().map((weather, index) => {
							return (
								<div className={`text-center rounded bg-white ${weather.label === selectedWeather ? 'border-orange border-2' : 'border-grey-mid border'} px-1 py-1 h-22 md:h-36 text-xs md:text-sm`} key={index} onClick={() => {selectWeather(weather.label)}}>
									<div className=""><i className={weather.icon}></i></div>

									{weather.label}
								</div>
							)
						})}
						{/* <div className={`text-center rounded border-grey-mid border px-1 py-1 h-22 md:h-36 text-xs md:text-sm`} onClick={() => {selectWeather('Indoors')}}>
							<div className=""><img src={icon_indoors} className="h-12 w-12 mx-auto mt-6" alt="" /></div>

							Indoors
						</div> */}
					</div>
				</div>
			</Modal>
		</>
	)
}

export default ProjectsWeather
