import { EDIT_SOILDESCRIPTION_LENGTH } from '../action-types'

const defaultState: number = 1

const SoilDescriptionLengthReducer = (state = defaultState, action: any) => {
	switch (action.type) {
		case EDIT_SOILDESCRIPTION_LENGTH: {
			return action.payload.length
		}
		default: {
			return state
		}
	}
}

export default SoilDescriptionLengthReducer