import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

import Button from '../../../form/Button'

type Props = {
	comment: string,
	title: string,
	numberOfRows: number,
	setComment: any,
	[x: string]: any;
}

const defaultProps: Props = {
	comment: '',
	title: 'Comments',
	numberOfRows: 5,
	setComment: () => {},
}

const Comment: FunctionComponent<Props> = ({ comment, setComment, title, numberOfRows, ...rest }) => {
	return (
		<>
			<div className="w-full text-center text-base md:text-lg mb-2 font-bold">{title}</div>

			<textarea className="form-control w-full mb-2" rows={numberOfRows} onChange={(event) => setComment(event.target.value)} value={comment} {...rest}></textarea>
		</>
	)
}

Comment.defaultProps = defaultProps

export default Comment