import React from 'react'

import Modal from '../../../components/Modal'
import WorkflowSteps from '../../../components/WorkflowSteps'
import Table from '../../../components/Table'
import InputGroup from '../../../components/form/InputGroup'
import Button from '../../../components/form/Button'
import WorkflowActions from '../../../components/form/WorkflowActions'
import NewBoreholeSaveButton from '../../../components/form/NewBoreholeSaveButton'

import Header from '../../../layout/Header'

import stepStatus from '../../../helpers/stepStatus'

import plusIcon from '../../../assets/icons/plus-white.svg'
import { v4 as uuidv4 } from "uuid";
import { Redirect } from "react-router-dom";
import { get } from "idb-keyval";
import { useGetIDBValues } from "../../../helpers/getIDBKey";
import { useSetIndexedDBValues } from "../../../helpers/addToIDB";
import { usePostToApi } from "../../../helpers/postToAPI";
import { Link } from 'react-router-dom'
import CrewList from '../../CrewList'
import RigList from '../../RigList'
import warningIcon from '../../../assets/icons/warning.png'

interface Props {
    toggleSidebar: () => void,
    router: any,
}


type BoreholeName = {
    id: number,
    guid: string,
    hole_name: string,
};


type Borehole = {
    id: number,
    guid: string,
    hole_name: string,
};


interface State {
    projectCurrentStep: number,
    boreholes: any,
    clientName: string,
    clientContact: string,
    clientPhone: string,
    clientEmail: string,
    engineerName: string,
    engineerPhone: string,
    engineerEmail: string,
    siteLocation: string,
    startDate: string,
    endDate: string,
    startTime: string,
    projectNo: string,
    siteDescription: string,
    keySiteFeatures: string,
    siteHistory: string,
    keyHS: string,
    address1: string,
    address2: string,
    city: string,
    county: string,
    postcode: string,
    expectedStrata: string,
    notes: string,
    equipmentRequired: string,
    guid: string,
    company_guid: string,
    redirect: boolean,

    borehole_guid: string,
    holeName: string,
    boreholeToUpdate: any,
    boreholeInformation: any,
    updatedHoleName: string,

    rig_guid: string,
    rig_name: string,
    driller: string,
    driller_cscs: string,
    updatedDriller: any,
    second_man: string,
    second_man_cscs: string,
    updatedSecondMan: any,
    currentRigValue: string,
    currentRigLabel: string,
    currentSPT_guid: string,

    showSecondMan: boolean,
    showRig: boolean,
    showPlantCheck: boolean,
    showCrewList: boolean,
    showRigList: boolean,
    crewSelectionType: string,
    hole_barcode: string,
    hole_barcode_updated: string,
    
    showSaveButton: boolean,
}

class UpdateBorehole extends React.Component<Props, State> {
    constructor(props: any) {
        super(props)

        this.state = {
            projectCurrentStep: 5,
            boreholes: [] as Borehole[],
            clientName: '',
            clientContact: '',
            clientPhone: '',
            clientEmail: '',
            engineerName: '',
            engineerPhone: '',
            engineerEmail: '',
            siteLocation: '',
            startDate: '',
            endDate: '',
            startTime: '',
            projectNo: '',
            siteDescription: '',
            keySiteFeatures: '',
            siteHistory: '',
            keyHS: '',
            address1: '',
            address2: '',
            city: '',
            county: '',
            postcode: '',
            expectedStrata: '',
            notes: '',
            equipmentRequired: '',
            guid: this.props.router.match.params.id,
            company_guid: '',
            redirect: false,

            borehole_guid: this.props.router.match.params.borehole,
            holeName: '', // this.props.router.match.params.boreholeName,
            boreholeToUpdate: [],
            boreholeInformation: [],
            updatedHoleName: '',

            rig_guid: '',
            rig_name: this.props.router.match.params.rig,
            driller: this.props.router.match.params.driller,
            driller_cscs: '',
            updatedDriller: [],
            second_man: this.props.router.match.params.secondman,
            second_man_cscs: '',
            updatedSecondMan: [],
            currentRigValue: '',
            currentRigLabel: this.props.router.match.params.rig,
            currentSPT_guid: '',

            showSecondMan: false,
            showRig: false,
            showPlantCheck: false,
            showCrewList: false,
            showRigList: false,
            crewSelectionType: '',
            hole_barcode: '',
            hole_barcode_updated: '',

            showSaveButton: false,
        }
    }

    getCacheValues = async () => {
        let cachedValues = useGetIDBValues
        // get cached hole locations
        await cachedValues(`hole-locations/project/${this.state.guid}`, (data: any) => {
            this.setState({
                boreholes: data
            })
        })
            .then()
        await cachedValues(`projects/${this.state.guid}`, (data: any) => {
            this.setState({
                clientName: data.name,
                clientContact: data.client_contact,
                clientPhone: data.client_phone,
                clientEmail: data.client_email,
                engineerName: data.contractor_name,
                engineerPhone: data.engineer,
                engineerEmail: data.engineer_email,
                siteLocation: data.location,
                startDate: data.start_date,
                endDate: data.end_date,
                startTime: data.start_time,
                projectNo: data.project_identifier,
                siteDescription: data.site_description,
                keySiteFeatures: data.key_site_features,
                siteHistory: data.site_history,
                keyHS: data.key_hands,
                address1: data.address,
                address2: data.address2,
                city: data.town_city,
                county: data.county_state,
                postcode: data.postcode,
                expectedStrata: data.expected_strata,
                notes: data.notes,
                equipmentRequired: data.equipment_required,
                company_guid: data.company_guid,
            })
        }).then()


        // get the current rig if it's set
        get(`drillit-currentRig-${this.props.router.match.params.id}`)
            // get(`drillit-currentRig-${this.state.guid}`)
            .then(data => {
                // console.log('this.props.router.match.params.id:', this.props.router.match.params.id)
                // console.log('rig data:', data)
                if (data) {

                    this.setState({
                        currentRigValue: data.guid,
                        currentRigLabel: data.label,
                    })

                }
            }).then()

        type Rig = {
            guid: string;
            spt_guid: string;
        };

        type RigsArray = Rig[];

        // get the current rig SPT ID
        await cachedValues('rigs/' + this.state.currentRigValue, (rigData: RigsArray) => {
            if (rigData) {
                const desiredRigGuid = this.state.currentRigValue;
                const filteredRig = rigData.find((rig) => rig.guid === desiredRigGuid);

                if (filteredRig) {
                    // console.log('Filtered Rig:', filteredRig);

                    this.setState({
                        currentSPT_guid: filteredRig?.spt_guid,
                    });
                } else {
                    // console.log(`**ERROR** RIG DATA NOT LOADED. ID: ${currentRigValue}`);
                }
                // console.log('rigData:', rigData);
            }
        });

        this.getBoreholeName()
        this.updateBoreholes(this.state.boreholeToUpdate)
        this.getHoleInformation()
        // this.getDriller()
        // this.getSecondMan()



        this.setState({
            hole_barcode_updated: this.state.projectNo + '-' + this.state.holeName
        })

    }

    getBoreholeName = () => {

        // Assuming boreholes is an array of borehole objects
        // console.log('boreholes:', this.state.boreholes);
        const targetBorehole = this.state?.boreholes.find((borehole: BoreholeName) => borehole.guid === this.state.borehole_guid);

        // console.log('targetBorehole in CLASS:', targetBorehole)
        if (targetBorehole) {
            if (this.state.holeName !== targetBorehole.hole_name) {
                this.setState({
                    holeName: targetBorehole.hole_name,
                    boreholeToUpdate: targetBorehole,
                })
            }
            // console.log('Found hole_name:', this.state.holeName);
        } else {
            // console.log('Borehole with GUID not found.');
        }

    }

    componentDidMount() {
        this.getCacheValues()
    };

    next = (event: any) => {
        if (this.state.projectCurrentStep < 5) {
            this.setState({ projectCurrentStep: this.state.projectCurrentStep + 1 })

            return
        }

        // Form completed
        event.preventDefault()
        this.save()
    }




    save = () => {

        console.log('Boreholes on Save:', this.state.boreholes)
        // if on final step and boreholes is not empty then save it
        if ((this.state.projectCurrentStep === 5) && (this.state.boreholes.length > 0)) {

            const token = localStorage.getItem('drillit-token')

            fetch(`${process.env.REACT_APP_API_ADDR}hole-locations/project/json`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(this.state.boreholes)
            })
                //return the status code here
                .then(response => response)
                .catch(err => err)


        }
        console.log('boreholes AFTER FETCH TOKEN', this.state.boreholes)
        console.log('boreholeToUpdate', this.state.boreholeToUpdate)
        let saveToIDB = useSetIndexedDBValues
        saveToIDB({
            name: this.state.clientName,
            client_contact: this.state.clientContact,
            client_phone: this.state.clientPhone,
            client_email: this.state.clientEmail,
            engineer_name: this.state.engineerName,
            engineer_phone: this.state.engineerPhone,
            engineer_email: this.state.engineerPhone,
            location: this.state.siteLocation,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
            start_time: this.state.startTime,
            project_identifier: this.state.projectNo,
            site_description: this.state.siteDescription,
            key_site_features: this.state.keySiteFeatures,
            site_history: this.state.siteHistory,
            key_hands: this.state.keyHS,
            address: this.state.address1,
            address2: this.state.address2,
            town_city: this.state.city,
            county_state: this.state.county,
            postcode: this.state.postcode,
            expected_strata: this.state.expectedStrata,
            notes: this.state.notes,
            equipment_required: this.state.equipmentRequired,

            boreholes: this.state.boreholes, // <<<<<<<<<<<<<<<<<<<<<<<<<<< boreholes

            guid: this.state.guid,
            company_guid: this.state.company_guid,
        }, 'projects', false, 'PUT')
            .then(() => this.setState({ redirect: true }))
    }





    saveFormAndNext = () => {
        this.save();  // Call saveForm logic
    };





    handleHoleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Update the updatedHoleName in the state when the input value changes
        this.setState({ updatedHoleName: event.target.value });

        // // console.log('set barcode with new hole name')
        this.setState({
            hole_barcode_updated: this.state.projectNo + '-' + event.target.value
        })

    };

    handleUpdateClick = () => {

        // console.log('boreholes handleUpdateClick', this.state.boreholes)
        // console.log('boreholeToUpdate handleUpdateClick', this.state.boreholeToUpdate)
        
        const { boreholes, boreholeToUpdate, holeName, driller, driller_cscs, second_man, second_man_cscs, 
            currentRigValue, currentRigLabel, currentSPT_guid, hole_barcode } = this.state;

        // Update the boreholeToUpdate with the new hole_name
        const updatedBorehole: Borehole = {
            ...boreholeToUpdate,
            hole_name: holeName,
            location_identifier: holeName,
            driller: driller,
            driller_cscs: driller_cscs,
            second_man: second_man,
            second_man_cscs: second_man_cscs,
            rig_id: currentRigValue,
            rig_name: currentRigLabel,
            spt_id: currentSPT_guid,
            hole_barcode: this.state.projectNo + '-' + holeName,
        };

        console.log('updatedBorehole handleUpdateClick', updatedBorehole)

        // Find the index of the boreholeToUpdate in the boreholes array
        const updatedBoreholeIndex = boreholes.findIndex(
            (borehole: any) => borehole.guid === boreholeToUpdate.guid
        );

        // Create a new array with the updated borehole at the correct index
        const updatedBoreholes = [
            ...boreholes.slice(0, updatedBoreholeIndex),
            updatedBorehole,
            ...boreholes.slice(updatedBoreholeIndex + 1),
        ];

        // Update the state with the new boreholes array
        this.setState({ boreholes: updatedBoreholes });

        // Update Hole Info

        this.setState((prevState) => ({
            boreholeInformation: {
                ...prevState.boreholeInformation,
                rig_id: this.state.currentRigValue,
                rig_name: this.state.currentRigLabel,
                spt_id: this.state.currentSPT_guid,
                hole_barcode: this.state.projectNo + '-' + this.state.holeName,
                location_identifier: this.state.holeName,

            },
        })
        // , () => {
        //     // Now you can make the API request with the updated data
        //     let saveToIDB = useSetIndexedDBValues;
        //     const hole = `hole-locations/${this.state.borehole_guid}`;

        //     //   hole-locations/7be2d690-995e-4c28-930f-712f76ae5090 - Proj 777 - BH045

        //     console.log('this.state.boreholeInformation sent to IDB: ', this.state.boreholeInformation)

        //     //   saveToIDB('updated values', hole, false, 'PUT')
        //     saveToIDB(
        //         {
        //             ...this.state.boreholeInformation,
        //             // Optionally, you can include the 'update' field here if needed
        //             //   update: true,
        //         },
        //         hole,
        //         false,
        //         'PUT' // ('Add to IDB PROPS: values:', values, 'idbKey', idbKey, 'create:', create, 'update:', update)
        //     );
        // }
        );

        this.setState({
            showSaveButton: true
        })

    };


    getDriller = () => {
        get('drillit-user').then(data => {
            // console.log('drillit-user data:', data)
            this.setState({
                driller: data.user.first_name + ' ' + data.user.last_name,
                driller_cscs: data.user.cscs_no,
            })
        })
    }


    getSecondMan = () => {
        get('drillit-secondman').then(secondmanData => {
            if (secondmanData) {
                const firstName = secondmanData?.first_name
                const lastName = secondmanData?.last_name
                const fullName = `${firstName} ${lastName}`
                const cscs = secondmanData?.cscs_no
                this.setState({
                    second_man: fullName,
                    second_man_cscs: cscs,
                })
            }
        })
    }

    // Function to update boreholes array
    updateBoreholes = (updatedBorehole: Borehole) => {
        const { boreholes } = this.state;

        // Create a new array with the updated borehole
        const updatedBoreholes: Borehole[] = boreholes.map((borehole: Borehole) =>
            borehole.guid === updatedBorehole.guid ? updatedBorehole : borehole
        );

        // console.log('updatedBoreholes:', updatedBoreholes)
        // Update the state with the new array
        // this.setState({
        //   boreholes: updatedBoreholes,
        // });
    };

    handleCrewListClose = () => {
        this.setState({ showCrewList: false });
    };

    handleRigListClose = () => {
        this.setState({ showRigList: false });
    };

    handleRigSelect = (selectedRig: any) => {
        console.log('selectedRig************************************:', selectedRig)

        const rigName = selectedRig?.name;
        const rigGuid = selectedRig?.guid;
        const sptGuid = selectedRig?.spt_guid;

        this.setState(
            { 
                currentRigValue: rigGuid,
                currentRigLabel: rigName,
                currentSPT_guid: sptGuid,
             },
            () => {
                // console.log('this.state.driller in handleCrewSelect***********************:', this.state.driller);
                this.handleRigUpdate(); // Call handleRigUpdate in the callback
            }
        );

    }

    handleCrewSelect = (selectedUser: any) => {
        // console.log('selectedUser************************************:', selectedUser)
        // console.log('this.state.crewSelectionType************************************:', this.state.crewSelectionType)

        if (this.state.crewSelectionType === 'driller') {
            this.setState({ updatedDriller: selectedUser });

            const firstName = selectedUser?.first_name;
            const lastName = selectedUser?.last_name;
            const fullName = `${firstName} ${lastName}`;
            const cscs = selectedUser?.cscs_no;

            this.setState(
                { 
                    driller: fullName,
                    driller_cscs: cscs,
                 },
                () => {
                    // console.log('this.state.driller in handleCrewSelect***********************:', this.state.driller);
                    this.handleDrillerUpdate(); // Call handleDrillerUpdate in the callback
                }
            );


        } else if (this.state.crewSelectionType === 'secondMan') {
            this.setState({ updatedSecondMan: selectedUser });
            const firstName = selectedUser?.first_name
            const lastName = selectedUser?.last_name
            const fullName = `${firstName} ${lastName}`
            const cscs = selectedUser?.cscs_no
            this.setState(
                { 
                    second_man: fullName,
                    second_man_cscs: cscs,
                 },
                () => {
                    console.log('this.state.second_man in handleCrewSelect*************************************:', this.state.second_man);
                    this.handleSecondManUpdate(); // Call handleSecondManUpdate in the callback
                }
            );
        }
    }

    updateDriller = (newDriller: any, newDrillerCSCS:any) => {
        // Assuming you're using setState with a callback to ensure the state is updated before sending the request
        this.setState((prevState) => ({
            boreholeInformation: {
                ...prevState.boreholeInformation,
                driller: newDriller,
                driller_cscs: newDrillerCSCS,
                // rig_id: this.state.currentRigValue,
                // rig_name: this.state.currentRigLabel,
                // spt_id: this.state.currentSPT_guid,
                // hole_barcode: this.state.projectNo + '-' + this.state.holeName,
                // location_identifier: this.state.holeName,

            },
        })
            , () => {
                // Now you can make the API request with the updated data
                let saveToIDB = useSetIndexedDBValues;
                const hole = `hole-locations/${this.state.borehole_guid}`;

                //   hole-locations/7be2d690-995e-4c28-930f-712f76ae5090 - Proj 777 - BH045

                console.log('this.state.boreholeInformation sent to IDB: ', this.state.boreholeInformation)

                //   saveToIDB('updated values', hole, false, 'PUT')
                saveToIDB(
                    {
                        ...this.state.boreholeInformation,
                        // Optionally, you can include the 'update' field here if needed
                        //   update: true,
                    },
                    hole,
                    false,
                    'PUT' // ('Add to IDB PROPS: values:', values, 'idbKey', idbKey, 'create:', create, 'update:', update)
                );
            }
        );
    };

    handleDrillerUpdate = () => {
        const newDriller = this.state.driller;
        const newDrillerCSCS = this.state.driller_cscs;
        console.log('handleDrillerUpdate: newDriller to SAVE:', newDriller)
        console.log('handleDrillerUpdate: newDrillerCSCS to SAVE:', newDrillerCSCS)
        console.log('handleDrillerUpdate: Before Update - boreholeInformation:', this.state.boreholeInformation);
        this.updateDriller(newDriller, newDrillerCSCS);

        console.log('handleDrillerUpdate: After Update - boreholeInformation:', this.state.boreholeInformation);
    };

    handleRigUpdate = () => {
        // const newDriller = this.state.driller;
        // const newDrillerCSCS = this.state.driller_cscs;
        // console.log('handleDrillerUpdate: newDriller to SAVE:', newDriller)
        // console.log('handleDrillerUpdate: newDrillerCSCS to SAVE:', newDrillerCSCS)
        // console.log('handleDrillerUpdate: Before Update - boreholeInformation:', this.state.boreholeInformation);
        // this.updateDriller(newDriller, newDrillerCSCS);

        // console.log('handleDrillerUpdate: After Update - boreholeInformation:', this.state.boreholeInformation);
    };

    updateSecondMan = (newSecondMan: any, newSecondManCSCS:any) => {

        // console.log('updateSecondMan: ', newSecondMan)
        // Assuming you're using setState with a callback to ensure the state is updated before sending the request
        this.setState((prevState) => ({
            boreholeInformation: {
                ...prevState.boreholeInformation,
                second_man: newSecondMan,
                second_man_cscs: newSecondManCSCS,
                // rig_id: this.state.currentRigValue,
                // rig_name: this.state.currentRigLabel,
                // spt_id: this.state.currentSPT_guid,
                // hole_barcode: this.state.projectNo + '-' + this.state.holeName,
                // location_identifier: this.state.holeName,

            },
        })
            , () => {
                // Now you can make the API request with the updated data
                let saveToIDB = useSetIndexedDBValues;
                const hole = `hole-locations/${this.state.borehole_guid}`;

                //   hole-locations/7be2d690-995e-4c28-930f-712f76ae5090 - Proj 777 - BH045

                  console.log('this.state.boreholeInformation sent to IDB: ', this.state.boreholeInformation)

                //   saveToIDB('updated values', hole, false, 'PUT')
                saveToIDB(
                    {
                        ...this.state.boreholeInformation,
                        // Optionally, you can include the 'update' field here if needed
                        //   update: true,
                    },
                    hole,
                    false,
                    'PUT' // ('Add to IDB PROPS: values:', values, 'idbKey', idbKey, 'create:', create, 'update:', update)
                );
            }
        );
    };

    handleSecondManUpdate = () => {
        const newSecondMan = this.state.second_man;
        const newSecondManCSCS = this.state.second_man_cscs;
        //   console.log('SecondMan to SAVE:', newSecondMan)
        //   console.log('Before Update - boreholeInformation:', this.state.boreholeInformation);
        this.updateSecondMan(newSecondMan, newSecondManCSCS);

        // console.log('After Update - boreholeInformation:', this.state.boreholeInformation);
    };

    getHoleInformation = () => {
        let cachedValues = useGetIDBValues;
        // hole-locations/7be2d690-995e-4c28-930f-712f76ae5090
        const hole = `hole-locations/${this.state.borehole_guid}`;

        cachedValues(hole, (data: any) => {
            if (data) {

                // Extract info from the response

                const driller = data.driller;
                const drillerCSCS = data.driller_cscs;
                const second_man = data.second_man;
                const second_manCSCS = data.second_man_cscs;
                const rig_id = data.rig_id;
                const rig_name = data.rig_name;
                const spt_id = data.spt_id;

                if (driller === '') {
                    this.getDriller()
                } else {
                    this.setState({
                        driller: driller,
                        driller_cscs: drillerCSCS,
                    });
                }

                if (second_man === '') {
                    this.getSecondMan();
                } else {
                    this.setState({
                        second_man: second_man,
                        second_man_cscs: second_manCSCS,
                    });
                }

                // Set the state with the users
                this.setState({
                    boreholeInformation: data,
                    // driller: driller,
                    // driller_cscs: drillerCSCS,
                    // second_man: second_man,
                    // second_man_cscs: second_manCSCS,
                });

                // console.log('boreholeInformation data:', data);
            } else {
                // Handle error case if needed
                // console.error('Error fetching users:', data.message);
            }
        });
    };




    render() {
        // console.log('boreholes in CLASS:', this.state.boreholes)
        console.log('boreholeToUpdate in CLASS:', this.state.boreholeToUpdate)
        // console.log('boreholeInformation in CLASS:', this.state.boreholeInformation)
        // console.log('driller:', this.state.driller)
        // console.log('updatedDriller:', this.state.updatedDriller)
        console.log('second_man:', this.state.second_man)
        // console.log('holeName:', this.state.holeName)
        // console.log('crewSelectionType:', this.state.crewSelectionType)
        // console.log('currentSPT_guid:', this.state.currentSPT_guid)
        // console.log('projectNo:', this.state.projectNo)
        // console.log('holeName:', this.state.holeName)
        // console.log('hole_barcode:', this.state.projectNo + '-' + this.state.holeName)
        console.log('hole_barcode_updated:', this.state.hole_barcode_updated)


        // console.log('this.props.router.match.params.rig:', this.props.router.match.params.rig)

        const { redirect } = this.state

        if (redirect) {
            return <Redirect to={`/projects/dashboard/${this.props.router.match.params.id}/cable-percussive/${this.state.borehole_guid}`} />
        }

        const holeType = this.props.router.match.params.type // "CP"
        // console.log('holeType:', holeType)
        let hole_type = ''
        let hole = ''
        let plantCheck = false
        let requiredDepth = false
        let otherRequirements = false
        let rig = false
        let userType = ''
        let secondMan = false
        let plantCheckCP = false
        let plantCheckRT = false
        let plantCheckWSDP = false
        let plantCheckLink = ''
        let hole_type_Select = 1
        let starting_hole_type_id = 1
        let buttonText = 'Update Borehole'
        let buttonTextFollowOn = 'Create Follow On Borehole'
        let followOnHole_temp = ''
        let createdText = 'Edited Borehole:'

        let infoIcon = (<img src={warningIcon} alt="info" width="20" className="inline-block mr-2 pb-2" />)

        if (holeType === 'CP') {
            hole_type = 'Cable Percussive'
            hole = 'Cable Borehole';
            if (this.state.showSecondMan === false) {
                this.setState({
                    showSecondMan: true,
                    showRig: true,
                    showPlantCheck: true,
                })
            }
            plantCheck = true;
            rig = true;
            secondMan = true;
            userType = 'Driller';
            secondMan = true;
            requiredDepth = true; otherRequirements = true;
            plantCheckCP = true;
            plantCheckLink = '/daily-plant-check-cable-percussive/0000/' + this.state.company_guid + '/' + this.state.guid
            hole_type_Select = 1
            starting_hole_type_id = 1
        }
        else if (holeType === 'WS') {
            hole_type = 'Window Sample'
            hole = 'Window Sample'; plantCheck = true; rig = true;
            if (this.state.showSecondMan === false) {
                this.setState({
                    showSecondMan: true,
                    showRig: true,
                    showPlantCheck: true,
                })
            }
            userType = 'Driller'; secondMan = true; requiredDepth = true; otherRequirements = true;
            plantCheckWSDP = true;
            plantCheckLink = '/daily-plant-check-window-sample/0000/' + this.state.company_guid + '/' + this.state.guid
            hole_type_Select = 2
            starting_hole_type_id = 2
        }
        else if (holeType === 'RT') {
            hole_type = 'Rotary'
            hole = 'Rotary Borehole'; plantCheck = true; rig = true;
            if (this.state.showSecondMan === false) {
                this.setState({
                    showSecondMan: true,
                    showRig: true,
                    showPlantCheck: true,
                })
            }
            userType = 'Driller'; secondMan = true; requiredDepth = true; otherRequirements = true;
            plantCheckRT = true;
            plantCheckLink = '/daily-plant-check-rotary/0000/' + this.state.company_guid + '/' + this.state.guid
            hole_type_Select = 3
            starting_hole_type_id = 3
        }
        else if (holeType === 'TP') {
            hole_type = 'Trialpit'
            userType = 'Engineer'; hole = 'Trialpit'; requiredDepth = true; otherRequirements = true;
            buttonText = 'Update Trialpit'
            hole_type_Select = 4
            starting_hole_type_id = 4
            createdText = 'Updated Trialpit:'
        }
        else if (holeType === 'DP') {
            hole_type = 'Dynamic Probe';
            hole = 'Dynamic Probe'; plantCheck = true; rig = true;
            if (this.state.showSecondMan === false) {
                this.setState({
                    showSecondMan: true,
                    showRig: true,
                    showPlantCheck: true,
                })
            }
            userType = 'Driller'; secondMan = true; requiredDepth = true;
            plantCheckWSDP = true;
            plantCheckLink = '/daily-plant-check-window-sample/0000/' + this.state.company_guid + '/' + this.state.guid
            buttonText = 'Update Probe'
            hole_type_Select = 5
            starting_hole_type_id = 5
            createdText = 'Updated Dynamic Probe:'
        }
        else if (holeType === 'HT') {
            hole_type = 'Head Test'; hole = 'Head Test'; userType = 'Engineer'; buttonText = 'Update Head Test'
            hole_type_Select = 6
            starting_hole_type_id = 6
            createdText = 'Updated Head Test:'
        }
        else if (holeType === 'LG') {
            hole_type = 'Logging'; hole = 'Borehole'; userType = 'Engineer';

            hole_type_Select = 1 // or 2 or 3 depending on hole type... discuss
            starting_hole_type_id = 1
        }
        else if (holeType === 'SA') {
            hole_type = 'Soakaway'; hole = 'Soakaway'; userType = 'Engineer'; requiredDepth = true; buttonText = 'Update Soakway'
            hole_type_Select = 7
            starting_hole_type_id = 7
            createdText = 'Updated Soakaway:'
        }
        else if (holeType === 'CB') {
            hole_type = 'CBR'; hole = 'CBR'; userType = 'Engineer'; buttonText = 'Update CBR'
            hole_type_Select = 8
            starting_hole_type_id = 8
            createdText = 'Updated CBR:'
        }
        else if (holeType === 'PT') {
            hole_type = 'Plate Test'; hole = 'Plate Test'; userType = 'Engineer'; buttonText = 'Update Plate Test'
            hole_type_Select = 9
            starting_hole_type_id = 9
            createdText = 'Updated Plate Test:'
        }
        else if (holeType === 'TR') {
            hole_type = 'TRL'; hole = 'TRL'; userType = 'Engineer'; buttonText = 'Update TRL'
            hole_type_Select = 10
            starting_hole_type_id = 10
            createdText = 'Updateed TRL:'
        }

        const inputLabel = hole + ' Name'

        const buttonTextSave = 'Save ' + hole









        return (
            <>

                <Header toggleSidebar={this.props.toggleSidebar} dark={true} />

                <Modal title={buttonText} toggleModal={() => this.props.router.history.goBack()}>


                    {this.state.showCrewList &&

                        <CrewList
                            selectionType={this.state.crewSelectionType}
                            onClose={this.handleCrewListClose}
                            onSelectItem={this.handleCrewSelect}
                        />
                    }


                    {this.state.showRigList &&

                        <RigList
                            onClose={this.handleRigListClose}
                            onSelectItem={this.handleRigSelect}
                            companyGuid={this.state.company_guid}
                        />
                    }


                    <div className="border border-grey-mid rounded px-2 pt-2 bg-sptTB mb-4 w-full sm:w-5/6 mx-auto text-center">
                        <div className="w-full md:w-2/3 lg:w-1/3 mx-auto mb-2 text-xl">

                            {inputLabel}


                            <InputGroup label=""
                                value={this.state.holeName}
                                placeholder={this.state.holeName === '' ? 'loading' : ''}
                                onChange={(value) => this.setState({ holeName: value })}
                                className="mb-1 text-sm sm:text-base md:text-base w-full"
                                inputCSS="text-sm sm:text-base md:text-2xl w-full py-0 font-bold text-center"
                            />

                        </div>

                        {/* <div className="text-center text-xs w-full">{this.state.borehole_guid}</div> */}

                    </div>

                    <div className="border border-grey-mid rounded px-2 py-2 bg-sptTB mb-4 w-full sm:w-5/6 mx-auto">

                        <table className="table-auto w-full md:w-5/6 text-sm text-center bg-white border border-grey-mid mx-auto text-base md:text-xl">
                            <tbody>
                                <tr>
                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                        {userType}:
                                    </td>
                                    <td className={`w-8/12 md:w-8/12 lg:w-1/2 p-1 pl-2 border-r border-b border-grey-mid text-left`}>
                                        {/* <div className="w-full" onClick={openCrewListForDriller}> */}
                                        <div className="w-full"
                                            onClick={() => {
                                                this.setState({
                                                    crewSelectionType: 'driller',
                                                    showCrewList: true,
                                                })
                                            }}
                                        >
                                            {this.state.driller !== '' ? (
                                                <div>
                                                    {/* Content to render when this.state.driller is set */}
                                                    {this.state.driller}
                                                </div>
                                                ) : (
                                                <div className="text-sm pl-2 text-blue">
                                                    {/* Loading indicator while waiting for this.state.driller */}
                                                    loading 
                                                    {/* <LoadingIndicator /> */}
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                                {this.state.showSecondMan &&
                                    <tr>
                                        <td className={`p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                            {this.state.second_man === '' && (
                                                <img src={warningIcon} alt="info" width="20" className="inline-block mr-2 pt-0 pb-1" />
                                            )}
                                            2nd Man:
                                        </td>
                                        <td className={`p-1 pl-2 border-r border-b border-grey-mid text-left`}>

                                            {/* <div className="w-full" onClick={openCrewListForSecondMan}> */}
                                            <div className="w-full"
                                                onClick={() => {
                                                    this.setState({
                                                        crewSelectionType: 'secondMan',
                                                        showCrewList: true,
                                                    })
                                                }}>
                                                {this.state.second_man !== '' ? (
                                                    <div>
                                                        {/* Content to render when this.state.driller is set */}
                                                        {this.state.second_man}
                                                    </div>
                                                    ) : (
                                                    <div className="text-sm pl-2 text-blue">
                                                        {/* Loading indicator while waiting for this.state.driller */}
                                                        loading
                                                        {/* <LoadingIndicator /> */}
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                }

                                {this.state.showRig &&
                                    <>
                                    <tr>
                                        <td className={`p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                            {this.state.currentRigLabel === '' && (
                                                <img src={warningIcon} alt="info" width="20" className="inline-block mr-2 pt-0 pb-1" />
                                            )}
                                            Rig:
                                        </td>
                                        <td className={`p-1 pl-2 border-r border-b border-grey-mid text-left`}>

                                        <div className="w-full"
                                            onClick={() => {
                                                this.setState({
                                                    showRigList: true,
                                                })
                                            }}
                                            >
                                            {this.state.currentRigLabel !== '' ? (
                                                <div>
                                                    {/* Content to render when this.state.driller is set */}
                                                    {this.state.currentRigLabel}
                                                </div>
                                                ) : (
                                                <div className="text-sm pl-2 text-blue">
                                                    {/* Loading indicator while waiting for this.state.driller */}
                                                    loading 
                                                    {/* <LoadingIndicator /> */}
                                                </div>
                                            )}
                                        </div>

                                            {/* <Link to={`/rigs/0000/${this.state.company_guid}/${this.props.router.match.params.id}`}>
                                                <div className="w-full h-full">
                                                    {this.state.currentRigLabel !== '' ? (
                                                        <div>
                                                            {this.state.currentRigLabel}
                                                        </div>
                                                        ) : (
                                                        <div className="text-sm pl-2 text-blue">
                                                            loading
                                                        </div>
                                                    )}
                                                </div>
                                            </Link> */}

                                        </td>
                                    </tr>
                                </>
                                }

                                {this.state.showPlantCheck &&
                                    <tr>
                                        <td className={`px-0 py-2  border-r border-grey-mid text-center text-base md:text-xl font-bold`} colSpan={2}>
                                            <img src={warningIcon} alt="info" width="22" className="inline-block mr-2 pt-0 pb-1" />
                                            <Link to={plantCheckLink}>
                                                Daily Plant Check Not Completed Today!
                                            </Link>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>

                        <div className="mt-4 mb-2">
                            <Button
                                theme="primary"
                                size="lg"
                                className="btn-lg m-auto w-60"
                                type="submit"
                                // type="button" 
                                // onClick={(event:any) => saveForm(event)}
                                onClick={() => this.handleUpdateClick()}
                            >
                                {buttonText}
                            </Button>
                        </div>

                    </div>

                    <div className="px-2 sm:px-10">


                        <>
                            {/* <EditBoreholes 
								defaultBoreholes={this.state.boreholes} 
								projectId={this.state.guid} 
								company_guid={this.state.company_guid}
								// onChange={(boreholes: any[]) => {console.log(this.state.guid);this.setState({boreholes: boreholes})}} 

								onChange={(boreholes: any[]) => {
									console.log(this.state.guid);
									this.setState({ boreholes: boreholes }, () => {
										// This callback is executed after the state is updated
										console.log('State updated:', this.state.boreholes);
									});
								}}
								
								hole_guid={this.state.borehole_guid}
								holeType="CP"
								ALLboreholes={this.state?.boreholes}
								onBoreholeUpdate={this.handleBoreholeUpdate}
								rig_guid={this.state.rig_guid}
								driller_name={this.state.driller}
								secondManName={this.state.second_man}
								/> */}

                            { this.state.showSaveButton &&
                                <form onSubmit={this.next}>
                                    <div className="panelFooter pt-5 pb-5 sm:pb-4 md:pb-10 px-4 md:px-14">
                                        {/* <WorkflowActions canExit={false}
                                        // onBack={() => this.previous()} 
                                        /> */}
                                        <NewBoreholeSaveButton canExit={false} 
                                        // onBack={() => this.previous()} 
                                        button_text = {buttonTextSave}
                                        />
                                    </div>
                                </form>
                            }
                            
                        </>
                    </div>
                </Modal>
            </>
        )
    }
}

export default UpdateBorehole
