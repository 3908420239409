import { del, set, get } from 'idb-keyval';

export const updateLogInformation = (
    guid: string,
    project_id: number,
    project_name: string,
    project_location: string,
    hole_type_id: number,
    driller_id: number,
    company_id: number,
    hole_name: string,
    log_date: string,
    project_guid: string,
    hole_guid: string,
    log_url: string,
    log_save_date_time: string,
) => {
    return new Promise((resolve, reject) => {
        const endpoint = `${process.env.REACT_APP_API_ADDR}logs/${guid}`;
        const updateData = {
            guid,
            project_id,
            project_name,
            project_location,
            hole_type_id,
            driller_id,
            company_id,
            hole_name,
            log_date,
            project_guid,
            hole_guid,
            log_url,
            log_save_date_time,
        };

        // Check if an update file already exists
        get(`drillit-logs/${guid}-update`)
            .then(existingUpdateData => {
                if (existingUpdateData) {
                    // Replace existing update data with the new data
                    set(`drillit-logs/${guid}-update`, updateData)
                        .then(() => {
                            console.log(`Replaced existing update entry: drillit-logs/${guid}-update`);
                        })
                        .catch(error => {
                            console.error(`Error replacing update entry: drillit-logs/${guid}-update`, error);
                            reject(error);
                        });
                } else {
                    // No existing update entry, proceed with normal update
                    makeRequest(endpoint, JSON.stringify(updateData), 'PUT')
                        .then(response => {
                            // Update the log entry in IndexedDB regardless of the API response
                            updateLogInIDB(guid, updateData);

                            if (response.status === 200) {
                                console.log(`Successfully updated log data to API for ${guid}`);
                                // Remove the -update entry if the API response is successful
                                del(`drillit-logs/${guid}-update`)
                                    .then(() => {
                                        console.log(`Deleted update entry: drillit-logs/${guid}-update`);
                                    })
                                    .catch(error => {
                                        console.error(`Error deleting update entry: drillit-logs/${guid}-update`, error);
                                    });
                            } else {
                                console.error(`Error updating log data to API for ${guid}: ${response.statusText}`);
                                // Create or update the -update entry if the API response is not successful
                                set(`drillit-logs/${guid}-update`, updateData)
                                    .then(() => {
                                        console.log(`Created or updated update entry: drillit-logs/${guid}-update`);
                                    })
                                    .catch(error => {
                                        console.error(`Error creating or updating update entry: drillit-logs/${guid}-update`, error);
                                    });
                            }
                        })
                        .catch(err => {
                            console.error(`Error updating log data to API for ${guid}:`, err);
                            // Create or update the -update entry if the API request fails
                            set(`drillit-logs/${guid}-update`, updateData)
                                .then(() => {
                                    console.log(`Created or updated update entry: drillit-logs/${guid}-update`);
                                })
                                .catch(error => {
                                    console.error(`Error creating or updating update entry: drillit-logs/${guid}-update`, error);
                                });
                            reject(err);
                        });
                }
                resolve(true);
            })
            .catch(error => {
                console.error(`Error checking existing update entry: drillit-logs/${guid}-update`, error);
                reject(error);
            });
    });
};

const updateLogInIDB = (guid: string, updateData: any) => {
    get('drillit-logs')
        .then((logs: any[]) => {
            if (logs && logs.length > 0) {
                const logIndex = logs.findIndex(log => log.guid === guid);
                if (logIndex > -1) {
                    // Update the existing log entry
                    logs[logIndex] = { ...logs[logIndex], ...updateData };

                    // Update the logs array in IndexedDB
                    set('drillit-logs', logs)
                        .then(() => {
                            console.log(`Successfully updated log in IndexedDB for ${guid}`);
                        })
                        .catch(error => {
                            console.error('Error updating log in IndexedDB', error);
                        });
                } else {
                    console.error(`Log with guid ${guid} not found in IndexedDB`);
                }
            } else {
                console.error('No logs found in IndexedDB');
            }
        })
        .catch(error => {
            console.error('Error getting logs from IndexedDB', error);
        });
};

const makeRequest = (endpoint: string, data: string, method: string) => {
    const token = localStorage.getItem('drillit-token');

    return fetch(endpoint, {
        method,
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: data,
    })
        .then(response => response)
        .catch(err => err);
};
