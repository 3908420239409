import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import Modal from '../components/Modal'
import ModalSmall from '../components/ModalSmall'
import closeIcon from '../assets/icons/close.svg'
import { get, entries, keys, clear, del, set } from 'idb-keyval'
import Button from '../components/form/Button'
import TextArea from '../components/form/TextArea'

import { useSetIndexedDBValues } from '../helpers/addToIDB'
import { useUpdateIndexedDBValues } from "../helpers/updateIDB"
import { useDeleteIndexedDBValue } from "../helpers/deleteFromIDB"
import { useGetLoggedInUser } from '../helpers/getLoggedInUser'

import logoOuter from '../assets/logo-icon-outer.svg'
import logoCenter from '../assets/logo-icon-center.svg'
import check from '../assets/icons/check.svg'

interface DataType {
    id: number; // Adjust the type according to your actual data structure
    // Add other properties as needed
}

type EntryType = [IDBValidKey, any]; // Define a type for entries

function Sync() {
    const [data, setData] = useState<DataType[] | null>(null);
    const [objectStoreNames, setObjectStoreNames] = useState<string[]>([]);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)
    const [recordToDelete, setRecordToDelete] = useState<string | null>(null);

    const history = useHistory();
    const [upToDate, setUpToDate] = useState<boolean>(true)
    const [IDBdetails, setIDBdetails] = useState<boolean>(false)
    const [showResendButton, setShowResendButton] = useState<boolean>(true)

    const toggleIDBdetails = () => {
        setIDBdetails(prevState => !prevState);
    };

    const [entriesList, setEntriesList] = useState<EntryType[]>([]);

    // console.log('entriesList:', entriesList)

    const saveToIDB = useSetIndexedDBValues

    const [isIdbModalOpen, setIsIdbModalOpen] = useState(false);
    // Define a type for the JSX elements array
    type JSXElementArray = JSX.Element[];

    // Inside your component function...
    const [idbModalContent, setIdbModalContent] = useState<JSXElementArray>([]);

    const table = document.createElement('table');
    table.classList.add('idb-entry-table'); // Add a CSS class for styling

    // console.log('SYNC****')

    useEffect(() => {
        // Open connection to the IndexedDB database
        const request = indexedDB.open('drillit');

        // get('drillit-currentRig').then((val) => console.log('drillit-currentRig', val));
        entries().then((entries) => console.log(entries)); // console.log ALL IDB entries
    }, []);





    useEffect(() => {
        const fetchData = async () => {
            try {
                const fetchedEntries = await entries();
                const relevantEntries = fetchedEntries.filter(([key, value]: EntryType) => {
                    const keyString = String(key);
                    
                    // Exclude entries that start with "drillit-projects"
                    if (keyString.startsWith('drillit-projects')) {
                        return false;
                    }

                    // Include entries that end with "-new", "-update", or "-delete"
                    return keyString.endsWith('-new') || keyString.endsWith('-update') || keyString.endsWith('-delete');
                });

                setEntriesList(relevantEntries);
                setUpToDate(relevantEntries.length === 0);

                // Call renderEntries to display the data
                renderEntries(relevantEntries);
            } catch (error) {
                console.error('Error fetching entries:', error);
            }
        };

        fetchData();
    }, []);
    


    // // Function to render entries in a table
    // const renderEntries = (entries: EntryType[]) => {
    //     const container = document.getElementById('data-container');
    //     if (!container) {
    //         console.log('Container not found');
    //         return;
    //     }

    //     // Clear the container before appending new entries
    //     container.innerHTML = '';

    //     // Create a table element to hold the entries
    //     const table = document.createElement('table');
    //     table.classList.add('idb-entry-table'); // Add a CSS class for styling

    //     // Iterate through the entries and create table rows for each entry
    //     entries.forEach(([key, value]) => {
    //         const keyString = String(key);

    //         // Create a table row for the entry
    //         const row = document.createElement('tr');

    //         // Create a cell for the title
    //         const titleCell = document.createElement('td');
    //         titleCell.textContent = keyString;

    //         // Attach a click event listener to the title cell
    //         titleCell.addEventListener('click', () => {
    //             // Assuming 'value' contains the entry content
    //             const entryContent = value;

    //             // Open the modal and set the content
    //             setIsIdbModalOpen(true);
    //             setIdbModalContent(renderContent(entryContent));
    //         });

    //         // Create a cell for the delete button
    //         const deleteCell = document.createElement('td');
    //         const deleteButton = document.createElement('button');
    //         deleteButton.textContent = 'Delete';
    //         deleteButton.addEventListener('click', () => {
    //             // Call the handleDelete function to handle the delete operation
    //             handleDelete(key, row); // Pass the table row for removal
    //         });
    //         deleteCell.appendChild(deleteButton);

    //         // Append cells to the row
    //         row.appendChild(titleCell);
    //         row.appendChild(deleteCell);

    //         // Append the row to the table
    //         table.appendChild(row);
    //     });

    //     // Append the table to the container
    //     container.appendChild(table);
    // };
    

    // const renderEntries = (entries: EntryType[]) => {
    //     const container = document.getElementById('data-container');
    //     if (!container) {
    //         console.log('Container not found');
    //         return;
    //     }
    
    //     // Clear the container before appending new entries
    //     container.innerHTML = '';
    
    //     // Create a table element to hold the entries
    //     const table = document.createElement('table');
    //     table.classList.add('idb-entry-table', 'min-w-full', 'border-collapse'); // Add Tailwind classes for styling
    
    //     // Create table header if needed
    //     const headerRow = document.createElement('tr');
    //     ['IDB Key', 'Resend', 'Delete'].forEach(text => {
    //         const headerCell = document.createElement('th');
    //         headerCell.textContent = text;
    //         headerCell.classList.add('p-2', 'border-b'); // Add padding and border styles
    //         headerRow.appendChild(headerCell);
    //     });
    //     table.appendChild(headerRow);
    
    //     // Iterate through the entries and create table rows for each entry
    //     entries.forEach(([key, value]) => {
    //         const keyString = String(key);
    
    //         // Determine the process function based on the key
    //         const matchingProcess = processFunctions.find(({ prefix, suffix }) =>
    //             keyString.startsWith(prefix) && keyString.endsWith(suffix)
    //         );
    
    //         // Create a table row for the entry
    //         const row = document.createElement('tr');
    //         row.setAttribute('data-key', keyString); // Optional: Add data attribute for easier manipulation
    
    //         // Create a cell for the title
    //         const titleCell = document.createElement('td');
    //         titleCell.textContent = keyString;
    //         titleCell.classList.add('p-2', 'border-b'); // Add padding and border styles
    
    //         // Attach a click event listener to the title cell
    //         titleCell.addEventListener('click', () => {
    //             const entryContent = value;
    //             setIsIdbModalOpen(true);
    //             setIdbModalContent(renderContent(entryContent));
    //         });
    
    //         // Create a cell for the delete button
    //         const deleteCell = document.createElement('td');
    //         deleteCell.classList.add('w-10', 'text-center', 'border-b'); // Add width and center alignment
    //         const deleteButton = document.createElement('button');
    //         deleteButton.textContent = 'X';
    //         deleteButton.classList.add('bg-red', 'text-white', 'py-1', 'px-2', 'rounded', 'text-center'); // Style the button
    //         deleteButton.addEventListener('click', () => {
    //             handleDelete(key, row);
    //         });
    //         deleteCell.appendChild(deleteButton);
    
    //         // Create a cell for the resend button
    //         const resendCell = document.createElement('td');
    //         resendCell.classList.add('w-10', 'text-center', 'border-b'); // Add width and center alignment
    //         const resendButton = document.createElement('button');
    //         resendButton.textContent = 'Resend';
    //         resendButton.disabled = !matchingProcess;
    //         resendButton.classList.add('bg-green-checkmark', 'text-white', 'py-1', 'px-2', 'rounded', 'text-center'); // Style the button
    //         resendButton.addEventListener('click', () => {
    //             if (matchingProcess) {
    //                 matchingProcess.processFunction(value, keyString) // Correct order of arguments
    //                     .then(() => {
    //                         console.log(`Resent entry with key: ${keyString}`);
    
    //                         // Remove the row from the table directly
    //                         row.remove();
    //                     })
    //                     .catch(error => {
    //                         console.error(`Error resending entry with key: ${keyString}`, error);
    //                     });
    //             }
    //         });
    //         resendCell.appendChild(resendButton);
    
    //         // Append cells to the row
    //         row.appendChild(titleCell);
    //         row.appendChild(resendCell);
    //         row.appendChild(deleteCell);
    
    //         // Append the row to the table
    //         table.appendChild(row);
    //     });
    
    //     // Append the table to the container
    //     container.appendChild(table);
    // };

    const sortEntries = (entries: EntryType[]) => {
        return entries.sort(([keyA], [keyB]) => {
            const keyAString = String(keyA);
            const keyBString = String(keyB);
    
            // Find the index of each key in the processFunctions array
            const indexA = processFunctions.findIndex(({ prefix, suffix }) =>
                keyAString.startsWith(prefix) && keyAString.endsWith(suffix)
            );
            const indexB = processFunctions.findIndex(({ prefix, suffix }) =>
                keyBString.startsWith(prefix) && keyBString.endsWith(suffix)
            );
    
            // Sort based on the index in the processFunctions array
            return indexA - indexB;
        });
    };
    

    const renderEntries = (entries: EntryType[]) => {
        const container = document.getElementById('data-container');
        if (!container) {
            console.log('Container not found');
            return;
        }
    
        // Clear the container before appending new entries
        container.innerHTML = '';
    
        // Sort the entries based on the order in processFunctions
        const sortedEntries = sortEntries(entries);
    
        // Create a table element to hold the entries
        const table = document.createElement('table');
        table.classList.add('idb-entry-table', 'min-w-full', 'border-collapse'); // Add Tailwind classes for styling
    
        // Create table header if needed
        const headerRow = document.createElement('tr');
        ['IDB Key', 'Resend', 'Delete'].forEach(text => {
            const headerCell = document.createElement('th');
            headerCell.textContent = text;
            headerCell.classList.add('p-2', 'border-b'); // Add padding and border styles
            headerRow.appendChild(headerCell);
        });
        table.appendChild(headerRow);
    
        // Iterate through the sorted entries and create table rows for each entry
        sortedEntries.forEach(([key, value]) => {
            const keyString = String(key);
    
            // Determine the process function based on the key
            const matchingProcess = processFunctions.find(({ prefix, suffix }) =>
                keyString.startsWith(prefix) && keyString.endsWith(suffix)
            );
    
            // Create a table row for the entry
            const row = document.createElement('tr');
            row.setAttribute('data-key', keyString); // Optional: Add data attribute for easier manipulation
    
            // Create a cell for the title
            const titleCell = document.createElement('td');
            titleCell.textContent = keyString;
            titleCell.classList.add('p-2', 'border-b'); // Add padding and border styles
    
            // Attach a click event listener to the title cell
            titleCell.addEventListener('click', () => {
                const entryContent = value;
                setIsIdbModalOpen(true);
                setIdbModalContent(renderContent(entryContent));
            });
    
            // Create a cell for the delete button
            const deleteCell = document.createElement('td');
            deleteCell.classList.add('w-10', 'text-center', 'border-b'); // Add width and center alignment
            const deleteButton = document.createElement('button');
            deleteButton.textContent = 'X';
            deleteButton.classList.add('bg-red', 'text-white', 'py-1', 'px-2', 'rounded', 'text-center'); // Style the button
            deleteButton.addEventListener('click', () => {
                handleDelete(key, row);
            });
            deleteCell.appendChild(deleteButton);
    
            // Create a cell for the resend button
            const resendCell = document.createElement('td');
            resendCell.classList.add('w-10', 'text-center', 'border-b'); // Add width and center alignment
            const resendButton = document.createElement('button');
            resendButton.textContent = 'Resend';
            resendButton.disabled = !matchingProcess;
            resendButton.classList.add('bg-green-checkmark', 'text-white', 'py-1', 'px-2', 'rounded', 'text-center'); // Style the button
            resendButton.addEventListener('click', () => {
                if (matchingProcess) {
                    matchingProcess.processFunction(value, keyString) // Correct order of arguments
                        .then(() => {
                            console.log(`Resent entry with key: ${keyString}`);
    
                            // Remove the row from the table directly
                            row.remove();
                        })
                        .catch(error => {
                            console.error(`Error resending entry with key: ${keyString}`, error);
                        });
                }
            });
            resendCell.appendChild(resendButton);
    
            // Append cells to the row
            row.appendChild(titleCell);
            row.appendChild(resendCell);
            row.appendChild(deleteCell);
    
            // Append the row to the table
            table.appendChild(row);
        });
    
        // Append the table to the container
        container.appendChild(table);
    };
    
    
    
    
    

    // Function to handle delete operation
    const handleDelete = (key: IDBValidKey, entryContainer: HTMLTableRowElement) => {
        setDeleteConfirmOpen(true);

        const confirmDeleteButton = document.getElementById('confirm-delete-button');
        if (confirmDeleteButton) {
            confirmDeleteButton.onclick = () => {
                del(key).then(() => {
                    entryContainer.remove();
                    setDeleteConfirmOpen(false);
                }).catch(error => {
                    console.error('Error deleting entry:', error);
                });
            };
        }
    };

    // Function to recursively render the content
    const renderContent = (content: any): JSX.Element[] => {
        if (!content || typeof content !== 'object') {
            return [];
        }

        return Object.entries(content).map(([key, val]) => (
            <div key={key}>
                <strong>{key}:</strong> {typeof val === 'object' ? renderContent(val) : val}
            </div>
        ));
    };


    useEffect(() => {
        // console.log('upToDate in useEffect:', upToDate);
    }, [upToDate]);









    useEffect(() => {
        // console.log("upToDate state changed: ", upToDate);
        if (upToDate) {
            const returnToPage = setTimeout(() => {
                history.goBack();
            }, 4000);

            return () => clearTimeout(returnToPage);
        }
    }, [upToDate, history]);







    //..................................................................................................
    //.NNNN...NNNN..EEEEEEEEEEEEEWW..WWWWW...WWWW.....HHHH...HHHH....OOOOOOO.....LLLL.......EEEEEEEEEE..
    //.NNNNN..NNNN..EEEEEEEEEEEEEWW..WWWWW..WWWW......HHHH...HHHH...OOOOOOOOOO...LLLL.......EEEEEEEEEE..
    //.NNNNN..NNNN..EEEEEEEEEEEEEWW..WWWWWW.WWWW......HHHH...HHHH..OOOOOOOOOOOO..LLLL.......EEEEEEEEEE..
    //.NNNNNN.NNNN..EEEE.......EEWW.WWWWWWW.WWWW......HHHH...HHHH..OOOOO..OOOOO..LLLL.......EEEE........
    //.NNNNNN.NNNN..EEEE.......EEWW.WWWWWWW.WWWW......HHHH...HHHH.HOOOO....OOOOO.LLLL.......EEEE........
    //.NNNNNNNNNNN..EEEEEEEEEE..EWWWWWWWWWW.WWW.......HHHHHHHHHHH.HOOO......OOOO.LLLL.......EEEEEEEEEE..
    //.NNNNNNNNNNN..EEEEEEEEEE..EWWWWWW.WWWWWWW.......HHHHHHHHHHH.HOOO......OOOO.LLLL.......EEEEEEEEEE..
    //.NNNNNNNNNNN..EEEEEEEEEE..EWWWWWW.WWWWWWW.......HHHHHHHHHHH.HOOO......OOOO.LLLL.......EEEEEEEEEE..
    //.NNNNNNNNNNN..EEEE........EWWWWWW.WWWWWWW.......HHHH...HHHH.HOOOO....OOOOO.LLLL.......EEEE........
    //.NNNN.NNNNNN..EEEE........EWWWWWW.WWWWWWW.......HHHH...HHHH..OOOOO..OOOOO..LLLL.......EEEE........
    //.NNNN..NNNNN..EEEEEEEEEEE..WWWWW...WWWWW........HHHH...HHHH..OOOOOOOOOOOO..LLLLLLLLLL.EEEEEEEEEE..
    //.NNNN..NNNNN..EEEEEEEEEEE..WWWWW...WWWWW........HHHH...HHHH...OOOOOOOOOO...LLLLLLLLLL.EEEEEEEEEE..
    //.NNNN...NNNN..EEEEEEEEEEE..WWWWW...WWWWW........HHHH...HHHH.....OOOOOO.....LLLLLLLLLL.EEEEEEEEEE..
    //..................................................................................................


    const processHoleLocationNew = async (value: any, key: string) => {
        console.log(`Starting processHoleLocationNew for key: ${key}`);
        
        // Check if value is an array or an object with numerical keys
        let dataArray;
        if (Array.isArray(value)) {
            dataArray = value;
        } else if (typeof value === 'object' && value !== null) {
            dataArray = Object.keys(value)
                .filter(key => !isNaN(Number(key)))
                .map(key => value[key]);
        } else {
            console.error('Invalid data structure:', value);
            return { totalItems: 0, itemsSent: 0, failedItems: [value] };
        }
    
        const totalItems = dataArray.length;
        let itemsSent = 0;
        let failedItems = [];
        
        console.log(`Total items to process: ${totalItems}`);
        console.log('New Hole Sync - values', value);
        
        for (const item of dataArray) {
            console.log('Processing item:', item);
    
            // Destructure properties from item itself
            const {
                activity_purpose,
                activity_termination_reason,
                activity_type,
                driller,
                driller_cscs,
                driller_id,
                end_date,
                final_hole_depth,
                follow_on_guid,
                follow_on_name,
                follow_on_type,
                general_remarks,
                grid_easting,
                grid_northing,
                grid_ref_system,
                ground_level,
                guid,
                hole_barcode,
                hole_name,
                location_identifier,
                osgb_grid_ref,
                project_guid,
                rig_id,
                rig_name,
                second_man,
                second_man_cscs,
                spt_id,
                start_date,
                starting_hole_type_id,
                status
            } = item;  // Assuming item itself has the properties
            
            const dataToSendToIDB = {
                activity_purpose,
                activity_termination_reason,
                activity_type,
                driller,
                driller_cscs,
                driller_id,
                end_date,
                final_hole_depth,
                follow_on_guid,
                follow_on_name,
                follow_on_type,
                general_remarks,
                grid_easting,
                grid_northing,
                grid_ref_system,
                ground_level,
                guid,
                hole_barcode,
                hole_name,
                location_identifier,
                osgb_grid_ref,
                project_guid,
                rig_id,
                rig_name,
                second_man,
                second_man_cscs,
                spt_id,
                start_date,
                starting_hole_type_id,
                status
            };
    
            const exists = await checkFilesExist(item.task_type, dataToSendToIDB.guid);
            if (exists) {
                console.log(`Record already exists for task_type ${item.task_type} with guid ${dataToSendToIDB.guid}. Skipping.`);
                itemsSent++;
                const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                setProgressHoleLocationNew(progressPercentage);
                continue;
            }
    
            const endpoint = `${process.env.REACT_APP_API_ADDR}hole-locations`;
            const method = 'POST';
        
            try {
                console.log(`Sending request to ${endpoint} with data:`, dataToSendToIDB);
                const response = await makeRequest(endpoint, JSON.stringify(dataToSendToIDB), method);
                console.log(`HOLE-LOCATION -NEW ${key}: ${response.statusText} ${response.status}`);
                if (response.status === 200) {
                    itemsSent++;
                    const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                    setProgressHoleLocationNew(progressPercentage);
                    console.log(`Successfully sent action-new data to API for ${key}`);
                } else {
                    console.error(`Error sending hole-locations-new data to API for ${key}: ${response.statusText} ${response.status}`);
                    failedItems.push(item);
                }
            } catch (err) {
                console.error(`Error sending action-new data to API for ${key}:`, err);
                failedItems.push(item);
            }
        }
        
        console.log(`Items sent: ${itemsSent}, Failed items: ${failedItems.length}`);
        
        if (failedItems.length > 0) {
            try {
                await set(key, failedItems);
                console.log(`Updated ${key} in IDB with failed items.`);
            } catch (err) {
                console.error(`Error updating ${key} in IDB with failed items:`, err);
            }
        } else {
            try {
                await del(key);
                console.log(`Deleted entry: ${key} from IDB after successful processing.`);
            } catch (err) {
                console.error(`Error deleting ${key} from IDB:`, err);
            }
        }
        
        console.log(`Finished processHoleLocationNew for key: ${key}`);
        
        // Retry failed items
        await processHoleLocationNewRetry(value, key); // Pass value and key to retry function
    };
    
    
    const processHoleLocationNewRetry = async (value: any, key: string) => {
        console.log(`Retrying failed items for key: ${key}`);
        
        try {
            const failedItems = await get(key); // Retrieve failed items from IndexedDB
            if (!failedItems || failedItems.length === 0) {
                console.log(`No failed items found for key: ${key}`);
                return;
            }
    
            let dataArray;
            if (Array.isArray(failedItems)) {
                dataArray = failedItems;
            } else if (typeof failedItems === 'object' && failedItems !== null) {
                dataArray = Object.keys(failedItems)
                    .filter(key => !isNaN(Number(key)))
                    .map(key => failedItems[key]);
            } else {
                console.error('Invalid data structure:', failedItems);
                return { totalItems: 0, itemsSent: 0, retryFailedItems: [failedItems] };
            }
        
            const totalItems = dataArray.length;
            let itemsSent = 0;
            let retryFailedItems = [];
    
            for (const item of dataArray) {
                console.log('Retrying item:', item);
    
                // Destructure properties from item itself
                const {
                    activity_purpose,
                    activity_termination_reason,
                    activity_type,
                    driller,
                    driller_cscs,
                    driller_id,
                    end_date,
                    final_hole_depth,
                    follow_on_guid,
                    follow_on_name,
                    follow_on_type,
                    general_remarks,
                    grid_easting,
                    grid_northing,
                    grid_ref_system,
                    ground_level,
                    guid,
                    hole_barcode,
                    hole_name,
                    location_identifier,
                    osgb_grid_ref,
                    project_guid,
                    rig_id,
                    rig_name,
                    second_man,
                    second_man_cscs,
                    spt_id,
                    start_date,
                    starting_hole_type_id,
                    status
                } = item;  // Assuming item itself has the properties
    
                const dataToSendToIDB = {
                    activity_purpose,
                    activity_termination_reason,
                    activity_type,
                    driller,
                    driller_cscs,
                    driller_id,
                    end_date,
                    final_hole_depth,
                    follow_on_guid,
                    follow_on_name,
                    follow_on_type,
                    general_remarks,
                    grid_easting,
                    grid_northing,
                    grid_ref_system,
                    ground_level,
                    guid,
                    hole_barcode,
                    hole_name,
                    location_identifier,
                    osgb_grid_ref,
                    project_guid,
                    rig_id,
                    rig_name,
                    second_man,
                    second_man_cscs,
                    spt_id,
                    start_date,
                    starting_hole_type_id,
                    status
                };
    
                // Check if the item still exists on the server
                const exists = await checkFilesExist(item.task_type, dataToSendToIDB.guid);
                if (exists) {
                    console.log(`Record still exists for task_type ${item.task_type} with guid ${dataToSendToIDB.guid}. Skipping retry.`);
                    itemsSent++;
                    const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                    setProgressActionsNew(progressPercentage);
                    continue;
                }
    
                const endpoint = `${process.env.REACT_APP_API_ADDR}hole-locations`;
                const method = 'POST';
    
                try {
                    console.log(`Retrying sending request to ${endpoint} with data:`, dataToSendToIDB);
                    const response = await makeRequest(endpoint, JSON.stringify(dataToSendToIDB), method);
                    console.log(`HOLE LOCATION -NEW Retry ${key}: ${response.statusText} ${response.status}`);
                    if (response.status === 200) {
                        itemsSent++;
                        const progressPercentage = Math.floor(((itemsSent + itemsSent) / totalItems) * 100);
                        setProgressActionsNew(progressPercentage);
                        console.log(`Successfully sent action-new data to API for ${key}`);
                    } else {
                        console.error(`Error sending action-new data to API for ${key}: ${response.statusText} ${response.status}`);
                        retryFailedItems.push(item);
                    }
                } catch (err) {
                    console.error(`Error sending action-new data to API for ${key}:`, err);
                    retryFailedItems.push(item);
                }
            }
    
            console.log(`Retry: Items sent: ${itemsSent}, Failed items: ${retryFailedItems.length}`);
    
            if (retryFailedItems.length > 0) {
                try {
                    await set(key, retryFailedItems);
                    console.log(`Updated ${key} in IDB with retry failed items.`);
                } catch (err) {
                    console.error(`Error updating ${key} in IDB with retry failed items:`, err);
                }
            } else {
                try {
                    await del(key);
                    console.log(`Deleted entry: ${key} from IDB after successful retry processing.`);
                } catch (err) {
                    console.error(`Error deleting ${key} from IDB after retry:`, err);
                }
            }
        } catch (error) {
            console.error(`Error retrying failed items for key ${key}:`, error);
        }
    
        console.log(`Finished retrying failed items for key: ${key}`);
    };
    




    //.......................................................................
    //.....AAAAA.......CCCCCCC....TTTTTTTTTTIIIII...OOOOOOO.....NNNN...NNNN..
    //.....AAAAA......CCCCCCCCC...TTTTTTTTTTIIIII..OOOOOOOOOO...NNNNN..NNNN..
    //....AAAAAA.....CCCCCCCCCCC..TTTTTTTTTTIIIII.OOOOOOOOOOOO..NNNNN..NNNN..
    //....AAAAAAA....CCCC...CCCCC....TTTT...IIIII.OOOOO..OOOOO..NNNNNN.NNNN..
    //...AAAAAAAA...CCCC.....CCC.....TTTT...IIIIIOOOOO....OOOOO.NNNNNN.NNNN..
    //...AAAAAAAA...CCCC.............TTTT...IIIIIOOOO......OOOO.NNNNNNNNNNN..
    //...AAAA.AAAA..CCCC.............TTTT...IIIIIOOOO......OOOO.NNNNNNNNNNN..
    //..AAAAAAAAAA..CCCC.............TTTT...IIIIIOOOO......OOOO.NNNNNNNNNNN..
    //..AAAAAAAAAAA.CCCC.....CCC.....TTTT...IIIIIOOOOO....OOOOO.NNNNNNNNNNN..
    //..AAAAAAAAAAA..CCCC...CCCCC....TTTT...IIIII.OOOOO..OOOOO..NNNN.NNNNNN..
    //.AAAA....AAAA..CCCCCCCCCCC.....TTTT...IIIII.OOOOOOOOOOOO..NNNN..NNNNN..
    //.AAAA.....AAAA..CCCCCCCCCC.....TTTT...IIIII..OOOOOOOOOO...NNNN..NNNNN..
    //.AAAA.....AAAA...CCCCCCC.......TTTT...IIIII....OOOOOO.....NNNN...NNNN..
    //.......................................................................

    // Define individual processing functions

    const processActionNew = async (value: any, key: string) => {
        // console.log(`Starting processActionNew for key: ${key}`);
        
        // Convert value to an array if it's not already
        const dataArray = Array.isArray(value) ? value : [value];
        const totalItems = dataArray.length;
        let itemsSent = 0;
        let failedItems = [];
        
        // console.log(`Total items to process: ${totalItems}`);
        
        for (const item of dataArray) {
            const { task_type, hole_type_id, hole_location_guid, data } = item;
            const dataToSendToIDB = {
                task_type,
                hole_type_id,
                hole_location_guid,
                data
            };
    
            const exists = await checkFilesExist(task_type, data.guid);
            if (exists) {
                // console.log(`Record already exists for task_type ${task_type} with guid ${data.guid}. Skipping.`);
                itemsSent++;
                const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                setProgressActionsNew(progressPercentage);
                continue;
            }
    
            const endpoint = `${process.env.REACT_APP_API_ADDR}action`;
            const method = 'POST';
        
            try {
                // console.log(`Sending request to ${endpoint} with data:`, dataToSendToIDB);
                const response = await makeRequest(endpoint, JSON.stringify(dataToSendToIDB), method);
                // console.log(`ACTION -NEW ${key}: ${response.statusText} ${response.status}`);
                if (response.status === 200) {
                    itemsSent++;
                    const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                    setProgressActionsNew(progressPercentage);
                    // console.log(`Successfully sent action-new data to API for ${key}`);
                } else {
                    // console.error(`Error sending action-new data to API for ${key}: ${response.statusText} ${response.status}`);
                    failedItems.push(item);
                }
            } catch (err) {
                // console.error(`Error sending action-new data to API for ${key}:`, err);
                failedItems.push(item);
            }
        }
        
        // console.log(`Items sent: ${itemsSent}, Failed items: ${failedItems.length}`);
        
        if (failedItems.length > 0) {
            try {
                await set(key, failedItems);
                // console.log(`Updated ${key} in IDB with failed items.`);
            } catch (err) {
                console.error(`Error updating ${key} in IDB with failed items:`, err);
            }
        } else {
            try {
                await del(key);
                // console.log(`Deleted entry: ${key} from IDB after successful processing.`);
            } catch (err) {
                console.error(`Error deleting ${key} from IDB:`, err);
            }
        }
        
        // console.log(`Finished processActionNew for key: ${key}`);
    
        // Retry failed items
        await processActionNewRetry(value, key); // Pass value and key to retry function
    };
    
    const processActionNewRetry = async (value: any, key: string) => {
        // console.log(`Retrying failed items for key: ${key}`);
    
        try {
            const failedItems = await get(key); // Retrieve failed items from IndexedDB
            if (!failedItems || failedItems.length === 0) {
                console.log(`No failed items found for key: ${key}`);
                return;
            }
    
            const totalItems = failedItems.length;
            let itemsSent = 0;
            let retryFailedItems = [];
    
            for (const item of failedItems) {
                const { task_type, hole_type_id, hole_location_guid, data } = item;
                const dataToSendToIDB = {
                    task_type,
                    hole_type_id,
                    hole_location_guid,
                    data
                };
    
                // Check if the item still exists on the server
                const exists = await checkFilesExist(task_type, data.guid);
                if (exists) {
                    // console.log(`Record still exists for task_type ${task_type} with guid ${data.guid}. Skipping retry.`);
                    itemsSent++;
                    const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                    setProgressActionsNew(progressPercentage);
                    continue;
                }
    
                const endpoint = `${process.env.REACT_APP_API_ADDR}action`;
                const method = 'POST';
    
                try {
                    // console.log(`Retrying sending request to ${endpoint} with data:`, dataToSendToIDB);
                    const response = await makeRequest(endpoint, JSON.stringify(dataToSendToIDB), method);
                    // console.log(`ACTION -NEW Retry ${key}: ${response.statusText} ${response.status}`);
                    if (response.status === 200) {
                        itemsSent++;
                        const progressPercentage = Math.floor(((itemsSent + itemsSent) / totalItems) * 100);
                        setProgressActionsNew(progressPercentage);
                        // console.log(`Successfully sent action-new data to API for ${key}`);
                    } else {
                        console.error(`Error sending action-new data to API for ${key}: ${response.statusText} ${response.status}`);
                        retryFailedItems.push(item);
                    }
                } catch (err) {
                    console.error(`Error sending action-new data to API for ${key}:`, err);
                    retryFailedItems.push(item);
                }
            }
    
            // console.log(`Retry: Items sent: ${itemsSent}, Failed items: ${retryFailedItems.length}`);
    
            if (retryFailedItems.length > 0) {
                try {
                    await set(key, retryFailedItems);
                    // console.log(`Updated ${key} in IDB with retry failed items.`);
                } catch (err) {
                    console.error(`Error updating ${key} in IDB with retry failed items:`, err);
                }
            } else {
                try {
                    await del(key);
                    // console.log(`Deleted entry: ${key} from IDB after successful retry processing.`);
                } catch (err) {
                    console.error(`Error deleting ${key} from IDB after retry:`, err);
                }
            }
        } catch (error) {
            console.error(`Error retrying failed items for key ${key}:`, error);
        }
    
        // console.log(`Finished retrying failed items for key: ${key}`);
    };
    
    
    
    // TEST STUFF
    
    const [taskType, setTaskType] = useState('');
    const [guidTest, setGuidTest] = useState('');
    const [exists, setExists] = useState(false);

    const checkFilesExistTest = async () => {
        const endpoint = `${process.env.REACT_APP_API_ADDR}action/${taskType}/${guidTest}/exists`;
        try {
            const response = await fetch(endpoint);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            // console.log('response:', response)
            const data = await response.json();
            setExists(data.exists);
        } catch (err) {
            // console.error(`Error checking file existence for ${taskType} with guid ${guidTest}:`, err);
            if (err instanceof Error) {
                console.log('Error message:', err.message);
            } else {
                console.log('Unknown error:', err);
            }
            setExists(false); // Handle the error case
        }
    };
    
    // Function to check if files exist on the server
    const checkFilesExist = async (taskType: string, guid: string) => {
        const endpoint = `${process.env.REACT_APP_API_ADDR}action/${taskType}/${guid}/exists`;
        try {
            const response = await fetch(endpoint);
            const data = await response.json();
            return data.exists;
        } catch (err) {
            console.error(`Error checking file existence for ${taskType} with guid ${guid}:`, err);
            return false; // Return false by default or handle the error case appropriately
        }
    };




    
    
    
    const processActionUpdate = async (value: any, key: string) => {
        // Implement processing logic for action-update
        const updateData = value;

        // Log data for debugging
        // console.log(`Data for key ${key}:`, updateData);

        // Total items to resend for -update
        const totalItems = updateData.length;
        let itemsSent = 0;

        updateData.forEach((actionData: any) => {
            if (actionData && actionData.data) {
                const { task_type, hole_type_id, hole_location_guid, data } = actionData;
                const dataToSendToIDB = {
                    "task_type": task_type,
                    "hole_type_id": hole_type_id,
                    "hole_location_guid": hole_location_guid,
                    "data": data
                };

                // Construct endpoint and method (PUT for -update)
                const endpoint = `${process.env.REACT_APP_API_ADDR}action/${data.guid}`;
                const method = 'PUT';

                // Make request to API using makeRequest function
                makeRequest(endpoint, JSON.stringify(dataToSendToIDB), method)
                    .then(response => {
                        if (response.status === 200) {
                            // console.log(`Successfully sent -update data to API for ${data.guid}`);
                            itemsSent++;
                            const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                            // Update progress state here
                            setProgressActionsUpdate(progressPercentage);

                            del(key).then(() => {
                                // console.log(`Deleted key ${key} from IDB`);
                            }).catch(err => {
                                console.error(`Error deleting key ${key} from IDB:`, err);
                            });
                            return true; // Indicates success
                        } else {
                            console.error(`Error sending -update data to API for ${data.guid}: ${response.statusText}`);
                            return false; // Indicates failure
                        }
                    })
                    .catch(err => {
                        console.error(`Error sending -update data to API for ${data.guid}:`, err);
                    });
            } else {
                console.log(`No valid data found for key ${key}`);
                return false; // Indicates failure
            }
        });
    };
    
    const processActionDelete = async (value: any, key: string) => {
        const deleteData = Array.isArray(value) ? value : [value];
        const totalItems = deleteData.length;
        let itemsSent = 0;
    
        for (const actionData of deleteData) {
            if (actionData && actionData.data) {
                const { data } = actionData;
                const endpoint = `${process.env.REACT_APP_API_ADDR}action/${data.guid}`;
                const method = 'DELETE';
    
                try {
                    const response = await makeRequestDelete(endpoint, method);
                    if (response.status === 200) {
                        // console.log(`Successfully sent -delete request to API for ${data.guid}`);
                        itemsSent++;
                    } else {
                        console.error(`Error sending -delete request to API for ${data.guid}: ${response.statusText}`);
                    }
                } catch (err) {
                    console.error(`Error sending -delete request to API for ${data.guid}:`, err);
                }
    
                // Calculate and update progress percentage after each item
                const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                setProgressActionsDelete(progressPercentage);
            } else {
                console.log(`No valid data found for key ${key}`);
            }
        }
    
        // After all items are processed, delete the entry from IndexedDB
        try {
            await del(key);
            // console.log(`Deleted key ${key} from IDB`);
            return true; // Indicates success
        } catch (err) {
            console.error(`Error deleting key ${key} from IDB:`, err);
            return false; // Indicates failure
        }
    };



    //..............................................................................
    //....CCCCCCC......OOOOOOO.....UUUU...UUUU..NNNN...NNNN..TTTTTTTTTTS.SSSSSSS....
    //...CCCCCCCCC....OOOOOOOOOO...UUUU...UUUU..NNNNN..NNNN..TTTTTTTTTTSSSSSSSSSS...
    //..CCCCCCCCCCC..OOOOOOOOOOOO..UUUU...UUUU..NNNNN..NNNN..TTTTTTTTTTSSSSSSSSSSS..
    //..CCCC...CCCCC.OOOOO..OOOOO..UUUU...UUUU..NNNNNN.NNNN.....TTTT...SSSSS..SSSS..
    //.CCCC.....CCC.OOOOO....OOOOO.UUUU...UUUU..NNNNNN.NNNN.....TTTT...SSSSS........
    //.CCCC.........OOOO......OOOO.UUUU...UUUU..NNNNNNNNNNN.....TTTT....SSSSSSS.....
    //.CCCC.........OOOO......OOOO.UUUU...UUUU..NNNNNNNNNNN.....TTTT.....SSSSSSSSS..
    //.CCCC.........OOOO......OOOO.UUUU...UUUU..NNNNNNNNNNN.....TTTT.......SSSSSSS..
    //.CCCC.....CCC.OOOOO....OOOOO.UUUU...UUUU..NNNNNNNNNNN.....TTTT..........SSSS..
    //..CCCC...CCCCC.OOOOO..OOOOO..UUUU...UUUU..NNNN.NNNNNN.....TTTT...SSSS....SSS..
    //..CCCCCCCCCCC..OOOOOOOOOOOO..UUUUUUUUUUU..NNNN..NNNNN.....TTTT...SSSSSSSSSSS..
    //...CCCCCCCCCC...OOOOOOOOOO....UUUUUUUUU...NNNN..NNNNN.....TTTT....SSSSSSSSSS..
    //....CCCCCCC.......OOOOOO.......UUUUUUU....NNNN...NNNN.....TTTT.....SSSSSSSS...
    //..............................................................................

    

    const processActionCountsNew = async (value: any, key: string) => {
        // console.log(`Starting processActionCountsNew for key: ${key}`);
    
        // Convert value to an array if it's not already
        const dataArray = Array.isArray(value) ? value : [value];
        const totalItems = dataArray.length;
        let itemsSent = 0;
        let failedItems = [];
    
        // console.log(`Total items to process: ${totalItems}`);
    
        for (const item of dataArray) {
            const { guid, project_guid, hole_type, hole_name, total_depth, count_D, count_B, count_ES, count_WS, count_SPT, count_CPT, count_SPTCPT, count_U, count_PS, count_SV } = item;
    
            // Prepare data to send to API
            const dataToSendToAPI = {
                guid, // Assuming guid is required for API
                project_guid,
                hole_type,
                hole_name,
                total_depth,
                count_D,
                count_B,
                count_ES,
                count_WS,
                count_SPT,
                count_CPT,
                count_SPTCPT,
                count_U,
                count_PS,
                count_SV
                // Add other fields as needed
            };
    
            const exists = await checkFilesExist('action_counts', guid);
            if (exists) {
                // console.log(`Record already exists for action_counts with guid ${guid}. Skipping.`);
                itemsSent++;
                const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                setProgressActionCountsNew(progressPercentage);
                continue;
            }
    
            const endpoint = `${process.env.REACT_APP_API_ADDR}action_counts`;
            const method = 'POST';
    
            try {
                // console.log(`Sending request to ${endpoint} with data:`, dataToSendToAPI);
                const response = await makeRequest(endpoint, JSON.stringify(dataToSendToAPI), method);
                // console.log(`ACTION_COUNTS -NEW ${key}: ${response.statusText} ${response.status}`);
                if (response.status === 200) {
                    itemsSent++;
                    const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                    setProgressActionCountsNew(progressPercentage);
                    // console.log(`Successfully sent action_counts-new data to API for ${key}`);
                } else {
                    console.error(`Error sending action_counts-new data to API for ${key}: ${response.statusText} ${response.status}`);
                    failedItems.push(item);
                }
            } catch (err) {
                console.error(`Error sending action_counts-new data to API for ${key}:`, err);
                failedItems.push(item);
            }
        }
    
        // console.log(`Items sent: ${itemsSent}, Failed items: ${failedItems.length}`);
    
        // Handle failed items
        if (failedItems.length > 0) {
            try {
                await set(key, failedItems);
                // console.log(`Updated ${key} in IDB with failed items.`);
            } catch (err) {
                console.error(`Error updating ${key} in IDB with failed items:`, err);
            }
        } else {
            try {
                await del(key);
                // console.log(`Deleted entry: ${key} from IDB after successful processing.`);
            } catch (err) {
                console.error(`Error deleting ${key} from IDB:`, err);
            }
        }
    
        // Retry failed items
        await processActionCountsNewRetry(value, key); // Pass value and key to retry function
    
        console.log(`Finished processActionCountsNew for key: ${key}`);
    };

    

    const processActionCountsNewRetry = async (value: any, key: string) => {
        // console.log(`Starting processActionCountsNew for key: ${key}`);
    
        // Convert value to an array if it's not already
        const dataArray = Array.isArray(value) ? value : [value];
        const totalItems = dataArray.length;
        let itemsSent = 0;
        let failedItems = [];
    
        // console.log(`Total items to process: ${totalItems}`);
    
        for (const item of dataArray) {
            const { guid, project_guid, hole_type, hole_name, total_depth, count_D, count_B, count_ES, count_WS, count_SPT, count_CPT, count_SPTCPT, count_U, count_PS, count_SV } = item;
    
            // Prepare data to send to API
            const dataToSendToAPI = {
                guid, // Assuming guid is required for API
                project_guid,
                hole_type,
                hole_name,
                total_depth,
                count_D,
                count_B,
                count_ES,
                count_WS,
                count_SPT,
                count_CPT,
                count_SPTCPT,
                count_U,
                count_PS,
                count_SV
                // Add other fields as needed
            };
    
            const exists = await checkFilesExist('action_counts', guid);
            if (exists) {
                // console.log(`Record already exists for action_counts with guid ${guid}. Skipping.`);
                itemsSent++;
                const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                setProgressActionCountsNew(progressPercentage);
                continue;
            }
    
            const endpoint = `${process.env.REACT_APP_API_ADDR}action_counts`;
            const method = 'POST';
    
            try {
                // console.log(`Sending request to ${endpoint} with data:`, dataToSendToAPI);
                const response = await makeRequest(endpoint, JSON.stringify(dataToSendToAPI), method);
                // console.log(`ACTION_COUNTS -NEW ${key}: ${response.statusText} ${response.status}`);
                if (response.status === 200) {
                    itemsSent++;
                    const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                    setProgressActionCountsNew(progressPercentage);
                    // console.log(`Successfully sent action_counts-new data to API for ${key}`);
                } else {
                    console.error(`Error sending action_counts-new data to API for ${key}: ${response.statusText} ${response.status}`);
                    failedItems.push(item);
                }
            } catch (err) {
                console.error(`Error sending action_counts-new data to API for ${key}:`, err);
                failedItems.push(item);
            }
        }
    
        // console.log(`Items sent: ${itemsSent}, Failed items: ${failedItems.length}`);
    
        // Handle failed items
        if (failedItems.length > 0) {
            try {
                await set(key, failedItems);
                // console.log(`Updated ${key} in IDB with failed items.`);
            } catch (err) {
                console.error(`Error updating ${key} in IDB with failed items:`, err);
            }
        } else {
            try {
                await del(key);
                // console.log(`Deleted entry: ${key} from IDB after successful processing.`);
            } catch (err) {
                console.error(`Error deleting ${key} from IDB:`, err);
            }
        }
    
        console.log(`Finished processActionCountsNew for key: ${key}`);
    };

    
    
    
    
    const processActionCountsUpdate = async (value: any, key: string) => {
        // Log data for debugging
        // console.log(`Data for key ${key}:`, value);
    
        // Total items to resend for -update
        const totalItems = value.length;
        let itemsSent = 0;
    
        for (const actionData of value) {
            if (actionData && actionData.data) {
                const { guid, project_guid, hole_type, hole_name, total_depth, count_D, count_B, count_ES, count_WS, count_SPT, count_CPT, count_SPTCPT, count_U, count_PS, count_SV } = actionData;
    
                // Prepare data to send to API
                const dataToSendToIDB = {
                    "guid": guid,
                    "project_guid": project_guid,
                    "hole_type": hole_type,
                    "hole_name": hole_name,
                    "total_depth": total_depth,
                    "count_D": count_D,
                    "count_B": count_B,
                    "count_ES": count_ES,
                    "count_WS": count_WS,
                    "count_SPT": count_SPT,
                    "count_CPT": count_CPT,
                    "count_SPTCPT": count_SPTCPT,
                    "count_U": count_U,
                    "count_PS": count_PS,
                    "count_SV": count_SV
                };
    
                // Construct endpoint and method (PUT for -update)
                const endpoint = `${process.env.REACT_APP_API_ADDR}action_counts/${guid}-update`;
                const method = 'PUT';
    
                try {
                    // Make request to API using makeRequest function
                    const response = await makeRequest(endpoint, JSON.stringify(dataToSendToIDB), method);
    
                    if (response.status === 200) {
                        // console.log(`Successfully sent -update data to API for ${guid}`);
                        itemsSent++;
                        const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                        // Update progress state here
                        setProgressActionCountsUpdate(progressPercentage);
    
                        try {
                            // Delete key from IDB after successful update
                            await del(key);
                            // console.log(`Deleted key ${key} from IDB after successful update.`);
                        } catch (err) {
                            console.error(`Error deleting key ${key} from IDB:`, err);
                        }
                    } else {
                        console.error(`Error sending -update data to API for ${guid}: ${response.statusText}`);
                    }
                } catch (err) {
                    console.error(`Error sending -update data to API for ${guid}:`, err);
                }
            } else {
                console.log(`No valid data found for key ${key}`);
            }
        }
    };
    
    

    //...................................................................................................
    //.HHHH...HHHH....OOOOOOO.....LLLL.......EEEEEEEEEEE..... III.INNN...NNNN..NFFFFFFFFF...OOOOOOO.....
    //.HHHH...HHHH...OOOOOOOOOO...LLLL.......EEEEEEEEEEE..... III.INNNN..NNNN..NFFFFFFFFF..OOOOOOOOOO...
    //.HHHH...HHHH..OOOOOOOOOOOO..LLLL.......EEEEEEEEEEE..... III.INNNN..NNNN..NFFFFFFFFF.OOOOOOOOOOOO..
    //.HHHH...HHHH..OOOOO..OOOOO..LLLL.......EEEE............ III.INNNNN.NNNN..NFFF.......OOOOO..OOOOO..
    //.HHHH...HHHH.HOOOO....OOOOO.LLLL.......EEEE............ III.INNNNN.NNNN..NFFF......FOOOO....OOOO..
    //.HHHHHHHHHHH.HOOO......OOOO.LLLL.......EEEEEEEEEE...... III.INNNNNNNNNN..NFFFFFFFF.FOOO......OOO..
    //.HHHHHHHHHHH.HOOO......OOOO.LLLL.......EEEEEEEEEE...... III.INNNNNNNNNN..NFFFFFFFF.FOOO......OOO..
    //.HHHHHHHHHHH.HOOO......OOOO.LLLL.......EEEEEEEEEE...... III.INNNNNNNNNN..NFFFFFFFF.FOOO......OOO..
    //.HHHH...HHHH.HOOOO....OOOOO.LLLL.......EEEE............ III.INNNNNNNNNN..NFFF......FOOOO....OOOO..
    //.HHHH...HHHH..OOOOO..OOOOO..LLLL.......EEEE............ III.INNN.NNNNNN..NFFF.......OOOOO..OOOOO..
    //.HHHH...HHHH..OOOOOOOOOOOO..LLLLLLLLLL.EEEEEEEEEEE..... III.INNN..NNNNN..NFFF.......OOOOOOOOOOOO..
    //.HHHH...HHHH...OOOOOOOOOO...LLLLLLLLLL.EEEEEEEEEEE..... III.INNN..NNNNN..NFFF........OOOOOOOOOO...
    //.HHHH...HHHH.....OOOOOO.....LLLLLLLLLL.EEEEEEEEEEE..... III.INNN...NNNN..NFFF..........OOOOOO.....
    //...................................................................................................
    
    const processHoleLocationUpdate = async (value: any, key: string) => {
        const updateData = value;
    
        try {
            const updateItems = Array.isArray(updateData) ? updateData : [updateData];
            const totalItems = updateItems.length;
            let itemsSent = 0;
    
            for (const item of updateItems) {
                const {
                    hole_name,
                    location_identifier,
                    driller,
                    driller_id,
                    driller_cscs,
                    second_man,
                    second_man_cscs,
                    rig_id,
                    rig_name,
                    spt_id,
                    hole_barcode,
                    final_hole_depth,
                    activity_termination_reason,
                } = item;
    
                // Extract guid from the key
                const startIndex = key.indexOf('drillit-hole-locations/updateInformation/') + 'drillit-hole-locations/updateInformation/'.length;
                const endIndex = key.indexOf('-update');
                const hole_guid = key.substring(startIndex, endIndex);
    
                const endpoint = `${process.env.REACT_APP_API_ADDR}hole-locations/updateInformation/${hole_guid}`;
                const method = 'PUT';
    
                const dataToSendToAPI = {
                    hole_name,
                    location_identifier,
                    driller,
                    driller_id,
                    driller_cscs,
                    second_man,
                    second_man_cscs,
                    rig_id,
                    rig_name,
                    spt_id,
                    hole_barcode,
                    final_hole_depth,
                    activity_termination_reason
                };
    
                try {
                    const response = await makeRequest(endpoint, JSON.stringify(dataToSendToAPI), method);
                    if (response.status === 200) {
                        itemsSent++;
                        const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                        setProgressHoleInformationUpdate(progressPercentage);
                        // console.log(`Successfully sent hole-location update data to API for ${hole_guid}`);
    
                        // Now that it's successfully sent, delete the entry from IndexedDB
                        await del(key);
                        // console.log(`Deleted entry: ${key}`);
    
                        // Return true indicating success
                        return true;
                    } else {
                        console.error(`Error sending hole-location update data to API for ${hole_guid}: ${response.statusText}`);
                        return false; // Indicates failure
                    }
    
                } catch (err) {
                    console.error(`Error sending hole-location update data to API for ${hole_guid}:`, err);
                    itemsSent++; // Increment itemsSent even on error to update progress correctly
                    const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                    setProgressHoleInformationUpdate(progressPercentage);
                    return false; // Indicates failure
                }
            }
        } catch (error) {
            console.error(`Error processing hole-location update data: ${error}`);
            return false; // Indicates failure
        }
    };



    //..........................................................................................
    //.....AAAAA.......CCCCCCC....TTTTTTTTTTIIIIIVVVV....VVVVA..AAAA....AATTTTTTTTTTTEEEEEEEEE..
    //.....AAAAA......CCCCCCCCC...TTTTTTTTTTIIIIIVVVV....VVVV..AAAAAA...AATTTTTTTTTTTEEEEEEEEE..
    //....AAAAAA.....CCCCCCCCCCC..TTTTTTTTTTIIIIIVVVV....VVVV..AAAAAA...AATTTTTTTTTTTEEEEEEEEE..
    //....AAAAAAA....CCCC...CCCCC....TTTT...IIIIIVVVVV..VVVV...AAAAAAA......TTTT...TTEE.........
    //...AAAAAAAA...CCCC.....CCC.....TTTT...IIIII.VVVV..VVVV..AAAAAAAA......TTTT...TTEE.........
    //...AAAAAAAA...CCCC.............TTTT...IIIII.VVVV..VVVV..AAAAAAAA......TTTT...TTEEEEEEEE...
    //...AAAA.AAAA..CCCC.............TTTT...IIIII.VVVVVVVVV...AAAA.AAAA.....TTTT...TTEEEEEEEE...
    //..AAAAAAAAAA..CCCC.............TTTT...IIIII..VVVVVVVV..AAAAAAAAAA.....TTTT...TTEEEEEEEE...
    //..AAAAAAAAAAA.CCCC.....CCC.....TTTT...IIIII..VVVVVVVV..AAAAAAAAAAA....TTTT...TTEE.........
    //..AAAAAAAAAAA..CCCC...CCCCC....TTTT...IIIII..VVVVVVV..VAAAAAAAAAAA....TTTT...TTEE.........
    //.AAAA....AAAA..CCCCCCCCCCC.....TTTT...IIIII...VVVVVV..VAAA....AAAA....TTTT...TTEEEEEEEEE..
    //.AAAA.....AAAA..CCCCCCCCCC.....TTTT...IIIII...VVVVVV..VAAA....AAAAA...TTTT...TTEEEEEEEEE..
    //.AAAA.....AAAA...CCCCCCC.......TTTT...IIIII...VVVVV..VVAAA.....AAAA...TTTT...TTEEEEEEEEE..
    //..........................................................................................



    const processActivateHole = async (value: any, key: string) => {
        const activateData = value;
    
        try {
            const activateItems = Array.isArray(activateData) ? activateData : [activateData];
            const totalItems = activateItems.length;
            let itemsSent = 0;
    
            // Strip '-update' or '-new' from key to get hole_guid
            const startIndex = key.indexOf('drillit-hole-locations/activate/') + 'drillit-hole-locations/activate/'.length;
            const hole_guid = key.substring(startIndex).replace(/-update|-new$/, '');
    
            for (const item of activateItems) {
                const { status } = item;
    
                const endpoint = `${process.env.REACT_APP_API_ADDR}hole-locations/activate/${hole_guid}`;
                const method = 'PUT';
    
                const dataToSendToAPI = {
                    status,
                    // Include other relevant fields from item
                };
    
                try {
                    const response = await makeRequest(endpoint, JSON.stringify(dataToSendToAPI), method);
                    if (response.status === 200) {
                        itemsSent++;
                        const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                        setProgressActivateHoleUpdate(progressPercentage);
                        // console.log(`Successfully sent activate hole data to API for ${hole_guid}`);
    
                        // Now that it's successfully sent, delete the entry from IndexedDB
                        await del(key);
                        // console.log(`Deleted entry: ${key}`);
    
                        // Return true indicating success
                        return true;
                    } else {
                        console.error(`Error sending activate hole data to API for ${hole_guid}: ${response.statusText}`);
                        return false; // Indicates failure
                    }
                } catch (err) {
                    console.error(`Error sending activate hole data to API for ${hole_guid}:`, err);
                    itemsSent++; // Increment itemsSent even on error to update progress correctly
                    const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                    setProgressActivateHoleUpdate(progressPercentage);
                    return false; // Indicates failure
                }
            }
        } catch (error) {
            console.error(`Error processing activate hole data: ${error}`);
            return false; // Indicates failure
        }
    };
    
    



    //.................................................................
    //....CCCCCCC....LLLL.........OOOOOOO......SSSSSSS....EEEEEEEEEEE..
    //...CCCCCCCCC...LLLL........OOOOOOOOOO...SSSSSSSSS...EEEEEEEEEEE..
    //..CCCCCCCCCCC..LLLL.......OOOOOOOOOOOO..SSSSSSSSSS..EEEEEEEEEEE..
    //..CCCC...CCCCC.LLLL.......OOOOO..OOOOO.SSSSS..SSSS..EEEE.........
    //.CCCC.....CCC..LLLL......OOOOO....OOOOOSSSSS........EEEE.........
    //.CCCC..........LLLL......OOOO......OOOO.SSSSSSS.....EEEEEEEEEE...
    //.CCCC..........LLLL......OOOO......OOOO..SSSSSSSSS..EEEEEEEEEE...
    //.CCCC..........LLLL......OOOO......OOOO....SSSSSSS..EEEEEEEEEE...
    //.CCCC.....CCC..LLLL......OOOOO....OOOOO.......SSSSS.EEEE.........
    //..CCCC...CCCCC.LLLL.......OOOOO..OOOOO.SSSS....SSSS.EEEE.........
    //..CCCCCCCCCCC..LLLLLLLLLL.OOOOOOOOOOOO.SSSSSSSSSSSS.EEEEEEEEEEE..
    //...CCCCCCCCCC..LLLLLLLLLL..OOOOOOOOOO...SSSSSSSSSS..EEEEEEEEEEE..
    //....CCCCCCC....LLLLLLLLLL....OOOOOO......SSSSSSSS...EEEEEEEEEEE..
    //.................................................................



    const processCloseHole = async (value: any, key: string) => {
        const updateData = value;
    
        try {
            const updateItems = Array.isArray(updateData) ? updateData : [updateData];
            const totalItems = updateItems.length;
            let itemsSent = 0;
    
            // Strip '-update' or '-new' from key to get hole_guid
            const startIndex = key.indexOf('drillit-hole-locations/complete/') + 'drillit-hole-locations/complete/'.length;
            const hole_guid = key.substring(startIndex).replace(/-update|-new$/, '');
    
            for (const item of updateItems) {
                const { end_date, hole_complete, status } = item;
    
                const endpoint = `${process.env.REACT_APP_API_ADDR}hole-locations/complete/${hole_guid}`;
                const method = 'PUT';
    
                const dataToSendToAPI = {
                    end_date,
                    hole_complete,
                    status,
                    // Include other relevant fields from item
                };
    
                try {
                    const response = await makeRequest(endpoint, JSON.stringify(dataToSendToAPI), method);
                    if (response.status === 200) {
                        itemsSent++;
                        const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                        setProgressCloseHoleUpdate(progressPercentage);
                        // console.log(`Successfully sent complete hole data to API for ${hole_guid}`);
    
                        // Now that it's successfully sent, delete the entry from IndexedDB
                        await del(key);
                        // console.log(`Deleted entry: ${key}`);
    
                        // Return true indicating success
                        return true;
                    } else {
                        console.error(`Error sending complete hole data to API for ${hole_guid}: ${response.statusText}`);
                        return false; // Indicates failure
                    }
                } catch (err) {
                    console.error(`Error sending complete hole data to API for ${hole_guid}:`, err);
                    itemsSent++; // Increment itemsSent even on error to update progress correctly
                    const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                    setProgressCloseHoleUpdate(progressPercentage);
                    return false; // Indicates failure
                }
            }
        } catch (error) {
            console.error(`Error processing close hole data: ${error}`);
            return false; // Indicates failure
        }
    };
    
    



    //.....................................................
    //.LLLL.........OOOOOOO........GGGGGGG.....SSSSSSS.....
    //.LLLL........OOOOOOOOOO....GGGGGGGGGG...SSSSSSSSS....
    //.LLLL.......OOOOOOOOOOOO..GGGGGGGGGGGG..SSSSSSSSSS...
    //.LLLL.......OOOOO..OOOOO..GGGGG..GGGGG.GSSSS..SSSS...
    //.LLLL......LOOOO....OOOOOOGGGG....GGG..GSSSS.........
    //.LLLL......LOOO......OOOOOGGG...........SSSSSSS......
    //.LLLL......LOOO......OOOOOGGG..GGGGGGGG..SSSSSSSSS...
    //.LLLL......LOOO......OOOOOGGG..GGGGGGGG....SSSSSSS...
    //.LLLL......LOOOO....OOOOOOGGGG.GGGGGGGG.......SSSSS..
    //.LLLL.......OOOOO..OOOOO..GGGGG....GGGGGSSS....SSSS..
    //.LLLLLLLLLL.OOOOOOOOOOOO..GGGGGGGGGGGG.GSSSSSSSSSSS..
    //.LLLLLLLLLL..OOOOOOOOOO....GGGGGGGGGG...SSSSSSSSSS...
    //.LLLLLLLLLL....OOOOOO........GGGGGGG.....SSSSSSSS....
    //.....................................................

    const processLogsNew = async (value: any, key: string) => {
        // console.log(`Starting processLogsNew for key: ${key}`);
    
        try {
            const dataArray = Array.isArray(value) ? value : [value];
            const totalItems = dataArray.length;
            let itemsSent = 0;
            let failedItems = [];
    
            for (const item of dataArray) {
                const {
                    guid,
                    company_id,
                    driller_id,
                    hole_guid,
                    hole_name,
                    hole_type_id,
                    log_date,
                    log_save_date_time,
                    log_url,
                    project_guid,
                    project_id,
                    project_location,
                    project_name
                } = item;
    
                try {
                    // Check if the log already exists on the server
                    const exists = await checkFilesExist(hole_guid, log_date); // Adjust parameters as needed
    
                    if (exists) {
                        // console.log(`Record already exists for log with guid ${guid} and date ${log_date}. Skipping.`);
                        itemsSent++;
                        const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                        setProgressLogsNew(progressPercentage);
                        continue;
                    }
    
                    const endpoint = `${process.env.REACT_APP_API_ADDR}logs`;
                    const method = 'POST';
    
                    const dataToSendToAPI = {
                        guid,
                        company_id,
                        driller_id,
                        hole_guid,
                        hole_name,
                        hole_type_id,
                        log_date,
                        log_save_date_time,
                        log_url,
                        project_guid,
                        project_id,
                        project_location,
                        project_name
                    };
    
                    const response = await makeRequest(endpoint, JSON.stringify(dataToSendToAPI), method);
    
                    if (response.status === 200) {
                        itemsSent++;
                        const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                        setProgressLogsNew(progressPercentage);
                        // console.log(`Successfully sent log-new data to API for ${guid}`);
                    } else {
                        console.error(`Error sending log-new data to API for ${guid}: ${response.statusText}`);
                        failedItems.push(item);
                    }
                } catch (err) {
                    console.error(`Error sending log-new data to API for ${guid}:`, err);
                    failedItems.push(item);
                }
            }
    
            // console.log(`Items sent: ${itemsSent}, Failed items: ${failedItems.length}`);
    
            if (failedItems.length > 0) {
                try {
                    await set(key, failedItems);
                    // console.log(`Updated ${key} in IDB with failed items.`);
                } catch (err) {
                    console.error(`Error updating ${key} in IDB with failed items:`, err);
                }
            } else {
                try {
                    await del(key);
                    // console.log(`Deleted entry: ${key} from IDB after successful processing.`);
                } catch (err) {
                    console.error(`Error deleting ${key} from IDB:`, err);
                }
            }
    
            // Retry failed items
            await processLogsNewRetry(value, key); // Pass value and key to retry function
        } catch (error) {
            console.error(`Error processing logs-new for key ${key}:`, error);
        }
    
        // console.log(`Finished processLogsNew for key: ${key}`);
    };
    
    const processLogsNewRetry = async (value: any, key: string) => {
        // console.log(`Retrying failed logs-new items for key: ${key}`);
    
        try {
            const failedItems = await get(key); // Retrieve failed items from IndexedDB
            if (!failedItems || failedItems.length === 0) {
                // console.log(`No failed logs-new items found for key: ${key}`);
                return;
            }
    
            const totalItems = failedItems.length;
            let itemsSent = 0;
            let retryFailedItems = [];
    
            for (const item of failedItems) {
                const {
                    guid,
                    company_id,
                    driller_id,
                    hole_guid,
                    hole_name,
                    hole_type_id,
                    log_date,
                    log_save_date_time,
                    log_url,
                    project_guid,
                    project_id,
                    project_location,
                    project_name
                } = item;
    
                // Check if the log still exists on the server
                const exists = await checkFilesExist(hole_guid, log_date); // Adjust parameters as needed
    
                if (exists) {
                    // console.log(`Record still exists for log with guid ${guid} and date ${log_date}. Skipping retry.`);
                    itemsSent++;
                    const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                    setProgressLogsNew(progressPercentage);
                    continue;
                }
    
                const endpoint = `${process.env.REACT_APP_API_ADDR}logs`;
                const method = 'POST';
    
                const dataToSendToAPI = {
                    guid,
                    company_id,
                    driller_id,
                    hole_guid,
                    hole_name,
                    hole_type_id,
                    log_date,
                    log_save_date_time,
                    log_url,
                    project_guid,
                    project_id,
                    project_location,
                    project_name
                };
    
                try {
                    // console.log(`Retrying sending log-new request to ${endpoint} with data:`, dataToSendToAPI);
                    const response = await makeRequest(endpoint, JSON.stringify(dataToSendToAPI), method);
                    // console.log(`LOGS -NEW Retry ${key}: ${response.statusText} ${response.status}`);
                    if (response.status === 200) {
                        itemsSent++;
                        const progressPercentage = Math.floor(((itemsSent + itemsSent) / totalItems) * 100);
                        setProgressLogsNew(progressPercentage);
                        // console.log(`Successfully sent log-new data to API for ${guid}`);
                    } else {
                        console.error(`Error sending log-new data to API for ${guid}: ${response.statusText} ${response.status}`);
                        retryFailedItems.push(item);
                    }
                } catch (err) {
                    console.error(`Error sending log-new data to API for ${guid}:`, err);
                    retryFailedItems.push(item);
                }
            }
    
            console.log(`Retry: Items sent: ${itemsSent}, Failed items: ${retryFailedItems.length}`);
    
            if (retryFailedItems.length > 0) {
                try {
                    await set(key, retryFailedItems);
                    // console.log(`Updated ${key} in IDB with retry failed items.`);
                } catch (err) {
                    console.error(`Error updating ${key} in IDB with retry failed items:`, err);
                }
            } else {
                try {
                    await del(key);
                    // console.log(`Deleted entry: ${key} from IDB after successful retry processing.`);
                } catch (err) {
                    console.error(`Error deleting ${key} from IDB after retry:`, err);
                }
            }
        } catch (error) {
            console.error(`Error retrying failed logs-new items for key ${key}:`, error);
        }
    
        console.log(`Finished retrying failed logs-new items for key: ${key}`);
    };
    
    
    
    
    
    const processLogsUpdate = async (value: any, key: string) => {
        const newData = value;
    
        try {
            const newItems = Array.isArray(newData) ? newData : [newData];
            const totalItems = newItems.length;
            let itemsSent = 0;
    
            for (const item of newItems) {
                const { guid, ...logData } = item;
                const endpoint = `${process.env.REACT_APP_API_ADDR}logs/${guid}`;
                const method = 'PUT';
    
                try {
                    const response = await makeRequest(endpoint, JSON.stringify(logData), method);
                    if (response.status === 200) {
                        // console.log(`Successfully sent log-update data to API for ${guid}`);
                        itemsSent++;
                    } else {
                        console.error(`Error sending log-update data to API for ${guid}: ${response.statusText}`);
                        // Handle error if needed
                    }
                } catch (err) {
                    console.error(`Error sending log-update data to API for ${guid}:`, err);
                    // Handle error if needed
                }
    
                // Calculate progress percentage after each item
                const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                setProgressLogsUpdate(progressPercentage);
            }
    
            // After all items are processed, delete the entry from IndexedDB
            await del(key);
            // console.log(`Deleted entry: ${key}`);
            return true; // Indicates success
    
        } catch (error) {
            console.error(`Error processing log-update data: ${error}`);
            return false; // Indicates failure
        }
    };


    //.....................................................................................................
    //....CCCCCCC......OOOOOOO.....NNNN...NNNN..TTTTTTTTTTA..AAAA........CCCCCCC...CTTTTTTTTTTT.SSSSSSS....
    //...CCCCCCCCC....OOOOOOOOOO...NNNNN..NNNN..TTTTTTTTTTA.AAAAAA......CCCCCCCCC..CTTTTTTTTTTTSSSSSSSSS...
    //..CCCCCCCCCCC..OOOOOOOOOOOO..NNNNN..NNNN..TTTTTTTTTTA.AAAAAA.....CCCCCCCCCCC.CTTTTTTTTTTTSSSSSSSSSS..
    //..CCCC...CCCCC.OOOOO..OOOOO..NNNNNN.NNNN.....TTTT.....AAAAAAA...ACCCC...CCCC.....TTTT...TSSS...SSSS..
    //.CCCC.....CCC.OOOOO....OOOOO.NNNNNN.NNNN.....TTTT....AAAAAAAA...ACCC....CCC......TTTT...TSSSS........
    //.CCCC.........OOOO......OOOO.NNNNNNNNNNN.....TTTT....AAAAAAAA...ACCC.............TTTT....SSSSSSS.....
    //.CCCC.........OOOO......OOOO.NNNNNNNNNNN.....TTTT....AAAA.AAAA..ACCC.............TTTT.....SSSSSSSS...
    //.CCCC.........OOOO......OOOO.NNNNNNNNNNN.....TTTT...AAAAAAAAAA..ACCC.............TTTT.......SSSSSSS..
    //.CCCC.....CCC.OOOOO....OOOOO.NNNNNNNNNNN.....TTTT...AAAAAAAAAAA.ACCC....CCC......TTTT..........SSSS..
    //..CCCC...CCCCC.OOOOO..OOOOO..NNNN.NNNNNN.....TTTT..TAAAAAAAAAAA.ACCCC...CCCCC....TTTT...TSSS...SSSS..
    //..CCCCCCCCCCC..OOOOOOOOOOOO..NNNN..NNNNN.....TTTT..TAAA....AAAA..CCCCCCCCCCC.....TTTT...TSSSSSSSSSS..
    //...CCCCCCCCCC...OOOOOOOOOO...NNNN..NNNNN.....TTTT..TAAA....AAAAA..CCCCCCCCC......TTTT....SSSSSSSSSS..
    //....CCCCCCC.......OOOOOO.....NNNN...NNNN.....TTTT.TTAAA.....AAAA...CCCCCCC.......TTTT.....SSSSSSS....
    //.....................................................................................................

    const processContactsNew = async (value: any, key: string) => {
        // console.log(`Starting processContactsNew for key: ${key}`);
    
        try {
            const dataArray = Array.isArray(value) ? value : [value];
            const totalItems = dataArray.length;
            let itemsSent = 0;
            let failedItems = [];
    
            // console.log(`Total items to process: ${totalItems}`);
    
            // Delete the entry from IndexedDB regardless of success or failure in processing
            await del(key);
            // console.log(`Deleted entry: ${key} from IndexedDB`);
    
            for (const item of dataArray) {
                const { guid, first_name, last_name, email, telephone, company_id } = item;
                const endpoint = `${process.env.REACT_APP_API_ADDR}contacts`;
                const method = 'POST';
    
                const dataToSendToAPI = {
                    guid,
                    first_name,
                    last_name,
                    email,
                    telephone,
                    company_id
                };
    
                // Check if record exists on the server
                const exists = await checkFilesExist('contacts', guid);
                if (exists) {
                    itemsSent++;
                    const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                    setProgressContactsNew(progressPercentage);
                    // console.log(`Record already exists for guid ${guid}. Skipping.`);
                    continue;
                }
    
                try {
                    // console.log(`Sending request to ${endpoint} with data:`, dataToSendToAPI);
                    const response = await makeRequest(endpoint, JSON.stringify(dataToSendToAPI), method);
                    // console.log(`CONTACT -NEW ${key}: ${response.statusText} ${response.status}`);
                    if (response.status === 200) {
                        itemsSent++;
                        const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                        setProgressContactsNew(progressPercentage);
                        // console.log(`Successfully sent contact-new data to API for ${guid}`);
                    } else {
                        console.error(`Error sending contact-new data to API for ${guid}: ${response.statusText}`);
                        failedItems.push(item);
                    }
                } catch (err) {
                    console.error(`Error sending contact-new data to API for ${guid}:`, err);
                    failedItems.push(item);
                }
            }
    
            // console.log(`Items sent: ${itemsSent}, Failed items: ${failedItems.length}`);
    
            if (failedItems.length > 0) {
                try {
                    await set(key, failedItems);
                    // console.log(`Updated ${key} in IDB with failed items.`);
                } catch (err) {
                    console.error(`Error updating ${key} in IDB with failed items:`, err);
                }
            } else {
                try {
                    await del(key);
                    // console.log(`Deleted entry: ${key} from IDB after successful processing.`);
                } catch (err) {
                    console.error(`Error deleting ${key} from IDB:`, err);
                }
            }
    
            // Retry failed items
            await processContactsNewRetry(value, key); // Pass value and key to retry function
    
            // console.log(`Finished processContactsNew for key: ${key}`);
            return true; // Indicates success
        } catch (error) {
            console.error(`Error processing contacts-new for key ${key}:`, error);
            return false; // Indicates failure
        }
    };
    
    const processContactsNewRetry = async (value: any, key: string) => {
        // console.log(`Retrying failed contacts-new items for key: ${key}`);
    
        try {
            const failedItems = await get(key); // Retrieve failed items from IndexedDB
            if (!failedItems || failedItems.length === 0) {
                console.log(`No failed contacts-new items found for key: ${key}`);
                return;
            }
    
            const totalItems = failedItems.length;
            let itemsSent = 0;
            let retryFailedItems = [];
    
            for (const item of failedItems) {
                const { guid, first_name, last_name, email, telephone, company_id } = item;
                const endpoint = `${process.env.REACT_APP_API_ADDR}contacts`;
                const method = 'POST';
    
                const dataToSendToAPI = {
                    guid,
                    first_name,
                    last_name,
                    email,
                    telephone,
                    company_id
                };
    
                // Check if record still doesn't exist on the server
                const exists = await checkFilesExist('contacts', guid);
                if (exists) {
                    // console.log(`Record still exists for guid ${guid}. Skipping retry.`);
                    itemsSent++;
                    const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                    setProgressContactsNew(progressPercentage);
                    continue;
                }
    
                try {
                    // console.log(`Retrying sending contact-new request to ${endpoint} with data:`, dataToSendToAPI);
                    const response = await makeRequest(endpoint, JSON.stringify(dataToSendToAPI), method);
                    // console.log(`CONTACT -NEW Retry ${key}: ${response.statusText} ${response.status}`);
                    if (response.status === 200) {
                        itemsSent++;
                        const progressPercentage = Math.floor(((itemsSent + itemsSent) / totalItems) * 100);
                        setProgressContactsNew(progressPercentage);
                        // console.log(`Successfully sent contact-new data to API for ${guid}`);
                    } else {
                        console.error(`Error sending contact-new data to API for ${guid}: ${response.statusText} ${response.status}`);
                        retryFailedItems.push(item);
                    }
                } catch (err) {
                    console.error(`Error sending contact-new data to API for ${guid}:`, err);
                    retryFailedItems.push(item);
                }
            }
    
            console.log(`Retry: Items sent: ${itemsSent}, Failed items: ${retryFailedItems.length}`);
    
            if (retryFailedItems.length > 0) {
                try {
                    await set(key, retryFailedItems);
                    // console.log(`Updated ${key} in IDB with retry failed items.`);
                } catch (err) {
                    console.error(`Error updating ${key} in IDB with retry failed items:`, err);
                }
            } else {
                try {
                    await del(key);
                    // console.log(`Deleted entry: ${key} from IDB after successful retry processing.`);
                } catch (err) {
                    console.error(`Error deleting ${key} from IDB after retry:`, err);
                }
            }
        } catch (error) {
            console.error(`Error retrying failed contacts-new items for key ${key}:`, error);
        }
    
        console.log(`Finished retrying failed contacts-new items for key: ${key}`);
    };
    
    

    
    
    
    const processContactsUpdate = async (value: any, key: string) => {
        const updateData = value;
        const updateItems = Array.isArray(updateData) ? updateData : [updateData];
        const totalItems = updateItems.length;
        let itemsSent = 0;
    
        for (const item of updateItems) {
            const { guid, first_name, last_name, email, telephone, company_id } = item;
            const endpoint = `${process.env.REACT_APP_API_ADDR}contacts/${guid}`; // Replace with your API endpoint
            const method = 'PUT';
    
            const dataToSendToAPI = {
                guid,
                first_name,
                last_name,
                email,
                telephone,
                company_id
            };
    
            try {
                const response = await makeRequest(endpoint, JSON.stringify(dataToSendToAPI), method);
                if (response.status === 200) {
                    // console.log(`Successfully sent contact-update data to API for ${guid}`);
                    itemsSent++;
                } else {
                    console.error(`Error sending contact-update data to API for ${guid}: ${response.statusText}`);
                    // Handle error if needed
                }
            } catch (err) {
                console.error(`Error sending contact-update data to API for ${guid}:`, err);
                // Handle error if needed
            }
    
            // Calculate progress percentage after each item
            const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
            setProgressContactsUpdate(progressPercentage);
        }
    
        // After all items are processed, delete the entry from IndexedDB
        try {
            await del(key);
            // console.log(`Deleted entry: ${key}`);
            return true; // Indicates success
        } catch (error) {
            console.error(`Error deleting entry: ${key}`, error);
            return false; // Indicates failure
        }
    };
    
    
    const processContactsDelete = async (value: any, key: string) => {
        const deleteData = Array.isArray(value) ? value : [value];
        const totalItems = deleteData.length;
        let itemsSent = 0;
    
        for (const item of deleteData) {
            const { guid, company_id } = item;
            const endpoint = `${process.env.REACT_APP_API_ADDR}contacts/delete/${guid}`;
            const method = 'DELETE';
    
            try {
                const response = await makeRequestDelete(endpoint, method);
                if (response.status === 200) {
                    // console.log(`Successfully sent -delete request to API for ${guid}`);
                    itemsSent++;
                } else {
                    console.error(`Error sending -delete request to API for ${guid}: ${response.statusText}`);
                }
            } catch (err) {
                console.error(`Error sending -delete request to API for ${guid}:`, err);
            }
    
            // Calculate and update progress percentage after each item
            const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
            setProgressContactsDelete(progressPercentage);
        }
    
        // After all items are processed, delete the entry from IndexedDB
        try {
            await del(key);
            // console.log(`Deleted key ${key} from IDB`);
    
            // Get all contacts and filter out the deleted ones
            const contacts = await get('drillit-contacts');
            const updatedContacts = contacts.filter((contact: any) => !deleteData.some((delItem: any) => delItem.guid === contact.guid));
            await set('drillit-contacts', updatedContacts);
            // console.log('Contact deleted from drillit-contacts in IDB.');
    
            // Update company-specific contacts
            for (const item of deleteData) {
                const { guid, company_id } = item;
                const companyKey = `drillit-contacts/company/${company_id}`;
                const companyContacts = await get(companyKey);
                if (companyContacts && companyContacts.contacts) {
                    const updatedCompanyContacts = companyContacts.contacts.filter((contact: any) => contact.guid !== guid);
                    companyContacts.contacts = updatedCompanyContacts;
                    await set(companyKey, companyContacts);
                    // console.log(`Contact deleted from drillit-contacts/company/${company_id} in IDB.`);
                }
            }
    
            return true; // Indicates success
        } catch (err) {
            console.error(`Error deleting key ${key} from IDB or updating contacts:`, err);
            return false; // Indicates failure
        }
    };
    



    



    //..................................................................................................................................
    //.PPPPPPPPP...PLLL..........AAAAA.....NNNN...NNNN..TTTTTTTTTTT.......CCCCCCC....HHHH...HHHH..EEEEEEEEEEE...CCCCCCC....KKKK...KKKK..
    //.PPPPPPPPPP..PLLL..........AAAAA.....NNNNN..NNNN..TTTTTTTTTTT......CCCCCCCCC...HHHH...HHHH..EEEEEEEEEEE..CCCCCCCCC...KKKK..KKKKK..
    //.PPPPPPPPPPP.PLLL.........AAAAAA.....NNNNN..NNNN..TTTTTTTTTTT.....CCCCCCCCCCC..HHHH...HHHH..EEEEEEEEEEE.CCCCCCCCCCC..KKKK.KKKKK...
    //.PPPP...PPPP.PLLL.........AAAAAAA....NNNNNN.NNNN.....TTTT.........CCCC...CCCCC.HHHH...HHHH..EEEE........CCCC...CCCCC.KKKKKKKKK....
    //.PPPP...PPPP.PLLL........AAAAAAAA....NNNNNN.NNNN.....TTTT........ CCC.....CCC..HHHH...HHHH..EEEE.......ECCC.....CCC..KKKKKKKK.....
    //.PPPPPPPPPPP.PLLL........AAAAAAAA....NNNNNNNNNNN.....TTTT........ CCC..........HHHHHHHHHHH..EEEEEEEEEE.ECCC..........KKKKKKKK.....
    //.PPPPPPPPPP..PLLL........AAAA.AAAA...NNNNNNNNNNN.....TTTT........ CCC..........HHHHHHHHHHH..EEEEEEEEEE.ECCC..........KKKKKKKK.....
    //.PPPPPPPPP...PLLL.......LAAAAAAAAA...NNNNNNNNNNN.....TTTT........ CCC..........HHHHHHHHHHH..EEEEEEEEEE.ECCC..........KKKKKKKKK....
    //.PPPP........PLLL.......LAAAAAAAAAA..NNNNNNNNNNN.....TTTT........ CCC.....CCC..HHHH...HHHH..EEEE.......ECCC.....CCC..KKKK.KKKKK...
    //.PPPP........PLLL.......LAAAAAAAAAA..NNNN.NNNNNN.....TTTT.........CCCC...CCCCC.HHHH...HHHH..EEEE........CCCC...CCCCC.KKKK..KKKK...
    //.PPPP........PLLLLLLLLLLLAA....AAAA..NNNN..NNNNN.....TTTT.........CCCCCCCCCCC..HHHH...HHHH..EEEEEEEEEEE.CCCCCCCCCCC..KKKK..KKKKK..
    //.PPPP........PLLLLLLLLLLLAA.....AAAA.NNNN..NNNNN.....TTTT..........CCCCCCCCCC..HHHH...HHHH..EEEEEEEEEEE..CCCCCCCCCC..KKKK...KKKK..
    //.PPPP........PLLLLLLLLLLLAA.....AAAA.NNNN...NNNN.....TTTT...........CCCCCCC....HHHH...HHHH..EEEEEEEEEEE...CCCCCCC....KKKK...KKKK..
    //..................................................................................................................................

    //............................
    //....CCCCCCC....PPPPPPPPP....
    //...CCCCCCCCC...PPPPPPPPPP...
    //..CCCCCCCCCCC..PPPPPPPPPPP..
    //..CCCC...CCCCC.PPPP...PPPP..
    //.CCCC.....CCC..PPPP...PPPP..
    //.CCCC..........PPPPPPPPPPP..
    //.CCCC..........PPPPPPPPPP...
    //.CCCC..........PPPPPPPPP....
    //.CCCC.....CCC..PPPP.........
    //..CCCC...CCCCC.PPPP.........
    //..CCCCCCCCCCC..PPPP.........
    //...CCCCCCCCCC..PPPP.........
    //....CCCCCCC....PPPP.........
    //............................
    
    const processPlantCheckCableNew = async (value: any, key: string) => {
        // console.log(`Starting processPlantCheckCableNew for key: ${key}`);
    
        try {
            const dataArray = Array.isArray(value) ? value : [value];
            const totalItems = dataArray.length;
            let itemsSent = 0;
            let failedItems = [];
    
            // console.log(`Total items to process: ${totalItems}`);
    
            // Delete the entry from IndexedDB regardless of success or failure in processing
            await del(key);
            // console.log(`Deleted entry: ${key} from IndexedDB`);
    
            for (const item of dataArray) {
                const {
                    towHitchHandbrakeSafteyCable,
                    allDrillingEquipmentGood,
                    wireRopeInDateGoodLoop,
                    fuelOilLevelsLeaks,
                    footBrakeInGoodOrder,
                    fireExtinguisherFirstAid,
                    plantNappyRequired,
                    spillKit,
                    safteyGuards,
                    wheelsTyres,
                    cluchInGoodOrder,
                    allLiftingPointsGood,
                    rigFrameRetainingPins,
                    engineKillStop,
                    drillingArea,
                    electricWinchRemote,
                    ignitionSystem,
                    mainWinchOperation,
                    footBrake,
                    engineOperation,
                    rigFrame,
                    issuesMaintenance,
                    company_guid,
                    company_id,
                    driller_name,
                    driller_cscs,
                    guid,
                    plant_guid,
                    plant_id,
                    plant_name,
                    spt_rod_type,
                    spt_id_ref,
                    spt_energy_ratio,
                    spt_calibration_date,
                    plant_test_date_expiry,
                    plant_check_date,
                    plantcheck_url,
                    project_guid
                } = item;
    
                const endpoint = `${process.env.REACT_APP_API_ADDR}plant-check-cable-percussive`;
                const method = 'POST';
    
                const dataToSendToAPI = {
                    towHitchHandbrakeSafteyCable,
                    allDrillingEquipmentGood,
                    wireRopeInDateGoodLoop,
                    fuelOilLevelsLeaks,
                    footBrakeInGoodOrder,
                    fireExtinguisherFirstAid,
                    plantNappyRequired,
                    spillKit,
                    safteyGuards,
                    wheelsTyres,
                    cluchInGoodOrder,
                    allLiftingPointsGood,
                    rigFrameRetainingPins,
                    engineKillStop,
                    drillingArea,
                    electricWinchRemote,
                    ignitionSystem,
                    mainWinchOperation,
                    footBrake,
                    engineOperation,
                    rigFrame,
                    issuesMaintenance,
                    company_guid,
                    company_id,
                    driller_name,
                    driller_cscs,
                    guid,
                    plant_guid,
                    plant_id,
                    plant_name,
                    spt_rod_type,
                    spt_id_ref,
                    spt_energy_ratio,
                    spt_calibration_date,
                    plant_test_date_expiry,
                    plant_check_date,
                    plantcheck_url,
                    project_guid
                };
    
                // Check if record exists on the server
                const exists = await checkFilesExist('plant-check-cable', guid);
                if (exists) {
                    // console.log(`Record already exists for guid ${guid}. Skipping.`);
                    itemsSent++;
                    const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                    setProgressPlantCheckCableNew(progressPercentage);
                    continue;
                }
    
                try {
                    // console.log(`Sending request to ${endpoint} with data:`, dataToSendToAPI);
                    const response = await makeRequest(endpoint, JSON.stringify(dataToSendToAPI), method);
                    // console.log(`PLANT-CHECK-CABLE -NEW ${key}: ${response.statusText} ${response.status}`);
                    if (response.status === 200) {
                        itemsSent++;
                        const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                        setProgressPlantCheckCableNew(progressPercentage);
                        // console.log(`Successfully sent plant-check-cable-percussive-new data to API for ${guid}`);
                    } else {
                        console.error(`Error sending plant-check-cable-percussive-new data to API for ${guid}: ${response.statusText}`);
                        failedItems.push(item);
                    }
                } catch (err) {
                    console.error(`Error sending plant-check-cable-percussive-new data to API for ${guid}:`, err);
                    failedItems.push(item);
                }
            }
    
            // console.log(`Items sent: ${itemsSent}, Failed items: ${failedItems.length}`);
    
            if (failedItems.length > 0) {
                try {
                    await set(key, failedItems);
                    // console.log(`Updated ${key} in IDB with failed items.`);
                } catch (err) {
                    console.error(`Error updating ${key} in IDB with failed items:`, err);
                }
            } else {
                try {
                    await del(key);
                    // console.log(`Deleted entry: ${key} from IDB after successful processing.`);
                } catch (err) {
                    console.error(`Error deleting ${key} from IDB:`, err);
                }
            }
    
            // Retry failed items
            await processPlantCheckCableNewRetry(value, key); // Pass value and key to retry function
    
            // console.log(`Finished processPlantCheckCableNew for key: ${key}`);
            return true; // Indicates success
        } catch (error) {
            console.error(`Error processing plant-check-cable-new for key ${key}:`, error);
            return false; // Indicates failure
        }
    };
    
    const processPlantCheckCableNewRetry = async (value: any, key: string) => {
        // console.log(`Retrying failed plant-check-cable-new items for key: ${key}`);
    
        try {
            const failedItems = await get(key); // Retrieve failed items from IndexedDB
            if (!failedItems || failedItems.length === 0) {
                // console.log(`No failed plant-check-cable-new items found for key: ${key}`);
                return;
            }
    
            const totalItems = failedItems.length;
            let itemsSent = 0;
            let retryFailedItems = [];
    
            for (const item of failedItems) {
                const {
                    towHitchHandbrakeSafteyCable,
                    allDrillingEquipmentGood,
                    wireRopeInDateGoodLoop,
                    fuelOilLevelsLeaks,
                    footBrakeInGoodOrder,
                    fireExtinguisherFirstAid,
                    plantNappyRequired,
                    spillKit,
                    safteyGuards,
                    wheelsTyres,
                    cluchInGoodOrder,
                    allLiftingPointsGood,
                    rigFrameRetainingPins,
                    engineKillStop,
                    drillingArea,
                    electricWinchRemote,
                    ignitionSystem,
                    mainWinchOperation,
                    footBrake,
                    engineOperation,
                    rigFrame,
                    issuesMaintenance,
                    company_guid,
                    company_id,
                    driller_name,
                    driller_cscs,
                    guid,
                    plant_guid,
                    plant_id,
                    plant_name,
                    spt_rod_type,
                    spt_id_ref,
                    spt_energy_ratio,
                    spt_calibration_date,
                    plant_test_date_expiry,
                    plant_check_date,
                    plantcheck_url,
                    project_guid
                } = item;
    
                const endpoint = `${process.env.REACT_APP_API_ADDR}plant-check-cable-percussive`;
                const method = 'POST';
    
                const dataToSendToAPI = {
                    towHitchHandbrakeSafteyCable,
                    allDrillingEquipmentGood,
                    wireRopeInDateGoodLoop,
                    fuelOilLevelsLeaks,
                    footBrakeInGoodOrder,
                    fireExtinguisherFirstAid,
                    plantNappyRequired,
                    spillKit,
                    safteyGuards,
                    wheelsTyres,
                    cluchInGoodOrder,
                    allLiftingPointsGood,
                    rigFrameRetainingPins,
                    engineKillStop,
                    drillingArea,
                    electricWinchRemote,
                    ignitionSystem,
                    mainWinchOperation,
                    footBrake,
                    engineOperation,
                    rigFrame,
                    issuesMaintenance,
                    company_guid,
                    company_id,
                    driller_name,
                    driller_cscs,
                    guid,
                    plant_guid,
                    plant_id,
                    plant_name,
                    spt_rod_type,
                    spt_id_ref,
                    spt_energy_ratio,
                    spt_calibration_date,
                    plant_test_date_expiry,
                    plant_check_date,
                    plantcheck_url,
                    project_guid
                };
    
                // Check if record still doesn't exist on the server
                const exists = await checkFilesExist('plant-check-cable', guid);
                if (exists) {
                    // console.log(`Record still exists for guid ${guid}. Skipping retry.`);
                    itemsSent++;
                    const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                    setProgressPlantCheckCableNew(progressPercentage);
                    continue;
                }
    
                try {
                    // console.log(`Retrying sending plant-check-cable-new request to ${endpoint} with data:`, dataToSendToAPI);
                    const response = await makeRequest(endpoint, JSON.stringify(dataToSendToAPI), method);
                    // console.log(`PLANT-CHECK-CABLE -NEW Retry ${key}: ${response.statusText} ${response.status}`);
                    if (response.status === 200) {
                        itemsSent++;
                        const progressPercentage = Math.floor(((itemsSent + itemsSent) / totalItems) * 100);
                        setProgressPlantCheckCableNew(progressPercentage);
                        // console.log(`Successfully sent plant-check-cable-percussive-new data to API for ${guid}`);
                    } else {
                        console.error(`Error sending plant-check-cable-percussive-new data to API for ${guid}: ${response.statusText} ${response.status}`);
                        retryFailedItems.push(item);
                    }
                } catch (err) {
                    console.error(`Error sending plant-check-cable-percussive-new data to API for ${guid}:`, err);
                    retryFailedItems.push(item);
                }
            }
    
            // console.log(`Retry: Items sent: ${itemsSent}, Failed items: ${retryFailedItems.length}`);
    
            if (retryFailedItems.length > 0) {
                try {
                    await set(key, retryFailedItems);
                    // console.log(`Updated ${key} in IDB with retry failed items.`);
                } catch (err) {
                    console.error(`Error updating ${key} in IDB with retry failed items:`, err);
                }
            } else {
                try {
                    await del(key);
                    // console.log(`Deleted entry: ${key} from IDB after successful retry processing.`);
                } catch (err) {
                    console.error(`Error deleting ${key} from IDB after retry:`, err);
                }
            }
        } catch (error) {
            console.error(`Error retrying failed plant-check-cable-new items for key ${key}:`, error);
        }
    
        console.log(`Finished retrying failed plant-check-cable-new items for key: ${key}`);
    };
    
    
    
    
    
    const processPlantCheckCableUpdate = async (value: any, key: string) => {
        const updateData = value;
        const updateItems = Array.isArray(updateData) ? updateData : [updateData];
        const totalItems = updateItems.length;
        let itemsSent = 0;
    
        for (const item of updateItems) {
            const { guid, ...plantCheckData } = item;
            const endpoint = `${process.env.REACT_APP_API_ADDR}plant-check-cable-percussive/${guid}`;
            const method = 'PUT';
    
            try {
                const response = await makeRequest(endpoint, JSON.stringify(plantCheckData), method);
                if (response.status === 200) {
                    itemsSent++;
                    // console.log(`Successfully sent plant-check-cable-percussive-update data to API for ${guid}`);
                } else {
                    console.error(`Error sending plant-check-cable-percussive-update data to API for ${guid}: ${response.statusText}`);
                    // Handle error if needed
                }
            } catch (err) {
                console.error(`Error sending plant-check-cable-percussive-update data to API for ${guid}:`, err);
                // Handle error if needed
            }
    
            // Calculate progress percentage after each item
            const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
            setProgressPlantCheckCableUpdate(progressPercentage);
        }
    
        // After all items are processed, delete the entry from IndexedDB
        try {
            await del(key);
            // console.log(`Deleted entry: ${key}`);
            return true; // Indicates success
        } catch (error) {
            console.error(`Error deleting entry: ${key}`, error);
            return false; // Indicates failure
        }
    };
    



    //.........................
    //.RRRRRRRRRR...TTTTTTTTT..
    //.RRRRRRRRRRR..TTTTTTTTT..
    //.RRRRRRRRRRR..TTTTTTTTT..
    //.RRRR...RRRRR....TTTT....
    //.RRRR...RRRRR....TTTT....
    //.RRRRRRRRRRR.....TTTT....
    //.RRRRRRRRRRR.....TTTT....
    //.RRRRRRRR........TTTT....
    //.RRRR.RRRR.......TTTT....
    //.RRRR..RRRR......TTTT....
    //.RRRR..RRRRR.....TTTT....
    //.RRRR...RRRRR....TTTT....
    //.RRRR....RRRR....TTTT....
    //.........................

    const processPlantCheckRotaryNew = async (value: any, key: string) => {
        // console.log(`Starting processPlantCheckRotaryNew for key: ${key}`);
    
        try {
            const dataArray = Array.isArray(value) ? value : [value];
            const totalItems = dataArray.length;
            let itemsSent = 0;
            let failedItems = [];
    
            // console.log(`Total items to process: ${totalItems}`);
    
            // Delete the entry from IndexedDB regardless of success or failure in processing
            await del(key);
            // console.log(`Deleted entry: ${key} from IndexedDB`);
    
            for (const item of dataArray) {
                const {
                    airlineSafetyConnections,
                    chainPulleysCleanAndLubricate,
                    chainTensionAndLubrication,
                    checkBatterySecurityAndLevel,
                    checkWarningLights,
                    company_guid,
                    company_id,
                    compressorAirLines,
                    compressorCoolantLevel,
                    compressorEngineOil,
                    compressorHydraulicOil,
                    compressorRunHours,
                    conditionOfCasingDriveHead,
                    conditionOfControlPanel,
                    conditionOfFlushPump,
                    conditionOfHoistingPlugs,
                    conditionOfHydraulicHammer,
                    conditionOfMistPump,
                    conditionOfSPTLiftingPoint,
                    conditionOfServiceWinch,
                    conditionOfShackles,
                    conditionOfSwivels,
                    conditionOfWirelineWinch,
                    coolantLevel,
                    driller_cscs,
                    driller_name,
                    emergencyStopTest,
                    engineHours,
                    engineOilLevel,
                    fireExtinguisherGoodCondition,
                    fixedGuardTest,
                    fuelLevel,
                    generalConditionOfBodyCracksDamage,
                    greaseHeadShaft,
                    guid,
                    headOilLevel,
                    hydraulicCircuitLeaksDamage,
                    hydraulicOilLevel,
                    issuesMaintenance,
                    lubricateHeadSlides,
                    lubricateMastSlides,
                    pinsGrease,
                    plant_check_date,
                    plant_guid,
                    plant_id,
                    plant_name,
                    plant_test_date_expiry,
                    plantcheck_url,
                    project_guid,
                    remoteControlWorking,
                    rigAndHeatExchangerClean,
                    securityOfBolts,
                    securityOfFlushSwivelAndGrease,
                    spt_calibration_date,
                    spt_energy_ratio,
                    spt_id_ref,
                    spt_rod_type
                } = item;
    
                const endpoint = `${process.env.REACT_APP_API_ADDR}plant-check-rotary`;
                const method = 'POST';
    
                const dataToSendToAPI = {
                    airlineSafetyConnections,
                    chainPulleysCleanAndLubricate,
                    chainTensionAndLubrication,
                    checkBatterySecurityAndLevel,
                    checkWarningLights,
                    company_guid,
                    company_id,
                    compressorAirLines,
                    compressorCoolantLevel,
                    compressorEngineOil,
                    compressorHydraulicOil,
                    compressorRunHours,
                    conditionOfCasingDriveHead,
                    conditionOfControlPanel,
                    conditionOfFlushPump,
                    conditionOfHoistingPlugs,
                    conditionOfHydraulicHammer,
                    conditionOfMistPump,
                    conditionOfSPTLiftingPoint,
                    conditionOfServiceWinch,
                    conditionOfShackles,
                    conditionOfSwivels,
                    conditionOfWirelineWinch,
                    coolantLevel,
                    driller_cscs,
                    driller_name,
                    emergencyStopTest,
                    engineHours,
                    engineOilLevel,
                    fireExtinguisherGoodCondition,
                    fixedGuardTest,
                    fuelLevel,
                    generalConditionOfBodyCracksDamage,
                    greaseHeadShaft,
                    guid,
                    headOilLevel,
                    hydraulicCircuitLeaksDamage,
                    hydraulicOilLevel,
                    issuesMaintenance,
                    lubricateHeadSlides,
                    lubricateMastSlides,
                    pinsGrease,
                    plant_check_date,
                    plant_guid,
                    plant_id,
                    plant_name,
                    plant_test_date_expiry,
                    plantcheck_url,
                    project_guid,
                    remoteControlWorking,
                    rigAndHeatExchangerClean,
                    securityOfBolts,
                    securityOfFlushSwivelAndGrease,
                    spt_calibration_date,
                    spt_energy_ratio,
                    spt_id_ref,
                    spt_rod_type
                };
    
                // Check if record exists on the server
                const exists = await checkFilesExist('plant-check-rotary', guid);
                if (exists) {
                    itemsSent++;
                    const progressPercentage = Math.floor(((itemsSent + totalItems) / (totalItems * 2)) * 100);
                    setProgressPlantCheckRotaryNew(progressPercentage);
                    console.log(`Record already exists for guid ${guid}. Skipping.`);
                    continue;
                }
    
                try {
                    // console.log(`Sending request to ${endpoint} with data:`, dataToSendToAPI);
                    const response = await makeRequest(endpoint, JSON.stringify(dataToSendToAPI), method);
                    // console.log(`PLANT-CHECK-ROTARY -NEW ${key}: ${response.statusText} ${response.status}`);
                    if (response.status === 200) {
                        itemsSent++;
                        const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                        setProgressPlantCheckRotaryNew(progressPercentage);
                        // console.log(`Successfully sent plant-check-rotary-new data to API for ${guid}`);
                    } else {
                        console.error(`Error sending plant-check-rotary-new data to API for ${guid}: ${response.statusText}`);
                        failedItems.push(item);
                    }
                } catch (err) {
                    console.error(`Error sending plant-check-rotary-new data to API for ${guid}:`, err);
                    failedItems.push(item);
                }
            }
    
            // console.log(`Items sent: ${itemsSent}, Failed items: ${failedItems.length}`);
    
            if (failedItems.length > 0) {
                try {
                    await set(key, failedItems);
                    // console.log(`Updated ${key} in IDB with failed items.`);
                } catch (err) {
                    console.error(`Error updating ${key} in IDB with failed items:`, err);
                }
            } else {
                try {
                    await del(key);
                    // console.log(`Deleted entry: ${key} from IDB after successful processing.`);
                } catch (err) {
                    console.error(`Error deleting ${key} from IDB:`, err);
                }
            }
    
            // Retry failed items
            await processPlantCheckRotaryNewRetry(value, key); // Pass value and key to retry function
    
            // console.log(`Finished processPlantCheckRotaryNew for key: ${key}`);
            return true; // Indicates success
        } catch (error) {
            console.error(`Error processing plant-check-rotary-new for key ${key}:`, error);
            return false; // Indicates failure
        }
    };
    
    const processPlantCheckRotaryNewRetry = async (value: any, key: string) => {
        // console.log(`Retrying failed plant-check-rotary-new items for key: ${key}`);
    
        try {
            const failedItems = await get(key); // Retrieve failed items from IndexedDB
            if (!failedItems || failedItems.length === 0) {
                // console.log(`No failed plant-check-rotary-new items found for key: ${key}`);
                return;
            }
    
            const totalItems = failedItems.length;
            let itemsSent = 0;
            let retryFailedItems = [];
    
            for (const item of failedItems) {
                const {
                    airlineSafetyConnections,
                    chainPulleysCleanAndLubricate,
                    chainTensionAndLubrication,
                    checkBatterySecurityAndLevel,
                    checkWarningLights,
                    company_guid,
                    company_id,
                    compressorAirLines,
                    compressorCoolantLevel,
                    compressorEngineOil,
                    compressorHydraulicOil,
                    compressorRunHours,
                    conditionOfCasingDriveHead,
                    conditionOfControlPanel,
                    conditionOfFlushPump,
                    conditionOfHoistingPlugs,
                    conditionOfHydraulicHammer,
                    conditionOfMistPump,
                    conditionOfSPTLiftingPoint,
                    conditionOfServiceWinch,
                    conditionOfShackles,
                    conditionOfSwivels,
                    conditionOfWirelineWinch,
                    coolantLevel,
                    driller_cscs,
                    driller_name,
                    emergencyStopTest,
                    engineHours,
                    engineOilLevel,
                    fireExtinguisherGoodCondition,
                    fixedGuardTest,
                    fuelLevel,
                    generalConditionOfBodyCracksDamage,
                    greaseHeadShaft,
                    guid,
                    headOilLevel,
                    hydraulicCircuitLeaksDamage,
                    hydraulicOilLevel,
                    issuesMaintenance,
                    lubricateHeadSlides,
                    lubricateMastSlides,
                    pinsGrease,
                    plant_check_date,
                    plant_guid,
                    plant_id,
                    plant_name,
                    plant_test_date_expiry,
                    plantcheck_url,
                    project_guid,
                    remoteControlWorking,
                    rigAndHeatExchangerClean,
                    securityOfBolts,
                    securityOfFlushSwivelAndGrease,
                    spt_calibration_date,
                    spt_energy_ratio,
                    spt_id_ref,
                    spt_rod_type
                } = item;
    
                const endpoint = `${process.env.REACT_APP_API_ADDR}plant-check-rotary`;
                const method = 'POST';
    
                const dataToSendToAPI = {
                    airlineSafetyConnections,
                    chainPulleysCleanAndLubricate,
                    chainTensionAndLubrication,
                    checkBatterySecurityAndLevel,
                    checkWarningLights,
                    company_guid,
                    company_id,
                    compressorAirLines,
                    compressorCoolantLevel,
                    compressorEngineOil,
                    compressorHydraulicOil,
                    compressorRunHours,
                    conditionOfCasingDriveHead,
                    conditionOfControlPanel,
                    conditionOfFlushPump,
                    conditionOfHoistingPlugs,
                    conditionOfHydraulicHammer,
                    conditionOfMistPump,
                    conditionOfSPTLiftingPoint,
                    conditionOfServiceWinch,
                    conditionOfShackles,
                    conditionOfSwivels,
                    conditionOfWirelineWinch,
                    coolantLevel,
                    driller_cscs,
                    driller_name,
                    emergencyStopTest,
                    engineHours,
                    engineOilLevel,
                    fireExtinguisherGoodCondition,
                    fixedGuardTest,
                    fuelLevel,
                    generalConditionOfBodyCracksDamage,
                    greaseHeadShaft,
                    guid,
                    headOilLevel,
                    hydraulicCircuitLeaksDamage,
                    hydraulicOilLevel,
                    issuesMaintenance,
                    lubricateHeadSlides,
                    lubricateMastSlides,
                    pinsGrease,
                    plant_check_date,
                    plant_guid,
                    plant_id,
                    plant_name,
                    plant_test_date_expiry,
                    plantcheck_url,
                    project_guid,
                    remoteControlWorking,
                    rigAndHeatExchangerClean,
                    securityOfBolts,
                    securityOfFlushSwivelAndGrease,
                    spt_calibration_date,
                    spt_energy_ratio,
                    spt_id_ref,
                    spt_rod_type
                };
    
                // Check if record exists on the server (retry only if it still doesn't exist)
                const exists = await checkFilesExist('plant-check-rotary', guid);
                if (exists) {
                    itemsSent++;
                    const progressPercentage = Math.floor(((itemsSent + totalItems) / (totalItems * 2)) * 100);
                    setProgressPlantCheckRotaryNew(progressPercentage);
                    // console.log(`Record now exists for guid ${guid} during retry attempt. Skipping.`);
                    continue;
                }
    
                try {
                    // console.log(`Retry: Sending request to ${endpoint} with data:`, dataToSendToAPI);
                    const response = await makeRequest(endpoint, JSON.stringify(dataToSendToAPI), method);
                    // console.log(`Retry: PLANT-CHECK-ROTARY -NEW ${key}: ${response.statusText} ${response.status}`);
                    if (response.status === 200) {
                        itemsSent++;
                        const progressPercentage = Math.floor(((itemsSent + totalItems) / (totalItems * 2)) * 100);
                        setProgressPlantCheckRotaryNew(progressPercentage);
                        // console.log(`Retry: Successfully sent plant-check-rotary-new data to API for ${guid}`);
                    } else {
                        console.error(`Retry: Error sending plant-check-rotary-new data to API for ${guid}: ${response.statusText}`);
                        retryFailedItems.push(item);
                    }
                } catch (err) {
                    console.error(`Retry: Error sending plant-check-rotary-new data to API for ${guid}:`, err);
                    retryFailedItems.push(item);
                }
            }
    
            console.log(`Retry: Items sent: ${itemsSent}, Failed items: ${retryFailedItems.length}`);
    
            if (retryFailedItems.length > 0) {
                try {
                    await set(key, retryFailedItems);
                    // console.log(`Retry: Updated ${key} in IDB with retry failed items.`);
                } catch (err) {
                    console.error(`Retry: Error updating ${key} in IDB with retry failed items:`, err);
                }
            } else {
                try {
                    await del(key);
                    // console.log(`Retry: Deleted entry: ${key} from IDB after successful retry processing.`);
                } catch (err) {
                    console.error(`Retry: Error deleting ${key} from IDB after retry:`, err);
                }
            }
        } catch (error) {
            console.error(`Retry: Error retrying failed plant-check-rotary-new items for key ${key}:`, error);
        }
    
        console.log(`Finished retrying failed plant-check-rotary-new items for key: ${key}`);
    };
    
    
    
    
    
    const processPlantCheckRotaryUpdate = async (value: any, keyString: string) => {
        const updateData = value;
        const updateItems = Array.isArray(updateData) ? updateData : [updateData];
        const totalItems = updateItems.length;
        let itemsSent = 0;
    
        for (const item of updateItems) {
            const { guid, ...plantCheckData } = item;
            const endpoint = `${process.env.REACT_APP_API_ADDR}plant-check-rotary/${guid}`;
            const method = 'PUT';
    
            try {
                const response = await makeRequest(endpoint, JSON.stringify(plantCheckData), method);
                if (response.status === 200) {
                    itemsSent++;
                    // console.log(`Successfully sent plant-check-rotary-update data to API for ${guid}`);
                } else {
                    console.error(`Error sending plant-check-rotary-update data to API for ${guid}: ${response.statusText}`);
                    // Handle error if needed
                }
            } catch (err) {
                console.error(`Error sending plant-check-rotary-update data to API for ${guid}:`, err);
                // Handle error if needed
            }
    
            // Calculate progress percentage after each item
            const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
            setProgressPlantCheckRotaryUpdate(progressPercentage);
        }
    
        // After all items are processed, delete the entry from IndexedDB
        try {
            await del(keyString);
            // console.log(`Deleted entry: ${keyString}`);
            return true; // Indicates success
        } catch (error) {
            console.error(`Error deleting entry: ${keyString}`, error);
            return false; // Indicates failure
        }
    };
    



    //...............................
    //.WWWW..WWWWW...WWWS.SSSSSSS....
    //.WWWW..WWWWW..WWWW.SSSSSSSSS...
    //.WWWW..WWWWWW.WWWW.SSSSSSSSSS..
    //.WWWW.WWWWWWW.WWWWSSSSS..SSSS..
    //.WWWW.WWWWWWW.WWWWSSSSS........
    //..WWWWWWWWWWW.WWW..SSSSSSS.....
    //..WWWWWWW.WWWWWWW...SSSSSSSSS..
    //..WWWWWWW.WWWWWWW.....SSSSSSS..
    //..WWWWWWW.WWWWWWW........SSSS..
    //..WWWWWWW.WWWWWWW.SSSS....SSS..
    //...WWWWW...WWWWW..SSSSSSSSSSS..
    //...WWWWW...WWWWW...SSSSSSSSSS..
    //...WWWWW...WWWWW....SSSSSSSS...
    //...............................    

    const processPlantCheckWindowSampleNew = async (value: any, key: string) => {
        // console.log(`Starting processPlantCheckWindowSampleNew for key: ${key}`);
    
        try {
            const dataArray = Array.isArray(value) ? value : [value];
            const totalItems = dataArray.length;
            let itemsSent = 0;
            let failedItems = [];
    
            // console.log(`Total items to process: ${totalItems}`);
    
            // Delete the entry from IndexedDB regardless of success or failure in processing
            await del(key);
            // console.log(`Deleted entry: ${key} from IndexedDB`);
    
            for (const item of dataArray) {
                const {
                    chalwynValveSparkArrestorFitted,
                    company_guid,
                    company_id,
                    driller_cscs,
                    driller_name,
                    drillingTools,
                    emergencyStop,
                    engineOperation,
                    fireExtinguisher,
                    fuelEngineOilLevelsFilters,
                    generalEngineCheck,
                    guardFitted,
                    guid,
                    hydraulicSystem,
                    ignitionSystemElectronicSystem,
                    issuesMaintenance,
                    leverFunction,
                    mainChainFunction,
                    plant_check_date,
                    plant_guid,
                    plant_id,
                    plant_name,
                    plant_test_date_expiry,
                    plantcheck_url,
                    project_guid,
                    rigFrame,
                    rigFrameFunction,
                    rigTracks,
                    spillKitDrillTrays,
                    spt_calibration_date,
                    spt_energy_ratio,
                    spt_id_ref,
                    spt_rod_type,
                    towingEyeHandbrakeJockeyWheelBreakawayCable
                } = item;
    
                const endpoint = `${process.env.REACT_APP_API_ADDR}plant-check-window-sample`;
                const method = 'POST';
    
                const dataToSendToAPI = {
                    chalwynValveSparkArrestorFitted,
                    company_guid,
                    company_id,
                    driller_cscs,
                    driller_name,
                    drillingTools,
                    emergencyStop,
                    engineOperation,
                    fireExtinguisher,
                    fuelEngineOilLevelsFilters,
                    generalEngineCheck,
                    guardFitted,
                    guid,
                    hydraulicSystem,
                    ignitionSystemElectronicSystem,
                    issuesMaintenance,
                    leverFunction,
                    mainChainFunction,
                    plant_check_date,
                    plant_guid,
                    plant_id,
                    plant_name,
                    plant_test_date_expiry,
                    plantcheck_url,
                    project_guid,
                    rigFrame,
                    rigFrameFunction,
                    rigTracks,
                    spillKitDrillTrays,
                    spt_calibration_date,
                    spt_energy_ratio,
                    spt_id_ref,
                    spt_rod_type,
                    towingEyeHandbrakeJockeyWheelBreakawayCable
                };
    
                // Check if record exists on the server
                const exists = await checkFilesExist('plant-check-window-sample', guid);
                if (exists) {
                    // console.log(`Record already exists for guid ${guid}. Skipping.`);
                    continue;
                }
    
                try {
                    // console.log(`Sending request to ${endpoint} with data:`, dataToSendToAPI);
                    const response = await makeRequest(endpoint, JSON.stringify(dataToSendToAPI), method);
                    // console.log(`PLANT-CHECK-WINDOW-SAMPLE -NEW ${key}: ${response.statusText} ${response.status}`);
                    if (response.status === 200) {
                        itemsSent++;
                        const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                        setProgressPlantCheckWindowSampleNew(progressPercentage);
                        // console.log(`Successfully sent plant-check-window-sample-new data to API for ${guid}`);
                    } else {
                        console.error(`Error sending plant-check-window-sample-new data to API for ${guid}: ${response.statusText}`);
                        failedItems.push(item);
                    }
                } catch (err) {
                    console.error(`Error sending plant-check-window-sample-new data to API for ${guid}:`, err);
                    failedItems.push(item);
                }
            }
    
            // console.log(`Items sent: ${itemsSent}, Failed items: ${failedItems.length}`);
    
            if (failedItems.length > 0) {
                try {
                    await set(key, failedItems);
                    // console.log(`Updated ${key} in IDB with failed items.`);
                } catch (err) {
                    console.error(`Error updating ${key} in IDB with failed items:`, err);
                }
            } else {
                try {
                    await del(key);
                    // console.log(`Deleted entry: ${key} from IDB after successful processing.`);
                } catch (err) {
                    console.error(`Error deleting ${key} from IDB:`, err);
                }
            }
    
            // Retry failed items
            await processPlantCheckWindowSampleNewRetry(value, key); // Pass value and key to retry function
    
            // console.log(`Finished processPlantCheckWindowSampleNew for key: ${key}`);
            return true; // Indicates success
        } catch (error) {
            console.error(`Error processing plant-check-window-sample-new for key ${key}:`, error);
            return false; // Indicates failure
        }
    };
    
    const processPlantCheckWindowSampleNewRetry = async (value: any, key: string) => {
        // console.log(`Retrying failed plant-check-window-sample-new items for key: ${key}`);
    
        try {
            const failedItems = await get(key); // Retrieve failed items from IndexedDB
            if (!failedItems || failedItems.length === 0) {
                // console.log(`No failed plant-check-window-sample-new items found for key: ${key}`);
                return;
            }
    
            const totalItems = failedItems.length;
            let itemsSent = 0;
            let retryFailedItems = [];
    
            for (const item of failedItems) {
                const {
                    chalwynValveSparkArrestorFitted,
                    company_guid,
                    company_id,
                    driller_cscs,
                    driller_name,
                    drillingTools,
                    emergencyStop,
                    engineOperation,
                    fireExtinguisher,
                    fuelEngineOilLevelsFilters,
                    generalEngineCheck,
                    guardFitted,
                    guid,
                    hydraulicSystem,
                    ignitionSystemElectronicSystem,
                    issuesMaintenance,
                    leverFunction,
                    mainChainFunction,
                    plant_check_date,
                    plant_guid,
                    plant_id,
                    plant_name,
                    plant_test_date_expiry,
                    plantcheck_url,
                    project_guid,
                    rigFrame,
                    rigFrameFunction,
                    rigTracks,
                    spillKitDrillTrays,
                    spt_calibration_date,
                    spt_energy_ratio,
                    spt_id_ref,
                    spt_rod_type,
                    towingEyeHandbrakeJockeyWheelBreakawayCable
                } = item;
    
                const endpoint = `${process.env.REACT_APP_API_ADDR}plant-check-window-sample`;
                const method = 'POST';
    
                const dataToSendToAPI = {
                    chalwynValveSparkArrestorFitted,
                    company_guid,
                    company_id,
                    driller_cscs,
                    driller_name,
                    drillingTools,
                    emergencyStop,
                    engineOperation,
                    fireExtinguisher,
                    fuelEngineOilLevelsFilters,
                    generalEngineCheck,
                    guardFitted,
                    guid,
                    hydraulicSystem,
                    ignitionSystemElectronicSystem,
                    issuesMaintenance,
                    leverFunction,
                    mainChainFunction,
                    plant_check_date,
                    plant_guid,
                    plant_id,
                    plant_name,
                    plant_test_date_expiry,
                    plantcheck_url,
                    project_guid,
                    rigFrame,
                    rigFrameFunction,
                    rigTracks,
                    spillKitDrillTrays,
                    spt_calibration_date,
                    spt_energy_ratio,
                    spt_id_ref,
                    spt_rod_type,
                    towingEyeHandbrakeJockeyWheelBreakawayCable
                };
    
                // Check if record exists on the server (retry only if it still doesn't exist)
                const exists = await checkFilesExist('plant-check-window-sample', guid);
                if (exists) {
                    // console.log(`Record now exists for guid ${guid} during retry attempt. Skipping.`);
                    continue;
                }
    
                try {
                    // console.log(`Retry: Sending request to ${endpoint} with data:`, dataToSendToAPI);
                    const response = await makeRequest(endpoint, JSON.stringify(dataToSendToAPI), method);
                    // console.log(`Retry: PLANT-CHECK-WINDOW-SAMPLE -NEW ${key}: ${response.statusText} ${response.status}`);
                    if (response.status === 200) {
                        itemsSent++;
                        const progressPercentage = Math.floor(((itemsSent + totalItems) / (totalItems * 2)) * 100);
                        setProgressPlantCheckWindowSampleNew(progressPercentage);
                        // console.log(`Retry: Successfully sent plant-check-window-sample-new data to API for ${guid}`);
                    } else {
                        console.error(`Retry: Error sending plant-check-window-sample-new data to API for ${guid}: ${response.statusText}`);
                        retryFailedItems.push(item);
                    }
                } catch (err) {
                    console.error(`Retry: Error sending plant-check-window-sample-new data to API for ${guid}:`, err);
                    retryFailedItems.push(item);
                }
            }
    
            console.log(`Retry: Items sent: ${itemsSent}, Failed items: ${retryFailedItems.length}`);
    
            if (retryFailedItems.length > 0) {
                try {
                    await set(key, retryFailedItems);
                    // console.log(`Retry: Updated ${key} in IDB with retry failed items.`);
                } catch (err) {
                    console.error(`Retry: Error updating ${key} in IDB with retry failed items:`, err);
                }
            } else {
                try {
                    await del(key);
                    // console.log(`Retry: Deleted entry: ${key} from IDB after successful retry processing.`);
                } catch (err) {
                    console.error(`Retry: Error deleting ${key} from IDB after retry:`, err);
                }
            }
        } catch (error) {
            console.error(`Retry: Error retrying failed plant-check-window-sample-new items for key ${key}:`, error);
        }
    
        // console.log(`Finished retrying failed plant-check-window-sample-new items for key: ${key}`);
    };
    
    
    
    
    const processPlantCheckWindowSampleUpdate = async (value: any, keyString: string) => {
        const updateData = value;
        const updateItems = Array.isArray(updateData) ? updateData : [updateData];
        const totalItems = updateItems.length;
        let itemsSent = 0;
    
        for (const item of updateItems) {
            const { guid, ...plantCheckData } = item;
            const endpoint = `${process.env.REACT_APP_API_ADDR}plant-check-window-sample/${guid}`;
            const method = 'PUT';
    
            try {
                const response = await makeRequest(endpoint, JSON.stringify(plantCheckData), method);
                if (response.status === 200) {
                    itemsSent++;
                    // console.log(`Successfully sent plant-check-window-sample-update data to API for ${guid}`);
                } else {
                    console.error(`Error sending plant-check-window-sample-update data to API for ${guid}: ${response.statusText}`);
                    // Handle error if needed
                }
            } catch (err) {
                console.error(`Error sending plant-check-window-sample-update data to API for ${guid}:`, err);
                // Handle error if needed
            }
    
            // Calculate progress percentage after each item
            const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
            setProgressPlantCheckWindowSampleUpdate(progressPercentage);
        }
    
        // After all items are processed, delete the entry from IndexedDB
        try {
            await del(keyString);
            // console.log(`Deleted entry: ${keyString}`);
            return true; // Indicates success
        } catch (error) {
            console.error(`Error deleting entry: ${keyString}`, error);
            return false; // Indicates failure
        }
    };
    



    //..............................................................................................................................................................................
    //.RRRRRRRRRR..RIIII..SSSSSSS....SKKK...KKKKK.........AAAAA......SSSSSSS.....SSSSSSS....SEEEEEEEEEE..SSSSSSS.....SSSSSSS...SSMMMM...MMMMMM.MEEEEEEEEEE.ENNN...NNNN..NTTTTTTTTT..
    //.RRRRRRRRRRR.RIIII.SSSSSSSSS...SKKK..KKKKK..........AAAAA.....ASSSSSSSS...SSSSSSSSS...SEEEEEEEEEE.ESSSSSSSS...SSSSSSSSS..SSMMMM...MMMMMM.MEEEEEEEEEE.ENNNN..NNNN..NTTTTTTTTT..
    //.RRRRRRRRRRR.RIIII.SSSSSSSSSS..SKKK.KKKKK..........AAAAAA.....ASSSSSSSSS..SSSSSSSSSS..SEEEEEEEEEE.ESSSSSSSSS..SSSSSSSSSS.SSMMMM...MMMMMM.MEEEEEEEEEE.ENNNN..NNNN..NTTTTTTTTT..
    //.RRRR...RRRRRRIIIIISSSS..SSSS..SKKKKKKKK...........AAAAAAA...AASSS..SSSS.SSSSS..SSSS..SEEE.......EESSS..SSSS.SSSSS..SSSS.SSMMMMM.MMMMMMM.MEEE........ENNNNN.NNNN.....TTTT.....
    //.RRRR...RRRRRRIIIIISSSS........SKKKKKKK...........AAAAAAAA...AASSS.......SSSSS........SEEE.......EESSS.......SSSSS.......SSMMMMM.MMMMMMM.MEEE........ENNNNN.NNNN.....TTTT.....
    //.RRRRRRRRRRR.RIIII.SSSSSSS.....SKKKKKKK...........AAAAAAAA....ASSSSSS.....SSSSSSS.....SEEEEEEEEE..ESSSSSS.....SSSSSSS....SSMMMMM.MMMMMMM.MEEEEEEEEE..ENNNNNNNNNN.....TTTT.....
    //.RRRRRRRRRRR.RIIII..SSSSSSSSS..SKKKKKKK...........AAAA.AAAA....SSSSSSSSS...SSSSSSSSS..SEEEEEEEEE...SSSSSSSSS...SSSSSSSSS.SSMMMMMMMMMMMMM.MEEEEEEEEE..ENNNNNNNNNN.....TTTT.....
    //.RRRRRRRR....RIIII....SSSSSSS..SKKKKKKKK......... AAAAAAAAA......SSSSSSS.....SSSSSSS..SEEEEEEEEE.....SSSSSSS.....SSSSSSS.SSMMMMMMMMMMMMM.MEEEEEEEEE..ENNNNNNNNNN.....TTTT.....
    //.RRRR.RRRR...RIIII.......SSSSS.SKKK.KKKKK........ AAAAAAAAAA........SSSSS.......SSSSS.SEEE..............SSSSS.......SSSSSSSMMMMMMMMMMMMM.MEEE........ENNNNNNNNNN.....TTTT.....
    //.RRRR..RRRR..RIIIIISSS....SSSS.SKKK..KKKK........ AAAAAAAAAA.AASS....SSSSSSSS....SSSS.SEEE.......EESS....SSSSSSSS....SSSSSSMM.MMMMM.MMMM.MEEE........ENNN.NNNNNN.....TTTT.....
    //.RRRR..RRRRR.RIIIIISSSSSSSSSSS.SKKK..KKKKK...... AA....AAAA.AASSSSSSSSSSSSSSSSSSSSSS.SEEEEEEEEEEEESSSSSSSSSSSSSSSSSSSSSSSSMM.MMMMM.MMMM.MEEEEEEEEEE.ENNN..NNNNN.....TTTT.....
    //.RRRR...RRRRRRIIII.SSSSSSSSSS..SKKK...KKKKK..... AA.....AAAA.ASSSSSSSSS..SSSSSSSSSS..SEEEEEEEEEE.ESSSSSSSSS..SSSSSSSSSS.SSMM.MMMMM.MMMM.MEEEEEEEEEE.ENNN..NNNNN.....TTTT.....
    //.RRRR....RRRRRIIII..SSSSSSSS...SKKK...KKKKK.... AA.....AAAA..SSSSSSSS....SSSSSSSS...SEEEEEEEEEE..SSSSSSSS....SSSSSSSS..SSMM.MMMMM.MMMM.MEEEEEEEEEE.ENNN...NNNN.....TTTT.....
    //..............................................................................................................................................................................
    
        const processRiskAssessmentNew = async (value: any, key: string) => {
        // console.log(`Starting processRiskAssessmentNew for key: ${key}`);
    
        try {
            const dataArray = Array.isArray(value) ? value : [value];
            const totalItems = dataArray.length;
            let itemsSent = 0;
            let failedItems = [];
    
            // console.log(`Total items to process: ${totalItems}`);
    
            // Delete the entry from IndexedDB regardless of success or failure in processing
            await del(key);
            // console.log(`Deleted entry: ${key} from IndexedDB`);
    
            for (const item of dataArray) {
                const { guid, project_guid } = item;
    
                const endpoint = `${process.env.REACT_APP_API_ADDR}risk-assessment/project/${project_guid}`;
                const method = 'POST';
    
                // Check if record exists on the server
                const exists = await checkFilesExist('risk-assessment', guid);
                if (exists) {
                    itemsSent++;
                    const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                    setProgressRiskAssessmentNew(progressPercentage);
                    // console.log(`Record already exists for guid ${guid}. Skipping.`);
                    continue;
                }
    
                try {
                    // console.log(`Sending request to ${endpoint} with data:`, item);
                    const response = await makeRequest(endpoint, JSON.stringify(item), method);
                    // console.log(`RISK-ASSESSMENT -NEW ${key}: ${response.statusText} ${response.status}`);
                    if (response.status === 200) {
                        itemsSent++;
                        const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                        setProgressRiskAssessmentNew(progressPercentage);
                        // console.log(`Successfully sent risk assessment data to API for ${guid}`);
                    } else {
                        console.error(`Error sending risk assessment data to API for ${guid}: ${response.statusText}`);
                        failedItems.push(item);
                    }
                } catch (err) {
                    console.error(`Error sending risk assessment data to API for ${guid}:`, err);
                    failedItems.push(item);
                }
            }
    
            // console.log(`Items sent: ${itemsSent}, Failed items: ${failedItems.length}`);
    
            if (failedItems.length > 0) {
                try {
                    await set(key, failedItems);
                    // console.log(`Updated ${key} in IDB with failed items.`);
                } catch (err) {
                    console.error(`Error updating ${key} in IDB with failed items:`, err);
                }
            } else {
                try {
                    await del(key);
                    // console.log(`Deleted entry: ${key} from IDB after successful processing.`);
                } catch (err) {
                    console.error(`Error deleting ${key} from IDB:`, err);
                }
            }
    
            // Retry failed items
            await processRiskAssessmentNewRetry(value, key); // Pass value and key to retry function
    
            // console.log(`Finished processRiskAssessmentNew for key: ${key}`);
            return true; // Indicates success
        } catch (error) {
            console.error(`Error processing risk assessment for key ${key}:`, error);
            return false; // Indicates failure
        }
    };
    
    const processRiskAssessmentNewRetry = async (value: any, key: string) => {
        // console.log(`Retrying failed risk assessments for key: ${key}`);
    
        try {
            const failedItems = await get(key); // Retrieve failed items from IndexedDB
            if (!failedItems || failedItems.length === 0) {
                // console.log(`No failed risk assessments found for key: ${key}`);
                return;
            }
    
            const totalItems = failedItems.length;
            let itemsSent = 0;
            let retryFailedItems = [];
    
            for (const item of failedItems) {
                const { guid, project_guid } = item;
    
                const endpoint = `${process.env.REACT_APP_API_ADDR}risk-assessment/project/${project_guid}`;
                const method = 'POST';
    
                // Check if record exists on the server (retry only if it still doesn't exist)
                const exists = await checkFilesExist('risk-assessment', guid);
                if (exists) {
                    itemsSent++;
                    const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                    setProgressRiskAssessmentNew(progressPercentage);
                    // console.log(`Record now exists for guid ${guid} during retry attempt. Skipping.`);
                    continue;
                }
    
                try {
                    // console.log(`Retry: Sending request to ${endpoint} with data:`, item);
                    const response = await makeRequest(endpoint, JSON.stringify(item), method);
                    // console.log(`Retry: RISK-ASSESSMENT -NEW ${key}: ${response.statusText} ${response.status}`);
                    if (response.status === 200) {
                        itemsSent++;
                        const progressPercentage = Math.floor(((itemsSent + totalItems) / (totalItems * 2)) * 100);
                        setProgressRiskAssessmentNew(progressPercentage);
                        // console.log(`Retry: Successfully sent risk assessment data to API for ${guid}`);
                    } else {
                        console.error(`Retry: Error sending risk assessment data to API for ${guid}: ${response.statusText}`);
                        retryFailedItems.push(item);
                    }
                } catch (err) {
                    console.error(`Retry: Error sending risk assessment data to API for ${guid}:`, err);
                    retryFailedItems.push(item);
                }
            }
    
            // console.log(`Retry: Items sent: ${itemsSent}, Failed items: ${retryFailedItems.length}`);
    
            if (retryFailedItems.length > 0) {
                try {
                    await set(key, retryFailedItems);
                    // console.log(`Retry: Updated ${key} in IDB with retry failed items.`);
                } catch (err) {
                    console.error(`Retry: Error updating ${key} in IDB with retry failed items:`, err);
                }
            } else {
                try {
                    await del(key);
                    // console.log(`Retry: Deleted entry: ${key} from IDB after successful retry processing.`);
                } catch (err) {
                    console.error(`Retry: Error deleting ${key} from IDB after retry:`, err);
                }
            }
        } catch (error) {
            console.error(`Retry: Error retrying failed risk assessments for key ${key}:`, error);
        }
    
        // console.log(`Finished retrying failed risk assessments for key: ${key}`);
    };
    
    



    //.........................................................
    //.EEEEEEEEEEEEEMMMM...MMMMMM....AAAAA....AIIII.LLLL.......
    //.EEEEEEEEEEEEEMMMM...MMMMMM....AAAAA....AIIII.LLLL.......
    //.EEEEEEEEEEEEEMMMM...MMMMMM...AAAAAA....AIIII.LLLL.......
    //.EEEE.......EEMMMMM.MMMMMMM...AAAAAAA...AIIII.LLLL.......
    //.EEEE.......EEMMMMM.MMMMMMM..AAAAAAAA...AIIII.LLLL.......
    //.EEEEEEEEEE.EEMMMMM.MMMMMMM..AAAAAAAA...AIIII.LLLL.......
    //.EEEEEEEEEE.EEMMMMMMMMMMMMM..AAAA.AAAA..AIIII.LLLL.......
    //.EEEEEEEEEE.EEMMMMMMMMMMMMM.AAAAAAAAAA..AIIII.LLLL.......
    //.EEEE.......EEMMMMMMMMMMMMM.AAAAAAAAAAA.AIIII.LLLL.......
    //.EEEE.......EEMM.MMMMM.MMMM.AAAAAAAAAAA.AIIII.LLLL.......
    //.EEEEEEEEEEEEEMM.MMMMM.MMMMMAAA....AAAA.AIIII.LLLLLLLLL..
    //.EEEEEEEEEEEEEMM.MMMMM.MMMMMAAA.....AAAAAIIII.LLLLLLLLL..
    //.EEEEEEEEEEEEEMM.MMMMM.MMMMMAAA.....AAAAAIIII.LLLLLLLLL..
    //.........................................................

    const processEmailNew = async (value: any, keyString: string) => {
        const dataArray = Array.isArray(value) ? value : [value];
        const totalItems = dataArray.length;
        let itemsSent = 0;
        let failedItems = [];
    
        try {
            await del(keyString);
            // console.log(`Deleted entry: ${keyString}`);

            // Introduce a 5-second delay
            await new Promise(resolve => setTimeout(resolve, 4000));
    
            for (const item of dataArray) {
                const { emails, log_paths, message, subject } = item;
    
                const endpoint = `${process.env.REACT_APP_API_ADDR}email`;
                const method = 'POST';
    
                try {
                    const response = await makeRequest(endpoint, JSON.stringify(item), method);
    
                    if (response.status === 200) {
                        itemsSent++;
                        const progressPercentage = Math.floor((itemsSent / totalItems) * 100);
                        setProgressEmailNew(progressPercentage);
                        // console.log(`Successfully sent email data to API`);
                    } else {
                        console.error(`Error sending email data to API: ${response.statusText}`);
                        failedItems.push(item);
                    }
                } catch (err) {
                    console.error(`Error sending email data to API:`, err);
                    failedItems.push(item);
                }
            }
    
            if (failedItems.length > 0) {
                try {
                    await set(keyString, failedItems);
                    // console.log(`Updated ${keyString} in IDB with failed items.`);
                } catch (err) {
                    console.error(`Error updating ${keyString} in IDB with failed items:`, err);
                }
            } else {
                try {
                    await del(keyString);
                    // console.log(`Deleted entry: ${keyString} from IDB after successful processing.`);
                } catch (err) {
                    console.error(`Error deleting ${keyString} from IDB:`, err);
                }
            }
        } catch (error) {
            console.error(`Error processing email data: ${error}`);
            return false; // Indicates failure
        }
    };
    
    



    //.............................................................................
    //.RRRRRRRRRR...EEEEEEEEEEE..SSSSSSS....SEEEEEEEEEE.ENNN...NNNN..NDDDDDDDD.....
    //.RRRRRRRRRRR..EEEEEEEEEEE.ESSSSSSSS...SEEEEEEEEEE.ENNNN..NNNN..NDDDDDDDDD....
    //.RRRRRRRRRRR..EEEEEEEEEEE.ESSSSSSSSS..SEEEEEEEEEE.ENNNN..NNNN..NDDDDDDDDDD...
    //.RRRR...RRRRR.EEEE.......EESSS..SSSS..SEEE........ENNNNN.NNNN..NDDD...DDDD...
    //.RRRR...RRRRR.EEEE.......EESSS........SEEE........ENNNNN.NNNN..NDDD....DDDD..
    //.RRRRRRRRRRR..EEEEEEEEEE..ESSSSSS.....SEEEEEEEEE..ENNNNNNNNNN..NDDD....DDDD..
    //.RRRRRRRRRRR..EEEEEEEEEE...SSSSSSSSS..SEEEEEEEEE..ENNNNNNNNNN..NDDD....DDDD..
    //.RRRRRRRR.....EEEEEEEEEE.....SSSSSSS..SEEEEEEEEE..ENNNNNNNNNN..NDDD....DDDD..
    //.RRRR.RRRR....EEEE..............SSSSS.SEEE........ENNNNNNNNNN..NDDD....DDDD..
    //.RRRR..RRRR...EEEE.......EESS....SSSS.SEEE........ENNN.NNNNNN..NDDD...DDDDD..
    //.RRRR..RRRRR..EEEEEEEEEEEEESSSSSSSSSS.SEEEEEEEEEE.ENNN..NNNNN..NDDDDDDDDDD...
    //.RRRR...RRRRR.EEEEEEEEEEE.ESSSSSSSSS..SEEEEEEEEEE.ENNN..NNNNN..NDDDDDDDDD....
    //.RRRR....RRRR.EEEEEEEEEEE..SSSSSSSS...SEEEEEEEEEE.ENNN...NNNN..NDDDDDDDD.....
    //.............................................................................

    
    // MANUAL

    const processFunctions = [
        { prefix: 'drillit-hole-locations', suffix: '-new', processFunction: processHoleLocationNew },
        { prefix: 'drillit-action', suffix: '-new', processFunction: processActionNew },
        { prefix: 'drillit-action', suffix: '-update', processFunction: processActionUpdate },
        { prefix: 'drillit-action', suffix: '-delete', processFunction: processActionDelete },
        { prefix: 'drillit-action_counts', suffix: '-new', processFunction: processActionCountsNew },
        { prefix: 'drillit-action_counts', suffix: '-update', processFunction: processActionCountsUpdate },
        { prefix: 'drillit-hole-locations/activate', suffix: '-update', processFunction: processActivateHole },
        { prefix: 'drillit-hole-locations/updateInformation', suffix: '-update', processFunction: processHoleLocationUpdate },
        { prefix: 'drillit-hole-locations/complete', suffix: '-update', processFunction: processCloseHole },
        { prefix: 'drillit-logs', suffix: '-new', processFunction: processLogsNew },
        { prefix: 'drillit-logs', suffix: '-update', processFunction: processLogsUpdate },
        { prefix: 'drillit-plant-check-cable', suffix: '-new', processFunction: processPlantCheckCableNew },
        { prefix: 'drillit-plant-check-cable', suffix: '-update', processFunction: processPlantCheckCableUpdate },
        { prefix: 'drillit-plant-check-rotary', suffix: '-new', processFunction: processPlantCheckRotaryNew },
        { prefix: 'drillit-plant-check-rotary', suffix: '-update', processFunction: processPlantCheckRotaryUpdate },
        { prefix: 'drillit-plant-check-window-sample', suffix: '-new', processFunction: processPlantCheckWindowSampleNew },
        { prefix: 'drillit-plant-check-window-sample', suffix: '-update', processFunction: processPlantCheckWindowSampleUpdate },
        { prefix: 'drillit-risk-assessment', suffix: '-new', processFunction: processRiskAssessmentNew },
        { prefix: 'drillit-contacts', suffix: '-new', processFunction: processContactsNew },
        { prefix: 'drillit-contacts', suffix: '-update', processFunction: processContactsUpdate },
        { prefix: 'drillit-contacts', suffix: '-delete', processFunction: processContactsDelete },
        { prefix: 'drillit-email', suffix: '-new', processFunction: processEmailNew },
    ];

    // ALL ITEMS

    const handleResend = async () => {
        setShowResendButton(false)
        const processOrder = [
            { prefix: 'drillit-hole-locations', suffix: '-new', processFunction: processHoleLocationNew },
            { prefix: 'drillit-action', suffix: '-new', processFunction: processActionNew },
            { prefix: 'drillit-action', suffix: '-update', processFunction: processActionUpdate },
            { prefix: 'drillit-action', suffix: '-delete', processFunction: processActionDelete },
            { prefix: 'drillit-action_counts', suffix: '-new', processFunction: processActionCountsNew },
            { prefix: 'drillit-action_counts', suffix: '-update', processFunction: processActionCountsUpdate },
            { prefix: 'drillit-hole-locations/activate', suffix: '-update', processFunction: processActivateHole },
            { prefix: 'drillit-hole-locations/updateInformation', suffix: '-update', processFunction: processHoleLocationUpdate },
            { prefix: 'drillit-hole-locations/complete', suffix: '-update', processFunction: processCloseHole },
            { prefix: 'drillit-logs', suffix: '-new', processFunction: processLogsNew },
            { prefix: 'drillit-logs', suffix: '-update', processFunction: processLogsUpdate },
            { prefix: 'drillit-plant-check-cable', suffix: '-new', processFunction: processPlantCheckCableNew },
            { prefix: 'drillit-plant-check-cable', suffix: '-update', processFunction: processPlantCheckCableUpdate },
            { prefix: 'drillit-plant-check-rotary', suffix: '-new', processFunction: processPlantCheckRotaryNew },
            { prefix: 'drillit-plant-check-rotary', suffix: '-update', processFunction: processPlantCheckRotaryUpdate },
            { prefix: 'drillit-plant-check-window-sample', suffix: '-new', processFunction: processPlantCheckWindowSampleNew },
            { prefix: 'drillit-plant-check-window-sample', suffix: '-update', processFunction: processPlantCheckWindowSampleUpdate },
            { prefix: 'drillit-risk-assessment', suffix: '-new', processFunction: processRiskAssessmentNew },
            { prefix: 'drillit-contacts', suffix: '-new', processFunction: processContactsNew },
            { prefix: 'drillit-contacts', suffix: '-update', processFunction: processContactsUpdate },
            { prefix: 'drillit-contacts', suffix: '-delete', processFunction: processContactsDelete },
            { prefix: 'drillit-email', suffix: '-new', processFunction: processEmailNew }
        ];
    
        let allProcessesSuccessful = true;
        let relevantEntries = []; // Declare relevantEntries within function scope
    
        try {
            for (const processItem of processOrder) {
                const { prefix, suffix, processFunction } = processItem;
    
                const itemsOfCategory = entriesList.filter(([key]) => {
                    if (typeof key === 'string') {
                        const isCorrectPrefixSuffix = key.startsWith(prefix) && key.endsWith(suffix);
                        if (prefix === 'drillit-action' && (suffix === '-new' || suffix === '-update')) {
                            return isCorrectPrefixSuffix && !key.startsWith('drillit-action_counts');
                        }
                        if (prefix === 'drillit-hole-locations' && (suffix === '-new' || suffix === '-update')) {
                            // return isCorrectPrefixSuffix && !key.startsWith('drillit-hole-locations/activate') && !key.startsWith('drillit-hole-locations/complete') && !key.startsWith('drillit-hole-locations/updateInformation');
                            return isCorrectPrefixSuffix && !key.startsWith('drillit-hole-locations/updateInformation');
                        } 
                        return isCorrectPrefixSuffix;
                    }
                    return false;
                });
    
                for (const [key, value] of itemsOfCategory) {
                    try {
                        const success = await processFunction(value, key as string);
                        if (!success) {
                            allProcessesSuccessful = false;
                        }
                    } catch (error) {
                        console.error(`Error processing ${prefix}${suffix}:`, error);
                        allProcessesSuccessful = false;
                    }
                }
            }
        } catch (error) {
            console.error('Unexpected error occurred:', error);
            allProcessesSuccessful = false;
        }
    
        // Re-fetch data from IndexedDB after a short delay to ensure state refresh
        await new Promise(resolve => setTimeout(resolve, 1000));
    
        try {
            const fetchedEntries = await entries();
            relevantEntries = fetchedEntries.filter(([key, value]: EntryType) => {
                const keyString = String(key);
                return keyString.endsWith('-new') || keyString.endsWith('-update') || keyString.endsWith('-delete');
            });
    
            setEntriesList(relevantEntries);
            setUpToDate(relevantEntries.length === 0);
        } catch (error) {
            console.error('Error fetching entries:', error);
        }
    
        // If no remaining entries, set upToDate state to true and trigger redirect
        if (allProcessesSuccessful && relevantEntries.length === 0) {
            setUpToDate(true);
            history.goBack(); // Or navigate to another page as needed
        } else {
            setShowResendButton(true)
        }
    };
    


    // console.log('Sync upToDate:', upToDate)
    







    const makeRequest = (endpoint: string, data: string, postOrPut: string) => {
        const token = localStorage.getItem('drillit-token')
        console.log(`Making ${postOrPut} request to ${endpoint} with data:`, data); // Log the request details

        return fetch(endpoint, {
            method: postOrPut,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: data
        })
            //return the status code here
            .then(response => response)
            .catch(err => err)
    }






    const makeRequestDelete = (endpoint: string, postOrPut: string) => {
        const token = localStorage.getItem('drillit-token')
        console.log(`Making ${postOrPut} request to ${endpoint} with data:`, data); // Log the request details

        return fetch(endpoint, {
            method: postOrPut,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        })
            //return the status code here
            .then(response => response)
            .catch(err => err)
    }

    const getIDB = (keyValue: string) => get(keyValue).then(data => data)








    const [progressHoleLocationNew, setProgressHoleLocationNew] = useState<number>(0);
    const [progressHoleLocationUpdate, setProgressHoleLocationUpdate] = useState<number>(0);
    const [progressHoleLocationDelete, setProgressHoleLocationDelete] = useState<number>(0);

    const [progressActionsNew, setProgressActionsNew] = useState<number>(0);
    const [progressActionsUpdate, setProgressActionsUpdate] = useState<number>(0);
    const [progressActionsDelete, setProgressActionsDelete] = useState<number>(0);

    const [progressActionCountsNew, setProgressActionCountsNew] = useState<number>(0);
    const [progressActionCountsUpdate, setProgressActionCountsUpdate] = useState<number>(0);
    const [progressActionCountsDelete, setProgressActionCountsDelete] = useState<number>(0);

    const [progressActivateHoleNew, setProgressActivateHoleNew] = useState<number>(0);
    const [progressActivateHoleUpdate, setProgressActivateHoleUpdate] = useState<number>(0);
    const [progressActivateHoleDelete, setProgressActivateHoleDelete] = useState<number>(0);

    const [progressHoleInformationNew, setProgressHoleInformationNew] = useState<number>(0);
    const [progressHoleInformationUpdate, setProgressHoleInformationUpdate] = useState<number>(0);
    const [progressHoleInformationDelete, setProgressHoleInformationDelete] = useState<number>(0);

    const [progressCloseHoleNew, setProgressCloseHoleNew] = useState<number>(0);
    const [progressCloseHoleUpdate, setProgressCloseHoleUpdate] = useState<number>(0);
    const [progressCloseHoleDelete, setProgressCloseHoleDelete] = useState<number>(0);

    const [progressContactsNew, setProgressContactsNew] = useState<number>(0);
    const [progressContactsUpdate, setProgressContactsUpdate] = useState<number>(0);
    const [progressContactsDelete, setProgressContactsDelete] = useState<number>(0);

    const [progressLogsNew, setProgressLogsNew] = useState<number>(0);
    const [progressLogsUpdate, setProgressLogsUpdate] = useState<number>(0);
    const [progressLogsDelete, setProgressLogsDelete] = useState<number>(0);

    const [progressPlantCheckCableNew, setProgressPlantCheckCableNew] = useState<number>(0);
    const [progressPlantCheckCableUpdate, setProgressPlantCheckCableUpdate] = useState<number>(0);
    const [progressPlantCheckCableDelete, setProgressPlantCheckCableDelete] = useState<number>(0);

    const [progressPlantCheckRotaryNew, setProgressPlantCheckRotaryNew] = useState<number>(0);
    const [progressPlantCheckRotaryUpdate, setProgressPlantCheckRotaryUpdate] = useState<number>(0);
    const [progressPlantCheckRotaryDelete, setProgressPlantCheckRotaryDelete] = useState<number>(0);

    const [progressPlantCheckWindowSampleNew, setProgressPlantCheckWindowSampleNew] = useState<number>(0);
    const [progressPlantCheckWindowSampleUpdate, setProgressPlantCheckWindowSampleUpdate] = useState<number>(0);
    const [progressPlantCheckWindowSampleDelete, setProgressPlantCheckWindowSampleDelete] = useState<number>(0);

    const [progressRiskAssessmentNew, setProgressRiskAssessmentNew] = useState<number>(0);
    const [progressRiskAssessmentUpdate, setProgressRiskAssessmentUpdate] = useState<number>(0);
    const [progressRiskAssessmentDelete, setProgressRiskAssessmentDelete] = useState<number>(0);

    const [progressEmailNew, setProgressEmailNew] = useState<number>(0);
    const [progressEmailUpdate, setProgressEmailUpdate] = useState<number>(0);
    const [progressEmailDelete, setProgressEmailDelete] = useState<number>(0);


    type EntryType = [IDBValidKey, any];

    const calculateActionCounts = (entries: EntryType[]) => {
        let newCount = 0;
        let updateCount = 0;
        let deleteCount = 0;

        entries.forEach(([key, value]: EntryType) => {
            const keyString = String(key);
            if (keyString.endsWith('-new')) {
                newCount++;
            } else if (keyString.endsWith('-update')) {
                updateCount++;
            } else if (keyString.endsWith('-delete')) {
                deleteCount++;
            }
        });

        return { newCount, updateCount, deleteCount };
    };





    // // Function to filter entries by category
    // const filterEntriesByCategory = (entries: EntryType[], category: string) => {
    //     return entries.filter(([key, _]) => String(key).startsWith(category));
    // };

    // Function to filter entries by category with specific rules
    const filterEntriesByCategory = (entries: [IDBValidKey, any][], category: string) => {
        return entries.filter(([key, _]) => {
            const keyString = String(key);

            // Apply filtering rules based on the category
            if (category === 'drillit-action') {
                // Exclude 'drillit-action_counts' entries
                return keyString.startsWith('drillit-action') && !keyString.startsWith('drillit-action_counts');
            }

            
            if (category === 'drillit-action_counts') {
                // Exclude 'drillit-action' entries
                return keyString.startsWith('drillit-action_counts');
            }

            // if (category === 'drillit-hole-locations') {
            //     // Include if keyString starts with 'drillit-hole-locations' but exclude specific entries
            //     return keyString.startsWith('drillit-hole-locations') &&
            //         !keyString.startsWith('drillit-hole-locations/updateInformation') &&
            //         !keyString.endsWith('-update');
            // }


            // if (category === 'drillit-hole-locations/updateInformation') {
            //     // Exclude 'drillit-hole-locations/activate' and 'drillit-hole-locations/complete' entries
            //     return keyString.startsWith('drillit-hole-locations/updateInformation') && !keyString.endsWith('-new')
            // }

            if (category === 'drillit-hole-locations') {
                // Include entries under 'drillit-hole-locations', but exclude specific subcategories
                return keyString.startsWith('drillit-hole-locations') &&
                    !keyString.startsWith('drillit-hole-locations/updateInformation') &&
                    !keyString.endsWith('-update');
            }
            
            if (category === 'drillit-hole-locations/updateInformation') {
                // Include all updateInformation entries except those ending with '-new'
                return keyString.startsWith('drillit-hole-locations/updateInformation') &&
                    !keyString.endsWith('-new');  // This will allow '-update' entries to pass
            }


            // Default case for other categories, include all entries that match the category
            return keyString.startsWith(category);
        });
    };


    // Define SyncItem component
    interface SyncItemProps {
        title: string;
        entries: [IDBValidKey, any][];
        progressNew: number;
        progressUpdate: number;
        progressDelete: number;
    }

    const SyncItem: React.FC<SyncItemProps> = ({ title, entries, progressNew, progressUpdate, progressDelete }) => {

        console.log('entries:', entries)

        const { newCount, updateCount, deleteCount } = calculateActionCounts(entries);

        console.log('newCount:', title, newCount);
        console.log('updateCount:', title, updateCount);
        // console.log('deleteCount:', title, deleteCount);
        // console.log('progressNew:', title, progressNew);
        // console.log('progressUpdate:', title, progressUpdate);
        // console.log('progressDelete:', title, progressDelete);

        // Function to calculate action counts
        function calculateActionCounts(entries: [IDBValidKey, any][]): { newCount: number; updateCount: number; deleteCount: number } {
            let newCount = 0;
            let updateCount = 0;
            let deleteCount = 0;
        
            entries.forEach(([key, value]) => {
                const keyString = String(key);
        
                console.log(`Processing key: "${keyString}", value:`, value);
        
                if (keyString.startsWith('drillit-action_counts')) {
                    // Handle 'drillit-action_counts' specifically
                    if (keyString.endsWith('-new')) {
                        // console.log("Match found for 'drillit-action_counts' with '-new'");
                        if (Array.isArray(value)) {
                            newCount += value.length;
                        } else {
                            newCount++;
                        }
                    } else if (keyString.endsWith('-update')) {
                        // console.log("Match found for 'drillit-action_counts' with '-update'");
                        updateCount++;
                    } else if (keyString.endsWith('-delete')) {
                        // console.log("Match found for 'drillit-action_counts' with '-delete'");
                        // deleteCount++;
                    }
                } else if (keyString.startsWith('drillit-action')) {
                    // Handle 'drillit-action' but exclude 'drillit-action_counts'
                    if (keyString.startsWith('drillit-action_counts')) {
                        // Skip 'drillit-action_counts' in 'drillit-action' branch
                        // console.log("Skipping 'drillit-action_counts' in 'drillit-action' branch");
                        return;
                    }
        
                    if (keyString.endsWith('-new')) {
                        // console.log("Match found for 'drillit-action' with '-new'");
                        if (Array.isArray(value)) {
                            newCount += value.length;
                        } else {
                            newCount++;
                        }
                    } else if (keyString.endsWith('-update')) {
                        // console.log("Match found for 'drillit-action' with '-update'");
                        updateCount++;
                    } else if (keyString.endsWith('-delete')) {
                        // console.log("Match found for 'drillit-action' with '-delete'");
                        deleteCount++;
                    }
                } else if (keyString.startsWith('drillit-hole-locations/updateInformation') && (keyString.endsWith('-update'))){
                    // Handle 'drillit-hole-locations/activate' specifically
                    console.log('keyString (updateInformation):', keyString)
                    // Handle 'drillit-hole-locations/activate' but exclude specific unwanted entries
                    if (keyString.endsWith('-new')) {
                        // console.log("Skipping 'drillit-hole-locations/activate' in 'drillit-action' branch");
                        return;
                    } else if (keyString.endsWith('-update')) {
                        // console.log("Match found for 'drillit-hole-locations/activate' with '-update'");
                        updateCount++;
                    } else if (keyString.endsWith('-delete')) {
                        // console.log("Match found for 'drillit-hole-locations/activate' with '-delete'");
                        // deleteCount++;
                        return;
                    }
                } else if (keyString.startsWith('drillit-hole-locations') && (keyString.endsWith('-new'))){
                    // Handle 'drillit-hole-locations' but exclude specific unwanted entries //
                    if (keyString.endsWith('-update')) {
                        // console.log("Skipping 'drillit-hole-locations/updateInformation' in 'drillit-action' branch");
                        return;
                    }
                    console.log('Hole Locations (new)')
                    console.log('keyString (hole-locations):', keyString)
                    if (keyString.endsWith('-new')) {
                        // console.log("Match found for 'drillit-hole-locations' with '-new'");
                        if (Array.isArray(value)) {
                            newCount += value.length;
                        } else {
                            newCount++;
                        }
                    } else if (keyString.endsWith('-update')) {
                        // console.log("Match found for 'drillit-hole-locations' with '-update'");
                        // updateCount++;
                    } else if (keyString.endsWith('-delete')) {
                        // console.log("Match found for 'drillit-hole-locations' with '-delete'");
                        // deleteCount++;
                    }
                } else {
                    // General handling for all other cases
                    // console.log("General case handling");
                    if (keyString.endsWith('-new')) {
                        // console.log("General '-new' found");
                        if (Array.isArray(value)) {
                            newCount += value.length;
                        } else {
                            newCount++;
                        }
                    } else if (keyString.endsWith('-update')) {
                        // console.log("General '-update' found");
                        updateCount++;
                    } else if (keyString.endsWith('-delete')) {
                        // console.log("General '-delete' found");
                        deleteCount++;
                    } else {
                        // console.log(`Key does not match any expected patterns: ${keyString}`);
                    }
                }
            });
        
            // console.log(`Final Counts - New: ${newCount}, Update: ${updateCount}, Delete: ${deleteCount}`);
            return { newCount, updateCount, deleteCount };
        }
        
    
        // Determine the width class based on count types
        let widthClass = 'w-full';
        if (newCount > 0 && updateCount === 0 && deleteCount === 0) {
            // Only new items
            widthClass = 'w-full';
        } else if (newCount === 0 && updateCount > 0 && deleteCount === 0) {
            // Only update items
            widthClass = 'w-full';
        } else if (newCount === 0 && updateCount === 0 && deleteCount > 0) {
            // Only delete items
            widthClass = 'w-full';
        } else if (newCount > 0 && updateCount > 0 && deleteCount === 0) {
            // Both new and update items, no delete
            widthClass = 'w-1/2';
        } else if (newCount > 0 && updateCount === 0 && deleteCount > 0) {
            // Both new and delete items, no update
            widthClass = 'w-1/2';
        } else if (newCount === 0 && updateCount > 0 && deleteCount > 0) {
            // Both update and delete items, no new
            widthClass = 'w-1/2';
        } else if (newCount > 0 && updateCount > 0 && deleteCount > 0) {
            // All three types: new, update, delete
            widthClass = 'w-1/3';
        }
    
        return (
            <>
                {newCount > 0 || updateCount > 0 || deleteCount > 0 ? (
                    <div className={`w-full md:w-48 bg-white border border-orange rounded py-2 md:pt-2 md:pb-2 text-center m-2`}>
                        <div className="font-bold">{title}</div>
                        <div className="flex justify-center flex-wrap text-xs">
                            {newCount > 0 && (
                                <div className={`my-1 ${widthClass}`}>
                                    new ({newCount})<br />
                                    {/* {progressNew !== null && <progress className="w-full mt-1 h-2" value={progressNew} max={100}></progress>} */}
                                    {progressNew !== null && (
                                        <div className="w-full mt-1 h-2 mx-auto progress-container">
                                            <div className="progress-bar" style={{ width: `${progressNew}%` }}></div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {updateCount > 0 && (
                                <div className={`my-1 ${widthClass}`}>
                                    update ({updateCount})<br />
                                    {/* {progressUpdate !== null && <progress className="w-full mt-1 h-2" value={progressUpdate} max={100}></progress>} */}
                                    {progressNew !== null && (
                                        <div className="w-full mt-1 h-2 mx-auto progress-container">
                                            <div className="progress-bar" style={{ width: `${progressUpdate}%` }}></div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {deleteCount > 0 && (
                                <div className={`my-1 ${widthClass}`}>
                                    del ({deleteCount})<br />
                                    {/* {progressDelete !== null && <progress className="w-full mt-1 h-2" value={progressDelete} max={100}></progress>} */}
                                    {progressNew !== null && (
                                        <div className="w-full mt-1 h-2 mx-auto progress-container">
                                            <div className="progress-bar" style={{ width: `${progressDelete}%` }}></div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                ) : null}
            </>
        );
    };




    // console.log('progressActionsNew****:', progressActionsNew)



    const [showEmail, setShowEmail] = useState<boolean>(false)
    // Define a state variable to hold the unescaped message
    const [unescapedMessage, setUnescapedMessage] = useState('');
    const [emailMessage, setEmailMessage] = useState(unescapedMessage)

	const loggedInUser = useGetLoggedInUser()
    const user = loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name)

    // Function to format and set the message when the modal opens
    const handleOpenEmail = () => {
        // Format the entriesList into a readable string
        const formattedEntries = entriesList.map(([key, value]) => {
            // Wrap the JSON value in <pre> tags to preserve formatting
            return `${key}:<br><pre>${JSON.stringify(value, null, 2)}</pre><br>`; // Pretty-print JSON with 2 spaces indentation
        }).join('<br>'); // Join all entries with <br> between each entry
    
        // Convert the formatted entries to a string without escaping newlines or quotes
        const unescapedMessage = formattedEntries.replace(/\\n/g, '\n').replace(/\\"/g, '"');

        // Set the unescaped message in the state
        setUnescapedMessage(unescapedMessage);

        setEmailMessage(unescapedMessage)

        console.log('unescapedMessage:', unescapedMessage)
        // Open email modal
        setShowEmail(true)
    };

    const sendEmail = () => {
        console.log('Sending Email');
    
        // Define the data to send in the request body
        const data = {
            log_paths: [],  // Assuming no log paths for this example
            emails: ['logs@drillitlogs.com'], 
            subject: `${user} - IDB Sync Issues`, 
            message: emailMessage // Use the unescaped string for the message
        };

        const endpoint = `${process.env.REACT_APP_API_ADDR}email`;
        const method = 'POST';
    
        try {
            const response = makeRequest(endpoint, JSON.stringify(data), method);
        } catch (err) {
            console.error(`Error sending email data to API:`, err);
        }
    };
    
    


    return (
        <>

            <div className="fixed inset-0 px-1 sm:px-4 md:px-8 py-2 sm:py-4 z-40 overflow-y-auto" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                <div className={`flex flex-col rounded-lg border border-orange  px-2 sm:px-2 md:px-2 pb-2 min-h-full modalshadow`} style={{ background: '#FBFCFC' }}>
                    <div className="flex items-center border-b border-grey-mid py-1">

                        <div className="flex-none flex items-left w-8">
                            <div className="flex-none">

                                {/* <span className="mr-auto">{subtitle}</span> */}
                                <div className="flex-none text-base font-bold text-center">
                                    {/* {subtitle} */}
                                </div>

                                <div className="flex-none">

                                </div>

                            </div>
                        </div>

                        <div className="flex-1 flex justify-center text-lg md:text-xl font-bold text-text leading-6 text-center mx-auto">
                            Sync
                        </div>

                        <div className="flex-none flex justify-center">
                            <span className="">
                                <button className="flex items-center justify-center min-w-9 h-8 text-4xl bold text-soil-grey bg-white border border-orange rounded"
                                    onClick={() => window.history.back()}
                                >
                                    <img className="w-8 h-8" src={closeIcon} alt="" />
                                </button>
                            </span>
                        </div>
                    </div>

                    {/* <div className="text-xs font-bold text-center mx-auto mt-4">
                        21/07/2024 @ 12:15
                    </div> */}

                    {upToDate && (
                        <div className="w-48 mx-auto text-center mt-4">
                            <div className="text-center w-20 h-20 mx-auto">
                                <div className="text-center w-20 h-20 absolute mt-0.5 items-left mx-auto">
                                    {/* <img src={logoCenter} className="h-16 w-16 absolute mt-2 ml-2" alt="logo inner" />                */}
                                    <img src={logoOuter} className="logo-spin-sync absolute h-20" alt="logo gear" />
                                    <img src={logoOuter} className="logo-spin-sync-left absolute h-12 mt-4 ml-4" alt="logo gear" />
                                </div>
                            </div>

                            <div className="mt-2">
                                <div className="text-lg font-bold">Your data is up to date!</div>
                            </div>

                        </div>
                        
                    )}

                    {!upToDate && (
                        <>
                            <div className="h-24">
                                <div className="text-center text-base mt-4">These items are waiting to send to the database:</div>
                                
                                {showResendButton && !IDBdetails && 
                                    <Button onClick={handleResend} className="mx-auto w-30 mt-4 bg-green text-white">
                                        Re-send All
                                    </Button>
                                }
                                {!showResendButton &&
                                <>
                                    <div className="text-center w-20 h-20 mx-auto">
                                        <div className="text-center w-20 h-20 absolute items-left mx-auto">
                                            <img src={logoOuter} className="logo-spin-sync-left absolute h-12 mt-2 ml-4" alt="logo gear" />
                                        </div>
                                    </div>
                                </>
                                }
                                <div className="text-right text-sm -mt-4" onClick={toggleIDBdetails}>details</div>
                            </div>
                        </>
                    )}

                    {/* <div>***********************************************************************************************************</div> */}

                    {/* <div className="flex flex-col mt-2 flex-grow p-4">
                        <div id="data-container" className="text-sm"></div>
                    </div> */}

                    {!upToDate && (
                        <>
                        {!IDBdetails && (
                            <>
                                <div className="rounded bg-sptSB m-1 p-2 border border-grey-mid mt-4 flex flex-wrap justify-center">

                                    <SyncItem title="Hole Locations" entries={filterEntriesByCategory(entriesList, 'drillit-hole-locations')}
                                        progressNew={progressHoleLocationNew}
                                        progressUpdate={progressHoleLocationUpdate}
                                        progressDelete={progressHoleLocationDelete}
                                    />

                                    <SyncItem title="Actions" entries={filterEntriesByCategory(entriesList, 'drillit-action')}
                                        progressNew={progressActionsNew}
                                        progressUpdate={progressActionsUpdate}
                                        progressDelete={progressActionsDelete}
                                    />
                                    
                                    <SyncItem title="Action Counts" entries={filterEntriesByCategory(entriesList, 'drillit-action_counts')}
                                        progressNew={progressActionCountsNew}
                                        progressUpdate={progressActionCountsUpdate}
                                        progressDelete={progressActionCountsDelete}
                                    />

                                    <SyncItem title="Activate Hole" entries={filterEntriesByCategory(entriesList, 'drillit-hole-locations/activate')}
                                        progressNew={progressActivateHoleNew}
                                        progressUpdate={progressActivateHoleUpdate}
                                        progressDelete={progressActivateHoleDelete}
                                    />

                                    <SyncItem title="Hole Information" entries={filterEntriesByCategory(entriesList, 'drillit-hole-locations/updateInformation')}  // drillit-hole-locations/updateInformation
                                        progressNew={progressHoleInformationNew}
                                        progressUpdate={progressHoleInformationUpdate}
                                        progressDelete={progressHoleInformationDelete}
                                    />

                                    <SyncItem title="Close Hole" entries={filterEntriesByCategory(entriesList, 'drillit-hole-locations/complete')}
                                        progressNew={progressCloseHoleNew}
                                        progressUpdate={progressCloseHoleUpdate}
                                        progressDelete={progressCloseHoleDelete}
                                    />

                                    <SyncItem title="Contacts" entries={filterEntriesByCategory(entriesList, 'drillit-contacts')}
                                        progressNew={progressContactsNew}
                                        progressUpdate={progressContactsUpdate}
                                        progressDelete={progressContactsDelete}
                                    />

                                    <SyncItem title="Logs" entries={filterEntriesByCategory(entriesList, 'drillit-logs')}
                                        progressNew={progressLogsNew}
                                        progressUpdate={progressLogsUpdate}
                                        progressDelete={progressLogsDelete}
                                    />

                                    <SyncItem title="Plant Check Cable" entries={filterEntriesByCategory(entriesList, 'drillit-plant-check-cable-percussive')}
                                        progressNew={progressPlantCheckCableNew}
                                        progressUpdate={progressPlantCheckCableUpdate}
                                        progressDelete={progressPlantCheckCableDelete}
                                    />

                                    <SyncItem title="Plant Check Rotary" entries={filterEntriesByCategory(entriesList, 'drillit-plant-check-rotary')}
                                        progressNew={progressPlantCheckRotaryNew}
                                        progressUpdate={progressPlantCheckRotaryUpdate}
                                        progressDelete={progressPlantCheckRotaryDelete}
                                    />

                                    <SyncItem title="Plant Check WS" entries={filterEntriesByCategory(entriesList, 'drillit-plant-check-window-sample')}
                                        progressNew={progressPlantCheckWindowSampleNew}
                                        progressUpdate={progressPlantCheckWindowSampleUpdate}
                                        progressDelete={progressPlantCheckWindowSampleDelete}
                                    />

                                    <SyncItem title="Risk Assessments" entries={filterEntriesByCategory(entriesList, 'drillit-risk-assessment')}
                                        progressNew={progressRiskAssessmentNew}
                                        progressUpdate={progressRiskAssessmentUpdate}
                                        progressDelete={progressRiskAssessmentDelete}
                                    />

                                    <SyncItem title="Email" entries={filterEntriesByCategory(entriesList, 'drillit-email')}
                                        progressNew={progressEmailNew}
                                        progressUpdate={progressEmailUpdate}
                                        progressDelete={progressEmailDelete}
                                    />

                                </div>
                            </>
                        )}

                        {IDBdetails && (
                            <>
                                {/* <div className="w-full text-center">List</div>
                                <div className="flex flex-col mt-2 flex-grow p-4">
                                    <div id="data-container" className="text-sm">
                                        {renderEntries(entriesList)} 
                                    </div>
                                </div> */}
                            </>
                        )}
                    </>
                    )}

                    




			{showEmail && (
				<>
					<ModalSmall title="Email Support" toggleModal={() => { setShowEmail(false) }} confirm={false}>

						<div className="my-2 text-base text-center">
							Email Support
						</div>

						<div className="w-full rounded bg-sptTB py-2 mt-2">

                            
                        <div className="">
                                <TextArea 
                                label=" Message" 
                                value={unescapedMessage} 
                                onChange={(value: any) => {
                                    setEmailMessage(value)
                                }} 
                                className="w-full md:w-2/3 lg:w-full mx-auto mb-2 align-text-top text-base font-bold"
                                inputCSS="h-48 overflow-x-auto text-xs md:text-sm"
                                numberOfRows={20}
                                />
                            </div>

							

						</div>

						<div className="w-30 mt-4 mb-8">
							<Button type="submit" theme="primary" size="lg" className="btn-lg m-auto text-lg font-bold" 
							onClick={() => {{
                                    setShowEmail(false)
                                    sendEmail()
                                    }}}>
								Send Email
							</Button>
						</div>

					</ModalSmall>
				</>
			)}

                    
                    {/* <div className="flex flex-col mt-2 flex-grow p-4"> */}
                    <div className={`flex flex-col -mt-4 flex-grow p-4 ${IDBdetails ? '' : 'hidden'}`}>
                        <div id="data-container" className="text-sm"></div>
                    </div>

                    <div className={`text-right text-orange text-xs ${IDBdetails ? '' : 'hidden'}`} 
                        onClick={handleOpenEmail}>
                        email support
                    </div>






                    {deleteConfirmOpen &&
                        <div className="fixed inset-0 z-50">
                            <div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">


                                <div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
                                    Are you sure you want to delete this record?

                                    <div className="flex mt-4">
                                        <Button theme="secondary"
                                            className="mr-5 text-sm sm:text-base px-3.5 md:px-7"
                                            onClick={() => setDeleteConfirmOpen(false)}>
                                            Cancel
                                        </Button>

                                        <Button id="confirm-delete-button" className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">
                                            Delete
                                        </Button>
                                    </div>
                                </div>


                            </div>
                        </div>
                    }

                    {isIdbModalOpen &&
                        <div className="fixed inset-0 z-50 w-full">
                            <div className="flex items-center justify-center min-h-full w-11/12 sm:w-10/12 md:w-8/12 lg:w-8/12 mx-auto">


                                <div className="bg-white rounded-lg border-2 border-charcoal px-4 p-2 text-center modalshadow w-full">

                                    <div className="flex mb-1 w-full justify-end">
                                        <Button theme="secondary"
                                            className="mr-0 text-sm sm:text-sm px-3.5 md:px-7 rounded"
                                            onClick={() => setIsIdbModalOpen(false)}>
                                            Close
                                        </Button>
                                    </div>

                                    <div className="text-left text-sm w-full max-h-[200px] overflow-auto">
                                        {idbModalContent}
                                    </div>

                                </div>


                            </div>
                        </div>
                    }

                </div>
            </div>
        </>
    );
}

export default Sync;