import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { keys } from "idb-keyval";
import sortNewAndUpdates from "./sortNewAndUpdates";

import { useHistory } from "react-router-dom";

type Props = {
    synced: boolean,
}

export const SyncStatus: FunctionComponent<Props> = ({ synced }): ReactElement => {

    let internetConnection = false;

    function useNetwork() {
        const [isOnline, setNetwork] = useState(window.navigator.onLine);

        const updateNetwork = () => {
            setNetwork(window.navigator.onLine);
        };

        useEffect(() => {
            window.addEventListener("offline", updateNetwork);
            window.addEventListener("online", updateNetwork);

            return () => {
                window.removeEventListener("offline", updateNetwork);
                window.removeEventListener("online", updateNetwork);
            };
        });

        internetConnection = isOnline;
        return isOnline;
    };

    useNetwork();

    const [amSynced, setAmSynced] = useState(synced);

    const newItems = keys().then((keys) => keys);

    let updateOrNew: IDBValidKey[];

    const allKeys = async () => {
        const a = await newItems;
        const newStr = '-new';
        const updateStr = '-update';
        const deleteStr = '-delete';

        const newKeys = a.filter(value => String(value).includes(newStr));
        const updateKeys = a.filter(value => String(value).includes(updateStr));
        const deleteKeys = a.filter(value => String(value).includes(deleteStr));

        updateOrNew = [...newKeys, ...updateKeys, ...deleteKeys];

        if (updateOrNew.length > 0) {
            setAmSynced(false);
        }
    };

    allKeys();

    const history = useHistory();

    const sortUpdatesNew = async () => {
        sortNewAndUpdates(updateOrNew);

        if (internetConnection === true) {
            history.push("/sync");
        }
    };

    return (
        <>
            {amSynced ? (
                <div className={`dataSyncFooter bg-blue z-30`} onClick={sortUpdatesNew}>
                    Your data is up to date
                </div>
            ) : (
                <div className={`dataSyncFooterOrange bg-orange z-30`} onClick={sortUpdatesNew}>
                    You have data to sync when you are online
                </div>
            )}
        </>
    );
};

export default SyncStatus;
