import React from 'react'
import { Link } from 'react-router-dom'

import Header from '../layout/Header'

import Modal from '../components/Modal'
import Heading from '../components/Heading'
import NumberInput from '../components/NumberInput'
import Button from '../components/form/Button'
import InputGroup from '../components/form/InputGroup'
import { useGetIDBValues } from "../helpers/getIDBKey";
import { get, set } from "idb-keyval";
import { setIDB } from "../helpers/setIDBKey";
import { Redirect } from "react-router-dom";
import warningIcon from '../assets/icons/warning.png'
import ProjectDate from '../components/project/ProjectDate'
import RigList from './RigList'
import DailyPlantCheckWindowSample from './DailyPlantCheckWindowSample'

interface Props {
	toggleSidebar: () => void,
	router: any,
	selectedDate: Date,
}

interface State {
	company_guid: string,
	company_id: number,
	project_guid: string,
	id: string,
	rigs: any[],
	currentRigValue: string,
	currentRigLabel: string,
	currentRigID: string,
	currentRigGUID: string,
	currentRigTestExpiryDate: string,
	currentRigType: string,
	currentRigSPTRodType: string,
	currentRigSPTIDRef: string,
	currentRigSPTEnergyRatio: string,
	currentRigSPTCalibrationDate: string,

	todaysDate: string,
	redirect: boolean,
	plantChecks: any[],
	showRigList: boolean,
	selectedDate: string,
	showPlantCheck: boolean,
	defaultValues: any[],
	rigInformation: any[],
	update: boolean,
	showPlantCheckButton: boolean,
	showPlantCheckComplete: boolean,
}

class DailyPlantCheckList extends React.Component<Props, State> {
	constructor(props: any) {
		super(props)

		this.state = {
			company_guid: this.props.router.match.params?.company, // this.props.router.match.params?.company
			company_id: 0,
			project_guid: this.props.router.match.params?.project,
			id: this.props.router.match.params?.id,
			rigs: [],
			currentRigLabel: 'RIG NOT SELECTED!',
			currentRigValue: '',
			currentRigID: '',
			currentRigGUID: '',
			currentRigType: '',
			currentRigSPTRodType: '',
			currentRigSPTIDRef: '',
			currentRigSPTEnergyRatio: '',
			currentRigSPTCalibrationDate: '',

			todaysDate: '',
			redirect: false,
			plantChecks: [],
			showRigList: false,
			selectedDate: '',
			showPlantCheck: false,
			currentRigTestExpiryDate: '',
			defaultValues: [],
			rigInformation: [],
			update: false,
			showPlantCheckButton: false,
			showPlantCheckComplete: false,
		}
	}

	date = new Date()

	save = (event: any) => {
		event.preventDefault()
		get(`drillit-dailyPlantCheck-${this.props.router.match.params?.project}`)
			.then(data => {
				setIDB(this.state, `drillit-dailyPlantCheck-${this.props.router.match.params?.project}`, data)
				console.log(data)
			})
			.then(() => this.setState({ redirect: true }))

	}


	// componentDidMount() {
	// 	this.getCacheValues()
	// 	// this.setDateString()
	// 	// this.getSelectedDate()
	// 	// this.getCurrentRigPlantChecks()
	// }

	async componentDidMount() {
		try {
			await this.getCompanyRigs();
			this.setCurrentRigData();
			await this.setPlantChecksState();
		} catch (error) {
			console.error('Error in componentDidMount:', error);
		}
	}

	// getCacheValues = () => {
	// 	let cachedValues = useGetIDBValues
	// 	cachedValues('rigs/company/' + this.props.router.match.params?.company, (data: any) => {
	// 		console.log('Company Rigs Data:', data)
	// 		// cachedValues('rigs', (data:any) => {
	// 		this.setState({
	// 			rigs: data
	// 		})
	// 		let selectValues: any = []
	// 		if (data) {

	// 			for (const rig of data) {
	// 				let { guid, name } = rig
	// 				const newValue = {
	// 					value: guid,
	// 					label: name
	// 				}
	// 				selectValues.push(newValue)
	// 			}

	// 		}

	// 	}).then()
	// 	// get(`drillit-currentRig-${this.props.router.match.params?.project}`)
	// 	get(`drillit-currentRig`)
	// 		.then(data => {

	// 			console.log('current rig data:', data)

	// 			if (data) {
	// 				this.setState({
	// 					currentRigValue: data?.guid,
	// 					currentRigLabel: data?.name,
	// 				});
	// 			}
	// 		})
	// 		.catch(error => {
	// 			console.error("Error fetching data:", error);
	// 		})
	// 		.then(() => {
	// 			if (this.state.currentRigValue !== '') {
	// 				// Now that getCacheValues is completed, call getCurrentRigPlantChecks
	// 				this.getCurrentRigPlantChecks();
	// 			};
	// 		})
	// 		.then(() => {
	// 			if (this.state.currentRigValue !== '') {
	// 				// Now that getCacheValues is completed, call setCurrentRigData
	// 				this.setCurrentRigData();
	// 			};
	// 		});
	// }

	// setCurrentRigData = () => {
	// 	// Find the rig data with the specified GUID
	// 	const currentRigGUID = this.state.currentRigValue;
	// 	const rigData = this.state.rigs.find(rig => rig.guid === currentRigGUID);
	// 	if (rigData) {
	// 		this.setState({
	// 			currentRigTestExpiryDate: rigData.test_date_expiry,
	// 			currentRigID: rigData.plant_id,
	// 			currentRigGUID: rigData.guid,
	// 			company_id: rigData.company_id,
	// 			currentRigSPTRodType: rigData.spt ? rigData.spt.rod_type : null,
	// 			currentRigSPTIDRef: rigData.spt ? rigData.spt.id_ref : null,
	// 			currentRigSPTEnergyRatio: rigData.spt ? rigData.spt.energy_ratio : null,
	// 			currentRigSPTCalibrationDate: rigData.spt ? rigData.spt.calibration_date : null,
	// 			rigInformation: rigData
	// 		});
	// 	} else {
	// 		// Handle case when rig with specified GUID is not found
	// 		console.log("Rig with GUID", currentRigGUID, "not found.");
	// 	}
	// }

	getCompanyRigs = async () => {
		try {
			// Fetch the rigs data for the company
			const company = this.state?.company_guid;
			const companyRigsData = await get(`drillit-rigs/company/${company}`);
			console.log('Company Rigs Data:', companyRigsData);

			// Update the state with the retrieved rigs data
			this.setState({ rigs: companyRigsData });

			let selectValues = [];
			if (companyRigsData) {
				for (const rig of companyRigsData) {
					const { guid, name } = rig;
					selectValues.push({ value: guid, label: name });
				}
			}

			// Now fetch the current rig data
			const currentRigData = await get(`drillit-currentRig`);
			console.log('Current Rig Data:', currentRigData);

			if (currentRigData) {
				this.setState({
					currentRigValue: currentRigData?.guid,
					currentRigLabel: currentRigData?.name,
				});
			}

		} catch (error) {
			console.error("Error fetching data:", error);
		}
	}

	setCurrentRigData = () => {
		// Find the rig data with the specified GUID
		const currentRigGUID = this.state.currentRigValue;
		const rigData = this.state.rigs.find(rig => rig.guid === currentRigGUID);
		if (rigData) {
			this.setState({
				currentRigTestExpiryDate: rigData.test_date_expiry,
				currentRigID: rigData.plant_id,
				currentRigGUID: rigData.guid,
				company_id: rigData.company_id,
				currentRigSPTRodType: rigData.spt ? rigData.spt.rod_type : null,
				currentRigSPTIDRef: rigData.spt ? rigData.spt.id_ref : null,
				currentRigSPTEnergyRatio: rigData.spt ? rigData.spt.energy_ratio : null,
				currentRigSPTCalibrationDate: rigData.spt ? rigData.spt.calibration_date : null,
				rigInformation: rigData
			});
			console.log('setCurrentRigData - rigData:', rigData)
		} else {
			// Handle case when rig with specified GUID is not found
			console.log("Rig with GUID", currentRigGUID, "not found.");
		}
	}

	// Placeholder method for retrieving data from IndexedDB
	async getPlantChecksFromIDB() {
		const projectGuid = this.state?.project_guid;
		const idbKey = `drillit-plant-check-window-sample/project/${projectGuid}`;

		// Replace this with your actual IndexedDB retrieval logic
		const data = await get(idbKey);  // Assuming idb.get() is your method to retrieve from IndexedDB

		return data ? data.plantCheck : [];
	}

	// Method to filter and set the state
	async setPlantChecksState() {
		try {
			const plantChecks = await this.getPlantChecksFromIDB();
			const { currentRigValue } = this.state;

			// Filter the plant checks matching the current rig's plant_guid
			const matchingPlantChecks = plantChecks.filter((check: { plant_guid: string }) => check.plant_guid === currentRigValue);

			// Update the state with the filtered plant checks
			this.setState({
				plantChecks: matchingPlantChecks
			});
			console.log('setPlantChecksState - plantChecks:', plantChecks)
		} catch (error) {
			console.error('Error setting plant checks state:', error);
		}
	}


	// getCurrentRigPlantChecks = () => {
	// 	let cachedValues = useGetIDBValues;
	// 	const rigPlantChecks = 'plant-check-window-sample/' + this.state.currentRigValue;
	// 	const rigPlantChecksIDB = 'drillit-plant-check-window-sample/' + this.state.currentRigValue;
	// 	console.log('Getting plantchecks....')
	// 	cachedValues(rigPlantChecks, (data: any) => {
	// 		console.log('plant check data:', data)
	// 		if (data) {
	// 			const plantChecks = data.plantCheck;

	// 			// Set the state with the plant checks
	// 			this.setState({
	// 				plantChecks: plantChecks
	// 			})

	// 		} else {
	// 			this.fetchPlantChecksFromIDB(rigPlantChecksIDB);
	// 			// Handle error case if needed
	// 			console.error('Error fetching plant checks');
	// 		}
	// 		console.log('this.state.currentRigLabel', this.state.currentRigLabel)

	// 	});
	// };

	getCurrentRigPlantChecks = async () => {
		try {
			const plantChecks = await this.getPlantChecksFromIDB(); // Await the promise to get the data
			const { currentRigValue } = this.state;
	
			// Filter the plant checks matching the current rig's plant_guid
			const matchingPlantChecks = plantChecks.filter((check: { plant_guid: string }) => check.plant_guid === currentRigValue);
	
			// Update the state with the filtered plant checks
			this.setState({
				plantChecks: matchingPlantChecks
			});
	
			console.log('getCurrentRigPlantChecks - matchingPlantChecks:', matchingPlantChecks);
		} catch (error) {
			console.error('Error setting plant checks state:', error);
		}
	};

	// Function to fetch plant checks from IndexedDB
	fetchPlantChecksFromIDB = async (rigPlantChecks:any) => {
		try {
		  const data = await get(rigPlantChecks);
		  if (data) {

			// Set the state with the plant checks
			this.setState({
				plantChecks: data.plantCheck
			})

		  } else {

			// Set the state with the plant checks
			this.setState({
				plantChecks: [] // Set an empty array if no data is found
			})
			
		  }
		} catch (error) {

			// Set the state with the plant checks
			this.setState({
				plantChecks: [] // Set an empty array if no data is found
			}) 
		  console.error('Error fetching data from IndexedDB:', error);
		}
	  };

	setDateString = () => {
		const date = new Date()
		this.setState({
			todaysDate: date.getTime().toString()
			// todaysDate: date.toISOString()
			// selectedDate: this.props.selectedDate
		})
	}


	handleRigListClose = () => {
		this.setState({ showRigList: false });
	};

	handleRigSelect = (selectedRig: any) => {
		console.log('selectedRig************************************:', selectedRig)

		const rigGuid = selectedRig?.guid;
		const rigName = selectedRig?.name;
		const rigID = selectedRig?.plant_id;
		const rigGUID = selectedRig?.guid;
		const rigTestExpiry = selectedRig?.test_date_expiry;
		const currentRigSPTRodType = selectedRig?.spt.rod_type;
		const currentRigSPTIDRef = selectedRig?.spt.id_ref;
		const currentRigSPTEnergyRatio = selectedRig?.spt.energy_ratio;
		const currentRigSPTCalibrationDate = selectedRig?.spt.calibration_date;
		const companyID = selectedRig?.company_id;

		this.setState(
			{
				currentRigValue: rigGuid,
				currentRigLabel: rigName,
				currentRigTestExpiryDate: rigTestExpiry,
				currentRigID: rigID,
				currentRigGUID: rigGUID,
				company_id: companyID,
				currentRigSPTRodType: currentRigSPTRodType,
				currentRigSPTIDRef: currentRigSPTIDRef,
				currentRigSPTEnergyRatio: currentRigSPTEnergyRatio,
				currentRigSPTCalibrationDate: currentRigSPTCalibrationDate,
				rigInformation: selectedRig
			})
		this.getCurrentRigPlantChecks()
	}

	// Function to format the date to 'yyyy-mm-dd'
	formatDate = (date: any) => {
		const year = date.getFullYear();
		const month = (date.getMonth() + 1).toString().padStart(2, '0'); // January is 0
		const day = date.getDate().toString().padStart(2, '0');

		return `${year}-${month}-${day}`;
	}

	handleDateChange = (date: any) => {
		// Update dateValue state with the new date value
		this.setState({ selectedDate: date });
	};


	handlePlantCheckClose = () => {
		this.setState({ showPlantCheck: false });
		this.getCurrentRigPlantChecks();
		// window.location.reload();
	};


	handlePlantCheckRefresh = () => {
		this.getCurrentRigPlantChecks();
	};


	handleNewPlantCheck = () => {
		this.setState({ showPlantCheck: true });
	};

	handlePlantCheckClick = (plantCheck: any) => {
		// Set the selected plant check data as defaultValues
		this.setState({ defaultValues: plantCheck });
		this.setState({ update: true });
		console.log('plantCheck', plantCheck)
		console.log('defaultValues', this.state.defaultValues)
		this.setState({ showPlantCheck: true });
		// this.setState({ update: false });
		// Optionally, close the modal or perform any other actions
	};

	handlePlantCheckButton = () => {
	}


	render() {
		const { redirect } = this.state

		console.log('plantChecks', this.state.plantChecks)

		// const plantCheckData = this.state.plantChecks.length > 0 
		// ? this.state.plantChecks.map(plantCheck => ({
		// 	// plantCheckDate: plantCheck.plant_check_date,
		// 	// issuesMaintenance: plantCheck.issuesMaintenance,
		// 	...plantCheck, // Spread the properties of plantCheck
		// }))
		// : [];

		const plantCheckData = this.state?.plantChecks && this.state.plantChecks?.length > 0 
		? this.state.plantChecks.map(plantCheck => ({
			// plantCheckDate: plantCheck.plant_check_date,
			// issuesMaintenance: plantCheck.issuesMaintenance,
			...plantCheck, // Spread the properties of plantCheck
		}))
		: [];

		const hasPlantCheckForSelectedDate = this.state.plantChecks?.some(
			check => check.plant_check_date === this.state.selectedDate
		);


		// if (this.state.currentRigLabel !== 'RIG NOT SELECTED!' && !hasPlantCheckForSelectedDate) {
		// 	this.setState({ 
		// 		showPlantCheckButton: true,
		// 		showPlantCheckComplete: false,
		// 	 });
		// }

		// if (this.state.currentRigLabel !== 'RIG NOT SELECTED!' && hasPlantCheckForSelectedDate) {
		// 	this.setState({ 
		// 		showPlantCheckButton: false,
		// 		showPlantCheckComplete: true,
		// 	 });
		// }

		// console.log('hasPlantCheckForSelectedDate', hasPlantCheckForSelectedDate)
		// console.log('currentRigValue', this.state.currentRigValue)
		// console.log('this.state.currentRigLabel', this.state.currentRigLabel)

		// console.log('id', this.state.id)
		// console.log('company_id', this.state.company_id)

		// console.log('this.props.selectedDate', this.props.selectedDate)
		// console.log('selectedDate', this.state.selectedDate)
		// console.log('todaysDate', this.state.todaysDate)

		// console.log('currentRigTestExpiryDate', this.state.currentRigTestExpiryDate)
		// console.log('currentRigID', this.state.currentRigID)

		// console.log('currentRigSPTRodType', this.state.currentRigSPTRodType)
		// console.log('currentRigSPTIDRef', this.state.currentRigSPTIDRef)
		// console.log('currentRigSPTEnergyRatio', this.state.currentRigSPTEnergyRatio)
		// console.log('currentRigSPTCalibrationDate', this.state.currentRigSPTCalibrationDate)

		// console.log('this.state.showPlantCheck', this.state.showPlantCheck)
		// console.log('this.state.rigInformation', this.state.rigInformation)


		if (redirect) {
			return <Redirect to={`/rigs/${this.props.router.match.params.company}/${this.props.router.match.params.project}`} />
		}

		let rigSelectedText = ''
		let showWarningIcon = ''
		let warningIconCSS = ''
		if (this.state.currentRigLabel === '') {
			showWarningIcon = warningIcon
			warningIconCSS = 'w-5 h-6 mr-1'
			rigSelectedText = "RIG NOT SELECTED!"
		} else {
			showWarningIcon = ''
			rigSelectedText = "Rig: " + this.state.currentRigLabel
		}

		// console.log('Rig Type: ', this.state.currentRigType)


		return (
			<>
				<Header toggleSidebar={this.props.toggleSidebar} />

				<Modal title={"Daily Plant Checks"} toggleModal={() => this.props.router.history.goBack()} confirm={false}>

					{this.state.showPlantCheck &&

						<DailyPlantCheckWindowSample
							onClose={this.handlePlantCheckClose}
							testDate={this.state.selectedDate}
							currentRigTestExpiryDate={this.state.currentRigTestExpiryDate}
							plantType='Window Sample'
							plantID={this.state.currentRigID}
							plantName={this.state.currentRigLabel}
							plantGUID={this.state.currentRigGUID}
							companyID={this.state.company_id}
							currentRigSPTRodType={this.state.currentRigSPTRodType}
							currentRigSPTIDRef={this.state.currentRigSPTIDRef}
							currentRigSPTEnergyRatio={this.state.currentRigSPTEnergyRatio}
							currentRigSPTCalibrationDate={this.state.currentRigSPTCalibrationDate}
							defaultValues={this.state.defaultValues} // load in the defaultValues
							rigInformation={this.state.rigInformation} // load in the rigInformation
							project_guid={this.state.project_guid}
							update={this.state.update}
							plantChecks={this.state?.plantChecks}
						/>
					}

					{this.state.showRigList &&

						<RigList
							onClose={this.handleRigListClose}
							onSelectItem={this.handleRigSelect}
							companyGuid={this.state.company_guid}
						/>
					}




					<div onClick={() => {
						this.setState({
							showRigList: true,
						})
					}}
						className="text-center text-lg md:text-2xl font-bold mt-2 mb-2">
						{/* <Link to={`/rigs/${this.state.company_guid}/${this.state.project_guid}`}> */}
						<img src={showWarningIcon} className={`inline-block pb-1 ${warningIconCSS}`} alt="" />
						{this.state.currentRigLabel}
						{/* </Link> */}
					</div>

					<div onClick={this.handlePlantCheckRefresh}
						className="text-center text-sm md:text-base font-bold mb-2">
						Rig Type: Window Sample
					</div>

					<div className="mb-2">
						<ProjectDate onDateChange={this.handleDateChange} />
					</div>

					<div className="h-16">
						{this.state.currentRigLabel !== 'RIG NOT SELECTED!' && this.state.currentRigLabel !== '' && !hasPlantCheckForSelectedDate && (
							<div onClick={() => {
								this.setState({
									update: false,
									showPlantCheck: true,
								})
							}}
								className="bg-orange text-lg md:text-2xl text-white rounded w-full md:w-2/3 mx-auto text-center py-3 mb-2">

								Click here to create new plant check

							</div>
						)}
						{this.state.currentRigLabel !== 'RIG NOT SELECTED!' && this.state.currentRigLabel !== '' &&  hasPlantCheckForSelectedDate && (
							<div className="bg-blue text-lg md:text-2xl text-white rounded w-full md:w-2/3 mx-auto text-center py-3 mb-2">

								Plant Check completed for this day!

							</div>
						)}
					</div>

					<table className="table-auto w-full mb-2 text-center w-full md:w-2/3 mx-auto border border-grey-mid">
						<thead>
							<tr>
								<th colSpan={2} className="px-0.5 py-1 border-b border-grey-mid bg-grey-mid">
									Plant Checks for {rigSelectedText}
								</th>
							</tr>
							<tr>
								<th className="px-0.5 py-1 border-b border-r border-grey-mid bg-grey-mid">
									Plant Check Date
								</th>
								<th className="px-0.5 py-1 border-b border-grey-mid bg-grey-mid">
									Issues / Maintenance
								</th>
							</tr>
						</thead>
						<tbody>
							{plantCheckData
								.slice() // Make a copy of the array to avoid mutating the original
								.sort((a: any, b: any) => (new Date(b.plant_check_date) as any) - (new Date(a.plant_check_date) as any))
								.map((plantCheck, index) => (
									// Render each row and attach onClick event handler
									<tr key={index} onClick={() => this.handlePlantCheckClick(plantCheck)}>
										<td className="px-0.5 py-1 border-b border-r border-grey-mid">
											<div onClick={() => this.handlePlantCheckClick(plantCheck)}>
												{plantCheck.plant_check_date}
											</div>
										</td>
										<td className="px-1 py-1 border-b border-grey-mid text-left">
											<div onClick={() => this.handlePlantCheckClick(plantCheck)}>
												{plantCheck.issuesMaintenance}
											</div>
										</td>
									</tr>
								))}
						</tbody>
					</table>


				</Modal>
			</>
		)
	}
}

export default DailyPlantCheckList
