import React, { FunctionComponent, useState } from 'react'

import WorkflowSteps from '../../WorkflowSteps'
import Modal from '../../Modal'
import Button from '../../form/Button'
import NumberInput from '../../NumberInput'
import MultiNumberInput from '../../MultiNumberInput'
import ButtonOptions from '../../form/ButtonOptions'
import { v4 as uuidv4 } from 'uuid';

import stepStatus from '../../../helpers/stepStatus'
import warningIcon from '../../../assets/icons/warning.png'

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: string,
	defaultValues?: any,
	selectedDate?: string
	update?: boolean,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => { },
	onComplete: () => { },
	borehole: '',
	defaultValues: {
		guid: '',
		casing_size: '',
		top_of_redrill: '',
		base_of_redrill: '',
	},
	selectedDate: '',
}

const ReDrill: FunctionComponent<Props> = ({ onClose, update, onComplete, borehole, defaultValues, selectedDate, setShowReDrillEdit, ...rest }) => {
	const [currentStep, setCurrentStep] = useState(1)

	const [guid, setGuid] = useState(defaultValues.guid)
	const [casing_size, setSize] = useState(defaultValues.casing_size)
	const [top_of_redrill, setDepthFrom] = useState(defaultValues.top_of_redrill)
	const [base_of_redrill, setDepthTo] = useState(defaultValues.base_of_redrill)

	const [saveButtonText, setButtonText] = useState('Continue')
	const [saveButtonColor, setButtonColor] = useState('primary')
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	const DeleteButton = () => {
		if (!update) return null;

		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	const deleteRecordConfirm = () => {
		setDeleteConfirmOpen(false)
		// onComplete()
	}

	const [time_of_action, setTimeOfAction] = useState(defaultValues.time_of_action)

	const isoDate = selectedDate ? `${new Date(selectedDate).toISOString().split('T')[0]} 09:00:00` : new Date().toISOString().split('.')[0]

	const previous = () => {
		if (currentStep > 1)
			setCurrentStep(currentStep - 1)
		setButtonText('Continue')
		setButtonColor('primary')
	}

	const next = () => {
		if (currentStep < 2) {
			setCurrentStep(currentStep + 1)
			if (currentStep == 1) {
				setButtonText('Save')
				setButtonColor('success')
			} else {
				setButtonText('Continue')
				setButtonColor('primary')
			}
			return
		}

		if (!casing_size || !top_of_redrill || !base_of_redrill) {
			//do nothing
		} else {

			onComplete({
				guid: update ? guid : uuidv4(),
				casing_size: casing_size,
				top_of_redrill: top_of_redrill2DP, //2DP,
				base_of_redrill: base_of_redrill2DP, //2DP,
				time_of_action: time_of_action ? time_of_action : isoDate,
				remarks: '',
				update: update ? true : false,

			})
		}
	}

	let requiredCasingText = ''
	let requiredFromText = ''
	let requiredToText = ''

	if (!casing_size) {
		requiredCasingText = "'Casing Size' - "
	}

	if (!top_of_redrill) {
		requiredFromText = "'Depth To' - "
	}

	if (!base_of_redrill) {
		requiredToText = "'Depth To'"
	}

	const MissingRequiredField = () => {
		if (currentStep === 2 && (!casing_size || !top_of_redrill || !base_of_redrill)) return (
			<div className="text-center bg-sptSB border border-red font-bold rounded mb-2 py-1 text-xs md:text-sm lg:text-base">
				<img src={warningIcon} alt="info" width="20" className="inline-block mr-1 pb-1" />
				Required field: {requiredCasingText} {requiredFromText} {requiredToText}
			</div>
		)

		return null
	}

	var top_of_redrill2DP = parseFloat(top_of_redrill).toFixed(2)
	var base_of_redrill2DP = parseFloat(base_of_redrill).toFixed(2)

	return (
		<Modal title="Re-Drill" subtitle={borehole} toggleModal={onClose}>
			<div className="flex flex-col flex-grow px-1 sm:px-2 md:px-10">
				<WorkflowSteps steps={[
					{
						label: 'Size',
						status: stepStatus(1, currentStep),
						onClick: () => {
							setCurrentStep(1)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Depth',
						status: stepStatus(2, currentStep),
						onClick: () => {
							setCurrentStep(2)
							setButtonText('Save')
							setButtonColor('success')
						}
					},
				]} className="mb-2" />

				<div className="flex-1 flex text-center">
					{currentStep === 1 &&
						<div className="w-full text-center flex flex-col flex-grow border border-grey-mid rounded px-2 bg-sptTB mb-2 w-full pb-2">
							<ButtonOptions onUpdate={(value: any) => setSize(value)}
								defaultValue={casing_size}
								columns={5}
								columnsMobile={2}
								title="Casing Size"
								buttonHeight={20}
								buttonHeightMobile={16}
								options={[
									{
										value: '150', label: <><div className="text-3xl sm:text-3xl md:text-4xl">6"</div>
											<div className="text-sm sm:text-lg md:text-lg">150mm</div></>
									},
									{
										value: '200', label: <><div className="text-3xl sm:text-3xl md:text-4xl">8"</div>
											<div className="text-sm sm:text-lg md:text-lg">200mm</div></>
									},
									{
										value: '250', label: <><div className="text-3xl sm:text-3xl md:text-4xl">10"</div>
											<div className="text-sm sm:text-lg md:text-lg">250mm</div></>
									},
									{
										value: '305', label: <><div className="text-3xl sm:text-3xl md:text-4xl">12"</div>
											<div className="text-sm sm:text-lg md:text-lg">305mm</div></>
									},
									{
										value: '610', label: <><div className="text-3xl sm:text-3xl md:text-4xl">24"</div>
											<div className="text-sm sm:text-lg md:text-lg">610mm</div></>
									},
								]}
								next={next}
								className="mb-10"
							/>
						</div>
					}

					{currentStep === 2 &&
						// <NumberInput onUpdate={(value: any) => setDepthFrom(value)} 
						// defaultValue={top_of_redrill} 
						// title="Enter Re-Drill Depth From (m)" 
						// className="mb-10" />

						<MultiNumberInput
							title="Depth (m)"  
							values={
								[
									{ defaultValue: top_of_redrill, onUpdate: setDepthFrom, label: 'From' },
									{ defaultValue: base_of_redrill, onUpdate: setDepthTo, label: 'To' },
								]
							}
							className="text-base sm:text-xl md:text-xl mb-2" 
							inputCSS="text-4xl sm:text-4xl md:text-3xl lg:text-4xl h-16 sm:h-16 mb-4" 
						/>
					}

					{/* {currentStep === 3 &&
						<NumberInput onUpdate={(value: any) => setDepthTo(value)} 
						defaultValue={base_of_redrill} 
						title="Enter Re-Drill Depth To (m)" 
						className="mb-10" />
					} */}
				</div>

				<MissingRequiredField />


				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">


							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?

								<div className="flex mt-4">
									<Button theme="secondary" 
										className="mr-5 text-sm sm:text-base px-3.5 md:px-7" 
										onClick={() => setDeleteConfirmOpen(false)}>
											Cancel
									</Button>

									<Button onClick={() => {
												onClose()
												onComplete({
													guid: guid,
													update: false,
													delete: true,
												})
												setShowReDrillEdit(false)
											}}
										className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">
										Delete
									</Button>
								</div>
							</div>


						</div>
					</div>
				}

				<div className="flex">
					{/* <Button theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button> */}
					<DeleteButton />

					<div className="flex ml-auto">
						<Button theme="primary" size="lg" className="px-4 sm:px-10" onClick={() => previous()}>Back</Button>
						<Button theme={saveButtonColor}
							size="lg"
							className="ml-4 px-8 sm:px-10 w-32 sm:w-60"
							onClick={() => next()}>{saveButtonText}
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

ReDrill.defaultProps = defaultProps

export default ReDrill
