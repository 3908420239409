import React, { FunctionComponent, useState, useEffect, useRef } from 'react'

import Modal from '../../Modal'
import Button from '../../form/Button'
import Timer from '../../Timer'

import formatTime from '../../../helpers/formatTime'
import edit from "../../../assets/icons/edit.svg"
import { v4 as uuidv4 } from 'uuid'

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: string,
	title: string,
	[x: string]: any;
	update?: boolean,
	defaultValues?: any,
	// id: number,
}

const defaultProps: Props = {
	onClose: () => { },
	onComplete: () => { },
	borehole: '',
	title: '',
	defaultValues: {
		results: [],
	},
	run: '',
}

const keys = [
	'1', '2', '3',
	'4', '5', '6',
	'7', '8', '9',
	'.', '0',
]

const HeadTest: FunctionComponent<Props> = ({ onClose, update, id, defaultValues, run, onComplete, borehole, title,
	setShowRun1Edit, setShowRun2Edit, setShowRun3Edit,
	...rest }) => {

	// console.log('defaultValues:', defaultValues)

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// SET RUNS DATA
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 


	const [headTestRun, setHeadTestRun] = useState(run);
	// console.log('headTestRun:', headTestRun)

	const [headTestData, setHeadTestData] = useState([]);

	useEffect(() => {
		if (headTestRun === 1) {
			setHeadTestData(defaultValues.runs[0].results)
		} else if (headTestRun === 2) {
			setHeadTestData(defaultValues.runs[1].results)
		} else if (headTestRun === 3) {
			setHeadTestData(defaultValues.runs[2].results)
		}
	}, [headTestRun]);
	console.log('headTestData:', headTestData)

	const [depth_of_headtest, set_depth_of_headtest] = useState(defaultValues.depth_of_borehole);

	const [currentStatus, set_currentStatus] = useState('inactive');
	// console.log('currentStatus in head test:', currentStatus)

	useEffect(() => {
		if (headTestRun === 1) {
			set_currentStatus(defaultValues.runs[0].status)
		} else if (headTestRun === 2) {
			set_currentStatus(defaultValues.runs[1].status)
		} else if (headTestRun === 3) {
			set_currentStatus(defaultValues.runs[2].status)
		}
	}, [headTestRun])

	const [startDateTime, set_startDateTime] = useState('');



	const [dataType, setDataType] = useState('');

	useEffect(() => {
		let initialDataType = '';
		let currentStatus = '';
	  
		if (headTestRun === 1) {
		  initialDataType = defaultValues.runs[0]?.data_type === 'manual' ? 'manual' : 'auto';
		  currentStatus = defaultValues.runs[0]?.status || '';
		} else if (headTestRun === 2) {
		  initialDataType = defaultValues.runs[1]?.data_type === 'manual' ? 'manual' : 'auto';
		  currentStatus = defaultValues.runs[1]?.status || '';
		} else if (headTestRun === 3) {
		  initialDataType = defaultValues.runs[2]?.data_type === 'manual' ? 'manual' : 'auto';
		  currentStatus = defaultValues.runs[2]?.status || '';
		}
	  
		set_currentStatus(currentStatus);
		setDataType(initialDataType); // Add this line to update the dataType state
	  
	  }, [defaultValues, headTestRun]);

	useEffect(() => {
		// console.log('dataType******************************************************:', dataType);
	}, [dataType]);
  




	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// SET OTHER DEFAULT VALUES
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 

	const [guid, set_guid] = useState(defaultValues.guid);
	const [depth_of_casing, set_depth_of_casing] = useState(defaultValues.depth_of_casing);
	const [diameter_of_borehole, set_diameter_of_borehole] = useState(defaultValues.diameter_of_borehole);
	const [general_remarks, set_general_remarks] = useState(defaultValues.general_remarks);
	const [gravel_backfill, set_gravel_backfill] = useState(defaultValues.gravel_backfill);
	const [height_of_casing, set_height_of_casing] = useState(defaultValues.height_of_casing);
	const [ht_indentifier, set_ht_indentifier] = useState(defaultValues.ht_indentifier);
	const [soil_type, set_soil_type] = useState(defaultValues.soil_type);
	const [water_level_before_test, set_water_level_before_test] = useState(defaultValues.water_level_before_test);
	const [test_type, set_test_type] = useState(defaultValues.test_type);
	const [time_of_action, set_time_of_action] = useState(defaultValues.time_of_action);



	const [time, setTime] = useState<number>(0)
	const [depthAtThisTime, setDepthAtThisTime] = useState<string>('')
	const [parentStatus, setParentStatus] = useState(currentStatus)
	const [focusedInput, setFocusedInput] = useState<number | null>(null);
	const [editedRowIndex, setEditedRowIndex] = useState<number | null>(null); // Rename this variable
	const [editedData, setEditedData] = useState<any[]>([]);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [editTime, setEditTime] = useState('');
	const [editDepth, setEditDepth] = useState('');
	const [deleteData, setDeleteData] = useState<any[][]>([['00:00:00', '0.00']])
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	const [parentDateTime, setParentDateTime] = useState(null)
	// console.log('parentDateTime:', parentDateTime)
	const statusText = parentStatus.toUpperCase()	

	const RunStatus = () => {
		if (parentStatus === 'active') return (
			<div className="bg-blue w-full py-0.5 px-0.5 md:px-2 rounded mt-1 mb-1 text-white font-bold">
				ACTIVE
			</div>
		)

		if (parentStatus === 'complete') return (
			<div className="bg-green-checkmark w-full py-0.5 px-0.5 md:px-2 rounded mt-1 mb-1 text-white font-bold">
				COMPLETE
			</div>
		)

		return (
			<div className="bg-charcoal w-full py-0.5 px-0.5 md:px-2 rounded mt-1 mb-1 text-white font-bold">
				INACTIVE
			</div>
		);
	}

	useEffect(() => {
		// console.log('parentStatus:', parentStatus)
		RunStatus()
	}, [parentStatus])

	useEffect(() => {
		if (headTestRun === 1) {
			set_startDateTime(defaultValues.runs[0].start_time)
			setParentDateTime(defaultValues.runs[0].start_time)
		} else if (headTestRun === 2) {
			set_startDateTime(defaultValues.runs[1].start_time)
			setParentDateTime(defaultValues.runs[1].start_time)
		} else if (headTestRun === 3) {
			set_startDateTime(defaultValues.runs[2].start_time)
			setParentDateTime(defaultValues.runs[2].start_time)
		}
	}, [headTestRun])

	useEffect(() => {
		// console.log('startDateTime in head test:', startDateTime)
	}, [])
	
	useEffect(() => {
			setParentStatus(currentStatus)
	}, [currentStatus])


	const [showManualInput, setShowManualInput] = useState<boolean>(false)
	const [showAutomaticInput, setShowAutomaticInput] = useState<boolean>(true)
	const [showAutomaticInputButtons, setShowAutomaticInputInputButtons] = useState<boolean>(true)
	const [showAutoMaunualButton, setShowAutoMaunualButton] = useState<boolean>(true)



	const numManualRows = 20; // Number of rows

	const [manualInputValues, setManualInputValues] = useState<{
		[key: string]: string;
	}>({
		input1: '', input2: '', input3: '', input4: '', input5: '', input6: '', input7: '', input8: '', input9: '', input10: '',
		input11: '', input12: '', input13: '', input14: '', input15: '', input16: '', input17: '', input18: '', input19: '', input20: '',
	});

	const predefinedTimeLabels = [
		'00:00:00', '00:00:30', '00:01:00', '00:01:30', '00:02:00',
		'00:02:30', '00:03:00', '00:03:30', '00:04:00', '00:04:30',
		'00:05:00', '00:06:00', '00:07:00', '00:08:00', '00:09:00',
		'00:10:00', '00:20:00', '00:30:00', '00:45:00', '01:00:00'
	];

	




	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// SET HEAD TEST DATA - AUTO MODE
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
	const [headTestSet, set_headTestSet] = useState(0);
	const [headTests, setHeadTests] = useState<any[]>([]);
	// Function to set headTests when data_type is 'auto'
	useEffect(() => {
		if (dataType === 'auto') {
		  const entriesArray = Object.entries(headTestData);

		//   console.log('headTestData:', headTestData)
		//   console.log('entriesArray:', entriesArray)
		  const headTestsArray = entriesArray
			.filter(([time, depth]) => depth !== null)
			.map(([time, depth]) => ({ time, depth }));
		  
		//   console.log('headTestsArray:', headTestsArray);
		  setHeadTests(headTestsArray);
		  set_headTestSet(1)
		//   loadManualInputValues(headTests);
		}
	  }, [dataType, headTestData]);


	// Function to log headTests when it changes
	useEffect(() => {
		// console.log('headTests on change:', headTests);
		// loadManualInputValues(headTests);
	}, [headTests, headTestSet]);




	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// SET HEAD TEST DATA - MANUAL MODE
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 

		// // Function to set headTests when data_type is 'manual'
		// useEffect(() => {
		// 	if (dataType === 'manual') {
		// 		// Fetch or generate manual data
		// 		// Replace the following line with your logic to get manual data
		// 		const manualData = fetchDataForManualTests();
			
		// 		// Process manualData and setHeadTests accordingly
		// 		const headTestsArray = Object.entries(manualData)
		// 		  .filter(([time, depth]) => depth !== null)
		// 		  .map(([time, depth]) => ({ time, depth }));
			
		// 		setHeadTests(headTestsArray);
		// 		set_headTestSet(1);
			
		// 		// Load manual input values
		// 		loadManualInputValues(headTestsArray);
		// 	  }
		//   }, [dataType, headTestData, loadManualInputValues]);

	// // // on load
	// useEffect(() => {
	// 	if (headTestRun === 1) {
	// 		if (defaultValues.runs[0].data_type === 'manual' && headTestData.length > 0) {
	// 			const updatedManualInputValues = { ...manualInputValues };
	
	// 			headTestData.forEach(({ time, depth }, index) => {
	// 				const inputKey = `input${index + 1}`;
	// 				updatedManualInputValues[inputKey] = depth !== null ? depth : '';
	// 			});
	
	// 			if (!objectsAreEqual(updatedManualInputValues, manualInputValues)) {
	// 				setManualInputValues(updatedManualInputValues);
	// 			}
	// 		}
	// 	} else if (headTestRun === 2) {
	// 		if (defaultValues.runs[1].data_type === 'manual' && headTests.length > 0) {
	// 			const updatedManualInputValues = { ...manualInputValues };
	
	// 			headTests.forEach(({ time, depth }, index) => {
	// 				const inputKey = `input${index + 1}`;
	// 				updatedManualInputValues[inputKey] = depth !== null ? depth.toString() : '';
	// 			});
	
	// 			if (!objectsAreEqual(updatedManualInputValues, manualInputValues)) {
	// 				setManualInputValues(updatedManualInputValues);
	// 			}
	// 		}
	// 	} else if (headTestRun === 3) {
	// 		console.log('RUN 3 Manual ****************************************')
	// 		if (defaultValues.runs[2].data_type === 'manual' && headTestData.length > 0) {
	// 			const updatedManualInputValues = { ...manualInputValues };
	
	// 			headTests.forEach(({ time, depth }, index) => {
	// 				const inputKey = `input${index + 1}`;
	// 				updatedManualInputValues[inputKey] = depth !== null ? depth.toString() : '';
	// 			});

	// 			console.log('*************************************')
	// 			console.log('*****MANUAL RUN 3 INPUTS:', updatedManualInputValues, manualInputValues)
	// 			console.log('*************************************')
	
	// 			if (!objectsAreEqual(updatedManualInputValues, manualInputValues)) {
	// 				setManualInputValues(updatedManualInputValues);
	// 			}
	// 		}
	// 	}
	// }, [headTestData]);

	// const loadManualInputValues = () => {

	// 	console.log('****************** loadManualInputValues ************************:');

	// 	const updatedManualInputValues: Record<string, string> = { ...manualInputValues };
	
	// 	// Convert headTestData into an array
	// 	const headTestDataArray: { time: string; depth: number | null }[] = Object.entries(headTestData)
	// 		.map(([time, depth]) => ({ time, depth }));

	// 	headTestDataArray.forEach(({ time, depth }, index) => {
	// 		const inputKey = `input${index + 1}`;
	// 		if (depth !== null) {
	// 			updatedManualInputValues[inputKey] = depth.toString();
	// 		} else {
	// 			updatedManualInputValues[inputKey] = '';
	// 		}
	// 	});

	// 	if (!objectsAreEqual(updatedManualInputValues, manualInputValues)) {
	// 		setManualInputValues(updatedManualInputValues);
	// 		console.log('ManualInputValues Updated:', updatedManualInputValues);
	// 	}
	//   };

	


	//   useEffect(() => {
	// 	loadManualInputValues()
	//   }, [headTestData])

	// whilst active
	// useEffect(() => {
	// 	console.log('DataType:', dataType);
	// 	console.log('HeadTests:', headTests);
	// 	console.log('ManualInputValues:', manualInputValues);
	// 	if (dataType === 'manual') {
	// 		console.log('*************** Manual ***************')
	// 		const updatedManualInputValues = { ...manualInputValues };

	// 		headTests.forEach(({ time, depth }, index) => {
	// 			const inputKey = `input${index + 1}`;
	// 			updatedManualInputValues[inputKey] = depth !== null ? depth.toString() : '';
	// 		});

	// 		if (!objectsAreEqual(updatedManualInputValues, manualInputValues)) {
	// 			setManualInputValues(updatedManualInputValues);
	// 			console.log('ManualInputValues Updated:', updatedManualInputValues);
	// 		}
	// 	}
	// }, [dataType, headTests, manualInputValues]);

	// console.log('headTests:', headTests)
	// console.log('manualInputValues:', manualInputValues)

	useEffect(() => {
		console.log('DataType:', dataType);
		console.log('HeadTestData:', headTestData);
		console.log('ManualInputValues:', manualInputValues);
	
		if (dataType === 'manual' && headTestData) {
			console.log('*************** Manual ***************');
			const updatedManualInputValues: Record<string, string> = { ...manualInputValues };
	
			Object.entries<{[key: string]: number | null}>(headTestData).forEach(([time, depth], index) => {
				const inputKey = `input${index + 1}`;
				if (depth !== null && typeof depth !== 'undefined') {
					updatedManualInputValues[inputKey] = depth.toString();
				} else {
					// Preserve existing value if available
					updatedManualInputValues[inputKey] = updatedManualInputValues[inputKey] || '';
				}
			});
	
			console.log('*************** updatedManualInputValues ***************');
			console.log(updatedManualInputValues, manualInputValues);
			console.log('*************** manualInputValues ***************');
	
			if (!objectsAreEqual(updatedManualInputValues, manualInputValues)) {
				setManualInputValues(updatedManualInputValues);
				console.log('ManualInputValues Updated:', updatedManualInputValues);
			}
		}
	}, [dataType, headTestData, manualInputValues]);


	// Helper function to compare objects
	const objectsAreEqual = (obj1: any, obj2: any) => JSON.stringify(obj1) === JSON.stringify(obj2);

	// Function to update manualInputValues when headTests changes
	// useEffect(() => {
	// 	if (dataType === 'manual') {
	// 		const newManualInputValues: { [key: string]: string } = {};
	// 		headTests.forEach(({ time, depth }) => {
	// 			const inputKey = `input${predefinedTimeLabels.indexOf(time) + 1}`;
	// 			newManualInputValues[inputKey] = depth;
	// 		});

	// 		// Only update state if it's different from the current state
	// 		if (!objectsAreEqual(newManualInputValues, manualInputValues)) {
	// 			// console.log('Updating manualInputValues:', newManualInputValues);
	// 			setManualInputValues(newManualInputValues);
	// 		}
	// 	}
	// }, [dataType, headTests]);








	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// TOGGLE AUTO / MANUAL MODE
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 

	const toggleAutoManualMode = () => {
		setShowManualInput(!showManualInput);
		setShowAutomaticInput(!showAutomaticInput);
		setShowAutomaticInputInputButtons(!showAutomaticInputButtons);
		setFocusedInput(null); // Set focusedInput to null
		setDataType(dataType === 'auto' ? 'manual' : 'auto');
	};

	


	useEffect(() => {
		if (currentStatus === 'active' || currentStatus === 'complete') {
			setShowAutoMaunualButton(false)
		}
	}, [currentStatus])

	

	// Disable AUTO / MANUAL mode if in EDIT

	useEffect(() => {

		if (headTestRun === 1) {
			if (defaultValues.runs[0].data_type === 'manual' 
			&& (defaultValues.runs[0].status === 'complete' 
			|| defaultValues.runs[0].status === 'active')) {
				setShowManualInput(true);
				setShowAutomaticInput(false);
				setShowAutomaticInputInputButtons(false);
				console.log('***MANUAL RUN 1***')
			} else if (defaultValues.runs[0].data_type === 'auto' 
			&& (defaultValues.runs[0].status === 'complete' 
			|| defaultValues.runs[0].status === 'active')) {
				setShowManualInput(false);
				setShowAutomaticInput(true);
				setShowAutomaticInputInputButtons(true);
				console.log('***AUTO RUN 1***')
			}
		} else if (headTestRun === 2) {
			if (defaultValues.runs[1].data_type === 'manual' 
			&& (defaultValues.runs[1].status === 'complete' 
			|| defaultValues.runs[1].status === 'active')) {
				setShowManualInput(true);
				setShowAutomaticInput(false);
				setShowAutomaticInputInputButtons(false);
				console.log('***MANUAL RUN 2***')
			} else if (defaultValues.runs[1].data_type === 'auto' 
			&& (defaultValues.runs[1].status === 'complete' 
			|| defaultValues.runs[1].status === 'active')) {
				setShowManualInput(false);
				setShowAutomaticInput(true);
				setShowAutomaticInputInputButtons(true);
				console.log('***AUTO RUN 2***')
			}
		} else if (headTestRun === 3) {
			if (defaultValues.runs[2].data_type === 'manual' 
			&& (defaultValues.runs[2].status === 'complete' 
			|| defaultValues.runs[2].status === 'active')) {
				setShowManualInput(true);
				setShowAutomaticInput(false);
				setShowAutomaticInputInputButtons(false);
				console.log('***MANUAL RUN 3***')
			} else if (defaultValues.runs[2].data_type === 'auto' 
			&& (defaultValues.runs[2].status === 'complete' 
			|| defaultValues.runs[2].status === 'active')) {
				setShowManualInput(false);
				setShowAutomaticInput(true);
				setShowAutomaticInputInputButtons(true);
				console.log('***AUTO RUN 3***')
			}
		}
	}, [defaultValues])

	// console.log('***AUTO/MANUAL***', dataType, currentStatus)






	const handleInputBlur = () => {
		// console.log('Blurred');
		// Optionally, you can add validation logic here before removing focus.
		setFocusedManualInput('')
	}

	const [focusedManualInput, setFocusedManualInput] = useState('');

	const inputRef = useRef(null); // Create a ref

	const handleInputFocus = (inputId: any) => {
		// console.log(`Focused on ${inputId}`);
		setFocusedInput(inputId);
		inputRef.current = inputId;
	};

	const DeleteButton = () => {
		if (!update) return null;

		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete Run</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	// const deleteRecordConfirm = () => {
	// 	setDeleteConfirmOpen(false)
	// 	// onComplete()
	// }

	const [value, setValue] = useState('')

	const handleInputChange = (inputKey: string, value: string) => {
		// console.log(`Updating ${inputKey} with value ${value}`);
		setManualInputValues((prevValues) => {
			if (prevValues[inputKey] !== value) {
				return {
					...prevValues,
					[inputKey]: value,
				};
			}
			return prevValues; // Return the unchanged state if no update is needed
		});
	};

	const handleDivChange = () => {
		const divElement = document.getElementById("divElement");
		if (divElement) {
			const divContent = divElement.textContent || "";
			setDepthAtThisTime(divContent);
		}
	};

	const addCharacter = (character: string) => {
		if (showAutomaticInput === true) { // AUTOMATIC MODE
			if (focusedInput !== null) {
				// If an input is focused, append the character to the focused input in editedData
				const updatedData = [...editedData];
				// console.log('updatedData****************************', updatedData)
				updatedData[focusedInput] = (updatedData[focusedInput] || '') + character;
				setEditedData(updatedData);
			} else {
				// If no input is focused, append the character to the common value
				const newValue = (value || '') + character;
				setValue(newValue);
				const divElement = document.getElementById("divElement");

				if (divElement) {
					divElement.textContent = newValue;
					handleDivChange();
				}
			}
		} else { // MANUAL MODE
			if (focusedInput) {

				// console.log('prevInputValues:', manualInputValues);

				setManualInputValues((prevInputValues: any) => ({
					...(prevInputValues || {}),  // If prevInputValues is undefined, use an empty object
					[focusedInput]: (prevInputValues && prevInputValues[focusedInput]) ? prevInputValues[focusedInput] + character : character,
				}));

				// Check if the input element exists
				const inputElement = document.getElementById(focusedInput.toString());
				if (inputElement) {
					inputElement.classList.add('focused');
				}
			}
		}
	};
	// console.log('focusedManualInput', focusedManualInput)

	const removeCharacter = () => {
		if (showAutomaticInput === true) { // AUTOMATIC MODE
			if (focusedInput !== null) {
				// If focusedInput is not null, remove the last character from the focused input
				const updatedData = [...editedData];
				const currentValue = updatedData[focusedInput];

				// Check if the focused input is depth (index 1)
				if (focusedInput === 1) {
					// Convert the value to a string before slicing
					const stringValue = String(currentValue);
					updatedData[focusedInput] = stringValue.slice(0, -1);
					setEditedData(updatedData);
				}
			} else {
				// If no input is focused, remove the last character from the common value
				let newValue = value || '';
				if (newValue !== '') {
					newValue = newValue.slice(0, -1);
				}

				setValue(newValue);
				const divElement = document.getElementById("divElement");

				if (divElement) {
					divElement.textContent = newValue;
					handleDivChange();
				}
			}
		} else { // MANUAL MODE

			if (focusedInput) {
				setManualInputValues((prevInputValues: any) => {
					const inputValue = prevInputValues[focusedInput];

					// Check if inputValue is null, undefined, or an empty string
					if (inputValue == null || inputValue === '') {
						return prevInputValues; // Do nothing
					}

					const newValue = inputValue.slice(0, -1); // Remove the last character
					return {
						...prevInputValues,
						[focusedInput]: newValue,
					};
				});
			}

		}
	}

	// Function to update depthAtThisTime and the <div> content
	const updateDepth = (value: any) => {
		setDepthAtThisTime(value);
	}

	const returnedStartDateTime = startDateTime || parentDateTime

	// console.log('returnedStartDateTime, startDateTime, parentDateTime:', returnedStartDateTime, startDateTime, parentDateTime)






	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// SAVE
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 

	const save = () => {
		// handleInputFocus('input1')
		const updatedResultManualArray = generateManualInputArray(numManualRows);
		// console.log('dataType on save:', dataType)
		// console.log('headTestsSaveArrayAuto on save:', headTestsSaveArrayAuto)
		// console.log('handleCreateManualArray on save:', handleCreateManualArray)
		onComplete({

			guid: guid,
			update: true,
			depth_of_borehole: depth_of_headtest,
			diameter_of_borehole: diameter_of_borehole,
			depth_of_casing: depth_of_casing,
			height_of_casing: height_of_casing,
			water_level_before_test: water_level_before_test,
			gravel_backfill: gravel_backfill,
			soil_type: soil_type,
			test_type: test_type,
			general_remarks: general_remarks,
			time_of_action: time_of_action,
			runs: updatedRuns,

		})
	}
	




	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// ADD DEPTH AT THIS TIME - AUTO MODE
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 

	const addDepthAtThisTime = () => {
		if (depthAtThisTime !== '') {

			const newEntry = {
				time: formatTime(time),
				depth: parseFloat(depthAtThisTime).toFixed(2),
			};

			setHeadTests([...headTests, newEntry]);
			// console.log('Time / Depth:', newEntry.time, newEntry.depth);

			setDepthAtThisTime('');
			const divElement = document.getElementById("divElement");
			if (divElement) {
				divElement.textContent = ''; // Reset the content of the div
			}
			setValue('');
			setFocusedInput(null);
		}
	};
	




	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// EDIT DEPTH VALUE - AUTO MODE
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 

	const handleEdit = (index: number) => {
		// Set the edited data based on the selected row
		setEditedData([headTests[index].time, headTests[index].depth]);
		setIsEditing(true);

		// Set the editedRowIndex to trigger the effect
		setEditedRowIndex(index);
	};

	const isTimeValid = (time: string) => {
		const regex = /^([0-9]{2}):([0-9]{2}):([0-9]{2})$/;
		return regex.test(time);
	};
	




	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// SAVE EDITED DEPTH VALUE - AUTO MODE
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 

	const handleSave = () => {
		if (editedRowIndex !== null) {
			// Check if the edited time is valid
			if (!isTimeValid(editedData[0])) {
				// Display an error message or prevent saving if the time is invalid
				alert('Invalid time format. Please use HH:MM:SS format.');
				return;
			}

			// Create a copy of the current headTests state
			const updatedItems = [...headTests];

			// Replace the item at the editedRowIndex with the editedData
			updatedItems[editedRowIndex] = {
				time: String(editedData[0]), // Ensure time is a string
				depth: String(editedData[1]), // Ensure depth is a string
			};

			// Update the headTests state with the updated data
			setHeadTests(updatedItems);

			// Clear the temporary editing-related state
			setEditedData([]);
			setEditedRowIndex(null);
			setIsEditing(false);
			setFocusedInput(null);
		}
	};
	




	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// DELETE RECORD - AUTO MODE
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 

	const handleDelete = (indexToDelete: any) => {
		const updatedData = [...headTests]
		updatedData.splice(indexToDelete, 1)
		setHeadTests(updatedData)
		// Clear the temporary editing-related state
		setEditedData([])
		setEditedRowIndex(null)
		setIsEditing(false)
		setFocusedInput(null)
	}

	// MANUAL ARRAY

	const generateManualInputArray = (numRows: any) => {
		// Initialize an empty array to store the result
		const resultManualArray: [string, string][] = []

		for (let i = 1; i <= numRows; i++) {
			const timeId = `manualTime${i}`
			const inputId = `manualInput${i}`
			const timeElement = document.getElementById(timeId) as HTMLTableCellElement
			const inputElement = document.getElementById(inputId) as HTMLInputElement

			const timeValue = (timeElement?.textContent ?? '') as string; // Provide a default empty string
			let inputValue = ''
			if (inputElement) {
				// Convert the input value to a number and format it to 2 decimal places
				const inputValueAsNumber = parseFloat(inputElement.value)
				inputValue = isNaN(inputValueAsNumber) ? '' : inputValueAsNumber.toFixed(2)
			}

			resultManualArray.push([timeValue, inputValue])
		}

		// Return the generated array
		return resultManualArray
	};

	// Call the function to generate the array
	const resultManualArray = generateManualInputArray(numManualRows)

	//   console.log('Result Array:', resultManualArray)


	// State for the modified headTests array without 'time' and 'depth'
	const [headTestsSaveArrayAuto, setHeadTestsSaveArrayAuto] = useState<any[]>([]);
	const [headTestsSaveArrayManual, setHeadTestsSaveArrayManual] = useState<ManualData[]>([]);

	useEffect(() => { // create headTestsSaveArrayAuto to export on save 
		if (headTests.length > 0) {
			// Combine time and depth into a single object
			const modifiedData = headTests.reduce((accumulator, { time, depth }) => {
				// Convert depth to a number if it's a string
				const depthValue = typeof depth === 'string' ? parseFloat(depth) : depth;
				// Apply 2 decimal places formatting
				const formattedDepth = typeof depthValue === 'number' ? depthValue.toFixed(2) : null;
				//   // Convert depth to a number if it's a string and represents a valid number, otherwise set it to null
				//   const depthValue = typeof depth === 'string' && !isNaN(parseFloat(depth)) ? parseFloat(depth) : null;
				accumulator[time] = formattedDepth;
				return accumulator;
			}, {});

			setHeadTestsSaveArrayAuto([modifiedData]);
			// loadManualInputValues(headTests);
		}

	}, [headTests]);

	interface ManualData {
		// [key: string]: number | null;
		[time: string]: number | null;
	}

	// const handleCreateManualArray = (): ManualData[] | undefined => {
	// 	if (resultManualArray.length > 0) {
	// 		const modifiedManualData: ManualData[] = resultManualArray.map(([time, depth]) => {
	// 			const depthValue = typeof depth === 'string' && !isNaN(parseFloat(depth)) ? parseFloat(depth) : null;
	// 			return { [time]: depthValue };
	// 		});

	// 		const flattenedManualData: ManualData = modifiedManualData.reduce((accumulator, currentValue) => {
	// 			return Object.assign(accumulator, currentValue);
	// 		}, {});

	// 		return [flattenedManualData];
	// 	}

	// 	return undefined;
	// };

	const handleCreateManualArray = (): ManualData | undefined => {
		if (resultManualArray.length > 0) {
		  const modifiedManualData: ManualData = resultManualArray.reduce(
			(accumulator: ManualData, [time, depth]) => {
			  const depthValue =
				typeof depth === 'string' && !isNaN(parseFloat(depth)) ? parseFloat(depth) : null;
			  accumulator[time] = depthValue;
			  return accumulator;
			},
			{}
		  );

		  	console.log('resultManualArray:', resultManualArray)
		  	console.log('modifiedManualData:', modifiedManualData)
	  
		  return modifiedManualData;
		}
		// if (resultManualArray.length > 0) {
		// 	const modifiedManualData: ManualData = resultManualArray.reduce(
		// 	  (accumulator: ManualData, [time, depth]) => {
		// 		const depthValue =
		// 		  typeof depth === 'number' ? depth :
		// 		  typeof depth === 'string' && depth.trim() !== '' && !isNaN(parseFloat(depth)) ? parseFloat(depth) :
		// 		  null;
		
		// 		if (time.trim() !== '') {
		// 		  accumulator[time] = depthValue;
		// 		}
		// 		return accumulator;
		// 	  },
		// 	  {}
		// 	);

		// 	console.log('resultManualArray:', resultManualArray)
		
		// 	return modifiedManualData;
		//   }
		
		//   return undefined;
		};
	  console.log('handleCreateManualArray():', handleCreateManualArray())


	const updatedRuns = (defaultValues.runs || []).map((runData: any, index: any) => {
		if (index + 1 === headTestRun) {
			// If this is the run to be updated
			const resultsObject = headTestsSaveArrayAuto; //[0]

			// console.log('resultsObject:', resultsObject)

			// // Convert depth values from string to number
			// const updatedResultsObject: Record<string, number | null> = {};

			// if (resultsObject.length > 0) {
			//   const result = resultsObject[0];
			  
			//   Object.entries(result).forEach(([time, depth]) => {
			// 	updatedResultsObject[time] = depth !== null && depth !== undefined ? Number(depth) : null;
			//   });
			// }

			// Convert depth values from string to number
			const updatedResultsObject: Record<string, number | null> = {};

			if (resultsObject.length > 0) {
			  const result = resultsObject[0];
			
			  Object.entries(result).forEach(([time, depth]) => {
				updatedResultsObject[time] = depth !== null && depth !== undefined ? Number(depth) : null;
			  });
			}

			// console.log('updatedResultsObject:', updatedResultsObject)

			return {
				run: headTestRun,
				start_time: returnedStartDateTime,
				data_type: dataType,
				status: parentStatus,
				results: dataType === 'auto' ? updatedResultsObject : handleCreateManualArray(),
			};
		} else {
			// If this run should remain unchanged
			return {
				run: runData.run,
				start_time: runData.start_time,
				data_type: runData.data_type,
				status: runData.status,
				results: runData.results,
			};
		}
	});







	const totalDepth = parseFloat(depth_of_headtest)

	const [showDepthVisual, setShowDepthVisual] = useState<boolean>(true)

	const toggleVisualMode = () => {
		setShowDepthVisual(!showDepthVisual);
	};

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// VISUAL MANUAL
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	// console.log('handleCreateManualArray():', handleCreateManualArray())

	const manualArray = handleCreateManualArray();

	const manualVisualArray = manualArray
	? Object.values(manualArray).map(value => (value !== null ? value : null))
	: [];

	console.log('manualVisualArray:', manualVisualArray);

	const manualVisualTimeArray = manualArray
	? Object.keys(manualArray)
	: [];
  
  const manualVisualCombinedArray = manualVisualTimeArray.map((time, index) => ({
	time,
	depth: manualVisualArray[index]
  }));

	// Find the maximum depth in manualVisualArray
	const maxDepthManual = manualVisualArray.reduce((max, depth) => (depth !== null && (max === null || depth > max) ? depth : max), null);

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// VISUAL AUTO
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	// console.log('headTestsSaveArrayAuto:', headTestsSaveArrayAuto)

	const autoVisualArray = headTestsSaveArrayAuto.length > 0
		? Object.values(headTestsSaveArrayAuto[0]).map(value => (value !== undefined && typeof value === 'string' ? parseFloat(value) : null))
		: [];

	  console.log('autoVisualArray:', autoVisualArray);

	const autoVisualTimeArray = headTestsSaveArrayAuto.length > 0
		? Object.keys(headTestsSaveArrayAuto[0])
		: [];

	const autoVisualCombinedArray = autoVisualTimeArray.map((time, index) => ({
		time,
		depth: autoVisualArray[index]
	}));

	// Find the maximum depth in manualVisualArray
	const maxDepthAuto = autoVisualArray.reduce((max, depth) => (depth !== null && (max === null || depth > max) ? depth : max), null);





	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// UPDATE & SAVE BUTTONS
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 

	const [updateClicked, setUpdateClicked] = useState(false);

	const SaveButton = () => {
		// If update button is not clicked, return null
		if (!updateClicked) return null;
		return (
			<Button theme="success" type="button" size="lg" className="ml-auto w-full md:w-96 mr-0"
				onClick={() => {
					save()
				}}
			>Save &amp; Exit</Button>
		);
	};

	const UpdateButton = () => {
		if (updateClicked) return null;
		return (
			<Button theme="primary" type="button" size="lg" className="ml-auto w-full md:w-96 mr-0"
				onClick={() => {
					console.log('updatedRuns**********************************************:', updatedRuns)
					setUpdateClicked(true);
				}}
			>Update</Button>
		);
	};














	return (
		<Modal title={title} subtitle={borehole} toggleModal={onClose}>

			{/* <div className="grid grid-cols-7 md:grid-cols-2 mb-1"> */}
			<div className="grid grid-cols-12 mb-1">

				<div className="col-span-5 flex flex-col justify-center items-center font-bold">
					{/* <div className="text-blue" style={{ fontSize: '0.6rem' }}>
						Start:
					</div> */}
					<div className="text-xs md:text-base h-6 md:h-6 -mt-8 md:-mt-1">
						{parentDateTime}
					</div>
					  
					{/* {startDateTime} */}
					<div className="bg-white border border-orange py-1.5 md:py-2 w-36 md:w-56 text-center text-3xl md:text-5xl rounded-lg">
						{formatTime(time)}
					</div>
				</div>

				<div className="col-span-1">
					
				</div>

				<div className="col-span-6 pt-0 md:pt-7">
					<Timer onUpdate={(value) => setTime(value)}
						setParentDateTime={setParentDateTime}
						start_time={startDateTime}
						status={currentStatus}
						setParentStatus={setParentStatus}
					/>
				</div>

			</div>

			<div className="grid grid-cols-12">

				<div className="col-span-5 text-xs md:text-base mb-0.5 text-center pr-1 font-bold">
					{/* Record the depth to the water from the top of the casing/standpipe */}
					{/* <div className="w-full border border-charcoal py-0.5 px-0.5 md:px-2 rounded mt-1 mb-1">{statusText}</div> */}
					{RunStatus()}
				</div>

				<div className="col-span-1 text-xs font-bold text-center">

				</div>

				<div className="col-span-6 text-xs md:text-base text-center mb-0.5">

					{showAutoMaunualButton &&
						<div className="bg-orange py-1 px-0.5 md:px-2 text-white rounded w-full ml-auto mr-0 md:mr-16 mt-1 mb-1" onClick={toggleAutoManualMode}>
							{showAutomaticInput ? 'AUTO INPUT' : 'MANUAL'}
						</div>
					}
				</div>

				<div className="col-span-5 pr-1">

					{/* MANUAL */}
					{showManualInput &&
						<div className="bg-white overflow-auto border-t border-grey-mid rounded manualInputHeight manualInputText">
							<table className="table-auto w-full text-center border-l border-grey-mid rounded border-separate text-base md:text-xl">
								<thead className="font-bold">
									<tr>
										<td className="px-1 py-0 bg-sptTB border-b border-grey-mid rounded-tl w-1/2 text-base">Time</td>
										<td className="px-1 py-0 bg-sptTB border-b border-grey-mid rounded-tr border-r border-grey-light w-1/2 text-base ">(m)</td>
										{/* <td className="px-1 py-0 bg-sptTB border-b border-grey-mid rounded-tr border-r border-grey-light w-1/5"></td> */}
									</tr>
								</thead>

								<tbody>
									<tr className="border-b border-r border-grey-mid bg-white mb-0">
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid"
											id="manualTime1">
											00:00:00
										</td>
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid">
											<input type="text"
												value={manualInputValues.input1}
												onChange={(e) => handleInputChange('input1', e.target.value)}
												onFocus={() => handleInputFocus('input1')}
												onBlur={handleInputBlur}
												className={`w-full text-center ${focusedManualInput === 'input1' ? 'focused' : ''}`}
												id="manualInput1"
												readOnly
											/>
										</td>
										{/* <td className="px-1 w-1/5 border-b border-grey-mid border-r border-b border-grey-mid">
										<img className="inline" width={"16"} src={edit} alt="" />
									</td> */}
									</tr>
									<tr className="border-b border-r border-grey-mid bg-white mb-0">
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid"
											id="manualTime2">
											00:00:30
										</td>
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid">
											<input type="text"
												value={manualInputValues.input2}
												onChange={(e) => handleInputChange('input2', e.target.value)}
												onFocus={() => handleInputFocus('input2')}
												onBlur={handleInputBlur}
												className={`w-full text-center ${focusedManualInput === 'input2' ? 'focused' : ''}`}
												id="manualInput2"
												readOnly
											/>
										</td>
										{/* <td className="px-1 w-1/5 border-b border-grey-mid border-r border-b border-grey-mid">
										<img className="inline" width={"16"} src={edit} alt="" />
									</td> */}
									</tr>
									<tr className="border-b border-r border-grey-mid bg-white mb-0">
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid"
											id="manualTime3">
											00:01:00
										</td>
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid">
											<input type="text"
												value={manualInputValues.input3}
												onChange={(e) => handleInputChange('input3', e.target.value)}
												onFocus={() => handleInputFocus('input3')}
												onBlur={handleInputBlur}
												className={`w-full text-center ${focusedManualInput === 'input3' ? 'focused' : ''}`}
												id="manualInput3"
												readOnly
											/>
										</td>
										{/* <td className="px-1 w-1/5 border-b border-grey-mid border-r border-b border-grey-mid">
										<img className="inline" width={"16"} src={edit} alt="" />
									</td> */}
									</tr>
									<tr className="border-b border-r border-grey-mid bg-white mb-0">
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid"
											id="manualTime4">
											00:01:30
										</td>
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid">
											<input type="text"
												value={manualInputValues.input4}
												onChange={(e) => handleInputChange('input4', e.target.value)}
												onFocus={() => handleInputFocus('input4')}
												onBlur={handleInputBlur}
												className={`w-full text-center ${focusedManualInput === 'input4' ? 'focused' : ''}`}
												id="manualInput4"
												readOnly
											/>
										</td>
										{/* <td className="px-1 w-1/5 border-b border-grey-mid border-r border-b border-grey-mid">
										<img className="inline" width={"16"} src={edit} alt="" />
									</td> */}
									</tr>
									<tr className="border-b border-r border-grey-mid bg-white mb-0">
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid"
											id="manualTime5">
											00:02:00
										</td>
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid">
											<input type="text"
												value={manualInputValues.input5}
												onChange={(e) => handleInputChange('input5', e.target.value)}
												onFocus={() => handleInputFocus('input5')}
												onBlur={handleInputBlur}
												className={`w-full text-center ${focusedManualInput === 'input5' ? 'focused' : ''}`}
												id="manualInput5"
												readOnly
											/>
										</td>
										{/* <td className="px-1 w-1/5 border-b border-grey-mid border-r border-b border-grey-mid">
										<img className="inline" width={"16"} src={edit} alt="" />
									</td> */}
									</tr>
									<tr className="border-b border-r border-grey-mid bg-white mb-0">
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid"
											id="manualTime6">
											00:02:30
										</td>
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid">
											<input type="text"
												value={manualInputValues.input6}
												onChange={(e) => handleInputChange('input6', e.target.value)}
												onFocus={() => handleInputFocus('input6')}
												onBlur={handleInputBlur}
												className={`w-full text-center ${focusedManualInput === 'input6' ? 'focused' : ''}`}
												id="manualInput6"
												readOnly
											/>
										</td>
										{/* <td className="px-1 w-1/5 border-b border-grey-mid border-r border-b border-grey-mid">
										<img className="inline" width={"16"} src={edit} alt="" />
									</td> */}
									</tr>
									<tr className="border-b border-r border-grey-mid bg-white mb-0">
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid"
											id="manualTime7">
											00:03:00
										</td>
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid">
											<input type="text"
												value={manualInputValues.input7}
												onChange={(e) => handleInputChange('input7', e.target.value)}
												onFocus={() => handleInputFocus('input7')}
												onBlur={handleInputBlur}
												className={`w-full text-center ${focusedManualInput === 'input7' ? 'focused' : ''}`}
												id="manualInput7"
												readOnly
											/>
										</td>
										{/* <td className="px-1 w-1/5 border-b border-grey-mid border-r border-b border-grey-mid">
										<img className="inline" width={"16"} src={edit} alt="" />
									</td> */}
									</tr>
									<tr className="border-b border-r border-grey-mid bg-white mb-0">
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid"
											id="manualTime8">
											00:03:30
										</td>
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid">
											<input type="text"
												value={manualInputValues.input8}
												onChange={(e) => handleInputChange('input8', e.target.value)}
												onFocus={() => handleInputFocus('input8')}
												onBlur={handleInputBlur}
												className={`w-full text-center ${focusedManualInput === 'input8' ? 'focused' : ''}`}
												id="manualInput8"
												readOnly
											/>
										</td>
										{/* <td className="px-1 w-1/5 border-b border-grey-mid border-r border-b border-grey-mid">
										<img className="inline" width={"16"} src={edit} alt="" />
									</td> */}
									</tr>
									<tr className="border-b border-r border-grey-mid bg-white mb-0">
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid"
											id="manualTime9">
											00:04:00
										</td>
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid">
											<input type="text"
												value={manualInputValues.input9}
												onChange={(e) => handleInputChange('input9', e.target.value)}
												onFocus={() => handleInputFocus('input9')}
												onBlur={handleInputBlur}
												className={`w-full text-center ${focusedManualInput === 'input9' ? 'focused' : ''}`}
												id="manualInput9"
												readOnly
											/>
										</td>
										{/* <td className="px-1 w-1/5 border-b border-grey-mid border-r border-b border-grey-mid">
										<img className="inline" width={"16"} src={edit} alt="" />
									</td> */}
									</tr>
									<tr className="border-b border-r border-grey-mid bg-white mb-0">
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid"
											id="manualTime10">
											00:04:30
										</td>
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid">
											<input type="text"
												value={manualInputValues.input10}
												onChange={(e) => handleInputChange('input10', e.target.value)}
												onFocus={() => handleInputFocus('input10')}
												onBlur={handleInputBlur}
												className={`w-full text-center ${focusedManualInput === 'input10' ? 'focused' : ''}`}
												id="manualInput10"
												readOnly
											/>
										</td>
										{/* <td className="px-1 w-1/5 border-b border-grey-mid border-r border-b border-grey-mid">
										<img className="inline" width={"16"} src={edit} alt="" />
									</td> */}
									</tr>
									<tr className="border-b border-r border-grey-mid bg-white mb-0">
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid"
											id="manualTime11">
											00:05:00
										</td>
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid">
											<input type="text"
												value={manualInputValues.input11}
												onChange={(e) => handleInputChange('input11', e.target.value)}
												onFocus={() => handleInputFocus('input11')}
												onBlur={handleInputBlur}
												className={`w-full text-center ${focusedManualInput === 'input11' ? 'focused' : ''}`}
												id="manualInput11"
												readOnly
											/>
										</td>
										{/* <td className="px-1 w-1/5 border-b border-grey-mid border-r border-b border-grey-mid">
										<img className="inline" width={"16"} src={edit} alt="" />
									</td> */}
									</tr>
									<tr className="border-b border-r border-grey-mid bg-white mb-0">
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid"
											id="manualTime12">
											00:06:00
										</td>
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid">
											<input type="text"
												value={manualInputValues.input12}
												onChange={(e) => handleInputChange('input12', e.target.value)}
												onFocus={() => handleInputFocus('input12')}
												onBlur={handleInputBlur}
												className={`w-full text-center ${focusedManualInput === 'input12' ? 'focused' : ''}`}
												id="manualInput12"
												readOnly
											/>
										</td>
										{/* <td className="px-1 w-1/5 border-b border-grey-mid border-r border-b border-grey-mid">
										<img className="inline" width={"16"} src={edit} alt="" />
									</td> */}
									</tr>
									<tr className="border-b border-r border-grey-mid bg-white mb-0">
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid"
											id="manualTime13">
											00:07:00
										</td>
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid">
											<input type="text"
												value={manualInputValues.input13}
												onChange={(e) => handleInputChange('input13', e.target.value)}
												onFocus={() => handleInputFocus('input13')}
												onBlur={handleInputBlur}
												className={`w-full text-center ${focusedManualInput === 'input13' ? 'focused' : ''}`}
												id="manualInput13"
												readOnly
											/>
										</td>
										{/* <td className="px-1 w-1/5 border-b border-grey-mid border-r border-b border-grey-mid">
										<img className="inline" width={"16"} src={edit} alt="" />
									</td> */}
									</tr>
									<tr className="border-b border-r border-grey-mid bg-white mb-0">
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid"
											id="manualTime14">
											00:08:00
										</td>
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid">
											<input type="text"
												value={manualInputValues.input14}
												onChange={(e) => handleInputChange('input14', e.target.value)}
												onFocus={() => handleInputFocus('input14')}
												onBlur={handleInputBlur}
												className={`w-full text-center ${focusedManualInput === 'input14' ? 'focused' : ''}`}
												id="manualInput14"
												readOnly
											/>
										</td>
										{/* <td className="px-1 w-1/5 border-b border-grey-mid border-r border-b border-grey-mid">
										<img className="inline" width={"16"} src={edit} alt="" />
									</td> */}
									</tr>
									<tr className="border-b border-r border-grey-mid bg-white mb-0">
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid"
											id="manualTime15">
											00:09:00
										</td>
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid">
											<input type="text"
												value={manualInputValues.input15}
												onChange={(e) => handleInputChange('input15', e.target.value)}
												onFocus={() => handleInputFocus('input15')}
												onBlur={handleInputBlur}
												className={`w-full text-center ${focusedManualInput === 'input15' ? 'focused' : ''}`}
												id="manualInput15"
												readOnly
											/>
										</td>
										{/* <td className="px-1 w-1/5 border-b border-grey-mid border-r border-b border-grey-mid">
										<img className="inline" width={"16"} src={edit} alt="" />
									</td> */}
									</tr>
									<tr className="border-b border-r border-grey-mid bg-white mb-0">
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid"
											id="manualTime16">
											00:10:00
										</td>
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid">
											<input type="text"
												value={manualInputValues.input16}
												onChange={(e) => handleInputChange('input16', e.target.value)}
												onFocus={() => handleInputFocus('input16')}
												onBlur={handleInputBlur}
												className={`w-full text-center ${focusedManualInput === 'input16' ? 'focused' : ''}`}
												id="manualInput16"
												readOnly
											/>
										</td>
										{/* <td className="px-1 w-1/5 border-b border-grey-mid border-r border-b border-grey-mid">
										<img className="inline" width={"16"} src={edit} alt="" />
									</td> */}
									</tr>
									<tr className="border-b border-r border-grey-mid bg-white mb-0">
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid"
											id="manualTime17">
											00:20:00
										</td>
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid">
											<input type="text"
												value={manualInputValues.input17}
												onChange={(e) => handleInputChange('input17', e.target.value)}
												onFocus={() => handleInputFocus('input17')}
												onBlur={handleInputBlur}
												className={`w-full text-center ${focusedManualInput === 'input17' ? 'focused' : ''}`}
												id="manualInput17"
												readOnly
											/>
										</td>
										{/* <td className="px-1 w-1/5 border-b border-grey-mid border-r border-b border-grey-mid">
										<img className="inline" width={"16"} src={edit} alt="" />
									</td> */}
									</tr>
									<tr className="border-b border-r border-grey-mid bg-white mb-0">
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid"
											id="manualTime18">
											00:30:00
										</td>
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid">
											<input type="text"
												value={manualInputValues.input18}
												onChange={(e) => handleInputChange('input18', e.target.value)}
												onFocus={() => handleInputFocus('input18')}
												onBlur={handleInputBlur}
												className={`w-full text-center ${focusedManualInput === 'input18' ? 'focused' : ''}`}
												id="manualInput18"
												readOnly
											/>
										</td>
										{/* <td className="px-1 w-1/5 border-b border-grey-mid border-r border-b border-grey-mid">
										<img className="inline" width={"16"} src={edit} alt="" />
									</td> */}
									</tr>
									<tr className="border-b border-r border-grey-mid bg-white mb-0">
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid"
											id="manualTime19">
											00:45:00
										</td>
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid">
											<input type="text"
												value={manualInputValues.input19}
												onChange={(e) => handleInputChange('input19', e.target.value)}
												onFocus={() => handleInputFocus('input19')}
												onBlur={handleInputBlur}
												className={`w-full text-center ${focusedManualInput === 'input19' ? 'focused' : ''}`}
												id="manualInput19"
												readOnly
											/>
										</td>
										{/* <td className="px-1 w-1/5 border-b border-grey-mid border-r border-b border-grey-mid">
										<img className="inline" width={"16"} src={edit} alt="" />
									</td> */}
									</tr>
									<tr className="border-b border-r border-grey-mid bg-white mb-0">
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid"
											id="manualTime20">
											01:00:00
										</td>
										<td className="px-1 w-1/2 border-b border-grey-mid border-r border-b border-grey-mid">
											<input type="text"
												value={manualInputValues.input20}
												onChange={() => handleInputFocus('input20')}
												onFocus={() => handleInputFocus('input20')}
												className={`w-full text-center ${focusedManualInput === 'input20' ? 'focused' : ''}`}
												id="manualInput20"
												readOnly
											/>
										</td>
										{/* <td className="px-1 w-1/5 border-b border-grey-mid border-r border-b border-grey-mid">
										<img className="inline" width={"16"} src={edit} alt="" />
									</td> */}
									</tr>
								</tbody>
							</table>
						</div>
					}
					{/* AUTOMATIC */}
					{showAutomaticInput &&
						<div className="bg-white overflow-auto border-t border-grey-mid rounded manualInputHeight">
							{/* <CompactTableEditable header={['Time', 'Depth (m)', 'Edit']} items={headTests} textCSS={['text-base md:text-lg']} /> */}

							{isEditing && (
								<div className="grid grid-cols-2 md:grid-cols-6 border-b border-l border-grey-mid border-r-none mb-2">
									{/* Your editing form or modal here */}

									<div className="col-span-1 md:col-span-1 pl-0 md:pl-1">
										<button className="text-center font-bold text-sm md:text-base bg-grey-mid text-charcoal w-full h-8 md:h-full px-3" 
											onClick={() => handleDelete(editedRowIndex)}>
											X
										</button>
									</div>

									{editedData.map((value, index) => {
										if (index === 0) {
											// Render TimeInput for index 0
											return (
												<div className="col-span-1 md:col-span-2 text-center font-bold border-grey-mid border-r w-full bg-white">
													<div className="py-0 h-3" style={{ fontSize: '0.6rem' }}>
														Time:
													</div>
													<div className="py-1 md:pt-0.5 h-7 md:h-10 text-sm md:text-3xl">
														{value}
													</div>
												</div>
											);
										} else {
											// Render regular input for other indices (depth)
											return (
												<div className="col-span-1 md:col-span-2">
													<div className="py-0 text-center"  style={{ fontSize: '0.6rem' }}>
														Depth:
													</div>
													<div className=" h-7 md:h-10">
														<input
															key={index}
															type="text"
															value={String(value)} // Convert value to string
															onChange={(e) => {
																const updatedData = [...editedData];
																updatedData[index] = e.target.value;
																setEditedData(updatedData);
															}}
															onFocus={() => setFocusedInput(1)}
															className={`text-base md:text-3xl text-center font-bold w-full ${focusedInput === 1 ? 'focused' : ''}`}
														/>
													</div>
												</div>
											);
										}
									})}
									<div className="col-span-1 md:col-span-1 pl-0 md:pl-1">
										<button className="text-center font-bold text-base md:text-lg bg-green-button text-white w-full h-8 md:h-full px-3" onClick={handleSave}>Save</button>
									</div>

									
								
								</div>
							)}


							<table className="table-auto w-full text-xs text-center border-grey-mid rounded border-separate">
								<thead className="font-bold">
									<tr>
										<td className="px-1 py-0 bg-sptTB border border-grey-mid rounded-tl w-2/5 text-base text-center">Time</td>
										<td className="px-1 py-0 bg-sptTB border border-grey-mid rounded-tr border-grey-light w-2/5 text-base text-center">(m)</td>
										{/* <td className="px-1 py-0 bg-sptTB border-b border-grey-mid rounded-tr border-r border-grey-light w-1/5"></td> */}
									</tr>
								</thead>

								<tbody>
									{/* Map over headTests and render each row */}
									{headTests.map((entry, index) => (
											<tr className="border-b border-r border-grey-mid bg-white mb-0 text-base md:text-2xl" 
											key={index}
											onClick={() => handleEdit(index)}>
													<td className="px-1 w-2/5 border-l border-b border-r border-grey-mid">
														{entry.time}
													</td>
													<td className="border-b border-r border-grey-mid w-2/5">
														{parseFloat(entry.depth).toFixed(2)}
													</td>
													{/* <td className="border-r border-l border-b border-grey-mid w-1/5">
														<button onClick={() => handleEdit(index)}>
															<img className="inline" width={"16"} src={edit} alt="" />
														</button>
													</td> */}
											</tr>
									))}

									<tr>
										<td colSpan={2} className="w-full text-grey-dark text-center pt-1"
										style={{ fontSize: '0.6rem' }}>
											{autoVisualArray && autoVisualArray.length > 0 &&
											<>
												(click to edit)
											</>
											}
										</td>
									</tr>

								</tbody>
							</table>

						</div>
					}
				</div>


				{/*****************************
				*
				VISUAL CHART 
				*
				******************************/}

				<div className="col-span-1 md:px-6 manualInputHeight">

					{(showAutomaticInput && showDepthVisual) && (  // autoVisualArray // DEPTH
						<>
							<div onClick={toggleVisualMode}
								className="text-xs md:text-lg font-bold text-center -mt-7 md:-mt-10 pb-3">
								(m)
							</div>
							<div className="border-l border-r border-b border-blue rounded-b w-full h-full relative" style={{ fontSize: '9px' }}>


								{autoVisualArray.map((depth, index) => (
									depth !== null && (
										<div key={index} className="border-b border-blue w-full h-full"
											style={{ position: 'absolute', top: '0', left: '0', height: `${(depth / totalDepth) * 100}%` }}>
											<div className="text-center absolute px-0.5 w-full" style={{ bottom: '5px', transform: 'translateY(50%)' }}>
												{depth.toFixed(2)}
											</div>
										</div>
									)
								))}



								{/* Additional div for overall depth */}
								<div className="bg-blue text-center absolute rounded-b w-full pb-2 text-white" style={{ position: 'absolute', bottom: '0', height: `${((totalDepth - (maxDepthAuto !== null ? maxDepthAuto : 0)) / totalDepth) * 100}%` }}>
									<div className="w-full text-center" style={{ position: 'absolute', bottom: '0' }}>
										{totalDepth.toFixed(2)}
									</div>
								</div>
							</div>
						</>
					)}

					{(showAutomaticInput && !showDepthVisual) && (  // autoVisualArray // TIME
						<>
							<div onClick={toggleVisualMode}
								className="text-xs md:text-lg font-bold text-center -mt-7 md:-mt-10 pb-3">
								(tm)
							</div>
							<div className="border-l border-r border-b border-blue rounded-b w-full h-full relative" style={{ fontSize: '9px' }}>


								{autoVisualCombinedArray.map(({ depth, time }, index) => (
									depth !== null && (
										<div key={index} className="border-b border-blue w-full h-full"
											style={{ position: 'absolute', top: '0', left: '0', height: `${(depth / totalDepth) * 100}%` }}>
											<div className="text-center absolute px-0.5 w-full" style={{ bottom: '5px', transform: 'translateY(50%)' }}>
												{time.substring(3)}
											</div>
										</div>
									)
								))}



								{/* Additional div for overall depth */}
								<div className="bg-blue text-center absolute rounded-b w-full pb-2 text-white" style={{ position: 'absolute', bottom: '0', height: `${((totalDepth - (maxDepthAuto !== null ? maxDepthAuto : 0)) / totalDepth) * 100}%` }}>
									<div className="w-full text-center" style={{ position: 'absolute', bottom: '0' }}>
										{totalDepth.toFixed(2)}
									</div>
								</div>
							</div>
						</>
					)}




					{(showManualInput && showDepthVisual) && (  // autoVisualArray // DEPTH
						<>
							<div onClick={toggleVisualMode}
								className="text-xs md:text-lg font-bold text-center -mt-4 md:-mt-7">
								(m)
							</div>
							<div className="border-l border-r border-b border-blue rounded-b w-full h-full relative" style={{ fontSize: '9px' }}>


								{manualVisualArray.map((depth, index) => (
									depth !== null && (
										<div key={index} className="border-b border-blue w-full h-full"
											style={{ position: 'absolute', top: '0', left: '0', height: `${(depth / totalDepth) * 100}%` }}>
											<div className="text-center absolute px-0.5 w-full" style={{ bottom: '5px', transform: 'translateY(50%)' }}>
												{depth.toFixed(2)}
											</div>
										</div>
									)
								))}



								{/* Additional div for overall depth */}
								<div className="bg-blue text-center absolute rounded-b w-full pb-2 text-white" style={{ position: 'absolute', bottom: '0', height: `${((totalDepth - (maxDepthManual !== null ? maxDepthManual : 0)) / totalDepth) * 100}%` }}>
									<div className="w-full text-center" style={{ position: 'absolute', bottom: '0' }}>
										{totalDepth.toFixed(2)}
									</div>
								</div>
							</div>
						</>
					)}

					{(showManualInput && !showDepthVisual) && (  // autoVisualArray // TIME
						<>
							<div onClick={toggleVisualMode}
								className="text-xs md:text-lg font-bold text-center -mt-4 md:-mt-7">
								(time)
							</div>
							<div className="border-l border-r border-b border-blue rounded-b w-full h-full relative" style={{ fontSize: '9px' }}>


								{manualVisualCombinedArray.map(({ depth, time }, index) => (
									depth !== null && (
										<div key={index} className="border-b border-blue w-full h-full"
											style={{ position: 'absolute', top: '0', left: '0', height: `${(depth / totalDepth) * 100}%` }}>
											<div className="text-center absolute px-0.5 w-full" style={{ bottom: '5px', transform: 'translateY(50%)' }}>
												{time}
											</div>
										</div>
									)
								))}



								{/* Additional div for overall depth */}
								<div className="bg-blue text-center absolute rounded-b w-full pb-2 text-white" style={{ position: 'absolute', bottom: '0', height: `${((totalDepth - (maxDepthManual !== null ? maxDepthManual : 0)) / totalDepth) * 100}%` }}>
									<div className="w-full text-center" style={{ position: 'absolute', bottom: '0' }}>
										{totalDepth.toFixed(2)}
									</div>
								</div>
							</div>
						</>
					)}



				</div>



				<div className="col-span-6 bg-sptTB px-2 rounded manualInputHeight">
					<div className="h-36 md:h-32">

						{showAutomaticInputButtons &&
							<div className="flex-none md:flex mb-2 md:mb-4">
								<div className="mb-2 text-center mr-0 md:mr-2 w-full md:w-2/4">
									<h3 className="font-bold text-base md:text-lg mb-1">Enter Depth (m)</h3>

									<input type="number"
										className="form-control-number w-full hidden"
										value={depthAtThisTime}
										onChange={(value: any) => setDepthAtThisTime(value.target.value)}
										placeholder="0"
										id="inputElement"
									/>

									<div contentEditable
										className="border border-orange rounded text-center text-5xl pt-1.5 bg-white h-16 mx-auto w-full mb-2 md:mb-4"
										// onChange={handleDivChange}
										suppressContentEditableWarning={true}
										id="divElement"
									>
										{value ? value : ''}
									</div>

								</div>

								<div className="w-full md:w-2/4 mt-0 md:mt-7 pt-0 md:pt-1">
									<Button theme="primary" size="lg" className="px-0 pt-1.5 md:pt-3.5 pb-2 md:pb-4 w-full text-sm md:text-2xl" onClick={() => addDepthAtThisTime()}>Add depth at this time</Button>
								</div>
							</div>
						}

					</div>

					<div className="grid grid-cols-3 gap-1 lg:gap-2 mt-0 md:mt-6 mb-6">  {/* NUMBER BUTTONS */}
						{keys.map((key, index) => {
							return <div className="border border-orange rounded py-2 md:py-4 lg:py-2 text-2xl md:text-4xl text-center bg-white"
								key={index}
								onClick={() => addCharacter(key)}
							>{key}</div>
						})}

						<div className="border border-orange rounded py-3 text-base md:text-xl text-center bg-white" onClick={() => removeCharacter()}>DEL</div>
					</div>

					<div className="justify-right">

						<UpdateButton />

						<SaveButton />

					</div>

				</div>
			</div>


			{deleteConfirmOpen &&
				<div className="fixed inset-0 z-50">
					<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">


						<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
							Are you sure you want to delete all data in this run?

							<div className="flex mt-4">
								<Button theme="secondary"
									className="mr-5 text-sm sm:text-base px-3.5 md:px-7"
									onClick={() => setDeleteConfirmOpen(false)}>
									Cancel
								</Button>

								<Button onClick={() => {
									onClose()
									// onComplete({
									// 	guid: guid,
									// 	update: false,
									// 	delete: true,
									// })
									if (run === 1) {
										setShowRun1Edit(false)
									} else if (run === 2) {
										setShowRun2Edit(false)
									} else if (run === 3) {
										setShowRun3Edit(false)
									}
								}}
									className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">
									Delete
								</Button>
							</div>
						</div>


					</div>
				</div>
			}

			<div className="flex mt-4">

				{/* <Button theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button> */}

				<DeleteButton />

				{/* <UpdateButton />

				<SaveButton /> */}

				{/* <div className="flex ml-auto flex">
					<Button theme="primary" size="lg" className="ml-4" onClick={() => save()}>Save &amp; Exit</Button>
				</div> */}
			</div>

		</Modal>
	)
}

HeadTest.defaultProps = defaultProps

export default HeadTest