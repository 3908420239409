import React, { FunctionComponent, useState, useEffect } from 'react'

import Modal from '../components/Modal'
import PrintLabelComponent from '../components/PrintLabel'

type Props = {
	onClose: any,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => {},
	onChange: () => {},
}



const PrintLabel: FunctionComponent<Props> = ({ onClose, borehole, sample, depth, sampled_by, ...rest }) => {

	const boreholeVar = borehole
	const sampleVar = sample
	const depthVar = depth
	const sampler = sampled_by

	return (
		<>
		<div className="-mt-10">
			<Modal title='Print Label' subtitle='' toggleModal={onClose} confirm={false}  >

				<div className="z-50 flex flex-col flex-grow px-1 sm:px-2 md:px-10">		
					<PrintLabelComponent 
						borehole={boreholeVar} 
						sample={sampleVar} 
						depth={depthVar}
						size='printLabel'
						title=""
						sampled_by={sampler}
						/>
				</div>

			</Modal>
		</div>
		</>
	)
}

PrintLabel.defaultProps = defaultProps

export default PrintLabel