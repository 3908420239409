import { get } from 'idb-keyval'

export const getUserDetails = (callback: any): string => {
    get('drillit-user')
    .then(response => {
        let userString = response
        if (userString) {
            callback(userString)
        }
        console.log('user userString:', userString)
    })
    return ''
}

export const fullName = (firstName: string, lastName: string): string => {
    return firstName + ' ' + lastName
}
