import React, { useEffect, useState } from 'react';
import Modal from '../components/Modal'
import closeIcon from '../assets/icons/close.svg'
import { get, entries, keys, clear, del } from 'idb-keyval'
import Button from '../components/form/Button'

interface DataType {
    id: number; // Adjust the type according to your actual data structure
    // Add other properties as needed
}

function SystemInformation() {
    const [data, setData] = useState<DataType[] | null>(null);
    const [objectStoreNames, setObjectStoreNames] = useState<string[]>([]);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)
    const [recordToDelete, setRecordToDelete] = useState<string | null>(null);

    const [isIdbModalOpen, setIsIdbModalOpen] = useState(false);
    // Define a type for the JSX elements array
    type JSXElementArray = JSX.Element[];

    // Inside your component function...
    const [idbModalContent, setIdbModalContent] = useState<JSXElementArray>([]);

    const table = document.createElement('table');
    table.classList.add('idb-entry-table'); // Add a CSS class for styling

    useEffect(() => {
        // Open connection to the IndexedDB database
        const request = indexedDB.open('drillit');

        get('drillit-currentRig').then((val) => console.log(val));
        entries().then((entries) => console.log(entries));
    }, []);

    // entries().then((entries) => {
    //     const container = document.getElementById('data-container'); // HTML to display the data
    //     if (!container) return; // Check if the container element exists

    //     // Check if the container already contains elements
    //     if (container.childElementCount === 0) {
    //         // If the container is empty, add the titles
    //         entries.forEach(([key, value]) => {
    //             // Filter out entries starting with 'drillit-action'
    //             const keyString = String(key);
    //             if (
    //                 !keyString.startsWith('drillit-action') &&
    //                 !keyString.startsWith('drillit-hole-types') &&
    //                 keyString !== 'drillit-user'
    //             ) {
    //                 const entryContainer = document.createElement('div'); // Create a new div element for each entry
    //                 const titleElement = document.createElement('span'); // Create a span element for the title
    //                 titleElement.textContent = String(key); // Convert the key to a string before setting the text content
    //                 const deleteButton = document.createElement('button'); // Create a delete button
    //                 deleteButton.textContent = ' - Delete'; // Set the text content of the delete button

    //                 // deleteButton.addEventListener('click', () => {
    //                 //     // Handle delete operation when the button is clicked
    //                 //     del(key).then(() => {
    //                 //         // Remove the entry from the container
    //                 //         entryContainer.remove();
    //                 //     });
    //                 // });
        
    //                 // deleteButton.addEventListener('click', () => {
    //                 //     // Open the delete confirm dialog when the button is clicked
    //                 //     setDeleteConfirmOpen(true);

    //                 //     // Set up the action for the confirm delete button
    //                 //     const confirmDeleteButton = document.getElementById('confirm-delete-button');
    //                 //     confirmDeleteButton.onclick = () => {
    //                 //         // Perform delete operation
    //                 //         del(key).then(() => {
    //                 //             // Remove the entry from the container
    //                 //             entryContainer.remove();
    //                 //             // Close the delete confirm dialog
    //                 //             setDeleteConfirmOpen(false);
    //                 //         });
    //                 //     };
    //                 // });

    //                 entryContainer.appendChild(titleElement); // Append the title element to the entry container
    //                 entryContainer.appendChild(deleteButton); // Append the delete button to the entry container
    //                 container.appendChild(entryContainer); // Append the entry container to the main container
    //             }
    //         });
    //     }
    // });

    useEffect(() => {
        entries().then((entries) => {
            const container = document.getElementById('data-container');
            if (!container) return;
    
            if (container.childElementCount === 0) {
                // Create a table element to hold the entries
                const table = document.createElement('table');
                table.classList.add('idb-entry-table'); // Add a CSS class for styling
    
                // Iterate through the entries and create table rows for each entry
                entries.forEach(([key, value]) => {
                    const keyString = String(key);
                    if (
                        // !keyString.startsWith('drillit-action') &&
                        !keyString.startsWith('drillit-hole-types') &&
                        keyString !== 'drillit-user'
                    ) {
                        // Create a table row for the entry
                        const row = document.createElement('tr');
    
                        // Create a cell for the title
                        const titleCell = document.createElement('td');
                        titleCell.textContent = String(key);

                        // Attach a click event listener to the title cell
                        titleCell.addEventListener('click', () => {
                            // Get the corresponding entry content
                            const entryContent = value; // Assuming 'value' contains the entry content

                            // Open the modal and set the content
                            setIsIdbModalOpen(true);
                            setIdbModalContent(renderContent(entryContent));
                        });
    
                        // Create a cell for the delete button
                        const deleteCell = document.createElement('td');
                        const deleteButton = document.createElement('button');
                        deleteButton.textContent = 'Delete';
                        deleteButton.addEventListener('click', () => {
                            // Call the handleDelete function to handle the delete operation
                            handleDelete(key, row); // Pass the table row for removal
                        });
                        deleteCell.appendChild(deleteButton);
    
                        // Append cells to the row
                        row.appendChild(titleCell);
                        row.appendChild(deleteCell);
    
                        // Append the row to the table
                        table.appendChild(row);
                    }
                });
    
                // Append the table to the container
                container.appendChild(table);
            }
        });
    }, []); // Empty dependency array to run this effect only once
    

    const handleDelete = (key: any, entryContainer: any) => {
        // Open the delete confirm dialog when the button is clicked
        setDeleteConfirmOpen(true);
    
        // Set up the action for the confirm delete button
        const confirmDeleteButton = document.getElementById('confirm-delete-button');
        if (confirmDeleteButton) { // Check if the element exists
            confirmDeleteButton.onclick = () => {
                // Perform delete operation
                del(key).then(() => {
                    // Remove the entry from the container
                    entryContainer.remove();
                    // Close the delete confirm dialog
                    setDeleteConfirmOpen(false);
                });
            };
        }
    };

    // Function to recursively render the content
    const renderContent = (content: any): JSX.Element[] => {
        if (!content || typeof content !== 'object') {
            return [];
        }
    
        return Object.entries(content).map(([key, val]) => (
            <div key={key}>
                <strong>{key}:</strong> {typeof val === 'object' ? renderContent(val) : val}
            </div>
        ));
    };



    return (
        <>

            <div className="fixed inset-0 px-1 sm:px-4 md:px-8 py-2 sm:py-4 z-40 overflow-y-auto" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                <div className={`flex flex-col rounded-lg border border-orange  px-2 sm:px-2 md:px-2 pb-2 min-h-full modalshadow`} style={{ background: '#FBFCFC' }}>
                    <div className="flex items-center border-b border-grey-mid py-1">

                        <div className="flex-none flex items-left w-8">
                            <div className="flex-none">

                                {/* <span className="mr-auto">{subtitle}</span> */}
                                <div className="flex-none text-base font-bold text-center">
                                    {/* {subtitle} */}
                                </div>

                                <div className="flex-none">

                                </div>

                            </div>
                        </div>

                        <div className="flex-1 flex justify-center text-lg md:text-xl font-bold text-text leading-6 text-center mx-auto">
                            System Information
                        </div>

                        <div className="flex-none flex justify-center">
                            <span className="">
                                <button className="flex items-center justify-center min-w-9 h-8 text-4xl bold text-soil-grey bg-white border border-orange rounded"
                                    onClick={() => window.history.back()}
                                >
                                    <img className="w-8 h-8" src={closeIcon} alt="" />
                                </button>
                            </span>
                        </div>
                    </div>

                    <div className="w-full text-center font-bold text-base mt-4">
                        THIS IS FOR DRILLIT LOGS ADMIN ONLY WHILST TESTING.
                        <br/>
                        DO NOT FIDDLE
                    </div>

                    <div className="flex flex-col mt-2 flex-grow p-4">
                        <div id="data-container" className="text-sm"></div>
                    </div>

                    {deleteConfirmOpen &&
                        <div className="fixed inset-0 z-50">
                            <div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">


                                <div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
                                    Are you sure you want to delete this record?

                                    <div className="flex mt-4">
                                        <Button theme="secondary"
                                            className="mr-5 text-sm sm:text-base px-3.5 md:px-7"
                                            onClick={() => setDeleteConfirmOpen(false)}>
                                            Cancel
                                        </Button>

                                        <Button id="confirm-delete-button" className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">
                                            Delete
                                        </Button>
                                    </div>
                                </div>


                            </div>
                        </div>
                    }

                    {isIdbModalOpen &&
                        <div className="fixed inset-0 z-50 w-full">
                            <div className="flex items-center justify-center min-h-full w-11/12 sm:w-10/12 md:w-8/12 lg:w-8/12 mx-auto">


                                <div className="bg-white rounded-lg border-2 border-charcoal px-4 p-2 text-center modalshadow w-full">

                                <div className="flex mb-1 w-full justify-end">
                                    <Button theme="secondary"
                                        className="mr-0 text-sm sm:text-sm px-3.5 md:px-7 rounded"
                                        onClick={() => setIsIdbModalOpen(false)}>
                                        Close
                                    </Button>
                                </div>
                                
                                <div className="text-left text-sm w-full max-h-[200px] overflow-auto">
                                    {idbModalContent}
                                </div>
                                    
                                </div>


                            </div>
                        </div>
                    }

                </div>
            </div>
        </>
    );
}

export default SystemInformation;