import React, { FunctionComponent, useState } from 'react'
import { useSelector } from 'react-redux'

import WorkflowSteps from '../../WorkflowSteps'
import Modal from '../../Modal'
import Button from '../../form/Button'
import SoilDescriptionGenerator from './SoilDescriptionGenerator'
import NumberInput from '../../NumberInput'
import { v4 as uuidv4 } from 'uuid';

import stepStatus from '../../../helpers/stepStatus'
import warningIcon from '../../../assets/icons/warning.png'

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: string,
	defaultValues?: any,
	selectedDate?: string,
	update?: boolean,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => { },
	onComplete: () => { },
	borehole: '',
	defaultValues: {
		guid: '',
		depth_to_top: '',
		depth_to_base: '',
		stratum_description: '',
		stratum_type: '',
	},
	selectedDate: '',
}

const SoilDescription: FunctionComponent<Props> = ({ onClose, update, onComplete, borehole, defaultValues, selectedDate, 
		lastSoilDescriptionDepth, lastSoilDescriptionDepthToTop, lastSoilDescriptionDepthToBase, lastSoilDescriptionStratum,  ...rest }) => {
	const [currentStep, setCurrentStep] = useState(1)

	const [guid, setGuid] = useState(defaultValues.guid)
	const [depth_to_top, setDepthFrom] = useState(defaultValues.depth_to_top)
	const [depth_to_base, setDepthTo] = useState(defaultValues.depth_to_base)
	const [stratum_description, setDescription] = useState(defaultValues.stratum_description)
	const [stratum_type, setStratum_type] = useState(defaultValues.stratum_type)

	const [lastSoilDesc_Top, setLastSoilDesc_Top] = useState(lastSoilDescriptionDepthToTop)
	const [lastSoilDesc_Base, setLastSoilDesc_Base] = useState(lastSoilDescriptionDepthToBase)
	const [lastSoilDesc_Stratum, setLastSoilDesc_Stratum] = useState(lastSoilDescriptionStratum)
	// lastSoilDescriptionDepthToTop={maxSoilDescriptionTop}
	// lastSoilDescriptionDepthToBase={latestSoilDescriptionDepthToBase}
	// lastSoilDescriptionStratum={maxSoilDescriptionStratum}

	// console.log('**************************Inside Soil Description Panel*****************************')
	// console.log('id: ', defaultValues.id)
	// console.log('guid: ', defaultValues.guid, guid)
	// console.log('depth_to_top: ', defaultValues.depth_to_top, depth_to_top, typeof depth_to_top)
	// console.log('depth_to_base: ', defaultValues.depth_to_base, depth_to_base, typeof depth_to_base)
	// console.log('stratum_description: ', stratum_description)
	// console.log('************************************************************************************')
	
	// console.log('lastSoilDescriptionDepth: ', lastSoilDescriptionDepth)
	let last_soil_description_depth
	if(lastSoilDescriptionDepth===0){
		last_soil_description_depth = 'GL'
	} else {
		last_soil_description_depth = lastSoilDescriptionDepth
	}
	// const last_soil_description_depth = lastSoilDescriptionDepth

	const soilDescriptionLength = useSelector((state: any) => state.soilDescriptionLength)

	const [time_of_action, setTimeOfAction] = useState(defaultValues.time_of_action)

	const isoDate = selectedDate ? `${new Date(selectedDate).toISOString().split('T')[0]} 09:00:00` : new Date().toISOString().split('.')[0]

	const previous = () => {
		if (currentStep > 1)
			setCurrentStep(currentStep - 1)
	}

	const next = () => {
		const compSoilDescriptionLength = (soilDescriptionLength === 1 ? Infinity : soilDescriptionLength)

		if (currentStep < compSoilDescriptionLength) {
			setCurrentStep(currentStep + 1)
			return
		}

		complete()
	}

	const complete = () => {

		if (depth_to_top === '' || !depth_to_base || stratum_description === '. ') {
			//do nothing
		} else {
			
			onComplete({
				guid: update ? guid : uuidv4(),
				depth_to_top: depth_to_top2DP, //2DP,
				depth_to_base: depth_to_base2DP, //2DP,
				stratum_description: stratum_description,
				stratum_type: stratum_type_in_string,
				general_remarks: '',
				time_of_action: time_of_action ? time_of_action : isoDate,
				update: update ? true : false,
				// userActivity: 'Drilling',
			})
		}
	}

	let requiredFromText = ''
	let requiredToText = ''
	let requiredDescriptionText = ''

	if (depth_to_top==='') {
		requiredFromText = "'Depth From' - "
	}

	if (!depth_to_base) {
		requiredToText = "'Depth To' - "
	}

	if (stratum_description === '. ') {
		requiredDescriptionText = "'Description'"
	}

	const MissingRequiredField = () => {
		if (currentStep === 3 && (depth_to_top==='' || !depth_to_base || stratum_description === '. ')) return (
			<div className="text-center bg-sptSB border border-red font-bold rounded mb-2 py-1 text-xs md:text-sm lg:text-base">
				<img src={warningIcon} alt="info" width="20" className="inline-block mr-1 pb-1" />
				Required field: {requiredFromText} {requiredToText} {requiredDescriptionText}
			</div>
		)

		return null
	}

	const PreviousDescription = () => {
		if (lastSoilDesc_Base) return (
			<div className="-mt-2 mb-2 bg-grey-light pl-4 py-1 text-xs rounded">
				<span className="font-bold text-blue">Last Soil Description: </span>&nbsp;&nbsp;|&nbsp;&nbsp;
				<span className="font-bold">From: </span>{lastSoilDesc_Top}m&nbsp;&nbsp;|&nbsp;&nbsp;
				<span className="font-bold">To: </span>{lastSoilDesc_Base}m&nbsp;&nbsp;|&nbsp;&nbsp;
				<span className="font-bold">Description: </span>{lastSoilDesc_Stratum}
			</div>
		)

		return null
	}

	// get stratum TYPE
	let stratum_type_in_string = ''
	if (stratum_description.indexOf('SAND') !== -1) { stratum_type_in_string = 'SAND' }
	if (stratum_description.indexOf('GRAVEL') !== -1) { stratum_type_in_string = 'GRAVEL' }
	if (stratum_description.indexOf('SANDGRAVEL') !== -1) { stratum_type_in_string = 'SANDGRAVEL' }
	if (stratum_description.indexOf('CLAY') !== -1) { stratum_type_in_string = 'CLAY' }
	if (stratum_description.indexOf('SILT') !== -1) { stratum_type_in_string = 'SILT' }
	if (stratum_description.indexOf('PEAT') !== -1) { stratum_type_in_string = 'PEAT' }
	if (stratum_description.indexOf('CHALK') !== -1) { stratum_type_in_string = 'CHALK' }
	if (stratum_description.indexOf('MADEGROUND') !== -1) { stratum_type_in_string = 'MADEGROUND' }
	if (stratum_description.indexOf('LIMESTONE') !== -1) { stratum_type_in_string = 'LIMESTONE' }
	if (stratum_description.indexOf('DOLOMITE') !== -1) { stratum_type_in_string = 'DOLOMITE' }
	if (stratum_description.indexOf('SANDSTONE') !== -1) { stratum_type_in_string = 'SANDSTONE' }
	if (stratum_description.indexOf('SILTSTONE') !== -1) { stratum_type_in_string = 'SILTSTONE' }
	if (stratum_description.indexOf('GYPSUM') !== -1) { stratum_type_in_string = 'GYPSUM' }
	if (stratum_description.indexOf('MUDSTONE') !== -1) { stratum_type_in_string = 'MUDSTONE' }
	if (stratum_description.indexOf('CHERT') !== -1) { stratum_type_in_string = 'CHERT' }
	if (stratum_description.indexOf('SHALE') !== -1) { stratum_type_in_string = 'SHALE' }
	if (stratum_description.indexOf('GRANITE') !== -1) { stratum_type_in_string = 'GRANITE' }
	if (stratum_description.indexOf('DIORITE') !== -1) { stratum_type_in_string = 'DIORITE' }
	if (stratum_description.indexOf('DOLERITE') !== -1) { stratum_type_in_string = 'DOLERITE' }
	if (stratum_description.indexOf('RHYOLITE') !== -1) { stratum_type_in_string = 'RHYOLITE' }
	if (stratum_description.indexOf('ANDERSITE') !== -1) { stratum_type_in_string = 'ANDERSITE' }
	if (stratum_description.indexOf('BASALT') !== -1) { stratum_type_in_string = 'BASALT' }
	if (stratum_description.indexOf('OBSIDIAN') !== -1) { stratum_type_in_string = 'OBSIDIAN' }
	if (stratum_description.indexOf('MARBLE') !== -1) { stratum_type_in_string = 'MARBLE' }
	if (stratum_description.indexOf('QUARTZITE') !== -1) { stratum_type_in_string = 'QUARTZITE' }
	if (stratum_description.indexOf('SLATE') !== -1) { stratum_type_in_string = 'SLATE' }
	if (stratum_description.indexOf('COAL') !== -1) { stratum_type_in_string = 'COAL' }
	if (stratum_description.indexOf('TOPSOIL') !== -1) { stratum_type_in_string = 'TOPSOIL' }

	// console.log('Variables', depth_to_top, depth_to_base, stratum_description, stratum_type_in_string)

	// if(depth_to_top == 'GL'){
	// 	let depth_to_top2DP = 'GL'
	// }else{
	// 	depth_to_top2DP = (Math.round(depth_to_top)).toFixed(2)
	// }

	let depth_to_top2DP = ''

	if (depth_to_top === '') { depth_to_top2DP = '' }
	else if (depth_to_top === 'GL' || depth_to_top === '0.00') { depth_to_top2DP = '0.00' }
	else { depth_to_top2DP = parseFloat(depth_to_top).toFixed(2) }

	// console.log(depth_to_top2DP)

	var depth_to_base2DP = parseFloat(depth_to_base).toFixed(2)
	// console.log(depth_to_base2DP)

	let lastSoilDescriptionDepthPreset
	let lastSoilDescriptionDepthPresetTitle
	if (last_soil_description_depth === 0 || last_soil_description_depth === 'GL') {
		lastSoilDescriptionDepthPreset = 'GL'
		lastSoilDescriptionDepthPresetTitle = 0
	} else {
		lastSoilDescriptionDepthPreset = parseFloat(last_soil_description_depth).toFixed(2)
		lastSoilDescriptionDepthPresetTitle = parseFloat(last_soil_description_depth).toFixed(2)
	}

	// console.log('Last Soil Desc Depth: ', last_soil_description_depth)
	// console.log('lastSoilDescriptionDepthPreset: ', lastSoilDescriptionDepthPreset)

	// last_soil_description_depth = defaultValues.lastSoilDescriptionDepth



	return (
		<Modal title="Soil Description" subtitle={borehole} toggleModal={onClose}>
			<div className="flex flex-col flex-grow px-1 sm:px-2 md:px-10">
				<WorkflowSteps steps={[
					{
						label: 'Depth From',
						status: stepStatus(1, currentStep),
						onClick: () => setCurrentStep(1),
					}, {
						label: 'Depth To',
						status: stepStatus(2, currentStep),
						onClick: () => setCurrentStep(2),
					}, {
						label: 'Description',
						status: stepStatus(3, currentStep),
						onClick: () => setCurrentStep(3),
					},
				]} className="mb-2" />

				<PreviousDescription/>

				<div className="flex-1">
					{currentStep === 1 &&
						<NumberInput
							title="Depth From (m)" 
							onUpdate={(value: any) => setDepthFrom(value)}
							defaultValue={depth_to_top2DP}
							className="mb-10 text-2xl"
							presets={[
								{ value: lastSoilDescriptionDepthPresetTitle, label: lastSoilDescriptionDepthPreset },
							]}
							next={next}
						/>
					}

					{currentStep === 2 &&
						<NumberInput 
							title="Depth To (m)" 
							onUpdate={(value: any) => setDepthTo(value)} 
							defaultValue={depth_to_base} 
							className="mb-10" 
						/>
					}

					{currentStep > 2 &&
						<SoilDescriptionGenerator 
						onChange={(value: any) => setDescription(value)} startingStep={3} step={currentStep} />
					}

					{/* <div className="w-full h-10 z-40 fixed top-40" onClick={() => next()}></div> */}

				</div>

				<MissingRequiredField />



				<div className="flex">
					{/* <Button theme="primary" size="lg" onClick={() => complete()}>Save &amp; Exit</Button> */}
					{/* <Button theme="secondary" size="lg" className="px-2 sm:px-10">Delete</Button> */}

					<div className="flex ml-auto">
						<Button theme="primary" size="lg" className="px-4 sm:px-10" onClick={() => previous()}>Back</Button>
						<Button theme="primary" size="lg" className="ml-4 px-8 sm:px-10" onClick={() => next()}>Continue</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

SoilDescription.defaultProps = defaultProps

export default SoilDescription
