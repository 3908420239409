// import React, { useRef } from 'react'

import Barcode from 'react-jsbarcode'
// import Button from './form/Button'
// import NumberIncrement from './form/NumberIncrement'
import TopImage from '../assets/labelTop.jpg'

// import ReactToPrint from 'react-to-print'
import { useSelector } from 'react-redux'
import { useGetLoggedInUser } from '../helpers/getLoggedInUser'

function Label(props: any) {

    const selectedProject = useSelector((state: any) => state.selectedProject)

    const loggedInUser = useGetLoggedInUser()

    const date = useSelector((state: any) => state.date)
    var labelDate = date.toLocaleDateString("en-UK")

    let projectLocation = selectedProject.location
    let projectID = selectedProject.number
    let boreholeName = props.borehole
    let sampleID = props.sample
    let sampleDepth = props.depth
    let user = props.user

    // console.log('boreholeName: ', boreholeName)

    projectLocation = projectLocation.toUpperCase();
    boreholeName = boreholeName.toUpperCase();

    projectLocation = projectLocation.replace(/[`¬!"£$%^&*()_\-+=<>,./|?#~;:[\]{}]/g, "")

    //label barcode
    // var labelbarcode = projectLocation + '-' + boreholeName + '-' + sampleID;
    var labelbarcode = projectID + '-' + boreholeName + '-' + sampleID;

    labelbarcode = labelbarcode.replaceAll(' ', '');

    const barcodeWidths = [
        3.3, 3.3, 3.3, 3.3, 3.3, 3.3, 3.0, 2.7, 2.5, 2.3, // 1 to 10
        2.1, 2.0, 1.85, 1.75, 1.65, 1.55, 1.5, 1.42, 1.36, 1.3, // 11 to 20
        1.25, 1.2, 1.15, 1.1, 1.05, 1.0, 1.0, 0.95, 0.93, 0.90, // 21 to 30
        0.88, 0.85, 0.83, 0.80, 0.80, 0.76, 0.75, 0.73, 0.72, 0.71 // 31 to 40
    ];

    var barcodeWidth = barcodeWidths[labelbarcode.length - 1]

    // var labelAreaClass = "labelArea " + (props.size)
    var labelType, defaultLabelType, defaultPrint

    // get default print values from somewhere
    // temp values: defaultPrint

    var sampleInitial = sampleID.charAt(0);
    if (sampleInitial === 'D') { defaultLabelType = "D"; labelType = 'general'; defaultPrint = 99; } // copiesD
    else if (sampleInitial === 'B') { defaultLabelType = "B"; labelType = 'general'; defaultPrint = 2; } // copiesB
    else if (sampleInitial === 'E') { defaultLabelType = "ES"; labelType = 'general'; defaultPrint = 3; } // copiesES
    else if (sampleInitial === 'W') { defaultLabelType = "WS"; labelType = 'general'; defaultPrint = 4; } // copiesWS
    else if (sampleInitial === 'L') { defaultLabelType = "LINER"; labelType = 'topImage'; defaultPrint = 4; } // copiesL
    else if (sampleInitial === 'U') { defaultLabelType = "U"; labelType = 'topImage'; defaultPrint = 5; } // copiesU
    else if (sampleInitial === 'P') { defaultLabelType = "PS"; labelType = 'topImage'; defaultPrint = 5; } // copiesPS
    else if (sampleInitial === 'C') { defaultLabelType = "CORE"; labelType = 'topImage'; defaultPrint = 8; } // copiesC
    else { defaultLabelType = "XXX"; labelType = 'general'; defaultPrint = 1; }

    console.log('Label Type:', sampleInitial, defaultLabelType, labelType, defaultPrint)

    // const printButtonText = 'Print Label'
    // const printingArea = useRef(null);
    // const printingArea_top = useRef(null);

    console.log('BATCH PRINT LABEL')


    if (labelType === 'general') {

        return ( // general label PRINT (D B WS ES)

            <>
                <div className=" ">  {/* START PRINT LABELS HOLDER */}

                    {/* START LABEL */}

                    <div className="">

                        <div id="label" className="">

                            <div className="labelBodyBatch overflow-hidden" >

                                <div className="w-full printArea">

                                    <table className="w-full border border-black p-0 text-left letterSpacing ">
                                        <tbody>
                                            <tr>
                                                <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                    Loc:
                                                </td>
                                                <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1rem', lineHeight: '1.3rem' }}>
                                                    {projectLocation}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                    Project:
                                                </td>
                                                <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.7rem', lineHeight: '2.0rem' }}>
                                                    {projectID}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                    ID:
                                                </td>
                                                <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold letterSpacingExtreme" style={{ fontSize: '2.4rem', lineHeight: '2.6rem' }}>
                                                    {boreholeName}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                    Type:
                                                </td>
                                                <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold letterSpacingExtreme" style={{ fontSize: '2.4rem', lineHeight: '2.6rem' }}>
                                                    {sampleID}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                    Depth:
                                                </td>
                                                <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.6rem', lineHeight: '1.8rem' }}>
                                                    {sampleDepth}<span className="ml-0.5" style={{ fontSize: '1rem', lineHeight: '1.1rem' }}>m</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                    User:
                                                </td>
                                                <td className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '0.9rem', lineHeight: '1.2rem' }}>
                                                    {/* {loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name)} */}
                                                    {user}
                                                </td>
                                                <td className="border border-black pl-1 pt-0.5 font-bold" style={{ width: '80px', fontSize: '0.9rem', lineHeight: '1.2rem' }}>
                                                    {labelDate}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div className="barcodeHolder">
                                            <Barcode value={labelbarcode} options={{
                                                format: 'code128',
                                                width: barcodeWidth,
                                                height: 20,
                                                fontSize: 14,
                                                // text: labelbarcode,
                                                background: 'none'
                                            }} />

                                        </div>

                                        {/* <div className="drillitLogsText mx-auto text-center mt-1 font-bold text-xs">PRODUCED BY DRILLIT LOGS - DRILLITLOGS.COM</div> */}
                                        <div className="mx-auto text-center mt-1 font-bold" style={{ fontSize: '0.65rem' }}>PRODUCED BY DRILLIT LOGS - DRILLITLOGS.COM</div>


                                {/* <div className="labelBodyRight_general">

                                    <div className="w-full border-0 text-left grid grid-cols-7 gap-0">
                                        <div className="col-span-1 locTextHeight text-right font-bold border-0 inline" style={{ fontSize: '9px' }}>
                                            Loc:
                                        </div>
                                        <div className="col-span-6 locTextHeight locText pl-1 font-bold text-left border-0 inline">
                                            {projectLocation}
                                        </div>
                                    </div>

                                    <div className="w-full border-0 text-left grid grid-cols-7 gap-0">
                                        <div className="col-span-1 projTextHeight text-right font-bold inline" style={{ fontSize: '9px' }}>
                                            Proj:
                                        </div>
                                        <div className="col-span-6 projText projTextHeight pl-1 font-bold text-left inline" id="labelProject">
                                            {projectID}
                                        </div>
                                    </div>

                                    <div className="w-full border-0 text-left grid grid-cols-7 gap-0">
                                        <div className="col-span-1 bhTextHeight text-right font-bold" style={{ fontSize: '9px' }}>
                                            ID:
                                        </div>
                                        <div className="col-span-6 bhText bhTextHeight pl-1 font-bold text-left" id="labelBH">
                                            {boreholeName}
                                        </div>
                                    </div>

                                    <div className="w-full border-0 text-left grid grid-cols-7 gap-0">
                                        <div className="col-span-1 sampTextHeight text-right font-bold" style={{ fontSize: '9px' }}>
                                            Type:
                                        </div>
                                        <div className="col-span-6 sampText sampTextHeight pl-1 font-bold text-left" id="labelSample">
                                            {sampleID}
                                        </div>
                                    </div>

                                    <div className="w-full border-0 text-left grid grid-cols-7 gap-0">
                                        <div className="col-span-1 depthTextHeight text-right font-bold" style={{ fontSize: '9px' }}>
                                            Depth:
                                        </div>
                                        <div className="col-span-6 depthText depthTextHeight pl-1 font-bold text-left" id="labelDepth">
                                            {sampleDepth}<span className="ml-0.5" style={{ fontSize: '1rem' }}>m</span>
                                        </div>
                                    </div>

                                    <div className="w-full border-0 text-left grid grid-cols-7 gap-0">
                                        <div className="col-span-1 drillerTextHeight w-1/7 text-right font-bold" style={{ fontSize: '9px' }}>
                                            User:
                                        </div>
                                        <div className="col-span-4 drillerText drillerTextHeight pl-1 font-bold text-left w-6/7" id="labelDriller">
                                            {loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name)}
                                        </div>
                                        <div className="col-span-2 dateText dateTextHeight font-bold text-right pr-2" id="labelDate">
                                            {labelDate}
                                        </div>
                                    </div>

                                    <div className="barcodeHolder -mt-6">
                                        <Barcode value={labelbarcode} options={{
                                            format: 'code128',
                                            width: barcodeWidth,
                                            height: 15,
                                            fontSize: 14,
                                            // text: labelbarcode,
                                            background: 'none'
                                        }} />

                                    </div>

                                    <div className="drillitLogsText mx-auto text-center -mt-2 font-bold">PRODUCED BY DRILLIT LOGS - DRILLITLOGS.COM</div>

                                </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* END LABEL */}

                </div> {/* END PRINT LABELS HOLDER */}

            </>
        )

    } else {
        console.log('TOP LABEL')

        return ( // TOPIMAGE LABEL PRINT (D B WS ES)


        

    



            <>
                <div className=" ">  {/* START PRINT LABELS HOLDER */}

                    {/* START LABEL */}

                    <div className="">

                        <div id="label" className="">

                            <div className="labelBodyBatch overflow-hidden" >

                                <div className="w-full printArea">

                                    <table className="w-full border border-black p-0 text-left letterSpacing">
                                        <tbody>
                                            <tr>
                                                <td rowSpan={6} className="border border-black bg-black text-right pr-1 pt-0.5 font-bold" style={{ width: '25px' }}>
                                                    <img className='topImg' src={TopImage} alt="TOP" />
                                                </td>
                                                <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                    Loc:
                                                </td>
                                                <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1rem', lineHeight: '1.3rem' }}>
                                                    {projectLocation}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                    Project:
                                                </td>
                                                <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '1.7rem', lineHeight: '2.0rem' }}>
                                                    {projectID}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                    ID:
                                                </td>
                                                <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold letterSpacingExtreme" style={{ fontSize: '2.4rem', lineHeight: '2.6rem' }}>
                                                    {boreholeName}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                    Type:
                                                </td>
                                                <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold letterSpacingExtreme" style={{ fontSize: '2.4rem', lineHeight: '2.6rem' }}>
                                                    {sampleID}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                    Depth:
                                                </td>
                                                <td colSpan={2} className="border border-black pl-1 pt-0.5 font-bold letterSpacingExtreme" style={{ fontSize: '1.6rem', lineHeight: '1.9rem' }}>
                                                    {sampleDepth}<span className="ml-0.5" style={{ fontSize: '1rem', lineHeight: '1.1rem' }}>m</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border border-black text-right pr-1 pt-0.5 font-bold" style={{ width: '55px', fontSize: '0.8rem', lineHeight: '1.1rem' }}>
                                                    User:
                                                </td>
                                                <td className="border border-black pl-1 pt-0.5 font-bold" style={{ fontSize: '0.9rem', lineHeight: '1.2rem' }}>
                                                    {/* {loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name)} */}
                                                    {user}
                                                </td>
                                                <td className="border border-black pl-1 pt-0.5 font-bold" style={{ width: '80px', fontSize: '0.9rem', lineHeight: '1.2rem' }}>
                                                    {labelDate}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div className="barcodeHolder">
                                            <Barcode value={labelbarcode} options={{
                                                format: 'code128',
                                                width: barcodeWidth,
                                                height: 20,
                                                fontSize: 14,
                                                // text: labelbarcode,
                                                background: 'none'
                                            }} />

                                        </div>

                                        {/* <div className="drillitLogsText mx-auto text-center mt-1 font-bold text-xs">PRODUCED BY DRILLIT LOGS - DRILLITLOGS.COM</div> */}
                                        <div className="mx-auto text-center mt-1 font-bold" style={{ fontSize: '0.65rem' }}>PRODUCED BY DRILLIT LOGS - DRILLITLOGS.COM</div>


                                {/* <div className="labelBodyLeft">
                                    <div className="topImg">
                                        <img className='topImg' src={TopImage} alt="TOP" />
                                    </div>
                                </div>

                                <div className="labelBodyRight">

                                    <div className="w-full border-0 text-left grid grid-cols-7 gap-0">
                                        <div className="col-span-1 locTextHeight text-right font-bold border-0 inline" style={{ fontSize: '9px' }}>
                                            Loc:
                                        </div>
                                        <div className="col-span-6 locTextHeight locText pl-1 font-bold text-left border-0 inline">
                                            {projectLocation}
                                        </div>
                                    </div>

                                    <div className="w-full border-0 text-left grid grid-cols-7 gap-0">
                                        <div className="col-span-1 projTextHeight text-right font-bold inline" style={{ fontSize: '9px' }}>
                                            Proj:
                                        </div>
                                        <div className="col-span-6 projText projTextHeight pl-1 font-bold text-left inline" id="labelProject">
                                            {projectID}
                                        </div>
                                    </div>

                                    <div className="w-full border-0 text-left grid grid-cols-7 gap-0">
                                        <div className="col-span-1 bhTextHeight text-right font-bold" style={{ fontSize: '9px' }}>
                                            ID:
                                        </div>
                                        <div className="col-span-6 bhText bhTextHeight pl-1 font-bold text-left" id="labelBH">
                                            {boreholeName}
                                        </div>
                                    </div>

                                    <div className="w-full border-0 text-left grid grid-cols-7 gap-0">
                                        <div className="col-span-1 sampTextHeight text-right font-bold" style={{ fontSize: '9px' }}>
                                            Type:
                                        </div>
                                        <div className="col-span-6 sampText sampTextHeight pl-1 font-bold text-left" id="labelSample">
                                            {sampleID}
                                        </div>
                                    </div>

                                    <div className="w-full border-0 text-left grid grid-cols-7 gap-0">
                                        <div className="col-span-1 depthTextHeight text-right font-bold" style={{ fontSize: '9px' }}>
                                            Depth:
                                        </div>
                                        <div className="col-span-6 depthText depthTextHeight pl-1 font-bold text-left" id="labelDepth">
                                            {sampleDepth}<span className="ml-0.5" style={{ fontSize: '1rem' }}>m</span>
                                        </div>
                                    </div>

                                    <div className="w-full border-0 text-left grid grid-cols-7 gap-0">
                                        <div className="col-span-1 drillerTextHeight w-1/7 text-right font-bold" style={{ fontSize: '9px' }}>
                                            User:
                                        </div>
                                        <div className="col-span-4 drillerText drillerTextHeight pl-1 font-bold text-left w-6/7" id="labelDriller">
                                            {loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name)}
                                        </div>
                                        <div className="col-span-2 dateText dateTextHeight font-bold text-right pr-2" id="labelDate">
                                            {labelDate}
                                        </div>
                                    </div>

                                    <div className="barcodeHolder -mt-6">
                                        <Barcode value={labelbarcode} options={{
                                            format: 'code128',
                                            width: barcodeWidth,
                                            height: 15,
                                            fontSize: 14,
                                            // text: labelbarcode,
                                            background: 'none'
                                        }} />

                                    </div>

                                    <div className="drillitLogsText mx-auto text-center -mt-2 font-bold">PRODUCED BY DRILLIT LOGS - DRILLITLOGS.COM</div> */}

                                </div>

                            </div>
                        </div>
                    </div>
                    {/* END LABEL */}

                </div> {/* END PRINT LABELS HOLDER */}

            </>
        )


    } // END OF PRINT LABELS
}

export default Label;