import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

import Button from '../../../form/Button'

type Props = {
	title: string,
	selectedValues: any[],
	setSelectedValue: any,
	columns?: number,
	columnsMobile?: number,
	values: any[];
    className?: string,
	[x: string]: any;
}

const defaultProps: Props = {
	title: '',
	selectedValues: [],
	setSelectedValue: () => {},
	columns: 3,
	columnsMobile: 1,
	className: '',
	values: [],
}

const MultiSelect: FunctionComponent<Props> = ({ title, selectedValues, setSelectedValue, columns, columnsMobile, className, values, ...rest }) => {
	const selectValue = (value: string) => {
		if(selectedValues.includes(value)) {
			setSelectedValue(selectedValues.filter(item => item !== value))
		} else {
			setSelectedValue([...selectedValues, value])
		}
	}

	return (
		<>
			<div className="w-full text-center mb-0 sm:mb-2 mt-0 sm:mt-2 text-sm sm:text-base">{title}</div>

			<div className={`grid grid-cols-${columnsMobile} sm:grid-cols-${columns} md:grid-cols-${columns} gap-x-0 gap-y-1 mb-2`}>
				{values.map((value, index) =>
					<div className={`md:col-span-1 text-charcoal flex flex-col text-center justify-center items-center bg-white ${className} ${selectedValues.includes(value) ? 'border-2 border-orange' : 'border border-grey-mid'} rounded`} onClick={() => selectValue(value)} key={index}>
						{value}
					</div>
				)}
			</div>
		</>
	)
}

MultiSelect.defaultProps = defaultProps

export default MultiSelect