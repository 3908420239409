import React, { FunctionComponent } from 'react'
import { useGetLoggedInUser } from '../helpers/getLoggedInUser'

import Container from './Container'
import InputGroup from '../components/form/InputGroup'

import logo from '../assets/logo.svg'
import bars from '../assets/bars.svg'

import logoOuter from '../assets/logo-icon-outer.svg'
import logoCenter from '../assets/logo-icon-center.svg'
import logoText from '../assets/logo-text.svg'

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
	Link,
} from "react-router-dom"

import routes from '../routes'
import Sidebar from '../layout/Sidebar'

const projectlink = "/"

type Props = {
	toggleSidebar: () => void
	middle?: any,
	dark?: boolean,
	children?: string,
	companyName?: string,
}

const defaultProps: Props = {
	toggleSidebar: () => {},
	middle: null,
	dark: false,
	children: '',
	companyName: '',
}

const Header: FunctionComponent<Props> = ({ toggleSidebar, middle, dark, children, companyName }) => {

	const loggedInUser = useGetLoggedInUser()
	// console.log('companyName header: ', companyName)

	return (
		<div className={`${dark ? 'bg-header-dark' : 'bg-header-light'} border-t-8 border-orange py-1 h-20`}>
			<Container>
				<div className="flex justify-between items-top">


					{/* <img src={logo} width="250" alt="Drillit Logs Logo" /> */}

					<div className="w-14 lg:w-72 h-16">
						<Link to={{pathname: projectlink}}>
							<div className="text-center mx-auto w-14 h-14 absolute mt-0.5 items-left">                
								<img src={logoOuter} className="logo-spin absolute h-14" alt="logo gear" />
								<img src={logoCenter} className="h-14 w-14 absolute" alt="logo inner" />
							</div>
						</Link>						
						<div className="mt-4 invisible lg:visible w-0 lg:w-60"> 
							<img src={logoText} className="pl-0 md:pl-14 w-0 md:w-48 lg:w-60 pt0 md:pt-1 lg:pt-0" alt="logo" />
						</div>

					</div>

					
					

					<div className="text-charcoal">
						{middle}
					</div>


					
					<div className="text-right w-14 lg:w-72 pt-2">
						{/* <div className="flex items-center">
							<img src="http://placehold.it/50x50" className="rounded-full shadow-xl" alt="" />

							<div className="ml-3">
								<div className="text-lg leading-5 text-text">{loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name) }</div>

								<div className="text-sm leading-4 uppercase text-grey-dark">Drillit Logs</div>
							</div>
						</div> */}

						{/* <button onClick={toggleSidebar} className="ml-10"><img src={bars} width="45" alt="" /></button> */}
						<button onClick={toggleSidebar} className="align-right"><img src={bars} className="h-11 w-11" alt="" /></button>
					</div>

				</div>
			</Container>
		</div>
	)
}

Header.defaultProps = defaultProps

export default Header
