import React, { FunctionComponent, useState } from 'react'
import { useSelector } from 'react-redux'

import WorkflowSteps from '../../WorkflowSteps'
import Modal from '../../Modal'
import Button from '../../form/Button'
import NumberInput from '../../NumberInput'
import TextArea from '../../form/TextArea'
import {v4 as uuidv4} from 'uuid';

import stepStatus from '../../../helpers/stepStatus'

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: string,
	defaultValues?: any,
	update?: boolean,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => {},
	onComplete: () => {},
	borehole: '',
	defaultValues: {
		guid: '',
		depth_to_top: '',
		depth_to_base: '',
		stratum_description: '',
		stratum_type: '',
	},
}

const SoilDescriptionEditor: FunctionComponent<Props> = ({ onClose, update, onComplete, borehole, defaultValues, setShowSoilDescriptionEdit, ...rest }) => {
	const [currentStep, setCurrentStep] = useState(1)

	const [guid, setGuid] = useState(defaultValues.guid)
	const [depth_to_top, setDepthFrom] = useState(defaultValues.depth_to_top)
	const [depth_to_base, setDepthTo] = useState(defaultValues.depth_to_base)
	const [stratum_description, setDescription] = useState(defaultValues.stratum_description)
	const [stratum_type, setStratum_type] = useState(defaultValues.stratum_type)

	const soilDescriptionLength = useSelector((state: any) => state.soilDescriptionLength)
	
	const [saveButtonText, setButtonText] = useState('Continue')
	const [saveButtonColor, setButtonColor] = useState('primary')
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)


    const [time_of_action, setTimeOfAction] = useState(defaultValues.time_of_action)

	const DeleteButton = () => {
		if (!update) return null;
	
		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	const deleteRecordConfirm = () => {
		setDeleteConfirmOpen(false)
		// onComplete()
	}

	const previous = () => {
		if(currentStep > 1)
			setCurrentStep(currentStep - 1)
			setButtonText('Continue')
			setButtonColor('primary')
	}

	const next = () => {
		if(currentStep < 3) {
			setCurrentStep(currentStep + 1)
			if(currentStep === 2) {
				setButtonText('Save') 
				setButtonColor('success')
			}else{
				setButtonText('Continue')
				setButtonColor('primary')
			}
			return
		}

		onComplete({
			guid: update ? guid : uuidv4(),
			depth_to_top: depth_to_top2DP,
			depth_to_base: depth_to_base2DP,
			stratum_description: stratum_description,
			stratum_type: stratum_type,
			general_remarks: '',
			time_of_action: time_of_action, // new Date().toISOString().split('.')[0],
			update: update ? true : false,
			// userActivity: 'Drilling',

		})
	}

	
	// if(depth_to_top == 'GL'){
	// 	let depth_to_top2DP = 'GL'
	// }else{
	// 	depth_to_top2DP = (Math.round(depth_to_top)).toFixed(2)
	// }

	if (depth_to_top === ''){var depth_to_top2DP = ''}
	else if (depth_to_top === 'GL') {depth_to_top2DP = 'GL'}
	else {depth_to_top2DP = parseFloat(depth_to_top).toFixed(2)}

	// console.log(depth_to_top2DP)

	var depth_to_base2DP = parseFloat(depth_to_base).toFixed(2)
	// console.log(depth_to_base2DP)

	return (
		<Modal title="Edit Soil Description" subtitle={borehole} toggleModal={onClose}>
			<div className="flex flex-col flex-grow px-1 sm:px-2 md:px-10">
				<WorkflowSteps steps={[
					{
						label: 'Depth From',
						status: stepStatus(1, currentStep),
						onClick: () => (setCurrentStep(1), setButtonText('Continue'), setButtonColor('primary'))
					}, {
						label: 'Depth To',
						status: stepStatus(2, currentStep),
						onClick: () => (setCurrentStep(2), setButtonText('Continue'), setButtonColor('primary'))
					}, {
						label: 'Description',
						status: stepStatus(3, currentStep),
						onClick: () => (setCurrentStep(3), setButtonText('Save'), setButtonColor('success'))
					},
				]} className="mb-2" />

				<div className="flex-1">
					{currentStep === 1 &&
						<NumberInput onUpdate={(value: any) => setDepthFrom(value)} 
                        title="Enter Depth From (m)" 
						defaultValue={depth_to_top2DP} 
						className="mb-10"
						presets={[
							{value: 'GL', label: 'GL'},
							]} 
						/>
					}

					{currentStep === 2 &&
						<NumberInput onUpdate={(value: any) => setDepthTo(value)}
                        title="Enter Depth To (m)" 
                        defaultValue={depth_to_base} 
                        className="mb-10" />
					}

					{currentStep === 3 &&
						// <SoilDescriptionGenerator onChange={(value: any) => setDescription(value)} startingStep={3} step={currentStep} />
                        
		                
                        <TextArea 
                        label = {stratum_type}
                        value={stratum_description} 
                        onChange={(value: any) => setDescription(value)}
                        className="w-full md:w-4/5 lg:w-3/5 mx-auto mb-2 align-text-top text-lg text-center"
                        inputCSS="h-36 sm:h-20 md:h-36 overflow-x-auto text-base"
                        numberOfRows={4}
                        />
                    }
				</div>
				

				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">
	
	
							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?
	
								<div className="flex mt-4">
									<Button theme="secondary" 
										className="mr-5 text-sm sm:text-base px-3.5 md:px-7" 
										onClick={() => setDeleteConfirmOpen(false)}>
											Cancel
									</Button>

									<Button onClick={() => {
												onClose()
												onComplete({
													guid: guid,
													update: false,
													delete: true,
												})
												setShowSoilDescriptionEdit(false)
											}}
										className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">
										Delete
									</Button>
								</div>
							</div>
	
	
						</div>
					</div>
				}

				<div className="flex">
					{/* <Button theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button> */}
					<DeleteButton/>

					<div className="flex ml-auto">
						<Button theme="primary" size="lg" className="px-4 sm:px-10" onClick={() => previous()}>Back</Button>
						<Button theme={saveButtonColor} 
							size="lg" 
							className="ml-4 px-8 sm:px-10 w-32 sm:w-60" 
							onClick={() => next()}>{saveButtonText}
						</Button>
				</div>
				</div>
				</div>
			</Modal>
	)
}

SoilDescriptionEditor.defaultProps = defaultProps

export default SoilDescriptionEditor
