import { get, set, del } from 'idb-keyval';

interface ApiResponse {
    success: boolean;
    status?: number;
}

export const deleteHole = (guid: string, project_guid: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        const endpoint = `${process.env.REACT_APP_API_ADDR}hole-locations/${guid}`;

        console.log('Deleting contact:', guid);

        makeRequest(endpoint, 'DELETE')
            .then((response: ApiResponse) => {
                console.log('API response:', response); // Log the API response

                if (response.success) {
                    console.log('Contact deleted successfully from API.');

                    // Delete the contact from IndexedDB drillit-hole-locations
                    del(`drillit-hole-locations/${guid}`)

                    // Delete hole from Project IDB
                    deleteHoleFromProjectIDB(guid, project_guid)

                } else {
                    console.error('Failed to delete contact from API.');

                    // Save the delete request to IndexedDB for retrying later
                    const idbKey = `drillit-hole-locations/${guid}-delete`;
                    set(idbKey, guid)
                        .then(() => {
                            console.log(`Saved delete request for ${guid} to IndexedDB for retry.`);
                        })
                        .catch(err => {
                            console.error(`Error saving delete request to IndexedDB for ${guid}:`, err);
                        });

                    // Delete the contact from IndexedDB drillit-hole-locations regardless
                    del(`drillit-hole-locations/${guid}`)

                    // Delete hole from Project IDB regardless
                    deleteHoleFromProjectIDB(guid, project_guid)
                }
            })
            .catch(error => {
                console.error('Error deleting contact:', error);

                // Save the delete request to IndexedDB for retrying later
                const idbKey = `drillit-hole-locations/${guid}-delete`;
                set(idbKey, guid)
                    .then(() => {
                        console.log(`Saved delete request for ${guid} to IndexedDB for retry.`);
                    })
                    .catch(err => {
                        console.error(`Error saving delete request to IndexedDB for ${guid}:`, err);
                    });

                    // Delete the contact from IndexedDB drillit-hole-locations regardless
                    del(`drillit-hole-locations/${guid}`)

                    // Delete hole from Project IDB regardless
                    deleteHoleFromProjectIDB(guid, project_guid)
            });
    });
};

const makeRequest = (endpoint: string, method: string): Promise<any> => {
    const token = localStorage.getItem('drillit-token');

    return fetch(endpoint, {
        method: method,
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
    .then(response => {
        if (!response.ok) {
            throw new Error(`Failed to delete hole. Status: ${response.status}`);
        }

        return response.json(); // Parse JSON response
    })
    .catch(err => {
        console.error('Fetch error:', err);
        throw err; // Propagate the error for further handling
    });
};

const deleteHoleFromProjectIDB = (guid: string, project_guid: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
        const projectIDB = `drillit-hole-locations/project/${project_guid}`;
        
        // Retrieve the data from IndexedDB
        get(projectIDB)
            .then((data: any[]) => {
                if (!data || data.length === 0) {
                    return reject(new Error('No data found in IndexedDB for the given project_guid'));
                }
                
                // Filter out the record with the matching guid
                const updatedData = data.filter(record => record.guid !== guid);
                
                // Update IndexedDB with the filtered data
                del(projectIDB) // Clear the existing data
                    .then(() => {
                        set(projectIDB, updatedData)
                            .then(() => resolve())
                            .catch(err => reject(err));
                    })
                    .catch(err => reject(err));
            })
            .catch(err => reject(err));
    });
};
