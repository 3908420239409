import React, { FunctionComponent, useState, useEffect } from 'react'

import { useLocation } from "react-router-dom";

import Header from '../layout/Header'

import Modal from '../components/Modal'
import WorkflowSteps from '../components/WorkflowSteps'
import NumberInput from '../components/NumberInput'
import Button from '../components/form/Button'
import InputGroup from '../components/form/InputGroup'
import WorkflowActions from '../components/form/WorkflowActions'
import CrewList from './CrewList'
import RigList from './RigList'

import stepStatus from '../helpers/stepStatus'

import Label from '../components/Label'
import { Link } from 'react-router-dom'
import RadioSelect from '../components/form/RadioSelect'

import { v4 as uuidv4 } from 'uuid'
import { useGetIDBValues } from "../helpers/getIDBKey";
import warningIcon from '../assets/icons/warning.png'
import checkIcon from '../assets/icons/check.svg'
import { updateHoleInformation } from "../helpers/updateHoleInformation";
import { deleteHole } from "../helpers/deleteHole";


var crewlink = "/crew"

// type Props = {
// 	title: string,
// 	subtitle?: string,
// 	bg?: string,
// 	// toggleModal: () => void,
// 	confirm?: false,
// 	children?: React.ReactNode,
// 	router?: any,
// 	holeType: string,
// }



// interface State {
// 	boreholeName: string,
// 	startDate: string,
// 	geoNorth: string,
// 	geoEast: string,
// 	holeType: string,
// }

type Props = {
	onClose: any,
	// onComplete: any,
	project_name?: string,
	projectId?: string,
	holeType: string,
	onChange: any,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => { },
	// onComplete: () => {},
	borehole: '',
	projectId: '',
	holeType: '',
	onChange: () => { },
}



const EditHole: FunctionComponent<Props> = ({ onClose, project_name, borehole_guid, onChange, borehole_name, leadDriller, 
	leadDrillerID, leadDrillerCSCS, holeType, secondMan, secondManCSCS, plantGuid, plantName, plantMakeModel, sptGUID, borehole_depthOverall, 
	company_guid, company_id, activity_termination_reason, plantCheck, project_guid, holeStatus, numberingType, ...rest }) => {

	const [companyGuid, setCompanyGuid] = useState(company_guid)
	const [companyID, setCompanyID] = useState(company_id)
	const [holeName, setHoleName] = useState(borehole_name)
	const [hole_type, setHole_type] = useState(holeType)
	const [projectName, setProjectName] = useState(project_name)
	const [projectGUID, setProjectGUID] = useState(project_guid)
	const [boreholeGuid, setBoreholeGuid] = useState(borehole_guid)
	const [boreholeName, setBoreholeName] = useState(borehole_name)
	const [driller, setDriller] = useState(leadDriller)
	const [drillerID, setDrillerID] = useState(leadDrillerID)
	const [drillerCSCS, setDrillerCSCS] = useState(leadDrillerCSCS)
	const [second_man, setSecond_man] = useState(secondMan)
	const [second_manCSCS, setSecond_manCSCS] = useState(secondManCSCS)
	const [rigGuid, setRigGuid] = useState(plantGuid)
	const [rigName, setRigName] = useState(plantName)
	const [rigMakeModel, setRigMakeModel] = useState(plantMakeModel)
	console.log('rigMakeModel in EDIT:', rigMakeModel)
	const [sptGuid, setSptGuid] = useState(sptGUID)
	const [boreholeDepth, setBoreholeDepth] = useState(borehole_depthOverall)
	const [activityTerminationReason, setActivityTerminationReason] = useState(activity_termination_reason)
	const [hole_barcode, setHole_barcode] = useState(projectName + '-' + holeName)
	const [hole_status, set_hole_status] = useState(holeStatus)
	const [numbering_type, set_numberingType] = useState(numberingType)

	const [showCrewList, setShowCrewList] = useState(false)
	const [showRigList, setShowRigList] = useState(false)
	const [crewSelectionType, setCrewSelectionType] = useState('driller')
	const [showSecondMan, setShowSecondMan] = useState(false)
	const [showPlantCheck, setShowPlantCheck] = useState(false)
	const [showRig, setShowRig] = useState(false)
	const [plantCheckComplete, setPlantCheckComplete] = useState(plantCheck)

	const [buttonText, setButtonText] = useState('')
	const [buttonTextSave, setButtonTextSave] = useState('')
	const [type_of_hole, set_type_of_hole] = useState('borehole')
	const [userType, setUserType] = useState('Driller')

	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)
	const [deleteButtonText, setDeleteButtonText] = useState('')
	const [redirect, setRedirect] = useState('')
	console.log('redirect:', redirect)

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	const deleteRecordConfirm = () => {
		setDeleteConfirmOpen(false)
		// onComplete()
	}



	const handleCrewSelect = (selectedUser: any) => {
		console.log('selectedUser************************************:', selectedUser)
		// console.log('this.state.crewSelectionType************************************:', this.state.crewSelectionType)

		if (crewSelectionType === 'driller') {

			const firstName = selectedUser?.first_name;
			const lastName = selectedUser?.last_name;
			const fullName = `${firstName} ${lastName}`;
			const cscs = selectedUser?.cscs_no;
			const driller_id = selectedUser?.id;

			setDriller(fullName)
			setDrillerID(driller_id)
			setDrillerCSCS(cscs)

		} else if (crewSelectionType === 'secondMan') {

			const firstName = selectedUser?.first_name
			const lastName = selectedUser?.last_name
			const fullName = `${firstName} ${lastName}`
			const cscs = selectedUser?.cscs_no

			setSecond_man(fullName)
			setSecond_manCSCS(cscs)
		}

	}

	const handleCrewListClose = () => {
		setShowCrewList(false)
	};

	const handleRigSelect = (selectedRig: any) => {
		// console.log('selectedRig************************************:', selectedRig)

		const rigGuid = selectedRig?.guid;
		const rigName = selectedRig?.name;
		const rigMakeModel = selectedRig?.make_model;
		const sptGuid = selectedRig?.spt_guid;

		setRigGuid(rigGuid)
		setRigName(rigName)
		setRigMakeModel(rigMakeModel)
		setSptGuid(sptGuid)

	}

	const handleRigListClose = () => {
		setShowRigList(false)
	};


	useEffect(() => {
		const holeTypeMapping: Record<string, {
			starting_hole_type_id: number;
			activity_type: string;
			redirect_location: string;
			hole: string;
			plantCheck: boolean;
			rig: boolean;
			secondMan: boolean;
			userType: string;
			requiredDepth: boolean;
			otherRequirements: boolean;
			plantCheckCP: boolean;
			plantCheckWS: boolean;
			plantCheckRT: boolean;
			// plantCheckLink: string;
		}> = {
			CP: {
				starting_hole_type_id: 1,
				activity_type: 'Cable Percussive',
				redirect_location: 'cable-percussive',
				hole: 'Cable Borehole',
				plantCheck: true,
				rig: true,
				secondMan: true,
				userType: 'Driller',
				requiredDepth: false,
				otherRequirements: false,
				plantCheckCP: true,
				plantCheckWS: false,
				plantCheckRT: false,
				// plantCheckLink: '/daily-plant-check-cable-percussive/0000/' + company_guid + '/' + this.state?.guid,
			},
			WS: {
				starting_hole_type_id: 2,
				activity_type: 'Window Sample',
				redirect_location: 'window-sample',
				hole: 'Window Sample',
				plantCheck: true,
				rig: true,
				secondMan: true,
				userType: 'Driller',
				requiredDepth: false,
				otherRequirements: false,
				plantCheckCP: false,
				plantCheckWS: true,
				plantCheckRT: false,
				// plantCheckLink: '/daily-plant-check-window-sample/0000/' + this.state?.company_guid + '/' + this.state?.guid,
			},
			RT: {
				starting_hole_type_id: 3,
				activity_type: 'Rotary',
				redirect_location: 'rotary',
				hole: 'Rotary Borehole',
				plantCheck: true,
				rig: true,
				secondMan: true,
				userType: 'Driller',
				requiredDepth: false,
				otherRequirements: false,
				plantCheckCP: false,
				plantCheckWS: false,
				plantCheckRT: true,
				// plantCheckLink: '/daily-plant-check-rotary/0000/' + this.state?.company_guid + '/' + this.state?.guid,
			},
			TP: {
				starting_hole_type_id: 4,
				activity_type: 'Trialpit',
				redirect_location: 'trialpit',
				hole: 'Trialpit',
				plantCheck: false,
				rig: false,
				secondMan: false,
				userType: 'Engineer',
				requiredDepth: false,
				otherRequirements: false,
				plantCheckCP: false,
				plantCheckWS: false,
				plantCheckRT: false,
				// plantCheckLink: '',
			},
			DP: {
				starting_hole_type_id: 5,
				activity_type: 'Dynamic Probe',
				redirect_location: 'dynamic-probe',
				hole: 'Dynamic Probe',
				plantCheck: true,
				rig: true,
				secondMan: true,
				userType: 'Driller',
				requiredDepth: false,
				otherRequirements: false,
				plantCheckCP: false,
				plantCheckWS: true,
				plantCheckRT: false,
				// plantCheckLink: '/daily-plant-check-window-sample/0000/' + this.state?.company_guid + '/' + this.state?.guid,
			},
			HT: {
				starting_hole_type_id: 6,
				activity_type: 'Head Test',
				redirect_location: 'head-test',
				hole: 'Head Test',
				plantCheck: false,
				rig: false,
				secondMan: false,
				userType: 'Engineer',
				requiredDepth: false,
				otherRequirements: false,
				plantCheckCP: false,
				plantCheckWS: false,
				plantCheckRT: false,
				// plantCheckLink: '',
			},
			SA: {
				starting_hole_type_id: 7,
				activity_type: 'Soakaway',
				redirect_location: 'soakaway',
				hole: 'Soakaway',
				plantCheck: false,
				rig: false,
				secondMan: false,
				userType: 'Engineer',
				requiredDepth: false,
				otherRequirements: false,
				plantCheckCP: false,
				plantCheckWS: false,
				plantCheckRT: false,
				// plantCheckLink: '',
			},
			PT: {
				starting_hole_type_id: 8,
				activity_type: 'Plate Test',
				redirect_location: 'plate-test',
				hole: 'Plate Test',
				plantCheck: false,
				rig: false,
				secondMan: false,
				userType: 'Engineer',
				requiredDepth: false,
				otherRequirements: false,
				plantCheckCP: false,
				plantCheckWS: false,
				plantCheckRT: false,
				// plantCheckLink: '',
			},
			CB: {
				starting_hole_type_id: 9,
				activity_type: 'CBR',
				redirect_location: 'cbr',
				hole: 'CBR',
				plantCheck: false,
				rig: false,
				secondMan: false,
				userType: 'Engineer',
				requiredDepth: false,
				otherRequirements: false,
				plantCheckCP: false,
				plantCheckWS: false,
				plantCheckRT: false,
				// plantCheckLink: '',
			},
			TR: {
				starting_hole_type_id: 10,
				activity_type: 'TRL',
				redirect_location: 'trl',
				hole: 'TRL',
				plantCheck: false,
				rig: false,
				secondMan: false,
				userType: 'Engineer',
				requiredDepth: false,
				otherRequirements: false,
				plantCheckCP: false,
				plantCheckWS: false,
				plantCheckRT: false,
				// plantCheckLink: '',
			},
			LG: {
				starting_hole_type_id: 11,
				activity_type: 'Logging',
				redirect_location: 'logging',
				hole: 'Logging',
				plantCheck: false,
				rig: false,
				secondMan: false,
				userType: 'Engineer',
				requiredDepth: false,
				otherRequirements: false,
				plantCheckCP: false,
				plantCheckWS: false,
				plantCheckRT: false,
				// plantCheckLink: '',
			},
		};

		const selectedMapping = holeTypeMapping[holeType];

		if (selectedMapping) {
			const startingHoleTypeId = selectedMapping.starting_hole_type_id;
			const activityType = selectedMapping.activity_type;
			const redirectLocation = selectedMapping.redirect_location;
			const hole = selectedMapping.hole;
			const plantCheck = selectedMapping.plantCheck;
			const rig = selectedMapping.rig;
			const secondMan = selectedMapping.secondMan;
			const userType = selectedMapping.userType;
			const requiredDepth = selectedMapping.requiredDepth;
			const otherRequirements = selectedMapping.otherRequirements;
			const plantCheckCP = selectedMapping.plantCheckCP;
			const plantCheckWS = selectedMapping.plantCheckWS;
			const plantCheckRT = selectedMapping.plantCheckRT;
			// const plantCheckLink = selectedMapping.plantCheckLink;


			setButtonText('Update ' + hole)
			setButtonTextSave('Save ' + hole)
			setUserType(userType)
			set_type_of_hole(hole)
			setDeleteButtonText('Delete ' + hole)

			const redirectURL = `/projects/dashboard/company/${company_guid}/${project_guid}/${redirectLocation}`

			setRedirect(redirectURL)

			// setState({
			//     starting_hole_type_id: startingHoleTypeId,
			//     activity_type: activityType,
			//     redirect_location: redirectLocation,
			//     hole: hole,
			//     plantCheck: plantCheck,
			//     rig: rig,
			//     secondMan: secondMan,
			//     userType: userType,
			//     requiredDepth: requiredDepth,
			//     otherRequirements: otherRequirements,
			//     plantCheckCP: plantCheckCP,
			//     plantCheckWS: plantCheckWS,
			//     plantCheckRT: plantCheckRT,
			//     plantCheckLink: plantCheckLink,

			//     buttonText: 'Create ' + hole,
			//     buttonTextSave: 'Save ' + hole,
			// });

			if (holeType === 'CP' || holeType === 'WS' || holeType === 'RT' || holeType === 'DP') {

				setShowSecondMan(true)
				setShowRig(true)
				setShowPlantCheck(true)
			}

		} else {
			console.log('Invalid hole type');
		}

	}, [])

	const inputLabel = type_of_hole + ' Name'

	useEffect(() => {
		setHole_barcode(projectName + '-' + holeName)
	}, [holeName])

	// const DeleteButton = () => {	
	// 	return (
	// 		<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete {hole}</Button>
	// 	);
	// };


	const DeleteButton = () => {
		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="text-sm md:text-base w-32 md:w-52 px-2 py-1.5 md:py-4">{deleteButtonText}</Button>
		);
	};

	const handleDeleteHole = () => {
		// Delete hole and send project_guid to deal with IDB stuff
		deleteHole(boreholeGuid, project_guid);
	
		// Wait 2 seconds before redirecting
		setTimeout(() => {
			window.location.href = redirect; // Perform the redirect after 2 seconds
		}, 2000); // 2000 milliseconds = 2 seconds
	};

	// const hole_barcode = holeName.replace(/[#_/*&^%$£"!-()~@?<>|'=`¬;:]/g, '') // New field for hole_locations
	// console.log(hole_barcode)

	/////////////////////////////////////////////////////////////////
	// VARIABLES FOR UPDATE HOLE:
	/////////////////////////////////////////////////////////////////
	// hole_name: holeName,
	// location_identifier: holeName,
	// driller: leadDriller,
	// driller_cscs: leadDrillerCSCS,
	// second_man: secondMan,
	// second_man_cscs: secondManCSCS,
	// rig_id: plantGuid,
	// rig_name: plantName,
	// spt_id: sptGUID,
	// hole_barcode: hole_barcode,
	// final_hole_depth: borehole_depthOverall

	function saveHoleData() {
		updateHoleInformation(
			boreholeGuid,
			holeName,
			driller,
			drillerID,
			drillerCSCS,
			second_man,
			second_manCSCS,
			rigGuid || '',
			rigName || '',
			rigMakeModel || '',
			sptGuid || '',
			hole_barcode,
			boreholeDepth,
			activityTerminationReason,
			projectGUID,
			hole_status,
			numberingType
		).then()

		// hole_name: borehole_name,
		// location_identifier: borehole_name,
		// driller: leadDriller,
		// driller_cscs: leadDrillerCSCS,
		// second_man: secondMan,
		// second_man_cscs: secondManCSCS,
		// rig_id: plantGuid,
		// rig_name: plantName,
		// spt_id: sptGUID,
		// hole_barcode: hole_barcode,
		// final_hole_depth: borehole_depthOverall

		onClose()
	}

	console.log('**************************************')
	console.log('boreholeGuid:', boreholeGuid)
	console.log('hole_name:', holeName)
	console.log('driller:', driller)
	console.log('drillerID:', drillerID)
	console.log('driller_cscs:', drillerCSCS)
	console.log('second_man:', second_man)
	console.log('second_man_cscs:', second_manCSCS)
	console.log('rig_id:', rigGuid)
	console.log('rig_name:', rigName)
	console.log('rig_make_model:', rigMakeModel)
	console.log('spt_id:', sptGuid)
	console.log('hole_barcode:', hole_barcode)
	console.log('final_hole_depth:', boreholeDepth)
	console.log('**************************************')

	

    //Plant Check
	let infoIconPlantCheck = ''
    let plantCheckText = ''
	console.log('plantCheckComplete:', plantCheckComplete)
    if (!plantCheckComplete) {
        infoIconPlantCheck = warningIcon
        plantCheckText = "Daily Plant Check Not Completed Today!"
    }else {
        infoIconPlantCheck = checkIcon
        plantCheckText = "Daily Plant Check Completed Today!"
    }

	return (

		<Modal title={'Edit ' + type_of_hole} subtitle={''} toggleModal={onClose} >


			{showCrewList &&
				<>
					<CrewList
						selectionType={crewSelectionType}
						onClose={handleCrewListClose}
						onSelectItem={handleCrewSelect}
						companyID={companyID}
					/>
				</>
			}


			{showRigList &&
				<>
					<RigList
						onClose={handleRigListClose}
						onSelectItem={handleRigSelect}
						companyGuid={company_guid}
					/>
				</>
			}

			<div className="relative">

				<div className="flex-grow">

					<div className="border border-grey-mid rounded px-2 pt-2 bg-sptTB mb-4 w-full sm:w-5/6 mx-auto text-center">
						<div className="w-full md:w-2/3 lg:w-1/3 mx-auto mb-2 text-xl">

							{inputLabel}


							<InputGroup label=""
								value={holeName}
								placeholder={holeName === '' ? 'loading' : ''}
								onChange={(value) => setHoleName(value)}
								className="mb-1 text-sm sm:text-base md:text-base w-full"
								inputCSS="text-sm sm:text-base md:text-2xl w-full py-0 font-bold text-center"
							/>

						</div>

					</div>

					<div className="border border-grey-mid rounded px-2 py-2 bg-sptTB mb-4 w-full sm:w-5/6 mx-auto">

						<table className="table-auto w-full md:w-5/6 text-sm text-center bg-white border border-grey-mid mx-auto text-base md:text-xl">
							<tbody>
								<tr>
									<td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
										{userType}:
									</td>
									<td className={`w-8/12 md:w-8/12 lg:w-1/2 p-1 pl-2 border-r border-b border-grey-mid text-left`}>

										<div className="w-full"
											onClick={() => {
												setCrewSelectionType('driller')
												setShowCrewList(true)
											}}
										>
											{driller !== '' ? (
												<div>
													{driller}.
												</div>
											) : (
												<div className="text-sm pl-2 text-blue">
													loading
												</div>
											)}
										</div>
									</td>
								</tr>
								{showSecondMan &&
									<tr>
										<td className={`p-1 pr-2 border-r border-b border-grey-mid text-right`}>
											{second_man === '' && (
												<img src={warningIcon} alt="info" width="20" className="inline-block mr-2 pt-0 pb-1" />
											)}
											2nd Man:
										</td>
										<td className={`p-1 pl-2 border-r border-b border-grey-mid text-left`}>

											<div className="w-full"
												onClick={() => {
													setCrewSelectionType('secondMan')
													setShowCrewList(true)
												}}>
												{second_man !== '' ? (
													<div>
														{second_man}.
													</div>
												) : (
													<div className="text-sm pl-2 text-blue">
														loading
													</div>
												)}
											</div>
										</td>
									</tr>
								}

								{showRig &&
									<>
										<tr>
											<td className={`p-1 pr-2 border-r border-b border-grey-mid text-right`}>
												{rigName === '' && (
													<img src={warningIcon} alt="info" width="20" className="inline-block mr-2 pt-0 pb-1" />
												)}
												Rig:
											</td>
											<td className={`p-1 pl-2 border-r border-b border-grey-mid text-left`}>

												<div className="w-full"
													onClick={() => {
														setShowRigList(true)
													}}
												>
													{rigName !== '' ? (
														<div>
															{rigName}.
														</div>
													) : (
														<div className="text-sm pl-2 text-blue">
															loading
														</div>
													)}
												</div>

											</td>
										</tr>
									</>
								}

								{showPlantCheck &&
									<tr>
										<td className={`px-0 py-2  border-r border-grey-mid text-center text-base md:text-xl font-bold`} colSpan={2}>
										<img src={infoIconPlantCheck} alt="info" width="22" className="inline-block mr-2 pb-2" />
											{plantCheckText}
										</td>
									</tr>
								}
							</tbody>
						</table>

						<div className="mt-4 mb-2">
							<Button
								theme="primary"
								size="lg"
								className="btn-lg m-auto w-60"
								type="submit"
								// type="button" 
								// onClick={(event:any) => saveForm(event)}
								onClick={() => saveHoleData()}
							>
								{buttonText}
							</Button>
						</div>

					</div>

				</div>

				<div className="w-full sm:w-5/6 mx-auto">
					<DeleteButton/>
				</div>

			</div>
				

				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">
	
	
							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this hole?
	
								<div className="flex mt-4">
									<Button theme="secondary" 
										className="mr-5 text-sm sm:text-base px-3.5 md:px-7" 
										onClick={() => setDeleteConfirmOpen(false)}
										>Cancel
									</Button>
	
									<Button onClick={() => {
												handleDeleteHole()
											}}
										className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">
										Delete
									</Button>
								</div>
							</div>
	
	
						</div>
					</div>
				}

		</Modal>

	)
}

EditHole.defaultProps = defaultProps

export default EditHole