export const updateActions = (action_name: string, action_guid: string, newValue: any) => {
    return new Promise((resolve, reject) => {
        const endpoint = `${process.env.REACT_APP_API_ADDR}action/${action_guid}`
        makeRequest(endpoint, JSON.stringify(newValue), 'PUT')
            .then(resolve => resolve.status)
            .then(resolve)
            .catch(reject => reject)
    })
}

const makeRequest = (endpoint: string, data: string, postOrPut: string) => {
    const token = localStorage.getItem('drillit-token')

    return fetch(endpoint, {
        method: postOrPut,
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: data
    })
        //return the status code here
        .then(response => response)
        .catch(err => err)
}
