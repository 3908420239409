import { get, set, del } from 'idb-keyval'
import { setIDB } from "./setIDBKey";
import { usePostToApi } from "./postToAPI";
import { updateActions } from "./updateActions";
import { log } from "util";

// updateIDB(state, `actions/${value['guid']}`, 'SamplesData', value['guid'], 'sample', submitSamplesData)

export const useUpdateIndexedDBValues = (
    values: object, // state
    idbKey: string, // actions/${value['guid']}
    update: string, // SamplesData
    actionGuid?: string, // value['guid']
    action?: string, // sample
    actionData?: object // submitSamplesData
): any => {

    console.log('**************** updateIDB(actions) ***************');
    console.log('**************** 25/07/2024 @ 12:55 ***************');

    console.log('updateIDB - values to edit:', values)
    console.log('updateIDB - actionData to edit:', actionData)

    const existingKey = `drillit-${idbKey}`
    const updateKey = `${existingKey}-update`

    console.log('updateIDB - ${idbKey}:', idbKey)
    console.log('updateIDB - ${existingKey}-update:', updateKey)

    //// update the current IDB result showing on browser
    set(existingKey, values).then()

    console.log('updateIDB - get(updateKey)', get(updateKey))

    //// Clear the existing key before setting the new values //
    // del(existingKey)
    //     .then(() => {
    //         console.log('updateIDB - existing key cleared');
    //         return set(existingKey, values);
    //     })
    //     .then(() => {
    //         console.log('updateIDB - values updated in IDB');
    //     })
    //     .catch(error => {
    //         console.error('updateIDB - error setting values:', error);
    //     });

    // send this to the API, if no response save it in the update array
    return updateActions(action!, actionGuid!, actionData!)
        .then(response => {
            if (response !== 200) {
                console.log('updateIDB - updateActions: failed!')
                // failed to send to the API, add it to the update array
                return new Promise((result: any, reject: any) => {
                    getIDB(updateKey).then(result)
                })
                    .then(result => {
                        if (result) {
                            console.log('updateIDB - updateKey: ', updateKey)
                            updateIDB(actionData!, updateKey, result as object[])
                        } else {
                            // updateIDB(actionData!, updateKey)
                            updateIDB(actionData!, updateKey, result as object[])
                        }
                    })
                    .then(result => result)
            }
        })
        .catch(reject => console.log('updateIDB - reject: ', reject));
}

const getIDB = (keyValue: string) => get(keyValue).then(data => data)

// this is purely for the update version, the main display IDB stuff should be taken from state at time of update
const updateIDB = (newData: object, idbKey: string, oldData?: object) => {
    let newArr = [] as any
    if (oldData) { newArr = oldData }
    newArr.push(newData)
    set(idbKey, newArr)
        .then(result => result)
        .catch(error => error)
}
