import React, { FunctionComponent, useState } from 'react'
import { Link } from 'react-router-dom'

import Button from './form/Button'
import Checkbox from "./form/Checkbox";

type Props = {
	title: string,
	selectedValues: any[],
	setSelectedValue: any,
	columns?: number,
	columnsMobile?: number,
	values: any[];
	subtitle: any[];
    className?: string,
	[x: string]: any;
}

const defaultProps: Props = {
	title: '',
	selectedValues: [],
	setSelectedValue: () => {},
	columns: 3,
	columnsMobile: 1,
	className: '',
	values: [],
	subtitle: [],
}

const MultiSelect: FunctionComponent<Props> = ({ 
    title, selectedValues, setSelectedValue, columns, columnsMobile, className, values, subtitle, logURL, selectedDate, dates, type,
    ...rest }) => {
    
    // console.log('logURL*:', logURL)
    console.log('selectedDate*:', selectedDate)

    const attachmentType = type

    const selectAllLogsWithSameDate = () => {
        const logsToSelect = logURL.filter((url:any, index:any) => dates[index] === selectedDate);
        setSelectedValue([...selectedValues, ...logsToSelect]);
    }
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const toggleSelectAll = () => {
        const logsToSelect = logURL.filter((url:any, index:any) => dates[index] === selectedDate);
        if (!selectAllChecked) {
            setSelectedValue([...selectedValues, ...logsToSelect]);
        } else {
            setSelectedValue(selectedValues.filter(value => !logsToSelect.includes(value)));
        }
        setSelectAllChecked(!selectAllChecked);
    }

    const selectValue = (value: string) => {
        const logIndex = selectedValues.indexOf(value);
    
        if (logIndex !== -1) {
            // Log already selected, remove it
            setSelectedValue(selectedValues.filter((_, index) => index !== logIndex));
        } else {
            // Log not selected, add it
            setSelectedValue([...selectedValues, value]);
        }
    }

    return (
        <>
            <div className="w-full text-center mb-0 sm:mb-2 mt-0 sm:mt-2 text-sm sm:text-base">{title}</div>
            
            {/* <label>
                <input type="checkbox" checked={selectAllChecked} onChange={toggleSelectAll} />
                Select All Logs with Same Date
            </label> */}

            {/* <div className="flex justify-center mx-auto w-full mt-0 mb-4">
				<div className="w-56 bg-grey-mid">
                    <Checkbox label="Select All Logs with Same Date"
                        checked={selectAllChecked}
                        onChange={toggleSelectAll}
                        className="scale-150 transform-gpu pl-12 py-2 text-xs" />
                </div>
            </div> */}
            {attachmentType === 'logs' && (
                <div className="text-center text-sm mb-1">
                    <button className="font-bold bg-orange text-white py-1 px-2 rounded -mt-1.5" onClick={selectAllLogsWithSameDate}>Select Today's Logs ({selectedDate})</button>
                </div>
            )}

            <div className={`flex flex-wrap justify-center mb-2 w-full`}>
                {values.map((value, index) =>
                    // <div className={`text-charcoal flex flex-col text-center justify-center items-center bg-white ${className} ${selectedValues.includes(`${value}_${subtitle[index]}`) ? 'border-2 border-orange px-2 md:px-3 py-0' : 'border-2 border-grey-mid px-2 md:px-3 py-0'} rounded`} onClick={() => selectValue(value, subtitle[index])} key={index}>
                    <div className={`text-charcoal flex flex-col text-center justify-center items-center bg-white ${className} ${selectedValues.includes(logURL[index]) ? 'border-2 border-orange px-2 md:px-3 py-0' : 'border-2 border-grey-mid px-2 md:px-3 py-0'} rounded`} onClick={() => selectValue(logURL[index])} key={index}>
                        <span className="font-bold">{value}</span>
                        <div className="text-xs text-center">
                            {subtitle[index]}
                        </div>
                    </div>
                )}
            </div>
            {/* <button onClick={selectAllLogsWithSameDate}>Select All Logs on {selectedDate}</button> */}
        </>
    );
}

MultiSelect.defaultProps = defaultProps

export default MultiSelect