import React, { FunctionComponent, useState, useEffect } from 'react'

import WorkflowSteps from '../../WorkflowSteps'
import Modal from '../../Modal'
import Button from '../../form/Button'
// import SelectGroup from '../../form/SelectGroup'
import ExclusiveSelect from './soil-description/ExclusiveSelect'
// import NumberInputBackfill from '../../NumberInputBackfill'
import MultiNumberInput from '../../MultiNumberInput'

import { v4 as uuidv4 } from 'uuid';

import stepStatus from '../../../helpers/stepStatus'
import Checkbox from "../../form/Checkbox";
import Comment from './soil-description/Comment'
// import edit from '../../../assets/icons/edit.svg'
// import plusIcon from '../../../assets/icons/plus-white.svg'

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: string,
	defaultValues?: any,
	selectedDate?: string,
	[x: string]: any;
	update?: boolean,
	// holeDepth: number,
}

const defaultProps: Props = {
	onClose: () => { },
	onComplete: () => { },
	borehole: '',
	defaultValues: {
		guid: '',
		pipe_solid_1: '',
		pipe_solid_2: '',
		pipe_slotted_1: '',
		pipe_slotted_2: '',
		pipe_width_1: '',
		pipe_width_2: '',
		pipe_geosock_1: false,
		pipe_geosock_2: false,
		cover_size: '',
		cover_type: '',
		gas_tap_1: false,
		gas_tap_2: false,
		layers: [],
		comment: '',
		saveButtonText: 'Continue',
		install1TextOutput: '',
		install2TextOutput: '',
		backfillTextOutput: '',
		cover_comment_text: '',
	},
	selectedDate: '',
}

const BackfillInstall: FunctionComponent<Props> = ({ onClose, update, onComplete, borehole, defaultValues, selectedDate, holeDepth, setShowInstallEdit, ...rest }) => {
	const [currentStep, setCurrentStep] = useState(1)

	const [guid, setGuid] = useState(defaultValues.guid)
	const [pipe_solid_1, setpipe_solid_1] = useState(defaultValues.pipe_solid_1)
	const [pipe_solid_2, setpipe_solid_2] = useState(defaultValues.pipe_solid_2)
	const [pipe_slotted_1, setpipe_slotted_1] = useState(defaultValues.pipe_slotted_1)
	const [pipe_slotted_2, setpipe_slotted_2] = useState(defaultValues.pipe_slotted_2)
	const [pipe_width_1, setpipe_width_1] = useState(defaultValues.pipe_width_1)
	const [pipe_width_2, setpipe_width_2] = useState(defaultValues.pipe_width_2)
	const [cover_size, setcover_size] = useState(defaultValues.cover_size)
	const [cover_type, setcover_type] = useState(defaultValues.cover_type)
	const [general_remarks, setComment] = useState(defaultValues.general_remarks)
	const [pipe_geosock_1, setpipe_geosock_1] = useState(defaultValues.pipe_geosock_1)
	const [pipe_geosock_2, setpipe_geosock_2] = useState(defaultValues.pipe_geosock_2)
	const [gas_tap_1, setgas_tap_1] = useState(defaultValues.gas_tap_1)
	const [gas_tap_2, setgas_tap_2] = useState(defaultValues.gas_tap_2)
	
	useEffect(() => {
		if (defaultValues.pipe_solid_1 === null) {setpipe_solid_1('')}
		if (defaultValues.pipe_solid_2 === null) {setpipe_solid_2('')}
		if (defaultValues.pipe_slotted_1 === null) {setpipe_slotted_1('')}
		if (defaultValues.pipe_slotted_2 === null) {setpipe_slotted_2('')}
		if (defaultValues.pipe_width_1 === null) {setpipe_width_1('')}
		if (defaultValues.pipe_width_2 === null) {setpipe_width_2('')}
		if (defaultValues.general_remarks === null) {setComment('')}
		if (defaultValues.pipe_geosock_1 === '1') {setpipe_geosock_1(true)}
		if (defaultValues.pipe_geosock_2 === '1') {setpipe_geosock_2(true)}
		if (defaultValues.gas_tap_1 === '1') {setgas_tap_1(true)}
		if (defaultValues.gas_tap_2 === '1') {setgas_tap_2(true)}
	}, [defaultValues.pipe_solid_1, defaultValues.pipe_solid_2, defaultValues.pipe_slotted_1, defaultValues.pipe_slotted_2,
		defaultValues.pipe_width_1, defaultValues.pipe_width_2, defaultValues.general_remarks,
		defaultValues.pipe_geosock_1, defaultValues.pipe_geosock_2, defaultValues.gas_tap_1, defaultValues.gas_tap_2])



	console.log('****************************************************************')
	console.log('pipe_solid_1:', pipe_solid_1)
	console.log('pipe_solid_2:', pipe_solid_2)
	console.log('pipe_slotted_1:', pipe_slotted_1)
	console.log('pipe_slotted_2:', pipe_slotted_2)
	console.log('pipe_width_1:', pipe_width_1)
	console.log('pipe_width_2:', pipe_width_2)
	console.log('pipe_geosock_1:', pipe_geosock_1)
	console.log('pipe_geosock_2:', pipe_geosock_2)
	console.log('cover_size:', cover_size)
	console.log('cover_type:', cover_type)
	console.log('gas_tap_1:', gas_tap_1)
	console.log('gas_tap_2:', gas_tap_2)
	console.log('general_remarks:', general_remarks)
	console.log('****************************************************************')


	const [saveButtonText, setButtonText] = useState('Continue')
	const [saveButtonColor, setButtonColor] = useState('primary')
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	// const [hole_depth, setHoleDepth] = useState(holeDepth)
	// const hole_depth = holeDepth

	const DeleteButton = () => {
		if (!update) return null;

		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	// const deleteRecordConfirm = () => {
	// 	setDeleteConfirmOpen(false)
	// 	// onComplete()
	// }

	// const [time_of_action, setTimeOfAction] = useState(defaultValues.time_of_action)
	const time_of_action = defaultValues.time_of_action

	const isoDate = selectedDate ? `${new Date(selectedDate).toISOString().split('T')[0]} 09:00:00` : new Date().toISOString().split('.')[0]

	const previous = () => {
		if (currentStep > 1)
			setCurrentStep(currentStep - 1)
		setButtonText('Continue')
		setButtonColor('primary')
	}

	const next = () => {
		if (currentStep < 3) {
			setCurrentStep(currentStep + 1)
			if (currentStep === 2) {
				setButtonText('Save')
				setButtonColor('success')
			} else {
				setButtonText('Continue')
				setButtonColor('primary')
			}
			return
		}

		console.log('cover_comment_text on save:', cover_comment_text)

		onComplete({
			guid: update ? guid : uuidv4(), //uuidv4(),
			// depth_to_top: '0',
			// depth_to_base: '0',
			// description: 'description',
			general_remarks: general_remarks !== undefined ? general_remarks : '', // general_remarks_temp, //general_remarks,
			time_of_action: time_of_action ? time_of_action : isoDate,
			pipe_solid_1: pipe_solid_1,
			pipe_solid_2: pipe_solid_2,
			pipe_slotted_1: pipe_slotted_1,
			pipe_slotted_2: pipe_slotted_2,
			pipe_width_1: pipe_width_1,
			pipe_width_2: pipe_width_2,
			pipe_geosock_1: pipe_geosock_1 ? 1 : 0,
			pipe_geosock_2: pipe_geosock_2 ? 1 : 0,
			// install1TextOutput: install1Text,
			// install2TextOutput: install2Text,
			// backfillTextOutput: backfillText,
			cover_comment_text: cover_comment_text, // cover_comment_text_to_save, // cover_comment_text,
			cover_size: cover_size ? cover_size : '',
			cover_type: cover_type ? cover_type : 'No Cover',
			gas_tap_1: gas_tap_1 ? 1 : 0,
			gas_tap_2: gas_tap_2 ? 1 : 0,
			// layers: layers[],
			update: update ? true : false,
		})
	}

	// console.log(gas_tap_1, pipe_geosock_1, gas_tap_2, pipe_geosock_2 )

	console.log('update', update)
	console.log('cover:', cover_size, cover_type )

	// INSTALL #1
	let install1Text = ''
	if (pipe_width_1 || pipe_solid_1 || pipe_slotted_1) {
		if (pipe_width_1) { install1Text = '#1: ' + pipe_width_1 + 'mm pipe to ' }
		else { install1Text = '#1: Pipe to ' }
		if (!pipe_solid_1 && !pipe_slotted_1) { install1Text = install1Text }
		if (pipe_solid_1 && !pipe_slotted_1) { install1Text = install1Text + (pipe_solid_1) + 'm (' + pipe_solid_1 + 'm solid)' }
		if (!pipe_solid_1 && pipe_slotted_1) { install1Text = install1Text + (pipe_slotted_1) + 'm (' + pipe_slotted_1 + 'm slotted)' }
		if (pipe_solid_1 && pipe_slotted_1) { install1Text = install1Text + (+pipe_solid_1 + +pipe_slotted_1) + 'm (' + pipe_solid_1 + 'm solid & ' + pipe_slotted_1 + 'm slotted)' }
		if (pipe_geosock_1) { install1Text = install1Text + ' + geosock' }
		if (gas_tap_1) { install1Text = install1Text + ' & gastap' }
	} else { install1Text = '' }
	// INSTALL #2
	let install2Text = ''
	if (pipe_width_2 || pipe_solid_2 || pipe_slotted_2) {
		if (pipe_width_2) { install2Text = '#2: ' + pipe_width_2 + 'mm pipe to ' }
		else { install2Text = '#2: Pipe to ' }
		if (!pipe_solid_2 && !pipe_slotted_2) { install2Text = install2Text }
		if (pipe_solid_2 && !pipe_slotted_2) { install2Text = install2Text + (pipe_solid_2) + 'm (' + pipe_solid_2 + 'm solid)' }
		if (!pipe_solid_2 && pipe_slotted_2) { install2Text = install2Text + (pipe_slotted_2) + 'm (' + pipe_slotted_2 + 'm slotted)' }
		if (pipe_solid_2 && pipe_slotted_2) { install2Text = install2Text + (+pipe_solid_2 + +pipe_slotted_2) + 'm (' + pipe_solid_2 + 'm solid & ' + pipe_slotted_2 + 'm slotted)' }
		if (pipe_geosock_2) { install2Text = install2Text + ' + geosock' }
		if (gas_tap_2) { install2Text = install2Text + ' & gastap' }
	} else { install2Text = '' }
	

	// COVER & COMMENT
	const [cover_comment_text_to_save, set_cover_comment_text_to_save] = useState('No Cover')
	let cover_comment_text = cover_size + ' ' + cover_type
	if (cover_comment_text === '') {cover_comment_text = 'No Cover'}
	// console.log('cover_comment_text:', cover_comment_text)
	if (general_remarks) { cover_comment_text = cover_comment_text + ' - ' + general_remarks }

	useEffect(() => {
		if (cover_comment_text !== '' && general_remarks !== '') {
			set_cover_comment_text_to_save(cover_comment_text + ' - ' + general_remarks)
		} else if (cover_comment_text === '' && general_remarks !== '') {
			set_cover_comment_text_to_save(general_remarks)
		} else if (cover_comment_text !== '' && general_remarks === '') {
			set_cover_comment_text_to_save(cover_comment_text)
		} else {
			set_cover_comment_text_to_save('No Cover')
		}
		
	}, [cover_comment_text, general_remarks])

	console.log('cover_comment_text_to_save:', cover_comment_text_to_save)

	// console.log(install1Text, install2Text, backfillText, cover_comment_text)

	let general_remarks_temp = ''
	if(general_remarks === ''){general_remarks_temp = ''} else {general_remarks_temp = general_remarks}


	return (
		<Modal title="Install" subtitle={borehole} toggleModal={onClose}>
			<div className="flex flex-col flex-grow px-1 sm:px-2 md:px-10">
				<WorkflowSteps steps={[
					{
						label: 'Install #1',
						status: stepStatus(1, currentStep),
						onClick: () => {
							setCurrentStep(1)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Install #2',
						status: stepStatus(2, currentStep),
						onClick: () => {
							setCurrentStep(2)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Cover',
						status: stepStatus(3, currentStep),
						onClick: () => {
							setCurrentStep(3)
							setButtonText('Save')
							setButtonColor('success')
						}
					}
				]} className="mb-2" />

				<div className="flex-1">
					{currentStep === 1 &&

					<>
						<div className="w-full h-10 text-center text-lg font-bold">Hole Depth: {holeDepth}m</div>

						<MultiNumberInput values={
							[
								{ defaultValue: pipe_solid_1, onUpdate: setpipe_solid_1, label: 'Solid (m)' },
								{ defaultValue: pipe_slotted_1, onUpdate: setpipe_slotted_1, label: 'Slotted (m)' },
								{ defaultValue: pipe_width_1, onUpdate: setpipe_width_1, label: 'Width (mm)' },
							]
						}
							className="flex-grow text-base sm:text-xl md:text-xl pb-12"
							inputCSS="text-2xl sm:text-2xl md:text-3xl lg:text-4xl h-14 sm:h-16"
							aboveInput={
								<div className="flex justify-center mx-auto w-full mt-2 mb-4">
									<div className="w-40 bg-grey-mid mr-1">
										<Checkbox label="Geo Sock"
											checked={pipe_geosock_1}
											onChange={(value: any) => setpipe_geosock_1(value)}
											className="scale-150 transform-gpu pl-12 py-2 text-xs" />
									</div>
									<div className="w-40 bg-grey-mid ml-1">
										<Checkbox label="Gas Tap"
											checked={gas_tap_1}
											onChange={(value: any) => setgas_tap_1(value)}
											className="scale-150 transform-gpu pl-12 py-2 text-xs" />
									</div>
								</div>
							} />
					</>
					}

					{currentStep === 2 &&

					<>
						<div className="w-full h-10 text-center text-lg font-bold">Hole Depth: {holeDepth}m</div>

						<MultiNumberInput values={
							[
								{ defaultValue: pipe_solid_2, onUpdate: setpipe_solid_2, label: 'Solid (m)' },
								{ defaultValue: pipe_slotted_2, onUpdate: setpipe_slotted_2, label: 'Slotted (m)' },
								{ defaultValue: pipe_width_2, onUpdate: setpipe_width_2, label: 'Width (mm)' },
							]
						}
							className="mb-10 text-base sm:text-xl md:text-xl"
							inputCSS="text-2xl sm:text-2xl md:text-3xl lg:text-4xl h-14 sm:h-16"
							aboveInput={
								<div className="flex justify-center mx-auto w-full mt-2 mb-4">
									<div className="w-40 bg-grey-mid mr-1">
										<Checkbox label="Geo Sock"
											checked={pipe_geosock_2}
											onChange={(value: any) => setpipe_geosock_2(value)}
											className="scale-150 transform-gpu pl-12 py-2 text-xs" />
									</div>
									<div className="w-40 bg-grey-mid ml-1">
										<Checkbox label="Gas Tap"
											checked={gas_tap_2}
											onChange={(value: any) => setgas_tap_2(value)}
											className="scale-150 transform-gpu pl-12 py-2 text-xs" />
									</div>
								</div>
							} />
					</>
					}

					{currentStep === 3 &&

						// <div className="grid grid-cols-3 w-full sm:w-3/5 mx-auto mb-10 sm:mb-0 md:mb-96 lg:mb-20">
						<div className="grid grid-cols-3 w-full sm:w-3/5 mx-auto mt-10">

							{/* <div className="col-span-3 pt-1 mb-2">
									Cover Type:
								</div> */}

							<div className="col-span-3 border border-grey-mid rounded px-2 bg-sptTB mb-2 text-base md:text-lg font-bold">
								<ExclusiveSelect title="Cover Type"
									selectedValue={cover_type}
									setSelectedValue={setcover_type}
									values={[
										'Flush Cover',
										'Heavy Duty Flush Cover',
										'Tophat Cover',
										'No Cover',
									]}
									columns={3}
									columnsMobile={3}
									className="text-xs md:text-lg mx-1 py-2 sm:py-1 md:py-1 px-0"
								/>
							</div>

							<div className="col-span-3 border border-grey-mid rounded px-2 bg-sptTB mb-2 text-base md:text-lg font-bold">
								<ExclusiveSelect title=" Cover Size"
									selectedValue={cover_size}
									setSelectedValue={setcover_size}
									values={[
										'4"',
										'5"',
										'6"',
										'8"',
										'10"',
										'12"',
									]}
									columns={3}
									columnsMobile={3}
									className="text-xl sm:text-lg lg:text-xl mx-1 py-2 sm:py-1 md:py-1 px-0"
								/>
							</div>

							<div className="col-span-3 border border-grey-mid rounded px-2 bg-sptTB mb-2 text-center w-full mb-10">
								<div className="col-span-3">
									<Comment comment={general_remarks} numberOfRows={3} setComment={setComment} title="Comments" />
								</div>
							</div>

						</div>

					}

				</div>


				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">


							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?

								<div className="flex mt-4">
									<Button theme="secondary" 
										className="mr-5 text-sm sm:text-base px-3.5 md:px-7" 
										onClick={() => setDeleteConfirmOpen(false)}>
											Cancel
									</Button>

									<Button onClick={() => {
												onClose()
												onComplete({
													guid: guid,
													update: false,
													delete: true,
												})
												setShowInstallEdit(false)
											}}
										className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">
										Delete
									</Button>
								</div>
							</div>


						</div>
					</div>
				}

				<div className="flex">
					{/* <Button theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button> */}
					<DeleteButton />

					<div className="flex ml-auto">
						<Button theme="primary" size="lg" className="px-4 sm:px-10" onClick={() => previous()}>Back</Button>
						<Button theme={saveButtonColor}
							size="lg"
							className="ml-4 px-8 sm:px-10 w-32 sm:w-60"
							onClick={() => next()}>{saveButtonText}
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

BackfillInstall.defaultProps = defaultProps

export default BackfillInstall
