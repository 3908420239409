import React, { FunctionComponent, useState, Component, useRef, useEffect, } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateSelectedProject } from '../../../actions/SelectedProject'

import Panel from '../../../components/Panel'
import PanelSingleUse from '../../../components/PanelSingleUse'
import PanelSingleUseHeadTest from '../../../components/PanelSingleUseHeadTest'
import PanelBackfillInstall from '../../../components/PanelBackfillInstall'
import Button from '../../../components/form/Button'
import Modal from '../../../components/Modal'
import ModalSmall from '../../../components/ModalSmall'
import CompactTable from '../../../components/CompactTable'

import ProjectOptionsBorehole from '../../../components/project/ProjectOptionsBorehole'
import ProjectHeader from '../../../components/project/ProjectHeader'

import SyncStatus from '../../../helpers/syncStatus'

import Comments from '../../../components/project/workflow/Comments'
import HeadTest from '../../../components/project/workflow/HeadTest'
import HeadTestDetails from '../../../components/project/workflow/HeadTestDetails'

import Labels from '../../../components/project/workflow/Labels'

import Header from '../../../layout/Header'
import Container from '../../../layout/Container'

import cablePercussiveIcon from '../../../assets/icons/cablepercussive-white.svg'


import '../../../LogsheetCSS.css';
import Barcode from 'react-jsbarcode'
import closeIcon from '../../../assets/icons/close.svg'
import previewIcon from '../../../assets/icons/preview.svg'
import labelIcon from '../../../assets/icons/label.svg'
import { Link } from 'react-router-dom'

import { useSetIndexedDBValues } from '../../../helpers/addToIDB'
import { useUpdateIndexedDBValues } from "../../../helpers/updateIDB"
import { useDeleteIndexedDBValue } from "../../../helpers/deleteFromIDB"
import { useGetIDBValues } from "../../../helpers/getIDBKey";
import { usePostToApi } from "../../../helpers/postToAPI";
import { updateActions } from "../../../helpers/updateActions"
import CompactTableObject from "../../../components/CompactTableObject";
import getWeatherList from "../../../helpers/getWeatherList";
import { connect } from "react-redux";
import { get, set, update, del } from "idb-keyval";
import edit from "../../../assets/icons/edit.svg";
import { activateHole } from "../../../helpers/activateHole";
import { updateWeather } from "../../../helpers/updateWeather";
import { closeHole } from "../../../helpers/closeHole";
import { Redirect, useHistory } from "react-router-dom";
import datesAreOnSameDay from "../../../helpers/datesAreOnSameDay";
import { updateHoleInformation } from "../../../helpers/updateHoleInformation";

import menubars from '../../../assets/menubars.svg'

import icon_ags from '../../../assets/icons/icon_ags.png'
import icon_preview from '../../../assets/icons/icon_preview.png'
import icon_savelog from '../../../assets/icons/icon_savelog.png'
import icon_complete from '../../../assets/icons/icon_complete.png'
import icon_project from '../../../assets/icons/icon_project.png'
import icon_email from '../../../assets/icons/icon_email.png'
import icon_labels from '../../../assets/icons/icon_labels.png'
import icon_newborehole from '../../../assets/icons/icon_newborehole.png'
import icon_plantcheck from '../../../assets/icons/icon_plantcheck.png'
import icon_editrig from '../../../assets/icons/icon_editrig.png'
import icon_reload from '../../../assets/icons/reload.png'
import icon_endshift from '../../../assets/icons/icon_endshift.png'

import NewHole from '../../NewHole'
import AGS from '../../AGS'
import Email from '../../Email'
import SaveLog from '../../SaveLog'

import ReactToPrint from 'react-to-print'
import Label from '../../../components/Label'
import PrintLabel from '../../PrintLabelModal'
import LabelBatchPrint from '../../../components/LabelBatchPrint'
import TopImage from '../../../assets/labelTop.jpg'
import { useGetLoggedInUser } from '../../../helpers/getLoggedInUser'

interface Props {
	// onClose: any,
	// router: any,
	toggleSidebar: () => void,
	company_guid: string,
	project_guid: string,
	project_id: string,
	project_name: any,
	starting_hole_type_id: string,
	borehole_guid: string,
	selectedWeather: string,
	selectedDate: string,
	lastActiveDate: string,
	borehole_id: string,
	borehole_name: string,

	// HOLE DATA
	AllHoleData: any,
	activeDates: any,

	reloadData: any,
}


interface State {
	// onClose: () => {},
	project_guid: string,
	borehole: any,
	completingBorehole: boolean,

	showComments: boolean,
	showCommentsEdit: boolean,

	showHeadTestDetails: boolean,
	showHeadTestDetailsEdit: boolean,

	showRun1: boolean,
	showRun2: boolean,
	showRun3: boolean,

	HeadTestDetails: any,
	Comments: any,

	addSampleData: any,
	fromType: any,

	redirect: boolean,

	project: any,
	holeType: string,

	previewVisible: boolean,
	labelsVisible: boolean,
	bhMenuVisible: boolean,

	showHoleEdit: boolean,
	showNewHole: boolean,
	showEmail: boolean,
	showAGS: boolean,
	setShowNewHole: boolean,
	setShowEmail: boolean,
	setShowAGS: boolean,

	showSaveLog: boolean,
	setShowSaveLog: boolean,

	showPrintLabel: boolean,
	setShowPrintLabel: boolean,

	defaultValues: object,
	guid: string;

	todaySoilDescriptionsVisible: boolean,
	allSoilDescriptionsVisible: boolean,

	todayCasingVisible: boolean,
	allCasingVisible: boolean,

	// END SHIFT
	showEndShiftDailyWaterLevels: boolean,
	showEndShiftSaveLog: boolean,
	showEndShiftAGS: boolean,
	showEndShiftEmail: boolean,
	showEndShiftlogout: boolean,
}



const FunctionTemplate: FunctionComponent<Props> = ({
	company_guid, project_guid, project_id, starting_hole_type_id, borehole_guid, selectedWeather, selectedDate, lastActiveDate,
	borehole_id, borehole_name, reloadData, project_name,
	//HOLE DATA
	AllHoleData, activeDates,
	toggleSidebar, ...rest }) => {

	console.log('AllHoleData in function:', AllHoleData)

	const [borehole, setBorehole] = useState(null)
	const [project, setProject] = useState(null)
	const [hole_guid, set_hole_guid] = useState(AllHoleData?.hole_guid || '')

	const [state, setState] = useState({
		completingBorehole: false,
		showRun1: false,
		showRun2: false,
		showRun3: false,

		HeadTestDetails: [],
		Comments: [],
		HeadTestRunResults: [],

		addSampleData: [],
		fromType: '',

		// ************************************************
		// editingGuid: '',
		// editingIndex: 1, // there is something wrong here - editingIndex won't update null
		redirect: false,

		project: '',
		holeType: '',

		defaultValues: {},
	})

	// const [editingGuid, setEditingGuid] = useState('')
	const [editingGuid, setEditingGuid] = useState([])
	const [editingIndex, setEditingIndex] = useState(0)
	const [defaultValues, setDefaultValues] = useState([])

	// console.log('editingGuid from root', editingGuid)
	// console.log('editingIndex from root', editingIndex)
	// console.log('defaultValues from root', defaultValues)

	const [addSampleData, setAddSampleData] = useState([])
	const [fromType, setFromType] = useState('')

	// console.log('addSampleData:', addSampleData)

	const [showComments, setShowComments] = useState(false)
	const [showCommentsEdit, setShowCommentsEdit] = useState(false)

	const [showHeadTestDetail, setShowHeadTestDetail] = useState(false)
	const [showHeadTestDetailEdit, setShowHeadTestDetailEdit] = useState(false)


	const [showRun1, setShowRun1] = useState(false)
	const [showRun2, setShowRun2] = useState(false)
	const [showRun3, setShowRun3] = useState(false)

	const [showRun1Edit, setShowRun1Edit] = useState(false)
	const [showRun2Edit, setShowRun2Edit] = useState(false)
	const [showRun3Edit, setShowRun3Edit] = useState(false)




















	//.......................................................
	//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNN...NNNN..UUUU...UUUU..
	//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNNN..NNNN..UUUU...UUUU..
	//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNNN..NNNN..UUUU...UUUU..
	//.MMMMMMM.MMMMMMM.EEEE........NNNNNN.NNNN..UUUU...UUUU..
	//.MMMMMMM.MMMMMMM.EEEE........NNNNNN.NNNN..UUUU...UUUU..
	//.MMMMMMM.MMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU..
	//.MMMMMMMMMMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU..
	//.MMMMMMMMMMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU..
	//.MMMMMMMMMMMMMMM.EEEE........NNNNNNNNNNN..UUUU...UUUU..
	//.MMMM.MMMMM.MMMM.EEEE........NNNN.NNNNNN..UUUU...UUUU..
	//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN..NNNNN..UUUUUUUUUUU..
	//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN..NNNNN...UUUUUUUUU...
	//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN...NNNN....UUUUUUU....
	//.......................................................

	const [bhMenuVisible, setBhMenuVisible] = useState(false)
	const popupRef = useRef<HTMLDivElement | null>(null); // Specify the type explicitly
	// Event listener to close the menu when clicked outside
	const toggleMenu = (event: MouseEvent) => {
		if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
			setBhMenuVisible(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', toggleMenu);
		return () => {
			document.removeEventListener('click', toggleMenu);
		};
	}, []);


	const handleReloadClick = () => {
		// Call the reload function passed as a prop
		reloadData();
	};




















	//.........................................................................
	//.PPPPPPPPP.....AAAA.....AAANN...NNNN.NNNEEEEEEEE.EEEL.........LSSSSSS....
	//.PPPPPPPPPP...AAAAAA....AAANN...NNNN.NNNEEEEEEEE.EEEL........LLSSSSSSS...
	//.PPPPPPPPPPP..AAAAAA....AAANNN..NNNN.NNNEEEEEEEE.EEEL.......LLLSSSSSSSS..
	//.PPPP...PPPP..AAAAAAA...AAANNNN.NNNN.NNNE........EEEL.......LLLS...SSSS..
	//.PPPP...PPPP.PAAAAAAA...AAANNNN.NNNN.NNNE........EEEL.......LLLSS........
	//.PPPPPPPPPPP.PAAAAAAA...AAANNNNNNNNN.NNNEEEEEEEE.EEEL........LLSSSSS.....
	//.PPPPPPPPPP..PAAA.AAAA..AAANNNNNNNNN.NNNEEEEEEEE.EEEL.........LSSSSSSS...
	//.PPPPPPPPP..PPAAAAAAAA..AAAN.NNNNNNN.NNNEEEEEEEE.EEEL...........SSSSSSS..
	//.PPPP.......PPAAAAAAAAA.AAAN.NNNNNNN.NNNE........EEEL..............SSSS..
	//.PPPP......PPPAAAAAAAAA.AAAN..NNNNNN.NNNE........EEEL.......LLLS...SSSS..
	//.PPPP......PPPA....AAAA.AAAN..NNNNNN.NNNEEEEEEEEEEEELLLLLLLLLLLSSSSSSSS..
	//.PPPP......PPPA....AAAAAAAAN...NNNNN.NNNEEEEEEEEEEEELLLLLLLL.LLSSSSSSSS..
	//.PPPP.....PPPPA.....AAAAAAAN....NNNN.NNNEEEEEEEEEEEELLLLLLLL..LSSSSSS....
	//.........................................................................

	const [todaySoilDescriptionsVisible, setTodaySoilDescriptionsVisible] = useState(true)
	const [allSoilDescriptionsVisible, setAllSoilDescriptionsVisible] = useState(false)

	const [todayCasingVisible, setTodayCasingVisible] = useState(true)
	const [allCasingVisible, setAllCasingVisible] = useState(false)

	const [completingBorehole, setCompletingBorehole] = useState(false)
	const menuAndMarkAsComplete = () => {
		setBhMenuVisible(value => !value)
		setCompletingBorehole(value => !value)
	}

	const [previewVisible, setPreviewVisible] = useState(false)
	const previewClick = () => setPreviewVisible(value => !value)
	const menuAndPreview = () => {
		setBhMenuVisible(value => !value)
		setPreviewVisible(value => !value)
	}
	const [labelsVisible, setLabelsVisible] = useState(false)
	const labelsClick = () => setLabelsVisible(value => !value)
	const menuAndLabels = () => {
		setBhMenuVisible(value => !value)
		setLabelsVisible(value => !value)
	}
	const [showHoleEdit, setShowHoleEdit] = useState(false)
	const [showNewHole, setShowNewHole] = useState(false)
	const menuAndNewHole = () => {
		setBhMenuVisible(value => !value)
		setShowNewHole(value => !value)
	}
	const [showEmail, setShowEmail] = useState(false)
	const menuAndEmail = () => {
		setBhMenuVisible(value => !value)
		setShowEmail(value => !value)
	}
	const [showAGS, setShowAGS] = useState(false)
	const menuAndAGS = () => {
		setBhMenuVisible(value => !value)
		setShowAGS(value => !value)
	}
	const [showSaveLog, setShowSaveLog] = useState(false)
	const menuAndSaveLog = () => {
		setBhMenuVisible(value => !value)
		setShowSaveLog(value => !value)
	}
	const [showPrintLabel, setShowPrintLabel] = useState(false)
	const printLabelClick = () => setShowPrintLabel(value => !value)
	const menuAndShowLabels = () => {
		setBhMenuVisible(value => !value)
		setShowPrintLabel(value => !value)
	}

	const [labelSample, setLabelSample] = useState('')
	const [labelDepth, setLabelDepth] = useState('')

	// const [redirect, setRedirect] = useState(false)
	// if (redirect) {
	// 	return <Redirect to={`/projects/dashboard/${project_guid}/head-test`} />
	// }
	
	const history = useHistory();
	const [redirect, setRedirect] = React.useState(false);

	// const handleConfirmClick = () => {
	// 	closeHole(borehole_guid);
	// 	setRedirect(true);
	// };

	// Redirect manually using history.push
	React.useEffect(() => {
		if (redirect) {
		history.push(`/projects/dashboard/${project_guid}/head-test`);
		}
	}, [redirect, history, project_guid]);

	const printAllSampleLabelsArea = useRef(null)
	const printAllTestLabelsArea = useRef(null)

	const [informationCheck, setInformationCheck] = useState(true)

	

	// END SHIFT
	const [showEndShiftDailyWaterLevels, setShowEndShiftDailyWaterLevels] = useState(false)
	const [showEndShiftSaveLog, setShowEndShiftSaveLog] = useState(false)
	const [showEndShiftAGS, setShowEndShiftAGS] = useState(false)
	const [showEndShiftEmail, setShowEndShiftEmail] = useState(false)
	const [showEndShiftLogout, setShowEndShiftLogout] = useState(false)
	
	const menuAndEndShift = () => {
		setBhMenuVisible(value => !value)
		setShowEndShiftSaveLog(value => !value)
	}



















	//.............................................................................................................................
	//.PPPPPPPPP.....AAAA.....AAANN...NNNN.NNNEEEEEEEE.EEEL............ TTTTTTTTTTTY....YYYYYYYPPPPPPP..PPPEEEEEEEE...ESSSSSS....
	//.PPPPPPPPPP...AAAAAA....AAANN...NNNN.NNNEEEEEEEE.EEEL............ TTTTTTTTTTTY...YYYYYYYYPPPPPPPP.PPPEEEEEEEE..EESSSSSSS...
	//.PPPPPPPPPPP..AAAAAA....AAANNN..NNNN.NNNEEEEEEEE.EEEL............ TTTTTTTTTTTYY..YYYY.YYYPPPPPPPPPPPPEEEEEEEE.EEESSSSSSSS..
	//.PPPP...PPPP..AAAAAAA...AAANNNN.NNNN.NNNE........EEEL................TTTT....TTYYYYYYY..YYYP....PPPPPPPE........EEES...SSSS..
	//.PPPP...PPPP.PAAAAAAA...AAANNNN.NNNN.NNNE........EEEL................TTTT.....TYYYYYYY..YYYP....PPPPPPPE........EEESS........
	//.PPPPPPPPPPP.PAAAAAAA...AAANNNNNNNNN.NNNEEEEEEEE.EEEL................TTTT.....TYYYYYY...YYYPPPPPPPPPPPPEEEEEEEE..EESSSSS.....
	//.PPPPPPPPPP..PAAA.AAAA..AAANNNNNNNNN.NNNEEEEEEEE.EEEL................TTTT......YYYYY....YYYPPPPPPPP.PPPEEEEEEEE...ESSSSSSS...
	//.PPPPPPPPP..PPAAAAAAAA..AAAN.NNNNNNN.NNNEEEEEEEE.EEEL................TTTT.......YYYY....YYYPPPPPPP..PPPEEEEEEEE.....SSSSSSS..
	//.PPPP.......PPAAAAAAAAA.AAAN.NNNNNNN.NNNE........EEEL................TTTT.......YYYY....YYYP........PPPE...............SSSS..
	//.PPPP......PPPAAAAAAAAA.AAAN..NNNNNN.NNNE........EEEL................TTTT.......YYYY....YYYP........PPPE........EEES...SSSS..
	//.PPPP......PPPA....AAAA.AAAN..NNNNNN.NNNEEEEEEEEEEEELLLLLLLL.........TTTT.......YYYY....YYYP........PPPEEEEEEEEEEEESSSSSSSS..
	//.PPPP......PPPA....AAAAAAAAN...NNNNN.NNNEEEEEEEEEEEELLLLLLLL.........TTTT.......YYYY....YYYP........PPPEEEEEEEEE.EESSSSSSSS..
	//.PPPP.....PPPPA.....AAAAAAAN....NNNN.NNNEEEEEEEEEEEELLLLLLLL.........TTTT.......YYYY....YYYP........PPPEEEEEEEEE..ESSSSSS....
	//.............................................................................................................................


	type CommentsDataType = {
		id: string
		guid: string
		type: string
		start_time: any
		duration: string
		general_remarks: string
		time_of_action: string
	}
	type HeadTestDetailDataType = {
		id: any
		guid: any
		depth_of_borehole: any
		diameter_of_borehole: any
		water_level_before_test: any
		depth_of_casing: any
		height_of_casing: any
		soil_type: any
		gravel_backfill: any
		weather_details: any
		general_remarks: any
		time_of_action: any
	}
	type HeadTestDataType = {
		id: any
		guid: any
		ht_indentifier: any
		depth_of_borehole: any
		diameter_of_borehole: any
		water_level_before_test: any
		depth_of_casing: any
		height_of_casing: any
		soil_type: any
		gravel_backfill: any
		weather_details: any
		general_remarks: any
		runs: any
		time_of_action: any
	}

	const [CommentsData, setCommentsData] = useState<CommentsDataType[]>([]);
	const [HeadTestData, setHeadTestData] = useState<HeadTestDataType[]>([]);
	const [HeadTestDetailData, setHeadTestDetailData] = useState<HeadTestDetailDataType[]>([]);


	interface RunData {
		ht_id: number;
		results: Array<{ [key: string]: any }>;
		run: number;
		start_time: string;
		status: any // inactive (default), active, complete
		data_type: any // auto or manual
	}



















	//.......................................................................................................
	//.PPPPPPPPP...PRRRRRRRRR.....OOOOOOO.........JJJJ....... DDDDDDDD.......AAAAA...AAATTTTTTTTT.TAAAA......
	//.PPPPPPPPPP..PRRRRRRRRRR...OOOOOOOOOO.......JJJJ....... DDDDDDDDD......AAAAA...AAATTTTTTTTT.TAAAA......
	//.PPPPPPPPPPP.PRRRRRRRRRR..ROOOOOOOOOOO......JJJJ....... DDDDDDDDDD....AAAAAA...AAATTTTTTTTT.TAAAAA.....
	//.PPPP...PPPP.PRRR...RRRRR.ROOOO..OOOOO......JJJJ....... DDD...DDDD....AAAAAAA......TTTT....TTAAAAA.....
	//.PPPP...PPPP.PRRR...RRRRRRROOO....OOOOO.....JJJJ....... DDD....DDDD..AAAAAAAA......TTTT....TTAAAAA.....
	//.PPPPPPPPPPP.PRRRRRRRRRR.RROO......OOOO.....JJJJ....... DDD....DDDD..AAAAAAAA......TTTT...TTTA.AAAA....
	//.PPPPPPPPPP..PRRRRRRRRRR.RROO......OOOO.....JJJJ....... DDD....DDDD..AAAA.AAAA.....TTTT...TTTA.AAAA....
	//.PPPPPPPPP...PRRRRRRR....RROO......OOOO.....JJJJ....... DDD....DDDD.DAAAAAAAAA.....TTTT...TTTAAAAAAA...
	//.PPPP........PRRR.RRRR...RROOO....OOOOOOOJJ.JJJJ....... DDD....DDDD.DAAAAAAAAAA....TTTT..TTTTAAAAAAA...
	//.PPPP........PRRR..RRRR...ROOOO..OOOOO.OOJJ.JJJJ....... DDD...DDDDD.DAAAAAAAAAA....TTTT..TTTTAAAAAAA...
	//.PPPP........PRRR..RRRRR..ROOOOOOOOOOO.OOJJJJJJJ....... DDDDDDDDDD.DDAA....AAAA....TTTT..TTTT....AAAA..
	//.PPPP........PRRR...RRRRR..OOOOOOOOOO..OOJJJJJJ........ DDDDDDDDD..DDAA.....AAAA...TTTT.TTTT.....AAAA..
	//.PPPP........PRRR....RRRR....OOOOOO.....OJJJJJ......... DDDDDDDD..DDDAA.....AAAA...TTTT.TTTT.....AAAA..
	//.......................................................................................................


	type ProjectData = {
		location?: string;
		project_identifier?: string;
		start_date?: string;
		site_description?: string;
		key_site_features?: string;
		site_history?: string;
		key_hands?: string;
		expected_strata?: string;
		comments?: string; // MISSING
		equipment_required?: string;
		name?: string;
		customer_contact?: string;
		customer_phone?: string;
		customer_email?: string;
		contractor_name?: string;
		engineer?: string;
		// engineer_email?: string;
	}

	const cachedProjectValues = useGetIDBValues;

	const [projectData, setProjectData] = useState<ProjectData | null>(null);

	useEffect(() => {
		cachedProjectValues(`projects/${project_guid}`, (data: any) => {
			// console.log('Retrieved project data:', data); // Log the retrieved data
			setProjectData(data);
		}).then();
	}, [project_guid]);

	const project_location = projectData?.location ?? ''
	const projectID = projectData?.project_identifier ?? ''
	const project_start_date = projectData?.start_date ?? ''
	const site_description = projectData?.site_description ?? ''
	const key_site_features = projectData?.key_site_features ?? ''
	const site_history = projectData?.site_history ?? ''
	const key_hands = projectData?.key_hands ?? ''
	const expected_strata = projectData?.expected_strata ?? ''
	const notes = projectData?.comments ?? ''
	const equipment_required = projectData?.equipment_required ?? ''
	const client = projectData?.name ?? ''
	const client_contact = projectData?.customer_contact ?? ''
	const client_phone = projectData?.customer_phone ?? ''
	const client_email = projectData?.customer_email ?? ''
	const engineer_name = projectData?.contractor_name ?? ''
	const engineer_phone = projectData?.engineer ?? ''
	//   const engineer_email = projectData?.engineer_email ?? ''

	// console.log('ProjectDATA*********************************************')
	// console.log('project_location:',projectData?.location)
	// console.log('projectID:',projectData?.project_identifier)
	// console.log('project_start_date:',projectData?.start_date)
	// console.log('site_description:',projectData?.site_description)
	// console.log('key_site_features:',projectData?.key_site_features)
	// console.log('site_history:',projectData?.site_history)
	// console.log('key_hands:',projectData?.key_hands)
	// console.log('expected_strata:',projectData?.expected_strata)
	// console.log('notes:',projectData?.comments)
	// console.log('equipment_required:',projectData?.equipment_required)
	// console.log('project_client:',projectData?.name)
	// console.log('client_contact:',projectData?.customer_contact)
	// console.log('client_phone:',projectData?.customer_phone)
	// console.log('client_email:',projectData?.customer_email)
	// console.log('engineer_name:',projectData?.contractor_name)
	// console.log('engineer_phone:',projectData?.engineer)
	// console.log('*******************************************************')

	const [engineerName, setEngineerName] = useState(engineer_name)

	if (engineer_name !== engineerName) {
		setEngineerName(engineer_name)
	}




















	//.........................................................
	//....CCCCCCC....RRRRRRRRRR...EEEEEEEEEEEEWWW..WWWWW...WW..
	//...CCCCCCCCC...RRRRRRRRRRR..EEEEEEEEEEEEWWW..WWWWW..WWW..
	//..CCCCCCCCCCC..RRRRRRRRRRR..EEEEEEEEEEEEWWW..WWWWWW.WWW..
	//..CCCC...CCCCC.RRRR...RRRRR.EEEE.......EWWW.WWWWWWW.WWW..
	//.CCCC.....CCC..RRRR...RRRRR.EEEE.......EWWW.WWWWWWW.WWW..
	//.CCCC..........RRRRRRRRRRR..EEEEEEEEEE..WWWWWWWWWWW.WWW..
	//.CCCC..........RRRRRRRRRRR..EEEEEEEEEE..WWWWWWW.WWWWWWW..
	//.CCCC..........RRRRRRRR.....EEEEEEEEEE..WWWWWWW.WWWWWWW..
	//.CCCC.....CCC..RRRR.RRRR....EEEE........WWWWWWW.WWWWWWW..
	//..CCCC...CCCCC.RRRR..RRRR...EEEE........WWWWWWW.WWWWWWW..
	//..CCCCCCCCCCC..RRRR..RRRRR..EEEEEEEEEEE..WWWWW...WWWWW...
	//...CCCCCCCCCC..RRRR...RRRRR.EEEEEEEEEEE..WWWWW...WWWWW...
	//....CCCCCCC....RRRR....RRRR.EEEEEEEEEEE..WWWWW...WWWWW...
	//.........................................................

	const loggedInUser = useGetLoggedInUser()
	// const [User, setUser] = useState(loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name))
	const User = loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name)
	const [userWarning, setUserWarning] = useState(false)
	
	const negPhrases = [
		"You shall not pass!",
		"None shall pass!",
		"Access denied!",
		"No trespassing!",
		"Entry prohibited!",
		"Stay out!",
		"Get yer stinkin' mitts off!",
		"These aren't the droids you're looking for!"
	  ];
	
	const randomNegPhrases = negPhrases[Math.floor(Math.random() * negPhrases.length)];
	
	const [leadDriller, setLeadDriller] = useState('')
	const [leadDrillerID, setLeadDrillerID] = useState(0)
	const [leadDrillerCSCS, setLeadDrillerCSCS] = useState('')
	const [secondMan, setSecondMan] = useState('')
	const [secondManCSCS, setSecondManCSCS] = useState('')
	const [hole_barcode, setHole_barcode] = useState('')
	const [hole_status, setHole_status] = useState('')
	const [numberingType, setNumberingType] = useState('')

	const [savedRigGuid, setSavedRigValue] = useState('')
	const [plantName, setPlantName] = useState('')
	const [sptGUID, setSptGUID] = useState('')
	const [activityTerminationReason, setActivityTerminationReason] = useState('')

	const [boreholeName, setBoreholeName] = useState(borehole_name)

	const cachedHoleInformation = useGetIDBValues

	useEffect(() => {
		if(borehole_guid){
			cachedHoleInformation(`hole-locations/${borehole_guid}`, (holeData: any) => {
				if (holeData) {
					console.log('holeData in PERSONNEL:', holeData)
					setLeadDriller(holeData.driller)
					setLeadDrillerID(holeData.driller_id)
					setLeadDrillerCSCS(holeData.driller_cscs);
					setSecondMan(holeData.second_man);
					setSecondManCSCS(holeData.second_man_cscs);
					setHole_barcode(holeData.hole_barcode);
					setSavedRigValue(holeData.rig_id); // RIG GUID
					setPlantName(holeData.rig_name); // RIG NAME
					setSptGUID(holeData.spt_id); // SPT ID
					setActivityTerminationReason(holeData.activity_termination_reason); // TERMINATION REASON
					setBoreholeName(holeData.hole_name); // HOLE NAME
					setHole_status(holeData.status); // HOLE STATUS
					setNumberingType(holeData.numberingType); // NUMBERING TYPE
				} else {
					// // console.log(`**ERROR** HOLE DATA NOT LOADED. ID: ${borehole_guid}`);
				}
				// // console.log('holeData:', holeData);
				
					console.log('PERSONNEL*********************************************')
					console.log('leadDriller:', leadDriller)
					console.log('leadDrillerID:', leadDrillerID)
					console.log('leadDrillerCSCS:', leadDrillerCSCS)
					console.log('secondMan:', secondMan)
					console.log('secondManCSCS:', secondManCSCS)
					console.log('hole_barcode:', hole_barcode)
					console.log('savedRigGuid:', savedRigGuid)
					console.log('plantName:', plantName)
					console.log('sptGUID:', sptGUID)
					console.log('boreholeName:', boreholeName)
					console.log('******************************************************')

			});
		}
	}, [borehole_guid]);




















	//.............................................................................................
	//....CCCCCCC......OOOOOOO....MMMMMM...MMMMMM.PPPPPPPPP.....AAAA.....ANNNN...NNNNNNYYY....YYY..
	//...CCCCCCCCC....OOOOOOOOOO..MMMMMM...MMMMMM.PPPPPPPPPP...AAAAAA....ANNNN...NNNN.NYYY...YYYY..
	//..CCCCCCCCCCC..OOOOOOOOOOOO.MMMMMM...MMMMMM.PPPPPPPPPPP..AAAAAA....ANNNNN..NNNN..YYYY..YYYY..
	//..CCCC...CCCCC.OOOOO..OOOOO.MMMMMMM.MMMMMMM.PPPP...PPPP..AAAAAAA...ANNNNNN.NNNN..YYYYYYYYY...
	//.CCCC.....CCC.OOOOO....OOOOOMMMMMMM.MMMMMMM.PPPP...PPPP.AAAAAAAA...ANNNNNN.NNNN...YYYYYYYY...
	//.CCCC.........OOOO......OOOOMMMMMMM.MMMMMMM.PPPPPPPPPPP.AAAAAAAA...ANNNNNNNNNNN...YYYYYYY....
	//.CCCC.........OOOO......OOOOMMMMMMMMMMMMMMM.PPPPPPPPPP..AAAA.AAAA..ANNNNNNNNNNN....YYYYY.....
	//.CCCC.........OOOO......OOOOMMMMMMMMMMMMMMM.PPPPPPPPP..AAAAAAAAAA..ANNN.NNNNNNN.....YYYY.....
	//.CCCC.....CCC.OOOOO....OOOOOMMMMMMMMMMMMMMM.PPPP.......AAAAAAAAAAA.ANNN.NNNNNNN.....YYYY.....
	//..CCCC...CCCCC.OOOOO..OOOOO.MMMM.MMMMM.MMMM.PPPP......PAAAAAAAAAAA.ANNN..NNNNNN.....YYYY.....
	//..CCCCCCCCCCC..OOOOOOOOOOOO.MMMM.MMMMM.MMMM.PPPP......PAAA....AAAA.ANNN..NNNNNN.....YYYY.....
	//...CCCCCCCCCC...OOOOOOOOOO..MMMM.MMMMM.MMMM.PPPP......PAAA....AAAAAANNN...NNNNN.....YYYY.....
	//....CCCCCCC.......OOOOOO....MMMM.MMMMM.MMMM.PPPP.....PPAAA.....AAAAANNN....NNNN.....YYYY.....
	//.............................................................................................

	const [companyInfo, setCompanyInfo] = useState({
		companyID: '',
		companyName: '',
		contactEmail: '',
		contactTelephone: '',
		companyAddress1: '',
		companyAddress2: '',
		companyAddress3: '',
		companyCounty: '',
		companyCountry: '',
		companyPostcode: '',
	});

	useEffect(() => {
		const getCompanyInfoFromCache = async () => {
			const response = await get('drillit-user');
			setCompanyInfo({
				companyID: response.user.company.id,
				companyName: response.user.company.name,
				contactEmail: response.user.company.email,
				contactTelephone: response.user.company.phone,
				companyAddress1: response.user.company.address1,
				companyAddress2: response.user.company.address2,
				companyAddress3: response.user.company.address3,
				companyCounty: response.user.company.county,
				companyCountry: response.user.company.country,
				companyPostcode: response.user.company.postcode,
			});
		};

		getCompanyInfoFromCache();
	}, []);

	const companyID = companyInfo.companyID
	const companyName = companyInfo.companyName
	const companyTelephone = companyInfo.contactTelephone
	const companyEmail = companyInfo.contactEmail

	let companyAddress = companyInfo.companyAddress1
	if (companyInfo.companyAddress2) { companyAddress = companyAddress + ', ' + companyInfo.companyAddress2 }
	if (companyInfo.companyAddress3) { companyAddress = companyAddress + ', ' + companyInfo.companyAddress3 }
	if (companyInfo.companyCounty) { companyAddress = companyAddress + ', ' + companyInfo.companyCounty }
	if (companyInfo.companyCountry) { companyAddress = companyAddress + ', ' + companyInfo.companyCountry }
	if (companyInfo.companyPostcode) { companyAddress = companyAddress + ', ' + companyInfo.companyPostcode }

	const companyLogo = "https://drillitlogs.com/images/company/" + companyID + ".png"











	// Info to pass into 'ProjectHeader' & 'ProjectOptionsBorehole'
	// Hole Name
	// Hole Driller
	// 2nd man
	// Rig
	// Daily Plant Check




















	//..........................................................................
	//.LLLL..........AAAAA.....BBBBBBBBBB...EEEEEEEEEEE.LLLL........SSSSSSS.....
	//.LLLL..........AAAAA.....BBBBBBBBBBB..EEEEEEEEEEE.LLLL.......SSSSSSSSS....
	//.LLLL.........AAAAAA.....BBBBBBBBBBB..EEEEEEEEEEE.LLLL.......SSSSSSSSSS...
	//.LLLL.........AAAAAAA....BBBB...BBBB..EEEE........LLLL......LSSSS..SSSS...
	//.LLLL........AAAAAAAA....BBBB...BBBB..EEEE........LLLL......LSSSS.........
	//.LLLL........AAAAAAAA....BBBBBBBBBBB..EEEEEEEEEE..LLLL.......SSSSSSS......
	//.LLLL........AAAA.AAAA...BBBBBBBBBB...EEEEEEEEEE..LLLL........SSSSSSSSS...
	//.LLLL.......AAAAAAAAAA...BBBBBBBBBBB..EEEEEEEEEE..LLLL..........SSSSSSS...
	//.LLLL.......AAAAAAAAAAA..BBBB....BBBB.EEEE........LLLL.............SSSSS..
	//.LLLL.......AAAAAAAAAAA..BBBB....BBBB.EEEE........LLLL......LSSS....SSSS..
	//.LLLLLLLLLLLAAA....AAAA..BBBBBBBBBBBB.EEEEEEEEEEE.LLLLLLLLLLLSSSSSSSSSSS..
	//.LLLLLLLLLLLAAA.....AAAA.BBBBBBBBBBB..EEEEEEEEEEE.LLLLLLLLLL.SSSSSSSSSS...
	//.LLLLLLLLLLLAAA.....AAAA.BBBBBBBBBB...EEEEEEEEEEE.LLLLLLLLLL..SSSSSSSS....
	//..........................................................................

	const projectLocation = project_location
	let logsheetLocationStyle = "headerCellLocationInput"
	if (projectLocation) {
		if (projectLocation.length <= 25) { logsheetLocationStyle = "headerCellLocationInput" }
		if (projectLocation.length > 25 && projectLocation.length <= 60) { logsheetLocationStyle = "headerCellLocationInputSmall" }
		if (projectLocation.length > 60) { logsheetLocationStyle = "headerCellLocationInputXSmall" }
	}

	let sheetDateObject = new Date(selectedDate)
	const sheetDate = sheetDateObject.toLocaleDateString()

	const labelbarcodeVar = projectID + '-' + borehole_name

	const barcodeWidths = [
		3.3, 3.3, 3.3, 3.3, 3.3, 3.3, 3.0, 2.7, 2.5, 2.3, // 1 to 10
		2.1, 2.0, 1.85, 1.75, 1.65, 1.55, 1.5, 1.42, 1.36, 1.3, // 11 to 20
		1.25, 1.2, 1.15, 1.1, 1.05, 1.0, 1.0, 0.95, 0.93, 0.90, // 21 to 30
		0.88, 0.85, 0.83, 0.80, 0.80, 0.76, 0.75, 0.73, 0.72, 0.71 // 31 to 40
	];

	let barcodeWidth = barcodeWidths[labelbarcodeVar.length - 1]





















	//.............................................................
	//.DDDDDDDDD.......AAAAA...AATTTTTTTTTTTEEEEEEEEE...SSSSSSS....
	//.DDDDDDDDDD......AAAAA...AATTTTTTTTTTTEEEEEEEEE..ESSSSSSSS...
	//.DDDDDDDDDDD....AAAAAA...AATTTTTTTTTTTEEEEEEEEE.EESSSSSSSSS..
	//.DDDD...DDDD....AAAAAAA......TTTT...TTEE........EESS...SSSS..
	//.DDDD....DDDD..AAAAAAAA......TTTT...TTEE........EESSS........
	//.DDDD....DDDD..AAAAAAAA......TTTT...TTEEEEEEEEE..ESSSSSS.....
	//.DDDD....DDDD..AAAA.AAAA.....TTTT...TTEEEEEEEEE...SSSSSSSS...
	//.DDDD....DDDD.AAAAAAAAAA.....TTTT...TTEEEEEEEEE.....SSSSSSS..
	//.DDDD....DDDD.AAAAAAAAAAA....TTTT...TTEE...............SSSS..
	//.DDDD...DDDDD.AAAAAAAAAAA....TTTT...TTEE........EESS...SSSS..
	//.DDDDDDDDDDD.DAAA....AAAA....TTTT...TTEEEEEEEEEEEESSSSSSSSS..
	//.DDDDDDDDDD..DAAA.....AAAA...TTTT...TTEEEEEEEEEE.ESSSSSSSSS..
	//.DDDDDDDDD..DDAAA.....AAAA...TTTT...TTEEEEEEEEEE..SSSSSSS....
	//.............................................................

	const [active_dates, setActive_dates] = useState(['06/04/2023', '05/04/2023'])

	function setAllActiveDates() {
		setActive_dates(activeDates)
	}

	useEffect(() => {
		setAllActiveDates()
	}, [activeDates])

	const formatDate = (dateString: string) => {
		const dateToFormat = new Date(dateString);
		return dateToFormat.toLocaleDateString('en-GB', {
			year: 'numeric',
			day: '2-digit',
			month: '2-digit',
		});
	};
	
	const headTestDate = HeadTestData.length > 0 ? formatDate(HeadTestData[0].time_of_action) : '';

	// console.log('HeadTestData.time_of_action:', headTestDate)





	// Convert selectedDate in yyyy-mm-dd format
	// strip selectedDate
	const originalDate = new Date(selectedDate)
	const year = originalDate.getFullYear()
	const month = String(originalDate.getMonth() + 1).padStart(2, '0') // Months are 0-indexed
	const day = String(originalDate.getDate()).padStart(2, '0')

	const formattedDate = `${year}-${month}-${day}`

	// console.log('formattedDate:', formattedDate)

















	//........................................................................................................
	//.HHHH...HHHH....OOOOOOO.....LLLL.......EEEEEEEEEEE...... DDDDDDDD.......AAAAA...AATTTTTTTTTT.AAAAA......
	//.HHHH...HHHH...OOOOOOOOOO...LLLL.......EEEEEEEEEEE...... DDDDDDDDD......AAAAA...AATTTTTTTTTT.AAAAA......
	//.HHHH...HHHH..OOOOOOOOOOOO..LLLL.......EEEEEEEEEEE...... DDDDDDDDDD....AAAAAA...AATTTTTTTTTT.AAAAAA.....
	//.HHHH...HHHH..OOOOO..OOOOO..LLLL.......EEEE............. DDD...DDDD....AAAAAAA......TTTT....TAAAAAA.....
	//.HHHH...HHHH.HOOOO....OOOOO.LLLL.......EEEE............. DDD....DDDD..AAAAAAAA......TTTT....TAAAAAA.....
	//.HHHHHHHHHHH.HOOO......OOOO.LLLL.......EEEEEEEEEE....... DDD....DDDD..AAAAAAAA......TTTT...TTAA.AAAA....
	//.HHHHHHHHHHH.HOOO......OOOO.LLLL.......EEEEEEEEEE....... DDD....DDDD..AAAA.AAAA.....TTTT...TTAA.AAAA....
	//.HHHHHHHHHHH.HOOO......OOOO.LLLL.......EEEEEEEEEE....... DDD....DDDD.DAAAAAAAAA.....TTTT...TTAAAAAAAA...
	//.HHHH...HHHH.HOOOO....OOOOO.LLLL.......EEEE............. DDD....DDDD.DAAAAAAAAAA....TTTT..TTTAAAAAAAA...
	//.HHHH...HHHH..OOOOO..OOOOO..LLLL.......EEEE............. DDD...DDDDD.DAAAAAAAAAA....TTTT..TTTAAAAAAAA...
	//.HHHH...HHHH..OOOOOOOOOOOO..LLLLLLLLLL.EEEEEEEEEEE...... DDDDDDDDDD.DDAA....AAAA....TTTT..TTTA....AAAA..
	//.HHHH...HHHH...OOOOOOOOOO...LLLLLLLLLL.EEEEEEEEEEE...... DDDDDDDDD..DDAA.....AAAA...TTTT.TTTT.....AAAA..
	//.HHHH...HHHH.....OOOOOO.....LLLLLLLLLL.EEEEEEEEEEE...... DDDDDDDD..DDDAA.....AAAA...TTTT.TTTT.....AAAA..
	//........................................................................................................

	const [hole_id, set_hole_id] = useState(0)

	function getAllHoleData() { // split out the HOLE DATA and SET CONSTs

		const CommentsArray = AllHoleData?.hole_data?.action_comment;
		const unpackedComments = CommentsArray?.filter((sample: any) => sample) || [];
		setCommentsData(unpackedComments);

		const HeadTestArray = AllHoleData?.hole_data?.action_head_test;
		const unpackedHeadTest = HeadTestArray?.filter((sample: any) => sample) || [];
		setHeadTestData(unpackedHeadTest);

		set_hole_id(AllHoleData?.hole_id)


	}



	useEffect(() => {
		getAllHoleData()
		runDataOnLoad(HeadTestData);
	}, [])


	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// HEAD TEST RUNS
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const [depthOfHeadTest, setDepthOfHeadTest] = useState('')
	const [numberOfHeadTestDetail, setNumberOfHeadTestDetail] = useState(0)

	const [status_run1, set_status_run1] = useState('inactive')
	const [status_run2, set_status_run2] = useState('inactive')
	const [status_run3, set_status_run3] = useState('inactive')

	const [data_type_run1, set_data_type_run1] = useState('')
	const [data_type_run2, set_data_type_run2] = useState('')
	const [data_type_run3, set_data_type_run3] = useState('')

	const [start_date_time_run1, set_start_date_time_run1] = useState('')
	const [start_date_time_run2, set_start_date_time_run2] = useState('')
	const [start_date_time_run3, set_start_date_time_run3] = useState('')

	const [start_date_time_text_run1, set_start_date_time_text_run1] = useState('')
	const [start_date_time_text_run2, set_start_date_time_text_run2] = useState('')
	const [start_date_time_text_run3, set_start_date_time_text_run3] = useState('')

	//number of elements
	const [numberOfRun1, setNumberOfRun1] = useState(0)
	const [numberOfRun2, setNumberOfRun2] = useState(0)
	const [numberOfRun3, setNumberOfRun3] = useState(0)

	useEffect(() => {
		if (start_date_time_run1 !== null && start_date_time_run1 !== undefined && start_date_time_run1 !== '') {
			set_start_date_time_text_run1('Started: ');
		} else {
			set_start_date_time_text_run1(''); // Reset to empty string
		}
		if (start_date_time_run2 !== null && start_date_time_run2 !== undefined && start_date_time_run2 !== '') {
			set_start_date_time_text_run2('Started: ');
		} else {
			set_start_date_time_text_run2(''); // Reset to empty string
		}
		if (start_date_time_run3 !== null && start_date_time_run3 !== undefined && start_date_time_run3 !== '') {
			set_start_date_time_text_run3('Started: ');
		} else {
			set_start_date_time_text_run3(''); // Reset to empty string
		}
	}, [start_date_time_run1, start_date_time_run2, start_date_time_run3]);

	//.............................................................................................
	//.RRRRRRRRRR...UUUU...UUUU..NNNN...NNNN.......DDDDDDDDD.......AAAAA...AATTTTTTTTTT.AAAAA......
	//.RRRRRRRRRRR..UUUU...UUUU..NNNNN..NNNN.......DDDDDDDDDD......AAAAA...AATTTTTTTTTT.AAAAA......
	//.RRRRRRRRRRR..UUUU...UUUU..NNNNN..NNNN.......DDDDDDDDDDD....AAAAAA...AATTTTTTTTTT.AAAAAA.....
	//.RRRR...RRRRR.UUUU...UUUU..NNNNNN.NNNN.......DDDD...DDDD....AAAAAAA......TTTT....TAAAAAA.....
	//.RRRR...RRRRR.UUUU...UUUU..NNNNNN.NNNN.......DDDD....DDDD..AAAAAAAA......TTTT....TAAAAAA.....
	//.RRRRRRRRRRR..UUUU...UUUU..NNNNNNNNNNN.......DDDD....DDDD..AAAAAAAA......TTTT...TTAA.AAAA....
	//.RRRRRRRRRRR..UUUU...UUUU..NNNNNNNNNNN.......DDDD....DDDD..AAAA.AAAA.....TTTT...TTAA.AAAA....
	//.RRRRRRRR.....UUUU...UUUU..NNNNNNNNNNN.......DDDD....DDDD.AAAAAAAAAA.....TTTT...TTAAAAAAAA...
	//.RRRR.RRRR....UUUU...UUUU..NNNNNNNNNNN.......DDDD....DDDD.AAAAAAAAAAA....TTTT..TTTAAAAAAAA...
	//.RRRR..RRRR...UUUU...UUUU..NNNN.NNNNNN.......DDDD...DDDDD.AAAAAAAAAAA....TTTT..TTTAAAAAAAA...
	//.RRRR..RRRRR..UUUUUUUUUUU..NNNN..NNNNN.......DDDDDDDDDDD.DAAA....AAAA....TTTT..TTTA....AAAA..
	//.RRRR...RRRRR..UUUUUUUUU...NNNN..NNNNN.......DDDDDDDDDD..DAAA.....AAAA...TTTT.TTTT.....AAAA..
	//.RRRR....RRRR...UUUUUUU....NNNN...NNNN.......DDDDDDDDD..DDAAA.....AAAA...TTTT.TTTT.....AAAA..
	//.............................................................................................





	const runDataOnLoad = (headTestData: any) => {

		if (headTestData) {
			setDepthOfHeadTest(headTestData.depth_of_borehole);
		}

	};



	//.........................................................................................................................
	//.RRRRRRRRRR...UUUU...UUUU..NNNN...NNNN.......DDDDDDDDD....EEEEEEEEEEE.ETTTTTTTTTT..AAAA.....AAII.IILL.........SSSSSSS....
	//.RRRRRRRRRRR..UUUU...UUUU..NNNNN..NNNN.......DDDDDDDDDD...EEEEEEEEEEE.ETTTTTTTTTT.AAAAAA....AAII.IILL........LSSSSSSSS...
	//.RRRRRRRRRRR..UUUU...UUUU..NNNNN..NNNN.......DDDDDDDDDDD..EEEEEEEEEEE.ETTTTTTTTTT.AAAAAA....AAII.IILL.......LLSSSSSSSSS..
	//.RRRR...RRRRR.UUUU...UUUU..NNNNNN.NNNN.......DDDD...DDDD..EEEE...........TTTT.....AAAAAAA...AAII.IILL.......LLSS...SSSS..
	//.RRRR...RRRRR.UUUU...UUUU..NNNNNN.NNNN.......DDDD....DDDD.EEEE...........TTTT....AAAAAAAA...AAII.IILL.......LLSSS........
	//.RRRRRRRRRRR..UUUU...UUUU..NNNNNNNNNNN.......DDDD....DDDD.EEEEEEEEEE.....TTTT....AAAAAAAA...AAII.IILL........LSSSSSS.....
	//.RRRRRRRRRRR..UUUU...UUUU..NNNNNNNNNNN.......DDDD....DDDD.EEEEEEEEEE.....TTTT....AAAA.AAAA..AAII.IILL.........SSSSSSSS...
	//.RRRRRRRR.....UUUU...UUUU..NNNNNNNNNNN.......DDDD....DDDD.EEEEEEEEEE.....TTTT...TAAAAAAAAA..AAII.IILL...........SSSSSSS..
	//.RRRR.RRRR....UUUU...UUUU..NNNNNNNNNNN.......DDDD....DDDD.EEEE...........TTTT...TAAAAAAAAAA.AAII.IILL..............SSSS..
	//.RRRR..RRRR...UUUU...UUUU..NNNN.NNNNNN.......DDDD...DDDDD.EEEE...........TTTT..TTAAAAAAAAAA.AAII.IILL.......LLSS...SSSS..
	//.RRRR..RRRRR..UUUUUUUUUUU..NNNN..NNNNN.......DDDDDDDDDDD..EEEEEEEEEEE....TTTT..TTAA....AAAA.AAII.IILLLLLLLLLLLSSSSSSSSS..
	//.RRRR...RRRRR..UUUUUUUUU...NNNN..NNNNN.......DDDDDDDDDD...EEEEEEEEEEE....TTTT..TTAA....AAAAAAAII.IILLLLLLLLL.LSSSSSSSSS..
	//.RRRR....RRRR...UUUUUUU....NNNN...NNNN.......DDDDDDDDD....EEEEEEEEEEE....TTTT.TTTAA.....AAAAAAII.IILLLLLLLLL..SSSSSSS....
	//.........................................................................................................................

	const Run1Status = () => {
		if (status_run1 === 'active') return (
			<div className="bg-blue w-20 text-center ml-1.5 rounded-t text-white pt-0.5"
				style={{ fontSize: '0.6rem', width: '3.8rem' }}>
				ACTIVE
			</div>
		)

		if (status_run1 === 'complete') return (
			<div className="bg-green-checkmark w-20 text-center ml-1.5 rounded-t text-white pt-0.5"
				style={{ fontSize: '0.6rem', width: '3.8rem' }}>
				COMPLETE
			</div>
		)

		return (
			<div className="bg-charcoal w-20 text-center ml-1.5 rounded-t text-white pt-0.5"
				style={{ fontSize: '0.6rem', width: '3.8rem' }}>
				INACTIVE
			</div>
		);
	}

	const Run2Status = () => {
		if (status_run2 === 'active') return (
			<div className="bg-blue w-20 text-center ml-1.5 rounded-t text-white pt-0.5"
				style={{ fontSize: '0.6rem', width: '3.8rem' }}>
				ACTIVE
			</div>
		)

		if (status_run2 === 'complete') return (
			<div className="bg-green-checkmark w-20 text-center ml-1.5 rounded-t text-white pt-0.5"
				style={{ fontSize: '0.6rem', width: '3.8rem' }}>
				COMPLETE
			</div>
		)

		return (
			<div className="bg-charcoal w-20 text-center ml-1.5 rounded-t text-white pt-0.5"
				style={{ fontSize: '0.6rem', width: '3.8rem' }}>
				INACTIVE
			</div>
		);
	}

	const Run3Status = () => {
		if (status_run3 === 'active') return (
			<div className="bg-blue w-20 text-center ml-1.5 rounded-t text-white pt-0.5"
				style={{ fontSize: '0.6rem', width: '3.8rem' }}>
				ACTIVE
			</div>
		)

		if (status_run3 === 'complete') return (
			<div className="bg-green-checkmark w-20 text-center ml-1.5 rounded-t text-white pt-0.5"
				style={{ fontSize: '0.6rem', width: '3.8rem' }}>
				COMPLETE
			</div>
		)

		return (
			<div className="bg-charcoal w-20 text-center ml-1.5 rounded-t text-white pt-0.5"
				style={{ fontSize: '0.6rem', width: '3.8rem' }}>
				INACTIVE
			</div>
		);
	}


	// useEffect(() => {
		
	// }, [start_date_time_run1]);

	// const YourComponent = () => {
	// 	// Define your state variables here
	// 	const [status_run1, set_status_run1] = useState(initialStatusRun1);
	// 	const [data_type_run1, set_data_type_run1] = useState(initialDataTypeRun1);
	// 	const [start_date_time_run1, set_start_date_time_run1] = useState(initialStartDateTimeRun1);
	// 	const [numberOfRun1, setNumberOfRun1] = useState(initialNumberOfRun1);
	  
	// 	// Other logic, functions, and useEffect hooks can also be placed here
	  
	// 	// Perform state updates and other logic
	// 	// This logic will run before rendering the JSX below
	// 	HeadTestData?.forEach((valueHeadTestDetail: any) => {
	// 	  let run1status = valueHeadTestDetail.runs[0].status;
	// 	  if (run1status !== status_run1) {
	// 		set_status_run1(run1status);
	// 	  }
	// 	  let run1datatype = valueHeadTestDetail.runs[0].data_type;
	// 	  if (run1datatype !== data_type_run1) {
	// 		set_data_type_run1(run1datatype);
	// 	  }
	// 	  let run1starttime = valueHeadTestDetail.runs[0].start_time;
	// 	  if (run1starttime !== start_date_time_run1) {
	// 		set_start_date_time_run1(run1starttime);
	// 		if (run1starttime !== null) {
	// 		  setNumberOfRun1(1);
	// 		}
	// 	  }
	// 	});






	//.......................................................................................................
	//...SSSSSSS.......AAAAA..AAAVV....VVVVVVEEEEEEEEE...... DDDDDDDD.......AAAA....AAATTTTTTTTT.TAAAA......
	//..SSSSSSSSS......AAAAA...AAVV....VVVVVVEEEEEEEEE...... DDDDDDDDD.....AAAAAA...AAATTTTTTTTT.TAAAA......
	//..SSSSSSSSSS....AAAAAA...AAVV...VVVVVVVEEEEEEEEE...... DDDDDDDDDD....AAAAAA...AAATTTTTTTTTTTAAAA......
	//.SSSSS..SSSS....AAAAAAA..AAVVV..VVVV.VVEE............. DD....DDDD....AAAAAAA......TTTT....TTAAAAA.....
	//.SSSSS.........AAAAAAAA...AVVV..VVVV.VVEE............. DD....DDDDD..AAAAAAAA......TTTT...TTTAAAAA.....
	//..SSSSSSS......AAAAAAAA...AVVV..VVVV.VVEEEEEEEEE...... DD.....DDDD..AAAAAAAA......TTTT...TTTAAAAA.....
	//...SSSSSSSSS...AAAA.AAAA..AVVV.VVVV..VVEEEEEEEEE...... DD.....DDDD..AAAA.AAAA.....TTTT...TTTA.AAAA....
	//.....SSSSSSS..AAAAAAAAAA...VVVVVVVV..VVEEEEEEEEE...... DD.....DDDD.DAAAAAAAAA.....TTTT..TTTTAAAAAA....
	//........SSSSS.AAAAAAAAAAA..VVVVVVVV..VVEE............. DD....DDDDD.DAAAAAAAAAA....TTTT..TTTTAAAAAAA...
	//.SSSS....SSSS.AAAAAAAAAAA..VVVVVVV...VVEE............. DD....DDDD.DDAAAAAAAAAA....TTTT..TTTTAAAAAAA...
	//.SSSSSSSSSSSSSAAA....AAAA...VVVVVV...VVEEEEEEEEEE..... DDDDDDDDDD.DDAA....AAAA....TTTT.TTTT....AAAA...
	//..SSSSSSSSSS.SAAA.....AAAA..VVVVVV...VVEEEEEEEEEE..... DDDDDDDDD..DDAA....AAAAA...TTTT.TTTT.....AAAA..
	//...SSSSSSSS.SSAAA.....AAAA..VVVVV....VVEEEEEEEEEE..... DDDDDDDD..DDDAA.....AAAA...TTTTTTTTT.....AAAA..
	//.......................................................................................................




	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// SAVE HOLE DATA
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	function saveAction(value: any, type: string, hide: any) {

		const saveToIDB = useSetIndexedDBValues
		const updateIDB = useUpdateIndexedDBValues
		const deleteFromIDB = useDeleteIndexedDBValue

		value['borehole_guid'] = borehole_guid;
		value['type'] = type;

		switch (type) {

			case 'Comments':
				const submitCommentsData = {
					"task_type": "action_comment",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value
					}
				}

				if (value['update']) {
					const newState = CommentsData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setCommentsData(newState)
					del(`drillit-actions/${value['guid']}-update`)
					updateIDB(state, `actions/${value['guid']}`, 'CommentsData', value['guid'], 'comment', submitCommentsData)
				} else if (value['delete']) {
					const newState = CommentsData.filter((obj) => obj.guid !== value['guid']);
					setCommentsData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'CommentsData', value['guid'], 'comment', submitCommentsData)
				} else {
					setCommentsData(prevState => [...prevState, value])
					saveToIDB(submitCommentsData, 'action', true)
				}

				break;

			case 'HeadTest':
				const submitHeadTestData = {
					"task_type": "action_head_test",
					"hole_type_id": starting_hole_type_id,
					"hole_location_guid": value['borehole_guid'],
					"data": {
						...value,
						"ht_indentifier": borehole_name,
						"weather_details": selectedWeather,
					}
				}

				if (value['update']) {
					const newState = HeadTestData.map((obj: any) => obj.guid === value['guid'] ? value : obj);
					setHeadTestData(newState)
					del(`drillit-actions/${value['guid']}-update`)
					updateIDB(state, `actions/${value['guid']}`, 'HeadTestData', value['guid'], 'head_test', submitHeadTestData)
				} else if (value['delete']) {
					const newState = HeadTestData.filter((obj) => obj.guid !== value['guid']);
					setHeadTestData(newState);
					deleteFromIDB(state, `actions/${value['guid']}`, 'HeadTestData', value['guid'], 'head_test', submitHeadTestData)
				} else {
					setHeadTestData(prevState => [...prevState, value])
					saveToIDB(submitHeadTestData, 'action', true)
				}

				break;
		}

		saveHoleData() 

		// // // Set hole to active if not already
		// if (hole_status === '1') {
		// 	// activateHole(borehole_guid, project_guid)
		// 	// activateHole(borehole_guid, project_guid)

		// 	updateHoleInformation(
		// 		borehole_guid,
		// 		boreholeName,
		// 		leadDriller,
		// 		leadDrillerID,
		// 		leadDrillerCSCS,
		// 		secondMan,
		// 		secondManCSCS,
		// 		'', // plantGuid
		// 		'', // plantName
		// 		'', // sptGUID
		// 		hole_barcode,
		// 		'', // borehole_depthOverall
		// 		activityTerminationReason,
		// 		project_guid,
		// 		'2' // hole_status
		// 	)
		// 	getHoleData()
		// }
	}

	//...............................................................................................................
	//.IIIII.DDDDDDDDD....BBBBBBBBBB........UUUU...UUUU..PPPPPPPPP...DDDDDDDDD.......AAAAA...AATTTTTTTTTTEEEEEEEEEE..
	//.IIIII.DDDDDDDDDD...BBBBBBBBBBB.......UUUU...UUUU..PPPPPPPPPP..DDDDDDDDDD......AAAAA...AATTTTTTTTTTEEEEEEEEEE..
	//.IIIII.DDDDDDDDDDD..BBBBBBBBBBB.......UUUU...UUUU..PPPPPPPPPPP.DDDDDDDDDDD....AAAAAA...AATTTTTTTTTTEEEEEEEEEE..
	//.IIIII.DDDD...DDDD..BBBB...BBBB.......UUUU...UUUU..PPPP...PPPP.DDDD...DDDD....AAAAAAA......TTTT...TEEE.........
	//.IIIII.DDDD....DDDD.BBBB...BBBB.......UUUU...UUUU..PPPP...PPPP.DDDD....DDDD..AAAAAAAA......TTTT...TEEE.........
	//.IIIII.DDDD....DDDD.BBBBBBBBBBB.......UUUU...UUUU..PPPPPPPPPPP.DDDD....DDDD..AAAAAAAA......TTTT...TEEEEEEEEEE..
	//.IIIII.DDDD....DDDD.BBBBBBBBBB........UUUU...UUUU..PPPPPPPPPP..DDDD....DDDD..AAAA.AAAA.....TTTT...TEEEEEEEEEE..
	//.IIIII.DDDD....DDDD.BBBBBBBBBBB.......UUUU...UUUU..PPPPPPPPP...DDDD....DDDD.AAAAAAAAAA.....TTTT...TEEEEEEEEEE..
	//.IIIII.DDDD....DDDD.BBBB....BBBB......UUUU...UUUU..PPPP........DDDD....DDDD.AAAAAAAAAAA....TTTT...TEEE.........
	//.IIIII.DDDD...DDDDD.BBBB....BBBB......UUUU...UUUU..PPPP........DDDD...DDDDD.AAAAAAAAAAA....TTTT...TEEE.........
	//.IIIII.DDDDDDDDDDD..BBBBBBBBBBBB......UUUUUUUUUUU..PPPP........DDDDDDDDDDD.DAAA....AAAA....TTTT...TEEEEEEEEEE..
	//.IIIII.DDDDDDDDDD...BBBBBBBBBBB........UUUUUUUUU...PPPP........DDDDDDDDDD..DAAA.....AAAA...TTTT...TEEEEEEEEEE..
	//.IIIII.DDDDDDDDD....BBBBBBBBBB..........UUUUUUU....PPPP........DDDDDDDDD..DDAAA.....AAAA...TTTT...TEEEEEEEEEE..
	//...............................................................................................................

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_comment = CommentsData;
				return val
			})
		}
	}, [CommentsData])

	useEffect(() => {
		if(starting_hole_type_id && borehole_guid) {
			update(`drillit-actions/${starting_hole_type_id}/${borehole_guid}`, (val) => {
				val.hole_data.action_head_test = HeadTestData;
				return val
			})
		}
	}, [HeadTestData])














	//...................................................................................................
	//.HHHH...HHHH....OOOOOOO.....LLLL.......EEEEEEEEEEE..... III.INNN...NNNN..NFFFFFFFFF...OOOOOOO.....
	//.HHHH...HHHH...OOOOOOOOOO...LLLL.......EEEEEEEEEEE..... III.INNNN..NNNN..NFFFFFFFFF..OOOOOOOOOO...
	//.HHHH...HHHH..OOOOOOOOOOOO..LLLL.......EEEEEEEEEEE..... III.INNNN..NNNN..NFFFFFFFFF.OOOOOOOOOOOO..
	//.HHHH...HHHH..OOOOO..OOOOO..LLLL.......EEEE............ III.INNNNN.NNNN..NFFF.......OOOOO..OOOOO..
	//.HHHH...HHHH.HOOOO....OOOOO.LLLL.......EEEE............ III.INNNNN.NNNN..NFFF......FOOOO....OOOO..
	//.HHHHHHHHHHH.HOOO......OOOO.LLLL.......EEEEEEEEEE...... III.INNNNNNNNNN..NFFFFFFFF.FOOO......OOO..
	//.HHHHHHHHHHH.HOOO......OOOO.LLLL.......EEEEEEEEEE...... III.INNNNNNNNNN..NFFFFFFFF.FOOO......OOO..
	//.HHHHHHHHHHH.HOOO......OOOO.LLLL.......EEEEEEEEEE...... III.INNNNNNNNNN..NFFFFFFFF.FOOO......OOO..
	//.HHHH...HHHH.HOOOO....OOOOO.LLLL.......EEEE............ III.INNNNNNNNNN..NFFF......FOOOO....OOOO..
	//.HHHH...HHHH..OOOOO..OOOOO..LLLL.......EEEE............ III.INNN.NNNNNN..NFFF.......OOOOO..OOOOO..
	//.HHHH...HHHH..OOOOOOOOOOOO..LLLLLLLLLL.EEEEEEEEEEE..... III.INNN..NNNNN..NFFF.......OOOOOOOOOOOO..
	//.HHHH...HHHH...OOOOOOOOOO...LLLLLLLLLL.EEEEEEEEEEE..... III.INNN..NNNNN..NFFF........OOOOOOOOOO...
	//.HHHH...HHHH.....OOOOOO.....LLLLLLLLLL.EEEEEEEEEEE..... III.INNN...NNNN..NFFF..........OOOOOO.....
	//...................................................................................................

	//number of elements
	const [numberOfComments, setNumberOfComments] = useState(0)

	//depths of elementsHeadTestDetail
	const [depthHeadTestDetail, setHeadTestDetail] = useState(0)

	const [borehole_depth, setBorehole_depth] = useState('')


	//..............................................................................
	//....CCCCCCC......OOOOOOO.....UUUU...UUUU..NNNN...NNNN..TTTTTTTTTTS.SSSSSSS....
	//...CCCCCCCCC....OOOOOOOOOO...UUUU...UUUU..NNNNN..NNNN..TTTTTTTTTTSSSSSSSSSS...
	//..CCCCCCCCCCC..OOOOOOOOOOOO..UUUU...UUUU..NNNNN..NNNN..TTTTTTTTTTSSSSSSSSSSS..
	//..CCCC...CCCCC.OOOOO..OOOOO..UUUU...UUUU..NNNNNN.NNNN.....TTTT...SSSSS..SSSS..
	//.CCCC.....CCC.OOOOO....OOOOO.UUUU...UUUU..NNNNNN.NNNN.....TTTT...SSSSS........
	//.CCCC.........OOOO......OOOO.UUUU...UUUU..NNNNNNNNNNN.....TTTT....SSSSSSS.....
	//.CCCC.........OOOO......OOOO.UUUU...UUUU..NNNNNNNNNNN.....TTTT.....SSSSSSSSS..
	//.CCCC.........OOOO......OOOO.UUUU...UUUU..NNNNNNNNNNN.....TTTT.......SSSSSSS..
	//.CCCC.....CCC.OOOOO....OOOOO.UUUU...UUUU..NNNNNNNNNNN.....TTTT..........SSSS..
	//..CCCC...CCCCC.OOOOO..OOOOO..UUUU...UUUU..NNNN.NNNNNN.....TTTT...SSSS....SSS..
	//..CCCCCCCCCCC..OOOOOOOOOOOO..UUUUUUUUUUU..NNNN..NNNNN.....TTTT...SSSSSSSSSSS..
	//...CCCCCCCCCC...OOOOOOOOOO....UUUUUUUUU...NNNN..NNNNN.....TTTT....SSSSSSSSSS..
	//....CCCCCCC.......OOOOOO.......UUUUUUU....NNNN...NNNN.....TTTT.....SSSSSSSS...
	//..............................................................................


	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - Comments
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateCounts_Comments = (filteredData: any[]) => {

		let totalComments = 0

		filteredDataComments.forEach((valueComments: any) => {
			const guid = valueComments.guid
			if (guid) { totalComments++ }
		});

		return { totalComments }
	}

	// Filter the data
	const filteredDataComments = CommentsData?.filter((action: any) => {
		return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	}) || []

	// Calculate type counts for the filtered data
	const countComments = calculateCounts_Comments(filteredDataComments);
	const { totalComments } = countComments

	if (totalComments !== numberOfComments) { setNumberOfComments(totalComments) }

	const [totalStandingTime, setTotalStandingTime] = useState(0)
	const [totalDayworks, setTotalDayworks] = useState(0)

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Calculate the guid counts for the filtered data - HeadTestDetail
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const calculateCounts_HeadTestDetail = (filteredData: any[]) => {

		let totalHeadTestDetail = 0

		filteredDataHeadTestDetail.forEach((valueHeadTestDetail: any) => {
			const guid = valueHeadTestDetail.guid
			if (guid) { totalHeadTestDetail++ }
		});

		return { totalHeadTestDetail }
	}

	// Filter the data
	// const filteredDataHeadTestDetail = HeadTestData?.filter((action: any) => {
	// 	return datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate));
	// }) || [];
	const filteredDataHeadTestDetail = HeadTestData || [];

	// Calculate type counts for the filtered data
	const countHeadTestDetail = calculateCounts_HeadTestDetail(filteredDataHeadTestDetail);
	const { totalHeadTestDetail } = countHeadTestDetail

	if (totalHeadTestDetail !== numberOfHeadTestDetail) { setNumberOfHeadTestDetail(totalHeadTestDetail) }


	// // Function to format the date string to "DD/MM/YYYY"
	// const formatDate = (dateString:any) => {
	// 	const date = new Date(dateString);
	// 	const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
	// 	return new Intl.DateTimeFormat('en-GB', options).format(date);
	// };









	//...........................................................................
	//.DDDDDDDDD....EEEEEEEEEEE.EPPPPPPPP...PTTTTTTTTTTTHHH...HHHH...SSSSSSS.....
	//.DDDDDDDDDD...EEEEEEEEEEE.EPPPPPPPPP..PTTTTTTTTTTTHHH...HHHH..HSSSSSSSS....
	//.DDDDDDDDDDD..EEEEEEEEEEE.EPPPPPPPPPP.PTTTTTTTTTTTHHH...HHHH..HSSSSSSSSS...
	//.DDDD...DDDD..EEEE........EPPP...PPPP....TTTT....THHH...HHHH.HHSSS..SSSS...
	//.DDDD....DDDD.EEEE........EPPP...PPPP....TTTT....THHH...HHHH.HHSSS.........
	//.DDDD....DDDD.EEEEEEEEEE..EPPPPPPPPPP....TTTT....THHHHHHHHHH..HSSSSSS......
	//.DDDD....DDDD.EEEEEEEEEE..EPPPPPPPPP.....TTTT....THHHHHHHHHH...SSSSSSSSS...
	//.DDDD....DDDD.EEEEEEEEEE..EPPPPPPPP......TTTT....THHHHHHHHHH.....SSSSSSS...
	//.DDDD....DDDD.EEEE........EPPP...........TTTT....THHH...HHHH........SSSSS..
	//.DDDD...DDDDD.EEEE........EPPP...........TTTT....THHH...HHHH.HHSS....SSSS..
	//.DDDDDDDDDDD..EEEEEEEEEEE.EPPP...........TTTT....THHH...HHHH.HHSSSSSSSSSS..
	//.DDDDDDDDDD...EEEEEEEEEEE.EPPP...........TTTT....THHH...HHHH..HSSSSSSSSS...
	//.DDDDDDDDD....EEEEEEEEEEE.EPPP...........TTTT....THHH...HHHH...SSSSSSSS....
	//...........................................................................



	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// CALCULATE MAX DEPTHS
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	/////////////////////////////////////////////////////////
	// HeadTestData
	const filteredHeadTestDetailData = HeadTestData?.filter((action: any) => {
		const actionDate = new Date(action.time_of_action);
		const endOfDaySelectedDate = new Date(selectedDate);
		endOfDaySelectedDate.setHours(23, 59, 59, 999); // Set to end of the day
		return actionDate <= endOfDaySelectedDate;
	}) || []

	let maxHeadTestDetailDepth
	if (filteredHeadTestDetailData.length > 0) {
		const maxHeadTestDetailDepthFiltered = filteredHeadTestDetailData.reduce((maxDepth, action) => {
			return Math.max(maxDepth, action.depth_of_borehole)
		}, -Infinity)
		maxHeadTestDetailDepth = maxHeadTestDetailDepthFiltered
	} else {
		maxHeadTestDetailDepth = 0 // Set to 0 when there's no data
	}

	if (maxHeadTestDetailDepth !== depthHeadTestDetail) {
		setHeadTestDetail(maxHeadTestDetailDepth)
	}


	// useEffect(() => {
	// 	// console.log('headTests on change:', headTests);
	// }, [HeadTestArray]);

	// const HeadTestArray = AllHoleData?.hole_data?.action_head_test;
	// 		const unpackedHeadTest = typeof HeadTestArray !== 'undefined' ? HeadTestArray : [];
	// 		setHeadTestData(unpackedHeadTest);

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// SET ALL DEPTHS
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const formattedDepthHeadTestDetail = depthHeadTestDetail.toFixed(2); // Format to 2 decimal places
	const formattedDepthHeadTestDetailString = formattedDepthHeadTestDetail.toString();
	if (formattedDepthHeadTestDetailString !== borehole_depth) { setBorehole_depth(formattedDepthHeadTestDetailString) }

	console.log('HeadTestData:', HeadTestData)
	console.log('depthHeadTestDetail:', depthHeadTestDetail)
	console.log('borehole_depth:', borehole_depth)







	//...........................................................................................................
	//....CCCCCCC......OOOOOOO....MMMMMM...MMMMMMMMMMMM...MMMMMM.EEEEEEEEEEE.NNNN...NNNN..TTTTTTTTTTS.SSSSSSS....
	//...CCCCCCCCC....OOOOOOOOOO..MMMMMM...MMMMMMMMMMMM...MMMMMM.EEEEEEEEEEE.NNNNN..NNNN..TTTTTTTTTTSSSSSSSSSS...
	//..CCCCCCCCCCC..OOOOOOOOOOOO.MMMMMM...MMMMMMMMMMMM...MMMMMM.EEEEEEEEEEE.NNNNN..NNNN..TTTTTTTTTTSSSSSSSSSSS..
	//..CCCC...CCCCC.OOOOO..OOOOO.MMMMMMM.MMMMMMMMMMMMMM.MMMMMMM.EEEE........NNNNNN.NNNN.....TTTT...SSSSS..SSSS..
	//.CCCC.....CCC.OOOOO....OOOOOMMMMMMM.MMMMMMMMMMMMMM.MMMMMMM.EEEE........NNNNNN.NNNN.....TTTT...SSSSS........
	//.CCCC.........OOOO......OOOOMMMMMMM.MMMMMMMMMMMMMM.MMMMMMM.EEEEEEEEEE..NNNNNNNNNNN.....TTTT....SSSSSSS.....
	//.CCCC.........OOOO......OOOOMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM.EEEEEEEEEE..NNNNNNNNNNN.....TTTT.....SSSSSSSSS..
	//.CCCC.........OOOO......OOOOMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM.EEEEEEEEEE..NNNNNNNNNNN.....TTTT.......SSSSSSS..
	//.CCCC.....CCC.OOOOO....OOOOOMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM.EEEE........NNNNNNNNNNN.....TTTT..........SSSS..
	//..CCCC...CCCCC.OOOOO..OOOOO.MMMM.MMMMM.MMMMMMMM.MMMMM.MMMM.EEEE........NNNN.NNNNNN.....TTTT...SSSS....SSS..
	//..CCCCCCCCCCC..OOOOOOOOOOOO.MMMM.MMMMM.MMMMMMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN..NNNNN.....TTTT...SSSSSSSSSSS..
	//...CCCCCCCCCC...OOOOOOOOOO..MMMM.MMMMM.MMMMMMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN..NNNNN.....TTTT....SSSSSSSSSS..
	//....CCCCCCC.......OOOOOO....MMMM.MMMMM.MMMMMMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN...NNNN.....TTTT.....SSSSSSSS...
	//...........................................................................................................












	//............................................................................................
	//.WWWW..WWWWW...WWWEEEEEEEEEEEE....AAAAA...ATTTTTTTTTTTHHH....HHHH.HEEEEEEEEEE.ERRRRRRRRRR...
	//.WWWW..WWWWW..WWWW.EEEEEEEEEEE....AAAAA...ATTTTTTTTTTTHHH....HHHH.HEEEEEEEEEE.ERRRRRRRRRRR..
	//.WWWW..WWWWWW.WWWW.EEEEEEEEEEE...AAAAAA...ATTTTTTTTTTTHHH....HHHH.HEEEEEEEEEE.ERRRRRRRRRRR..
	//.WWWW.WWWWWWW.WWWW.EEEE..........AAAAAAA......TTTT...THHH....HHHH.HEEE........ERRR....RRRR..
	//.WWWW.WWWWWWW.WWWW.EEEE.........AAAAAAAA......TTTT...THHH....HHHH.HEEE........ERRR....RRRR..
	//..WWWWWWWWWWW.WWW..EEEEEEEEEE...AAAAAAAA......TTTT...THHHHHHHHHHH.HEEEEEEEEEE.ERRRRRRRRRRR..
	//..WWWWWWW.WWWWWWW..EEEEEEEEEE...AAAA.AAAA.....TTTT...THHHHHHHHHHH.HEEEEEEEEEE.ERRRRRRRRRR...
	//..WWWWWWW.WWWWWWW..EEEEEEEEEE..AAAAAAAAAA.....TTTT...THHHHHHHHHHH.HEEEEEEEEEE.ERRRRRRRR.....
	//..WWWWWWW.WWWWWWW..EEEE........AAAAAAAAAAA....TTTT...THHH....HHHH.HEEE........ERRR.RRRRR....
	//..WWWWWWW.WWWWWWW..EEEE........AAAAAAAAAAA....TTTT...THHH....HHHH.HEEE........ERRR..RRRRR...
	//...WWWWW...WWWWW...EEEEEEEEEEEAAAA....AAAA....TTTT...THHH....HHHH.HEEEEEEEEEEEERRR...RRRRR..
	//...WWWWW...WWWWW...EEEEEEEEEEEAAAA.....AAAA...TTTT...THHH....HHHH.HEEEEEEEEEEEERRR....RRRR..
	//...WWWWW...WWWWW...EEEEEEEEEEEAAAA.....AAAA...TTTT...THHH....HHHH.HEEEEEEEEEEEERRR.....RRR..
	//............................................................................................

	let weatherIcon = ''
	getWeatherList().map((weather) => {
		if (weather.label === selectedWeather)
			weatherIcon = weather.icon
	})
	

	const [boreholeWeather, setBoreholeWeather] = useState(selectedWeather)

	useEffect(() => {
		if(borehole_guid){
			cachedHoleInformation(`hole-locations/${borehole_guid}`, (holeData: any) => {
				if (holeData) {
					setBoreholeWeather(holeData.weather); // HOLE WEATHER
				}
			});
		}
	}, [borehole_guid]);

	useEffect(() => {
		if(borehole_guid){
			updateWeather(
				borehole_guid,
				selectedWeather
			)
		}
	}, [selectedWeather, borehole_guid]);

















	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// HEAD TEST RUNS
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////













	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// HEAD TEST LOG DATA
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const maxRows = 25; // Total number of rows in log

	// RUN #1
	const logRowsRun1 = [];





















	//..................................................................................................................
	//.IIIII.NNNN...NNNN..FFFFFFFFFF...OOOOOOO............CCCCCCC....HHHH...HHHH..EEEEEEEEEEE...CCCCCCC....KKKK...KKKK..
	//.IIIII.NNNNN..NNNN..FFFFFFFFFF..OOOOOOOOOO.........CCCCCCCCC...HHHH...HHHH..EEEEEEEEEEE..CCCCCCCCC...KKKK..KKKKK..
	//.IIIII.NNNNN..NNNN..FFFFFFFFFF.OOOOOOOOOOOO.......CCCCCCCCCCC..HHHH...HHHH..EEEEEEEEEEE.CCCCCCCCCCC..KKKK.KKKKK...
	//.IIIII.NNNNNN.NNNN..FFFF.......OOOOO..OOOOO.......CCCC...CCCCC.HHHH...HHHH..EEEE........CCCC...CCCCC.KKKKKKKKK....
	//.IIIII.NNNNNN.NNNN..FFFF......OOOOO....OOOOO.....CCCC.....CCC..HHHH...HHHH..EEEE.......ECCC.....CCC..KKKKKKKK.....
	//.IIIII.NNNNNNNNNNN..FFFFFFFFF.OOOO......OOOO.....CCCC..........HHHHHHHHHHH..EEEEEEEEEE.ECCC..........KKKKKKKK.....
	//.IIIII.NNNNNNNNNNN..FFFFFFFFF.OOOO......OOOO.....CCCC..........HHHHHHHHHHH..EEEEEEEEEE.ECCC..........KKKKKKKK.....
	//.IIIII.NNNNNNNNNNN..FFFFFFFFF.OOOO......OOOO.....CCCC..........HHHHHHHHHHH..EEEEEEEEEE.ECCC..........KKKKKKKKK....
	//.IIIII.NNNNNNNNNNN..FFFF......OOOOO....OOOOO.....CCCC.....CCC..HHHH...HHHH..EEEE.......ECCC.....CCC..KKKK.KKKKK...
	//.IIIII.NNNN.NNNNNN..FFFF.......OOOOO..OOOOO.......CCCC...CCCCC.HHHH...HHHH..EEEE........CCCC...CCCCC.KKKK..KKKK...
	//.IIIII.NNNN..NNNNN..FFFF.......OOOOOOOOOOOO.......CCCCCCCCCCC..HHHH...HHHH..EEEEEEEEEEE.CCCCCCCCCCC..KKKK..KKKKK..
	//.IIIII.NNNN..NNNNN..FFFF........OOOOOOOOOO.........CCCCCCCCCC..HHHH...HHHH..EEEEEEEEEEE..CCCCCCCCCC..KKKK...KKKK..
	//.IIIII.NNNN...NNNN..FFFF..........OOOOOO............CCCCCCC....HHHH...HHHH..EEEEEEEEEEE...CCCCCCC....KKKK...KKKK..
	//..................................................................................................................

	// useEffect(() => {
	// 	if (rig_name === 'Select Current Rig') {
	// 		setInformationCheck(false)
	// 	} else {
	// 		setInformationCheck(true)
	// 	}
	// })







	//..........................................................................................................................
	//.UUUU...UUUU..PPPPPPPPP...PDDDDDDDD.......AAAAA...AAATTTTTTTTTTEEEEEEEEE...... I.IIINN...NNNN.NNNFFFFFFFF...OOOOOOO.....
	//.UUUU...UUUU..PPPPPPPPPP..PDDDDDDDDD......AAAAA...AAATTTTTTTTTTEEEEEEEEE...... I.IIINN...NNNN.NNNFFFFFFFF..OOOOOOOOOO...
	//.UUUU...UUUU..PPPPPPPPPPP.PDDDDDDDDDD....AAAAAA...AAATTTTTTTTTTEEEEEEEEE...... I.IIINNN..NNNN.NNNFFFFFFFF.FOOOOOOOOOOO..
	//.UUUU...UUUU..PPPP...PPPP.PDDD...DDDD....AAAAAAA......TTTT...TTEE............. I.IIINNNN.NNNN.NNNF.......FFOOO...OOOOO..
	//.UUUU...UUUU..PPPP...PPPP.PDDD....DDDD..AAAAAAAA......TTTT...TTEE............. I.IIINNNN.NNNN.NNNF.......FFOO.....OOOO..
	//.UUUU...UUUU..PPPPPPPPPPP.PDDD....DDDD..AAAAAAAA......TTTT...TTEEEEEEEEE...... I.IIINNNNNNNNN.NNNFFFFFFF.FFOO......OOO..
	//.UUUU...UUUU..PPPPPPPPPP..PDDD....DDDD..AAAA.AAAA.....TTTT...TTEEEEEEEEE...... I.IIINNNNNNNNN.NNNFFFFFFF.FFOO......OOO..
	//.UUUU...UUUU..PPPPPPPPP...PDDD....DDDD.DAAAAAAAAA.....TTTT...TTEEEEEEEEE...... I.IIIN.NNNNNNN.NNNFFFFFFF.FFOO......OOO..
	//.UUUU...UUUU..PPPP........PDDD....DDDD.DAAAAAAAAAA....TTTT...TTEE............. I.IIIN.NNNNNNN.NNNF.......FFOO.....OOOO..
	//.UUUU...UUUU..PPPP........PDDD...DDDDD.DAAAAAAAAAA....TTTT...TTEE............. I.IIIN..NNNNNN.NNNF.......FFOOOO..OOOOO..
	//.UUUUUUUUUUU..PPPP........PDDDDDDDDDD.DDAA....AAAA....TTTT...TTEEEEEEEEEE..... I.IIIN..NNNNNN.NNNF........FOOOOOOOOOOO..
	//..UUUUUUUUU...PPPP........PDDDDDDDDD..DDAA.....AAAA...TTTT...TTEEEEEEEEEE..... I.IIIN...NNNNN.NNNF.........OOOOOOOOOO...
	//...UUUUUUU....PPPP........PDDDDDDDD..DDDAA.....AAAA...TTTT...TTEEEEEEEEEE..... I.IIIN....NNNN.NNNF..........OOOOOOO.....
	//..........................................................................................................................


	function getHoleData() {


		cachedHoleInformation(`hole-locations/${borehole_guid}`, (holeData: any) => {
			if (holeData) {
				console.log('holeData in getHoleData:', holeData)
				setLeadDriller(holeData.driller)
				setLeadDrillerID(holeData.driller_id)
				setLeadDrillerCSCS(holeData.driller_cscs);
				setSecondMan(holeData.second_man);
				setSecondManCSCS(holeData.second_man_cscs);
				
				setSavedRigValue(holeData.rig_id); // RIG GUID
				setPlantName(holeData.rig_name); // RIG NAME
				setSptGUID(holeData.spt_id); // SPT ID

				setBoreholeName(holeData.hole_name); // HOLE NAME
				setHole_barcode(holeData.hole_barcode); // BARCODE
				setActivityTerminationReason(holeData.activity_termination_reason); // TERMINATION REASON
				setHole_status(holeData.status); // HOLE STATUS
			} else {
				// // console.log(`**ERROR** HOLE DATA NOT LOADED. ID: ${borehole_guid}`);
			}
			// // console.log('holeData:', holeData);
		})

	}

		
	// function saveHoleData() {
	// 	// updateHoleInformation(
	// 	// 	borehole_guid,
	// 	// 	borehole_name,
	// 	// 	leadDriller,
	// 	// 	leadDrillerID, ///////
	// 	// 	leadDrillerCSCS,
	// 	// 	secondMan,
	// 	// 	secondManCSCS,
	// 	// 	plantGuid || '',
	// 	// 	plantName,
	// 	// 	sptGUID || '',
	// 	// 	hole_barcode,
	// 	// 	borehole_depthOverall,
	// 	// activityTerminationReason,
	// 	// 	) 
		
	// 	// hole_name: borehole_name,
	// 	// location_identifier: borehole_name,
	// 	// driller: leadDriller,
	// 	// driller_cscs: leadDrillerCSCS,
	// 	// second_man: secondMan,
	// 	// second_man_cscs: secondManCSCS,
	// 	// rig_id: plantGuid,
	// 	// rig_name: plantName,
	// 	// spt_id: sptGUID,
	// 	// hole_barcode: hole_barcode,
	// 	// final_hole_depth: borehole_depthOverall
	// }
	

	function saveHoleData() {
	
		// Attempt to delete the outdated hole information
		del(`drillit-hole-locations/updateInformation/${borehole_guid}-update`)
			.catch(error => {
				console.warn('Failed to delete outdated hole information (may not exist):', error);
			})
			.finally(() => {
				let hole_status_temp = '1'
				if (hole_status !== '3') {
					hole_status_temp = '2'
				} else {
					hole_status_temp = '3'
				}
				// Update the hole information
				updateHoleInformation(
					borehole_guid,
					boreholeName,
					leadDriller,
					leadDrillerID,
					leadDrillerCSCS,
					secondMan,
					secondManCSCS,
					'', //plantGuid || '',
					'', //plantName,
					'', //plantMakeModel || '',
					'', //sptGUID || '',
					hole_barcode,
					borehole_depth,
					activityTerminationReason,
					project_guid,
					hole_status_temp, // hole_status
					numberingType
				)
				.then(() => {
					// Fetch the updated hole data
					getHoleData();
				})
				.catch(error => {
					console.error('Failed to update hole information:', error);
				});
			});
	}














	return (

		<>


			{HeadTestData?.map((valueHeadTestDetail: any, index: number) => {

				// Update run1
				let run1status = valueHeadTestDetail.runs[0].status
				if (run1status !== status_run1) {
					set_status_run1(run1status);
				}
				let run1datatype = valueHeadTestDetail.runs[0].data_type
				if (run1datatype !== data_type_run1) {
					set_data_type_run1(run1datatype);
				}
				let run1starttime = valueHeadTestDetail.runs[0].start_time
				console.log('numberOfHeadTestDetail', numberOfHeadTestDetail)
				if (run1starttime !== start_date_time_run1) {
					set_start_date_time_run1(run1starttime);
					if (run1starttime !== null) {
						setNumberOfRun1(1)
					}
				}

				// // Update run2
				let run2status = valueHeadTestDetail.runs[1].status
				if (run2status !== status_run2) {
					set_status_run2(run2status);
				}
				let run2datatype = valueHeadTestDetail.runs[1].data_type
				if (run2datatype !== data_type_run2) {
					set_data_type_run2(run2datatype);
				}
				let run2starttime = valueHeadTestDetail.runs[1].start_time
				if (run2starttime !== start_date_time_run2) {
					set_start_date_time_run2(run2starttime);
					if (run2starttime !== null) {
						setNumberOfRun2(1)
					}

				}
				// // Update run3
				let run3status = valueHeadTestDetail.runs[2].status
				if (run3status !== status_run3) {
					set_status_run3(run3status);
				}
				let run3datatype = valueHeadTestDetail.runs[2].data_type
				if (run3datatype !== data_type_run3) {
					set_data_type_run3(run3datatype);
				}
				let run3starttime = valueHeadTestDetail.runs[2].start_time
				if (run3starttime !== start_date_time_run3) {
					set_start_date_time_run3(run3starttime);
					if (run3starttime !== null) {
						setNumberOfRun3(1)
					}
				}


			})}

			{/* //..............................................................................
//.HHHH...HHHH..EEEEEEEEEEE....AAAAA.....ADDDDDDDD....DEEEEEEEEEE.ERRRRRRRRR....
//.HHHH...HHHH..EEEEEEEEEEE....AAAAA.....ADDDDDDDDD...DEEEEEEEEEE.ERRRRRRRRRR...
//.HHHH...HHHH..EEEEEEEEEEE...AAAAAA.....ADDDDDDDDDD..DEEEEEEEEEE.ERRRRRRRRRR...
//.HHHH...HHHH..EEEE..........AAAAAAA....ADDD...DDDD..DEEE........ERRR...RRRRR..
//.HHHH...HHHH..EEEE.........AAAAAAAA....ADDD....DDDD.DEEE........ERRR...RRRRR..
//.HHHHHHHHHHH..EEEEEEEEEE...AAAAAAAA....ADDD....DDDD.DEEEEEEEEE..ERRRRRRRRRR...
//.HHHHHHHHHHH..EEEEEEEEEE...AAAA.AAAA...ADDD....DDDD.DEEEEEEEEE..ERRRRRRRRRR...
//.HHHHHHHHHHH..EEEEEEEEEE..EAAAAAAAAA...ADDD....DDDD.DEEEEEEEEE..ERRRRRRR......
//.HHHH...HHHH..EEEE........EAAAAAAAAAA..ADDD....DDDD.DEEE........ERRR.RRRR.....
//.HHHH...HHHH..EEEE........EAAAAAAAAAA..ADDD...DDDDD.DEEE........ERRR..RRRR....
//.HHHH...HHHH..EEEEEEEEEEEEEAA....AAAA..ADDDDDDDDDD..DEEEEEEEEEE.ERRR..RRRRR...
//.HHHH...HHHH..EEEEEEEEEEEEEAA.....AAAA.ADDDDDDDDD...DEEEEEEEEEE.ERRR...RRRRR..
//.HHHH...HHHH..EEEEEEEEEEEEEAA.....AAAA.ADDDDDDDD....DEEEEEEEEEE.ERRR....RRRR..
//.............................................................................. */}

			<Header toggleSidebar={toggleSidebar}
				middle={
					<ProjectHeader projectId={project_guid}
						information_check={informationCheck}
						holeType="HT"
						holeName={borehole_name}
						companyGuid={company_guid}
						company_id={companyID}
						projectGuid={project_guid}
						activeDates={active_dates}
						lastActiveDate={lastActiveDate}

						project_start_date={project_start_date}
						site_description={site_description}
						key_site_features={key_site_features}
						site_history={site_history}
						key_hands={key_hands}
						expected_strata={expected_strata}
						notes={notes}
						equipment_required={equipment_required}
						project_client={client}
						client_contact={client_contact}
						client_phone={client_phone}
						client_email={client_email}
						engineer_name={engineer_name}
						engineer_phone={engineer_phone}
						engineer_email='' // missing db field
						rig_name=''
					/>
				}
			// companyName={company_name}
			/>








			{/* //.........................................................................
			//.PPPPPPPPP.....AAAA.....AAANN...NNNN.NNNEEEEEEEE.EEEL.........LSSSSSS....
			//.PPPPPPPPPP...AAAAAA....AAANN...NNNN.NNNEEEEEEEE.EEEL........LLSSSSSSS...
			//.PPPPPPPPPPP..AAAAAA....AAANNN..NNNN.NNNEEEEEEEE.EEEL.......LLLSSSSSSSS..
			//.PPPP...PPPP..AAAAAAA...AAANNNN.NNNN.NNNE........EEEL.......LLLS...SSSS..
			//.PPPP...PPPP.PAAAAAAA...AAANNNN.NNNN.NNNE........EEEL.......LLLSS........
			//.PPPPPPPPPPP.PAAAAAAA...AAANNNNNNNNN.NNNEEEEEEEE.EEEL........LLSSSSS.....
			//.PPPPPPPPPP..PAAA.AAAA..AAANNNNNNNNN.NNNEEEEEEEE.EEEL.........LSSSSSSS...
			//.PPPPPPPPP..PPAAAAAAAA..AAAN.NNNNNNN.NNNEEEEEEEE.EEEL...........SSSSSSS..
			//.PPPP.......PPAAAAAAAAA.AAAN.NNNNNNN.NNNE........EEEL..............SSSS..
			//.PPPP......PPPAAAAAAAAA.AAAN..NNNNNN.NNNE........EEEL.......LLLS...SSSS..
			//.PPPP......PPPA....AAAA.AAAN..NNNNNN.NNNEEEEEEEEEEEELLLLLLLLLLLSSSSSSSS..
			//.PPPP......PPPA....AAAAAAAAN...NNNNN.NNNEEEEEEEEEEEELLLLLLLL.LLSSSSSSSS..
			//.PPPP.....PPPPA.....AAAAAAAN....NNNN.NNNEEEEEEEEEEEELLLLLLLL..LSSSSSS....
			//......................................................................... */}


			{completingBorehole &&
				<Modal title="Mark as Complete" toggleModal={() => { setCompletingBorehole(false) }}>
					<div className="flex items-center flex-col pt-36">
						<div className="mb-14">
							Confirm you have completed activity on this Head Test
						</div>

						<div className="flex">
							<Button theme="secondary" size="lg" className="mr-8" onClick={() => { setCompletingBorehole(false) }}>Cancel</Button>

							<Button theme="primary" size="lg" onClick={() => {
								closeHole(borehole_guid, formattedDate, project_guid).then(() => { setRedirect(true) })
							}}>Confirm</Button>
						</div>
					</div>
				</Modal>
			}

			{showComments &&
				<Comments
					onClose={() => { setShowComments(false) }}
					onComplete={(value: any) => saveAction(value, 'Comments', setShowComments(false))}
					borehole={borehole_name}
					selectedDate={selectedDate}
				/>
			}

			{showCommentsEdit &&
				<Comments
					update={true}
					onClose={() => { setShowCommentsEdit(false) }}
					setShowCommentsEdit={setShowCommentsEdit}
					onComplete={(value: any) => saveAction(value, 'Comments', setShowCommentsEdit(false))}
					borehole={borehole_name}
					defaultValues={CommentsData[editingIndex]}
				/>
			}

			{showHeadTestDetail &&
				<HeadTestDetails
					onClose={() => { setShowHeadTestDetail(false) }}
					onComplete={(value: any) => {
						// console.log('Head Test returned:', value)
						runDataOnLoad(value)
						saveAction(value, 'HeadTest', setShowHeadTestDetail(false))
						// setShowHeadTestDetail(false)
					}}
					borehole={borehole_name}
					selectedDate={selectedDate}
				/>
			}

			{showHeadTestDetailEdit &&
				<HeadTestDetails
					update={true}
					onClose={() => { setShowHeadTestDetailEdit(false) }}
					setShowHeadTestDetailEdit={setShowHeadTestDetailEdit}
					onComplete={(value: any) => {
						// console.log('Head Test EDITED returned:', value)
						runDataOnLoad(value)
						saveAction(value, 'HeadTest', setShowHeadTestDetailEdit(false))
						// setShowHeadTestDetailEdit(false)
					}}
					borehole={borehole_name}
					defaultValues={HeadTestData[editingIndex]}
				/>
			}

			{showRun1 &&
				<HeadTest onClose={() => { setShowRun1(false) }}
					onComplete={(value: any) => {
						console.log('value returned from Head Test 1', value)
						runDataOnLoad(value);
						saveAction(value, 'HeadTest', setShowRun1(false))
						// setShowRun1(false)
					}}
					borehole={borehole_name}
					title="HT Run 1"
					run={1}
					defaultValues={HeadTestData[editingIndex]}
				/>
			}

			{showRun1Edit &&
				<HeadTest onClose={() => { setShowRun1Edit(false) }}
					update={true}
					onComplete={(value: any) => {
						saveAction(value, 'HeadTest', setShowRun1Edit(false))
					}}
					setShowRun1Edit={setShowRun1Edit}
					borehole={borehole_name}
					title="HT Run 1"
					run={1}
					defaultValues={HeadTestData[editingIndex]}
				/>
			}

			{showRun2 &&
				<HeadTest onClose={() => { setShowRun2(false) }}
					onComplete={(value: any) => {
						console.log('value returned from Head Test 2', value)
						runDataOnLoad(value);
						saveAction(value, 'HeadTest', setShowRun2(false))
						// setShowRun2(false)
					}}
					borehole={borehole_name}
					title="HT Run 2"
					// start_time={start_date_time_run2}
					// status={status_run2} // inactive /active /complete
					run={2}
					// data_type={data_type_run2}
					// depth_of_headtest={depthOfHeadTest}
					defaultValues={HeadTestData[editingIndex]}
				/>
			}

			{showRun2Edit &&
				<HeadTest onClose={() => { setShowRun2Edit(false) }}
					update={true}
					onComplete={(value: any) => {
						saveAction(value, 'HeadTest', setShowRun2Edit(false))
						// console.log('HeadTest returned:', value)
					}}
					setShowRun2Edit={setShowRun2Edit}
					borehole={borehole_name}
					title="HT Run 2"
					// start_time={start_date_time_run2}
					// status={status_run2} // inactive /active /complete
					run={2}
					// data_type={data_type_run2}
					// depth_of_headtest={depthOfHeadTest}
					defaultValues={HeadTestData[editingIndex]}
				/>
			}

			{showRun3 &&
				<HeadTest onClose={() => { setShowRun3(false) }}
					onComplete={(value: any) => {
						console.log('value returned from Head Test 3', value)
						runDataOnLoad(value);
						saveAction(value, 'HeadTest', setShowRun3(false))
						// setShowRun3(false)
					}}
					borehole={borehole_name}
					title="HT Run 3"
					// start_time={start_date_time_run3}
					// status={status_run3} // inactive /active /complete
					run={3}
					// data_type={data_type_run3}
					// depth_of_headtest={depthOfHeadTest}
					defaultValues={HeadTestData[editingIndex]}
				/>
			}

			{showRun3Edit &&
				<HeadTest onClose={() => { setShowRun3Edit(false) }}
					update={true}
					onComplete={(value: any) => {
						saveAction(value, 'HeadTest', setShowRun3Edit(false))
					}}
					setShowRun3Edit={setShowRun3Edit}
					borehole={borehole_name}
					title="HT Run 3"
					// start_time={start_date_time_run3}
					// status={status_run3} // inactive /active /complete
					run={3}
					// data_type={data_type_run3}
					// depth_of_headtest={depthOfHeadTest}
					defaultValues={HeadTestData[editingIndex]}
				/>
			}















			{/* //....................................................................................................................................
			//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNN...NNNN..UUUU...UUUU.......PPPPPPPPP.....AAAA.....AANNN...NNNN.NNEEEEEEEEE.EELL.........SSSSSSS....
			//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNNN..NNNN..UUUU...UUUU.......PPPPPPPPPP...AAAAAA....AANNN...NNNN.NNEEEEEEEEE.EELL........LSSSSSSSS...
			//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNNN..NNNN..UUUU...UUUU.......PPPPPPPPPPP..AAAAAA....AANNNN..NNNN.NNEEEEEEEEE.EELL.......LLSSSSSSSSS..
			//.MMMMMMM.MMMMMMM.EEEE........NNNNNN.NNNN..UUUU...UUUU.......PPPP...PPPP..AAAAAAA...AANNNNN.NNNN.NNEE........EELL.......LLSS...SSSS..
			//.MMMMMMM.MMMMMMM.EEEE........NNNNNN.NNNN..UUUU...UUUU.......PPPP...PPPP.AAAAAAAA...AANNNNN.NNNN.NNEE........EELL.......LLSSS........
			//.MMMMMMM.MMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU.......PPPPPPPPPPP.AAAAAAAA...AANNNNNNNNNN.NNEEEEEEEEE.EELL........LSSSSSS.....
			//.MMMMMMMMMMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU.......PPPPPPPPPP..AAAA.AAAA..AANNNNNNNNNN.NNEEEEEEEEE.EELL.........SSSSSSSS...
			//.MMMMMMMMMMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU.......PPPPPPPPP..PAAAAAAAAA..AANN.NNNNNNN.NNEEEEEEEEE.EELL...........SSSSSSS..
			//.MMMMMMMMMMMMMMM.EEEE........NNNNNNNNNNN..UUUU...UUUU.......PPPP.......PAAAAAAAAAA.AANN.NNNNNNN.NNEE........EELL..............SSSS..
			//.MMMM.MMMMM.MMMM.EEEE........NNNN.NNNNNN..UUUU...UUUU.......PPPP......PPAAAAAAAAAA.AANN..NNNNNN.NNEE........EELL.......LLSS...SSSS..
			//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN..NNNNN..UUUUUUUUUUU.......PPPP......PPAA....AAAA.AANN..NNNNNN.NNEEEEEEEEEEEELLLLLLLLLLLSSSSSSSSS..
			//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN..NNNNN...UUUUUUUUU........PPPP......PPAA....AAAAAAANN...NNNNN.NNEEEEEEEEEEEELLLLLLLLL.LSSSSSSSSS..
			//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN...NNNN....UUUUUUU.........PPPP.....PPPAA.....AAAAAANN....NNNN.NNEEEEEEEEEEEELLLLLLLLL..SSSSSSS....
			//.................................................................................................................................... */}

			{showNewHole &&
				<NewHole onClose={() => { setShowNewHole(false) }}
					holeType="HT"
					defaultValues={{
						// company_guid: project.company_guid,
						// project_guid: project_guid,
					}}
				/>
			}

			{showEmail &&
				<Email onClose={() => { setShowEmail(false) }}
				company_id={companyID}
				project_location={project_location}
				project_id={projectID}
				project_guid={project_guid}
				selected_date={selectedDate}
				/>
			}

			{showAGS &&
				<AGS onClose={() => { setShowAGS(false) }}
					project_start_date={project_start_date}
					project_id={project_id}
					company_id={companyID}
				/>
			}

			{showSaveLog &&
				<SaveLog onClose={() => { setShowSaveLog(false) }}
					boreholeName={borehole_name}
					holeType="HT"
					project_id={project_id}
					project_guid={project_guid}
					project_name={project_name}
					project_location={project_location}
					hole_type_id={starting_hole_type_id}
					driller_id={leadDrillerID}
					company_id={companyID}
					company_guid={company_guid}
					logDate={sheetDate}
					activeDates={active_dates}

					boreholeDepth={borehole_depth}
					hole_guid={hole_guid}
					endShift={false}
					holeStatus={hole_status}
					numberingType={numberingType}
				/>
			}

			{showPrintLabel &&
				<PrintLabel onClose={() => { setShowPrintLabel(false) }}
					borehole={borehole_name}
					sample={labelSample}
					depth={labelDepth}
					size='printLabel'
				/>
			}


			{userWarning &&
				<ModalSmall title="Log Information" toggleModal={() => { setUserWarning(false) }} confirm={false}>
					<div className="flex items-center flex-col pt-16">
						<div className="text-lg">
							This log belongs to:
						</div>
						<div className="text-3xl font-bold">
							{leadDriller}
						</div>
						<div className="text-lg mb-8">
							{randomNegPhrases}
						</div>

						<div className="flex">
							<Button theme="primary" size="lg" className="mb-16" onClick={() => { setUserWarning(false) }}>OK</Button>
						</div>
					</div>
				</ModalSmall>
			}

			{/* //.................................................................................................
				//.EEEEEEEEEEE.ENNN...NNNN..NDDDDDDDD..........SSSSSSS....SHHH...HHHH.HHIII.IFFFFFFFFF.FTTTTTTTTT..
				//.EEEEEEEEEEE.ENNNN..NNNN..NDDDDDDDDD........ SSSSSSSS...SHHH...HHHH.HHIII.IFFFFFFFFF.FTTTTTTTTT..
				//.EEEEEEEEEEE.ENNNN..NNNN..NDDDDDDDDDD....... SSSSSSSSS..SHHH...HHHH.HHIII.IFFFFFFFFF.FTTTTTTTTT..
				//.EEEE........ENNNNN.NNNN..NDDD...DDDD...... SSS..SSSS..SHHH...HHHH.HHIII.IFFF..........TTTT.....
				//.EEEE........ENNNNN.NNNN..NDDD....DDDD..... SSS........SHHH...HHHH.HHIII.IFFF..........TTTT.....
				//.EEEEEEEEEE..ENNNNNNNNNN..NDDD....DDDD...... SSSSSS.....SHHHHHHHHHH.HHIII.IFFFFFFFF.....TTTT.....
				//.EEEEEEEEEE..ENNNNNNNNNN..NDDD....DDDD.......SSSSSSSSS..SHHHHHHHHHH.HHIII.IFFFFFFFF.....TTTT.....
				//.EEEEEEEEEE..ENNNNNNNNNN..NDDD....DDDD.........SSSSSSS..SHHHHHHHHHH.HHIII.IFFFFFFFF.....TTTT.....
				//.EEEE........ENNNNNNNNNN..NDDD....DDDD............SSSSS.SHHH...HHHH.HHIII.IFFF..........TTTT.....
				//.EEEE........ENNN.NNNNNN..NDDD...DDDDD..... SS....SSSS.SHHH...HHHH.HHIII.IFFF..........TTTT.....
				//.EEEEEEEEEEE.ENNN..NNNNN..NDDDDDDDDDD...... SSSSSSSSSS.SHHH...HHHH.HHIII.IFFF..........TTTT.....
				//.EEEEEEEEEEE.ENNN..NNNNN..NDDDDDDDDD........ SSSSSSSSS..SHHH...HHHH.HHIII.IFFF..........TTTT.....
				//.EEEEEEEEEEE.ENNN...NNNN..NDDDDDDDD..........SSSSSSSS...SHHH...HHHH.HHIII.IFFF..........TTTT.....
				//................................................................................................. */}
	
				{showEndShiftSaveLog &&
					<SaveLog onClose={() => { 
							setShowEndShiftSaveLog(false) 
							setShowEndShiftAGS(true) 
						}}
						boreholeName={boreholeName}
						holeType="HT"
						project_id={project_id}
						project_guid={project_guid}
						project_name={project_name}
						project_location={project_location}
						hole_type_id={starting_hole_type_id}
						driller_id={leadDrillerID}
						company_id={companyID}
						logDate={sheetDate}
						activeDates={active_dates}
	
						boreholeDepth={borehole_depth}
						hole_guid={hole_guid}
						endShift={true}
					/>
				}
	
				{showEndShiftAGS &&
					<AGS onClose={() => { 
							setShowEndShiftAGS(false)  
							setShowEndShiftEmail(true) 
						}}
						project_start_date={project_start_date}
						project_id={project_id}
						company_id={companyID}
					/>
				}
	
				{showEndShiftEmail &&
					<Email onClose={() => { 
							setShowEndShiftEmail(false) 
							setShowEndShiftLogout(true) 
						}}
						company_id={companyID}
						project_location={project_location}
						project_id={projectID}
						project_guid={project_guid}
						selected_date={selectedDate}
					/>
				}
	
				{showEndShiftLogout &&
					<ModalSmall title="Log Out" toggleModal={() => { setShowEndShiftLogout(false) }} confirm={false}>
						<div className="flex items-center flex-col pt-10">
							
							<Link to={`/logout`}>
								<div className="bg-blue rounded w-48 p-2 mb-10 text-center text-white font-bold text-lg">
									<img src={icon_endshift} className="h-12 w-12 mx-auto mb-1" alt="" />
									Log Out
								</div>
							</Link>
						</div>
					</ModalSmall>
				}











			{/* //....................................................................................
			//....OOOOOOO.....PPPPPPPPP...TTTTTTTTTTTIIII...OOOOOOO.....NNNN...NNNN...SSSSSSS.....
			//...OOOOOOOOOO...PPPPPPPPPP..TTTTTTTTTTTIIII..OOOOOOOOOO...NNNNN..NNNN..SSSSSSSSS....
			//..OOOOOOOOOOOO..PPPPPPPPPPP.TTTTTTTTTTTIIII.OOOOOOOOOOOO..NNNNN..NNNN..SSSSSSSSSS...
			//..OOOOO..OOOOO..PPPP...PPPP....TTTT...TIIII.OOOOO..OOOOO..NNNNNN.NNNN.NSSSS..SSSS...
			//.OOOOO....OOOOO.PPPP...PPPP....TTTT...TIIIIIOOOO....OOOOO.NNNNNN.NNNN.NSSSS.........
			//.OOOO......OOOO.PPPPPPPPPPP....TTTT...TIIIIIOOO......OOOO.NNNNNNNNNNN..SSSSSSS......
			//.OOOO......OOOO.PPPPPPPPPP.....TTTT...TIIIIIOOO......OOOO.NNNNNNNNNNN...SSSSSSSSS...
			//.OOOO......OOOO.PPPPPPPPP......TTTT...TIIIIIOOO......OOOO.NNNNNNNNNNN.....SSSSSSS...
			//.OOOOO....OOOOO.PPPP...........TTTT...TIIIIIOOOO....OOOOO.NNNNNNNNNNN........SSSSS..
			//..OOOOO..OOOOO..PPPP...........TTTT...TIIII.OOOOO..OOOOO..NNNN.NNNNNN.NSSS....SSSS..
			//..OOOOOOOOOOOO..PPPP...........TTTT...TIIII.OOOOOOOOOOOO..NNNN..NNNNN.NSSSSSSSSSSS..
			//...OOOOOOOOOO...PPPP...........TTTT...TIIII..OOOOOOOOOO...NNNN..NNNNN..SSSSSSSSSS...
			//.....OOOOOO.....PPPP...........TTTT...TIIII....OOOOOO.....NNNN...NNNN...SSSSSSSS....
			//.................................................................................... */}

			<Container>

				<ProjectOptionsBorehole
					onMarkAsComplete={() => { setCompletingBorehole(true) }}
					company_guid={company_guid}
					companyID={companyID}
					project_guid={project_guid}
					project_id={project_id}
					project_identifier={projectID}
					boreholeName={borehole_name}
					borehole_guid={borehole_guid}
					boreholeType="HT"
					boreholeDepth= {borehole_depth}
					boreholeRequiredText=""
					// boreholeRequiredDepth={this.state.borehole.depth}
					boreholeRequiredDepth=""
					// boreholeRequiredTests={this.state.borehole.samplesTests}
					boreholeRequiredTests=""
					editWeatherLink={'/projects/weather/' + project_guid}
					rig_guid=''
					driller={leadDriller}
					driller_id={leadDrillerID}
					driller_cscs={leadDrillerCSCS}
					second_man= '' // {secondMan}
					second_man_cscs= '' // {secondManCSCS}
					rig_name=''
					spt_guid=''
					borehole_depthOverall='' //{borehole_depthOverall}
					activity_termination_reason={activityTerminationReason}
					className="mt-1 mb-1 py-1"
					getHoleData={getHoleData}
					plantCheck=''
					holeStatus={hole_status}
					numberingType={numberingType}
				/>


				{/* <div className="w-full py-2 bg-grey-vdark text-center mt-1 mb-0 text-sm text-white rounded-md">
					<div onClick={() => setBhMenuVisible(!bhMenuVisible)} ref={popupRef}>
						Head Test Menu <img src={menubars} className="h-3 w-3 mb-0.5 inline" alt="" />
					</div>
				</div> */}


				<div className="flex w-full py-2 bg-grey-vdark text-center mt-1 mb-0 text-sm text-white rounded-md">
					<div className="flex-none ml-0 w-6 pl-3 pt-0.5">
						<img onClick={handleReloadClick} src={icon_reload} className="ml-0 w-3.5 h-3.5" alt="" />
					</div>
					<div onClick={() => setBhMenuVisible(!bhMenuVisible)} ref={popupRef} className="flex-1">
						Head Test Menu <img src={menubars} className="h-3 w-3 mb-0.5 inline" alt="" />
					</div>
					<div className="flex-none mr-0 w-6">

					</div>
				</div>


				<div className="relative"> {/* menu container */}

					<div className="grid lg:grid-cols-5 lg:gap-6 mt-2">
						<div className="col-span-5 lg:col-span-3 mt-2">





















							{/* LEFT SIDE PANELS GO HERE */}


							{/* //..............................................................................................
							//.HHHH...HHHH..TTTTTTTTTTT.....DDDDDDDDD....EEEEEEEEEEE.TTTTTTTTTTT..AAAA.....AAII.IILL........
							//.HHHH...HHHH..TTTTTTTTTTT.....DDDDDDDDDD...EEEEEEEEEEE.TTTTTTTTTTT.AAAAAA....AAII.IILL........
							//.HHHH...HHHH..TTTTTTTTTTT.....DDDDDDDDDDD..EEEEEEEEEEE.TTTTTTTTTTT.AAAAAA....AAII.IILL........
							//.HHHH...HHHH.....TTTT.........DDDD...DDDD..EEEE...........TTTT.....AAAAAAA...AAII.IILL........
							//.HHHH...HHHH.....TTTT.........DDDD....DDDD.EEEE...........TTTT....AAAAAAAA...AAII.IILL........
							//.HHHHHHHHHHH.....TTTT.........DDDD....DDDD.EEEEEEEEEE.....TTTT....AAAAAAAA...AAII.IILL........
							//.HHHHHHHHHHH.....TTTT.........DDDD....DDDD.EEEEEEEEEE.....TTTT....AAAA.AAAA..AAII.IILL........
							//.HHHHHHHHHHH.....TTTT.........DDDD....DDDD.EEEEEEEEEE.....TTTT...TAAAAAAAAA..AAII.IILL........
							//.HHHH...HHHH.....TTTT.........DDDD....DDDD.EEEE...........TTTT...TAAAAAAAAAA.AAII.IILL........
							//.HHHH...HHHH.....TTTT.........DDDD...DDDDD.EEEE...........TTTT..TTAAAAAAAAAA.AAII.IILL........
							//.HHHH...HHHH.....TTTT.........DDDDDDDDDDD..EEEEEEEEEEE....TTTT..TTAA....AAAA.AAII.IILLLLLLLL..
							//.HHHH...HHHH.....TTTT.........DDDDDDDDDD...EEEEEEEEEEE....TTTT..TTAA....AAAAAAAII.IILLLLLLLL..
							//.HHHH...HHHH.....TTTT.........DDDDDDDDD....EEEEEEEEEEE....TTTT.TTTAA.....AAAAAAII.IILLLLLLLL..
							//.............................................................................................. */}

							<PanelSingleUseHeadTest title="Borehole Details"
								numberOfElements={numberOfHeadTestDetail} //{numberOfHeadTestDetail}
								onAdd={() => setShowHeadTestDetail(true)}
								onEdit={() => setShowHeadTestDetailEdit(true)}
								actionDate={headTestDate}
							>

								{HeadTestData?.map((valueHeadTestDetail: any, index: number) => {

									//Get actualIndex
									const actualIndex = HeadTestData.map((object: any) => object.guid).indexOf(valueHeadTestDetail.guid)

									return (
										<React.Fragment key={index}>

											<div className="mb-4"
												onClick={() => {
													setEditingGuid(valueHeadTestDetail.guid)
													setEditingIndex(0)
													setShowHeadTestDetailEdit(true)
												}}>

												<table className="table-auto w-full text-xs sm:text-sm text-center border-collapse overflow-hidden rounded-t-md">
													<tr className={`px-1 sm:px-1 pt-0.5 border-l border-r border-grey-mid bg-grey-mid font-bold`}>
														<td className="w-1/3">Depth of borehole (mbgl)</td>
														<td className="w-1/3">Diameter of borehole (mm)</td>
														<td className="w-1/3">Water level before test (mbgl)</td>
													</tr>
													<tr>
														<td className={`px-1 py-0.5 border-l border-r border-grey-mid`}>{valueHeadTestDetail.depth_of_borehole}</td>
														<td className={`px-1 py-0.5 border-r border-grey-mid`}>{valueHeadTestDetail.diameter_of_borehole}</td>
														<td className={`px-1 py-0.5 border-r border-grey-mid`}>{valueHeadTestDetail.water_level_before_test}</td>
													</tr>
													<tr className={`px-1 sm:px-1 pt-0.5 border-l border-r border-grey-mid bg-grey-mid font-bold`}>
														<td>Height of casing above ground (m)</td>
														<td>Depth of casing (mbgl)</td>
														<td>Gravel Backfill?</td>
													</tr>
													<tr>
														<td className={`px-1 py-0.5 border-l border-r border-grey-mid`}>{valueHeadTestDetail.depth_of_casing}</td>
														<td className={`px-1 py-0.5 border-r border-grey-mid`}>{valueHeadTestDetail.height_of_casing}</td>
														<td className={`px-1 py-0.5 border-r border-grey-mid`}>{valueHeadTestDetail.gravel_backfill}</td>
													</tr>
													<tr className={`px-1 sm:px-1 pt-0.5 border-l border-r border-grey-mid bg-grey-mid font-bold`}>
														<td>Main Soil Type</td>
														<td>Test Type</td>
														<td>Comments</td>
													</tr>
													<tr>
														<td className={`px-1 py-0.5 border-l border-r border-b border-grey-mid`}>{valueHeadTestDetail.soil_type}</td>
														<td className={`px-1 py-0.5 border-r border-b border-grey-mid`}>{valueHeadTestDetail.test_type}</td>
														<td className={`px-1 py-0.5 border-r border-b border-grey-mid`}>{valueHeadTestDetail.general_remarks}</td>
													</tr>
												</table>

											</div>
										</React.Fragment>
									)
								})}
							</PanelSingleUseHeadTest>












						</div>  {/* END OF LEFT SIDE */}

						<div className="col-span-5 lg:col-span-2 mt-0 md:mt-2"> {/* START OF RIGHT SIDE */}

							{/* RIGHT SIDE PANELS GO HERE */}









							{/* //....................................................
							//.TTTTTTTTTTTRRRRRRRRRR...EEEEEEEEEEEEMMMMM...MMMMM..
							//.TTTTTTTTTTTRRRRRRRRRRR..EEEEEEEEEEEEMMMMM...MMMMM..
							//.TTTTTTTTTTTRRRRRRRRRRR..EEEEEEEEEEEEMMMMM...MMMMM..
							//....TTTT....RRRR...RRRRR.EEEE.......EMMMMMM.MMMMMM..
							//....TTTT....RRRR...RRRRR.EEEE.......EMMMMMM.MMMMMM..
							//....TTTT....RRRRRRRRRRR..EEEEEEEEEE.EMMMMMM.MMMMMM..
							//....TTTT....RRRRRRRRRRR..EEEEEEEEEE.EMMMMMMMMMMMMM..
							//....TTTT....RRRRRRRR.....EEEEEEEEEE.EMMMMMMMMMMMMM..
							//....TTTT....RRRR.RRRR....EEEE.......EMMMMMMMMMMMMM..
							//....TTTT....RRRR..RRRR...EEEE.......EMMM.MMMMM.MMM..
							//....TTTT....RRRR..RRRRR..EEEEEEEEEEEEMMM.MMMMM.MMM..
							//....TTTT....RRRR...RRRRR.EEEEEEEEEEEEMMM.MMMMM.MMM..
							//....TTTT....RRRR....RRRR.EEEEEEEEEEEEMMM.MMMMM.MMM..
							//.................................................... */}

							<Panel title="Comments / Standing Time / Day Works" numberOfElements={numberOfComments} onAdd={() => setShowComments(true)}>
								<table className="table-auto w-full text-xs sm:text-sm text-center border-collapse overflow-hidden rounded-t-md">
									<thead className="font-bold">
										<tr>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid rounded-tl-sm rounded-tr-sm border-l  border-r border-grey-light `}>Type</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>Time From</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>Duration</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}>Comment</td>
											<td className={`px-1 py-0 bg-grey-mid border-grey-mid border-r border-grey-light`}></td>
										</tr>
									</thead>
									<tbody>

										{CommentsData?.filter((action: any) => {
											return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
										}).map((valueComment: any, index: number) => {

											//Get actualIndex
											const actualIndex = CommentsData.map((object: any) => object.guid).indexOf(valueComment.guid)
											// console.log('typeof actualIndex: ', actualIndex, typeof actualIndex)

											return (
												<React.Fragment key={index}>
													<tr className="border border-grey-mid bg-white mb-1">
														<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{valueComment.comment_type}</td>
														<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{valueComment.start_time}</td>
														<td className={`px-1 pt-0.5 border-r border-grey-mid`}>{valueComment.duration}</td>
														<td className={`px-1 pt-0.5 border-r border-grey-mid text-left`}>{valueComment.general_remarks}</td>
														<td className={`px-1 py-0 border-r border-grey-mid w-8 md:w-12`}>
															<div className={"text-center px-0 pb-0.5"}>
																<img
																	onClick={() => {
																		const actualIndex = CommentsData.map((object: any) => object.guid).indexOf(valueComment.guid)
																		setEditingGuid(valueComment.guid)
																		setEditingIndex(actualIndex)
																		setShowCommentsEdit(true)
																	}}
																	className="inline" width={"16"} src={edit} alt="" />
															</div>
														</td>
													</tr>
												</React.Fragment>
											)
										})}

										{/* <tr>
											<td className="h-2"></td>
										</tr>
										<tr>
											<td colSpan={5} className="w-full border border-grey-mid">
												<div className="grid grid-cols-12 w-full">
													<div className="col-span-3 pr-2 py-1 border-r border-grey-mid text-right bg-sptTB">
														Total Standing:
													</div>
													<div className="col-span-3 py-1 border-r border-grey-mid text-center font-bold">
														hrs mins
													</div>
													<div className="col-span-3 pr-2 py-1 border-r border-grey-mid text-right bg-sptTB">
														Total Dayworks:
													</div>
													<div className="col-span-3 py-1 text-center font-bold">
														hrs mins
													</div>
												</div>
											</td>
										</tr> */}
									</tbody>
								</table>

							</Panel>


						</div>  {/* END OF RIGHT SIDE */}

					</div>

					{numberOfHeadTestDetail !== 0 &&

					<div className="flex">

						<div className="flex-1 pr-1">

						{/* //......................................................
						//.RRRRRRRRRR...UUUU...UUUU..NNNN...NNNN..........1111..
						//.RRRRRRRRRRR..UUUU...UUUU..NNNNN..NNNN.........11111..
						//.RRRRRRRRRRR..UUUU...UUUU..NNNNN..NNNN........111111..
						//.RRRR...RRRRR.UUUU...UUUU..NNNNNN.NNNN.......1111111..
						//.RRRR...RRRRR.UUUU...UUUU..NNNNNN.NNNN.......1111111..
						//.RRRRRRRRRRR..UUUU...UUUU..NNNNNNNNNNN.......11.1111..
						//.RRRRRRRRRRR..UUUU...UUUU..NNNNNNNNNNN..........1111..
						//.RRRRRRRR.....UUUU...UUUU..NNNNNNNNNNN..........1111..
						//.RRRR.RRRR....UUUU...UUUU..NNNNNNNNNNN..........1111..
						//.RRRR..RRRR...UUUU...UUUU..NNNN.NNNNNN..........1111..
						//.RRRR..RRRRR..UUUUUUUUUUU..NNNN..NNNNN..........1111..
						//.RRRR...RRRRR..UUUUUUUUU...NNNN..NNNNN..........1111..
						//.RRRR....RRRR...UUUUUUU....NNNN...NNNN..........1111..
						//...................................................... */}

							{Run1Status()}

							<PanelSingleUse title="Run 1"
								numberOfElements={numberOfRun1}
								onAdd={() => setShowRun1(true)}
								onEdit={() => setShowRun1Edit(true)}
							>
								{HeadTestData?.map((valueHeadTestDetail: any, index: number) => {

									let start_time_date_text = ''
									const start_date_time_text_run1 = valueHeadTestDetail.runs[0].start_time
									if (start_date_time_text_run1) {
										start_time_date_text = 'Start:' + start_date_time_text_run1
									}

									const run1_data = valueHeadTestDetail.runs[0].results
									const dataArray = Object.entries(run1_data)
										.filter(([time, value]) => value !== null) // Filter out entries where value is null
										.map(([time, value]) => ({
											time,
											value: parseFloat(value as string).toFixed(2), // Explicitly cast to string
										}));

									return (
										<React.Fragment key={index}>

											<div className="mb-4"
												onClick={() => {
													setShowRun1(true)
												}}
											>


												<div className="text-center bg-sptTB text-xs md:text-sm font-bold border-l border-r border-t border-grey-mid">
													{start_time_date_text}
												</div>

												<CompactTable
													header={['Time', 'Depth (m)']}
													items={dataArray.map(({ time, value }) => [time, value])}
													textCSS={['text-xs md:text-lg']}
												/>


											</div>
										</React.Fragment>
									)
								})}

							</PanelSingleUse>

						</div>





						<div className="flex-1 px-1">


						{/* //........................................................
						//.RRRRRRRRRR...UUUU...UUUU..NNNN...NNNN........222222....
						//.RRRRRRRRRRR..UUUU...UUUU..NNNNN..NNNN.......22222222...
						//.RRRRRRRRRRR..UUUU...UUUU..NNNNN..NNNN...... 222..2222..
						//.RRRR...RRRRR.UUUU...UUUU..NNNNNN.NNNN...... 222..2222..
						//.RRRR...RRRRR.UUUU...UUUU..NNNNNN.NNNN............2222..
						//.RRRRRRRRRRR..UUUU...UUUU..NNNNNNNNNNN...........22222..
						//.RRRRRRRRRRR..UUUU...UUUU..NNNNNNNNNNN..........22222...
						//.RRRRRRRR.....UUUU...UUUU..NNNNNNNNNNN.........22222....
						//.RRRR.RRRR....UUUU...UUUU..NNNNNNNNNNN........22222.....
						//.RRRR..RRRR...UUUU...UUUU..NNNN.NNNNNN.......22222......
						//.RRRR..RRRRR..UUUUUUUUUUU..NNNN..NNNNN.......2222.......
						//.RRRR...RRRRR..UUUUUUUUU...NNNN..NNNNN...... 222222222..
						//.RRRR....RRRR...UUUUUUU....NNNN...NNNN...... 222222222..
						//........................................................ */}


							{Run2Status()}

							<PanelSingleUse title="Run 2"
								numberOfElements={numberOfRun2}
								onAdd={() => setShowRun2(true)}
								onEdit={() => setShowRun2Edit(true)}
							>
								{HeadTestData?.map((valueHeadTestDetail: any, index: number) => {

									let start_time_date_text = ''
									const start_date_time_text_run2 = valueHeadTestDetail.runs[1].start_time
									if (start_date_time_text_run2) {
										start_time_date_text = 'Start: ' + start_date_time_text_run2
									}

									const run2_data = valueHeadTestDetail.runs[1].results
									const dataArray = Object.entries(run2_data)
										.filter(([time, value]) => value !== null) // Filter out entries where value is null
										.map(([time, value]) => ({
											time,
											value: parseFloat(value as string).toFixed(2), // Explicitly cast to string
										}));

									return (
										<React.Fragment key={index}>

											<div className="mb-4"
												onClick={() => {
													setShowRun2(true)
												}}
											>


												<div className="text-center bg-sptTB text-xs md:text-sm font-bold border-l border-r border-t border-grey-mid">
													{start_time_date_text}
												</div>

												<CompactTable
													header={['Time', 'Depth (m)']}
													items={dataArray.map(({ time, value }) => [time, value])}
													textCSS={['text-xs md:text-lg']}
												/>


											</div>
										</React.Fragment>
									)
								})}

							</PanelSingleUse>

						</div>



						<div className="flex-1 pl-1">

						{/* //........................................................
						//.RRRRRRRRRR...UUUU...UUUU..NNNN...NNNN........333333....
						//.RRRRRRRRRRR..UUUU...UUUU..NNNNN..NNNN.......33333333...
						//.RRRRRRRRRRR..UUUU...UUUU..NNNNN..NNNN.......33333333...
						//.RRRR...RRRRR.UUUU...UUUU..NNNNNN.NNNN...... 333.3333...
						//.RRRR...RRRRR.UUUU...UUUU..NNNNNN.NNNN...........3333...
						//.RRRRRRRRRRR..UUUU...UUUU..NNNNNNNNNNN..........3333....
						//.RRRRRRRRRRR..UUUU...UUUU..NNNNNNNNNNN.........333333...
						//.RRRRRRRR.....UUUU...UUUU..NNNNNNNNNNN...........33333..
						//.RRRR.RRRR....UUUU...UUUU..NNNNNNNNNNN............3333..
						//.RRRR..RRRR...UUUU...UUUU..NNNN.NNNNNN...... 333..3333..
						//.RRRR..RRRRR..UUUUUUUUUUU..NNNN..NNNNN...... 333333333..
						//.RRRR...RRRRR..UUUUUUUUU...NNNN..NNNNN.......33333333...
						//.RRRR....RRRR...UUUUUUU....NNNN...NNNN........333333....
						//........................................................ */}

							{Run3Status()}

							<PanelSingleUse title="Run 3"
								numberOfElements={numberOfRun3}
								onAdd={() => setShowRun3(true)}
								onEdit={() => setShowRun3Edit(true)}
							>
								{HeadTestData?.map((valueHeadTestDetail: any, index: number) => {

									let start_time_date_text = ''
									const start_date_time_text_run3 = valueHeadTestDetail.runs[2].start_time
									if (start_date_time_text_run3) {
										start_time_date_text = 'Start: ' + start_date_time_text_run3
									}

									const run3_data = valueHeadTestDetail.runs[2].results
									const dataArray = Object.entries(run3_data)
										.filter(([time, value]) => value !== null) // Filter out entries where value is null
										.map(([time, value]) => ({
											time,
											value: parseFloat(value as string).toFixed(2), // Explicitly cast to string
										}));

									return (
										<React.Fragment key={index}>

											<div className="mb-4"
												onClick={() => {
													setShowRun3(true)
												}}
											>


												<div className="text-center bg-sptTB text-xs md:text-sm font-bold border-l border-r border-t border-grey-mid">
													{start_time_date_text}
												</div>

												<CompactTable
													header={['Time', 'Depth (m)']}
													items={dataArray.map(({ time, value }) => [time, value])}
													textCSS={['text-xs md:text-lg']}
												/>


											</div>
										</React.Fragment>
									)
								})}

							</PanelSingleUse>

						</div>

					</div>


					}











					{/* //.................................................................................................................
					//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNN...NNNN..UUUU...UUUU.......BBBBBBBBBB.....OOOOOOO.....DDDDDDDDD...YYYY....YYYY..
					//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNNN..NNNN..UUUU...UUUU.......BBBBBBBBBBB...OOOOOOOOOO...DDDDDDDDDD..YYYYY..YYYYY..
					//.MMMMMM...MMMMMM.EEEEEEEEEEE.NNNNN..NNNN..UUUU...UUUU.......BBBBBBBBBBB..OOOOOOOOOOOO..DDDDDDDDDDD..YYYY..YYYY...
					//.MMMMMMM.MMMMMMM.EEEE........NNNNNN.NNNN..UUUU...UUUU.......BBBB...BBBB..OOOOO..OOOOO..DDDD...DDDD..YYYYYYYYY....
					//.MMMMMMM.MMMMMMM.EEEE........NNNNNN.NNNN..UUUU...UUUU.......BBBB...BBBB.OOOOO....OOOOO.DDDD....DDDD..YYYYYYYY....
					//.MMMMMMM.MMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU.......BBBBBBBBBBB.OOOO......OOOO.DDDD....DDDD...YYYYYY.....
					//.MMMMMMMMMMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU.......BBBBBBBBBB..OOOO......OOOO.DDDD....DDDD...YYYYYY.....
					//.MMMMMMMMMMMMMMM.EEEEEEEEEE..NNNNNNNNNNN..UUUU...UUUU.......BBBBBBBBBBB.OOOO......OOOO.DDDD....DDDD....YYYY......
					//.MMMMMMMMMMMMMMM.EEEE........NNNNNNNNNNN..UUUU...UUUU.......BBBB....BBBBOOOOO....OOOOO.DDDD....DDDD....YYYY......
					//.MMMM.MMMMM.MMMM.EEEE........NNNN.NNNNNN..UUUU...UUUU.......BBBB....BBBB.OOOOO..OOOOO..DDDD...DDDDD....YYYY......
					//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN..NNNNN..UUUUUUUUUUU.......BBBBBBBBBBBB.OOOOOOOOOOOO..DDDDDDDDDDD.....YYYY......
					//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN..NNNNN...UUUUUUUUU........BBBBBBBBBBB...OOOOOOOOOO...DDDDDDDDDD......YYYY......
					//.MMMM.MMMMM.MMMM.EEEEEEEEEEE.NNNN...NNNN....UUUUUUU.........BBBBBBBBBB......OOOOOO.....DDDDDDDDD.......YYYY......
					//................................................................................................................. */}

					{bhMenuVisible &&

						// <div style={{ display: bhMenuVisible ? 'block' : 'none' }}>
						<div>

							<div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 sm:gap-3 md:gap-4 bg-grey-vdark w-full absolute z-50 top-0 left-0 -mt-3 text-center text-white text-xs md:text-sm pt-2 px-2 md:px-3 lg:px-4 pb-2 md:pb-3 lg:pb-4 rounded-b-lg modalshadow">

								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<Link to={`/projects/update/${project_guid}`}>
										<img src={icon_project} className="h-12 w-12 mx-auto mb-1" alt="" />
										Edit Project
									</Link>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<div onClick={menuAndAGS}>
										<img src={icon_ags} className="h-12 w-12 mx-auto mb-1" alt="" />
										Output AGS
									</div>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<div onClick={menuAndEmail}>
										<img src={icon_email} className="h-12 w-12 mx-auto mb-1" alt="" />
										Send Email
									</div>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<button className="items-center justify-center px-2 bold mx-auto" onClick={menuAndPreview} >
										<img src={icon_preview} className="h-12 w-12 mx-auto mb-1" alt="" />
										Preview Log
									</button>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<div onClick={menuAndSaveLog}>
										<img src={icon_savelog} className="h-12 w-12 mx-auto mb-1" alt="" />
										Save Log
									</div>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									{/* <Link to={`/#`} onClick={this.bhMenuClick}> */}
									{/* <Link to={`#`} onClick={menuAndMarkAsComplete}>
										<img src={icon_complete} className="h-12 w-12 mx-auto mb-1" alt="" />
										Mark As Complete
									</Link> */}
									<div onClick={menuAndEndShift}>
										<img src={icon_endshift} className="h-12 w-12 mx-auto mb-1" alt="" />
										End Shift
									</div>
								</div>
								<div className="border border-grey-dark rounded pt-1.5 pb-1">
									<Link to={`/newhole/HT/${project_guid}`}>
										<img src={icon_newborehole} className="h-12 w-12 mx-auto mb-1" alt="" />
										New Head Test
									</Link>
								</div>

								<div className="col-span-3 md:col-span-4 lg:col-span-5">
									<div className="grid grid-cols-2 gap-2 sm:gap-3 md:gap-4 ">
										<div className="border border-grey-dark rounded py-2 text-lg">
											<Link to={`/projects/dashboard/company/${company_guid}/${project_guid}/head-test`}>
												Head Test Select
											</Link>
										</div>
										<div className="border border-grey-dark rounded py-2 text-lg">
											<Link to={`/projects/dashboard/${project_guid}/company/${company_guid}`}>
												Change Activity
											</Link>
										</div>
									</div>
								</div>

							</div>

						</div>

					}

				</div> {/* end of menu container */}














				{/* //.....................................................................................
				//.PPPPPPPPP...PRRRRRRRRR...REEEEEEEEEEEEVV....VVVVVVIII.IEEEEEEEEEEEEWW..WWWWW...WWW..
				//.PPPPPPPPPP..PRRRRRRRRRR..REEEEEEEEEEEEVV....VVVVVVIII.IEEEEEEEEEEEEWW..WWWWW..WWWW..
				//.PPPPPPPPPPP.PRRRRRRRRRR..REEEEEEEEEEEEVV....VVVVVVIII.IEEEEEEEEEEEEWW..WWWWWW.WWWW..
				//.PPPP...PPPP.PRRR...RRRRR.REEE.......EEVVV..VVVV.VVIII.IEEE.......EEWW.WWWWWWW.WWWW..
				//.PPPP...PPPP.PRRR...RRRRR.REEE........EVVV..VVVV.VVIII.IEEE.......EEWW.WWWWWWW.WWWW..
				//.PPPPPPPPPPP.PRRRRRRRRRR..REEEEEEEEE..EVVV..VVVV.VVIII.IEEEEEEEEE..EWWWWWWWWWW.WWW...
				//.PPPPPPPPPP..PRRRRRRRRRR..REEEEEEEEE..EVVVVVVVV..VVIII.IEEEEEEEEE..EWWWWWW.WWWWWWW...
				//.PPPPPPPPP...PRRRRRRR.....REEEEEEEEE...VVVVVVVV..VVIII.IEEEEEEEEE..EWWWWWW.WWWWWWW...
				//.PPPP........PRRR.RRRR....REEE.........VVVVVVVV..VVIII.IEEE........EWWWWWW.WWWWWWW...
				//.PPPP........PRRR..RRRR...REEE.........VVVVVVV...VVIII.IEEE........EWWWWWW.WWWWWWW...
				//.PPPP........PRRR..RRRRR..REEEEEEEEEE...VVVVVV...VVIII.IEEEEEEEEEE..WWWWW...WWWWW....
				//.PPPP........PRRR...RRRRR.REEEEEEEEEE...VVVVVV...VVIII.IEEEEEEEEEE..WWWWW...WWWWW....
				//.PPPP........PRRR....RRRR.REEEEEEEEEE...VVVVV....VVIII.IEEEEEEEEEE..WWWWW...WWWWW....
				//..................................................................................... */}

				{previewVisible &&

					<>

						{/* LOGSHEET */}

						<div className="flex flex-col rounded-lg border border-orange bg-modal-bg px-2 sm:px-2 md:px-2 pb-6 modalshadow previewLogsheet z-50" >

							<div className="flex items-center border-b border-grey-dark py-2">

								<div className="flex-1 flex items-left">

								</div>

								<div className="flex-1 flex justify-center text-lg sm:text-xl font-bold text-text leading-6 text-center mx-auto">
									Preview Logsheet
								</div>

								<div className="flex-1 flex justify-center">
									<span className="ml-auto">
										<button className="flex items-center justify-center w-8 h-8 text-4xl bold text-soil-grey bg-white border border-orange rounded" onClick={previewClick} ><img src={closeIcon} width="32" alt="" /></button>
									</span>
								</div>
							</div>

							<div className="w-full overflow-scroll">

								<div className="logsheetContainer mx-auto pb-6">

									<div className="mainContainer">

										<table className="w-full">
											<tbody>
												{/* HEADER TABLE */}
												<tr>
													<td colSpan={2}>
														<div className="headerTableHolder border border-grey-mid">
															<table className="noTableBorder text-center w-full">
																<tbody>
																	<tr>
																		<td rowSpan={3} className="headerLeft">{/* LOGO */}<img className="mt-1" src={companyLogo} /></td>
																		<td colSpan={4} className="headerTitle">Daily Head Test Record</td>
																		<td rowSpan={2} colSpan={2} className="headerRight headerBarcodeHolder pt-3 pl-5 text-center">
																			{/* <svg id="barcode" className="barcodeCSS"></svg> */}
																			<Barcode value={labelbarcodeVar}
																				options={{
																					format: 'code128',
																					//width: 2,
																					width: barcodeWidth,
																					height: 60,
																					fontSize: 12,
																					//marginLeft: 80,
																					//text: barcodeVar
																					//background: "#ccffff"
																				}} />
																		</td>
																	</tr>
																	<tr>
																		<td className="headerCellTitleLeft">Location:</td>
																		<td className={`${logsheetLocationStyle}`}>{projectLocation}</td>
																		<td className="headerCellTitleRight">Client:</td>
																		<td className="headerCellInputRight">{client}</td>
																	</tr>
																	<tr>
																		<td className="headerCellTitleLeft">Project ID:</td>
																		<td id="projectID" className="headerCellInputLeft">{projectID}</td>
																		<td className="headerCellTitleRight">HT ID:</td>
																		<td id="boreholeID" className="headerCellInputRight">{borehole_name}</td>
																		<td id="headerDate">{sheetDate}</td>
																		<td id="headerSheets">Sheet 1 of 1</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</td>
												</tr>
												{/* END OF HEADER TABLE */}
												<tr>
													<td colSpan={2}>

														{HeadTestData?.map((valueHeadTestDetail: any, index: number) => {

															return (
																<React.Fragment key={index}>

																	<table className="w-full">
																		<tr>
																			<td colSpan={4} className="tableRow bg-grey-mid sectionTitle">Borehole Details</td>
																		</tr>
																		<tr>
																			<td className="tableRow w-2/6 text-right pr-1 bg-sptTB border-b border-r border-grey-mid">Depth of borehole (mbgl):</td>
																			<td className="tableRow w-1/6 border-b border-r border-grey-mid">{valueHeadTestDetail.depth_of_borehole}</td>
																			<td className="tableRow w-2/6 text-right pr-1 bg-sptTB border-b border-r border-grey-mid">Diameter of borehole (m)</td>
																			<td className="tableRow w-1/6 border-b border-r border-grey-mid">{valueHeadTestDetail.diameter_of_borehole}</td>
																		</tr>
																		<tr>
																			<td className="tableRow w-2/6 text-right pr-1 bg-sptTB border-b border-r border-grey-mid">Water level before test (mbgl): </td>
																			<td className="tableRow w-1/6 border-b border-r border-grey-mid">{valueHeadTestDetail.water_level_before_test}</td>
																			<td className="tableRow w-2/6 text-right pr-1 bg-sptTB border-b border-r border-grey-mid">Depth of casing (mbgl):</td>
																			<td className="tableRow w-1/6 border-b border-r border-grey-mid">{valueHeadTestDetail.depth_of_casing}</td>
																		</tr>
																		<tr>
																			<td className="tableRow w-2/6 text-right pr-1 bg-sptTB border-b border-r border-grey-mid">Main soil type:</td>
																			<td className="tableRow w-1/6 border-b border-r border-grey-mid">{valueHeadTestDetail.soil_type}</td>
																			<td className="tableRow w-2/6 text-right pr-1 bg-sptTB border-b border-r border-grey-mid">Height of casing above ground (m): </td>
																			<td className="tableRow w-1/6 border-b border-r border-grey-mid">{valueHeadTestDetail.height_of_casing}</td>
																		</tr>
																		<tr>
																			<td className="tableRow w-2/6 text-right pr-1 bg-sptTB border-b border-r border-grey-mid">Weather Conditions: </td>
																			<td className="tableRow w-1/6 border-b border-r border-grey-mid">{selectedWeather}</td>
																			<td className="tableRow w-2/6 text-right pr-1 bg-sptTB border-b border-r border-grey-mid"></td>
																			<td className="tableRow w-1/6 border-b border-r border-grey-mid"></td>
																		</tr>
																	</table>

																</React.Fragment>

															)
														})}
													</td>
												</tr>
												<tr>
													<td colSpan={2}>
														<div className="grid grid-cols-3 border-l border-grey-mid">
															{/* RUN #1 */}
															<table className="headtestRun1Table borderLeftBottom" id="run1DivsHolder">
																<tr>
																	<td colSpan={2} className="tableRow headtestRun1Title border-b border-r border-grey-mid h-6 pt-0.5">RUN #1</td>
																</tr>
																<tr>
																	<td colSpan={2} className="tableRow sectionTitle border-b border-r border-grey-mid">Start Time:<br /><span className="text-xs">{start_date_time_run1}</span></td>
																</tr>
																{/* <tr>
																	<td className="tableRow headtestRunInput sectionTitleBG sectionTitle border-b border-r border-grey-mid bg-sptTB">Time (hh:mm:ss)</td>
																	<td className="tableRow headtestRunInput sectionTitleBG sectionTitle border-b border-r border-grey-mid bg-sptTB">Depth (m)</td>
																</tr> */}
																{/* {logRowsRun1} */}
																<tr>
																	<td colSpan={2} className="text-sm align-top w-full">
																		{HeadTestData?.map((valueHeadTest: any, index: number) => {

																		const run1_data = valueHeadTest.runs[0].results
																		// console.log('HeadTestData:', SoakawayData)
																		// console.log('run1_data:', run1_data)
																		const dataArray = Object.entries(run1_data)
																			.filter(([time, value]) => value !== null) // Filter out entries where value is null
																			.map(([time, value]) => ({
																				time,
																				value: parseFloat(value as string).toFixed(2), // Explicitly cast to string
																			}));

																		return (
																			<React.Fragment key={index}>

																					<CompactTable
																						header={['Time', 'Depth (m)']}
																						items={dataArray.map(({ time, value }) => [time, value])}
																						textCSS={['text-base']}
																					/>

																			</React.Fragment>
																		)
																		})}
																	</td>
																</tr>
															</table>
															{/* RUN #2 */}
															<table className="headtestRun1Table borderLeftBottom" id="run2DivsHolder">
																<tr>
																	<td colSpan={2} className="tableRow headtestRun2Title border-b border-r border-grey-mid h-6 pt-0.5">RUN #2</td>
																</tr>
																<tr>
																	<td colSpan={2} className="tableRow sectionTitle border-b border-r border-grey-mid">Start Time:<br /><span className="text-xs">{start_date_time_run2}</span></td>
																</tr>
																{/* <tr>
																	<td className="tableRow headtestRunInput sectionTitleBG sectionTitle border-b border-r border-grey-mid bg-sptTB">Time (hh:mm:ss)</td>
																	<td className="tableRow headtestRunInput sectionTitleBG sectionTitle border-b border-r border-grey-mid bg-sptTB">Depth (m)</td>
																</tr> */}
																{/* {logRowsRun2} */}
																<tr>
																	<td colSpan={2} className="text-sm align-top w-full">
																		{HeadTestData?.map((valueHeadTest: any, index: number) => {

																		const run2_data = valueHeadTest.runs[1].results
																		// console.log('HeadTestData:', SoakawayData)
																		// console.log('run1_data:', run1_data)
																		const dataArray = Object.entries(run2_data)
																			.filter(([time, value]) => value !== null) // Filter out entries where value is null
																			.map(([time, value]) => ({
																				time,
																				value: parseFloat(value as string).toFixed(2), // Explicitly cast to string
																			}));

																		return (
																			<React.Fragment key={index}>

																					<CompactTable
																						header={['Time', 'Depth (m)']}
																						items={dataArray.map(({ time, value }) => [time, value])}
																						textCSS={['text-base']}
																					/>

																			</React.Fragment>
																		)
																		})}
																	</td>
																</tr>
															</table>
															{/* RUN #3 */}
															<table className="headtestRun1Table borderLeftBottom" id="run3DivsHolder">
																<tr>
																	<td colSpan={2} className="tableRow headtestRun3Title border-b border-r border-grey-mid h-6 pt-0.5">RUN #3</td>
																</tr>
																<tr>
																	<td colSpan={2} className="tableRow sectionTitle border-b border-r border-grey-mid">Start Time:<br /><span className="text-xs">{start_date_time_run3}</span></td>
																</tr>
																{/* <tr>
																	<td className="tableRow headtestRunInput sectionTitleBG sectionTitle border-b border-r border-grey-mid bg-sptTB">Time (hh:mm:ss)</td>
																	<td className="tableRow headtestRunInput sectionTitleBG sectionTitle border-b border-r border-grey-mid bg-sptTB">Depth (m)</td>
																</tr> */}
																{/* {logRowsRun3} */}
																<tr>
																	<td colSpan={2} className="text-sm align-top w-full">
																		{HeadTestData?.map((valueHeadTest: any, index: number) => {

																		const run3_data = valueHeadTest.runs[2].results
																		// console.log('HeadTestData:', SoakawayData)
																		// console.log('run1_data:', run1_data)
																		const dataArray = Object.entries(run3_data)
																			.filter(([time, value]) => value !== null) // Filter out entries where value is null
																			.map(([time, value]) => ({
																				time,
																				value: parseFloat(value as string).toFixed(2), // Explicitly cast to string
																			}));

																		return (
																			<React.Fragment key={index}>

																					<CompactTable
																						header={['Time', 'Depth (m)']}
																						items={dataArray.map(({ time, value }) => [time, value])}
																						textCSS={['text-base']}
																					/>

																			</React.Fragment>
																		)
																		})}
																	</td>
																</tr>
															</table>
														</div>
													</td>
												</tr>
												<tr>
													<td className="w-2/4 align-top border-r border-grey-mid"> {/* LEFT SIDE */}
														{/* COMMENTS */}
														<div className="commentsHolder_HT w-full border-b border-l border-grey-mid">
															<table className="w-full align-top noTableBorder">
																<tbody>
																	<tr>
																		<td className="tableRow bg-grey-mid sectionTitle">Comments / Standing Time / Dayworks</td>
																	</tr>
																	<tr>
																		<td className="commentsInput">
																			{/* ////////////////////////////////////////////////////// */}
																			{CommentsData?.filter((action: any) => {
																				return (datesAreOnSameDay(new Date(action.time_of_action), new Date(selectedDate)))
																			}).map((valueComments: any, index: number) => {
																				return (
																					<React.Fragment key={index}>

																						{valueComments.comment_type} ({valueComments.duration}) - {valueComments.remarks}.&nbsp;

																					</React.Fragment>
																				)
																			})}
																			{/* ////////////////////////////////////////////////////// */}
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
														{/* END OF COMMENTS */}
														{/* STANDING TIME / DAYWORKS TOTALS */}
														<div className="standingTimeTotalsHolder w-full border-b border-l border-grey-mid">
															<table className="standingTimeTable w-full text-center noTableBorder">
																<tbody>
																	<tr>
																		<td className="tableRow bg-grey-mid sectionTitle standingTimeCategories">Total Standing Time:</td>
																		{/* ////////////////////////////////////////////////////// */}
																		<td className="tableRow sectionTitle standingTimeInputs">hrs mins</td>
																		{/* ////////////////////////////////////////////////////// */}
																		<td className="tableRow bg-grey-mid sectionTitle standingTimeCategories">Total Dayworks:</td>
																		{/* ////////////////////////////////////////////////////// */}
																		<td className="tableRow sectionTitle standingTimeInputs">hrs mins</td>
																		{/* ////////////////////////////////////////////////////// */}
																	</tr>
																</tbody>
															</table>
														</div>
														{/* END OF STANDING TIME / DAYWORKS TOTALS */}
													</td> {/* END OF LEFT SIDE */}
													<td className="w-2/4 align-top"> {/* RIGHT SIDE */}
														{/* PERSONNEL */}
														<div className="w-full border-b border-r border-grey-mid">
															<table className="w-full noTableBorder">
																<tbody>
																	<tr>
																		<td colSpan={2} className="tableRow bg-grey-mid sectionTitle">Personnel Details</td>
																	</tr>
																	{/* ////////////////////////////////////////////////////// */}
																	<tr>
																		<td className="tableRow bg-sptTB w-1/2">Name:</td>
																		<td className="tableRow bg-sptTB w-4/5">CSCS:</td>
																	</tr>
																	<tr>
																		<td className="tableRow w-1/2">{leadDriller}</td>
																		<td className="tableRow w-4/5">{leadDrillerCSCS}</td>
																	</tr>
																	{/* ////////////////////////////////////////////////////// */}
																</tbody>
															</table>
														</div>
														{/* END OF PERSONNEL */}
														{/* CLIENT INFO FOOTER */}
														<div className="w-full border-b border-r border-grey-mid">
															<table className="clientInfoTable noTableBorder">
																<tbody>
																	<tr>
																		{/* ////////////////////////////////////////////////////// */}
																		<td className="tableRow clientInfoContact">
																			{companyName}<br />
																			{companyAddress}<br />
																			Telephone: {companyTelephone}<br />
																			Email: {companyEmail}
																		</td>
																		{/* ////////////////////////////////////////////////////// */}
																		<td className="tableRow clientInfoDrillitLogs"></td>
																	</tr>
																</tbody>
															</table>
														</div>
														{/* END OF CLIENT INFO FOOTER */}
													</td>
												</tr>
											</tbody>
											{/* END OF tableHolderTable */}
										</table>

										{/* END OF mainContainer */}
									</div>

								</div>

							</div>

						</div>

					</>

				}



















			</Container>

			<br /><br />

			<SyncStatus synced />

		</>

	)
}

export default FunctionTemplate