import React, { FunctionComponent, useState, useEffect, Component } from 'react'

import { useLocation } from "react-router-dom";

import Header from '../layout/Header'

import Modal from '../components/Modal'
import WorkflowSteps from '../components/WorkflowSteps'
import NumberInput from '../components/NumberInput'
import Button from '../components/form/Button'
import InputGroup from '../components/form/InputGroup'
import TextArea from '../components/form/TextArea'
import WorkflowActions from '../components/form/WorkflowActions'
import edit from "../assets/icons/edit.svg";
import sortBoth from "../assets/icons/sort_both.svg";
import sortUp from "../assets/icons/sort_up.svg";
import sortDown from "../assets/icons/sort_down.svg";

import stepStatus from '../helpers/stepStatus'

import Label from '../components/Label'
import { Link } from 'react-router-dom'
import RadioSelect from '../components/form/RadioSelect'

import { useGetIDBValues } from "../helpers/getIDBKey";
import { useSetIndexedDBValues } from '../helpers/addToIDB'
import { saveContactList } from '../helpers/saveContactList'
import { get, set, del } from 'idb-keyval'
import { v4 as uuidv4 } from 'uuid';
import { updateContactInformation } from "../helpers/updateContactInformation";
import { deleteContactInformation } from "../helpers/deleteContactInformation";

type Props = {
    onClose: any,
    // onComplete: any,
    defaultValues?: any,
    projectId?: string,
    [x: string]: any;
}

const defaultProps: Props = {
    onClose: () => { },
    // onComplete: () => {},
    projectId: '',
    defaultValues: {
        guid: '',
    },
}

interface User {
    // id: number;
    guid: string;
    first_name: string;
    email: string;
    telephone: string;
    last_name: string;
    company_id: number;
}

interface NewUser {
    guid: string;
    first_name: string;
    email: string;
    telephone: string;
    last_name: string;
    company_id: number;
}


interface CustomCheckboxProps {
    checked: boolean;
    onChange: () => void;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ checked, onChange }) => {
    return (
        <div 
            className={`custom-checkbox ${checked ? 'checked' : ''} rounded w-8 h-8 border-2 border-blue`} 
            onClick={onChange}
            style={{
                // width: '20px',
                // height: '20px',
                // border: '2px solid #2185D0',
                display: 'inline-block',
                cursor: 'pointer',
                backgroundColor: checked ? '#2185D0' : '#fff',
                marginTop: checked ? '0px' : '5px',
            }}
        >
            {checked && <span style={{ color: '#fff' }}>✔</span>}
        </div>
    );
};


const ContactBook: FunctionComponent<Props> = ({ onClose, defaultValues, companyID, onSelectItem, currentEmails, ...rest }) => {


    const [company_id, setCompany_id] = useState(companyID);
    // console.log('company_id:', company_id);
    const [contacts, setContacts] = useState<User[]>([]);

    const [selectedContacts, setSelectedContacts] = useState<string[]>(currentEmails);

    useEffect(() => {
        console.log('currentEmails:', currentEmails);
        console.log('selectedContacts:', selectedContacts);
    }, [currentEmails, selectedContacts]);


    const [deleteConfirmOpen, showDeleteConfirmOpen] = useState(false);


    useEffect(() => {
        getContacts();
        getUsers();
    }, [company_id]);

    const getContacts = () => {
        let cachedValues = useGetIDBValues;

        const companyContacts = `contacts/company/${company_id}`;

        // Retrieve contacts for a specific company
        cachedValues(companyContacts, (data: any) => {
            //   console.log('Data from IndexedDB:', data);

            if (data && data.contacts) {
                setContacts(data.contacts); // Update state with contact data
            } else {
                console.error('Invalid contact data structure:', data);
            }
        });
    };

    // console.log('contacts:', contacts);


    const [companyUsers, setCompanyUsers] = useState<User[]>([]);

    const getUsers = () => {
        let cachedValues = useGetIDBValues;

        const companyUsers = `users/company/${company_id}`;

        // Retrieve contacts for a specific company
        cachedValues(companyUsers, (data: any) => {
            //   console.log('Data from IndexedDB:', data);

            if (data && data.users) {
                setCompanyUsers(data.users); // Update state with contact data
            } else {
                console.error('Invalid user data structure:', data);
            }
        });
    };

    console.log('companyUsers:', companyUsers);

    // Extract first_name and last_name into a new array
    // const contactsNames = contacts.map(contact => `${contact.first_name} ${contact.last_name}`);


    const toggleSelectContact = (email: string) => {
        // Check if the contact is already selected
        const isSelected = selectedContacts.includes(email);

        if (isSelected) {
            // Remove the contact from the selected list
            const updatedSelection = selectedContacts.filter((selectedEmail) => selectedEmail !== email);
            setSelectedContacts(updatedSelection);
        } else {
            // Add the contact to the selected list
            setSelectedContacts([...selectedContacts, email]);
        }
    };

    const handleSendToSelected = () => {
        // console.log('selectedContacts:', selectedContacts);
        // Call the parent component with the selected emails
        onSelectItem(selectedContacts);
        onClose()
    };


    /////////////////////////////////////////
    // SORT CONTACTS
    /////////////////////////////////////////

    const [sortOrder, setSortOrder] = useState('asc');
    const [showSortBoth, setShowSortBoth] = useState(true);
    const [showSortUp, setShowSortUp] = useState(false);
    const [showSortDown, setShowSortDown] = useState(false);

    const toggleSortOrder = () => {
        setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    };

    // Function to sort the contacts by name
    const sortContactsByName = () => {
        const sortedContacts = [...contacts, ...companyUsers].sort((a, b) => {
            const nameA = `${a.first_name} ${a.last_name}`.toUpperCase();
            const nameB = `${b.first_name} ${b.last_name}`.toUpperCase();

            if (sortOrder === 'asc') {
                return nameA.localeCompare(nameB);
            } else {
                return nameB.localeCompare(nameA);
            }
        });

        return sortedContacts;
    };

    useEffect(() => {
        if (sortOrder === 'asc') {
            setShowSortBoth(false)
            setShowSortUp(false)
            setShowSortDown(true)
        } else {
            setShowSortBoth(false)
            setShowSortUp(true)
            setShowSortDown(false)
        }
    }, [sortOrder]);




    /////////////////////////////////////////
    // NEW CONTACT
    /////////////////////////////////////////


    const [showAddNewContact, setShowAddNewContact] = useState(false);
    const [showAddNewContactButton, setShowAddNewContactButton] = useState(true);
    const [showEditContact, setShowEditContact] = useState(false);
    const [showEmailErrorMessageAdd, setShowEmailErrorMessageAdd] = useState(false)
    const [showEmailErrorMessageUpdate, setShowEmailErrorMessageUpdate] = useState(false)

    const [newContact, setNewContact] = useState<NewUser>({
        guid: uuidv4(),
        first_name: '',
        last_name: '',
        email: '',
        telephone: '',
        company_id: company_id,
    });



    const handleCheckNewEmail = () => {
        // Validate manually entered email address
        if (isValidEmail(newContact.email)) {

            setShowEmailErrorMessageAdd(false);

            handleAddContact();
            setShowAddNewContact(false)
            setShowAddNewContactButton(true)

        } else {
            // Display an error message or handle invalid email address
            console.error('Invalid email address');
            setShowEmailErrorMessageAdd(true);
        }
    }

    const isValidEmail = (email: string) => {
        // Regular expression for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleAddContact = () => {
        // Add validation if needed

        // Assuming you have a contacts array state
        setContacts((prevContacts) => [
            ...prevContacts,
            newContact,
        ]);

        saveContact()

        // Clear the form after adding a new contact
        setNewContact({
            guid: uuidv4(),
            first_name: '',
            last_name: '',
            email: '',
            telephone: '',
            company_id: company_id,
        });
    };

    console.log('newContact:', newContact);

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setNewContact((prevContact) => ({
            ...prevContact,
            [name]: value,
        }));
    };

    /////////////////////////////////////////
    // SAVE NEW CONTACT
    /////////////////////////////////////////    


    // const saveContact = () => {
    //     const companyContactsIDB = `drillit-contacts/company/${company_id}`;

    //     // Function to save data to IndexedDB
    //     let saveToIDB = useSetIndexedDBValues;

    //     // Step 1: Save the new contact to the 'contacts' table
    //     saveToIDB(newContact, 'contacts', true)
    //         .then(() => {
    //             // Step 2: Retrieve the company's existing contacts
    //             get(companyContactsIDB)
    //                 .then(existingContacts => {
    //                     // Extract the contacts array from the retrieved object
    //                     let contactsArray = existingContacts.contacts || [];

    //                     // Step 3: Add the new contact to the company's contacts list
    //                     let updatedContacts = [...contactsArray, newContact];
    //                     console.log('updatedContacts:', updatedContacts)

    //                     // Step 4: Save the updated company's contacts list back to companyContactsIDB
    //                     set(companyContactsIDB, { contacts: updatedContacts })
    //                         .then(() => {
    //                             console.log('Company contacts updated in IndexedDB');
    //                         })
    //                         .catch((error: unknown) => {
    //                             console.error('Error updating company contacts in IndexedDB:', error);
    //                         });
    //                 })
    //                 .catch((error: unknown) => {
    //                     console.error('Error retrieving company contacts:', error);
    //                 });
    //         })
            
    //         .catch((error: unknown) => {
    //             console.error('Error saving new contact:', error);
    //         });

    //     // Clear the form after adding a new contact
    //     setNewContact({
    //         guid: uuidv4(),
    //         first_name: '',
    //         last_name: '',
    //         email: '',
    //         telephone: '',
    //         company_id: company_id,
    //     });
    // };


    const saveContact = () => {
        const companyContactsIDB = `drillit-contacts/company/${company_id}`;
    
        // Function to save data to IndexedDB
        let saveToIDB = useSetIndexedDBValues;
    
        // Step 1: Save the new contact to the 'contacts' table
        saveToIDB(newContact, 'contacts', true)
            .then(() => {
                console.log('New contact saved to IndexedDB successfully.');
    
                // Step 2: Retrieve the company's existing contacts
                return get(companyContactsIDB);
            })
            .then((existingContacts:any) => {
                // Extract the contacts array from the retrieved object
                let contactsArray = existingContacts.contacts || [];
    
                // Step 3: Add the new contact to the company's contacts list
                let updatedContacts = [...contactsArray, newContact];
                console.log('Updated contacts:', updatedContacts);
    
                // Step 4: Save the updated company's contacts list back to companyContactsIDB
                return set(companyContactsIDB, { contacts: updatedContacts });
            })
            .then(() => {
                console.log('Company contacts updated in IndexedDB successfully.');
                // Optionally update UI or perform other actions after successful update
            })
            .catch((error:any) => {
                console.error('Error saving new contact or updating company contacts:', error);
                // Handle errors from IndexedDB operations
            })
            .finally(() => {
                // Clear the form after adding a new contact
                setNewContact({
                    guid: uuidv4(),
                    first_name: '',
                    last_name: '',
                    email: '',
                    telephone: '',
                    company_id: company_id,
                });
            });
    };
    
    
    







    // const saveContact = () => {
    //     const companyContactsIDB = `drillit-contacts/company/${company_id}`;
    //     const newKeyValue = `drillit-contacts-new`;
    //     const apiPostAddr = `${process.env.REACT_APP_API_ADDR}contacts`;
    
    //     // Function to save data to IndexedDB
    //     let saveToIDB = useSetIndexedDBValues;
    
    //     // Function to post the contact to the server
    //     const postContact = (contact: any) => {
    //         return makeRequest(apiPostAddr, JSON.stringify(contact), 'POST');
    //     };
    
    //     // Step 1: Attempt to post the new contact to the server
    //     postContact(newContact)
    //         .then(response => {
    //             console.log('Response status:', response.status);
    //             if (response.status === 200) {
    //                 console.log('Contact successfully posted to the server.');
    
    //                 // Step 2: Save the new contact to the 'contacts' table in IndexedDB
    //                 return saveToIDB(newContact, 'contacts', true)
    //                     .then(() => {
    //                         // Step 3: Retrieve the company's existing contacts
    //                         return get(companyContactsIDB);
    //                     })
    //                     .then((existingContacts:any) => {
    //                         // Extract the contacts array from the retrieved object
    //                         let contactsArray = existingContacts.contacts || [];
    
    //                         // Step 4: Add the new contact to the company's contacts list
    //                         let updatedContacts = [...contactsArray, newContact];
    //                         console.log('updatedContacts:', updatedContacts);
    
    //                         // Step 5: Save the updated company's contacts list back to companyContactsIDB
    //                         return set(companyContactsIDB, { contacts: updatedContacts });
    //                     })
    //                     .then(() => {
    //                         console.log('Company contacts updated in IndexedDB');
    //                     })
    //                     .catch((error: unknown) => {
    //                         console.error('Error updating company contacts in IndexedDB:', error);
    //                     });
    //             } else {
    //                 throw new Error('Failed to post contact to the server.');
    //             }
    //         })
    //         .catch(error => {
    //             console.error('Error saving new contact:', error);
    
    //             // Add the contact to the offline queue if the server post failed
    //             get(newKeyValue)
    //                 .then(result => {
    //                     let newContactsQueue = result || [];
    //                     newContactsQueue.push(newContact);
    //                     set(newKeyValue, newContactsQueue)
    //                         .then(() => {
    //                             console.log('New contact added to offline queue');
    //                         })
    //                         .catch((error) => {
    //                             console.error('Error adding new contact to offline queue:', error);
    //                         });
    //                 })
    //                 .catch((error) => {
    //                     console.error('Error retrieving offline queue:', error);
    //                 });
    //         });
    
    //     // Clear the form after adding a new contact
    //     setNewContact({
    //         guid: uuidv4(),
    //         first_name: '',
    //         last_name: '',
    //         email: '',
    //         telephone: '',
    //         company_id: company_id,
    //     });
    // };






    
    
    // const saveContact = () => {
    //     const companyContactsIDB = `drillit-contacts/company/${company_id}`;
    //     const newKeyValue = `drillit-contacts-new`;
    //     const apiPostAddr = `${process.env.REACT_APP_API_ADDR}contacts`;
    
    //     // Function to save data to IndexedDB
    //     let saveToIDB = useSetIndexedDBValues;
    
    //     // Function to post the contact to the server
    //     const postContact = (contact:any) => {
    //         return makeRequest(apiPostAddr, JSON.stringify(contact), 'POST');
    //     };
    
    //     // Step 1: Attempt to post the new contact to the server
    //     postContact(newContact)
    //         .then(response => {
    //             if (response.status === 200) {
    //                 console.log('Contact successfully posted to the server.');
    
    //                 // Step 2: Save the new contact to the 'contacts' table in IndexedDB
    //                 return saveToIDB(newContact, 'contacts', true);
    //             } else {
    //                 throw new Error('Failed to post contact to the server.');
    //             }
    //         })
    //         .then(() => {
    //             // Step 3: Retrieve the company's existing contacts
    //             return get(companyContactsIDB);
    //         })
    //         .then(existingContacts => {
    //             // Extract the contacts array from the retrieved object
    //             let contactsArray = existingContacts.contacts || [];
    
    //             // Step 4: Add the new contact to the company's contacts list
    //             let updatedContacts = [...contactsArray, newContact];
    //             console.log('updatedContacts:', updatedContacts);
    
    //             // Step 5: Save the updated company's contacts list back to companyContactsIDB
    //             return set(companyContactsIDB, { contacts: updatedContacts });
    //         })
    //         .then(() => {
    //             console.log('Company contacts updated in IndexedDB');
    //         })
    //         .catch(error => {
    //             console.error('Error saving contact:', error);
    
    //             // Add the contact to the offline queue if the server post failed
    //             get(newKeyValue).then(result => {
    //                 let newContactsQueue = result || [];
    //                 newContactsQueue.push(newContact);
    //                 set(newKeyValue, newContactsQueue).then(() => {
    //                     console.log('New contact added to offline queue');
    //                 }).catch((error) => {
    //                     console.error('Error adding new contact to offline queue:', error);
    //                 });
    //             }).catch((error) => {
    //                 console.error('Error retrieving offline queue:', error);
    //             });
    //         });
    
    //     // Clear the form after adding a new contact
    //     setNewContact({
    //         guid: uuidv4(),
    //         first_name: '',
    //         last_name: '',
    //         email: '',
    //         telephone: '',
    //         company_id: company_id,
    //     });
    // };
    
    const makeRequest = (endpoint: string, data: string, postOrPut: string) => {
        const token = localStorage.getItem('drillit-token');
        console.log(`Making ${postOrPut} request to ${endpoint} with data:`, data); // Log the request details
    
        return fetch(endpoint, {
            method: postOrPut,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: data
        })
        .then(response => {
            if (!response.ok) {
                throw response;
            }
            return response;
        })
        .catch(err => {
            console.error(`Error during ${postOrPut} request to ${endpoint}:`, err);
            throw err;
        });
    }
    


    /////////////////////////////////////////
    // EDIT CONTACT
    /////////////////////////////////////////   

    // const [selectedContact, setSelectedContact] = useState<User | null>(null);
    const [selectedContact, setSelectedContact] = useState<User | null>(null);

    // Function to handle input changes for updating contact
    const handleInputChangeEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setSelectedContact((prevContact) => ({
            ...prevContact!,
            [name]: value,
        }));
    };



    const handleCheckUpdateEmail = () => {
        // Validate manually entered email address
        if (isValidEmail(selectedContact!.email)) {

            setShowEmailErrorMessageUpdate(false);

            handleUpdateContact();
            setShowEditContact(false)
            setShowAddNewContactButton(true)

        } else {
            // Display an error message or handle invalid email address
            console.error('Invalid email address');
            setShowEmailErrorMessageUpdate(true);
        }
    }

    const handleUpdateContact = () => {
        // Add validation if needed

        // Assuming you have a contacts array state
        setContacts((prevContacts) => [
            ...prevContacts.map((contact) =>
                contact.guid === selectedContact!.guid ? { ...contact, ...selectedContact } : contact
            ),
        ]);

        if (selectedContact) {
            updateContactInformation(
                selectedContact.guid,
                selectedContact.first_name,
                selectedContact.email,
                selectedContact.telephone,
                selectedContact.last_name,
                selectedContact.company_id,
            )
        }


        // Clear the form after updating the contact
        setSelectedContact(null);
    };

    // const handleDeleteContact = () => {
    //     // Update prevContacts in state
    //     setContacts((prevContacts) =>
    //         prevContacts.filter((contact) => contact.guid !== selectedContact!.guid)
    //     );

    //     // Call deleteContactInformation function
    //     if (selectedContact) {
    //         deleteContactInformation(selectedContact.guid)
    //             .then((success) => {
    //                 if (success) {
    //                     console.log(`Successfully deleted contact with GUID: ${selectedContact.guid}`);
    //                     // Clear the form after updating the contact
    //                     setSelectedContact(null);
    //                 } else {
    //                     console.error(`Failed to delete contact with GUID: ${selectedContact.guid}`);
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.error(`Error deleting contact with GUID: ${selectedContact.guid}`, error);
    //                 // Handle error as needed
    //             });
    //     } else {
    //         console.error('No selected contact to delete');
    //     }
    // };

    const handleDeleteContact = () => {
        // Update prevContacts in state
        setContacts((prevContacts) =>
            prevContacts.filter((contact) => contact.guid !== selectedContact!.guid)
        );

        // Call deleteContactInformation function
        if (selectedContact) {
            deleteContactInformation(selectedContact.guid, selectedContact.company_id)
                .then((success) => {
                    if (success) {
                        console.log(`Successfully deleted contact with GUID: ${selectedContact.guid}`);

                        // Delete the specific contact from IndexedDB
                        // const contactIDBKey = `drillit-contacts/company/${selectedContact.guid}`;
                        // // get(contactIDBKey)
                        // del(contactIDBKey)
                        //     .then(() => {
                        //         console.log(`Deleted contact from IndexedDB: ${selectedContact.guid}`);
                        //     })
                        //     .catch((error) => {
                        //         console.error(`Error deleting contact from IndexedDB: ${selectedContact.guid}`, error);
                        //     });

                        // Clear the form after updating the contact
                        setSelectedContact(null);
                    } else {
                        console.error(`Failed to delete contact with GUID: ${selectedContact.guid}`);
                    }
                })
                .catch((error) => {
                    console.error(`Error deleting contact with GUID: ${selectedContact.guid}`, error);
                    // Handle error as needed
                });
                setSelectedContact(null);
        } else {
            console.error('No selected contact to delete');
        }
    };

    const handleScrollToTop = () => {
        // Set state or perform actions here as needed
        // Scroll to the top of the modal content container
        const topElement = document.getElementById('top');
        if (topElement) {
            topElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };



    console.log('selectedContacts:', selectedContacts);
    // console.log('contact:', contact);

    return (

        <>

            {/* <Header toggleSidebar={this.props.toggleSidebar} /> */}

            <Modal title="Contact Book" subtitle="" toggleModal={onClose} confirm={false} >
                <>

                    <div id="top"></div>

                    {showAddNewContactButton &&
                        <div className="grid grid-cols-5 gap-x-2 px-2 w-full md:w-11/12 mx-auto h-8 md:h-10 mb-2">
                            <div className="col-span-3 md:col-span-4"></div>
                            <div className="col-span-2 md:col-span-1">
                                <button
                                    type="submit"
                                    className="btn-primary rounded w-full h-full"
                                    onClick={() => {
                                        setShowAddNewContact(true)
                                        setShowAddNewContactButton(false)
                                    }}
                                >
                                    Add New
                                </button>

                            </div>
                        </div>
                    }

                    {showAddNewContact &&
                        <>
                            <div className="border border-grey-mid rounded px-2 pb-2 md:pb-4 bg-sptTB w-full md:w-11/12 justify-center mx-auto mb-2">

                                <form onSubmit={(e) => {
                                    e.preventDefault(); // Prevent the default form submission
                                    handleCheckNewEmail();
                                    // handleAddContact();
                                    // setShowAddNewContact(false)
                                    // setShowAddNewContactButton(true)
                                }}>

                                    <div className="grid grid-cols-5 gap-x-2">

                                        <div className="col-span-5 text-center text-lg py-1 font-bold">Add New Contact</div>
                                        {/* <div className="text-xs">{newContact.guid}</div> */}
                                        <div className="col-span-4 grid grid-cols-1 md:grid-cols-2 gap-x-2">
                                            <div>
                                                <input
                                                    type="text"
                                                    name="first_name"
                                                    className="form-control w-full mb-1"
                                                    placeholder="First Name"
                                                    value={newContact.first_name}
                                                    onChange={handleInputChange}
                                                    required
                                                    autoComplete="off"
                                                />
                                            </div>
                                            <div>
                                                <input
                                                    type="text"
                                                    name="last_name"
                                                    className="form-control w-full mb-3 md:mb-1"
                                                    placeholder="Last Name"
                                                    value={newContact.last_name}
                                                    onChange={handleInputChange}
                                                    required
                                                    autoComplete="off"
                                                />
                                            </div>
                                            <div>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    className="form-control w-full"
                                                    placeholder="Email"
                                                    value={newContact.email}
                                                    onChange={handleInputChange}
                                                    required
                                                    autoComplete="off"
                                                />
                                                {showEmailErrorMessageAdd &&
                                                    <div className="bg-red text-white font-bold text-sm text-center rounded">This is not a valid email address</div>
                                                }
                                            </div>
                                            <div>
                                                <input
                                                    type="text"
                                                    name="telephone"
                                                    className="form-control w-full"
                                                    placeholder="Telephone"
                                                    value={newContact.telephone}
                                                    onChange={handleInputChange}
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                        <div className="row-span-2">
                                            <button
                                                type="submit"
                                                className="btn-orange rounded w-full h-full"
                                            // onClick={handleAddContact}
                                            >
                                                Add
                                            </button>
                                        </div>
                                    </div>

                                </form>

                            </div>
                        </>
                    }

                    {showEditContact && selectedContact && (
                        <>
                            <div className="border border-grey-mid rounded px-2 pb-2 md:pb-2 bg-sptTB w-full md:w-11/12 justify-center mx-auto mb-2">

                                <form onSubmit={(e) => {
                                    e.preventDefault(); // Prevent the default form submission
                                    handleCheckUpdateEmail();
                                    // handleUpdateContact();
                                    // setShowEditContact(false)
                                    // setShowAddNewContactButton(true)
                                }}>

                                    <div className="grid grid-cols-5 gap-x-2">

                                        <div className="col-span-5 text-center text-lg py-1 font-bold">Edit Contact</div>

                                        <div className="col-span-4 grid grid-cols-1 md:grid-cols-2 gap-x-2">
                                        {/* <div>{selectedContact.guid}</div> */}
                                            <div>
                                                <input
                                                    type="text"
                                                    name="first_name"
                                                    className="form-control w-full mb-1"
                                                    placeholder="First Name"
                                                    value={selectedContact.first_name}
                                                    onChange={handleInputChangeEdit}
                                                    required
                                                    autoComplete="off"
                                                />
                                            </div>
                                            <div>
                                                <input
                                                    type="text"
                                                    name="last_name"
                                                    className="form-control w-full mb-3 md:mb-1"
                                                    placeholder="Last Name"
                                                    value={selectedContact.last_name}
                                                    onChange={handleInputChangeEdit}
                                                    required
                                                    autoComplete="off"
                                                />
                                            </div>
                                            <div>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    className="form-control w-full"
                                                    placeholder="Email"
                                                    value={selectedContact.email}
                                                    onChange={handleInputChangeEdit}
                                                    required
                                                    autoComplete="off"
                                                />
                                                {showEmailErrorMessageUpdate &&
                                                    <div className="bg-red text-white font-bold text-sm text-center rounded">This is not a valid email address</div>
                                                }
                                            </div>
                                            <div>
                                                <input
                                                    type="text"
                                                    name="telephone"
                                                    className="form-control w-full"
                                                    placeholder="Telephone"
                                                    value={selectedContact.telephone}
                                                    onChange={handleInputChangeEdit}
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                        <div className="row-span-1">
                                            <button
                                                type="submit"
                                                className="btn-orange rounded w-full h-full"
                                            // onClick={handleAddContact}
                                            >
                                                Update
                                            </button>
                                        </div>
                                        <div className="col-span-4"></div>
                                        <div className="row-span-1">
                                            <button
                                                className="bg-sptSB rounded w-full h-full border border-grey-mid mt-1.5"
                                                onClick={(e) => {
                                                    // handleDeleteContact()
                                                    e.preventDefault(); // Prevent the default form submission
                                                    // setShowAddNewContact(false)
                                                    // setShowAddNewContactButton(true)
                                                    showDeleteConfirmOpen(true)
                                                }}
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </div>

                                </form>

                            </div>
                        </>
                    )}

                    <div className="border border-grey-mid rounded p-2 md:p-4 bg-sptTB w-full md:w-11/12 justify-center mx-auto mb-2">

                        <button
                            onClick={handleSendToSelected}
                            className="btn-primary rounded w-64 h-12 md:h-12 mb-4"
                        >
                            Send to Selected
                        </button>
                        {/* <div className="flex overflow-hidden overflow-scroll contactsHolder"> */}
                        <div className="contactsHolder">

                            <table className='table-auto table-hover w-full text-base md::text-lg text-left'> {/* h-96 overflow-y */}
                                <thead className="bg-grey-mid">
                                    <tr>
                                        <th className="w-1/12 text-xs md:text-sm text-center px-1"
                                            style={{ fontSize: '0.7rem' }}>
                                            Select
                                        </th>
                                        <th className="w-4/12 px-1 md:px-3 py-1 pr-2 relative"
                                            onClick={() => {
                                                toggleSortOrder();
                                            }}>
                                            Name
                                            {/* <span className="text-xs italic absolute right-2 mt-1.5">(click to sort)</span> */}
                                            {/* <span className="text-xs  absolute right-2 mt-1.5"></span> */}
                                            {showSortBoth &&
                                                <img className="inline" src={sortBoth} alt="" />
                                            }
                                            {showSortUp &&
                                                <img className="inline" src={sortUp} alt="" />
                                            }
                                            {showSortDown &&
                                                <img className="inline" src={sortDown} alt="" />
                                            }
                                        </th>
                                        <th className="w-6/12 px-1 md:px-3 py-1">Email / Phone</th>
                                        <th className="w-1/12 border-l border-r border-sptTB text-xs md:text-sm text-center px-1.5" style={{ fontSize: '0.7rem' }}>Edit</th>
                                    </tr>
                                </thead>
                                {/* <tbody className="bg-white text-sm sm:text-base md:text-lg">
                                    {sortContactsByName().map((contact, index) => (
                                        <tr key={index} className="">
                                            <td className="border-r border-b border-grey-mid text-center pr-0 h-16">
                                                <input
                                                    type="checkbox"
                                                    className="form-control-checkbox checkboxLG"
                                                    checked={selectedContacts.includes(contact.email)}
                                                    onChange={() => toggleSelectContact(contact.email)}
                                                />
                                            </td>
                                            <td className={`px-4 py-2 border-r border-b border-grey-mid text-left cursor-pointer h-16`}>
                                                {`${contact.first_name} ${contact.last_name}`}
                                            </td>
                                            <td className="">
                                                <table className="w-full h-full h-16">
                                                    <tbody className="w-full">
                                                        <tr className="w-full">
                                                            <td className={`w-full px-2 md:px-4 py-1 border-b border-grey-mid text-left cursor-pointer h-8`}>
                                                                {contact.email}
                                                            </td>
                                                        </tr>
                                                        <tr className="w-full">
                                                            <td className={`w-full px-2 md:px-4 py-1 border-b border-grey-mid text-left cursor-pointer h-8`}>
                                                                {contact.telephone}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td className="border-b border-l border-r border-grey-mid text-center">
                                                {contacts.includes(contact) ? (
                                                    <img
                                                        onClick={() => {
                                                            setSelectedContact(contact);
                                                            setShowEditContact(true);
                                                            setShowAddNewContactButton(false);
                                                            // Scroll to the top of the page
                                                            handleScrollToTop()
                                                        }}
                                                        className="inline w-4 md:w-7"
                                                        src={edit}
                                                        alt=""
                                                    />
                                                ) : (
                                                    <span className="text-grey-dark">~</span>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody> */}
                                <tbody className="bg-white text-sm sm:text-base md:text-lg">
                                    {sortContactsByName().map((contact, index) => (
                                        <tr key={index} className="">
                                            <td className="border-r border-b border-grey-mid text-center pr-0 h-16">
                                                <CustomCheckbox
                                                    checked={selectedContacts.includes(contact.email)}
                                                    onChange={() => toggleSelectContact(contact.email)}
                                                />
                                            </td>
                                            <td className="px-4 py-2 border-r border-b border-grey-mid text-left cursor-pointer h-16">
                                                {`${contact.first_name} ${contact.last_name}`}
                                            </td>
                                            <td className="">
                                                <table className="w-full h-full h-16">
                                                    <tbody className="w-full">
                                                        <tr className="w-full">
                                                            <td className="w-full px-2 md:px-4 py-1 border-b border-grey-mid text-left cursor-pointer h-8">
                                                                {contact.email}
                                                            </td>
                                                        </tr>
                                                        <tr className="w-full">
                                                            <td className="w-full px-2 md:px-4 py-1 border-b border-grey-mid text-left cursor-pointer h-8">
                                                                {contact.telephone}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td className="border-b border-l border-r border-grey-mid text-center">
                                                {contacts.includes(contact) ? (
                                                    <img
                                                        onClick={() => {
                                                            setSelectedContact(contact);
                                                            setShowEditContact(true);
                                                            setShowAddNewContactButton(false);
                                                            handleScrollToTop();
                                                        }}
                                                        className="inline w-4 md:w-7"
                                                        src={edit}
                                                        alt=""
                                                    />
                                                ) : (
                                                    <span className="text-grey-dark">~</span>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>

                        {/* <div className="flex w-64 mt-4 mx-auto">
                            <button
                                onClick={handleSendToSelected}
                                className="btn-primary rounded w-64 h-12 md:h-12"
                            >
                                Send to Selected
                            </button>
                        </div> */}

                    </div>


                    {deleteConfirmOpen &&
                        <div className="fixed inset-0 z-50">
                            <div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">


                                <div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
                                    Are you sure you want to delete this record?

                                    <div className="flex mt-4">
                                        <Button theme="secondary"
                                            className="mr-5 text-sm sm:text-base px-3.5 md:px-7"
                                            onClick={() => showDeleteConfirmOpen(false)}>
                                            Cancel
                                        </Button>

                                        <Button onClick={() => {
                                            handleDeleteContact()
                                            setShowAddNewContact(false)
                                            setShowAddNewContactButton(true)
                                            showDeleteConfirmOpen(false)
                                        }}
                                            className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">
                                            Delete
                                        </Button>
                                    </div>
                                </div>


                            </div>
                        </div>
                    }

                </>

            </Modal >

        </>

    )
}

ContactBook.defaultProps = defaultProps

export default ContactBook