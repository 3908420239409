import Login from './views/Login'
import Companies from './views/companies/Companies'
import CompaniesAdmin from './views/companies/CompaniesAdmin'
import CompaniesCreate from './views/companies/CompaniesCreate'
import CompaniesUpdate from './views/companies/CompaniesUpdate'
import Company from './views/Company'
import CompaniesUsersCreate from './views/companies/CompaniesUsersCreate'
import UsersUpdate from './views/users/UsersUpdate'
import Rigs from './views/rigs/Rigs'
import RigsCreate from './views/rigs/RigsCreate'
import RigsUpdate from './views/rigs/RigsUpdate'
import DailyPlantCheckListCablePercussive from './views/DailyPlantCheckListCablePercussive'
import DailyPlantCheckListRotary from './views/DailyPlantCheckListRotary'
import DailyPlantCheckListWindowSample from './views/DailyPlantCheckListWindowSample'
import DailyPlantCheckCablePercussive from './views/DailyPlantCheckCablePercussive'
import DailyPlantCheckRotary from './views/DailyPlantCheckRotary'
import DailyPlantCheckWindowSample from './views/DailyPlantCheckWindowSample'
import DailyPlantCheckWindowSampleLog from './views/DailyPlantCheckWindowSampleLog'
import RigsSptCreate from './views/rigs/RigsSptCreate'
import RigsSptUpdate from './views/rigs/RigsSptUpdate'
import EndShift from './views/EndShift'
import Update from './views/Update'
import Profile from './views/Profile'
import ProjectsFilter from './views/projects/ProjectsFilter'
import ProjectsFilterNewHole from './views/projects/ProjectsFilterNewHole'
import ProjectsFilterDynamicProbe from './views/projects/ProjectsFilterDynamicProbe'
import ProjectsFilterHeadTest from './views/projects/ProjectsFilterHeadTest'
import ProjectsFilterSoakaway from './views/projects/ProjectsFilterSoakaway'
import ProjectsFilterPlateTest from './views/projects/ProjectsFilterPlateTest'
import ProjectsFilterCBR from './views/projects/ProjectsFilterCBR'
import ProjectsFilterTRL from './views/projects/ProjectsFilterTRL'
import ProjectsFilterLogging from './views/projects/ProjectsFilterLogging'
import ProjectsCreate from './views/projects/ProjectsCreate'
import ProjectsUpdate from './views/projects/ProjectsUpdate'

import ProjectsUpdateCPBorehole from './views/projects/editHoles/EditCPborehole'

import ProjectsNewCP from './views/projects/newHoles/ProjectsNewCP'
import NewBorehole from './views/projects/newHoles/NewBorehole'
import ProjectsNewWS from './views/projects/newHoles/ProjectsNewWS'
import ProjectsNewRT from './views/projects/newHoles/ProjectsNewRT'
import ProjectsNewTP from './views/projects/newHoles/ProjectsNewTP'
import ProjectsNewDP from './views/projects/newHoles/ProjectsNewDP'
import ProjectsNewLG from './views/projects/newHoles/ProjectsNewLG'
import ProjectsNewHT from './views/projects/newHoles/ProjectsNewHT'
import ProjectsNewSA from './views/projects/newHoles/ProjectsNewSA'
import ProjectsNewPT from './views/projects/newHoles/ProjectsNewPT'
import ProjectsNewCB from './views/projects/newHoles/ProjectsNewCB'
import ProjectsNewTR from './views/projects/newHoles/ProjectsNewTR'
import EditBorehole from './views/projects/editHoles/EditBorehole'
import Projects from './views/projects/Projects'
import ProjectsDashboard from './views/projects/ProjectsDashboard'
import ProjectsWeather from './views/projects/ProjectsWeather'
import ProjectsInfo from './views/projects/ProjectsInfo'
import RiskAssessment from './views/RiskAssessment'
// import ProjectsCablePercussiveBorehole from './views/projects/boreholes/ProjectsCablePercussiveBorehole_class'
import ProjectsCablePercussiveBorehole from './views/projects/boreholes/ProjectsCablePercussiveBorehole_new'
// import ProjectsDynamicProbeBorehole from './views/projects/boreholes/ProjectsDynamicProbeBorehole'
import ProjectsDynamicProbeBorehole from './views/projects/boreholes/ProjectsDynamicProbeBorehole_new'
// import ProjectsHeadTestBorehole from './views/projects/boreholes/ProjectsHeadTestBorehole'
import ProjectsHeadTestBorehole from './views/projects/boreholes/ProjectsHeadTestBorehole_new'
// import ProjectsSoakaway from './views/projects/boreholes/ProjectsSoakaway'
import ProjectsSoakaway from './views/projects/boreholes/ProjectsSoakaway_new'
import ProjectsCBR from './views/projects/boreholes/ProjectsCBR'
import ProjectsPlateTest from './views/projects/boreholes/ProjectsPlateTest'
// import ProjectsRotaryBorehole from './views/projects/boreholes/ProjectsRotaryBorehole'
import ProjectsRotaryBorehole from './views/projects/boreholes/ProjectsRotaryBorehole_new'
// import ProjectsWindowSampleBorehole from './views/projects/boreholes/ProjectsWindowSampleBorehole'
import ProjectsWindowSampleBorehole from './views/projects/boreholes/ProjectsWindowSampleBorehole_new'
// import ProjectsLogging from './views/projects/boreholes/ProjectsLogging'
import ProjectsLogging from './views/projects/boreholes/ProjectsLogging_new'
import ProjectsTRL from './views/projects/boreholes/ProjectsTRL'
// import ProjectsTrialpitBorehole from './views/projects/boreholes/ProjectsTrialpitBorehole'
import ProjectsTrialpitBorehole from './views/projects/boreholes/ProjectsTrialpitBorehole_new'
import RigsCompanies from "./views/rigs/RigsCompanies"
import Logout from "./views/Logout"

import Labels from "./views/Labels"
import PrintLabel from "./views/PrintLabel"
import AGS from "./views/AGS"
import NewHole from "./views/NewHole"
import EditHole from "./views/EditHole"
import SaveLog from "./views/SaveLog"
import Email from "./views/Email"
import CurrentInformation from "./views/Information"
import CrewList from "./views/CrewList"
import RigList from "./views/RigList"
import Sync from "./views/Sync"
import SystemInformation from "./views/System"
import Bluetooth from "./views/BluetoothComponent"
import BluetoothComponent from './views/BluetoothComponent'
import AdminPanel from './views/AdministrationPanel'
import PrinterSetup from './views/PrinterSetup'

const routes = (props: any) => {
	return [
		{
			path: '/',
			view: <Projects {...props} />
		},
		{
			path: '/login',
			view: <Login {...props} />
		},
		{
			path: '/logout',
			view: <Logout {...props} />
		},
		{
			path: '/company',
			view: <Company {...props} />
		},
		{
			path: '/rigs/create/:company/:project',
			view: <RigsCreate {...props} />
		},
		{
			path: '/rigs/companies/:id',
			view: <RigsCompanies {...props} />
		},
		{
			path: '/rigs/update/:id/:company/:project',
			view: <RigsUpdate {...props} />
		},
		{
			path: '/rigs/:company/:project',
			view: <Rigs {...props} />
		},
		{
			path: '/spt/create/:company/:project',
			view: <RigsSptCreate {...props} />
		},
		{
			path: '/spt/update/:id/:company/:project',
			view: <RigsSptUpdate {...props} />
		},
		{
			path: '/daily-plant-check-list-cable-percussive/:company/:project',
			view: <DailyPlantCheckListCablePercussive {...props} />
		},
		{
			path: '/daily-plant-check-list-rotary/:company/:project',
			view: <DailyPlantCheckListRotary {...props} />
		},
		{
			path: '/daily-plant-check-list-window-sample/:company/:project',
			view: <DailyPlantCheckListWindowSample {...props} />
		},
		{
			path: '/daily-plant-check-cable-percussive/:company/:project',
			view: <DailyPlantCheckCablePercussive {...props} />
		},
		{
			path: '/daily-plant-check-rotary/:company/:project',
			view: <DailyPlantCheckRotary {...props} />
		},
		{
			path: '/daily-plant-check-window-sample/:company/:project',
			view: <DailyPlantCheckWindowSample {...props} />
		},
		{
			path: '/end-shift',
			view: <EndShift {...props} />
		},
		{
			path: '/update',
			view: <Update {...props} />
		},
		{
			path: '/profile',
			view: <Profile {...props} />
		},
		{
			path: '/administration_panel',
			view: <AdminPanel {...props} />
		},

		{
			path: '/projects',
			view: <Projects {...props} />
		},
		{
			path: '/projects/create',
			view: <ProjectsCreate {...props} />
		},
		{
			path: '/projects/update/:id',
			view: <ProjectsUpdate {...props} />
		},
		{
			path: '/projects/info/:id',
			view: <ProjectsInfo {...props} />
		},
		{
			path: '/projects/risk-assessment',
			view: <RiskAssessment {...props} />
		},

//////// NEW HOLES /////////////////////////////////////////////////////////////////
		{
			// path: '/newhole/CP/:id/:rig/:driller/:secondman',
			// view: <ProjectsNewCP { ...props} />
			path: '/newhole/:type/:id/',
			view: <NewBorehole { ...props} />
		},
		// {
		// 	path: '/newhole/WS/:id/:rig/:driller/:secondman',
		// 	view: <ProjectsNewWS { ...props} />
		// },
		// {
		// 	path: '/newhole/RT/:id/:rig/:driller/:secondman',
		// 	view: <ProjectsNewRT { ...props} />
		// },
		// {
		// 	path: '/newhole/TP/:id/:rig/:driller/:secondman',
		// 	view: <ProjectsNewTP { ...props} />
		// },
		// {
		// 	path: '/newhole/DP/:id/:rig/:driller/:secondman',
		// 	view: <ProjectsNewDP { ...props} />
		// },
		// {
		// 	path: '/newhole/LG/:id/:rig/:driller/:secondman',
		// 	view: <ProjectsNewLG { ...props} />
		// },
		// {
		// 	path: '/newhole/HT/:id/:rig/:driller/:secondman',
		// 	view: <ProjectsNewHT { ...props} />
		// },
		// {
		// 	path: '/newhole/SA/:id/:rig/:driller/:secondman',
		// 	view: <ProjectsNewSA { ...props} />
		// },
		// {
		// 	path: '/newhole/PT/:id/:rig/:driller/:secondman',
		// 	view: <ProjectsNewPT { ...props} />
		// },
		// {
		// 	path: '/newhole/CB/:id/:rig/:driller/:secondman',
		// 	view: <ProjectsNewCB { ...props} />
		// },
		// {
		// 	path: '/newhole/TR/:id/:rig/:driller/:secondman',
		// 	view: <ProjectsNewTR { ...props} />
		// },

//////// EDIT HOLES /////////////////////////////////////////////		
		{
			path: '/projects/edit_borehole/:id/:type/:borehole/:boreholeName/:rig/:driller/:secondman',
			// path: '/projects/edit_borehole/:id/:type/:borehole/',
			view: <EditBorehole {...props} />
		},

		{
			path: '/projects/dashboard/:id/company/:company',
			view: <ProjectsDashboard {...props} />
		},
		{
			path: '/projects/weather/:id',
			view: <ProjectsWeather {...props} />
		},
		// {
		// 	path: '/projects/dashboard/:id/dynamic-probe',
		// 	view: <ProjectsFilterDynamicProbe {...props} />
		// },
		// {
		// 	path: '/projects/dashboard/:id/trl',
		// 	view: <ProjectsFilterTRL {...props} />
		// },
		// {
		// 	path: '/projects/dashboard/:id/head-test',
		// 	view: <ProjectsFilterHeadTest {...props} />
		// },
		// {
		// 	path: '/projects/dashboard/:id/soakaway',
		// 	view: <ProjectsFilterSoakaway {...props} />
		// },
		// {
		// 	path: '/projects/dashboard/:id/plate-test',
		// 	view: <ProjectsFilterPlateTest {...props} />
		// },
		// {
		// 	path: '/projects/dashboard/:id/cbr',
		// 	view: <ProjectsFilterCBR {...props} />
		// },
		// {
		// 	path: '/projects/dashboard/:id/logging',
		// 	view: <ProjectsFilterLogging {...props} />
		// },
		{
			path: '/projects/dashboard/company/:company/:id/:filter',
			view: <ProjectsFilter {...props} />
		},
		{
			path: '/projects/dashboard/company/:company/:id/:filter/newhole/:newhole',
			view: <ProjectsFilterNewHole {...props} />
		},
		{
			path: '/projects/dashboard/company/:company/:id/cable-percussive/:borehole',
			view: <ProjectsCablePercussiveBorehole {...props} />
		},
		{
			path: '/projects/dashboard/company/:company/:id/dynamic-probe/:borehole',
			view: <ProjectsDynamicProbeBorehole {...props} />
		},
		{
			path: '/projects/dashboard/company/:company/:id/trl/:borehole',
			view: <ProjectsTRL {...props} />
		},
		{
			path: '/projects/dashboard/company/:company/:id/head-test/:borehole',
			view: <ProjectsHeadTestBorehole {...props} />
		},
		{
			path: '/projects/dashboard/company/:company/:id/soakaway/:borehole',
			view: <ProjectsSoakaway {...props} />
		},
		{
			path: '/projects/dashboard/company/:company/:id/cbr/:borehole',
			view: <ProjectsCBR {...props} />
		},
		{
			path: '/projects/dashboard/company/:company/:id/plate-test/:borehole',
			view: <ProjectsPlateTest {...props} />
		},
		{
			path: '/projects/dashboard/company/:company/:id/rotary/:borehole',
			view: <ProjectsRotaryBorehole {...props} />
		},
		{
			path: '/projects/dashboard/company/:company/:id/window-sample/:borehole',
			view: <ProjectsWindowSampleBorehole {...props} />
		},
		{
			path: '/projects/dashboard/company/:company/:id/logging/:borehole',
			view: <ProjectsLogging {...props} />
		},
		{
			path: '/projects/dashboard/company/:company/:id/trialpit/:borehole',
			view: <ProjectsTrialpitBorehole {...props} />
		},
		{
			path: '/labels',
			view: <Labels {...props} />
		},
		{
			path: '/printlabel',
			view: <PrintLabel {...props} />
		},
		{
			path: '/ags',
			view: <AGS {...props} />
		},
		{
			path: '/newhole',
			view: <NewHole {...props} />
		},
		{
			path: '/edithole',
			view: <EditHole {...props} />
		},
		{
			path: '/savelog',
			view: <SaveLog {...props} />
		},
		{
			path: '/email',
			view: <Email {...props} />
		},
		{
			path: '/information',
			view: <CurrentInformation {...props} />
		},
		{
			path: '/crew',
			view: <CrewList {...props} />
		},
		{
			path: '/riglist:companyguid',
			view: <RigList {...props} />
		},
		{
			path: '/sync',
			view: <Sync {...props} />
		},
		{
			path: '/system',
			view: <SystemInformation {...props} />
		},
		{
			path: '/bluetooth',
			view: <BluetoothComponent {...props} />
		},
		{
			path: '/printer',
			view: <PrinterSetup {...props} />
		},
	]
}

export default routes
