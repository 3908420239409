import { del } from 'idb-keyval';

export const updateWeather = (
    hole_guid: string, 
    weather: string,
    ) => {
    return new Promise((resolve, reject) => {
        const endpoint = `${process.env.REACT_APP_API_ADDR}hole-locations/updateWeather/${hole_guid}`
        
        const updateData = {
            'weather': weather,
        }

        console.log('updateData:', updateData)

        makeRequest(endpoint, JSON.stringify(updateData), 'PUT')
            .then(() => {
                // del(`drillit-hole-actions/${hole_guid}`)
                // console.log('cleanup on aisle 9')
            })

        return resolve(true)
    })
}

const makeRequest = (endpoint: string, data: string, postOrPut: string) => {
    const token = localStorage.getItem('drillit-token')

    return fetch(endpoint, {
        method: postOrPut,
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: data
    })
        //return the status code here
        .then(response => response)
        .catch(err => err)
}
