// import React, { forwardRef, useImperativeHandle, useEffect } from 'react'
import React, { forwardRef, useEffect } from 'react'
type Props = {
    className?: string;
    aboveInput?: JSX.Element;
    defaultValue: string;
    title?: string;
    presets?: any[];
    onUpdate: (value: string) => void;
    onReset: () => void; // Added onReset to the type definition
    ref?: React.RefObject<any>;
    value: string; // Lifted state as a prop
    setValue: React.Dispatch<React.SetStateAction<string>>; // Function to update the lifted state
  };

// const defaultProps: Props = {
// 	className: '',
// 	defaultValue: '',
// 	title: '',
// 	aboveInput: <></>,
// 	presets: [],
// 	onUpdate: (value: any) => {},
//     onReset: () => {},
// }

const keys = [
	'1', '2', '3',
	'4', '5', '6',
	'7', '8', '9',
	'.', '0',
]

const NumberInput: React.ForwardRefRenderFunction<any, Props> = ({ className, defaultValue, aboveInput, presets, title, 
    onUpdate, onReset, ref, value, setValue,  ...rest }) => {
	
        // const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
        // Update the local state when the lifted state changes
        setValue(defaultValue);
      }, [defaultValue, setValue]);

    console.log('value:', value)

	const addCharacter = (character: string) => {
		let newValue = value

		if(value === '')
			newValue = character
		else
			newValue = value + character

		setValue(newValue)
		onUpdate(newValue)
	}

	const removeCharacter = () => {
		let newValue = value

		if(value !== '') {
			if(value.length === 1)
				newValue = ''
			else
				newValue = value.slice(0, -1)
		}

		setValue(newValue)
		onUpdate(newValue)
	}

	const presetClick = (value: string) => {
		setValue(value)
		onUpdate(value)
	}

    // // Move useImperativeHandle outside the return statement
    // useImperativeHandle(
    //   ref,
    //   () => ({
    //     resetValue: () => {
    //       setValue('');
    //       onReset();
    //     },
    //   }),
    //   [onReset]
    // );

	return (
		<div className="bg-sptTB w-full">
			<div className={`w-full ${className}`}>
				{title ? <div className="text-base sm:text-xl text-center">{title}</div> : <></>}

				{(presets && presets.length) ?
					<>
						<div className="grid grid-cols-5 gap-0 mb-2 h-12">
							{/* <div className="w-10 col-span-1"></div> */}

							<div className="col-span-3 flex mx-4 sm:m-auto items-center justify-center border border-orange rounded text-3xl bg-white h-10 ml-2 w-24 sm:w-52 ">
								{value ? value : ''}
							</div>

							<div className="col-span-2 text-center -mt-1">
                            <div className="text-xs text-center -mb-0.5 md:mb-0">Remaining</div>
								{presets.map((preset, index) => {
									return <button className="bg-blue text-white rounded pt-0 md:pt-0.5 pb-0 md:pb-0.5 mb-0.5 w-full md:w-8/12 align-middle text-base md:text-lg leading-6" 
									key={index}
									onClick={() => {
										presetClick(preset.value)
										}}>
									{preset.label}
									</button>
								})}
							</div>
						</div>
					</>
					:
					<>
						{/* <div className="grid grid-cols-3 gap-3 mb-8">
							<div></div> */}
						<div className="mx-auto w-full mt-4 w-full md:w-2/5 lg:w-2/5">
							<div></div>

							<div className="border border-orange rounded py-3 text-center text-4xl bg-white h-16 mx-auto w-44 sm:w-60">
								{value ? value : ''}
							</div>

							<div></div>
						</div>
					</>
				}

				{aboveInput}

				<div className="grid grid-cols-3  gap-1 md:gap-2 ml-2 md:ml-8 mr-2 md:mr-8">
					{keys.map((key, index) => {
						return <div className="border border-orange bg-white rounded py-1 text-center text-xl" key={index} onClick={() => addCharacter(key)}>{key}</div>
					})}

					{/* <div className="border border-orange bg-white rounded py-2 text-center text-2xl" onClick={() => removeCharacter()}>⌫</div> */}
					<div className="border border-orange bg-white rounded py-1 text-center text-base" onClick={() => removeCharacter()}>DEL</div>
				</div>
			</div>
		</div>
	)

}



export default forwardRef(NumberInput);