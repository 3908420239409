import React, { FunctionComponent, useState, useEffect, Component } from 'react'

import Modal from '../components/Modal'
import Button from '../components/form/Button'
import InputGroup from '../components/form/InputGroup'
import TextArea from '../components/form/TextArea'

import ContactBook from './ContactBook'
import Attachments from './Attachments'
import icon_contacts from '../assets/icons/icon_contacts.png'
import { useGetLoggedInUser } from '../helpers/getLoggedInUser'
import { useSetIndexedDBValues } from '../helpers/addToIDB'

type Props = {
	onClose: any,
	// onComplete: any,
	defaultValues?: any,
	projectId?: string,
	company_id?: any,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => {},
	// onComplete: () => {},
	projectId: '',
	company_id: '',
	defaultValues: {
		guid: '',
        email: '',
        emailSubject: "Rob's Test Project files: BH004, BH005, AGS",
        emailMessage: "Please find attached files for:\nLocation: Reedham\nProject: Rob's Test Project\nFiles: BH004, BH005, AGS",
	},
}



const Email: FunctionComponent<Props> = ({ onClose, defaultValues, company_id, project_location, project_id, project_guid, selected_date, ...rest }) => {
	
	const [email, setEmail] = useState('')
    const [emailSubject, setEmailSubject] = useState(defaultValues.emailSubject)
    const [emailMessage, setEmailMessage] = useState(defaultValues.emailMessage)
    const [emailMessageScreen, setEmailMessageScreen] = useState(defaultValues.emailMessage)

    console.log('selected_date:', selected_date)

    const [projectLocation, setProjectLocation] = useState(project_location)
    const [projectId, setProjectId] = useState(project_id)

    // const sanitizedProjectLocation = projectLocation.replace(/[ -]+/g, '');
    // const sanitizedProjectName = projectId.replace(/[ -]+/g, '');
    const sanitizedProjectLocation = projectLocation.replace(/ +/g, ''); // just remove 'space'
    const sanitizedProjectName = projectId.replace(/ +/g, ''); // just remove 'space'

    const loggedInUser = useGetLoggedInUser()
	const User = loggedInUser && (loggedInUser?.user.first_name + ' ' + loggedInUser?.user.last_name)
    console.log('User:', User)
    // console.log(projectLocation, projectId, project_guid)

	useEffect(() => {
		setEmailSubject(projectLocation + ' (' + projectId + ')')
		setEmailMessageScreen("Please find attached files for:\nLocation: " + projectLocation + "\nProject: " + projectId  + "\nOperative: " + User  + "\n")
        setEmailMessage("Please find attached files for:<br>Location: " + projectLocation + "<br>Project: " + projectId  + "<br>Operative: " + User  + "<br>");

	}, [projectLocation, projectId, User]);

    const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
    const [manualEmailInput, setManualEmailInput] = useState('');

    const [selectedAttachments, setSelectedAttachments] = useState<string[]>([]);

    const [companyID, setCompanyID] = useState(company_id)
    // console.log('companyID:', companyID)

    // console.log(defaultValues)

    const [showContactBook, setShowContactBook] = useState(false)
    const openContactBook = () => setShowContactBook(true)

    const [showAttachments, setShowAttachments] = useState(false)
    const openAttachments = () => setShowAttachments(true)
    

	const handleSelectedEmails = (selectedContacts: any) => {
		// console.log('selectedEmails************************************:', selectedContacts)
        setSelectedEmails(selectedContacts);
    }

    const handleInputChange = (value: string) => {
      setEmail(value);
    };
    

	const handleSelectedAttachments = (selectedAttachments: any) => {
		console.log('selectedAttachments************************************:', selectedAttachments)
        setSelectedAttachments(selectedAttachments);
    }

    const renderAttachments = () => {
        return (
        <div className="flex flex-wrap justify-center">
            {selectedAttachments.map(attachment => {
                if (attachment.includes('.ags')) {
                    return (
                        <div key={attachment} className="px-2 py-0.5 text-xs md:text-base bg-orange text-white font-bold rounded m-1">
                            AGS
                        </div>
                    );
                } else if (attachment.includes('risk_assessments')) {
                    return (
                        <div key={attachment} className="px-2 py-0.5 text-xs md:text-base bg-grey-dark text-white font-bold rounded m-1">
                            Risk Assessment
                        </div>
                    );
                } else if (attachment.includes('plant-checks')) {
                    // Extract date from the attachment
                    const dateIndex = attachment.lastIndexOf('-');
                    const datePart = attachment.substring(dateIndex + 1, dateIndex + 9); // Extract 8 characters for the date
                    const date = `${datePart.substring(6, 8)}/${datePart.substring(4, 6)}/${datePart.substring(0, 4)}`; // Format date as dd/mm/yyyy
                    
                    return (
                        <div key={attachment} className="px-2 py-0.5 text-xs md:text-base bg-orange text-white font-bold rounded m-1">
                            PlantCheck - {date}
                        </div>
                    );
                } else if (attachment.includes('logs')) {
                    // Extract log information from the attachment
                    const firstHyphenIndex = attachment.indexOf('-');
                    const secondHyphenIndex = attachment.indexOf('-', firstHyphenIndex + 1);
                    const logInfo = attachment.substring(secondHyphenIndex + 1, attachment.lastIndexOf('-')); // Extract the part after the second hyphen and before the last hyphen
                    
                    // Extract date from the attachment
                    const dateIndex = attachment.lastIndexOf('-');
                    const datePart = attachment.substring(dateIndex + 1, dateIndex + 9); // Extract 8 characters for the date
                    const date = `${datePart.substring(6, 8)}/${datePart.substring(4, 6)}/${datePart.substring(0, 4)}`; // Format date as dd/mm/yyyy
                    
                    return (
                        <div key={attachment} className="px-2 py-0.5 text-xs md:text-base bg-blue text-white font-bold rounded m-1">
                            {logInfo} - {date}
                        </div>
                    );
                } else {
                    // Extract the file name from the log_url
                    const fileName = attachment.substring(attachment.lastIndexOf('/') + 1);
                    return (
                        <div key={attachment}>
                            {fileName}
                        </div>
                    );
                }
            })}
        </div>
        );
    };

    const [showManualEmailErrorMessage, setShowManualEmailErrorMessage] = useState(false)
    const [showEmailErrorMessage, setShowEmailErrorMessage] = useState(false)
    const [showEmailSuccessMessage, setShowEmailSuccessMessage] = useState(false)

    const handleSendEmail1 = () => {
        console.log('Send Email')
        console.log('manualEmailInput', manualEmailInput)
        console.log('selectedEmails', selectedEmails)
        if(manualEmailInput && selectedEmails.length > 0) {
            // Validate manually entered email address
            if (isValidEmail(manualEmailInput)) {
                // Add the valid email address to selectedEmails
                setSelectedEmails([...selectedEmails, manualEmailInput]);
                // Clear the manualEmailInput field
                setManualEmailInput('');
                setShowManualEmailErrorMessage(false);
                setShowEmailErrorMessage(false)
    
                sendEmail()
                setShowEmailSuccessMessage(true)
            } else {
                // Display an error message or handle invalid email address
                console.error('Invalid email address');
                setShowManualEmailErrorMessage(true);
                setShowEmailSuccessMessage(false)
            }
        } else if (!manualEmailInput && selectedEmails.length > 0){
            sendEmail()
            setShowEmailSuccessMessage(true)
        } else if (manualEmailInput && !selectedEmails){
            // Add the valid email address to selectedEmails
            setSelectedEmails([...selectedEmails, manualEmailInput]);
            // Clear the manualEmailInput field
            setManualEmailInput('');
            setShowEmailErrorMessage(false)
            sendEmail()
            setShowEmailSuccessMessage(true)
        } else {
            setShowEmailErrorMessage(true)
            setShowEmailSuccessMessage(false)
        }
    };

    const handleSendEmail = () => {
        if (isValidEmail(manualEmailInput) && selectedEmails.length > 0) {
            // Add the valid email address to selectedEmails
            setSelectedEmails([...selectedEmails, manualEmailInput]);
            // Clear the manualEmailInput field
            setManualEmailInput('');
            setShowManualEmailErrorMessage(false);
            setShowEmailErrorMessage(false)

            sendEmail()
            setShowEmailSuccessMessage(true)
        }
        if (!manualEmailInput && selectedEmails.length > 0){
            // Add the valid email address to selectedEmails
            // setSelectedEmails([...selectedEmails, manualEmailInput]);
            // Clear the manualEmailInput field
            setManualEmailInput('');
            setShowManualEmailErrorMessage(false);
            setShowEmailErrorMessage(false)

            sendEmail()
            setShowEmailSuccessMessage(true)
        }
        if (manualEmailInput && selectedEmails.length > 0){
            if (isValidEmail(manualEmailInput)){
                setShowManualEmailErrorMessage(true);
                setShowEmailSuccessMessage(false)
            } else {
                // Add the valid email address to selectedEmails
                setSelectedEmails([...selectedEmails, manualEmailInput]);
                // Clear the manualEmailInput field
                setManualEmailInput('');
                setShowManualEmailErrorMessage(false);
                setShowEmailErrorMessage(false)
    
                sendEmail()
                setShowEmailSuccessMessage(true)
            }
        }
        if (isValidEmail(manualEmailInput) && selectedEmails.length === 0){
            // Add the valid email address to selectedEmails
            setSelectedEmails([...selectedEmails, manualEmailInput]);
            // Clear the manualEmailInput field
            setManualEmailInput('');
            setShowManualEmailErrorMessage(false);
            setShowEmailErrorMessage(false)

            sendEmail()
            setShowEmailSuccessMessage(true)
        }
        if (!manualEmailInput && selectedEmails.length === 0){
            setShowManualEmailErrorMessage(false);
            setShowEmailErrorMessage(true)
        }

    }

    const isValidEmail = (email: string) => {
        // Regular expression for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    useEffect(() => {
        if (showEmailSuccessMessage) {
            const timer = setTimeout(() => {
                setShowEmailSuccessMessage(false);
            }, 3000);

            return () => clearTimeout(timer); // Cleanup the timer when the component unmounts or showEmailSuccessMessage changes
        }
    }, [showEmailSuccessMessage]);

    const sendEmail = () => {

        console.log('Sending Email')

        // Define the data to send in the request body
        const data = {
            log_paths: selectedAttachments, // Assuming selectedAttachments contains the array of log file paths
            emails: selectedEmails, // Assuming selectedEmails contains the array of email addresses
            subject: emailSubject, // Assuming emailSubject contains the subject message
            message: emailMessage // Assuming emailMessage contains the email body text
        };

        // console.log('Email Data:', data)

        try {
            // Create Logs records
            // console.log('Email Data:', data)
            let saveToIDB = useSetIndexedDBValues;
            saveToIDB(data, 'email', true)
  
          } catch (error) {
            // console.error('Error sending email:', error);
          }
    }

    console.log('companyID:', companyID)

	return (

        <>

				<Modal title="Email" subtitle="" toggleModal={onClose} confirm={false} >
				
                {showContactBook && 
                    <ContactBook onClose={() => { setShowContactBook(false) }} 
                    companyID = {companyID}
                    onSelectItem={handleSelectedEmails}
                    currentEmails={selectedEmails}
                    />
                }
				
                {showAttachments && 
                    <Attachments onClose={() => { setShowAttachments(false) }} 
                    companyID = {companyID}
                    onSelectItem={handleSelectedAttachments}
                    currentAttachments={selectedAttachments}
                    projectId={projectId}
                    projectGUID={project_guid}
                    sanitizedProjectLocation={sanitizedProjectLocation}
                    sanitizedProjectName={sanitizedProjectName}
                    selected_date={selected_date}
                    />
                }

				
                    <div className="grid grid-cols-2 gap-3 border border-grey-mid rounded px-4 bg-sptTB w-full md:w-11/12 justify-center mx-auto mb-4 pt-4 pb-2">
                    
                        <div className="col-span-2 lg:col-span-1">

                        {showEmailErrorMessage &&
                            <div className="col-span-2 bg-red text-white font-bold text-sm text-center rounded">
                                You must enter a valid email address!
                            </div>
                        }

                        {showEmailSuccessMessage &&
                            <div className="col-span-2 bg-green-button text-white font-bold text-sm text-center rounded">
                                Email Sent
                            </div>
                        }

						    <div className="flex w-full md:w-2/3 lg:w-full mx-auto">
                                {/* <InputGroup 
                                label="Email Address(s)" 
                                defaultValue={defaultValues.email || ''}
                                value={email} 
                                onChange={(value: any) => setEmail(value)} 
                                className="w-10/12 mb-1 md:mb-2 font-bold" // lg:w-full
                                /> */}


                                <InputGroup
                                    label="Email Address(s)"
                                    value={selectedEmails.join('; ')}
                                    onChange={handleInputChange}
                                    className="w-10/12 mb-1 md:mb-2 font-bold"
                                    onClick={openContactBook}
                                />

                                <div onClick={openContactBook} className="pt-5" >
                                    <img src={icon_contacts} className="h-10 w-10 ml-4" alt="" />
							    </div>
                                
                            </div>
                            <div className="mb-2">
                                <InputGroup
                                    label="Enter manually:"
                                    value={manualEmailInput}
                                    onChange={(value: any) => setManualEmailInput(value)} 
                                    className="w-full md:w-2/3 lg:w-full mx-auto font-bold"
                                />
                                {showManualEmailErrorMessage &&
                                    <div className="bg-red text-white font-bold text-sm text-center rounded">This is not a valid email address</div>
                                }
                            </div>
                            <div className="">
                                <InputGroup 
                                label="Subject" 
                                value={emailSubject} 
                                onChange={(value: any) => setEmailSubject(value)} 
                                className="w-full md:w-2/3 lg:w-full mx-auto mb-2 font-bold"
                                />
                            </div>
                        </div>
                        <div className="col-span-2 lg:col-span-1">
                            <div className="">
                                <TextArea 
                                label="Message" 
                                value={emailMessageScreen} 
                                onChange={(value: any) => {
                                    setEmailMessageScreen(value)
                                    setEmailMessage(value)
                                }} 
                                className="w-full md:w-2/3 lg:w-full mx-auto mb-2 align-text-top text-base font-bold"
                                inputCSS="h-36 md:h-44 overflow-x-auto text-xs md:text-sm"
                                numberOfRows={5}
                                />
                            </div>
                        </div>
                        
                    </div>

                    <div className="border border-grey-mid rounded px-4 bg-sptTB w-full md:w-11/12 justify-center mx-auto mb-2 pt-2">

                        <div className="text-center text-base md:text-xl mb-1">Attachments</div> 
										
                        {/* <div className="text-center mx-auto bg-modal-bg ags-item items-center rounded text-base md:text-lg px-4 md:px-5 py-1 md:py-2 w-full mb-4">
                            Todays Logs <input type="checkbox" className="form-control-checkbox" defaultChecked={true} />
                            <div className="text-center text-sm">(2 logs + AGS)</div>
                        </div> */}

                        <div className="text-center mx-auto bg-modal-bg ags-item items-center rounded text-base md:text-lg px-4 md:px-4 pt-1 md:pt-2 pb-2 md:pb-4 w-full mb-4">
                        {renderAttachments()}
                             <div onClick={openAttachments} className="text-center text-base mt-2">(Select files)</div> 

                        </div> 
                    
                    </div>

                    <div className="text-center flex justify-center">
                        <Button onClick={onClose} type="submit" theme="secondary" className="w-60 py-2 text-lg font-bold mr-2">Cancel</Button>
                        <Button onClick={handleSendEmail} type="submit" theme="primary" className="w-60 py-2 text-lg font-bold ml-2">Send Email</Button>
                    </div>



				</Modal>

            </> 

	)
}

Email.defaultProps = defaultProps

export default Email