import React, { FunctionComponent, useState } from 'react'

type Props = {
	className?: string,
	options: any[],
	defaultValue: string,
	title?: string,
	columns?: number,
	columnsMobile?: number,
	gap?: number,
	buttonHeight?: number,
	buttonHeightMobile?: number,
	onUpdate: (value: string) => void;
	[x: string]: any;
}

const defaultProps: Props = {
	className: '',
	options: [],
	defaultValue: '',
	title: '',
	columns: 3,
	columnsMobile: 2,
	gap: 2,
	buttonHeight: 20,
	buttonHeightMobile: 10,
	onUpdate: (value: any) => {},
}

const ButtonOptions: FunctionComponent<Props> = ({
    className,
    options,
    defaultValue,
    title,
    columns,
    columnsMobile,
    gap,
    buttonHeight,
    buttonHeightMobile,
    next,
    onUpdate,
    ...rest
}) => {
    const [value, setValue] = useState(defaultValue);
	console.log('defaultValue:', defaultValue)

    const update = (option: any) => {
        setValue(option);
        onUpdate(option);
    };

    return (
        <div className={`w-full ${className}`}>
            {title && (
                <div className="text-base sm:text-xl mb-4 text-center">{title}</div>
            )}

            <div
                className={`grid grid-cols-${columnsMobile} sm:grid-cols-${columns} md:grid-cols-${columns} gap-${gap}`}
            >
                {options.map((option, index) => {
                    let optionValue = '';
                    let optionLabel = '';

                    if (typeof option === 'object' && 'label' in option && option !== null) {
                        optionValue = option.value;
                        optionLabel = option.label;
                    } else {
                        optionValue = option;
                        optionLabel = option;
                    }

                    return (
                        <div
                            className={`${
                                optionValue === value
                                    ? 'border-4 border-orange pt-0 md:pt-1'
                                    : 'border border-grey-mid pt-1'
                            } bg-white rounded text-center align-middle h-${buttonHeightMobile} sm:h-${buttonHeight}`}
                            key={index}
                            onClick={() => {
                                update(optionValue);
                                setTimeout(() => {
                                    next?.();
                                }, 500); // Delay of 1/2 second (500 milliseconds)
                            }}
                        >
                            {optionLabel}
                        </div>
                    );
                })}
            </div>

			{/* {defaultValue !=='' &&
				<div className="font-bold text-lg">Selected: {defaultValue}</div>
			} */}

        </div>
    );
};

ButtonOptions.defaultProps = defaultProps

export default ButtonOptions
