import React, { FunctionComponent } from 'react'

type Props = {
	type?: string,
	label: string,
	value: string,
	placeholder?: string,
	required?: boolean,
	multiline?: boolean,
	numberOfRows: number,
	className?: string,
	inputCSS?: string,
	onChange: (value: any) => void;
	[x: string]: any;
}

const defaultProps: Props = {
	type: 'textarea',
	label: '',
	value: '',
    multiline: true,
	placeholder: '',
	required: false,
	className: '',
	inputCSS: '',
	numberOfRows: 5,
	onChange: (value) => {},
}

const InputGroup: FunctionComponent<Props> = ({ type, label, value, placeholder, required, className, inputCSS, numberOfRows, onChange, ...rest }) => {
	const handleChange = (event: any) => {
		onChange(event.target.value)
	}

	return (
		<div className={`flex flex-col ${className}`}>
			{label && <label className="w-full text-charcoal mb-1">{label}</label>}

		<textarea className={`form-control ${inputCSS}`} rows={numberOfRows} placeholder={placeholder} value={value?value:''} required={required} onChange={handleChange} {...rest} />
		</div>
	)
}

InputGroup.defaultProps = defaultProps

export default InputGroup
