import { get } from 'idb-keyval'
import { setIDB } from './setIDBKey'
import { usePostToApi } from "./postToAPI-LOGS";

// take in a string of new values and the key you want to save against
export const useSetIndexedDBValues = (values: object, idbKey: string, create?: boolean, update?: string): any => {

    console.log('addToIDB-LOGS - PROPS: values:', values, 'idbKey', idbKey, 'create:', create, 'update:', update)

    if (update){
        console.log('addToIDB-LOGS - UPDATE')
    }

    // const fullEndpoint = `${process.env.REACT_APP_API_ADDR}${idbKey}`
    const existingKey = `drillit-${idbKey}`
    const newKey = `${idbKey}-new`
    const updateKey = `${idbKey}-update`

    return new Promise( (result: any, reject: any) => {
        getIDB(existingKey).then(result)
        console.log('addToIDB-LOGS - getIDB(existingKey):', result, 'getIDB(existingKey):', getIDB(existingKey))
    })    
        .then(result => {
            if (!update) {
                const newResult = {}
                if (result) {
                    const newResult = setIDB(values, existingKey, result as object[])
                } else {
                    const newResult = setIDB(values, existingKey)
                }
            }
            console.log('addToIDB-LOGS - CREATE - setIDB(values, existingKey, result as object[]):', result)
            return result
        }) 
        // .then(result => {
        //     if (!update) {
        //         const newResult = {}
        //         if (result) {
        //             const newResult = setIDB(values, existingKey, result as object[]);
        //         } else {
        //             const newResult = setIDB(values, existingKey);
        //         }
        //         console.log('Add to IDB newResult:', newResult);
        //         return newResult;
        //     } else {
        //         console.log('Add to IDB result:', result);
        //         return result;
        //     }
        // })
        .then(result => {
            // if create is true then we can add to another object of purely 'new' entries by recursively calling this function
            if (create) {
                const saveNewKey = useSetIndexedDBValues
                console.log('addToIDB-LOGS - CREATE - we can add to another object of purely new entries by recursively calling this function')
                // console.log('saveNewKey:', saveNewKey)
                return saveNewKey(values, newKey)
            }
            return result
        })
        .then(result => {
            if (update) {
                const newResult: any = result
                const newItem: any = values
                const updatedArray = newResult.map((obj: any) => obj.guid === newItem.guid ? newItem : obj )
                console.log('addToIDB-LOGS - UPDATE - setIDB ON UPDATE!!!')
                setIDB(updatedArray, existingKey)
                // const sendToIDB = setIDB(updatedArray, existingKey)
            }
            return result
        })
        .then(result => {
            // create a new object of updated values
            if (update) {
                const saveUpdateKey = useSetIndexedDBValues
                console.log('addToIDB-LOGS - UPDATE - create a new object of updated values')
                return saveUpdateKey(values, updateKey)
            }
            return result
        })
        .then(result => {
            // push to api in here ///
            if (create) {
                const postToApi = usePostToApi
                console.log('addToIDB-LOGS - CREATE - Push to API if create')
                console.log('addToIDB-LOGS - idbKey:', idbKey)
                return postToApi(idbKey, 'POST')
            }
            // we can return a variable to check for  redirect here as you probably don't want to stay on the 'new' page
            return result
        })
        .then(result => {
            // push to api in here
            if (update) {
                const postToApi = usePostToApi
                console.log('addToIDB-LOGS - UPDATE - Push to API')
                return postToApi(idbKey, update, true)
            }
            return result
        })
            .catch(reject => reject)
        }

const getIDB = (keyValue: string) => get(keyValue).then(data => data)
