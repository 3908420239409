import React, { FunctionComponent, useState, Component } from 'react'

import Modal from '../components/Modal'
import { Link } from 'react-router-dom'

import warningIcon from '../assets/icons/warning.png'
import CrewList from './CrewList'
import RigSelect from './rigs/RigsSelect'
import Button from '../components/form/Button'

import { useGetLoggedInUser } from '../helpers/getLoggedInUser'

import { setIDB } from "../helpers/setIDBKey";
import {get,set} from "idb-keyval";

type Props = {
	onClose: any,
	// onComplete: any,
	defaultValues?: any,
	projectId?: string,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => {},
	defaultValues: {
		guid: '',
	},
}


const CurrentInformation: FunctionComponent<Props> = ({ onClose, defaultValues, ...rest }) => {

    
    const site_description = defaultValues.site_description
    const key_site_features = defaultValues.key_site_features
    const site_history = defaultValues.site_history
    const key_hands = defaultValues.key_hands
    const expected_strata = defaultValues.expected_strata
    const notes = defaultValues.notes
    const equipment_required = defaultValues.equipment_required
    const client_name = defaultValues.client_name
    const client_contact = defaultValues.client_contact
    const client_phone = defaultValues.client_phone
    const client_email = defaultValues.client_email
    const engineer_name = defaultValues.engineer_name
    const engineer_phone = defaultValues.engineer_phone

    const [showSiteInformation, setShowSiteInformation] = useState(true)
    const [showContactInformation, setShowContactInformation] = useState(false)

	const [siteInformationButtonColor, setSiteInformationButtonColor] = useState('orange')
	const [contactInformationButtonColor, setContactInformationButtonColor] = useState('primary')
    

	return (

        <>

            <Modal title="Information" subtitle="" toggleModal={onClose} confirm={false} >
				
               

                <div className="flex mb-4 w-full justify-center">

                    <Button onClick={() => {setShowSiteInformation(true); setShowContactInformation(false);
                        setSiteInformationButtonColor('orange'); setContactInformationButtonColor('primary')}
                }  
                        theme={siteInformationButtonColor} size="lg" className="btn-lg px-2 mx-1" >
                            Site Info
                    </Button>

                    <Button onClick={() => {setShowSiteInformation(false); setShowContactInformation(true);
                        setSiteInformationButtonColor('primary'); setContactInformationButtonColor('orange')}
                } 
                        theme={contactInformationButtonColor} size="lg" className="btn-lg px-2 mx-1" >
                            Contact Info
                    </Button>

                </div>

                {showSiteInformation &&

                    <table className="table-auto w-full md:w-5/6 text-sm text-center bg-white border border-grey-mid mx-auto text-base md:text-xl">
                        <tr>
                            <td className={`w-4/12 md:w-4/12 lg:w-1/2 px-2 py-2 border-r border-b border-grey-mid text-right align-top`}>Site Description:</td>
                            <td className={`w-8/12 md:w-8/12 lg:w-1/2 px-2 py-2 border-r border-b border-grey-mid text-left align-top`}>{defaultValues.site_description}</td>
                        </tr>
                        <tr>
                            <td className={`pr-2 py-2 border-r border-b border-grey-mid text-right align-top`}>Key Site Features:</td>
                            <td className={`pl-2 py-2 border-r border-b border-grey-mid text-left align-top`}>{defaultValues.key_site_features}</td>
                        </tr>
                        <tr>
                            <td className={`pr-2 py-2 border-r border-b border-grey-mid text-right align-top`}>Site History:</td>
                            <td className={`pl-2 py-2 border-r border-b border-grey-mid text-left align-top`}>{defaultValues.site_history}</td>
                        </tr>

                        <tr>
                            <td className={`pr-2 py-2 border-r border-b border-grey-mid text-right align-top`}>Key H&S:</td>
                            <td className={`pl-2 py-2 border-r border-b border-grey-mid text-left align-top`}>{defaultValues.key_hands}</td>
                        </tr>

                        <tr>
                            <td className={`pr-2 py-2 border-r border-b border-grey-mid text-right align-top`}>Expected Strata:</td>
                            <td className={`pl-2 py-2 border-r border-b border-grey-mid text-left align-top`}>{defaultValues.expected_strata}</td>
                        </tr>

                        <tr>
                            <td className={`pr-2 py-2 border-r border-b border-grey-mid text-right align-top`}>Notes:</td>
                            <td className={`pl-2 py-2 border-r border-b border-grey-mid text-left align-top`}>{defaultValues.notes}</td>
                        </tr>

                        <tr>
                            <td className={`pr-2 py-2 border-r border-b border-grey-mid text-right align-top`}>Equipment Required:</td>
                            <td className={`pl-2 py-2 border-r border-b border-grey-mid text-left align-top`}>{defaultValues.equipment_required}</td>
                        </tr>

                    </table>

                }  {/* END OF SITE INFORMATION */}

                {showContactInformation &&
                    <>
                        <table className="table-auto w-full md:w-5/6 text-sm text-center bg-white border border-grey-mid mx-auto text-base md:text-xl mb-6">
                            <tr>
                                <td className={`pr-2 py-2 border-r border-b border-grey-mid text-right align-top w-1/2`}>Site Engineer:</td>
                                <td className={`pl-2 py-2 border-r border-b border-grey-mid text-left align-top w-1/2`}>{defaultValues.engineer_name}</td>
                            </tr>

                            <tr>
                                <td className={`pr-2 py-2 border-r border-b border-grey-mid text-right align-top w-1/2`}>Engineer Phone:</td>
                                <td className={`pl-2 py-2 border-r border-b border-grey-mid text-left align-top w-1/2`}>{defaultValues.engineer_phone}</td>
                            </tr>
                            <tr>
                                <td colSpan={2} className={`pr-2 py-2 border-r border-b border-grey-mid text-center align-top`}>Engineer Email:<br/>
                                {defaultValues.engineer_email}
                                </td>
                            </tr>
                        </table>
                            
                        <table className="table-auto w-full md:w-5/6 text-sm text-center bg-white border border-grey-mid mx-auto text-base md:text-xl">
                            <tr>
                                <td className={`w-4/12 md:w-4/12 lg:w-1/2 px-2 py-2 border-r border-b border-grey-mid text-right align-top w-1/2`}>Client Contact:</td>
                                <td className={`w-8/12 md:w-8/12 lg:w-1/2 px-2 py-2 border-r border-b border-grey-mid text-left align-top w-1/2`}>{defaultValues.client_contact}</td>
                            </tr>
                            <tr>
                                <td className={`pr-2 py-2 border-r border-b border-grey-mid text-right align-top w-1/2`}>Client Phone:</td>
                                <td className={`pl-2 py-2 border-r border-b border-grey-mid text-left align-top w-1/2`}>{defaultValues.client_phone}</td>
                            </tr>
                            <tr>
                                <td colSpan={2} className={`pr-2 py-2 border-r border-b border-grey-mid text-center align-top`}>Client Email:<br/>
                                {defaultValues.client_email}
                                </td>
                            </tr>
                        </table>
                    </>
                }  {/* END OF CONTACT INFORMATION */}

            </Modal>

        </> 

	)
}

CurrentInformation.defaultProps = defaultProps

export default CurrentInformation