import React from 'react'
import {
    Link, Redirect
} from "react-router-dom"

import { get, set } from 'idb-keyval'

interface Props {
    router: any,
}

interface State {

}

class Logout extends React.Component<Props, State> {

    handleLogout = () => {
        localStorage.removeItem('drillit-token')
    }

    componentDidMount() {
        this.handleLogout()
        this.props.router.history.push('/login')
    }

    render() {
        return null
    }
}

export default Logout
