export const useGetFromAPI: any = async (endpoint: string) => {
    const endpointPath = process.env.REACT_APP_API_ADDR + endpoint
    const token = localStorage.getItem('drillit-token')

    return fetch(endpointPath, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then((response:Response|any) => {
            // console.log(await response)
            if (!response.ok) {
                throw new Error('getFromAPI - Network response was not ok')
            }
            console.log('getFromAPI - response from GET', response)
            const status:any = response.status
            return ({status, response})
        })
        .then(res => {
            const blah = {
                actualData: res.response.json(),
                actualStatus: res.status
            }
            console.log('getFromAPI - blah:', blah)
            return blah
        })
        .catch((error) => {
            console.log('getFromAPI - error', error)
            return new Response('Failed to fetch', {status: 418})
        })
}
