// export const deleteActions = (action_guid: string) => {
//     return new Promise((resolve, reject) => {
//         const endpoint = `${process.env.REACT_APP_API_ADDR}action/${action_guid}`
//         makeRequest(endpoint, 'DELETE')
//             .then(resolve => resolve.status)
//             .then(resolve)
//             .catch(reject => reject)
//     })
// }

export const deleteActions = (action_guid: string): Promise<Response> => {
    return new Promise((resolve, reject) => {
      const endpoint = `${process.env.REACT_APP_API_ADDR}action/${action_guid}`;
      makeRequest(endpoint, 'DELETE')
        .then(response => {
          resolve(response); // Resolve with the full response object
        })
        .catch(error => {
          reject(error); // Propagate fetch errors
        });
    });
  };

const makeRequest = (endpoint: string, postOrPut: string) => {
    const token = localStorage.getItem('drillit-token')

    return fetch(endpoint, {
        method: postOrPut,
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        //return the status code here
        .then(response => response)
        .catch(err => err)
}
