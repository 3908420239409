import { del, set } from 'idb-keyval';

export const updatePlantCheckRotary = (
    generalConditionOfBodyCracksDamage: number,
    chainTensionAndLubrication: number,
    chainPulleysCleanAndLubricate: number,
    lubricateHeadSlides: number,
    lubricateMastSlides: number,
    securityOfBolts: number,
    pinsGrease: number,
    conditionOfHydraulicHammer: number,
    engineOilLevel: number,
    headOilLevel: number,
    hydraulicOilLevel: number,
    fuelLevel: number,
    coolantLevel: number,
    checkWarningLights: number,
    conditionOfControlPanel: number,
    remoteControlWorking: number,
    greaseHeadShaft: number,
    securityOfFlushSwivelAndGrease: number,
    hydraulicCircuitLeaksDamage: number,
    emergencyStopTest: number,
    fixedGuardTest: number,
    rigAndHeatExchangerClean: number,
    checkBatterySecurityAndLevel: number,
    conditionOfWirelineWinch: number,
    conditionOfServiceWinch: number,
    compressorEngineOil: number,
    compressorHydraulicOil: number,
    compressorCoolantLevel: number,
    compressorAirLines: number,
    airlineSafetyConnections: number,
    conditionOfShackles: number,
    conditionOfSwivels: number,
    conditionOfCasingDriveHead: number,
    conditionOfHoistingPlugs: number,
    conditionOfSPTLiftingPoint: number,
    conditionOfFlushPump: number,
    conditionOfMistPump: number,
    fireExtinguisherGoodCondition: number,

    engineHours: number,
    compressorRunHours: number,
    issuesMaintenance: string,

    company_guid: string,
    company_id: number,
    driller_name: string,
    driller_cscs: string,
    guid: string,
    plant_guid: string,
    plant_id: string,
    plant_name: string,
    spt_rod_type: string,
    spt_id_ref: string,
    spt_energy_ratio: string,
    spt_calibration_date: string,
    plant_test_date_expiry: string,
    plant_check_date: string,
    plantcheck_url: string,
    projectGUID: string,
    mergedRigPlantChecksUpdated: any

    ) => {
    return new Promise(async (resolve, reject) => {
        const endpoint = `${process.env.REACT_APP_API_ADDR}plant-check-rotary/${guid}`
        // console.log('endpoint:', endpoint)
        // make a date string
        const updateData = {
            'generalConditionOfBodyCracksDamage': generalConditionOfBodyCracksDamage ? 1 : 0,
            'chainTensionAndLubrication': chainTensionAndLubrication ? 1 : 0,
            'chainPulleysCleanAndLubricate': chainPulleysCleanAndLubricate ? 1 : 0,
            'lubricateHeadSlides': lubricateHeadSlides ? 1 : 0,
            'lubricateMastSlides': lubricateMastSlides ? 1 : 0,
            'securityOfBolts': securityOfBolts ? 1 : 0,
            'pinsGrease': pinsGrease ? 1 : 0,
            'conditionOfHydraulicHammer': conditionOfHydraulicHammer ? 1 : 0,
            'engineOilLevel': engineOilLevel ? 1 : 0,
            'headOilLevel': headOilLevel ? 1 : 0,
            'hydraulicOilLevel': hydraulicOilLevel ? 1 : 0,
            'fuelLevel': fuelLevel ? 1 : 0,
            'coolantLevel': coolantLevel ? 1 : 0,
            'checkWarningLights': checkWarningLights ? 1 : 0,
            'conditionOfControlPanel': conditionOfControlPanel ? 1 : 0,
            'remoteControlWorking': remoteControlWorking ? 1 : 0,
            'greaseHeadShaft': greaseHeadShaft ? 1 : 0,
            'securityOfFlushSwivelAndGrease': securityOfFlushSwivelAndGrease ? 1 : 0,
            'hydraulicCircuitLeaksDamage': hydraulicCircuitLeaksDamage ? 1 : 0,
            'emergencyStopTest': emergencyStopTest ? 1 : 0,
            'fixedGuardTest': fixedGuardTest ? 1 : 0,
            'rigAndHeatExchangerClean': rigAndHeatExchangerClean ? 1 : 0,
            'checkBatterySecurityAndLevel': checkBatterySecurityAndLevel ? 1 : 0,
            'conditionOfWirelineWinch': conditionOfWirelineWinch ? 1 : 0,
            'conditionOfServiceWinch': conditionOfServiceWinch ? 1 : 0,
            'compressorEngineOil': compressorEngineOil ? 1 : 0,
            'compressorHydraulicOil': compressorHydraulicOil ? 1 : 0,
            'compressorCoolantLevel': compressorCoolantLevel ? 1 : 0,
            'compressorAirLines': compressorAirLines ? 1 : 0,
            'airlineSafetyConnections': airlineSafetyConnections ? 1 : 0,
            'conditionOfShackles': conditionOfShackles ? 1 : 0,
            'conditionOfSwivels': conditionOfSwivels ? 1 : 0,
            'conditionOfCasingDriveHead': conditionOfCasingDriveHead ? 1 : 0,
            'conditionOfHoistingPlugs': conditionOfHoistingPlugs ? 1 : 0,
            'conditionOfSPTLiftingPoint': conditionOfSPTLiftingPoint ? 1 : 0,
            'conditionOfFlushPump': conditionOfFlushPump ? 1 : 0,
            'conditionOfMistPump': conditionOfMistPump ? 1 : 0,
            'fireExtinguisherGoodCondition': fireExtinguisherGoodCondition ? 1 : 0,
            'engineHours': engineHours,
            'compressorRunHours': compressorRunHours,
            'issuesMaintenance': issuesMaintenance,
            'company_guid': company_guid,
            'company_id': company_id,
            'driller_name': driller_name,
            'driller_cscs': driller_cscs,
            'guid': guid,
            'plant_guid': plant_guid,
            'plant_id': plant_id,
            'plant_name': plant_name,
            'spt_rod_type': spt_rod_type,
            'spt_id_ref': spt_rod_type,
            'spt_energy_ratio': spt_energy_ratio,
            'spt_calibration_date': spt_calibration_date,
            'plant_test_date_expiry': plant_test_date_expiry,
            'plant_check_date': plant_check_date,
            'plantcheck_url': plantcheck_url,
            'project_guid': projectGUID,
        }

        // Delete any existing records
        const offlineKey = `drillit-plant-check-rotary/${plant_guid}-update`;
        try {
            await del(offlineKey);
            console.log(`Deleted existing record: ${offlineKey}`);
        } catch (error) {
            console.error('Error deleting existing record:', error);
        }

        // Make the request and handle response
        makeRequest(endpoint, JSON.stringify(updateData), 'PUT')
            .then(response => {
                if (response.status !== 200) {
                    saveToIndexedDB(offlineKey, updateData);
                }
                resolve(true);
            })
            .catch(error => {
                console.error('Error making request:', error);
                saveToIndexedDB(offlineKey, updateData);
                resolve(false);
            });

        // Update the currentRigPlantChecks state in IndexedDB
        try {
            set(`drillit-plant-check-rotary/${plant_guid}`, mergedRigPlantChecksUpdated);
            console.log('Updating drillit-plant-check-rotary/');
        } catch (error) {
            console.error('Error updating current rig plant checks:', error);
        }
    });
};

const makeRequest = (endpoint: string, data: string, postOrPut: string) => {
    const token = localStorage.getItem('drillit-token');

    console.log('MAKING REQUEST:', data);

    return fetch(endpoint, {
        method: postOrPut,
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: data,
    })
        .then(response => response)
        .catch(err => err);
};

const saveToIndexedDB = async (key:any, data:any) => {
    try {
        await set(key, data);
        console.log(`Data saved to IndexedDB with key: ${key}`);
    } catch (error) {
        console.error('Error saving data to IndexedDB:', error);
    }
};