import React, { FunctionComponent, useState, useEffect } from 'react'

import WorkflowSteps from '../../../WorkflowSteps'
import Modal from '../../../Modal'
import Button from '../../../form/Button'
import ButtonOptions from '../../../form/ButtonOptions'
import NumberInput from '../../../NumberInput'
import MultiNumberInput from '../../../MultiNumberInput'
import { v4 as uuidv4 } from 'uuid';

import stepStatus from '../../../../helpers/stepStatus'

import PrintLabel from '../../../../components/PrintLabel'
import SamplesCreate from '../SamplesCreate'
import { updateSelectedProject } from '../../../../actions/SelectedProject'
import { useSelector, useDispatch } from 'react-redux'
import { useGetIDBValues } from "../../../../helpers/getIDBKey"
import { useGetLoggedInUser } from '../../../../helpers/getLoggedInUser'
import warningIcon from '../../../../assets/icons/warning.png'
import Checkbox from "../../../form/Checkbox";

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: string,
	defaultValues?: any,
	selectedDate?: string,
	update?: boolean,
	projectId?: string,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => { },
	onComplete: () => { },
	borehole: '',
	projectId: '',
	defaultValues: {
		guid: '',
		type: '',
		depth_to_top: '',
		depth_to_base: '',
		casing_casing: '',
		depth_of_water: '',
		ublows: '',
		recovery: '',
		utype: '',
	},
	selectedDate: '',
}

const U100: FunctionComponent<Props> = ({ onClose, update, onComplete, borehole, defaultValues, selectedDate, sampled_by, 
	casing_preset, setShowUEdit, new_type_number, boreholeDepth,
	...rest }) => {
	const [sampler, setSampler] = useState(sampled_by)
	const [currentStep, setCurrentStep] = useState(1)
	const date = useSelector((state: any) => state.date)
	var labelDate = date.toLocaleDateString("en-UK")

	const [guid, setGuid] = useState(defaultValues.guid)
	const [depth_to_top, setDepthFrom] = useState(defaultValues.depth_to_top)
	const [depth_to_base, setDepthTo] = useState(defaultValues.depth_to_base)
	// console.log('depth_to_base: ', depth_to_base)
	const [casing_depth, setCasing] = useState(defaultValues.casing_depth)
	const [utype, setUType] = useState(defaultValues.utype)
	const [water_depth, setWater] = useState(defaultValues.depth_of_water)
	const [ublows, setUblows] = useState(defaultValues.ublows)
	const [recovery, setRecovery] = useState(defaultValues.recovery)
	const [sample_preparation, setSample_preparation] = useState(defaultValues.sample_preparation)
	
	useEffect(() => {
		if(!update) {setSample_preparation(true)}
		if (defaultValues.sample_preparation === undefined) {setSample_preparation(true)}
		if (defaultValues.sample_preparation === '1') {setSample_preparation(true)}
		if (defaultValues.sample_preparation === '') {setSample_preparation(false)}
	}, [defaultValues.sample_preparation, update])

	console.log('sample_preparation:', sample_preparation)
	console.log('update:', update)

	const [saveButtonText, setButtonText] = useState('Continue')
	const [saveButtonColor, setButtonColor] = useState('primary')

	const loggedInUser = useGetLoggedInUser()

	const selectedProject = useSelector((state: any) => state.selectedProject)
	// const dispatch = useDispatch()
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	const DeleteButton = () => {
		if (!update) return null;

		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	const deleteRecordConfirm = () => {
		setDeleteConfirmOpen(false)
		// onComplete()
	}

	const [time_of_action, setTimeOfAction] = useState(defaultValues.time_of_action)

	const isoDate = selectedDate ? `${new Date(selectedDate).toISOString().split('T')[0]} 09:00:00` : new Date().toISOString().split('.')[0]

	const previous = () => {
		if (currentStep > 1)
			setCurrentStep(currentStep - 1)
		setButtonText('Continue')
		setButtonColor('primary')
	}

	const next = () => {
		if (currentStep < 7) {
			setCurrentStep(currentStep + 1)
			if (currentStep === 6) {
				setButtonText('Save')
				setButtonColor('success')
			} else {
				setButtonText('Continue')
				setButtonColor('primary')
			}
			return
		}

		if (depth_to_top === '' || utype === '' || recovery === '') {
			//do nothing
		} else {

			onComplete({
				guid: update ? guid : uuidv4(),
				depth_to_top: depth_to_top2DP,
				casing_depth: casing_depth2DP !== 'NaN' ? casing_depth2DP : '',
				depth_of_water: water_depth2DP,
				depth_to_base: depth_to_base_calc2DP,
				seating_blows_1st_inc: 0,
				seating_blows_2nd_inc: 0,
				mm_over: 0,
				mm_overSB: 0,
				test_blows_1st_inc: 0,
				test_blows_2nd_inc: 0,
				test_blows_3rd_inc: 0,
				test_blows_4th_inc: 0,
				self_weight_penetration: '', 
				spt_in_soft_rock: '', 
				general_remarks: '',
				pen_1: 0,
				pen_2: 0,
				pen_3: 0,
				pen_4: 0,
				pen_5: 0,
				pen_6: 0,
				seating_blows: 0,
				total_blows: 0,
				reported_result: '',
				type: 'U',
				type_number: type_number_output,
				utype: utype,
				ublows: parseInt(ublows, 10),
				sample_preparation: sample_preparation ? 1 : 0,
				recovery: ''+ recovery,
				size: sample_diameter,
				test_type: '',
				sv_result1: '',
				sv_result2: '',
				sv_result3: '',
				sv_result4: '',
				average: '',
				suitableForTesting: '',
				time_of_action: time_of_action ? time_of_action : isoDate,
				update: update ? true : false,
				create: update ? false : true,
			})
		}
	}

	console.log('sample_preparation:', sample_preparation)
	console.log('recovery:', recovery)

	let requiredTypeText = ''
	let requiredDepthText = ''
	let requiredRecoveryText = ''

	if (utype === '') {
		requiredTypeText = "'U Type' - "
	}

	if (depth_to_top === '') {
		requiredDepthText = "'Depth From'"
	}

	if (recovery === '') {
		requiredRecoveryText = "'Recovery'"
	}

	const MissingRequiredField = () => {
		if (currentStep === 7 && (depth_to_top === '' || recovery === '' || utype === '')) return (
			<div className="text-center bg-sptSB border border-red font-bold rounded mb-2 py-1 text-xs md:text-sm lg:text-base">
				<img src={warningIcon} alt="info" width="20" className="inline-block mr-1 pb-1" />
				Required field: {requiredTypeText} {requiredDepthText} {requiredRecoveryText}
			</div>
		)
	
		return null
	}

	// let casing_depth2DP = ''
	// if (casing_depth === 'no casing' || casing_depth === '') {
	// 	casing_depth2DP = 'no casing'
	// // } else if (casing_depth) {
	// // 	casing_depth2DP = parseFloat(casing_depth).toFixed(2)
	// } else {
	// 	casing_depth2DP = parseFloat(casing_depth).toFixed(2)
	// }

	// if (!casing_preset) { var casing_preset_value = 'no casing' }
	// else { casing_preset_value = casing_depth2DP}

	let casing_depth2DP = ''
	if (casing_depth === '') { casing_depth2DP = '' }
	else if (casing_depth === 'no casing') { casing_depth2DP = 'no casing' }
	else { casing_depth2DP = parseFloat(casing_depth).toFixed(2) }

	let casing_preset_value = ''
	if (!casing_preset) { casing_preset_value = 'no casing' }
	else { casing_preset_value = parseFloat(casing_preset).toFixed(2)}

	// console.log('casing_preset: ', casing_preset)
	// console.log('casing_preset_value: ', casing_preset_value)
	// console.log('casing_depth2DP: ', casing_depth2DP, parseFloat(casing_depth).toFixed(2))

	var water_depth2DP = ''
	if (water_depth === '') { water_depth2DP = '' }
	else if (water_depth === 'dry') { water_depth2DP = 'dry' }
	else if (water_depth === 'damp') { water_depth2DP = 'damp' }
	else { water_depth2DP = parseFloat(water_depth).toFixed(2) }

	let depth_to_top2DP = parseFloat(depth_to_top).toFixed(2)
	let depth_to_base_calc = 0
	let depth_to_base_calc2DP = ''
	let depth_to_preset = '0.45'
	let hole_depth_preset = '0.45'
	let label_depth_from = ''
	if (!depth_to_top) {
		// do nothing
	} else {
		if (depth_to_top === 'GL' || depth_to_top === 'G' || depth_to_top === 0 || 
		depth_to_top === 0.00 || depth_to_top === '0.00' || depth_to_top === '0') {
			depth_to_top2DP = '0'
			depth_to_preset = '0.45'
			hole_depth_preset = '0.45'
			label_depth_from = 'GL'
		} else {
			depth_to_top2DP = parseFloat(depth_to_top).toFixed(2)
			depth_to_base_calc = +depth_to_top + 0.45
			depth_to_preset = (Math.round(depth_to_base_calc * 100) / 100).toFixed(2)
			hole_depth_preset = depth_to_preset
			label_depth_from = depth_to_top2DP
		}
	}
	// console.log('label_depth_from:', label_depth_from)

	depth_to_base_calc2DP = (Math.round(depth_to_base * 100) / 100).toFixed(2)

	var depth_to_shoe_base = +depth_to_base + 0.05
	var depth_to_shoe_base2DP = (Math.round(depth_to_shoe_base * 100) / 100).toFixed(2)

	var printDepth = label_depth_from + ' - ' + depth_to_base_calc2DP
	
	var sampleID = ''
	var type_number_output = 0
	if (update) {
		// console.log('update?:', update, defaultValues.type_number)
		sampleID = "U" + utype + "-" + defaultValues.type_number
		type_number_output = defaultValues.type_number
	} else {
		sampleID = "U" + utype + "-" + new_type_number
		type_number_output = new_type_number
	}

	// var shoePrintDepth = depth_to_base_calc2DP + '-' + depth_to_shoe_base2DP
	// var shoeSampleID = "D-" + "#"

	var modalTitle = "Undisturbed Sample (U" + utype + ")"

	let title_to_send_for_Usample = 'Print Label for U' + utype + '-#?'
	let title_to_send_for_Dsample = 'Print Label for D Sample?'

	let remarks_to_send_for_Dsample = 'Create D Sample for U' + utype + '-# shoe'

	let sample_diameter = ''
	if (utype === '100') {
		sample_diameter = '100'
	} else if (utype === 'T100') {
		sample_diameter = '100'
	} else if (utype === '84') {
		sample_diameter = '84'
	} else if (utype === '38') {
		sample_diameter = '38'
	}

	return (
		<Modal title={modalTitle} subtitle={borehole} toggleModal={onClose}>
			<div className="flex flex-col flex-grow px-1 sm:px-2 md:px-10">
				<WorkflowSteps steps={[
					{
						label: 'Type',
						status: stepStatus(1, currentStep),
						onClick: () => (setCurrentStep(1), setButtonText('Continue'), setButtonColor('primary'))
					}, {
						label: 'Depth From',
						status: stepStatus(2, currentStep),
						onClick: () => (setCurrentStep(2), setButtonText('Continue'), setButtonColor('primary'))
					}, {
						label: 'Depth To',
						status: stepStatus(3, currentStep),
						onClick: () => (setCurrentStep(3), setButtonText('Continue'), setButtonColor('primary'))
					}, {
						label: 'Casing',
						status: stepStatus(4, currentStep),
						onClick: () => (setCurrentStep(4), setButtonText('Continue'), setButtonColor('primary'))
					}, {
						label: 'Water',
						status: stepStatus(5, currentStep),
						onClick: () => (setCurrentStep(5), setButtonText('Continue'), setButtonColor('primary'))
					}, {
						label: 'Results',
						status: stepStatus(6, currentStep),
						onClick: () => (setCurrentStep(6), setButtonText('Continue'), setButtonColor('primary'))
					}, {
						label: 'Label',
						status: stepStatus(7, currentStep),
						onClick: () => (setCurrentStep(7), setButtonText('Save'), setButtonColor('success'))
					}, 
					// {
					// 	label: 'Shoe Sample',
					// 	status: stepStatus(8, currentStep),
					// 	onClick: () => (setCurrentStep(8), setButtonText('Save'), setButtonColor('success'))
					// },
				]} className="mb-2" />

				<div className="flex-1 flex text-center">
					{currentStep === 1 &&

						<div className="w-full flex flex-col flex-grow bg-sptTB px-10 mb-2 border border-grey-mid rounded">

							<ButtonOptions options={[

								{
									value: '100', label: <><div className="text-center">U100</div>
										<div className="text-sm text-center">100mm</div></>
								},
								{
									value: 'T100', label: <><div className="text-center">UT100</div>
										<div className="text-sm text-center">100mm (thin-wall system)</div></>
								},
								{
									value: '38', label: <><div className="text-center">U38</div>
										<div className="text-sm text-center">38mm</div></>
								},
								{
									value: '84', label: <><div className="text-center">U84</div>
										<div className="text-sm text-center">84mm</div></>
								},

							]} onUpdate={
								(value: any) => setUType(value)
							}
								title="Select Type"
								defaultValue={utype}
								columns={2}
								columnsMobile={1}
								buttonHeight={20}
								buttonHeightMobile={16}
								next={next}
								className="mb-10 text-xl sm:text-xl md:text-lg md:text-2xl" />
						</div>
					}

					{currentStep === 2 &&

						<NumberInput onUpdate={(value: any) => setDepthFrom(value)}
							title="Depth From (m)"
							defaultValue={depth_to_top}
							className="mb-10 text-xl md:text-2xl leading-5 md:leading-8"
							presets={[
								{ value: boreholeDepth, label: boreholeDepth },
							]}
							next={next}
						/>

						// <MultiNumberInput values={
						// 	[
						// 		{defaultValue: depth_to_top , onUpdate: setDepthFrom, label: 'Depth From (m)'},
						// 		{defaultValue: depth_to_base_calc , onUpdate: setDepthTo, label: 'Depth To (m)'},
						// 	]
						// } 
						// className="mb-2 text-base sm:text-xl md:text-xl" 
						// inputCSS="text-4xl h-16 sm:h-16" 
						// />
					}

					{currentStep === 3 &&
						<NumberInput onUpdate={(value: any) => setDepthTo(value)}
							title="Depth To (m)"
							defaultValue={depth_to_base}
							className="mb-10 text-2xl"
							presets={[
								{ value: depth_to_preset, label: depth_to_preset },
							]}
							next={next}
						/>
					}

					{currentStep === 4 &&
						<NumberInput onUpdate={(value: any) => setCasing(value)}
							title="Enter Casing Depth (m)"
							defaultValue={casing_depth}
							className="mb-10 text-xl md:text-2xl leading-5 md:leading-8"
							// Load in latest casing depth
							presets={[
								{ value: casing_preset_value, label: casing_preset_value },
							]}
							next={next}
						/>
					}

					{currentStep === 5 &&
						<NumberInput onUpdate={(value: any) => setWater(value)}
							title="Enter Water Depth (m)"
							defaultValue={water_depth}
							className="mb-10 text-xl"
							presets={[
								{ value: 'dry', label: 'dry' },
								{ value: 'damp', label: 'damp' },
							]}
							next={next}
						/>
					}

					{currentStep === 6 &&
						// <NumberInput onUpdate={(value: any) => setBlows(value)} 
						// title="Number of Blows" 
						// defaultValue={blows} 
						// className="mb-10" />

						<MultiNumberInput 
						title="Results"
						values={
							[
								{ defaultValue: ublows, onUpdate: setUblows, label: 'Blows' },
								{ defaultValue: recovery, onUpdate: setRecovery, label: 'Recovery (mm)' },
							]
						}
							className="mb-2 text-base sm:text-xl md:text-xl"
							inputCSS="text-4xl h-16 sm:h-16 mb-5"

							aboveInput={
								<div className="flex justify-center mx-auto w-full mt-0 mb-4">
									<div className="w-56 bg-grey-mid">
										<Checkbox label="Preservative added"
											checked={sample_preparation}
											onChange={(value: any) => setSample_preparation(value)}
											className="scale-150 transform-gpu pl-12 py-2 text-xs" />
									</div>
								</div>
							}
						/>

					}

					{currentStep === 7 &&

						<>
							{/* <div className="text-xl text-center w-full">Print Label for U{utype}-##?</div> */}

							<PrintLabel
								location={selectedProject.location}
								project={selectedProject.number}
								borehole={borehole}
								sampledate={labelDate}
								// user={loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name)}
								sampled_by={sampler}
								sample={sampleID}
								depth={printDepth}
								title={title_to_send_for_Usample}
							/>
						</>
					}

					{currentStep === 8 &&

						<div className="w-full flex flex-col flex-grow">

							{/* <div className="border border-grey-mid rounded px-2 bg-sptTB mb-4 w-full pb-2">
						
								<div className="text-xl text-center w-full">Add D Sample for U{utype} shoe?</div>
								<div className="text-xs text-center">
									Sample: D-## | Depth From: {depth_to_base_calc2DP} | Depth To: {depth_to_shoe_base2DP} | Casing: {casing_depth2DP}  | 
									Water: {water_depth2DP}  | Notes: Sample from U{utype}-## shoe
								</div>

								<Button theme="primary" size="lg" className="px-4 sm:px-10 mx-auto">Create Sample</Button>
							</div> */}

							{/* <div className="">

							<PrintLabel 
							location={selectedProject.location}
							project={selectedProject.number}
							borehole={borehole} 
							sampledate={labelDate}  
							user={loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name) }
							sample={shoeSampleID}
							depth={shoePrintDepth}
							size='printLabel'
							title={title_to_send_for_Dsample}
							/>
							</div> */}

							{/* <SamplesCreate
								// onComplete={(value: any) => this.saveAction(value, 'D', this.setState({ showD: false }))}
								borehole={borehole}
								selectedDate={selectedDate}
								title="D"
								casing_preset={casing_depth}
								defaultValues={{
									type: 'D',
									remarks: `Sample from U${utype}# shoe`,
									depth_to_top: depth_to_base_calc2DP,
									depth_to_base: depth_to_shoe_base2DP,
									casing_depth: casing_depth,
									water_depth: water_depth,
									importSampleCreateText: remarks_to_send_for_Dsample,
								}}
							/> */}

						</div>
					}

				</div>

				<MissingRequiredField />


				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">


							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?

								<div className="flex mt-4">
									<Button theme="secondary" 
										className="mr-5 text-sm sm:text-base px-3.5 md:px-7" 
										onClick={() => setDeleteConfirmOpen(false)}>
											Cancel
									</Button>

									<Button onClick={() => {
												onClose()
												onComplete({
													guid: guid,
													update: false,
													delete: true,
												})
												setShowUEdit(false)
											}}
										className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">
										Delete
									</Button>
								</div>
							</div>


						</div>
					</div>
				}

				<div className="flex">
					{/* <Button theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button> */}
					<DeleteButton />

					<div className="flex ml-auto">
						<Button theme="primary" size="lg" className="px-4 sm:px-10" onClick={() => previous()}>Back</Button>
						<Button theme={saveButtonColor}
							size="lg"
							className="ml-4 px-8 sm:px-10 w-32 sm:w-60"
							onClick={() => next()}>{saveButtonText}
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

U100.defaultProps = defaultProps

export default U100
