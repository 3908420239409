import React from 'react'

import Header from '../../layout/Header'

import Modal from '../../components/Modal'
import Button from '../../components/form/Button'
import InputGroup from '../../components/form/InputGroup'
import { useGetIDBValues } from "../../helpers/getIDBKey";
import { useSetIndexedDBValues } from "../../helpers/addToIDB";
import { Redirect } from "react-router-dom";
import { get, set } from "idb-keyval";

interface Props {
	toggleSidebar: () => void,
	router: any,
}

interface State {
	id: string,
	id_ref: string,
	rod_type: string,
	calibration_date: string,
	energy_ratio: string,
	guid: string,
	company_guid: string,
	redirect: boolean,
	deleteConfirmOpen: boolean,
	deleteRecordConfirm: boolean,
}

class RigsSptUpdate extends React.Component<Props, State> {
	constructor(props: any) {
		super(props)

		this.state = {
			id: '',
			id_ref: '',
			rod_type: '',
			calibration_date: '',
			energy_ratio: '',
			guid: this.props.router.match.params.id,
			company_guid: this.props.router.match.params.company,
			redirect: false,
			deleteConfirmOpen: false,
			deleteRecordConfirm: false,
		}
	}

	save = (event: any) => {
		event.preventDefault()
		const saveToIDB = useSetIndexedDBValues
		saveToIDB(this.state, 'spt-hammers', false, 'PUT')
			.then(() => {
				// update the spt-hammers-for-project key for this value only
				get(`drillit-spt-hammers/company/${this.props.router.match.params.company}`).then(result => {
					if (result) {
						result.map((spt: any) => {
							if (spt.guid === this.state.guid) {
								spt.id = this.state.id
								spt.id_ref = this.state.id_ref
								spt.rod_type = this.state.rod_type
								spt.calibration_date = this.state.calibration_date
								spt.energy_ratio = this.state.energy_ratio
								spt.guid = this.state.guid
							}
						})
					}
				}).then(result => {
					set(`drillit-spt-hammers/company/${this.props.router.match.params.company}`, this.state)
				})
			})
			.then(() => setTimeout(() => this.setState({ redirect: true }), 1000))
	}

	componentDidMount() {
		this.getCacheValues()
	}

	getCacheValues = () => {
		let cachedValues = useGetIDBValues
		cachedValues('spt-hammers', (data: any) => {
			for (const hammers of data) {
				if (hammers.guid === this.state.guid) {
					this.setState({
						id: hammers.id,
						id_ref: hammers.id_ref,
						rod_type: hammers.rod_type,
						calibration_date: hammers.calibration_date,
						energy_ratio: hammers.energy_ratio,
						guid: hammers.guid,
					})
				}
			}
		}).then()
	}

	setDeleteConfirmOpen() {
		this.setState({ deleteConfirmOpen: !this.state.deleteConfirmOpen });
	}

	setDeleteRecordConfirm() {
		this.setState({ deleteRecordConfirm: !this.state.deleteRecordConfirm });
	}



	handleCancelClick = () => {
		window.history.back();
	};

	render() {
		const { redirect } = this.state

		if (redirect) {
			return <Redirect to={`/rigs/${this.state.company_guid}/${this.props.router.match.params.project}`} />
		}

		return (
			<>

				{this.state.deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">


							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this SPT?

								<div className="flex mt-4">
									<Button theme="secondary" className="mr-5 text-sm sm:text-base px-3.5 md:px-7" onClick={() => this.setState({ deleteConfirmOpen: false })}>Cancel</Button>

									<Button onClick={() => this.setState({ deleteRecordConfirm: false })} className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">Delete</Button>
								</div>
							</div>


						</div>
					</div>
				}
				<Header toggleSidebar={this.props.toggleSidebar} />

				<Modal title="Edit SPT" toggleModal={() => this.props.router.history.goBack()}>
					<form onSubmit={this.save}>
						<div className="w-full lg:w-3/5 pl-1 pr-1 mx-auto">
							<div className="border border-grey-mid rounded px-2 pt-2 pb-1 lg:pb-8 bg-sptTB mb-4">
								<InputGroup label="SPT ID"
									value={this.state.id_ref}
									onChange={(value) => this.setState({ id_ref: value })}
									className="mt-8 md:text-lg pt-2"
								/>

								<InputGroup label="Rod Type"
									value={this.state.rod_type}
									onChange={(value) => this.setState({ rod_type: value })}
									className="md:text-lg pt-2"
								/>

								<InputGroup type="date"
									label="Calibration Date"
									value={this.state.calibration_date}
									onChange={(value) => this.setState({ calibration_date: value })}
									className="md:text-lg pt-2"
								/>

								<InputGroup type="number" label="Energy Ratio"
									value={this.state.energy_ratio}
									onChange={(value) => this.setState({ energy_ratio: value })}
									className="md:text-lg pt-2 mb-10"
								/>
							</div>

							<div className="flex justify-between">
								<Button onClick={() => this.setState({ deleteConfirmOpen: true })} className="ml-1 text-sm w-16 md:w-32" theme="secondary">Delete</Button>
								<div className="flex">
									<Button onClick={this.handleCancelClick} theme="secondary" className="mr-2 md:mr-4 h-12 w-20 md:w-32 text-sm md:text-base">Cancel</Button>
									<Button type="submit" theme="primary" className="mr-1 h-12 w-32 md:w-40 text-base">Save</Button>
								</div>
							</div>

						</div>
					</form>
				</Modal >
			</>
		)
	}
}

export default RigsSptUpdate
