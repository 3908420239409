import React, { FunctionComponent } from 'react'

type Props = {
	label: string,
	values: {value: string, label: string}[],
	selected?: string,
	required?: boolean,
	className?: string,
	onChange: (value: any) => void,
}

const defaultProps: Props = {
	label: '',
	values: [],
	selected: '',
	className: '',
	onChange: (value) => {},
}

const SelectGroup: FunctionComponent<Props> = ({ label, values, required, selected, className, onChange }) => {
	const handleChange = (event: any) => {
		onChange({value: event.target.value, label: event.target.options[event.target.selectedIndex].textContent})
	}

	return (
		<div className={`flex flex-col mb-4 ${className}`}>
			{label && <label className="w-full text-charcoal font-bold mb-2">{label}</label>}

			<select onChange={handleChange} className="form-control" value={selected} required={required}>
				<option value="" disabled>--select--</option>
				{values.map((value, index) => {
					return <option value={value.value} key={index}>{value.label}</option>
				})}
			</select>
		</div>
	)
}

SelectGroup.defaultProps = defaultProps

export default SelectGroup
