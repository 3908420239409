import React, { FunctionComponent } from 'react';

type Props = {
	header: string[],
	items: any[][],
	textCSS: string[],
}

const defaultProps: Props = {
	header: [],
	items: [[]],
	textCSS: [],
}

const CompactTable: FunctionComponent<Props> = ({ header, items, textCSS, }) => {
	return (
		<table className="table-auto w-full text-xs md:text-base text-center border-l border-grey-mid rounded border-separate">
			<thead className="font-bold text-xs md:text-base">
				<tr>
					{header.map((title, index) => {
						return <td className={`px-1 py-1 bg-sptTB border-b border-grey-mid ${index === 0 ? 'rounded-tl' : null} ${index === header.length - 1 ? 'rounded-tr' : null} ${index !== header.length ? 'border-r border-grey-light' : null}`} key={index}>{title}</td>
					})}
				</tr>
			</thead>

			<tbody>
			{items.map((values, index) => {
				return (
					<tr className={`border-b border-grey-mid bg-white mb-1 ${textCSS}`} key={index}>
						{values.map((value, index) => {
							return <td className={`px-1 py-0.5 ${index !== values.length ? 'border-r border-b border-grey-mid' : null}`} key={index}>{value}</td>
						})}
					</tr>
				)
			})}
			</tbody>
		</table>
	)
}

CompactTable.defaultProps = defaultProps

export default CompactTable
