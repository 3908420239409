import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

import Button from '../../../form/Button'

import plus from '../../../assets/icons/plus.svg'

type Props = {
	colorType: string[],
	setColorType: any;
	[x: string]: any;
}

const defaultProps: Props = {
	colorType: [],
	setColorType: () => {},
}

const colorTypes = [
	[
		{'label': 'Light', 'style': 'bg-white'},
		{'label': 'Dark', 'style': 'bg-charcoal text-white'},
		{'label': 'Mottled', 'style': 'bg-mottled text-white'},
		{'label': 'Multi-coloured', 'style': 'bg-multicoloured text-white'},
	],
	[
		{'label': 'Reddish', 'style': 'bg-soil-reddish text-white'},
		{'label': 'Pinkish', 'style': 'bg-soil-pinkish text-white'},
		{'label': 'Orangish', 'style': 'bg-soil-orangish text-white'},
		{'label': 'Yellowish', 'style': 'bg-soil-yellowish text-charcoal'},
		{'label': 'Brownish', 'style': 'bg-soil-brownish text-white'},
		{'label': 'Greenish', 'style': 'bg-soil-greenish text-white'},
		{'label': 'Blueish', 'style': 'bg-soil-blueish text-white'},
		{'label': 'Greyish', 'style': 'bg-soil-greyish text-white'},
	],
	[
		{'label': 'Red', 'style': 'bg-soil-red text-white'},
		{'label': 'Pink', 'style': 'bg-soil-pink text-white'},
		{'label': 'Orange', 'style': 'bg-soil-orange text-white'},
		{'label': 'Yellow', 'style': 'bg-soil-yellow text-charcoal'},
		{'label': 'Cream', 'style': 'bg-soil-cream text-charcoal'},
		{'label': 'Brown', 'style': 'bg-soil-brown text-white'},
		{'label': 'Green', 'style': 'bg-soil-green text-white'},
		{'label': 'Blue', 'style': 'bg-soil-blue text-white'},
		{'label': 'White', 'style': 'bg-soil-white text-charcoal'},
		{'label': 'Grey', 'style': 'bg-soil-grey text-white'},
		{'label': 'Black', 'style': 'bg-soil-black text-white'},
	],
]

const ColorType: FunctionComponent<Props> = ({ colorType, setColorType, ...rest }) => {
	const toggleColorType = (color: string) => {
		if(color === 'Dark') {
			colorType = colorType.filter(item => item !== 'Light')
		} else if(color === 'Light') {
			colorType = colorType.filter(item => item !== 'Dark')
		}

		if(colorType.includes(color)) {
			colorType = colorType.filter(item => item !== color)
		} else {
			colorType = [...colorType, color]
		}

		setColorType(colorType)
	}

	return (
		<div className="grid md:grid-cols-3 md:gap-2 mt-4 mb-2 w-full sm:w-5/6 mx-auto">

			{/* <div className="md:col-span-1 md:border-r md:border-grey-mid md:pr-2"> */}
					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2 pt-2 pb-2 sm:pb-4">
				<div className="flex justify-center invisible sm:visible h-0 sm:h-11">
					<div className="bg-grey-selected rounded-l-lg rounded-r-sm pl-4 pr-3 py-1 mb-3 relative">
						1
						<div className="step-arrow"></div>
					</div>
				</div>

				<div className="grid grid-cols-4 sm:grid-cols-2 gap-2">
					{colorTypes[0].map((colorTypeLabel: any, index: number) =>
						<div className={`h-10 flex flex-col text-center text-sm sm:text-base justify-center items-center ${colorType.includes(colorTypeLabel.label) ? 'border-4' : 'border-none'} border-orange rounded ${colorTypeLabel.style}`} onClick={() => toggleColorType(colorTypeLabel.label)} key={index}>
							{colorTypeLabel.label}
						</div>
					)}
				</div>
			</div>

			{/* <div className="md:col-span-1 md:border-r md:border-grey-mid md:pr-2"> */}
					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2 pt-2 pb-2 sm:pb-4">
				<div className="flex justify-center invisible sm:visible h-0 sm:h-11">
					<div className="bg-grey-selected rounded-l-lg rounded-r-sm pl-4 pr-3 py-1 mb-3 relative">
						2
						<div className="step-arrow"></div>
					</div>
				</div>

				<div className="grid grid-cols-4 sm:grid-cols-2 gap-2">
					{colorTypes[1].map((colorTypeLabel: any, index: number) =>
						<div className={`h-10 flex flex-col text-center justify-center items-center ${colorType.includes(colorTypeLabel.label) ? 'border-4' : 'border-none'} border-orange rounded ${colorTypeLabel.style}`} onClick={() => toggleColorType(colorTypeLabel.label)} key={index}>
							{colorTypeLabel.label}
						</div>
					)}
				</div>
			</div>

			{/* <div className="md:col-span-1 md:border-r md:border-grey-mid md:pr-2"> */}
					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2 pt-2 pb-2 sm:pb-4">
				<div className="flex justify-center invisible sm:visible h-0 sm:h-11">
					<div className="bg-grey-selected rounded-l-lg rounded-r-sm pl-4 pr-3 py-1 mb-3 relative">
						3
						<div className="step-arrow"></div>
					</div>
				</div>

				<div className="grid grid-cols-4 sm:grid-cols-2 gap-2">
					{colorTypes[2].map((colorTypeLabel: any, index: number) =>
						<div className={`h-10 flex flex-col text-center justify-center items-center ${colorType.includes(colorTypeLabel.label) ? 'border-4' : 'border-none'} border-orange rounded ${colorTypeLabel.style}`} onClick={() => toggleColorType(colorTypeLabel.label)} key={index}>
							{colorTypeLabel.label}
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

ColorType.defaultProps = defaultProps

export default ColorType