import { get, set } from 'idb-keyval'
import { useGetFromAPI } from "./getFromAPI";

export const useGetIDBValues = async (keyValue: string, updateState: any) => {

    const fullKeyValue = `drillit-${keyValue}`
    console.log('getIDBKey - fullKeyValue:', fullKeyValue)
    const idbValues = getIDB(fullKeyValue).then(result => {
        updateState(result)
        return result
    })
    // console.log('idbValues:', idbValues);

    const apiValues = useGetFromAPI
    // if ( idbValues ) {
        try {
            const response = await apiValues(keyValue)
            const {data} = await response.actualData

            if (response.status !== 418) {
                // console.log('NOT 418 error in getIDBKey *************************')
                set(fullKeyValue, data).then(x => {
                    updateState(data)
                })
            }

        } catch (e) {

        }
    // }

    return idbValues
}

// const getIDB = (keyValue: string) => get(keyValue).then(data => data)

const getIDB = (keyValue: string) => {
    return get(keyValue).then(data => {
        // console.log('Data from IndexedDB:', data);
        return data;
    });
};
