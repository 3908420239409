import { EDIT_WEATHER } from '../action-types'

const defaultState: string = 'Mostly Sunny & Dry'

const WeatherReducer = (state = defaultState, action: any) => {
	switch (action.type) {
		case EDIT_WEATHER: {
			const weather = action.payload.weather

			return weather
		}
		default: {
			return state
		}
	}
}

export default WeatherReducer