import React, { FunctionComponent, useState, useEffect } from 'react'

import WorkflowSteps from '../../../WorkflowSteps'
import Modal from '../../../Modal'
import Button from '../../../form/Button'
import NumberInput from '../../../NumberInput'
import ButtonOptions from '../../../form/ButtonOptions'
import { v4 as uuidv4 } from 'uuid';

import stepStatus from '../../../../helpers/stepStatus'
// import SamplesCreate from '../SamplesCreate'
// import PrintLabel from '../../../../components/PrintLabel'
// import { updateSelectedProject } from '../../../../actions/SelectedProject'
import { useSelector, useDispatch } from 'react-redux'
// import { useGetIDBValues } from "../../../../helpers/getIDBKey"
import { useGetLoggedInUser } from '../../../../helpers/getLoggedInUser'
import warningIcon from '../../../../assets/icons/warning.png'
import infoIcon from '../../../../assets/icons/info.png'
import closeIcon from '../../../../assets/icons/close.svg'

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: string,
	defaultValues?: any,
	selectedDate?: string,
	update?: boolean,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => { },
	onComplete: () => { },
	borehole: '',
	defaultValues: {
		guid: '',
		projectId: '',
		depth_to_top: '',
		depth_to_base: '',
		depthTo: '',
		time: '00:00:00',
		casing_depth: '',
		depth_of_water: '',
		seating_blows_1st_inc: '',
		seating_blows_2nd_inc: '',
		mm_overSB: '',
		mm_over: '',
		test_blows_1st_inc: '',
		test_blows_2nd_inc: '',
		test_blows_3rd_inc: '',
		test_blows_4th_inc: '',

	},
	selectedDate: '',
}

const keys = [
	'1', '2', '3',
	'4', '5', '6',
	'7', '8', '9',
	'0',
]

const SPT: FunctionComponent<Props> = ({ onClose, update, onComplete, borehole, defaultValues, selectedDate, sampled_by,
	casing_preset, setShowSPTEdit, new_type_number, new_type_number_sequencial, numbering_type, onCompleteNumbering, boreholeDepth,
	...rest }) => {

	// console.log('update', update)
	const [sampler, setSampler] = useState(sampled_by)

	const [currentStep, setCurrentStep] = useState(1)
	// console.log('boreholeDepth:', boreholeDepth)
	const [guid, setGuid] = useState(defaultValues.guid)
	const [depth_to_top, setDepthFrom] = useState(defaultValues.depth_to_top)
	const [casing_depth, setCasing] = useState(defaultValues.casing_depth)
	const [water_depth, setWater] = useState(defaultValues.depth_of_water)
	const [seating_blows_1st_inc, setSeatingBlow1] = useState(defaultValues.seating_blows_1st_inc)
	const [seating_blows_2nd_inc, setSeatingBlow2] = useState(defaultValues.seating_blows_2nd_inc)

	useEffect(() => {
		if (defaultValues.casing_depth === null) { setCasing('') }
		if (defaultValues.depth_of_water === null) { setWater('') }
	}, [defaultValues.casing_depth, defaultValues.depth_of_water])

	const [mm_overSB, setMmOverSB] = useState(defaultValues.mm_overSB)
	const [mm_over, setMmOver] = useState(defaultValues.mm_over)

	const [test_blows_1st_inc, setTestDrive1] = useState(defaultValues.test_blows_1st_inc)
	const [test_blows_2nd_inc, setTestDrive2] = useState(defaultValues.test_blows_2nd_inc)
	const [test_blows_3rd_inc, setTestDrive3] = useState(defaultValues.test_blows_3rd_inc)
	const [test_blows_4th_inc, setTestDrive4] = useState(defaultValues.test_blows_4th_inc)

	const [saveButtonText, setButtonText] = useState('Continue')
	const [saveButtonColor, setButtonColor] = useState('primary')

	const [selectedValue, setSelectedValue] = useState(1)

	console.log('selectedValue:', selectedValue)




	const [showSeatingBlowsInfo, setShowSeatingBlowsInfo] = useState(false)
	const [showTestBlowsInfo, setShowTestBlowsInfo] = useState(false)

	const [seatingBlowsInfoText, setSeatingBlowsInfoText] = useState('')
	const [testBlowsInfoText, setTestBlowsInfoText] = useState('')

	const [seatingBlowsInfoValue, setSeatingBlowsInfoValue] = useState(0)
	const [testBlowsInfoValue, setTestBlowsInfoValue] = useState(0)

	const [numberingType, setNumberingType] = useState(numbering_type);
	const [showNumberingInfo, setShowNumberingInfo] = useState(false);
	const [showNumberingSelect, setShowNumberingSelect] = useState(false);
	const [showIndividualNumbering, setShowIndividualNumbering] = useState(false);
	const [showOverallNumbering, setShowOverallNumbering] = useState(false);

	useEffect(() => {

		// Set the appropriate state based on numbering_type
		if (numberingType === null || numberingType === '') {
			setShowNumberingSelect(true);
			setShowIndividualNumbering(false);
			setShowOverallNumbering(false);
		} else if (numberingType === 'default') {
			setShowNumberingSelect(false);
			setShowIndividualNumbering(true);
			setShowOverallNumbering(false);
		} else if (numberingType === 'overall') {
			setShowNumberingSelect(false);
			setShowIndividualNumbering(false);
			setShowOverallNumbering(true);
		}
	}, []);

	var sampleLabel = ''
	var type_number_output = 0
	if (update) {
		type_number_output = defaultValues.type_number
	} else {
		if (numberingType === 'overall') {
			type_number_output = new_type_number_sequencial
		} else {
			type_number_output = new_type_number
		}
	}

	const date = useSelector((state: any) => state.date)
	var labelDate = date.toLocaleDateString("en-UK")

	const loggedInUser = useGetLoggedInUser()

	const selectedProject = useSelector((state: any) => state.selectedProject)
	const dispatch = useDispatch()

	const [time_of_action, setTimeOfAction] = useState(defaultValues.time_of_action)

	const isoDate = selectedDate ? `${new Date(selectedDate).toISOString().split('T')[0]} 09:00:00` : new Date().toISOString().split('.')[0]

	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	const DeleteButton = () => {
		if (!update) return null;

		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	const deleteRecordConfirm = () => {
		setDeleteConfirmOpen(false)
		// onComplete()
	}

	const previous = () => {
		if (currentStep > 1)
			setCurrentStep(currentStep - 1)
		setButtonText('Continue')
		setButtonColor('primary')
	}

	const next = () => {
		if (currentStep < 4) {
			setCurrentStep(currentStep + 1)
			if (currentStep === 3) {
				setButtonText('Save')
				setButtonColor('success')
			} else {
				setButtonText('Continue')
				setButtonColor('primary')
			}
			return
		}

		if (depth_to_top === '') {
			//do nothing
		} else {

			let numberingTypeToSave = ''
			if (numberingType === '' || numberingType === null) {
				numberingTypeToSave = 'default'
			} else {
				numberingTypeToSave = numberingType
			}

			onCompleteNumbering(numberingTypeToSave)

			onComplete({
				guid: update ? guid : uuidv4(),
				depth_to_top: depthtotop2DP,
				casing_depth: casing_depth2DP,
				depth_of_water: water_depth2DP,
				depth_to_base: depth_to_base2DP,
				seating_blows_1st_inc: seating_blows_1st_inc ? parseInt(seating_blows_1st_inc, 10) : 0,
				seating_blows_2nd_inc: seating_blows_2nd_inc ? parseInt(seating_blows_2nd_inc, 10) : 0,
				mm_over: mm_over ? parseInt(mm_over, 10) : 0,
				mm_overSB: mm_overSB ? parseInt(mm_overSB, 10) : 0,
				test_blows_1st_inc: test_blows_1st_inc ? parseInt(test_blows_1st_inc, 10) : 0,
				test_blows_2nd_inc: test_blows_2nd_inc ? parseInt(test_blows_2nd_inc, 10) : 0,
				test_blows_3rd_inc: test_blows_3rd_inc ? parseInt(test_blows_3rd_inc, 10) : 0,
				test_blows_4th_inc: test_blows_4th_inc ? parseInt(test_blows_4th_inc, 10) : 0,
				self_weight_penetration: '',
				spt_in_soft_rock: '',
				general_remarks: '',
				pen_1: pen1,
				pen_2: pen2,
				pen_3: pen3,
				pen_4: pen4,
				pen_5: pen5,
				pen_6: pen6,
				seating_blows: totalSBlows,
				total_blows: totalBlows,
				reported_result: 'N=' + totalBlows + refusal_text,
				type: 'SPT',
				type_number: type_number_output,
				utype: '',
				ublows: 0,
				sample_preparation: 0,
				recovery: '',
				size: '',
				test_type: '',
				sv_result1: '',
				sv_result2: '',
				sv_result3: '',
				sv_result4: '',
				average: '',
				suitableForTesting: '',
				time_of_action: time_of_action ? time_of_action : isoDate,
				update: update ? true : false,
				create: update ? false : true,
			})
		}

	}

	const MissingRequiredField = () => {
		if (currentStep === 4 && !depth_to_top) return (
			<div className="text-center bg-sptSB border border-red font-bold rounded mb-2 py-1 text-xs md:text-sm lg:text-base">
				<img src={warningIcon} alt="info" width="20" className="inline-block mr-1 pb-1" />
				Required field: 'Start Depth'
			</div>
		)

		return null
	}


	const ownWeight = (character: string) => {
		switch (selectedValue) {
			case 0:
				// setMmOverSB('' + character)
				break
			case -1:
				// setMmOver('' + character)
				break
			case 1:
				setSeatingBlow1('' + character)
				break
			case 2:
				setSeatingBlow2('' + character)
				break
			case 3:
				setTestDrive1('' + character)
				break
			case 4:
				setTestDrive2('' + character)
				break
			case 5:
				setTestDrive3('' + character)
				break
			case 6:
				setTestDrive4('' + character)
				break
		}
	}

	// console.log('depth_to_top:', parseFloat(depth_to_top).toFixed(2))

	const addCharacter = (character: string) => {
		switch (selectedValue) {
			case 0:
				setMmOverSB((mm_overSB ? mm_overSB : '') + character)
				break
			case -1:
				setMmOver((mm_over ? mm_over : '') + character)
				break
			case 1:
				setSeatingBlow1((seating_blows_1st_inc ? seating_blows_1st_inc : '') + character)
				break
			case 2:
				setSeatingBlow2((seating_blows_2nd_inc ? seating_blows_2nd_inc : '') + character)
				break
			case 3:
				setTestDrive1((test_blows_1st_inc ? test_blows_1st_inc : '') + character)
				break
			case 4:
				setTestDrive2((test_blows_2nd_inc ? test_blows_2nd_inc : '') + character)
				break
			case 5:
				setTestDrive3((test_blows_3rd_inc ? test_blows_3rd_inc : '') + character)
				break
			case 6:
				setTestDrive4((test_blows_4th_inc ? test_blows_4th_inc : '') + character)
				break
		}
	}



	const removeCharacter = () => {
		console.log('selectedValue:', selectedValue)
		switch (selectedValue) {
			case 0:
				setMmOverSB((mm_overSB ? mm_overSB : '').slice(0, -1))
				break
			case -1:
				setMmOver((mm_over ? mm_over : '').slice(0, -1))
				break
			case 1:
				setSeatingBlow1((seating_blows_1st_inc ? seating_blows_1st_inc : '').slice(0, -1))
				reset_mmOver_SB()
				break
			case 2:
				setSeatingBlow2((seating_blows_2nd_inc ? seating_blows_2nd_inc : '').slice(0, -1))
				reset_mmOver_SB()
				break
			case 3:
				setTestDrive1((test_blows_1st_inc ? test_blows_1st_inc : '').slice(0, -1))
				reset_mmOver_TB()
				break
			case 4:
				setTestDrive2((test_blows_2nd_inc ? test_blows_2nd_inc : '').slice(0, -1))
				reset_mmOver_TB()
				break
			case 5:
				setTestDrive3((test_blows_3rd_inc ? test_blows_3rd_inc : '').slice(0, -1))
				reset_mmOver_TB()
				break
			case 6:
				setTestDrive4((test_blows_4th_inc ? test_blows_4th_inc : '').slice(0, -1))
				reset_mmOver_TB()
				break
		}
	}

	var sblows1 = 0
	var sblows2 = 0
	var blows1 = 0
	var blows2 = 0
	var blows3 = 0
	var blows4 = 0
	var mmSB = 0
	var mmTB = 0

	// seating blows 1
	if (seating_blows_1st_inc === '') { sblows1 = 0 } else { sblows1 = parseInt(seating_blows_1st_inc) }

	// seating blows 2
	if (seating_blows_2nd_inc === '') { sblows2 = 0 } else { sblows2 = (seating_blows_2nd_inc) }

	// test blows 1
	if (test_blows_1st_inc === '') { blows1 = 0 } else { blows1 = parseInt(test_blows_1st_inc) }

	// test blows 2
	if (test_blows_2nd_inc === '') { blows2 = 0 } else { blows2 = parseInt(test_blows_2nd_inc) }

	// test blows 3
	if (test_blows_3rd_inc === '') { blows3 = 0 } else { blows3 = parseInt(test_blows_3rd_inc) }

	// test blows 4
	if (test_blows_4th_inc === '') { blows4 = 0 } else { blows4 = parseInt(test_blows_4th_inc) }

	// mm over seating blows
	if (mm_overSB === '') { mmSB = 0 } else { mmSB = parseInt(mm_overSB) }

	// mm over test blows
	if (mm_over === '') { mmTB = 0 } else { mmTB = parseInt(mm_over) }

	let totalSBlows = (+sblows1 + +sblows2)
	let totalBlows = (+blows1 + +blows2 + +blows3 + +blows4)

	const reset_mmOver_SB = () => {
		setMmOverSB('')
	}

	const reset_mmOver_TB = () => {
		setMmOver('')
	}

	var pen1 = 0; var pen2 = 0; var pen3 = 0; var pen4 = 0; var pen5 = 0; var pen6 = 0

	// seating blows 1
	if (seating_blows_1st_inc !== '' && seating_blows_2nd_inc === '' && mmSB === 0) { //
		pen1 = 75
	} else if (seating_blows_1st_inc !== '' && seating_blows_2nd_inc === '' && mmSB === 0) {
		pen1 = 75
	} else if (seating_blows_1st_inc !== '' && seating_blows_2nd_inc !== '' && mmSB === 0) {
		pen1 = 75
	} else if (seating_blows_1st_inc !== '' && seating_blows_2nd_inc !== '' && mmSB !== 0) {
		pen1 = 75
	} else if (seating_blows_1st_inc === '0') {
		pen1 = 75
	} else if (seating_blows_1st_inc !== '' && seating_blows_2nd_inc === '' && mmSB !== 0) {
		pen1 = mmSB
	} else {
		pen1 = 0
	}

	// seating blows 2
	if (seating_blows_2nd_inc === '' && mmSB === 0) { //
		pen2 = 75
	} else if (seating_blows_2nd_inc !== '' && mmSB === 0) {
		pen2 = 75
	} else if (seating_blows_2nd_inc === '0') {
		pen2 = 75
	} else if (seating_blows_2nd_inc !== '' && mmSB !== 0) {
		pen2 = mmSB
	} else {
		pen2 = 0
	}

	var penSB = +pen1 + pen2


	// test blows 1
	if (test_blows_1st_inc !== '' && test_blows_2nd_inc === '' && mmTB === 0) { //
		pen3 = 75
	} else if (test_blows_1st_inc !== '' && test_blows_2nd_inc === '' && mmTB === 0) {
		pen3 = 75
	} else if (test_blows_1st_inc !== '' && test_blows_2nd_inc !== '' && mmTB === 0) {
		pen3 = 75
	} else if (test_blows_1st_inc !== '' && test_blows_2nd_inc !== '' && mmTB !== 0) {
		pen3 = 75
	} else if (test_blows_1st_inc === '0') {
		pen3 = 75
	} else if (test_blows_1st_inc !== '' && test_blows_2nd_inc === '' && mmTB !== 0) {
		pen3 = mmTB
	} else {
		pen3 = 0
	}

	// test blows 2
	if (test_blows_2nd_inc !== '' && test_blows_3rd_inc === '' && mmTB === 0) { //
		pen4 = 75
	} else if (test_blows_2nd_inc !== '' && test_blows_3rd_inc === '' && mmTB === 0) {
		pen4 = 75
	} else if (test_blows_2nd_inc !== '' && test_blows_3rd_inc !== '' && mmTB === 0) {
		pen4 = 75
	} else if (test_blows_2nd_inc !== '' && test_blows_3rd_inc !== '' && mmTB !== 0) {
		pen4 = 75
	} else if (test_blows_2nd_inc === '0') {
		pen4 = 75
	} else if (test_blows_2nd_inc !== '' && test_blows_3rd_inc === '' && mmTB !== 0) {
		pen4 = mmTB
	} else {
		pen4 = 0
	}

	// test blows 3
	if (test_blows_3rd_inc !== '' && test_blows_4th_inc === '' && mmTB === 0) { //
		pen5 = 75
	} else if (test_blows_3rd_inc !== '' && test_blows_4th_inc === '' && mmTB === 0) {
		pen5 = 75
	} else if (test_blows_3rd_inc !== '' && test_blows_4th_inc !== '' && mmTB === 0) {
		pen5 = 75
	} else if (test_blows_3rd_inc !== '' && test_blows_4th_inc !== '' && mmTB !== 0) {
		pen5 = 75
	} else if (test_blows_3rd_inc === '0') {
		pen5 = 75
	} else if (test_blows_3rd_inc !== '' && test_blows_4th_inc === '' && mmTB !== 0) {
		pen5 = mmTB
	} else {
		pen5 = 0
	}

	// test blows 4
	if (test_blows_4th_inc === '0') {
		pen6 = 75
	} else {
		if (+blows4 > 0 && mmTB === 0) {
			pen6 = 75
		} else {
			if (pen5 === 75) { pen6 = mmTB }
			else { pen6 = 0 }
		}
	}

	var penTB = +pen3 + +pen4 + +pen5 + +pen6
	var depthtotop = parseFloat(depth_to_top)
	var depthtotop2DP = parseFloat(depth_to_top).toFixed(2)
	// console.log('depthtotop2DP:', depthtotop2DP, depthtotop)

	const depth_to_base2DP = (+depthtotop + (+pen1 + +pen2 + +pen3 + +pen4 + +pen5 + +pen6) / 1000).toFixed(2)

	// console.log(depth_to_top, mmSB, penSB,  mmTB, penTB, pen1, pen2, pen3, pen4, pen5, pen6, depth_to_base)

	let refusal_text = ''

	let total_penetration = 0
	total_penetration = +pen1 + +pen2 + +pen3 + +pen4 + +pen5 + +pen6

	refusal_text = ' (Penetration: ' + total_penetration + 'mm)'

	let casing_depth2DP = ''
	// console.log('casing_depth & water_depth:', casing_depth, water_depth )
	if (casing_depth === '' || casing_depth === '0' || casing_depth === 0) { casing_depth2DP = '' }
	else if (casing_depth === 'no casing') { casing_depth2DP = 'no casing' }
	else { casing_depth2DP = parseFloat(casing_depth).toFixed(2) }
	// console.log('casing_depth2DP:', casing_depth2DP, casing_depth)
	let casing_preset_value = ''
	if (!casing_preset) { casing_preset_value = 'no casing' }
	else { casing_preset_value = parseFloat(casing_preset).toFixed(2) }

	let water_depth2DP = ''
	if (water_depth === '' || water_depth === '0' || water_depth === 0) { water_depth2DP = '' }
	else if (water_depth === 'dry') { water_depth2DP = 'dry' }
	else if (water_depth === 'damp') { water_depth2DP = 'damp' }
	else { water_depth2DP = parseFloat(water_depth).toFixed(2) }

	// let printDepth = depthtotop2DP + '-' + depth_to_base2DP

	// let sampleID = "D" + "#"

	// let title_to_send_for_Dsample = 'Print Label for D Sample?'

	// let remarks_to_send_for_Dsample = 'Create Recovery D Sample'


	return (
		<Modal title="SPT" subtitle={borehole} toggleModal={onClose}>



			{showSeatingBlowsInfo &&
				<div className="infoContainer">
					<div className="m-auto h-80 w-60 rounded-lg border border-orange bg-white modalshadow p-2">
						<div className="flex">
							<div className="info-icon-container">
								<img className="info-icon" src={infoIcon} alt="Info" />
							</div>
							<span className="font-bold text-lg">Information</span>
							<span className="ml-auto">
								<button
									className="flex items-center justify-center w-8 h-8 text-4xl bold text-soil-grey bg-white border border-orange rounded"
									onClick={() => setShowSeatingBlowsInfo(false)}
								>
									<img src={closeIcon} width="20" alt="" />
								</button>
							</span>
						</div>

						<div className="mt-4">
							The Seating Blows have gone over 25 blows!
						</div>

						<div className="mt-5">
							{/* {seatingBlowsInfoText} */}
							The test has failed on the last increment with a value entered.
						</div>

						<div className="mt-5">
							Measure how many millimetres the test travelled in the last increment (max 75mm)
							{/* {seatingBlowsInfoValue}  */}

						</div>

						{/* {seating_blows_1st_inc > 25 &&
										<>
										<div className="mt-5">
											The first increment of 75mm was <span className="font-bold">{seating_blows_1st_inc}</span> blows
										</div>

										<div className="mt-5">Measure how many millimetres the test travelled over these XX blows (max 75mm)</div>
										</>
									}
									{(seating_blows_1st_inc + seating_blows_2nd_inc) > 25 &&
										<>
										<div className="mt-5">
											The second increment of 75mm was <span className="font-bold">{seating_blows_2nd_inc}</span> blows
										</div>

										<div className="mt-5">Measure how many millimetres the test travelled over these <span className="font-bold">{seating_blows_2nd_inc}</span> blows (max 75mm)</div>
										</>
									} */}

					</div>
				</div>

			}



			{showTestBlowsInfo &&
				<div className="infoContainer">
					<div className="m-auto h-80 w-60 rounded-lg border border-orange bg-white modalshadow p-2">
						<div className="flex">
							<div className="info-icon-container">
								<img className="info-icon" src={infoIcon} alt="Info" />
							</div>
							<span className="font-bold text-lg">Information</span>
							<span className="ml-auto">
								<button
									className="flex items-center justify-center w-8 h-8 text-4xl bold text-soil-grey bg-white border border-orange rounded"
									onClick={() => setShowTestBlowsInfo(false)}
								>
									<img src={closeIcon} width="20" alt="" />
								</button>
							</span>
						</div>

						<div className="mt-4">
							The Test Blows have gone over 50 blows!
						</div>

						<div className="mt-5">
							The test has failed on the last increment with a value entered.
							{/* {testBlowsInfoText} */}
						</div>

						<div className="mt-5">
							Measure how many millimetres the test travelled in the last increment (max 75mm)
							{/* Measure how many millimetres the test travelled over these {testBlowsInfoValue} blows (max 75mm) */}
						</div>

					</div>
				</div>

			}



			{showNumberingInfo && (
				<div className="z-50 fixed inset-x-0 bottom-20 flex justify-center w-80 md:w-96 mx-auto"> {/* infoContainer */}
					<div className="m-auto w-full rounded-lg border border-orange bg-white modalshadow p-2">
						<div className="flex">
							<div className="info-icon-container">
								<img className="info-icon" src={infoIcon} alt="Info" />
							</div>
							<span className="font-bold text-lg">Information</span>
							<span className="ml-auto">
								<button
									className="flex items-center justify-center w-8 h-8 text-4xl bold text-soil-grey bg-white border border-orange rounded"
									onClick={() => setShowNumberingInfo(false)}
								>
									<img src={closeIcon} width="20" alt="" />
								</button>
							</span>
						</div>

						<div className="bg-white rounded text-center border border-grey-mid mt-2 mb-2 py-1 px-2">
							<div className="text-base md:text-lg">Individual (default)</div>
							<div className="text-lg md:text-2xl">SPT-{new_type_number}, CPT-{new_type_number}</div>
							<div className="text-xs md:text-sm">Samples and Tests are individually sequentially numbered.</div>
							<div className="text-xs">ie
								<span className="text-orange"> D-1 </span>
								<span className="text-charcoal">B-1 </span>
								<span className="text-blue">SPT-1 </span>
								<span className="text-charcoal">CPT-1 </span>
								<span className="text-orange">D-2 </span>
								<span className="text-charcoal">B-2 </span>
								<span className="text-blue">SPT-2 </span>
								<span className="text-charcoal">CPT-2 </span>
							</div>
						</div>

						<div className="bg-white rounded text-center border border-grey-mid mb-2 py-1 px-2">
							<div className="text-base md:text-lg">Overall</div>
							<div className="text-lg md:text-2xl">SPT-{new_type_number_sequencial}, CPT-{new_type_number_sequencial + 1}</div>
							<div className="text-xs md:text-sm">Samples and Tests are sequentially numbered OVERALL.</div>
							<div className="text-xs">ie
								<span className="text-orange"> D-1 </span>
								<span className="text-charcoal">B-2 </span>
								<span className="text-blue">SPT-1 </span>
								<span className="text-charcoal">CPT-2 </span>
								<span className="text-orange">D-3 </span>
								<span className="text-charcoal">B-4 </span>
								<span className="text-blue">SPT-3 </span>
								<span className="text-charcoal">CPT-4 </span>
							</div>
						</div>

					</div>
				</div>

			)}


			<div className="flex flex-col flex-grow px-1 sm:px-2 md:px-10">
				<WorkflowSteps steps={[
					{
						label: 'Depth From',
						status: stepStatus(1, currentStep),
						onClick: () => (setCurrentStep(1), setButtonText('Continue'), setButtonColor('primary'))
					}, {
						label: 'Casing',
						status: stepStatus(2, currentStep),
						onClick: () => (setCurrentStep(2), setButtonText('Continue'), setButtonColor('primary'))
					}, {
						label: 'Water',
						status: stepStatus(3, currentStep),
						onClick: () => (setCurrentStep(3), setButtonText('Continue'), setButtonColor('primary'))
					}, {
						label: 'Drives',
						status: stepStatus(4, currentStep),
						onClick: () => (setCurrentStep(4), setButtonText('Save'), setButtonColor('success'))
					},
				]} className="mb-2" />

				<div className="flex flex-1">
					{currentStep === 1 &&
						<>
							<div className="w-full">
								<NumberInput onUpdate={(value: any) => setDepthFrom(value)} required
									defaultValue={depth_to_top}
									title="Enter Start Depth (m)"
									className="mb-4 text-xl md:text-2xl leading-5 md:leading-8"
									presets={[
										{ value: boreholeDepth, label: boreholeDepth },
									]}
									next={next}
								/>
								<div className="bg-sptSB border border-grey-mid rounded px-1 md:px-2 py-1 md:py-2">

									{showNumberingSelect && (
										<>

											<div className="flex items-center justify-center text-base md:text-lg mb-2">
												<span>Numbering Type</span>
												<img
													className="ml-2" // Add some margin to the left of the image for spacing
													src={infoIcon}
													width="16"
													alt="INFO"
													onClick={() => setShowNumberingInfo(true)}
												/>
											</div>
											<ButtonOptions onUpdate={(value: any) => setNumberingType(value)}
												defaultValue={numberingType}
												title=""
												columns={2}
												columnsMobile={2}
												buttonHeight={20}
												buttonHeightMobile={16}
												options={[
													{
														value: 'default', label: <><div className="text-base md:text-lg">Individual</div>
															<div className="text-lg md:text-2xl">SPT-{new_type_number}, CPT-{new_type_number}</div></>
													},
													{
														value: 'overall', label: <><div className="text-base md:text-lg">Overall</div>
															<div className="text-lg md:text-2xl">SPT-{new_type_number_sequencial}, CPT-{new_type_number_sequencial + 1}</div></>
													},
												]} className="mb-1" />

											<div className="text-center text-xs md:text-sm mt-2 mb-1">If not selected, 'Individual Numbering' will be used as default</div>

											{/* <div className="grid grid-cols-2 gap-1 md:gap-2">
										<div className="bg-white rounded text-center border border-grey-mid mb-2 py-1">
											<div className="text-base md:text-lg">Individual</div>
											<div className="text-lg md:text-2xl">SPT-{new_type_number}, CPT-{new_type_number}</div>
										</div>

										<div className="bg-white rounded text-center border border-grey-mid mb-2 py-1">
											<div className="text-base md:text-lg">Overall</div>
											<div className="text-lg md:text-2xl">SPT-{new_type_number_sequencial}, CPT-{new_type_number_sequencial + 1}</div>
										</div>
									</div> */}
										</>
									)}

									{showIndividualNumbering && (
										<>
											<div className="flex items-center justify-center text-base md:text-lg mb-1">
												<span>Numbering Type</span>
											</div>

											<div className="grid grid-cols-1 md:grid-cols-3 gap-1 md:gap-2">
												<div></div>
												<div className="bg-white rounded text-center border border-grey-mid mb-2 py-1">
													<div className="text-base md:text-lg">Individual</div>
													<div className="text-lg md:text-2xl">SPT-{update ? type_number_output : new_type_number}</div>
													{/* <div className="text-xs md:text-sm">Samples and Tests are individually sequentially numbered.</div> */}
												</div>
												<div></div>
											</div>
										</>
									)}

									{showOverallNumbering && (
										<>
											<div className="flex items-center justify-center text-base md:text-lg mb-1">
												<span>Numbering Type</span>
											</div>

											<div className="grid grid-cols-1 md:grid-cols-3 gap-1 md:gap-2">
												<div></div>
												<div className="bg-white rounded text-center border border-grey-mid mb-2 py-1">
													<div className="text-base md:text-lg">Overall</div>
													<div className="text-lg md:text-2xl">SPT-{update ? type_number_output : new_type_number_sequencial}</div>
													{/* <div className="text-xs md:text-sm">Samples and Tests are sequentially numbered OVERALL.</div> */}
												</div>
												<div></div>
											</div>
										</>
									)}
								</div>

							</div>
						</>
					}

					{currentStep === 2 &&
						<NumberInput onUpdate={(value: any) => setCasing(value)}
							defaultValue={casing_depth}
							title="Enter Casing Depth (m)"
							className="mb-10 text-xl md:text-2xl leading-5 md:leading-8"
							// Load in latest casing depth
							presets={[
								{ value: casing_preset_value, label: casing_preset_value },
							]}
							next={next}
						/>
					}

					{currentStep === 3 &&
						<NumberInput onUpdate={(value: any) => setWater(value)}
							defaultValue={water_depth}
							title="Enter Water Depth (m)"
							className="mb-10 text-xl"
							presets={[
								{ value: 'dry', label: 'dry' },
								{ value: 'damp', label: 'damp' },
							]}
							next={next}
						/>
					}

					{currentStep === 4 &&
						<div className="w-full">


							{/* SEATING BLOWS */}

							<div className="grid grid-cols-5 gap-1 md:gap-4 bg-grey-light px-2 lg:px-5 pb-0 rounded mt-0 mb-2">
								<div className="col-span-5 text-center mx-0 lg:mx-36">

									<div className="grid grid-cols-5 gap-1 md:gap-2 w-full pb-1.5">

										<div className="col-span-5 text-center text-sm sm:text-base">
											<p className="text-base sm:text-lg font-bold">Seating Blows - <span className="sptBlows">{totalSBlows} Blows</span></p></div>
										{/* <div className="col-span-2 md:col-span-2"></div> */}

										<div className="col-span-1 ">
											<div className={`form-control-xl sm:form-control-xxl text-3xl md:text-5xl lg:text-5xl text-center h-12 md:h-16 lg:h-16 py-0 md:py-2 lg:py-2 ${selectedValue === 1 ? "form-control-active" : ''}`} onClick={() => setSelectedValue(1)}>
												{seating_blows_1st_inc ? seating_blows_1st_inc : ''}
											</div>

											{/* <div className="text-xs">
												{pen1}mm
											</div> */}

											<div className="text-xs text-center h-4">
												{/* {pen2 > 0  || seating_blows_1st_inc !== '' && ( */}
												{seating_blows_1st_inc !== '' && (
													<>
														<div className="text-xs text-center bg-orange w-4/5 mx-auto text-grey-light rounded-b">
															{pen1}mm
														</div>
													</>
												)}
											</div>
										</div>

										<div className="col-span-1 ">
											<div className={`form-control-xl sm:form-control-xxl text-3xl md:text-5xl lg:text-5xl text-center h-12 md:h-16 lg:h-16 py-0 md:py-2 lg:py-2 ${selectedValue === 2 ? "form-control-active" : ''}`} onClick={() => setSelectedValue(2)}>
												{seating_blows_2nd_inc ? seating_blows_2nd_inc : ''}
											</div>

											{/* <div className="text-xs">
												{pen2}mm
											</div> */}

											<div className="text-xs text-center h-4">
												{/* {pen3 > 0 || seating_blows_2nd_inc !== '' && ( */}
												{seating_blows_2nd_inc !== '' && (
													<>
														<div className="text-xs text-center bg-orange w-4/5 mx-auto text-grey-light rounded-b">
															{pen2}mm
														</div>
													</>
												)}
											</div>
										</div>

										<div className="col-span-2"></div>

										<div className="col-span-1 ">

											{/* <div className="text-xs text-center text-grey-dark">
														{mmSB}
													</div> */}

											{totalSBlows > 24 && (
												<>
													<div className="pb-2 -mt-6">
														<img className="mx-auto" src={infoIcon} width="16" alt="INFO"
															onClick={() => setShowSeatingBlowsInfo(true)}
														>
														</img>
													</div>
													<div className={`form-control-xl-dark text-3xl md:text-5xl lg:text-5xl text-center h-12 md:h-16 lg:h-16 py-0 md:py-2 lg:py-2 ${selectedValue === 0 ? "form-control-active" : ''}`} onClick={() => setSelectedValue(0)}>
														{mm_overSB ? mm_overSB : ''}
													</div>
													<div className="text-xs text-center text-grey-dark">
														over XXmm
													</div>
												</>
											)}

										</div>


										{/* <div className="md:col-span-2">
											<p className="sptBlowCount">{parseInt(seating_blows_1st_inc ? seating_blows_1st_inc : 0) + parseInt(seating_blows_2nd_inc ? seating_blows_2nd_inc : 0)} Blows</p>
										</div> */}

									</div>



									{/* <p>(Refusal Rate is 25 blows)</p> */}
								</div>
							</div>


							{/* TEST BLOWS */}

							<div className="grid grid-cols-5 gap-1 md:gap-4 bg-grey-light px-2 lg:px-5 pb-0 rounded mt-0 mb-2">
								<div className="col-span-5 text-center mx-0 lg:mx-36">

									<div className="grid grid-cols-5 gap-1 md:gap-2 w-full pb-1.5">

										<div className="col-span-5 text-center text-sm sm:text-base">
											<p className="text-base sm:text-lg font-bold">Test Drives - <span className="sptBlows">{totalBlows} Blows</span></p></div>
										{/* <div className="col-span-2 md:col-span-2"></div> */}

										<div className="col-span-1 ">
											<div className={`form-control-xl sm:form-control-xxl text-3xl md:text-5xl lg:text-5xl text-center h-12 md:h-16 lg:h-16 py-0 md:py-2 lg:py-2 ${selectedValue === 3 ? "form-control-active" : ''}`} onClick={() => setSelectedValue(3)}>
												{test_blows_1st_inc ? test_blows_1st_inc : ''}
											</div>

											{/* <div className="text-xs">
												{pen3}mm
											</div> */}

											<div className="text-xs text-center h-4">
												{test_blows_1st_inc !== '' && (
													<>
														<div className="text-xs text-center bg-orange w-4/5 mx-auto text-grey-light rounded-b">
															{pen3}mm
														</div>
													</>
												)}
											</div>
										</div>

										<div className="col-span-1 ">
											<div className={`form-control-xl sm:form-control-xxl text-3xl md:text-5xl lg:text-5xl text-center h-12 md:h-16 lg:h-16 py-0 md:py-2 lg:py-2 ${selectedValue === 4 ? "form-control-active" : ''}`} onClick={() => setSelectedValue(4)}>
												{test_blows_2nd_inc ? test_blows_2nd_inc : ''}
											</div>

											{/* <div className="text-xs">
												{pen4}mm
											</div> */}

											<div className="text-xs text-center h-4">
												{test_blows_2nd_inc !== '' && (
													<>
														<div className="text-xs text-center bg-orange w-4/5 mx-auto text-grey-light rounded-b">
															{pen4}mm
														</div>
													</>
												)}
											</div>
										</div>

										<div className="col-span-1 ">
											<div className={`form-control-xl sm:form-control-xxl text-3xl md:text-5xl lg:text-5xl text-center h-12 md:h-16 lg:h-16 py-0 md:py-2 lg:py-2 ${selectedValue === 5 ? "form-control-active" : ''}`} onClick={() => setSelectedValue(5)}>
												{test_blows_3rd_inc ? test_blows_3rd_inc : ''}
											</div>

											{/* <div className="text-xs">
												{pen5}mm
											</div> */}

											<div className="text-xs text-center h-4">
												{test_blows_3rd_inc !== '' && (
													<>
														<div className="text-xs text-center bg-orange w-4/5 mx-auto text-grey-light rounded-b">
															{pen5}mm
														</div>
													</>
												)}
											</div>
										</div>

										<div className="col-span-1 ">
											<div className={`form-control-xl sm:form-control-xxl text-3xl md:text-5xl lg:text-5xl text-center h-12 md:h-16 lg:h-16 py-0 md:py-2 lg:py-2 ${selectedValue === 6 ? "form-control-active" : ''}`} onClick={() => setSelectedValue(6)}>
												{test_blows_4th_inc ? test_blows_4th_inc : ''}
											</div>

											{/* <div className="text-xs">
												{pen6}mm
											</div> */}

											<div className="text-xs text-center h-4">
												{test_blows_4th_inc !== '' && (
													<>
														<div className="text-xs text-center bg-orange w-4/5 mx-auto text-grey-light rounded-b">
															{pen6}mm
														</div>
													</>
												)}
											</div>
										</div>

										<div className="col-span-1 ">

											{totalBlows > 49 && (
												<>
													<div className="pb-2 -mt-6">
														<img className="mx-auto" src={infoIcon} width="16" alt="INFO"
															onClick={() => setShowTestBlowsInfo(true)}
														>
														</img>
													</div>
													<div className={`form-control-xl-dark text-3xl md:text-5xl lg:text-5xl text-center h-12 md:h-16 lg:h-16 py-0 md:py-2 lg:py-2 ${selectedValue === -1 ? "form-control-active" : ''}`} onClick={() => setSelectedValue(-1)}>
														{mm_over ? mm_over : ''}
													</div>
													<div className="text-xs text-center text-grey-dark">over XXmm</div>
												</>
											)}

											{/* <div className="text-xs text-center text-grey-dark">
															{mmTB}
														</div> */}
										</div>

										{/* <div className="md:col-span-4">
										<p className="sptBlowCount">{parseInt(test_blows_1st_inc ? test_blows_1st_inc : 0) + parseInt(test_blows_2nd_inc ? test_blows_2nd_inc : 0) + parseInt(test_blows_3rd_inc ? test_blows_3rd_inc : 0) + parseInt(test_blows_4th_inc ? test_blows_4th_inc : 0)} Blows</p>
										</div> */}

									</div>



									{/* <p>(Refusal Rate is 50 blows)</p> */}
								</div>
							</div>

							{/* <p className="text-center mb-2">The test fails at 50 blows (4 test drives). If the test fails, user enters 'mm over' from that 75mm run</p> */}

							<div className="mb-3 border border-grey-mid rounded bg-sptTB ">

								<div className="grid grid-cols-3 gap-2 px-2 py-2 mx-0 lg:mx-36">
									<div className="col-span-2 text-center">
										<div className="grid grid-cols-3 gap-2">
											{keys.map((key, index) => {
												return <div className="border border-orange rounded py-1 md:py-6 lg:py-2  text-3xl text-center bg-white" key={index} onClick={() => addCharacter(key)}>{key}</div>
											})}

											<div className="col-span-2 border border-orange rounded py-2.5 md:py-6 lg:py-3 text-base md:text-xl lg:text-lg text-center bg-white"
												onClick={() => removeCharacter()}>
												DEL
											</div>
										</div>
									</div>

									<div className="md:col-span-1 text-center">
										<div>
											<button className="border border-orange rounded py-1.5 md:py-6 lg:py-2 w-full text-2xl text-center bg-white nextButtonHeight"
												onClick={() => {
													setSelectedValue(selectedValue + 1)
												}}>Next</button>
										</div>

										<div>
											<button className="bg-blue text-white mt-2 rounded w-full text-2xl md:py-6 lg:py-0 w-full text-2xl text-center blowButtonHeight"
												onClick={() => {
													switch (selectedValue) {
														case 0:
															setMmOverSB((mm_overSB ? parseInt(mm_overSB) : 0) + 1)
															break
														case -1:
															setMmOver((mm_over ? parseInt(mm_over) : 0) + 1)
															break
														case 1:
															setSeatingBlow1((seating_blows_1st_inc ? parseInt(seating_blows_1st_inc) : 0) + 1)
															break
														case 2:
															setSeatingBlow2((seating_blows_2nd_inc ? parseInt(seating_blows_2nd_inc) : 0) + 1)
															break
														case 3:
															setTestDrive1((test_blows_1st_inc ? parseInt(test_blows_1st_inc) : 0) + 1)
															break
														case 4:
															setTestDrive2((test_blows_2nd_inc ? parseInt(test_blows_2nd_inc) : 0) + 1)
															break
														case 5:
															setTestDrive3((test_blows_3rd_inc ? parseInt(test_blows_3rd_inc) : 0) + 1)
															break
														case 6:
															setTestDrive4((test_blows_4th_inc ? parseInt(test_blows_4th_inc) : 0) + 1)
															break
													}
												}}>BLOW</button>
										</div>

										<div>
											<button className="border border-orange rounded mt-2 py-1.5 md:py-6 lg:py-2 w-full text-sm md:text-xl text-center bg-white ownWeightButtonHeight"
												onClick={() => {
													// addCharacter('0')
													ownWeight('0')
													setSelectedValue(selectedValue + 1)
												}}>Own Weight</button>
										</div>
									</div>
								</div>

								<div className="text-center text-base md:text-lg font-bold mb-2">
									Total Penetration: {total_penetration}mm
								</div>

							</div>
						</div>
					}

					{currentStep === 5 &&

						<div className="w-full flex flex-col flex-grow">
							{/* <div className="border border-grey-mid rounded px-2 bg-sptTB mb-4 w-full pb-2">
							
									<div className="text-xl text-center w-full">Add Recovery D Sample?</div>
									<div className="text-xs text-center">
										Sample: D-## | Depth From: {depthtotop2DP} | Depth To: {depth_to_base2DP} | Casing: {casing_depth2DP}  | 
										Water: {water_depth2DP}  | Notes: Sample from SPT-##
									</div>

									<Button theme="primary" size="lg" className="px-4 sm:px-10 mx-auto">Create Sample</Button>
								</div>

								<PrintLabel 
								location={selectedProject.location}
								project={selectedProject.number}
								borehole={borehole} 
								sampledate={labelDate}  
								user={loggedInUser && (loggedInUser.user.first_name + ' ' + loggedInUser.user.last_name) }
								sample={sampleID}
								depth={printDepth}
								size='printLabel'
								title={title_to_send_for_Dsample}
								/> */}

							{/* <SamplesCreate
								// onComplete={(value: any) => this.saveAction(value, 'B', this.setState({ showB: false }))}
								borehole={borehole}
								selectedDate={selectedDate}
								title="Disturbed"
								casing_preset={casing_depth}
								defaultValues={{
									type: 'D',
									remarks: `Sample from SPT#`,
									depth_to_top: depthtotop2DP,
									depth_to_base: depth_to_base2DP,
									casing_depth: casing_depth,
									water_depth: water_depth,
									importSampleCreateText: remarks_to_send_for_Dsample,
								}}
							/> */}

						</div>
					}

				</div>

				<MissingRequiredField />


				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">


							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?

								<div className="flex mt-4">
									<Button theme="secondary"
										className="mr-5 text-sm sm:text-base px-3.5 md:px-7"
										onClick={() => setDeleteConfirmOpen(false)}>
										Cancel
									</Button>

									<Button onClick={() => {
										onClose()
										onComplete({
											guid: guid,
											update: false,
											delete: true,
										})
										setShowSPTEdit(false)
									}}
										className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">
										Delete
									</Button>
								</div>
							</div>


						</div>
					</div>
				}

				<div className="flex">
					{/* <Button theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button> */}
					<DeleteButton />

					<div className="flex ml-auto">
						<Button theme="primary" size="lg" className="px-4 sm:px-10" onClick={() => previous()}>Back</Button>
						<Button theme={saveButtonColor}
							size="lg"
							className="ml-4 px-8 sm:px-10 w-32 sm:w-60"
							onClick={() => next()}>{saveButtonText}
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

SPT.defaultProps = defaultProps

export default SPT
