import { useState, useEffect } from 'react';

const NumberIncrement = (props: any) => {
    const defaultNum = props.defaultNumber || 0;

    const [num, setNum] = useState(defaultNum);

    const incNum = () => {
        if (num < 99) {
            setNum(num + 1);
        } else {
            setNum(99);
        }
    };

    const decNum = () => {
        if (num > 0) {
            setNum(num - 1);
        } else {
            setNum(0);
        }
    };

    useEffect(() => {
        if (props.onChange) {
            props.onChange(num);
        }
    }, [num]);

    return (
        <div className="number-spinner inline-flex border inline-block mt-0 rounded-md border-grey-dark">
            <button className="rounded-l border-r" onClick={decNum}></button>
            <div className="w-16 p-2 text-2xl h-8 font-bold leading-4 border-l border-r border-grey-dark bg-white text-center">
                {num}
            </div>
            <button className="plus rounded-r border-l" onClick={incNum}></button>
        </div>
    );
};

export default NumberIncrement;