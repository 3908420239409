import React, { FunctionComponent, useState, useEffect, Component } from 'react'

import { useLocation } from "react-router-dom";

import Header from '../layout/Header'

import Modal from '../components/Modal'
import ModalSmall from '../components/ModalSmall'
import WorkflowSteps from '../components/WorkflowSteps'
import NumberInput from '../components/NumberInput'
import Button from '../components/form/Button'
import InputGroup from '../components/form/InputGroup'
import TextArea from '../components/form/TextArea'
import WorkflowActions from '../components/form/WorkflowActions'
import infoIcon from '../assets/icons/info.png'
import warningIcon from '../assets/icons/warning.png'

import { useGetLoggedInUser } from '../helpers/getLoggedInUser'
import { v4 as uuidv4 } from 'uuid';
import { useSetIndexedDBValues } from '../helpers/addToIDB'
import { updatePlantCheckCable } from "../helpers/updatePlantCheckCable";

import Label from '../components/Label'
import { Link } from 'react-router-dom'
import RadioSelect from '../components/form/RadioSelect'

import { useGetIDBValues } from "../helpers/getIDBKey";
import { get, set, del } from 'idb-keyval'

type Props = {
	onClose: any,
	projectId?: string,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => { },
	// onComplete: () => {},
	projectId: '',
	defaultValues: {
		guid: '',
	},
}

// Define the type for a plant check object
interface PlantCheck {
	plantCheck: {
		towHitchHandbrakeSafteyCable: number;
		allDrillingEquipmentGood: number;
		wireRopeInDateGoodLoop: number;
		fuelOilLevelsLeaks: number;
		footBrakeInGoodOrder: number;
		fireExtinguisherFirstAid: number;
		plantNappyRequired: number;
		spillKit: number;
		safteyGuards: number;
		wheelsTyres: number;
		cluchInGoodOrder: number;
		allLiftingPointsGood: number;
		rigFrameRetainingPins: number;
		engineKillStop: number;
		drillingArea: number;
		electricWinchRemote: number;
		ignitionSystem: number;
		mainWinchOperation: number;
		footBrake: number;
		engineOperation: number;
		rigFrame: number;
		issuesMaintenance: string;
		company_guid: string;
		company_id: number;
		driller_name: string;
		driller_cscs: string;
		guid: string;
		plant_guid: string;
		plant_id: string;
		plant_name: string;
		spt_rod_type: string;
		spt_id_ref: string;
		spt_energy_ratio: string;
		spt_calibration_date: string;
		plant_test_date_expiry: string;
		plant_check_date: string;
		plantcheck_url: string;
		project_guid: string;
	}[];
}


// Define the type for currentRigPlantChecks
interface RigPlantChecks {
	plantCheck: PlantCheck[];
}


interface State {
	towHitchHandbrakeSafteyCable: number,
	allDrillingEquipmentGood: number,
	wireRopeInDateGoodLoop: number,
	fuelOilLevelsLeaks: number,
	footBrakeInGoodOrder: number,
	fireExtinguisherFirstAid: number,
	plantNappyRequired: number,
	spillKit: number,
	safteyGuards: number,
	wheelsTyres: number,
	cluchInGoodOrder: number,
	allLiftingPointsGood: number,
	rigFrameRetainingPins: number,
	engineKillStop: number,
	drillingArea: number,
	electricWinchRemote: number,
	ignitionSystem: number,
	mainWinchOperation: number,
	footBrake: number,
	engineOperation: number,
	rigFrame: number,
	issuesMaintenance: string,

	guid: string,
	company_guid: string,
	project_guid: string,
	project_location: string,
	project_id: string,
	company_id: string,

	plant_id: string,
	plant_guid: string,
	plant_name: string,
	plant_check_date: string,
	plant_check_url: string,
	driller_name: string,
	driller_cscs: string,
}



const PlantCheck: FunctionComponent<Props> = ({ onClose, update, companyGuid, defaultValues,
	project_guid, testDate, currentRigTestExpiryDate, plantType, plantID, plantName, companyID, plantGUID, plantChecks,
	currentRigSPTRodType, currentRigSPTIDRef, currentRigSPTEnergyRatio, currentRigSPTCalibrationDate, rigInformation,
	...rest }) => {


	console.log('defaultValues:', defaultValues)
	console.log('rigInformation:', rigInformation)
	// console.log('plantChecks in plantChecks:', plantChecks)

	const [driller_name, setLeadDriller] = useState(defaultValues.driller_name)
	const loggedInUser = useGetLoggedInUser();
	const [loggedInDriller, setLoggedInDriller] = useState('')
	const [loggedInDrillerCSCS, setLoggedInDrillerCSCS] = useState('')
	useEffect(() => {
		if (loggedInUser && loggedInUser.user) {
			setLoggedInDriller(`${loggedInUser.user.first_name} ${loggedInUser.user.last_name}`);
			setLoggedInDrillerCSCS(`${(loggedInUser.user as any).cscs_no}`);
		}
	}, [loggedInUser]);
	// console.log('loggedInDrillerCSCS:', loggedInDrillerCSCS)
	console.log('update:', update)
	const [guid, setGuid] = useState(defaultValues.guid)
	const [project_id, setProject_id] = useState(defaultValues.project_id)
	const [plant_guid, setPlant_guid] = useState(defaultValues.plant_guid)
	// console.log('plantGUID:', plantGUID)
	// const [project_guid, setProject_guid] = useState(defaultValues.project_guid)
	const [projectGUID, setProjectGUID] = useState(project_guid)
	const [project_name, setProject_name] = useState(defaultValues.project_name)
	const [project_location, setProject_location] = useState(defaultValues.project_location)
	const [plant_id, setPlant_id] = useState(defaultValues.plant_id)
	const [plant_name, setPlant_name] = useState(defaultValues.plant_name)
	const [plant_check_date, setPlant_check_date] = useState(defaultValues.plant_check_date)
	const [spt_rod_type, set_spt_rod_type] = useState(defaultValues.spt_rod_type)
	const [spt_id_ref, set_spt_id_ref] = useState(defaultValues.spt_id_ref)
	const [spt_energy_ratio, set_spt_energy_ratio] = useState(defaultValues.spt_energy_ratio)
	const [spt_calibration_date, set_spt_calibration_date] = useState(defaultValues.spt_calibration_date)
	// const company_id = 1
	const [company_id, setCompany_id] = useState(defaultValues.company_id)
	const [company_guid, setCompany_guid] = useState(companyGuid)
	const [current_rig_test_expiry_date, set_current_rig_test_expiry_date] = useState(defaultValues.plant_test_date_expiry)
	const [plant_type, set_plant_type] = useState(plantType)
	// console.log('current_rig_test_expiry_date:', current_rig_test_expiry_date)

	const [towHitchHandbrakeSafteyCable, set_towHitchHandbrakeSafteyCable] = useState(defaultValues.towHitchHandbrakeSafteyCable === 1 ? true : false);
	const [allDrillingEquipmentGood, set_allDrillingEquipmentGood] = useState(defaultValues.allDrillingEquipmentGood === 1 ? true : false);
	const [wireRopeInDateGoodLoop, set_wireRopeInDateGoodLoop] = useState(defaultValues.wireRopeInDateGoodLoop === 1 ? true : false);
	const [fuelOilLevelsLeaks, set_fuelOilLevelsLeaks] = useState(defaultValues.fuelOilLevelsLeaks === 1 ? true : false);
	const [footBrakeInGoodOrder, set_footBrakeInGoodOrder] = useState(defaultValues.footBrakeInGoodOrder === 1 ? true : false);
	const [fireExtinguisherFirstAid, set_fireExtinguisherFirstAid] = useState(defaultValues.fireExtinguisherFirstAid === 1 ? true : false);
	const [plantNappyRequired, set_plantNappyRequired] = useState(defaultValues.plantNappyRequired === 1 ? true : false);
	const [spillKit, set_spillKit] = useState(defaultValues.spillKit === 1 ? true : false);
	const [safteyGuards, set_safteyGuards] = useState(defaultValues.safteyGuards === 1 ? true : false);
	const [wheelsTyres, set_wheelsTyres] = useState(defaultValues.wheelsTyres === 1 ? true : false);
	const [cluchInGoodOrder, set_cluchInGoodOrder] = useState(defaultValues.cluchInGoodOrder === 1 ? true : false);
	const [allLiftingPointsGood, set_allLiftingPointsGood] = useState(defaultValues.allLiftingPointsGood === 1 ? true : false);
	const [rigFrameRetainingPins, set_rigFrameRetainingPins] = useState(defaultValues.rigFrameRetainingPins === 1 ? true : false);
	const [engineKillStop, set_engineKillStop] = useState(defaultValues.engineKillStop === 1 ? true : false);
	const [drillingArea, set_drillingArea] = useState(defaultValues.drillingArea === 1 ? true : false);
	const [electricWinchRemote, set_electricWinchRemote] = useState(defaultValues.electricWinchRemote === 1 ? true : false);
	const [ignitionSystem, set_ignitionSystem] = useState(defaultValues.ignitionSystem === 1 ? true : false);
	const [mainWinchOperation, set_mainWinchOperation] = useState(defaultValues.mainWinchOperation === 1 ? true : false);
	const [footBrake, set_footBrake] = useState(defaultValues.footBrake === 1 ? true : false);
	const [engineOperation, set_engineOperation] = useState(defaultValues.engineOperation === 1 ? true : false);
	const [rigFrame, set_rigFrame] = useState(defaultValues.rigFrame === 1 ? true : false);
	const [issuesMaintenance, set_issuesMaintenance] = useState(defaultValues.issuesMaintenance || '');

	const [sheetDate, set_sheetDate] = useState('');
	const [sheetUpdate, set_sheetUpdate] = useState('');

	useEffect(() => {
		if (update) {
			set_sheetDate(plant_check_date)
			set_sheetUpdate('(update)')
		} else {
			set_sheetDate(testDate)
			set_sheetUpdate('(new)')
		}
	}, [update, plant_check_date, testDate]);

	useEffect(() => {
		console.log('currentRigTestExpiryDate:', currentRigTestExpiryDate)
		console.log('testDate:', testDate)
	}, [currentRigTestExpiryDate, testDate]);


	// GET EXISTING PLANT CHECKS FOR SELECTED RIG

	console.log('plantChecks INSIDE PlantCheck:', plantChecks)

	// const [currentRigPlantChecks, setCurrentRigPlantChecks] = useState<RigPlantChecks>({ plantCheck: [] });

	// useEffect(() => {
	//     // Initialize currentRigPlantChecks with the provided plantChecks
	//     setCurrentRigPlantChecks({ plantCheck: plantChecks });
	// }, [plantChecks]);

	// console.log('currentRigPlantChecks', currentRigPlantChecks)

	/////////////////////////////////////////
	// SAVE NEW LOG INFO
	/////////////////////////////////////////   

	// PlantID string
	let logPlantIDString = plantID.replace(/\s/g, '');
	// Date string
	// Split the date string into day, month, and year
	let parts = testDate.split('-');
	let year = parseInt(parts[0]);
	let month = parseInt(parts[1]);
	let day = parseInt(parts[2]);

	// Create a new Date object with the parts
	let dateObject = new Date(year, month - 1, day);

	// Get the year, month, and day in YYYY, MM, and DD format respectively
	let YYYY = dateObject.getFullYear();
	let MM = String(dateObject.getMonth() + 1).padStart(2, '0'); // Adding 1 because month is zero-based
	let DD = String(dateObject.getDate()).padStart(2, '0');

	// Concatenate them in YYYYMMDD format
	let logDateString = `${YYYY}${MM}${DD}`;

	let plantcheck_url = logPlantIDString + '-' + logDateString + '.pdf'

	const newPlantCheck = {
		towHitchHandbrakeSafteyCable: towHitchHandbrakeSafteyCable ? 1 : 0,
		allDrillingEquipmentGood: allDrillingEquipmentGood ? 1 : 0,
		wireRopeInDateGoodLoop: wireRopeInDateGoodLoop ? 1 : 0,
		fuelOilLevelsLeaks: fuelOilLevelsLeaks ? 1 : 0,
		footBrakeInGoodOrder: footBrakeInGoodOrder ? 1 : 0,
		fireExtinguisherFirstAid: fireExtinguisherFirstAid ? 1 : 0,
		plantNappyRequired: plantNappyRequired ? 1 : 0,
		spillKit: spillKit ? 1 : 0,
		safteyGuards: safteyGuards ? 1 : 0,
		wheelsTyres: wheelsTyres ? 1 : 0,
		cluchInGoodOrder: cluchInGoodOrder ? 1 : 0,
		allLiftingPointsGood: allLiftingPointsGood ? 1 : 0,
		rigFrameRetainingPins: rigFrameRetainingPins ? 1 : 0,
		engineKillStop: engineKillStop ? 1 : 0,
		drillingArea: drillingArea ? 1 : 0,
		electricWinchRemote: electricWinchRemote ? 1 : 0,
		ignitionSystem: ignitionSystem ? 1 : 0,
		mainWinchOperation: mainWinchOperation ? 1 : 0,
		footBrake: footBrake ? 1 : 0,
		engineOperation: engineOperation ? 1 : 0,
		rigFrame: rigFrame ? 1 : 0,
		issuesMaintenance: issuesMaintenance || '',

		company_guid: company_guid || '',
		company_id: update ? company_id : companyID,
		project_guid: projectGUID,
		driller_name: update ? driller_name : loggedInDriller,
		driller_cscs: update ? loggedInDrillerCSCS : loggedInDrillerCSCS,
		guid: update ? guid : uuidv4(),
		plant_guid: update ? plant_guid : plantGUID,
		plant_id: update ? plant_id : plantID,
		plant_name: update ? plant_name : plantName,
		spt_rod_type: update ? spt_rod_type : currentRigSPTRodType,
		spt_id_ref: update ? spt_id_ref : currentRigSPTIDRef,
		spt_energy_ratio: update ? parseFloat(spt_energy_ratio).toString() : parseFloat(currentRigSPTEnergyRatio).toString(),
		spt_calibration_date: update ? spt_calibration_date : currentRigSPTCalibrationDate,
		plant_test_date_expiry: update ? current_rig_test_expiry_date : currentRigTestExpiryDate,
		plant_check_date: update ? plant_check_date : testDate,
		plantcheck_url: plantcheck_url,
	}

	const newPlantCheckToSave = [newPlantCheck]

	// console.log('plantChecks in PlantCheck:', plantChecks)
	console.log('newPlantCheck in PlantCheck:', newPlantCheck)
	console.log('newPlantCheckToSave in PlantCheck:', newPlantCheckToSave)



	// const mergedPlantChecks = {
	// 	...currentRigPlantChecks,
	// 	...newPlantCheckToSave,
	// };

	const [mergedRigPlantChecks, setMergedRigPlantChecks] = useState<PlantCheck[]>([]);

	const mergeAllPlantChecks = () => {
		const mergedPlantChecks = [...plantChecks, newPlantCheck];
		setMergedRigPlantChecks(mergedPlantChecks);
	};

	console.log('mergedRigPlantChecks:', mergedRigPlantChecks);

	const [mergedRigPlantChecksUpdated, setMergedRigPlantChecksUpdated] = useState<PlantCheck[]>([]);

	const updatePlantChecks = () => {
		if (!newPlantCheck) return;

		const updatedPlantChecks = plantChecks.map((check: any) =>
			check.guid === newPlantCheck.guid ? newPlantCheck : check
		);

		setMergedRigPlantChecksUpdated(updatedPlantChecks);
	};

	console.log('mergedRigPlantChecksUpdated:', mergedRigPlantChecksUpdated);





	const save = () => {

		console.log('newPlantCheckToSave:', newPlantCheckToSave)

		if (!update) {
			let saveToIDB = useSetIndexedDBValues;

			console.log('newPlantCheck:', newPlantCheck);

			const endPoint = 'plant-check-cable-percussive';

			console.log('endPoint:', endPoint);

			// Save the new plant check to IndexedDB
			saveToIDB(newPlantCheck, endPoint, true).then(async () => {
				console.log('Plant check saving to IndexedDB');

				// mergeAllPlantChecks()
				const mergedPlantChecks = [...plantChecks, newPlantCheck];
				setMergedRigPlantChecks(mergedPlantChecks);

				// Ensure the structure is maintained
				const updatedData = {
					plantCheck: mergedPlantChecks
				};

				console.log('updatedData on save:', updatedData)

				try {
					// Update the currentRigPlantChecks state in IndexedDB
					// await set('drillit-plant-check-cable-percussive/' + rigInformation.guid, mergedRigPlantChecks);
					await set('drillit-plant-check-cable-percussive/project/' + project_guid, updatedData);
					console.log('Updating drillit-plant-check-cable-percussive/project/');
				} catch (error) {
					console.error('Error updating current rig plant checks:', error);
				}
			});

			setTimeout(() => onClose(), 2000);

		} else {
			console.log('UPDATING PLANT CHECK!!!')

			console.log('newPlantCheck:', newPlantCheck)

			updatePlantChecks()

			updatePlantCheckCable(
				newPlantCheck.towHitchHandbrakeSafteyCable,
				newPlantCheck.allDrillingEquipmentGood,
				newPlantCheck.wireRopeInDateGoodLoop,
				newPlantCheck.fuelOilLevelsLeaks,
				newPlantCheck.footBrakeInGoodOrder,
				newPlantCheck.fireExtinguisherFirstAid,
				newPlantCheck.plantNappyRequired,
				newPlantCheck.spillKit,
				newPlantCheck.safteyGuards,
				newPlantCheck.wheelsTyres,
				newPlantCheck.cluchInGoodOrder,
				newPlantCheck.allLiftingPointsGood,
				newPlantCheck.rigFrameRetainingPins,
				newPlantCheck.engineKillStop,
				newPlantCheck.drillingArea,
				newPlantCheck.electricWinchRemote,
				newPlantCheck.ignitionSystem,
				newPlantCheck.mainWinchOperation,
				newPlantCheck.footBrake,
				newPlantCheck.engineOperation,
				newPlantCheck.rigFrame,
				newPlantCheck.issuesMaintenance,
				newPlantCheck.company_guid,
				newPlantCheck.company_id,
				newPlantCheck.driller_name,
				newPlantCheck.driller_cscs,
				newPlantCheck.guid,
				newPlantCheck.plant_guid,
				newPlantCheck.plant_id,
				newPlantCheck.plant_name,
				newPlantCheck.spt_rod_type,
				newPlantCheck.spt_rod_type,
				newPlantCheck.spt_energy_ratio,
				newPlantCheck.spt_calibration_date,
				newPlantCheck.plant_test_date_expiry,
				newPlantCheck.plant_check_date,
				newPlantCheck.plantcheck_url,
				newPlantCheck.project_guid,
				mergedRigPlantChecksUpdated
			)
				.then(() => setTimeout(() => onClose(), 2000));

			// onClose();
		}
	}


	const handleCloseModal = () => {
		console.log('handleCloseModal triggered')
		onClose(); // Call the onClose function passed as prop
	};


	function checkAll() {
		set_towHitchHandbrakeSafteyCable(true)
		set_allDrillingEquipmentGood(true)
		set_wireRopeInDateGoodLoop(true)
		set_fuelOilLevelsLeaks(true)
		set_footBrakeInGoodOrder(true)
		set_footBrakeInGoodOrder(true)
		set_fireExtinguisherFirstAid(true)
		set_plantNappyRequired(true)
		set_spillKit(true)
		set_safteyGuards(true)
		set_wheelsTyres(true)
		set_cluchInGoodOrder(true)
		set_allLiftingPointsGood(true)
		set_rigFrameRetainingPins(true)
		set_engineKillStop(true)
		set_drillingArea(true)
		set_electricWinchRemote(true)
		set_ignitionSystem(true)
		set_mainWinchOperation(true)
		set_footBrake(true)
		set_engineOperation(true)
		set_rigFrame(true)
	}

	function uncheckAll() {
		set_towHitchHandbrakeSafteyCable(false)
		set_allDrillingEquipmentGood(false)
		set_wireRopeInDateGoodLoop(false)
		set_fuelOilLevelsLeaks(false)
		set_footBrakeInGoodOrder(false)
		set_footBrakeInGoodOrder(false)
		set_fireExtinguisherFirstAid(false)
		set_plantNappyRequired(false)
		set_spillKit(false)
		set_safteyGuards(false)
		set_wheelsTyres(false)
		set_cluchInGoodOrder(false)
		set_allLiftingPointsGood(false)
		set_rigFrameRetainingPins(false)
		set_engineKillStop(false)
		set_drillingArea(false)
		set_electricWinchRemote(false)
		set_ignitionSystem(false)
		set_mainWinchOperation(false)
		set_footBrake(false)
		set_engineOperation(false)
		set_rigFrame(false)
	}


	// RIG INFORMATION

	const [showRigInformation, setShowRigInformation] = useState(false)
	const [information_check, setInformation_check] = useState(false)
	// console.log('rigInformation:', rigInformation)
	const [rigName, setRigName] = useState(rigInformation.name)
	const [rigType, setRigType] = useState(rigInformation.type)
	const [rigTestExpiry, setRigTestExpiry] = useState(rigInformation.test_date_expiry)
	const [rigSPT, setRigSPT] = useState(rigInformation.spt.id_ref)
	const [rigSPTEnergy, setRigSPTEnergy] = useState(rigInformation.spt.energy_ratio)
	const [rigSPTCalibrationDate, setRigSPTCalibrationDate] = useState(rigInformation.spt.calibration_date)

	// console.log(sheetDate, typeof sheetDate, rigTestExpiry, typeof rigTestExpiry, rigSPTCalibrationDate, typeof rigSPTCalibrationDate)

	const sheetDateObj = new Date(sheetDate);
	const rigTestExpiryObj = new Date(rigTestExpiry);
	const rigSPTCalibrationDateObj = new Date(rigSPTCalibrationDate);
	const currentDate = new Date();

	// Calculate the difference in milliseconds between the rig test expiry date and the current date
	const timeDiffRig = rigTestExpiryObj.getTime() - currentDate.getTime();
	// Convert milliseconds to days
	const daysDiffRig = Math.ceil(timeDiffRig / (1000 * 3600 * 24));

	// Calculate the difference in milliseconds between the rig test expiry date and the current date
	const timeDiffSPT = currentDate.getTime() - rigSPTCalibrationDateObj.getTime();
	// Convert milliseconds to days
	const daysDiffSPT = Math.ceil(timeDiffSPT / (1000 * 3600 * 24));

	const [rigTestExpiryText, setRigTestExpiryText] = useState('The Rig Test has expired!!!')
	const [rigSPTCalibrationDateText, setRigSPTCalibrationDateText] = useState('The SPT Calibration has expired!!!')

	const [showRigTypeConflict, setShowRigTypeConflict] = useState(false)
	const [showRigTypeConflictText, setShowRigTypeConflictText] = useState('')

	useEffect(() => {

		if (rigType !== 'Cable Percussive') {
			setShowRigTypeConflict(true)
			setShowRigTypeConflictText('Incorect type of rig for plant check!!!')
		} else {
			setShowRigTypeConflict(false)
			setShowRigTypeConflictText('')
		}

	}, [rigType]);

	const [showTestExpiryInfo, setShowTestExpiryInfo] = useState(true)
	const [showSPTExpiryInfo, setShowSPTExpiryInfo] = useState(true)

	useEffect(() => {

		// Compare the timestamps of the Date objects
		if (sheetDateObj.getTime() < rigTestExpiryObj.getTime()) {
			if (daysDiffRig <= 10 && daysDiffRig > 0) {
				setRigTestExpiryText(`The Rig Test will expire in ${daysDiffRig} days!!!`);
				setShowTestExpiryInfo(true)
			} else if (daysDiffRig === 0) {
				setRigTestExpiryText(`The Rig Test expires today!`);
				setShowTestExpiryInfo(true)
			} else if (daysDiffRig < 0) {
				setRigTestExpiryText(`The Rig Test expired on ${rigTestExpiry}`);
				setShowTestExpiryInfo(true)
			} else {
				setRigTestExpiryText(`The Rig Test is still valid`);
				setShowTestExpiryInfo(false)
			}
			// console.log("Sheet date is before rig test expiry date");
		} else if (sheetDateObj.getTime() > rigTestExpiryObj.getTime()) {
			setRigTestExpiryText('The Rig Test has expired!!!')
			setShowTestExpiryInfo(true)
			// console.log("Sheet date is after rig test expiry date");
		} else {
			// console.log("Sheet date is equal to rig test expiry date");
		}

	}, [sheetDate, rigTestExpiry]);

	useEffect(() => {

		// Compare the timestamps of the Date objects
		if (rigSPTCalibrationDateObj.getTime() < sheetDateObj.getTime()) {
			if (daysDiffSPT <= 10 && daysDiffSPT > 0) {
				setRigSPTCalibrationDateText(`The SPT calibration will expire in ${daysDiffSPT} days!!!`);
				setShowSPTExpiryInfo(true)
			} else if (daysDiffSPT === 0) {
				setRigSPTCalibrationDateText(`The SPT calibration expires today!`);
				setShowSPTExpiryInfo(true)
			} else if (daysDiffSPT < 0) {
				setRigSPTCalibrationDateText(`The SPT calibration was ${rigSPTCalibrationDate}`);
				setShowSPTExpiryInfo(true)
			} else {
				setRigSPTCalibrationDateText(`The SPT calibration is still valid`);
				setShowSPTExpiryInfo(false)
			}
			// console.log("Sheet date is before rig test expiry date");
		} else if (sheetDateObj.getTime() > rigTestExpiryObj.getTime()) {
			setRigSPTCalibrationDateText('The SPT calibration has expired!!!')
			setShowSPTExpiryInfo(true)
			// console.log("Sheet date is after rig test expiry date");
		} else {
			// console.log("Sheet date is equal to rig test expiry date");
		}

	}, [sheetDate, rigTestExpiry]);



	useEffect(() => {

		if (showTestExpiryInfo === true || showSPTExpiryInfo === true) {
			setInformation_check(false)
		} else {
			setInformation_check(true)
		}

	}, [showTestExpiryInfo, showSPTExpiryInfo]);



	let infoWarningIcon = ''

	if (information_check === false) {
		infoWarningIcon = warningIcon
	} else {
		infoWarningIcon = infoIcon
	}

	console.log('newPlantCheck:', newPlantCheck)

	return (

		<>

			{/* <Modal title={`Daily Plant Check (${plant_check_date})`}  toggleModal={onClose}> */}
			<Modal title={`Daily Cable Plant Check`} toggleModal={onClose} confirm={true}>



				{showRigInformation &&
					<ModalSmall title="Rig Information"
						toggleModal={() => { setShowRigInformation(false) }}
						confirm={false}
					>

						<table className="table-auto w-5/6 text-sm text-center bg-white border border-grey-mid mt-4 mx-auto text-xl">
							<tbody>
								<tr >
									<td className="px-4 py-2 border-r border-b border-grey-mid text-center">
										Conducting Plant Check: <span className="font-bold">{loggedInDriller}</span>
									</td>
								</tr>
								<tr >
									<td className="px-4 py-2 border-r border-b border-grey-mid text-center">
										Date of Plant Check: <span className="font-bold">{sheetDate}</span>
									</td>
								</tr>
								<tr >
									<td className="px-4 py-2 border-r border-b border-grey-mid text-center">
										Rig: <span className="font-bold">{rigName}</span>
										{showRigTypeConflict &&
											<div className="text-base text-red font-bold">
												<img src={warningIcon} width="15" className="mr-1 inline-block pb-0.5" alt="" />
												This Rig is a {rigType} rig!!! Please select correct rig!
											</div>
										}
									</td>
								</tr>
								<tr >
									<td className="px-4 py-2 border-r border-b border-grey-mid text-center">
										Rig Test Expiry: <span className="font-bold">{rigTestExpiry}</span>
										{showTestExpiryInfo &&
											<div className="text-base text-red font-bold">
												<img src={warningIcon} width="15" className="mr-1 inline-block pb-0.5" alt="" />
												{rigTestExpiryText}
											</div>
										}
									</td>
								</tr>
								<tr >
									<td className="px-4 py-2 border-r border-b border-grey-mid text-center">
										SPT Hammer: <span className="font-bold">{rigSPT}</span>
									</td>
								</tr>
								<tr >
									<td className="px-4 py-2 border-r border-b border-grey-mid text-center">
										SPT Hammer Energy Ratio: <span className="font-bold">{rigSPTEnergy}</span>
									</td>
								</tr>
								<tr >
									<td className="px-4 py-2 border-r border-b border-grey-mid text-center">
										SPT Calibration Date: <span className="font-bold">{rigSPTCalibrationDate}</span>
										{showSPTExpiryInfo &&
											<div className="text-base text-red font-bold">
												<img src={warningIcon} width="15" className="mr-1 inline-block pb-0.5" alt="" />
												{rigSPTCalibrationDateText}
											</div>
										}
									</td>
								</tr>
							</tbody>
						</table>

						<div className="items-center mt-6 w-full">
							<Button theme="primary" size="lg" className="mb-8 mx-auto" onClick={() => { setShowRigInformation(false) }}>OK</Button>
						</div>

					</ModalSmall>
				}

				<div onClick={() => { setShowRigInformation(true) }} className="text-center text-lg md:text-2xl font-bold -mt-2 mb-2">
					{sheetDate} {sheetUpdate} <img src={infoWarningIcon} width="25" className="ml-1 inline-block pb-0.5" alt="" />
				</div>

				<form onSubmit={(e) => {
					e.preventDefault(); // Prevent the default form submission
					save();
				}}>
					{/* <Heading left={['Phase 1: Pre-Start Checks']} className="pt-0" /> */}

					<div className="border border-grey-mid rounded px-2 py-2 bg-sptTB mb-2">

						<div className="text-lg pb-2 text-center">Phase 1: Pre-Start Checks</div>

						<div className="grid sm:grid-cols-2 lg:grid-cols-3 rounded-sm text-sm md:text-base border-l border-t border-grey-mid">

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="py-1 pl-3 flex-1 text-right pr-2">
									Tow hitch, handbrake &amp; safety cable:
								</div>

								<div className="plantCheck text-center border-l border-grey-mid">
									<input type="checkbox" className="form-control-checkbox" checked={towHitchHandbrakeSafteyCable} onChange={(value: any) => set_towHitchHandbrakeSafteyCable(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="py-1 pl-3 flex-1 text-right pr-2">
									All drilling equipment in good order (casing threads, drive bars):
								</div>

								<div className="plantCheck text-center border-l border-grey-mid">
									<input type="checkbox" className="form-control-checkbox" checked={allDrillingEquipmentGood} onChange={(value: any) => set_allDrillingEquipmentGood(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="py-1 pl-3 flex-1 text-right pr-2">
									Wire rope - in date &amp; good loop:
								</div>

								<div className="plantCheck text-center border-l border-grey-mid">
									<input type="checkbox" className="form-control-checkbox" checked={wireRopeInDateGoodLoop} onChange={(value: any) => set_wireRopeInDateGoodLoop(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="py-1 pl-3 flex-1 text-right pr-2">
									Fuel, oil - levels &amp; leaks:
								</div>

								<div className="plantCheck text-center border-l border-grey-mid">
									<input type="checkbox" className="form-control-checkbox" checked={fuelOilLevelsLeaks} onChange={(value: any) => set_fuelOilLevelsLeaks(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="py-1 pl-3 flex-1 text-right pr-2">
									Foot brake in good order:
								</div>

								<div className="plantCheck text-center border-l border-grey-mid">
									<input type="checkbox" className="form-control-checkbox" checked={footBrakeInGoodOrder} onChange={(value: any) => set_footBrakeInGoodOrder(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="py-1 pl-3 flex-1 text-right pr-2">
									Fire extinguisher &amp; first aid:
								</div>

								<div className="plantCheck text-center border-l border-grey-mid">
									<input type="checkbox" className="form-control-checkbox" checked={fireExtinguisherFirstAid} onChange={(value: any) => set_fireExtinguisherFirstAid(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="py-1 pl-3 flex-1 text-right pr-2">
									Plant nappy required:
								</div>

								<div className="plantCheck text-center border-l border-grey-mid">
									<input type="checkbox" className="form-control-checkbox" checked={plantNappyRequired} onChange={(value: any) => set_plantNappyRequired(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="py-1 pl-3 flex-1 text-right pr-2">
									Spill kit:
								</div>

								<div className="plantCheck text-center border-l border-grey-mid">
									<input type="checkbox" className="form-control-checkbox" checked={spillKit} onChange={(value: any) => set_spillKit(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="py-1 pl-3 flex-1 text-right pr-2">
									Safety Guards:
								</div>

								<div className="plantCheck text-center border-l border-grey-mid">
									<input type="checkbox" className="form-control-checkbox" checked={safteyGuards} onChange={(value: any) => set_safteyGuards(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="py-1 pl-3 flex-1 text-right pr-2">
									Wheels &amp; tyres:
								</div>

								<div className="plantCheck text-center border-l border-grey-mid">
									<input type="checkbox" className="form-control-checkbox" checked={wheelsTyres} onChange={(value: any) => set_wheelsTyres(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="py-1 pl-3 flex-1 text-right pr-2">
									Clutch in good working order:
								</div>

								<div className="plantCheck text-center border-l border-grey-mid">
									<input type="checkbox" className="form-control-checkbox" checked={cluchInGoodOrder} onChange={(value: any) => set_cluchInGoodOrder(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="py-1 pl-3 flex-1 text-right pr-2">
									All lifting points in good order &amp; certs in date:
								</div>

								<div className="plantCheck text-center border-l border-grey-mid">
									<input type="checkbox" className="form-control-checkbox" checked={allLiftingPointsGood} onChange={(value: any) => set_allLiftingPointsGood(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="py-1 pl-3 flex-1 text-right pr-2">
									Rig frame &amp; retaining pins:
								</div>

								<div className="plantCheck text-center border-l border-grey-mid">
									<input type="checkbox" className="form-control-checkbox" checked={rigFrameRetainingPins} onChange={(value: any) => set_rigFrameRetainingPins(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="py-1 pl-3 flex-1 text-right pr-2">
									Engine kill/stop:
								</div>

								<div className="plantCheck text-center border-l border-grey-mid">
									<input type="checkbox" className="form-control-checkbox" checked={engineKillStop} onChange={(value: any) => set_engineKillStop(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="py-1 pl-3 flex-1 text-right pr-2">
									Drilling area - no changes since previous shift:
								</div>

								<div className="plantCheck text-center border-l border-grey-mid">
									<input type="checkbox" className="form-control-checkbox" checked={drillingArea} onChange={(value: any) => set_drillingArea(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="py-1 pl-3 flex-1 text-right pr-2">
									Electric winch &amp; remote:
								</div>

								<div className="plantCheck text-center border-l border-grey-mid">
									<input type="checkbox" className="form-control-checkbox" checked={electricWinchRemote} onChange={(value: any) => set_electricWinchRemote(value.target.checked)} />
								</div>
							</div>

						</div>
					</div>

					{/* <Heading left={['Phase 2: Run/Post Erection Checks']} /> */}

					<div className="border border-grey-mid rounded px-2 py-2 bg-sptTB mb-2">

						<div className="text-lg pb-2 text-center">Phase 2: Run/Post Erection Checks</div>

						<div className="grid sm:grid-cols-2 lg:grid-cols-3 rounded-sm text-sm md:text-base border-l border-t border-grey-mid">

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="py-1 pl-3 flex-1 text-right pr-2">
									Ignition system:
								</div>

								<div className="plantCheck text-center border-l border-grey-mid">
									<input type="checkbox" className="form-control-checkbox" checked={ignitionSystem} onChange={(value: any) => set_ignitionSystem(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="py-1 pl-3 flex-1 text-right pr-2">
									Main winch operation:
								</div>

								<div className="plantCheck text-center border-l border-grey-mid">
									<input type="checkbox" className="form-control-checkbox" checked={mainWinchOperation} onChange={(value: any) => set_mainWinchOperation(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="py-1 pl-3 flex-1 text-right pr-2">
									Foot brake:
								</div>

								<div className="plantCheck text-center border-l border-grey-mid">
									<input type="checkbox" className="form-control-checkbox" checked={footBrake} onChange={(value: any) => set_footBrake(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="py-1 pl-3 flex-1 text-right pr-2">
									Engine operation:
								</div>

								<div className="plantCheck text-center border-l border-grey-mid">
									<input type="checkbox" className="form-control-checkbox" checked={engineOperation} onChange={(value: any) => set_engineOperation(value.target.checked)} />
								</div>
							</div>

							<div className="flex border-b border-r border-grey-mid bg-white">
								<div className="py-1 pl-3 flex-1 text-right pr-2">
									Rig frame:
								</div>

								<div className="plantCheck text-center border-l border-grey-mid">
									<input type="checkbox" className="form-control-checkbox" checked={rigFrame} onChange={(value: any) => set_rigFrame(value.target.checked)} />
								</div>
							</div>

						</div>
					</div>

					<div className="border border-grey-mid rounded px-2 py-2 bg-sptTB mb-4">
						<div className="flex items-center mt-4 mb-5">
							<div className="">
								Issues/Maintenance:
							</div>

							<div className="flex-1 ml-4">
								<input type="text" className="form-control w-full" value={issuesMaintenance} onChange={(value) => set_issuesMaintenance(value.target.value)} />
							</div>
						</div>
					</div>

					{/* <div className="grid grid-cols-2 items-center"> */}
					<div className="w-full flex items-center justify-content-center mb-4">
						<Button type="button" theme="primary" onClick={() => checkAll()} className="mr-2 text-sm px-2 h-12 md:h-10 ml-auto" >Check All</Button>
						<Button type="button" theme="primary" onClick={() => uncheckAll()} className="text-sm px-2 h-12 md:h-10 mr-auto" >Uncheck All</Button>
					</div>

					<div className="w-full">
						{showRigTypeConflictText !== '' && (
							<div className="text-red font-bold w-60 md:w-96 text-base ml-auto mr-0 text-center">{showRigTypeConflictText}</div>
						)}
						{!showRigTypeConflict &&
							<Button type="submit" theme="success" className="px-10 h-12 md:h-16 w-60 md:w-96 text-lg font-bold ml-auto -mt-0 md:-mt-8">Save</Button>
						}
					</div>
					{/* </div> */}

				</form>

			</Modal>
		</>

	)
}

PlantCheck.defaultProps = defaultProps

export default PlantCheck