import React, { FunctionComponent, useState, useEffect, Component } from 'react'

import { useLocation } from "react-router-dom";

import Header from '../layout/Header'

import Modal from '../components/Modal'
import WorkflowSteps from '../components/WorkflowSteps'
import NumberInput from '../components/NumberInput'
import Button from '../components/form/Button'
import MultiSelect from '../components/project/workflow/soil-description/MultiSelect'
import MultiSelectEmailAttachments from '../components/MultiSelectEmailAttachments'
import ButtonOptionsNoGrid from '../components/form/ButtonOptionsNoGrid'
import InputGroup from '../components/form/InputGroup'
import TextArea from '../components/form/TextArea'
import WorkflowActions from '../components/form/WorkflowActions'
import edit from "../assets/icons/edit.svg";
import sortBoth from "../assets/icons/sort_both.svg";
import sortUp from "../assets/icons/sort_up.svg";
import sortDown from "../assets/icons/sort_down.svg";

import stepStatus from '../helpers/stepStatus'

import Label from '../components/Label'
import { Link } from 'react-router-dom'
import RadioSelect from '../components/form/RadioSelect'

import { useGetIDBValues } from "../helpers/getIDBKey";
import { useSetIndexedDBValues } from '../helpers/addToIDB'
import { saveContactList } from '../helpers/saveContactList'
import { get, set } from 'idb-keyval'
import { v4 as uuidv4 } from 'uuid';
import { updateContactInformation } from "../helpers/updateContactInformation";
import { useGetLoggedInUser } from '../helpers/getLoggedInUser'
import Checkbox from "../components/form/Checkbox";

type Props = {
    onClose: any,
    // onComplete: any,
    defaultValues?: any,
    projectId?: string,
    [x: string]: any;
}

const defaultProps: Props = {
    onClose: () => { },
    // onComplete: () => {},
    projectId: '',
    defaultValues: {
        guid: '',
    },
}

interface user {
    id: number;
}
interface UserLog {
    id: number;
    project_guid: string;
    // Add other properties here as needed
}



const Logs: FunctionComponent<Props> = ({
    onClose, project_location, project_id, company_guid, project_guid,
    ...rest }) => {


    const [company_id, set_company_id] = useState(null)

    const loggedInUser = useGetLoggedInUser()

    useEffect(() => {
        console.log('company_id:', (loggedInUser?.user as any)?.company_id);
        set_company_id((loggedInUser?.user as any)?.company_id)
    }, [loggedInUser]);

    // const [companyID, setCompanyID] = useState(2);
    const [logType, setLogType] = useState('logs');

    const [holeLogs, setHoleLogs] = useState<any[]>([])

    const [holeLogsCable, setHoleLogsCable] = useState<any[]>([])
    const [holeLogsRotary, setHoleLogsRotary] = useState<any[]>([])
    const [holeLogsWindowSample, setHoleLogsWindowSample] = useState<any[]>([])
    const [holeLogsDynamicProbe, setHoleLogsDynamicProbe] = useState<any[]>([])
    const [holeLogsTrialpit, setHoleLogsTrialpit] = useState<any[]>([])
    const [holeLogsHeadTest, setHoleLogsHeadTest] = useState<any[]>([])
    const [holeLogsSoakaway, setHoleLogsSoakaway] = useState<any[]>([])

    const [plantChecksCable, setPlantChecksCable] = useState<any[]>([])
    const [plantChecksRotary, setPlantChecksRotary] = useState<any[]>([])
    const [plantChecksWindowSample, setPlantChecksWindowSample] = useState<any[]>([])

    const [riskAssessments, setRiskAssessments] = useState<any[]>([])

    const [companyUsers, setCompanyUsers] = useState<any[]>([])

    const [agsTitle, setAgsTitle] = useState('')
    
    const [showLogs_AGS, setShowLogs_AGS] = useState(true);
    const [showLogs_Cable, setShowLogs_Cable] = useState(false);
    const [showLogs_Rotary, setShowLogs_Rotary] = useState(false);
    const [showLogs_WindowSample, setShowLogs_WindowSample] = useState(false);
    const [showLogs_DynamicProbe, setShowLogs_DynamicProbe] = useState(false);
    const [showLogs_Trialpit, setShowLogs_Trialpit] = useState(false);
    const [showLogs_HeadTest, setShowLogs_HeadTest] = useState(false);
    const [showLogs_Soakaway, setShowLogs_Soakaway] = useState(false);
    const [showLogs_RiskAssessments, setShowLogs_RiskAssessments] = useState(false);

    const [showPlantChecks, setShowPlantChecks] = useState(false);
    const [showPlantChecksCable, setShowPlantChecksCable] = useState(false);
    const [showPlantChecksRotary, setShowPlantChecksRotary] = useState(false);
    const [showPlantChecksWindowSample, setShowPlantChecksWindowSample] = useState(false);

    const handleToggle = () => {
        setShowPlantChecks(!showPlantChecks);
    };

    console.log('company_id:', company_id)

    // Create AGS button text
    useEffect(() => {
        // Remove spaces from project_location and project_id
        const formattedLocation = project_location.replace(/\s/g, '');
        const formattedId = project_id.replace(/\s/g, '');

        // Combine formattedLocation and formattedId with a hyphen
        const combinedTitle = `${formattedLocation}-${formattedId}`;

        // Set the combined title as agsTitle
        setAgsTitle(combinedTitle);
    }, [project_location, project_id]);

    const filepath = 'FreethorpeVillageHall-AGI00007-CP02-20240502.pdf'

    // const handleDownload = () => {
    //     let cachedValues = useGetIDBValues;
    //     const logFilePathEndpoint = `logs/${companyID}/${logType}/${filepath}`;
    //     cachedValues(logFilePathEndpoint, get)
    //   };

    // const handleDownload = async () => {
    //     try {
    //         const response = await fetch(`logs/${company_id}/${logType}/${filepath}`);

    //         // Check if the response is successful
    //         if (!response.ok) {
    //             console.error('Failed to fetch log file:', response.statusText);
    //             return;
    //         }

    //         // Log response headers
    //         console.log('Response Headers:', response.headers);
    //         // Log specific headers
    //         console.log('Content-Type:', response.headers.get('Content-Type'));
    //         console.log('Content-Disposition:', response.headers.get('Content-Disposition'));

    //         console.log('log file response:', response.statusText);

    //         // Convert the response to a blob
    //         const blob = await response.blob();

    //         console.log('blob response:', blob);

    //         // Create a temporary URL for the blob
    //         const url = window.URL.createObjectURL(blob);

    //         console.log('const url = window.URL.createObjectURL(blob):', url);

    //         // Create a link element to trigger the download
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.download = filepath; // Set the filename to the filepath
    //         document.body.appendChild(link);
    //         link.click();
    //         document.body.removeChild(link);

    //         // Clean up resources
    //         window.URL.revokeObjectURL(url);
    //     } catch (error) {
    //         console.error('Error downloading log file:', error);
    //     }
    // };

    // function handleDownload() {
    //     const company_id = '2';
    //     const log_type = 'ags';
    //     const full_filename = 'FreethorpeVillageHall-AGI00007.ags';

    //     const cachedValues = useGetIDBValues;

    //     // Construct the URL
    //     const file_url = `logs/${company_id}/${log_type}/${full_filename}`;

    //     cachedValues(file_url, (data: any) => {
    //         console.log('Pdownload:', data);
    //     })

    //     // // Send a GET request to download the file
    //     // fetch(url)
    //     //     .then(response => {
    //     //         // Log the response
    //     //         console.log('Response from server:', response);
    //     //         // Check if the request was successful
    //     //         if (!response.ok) {
    //     //             throw new Error('Network response was not ok');
    //     //         }
    //     //         // Return the response
    //     //         return response.blob();
    //     //     })
    //     //     .then(blob => {
    //     //         console.log('blob:', blob);
    //     //         // Create a URL for the blob
    //     //         const url = window.URL.createObjectURL(blob);

    //     //         // Create a temporary link element
    //     //         const a = document.createElement('a');
    //     //         a.href = url;
    //     //         a.download = full_filename;

    //     //         // Append the link to the body and click it programmatically
    //     //         document.body.appendChild(a);
    //     //         a.click();

    //     //         // Clean up by removing the temporary link and URL object
    //     //         window.URL.revokeObjectURL(url);
    //     //         a.remove();
    //     //     })
    //     //     .catch(error => {
    //     //         console.error('There was a problem with the download:', error);
    //     //     });
    // }

    const handleOpenFile = async () => {
        try {
            const response = await fetch(`logs/${company_id}/${logType}/${filepath}`);

            // Check if the response is successful
            if (!response.ok) {
                console.error('Failed to fetch log file:', response.statusText);
                return;
            }

            // Convert the response to a blob
            const blob = await response.blob();

            // Convert the blob to a data URL
            const dataUrl = URL.createObjectURL(blob);

            // Open the data URL in a new browser tab
            window.open(dataUrl, '_blank');

            // Clean up resources
            URL.revokeObjectURL(dataUrl);
        } catch (error) {
            console.error('Error opening log file:', error);
        }
    };



    // // LOGS
    // const getLogs = () => {
    //     const cachedValues = useGetIDBValues;
    //     const projectLogsKey = `logs/project/${project_guid}`;

    //     // Fetch project logs from the API
    //     cachedValues(projectLogsKey, (data: any) => {
    //         if(data){
    //             setHoleLogs(data.log)
    //         }
    //     })
    // }

    useEffect(() => {

        const getLogs = () => {
            const cachedValues = useGetIDBValues;
            const projectLogsKey = `logs/project/${project_guid}`;

            // Fetch project logs from the API
            cachedValues(projectLogsKey, (data: any) => {
                if (data) {
                    setHoleLogs(data.log)
                }
            })
        }

        const getPlantChecksCable = () => {
            const cachedValues = useGetIDBValues;
            const endpoint = `plant-check-cable-percussive/project/${project_guid}`;

            // Fetch project logs from the API
            cachedValues(endpoint, (data: any) => {
                console.log('Cable:', data)
                if (data) {
                    setPlantChecksCable(data.plantCheck)
                }
            })
        }

        const getPlantChecksRotary = () => {
            const cachedValues = useGetIDBValues;
            const endpoint = `plant-check-rotary/project/${project_guid}`;

            // Fetch project logs from the API
            cachedValues(endpoint, (data: any, response: Response) => {
                // if (response.status === 404) {
                //     // Handle the case where no plant checks are found (404 response)
                //     console.log('No plant checks found for rotary.');
                //     return;
                // }

                console.log('Rotary:', data);
                if (data) {
                    setPlantChecksRotary(data.plantCheck)
                }
            })
        }

        const getPlantChecksWindowSample = () => {
            const cachedValues = useGetIDBValues;
            const endpoint = `plant-check-window-sample/project/${project_guid}`;

            // Fetch project logs from the API
            cachedValues(endpoint, (data: any) => {
                console.log('Window:', data)
                if (data) {
                    setPlantChecksWindowSample(data.plantCheck)
                }
            })
        }

        const getRiskAssessments = () => {
            const cachedValues = useGetIDBValues;
            const endpoint = `risk-assessment/project_ra/${project_guid}`;

            // Fetch project logs from the API
            cachedValues(endpoint, (data: any) => {
                console.log('Risk Assessments:', data)
                if (data) {
                    setRiskAssessments(data.riskAssessment)
                }
            })
        }

        const getCompanyUsers = () => {
            const cachedValues = useGetIDBValues;
            const endpoint = `users/company/${company_id}`;

            // Fetch project logs from the API
            cachedValues(endpoint, (data: any) => {
                console.log('Company Users:', data.users)
                if (data) {
                    setCompanyUsers(data.users)
                }
            })
        }

        if (project_guid) {
            getLogs();
            getPlantChecksCable();
            getPlantChecksRotary();
            getPlantChecksWindowSample();
            getRiskAssessments();
        }
        if (company_id) {
            getCompanyUsers();
        }

    }, [project_guid, company_id]);

    useEffect(() => {
        // Filter logs where hole_type_id === 1 (Cable Percussive)
        const filteredLogsCable = holeLogs.filter(log => log.hole_type_id === 1);
        setHoleLogsCable(filteredLogsCable);
        // Filter logs where hole_type_id === 2 (Window Sample)
        const filteredLogsWindowSample = holeLogs.filter(log => log.hole_type_id === 2);
        setHoleLogsWindowSample(filteredLogsWindowSample);
        // Filter logs where hole_type_id === 3 (Rotary)
        const filteredLogsRotary = holeLogs.filter(log => log.hole_type_id === 3);
        setHoleLogsRotary(filteredLogsRotary);
        // Filter logs where hole_type_id === 4 (Trialpit)
        const filteredLogsTrialpit = holeLogs.filter(log => log.hole_type_id === 4);
        setHoleLogsTrialpit(filteredLogsTrialpit);
        // Filter logs where hole_type_id === 5 (Dynamic Probe)
        const filteredLogsDynamicProbe = holeLogs.filter(log => log.hole_type_id === 5);
        setHoleLogsDynamicProbe(filteredLogsDynamicProbe);
        // Filter logs where hole_type_id === 6 (Head Test)
        const filteredLogsHeadTest = holeLogs.filter(log => log.hole_type_id === 6);
        setHoleLogsHeadTest(filteredLogsHeadTest);
        // Filter logs where hole_type_id === 7 (Soakaway)
        const filteredLogsSoakaway = holeLogs.filter(log => log.hole_type_id === 7);
        setHoleLogsSoakaway(filteredLogsSoakaway);
    }, [holeLogs]);

    interface BoreholeLog {
        log_date: string;
        driller_name: string;
        log_url: string;
        updated_at: string;
        // Add other properties if needed
    }

    interface BoreholeLogsByDate {
        [date: string]: BoreholeLog;
    }

    interface BoreholesWithUniqueDates {
        [holeName: string]: BoreholeLogsByDate;
    }

    const updatedAt = new Date().toISOString();

    function formatDateTime(dateString:any) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        
        return `${year}-${month}-${day} - ${hours}:${minutes}`;
    }

    //................................................................
    //....CCCCCCC.......AAAAA.....BBBBBBBBBB...LLLL.......EEEEEEEEEE..
    //...CCCCCCCCC......AAAAA.....BBBBBBBBBBB..LLLL.......EEEEEEEEEE..
    //..CCCCCCCCCCC....AAAAAA.....BBBBBBBBBBB..LLLL.......EEEEEEEEEE..
    //..CCCC...CCCCC...AAAAAAA....BBBB...BBBB..LLLL.......EEEE........
    //.CCCC.....CCC...AAAAAAAA....BBBB...BBBB..LLLL.......EEEE........
    //.CCCC...........AAAAAAAA....BBBBBBBBBBB..LLLL.......EEEEEEEEEE..
    //.CCCC...........AAAA.AAAA...BBBBBBBBBB...LLLL.......EEEEEEEEEE..
    //.CCCC..........AAAAAAAAAA...BBBBBBBBBBB..LLLL.......EEEEEEEEEE..
    //.CCCC.....CCC..AAAAAAAAAAA..BBBB....BBBB.LLLL.......EEEE........
    //..CCCC...CCCCC.AAAAAAAAAAA..BBBB....BBBB.LLLL.......EEEE........
    //..CCCCCCCCCCC.AAAA....AAAA..BBBBBBBBBBBB.LLLLLLLLLL.EEEEEEEEEE..
    //...CCCCCCCCCC.AAAA.....AAAA.BBBBBBBBBBB..LLLLLLLLLL.EEEEEEEEEE..
    //....CCCCCCC..CAAAA.....AAAA.BBBBBBBBBB...LLLLLLLLLL.EEEEEEEEEE..
    //................................................................

    // Create an object to store boreholes with unique dates
    const holeLogsCableWithUniqueDates: BoreholesWithUniqueDates = {};
    // Iterate over each borehole in holeLogsCable
    holeLogsCable.forEach(borehole => {
        const { hole_name, log_date, driller_id, log_url, updated_at } = borehole;
        holeLogsCableWithUniqueDates[hole_name] = holeLogsCableWithUniqueDates[hole_name] || {};

        // Find the user with the matching driller_id
        const driller = companyUsers.find(user => user.id === driller_id);
        const driller_name = driller ? `${driller.first_name} ${driller.last_name}` : 'Unknown'; // Concatenate first and last name

        // If there's no log for this date or the current log is newer, update it
        // if (!holeLogsCableWithUniqueDates[hole_name][log_date] || holeLogsCableWithUniqueDates[hole_name][log_date].log_date < log_date) {
        //     holeLogsCableWithUniqueDates[hole_name][log_date] = { log_date, driller_name };
        // }
        if (!holeLogsCableWithUniqueDates[hole_name][log_date] || holeLogsCableWithUniqueDates[hole_name][log_date].log_date < log_date) {
            holeLogsCableWithUniqueDates[hole_name][log_date] = { log_date, driller_name, log_url, updated_at };
        }
    });

    // Function to count holes with unique dates
    const countCPHolesWithUniqueDates = (holesWithUniqueDates:any) => {
        let count = 0;
        for (const hole_name in holesWithUniqueDates) {
            if (Object.hasOwnProperty.call(holesWithUniqueDates, hole_name)) {
                const uniqueDates = Object.keys(holesWithUniqueDates[hole_name]);
                count += uniqueDates.length;
            }
        }
        return count;
    };
    
    // Get the count of holes with unique dates
    const uniqueCPDateCount = countCPHolesWithUniqueDates(holeLogsCableWithUniqueDates);
    // console.log('Count of holes with unique dates:', uniqueCPDateCount);

    //.................................................................................................................................................................
    //.WWWW..WWWWW...WWWIIIII.NNNN...NNNN..DDDDDDDDD......OOOOOOO...OWWWW..WWWWW...WWW ......SSSSSSS.......AAAAA....MMMMMM...MMMMMM.PPPPPPPPP...LLLL.......EEEEEEEEEE..
    //.WWWW..WWWWW..WWWWIIIII.NNNNN..NNNN..DDDDDDDDDD....OOOOOOOOOO..WWWW..WWWWW..WWWW......SSSSSSSSS......AAAAA....MMMMMM...MMMMMM.PPPPPPPPPP..LLLL.......EEEEEEEEEE..
    //.WWWW..WWWWWW.WWWWIIIII.NNNNN..NNNN..DDDDDDDDDDD..OOOOOOOOOOOO.WWWW..WWWWWW.WWWW......SSSSSSSSSS....AAAAAA....MMMMMM...MMMMMM.PPPPPPPPPPP.LLLL.......EEEEEEEEEE..
    //.WWWW.WWWWWWW.WWWWIIIII.NNNNNN.NNNN..DDDD...DDDD..OOOOO..OOOOO.WWWW.WWWWWWW.WWWW.....SSSSS..SSSS....AAAAAAA...MMMMMMM.MMMMMMM.PPPP...PPPP.LLLL.......EEEE........
    //.WWWW.WWWWWWW.WWWWIIIII.NNNNNN.NNNN..DDDD....DDDDOOOOO....OOOOOWWWW.WWWWWWW.WWWW.....SSSSS.........AAAAAAAA...MMMMMMM.MMMMMMM.PPPP...PPPP.LLLL.......EEEE........
    //..WWWWWWWWWWW.WWW.IIIII.NNNNNNNNNNN..DDDD....DDDDOOOO......OOOO.WWWWWWWWWWW.WWW.......SSSSSSS......AAAAAAAA...MMMMMMM.MMMMMMM.PPPPPPPPPPP.LLLL.......EEEEEEEEEE..
    //..WWWWWWW.WWWWWWW.IIIII.NNNNNNNNNNN..DDDD....DDDDOOOO......OOOO.WWWWWWW.WWWWWWW........SSSSSSSSS...AAAA.AAAA..MMMMMMMMMMMMMMM.PPPPPPPPPP..LLLL.......EEEEEEEEEE..
    //..WWWWWWW.WWWWWWW.IIIII.NNNNNNNNNNN..DDDD....DDDDOOOO......OOOO.WWWWWWW.WWWWWWW..........SSSSSSS..AAAAAAAAAA..MMMMMMMMMMMMMMM.PPPPPPPPP...LLLL.......EEEEEEEEEE..
    //..WWWWWWW.WWWWWWW.IIIII.NNNNNNNNNNN..DDDD....DDDDOOOOO....OOOOO.WWWWWWW.WWWWWWW.............SSSSS.AAAAAAAAAAA.MMMMMMMMMMMMMMM.PPPP........LLLL.......EEEE........
    //..WWWWWWW.WWWWWWW.IIIII.NNNN.NNNNNN..DDDD...DDDDD.OOOOO..OOOOO..WWWWWWW.WWWWWWW......SSSS....SSSS.AAAAAAAAAAA.MMMM.MMMMM.MMMM.PPPP........LLLL.......EEEE........
    //...WWWWW...WWWWW..IIIII.NNNN..NNNNN..DDDDDDDDDDD..OOOOOOOOOOOO...WWWWW...WWWWW.......SSSSSSSSSSSSAAAA....AAAA.MMMM.MMMMM.MMMM.PPPP........LLLLLLLLLL.EEEEEEEEEE..
    //...WWWWW...WWWWW..IIIII.NNNN..NNNNN..DDDDDDDDDD....OOOOOOOOOO....WWWWW...WWWWW........SSSSSSSSSS.AAAA.....AAAAMMMM.MMMMM.MMMM.PPPP........LLLLLLLLLL.EEEEEEEEEE..
    //...WWWWW...WWWWW..IIIII.NNNN...NNNN..DDDDDDDDD.......OOOOOO......WWWWW...WWWWW.........SSSSSSSS.SAAAA.....AAAAMMMM.MMMMM.MMMM.PPPP........LLLLLLLLLL.EEEEEEEEEE..
    //.................................................................................................................................................................

    // Create an object to store boreholes with unique dates
    const holeLogsWindowSampleWithUniqueDates: BoreholesWithUniqueDates = {};
    // Iterate over each borehole in holeLogsCable
    holeLogsWindowSample.forEach(borehole => {
        const { hole_name, log_date, driller_id, log_url, updated_at } = borehole;
        holeLogsWindowSampleWithUniqueDates[hole_name] = holeLogsWindowSampleWithUniqueDates[hole_name] || {};

        // Find the user with the matching driller_id
        const driller = companyUsers.find(user => user.id === driller_id);
        const driller_name = driller ? `${driller.first_name} ${driller.last_name}` : 'Unknown'; // Concatenate first and last name

        // If there's no log for this date or the current log is newer, update it
        if (!holeLogsWindowSampleWithUniqueDates[hole_name][log_date] || holeLogsWindowSampleWithUniqueDates[hole_name][log_date].log_date < log_date) {
            holeLogsWindowSampleWithUniqueDates[hole_name][log_date] = { log_date, driller_name, log_url, updated_at };
        }
    });

    // Function to count holes with unique dates
    const countWSHolesWithUniqueDates = (holesWithUniqueDates:any) => {
        let count = 0;
        for (const hole_name in holesWithUniqueDates) {
            if (Object.hasOwnProperty.call(holesWithUniqueDates, hole_name)) {
                const uniqueDates = Object.keys(holesWithUniqueDates[hole_name]);
                count += uniqueDates.length;
            }
        }
        return count;
    };
    
    // Get the count of holes with unique dates
    const uniqueWSDateCount = countWSHolesWithUniqueDates(holeLogsWindowSampleWithUniqueDates);
    // console.log('Count of holes with unique dates:', uniqueWSDateCount);

    //...........................................................................
    //.RRRRRRRRRR.....OOOOOOO.....TTTTTTTTTTT..AAAA.....AARRRRRRRRR.RRRY....YYY..
    //.RRRRRRRRRRR...OOOOOOOOOO...TTTTTTTTTTT.AAAAAA....AARRRRRRRRRRRRRYY..YYYY..
    //.RRRRRRRRRRR..OOOOOOOOOOOO..TTTTTTTTTTT.AAAAAA....AARRRRRRRRRR.RRYY..YYYY..
    //.RRRR...RRRRR.OOOOO..OOOOO.....TTTT.....AAAAAAA...AARR....RRRR.RRYYYYYYY...
    //.RRRR...RRRRRROOOO....OOOOO....TTTT....AAAAAAAA...AARR....RRRR..RYYYYYYY...
    //.RRRRRRRRRRR.ROOO......OOOO....TTTT....AAAAAAAA...AARRRRRRRRRR...YYYYYY....
    //.RRRRRRRRRRR.ROOO......OOOO....TTTT....AAAA.AAAA..AARRRRRRRRR....YYYYYY....
    //.RRRRRRRR....ROOO......OOOO....TTTT...TAAAAAAAAA..AARRRRRRR.......YYYY.....
    //.RRRR.RRRR...ROOOO....OOOOO....TTTT...TAAAAAAAAAA.AARR.RRRRR......YYYY.....
    //.RRRR..RRRR...OOOOO..OOOOO.....TTTT..TTAAAAAAAAAA.AARR..RRRRR.....YYYY.....
    //.RRRR..RRRRR..OOOOOOOOOOOO.....TTTT..TTAA....AAAA.AARR...RRRRR....YYYY.....
    //.RRRR...RRRRR..OOOOOOOOOO......TTTT..TTAA....AAAAAAARR....RRRR....YYYY.....
    //.RRRR....RRRR....OOOOOO........TTTT.TTTAA.....AAAAAARR.....RRRR...YYYY.....
    //...........................................................................

    // Create an object to store boreholes with unique dates
    const holeLogsRotaryWithUniqueDates: BoreholesWithUniqueDates = {};
    // Iterate over each borehole in holeLogsCable
    holeLogsRotary.forEach(borehole => {
        const { hole_name, log_date, driller_id, log_url, updated_at } = borehole;
        holeLogsRotaryWithUniqueDates[hole_name] = holeLogsRotaryWithUniqueDates[hole_name] || {};

        // Find the user with the matching driller_id
        const driller = companyUsers.find(user => user.id === driller_id);
        const driller_name = driller ? `${driller.first_name} ${driller.last_name}` : 'Unknown'; // Concatenate first and last name

        // If there's no log for this date or the current log is newer, update it
        if (!holeLogsRotaryWithUniqueDates[hole_name][log_date] || holeLogsRotaryWithUniqueDates[hole_name][log_date].log_date < log_date) {
            holeLogsRotaryWithUniqueDates[hole_name][log_date] = { log_date, driller_name, log_url, updated_at };
        }
    });

    // Function to count holes with unique dates
    const countRTHolesWithUniqueDates = (holesWithUniqueDates:any) => {
        let count = 0;
        for (const hole_name in holesWithUniqueDates) {
            if (Object.hasOwnProperty.call(holesWithUniqueDates, hole_name)) {
                const uniqueDates = Object.keys(holesWithUniqueDates[hole_name]);
                count += uniqueDates.length;
            }
        }
        return count;
    };
    
    // Get the count of holes with unique dates
    const uniqueRTDateCount = countRTHolesWithUniqueDates(holeLogsRotaryWithUniqueDates);
    // console.log('Count of holes with unique dates:', uniqueRTDateCount);

    //..................................................................................
    //.TTTTTTTTTTTRRRRRRRRRR..RIIII....AAAAA.....LLLL.......PPPPPPPPP..PIIII.TTTTTTTTT..
    //.TTTTTTTTTTTRRRRRRRRRRR.RIIII....AAAAA.....LLLL.......PPPPPPPPPP.PIIII.TTTTTTTTT..
    //.TTTTTTTTTTTRRRRRRRRRRR.RIIII...AAAAAA.....LLLL.......PPPPPPPPPPPPIIII.TTTTTTTTT..
    //....TTTT....RRRR...RRRRRRIIII...AAAAAAA....LLLL.......PPPP...PPPPPIIII....TTTT....
    //....TTTT....RRRR...RRRRRRIIII..AAAAAAAA....LLLL.......PPPP...PPPPPIIII....TTTT....
    //....TTTT....RRRRRRRRRRR.RIIII..AAAAAAAA....LLLL.......PPPPPPPPPPPPIIII....TTTT....
    //....TTTT....RRRRRRRRRRR.RIIII..AAAA.AAAA...LLLL.......PPPPPPPPPP.PIIII....TTTT....
    //....TTTT....RRRRRRRR....RIIII.AAAAAAAAAA...LLLL.......PPPPPPPPP..PIIII....TTTT....
    //....TTTT....RRRR.RRRR...RIIII.AAAAAAAAAAA..LLLL.......PPPP.......PIIII....TTTT....
    //....TTTT....RRRR..RRRR..RIIII.AAAAAAAAAAA..LLLL.......PPPP.......PIIII....TTTT....
    //....TTTT....RRRR..RRRRR.RIIIIIAAA....AAAA..LLLLLLLLLL.PPPP.......PIIII....TTTT....
    //....TTTT....RRRR...RRRRRRIIIIIAAA.....AAAA.LLLLLLLLLL.PPPP.......PIIII....TTTT....
    //....TTTT....RRRR....RRRRRIIIIIAAA.....AAAA.LLLLLLLLLL.PPPP.......PIIII....TTTT....
    //..................................................................................

    // Create an object to store boreholes with unique dates
    const holeLogsTrialpitWithUniqueDates: BoreholesWithUniqueDates = {};
    // Iterate over each borehole in holeLogsCable
    holeLogsTrialpit.forEach(borehole => {
        const { hole_name, log_date, driller_id, log_url, updated_at } = borehole;
        holeLogsTrialpitWithUniqueDates[hole_name] = holeLogsTrialpitWithUniqueDates[hole_name] || {};

        // Find the user with the matching driller_id
        const driller = companyUsers.find(user => user.id === driller_id);
        const driller_name = driller ? `${driller.first_name} ${driller.last_name}` : 'Unknown'; // Concatenate first and last name

        // If there's no log for this date or the current log is newer, update it
        if (!holeLogsTrialpitWithUniqueDates[hole_name][log_date] || holeLogsTrialpitWithUniqueDates[hole_name][log_date].log_date < log_date) {
            holeLogsTrialpitWithUniqueDates[hole_name][log_date] = { log_date, driller_name, log_url, updated_at };
        }
    });

    // Function to count holes with unique dates
    const countTPHolesWithUniqueDates = (holesWithUniqueDates:any) => {
        let count = 0;
        for (const hole_name in holesWithUniqueDates) {
            if (Object.hasOwnProperty.call(holesWithUniqueDates, hole_name)) {
                const uniqueDates = Object.keys(holesWithUniqueDates[hole_name]);
                count += uniqueDates.length;
            }
        }
        return count;
    };
    
    // Get the count of holes with unique dates
    const uniqueTPDateCount = countTPHolesWithUniqueDates(holeLogsTrialpitWithUniqueDates);
    // console.log('Count of holes with unique dates:', uniqueTPDateCount);

    //...........................................................................................................................................................
    //.DDDDDDDDD...DYYY....YYYY.YNNN...NNNN.....AAAAA....AAMMMM...MMMMMMMIIII...CCCCCCC.........PPPPPPPPP...PRRRRRRRRR.....OOOOOOO.....OBBBBBBBBB...BEEEEEEEEEE..
    //.DDDDDDDDDD..DYYYY..YYYYY.YNNNN..NNNN.....AAAAA....AAMMMM...MMMMMMMIIII..CCCCCCCCC........PPPPPPPPPP..PRRRRRRRRRR...OOOOOOOOOO...OBBBBBBBBBB..BEEEEEEEEEE..
    //.DDDDDDDDDDD..YYYY..YYYY..YNNNN..NNNN....AAAAAA....AAMMMM...MMMMMMMIIII.CCCCCCCCCCC.......PPPPPPPPPPP.PRRRRRRRRRR..ROOOOOOOOOOO..OBBBBBBBBBB..BEEEEEEEEEE..
    //.DDDD...DDDD..YYYYYYYYY...YNNNNN.NNNN....AAAAAAA...AAMMMMM.MMMMMMMMIIII.CCCC...CCCCC......PPPP...PPPP.PRRR...RRRRR.ROOOO..OOOOO..OBBB...BBBB..BEEE.........
    //.DDDD....DDDD..YYYYYYYY...YNNNNN.NNNN...AAAAAAAA...AAMMMMM.MMMMMMMMIIIIICCC.....CCC.......PPPP...PPPP.PRRR...RRRRRRROOO....OOOOO.OBBB...BBBB..BEEE.........
    //.DDDD....DDDD...YYYYYY....YNNNNNNNNNN...AAAAAAAA...AAMMMMM.MMMMMMMMIIIIICCC...............PPPPPPPPPPP.PRRRRRRRRRR.RROO......OOOO.OBBBBBBBBBB..BEEEEEEEEE...
    //.DDDD....DDDD...YYYYYY....YNNNNNNNNNN...AAAA.AAAA..AAMMMMMMMMMMMMMMIIIIICCC...............PPPPPPPPPP..PRRRRRRRRRR.RROO......OOOO.OBBBBBBBBB...BEEEEEEEEE...
    //.DDDD....DDDD....YYYY.....YNNNNNNNNNN..NAAAAAAAAA..AAMMMMMMMMMMMMMMIIIIICCC...............PPPPPPPPP...PRRRRRRR....RROO......OOOO.OBBBBBBBBBB..BEEEEEEEEE...
    //.DDDD....DDDD....YYYY.....YNNNNNNNNNN..NAAAAAAAAAA.AAMMMMMMMMMMMMMMIIIIICCC.....CCC.......PPPP........PRRR.RRRR...RROOO....OOOOO.OBBB....BBBB.BEEE.........
    //.DDDD...DDDDD....YYYY.....YNNN.NNNNNN..NAAAAAAAAAA.AAMM.MMMMM.MMMMMIIII.CCCC...CCCCC......PPPP........PRRR..RRRR...ROOOO..OOOOO..OBBB....BBBB.BEEE.........
    //.DDDDDDDDDDD.....YYYY.....YNNN..NNNNN.NNAA....AAAA.AAMM.MMMMM.MMMMMIIII.CCCCCCCCCCC.......PPPP........PRRR..RRRRR..ROOOOOOOOOOO..OBBBBBBBBBBB.BEEEEEEEEEE..
    //.DDDDDDDDDD......YYYY.....YNNN..NNNNN.NNAA.....AAAAAAMM.MMMMM.MMMMMIIII..CCCCCCCCCC.......PPPP........PRRR...RRRRR..OOOOOOOOOO...OBBBBBBBBBB..BEEEEEEEEEE..
    //.DDDDDDDDD.......YYYY.....YNNN...NNNNNNNAA.....AAAAAAMM.MMMMM.MMMMMIIII...CCCCCCC.........PPPP........PRRR....RRRR....OOOOOO.....OBBBBBBBBB...BEEEEEEEEEE..
    //...........................................................................................................................................................

    // Create an object to store boreholes with unique dates
    const holeLogsDynamicProbeWithUniqueDates: BoreholesWithUniqueDates = {};
    // Iterate over each borehole in holeLogsCable
    holeLogsDynamicProbe.forEach(borehole => {
        const { hole_name, log_date, driller_id, log_url, updated_at } = borehole;
        holeLogsDynamicProbeWithUniqueDates[hole_name] = holeLogsDynamicProbeWithUniqueDates[hole_name] || {};

        // Find the user with the matching driller_id
        const driller = companyUsers.find(user => user.id === driller_id);
        const driller_name = driller ? `${driller.first_name} ${driller.last_name}` : 'Unknown'; // Concatenate first and last name

        // If there's no log for this date or the current log is newer, update it
        if (!holeLogsDynamicProbeWithUniqueDates[hole_name][log_date] || holeLogsDynamicProbeWithUniqueDates[hole_name][log_date].log_date < log_date) {
            holeLogsDynamicProbeWithUniqueDates[hole_name][log_date] = { log_date, driller_name, log_url, updated_at };
        }
    });

    // Function to count holes with unique dates
    const countDPHolesWithUniqueDates = (holesWithUniqueDates:any) => {
        let count = 0;
        for (const hole_name in holesWithUniqueDates) {
            if (Object.hasOwnProperty.call(holesWithUniqueDates, hole_name)) {
                const uniqueDates = Object.keys(holesWithUniqueDates[hole_name]);
                count += uniqueDates.length;
            }
        }
        return count;
    };
    
    // Get the count of holes with unique dates
    const uniqueDPDateCount = countDPHolesWithUniqueDates(holeLogsDynamicProbeWithUniqueDates);
    // console.log('Count of holes with unique dates:', uniqueDPDateCount);

    //........................................................................................................
    //.HHHH...HHHH..EEEEEEEEEEE....AAAAA.....ADDDDDDDD......... TTTTTTTTTTEEEEEEEEEEE..SSSSSSS....STTTTTTTTT..
    //.HHHH...HHHH..EEEEEEEEEEE....AAAAA.....ADDDDDDDDD........ TTTTTTTTTTEEEEEEEEEEE.ESSSSSSSS...STTTTTTTTT..
    //.HHHH...HHHH..EEEEEEEEEEE...AAAAAA.....ADDDDDDDDDD....... TTTTTTTTTTEEEEEEEEEEE.ESSSSSSSSS..STTTTTTTTT..
    //.HHHH...HHHH..EEEE..........AAAAAAA....ADDD...DDDD..........TTTT....EEEE.......EESSS..SSSS.....TTTT.....
    //.HHHH...HHHH..EEEE.........AAAAAAAA....ADDD....DDDD.........TTTT....EEEE.......EESSS...........TTTT.....
    //.HHHHHHHHHHH..EEEEEEEEEE...AAAAAAAA....ADDD....DDDD.........TTTT....EEEEEEEEEE..ESSSSSS........TTTT.....
    //.HHHHHHHHHHH..EEEEEEEEEE...AAAA.AAAA...ADDD....DDDD.........TTTT....EEEEEEEEEE...SSSSSSSSS.....TTTT.....
    //.HHHHHHHHHHH..EEEEEEEEEE..EAAAAAAAAA...ADDD....DDDD.........TTTT....EEEEEEEEEE.....SSSSSSS.....TTTT.....
    //.HHHH...HHHH..EEEE........EAAAAAAAAAA..ADDD....DDDD.........TTTT....EEEE..............SSSSS....TTTT.....
    //.HHHH...HHHH..EEEE........EAAAAAAAAAA..ADDD...DDDDD.........TTTT....EEEE.......EESS....SSSS....TTTT.....
    //.HHHH...HHHH..EEEEEEEEEEEEEAA....AAAA..ADDDDDDDDDD..........TTTT....EEEEEEEEEEEEESSSSSSSSSS....TTTT.....
    //.HHHH...HHHH..EEEEEEEEEEEEEAA.....AAAA.ADDDDDDDDD...........TTTT....EEEEEEEEEEE.ESSSSSSSSS.....TTTT.....
    //.HHHH...HHHH..EEEEEEEEEEEEEAA.....AAAA.ADDDDDDDD............TTTT....EEEEEEEEEEE..SSSSSSSS......TTTT.....
    //........................................................................................................

    // Create an object to store boreholes with unique dates
    const holeLogsHeadTestWithUniqueDates: BoreholesWithUniqueDates = {};
    // Iterate over each borehole in holeLogsCable
    holeLogsHeadTest.forEach(borehole => {
        const { hole_name, log_date, driller_id, log_url, updated_at } = borehole;
        holeLogsHeadTestWithUniqueDates[hole_name] = holeLogsHeadTestWithUniqueDates[hole_name] || {};

        // Find the user with the matching driller_id
        const driller = companyUsers.find(user => user.id === driller_id);
        const driller_name = driller ? `${driller.first_name} ${driller.last_name}` : 'Unknown'; // Concatenate first and last name

        // If there's no log for this date or the current log is newer, update it
        if (!holeLogsHeadTestWithUniqueDates[hole_name][log_date] || holeLogsHeadTestWithUniqueDates[hole_name][log_date].log_date < log_date) {
            holeLogsHeadTestWithUniqueDates[hole_name][log_date] = { log_date, driller_name, log_url, updated_at };
        }
    });

    // Function to count holes with unique dates
    const countHTHolesWithUniqueDates = (holesWithUniqueDates:any) => {
        let count = 0;
        for (const hole_name in holesWithUniqueDates) {
            if (Object.hasOwnProperty.call(holesWithUniqueDates, hole_name)) {
                const uniqueDates = Object.keys(holesWithUniqueDates[hole_name]);
                count += uniqueDates.length;
            }
        }
        return count;
    };
    
    // Get the count of holes with unique dates
    const uniqueHTDateCount = countHTHolesWithUniqueDates(holeLogsHeadTestWithUniqueDates);
    // console.log('Count of holes with unique dates:', uniqueHTDateCount);

    //..........................................................................................................
    //...SSSSSSS......OOOOOOO........AAAAA.....KKKK...KKKKK....AAAAA..AAAWW..WWWWW...WWWW..AAAAA..AAAA....YYYY..
    //..SSSSSSSSS....OOOOOOOOOO......AAAAA.....KKKK..KKKKK.....AAAAA...AAWW..WWWWW..WWWW...AAAAA..AAAAY...YYYY..
    //..SSSSSSSSSS..OOOOOOOOOOOO....AAAAAA.....KKKK.KKKKK.....AAAAAA...AAWW..WWWWWW.WWWW..AAAAAA...AAAYY.YYYY...
    //.SSSSS..SSSS..OOOOO..OOOOO....AAAAAAA....KKKKKKKKK......AAAAAAA..AAWW.WWWWWWW.WWWW..AAAAAAA...AAYY.YYYY...
    //.SSSSS.......SOOOO....OOOOO..AAAAAAAA....KKKKKKKK......AAAAAAAA..AAWW.WWWWWWW.WWWW.AAAAAAAA...AAYYYYYY....
    //..SSSSSSS....SOOO......OOOO..AAAAAAAA....KKKKKKKK......AAAAAAAA...AWWWWWWWWWW.WWW..AAAAAAAA....AYYYYYY....
    //...SSSSSSSSS.SOOO......OOOO..AAAA.AAAA...KKKKKKKK......AAAA.AAAA..AWWWWWW.WWWWWWW..AAAA.AAAA....YYYYY.....
    //.....SSSSSSS.SOOO......OOOO.AAAAAAAAAA...KKKKKKKKK....AAAAAAAAAA..AWWWWWW.WWWWWWW.WAAAAAAAAA....YYYY......
    //........SSSSSSOOOO....OOOOO.AAAAAAAAAAA..KKKK.KKKKK...AAAAAAAAAAA.AWWWWWW.WWWWWWW.WAAAAAAAAAA...YYYY......
    //.SSSS....SSSS.OOOOO..OOOOO..AAAAAAAAAAA..KKKK..KKKK...AAAAAAAAAAA.AWWWWWW.WWWWWWW.WAAAAAAAAAA...YYYY......
    //.SSSSSSSSSSSS.OOOOOOOOOOOO.OAAA....AAAA..KKKK..KKKKK.KAAA....AAAA..WWWWW...WWWWW.WWAA....AAAA...YYYY......
    //..SSSSSSSSSS...OOOOOOOOOO..OAAA.....AAAA.KKKK...KKKKKKAAA.....AAAA.WWWWW...WWWWW.WWAA.....AAAA..YYYY......
    //...SSSSSSSS......OOOOOO...OOAAA.....AAAA.KKKK...KKKKKKAAA.....AAAA.WWWWW...WWWWWWWWAA.....AAAA..YYYY......
    //..........................................................................................................

    // Create an object to store boreholes with unique dates
    const holeLogsSoakawayWithUniqueDates: BoreholesWithUniqueDates = {};
    // Iterate over each borehole in holeLogsCable
    holeLogsSoakaway.forEach(borehole => {
        const { hole_name, log_date, driller_id, log_url, updated_at } = borehole;
        holeLogsSoakawayWithUniqueDates[hole_name] = holeLogsSoakawayWithUniqueDates[hole_name] || {};

        // Find the user with the matching driller_id
        const driller = companyUsers.find(user => user.id === driller_id);
        const driller_name = driller ? `${driller.first_name} ${driller.last_name}` : 'Unknown'; // Concatenate first and last name

        // If there's no log for this date or the current log is newer, update it
        if (!holeLogsSoakawayWithUniqueDates[hole_name][log_date] || holeLogsSoakawayWithUniqueDates[hole_name][log_date].log_date < log_date) {
            holeLogsSoakawayWithUniqueDates[hole_name][log_date] = { log_date, driller_name, log_url, updated_at };
        }
    });

    // Function to count holes with unique dates
    const countSAHolesWithUniqueDates = (holesWithUniqueDates:any) => {
        let count = 0;
        for (const hole_name in holesWithUniqueDates) {
            if (Object.hasOwnProperty.call(holesWithUniqueDates, hole_name)) {
                const uniqueDates = Object.keys(holesWithUniqueDates[hole_name]);
                count += uniqueDates.length;
            }
        }
        return count;
    };
    
    // Get the count of holes with unique dates
    const uniqueSADateCount = countSAHolesWithUniqueDates(holeLogsSoakawayWithUniqueDates);
    // console.log('Count of holes with unique dates:', uniqueSADateCount);

    // console.log('Project Logs:', holeLogs);
    // console.log('Project Logs Cable:', holeLogsCable);
    // console.log('Project Logs Rotary:', holeLogsRotary);
    // console.log('Project Logs Window Sample:', holeLogsWindowSample);
    // console.log('Project Logs Trialpit:', holeLogsTrialpit);
    // console.log('Project Logs DynamicProbe:', holeLogsDynamicProbe);
    // console.log('Project Logs HeadTest:', holeLogsHeadTest);
    // console.log('Project Logs Soakaway:', holeLogsSoakaway);
    // console.log('Plant Checks - Cable:', plantChecksCable);
    // console.log('Plant Checks - Rotary:', plantChecksRotary);
    console.log('Plant Checks - Window Sample:', plantChecksWindowSample);
    // console.log('Risk Assessments:', riskAssessments);
    // console.log('Company Users:', companyUsers);
    // console.log('company_id:', company_id);


    // DOWNLOAD FILES



    const handleView = (url: string, filename: string) => {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank'; // Opens the link in a new tab
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    const handleDownload = async (url: string, filename: string) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Download failed:', error);
        }
    };


    return (

        <>

            <Modal title="Log Manager" subtitle="" toggleModal={onClose} confirm={false} >
                <>

                    <div className="p-1 w-full md:w-11/12 justify-center mx-auto mb-2 text-sm md:text-base">

                        <div className="text-blue text-sm font-bold text-center">
                            This page requires an Internet connection to work properly!
                        </div>

                        <div className="text-lg md:text-2xl font-bold text-center mb-2">{project_location} - {project_id}</div>


                        <div>

                            <div className="flex p-0 mb-4 w-full sm:w-5/6 mx-auto text-center font-bold text-base md:text-lg">
                                <div className={`w-1/2`}
                                    onClick={() => setShowPlantChecks(false)}>
                                        <div className={`rounded py-1 mr-1 border ${!showPlantChecks ? 'bg-orange text-white border-orange' : 'bg-sptTB text-charcoal border-grey-mid'}`}>
                                            Logs
                                        </div>
                                </div>
                                <div className={`w-1/2`}
                                    onClick={() => setShowPlantChecks(true)}>
                                        <div className={`rounded py-1 ml-1 border ${showPlantChecks ? 'bg-orange text-white border-orange' : 'bg-sptTB text-charcoal border-grey-mid'}`}>
                                            Plant Checks
                                        </div>
                                </div>
                            </div>

                            {showPlantChecks ? (
                                <div>
                                    <div className="flex flex-wrap p-0 mb-4 w-full sm:w-5/6 mx-auto text-center font-bold justify-center items-center">
                                        <div className={`relative rounded w-1/4 text-white py-1 px-3 m-1 border border-grey-mid flex justify-center items-center ${showPlantChecksCable ? 'bg-blue text-white' : 'bg-sptTB text-charcoal'}`}
                                            onClick={() => {
                                                setShowPlantChecksCable(true)
                                                setShowPlantChecksRotary(false)
                                                setShowPlantChecksWindowSample(false)
                                            }}>
                                            <div className="font-bold">
                                                Cable
                                            </div>
                                            {plantChecksCable && plantChecksCable.length > 0 &&  
                                                <div className="absolute top-0 right-0 transform translate-x-1/3 -translate-y-1/3 bg-grey-dark text-white rounded-full py-0 px-1.5" style={{fontSize: '0.60rem', lineHeight: '1.15rem'}}>
                                                    {plantChecksCable.length}
                                                </div>
                                            }
                                        </div>
                                        <div className={`relative rounded w-1/4 text-white py-1 px-3 m-1 border border-grey-mid flex justify-center items-center ${showPlantChecksRotary ? 'bg-blue text-white' : 'bg-sptTB text-charcoal'}`}
                                            onClick={() => {
                                                setShowPlantChecksCable(false)
                                                setShowPlantChecksRotary(true)
                                                setShowPlantChecksWindowSample(false)
                                            }}>
                                            <div className="font-bold">
                                                Rotary
                                            </div>
                                            {plantChecksRotary && plantChecksRotary.length > 0 &&  
                                                <div className="absolute top-0 right-0 transform translate-x-1/3 -translate-y-1/3 bg-grey-dark text-white rounded-full py-0 px-1.5" style={{fontSize: '0.60rem', lineHeight: '1.15rem'}}>
                                                    {plantChecksRotary.length}
                                                </div>
                                            }
                                        </div>
                                        <div className={`relative rounded w-1/4 text-white py-1 px-3 m-1 border border-grey-mid flex justify-center items-center ${showPlantChecksWindowSample ? 'bg-blue text-white' : 'bg-sptTB text-charcoal'}`}
                                            onClick={() => {
                                                setShowPlantChecksCable(false)
                                                setShowPlantChecksRotary(false)
                                                setShowPlantChecksWindowSample(true)
                                            }}>
                                            <div className="font-bold">
                                                Window Sample
                                            </div>
                                            {plantChecksWindowSample && plantChecksWindowSample.length > 0 &&  
                                                <div className="absolute top-0 right-0 transform translate-x-1/3 -translate-y-1/3 bg-grey-dark text-white rounded-full py-0 px-1.5" style={{fontSize: '0.60rem', lineHeight: '1.15rem'}}>
                                                    {plantChecksWindowSample.length > 0 ? 1 : 0}
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    {/* Plant Checks Logs content goes here */}
                                    <div>
                                        <>
                                            {showPlantChecksCable && (
                                                <>
                                                    <div className={`justify-center w-full border border-grey-mid rounded bg-sptTB mb-2 p-2`}>
                                                        <div className="text-lg font-bold text-center">Cable Plant Checks</div>
                                                            <div className={`flex flex-wrap justify-center w-full`}>
                                                            {plantChecksCable
                                                                .sort((a:any, b:any) => new Date(b.plant_check_date).getTime() - new Date(a.plant_check_date).getTime())  // Sorting by date, newest first
                                                                .map((plantCheck, index) => (
                                                                    <div className="bg-sptSB m-1 py-1.5 px-1.5 rounded-lg border border-grey-mid">
                                                                    <div onClick={() => {
                                                                        handleDownload(`https://logs.drillitlogs.com/${company_id}/plant-checks/${plantCheck.plantcheck_url}`, `${plantCheck.plantcheck_url}`)
                                                                        console.log('plantCheck.plantcheck_url:', (plantCheck.plantcheck_url))
                                                                    }} 
                                                                    key={index} className="text-charcoal flex flex-col text-center justify-center items-center bg-white border-2 border-blue px-2 md:px-3 py-0 rounded m-0.5" >
                                                                        <div className="font-bold text-lg">{plantCheck.plant_name}</div>
                                                                        {/* <div className="text-xs text-center text-charcoal font-bold">{logDate} - <span className="text-xs text-center text-blue">{log.driller_name}</span></div> */}
                                                                        <div className="text-sm text-center text-charcoal font-bold">{plantCheck.plant_check_date}</div>
                                                                        <div className="text-xs text-center text-blue">{plantCheck.driller_name}</div>
                                                                    </div>
                                                                    <div className={`flex flex-wrap justify-center w-full py-1`}>
                                                                        <div onClick={() => handleDownload(`https://logs.drillitlogs.com/${company_id}/plant-checks/${plantCheck.plantcheck_url}`, `${plantCheck.plantcheck_url}`)} 
                                                                            className="text-xs text-center text-blue font-bold">
                                                                                download
                                                                        </div>
                                                                        <div className="text-xs text-center text-charcoal px-2">
                                                                                |
                                                                        </div>
                                                                        <div onClick={() => handleView(`https://logs.drillitlogs.com/${company_id}/plant-checks/${plantCheck.plantcheck_url}`, `${plantCheck.plantcheck_url}`)} 
                                                                            className="text-xs text-center text-blue font-bold">
                                                                                view
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                            ))}
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            {showPlantChecksRotary && (
                                                <>
                                                    <div className={`justify-center w-full border border-grey-mid rounded bg-sptTB mb-2 p-2`}>
                                                        <div className="text-lg font-bold text-center">Rotary Plant Checks</div>
                                                            <div className={`flex flex-wrap justify-center w-full`}>
                                                            {plantChecksRotary
                                                                .sort((a:any, b:any) => new Date(b.plant_check_date).getTime() - new Date(a.plant_check_date).getTime())  // Sorting by date, newest first
                                                                .map((plantCheck, index) => (
                                                                    <div className="bg-sptSB m-1 py-1.5 px-1.5 rounded-lg border border-grey-mid">
                                                                    <div onClick={() => {
                                                                        handleDownload(`https://logs.drillitlogs.com/${company_id}/plant-checks/${plantCheck.plantcheck_url}`, `${plantCheck.plantcheck_url}`)
                                                                        console.log('plantCheck.plantcheck_url:', (plantCheck.plantcheck_url))
                                                                    }} 
                                                                    key={index} className="text-charcoal flex flex-col text-center justify-center items-center bg-white border-2 border-blue px-2 md:px-3 py-0 rounded m-0.5" >
                                                                        <div className="font-bold text-lg">{plantCheck.plant_name}</div>
                                                                        {/* <div className="text-xs text-center text-charcoal font-bold">{logDate} - <span className="text-xs text-center text-blue">{log.driller_name}</span></div> */}
                                                                        <div className="text-sm text-center text-charcoal font-bold">{plantCheck.plant_check_date}</div>
                                                                        <div className="text-xs text-center text-blue">{plantCheck.driller_name}</div>
                                                                    </div>
                                                                    <div className={`flex flex-wrap justify-center w-full py-1`}>
                                                                        <div onClick={() => handleDownload(`https://logs.drillitlogs.com/${company_id}/plant-checks/${plantCheck.plantcheck_url}`, `${plantCheck.plantcheck_url}`)} 
                                                                            className="text-xs text-center text-blue font-bold">
                                                                                download
                                                                        </div>
                                                                        <div className="text-xs text-center text-charcoal px-2">
                                                                                |
                                                                        </div>
                                                                        <div onClick={() => handleView(`https://logs.drillitlogs.com/${company_id}/plant-checks/${plantCheck.plantcheck_url}`, `${plantCheck.plantcheck_url}`)} 
                                                                            className="text-xs text-center text-blue font-bold">
                                                                                view
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                            ))}
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            {showPlantChecksWindowSample && (
                                                <>
                                                    <div className={`justify-center w-full border border-grey-mid rounded bg-sptTB mb-2 p-2`}>
                                                        <div className="text-lg font-bold text-center">Window Sample Plant Checks</div>
                                                            <div className={`flex flex-wrap justify-center w-full`}>
                                                                {plantChecksWindowSample
                                                                .sort((a:any, b:any) => new Date(b.plant_check_date).getTime() - new Date(a.plant_check_date).getTime())  // Sorting by date, newest first
                                                                .map((plantCheck, index) => (
                                                                    <div className="bg-sptSB m-1 py-1.5 px-1.5 rounded-lg border border-grey-mid">
                                                                        <div onClick={() => {
                                                                            handleDownload(`https://logs.drillitlogs.com/${company_id}/plant-checks/${plantCheck.plantcheck_url}`, `${plantCheck.plantcheck_url}`)
                                                                            console.log('plantCheck.plantcheck_url:', (plantCheck.plantcheck_url))
                                                                        }} 
                                                                        key={index} className="text-charcoal flex flex-col text-center justify-center items-center bg-white border-2 border-blue px-2 md:px-3 py-0 rounded m-0.5" >
                                                                            <div className="font-bold text-lg">{plantCheck.plant_name}</div>
                                                                            {/* <div className="text-xs text-center text-charcoal font-bold">{logDate} - <span className="text-xs text-center text-blue">{log.driller_name}</span></div> */}
                                                                            <div className="text-sm text-center text-charcoal font-bold">{plantCheck.plant_check_date}</div>
                                                                            <div className="text-xs text-center text-blue">{plantCheck.driller_name}</div>
                                                                        </div>
                                                                        <div className={`flex flex-wrap justify-center w-full py-1`}>
                                                                            <div onClick={() => handleDownload(`https://logs.drillitlogs.com/${company_id}/plant-checks/${plantCheck.plantcheck_url}`, `${plantCheck.plantcheck_url}`)} 
                                                                                className="text-xs text-center text-blue font-bold">
                                                                                    download
                                                                            </div>
                                                                            <div className="text-xs text-center text-charcoal px-2">
                                                                                    |
                                                                            </div>
                                                                            <div onClick={() => handleView(`https://logs.drillitlogs.com/${company_id}/plant-checks/${plantCheck.plantcheck_url}`, `${plantCheck.plantcheck_url}`)} 
                                                                                className="text-xs text-center text-blue font-bold">
                                                                                    view
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                ))}
                                                            </div>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    </div>
                                </div>



                            ) : (



                                <div>
                                    {/* Normal Logs content goes here */}

                                    <div className={`flex flex-wrap justify-center w-full mb-2`}>

                                        <div className={`relative rounded text-white py-1 px-3 m-1 border flex justify-center items-center ${showLogs_AGS ? 'bg-blue text-white border-blue' : 'bg-sptTB text-charcoal border-grey-mid'}`}
                                            onClick={() => {
                                                setShowLogs_AGS(true)
                                                setShowLogs_Cable(false)
                                                setShowLogs_Rotary(false)
                                                setShowLogs_WindowSample(false)
                                                setShowLogs_DynamicProbe(false)
                                                setShowLogs_Trialpit(false)
                                                setShowLogs_HeadTest(false)
                                                setShowLogs_Soakaway(false)
                                                setShowLogs_RiskAssessments(false)
                                            }}>
                                            <div className="font-bold">
                                                AGS
                                            </div>
                                            {holeLogs && holeLogs.length > 0 &&  
                                                <div className="absolute top-0 right-0 transform translate-x-1/3 -translate-y-1/3 bg-grey-dark text-white rounded-full py-0 px-1.5" style={{fontSize: '0.60rem', lineHeight: '1.15rem'}}>
                                                    {holeLogs && holeLogs.length > 0 ? 1 : 0}
                                                </div>
                                            }
                                        </div>

                                        <div className={`relative rounded text-white py-1 px-3 m-1 border flex justify-center items-center ${showLogs_Cable ? 'bg-blue text-white border-blue' : 'bg-sptTB text-charcoal border-grey-mid'}`}
                                            onClick={() => {
                                                setShowLogs_AGS(false);
                                                setShowLogs_Cable(true);
                                                setShowLogs_Rotary(false);
                                                setShowLogs_WindowSample(false);
                                                setShowLogs_DynamicProbe(false);
                                                setShowLogs_Trialpit(false);
                                                setShowLogs_HeadTest(false);
                                                setShowLogs_Soakaway(false);
                                                setShowLogs_RiskAssessments(false);
                                            }}
                                        >
                                            <div className="font-bold">
                                                Cable
                                            </div>
                                            {uniqueCPDateCount !== 0 &&  
                                                <div className="absolute top-0 right-0 transform translate-x-1/3 -translate-y-1/3 bg-grey-dark text-white rounded-full py-0 px-1.5" style={{fontSize: '0.60rem', lineHeight: '1.15rem'}}>
                                                    {uniqueCPDateCount}
                                                </div>
                                            }
                                        </div>

                                        <div className={`relative rounded text-white py-1 px-3 m-1 border flex justify-center items-center ${showLogs_Rotary ? 'bg-blue text-white border-blue' : 'bg-sptTB text-charcoal border-grey-mid'}`}
                                            onClick={() => {
                                                setShowLogs_AGS(false)
                                                setShowLogs_Cable(false)
                                                setShowLogs_Rotary(true)
                                                setShowLogs_WindowSample(false)
                                                setShowLogs_DynamicProbe(false)
                                                setShowLogs_Trialpit(false)
                                                setShowLogs_HeadTest(false)
                                                setShowLogs_Soakaway(false)
                                                setShowLogs_RiskAssessments(false)
                                            }}>
                                            <div className="font-bold">
                                                Rotary
                                            </div>
                                            {uniqueRTDateCount !== 0 &&  
                                                <div className="absolute top-0 right-0 transform translate-x-1/3 -translate-y-1/3 bg-grey-dark text-white rounded-full py-0 px-1.5" style={{fontSize: '0.60rem', lineHeight: '1.15rem'}}>
                                                    {uniqueRTDateCount}
                                                </div>
                                            }
                                        </div>

                                        <div className={`relative rounded text-white py-1 px-3 m-1 border flex justify-center items-center ${showLogs_WindowSample ? 'bg-blue text-white border-blue' : 'bg-sptTB text-charcoal border-grey-mid'}`}
                                            onClick={() => {
                                                setShowLogs_AGS(false)
                                                setShowLogs_Cable(false)
                                                setShowLogs_Rotary(false)
                                                setShowLogs_WindowSample(true)
                                                setShowLogs_DynamicProbe(false)
                                                setShowLogs_Trialpit(false)
                                                setShowLogs_HeadTest(false)
                                                setShowLogs_Soakaway(false)
                                                setShowLogs_RiskAssessments(false)
                                            }}>
                                            <div className="font-bold">
                                                Window Sample
                                            </div>
                                            {uniqueWSDateCount !== 0 &&  
                                                <div className="absolute top-0 right-0 transform translate-x-1/3 -translate-y-1/3 bg-grey-dark text-white rounded-full py-0 px-1.5" style={{fontSize: '0.60rem', lineHeight: '1.15rem'}}>
                                                    {uniqueWSDateCount}
                                                </div>
                                            }
                                        </div>

                                        <div className={`relative rounded text-white py-1 px-3 m-1 border flex justify-center items-center ${showLogs_DynamicProbe ? 'bg-blue text-white border-blue' : 'bg-sptTB text-charcoal border-grey-mid'}`}
                                            onClick={() => {
                                                setShowLogs_AGS(false)
                                                setShowLogs_Cable(false)
                                                setShowLogs_Rotary(false)
                                                setShowLogs_WindowSample(false)
                                                setShowLogs_DynamicProbe(true)
                                                setShowLogs_Trialpit(false)
                                                setShowLogs_HeadTest(false)
                                                setShowLogs_Soakaway(false)
                                                setShowLogs_RiskAssessments(false)
                                            }}>
                                            <div className="font-bold">
                                                Dynamic Probe
                                            </div>
                                            {uniqueDPDateCount !== 0 &&  
                                                <div className="absolute top-0 right-0 transform translate-x-1/3 -translate-y-1/3 bg-grey-dark text-white rounded-full py-0 px-1.5" style={{fontSize: '0.60rem', lineHeight: '1.15rem'}}>
                                                    {uniqueDPDateCount}
                                                </div>
                                            }
                                        </div>

                                        <div className={`relative rounded text-white py-1 px-3 m-1 border flex justify-center items-center ${showLogs_Trialpit ? 'bg-blue text-white border-blue' : 'bg-sptTB text-charcoal border-grey-mid'}`}
                                            onClick={() => {
                                                setShowLogs_AGS(false)
                                                setShowLogs_Cable(false)
                                                setShowLogs_Rotary(false)
                                                setShowLogs_WindowSample(false)
                                                setShowLogs_DynamicProbe(false)
                                                setShowLogs_Trialpit(true)
                                                setShowLogs_HeadTest(false)
                                                setShowLogs_Soakaway(false)
                                                setShowLogs_RiskAssessments(false)
                                            }}>
                                            <div className="font-bold">
                                                Trialpit
                                            </div>
                                            {uniqueTPDateCount !== 0 &&  
                                                <div className="absolute top-0 right-0 transform translate-x-1/3 -translate-y-1/3 bg-grey-dark text-white rounded-full py-0 px-1.5" style={{fontSize: '0.60rem', lineHeight: '1.15rem'}}>
                                                    {uniqueTPDateCount}
                                                </div>
                                            }
                                        </div>

                                        <div className={`relative rounded text-white py-1 px-3 m-1 border flex justify-center items-center ${showLogs_HeadTest ? 'bg-blue text-white border-blue' : 'bg-sptTB text-charcoal border-grey-mid'}`}
                                            onClick={() => {
                                                setShowLogs_AGS(false)
                                                setShowLogs_Cable(false)
                                                setShowLogs_Rotary(false)
                                                setShowLogs_WindowSample(false)
                                                setShowLogs_DynamicProbe(false)
                                                setShowLogs_Trialpit(false)
                                                setShowLogs_HeadTest(true)
                                                setShowLogs_Soakaway(false)
                                                setShowLogs_RiskAssessments(false)
                                            }}>
                                            <div className="font-bold">
                                                Head Test
                                            </div>
                                            {uniqueHTDateCount !== 0 && 
                                                <div className="absolute top-0 right-0 transform translate-x-1/3 -translate-y-1/3 bg-grey-dark text-white rounded-full py-0 px-1.5" style={{fontSize: '0.60rem', lineHeight: '1.15rem'}}>
                                                    {uniqueHTDateCount}
                                                </div>
                                            }
                                        </div>

                                        <div className={`relative rounded text-white py-1 px-3 m-1 border flex justify-center items-center ${showLogs_Soakaway ? 'bg-blue text-white border-blue' : 'bg-sptTB text-charcoal border-grey-mid'}`}
                                            onClick={() => {
                                                setShowLogs_AGS(false)
                                                setShowLogs_Cable(false)
                                                setShowLogs_Rotary(false)
                                                setShowLogs_WindowSample(false)
                                                setShowLogs_DynamicProbe(false)
                                                setShowLogs_Trialpit(false)
                                                setShowLogs_HeadTest(false)
                                                setShowLogs_Soakaway(true)
                                                setShowLogs_RiskAssessments(false)
                                            }}>
                                            <div className="font-bold">
                                                Soakaway
                                            </div>
                                            {uniqueSADateCount !== 0 &&  
                                                <div className="absolute top-0 right-0 transform translate-x-1/3 -translate-y-1/3 bg-grey-dark text-white rounded-full py-0 px-1.5" style={{fontSize: '0.60rem', lineHeight: '1.15rem'}}>
                                                    {uniqueSADateCount}
                                                </div>
                                            }
                                        </div>

                                        <div className={`relative rounded text-white py-1 px-3 m-1 border flex justify-center items-center ${showLogs_RiskAssessments ? 'bg-blue text-white border-blue' : 'bg-sptTB text-charcoal border-grey-mid'}`}
                                            onClick={() => {
                                                setShowLogs_AGS(false)
                                                setShowLogs_Cable(false)
                                                setShowLogs_Rotary(false)
                                                setShowLogs_WindowSample(false)
                                                setShowLogs_DynamicProbe(false)
                                                setShowLogs_Trialpit(false)
                                                setShowLogs_HeadTest(false)
                                                setShowLogs_Soakaway(false)
                                                setShowLogs_RiskAssessments(true)
                                            }}>
                                            <div className="font-bold">
                                                Risk Assessments
                                            </div>
                                            {riskAssessments.length !== 0 &&  
                                                <div className="absolute top-0 right-0 transform translate-x-1/3 -translate-y-1/3 bg-grey-dark text-white rounded-full py-0 px-1.5" style={{fontSize: '0.60rem', lineHeight: '1.15rem'}}>
                                                    {riskAssessments.length}
                                                </div>
                                            }
                                        </div>

                                    </div>

                                    
                                    {showLogs_AGS && 
                                        <>
                                            <div className="justify-center border border-grey-mid rounded bg-sptTB mb-2 pt-1 pb-2">
                                                <div className="text-lg font-bold text-center">AGS Data</div>

                                                {holeLogs && holeLogs.length > 0 ? (

                                                    <div className={`flex flex-wrap justify-center w-full`}>
                                                        <div className="bg-sptSB m-1 py-1.5 px-1.5 rounded-lg border border-grey-mid">
                                                            <div onClick={() => handleDownload(`https://logs.drillitlogs.com/${company_id}/ags/${agsTitle}.ags`, `${agsTitle}.ags`)} 
                                                                className={`text-charcoal flex flex-col text-center justify-center items-center bg-white border-2 border-orange px-2 md:px-3 py-1 rounded`} >
                                                                <div className="font-bold text-lg">AGS</div>
                                                                <div className="text-sm text-center text-charcoal font-bold">{agsTitle}</div>
                                                            </div>
                                                            <div className={`flex flex-wrap justify-center w-full pt-1`}>
                                                                <div onClick={() => handleDownload(`https://logs.drillitlogs.com/${company_id}/ags/${agsTitle}.ags`, `${agsTitle}.ags`)} 
                                                                    className="text-xs text-center text-blue font-bold">
                                                                        download
                                                                </div>
                                                                <div className="text-xs text-center text-charcoal px-2">
                                                                        |
                                                                </div>
                                                                <div onClick={() => handleView(`https://logs.drillitlogs.com/${company_id}/ags/${agsTitle}.ags`, `${agsTitle}.ags`)} 
                                                                    className="text-xs text-center text-blue font-bold">
                                                                        view
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                ) : (

                                                    <div className="text-sm md:text-base text-center py-2">No AGS available</div>

                                                )}

                                            </div>
                                            {/* <div className="w-full border-b border-charcoal mt-4 mb-4"></div> */}
                                        </>
                                    }

                                    {showLogs_Cable &&
                                        <>
                                            <div className="justify-center border border-grey-mid rounded bg-sptTB mb-2 pt-1 pb-2">
                                                <div className="text-lg font-bold text-center">Cable Percussive Logs</div>
                                                
                                                {holeLogsCable && holeLogsCable.length > 0 ? (

                                                    <>

                                                    <div className={`flex flex-wrap justify-center w-full`}>
                                                    {Object.entries(holeLogsCableWithUniqueDates).reverse().map(([holeName, logsByDate]) => (
                                                        Object.entries(logsByDate).reverse().map(([logDate, log]) => (
                                                                <div key={`${holeName}-${logDate}`} className="bg-sptSB m-1 py-1.5 px-1.5 rounded-lg border border-grey-mid">
                                                                    <div onClick={() => {
                                                                        handleDownload(`https://logs.drillitlogs.com/${company_id}/logs/${log.log_url}`, `${log.log_url}`);
                                                                        console.log('log.log_url:', log.log_url);
                                                                    }} 
                                                                    className="text-charcoal flex flex-col text-center justify-center items-center bg-white border-2 border-orange px-2 md:px-3 py-1 rounded m-0.5">
                                                                        <div className="font-bold text-lg">{holeName}</div>
                                                                        <div className="text-sm text-center text-charcoal font-bold">{logDate}</div>
                                                                        <div className="text-xs text-center text-blue">{log.driller_name}</div>
                                                                        <div className="text-xs text-center text-charcoal">Last Updated:</div>
                                                                        <div className="text-xs text-center text-charcoal">{formatDateTime(log.updated_at)}</div>
                                                                    </div>
                                                                    <div className="flex flex-wrap justify-center w-full py-1">
                                                                        <div onClick={() => handleDownload(`https://logs.drillitlogs.com/${company_id}/logs/${log.log_url}`, `${log.log_url}`)} 
                                                                            className="text-xs text-center text-blue font-bold">
                                                                                download
                                                                        </div>
                                                                        <div className="text-xs text-center text-charcoal px-2">
                                                                            |
                                                                        </div>
                                                                        <div onClick={() => handleView(`https://logs.drillitlogs.com/${company_id}/logs/${log.log_url}`, `${log.log_url}`)} 
                                                                            className="text-xs text-center text-blue font-bold">
                                                                                view
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                    ))}



                                                    </div>

                                                    </>

                                                ) : (

                                                    <div className="text-sm md:text-base text-center py-2">No Cable Percussive Logs available</div>

                                                )}

                                            </div>
                                            {/* <div className="w-full border-b border-charcoal mt-4 mb-4"></div> */}
                                        </>
                                    }

                                    {showLogs_Rotary &&
                                        <>
                                            <div className="justify-center border border-grey-mid rounded bg-sptTB mb-2 pt-1 pb-2">
                                                <div className="text-lg font-bold text-center">Rotary Logs</div>
                                                
                                                {holeLogsRotary && holeLogsRotary.length > 0 ? (

                                                    <>
                                                    <div className={`flex flex-wrap justify-center w-full`}>
                                                        {Object.entries(holeLogsRotaryWithUniqueDates).map(([holeName, logsByDate]) => (
                                                            Object.entries(logsByDate).map(([logDate, log]) => (
                                                                <div className="bg-sptSB m-1 py-1.5 px-1.5 rounded-lg border border-grey-mid">
                                                                        <div onClick={() => handleDownload(`https://logs.drillitlogs.com/${company_id}/logs/${log.log_url}`, `${log.log_url}`)} 
                                                                            key={`${holeName}-${logDate}`} className={`text-charcoal flex flex-col text-center justify-center items-center bg-white border-2 border-orange px-2 md:px-3 py-1 rounded m-0.5`} >
                                                                            <div className="font-bold text-lg">{holeName}</div>
                                                                            {/* <div className="text-xs text-center text-charcoal font-bold">{logDate} - <span className="text-xs text-center text-blue">{log.driller_name}</span></div> */}
                                                                            <div className="text-sm text-center text-charcoal font-bold">{logDate}</div>
                                                                            <div className="text-xs text-center text-blue">{log.driller_name}</div>
                                                                            <div className="text-xs text-center text-charcoal">Last Updated:</div>
                                                                            <div className="text-xs text-center text-charcoal">{formatDateTime(log.updated_at)}</div>
                                                                        </div>
                                                                        <div className={`flex flex-wrap justify-center w-full py-1`}>
                                                                            <div onClick={() => handleDownload(`https://logs.drillitlogs.com/${company_id}/logs/${log.log_url}`, `${log.log_url}`)} 
                                                                                className="text-xs text-center text-blue font-bold">
                                                                                    download
                                                                            </div>
                                                                            <div className="text-xs text-center text-charcoal px-2">
                                                                                    |
                                                                            </div>
                                                                            <div onClick={() => handleView(`https://logs.drillitlogs.com/${company_id}/logs/${log.log_url}`, `${log.log_url}`)} 
                                                                                className="text-xs text-center text-blue font-bold">
                                                                                    view
                                                                            </div>
                                                                        </div>
                                                                </div>

                                                            ))
                                                        ))}
                                                    </div>

                                                    </>

                                                ) : (

                                                <div className="text-sm md:text-base text-center py-2">No Cable Percussive Logs available</div>

                                                )}

                                                </div>
                                        </>
                                    }

                                    {showLogs_WindowSample &&
                                        <>
                                            <div className="justify-center border border-grey-mid rounded bg-sptTB mb-2 pt-1 pb-2">
                                                <div className="text-lg font-bold text-center">Window Sample Logs</div>
                                                
                                                {holeLogsWindowSample && holeLogsWindowSample.length > 0 ? (

                                                    <>
                                                    <div className={`flex flex-wrap justify-center w-full`}>
                                                        {Object.entries(holeLogsWindowSampleWithUniqueDates).map(([holeName, logsByDate]) => (
                                                            Object.entries(logsByDate).map(([logDate, log]) => (
                                                                <div className="bg-sptSB m-1 py-1.5 px-1.5 rounded-lg border border-grey-mid">
                                                                        <div onClick={() => handleDownload(`https://logs.drillitlogs.com/${company_id}/logs/${log.log_url}`, `${log.log_url}`)} 
                                                                            key={`${holeName}-${logDate}`} className={`text-charcoal flex flex-col text-center justify-center items-center bg-white border-2 border-orange px-2 md:px-3 py-1 rounded m-0.5`} >
                                                                            <div className="font-bold text-lg">{holeName}</div>
                                                                            {/* <div className="text-xs text-center text-charcoal font-bold">{logDate} - <span className="text-xs text-center text-blue">{log.driller_name}</span></div> */}
                                                                            <div className="text-sm text-center text-charcoal font-bold">{logDate}</div>
                                                                            <div className="text-xs text-center text-blue">{log.driller_name}</div>
                                                                            <div className="text-xs text-center text-charcoal">Last Updated:</div>
                                                                            <div className="text-xs text-center text-charcoal">{formatDateTime(log.updated_at)}</div>
                                                                        </div>
                                                                        <div className={`flex flex-wrap justify-center w-full py-1`}>
                                                                            <div onClick={() => handleDownload(`https://logs.drillitlogs.com/${company_id}/logs/${log.log_url}`, `${log.log_url}`)} 
                                                                                className="text-xs text-center text-blue font-bold">
                                                                                    download
                                                                            </div>
                                                                            <div className="text-xs text-center text-charcoal px-2">
                                                                                    |
                                                                            </div>
                                                                            <div onClick={() => handleView(`https://logs.drillitlogs.com/${company_id}/logs/${log.log_url}`, `${log.log_url}`)} 
                                                                                className="text-xs text-center text-blue font-bold">
                                                                                    view
                                                                            </div>
                                                                        </div>
                                                                </div>

                                                            ))
                                                        ))}
                                                    </div>

                                                    </>

                                            ) : (

                                                <div className="text-sm md:text-base text-center py-2">No Window Sample Logs available</div>

                                            )}

                                            </div>
                                        </>
                                    }

                                    {showLogs_Trialpit && 
                                        <>
                                            <div className="justify-center border border-grey-mid rounded bg-sptTB mb-2 pt-1 pb-2">
                                                <div className="text-lg font-bold text-center">Trialpit Logs</div>
                                                
                                                {holeLogsTrialpit && holeLogsTrialpit.length > 0 ? (

                                                    <>
                                                    <div className={`flex flex-wrap justify-center w-full`}>
                                                        {Object.entries(holeLogsTrialpitWithUniqueDates).map(([holeName, logsByDate]) => (
                                                            Object.entries(logsByDate).map(([logDate, log]) => (
                                                                <div className="bg-sptSB m-1 py-1.5 px-1.5 rounded-lg border border-grey-mid">
                                                                        <div onClick={() => handleDownload(`https://logs.drillitlogs.com/${company_id}/logs/${log.log_url}`, `${log.log_url}`)} 
                                                                            key={`${holeName}-${logDate}`} className={`text-charcoal flex flex-col text-center justify-center items-center bg-white border-2 border-orange px-2 md:px-3 py-1 rounded m-0.5`} >
                                                                            <div className="font-bold text-lg">{holeName}</div>
                                                                            {/* <div className="text-xs text-center text-charcoal font-bold">{logDate} - <span className="text-xs text-center text-blue">{log.driller_name}</span></div> */}
                                                                            <div className="text-sm text-center text-charcoal font-bold">{logDate}</div>
                                                                            <div className="text-xs text-center text-blue">{log.driller_name}</div>
                                                                            <div className="text-xs text-center text-charcoal">Last Updated:</div>
                                                                            <div className="text-xs text-center text-charcoal">{formatDateTime(log.updated_at)}</div>
                                                                        </div>
                                                                        <div className={`flex flex-wrap justify-center w-full py-1`}>
                                                                            <div onClick={() => handleDownload(`https://logs.drillitlogs.com/${company_id}/logs/${log.log_url}`, `${log.log_url}`)} 
                                                                                className="text-xs text-center text-blue font-bold">
                                                                                    download
                                                                            </div>
                                                                            <div className="text-xs text-center text-charcoal px-2">
                                                                                    |
                                                                            </div>
                                                                            <div onClick={() => handleView(`https://logs.drillitlogs.com/${company_id}/logs/${log.log_url}`, `${log.log_url}`)} 
                                                                                className="text-xs text-center text-blue font-bold">
                                                                                    view
                                                                            </div>
                                                                        </div>
                                                                </div>

                                                            ))
                                                        ))}
                                                    </div>

                                                    </>

                                                ) : (

                                                    <div className="text-sm md:text-base text-center py-2">No Trialpit Logs available</div>

                                                )}

                                            </div>
                                        </>
                                    }

                                    {showLogs_DynamicProbe && 
                                        <>
                                            <div className="justify-center border border-grey-mid rounded bg-sptTB mb-2 pt-1 pb-2">
                                                <div className="text-lg font-bold text-center">Dynamic Probe Logs</div>
                                                
                                                {holeLogsDynamicProbe && holeLogsDynamicProbe.length > 0 ? (

                                                    <>
                                                    <div className={`flex flex-wrap justify-center w-full`}>
                                                        {Object.entries(holeLogsDynamicProbeWithUniqueDates).map(([holeName, logsByDate]) => (
                                                            Object.entries(logsByDate).map(([logDate, log]) => (
                                                                <div className="bg-sptSB m-1 py-1.5 px-1.5 rounded-lg border border-grey-mid">
                                                                        <div onClick={() => handleDownload(`https://logs.drillitlogs.com/${company_id}/logs/${log.log_url}`, `${log.log_url}`)} 
                                                                            key={`${holeName}-${logDate}`} className={`text-charcoal flex flex-col text-center justify-center items-center bg-white border-2 border-orange px-2 md:px-3 py-1 rounded m-0.5`} >
                                                                            <div className="font-bold text-lg">{holeName}</div>
                                                                            {/* <div className="text-xs text-center text-charcoal font-bold">{logDate} - <span className="text-xs text-center text-blue">{log.driller_name}</span></div> */}
                                                                            <div className="text-sm text-center text-charcoal font-bold">{logDate}</div>
                                                                            <div className="text-xs text-center text-blue">{log.driller_name}</div>
                                                                            <div className="text-xs text-center text-charcoal">Last Updated:</div>
                                                                            <div className="text-xs text-center text-charcoal">{formatDateTime(log.updated_at)}</div>
                                                                        </div>
                                                                        <div className={`flex flex-wrap justify-center w-full py-1`}>
                                                                            <div onClick={() => handleDownload(`https://logs.drillitlogs.com/${company_id}/logs/${log.log_url}`, `${log.log_url}`)} 
                                                                                className="text-xs text-center text-blue font-bold">
                                                                                    download
                                                                            </div>
                                                                            <div className="text-xs text-center text-charcoal px-2">
                                                                                    |
                                                                            </div>
                                                                            <div onClick={() => handleView(`https://logs.drillitlogs.com/${company_id}/logs/${log.log_url}`, `${log.log_url}`)} 
                                                                                className="text-xs text-center text-blue font-bold">
                                                                                    view
                                                                            </div>
                                                                        </div>
                                                                </div>

                                                            ))
                                                        ))}
                                                    </div>

                                                    </>

                                                ) : (

                                                    <div className="text-sm md:text-base text-center py-2">No Dynamic Probe Logs available</div>

                                                )}

                                            </div>
                                        </>
                                    }
                                    
                                    {showLogs_HeadTest && 
                                        <>
                                            <div className="justify-center border border-grey-mid rounded bg-sptTB mb-2 pt-1 pb-2">
                                                <div className="text-lg font-bold text-center">Head Test Logs</div>

                                                {holeLogsHeadTest && holeLogsHeadTest.length > 0 ? (

                                                    <>
                                                    <div className={`flex flex-wrap justify-center w-full`}>
                                                        {Object.entries(holeLogsHeadTestWithUniqueDates).map(([holeName, logsByDate]) => (
                                                            Object.entries(logsByDate).map(([logDate, log]) => (
                                                                <div className="bg-sptSB m-1 py-1.5 px-1.5 rounded-lg border border-grey-mid">
                                                                        <div onClick={() => handleDownload(`https://logs.drillitlogs.com/${company_id}/logs/${log.log_url}`, `${log.log_url}`)} 
                                                                            key={`${holeName}-${logDate}`} className={`text-charcoal flex flex-col text-center justify-center items-center bg-white border-2 border-orange px-2 md:px-3 py-1 rounded m-0.5`} >
                                                                            <div className="font-bold text-lg">{holeName}</div>
                                                                            {/* <div className="text-xs text-center text-charcoal font-bold">{logDate} - <span className="text-xs text-center text-blue">{log.driller_name}</span></div> */}
                                                                            <div className="text-sm text-center text-charcoal font-bold">{logDate}</div>
                                                                            <div className="text-xs text-center text-blue">{log.driller_name}</div>
                                                                            <div className="text-xs text-center text-charcoal">Last Updated:</div>
                                                                            <div className="text-xs text-center text-charcoal">{formatDateTime(log.updated_at)}</div>
                                                                        </div>
                                                                        <div className={`flex flex-wrap justify-center w-full py-1`}>
                                                                            <div onClick={() => handleDownload(`https://logs.drillitlogs.com/${company_id}/logs/${log.log_url}`, `${log.log_url}`)} 
                                                                                className="text-xs text-center text-blue font-bold">
                                                                                    download
                                                                            </div>
                                                                            <div className="text-xs text-center text-charcoal px-2">
                                                                                    |
                                                                            </div>
                                                                            <div onClick={() => handleView(`https://logs.drillitlogs.com/${company_id}/logs/${log.log_url}`, `${log.log_url}`)} 
                                                                                className="text-xs text-center text-blue font-bold">
                                                                                    view
                                                                            </div>
                                                                        </div>
                                                                </div>

                                                            ))
                                                        ))}
                                                    </div>

                                                    </>

                                                ) : (

                                                    <div className="text-sm md:text-base text-center py-2">No Head Test Logs available</div>

                                                )}

                                            </div>
                                            
                                        </>
                                    }

                                    {showLogs_Soakaway && 
                                        <>
                                            <div className="justify-center border border-grey-mid rounded bg-sptTB mb-2 pt-1 pb-2">
                                                <div className="text-lg font-bold text-center">Soakaway Logs</div>
                                    
                                                {holeLogsSoakaway && holeLogsSoakaway.length > 0 ? (

                                                    <>
                                                    <div className={`flex flex-wrap justify-center w-full`}>
                                                        {Object.entries(holeLogsSoakawayWithUniqueDates).map(([holeName, logsByDate]) => (
                                                            Object.entries(logsByDate).sort(([logDateA], [logDateB]) => new Date(logDateB).getTime() - new Date(logDateA).getTime()) // Sort by logDate, newest first
                                                                .map(([logDate, log]) => (
                                                                <div className="bg-sptSB m-1 py-1.5 px-1.5 rounded-lg border border-grey-mid">
                                                                        <div onClick={() => handleDownload(`https://logs.drillitlogs.com/${company_id}/logs/${log.log_url}`, `${log.log_url}`)} 
                                                                            key={`${holeName}-${logDate}`} className={`text-charcoal flex flex-col text-center justify-center items-center bg-white border-2 border-orange px-2 md:px-3 py-1 rounded m-0.5`} >
                                                                            <div className="font-bold text-lg">{holeName}</div>
                                                                            {/* <div className="text-xs text-center text-charcoal font-bold">{logDate} - <span className="text-xs text-center text-blue">{log.driller_name}</span></div> */}
                                                                            <div className="text-sm text-center text-charcoal font-bold">{logDate}</div>
                                                                            <div className="text-xs text-center text-blue">{log.driller_name}</div>
                                                                            <div className="text-xs text-center text-charcoal">Last Updated:</div>
                                                                            <div className="text-xs text-center text-charcoal">{formatDateTime(log.updated_at)}</div>
                                                                        </div>
                                                                        <div className={`flex flex-wrap justify-center w-full py-1`}>
                                                                            <div onClick={() => handleDownload(`https://logs.drillitlogs.com/${company_id}/logs/${log.log_url}`, `${log.log_url}`)} 
                                                                                className="text-xs text-center text-blue font-bold">
                                                                                    download
                                                                            </div>
                                                                            <div className="text-xs text-center text-charcoal px-2">
                                                                                    |
                                                                            </div>
                                                                            <div onClick={() => handleView(`https://logs.drillitlogs.com/${company_id}/logs/${log.log_url}`, `${log.log_url}`)} 
                                                                                className="text-xs text-center text-blue font-bold">
                                                                                    view
                                                                            </div>
                                                                        </div>
                                                                </div>

                                                            ))
                                                        ))}
                                                    </div>

                                                    </>

                                                ) : (

                                                    <div className="text-sm md:text-base text-center py-2">No Soakaway Logs available</div>

                                                )}

                                            </div>
                                            
                                        </>
                                    }

                                    {showLogs_RiskAssessments && 
                                        <>
                                            <div className="justify-center border border-grey-mid rounded bg-sptTB mb-2 pt-1 pb-2">
                                                <div className="text-lg font-bold text-center">Risk Assessments</div>
                                                
                                                {riskAssessments && riskAssessments.length > 0 ? (

                                                    <div className={`flex flex-wrap justify-center w-full`}>

                                                        {riskAssessments
                                                            .sort((a, b) => new Date(b.risk_assessment_date).getTime() - new Date(a.risk_assessment_date).getTime())  // Sort by risk_assessment_date, newest first
                                                            .map((riskAssessment, index) => (
                                                                <div key={index} className={`text-charcoal flex flex-col text-center justify-center items-center px-2 md:px-3 py-0 rounded m-0.5`} >
                                                                    <div className={`flex flex-wrap justify-center w-full`}>
                                                                        <div className="bg-sptSB m-1 py-1.5 px-1.5 rounded-lg border border-grey-mid">
                                                                            <div onClick={() => handleDownload(`https://logs.drillitlogs.com/${company_id}/risk_assessments/${agsTitle}-${riskAssessment.driller_id}.pdf`, `${agsTitle}-${riskAssessment.driller_name.replace(/\s+/g, '')}.pdf`)} 
                                                                                className={`text-charcoal flex flex-col text-center justify-center items-center bg-white border-2 border-orange px-2 md:px-3 py-1 rounded`} >
                                                                                <div className="text-lg text-center text-charcoal font-bold">{riskAssessment.project_location} - {riskAssessment.project_id}</div>
                                                                                <div className="text-sm text-center text-charcoal font-bold">{riskAssessment.risk_assessment_date}</div>
                                                                                <div className="text-xs text-center text-blue">{riskAssessment.driller_name}</div>
                                                                                <div className="text-xs text-center text-charcoal">Last Updated:</div>
                                                                                <div className="text-xs text-center text-charcoal">{formatDateTime(riskAssessment.updated_at)}</div>
                                                                            </div>
                                                                            <div className={`flex flex-wrap justify-center w-full pt-1`}>
                                                                                <div onClick={() => handleDownload(`https://logs.drillitlogs.com/${company_id}/risk_assessments/${agsTitle}-${riskAssessment.driller_id}.pdf`, `${agsTitle}-${riskAssessment.driller_name.replace(/\s+/g, '')}.pdf`)} 
                                                                                    className="text-xs text-center text-blue font-bold">
                                                                                        download
                                                                                </div>
                                                                                <div className="text-xs text-center text-charcoal px-2">
                                                                                        |
                                                                                </div>
                                                                                <div onClick={() => handleView(`https://logs.drillitlogs.com/${company_id}/risk_assessments/${agsTitle}-${riskAssessment.driller_id}.pdf`, `${agsTitle}-${riskAssessment.driller_name.replace(/\s+/g, '')}.pdf`)} 
                                                                                    className="text-xs text-center text-blue font-bold">
                                                                                        view
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}

                                                    </div>

                                                ) : (

                                                    <div className="text-sm md:text-base text-center py-2">No AGS available</div>

                                                )}

                                            </div>
                                        </>
                                    }

                                </div>
                            )}
                        </div>


                    </div>
                </>

            </Modal >

        </>

    )
}

Logs.defaultProps = defaultProps

export default Logs
