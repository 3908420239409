import React, { FunctionComponent, useState, useEffect } from 'react'

// import WorkflowSteps from '../../WorkflowSteps'
import Modal from '../../Modal'
import Button from '../../form/Button'
// import NumberInput from '../../NumberInput'
import ButtonOptions from '../../form/ButtonOptions'
import ImageUploading from 'react-images-uploading'
import { ImageListType } from "react-images-uploading";
import Comment from './soil-description/Comment'

import { v4 as uuidv4 } from 'uuid';
// import stepStatus from '../../../helpers/stepStatus'
import warningIcon from '../../../assets/icons/warning.png'

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: string,
	defaultValues?: any,
	selectedDate?: string,
	update?: any,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => { },
	onComplete: () => { },
	borehole: '',
	defaultValues: {
		guid: '',
		comment: '',
	},
	selectedDate: '',
}

const Photo: FunctionComponent<Props> = ({ onClose, update, onComplete, borehole, defaultValues, selectedDate, setShowDrillCoreSizeRecordsEdit, ...rest }) => {

	const [guid, setGuid] = useState(defaultValues.guid)

	useEffect(() => {
		if (!guid) {
			setGuid(uuidv4());
		}
	}, [defaultValues.guid, guid]);

	const [photo, setPhoto] = useState(defaultValues.photo)
	const [photo_type, setPhotoType] = useState(defaultValues.photo_type)
	const [comment, setComment] = useState(defaultValues.comment)
	const [time_of_action, setTimeOfAction] = useState(defaultValues.time_of_action)

	const isoDate = selectedDate ? `${new Date(selectedDate).toISOString().split('T')[0]} 09:00:00` : new Date().toISOString().split('.')[0]

	

	// const maxNumberOfPhotos = 1
	const [images, setImages] = useState<ImageListType>([]);
	console.log('images:', images)
	const [showWebcamUI, setShowWebcamUI] = useState(false);
	// console.log('showWebcamUI:', showWebcamUI)
	// const [webcamStream, setWebcamStream] = useState<MediaStream | null>(null);
	// console.log(webcamStream)
	const onChange = (imageList: any, addUpdateIndex: any) => {
		setImages(imageList);
	};

	useEffect(() => {
		const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			navigator.userAgent
		);

		if (!isMobile) {
			setShowWebcamUI(true);
		}
	}, [])



	// Function to get image type from dataURL
	function getImageType(dataURL:any) {
		try {
		// Extract the base64 part of the dataURL
		const base64Part = dataURL.split(',')[1];
	
		// Convert base64 to binary
		const binary = atob(base64Part);
	
		// Read the first byte to determine the image type
		const byte = binary.charCodeAt(0);
		switch (byte) {
			case 0xFF:
			return 'image/jpeg';
			case 0x89:
			return 'image/png';
			case 0x47:
			return 'image/gif';
			case 0x42:
			return 'image/bmp';
			// Add more cases for other image types if needed
			default:
			return 'unknown';
		}
		} catch (error) {
		console.error('Error getting image type:', error);
		return 'unknown';
		}
	}

	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	const DeleteButton = () => {
		if (!update) return null;

		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	const save = () => {

		console.log('images on save:', images)

		if (!images) {
			//do nothing
		} else {

			// saveImageToIDB(guid, images)

			onComplete({
				guid: guid,
				comment_type: 'Photo',
				// photo: guid,
				start_time: '',
				duration: '',
				general_remarks: comment,
				photo_type: photo_type,
				time_of_action: time_of_action ? time_of_action : isoDate,
				update: update ? true : false
			})
		}
	}

	const openIDB = (): Promise<IDBDatabase> => {
		return new Promise((resolve, reject) => {
		  const request = indexedDB.open('drillit', 2); // Ensure the version matches the current schema
		  
		  request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
			const db = (event.target as IDBOpenDBRequest).result;
			console.log('Upgrading IndexedDB');
			if (!db.objectStoreNames.contains('images')) {
			  db.createObjectStore('images', { keyPath: 'guid' });
			  console.log('Object store "images" created');
			}
		  };
		  
		  request.onsuccess = (event: Event) => {
			const db = (event.target as IDBOpenDBRequest).result;
			console.log('IndexedDB opened successfully');
			resolve(db);
		  };
		  
		  request.onerror = (event: Event) => {
			console.error('Error opening IndexedDB', (event.target as IDBOpenDBRequest).error);
			reject(`Failed to open IndexedDB: ${(event.target as IDBOpenDBRequest).error?.message || 'Unknown error'}`);
		  };
		});
	  };
	  
	  
	  
	  

	  const saveImageToIDB = async (guid: string, imageDataURL: string): Promise<void> => {
		const db = await openIDB(); // Open your IndexedDB
		const tx = db.transaction('images', 'readwrite'); // Start the transaction
		const store = tx.objectStore('images');
	  
		store.put({ guid, image: imageDataURL }); // Save the image data
	  
		return new Promise((resolve, reject) => {
		  tx.oncomplete = () => {
			resolve(); // Resolve when transaction completes
		  };
		  tx.onerror = () => {
			reject(tx.error); // Reject if transaction fails
		  };
		});
	  };

	  const handleSaveImage = async () => {
		if (images.length > 0) {
		  const image = images[0]; // Extract the first image
		  const photo_guid = guid; // Generate or assign a GUID
		  const imageType = getImageType(image.dataURL); // Extract the image type
	  
		  console.log(`Saving image with GUID: ${photo_guid}, Type: ${imageType}`);
	  
		  // Save the image to IndexedDB
		  await saveImageToIDB(photo_guid, image.dataURL || '');
		  
		  // You can also store the photo type if needed
		  setPhotoType(imageType);

		  save() // save record

		} else {
		  console.log('No images to save');
		}
	  };
	  

	console.log('images:', images)

	const MissingRequiredField = () => {
		if (photo ===  '') {
			return (
				<div className="text-center bg-sptSB border border-red font-bold rounded mb-2 py-1 text-xs md:text-sm lg:text-base">
					<img src={warningIcon} alt="info" width="20" className="inline-block mr-1 pb-1" />
					Required field: 'Photo'
				</div>
			);
		}
	
		return null;
	};





	return (
		<Modal title="Photo" subtitle={borehole} toggleModal={onClose}>
			<div className="flex flex-col flex-grow px-1 sm:px-2 md:px-10">
				{/* <WorkflowSteps steps={[
					{
						label: 'Drill Core Size',
						status: stepStatus(1, 1),
					}
				]} className="mb-6" /> */}

				<div className="flex flex-col flex-grow border border-grey-mid rounded px-2 py-2 bg-sptTB mb-2">

					{/* <div className="text-xl text-center mb-2">
						Select Drill Core Size
					</div> */}

					<div className="mx-auto text-center text-charcoal text-base">

						<ImageUploading value={images} onChange={onChange} maxNumber={1}>
							{({
								imageList,
								onImageUpload,
								onImageRemoveAll,
								onImageUpdate,
								onImageRemove,
								isDragging,
								dragProps,
							}) => (
								// write your building UI
								<div className="mb-2">
									<button
										className="bg-blue rounded-lg px-4 py-2 mb-3 text-white"
										style={isDragging ? { color: "red" } : undefined}
										onClick={onImageUpload}
										{...dragProps}
									>
										Click here to add photo
									</button>

									{imageList.map((image, index) => {

										console.log(`Processing image ${index + 1}`);
										const imageType = getImageType(image.dataURL);
										console.log(`Image ${index + 1} type: ${imageType}`);
										setPhotoType(imageType)

										return (
										<div key={index} className="image-item">
											<img src={image.dataURL} alt="" className="mx-auto w-4/6" />
											<div className="image-item__btn-wrapper">
												<button
													className="mr-5 rounded-lg bg-grey-mid text-charcoal px-4 py-2 mt-2"
													onClick={() => onImageRemove(index)}
												>
													Remove
												</button>
												<button
													className="ml-5 rounded-lg bg-orange text-white px-4 py-2 mt-2"
													onClick={() => onImageUpdate(index)}
												>
													Update
												</button>
											</div>
										</div>
										);
									})}
									</div>
							)}
						</ImageUploading>

					</div>
					<Comment comment={comment} numberOfRows={2} setComment={setComment} title="Comments" />

				</div>

				<MissingRequiredField />


				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">


							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?

								<div className="flex mt-4">
									<Button theme="secondary" 
										className="mr-5 text-sm sm:text-base px-3.5 md:px-7" 
										onClick={() => setDeleteConfirmOpen(false)}>
											Cancel
									</Button>

									<Button onClick={() => {
												onClose()
												onComplete({
													guid: guid,
													update: false,
													delete: true,
												})
												setShowDrillCoreSizeRecordsEdit(false)
											}}
										className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">
										Delete
									</Button>
								</div>
							</div>


						</div>
					</div>
				}

				<div className="flex">

					{/* <Button theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button> */}
					<DeleteButton />
					<div className="flex ml-auto">
						<Button theme="success" size="lg"
							className="ml-4 px-8 sm:px-10 w-32 sm:w-60"
							// onClick={() => save()}>Save</Button>
							onClick={() => handleSaveImage()}>Save</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

Photo.defaultProps = defaultProps

export default Photo
