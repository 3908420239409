import { get, del } from 'idb-keyval';

export const usePostToApi = (keyValueEndpoint: string, postOrPut: string, update?: boolean) => {
    const newKeyValue = `drillit-${keyValueEndpoint}-new`;
    const updateKeyValue = `drillit-${keyValueEndpoint}-update`;

    const noV1ApiRoutes = ['login'];
    const apiPostAddr = noV1ApiRoutes.includes(keyValueEndpoint)
        ? `${process.env.REACT_APP_API_ADDR_NOV1}${keyValueEndpoint}`
        : `${process.env.REACT_APP_API_ADDR}${keyValueEndpoint}`;

    if (update) {
        return handleUpdate(updateKeyValue, apiPostAddr, postOrPut);
    }

    return handleNew(newKeyValue, apiPostAddr, postOrPut);
};

const handleUpdate = (updateKeyValue: string, apiPostAddr: string, postOrPut: string) => {
    return getIDB(updateKeyValue).then(result => {
        if (result) {
            let removedItemsArray = result;
            result.forEach(async (element: any) => {
                const elId = element.guid;
                const apiPutAddr = `${apiPostAddr}/${elId}`;
                try {
                    const postAttempt = await makeRequest(apiPutAddr, JSON.stringify(element), postOrPut);
                    if (postAttempt.status === 200) {
                        removedItemsArray = removedItemsArray.filter((item: any) => item.guid !== element.guid);
                    } else {
                        const exists = await checkFilesExist('hole-locations', element.guid);
                        if (exists) {
                            removedItemsArray = removedItemsArray.filter((item: any) => item.guid !== element.guid);
                        }
                    }
                } catch (error) {
                    console.error('Error in postAttempt:', error);
                }
                if (removedItemsArray.length === 0) {
                    await del(updateKeyValue);
                }
            });
        }
        return result;
    });
};

const handleNew = (newKeyValue: string, apiPostAddr: string, postOrPut: string) => {
    return getIDB(newKeyValue).then(result => {
        if (result) {
            let removedItemsArray = result;
            result.forEach(async (element: any) => {
                try {
                    const postAttempt = await makeRequest(apiPostAddr, JSON.stringify(element), postOrPut);
                    if (postAttempt.status === 200) {
                        removedItemsArray = removedItemsArray.filter((item: any) => item.guid !== element.guid);
                    } else {
                        const exists = await checkFilesExist('hole-locations', element.guid);
                        if (exists) {
                            removedItemsArray = removedItemsArray.filter((item: any) => item.guid !== element.guid);
                        }
                    }
                } catch (error) {
                    console.error('Error in postAttempt:', error);
                }
                if (removedItemsArray.length === 0) {
                    await del(newKeyValue);
                }
            });
        }
        return result;
    });
};

// Function to check if files exist on the server
const checkFilesExist = async (taskType: string, guid: string) => {
    const endpoint = `${process.env.REACT_APP_API_ADDR}action/${taskType}/${guid}/exists`;
    try {
        const response = await fetch(endpoint);
        const data = await response.json();
        console.log(`File existence for ${taskType} with guid ${guid}: ${data.exists}`);
        return data.exists;
    } catch (err) {
        console.error(`Error checking file existence for ${taskType} with guid ${guid}:`, err);
        return false;
    }
};

const makeRequest = (endpoint: string, data: string, postOrPut: string) => {
    const token = localStorage.getItem('drillit-token');
    return fetch(endpoint, {
        method: postOrPut,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: data
    }).then(response => response).catch(err => err);
};

const getIDB = (keyValue: string) => get(keyValue).then(data => data);



// import { get, del } from 'idb-keyval'

//     export const usePostToApi = (keyValueEndpoint: string, postOrPut: string, update?: boolean) => {
//     const newKeyValue = `drillit-${keyValueEndpoint}-new`
//     const updateKeyValue = `drillit-${keyValueEndpoint}-update`
//     // add in any routes that you want to use the noV1ApiRoute
//     const noV1ApiRoutes = [
//         'login'
//     ]
//     let apiPostAddr = ''
//     if (noV1ApiRoutes.includes(keyValueEndpoint)) {
//         apiPostAddr = `${process.env.REACT_APP_API_ADDR_NOV1}${keyValueEndpoint}`
//     } else {
//         apiPostAddr = `${process.env.REACT_APP_API_ADDR}${keyValueEndpoint}`
//     }

//     if (update) {
//         const updatedObjects = getIDB(updateKeyValue).then(result => {
//             if (result) {
//                 let removedItemsArray: any = result
//                 result.forEach((element: any) => {
//                     // get the id of the current item and put it on the end of the apiAddress
//                     const elId = element.guid
//                     const apiPutAddr = `${apiPostAddr}/${elId}`
//                     const putAttempt = makeRequest(apiPutAddr, JSON.stringify(element), postOrPut)
//                     putAttempt
//                         .then(posted => {
//                             if (posted.status === 200) {
//                                 removedItemsArray = removedItemsArray.filter((item:any) => item !== element)
//                             }
//                             return posted
//                         })
//                         .then(endResult => {
//                             if (removedItemsArray.length === 0) {
//                                 return del(updateKeyValue).then(res => res)
//                                 // return endResult
//                             }
//                             return endResult
//                         })
//                     return putAttempt
//                 })
//             }
//             return result
//         })

//         return updatedObjects
//     }

//     const newObjects = getIDB(newKeyValue).then(result => {
//         if (result && newKeyValue !== 'drillit-action_counts-new') {
//             let removedItemsArray: any = result
//             // there are unsaved new items for this key, push them to the api on at a time
//             result.forEach((element: any,index: any) => {
//                 // make this an if checking for 200 status
//                 // if we get that 200 then remove the current value from 'whatever'-new key
//                 // if we get validation errors then we need to return to the form with those errors
//                 // if we get 404 or 500 ie offline then break out of the loop and come back to it later
//                 const postAttempt = makeRequest(apiPostAddr, JSON.stringify(element), postOrPut)
//                 console.log('postAttempt:', postAttempt)
//                 postAttempt
//                     .then(posted => {
//                     console.log('**** New Hole - posted.status:', posted.status)
//                         if (posted.status === 200) {
//                             console.log('**** New Hole - POSTED!!! Status: 200')
//                             removedItemsArray = removedItemsArray.filter((item:any) => item !== element)
//                         }
//                         return posted
//                     })
//                     .then(endResult => {
//                         if (removedItemsArray.length === 0) {
//                             return del(newKeyValue).then(res => res)
//                         }

//                         // if that array still has length then we need to update on the idb-keyval key with it
//                         return endResult
//                     })
//                 return postAttempt
//             })
//         }
//         return result
//     })


//     return newObjects
// }



// // Function to check if files exist on the server
// const checkFilesExist = async (taskType: string, guid: string) => {
//     const endpoint = `${process.env.REACT_APP_API_ADDR}action/${taskType}/${guid}/exists`;
//     try {
//         const response = await fetch(endpoint);
//         const data = await response.json();
//         console.log(`**** New Hole - File existence for ${taskType} with guid ${guid}: ${data.exists}`);
//         return data.exists;
//     } catch (err) {
//         console.error(`**** New Hole - Error checking file existence for ${taskType} with guid ${guid}:`, err);
//         return false;
//     }
// };

// const makeRequest = (endpoint: string, data: string, postOrPut: string) => {
//     const token = localStorage.getItem('drillit-token')

//     return fetch(endpoint, {
//         method: postOrPut,
//         headers: {
//             'Content-Type': 'application/json',
//             'Accept': 'application/json',
//             'Authorization': `Bearer ${token}`,
//         },
//         body: data
//     })
//         //return the status code here
//         .then(response => response)
//         .catch(err => err)
// }

// const getIDB = (keyValue: string) => get(keyValue).then(data => data)
