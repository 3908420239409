import { del, set, get } from 'idb-keyval';

export const updateContactInformation = (
    guid: string,
    first_name: string,
    email: string,
    telephone: string,
    last_name: string,
    company_id: number
): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
        const endpoint = `${process.env.REACT_APP_API_ADDR}contacts/${guid}`;
        const updateData = {
            guid,
            first_name,
            email,
            telephone,
            last_name,
            company_id,
        };

        // Make PUT request to update contact
        makeRequest(endpoint, JSON.stringify(updateData), 'PUT')
            .then(response => {
                if (response.status === 200) {
                    console.log(`Successfully updated contact data for ${guid}`);
                } else {
                    console.error(`Error updating contact data for ${guid}: ${response.statusText}`);
                    // Save the update request to IndexedDB for retrying later
                    const idbKey = `drillit-contacts/${guid}-update`;
                    set(idbKey, updateData)
                        .then(() => {
                            console.log(`Saved update request for ${guid} to IndexedDB for retry.`);
                        })
                        .catch(err => {
                            console.error(`Error saving update request to IndexedDB for ${guid}:`, err);
                        });
                }

                // Update IndexedDB records regardless of PUT request success or failure
                Promise.all([
                    updateContactInDrillitContacts(guid, updateData),
                    updateContactInCompanyContacts(company_id, guid, updateData)
                ]).then(() => {
                    resolve(); // Resolve without any argument
                }).catch(err => {
                    console.error(`Error updating contacts in IndexedDB:`, err);
                    reject(err);
                });

            })
            .catch(err => {
                console.error(`Error updating contact data for ${guid}:`, err);
                // Save the update request to IndexedDB for retrying later
                const idbKey = `drillit-contacts/${guid}-update`;
                set(idbKey, updateData)
                    .then(() => {
                        console.log(`Saved update request for ${guid} to IndexedDB for retry.`);
                    })
                    .catch(saveErr => {
                        console.error(`Error saving update request to IndexedDB for ${guid}:`, saveErr);
                    });

                // Update IndexedDB records regardless of fetch error
                Promise.all([
                    updateContactInDrillitContacts(guid, updateData),
                    updateContactInCompanyContacts(company_id, guid, updateData)
                ]).then(() => {
                    resolve(); // Resolve without any argument
                }).catch(dbErr => {
                    console.error(`Error updating contacts in IndexedDB:`, dbErr);
                    reject(dbErr);
                });
            });
    });
};

const makeRequest = (endpoint: string, data: string, postOrPut: string) => {
    const token = localStorage.getItem('drillit-token')

    return fetch(endpoint, {
        method: postOrPut,
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: data
    })
        //return the status code here
        .then(response => response)
        .catch(err => err)
}

const updateContactInDrillitContacts = (guid: string, updatedContact: any): Promise<void> => {
    return new Promise((resolve, reject) => {
        get('drillit-contacts')
            .then((contacts: any[]) => {
                if (!Array.isArray(contacts)) {
                    contacts = []; // Ensure contacts is an array
                }

                // Find the index of the contact with the matching GUID
                const index = contacts.findIndex(contact => contact.guid === guid);

                if (index !== -1) {
                    // Update the contact in the array
                    contacts[index] = updatedContact;
                    console.log(`Updated contact ${guid} in drillit-contacts in IndexedDB.`);
                } else {
                    // Add the new contact to the array
                    contacts.push(updatedContact);
                    console.log(`Added new contact ${guid} to drillit-contacts in IndexedDB.`);
                }

                // Save the updated contacts array back to IndexedDB
                set('drillit-contacts', contacts)
                    .then(() => {
                        console.log(`Saved contacts to IndexedDB.`);
                        resolve();
                    })
                    .catch(err => {
                        console.error(`Error saving contacts to IndexedDB:`, err);
                        reject(err);
                    });
            })
            .catch(err => {
                console.error(`Error retrieving drillit-contacts from IndexedDB:`, err);
                reject(err);
            });
    });
};


const updateContactInCompanyContacts = (company_id: number, guid: string, updatedContact: any): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
        const companyContactsIDB = `drillit-contacts/company/${company_id}`;
        console.log('updateContactInCompanyContacts - company_id:', company_id)
        console.log('updateContactInCompanyContacts - company_id:', guid)
        console.log('updateContactInCompanyContacts - updatedContact:', updatedContact)

        get(companyContactsIDB)
            .then((companyContacts: any) => {
                if (companyContacts && companyContacts.contacts) {
                    console.log('updateContactInCompanyContacts - companyContacts.contacts:', companyContacts.contacts)
                    // Find the index of the contact with the matching GUID in the contacts array
                    const index = companyContacts.contacts.findIndex((contact: any) => contact.guid === guid);

                    if (index !== -1) {
                        // Update the contact in the company contacts array
                        companyContacts.contacts[index] = updatedContact;

                        // Save the updated company contacts array back to IndexedDB
                        set(companyContactsIDB, companyContacts)
                            .then(() => {
                                console.log(`Updated contact ${guid} in company contacts in IndexedDB for company ID ${company_id}.`);
                                resolve();
                            })
                            .catch(err => {
                                console.error(`Error saving updated company contacts to IndexedDB:`, err);
                                reject(err);
                            });
                    } else {
                        reject(new Error(`Contact with GUID ${guid} not found in company contacts for company ID ${company_id}.`));
                    }
                } else {
                    reject(new Error(`Company contacts not found in IndexedDB for company ID ${company_id}.`));
                }
            })
            .catch(err => {
                console.error(`Error retrieving company contacts from IndexedDB for company ID ${company_id}:`, err);
                reject(err);
            });
    });
};

