import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

import Button from '../../../form/Button'

type Props = {
	title: string,
	selectedValue: string,
	setSelectedValue: any,
	columns?: number,
	columnsMobile?: number,
	values: any[];
    className?: string,
	[x: string]: any;
}

const defaultProps: Props = {
	title: '',
	selectedValue: '',
	setSelectedValue: () => {},
	columns: 3,
	columnsMobile: 1,
	className: '',
	values: [],
}

const ExclusiveSelect: FunctionComponent<Props> = ({ title, selectedValue, setSelectedValue, columns, columnsMobile, className, values, ...rest }) => {
	const selectValue = (value: string) => {
		if(selectedValue === value) {
			setSelectedValue('')
			return
		}

		setSelectedValue(value)
	}

	return (
		<>
			<div className="w-full text-center mb-0 sm:mb-2 mt-0 sm:mt-0 text-base md:text-lg">{title}</div>

			<div className={`grid grid-cols-${columnsMobile} sm:grid-cols-${columns} md:grid-cols-${columns} gap-x-0 gap-y-1 mb-2`}>
				{values.map((value, index) => {
					return typeof value === 'object' && value !== null ?
						<div className={`md:col-span-1 text-charcoal px-1 py-1 flex flex-col text-center justify-center items-center bg-white font-normal ${className} ${selectedValue === value.value ? 'border-2 border-orange' : 'border-2 border-grey-mid'} rounded`} onClick={() => selectValue(value.value)} key={index}>
							{value.title}
						</div>
					:
						<div className={`md:col-span-1 text-charcoal px-1 py-1 flex flex-col text-center justify-center items-center bg-white font-normal ${className} ${selectedValue === value ? 'border-2 border-orange' : 'border-2 border-grey-mid'} rounded`} onClick={() => selectValue(value)} key={index}>
							{value}
						</div>
					}
				)}
			</div>
		</>
	)
}

ExclusiveSelect.defaultProps = defaultProps

export default ExclusiveSelect