import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import Button from '../../components/form/Button'
// import ProjectHeader from '../../components/project/ProjectHeader'
import ProjectHeaderNoDate from '../../components/project/ProjectHeaderNoDate'
import Borehole from '../../components/Borehole'
import ModalSmall from '../../components/ModalSmall'
import Logs from '../Logs'

import Header from '../../layout/Header'
import Container from '../../layout/Container'

import editIcon from '../../assets/icons/edit-white.svg'
import plusIcon from '../../assets/icons/plus-white.svg'
import plusIconBlue from '../../assets/icons/plus-blue.svg'
import cablePercussiveIcon from '../../assets/icons/cablepercussive-white.svg'
import windowSampleIcon from '../../assets/icons/windowsample-white.svg'
import rotaryIcon from '../../assets/icons/rotary-white.svg'
import trialpitIcon from '../../assets/icons/trialpit-white.svg'
import dynamicProbeIcon from '../../assets/icons/dynamicprobe-white.svg'
import headTestIcon from '../../assets/icons/headtest-white.svg'
import soakawayIcon from '../../assets/icons/soakaway-white.svg'
import plateTestIcon from '../../assets/icons/platetest-white.svg'
import cbrIcon from '../../assets/icons/cbr-white.svg'
import trlIcon from '../../assets/icons/trl-white.svg'
import loggingIcon from '../../assets/icons/logging-white.svg'
import icon_reload from '../../assets/icons/reload.png'

import getWeatherList from '../../helpers/getWeatherList'
import { get } from "idb-keyval";
import { useGetIDBValues } from "../../helpers/getIDBKey";
import SyncStatus from '../../helpers/syncStatus'
import { useGetLoggedInUser } from '../../helpers/getLoggedInUser'


import menubars from '../../assets/menubars.svg'

import icon_ags from '../../assets/icons/icon_ags.png'
import icon_project from '../../assets/icons/icon_project.png'
import icon_logs from '../../assets/icons/icon_logs.png'
import icon_email from '../../assets/icons/icon_email.png'
import icon_newborehole from '../../assets/icons/icon_newborehole.png'
import icon_plantcheck from '../../assets/icons/icon_plantcheck.png'
import icon_editrig from '../../assets/icons/icon_editrig.png'
import warningIcon from '../../assets/icons/warning.png'
import checkIcon from '../../assets/icons/check.svg'
import icon_endshift from '../../assets/icons/icon_endshift.png'

import NewHole from '../NewHole'
import AGS from '../AGS'
import Email from '../Email'

const drillTypes: any = {
	'cable-percussive': {
		name: 'Cable Percussive',
		icon: cablePercussiveIcon,
		iconWidth: '12',
		editRigVisibility: 'block',
		plantCheckLink: 'daily-plant-check-list-cable-percussive',
		plantCheckVisibility: 'block',
		holeType: 'Borehole',
		holeAbbr: 'CP',
	},
	'window-sample': {
		name: 'Window Sample',
		icon: windowSampleIcon,
		iconWidth: '10',
		editRigVisibility: 'block',
		plantCheckLink: 'daily-plant-check-list-window-sample',
		plantCheckVisibility: 'block',
		holeType: 'Borehole',
		holeAbbr: 'WS',
	},
	'rotary': {
		name: 'Rotary',
		icon: rotaryIcon,
		iconWidth: '9',
		editRigVisibility: 'block',
		plantCheckLink: 'daily-plant-check-list-rotary',
		plantCheckVisibility: 'block',
		holeType: 'Borehole',
		holeAbbr: 'RT',
	},
	'trialpit': {
		name: 'Trialpit',
		icon: trialpitIcon,
		iconWidth: '25',
		editRigVisibility: 'hidden',
		plantCheckLink: '',
		plantCheckVisibility: 'hidden',
		holeType: 'Trialpit',
		holeAbbr: 'TP',
	},
	'dynamic-probe': {
		name: 'Dynamic Probe',
		icon: dynamicProbeIcon,
		iconWidth: '30',
		editRigVisibility: 'block',
		plantCheckLink: 'daily-plant-check-list-window-sample',
		plantCheckVisibility: 'block',
		holeType: 'Probe',
		holeAbbr: 'DP',
	},
	'head-test': {
		name: 'Head Test',
		icon: headTestIcon,
		iconWidth: '20',
		editRigVisibility: 'hidden',
		plantCheckLink: '',
		plantCheckVisibility: 'hidden',
		holeType: 'Head Test',
		holeAbbr: 'HT',
	},
	'soakaway': {
		name: 'Soakaway',
		icon: soakawayIcon,
		iconWidth: '25',
		plantCheckLink: '',
		editRigVisibility: 'hidden',
		plantCheckVisibility: 'hidden',
		holeType: 'Soakaway',
		holeAbbr: 'SA',
	},
	'plate-test': {
		name: 'Plate Test',
		icon: plateTestIcon,
		iconWidth: '30',
		plantCheckLink: '',
		editRigVisibility: 'hidden',
		plantCheckVisibility: 'hidden',
		holeType: 'Plate Test',
		holeAbbr: 'PT',
	},
	'cbr': {
		name: 'CBR',
		icon: cbrIcon,
		iconWidth: '30',
		plantCheckLink: '',
		editRigVisibility: 'hidden',
		plantCheckVisibility: 'hidden',
		holeType: 'CBR',
		holeAbbr: 'CB',
	},
	'trl': {
		name: 'TRL',
		icon: trlIcon,
		iconWidth: '25',
		plantCheckLink: '',
		editRigVisibility: 'hidden',
		plantCheckVisibility: 'hidden',
		holeType: 'TRL',
		holeAbbr: 'TR',
	},
	'logging': {
		name: 'Logging',
		icon: loggingIcon,
		iconWidth: '20',
		editRigVisibility: 'hidden',
		plantCheckLink: '',
		plantCheckVisibility: 'hidden',
		holeType: 'Logging',
		holeAbbr: 'LG',
	},
}

interface Props {
	router: any,
	toggleSidebar: () => void,
	selectedWeather: string,
	selectedDate: string,
}

interface State {
	project_guid: string,
	company_guid: string,
	filter: string,
	boreholeFilter: number,
	boreholes: any[],
	project: any,
	bhMenuVisible: boolean,
	showHoleEdit: boolean,
	showNewHole: boolean,
	showEmail: boolean,
	showAGS: boolean,
	showEndShiftEmail: boolean,
	showEndShiftLogout: boolean,
	setShowNewHole: boolean,
	setShowEmail: boolean,
	setShowAGS: boolean,
	setShowEndShiftEmail: boolean,
	setShowEndShiftLogout: boolean,
	// site_description: string,
	currentRigValue: any,
	currentRigLabel: any,
	currentRigID: any,
	loggedInUser: any,
	leadDriller: any,
	secondMan: any,
	plant_checks_cp: any[],
	logsVisible: boolean,
	
}

class ProjectsDashboard extends React.Component<Props, State> {
	// private projectDateSwitcher: JSX.Element

	// Declare the menuRef property
	menuRef = React.createRef<HTMLDivElement>();

	constructor(props: any) {
		super(props)

		this.state = {
			project_guid: this.props.router.match.params?.id,
			company_guid: this.props.router.match.params?.company,
			// company_guid: '',
			filter: this.props.router.match.params?.filter,
			boreholeFilter: 0,
			boreholes: [],
			project: '',
			bhMenuVisible: false,

			showHoleEdit: false,
			showNewHole: false,
			showEmail: false,
			showAGS: false,
			showEndShiftEmail: false,
			showEndShiftLogout: false,
			setShowNewHole: false,
			setShowEmail: false,
			setShowAGS: false,
			setShowEndShiftEmail: false,
			setShowEndShiftLogout: false,

			currentRigValue: '',
			currentRigLabel: '',
			currentRigID: '',

			loggedInUser: null,
			leadDriller: 'Levi Puller',
			secondMan: 'Rod Lifter',

			plant_checks_cp: [],
			
			logsVisible: false,
		}

		this.bhMenuClick = this.bhMenuClick.bind(this);
		this.menuOutsideClick = this.menuOutsideClick.bind(this);
	}

	menuOutsideClick = (event: MouseEvent) => {
		if (
			this.menuRef.current &&
			!this.menuRef.current.contains(event.target as Node)
		) {
			this.setState({ bhMenuVisible: false });
		}
	}

	toggleBhMenu = () => {
		this.setState((prevState) => ({
			bhMenuVisible: !prevState.bhMenuVisible,
		}))
	}

	bhMenuClick() {
		this.setState({ bhMenuVisible: !this.state.bhMenuVisible });
	}

	newHoleClick = () => {
		this.setState({ showNewHole: true });
	}

	menuAndNewHole = () => {
		this.setState({ bhMenuVisible: !this.state.bhMenuVisible });
		this.setState({ showNewHole: true });
	}

	menuAndEmail = () => {
		this.setState({ bhMenuVisible: !this.state.bhMenuVisible });
		this.setState({ showEmail: true });
	}

	menuAndLogs= () => {
		this.setState({ bhMenuVisible: !this.state.bhMenuVisible });
		this.setState({ logsVisible: true });
	}

	menuAndAGS = () => {
		this.setState({ bhMenuVisible: !this.state.bhMenuVisible });
		this.setState({ showAGS: true });
	}

	menuAndEndShift = () => {
		this.setState({ bhMenuVisible: !this.state.bhMenuVisible });
		this.setState({ showEndShiftEmail: true });
	}


	componentDidMount() {
		this.getCacheValues()
		document.addEventListener('click', this.menuOutsideClick, true);
		// this.getRigData()
		// this.getRigID()
		// this.getPlantChecksCP()
	}

	getRigData = () => {
		get(`drillit-currentRig-${this.state?.project_guid}`)
			.then(data => {
				if (data) {
					this.setState({
						currentRigValue: data.guid,
						currentRigLabel: data.label,
					})
				}
			})
	}

	getRigID = () => {
		const cachedValues = useGetIDBValues;
		// cachedValues('rigs', (data: any) => {
		const companyRigs = `rigs/company/${this.state?.company_guid}`;
		// console.log('companyRigs:', companyRigs)
		cachedValues(companyRigs, (data: any) => {
			if (Array.isArray(data)) {
				const rig = data.find((rig: any) => rig.guid === this.state?.currentRigValue);
				console.log('rig data:', rig);
				if (rig) {
					this.setState({
						currentRigID: rig.plant_id,
					});
				} else {
					this.setState({
						currentRigID: 'undefined',
					});
				}
			} else {
				// Handle the case where data is not an array
				// console.error('Invalid data format:', data);
				this.setState({
					currentRigID: 'undefined',
				});
			}
		});
	};

	// getPlantChecksCP = () => {

	// }

	componentWillUnmount() {
		document.removeEventListener('click', this.menuOutsideClick, true);
	}

	// getCacheValues = () => {
	// 	let cachedValues = useGetIDBValues
	// 	cachedValues(`hole-locations/project/${this.state?.project_guid}`, (data: any) => {
	// 		this.setState({
	// 			boreholes: data
	// 		})
	// 	})
	// 	cachedValues(`projects/${this.state?.project_guid}`, (data: any) => {
	// 		// console.log('Project Data:', data)
	// 		this.setState({
	// 			project: data,
	// 			company_guid: data.company.guid
	// 		})
	// 	}).then(() => {
	// 		// this.getBoreholeData();
	// 		// Proceed with rig data and rig ID retrieval
	// 		this.getRigData();
	// 		this.getRigID();
	// 	})

	// }

	getCacheValues = async () => {
		let cachedValues = useGetIDBValues;
		const projectGuid = this.state?.project_guid;
	
		console.log(`Starting data fetch for project: ${projectGuid}`);
	
		try {
			console.log(`Attempting to fetch boreholes from IndexedDB using cachedValues for: hole-locations/project/${projectGuid}`);
			
			// Try to fetch boreholes data from IndexedDB
			await new Promise((resolve, reject) => {
				cachedValues(`hole-locations/project/${projectGuid}`, (data: any) => {
					console.log(`Fetched boreholes data from IndexedDB:`, data);
					if (data) {
						this.setState({ boreholes: data });
						resolve(data);
					} else {
						console.log(`No boreholes data found for key: hole-locations/project/${projectGuid}`);
						reject('No data found');
					}
				});
			});
	
			console.log(`Attempting to fetch project data from IndexedDB using cachedValues for: projects/${projectGuid}`);
			
			// Fetch project data from IndexedDB
			await new Promise((resolve, reject) => {
				cachedValues(`projects/${projectGuid}`, (data: any) => {
					console.log(`Fetched project data from IndexedDB:`, data);
					if (data) {
						this.setState({
							project: data,
							// company_guid: data.company.guid
						});
						resolve(data);
					} else {
						console.log(`No project data found for key: projects/${projectGuid}`);
						reject('No data found');
					}
				});
			});
	
			console.log(`Successfully fetched data from IndexedDB, proceeding with rig data and rig ID retrieval.`);
			this.getRigData();
			this.getRigID();
	
		} catch (error) {
			console.error(`Error during IndexedDB fetch:`, error);
	
			// Fallback to directly getting data from IndexedDB if the initial fetch fails
			try {
				console.log(`Fetching boreholes directly from IndexedDB for key: drillit-hole-locations/project/${projectGuid}`);
				const boreholes = await get(`drillit-hole-locations/project/${projectGuid}`);
				console.log(`Fetched boreholes directly from IndexedDB:`, boreholes);
				if (Array.isArray(boreholes)) {
					this.setState({ boreholes });
				} else {
					console.log(`No borehole data found for key: drillit-hole-locations/project/${projectGuid}`);
				}
			} catch (error) {
				console.error(`Error fetching boreholes directly from IndexedDB:`, error);
			}
	
			try {
				console.log(`Fetching project data directly from IndexedDB for key: projects/${projectGuid}`);
				const project = await get(`projects/${projectGuid}`);
				console.log(`Fetched project data directly from IndexedDB:`, project);
				if (project) {
					this.setState({
						project,
						// company_guid: project.company.guid
					});
					this.getRigData();
					this.getRigID();
				} else {
					console.log(`No project data found for key: projects/${projectGuid}`);
				}
			} catch (error) {
				console.error(`Error fetching project data directly from IndexedDB:`, error);
			}
		}
	}
	

    componentDidUpdate(prevProps: {}, prevState: State) {
        // Check if project data has changed
        if (prevState.project !== this.state.project) {
            // this.getBoreholeData();
        }
    }

	getBoreholeData = () => {
		// Check if the project data is available in the state
		const { project } = this.state;
	
		if (project && project.hole_locations) {
			// Extract hole_locations from project
			const boreholes = project.hole_locations;
	
			// Update the state with the extracted boreholes
			this.setState({
				boreholes: boreholes
			});
		} else {
			console.error('Project data or hole_locations is not available.');
		}
	};

	handleFilterChange = (event: any) => {
		this.setState({ boreholeFilter: parseInt(event.target.value) })
	}

	getWeatherIcon = () => {
		let weatherIcon = ''

		getWeatherList().map((weather) => {
			if (weather.label === this.props.selectedWeather)
				weatherIcon = weather.icon
		})

		return weatherIcon
	}
	

	handleReloadClick = () => {
		window.location.reload();
	  };

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// PERSONNEL
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////





	render() {


		const { leadDriller, secondMan } = this.state;
		const { currentRigID, filter } = this.state;
		const { company_guid } = this.state;

		// console.log('selectedDate:', this.props.selectedDate)

		// console.log('company_guid:', this.state.company_guid)
		// console.log('currentRigID:', this.state.currentRigID)
		// console.log('currentRigValue:', this.state.currentRigValue)
		// console.log('currentRigLabel:', this.state.currentRigLabel)
		// console.log('this.state:', this.state)

		// // Render only when company_guid is available
		// if (!company_guid) {
		// return null; // or loading indicator
		// }

	console.log('this.state.project:', this.state.project)

		
		const { logsVisible } = this.state;

		const statusIconInactive = (
			<div className="text-center mr-1 w-10">
				<div className="mx-auto rounded-full border-4 border-grey-mid w-7 h-7">
				</div>
				<div className="text-center" style={{ fontSize: '0.6rem' }}>
					Inactive
				</div>
			</div>
		)

		const statusIconActive = (
			<div className="text-center mr-1 w-10">
				<div className="mx-auto rounded-full border-4 border-orange w-7 h-7">
				</div>
				<div className="text-center" style={{ fontSize: '0.6rem' }}>
					Active
				</div>
			</div>
		)

		const statusIconComplete = (
			<div className="text-center mr-1 w-10">
				<div className="mx-auto rounded-full border-4 border-green-checkmark text-green-checkmark w-7 h-7 flex items-center justify-center">
					<img src={checkIcon} width="14" alt="" />
				</div>
				<div className="text-center" style={{ fontSize: '0.6rem' }}>
					Complete
				</div>
			</div>
		)

		// console.log('boreholes:', this.state.boreholes)

		return (
			<>


				{/* MENU STUFF */}

				{this.state.showNewHole &&
					// <NewHole onClose={() => { setShowNewHole(false) }} 
					// <NewHole onClose={() => { this.setState({ setShowNewHole: false }) }}

					<NewHole onClose={() => { this.setState({ showNewHole: false }) }}
						holeType={drillTypes[this.state.filter].holeAbbr}
					/>
				}

				{this.state.showEmail &&
					<Email onClose={() => { this.setState({ showEmail: false }) }}
					company_id={this.state?.project.company.id}
					project_location={this.state?.project.location}
					project_id={this.state?.project.project_identifier}
					project_guid={this.state?.project.guid}
					selected_date={this.props.selectedDate}
				/>
				}

				{this.state.showEndShiftEmail &&
					<Email onClose={() => { this.setState({ 
						showEndShiftEmail: false,
						showEndShiftLogout: true 
					}) }}
					company_id={this.state?.project.company.id}
					project_location={this.state?.project.location}
					project_id={this.state?.project.project_identifier}
					project_guid={this.state?.project.guid}
					selected_date={this.props.selectedDate}
				/>
				}

				{this.state.showEndShiftLogout &&
					<ModalSmall title="Log Out" confirm={false} toggleModal={() => { this.setState({ 
						showEndShiftEmail: false,
						showEndShiftLogout: false 
					}) }}>
						<div className="flex items-center flex-col pt-10">
							
							<Link to={`/logout`}>
								<div className="bg-blue rounded w-48 p-2 mb-10 text-center text-white font-bold text-lg">
									<img src={icon_endshift} className="h-12 w-12 mx-auto mb-1" alt="" />
									Log Out
								</div>
							</Link>
						</div>
					</ModalSmall>
				}

				{this.state.showAGS &&
					<AGS onClose={() => { this.setState({ showAGS: false }) }}
						project_start_date='09/25/2023'
					/>
				}

				

				{logsVisible &&
					<Logs onClose={() => { this.setState({ logsVisible: false }) }}
						project_location={this.state?.project?.location}
						project_id={this.state?.project?.project_identifier}
						company_guid={this.state?.company_guid}
						// company_id={this.state?.project.company.id}
						project_guid={this.state?.project?.guid}
					/>
				}

				{/* <Header toggleSidebar={this.props.toggleSidebar} middle={this.projectDateSwitcher} /> */}
				{/* <Header toggleSidebar={this.props.toggleSidebar} middle={<ProjectHeader projectId={this.props.router.match.params.id} />} /> */}

				<Header toggleSidebar={this.props.toggleSidebar} middle={
					<ProjectHeaderNoDate projectId={this.state?.project_guid}
						company_id={this.state?.project.company?.id}
						site_description={this.state.project?.site_description}
						key_site_features={this.state.project?.key_site_features}
						site_history={this.state.project?.site_history}
						key_hands={this.state.project?.key_hands}
						expected_strata={this.state.project?.expected_strata}
						notes={this.state.project?.notes}
						equipment_required={this.state.project?.equipment_required}
						client_name={this.state.project?.client_name}
						client_contact={this.state.project?.client_contact}
						client_phone={this.state.project?.client_phone}
						client_email={this.state.project?.client_email}
						engineer_name={this.state.project?.engineer_name}
						engineer_phone={this.state.project?.engineer_phone}
						engineer_email={this.state.project?.engineer_email}
					/>
				} />

				<Container>
					<div className={`flex items-center justify-between mt-2 text-xl pb-1`}>

						<div className="flex items-center">
							<div className={`flex items-center justify-center bg-orange rounded h-9 w-9`}>
								<img src={drillTypes[this.state.filter].icon} width={drillTypes[this.state.filter].iconWidth} alt="" />
							</div>

							<div className="ml-2 text-base md:text-lg font-bold">{drillTypes[this.state.filter].name}</div>
						</div>

						<div className={`mr-0`}>
							<Link to={'/projects/weather/' + this.state.project_guid}><i className={"text-3xl md:text-4xl m-0 pt-1 " + this.getWeatherIcon()}></i></Link>
						</div>
					</div>

					{/* {(currentRigID === 'undefined' && ['cable-percussive', 'rotary', 'window-sample', 'dynamic-probe'].includes(filter)) && (
						<div className="bg-yellow w-64 text-center text-xs font-bold mx-auto -mb-1 -mt-4 rounded-t">
						<Link to={`/rigs/0000/${this.state.company_guid}/${this.state.project_guid}`}>
							<img src={warningIcon} width="12" className="ml-1 inline-block pb-0.5" alt="" />&nbsp;
							Rig Not Selected
						</Link>
						</div>
					)} */}


					<div className="flex w-full py-2 bg-grey-vdark text-center mt-1 mb-0 text-sm text-white rounded-md">
						<div className="flex-none ml-0 w-6 pl-3 pt-0.5">
							<img onClick={this.handleReloadClick} src={icon_reload} className="ml-0 w-3.5 h-3.5" alt="" />
						</div>
						<div onClick={this.toggleBhMenu} className="flex-1" >
							{drillTypes[this.state.filter].name} Menu <img src={menubars} className="h-3 w-3 mb-0.5 inline" alt="" />
						</div>
						<div className="flex-none mr-0 w-6">

						</div>
					</div>

					<div className="relative"> {/* menu container */}


						<div className="flex items-center pb-2 mt-1">

							<div className="flex mr-auto items-left pt-2">
								{statusIconInactive}{statusIconActive}{statusIconComplete}
							</div>

							<div className="flex ml-auto items-center">



								<div className="w-40 md:w-44">
									{/* <div className="mr-3">Status:</div> */}
									<div className="mr-3 text-xs">{drillTypes[this.state.filter].name} Status:</div>
									<select className="form-control w-full" onChange={this.handleFilterChange}>
										<option value="0">All</option>
										<option value="1">Inactive</option>
										<option value="2">Active</option>
										<option value="3">Completed</option>
									</select>
								</div>
							</div>
						</div>

						<div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-x-2 sm:gap-x-2 md:gap-x-3">

							{/* NEW ITEM BUTTON */}

							{/* <Link to={`/newhole/${drillTypes[this.state.filter].holeAbbr}/${this.props.router.match.params.id}/${this.state.currentRigID}/${this.state.leadDriller}/${this.state.secondMan}`} onClick={this.bhMenuClick}> */}
							<Link to={`/newhole/${drillTypes[this.state.filter].holeAbbr}/${this.props.router.match.params.id}`} onClick={this.bhMenuClick}>
								<div className="flex items-center border md:border-2 border-blue relative px-2 py-3 text-lg leading-none bg-white rounded ">
									<img src={plusIconBlue} className="w-4 h-4 mr-2" alt="New" width="18" />
									<div className="font-bold">New</div>
								</div>
							</Link>



							{this.state.boreholes && this.state.boreholes.length > 0 &&
								this.state.boreholes
									.filter(borehole => (this.state.boreholeFilter === 0 || this.state.boreholeFilter === borehole.status))
									.filter(borehole => borehole.activity_type === drillTypes[this.state.filter].name)
									.reverse() // Reversing the order of the array
									.map((borehole, index) => (
										<Link to={this.state.filter + '/' + borehole.guid} key={index}>
											<Borehole complete={borehole.hole_complete ? borehole.hole_complete : ''} 
												name={borehole.hole_name} 
												status={borehole.status} 
												drillerName={borehole.driller} 
												followOnType={borehole.follow_on_type} 
												/>
										</Link>
									))
							}

							<div ref={this.menuRef} className={`menu ${this.state.bhMenuVisible ? 'visible' : 'hidden'}`}>

								<div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 sm:gap-3 md:gap-4 bg-grey-vdark w-full absolute z-99 top-0 left-0 -mt-3 text-center text-white text-sm pt-2 px-2 md:px-3 lg:px-4 pb-2 md:pb-3 lg:pb-4 rounded-b-lg modalshadow">

									<div className="border border-grey-dark rounded py-3">
										<Link to={`/projects/update/${this.props.router.match.params.id}`} onClick={this.bhMenuClick}>
											<img src={icon_project} className="h-12 w-12 mx-auto mb-1" alt="" />
											Edit Project
										</Link>
									</div>
									<div className="border border-grey-dark rounded py-3">
										<div onClick={this.menuAndLogs}>
											<img src={icon_logs} className="h-12 w-12 mx-auto mb-1" alt="" />
											Log Manager
										</div>
									</div>
									{/* <div className="border border-grey-dark rounded py-3">
										<div onClick={this.menuAndAGS}>
											<img src={icon_ags} className="h-12 w-12 mx-auto mb-1" alt="" />
											Output AGS
										</div>
									</div> */}
									<div className="border border-grey-dark rounded py-3">
										<div onClick={this.menuAndEmail}>
											<img src={icon_email} className="h-12 w-12 mx-auto mb-1" alt="" />
											Send Email
										</div>
									</div>
									<div className={`border border-grey-dark rounded py-3 ${drillTypes[this.state.filter].plantCheckVisibility}`}>
										<Link to={`/rigs/${this.state?.company_guid}/${this.state?.project_guid}`} onClick={this.bhMenuClick}>
											<img src={icon_editrig} className="h-12 w-12 mx-auto mb-1" alt="" />
											Rigs
										</Link>
									</div>
									<div className={`border border-grey-dark rounded py-3 ${drillTypes[this.state.filter].plantCheckVisibility}`}>
										<Link to={`/${drillTypes[this.state.filter].plantCheckLink}/${this.state?.company_guid}/${this.state?.project_guid}`} onClick={this.bhMenuClick}>
											<img src={icon_plantcheck} className="h-12 w-12 mx-auto mb-1" alt="" />
											Daily Plant Check
										</Link>
									</div>
									<div className="border border-grey-dark rounded pt-1.5 pb-1">
										{/* <Link to={`/#`} onClick={this.bhMenuClick}> */}
										{/* <Link to={`#`} onClick={menuAndMarkAsComplete}>
											<img src={icon_complete} className="h-12 w-12 mx-auto mb-1" alt="" />
											Mark As Complete
										</Link> */}
										<div onClick={this.menuAndEndShift}>
											<img src={icon_endshift} className="h-12 w-12 mx-auto mb-1" alt="" />
											End Shift
										</div>
									</div>
									<div className="border border-grey-dark rounded py-3">
										{/* <div onClick={this.menuAndNewHole}>
								<img src={icon_newborehole} className="h-12 w-12 mx-auto mb-1" alt="" />
									New {drillTypes[this.state.filter].holeType}
								</div> */}

										{/* <Link to={`/newhole/CP/${this.props.router.match.params.id}`} onClick={this.bhMenuClick}> */}

										<Link to={`/newhole/${drillTypes[this.state.filter].holeAbbr}/${this.props.router.match.params?.id}`} onClick={this.bhMenuClick}>
											<img src={icon_newborehole} className="h-12 w-12 mx-auto mb-1" alt="" />
											New {drillTypes[this.state.filter].holeType}
										</Link>

									</div>
									<div className="col-span-3 md:col-span-4 lg:col-span-5 border border-grey-dark rounded py-2 text-lg">
										<Link to={`/projects/dashboard/${this.state?.project_guid}/company/${this.state?.company_guid}`} onClick={this.bhMenuClick}>
											Change Activity
										</Link>
									</div>
								</div>

							</div>

						</div> {/* end of menu container */}

					</div>
				</Container>

				<br /><br />



				<SyncStatus synced />
			</>
		)
	}
}

const mapStateToProps = (state: any) => ({
	selectedWeather: state.weather,
	selectedDate: state.date,
})

export default connect(mapStateToProps, null)(ProjectsDashboard)
