import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

type Props = {
	title: string,
	link: string,
	count?: number,
	icon: any,
	iconWidth?: number,
	iconWidthCSS?: string,
	className?: string;
	[x: string]: any;
}

const defaultProps: Props = {
	title: '',
	link: '',
	count: 0,
	icon: '',
	iconWidth: 50,
	iconWidthCSS: '',
	className: '',
}

const ProjectType: FunctionComponent<Props> = ({ title, link, count, icon, iconWidth, iconWidthCSS, className, ...rest }) => {
	return (
		<Link to={link} className={`relative text-center rounded-lg border-2 md:border-2 border-orange bg-white pb-7 md:pb-8 ${className}`} {...rest}>
			{/* <div className="absolute top-1 right-2 font-bold text-grey-dark text-base md:text-lg">{count}</div> */}
			<div className={`absolute top-1 right-1 font-bold text-white text-xs rounded-full w-5 h-5 text-center pt-0.5 ${count !== 0 ? 'bg-orange' : 'bg-grey-dark'}`}>
				{count}
			</div>
			{/* <img src={icon} width={iconWidth} className={`${iconWidthCSS} mx-auto mt-5`} alt="" /> */}

			<img src={icon} className={`${iconWidthCSS} mx-auto`} alt="" />

			<div className="absolute bottom-0 left-0 right-0 pt-3">
				{/* <div className="flex items-center justify-center text-xs sm:text-lg md:text-lg leading-3 md:leading-5 px-10 sm:px-24 h-10 md:h-16">{title}</div> */}
				{/* <div className="flex items-center justify-center text-base sm:text-lg md:text-lg leading-4 md:leading-5 px-2 sm:px-24 h-8 md:h-16">{title}</div> */}
				<div className="mb-1 text-center text-xs md:text-sm uppercase font-bold">{title}</div>
				<div className="mx-auto border-t-2 md:border-t-4 border-orange w-16 rounded-t"></div>
				{/* <div className="-mt-2 ml-auto bg-orange w-28 md:w-36 rounded-tl-md text-center text-white h-3.5 md:h-5 pb-0.5col-span-2 md:col-span-1 text-xs md:text-base">{title}</div> */}
			</div>
		</Link>
	)
}

ProjectType.defaultProps = defaultProps

export default ProjectType