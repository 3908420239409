import React, { FunctionComponent, useState } from 'react'

import plusIcon from '../assets/icons/plus.svg'
import plusIconWhite from '../assets/icons/plus-white.svg'

type Props = {
	title: string,
	options?: any[],
	onAdd?: () => void,
	className?: string,
	children?: React.ReactNode,
	numberOfElements: number,
}

const defaultProps: Props = {
	title: '',
	options: [],
	onAdd: () => {},
	className: '',
	children: [],
	numberOfElements: 0
}

const Panel: FunctionComponent<Props> = ({ title, options, onAdd, className, children, numberOfElements, }) => { //numberOfElements
	const [contentActive, setContentActive] = useState(false)
	const [optionsActive, setOptionsActive] = useState(false)

	// var countInstances = 0
	var showCount = ''
	let elementsExist = false
	if (numberOfElements === 0){showCount = ''; elementsExist = false} 
	// else if (numberOfElements > 0) {showCount = '('+numberOfElements+')'}
	else if (numberOfElements > 0) {showCount = numberOfElements+''; elementsExist = true}

	return (
		<div className={`mb-2 ${className}`}>
			<div className="flex">
				<div className={`
					${contentActive || optionsActive ? 'bg-orange text-white' : 'bg-white'}
					${optionsActive ? '' : 'rounded-r md:rounded-r-lg'}
					border md:border-2 flex h-9 sm:h-12 items-center border-orange rounded-l md:rounded-l-md text-lg w-full
				`}>
					<div className="flex-1 flex items-center h-full pl-1 sm:pl-2 text-base sm:text-lg relative" 
					onClick={() => {setContentActive(!contentActive); setOptionsActive(false)}}
					>
						{title}
						<span className={`
							${elementsExist && (contentActive || optionsActive) ? 'bg-white text-orange' : ''}
							${elementsExist && (!contentActive && !optionsActive) ? 'bg-orange text-white' : ''}
							${elementsExist && optionsActive ? 'right-0' : 'right-1.5'}
							text-xs font-bold rounded-full px-1.5 pt-0.5 absolute pb-0.5
							`}>
							{showCount}
						</span>
					</div>

					{optionsActive && options && options.length > 0 &&
						<div className="ml-auto">
							{options && options.map((option, index) => {
								return <button className="bg-white text-orange text-charcoal rounded py-0 sm:py-1 ml-1 sm:ml-2 w-9 sm:w-16 text-base sm:text-lg md:text-xl" key={index} onClick={() => {option.onClick(); setOptionsActive(false)}}>{option.name}</button>
							})}
						</div>
					}
				</div>

				<div
					className={`${optionsActive ? 'bg-orange text-white' : 'bg-white  ml-1 rounded-l md:rounded-l-lg'} border md:border-2 border-orange rounded-r md:rounded-r-lg w-11 md:w-14 flex items-center justify-center plus-background`}
					onClick={() => {
						if(options && options.length) {
							setOptionsActive(!optionsActive)
							return
						}
						
						if(onAdd) {
							onAdd()
							return
						}
					}}>
					<img src={optionsActive ? plusIconWhite : plusIcon} width="15" alt="" className="z-10" />
				</div>
			</div>

			{contentActive &&
				<div className={`mt-1`}>
					{children}
				</div>
			}
		</div>
	)
}

Panel.defaultProps = defaultProps

export default Panel