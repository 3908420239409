import { del } from 'idb-keyval';

export const updateUserProfile = (
    guid: string,
    firstname: string,
    lastname: string,
    phone: string,
    // email: string,
    cscs: string,
    password: string

    ) => {
    return new Promise((resolve, reject) => {
        const endpoint = `${process.env.REACT_APP_API_ADDR}user/${guid}`
        console.log('endpoint:', endpoint)
        // make a date string
        const updateData = {
            'guid': guid,
            'first_name': firstname,
            'last_name': lastname,
            'telephone': phone,
            // 'email': email,
            'cscs_no': cscs,
            'password': password
        }

        console.log('update profile:', updateData)

        makeRequest(endpoint, JSON.stringify(updateData), 'PUT')
            .then(() => {
                // del(`drillit-hole-actions/${hole_guid}`)
                // console.log('cleanup on aisle 9')
            })

        return resolve(true)
    })
}

const makeRequest = (endpoint: string, data: string, postOrPut: string) => {
    const token = localStorage.getItem('drillit-token')

    return fetch(endpoint, {
        method: postOrPut,
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: data
    })
        //return the status code here
        .then(response => response)
        .catch(err => err)
}
