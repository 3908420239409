import React, { FunctionComponent, useState } from 'react'

import Header from '../layout/Header'

import Modal from '../components/Modal'
import WorkflowSteps from '../components/WorkflowSteps'
import NumberInput from '../components/NumberInput'
import MultiNumberInput from '../components/MultiNumberInput'
import Button from '../components/form/Button'
import InputGroup from '../components/form/InputGroup'
import TextArea from '../components/form/TextArea'
import WorkflowActions from '../components/form/WorkflowActions'

import stepStatus from '../helpers/stepStatus'
import ProjectDate from '../components/project/ProjectDate'

import ContactBook from './ContactBook'
import icon_contacts from '../assets/icons/icon_contacts.png'

interface Props {
	toggleSidebar: () => void,
	router: any,
}

interface State {
	currentStep: number,
	finalWaterLevel: string,
	finalCasingDepth: string,
	email: string,
	emailsubject: string,
	emailmessage: string,
	startDate: string,
	endDate: string,
	numberOfRows: number,
}

class EndShift extends React.Component<Props, State> {

	constructor(props: any) {
		super(props)

		this.state = {
			currentStep: 1,
			finalWaterLevel: '',
			finalCasingDepth: '',
            email: '',
            emailsubject: 'test-project files: BH004, BH005, AGS',
            emailmessage: "Please find attached files for:\nLocation: Rob's Test Project\nProject: test-project\nFiles: BH004, BH005, AGS",
            numberOfRows: 8,
			startDate: '',
			endDate: '',
		}
	}

	previous = () => {
		if(this.state.currentStep > 1)
			this.setState({currentStep: this.state.currentStep - 1})
	}

	next = () => {
		if(this.state.currentStep < 5)
			this.setState({currentStep: this.state.currentStep + 1})
	}

	
		// const [showContactBook, setShowContactBook] = useState(false)
		// const openContactBook = () => setShowContactBook(true)
	
	render() {

		return (
			<>
				<Header toggleSidebar={this.props.toggleSidebar} />

				<Modal title="End of Shift" toggleModal={() => {this.props.router.history.goBack()}} confirm={false}>
				
				{/* {showContactBook && 
					<ContactBook onClose={() => { setShowContactBook(false) }} />
				} */}

					<WorkflowSteps className="mb-4" steps={[
						{
							label: 'Water Depth',
							status: stepStatus(1, this.state.currentStep),
							onClick: () => this.setState({currentStep: 1})
						}, {
						// 	label: 'Casing Depth',
						// 	status: stepStatus(2, this.state.currentStep),
						// 	onClick: () => this.setState({currentStep: 2})
						// }, {
							label: 'Save Log',
							status: stepStatus(2, this.state.currentStep),
							onClick: () => this.setState({currentStep: 2})
						}, {
							label: 'AGS Data',
							status: stepStatus(3, this.state.currentStep),
							onClick: () => this.setState({currentStep: 3})
						}, {
							label: 'Send Email',
							status: stepStatus(4, this.state.currentStep),
							onClick: () => this.setState({currentStep: 4})
						},
					]} />

					<div className="px-2">
						{this.state.currentStep === 1 &&
							<form onSubmit={this.next}>
								<NumberInput title="Enter Final Water Depth (m)"
								onUpdate={(value: any) => this.setState({finalWaterLevel: value})} 
								defaultValue={this.state.finalWaterLevel} 
								className="truncate  mb-16 text-base sm:text-xl md:text-xl" 
								inputCSS="text-2xl sm:text-2xl md:text-3xl lg:text-4xl h-16 sm:h-16" 
								/>

								<WorkflowActions onBack={() => this.previous()} />
							</form>
						}

						{/* {this.state.currentStep === 2 &&
							<form onSubmit={this.next}>
								<NumberInput title="Enter Final Casing Depth (m)"
								onUpdate={(value: any) => this.setState({finalCasingDepth: value})} 
								defaultValue={this.state.finalCasingDepth} 
								className="mb-16" 
								/>

								<WorkflowActions onBack={() => this.previous()} />
							</form>
						} */}

						{this.state.currentStep === 2 &&
							<form onSubmit={this.next}>

								<div className="px-2 pb-4 mb-40 w-full sm:w-5/6 mx-auto">
									
									<div className="mb-2 text-2xl font-bold text-center">
										BH001
									</div>
								
									<div className="mb-6">
									<div className="text-center text-sm">Date of Log</div>
										{/* <ProjectDate /> */}
									</div>
										
									<div className="text-center mx-auto bg-white ags-item items-center border border-orange rounded text-sm md:text-lg px-4 md:px-5 py-1 md:py-2 w-full sm:w-3/5 md:w-3/5 lg:w-2/5 mb-8">
										Include Daily Plant Check in Log <input type="checkbox" className="form-control-checkbox" defaultChecked={false} />
									<div className="text-center text-sm">(for the day of the log)</div>
									</div>

									<div className="w-30">
										<Button theme="primary" size="lg" className="btn-lg m-auto" >Save Logsheet</Button>
									</div>

								</div>

								<WorkflowActions onBack={() => this.previous()} />
							</form>
						}

						{this.state.currentStep === 3 &&
							<form onSubmit={this.next}>

							{/* <div className="text-center text-xl">Select Holes in Project</div> 
							<div className="text-center text-sm">(All selected by default)</div>   */}

							<div className="border border-grey-mid rounded p-2 md:p-4 bg-sptTB w-full md:w-11/12 justify-center mx-auto mb-4">
										
								<div className="text-center mx-auto bg-modal-bg ags-item items-center rounded text-base md:text-lg font-bold px-1 md:px-5 py-1 md:py-2 w-full mb-4">
									Entire Project <input type="checkbox" className="form-control-checkbox" defaultChecked={true} />
								</div>
				
								<div className="text-center mx-auto bg-modal-bg ags-item items-center rounded text-base md:text-lg px-4 md:px-5 py-1 md:py-2 w-full">

									<div className="text-center text-base">or select locations manually</div>   

										<ul className="flex flex-wrap justify-center text-center mb-2 w-full"> {/* START BOREHOLES HOLDER */}

											<li className="ags-item items-center border border-orange rounded text-sm md:text-lg pl-0.5 md:pl-2 py-1 m-1 bg-sptSB">
												BH001 <input type="checkbox" className="form-control-checkbox" defaultChecked={false} />
											</li>

											<li className="ags-item items-center border border-orange rounded text-sm md:text-lg pl-0.5 md:pl-2 py-1 m-1 bg-sptSB">
												BH002 <input type="checkbox" className="form-control-checkbox" defaultChecked={false} />
											</li>

											<li className="ags-item items-center border border-orange rounded text-sm md:text-lg pl-0.5 md:pl-2 py-1 m-1 bg-sptSB">
												BH003 <input type="checkbox" className="form-control-checkbox" defaultChecked={false} />
											</li>

											<li className="ags-item items-center border border-orange rounded text-sm md:text-lg pl-0.5 md:pl-2 py-1 m-1 bg-sptSB">
												BH004 <input type="checkbox" className="form-control-checkbox" defaultChecked={false} />
											</li>

											<li className="ags-item items-center border border-orange rounded text-sm md:text-lg pl-0.5 md:pl-2 py-1 m-1 bg-sptSB">
												BH005 <input type="checkbox" className="form-control-checkbox" defaultChecked={false} />
											</li>

										</ul>
									</div>

									<div className="text-center mx-auto bg-modal-bg ags-item items-center rounded text-base md:text-lg font-bold px-1 md:px-5 py-1 md:py-2 w-full mt-4">	
			
										<div className="text-center text-xl">Select Date Range</div> 
										<div className="text-center text-xs md:text-sm font-normal mb-2">(Entire project by default)</div> 
					
										<ul className="flex flex-wrap justify-center text-center mb-6 w-full"> 
					
										<li className="ags-item items-center border border-grey-mid rounded text-sm md:text-lg px-1 md:px-5 py-1 md:py-2 m-1 md:m-2">
										Start Date <InputGroup label="" type={'date'} value={this.state.startDate} onChange={(value) => this.setState({startDate: value})} />
										<span className="text-xs">(Project start by default)</span>
										</li>
					
										<li className="ags-item items-center border border-grey-mid rounded text-sm md:text-lg px-1 md:px-5 py-1 md:py-2 m-1 md:m-2">
										End Date <InputGroup label="" type={'date'} value={this.state.endDate} onChange={(value) => this.setState({endDate: value})} />
										<span className="text-xs">(today's date by default)</span>
										</li>
					
										<li className="ags-item items-center border border-grey-mid rounded text-sm md:text-lg px-4 md:px-5 py-1 md:py-2 m-1 md:m-2">
											Today<br/><input type="checkbox" className="form-control-checkbox" defaultChecked={false} />
										</li>
					
										</ul>
					
					
					
					
										<div className="w-30 mb-4">
											<Button theme="primary" size="lg" className="btn-lg m-auto" >Generate AGS</Button>
										</div>

									</div>

								</div>

								<WorkflowActions onBack={() => this.previous()} />
							</form>
						}

						{this.state.currentStep === 4 &&
							<form onSubmit={this.next}><div className="grid grid-cols-2 gap-3 border border-grey-mid rounded px-4 bg-sptTB w-full md:w-11/12 justify-center mx-auto mb-4 pt-4 pb-2">

							<div className="col-span-2 lg:col-span-1">
								<div className="flex w-full md:w-2/3 lg:w-full mx-auto">
									<InputGroup 
									label="Email Address(s)" 
									value={this.state.email} 
									onChange={(value: any) => {this.setState({email: value})}} 
									className="w-10/12 mb-1 md:mb-2 font-bold" // lg:w-full
									/>
									{/* <div onClick={openContactBook} className="pt-5" >
										<img src={icon_contacts} className="h-10 w-10 ml-4" alt="" />
									</div> */}
									<div className="pt-5" >
										<img src={icon_contacts} className="h-10 w-10 ml-4" alt="" />
									</div>
									
								</div>
								<div className="">
									<InputGroup 
									label="Subject" 
									value={this.state.emailsubject} 
									onChange={(value: any) => {this.setState({emailsubject: value})}} 
									className="w-full md:w-2/3 lg:w-full mx-auto mb-2 font-bold"
									/>
								</div>
							</div>
							<div className="col-span-2 lg:col-span-1">
								<div className="">
									<TextArea 
									label="Message" 
									value={this.state.emailmessage} 
									onChange={(value: any) => {this.setState({emailmessage: value})}} 
									className="w-full md:w-2/3 lg:w-full mx-auto mb-2 align-text-top text-base font-bold"
									inputCSS="h-20 md:h-28 overflow-x-auto text-xs md:text-sm"
									numberOfRows={5}
									/>
								</div>
							</div>
							
						</div>
	
						<div className="border border-grey-mid rounded px-4 bg-sptTB w-full md:w-11/12 justify-center mx-auto mb-2 pt-2">
	
							<div className="text-center text-base md:text-xl mb-1">Attachments</div> 
											
							<div className="text-center mx-auto bg-modal-bg ags-item items-center rounded text-base md:text-lg px-4 md:px-5 py-1 md:py-2 w-full mb-4">
								Todays Logs <input type="checkbox" className="form-control-checkbox" defaultChecked={true} />
								<div className="text-center text-sm">(2 logs + AGS)</div>
							</div>
	
							<div className="text-center mx-auto bg-modal-bg ags-item items-center rounded text-base md:text-lg px-4 md:px-4 py-1 md:py-2 w-full mb-4">
	
								<div className="text-center text-base">or select files manually</div> 
								{/* <div className="text-center text-xs">(None selected by default)</div>   */}
	
									<ul className="flex flex-wrap justify-center text-center mb-1 w-full"> {/* START BOREHOLES HOLDER */}
	
										<li className="ags-item items-center border border-orange rounded text-sm md:text-lg px-2 py-1 m-1 bg-sptSB">
											BH001 <input type="checkbox" className="form-control-checkbox" defaultChecked={false} />
										</li>
	
										<li className="ags-item items-center border border-orange rounded text-sm md:text-lg px-2 py-1 m-1 bg-sptSB">
											BH002 <input type="checkbox" className="form-control-checkbox" defaultChecked={false} />
										</li>
	
										<li className="ags-item items-center border border-orange rounded text-sm md:text-lg px-2 py-1 m-1 bg-sptSB">
											BH003 <input type="checkbox" className="form-control-checkbox" defaultChecked={false} />
										</li>
	
										<li className="ags-item items-center border border-orange rounded text-sm md:text-lg px-2 py-1 m-1 bg-sptSB">
											BH004 <input type="checkbox" className="form-control-checkbox" defaultChecked={false} />
										</li>
	
										<li className="ags-item items-center border border-orange rounded text-sm md:text-lg px-2 py-1 m-1 bg-sptSB">
											BH005 <input type="checkbox" className="form-control-checkbox" defaultChecked={false} />
										</li>
	
										<li className="ags-item items-center border border-orange rounded text-sm md:text-lg px-2 py-1 m-1 bg-sptSB">
											AGS <input type="checkbox" className="form-control-checkbox" defaultChecked={false} />
										</li>
	
									</ul>
	
							</div>
						
						</div>



									
									{/* <div className="w-full sm:w-4/5 justify-center mx-auto mb-4">

										<div className="">
											<div className="">
												<InputGroup 
												label="Email Address(s)" 
												value={this.state.email} 
												onChange={(value: any) => {this.setState({email: value})}} 
												className="w-full md:w-3/5 lg:w-3/5 mx-auto mb-2"
												inputCSS="text-sm"
												/>
											</div>
                                
											<div className="">
												<InputGroup 
												label="Subject" 
												value={this.state.emailsubject} 
												onChange={(value: any) => {this.setState({emailsubject: value})}} 
												className="w-full md:w-3/5 lg:w-3/5 mx-auto mb-2"
												inputCSS="text-sm"
												/>
											</div>
											<div className="">
												<TextArea 
												label="Message" 
												value={this.state.emailmessage} 
												onChange={(value: any) => {this.setState({emailmessage: value})}} 
												className="w-full md:w-3/5 lg:w-3/5 mx-auto mb-2 align-text-top"
												inputCSS="h-24 overflow-x-auto text-sm"
												numberOfRows={5}
												/>
											</div>
										</div>

									</div> */}

													
									{/* <div className="text-center mx-auto bg-white ags-item items-center border border-orange rounded text-sm md:text-lg px-4 md:px-5 py-1 md:py-2 w-3/5 sm:w-3/5 md:w-2/5 lg:w-1/5 mb-2">
									Todays Logs <input type="checkbox" className="form-control-checkbox" defaultChecked={true} />
									<div className="text-center text-sm">(2 logs + AGS)</div>
									</div>

									<div className="text-center text-xl">Select Files</div> 
									<div className="text-center text-xs">(None selected by default)</div>  

									<ul className="flex flex-wrap justify-center text-center mb-6 w-full"> 

										<li className="ags-item items-center border border-orange rounded text-sm md:text-lg px-1 md:px-5 py-1 md:py-2 m-1 md:m-2">
											BH001 <input type="checkbox" value="BH001" className="form-control-checkbox" defaultChecked={false} />
										</li>

										<li className="ags-item items-center border border-orange rounded text-sm md:text-lg px-1 md:px-5 py-1 md:py-2 m-1 md:m-2">
											BH002 <input type="checkbox" value="BH002" className="form-control-checkbox" defaultChecked={false} />
										</li>

										<li className="ags-item items-center border border-orange rounded text-sm md:text-lg px-1 md:px-5 py-1 md:py-2 m-1 md:m-2">
											BH003 <input type="checkbox" value="BH003" className="form-control-checkbox" defaultChecked={false} />
										</li>

										<li className="ags-item items-center border border-orange rounded text-sm md:text-lg px-1 md:px-5 py-1 md:py-2 m-1 md:m-2">
											BH004 <input type="checkbox" value="BH004" className="form-control-checkbox" defaultChecked={true} />
										</li>

										<li className="ags-item items-center border border-orange rounded text-sm md:text-lg px-1 md:px-5 py-1 md:py-2 m-1 md:m-2">
											BH005 <input type="checkbox" value="BH005" className="form-control-checkbox" defaultChecked={true} />
										</li>

										<li className="ags-item items-center border border-orange rounded text-sm md:text-lg px-1 md:px-5 py-1 md:py-2 m-1 md:m-2">
											AGS <input type="checkbox" value="AGS" className="form-control-checkbox" defaultChecked={true} />
										</li>

									</ul> */}




									<div className="w-30 mb-2">
									<Button theme="primary" size="lg" className="btn-lg m-auto" >Send Email</Button>
									</div>

								{/* <div className="flex">
									<Button theme="primary" size="lg" className="ml-auto px-4" onClick={() => this.previous()}>Back</Button>
								</div> */}
							</form>
						}
					</div>
				</Modal>
			</>
		)
	}
}

export default EndShift