import React, { FunctionComponent, useState } from 'react'

import WorkflowSteps from '../../WorkflowSteps'
import Modal from '../../Modal'
import Button from '../../form/Button'
import NumberInput from '../../NumberInput'
import ButtonOptions from '../../form/ButtonOptions'
import { v4 as uuidv4 } from 'uuid';

import stepStatus from '../../../helpers/stepStatus'
import warningIcon from '../../../assets/icons/warning.png'

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: string,
	defaultValues?: any,
	update?: boolean,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => { },
	onComplete: () => { },
	borehole: '',
	defaultValues: {
		casing_diameter: '',
		casing_depth: '',
		depth_to_top: '',
		depth_to_base: '',
		depth_of_borehole: '',
	},
}

const CasingRecordRotary: FunctionComponent<Props> = ({ onClose, update, onComplete, borehole, defaultValues, 
	setShowCasingRecordEdit, selectedDate, ...rest }) => {
	const [currentStep, setCurrentStep] = useState(1)

	const [guid, setGuid] = useState(defaultValues.guid)
	const [casing_diameter, setSize] = useState(defaultValues.casing_diameter)
	const [casing_type, setCasingType] = useState(defaultValues.casing_type)
	const [casing_depth, setCasingDepth] = useState(defaultValues.depth_to_base)
	// const [depth_to_top, setDepthFrom] = useState(defaultValues.depth_to_top)
	const depth_to_top = defaultValues.depth_to_top
	// const [depth_to_base, setDepthTo] = useState(defaultValues.depth_to_base)
	const depth_to_base = defaultValues.depth_to_base
	const [depth_of_borehole, set_depth_of_borehole] = useState(defaultValues.depth_of_borehole ?? '');
	// const depth_of_borehole = defaultValues.depth_of_borehole

	const [saveButtonText, setButtonText] = useState('Continue')
	const [saveButtonColor, setButtonColor] = useState('primary')
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	const DeleteButton = () => {
		if (!update) return null;

		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	// const deleteRecordConfirm = () => {
	// 	setDeleteConfirmOpen(false)
	// 	// onComplete()
	// }
	const time_of_action = defaultValues.time_of_action

    const isoDate = selectedDate ? `${new Date(selectedDate).toISOString().split('T')[0]} 09:00:00` : new Date().toISOString().split('.')[0]


	const previous = () => {
		if (currentStep > 1)
			setCurrentStep(currentStep - 1)
		setButtonText('Continue')
		setButtonColor('primary')
	}

	const next = () => {
		if (currentStep < 3) {
			setCurrentStep(currentStep + 1)
			if (currentStep === 1) {
				setButtonText('Save')
				setButtonColor('success')
			} else {
				setButtonText('Continue')
				setButtonColor('primary')
			}
			return
		}

		onComplete({
			guid: update ? guid : uuidv4(),
			casing_type: casing_type,
			// casing_depth: parseFloat(casing_depth).toFixed(2), //2DP,
			casing_diameter: casing_diameter,
			depth_to_top: '0.00', // depth_to_top,
			depth_to_base: parseFloat(casing_depth).toFixed(2),
			depth_of_borehole: parseFloat(depth_of_borehole).toFixed(2), // depth_of_borehole,
			general_remarks: '',
			// time_of_action: new Date().toISOString().split('.')[0],
			// time_of_action: time_of_action ? time_of_action : new Date().toISOString().split('.')[0],
			time_of_action: time_of_action ? time_of_action : isoDate,
			update: update ? true : false,
		})
	}

	let requiredCasingText = ''
	let requiredTypeText = ''
	let requiredDepthText = ''
	let requiredBoreholeDepthText = ''

	if (!casing_diameter) {
		requiredCasingText = "'Casing Size' - "
	}

	if (!casing_type) {
		requiredTypeText = "'Casing Type'"
	}

	if (!casing_depth) {
		requiredDepthText = "'Depth To' - "
	}

	if (!depth_of_borehole) {
		requiredBoreholeDepthText = "'Borehole Depth'"
	}

	const MissingRequiredField = () => {
		if (currentStep === 2 && (!casing_depth || !casing_diameter || !casing_type)) return (
			<div className="text-center bg-sptSB border border-red font-bold rounded mb-2 py-1 text-xs md:text-sm lg:text-base">
				<img src={warningIcon} alt="info" width="20" className="inline-block mr-1 pb-1" />
				Required field: {requiredDepthText} {requiredCasingText} {requiredTypeText} {requiredBoreholeDepthText}
			</div>
		)
	
		return null
	}

	const casingTypes = (): any[] => {
		const map: any = {
			'36.5': ['RX', 'RW'],
			'46.0': ['EX', 'EW'],
			'57.1': ['AX', 'AW'],
			'73.0': ['BX', 'BW'],
			'88.9': ['NX', 'NW'],
			'114.3': ['HX', 'HW'],
			'139.7': ['PX', 'PW'],
			'168.2': ['SX', 'SW'],
			'193.6': ['UX', 'UW'],
			'219.0': ['ZX', 'ZW'],
			'': [],
		}

		return map[casing_diameter]
	}
	// var casing_depth2DP = parseFloat(casing_depth).toFixed(2)

	return (
		<Modal title="Casing Record" subtitle={borehole} toggleModal={onClose}>
			<div className="flex flex-col flex-grow px-1 sm:px-2 md:px-10">
				<WorkflowSteps steps={[
					{
						label: 'Depth',
						status: stepStatus(1, currentStep),
						onClick: () => {
							setCurrentStep(1)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Size',
						status: stepStatus(2, currentStep),
						onClick: () => {
							setCurrentStep(2)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Borehole Depth',
						status: stepStatus(3, currentStep),
						onClick: () => {
							setCurrentStep(3)
							setButtonText('Save')
							setButtonColor('success')
						}
					},
				]} className="mb-2" />

				{currentStep === 1 &&
					<NumberInput onUpdate={(value: any) => setCasingDepth(value)}
						defaultValue={casing_depth}
						title="Enter Casing Depth (m):"
						className="mb-40 md:mb-32" />
				}

				{currentStep === 2 &&
					<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2 w-full flex flex-col flex-grow">
						<div className="flex flex-col w-full text-center">
							<div className="w-full">
								<ButtonOptions onUpdate={(value: any) => setSize(value)}
									defaultValue={casing_diameter}
									title="Select Casing Size (mm)"
									columns={5}
									options={[
										'36.5',
										'46.0',
										'57.1',
										'73.0',
										'88.9',
										'114.3',
										'139.7',
										'168.2',
										'193.6',
										'219.0',
									]}
									className="mb-4 text-2xl"
									buttonHeight={12}
									buttonHeightMobile={12}
									columnsMobile={4}
								/>
							</div>

							<ButtonOptions onUpdate={(value: any) => setCasingType(value)}
								defaultValue={casing_type}
								title="Select Casing Type"
								columns={2}
								options={
									casingTypes()
								}
								className="mb-10 text-3xl"
								buttonHeight={14}
								buttonHeightMobile={14}
							/>
						</div>
					</div>
				}

				{currentStep === 3 &&
					<NumberInput onUpdate={(value: any) => set_depth_of_borehole(value)}
						defaultValue={depth_of_borehole}
						title="Enter Borehole Depth (m):"
						className="mb-40 md:mb-32" />
				}

				{/* {currentStep === 3 &&
						<NumberInput onUpdate={(value: any) => setDepthTo(value)} defaultValue={depth_to_base} title="Enter Casing Depth To (m):" className="mb-10" />
					}

					{currentStep === 4 &&
						<NumberInput onUpdate={(value: any) => setBHDepth(value)} defaultValue={depth_of_borehole} title="Borehole Depth (m):" className="mb-10" />
					} */}

				<MissingRequiredField />


				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">


							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?

								<div className="flex mt-4">
									<Button theme="secondary" 
										className="mr-5 text-sm sm:text-base px-3.5 md:px-7" 
										onClick={() => setDeleteConfirmOpen(false)}>
											Cancel
									</Button>

									<Button onClick={() => {
												onClose()
												onComplete({
													guid: guid,
													update: false,
													delete: true,
												})
												setShowCasingRecordEdit(false)
											}}
										className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">
										Delete
									</Button>
								</div>
							</div>


						</div>
					</div>
				}

				<div className="flex">
					{/* <Button theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button> */}
					<DeleteButton />

					<div className="flex ml-auto">
						<Button theme="primary" size="lg" className="px-4 sm:px-10" onClick={() => previous()}>Back</Button>
						<Button theme={saveButtonColor}
							size="lg"
							className="ml-4 px-8 sm:px-10 w-40 sm:w-60"
							onClick={() => next()}>{saveButtonText}
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

CasingRecordRotary.defaultProps = defaultProps

export default CasingRecordRotary
