import React, { FunctionComponent, useState, useEffect } from 'react'

// import WorkflowSteps from '../../WorkflowSteps'
import Modal from '../../Modal'
import Button from '../../form/Button'
// import NumberInput from '../../NumberInput'
// import InputGroup from '../../form/InputGroup'
// import TextArea from '../../form/TextArea'
// import CompactTable from '../../CompactTable'
// import MiniNumberInput from '../../MiniNumberInput'
// import Timer from '../../Timer'
import {v4 as uuidv4} from 'uuid';

// import formatTime from '../../../helpers/formatTime'
// import stepStatus from '../../../helpers/stepStatus'
// import { log } from "util";

import dp_chart_bg from '../../../assets/dp_chart_bg.jpg'

import graphIcon from '../../../assets/graphIcon.svg'
import infoIcon from '../../../assets/icons/info.png'
import closeIcon from '../../../assets/icons/close.svg'

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: any,
	borehole_guid?: any,
	title: string,
	// torquetext1: string,
	// torquetext2: string,
	rows?: number,
	[x: string]: any;
	results?: any,
}

const defaultProps: Props = {
	onClose: () => { },
	onComplete: () => { },
	borehole: '',
	borehole_guid: '',
	title: '',
	// torquetext1: '',
	// torquetext2: '',
	rows: 30,
	defaultValues: {
		guid: '',
		remarks: '',
	},
	results: {},
	selectedDate: ''
}

const initializeCells = (rows: number, results_blows?: any): string[] => {
	let results_blows_flat: any = []
	if (Object.entries(results_blows).length) {
		results_blows_flat = results_blows?.data?.blows?.flat()
	}
	let cells: any = {}

	for (const row in [...Array(rows)]) {
		for (const column in [...Array(10)]) {
			if (results_blows_flat[parseInt(row + '' + column) + 0]) {
				cells[parseInt(row + '' + column) + 0] = `${results_blows_flat[parseInt(row + '' + column) + 0]}`
			} else {
				cells[parseInt(row + '' + column) + 0] = ''
			}
		}
	}

	// console.log('cells:', cells)

	return cells
}

const initializeTorques = (rows: number, results_torques?: any): string[] => {
	let results_torques_flat: any = [];
	if (Object.entries(results_torques).length) {
	  results_torques_flat = results_torques?.data?.torques?.flat();
	}
	let torques: string[] = [];
  
	for (let row = 0; row < rows; row++) {
	  if (results_torques_flat[row]) {
		// Convert the value to a string and store it
		torques.push(`${results_torques_flat[row]}`);
	  } else {
		torques.push(''); // Push an empty string if there's no value
	  }
	}
	return torques;
  };

const keys = [
	'1', '2', '3',
	'4', '5', '6',
	'7', '8', '9',
	'.', '0',
]



const DynamicProbe: FunctionComponent<Props> = ({ onClose, update, onComplete, borehole, borehole_guid, onUpdate, 
	defaultValues, title, rows = 10, results, setShowDynamicProbeEdit, torque_default, selectedDate, holeDepth, ...rest }) => {

	// console.log('defaultValues.blows:', typeof defaultValues.blows, defaultValues.blows)
	// console.log('defaultValues.guid:', defaultValues.guid)

	console.log('**********************************update?:', update)

	const [newDP, set_newDP] = useState<boolean>(true)

	useEffect(() => {
		if (update) {set_newDP(false)}
	}, [update])

	

	let hole_depth = holeDepth
	let hole_depth_cell = ((parseFloat(holeDepth)).toFixed(2))
	let hole_depth_cell_value = ((parseFloat(holeDepth)*10))
	// convert hole_depth into activeCell
	// 
	console.log('hole_depth:', hole_depth)
	console.log('hole_depth_cell:', hole_depth_cell)
	console.log('hole_depth_cell_value:', hole_depth_cell_value)

	// const [activeCell, setActiveCell] = useState<number>(0)
	const [activeCell, setActiveCell] = useState<number>(hole_depth_cell_value)
	
	console.log('activeCell:', activeCell)

	const [activeTorque, setActiveTorque] = useState<number>(-1)
	const [cellNotTorque, setCellNotTorque] = useState<boolean>(true)
	// const [numRows, setNumRows] = useState<number>(rows)
	const numRows = rows

	// Populate input cells andtorqueswith defaultValues

	useEffect(() => {
		if (defaultValues && Array.isArray(defaultValues.blows) && defaultValues.torques) {

			const flattenedBlows = (defaultValues.blows as (string | number)[][])
			.flat()
			.map((value) => (value === -1 || value === '-1' || value === -2 || value === '-2' ? '' : String(value)));
	
			setCells(flattenedBlows.map(String));

			// Calculate the number of values in flattenedBlows
			const numberOfValues = flattenedBlows.length;

			// Set the remaining cells to ''
			setCells((prevCells) => [
			...prevCells.slice(0, numberOfValues),
			...Array.from({ length: 300 - numberOfValues }, () => ''),
			]);
			
			const flattenedTorques = defaultValues.torques.flat();
			setTorques(flattenedTorques.map(String));

			// Calculate the number of values in flattenedTorques
			const numberOfTorques = flattenedTorques.length;

			// Set the remaining cells to ''
			setTorques((prevCells) => [
			...prevCells.slice(0, numberOfTorques),
			...Array.from({ length: 30 - numberOfTorques }, () => ''),
			]);

		}
	}, [defaultValues, numRows, results]);


	const [cells, setCells] = useState<string[]>(initializeCells(numRows, results))
	const [torques, setTorques] = useState<string[]>(initializeTorques(numRows, results))
	  



	// const [remarks, setComment] = useState(defaultValues.remarks)
	const remarks = defaultValues.remarks
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	const [guid, setGuid] = useState(defaultValues.guid)
	// const guid = defaultValues.guid

	const [guidOnUpdate, setGuidOnUpdate] = useState(defaultValues.guid)

	useEffect(() => {
		if (!guid) {
			setGuid(uuidv4());
		}
		}, [defaultValues.guid, guid]);

	const initialTorqueDefault = defaultValues.dp_torque_unit
    ? defaultValues.dp_torque_unit
    : torque_default;

	// If defaultValues.dp_torque_unit changes, update torqueDefault
	useEffect(() => {
	if (defaultValues.dp_torque_unit) {
		setTorqueDefault(defaultValues.dp_torque_unit);
	}
	}, [defaultValues.dp_torque_unit]);

	const [torqueDefault, setTorqueDefault] = useState(initialTorqueDefault)

	
	const [showTorqueInfo, setShowTorqueInfo] = useState(false)

	const time_of_action = defaultValues.time_of_action

	const isoDate = selectedDate ? `${new Date(selectedDate).toISOString().split('T')[0]} 09:00:00` : new Date().toISOString().split('.')[0]





	const DeleteButton = () => {
		if (!update) return null;

		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	// const deleteRecordConfirm = () => {
	// 	setDeleteConfirmOpen(false)
	// 	// onComplete()
	// }

	const addCharacterCell = (character: string) => {
		let newValue = cells[activeCell]

		if (cells[activeCell] === '0')
			newValue = character
		else
			newValue = cells[activeCell] + character

		let newCells = { ...cells }
		newCells[activeCell] = newValue

		setCells(newCells)
		setUpdateClicked(false)
	}

	const addCharacterTorque = (character: string) => {
		let newValue = torques[activeTorque];
	  
		if (!newValue) // Check if the value is empty
		  newValue = character; // If empty, replace it with the new character
		else
		  newValue = torques[activeTorque] + character;
	  
		let newTorques = { ...torques };
		newTorques[activeTorque] = newValue;
	  
		setTorques(newTorques);
		setUpdateClicked(false)
	  };

	const addCharacter = (character: string) => {
		if (cellNotTorque) {
			addCharacterCell(character)
		} else {
			addCharacterTorque(character)
		}
		setUpdateClicked(false)
	}

	const removeCharacterCell = () => {
		let newValue = cells[activeCell]

		if (cells[activeCell] !== '') {
			if (cells[activeCell].length === 1)
				newValue = ''
			else
				newValue = cells[activeCell].slice(0, -1)
		}

		let newCells = { ...cells }
		newCells[activeCell] = newValue

		setCells(newCells)
		setUpdateClicked(false)
	}

	const removeCharacterTorque = () => {
		let newValue = torques[activeTorque]

		if (torques[activeTorque] !== '') {
			if (torques[activeTorque].length === 1)
				newValue = ''
			else
				newValue = torques[activeTorque].slice(0, -1)
		}

		let newTorques = { ...torques }
		newTorques[activeTorque] = newValue

		setTorques(newTorques)
		setUpdateClicked(false)
	}

	const removeCharacter = () => {
		if (cellNotTorque) {
			removeCharacterCell()
		} else {
			removeCharacterTorque()
		}
		setUpdateClicked(false)
	}

	const clearEntryCell = () => {
		let newCells = { ...cells }
		newCells[activeCell] = ''

		setCells(newCells)
		setUpdateClicked(false)
	}

	const clearEntryTorque = () => {
		let newTorques = { ...torques }
		newTorques[activeTorque] = ''

		setTorques(newTorques)
		setUpdateClicked(false)
	}

	const clearEntry = () => {
		if (cellNotTorque) {
			clearEntryCell()
		} else {
			clearEntryTorque()
		}
		setUpdateClicked(false)
	}

	const blowCells = () => {
		let newCells = { ...cells }

		if (cells[activeCell] === '') {
			newCells[activeCell] = '1'
		} else {
			newCells[activeCell] = (parseInt(cells[activeCell]) + 1).toString()
		}

		setCells(newCells)
		updateInputValues()
		setUpdateClicked(false)
	}

	const blowTorques = () => {
		let newTorques = { ...torques }

		if (torques[activeTorque] === '') {
			newTorques[activeTorque] = '1'
		} else {
			newTorques[activeTorque] = (parseInt(torques[activeTorque]) + 1).toString()
		}

		setTorques(newTorques)
		setUpdateClicked(false)
	}

	const blow = () => {
		if (cellNotTorque) {
			blowCells()
		} else {
			blowTorques()
		}
		setUpdateClicked(false)
	}

	const removeBlowCells = () => {
		let newCells = { ...cells }

		if (cells[activeCell] === '') {
			newCells[activeCell] = '0'
		} else {
			newCells[activeCell] = (parseInt(cells[activeCell]) - 1).toString()
		}

		setCells(newCells)
		setUpdateClicked(false)
	}

	const removeBlowTorques = () => {
		let newTorques = { ...torques }

		if (torques[activeTorque] === '') {
			newTorques[activeTorque] = '0'
		} else {
			newTorques[activeTorque] = (parseInt(torques[activeTorque]) - 1).toString()
		}

		setTorques(newTorques)
		setUpdateClicked(false)
	}

	const removeBlow = () => {
		if (cellNotTorque) {
			removeBlowCells()
		} else {
			removeBlowTorques()
		}
		setUpdateClicked(false)
	}

	const save = () => {
		const holeId = borehole_guid
		updateInputValues()
		findLastNonZeroInput()

		onComplete({
			// guid: update ? guid : uuidv4(),
			guid: guid,
			blows: flatToMultiDimensional(filteredCells,10), // filteredCells, // cells // numericBlows // finalNumericBlows
			torques: flatToMultiDimensional(finalNumericTorques,10), // torques // filteredTorques
			// cumulative_blows: calculateCumulative(cells, lastInputIdWithNonZeroValue), //cumulativeCells, // 
			dp_torque_unit: torqueDefault_temp !== undefined ? torqueDefault_temp : '',
			general_remarks: '',
			dp_identifier: borehole,
			// hole_location_id: holeId,
			final_depth: (lastInputIdWithNonZeroValue/10).toFixed(2),
			time_of_action: time_of_action ? time_of_action : isoDate,
			// update: update ? true : false
			update: true
		})
	}

	const updateSave = () => {
		const holeId = borehole_guid
		updateInputValues()
		findLastNonZeroInput()

		let updateDPvalues = false

		if (newDP === false) {
			updateDPvalues = true
		}

		// if (!guid) {
		// 	setGuid(uuidv4())
		// 	setGuidOnUpdate(guid)
		// } else {
		// 	setGuidOnUpdate(guid)
		// }

		console.log('***onUpdate*********')
		console.log('guid:', guid)
		console.log('blows:', flatToMultiDimensional(filteredCells,10))
		console.log('torques:', flatToMultiDimensional(finalNumericTorques,10))
		console.log('dp_torque_unit:', torqueDefault_temp !== undefined ? torqueDefault_temp : '')
		console.log('dp_identifier:', borehole)
		console.log('final_depth:', (lastInputIdWithNonZeroValue/10).toFixed(2))
		console.log('update:', updateDPvalues)
		console.log('********************')

		onUpdate({
			guid: guid,
			blows: flatToMultiDimensional(filteredCells,10), // filteredCells, // cells // numericBlows // finalNumericBlows
			torques: flatToMultiDimensional(finalNumericTorques,10), // torques // filteredTorques
			// cumulative_blows: calculateCumulative(cells, lastInputIdWithNonZeroValue), //cumulativeCells, // 
			dp_torque_unit: torqueDefault_temp !== undefined ? torqueDefault_temp : '',
			general_remarks: '',
			dp_identifier: borehole,
			// hole_location_id: holeId,
			final_depth: (lastInputIdWithNonZeroValue/10).toFixed(2),
			time_of_action: time_of_action ? time_of_action : isoDate,
			update: updateDPvalues
		})
		set_newDP(false)
	}

	// convert array into multidimensional array
	function flatToMultiDimensional(arr: any, subarrayLength: number) {
		const result = [];
		for (let i = 0; i < arr.length; i += subarrayLength) {
			result.push(arr.slice(i, i + subarrayLength));
		}
		return result;
	}

	var startingText = initialTorqueDefault
	const [torqueDefault_temp, setOutputText] = useState(startingText)

	function textChange() {
		const newText =
		  torqueDefault_temp === "(Nm)" ? "(kg/m)" : "(Nm)";
		setOutputText(newText);
	  
		// Assuming you want to update torqueDefault_temp as well
		setOutputText(newText);
	  }

	var startingChartText = "Show Chart"
	const [showChart, setShowChart] = useState(startingChartText)
	const [toggleChart, setToggleChart] = useState(false)

	// function chartTextChange() {
	// 	setShowChart(showChart === startingChartText ? "Hide Chart" : startingChartText)
	// }

	const handleShowGraph = () => {
		updateInputValues()
		setToggleChart(!toggleChart)
	}





	// Calculate graph widths

	const [inputValues, setInputValues] = useState(Array(310).fill(0))
	const maxValue = 50; // Maximum possible value

	const updateInputValues = () => {
		const newInputValues = Array(310).fill(0).map((_, index) => {
			const input = document.getElementById(`input${index}`)
			return input ? parseFloat(input.innerText) : 0
		});
		setInputValues(newInputValues)
		// console.log('updateInputValues triggered')
	}

	const calculateGraphWidths = () => {
		const widths = inputValues.map(value => {
			// If the value is not a number or is negative, return 0
			if (isNaN(value) || value < 0) {
				return 0;
			}
			return (value / maxValue) * 100;
		})
		return widths;
	}

	const graphWidths = calculateGraphWidths();








	// This effect will run whenever inputValues changes
	useEffect(() => {
		findLastNonZeroInput()
	}, [inputValues])


	/////////////////////////////////////////////////////////////////////////////////////////////////////
	// Blows
	/////////////////////////////////////////////////////////////////////////////////////////////////////

	/// FIND LAST INPUT WITH VALUE

	const [lastInputIdWithNonZeroValue, setLastInputIdWithNonZeroValue] = useState(0);

	const findLastNonZeroInput = () => {
		const reversedInputValues = [...inputValues].reverse(); // Create a reversed copy of the inputValues array
		const lastIndexInReversed = reversedInputValues.findIndex(value => value !== null && value !== '' && value !== 0 && !isNaN(value));

		if (lastIndexInReversed !== -1) {
			const actualLastIndex = inputValues.length - 1 - lastIndexInReversed; // Convert the reversed index to the actual index
			setLastInputIdWithNonZeroValue(actualLastIndex);
			// console.log('lastIndex:', actualLastIndex);
		}

		findInvalidInputIndices()

	};

	console.log('lastInputIdWithNonZeroValue:', lastInputIdWithNonZeroValue);

	const findInvalidInputIndices = () => {
		const invalidIndices = [];

		for (let i = 0; i <= lastInputIdWithNonZeroValue; i++) {
			const value = inputValues[i];
			if (value === null || value === '' || isNaN(value)) { // || value === 0
				invalidIndices.push(i);
			}
		}

		return invalidIndices;
	};


	const invalidIndices = findInvalidInputIndices()

	// const filteredCells: string[] = [];
	// for (let i = 0; i <= lastInputIdWithNonZeroValue; i++) { // lastInputIdWithNonZeroValue
	// // for (let i = 0; i < 300; i++) {
	//   // If the value is not empty, add it to the array
	//   if (cells[i] !== '') {
	// 	filteredCells.push(cells[i]);
	//   } else {
	// 	// If the value is empty, add an empty string to the array
	// 	filteredCells.push('');
	//   }
	// }

	const filteredCells: string[] = [];
	const totalCells = 300;  // Assuming a total of 300 cells

	for (let i = 0; i < totalCells; i++) {
		// If the value is not empty, add it to the array
		if (cells[i] !== '') {
		  filteredCells.push(cells[i]);
		} else {
		  // If the value is empty and within the range up to lastInputIdWithNonZeroValue, add -1 to the array
		  // Otherwise, add -2 to the array
		  filteredCells.push(i <= lastInputIdWithNonZeroValue ? '-1' : '-2');
		}
	  }
	
	// Determine the index after which to set the value to -2
	const lastIndex = Math.min(lastInputIdWithNonZeroValue, filteredCells.length);

	// Convert the array to numbers, ensuring valid integers
	const numericBlows = filteredCells.map((value, index) => {
		if (index > lastIndex) {
		return undefined; // or any other value you want to set for positions after lastIndex
		}
		const parsedValue = parseInt(value);
		return isNaN(parsedValue) ? -1 : parsedValue;
	});
	
	// Remove undefined values
	const finalNumericBlows = numericBlows.filter(value => value !== undefined);

	// console.log('numericBlows:', numericBlows)
	// console.log('filteredCells:', filteredCells)

	// console.log('finalNumericBlows:', finalNumericBlows)







	/////////////////////////////////////////////////////////////////////////////////////////////////////
	// Cumulative Blows :) - now dealing with cumulative blows in the back-end
	/////////////////////////////////////////////////////////////////////////////////////////////////////

	// function calculateCumulative(cells: any, lastInputIdWithNonZeroValue: number) {
	// 	const cumulativeCells: number[] = [];
	// 	let cumulativeSum = 0;
	
	// 	for (let i = 0; i <= lastInputIdWithNonZeroValue; i++) {
	// 	if (cells[i] !== '') {
	// 		cumulativeSum += parseInt(cells[i]);
	// 	}
	// 	cumulativeCells.push(cumulativeSum);
	// 	}
	
	// 	return cumulativeCells;
	// }




	/////////////////////////////////////////////////////////////////////////////////////////////////////
	// Torques
	/////////////////////////////////////////////////////////////////////////////////////////////////////


	// Convert the object to an array
	const torquesArray = Object.values(torques);

	// Create a new array with the same length, replacing empty values with ''
	const filteredTorques = torquesArray.map(value => (value !== undefined && value !== '' ? parseInt(value) : -1));

	// Parse the values directly, treating empty values as 0
	const numericTorques = torquesArray.map(value => {
		const parsedValue = parseInt(value);
		return isNaN(parsedValue) ? 0 : parsedValue;
	});
  
	// Find the last index with a valid torque value
	const lastIndexTorques = torquesArray.map(value => value !== '').lastIndexOf(true);

	// Process only the values up to the last valid entry, replacing empty values with 0
	const finalNumericTorques = torquesArray.slice(0, lastIndexTorques + 1).map(value => {
		const parsedValue = parseInt(value);
		return isNaN(parsedValue) ? 0 : parsedValue;
	});

	// console.log('lastIndexTorques:', lastIndexTorques)
	// console.log('cells:', cells)
	// console.log('numericBlows:', numericBlows)
	// console.log('finalNumericBlows:', finalNumericBlows)
	// console.log('cumulativeCells:', calculateCumulative(cells, lastInputIdWithNonZeroValue))
	// console.log('torques:', torques)
	// console.log('torquesArray:', torquesArray)
	// console.log('numericTorques:', numericTorques)
	// console.log('finalNumericTorques:', finalNumericTorques)
	// console.log('filteredTorques:', filteredTorques)
	// console.log('dp_torque_unit:', torqueDefault_temp)
	// console.log('holeId:', borehole_guid)


	// Update & Save buttons
	
	const [updateClicked, setUpdateClicked] = useState(false);

	const SaveButton = () => {
		console.log('updateClicked:', updateClicked)
		// If update button is not clicked, return null
		if (!updateClicked) return null;
		return (
			<Button theme="success" type="button" size="lg" className="ml-auto w-64 md:w-96 mr-0 md:mr-9" 
						onClick={() => {
							updateInputValues()
							// setActiveCell(activeCell === (numRows * 10) + 9 ? activeCell : activeCell + 1)
							updateInputValues()
							save()
						}}
						>Save</Button>
		);
	};

	const SaveButtonInactive = () => {
		// if (updateClicked) return null;
		return (
			<Button theme="secondary" type="button" size="lg" className="ml-2 w-64 md:w-96 mr-0 md:mr-9" 
						onClick={() => {
							updateInputValues()
							// setActiveCell(activeCell === (numRows * 10) + 9 ? activeCell : activeCell + 1)
							setUpdateClicked(true);
						}}
						>Update</Button>
		);
	};

	const UpdateButton = () => {
		if (updateClicked) return null;
		return (
			<Button theme="primary" type="button" size="lg" className="ml-auto w-64 md:w-96 mr-0 md:mr-9" 
						onClick={() => {
							// setActiveCell(activeCell === (numRows * 10) + 9 ? activeCell : activeCell + 1)
							updateInputValues()
							setUpdateClicked(true)
							updateSave()
						}}
						>Update</Button>
		);
	};


	console.log('*************************newDP?:', newDP)
	console.log('*************************guid:', guid)


	return (
		<Modal title={title} subtitle={borehole} toggleModal={onClose}>

			

		{showTorqueInfo &&
			<div className="infoContainer">
				<div className="m-auto h-80 w-60 rounded-lg border border-orange bg-white modalshadow p-2">
				<div className="flex">
					<div className="info-icon-container">
						<img className="info-icon" src={infoIcon} alt="Info" />
					</div>
					<span className="font-bold text-lg">Information</span>
					<span className="ml-auto">
						<button
						className="flex items-center justify-center w-8 h-8 text-4xl bold text-soil-grey bg-white border border-orange rounded"
						onClick={() => setShowTorqueInfo(false)}
						>
						<img src={closeIcon} width="20" alt="" />
						</button>
					</span>
					</div>

					<div className="mt-4">
						Click below to change the Torque Unit
					</div>

					<div className="mt-5">
						{/* {seatingBlowsInfoText} */}
						To permanently change the Torque Unit, go to 'Rigs', select the rig to edit and change the default 'Torque Meter Type'.
					</div>

				</div>
			</div>
			
		}

			{/* {console.log(results)} */}

			<div className="flex flex-col mt-2">

				<div className="grid grid-cols-9 md:gap-x-4 mb-2">

					<div className="col-span-2"></div>
					<div className="col-span-5 text-center text-base md:text-2xl font-bold -mt-2">Current Depth: {activeCell / 10}m</div>
					<div className="col-span-2 py-0 -mt-2 text-center align-right">
						<Button onClick={handleShowGraph}
							theme="outline"
							type="button"
							className="text-sm md:text-base lg:text-base py-1 w-12 text-left">
							<img src={graphIcon} className="h-3 md:h-6 w-10 ml-0" alt="" />
						</Button>
					</div>
				</div>

				<div className="grid md:grid-cols-12 md:gap-x-4">

					<div className="col-span-12 lg:col-span-7 mb-2">   {/* LEFT SIDE - INPUTS */}


						<div className="">
							<div className="grid grid-cols-14 gap-0 font-bold text-sm text-center bg-grey-mid rounded-t-md">
								<div className={`col-span-2 px-0 py-1 text-base`}>(m)</div>
								<div className={`col-span-10 px-2 py-1 text-base`}>Blows</div>
								<div className={`col-span-2 px-0 pt-1 text-xs md:text-xs`} onClick={() => setShowTorqueInfo(true)}>
									<div className="flex items-center justify-center w-full text-center pt-1">
										Torque <img className="ml-1" src={infoIcon} width="12"></img>
									</div>
								</div>
							</div>
						</div>

						<div className="">
							<div className="snapContainer grid grid-cols-14 gap-0 text-center overflow-auto border-b border-grey-mid dp_inputs_main_container"> {/* snapContainer */}
							
								<div className={`col-span-2 px-0`}>
									{[...Array(numRows + 1)].map((_, row) => {
										return <div className={`snapItem flex items-center justify-center py-0 text-xl md:text-3xl border-l border-b border-r border-grey-mid`}
											style={{ 'height': '82px' }} key={'rowl' + row}>{row++}</div>
									})}
								</div>

								<div className={`col-span-10`}>
									<div className="grid grid-cols-5 gap-0 text-center">
										{[...Array(5)].map((_, column) =>
											<div className={`col-span-1`} key={'column' + column}>
												<div className="w-full border-b border-grey-mid" style={{ 'height': (column * 8) + 'px' }}></div>

												{[...Array(numRows + 1)].map((_, row) =>
													<React.Fragment key={'row' + row}>
														<div className={` w-full border-b border-r border-grey-mid`}>
															<div
																className={`
																w-full text-2xl md:text-3xl pt-0.5 md:pt-0 text-center 
																${row % 2 ? 'bg-grey-light' : 'bg-white'} 
																${(activeCell === parseInt(row + '' + column) + 0 && cellNotTorque) ? 'bg-blue border-2 border-blue rounded text-white incrementHeight' : ''}
																${invalidIndices.includes(parseInt(row + '' + column) + 0) &&
																		(invalidIndices.length > 1 || invalidIndices[0] !== 0)
																		? 'ownWeight_BG'
																		: ''
																	}
																`}
																style={{ 'height': '40px' }}
																onClick={() => {
																	setActiveCell(parseInt(row + '' + column) + 0)
																	setCellNotTorque(true)
																	updateInputValues()
																	setUpdateClicked(false)
																}}
																data-row={row}
																data-column={column}
																data-value={inputValues[parseInt(row + '' + +column) + 0]}
																tabIndex={parseInt(row + '' + +column) + 0}
																id={"input" + (parseInt(row + '' + column) + 0)}
															>

																{cells[parseInt(row + '' + column) + 0]}
															</div>
														</div>
														<div className={`w-full border-b border-r border-grey-mid hidden`}> {/* hidden */}
															<div
																className={`w-full text-base pt-0.5 md:pt-0 text-center ${row % 2 ? 'bg-grey-light' : 'bg-white'} ${(activeCell === parseInt(row + '' + column) + 0 && cellNotTorque) ? 'bg-blue border-2 border-blue rounded text-white incrementHeight' : ''}`}
																style={{ 'height': '30px' }}
																id={"cumulativeValue" + (parseInt(row + '' + column) + 0)}>

																{cells[parseInt(row + '' + column) + 0]}

															</div>
														</div>

														<div className={`w-full border-b border-r border-grey-mid`}>
															<div
																className={`
																w-full text-2xl md:text-3xl pt-0.5 md:pt-0 text-center 
																${row % 2 ? 'bg-grey-light' : 'bg-white'} 
																${(activeCell === parseInt(row + '' + column) + 5 && cellNotTorque) ? 'bg-blue border-2 border-blue rounded text-white incrementHeight' : ''}
																${invalidIndices.includes(parseInt(row + '' + column) + 5) &&
																		(invalidIndices.length > 1 || invalidIndices[0] !== 0)
																		? 'ownWeight_BG'
																		: ''
																	}
																`}
																style={{ 'height': '40px' }}
																onClick={() => {
																	setActiveCell(parseInt(row + '' + column) + 5)
																	setCellNotTorque(true)
																	updateInputValues()
																	setUpdateClicked(false)
																}}
																data-row={row + 5}
																data-column={column + 5}
																data-value={inputValues[parseInt(row + '' + +column) + 5]}
																tabIndex={parseInt(row + '' + column) + 5}
																////////////////////////////////////////////////////////////
																id={"input" + (parseInt(row + '' + column) + 5)}>

																{cells[parseInt(row + '' + column) + 5]}

															</div>
														</div>
														<div className={`w-full border-b border-r border-grey-mid hidden`}> {/* hidden */}
															<div
																className={`w-full text-base pt-0.5 md:pt-0 text-center ${row % 2 ? 'bg-grey-light' : 'bg-white'} ${(activeCell === parseInt(row + '' + column) + 5 && cellNotTorque) ? 'bg-blue border-2 border-blue rounded text-white incrementHeight' : ''}`}
																style={{ 'height': '30px' }}
																id={"cumulativeValue" + (parseInt(row + '' + column) + 5)}>

																{cells[parseInt(row + '' + column) + 5]}

															</div>
														</div>
													</React.Fragment>
												)}
											</div>
										)}
									</div>
								</div>

								<div className={`col-span-2`}>
									<div className="w-full border-b border-grey-mid" style={{ 'height': '32px' }}>
										<Button onClick={textChange}
											className="bg-white border-none text-xs md:text-xs text-charcoal font-bold px-0 pt-2 text-center w-full">
												{torqueDefault_temp}
										</Button>
									</div>

									{[...Array(numRows + 1)].map((_, row) => {
										return <div
											className={`flex items-center justify-center text-2xl md:text-3xl ${(activeTorque === row && !cellNotTorque) ? 'bg-blue border-black rounded text-white' : 'border-grey-mid'} border-b border-r `}
											style={{ 'height': '82px' }}
											key={'rowr' + row}
											onClick={() => {
												setCellNotTorque(false)
												setActiveTorque(row)
												updateInputValues()
												setUpdateClicked(false)
											}}>
											<div className={`p-0`}>{torques[row]}</div>
										</div>
									})}
								</div>
							</div>



							{/* <div className="col-span-14 invisible lg:visible h-0 lg:h-8 mt-0 lg:mt-2">
								<TextArea
									label=""
									value={remarks}
									placeholder="Comments"
									onChange={(value: any) => setComment(value)}
									className="w-full mx-auto mb-2 align-text-top"
									inputCSS="overflow-x-auto text-sm h-12"
									numberOfRows={2}
								/>
							</div> */}




						</div>
					</div>

					{/* BUTTONS ETC */}

					<div className="col-span-12 lg:col-span-5"> {/* BUTTONS GRID */}

						<div className="DP_buttons_container relative">

							<div className="w-1/3 lg:w-full">

								<div className="w-full lg:w-3/5 DP_nav_blow">

									<div className="w-1/3 inline-flex"> {/* BACK BUTTON */}
										<Button theme="outline" type="button" className="w-full text-base md:text-2xl lg:text-xl py-4 md:py-10 lg:py-5  mr-1 lg:mr-2 mb-1 lg:mb-2"
											onClick={() => {
												setActiveCell(activeCell === 10 ? activeCell : activeCell - 1)
												updateInputValues()
											}}>&lt;</Button>
									</div>

									<div className="w-2/3 inline-flex"> {/* NEXT BUTTON */}
										<Button theme="outline" type="button" className="w-full text-base md:text-2xl lg:text-xl py-4 md:py-10 lg:py-5 mr-0 lg:mr-1 mb-1 lg:mb-2"
											onClick={() => {
												setActiveCell(activeCell === (numRows * 10) + 9 ? activeCell : activeCell + 1)
												updateInputValues()
											}}>Next &gt;</Button>
									</div>

									<div className=" mr-0 lg:mr-1">  {/* BLOW BUTTON */}
										<div className="w-full block">
											<Button theme="primary" type="button" size="xl" className="w-full text-base py-8 md:py-6 lg:py-10 text-2xl md:text-4xl lg:text-4xl  mr-0 lg:mr-1 mb-3 lg:mb-2 h-24 md:h-48 lg:h-36"
												onClick={() => {
													blow()
													updateInputValues()
												}}>BLOW</Button>
										</div>
									</div>

								</div>

								<div className="w-full lg:w-2/5 DP_other_buttons invisible lg:visible overflow-hidden lg:overflow-visible h-1 lg:h-56">

									<div className="pl-1 mb-2">
										<Button theme="outline" type="button" className="w-full text-xl py-5 invisible lg:visible" onClick={() => {
											let newCells = { ...cells }
											newCells[activeCell] = '0'
											setCells(newCells)
											setActiveCell(activeCell === (numRows * 10) + 9 ? activeCell : activeCell + 1)
											updateInputValues()
										}}>Own Weight</Button>
									</div>
									<div className="pl-1 mb-2">
										<Button theme="outline" type="button" className="w-full text-xl py-5 invisible lg:visible" 
										onClick={() => {
											removeBlow()
											updateInputValues()
											}}>Remove Blow</Button>
									</div>
									<div className="pl-1 mb-2">
										<Button theme="outline" type="button" className="w-full text-xl pt-4 pb-5 invisible lg:visible" 
										onClick={() => {
											clearEntry()
											updateInputValues()
											}}>Clear Entry</Button>
									</div>

								</div>

							</div>

							<div className="w-2/3 md:w-full pr-1 lg:pr-0">
								<div className="grid grid-cols-3 gap-1 lg:gap-2">  {/* NUMBER BUTTONS */}
									{keys.map((key, index) => {
										return <div className="border border-orange rounded py-0.5 md:py-4 lg:py-4 text-2xl md:text-4xl text-center" key={index}
											onClick={() => {
												addCharacter(key)
												updateInputValues()
											}}>{key}</div>
									})}

									{/* <div className="border border-orange rounded py-2 md:py-4 lg:py-4 text-2xl md:text-4xl text-center" onClick={() => removeCharacter()}>⌫</div> */}
									<div className="border border-orange rounded py-1 pt-1.5 md:py-4 lg:py-5 text-base md:text-lg text-center" 
										onClick={() => {
											removeCharacter()
											updateInputValues()
											}}>DEL</div>
								</div>
							</div>



							{toggleChart && (

								<div className=" bg-grey-mid h-10 w-full z-99 dp_chart_main_container_input_page absolute top-0 left-0 rounded-t-sm">

									<div className="text-left text-xs pl-9 sm:pl-16 lg:pl-10 font-bold">Blows</div>
									<div className="grid grid-cols-11 py-1 text-right text-charcoal text-xs -mt-2">
										<div className="col-span-1"><span className="font-bold pr-0 sm:pr-4 lg:pr-1">(m)</span> 0</div>
										<div className="col-span-1">5</div>
										<div className="col-span-1">10</div>
										<div className="col-span-1">15</div>
										<div className="col-span-1">20</div>
										<div className="col-span-1">25</div>
										<div className="col-span-1">30</div>
										<div className="col-span-1">35</div>
										<div className="col-span-1">40</div>
										<div className="col-span-1">45</div>
										<div className="col-span-1 pr-1">50</div>
									</div>

									<div className="grid grid-cols-11 text-right text-charcoal text-xs overflow-scroll dp_chart_bars_container_input_page">

										<div className="col-span-1 w-full bgFullWidth">
											{[...Array(31)].map((_, row) => {
												return <div className={`h-10 border-t border-modal-bg py-3 text-lg font-bold text-center`}
													style={{ height: '3.132rem' }}
													key={'rowl' + row}>{row++}</div>
											})}
										</div>

										<div className="col-span-10 w-full bgFullWidth pt-0.5" style={{ 'background': `url(${dp_chart_bg})` }}>


											{/* {[...Array(310)].map((_, row) => {

												var minBlows = 20;
												var maxBlows = 40;
												var randBlows = minBlows + (Math.random() * (maxBlows - minBlows))

												// return <div className="bg-charcoal" style={{ height: '0.2rem', 'width': randBlows+'%', marginBottom: '0.05rem'}} 
												
												return <div className="bg-charcoal" style={{ height: '0.25rem', 'width': randBlows + '%', marginBottom: '1px' }}
													key={'rowl' + row}>
													</div>

												// Fetch the associated input value based on the index and ID
												// const inputValue = inputValues[row];
												// const inputValue = inputValues.find(input => input.id === inputId)?.value || 0;

												// Calculate the width for the graph bar based on inputValue
												// const graphWidth = (inputValue / maxValue) * 100 || 0; // Avoid NaN or negative values

												// return (
												// 	<div
												// 	className="bg-charcoal"
												// 	style={{ height: '0.25rem', width: `${graphWidth}%`, marginBottom: '1px' }}
												// 	key={'rowl' + row}
												// 	>
												// 	</div>
												// 	)
											})} */}

											{/* <div className="bg-grey-mid w-full" style={{ height:'0.05rem'}} ></div> */}

											{graphWidths.map((width, index) => (
												<div
													className="bg-charcoal"
													style={{ width: `${width}%`, height: '0.25rem', marginBottom: '1px' }}
													key={`row${index}`}
												></div>
											))}

										</div>

									</div>

								</div>

							)}

						</div>

					</div>

					<div className="col-span-12 mt-1 lg:overflow-hidden lg:h-1 -mt-6 md:-mt-5 lg:mt-0 -z-99">

						<div className="w-full grid grid-cols-3 visible lg:invisible">

							<div className="pl-0 lg:pl-1 mb-2 inline">
								<Button theme="outline" type="button" className="w-full text-base md:text-2xl lg:text-xl py-2 md:py-6 lg:py-5" onClick={() => clearEntry()}>Clear Entry</Button>
							</div>
							<div className="pl-1 mb-2 inline">
								<Button theme="outline" type="button" className="w-full text-base md:text-2xl lg:text-xl py-2 md:py-6 lg:py-5" onClick={() => removeBlow()}>Remove Blow</Button>
							</div>
							<div className="pl-1 mb-2 inline">
								<Button theme="outline" type="button" className="w-full text-base md:text-2xl lg:text-xl py-2 md:py-6 lg:py-5" onClick={() => {
									let newCells = { ...cells }
									newCells[activeCell] = '0'
									setCells(newCells)
									setActiveCell(activeCell === (numRows * 10) + 9 ? activeCell : activeCell + 1)
									updateInputValues()
								}}>Own Weight</Button>
							</div>

							{/* <div className="col-span-3 mb-2">
								<TextArea
									label=""
									value={remarks}
									placeholder="Comments"
									onChange={(value: any) => setComment(value)}
									className="w-full lg:w-3/5 mx-auto mb-0 align-text-top"
									inputCSS="overflow-x-auto text-sm md:text-lg h-8 md:h-20"
									numberOfRows={2}
								/>
							</div> */}

						</div>

					</div>

				</div>


				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">


							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?

								<div className="flex mt-4">
									<Button theme="secondary" 
										className="mr-5 text-sm sm:text-base px-3.5 md:px-7" 
										onClick={() => setDeleteConfirmOpen(false)}>
											Cancel
									</Button>

									<Button onClick={() => {
												onClose()
												onComplete({
													guid: guid,
													update: false,
													delete: true,
												})
												setShowDynamicProbeEdit(false)
											}}
										className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">
										Delete
									</Button>
								</div>
							</div>


						</div>
					</div>
				}

				{/*<div className="flex items-center mt-10">
					<Button theme="primary" type="button" onClick={() => }>Comments</Button>
				</div>*/}

				<div className="flex items-center mt-1 md:mt-4">
					{/* <Button theme="secondary" type="button" size="lg">Delete DP</Button> */}

					<DeleteButton />

						{/* <Button theme="primary" type="button" size="lg" className="ml-auto w-28 md:w-48" 
						onClick={() => {
							updateInputValues()
						}}
						>Update</Button> */}

					<UpdateButton />

						{/* <Button theme="success" type="button" size="lg" className="ml-2 w-28 md:w-48" 
						onClick={() => {
							updateInputValues()
							setActiveCell(activeCell === (numRows * 10) + 9 ? activeCell : activeCell + 1)
							updateInputValues()
							save()
						}}
						>Save</Button> */}
					{/* <SaveButtonInactive /> */}
					<SaveButton />

				</div>
			</div>
		</Modal>
	)
}

DynamicProbe.defaultProps = defaultProps

export default DynamicProbe
