import React, { FunctionComponent, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { updateSelectedProject } from '../../actions/SelectedProject'
import { useGetIDBValues } from "../../helpers/getIDBKey"
import infoIcon from '../../assets/icons/info.png'

import InformationProject from '../../views/InformationProject'
import { useGetLoggedInUser } from '../../helpers/getLoggedInUser'

type Props = {
	className?: string,
	projectId?: string,
	information_check?: boolean,
	[x: string]: any;
}

const defaultProps: Props = {
	className: '',
	projectId: '',
	information_check: false,
}

const ProjectHeaderNoDate: FunctionComponent<Props> = ({ className, projectId, information_check, site_description, key_site_features, site_history, key_hands, expected_strata, notes, 
	equipment_required, client_name, client_contact, client_phone, client_email, engineer_name, engineer_phone, engineer_email, ...rest }) => {

	const selectedProject = useSelector((state: any) => state.selectedProject)
	const dispatch = useDispatch()

	const loggedInUser = useGetLoggedInUser()

	const [company_id, set_company_id] = useState(null)
  
	useEffect(() => {
		console.log('company_id:', (loggedInUser?.user as any)?.company_id);
		set_company_id((loggedInUser?.user as any)?.company_id)
	}, [loggedInUser]);
  
	useEffect(() => {
		updateEmptyProject(company_id);
	}, [company_id]);

	const updateEmptyProject = (company_id: any) => {
		console.log('company_id:', company_id);
		if (!company_id) {
			// console.error('company_id is not available yet.');
			return; // Exit early if company_id is not available
		}
	
		let cachedValues = useGetIDBValues; // Assuming useGetIDBValues is a function
		if (company_id) {
			cachedValues(`projects/company/${company_id}`, (data: any) => {
				for (const project of data) {
					if (project.guid === projectId) {
						dispatch(updateSelectedProject({
							guid: project.guid,
							name: project.name,
							location: project.location,
							number: project.project_identifier,
						}));
					}
				}
			});
		}
	};	

	if(selectedProject.guid === '')
		updateEmptyProject(company_id)
	
		const [showInformation, setShowInformation] = useState(false);
		
		const showInformationClick = () => {
			setShowInformation((prev) => !prev)
		}

		// console.log('selectedProject.guid (header):', selectedProject.guid)
		// console.log('selectedProject.name (header):', selectedProject.name)
		// console.log('selectedProject.start_date (header):', selectedProject.start_date)

			// is mobile?
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

	const handleResize = () => {
	  setIsMobile(window.innerWidth <= 768);
	};
  
	useEffect(() => {
	  window.addEventListener('resize', handleResize);
	  return () => {
		window.removeEventListener('resize', handleResize);
	  };
	}, []);

	// console.log('selectedProject.location:', selectedProject.location.length)

	const renderLocation = () => {
		const { location } = selectedProject;

		if (isMobile && location.length > 20) { // was 15 before removing projectID from header
			return `${location.substring(0, 20)}...`;
		} else {
		  return location;
		}
	  };
	//   console.log('renderLocation:', renderLocation())

	return (
		<>

		{showInformation && (
			<InformationProject onClose={() => { setShowInformation(false) }} 
				defaultValues={{
					site_description: site_description,
					key_site_features: key_site_features,
					site_history: site_history,
					key_hands: key_hands,
					expected_strata: expected_strata,
					notes: notes,
					equipment_required: equipment_required,
					client_name: client_name,
					client_contact: client_contact,
					client_phone: client_phone,
					client_email: client_email,
					engineer_name: engineer_name,
					engineer_phone: engineer_phone,
					engineer_email: engineer_email,
						
					// site_description:'Open field with big fence around it',
					// key_site_features:'Large muddy area in the middle',
					// site_history:'Lots of stuff done here before',
					// key_hands:'Look out for the crazy bull',
					// expected_strata:'topsoil over sand and gravels over chalk',
					// notes:'these are some notes',
					// equipment_required:'breaker and shovel',
					// client_name:'Bovis Homes',
					// client_contact:'Big Trevor',
					// client_phone:'0123456789',
					// client_email:'bigtrevor@bovishomes.com',
					// engineer_name:'Little Nigel',
					// engineer_phone:'0987654321',
					// engineer_email:'littlenigel@acmegroundinvestigations.com',
				}}
			/>
			)
		}

			<div className={`flex items-start ${className}`} {...rest}>
				<div className="flex justify-center flex-col">

					{/* <div className="text-center mb-8 pt-1">
						<div onClick={showInformationClick}>
							<div className="align-bottom inline-block text-sm md:text-base font-bold pb-4 md:pb-6" style={{lineHeight: 1.1}}> 
								{selectedProject.number} - {selectedProject.location}
							</div>
							<img src={infoIcon} width="20" className="ml-2 -mt-6 inline-block pb-2 md:pb-5" alt="" />
						</div>
					</div> */}

					<div className="text-center mb-8 pt-0">
						<div onClick={showInformationClick}>
							<div className="align-bottom inline-block text-sm md:text-base font-bold pb-0.5 md:pb-0"> {/* style={{lineHeight: 1.2}} */}
							{/* {selectedProject.number} - {renderLocation()} selectedProject.location */}
							{renderLocation()} {/* selectedProject.location */}
							</div>
							<img src={infoIcon} width="20" className="ml-1 inline-block pb-0.5" alt="" />
						</div>
					</div>

				</div>

			</div>
		</>
	)
}

ProjectHeaderNoDate.defaultProps = defaultProps

export default ProjectHeaderNoDate
