import React, { FunctionComponent, useState } from 'react'

import WorkflowSteps from '../../../WorkflowSteps'
import Modal from '../../../Modal'
import Button from '../../../form/Button'
import NumberInput from '../../../NumberInput'
import RadioSelect from '../../../form/RadioSelect'
import MultiNumberInput from '../../../MultiNumberInput'
import { v4 as uuidv4 } from 'uuid';

import stepStatus from '../../../../helpers/stepStatus'
import warningIcon from '../../../../assets/icons/warning.png'

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: string,
	defaultValues?: any,
	selectedDate?: string,
	update?: boolean,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => { },
	onComplete: () => { },
	borehole: '',
	defaultValues: {
		guid: '',
		depth_to_top: '',
		size: 'Small',
		test_type: 'Borehole',
		sv_result1: '',
		sv_result2: '',
		sv_result3: '',
		sv_result4: '',
		suitableForTesting: true,
		average: '',
		outputText: '',
		averageReading: '0',
	},
	selectedDate: '',
}

const SV: FunctionComponent<Props> = ({ onClose, update, onComplete, borehole, defaultValues, selectedDate, 
	setShowSVEdit, new_type_number, boreholeDepth, ...rest }) => {
	const [currentStep, setCurrentStep] = useState(1)

	const [guid, setGuid] = useState(defaultValues.guid)
	const [depth_to_top, setDepth_to_top] = useState(defaultValues.depth_to_top)
	const [size, setVaneSize] = useState(defaultValues.size)
	const [test_type, setVaneType] = useState(defaultValues.test_type)

	const [result1, setResult1] = useState(defaultValues.sv_result1)
	const [result2, setResult2] = useState(defaultValues.sv_result2)
	const [result3, setResult3] = useState(defaultValues.sv_result3)
	const [result4, setResult4] = useState(defaultValues.sv_result4)

	let result1_temp = ''; 
	// let result2_temp = ''; let result3_temp = ''; let result4_temp = ''; 
	if(result1 === 'undefined'){result1_temp = ''} else {result1_temp = result1}

	console.log('result1:', result1)
	console.log('result1_temp:', result1_temp)

	const [suitableForTesting, setSuitableForTesting] = useState(defaultValues.suitableForTesting)
	console.log('suitableForTesting:', suitableForTesting)
	console.log('defaultValues.suitableForTesting:', defaultValues.suitableForTesting)

	const [saveButtonText, setButtonText] = useState('Continue')
	const [saveButtonColor, setButtonColor] = useState('primary')
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	const DeleteButton = () => {
		if (!update) return null;

		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	const deleteRecordConfirm = () => {
		setDeleteConfirmOpen(false)
		// onComplete()
	}

	var numberOfTests = 0
	if (result1 > 0) { numberOfTests = numberOfTests + 1 }
	if (result2 > 0) { numberOfTests = numberOfTests + 1 }
	if (result3 > 0) { numberOfTests = numberOfTests + 1 }
	if (result4 > 0) { numberOfTests = numberOfTests + 1 }

	// console.log(numberOfTests)

	if (result1 === '') { var res1 = 0 } else { res1 = parseInt(result1) }
	if (result2 === '') { var res2 = 0 } else { res2 = parseInt(result2) }
	if (result3 === '') { var res3 = 0 } else { res3 = parseInt(result3) }
	if (result4 === '') { var res4 = 0 } else { res4 = parseInt(result4) }

	var resTotal = res1 + res2 + res3 + res4
	var resAverage = 0
	resAverage = resTotal / numberOfTests

	if (resTotal === 0) { var averageReading = 0 } else { averageReading = Math.round(resAverage * 100) / 100 }

	const [average, setResults] = useState(defaultValues.average)
	// const [average, setResults] = useState(defaultValues.averageReading)

	var outputText = ''
	let suitableForTestingButton = false
	if (suitableForTesting === false || suitableForTesting === 'false') {
		outputText = 'Not suitable for testing'
		suitableForTestingButton = false
	} else {
		outputText = '' + averageReading
		suitableForTestingButton = true
	}

	console.log('suitableForTesting:', suitableForTesting, outputText)

	// console.log(averageReading)

	const [time_of_action, setTimeOfAction] = useState(defaultValues.time_of_action)

	const isoDate = selectedDate ? `${new Date(selectedDate).toISOString().split('T')[0]} 09:00:00` : new Date().toISOString().split('.')[0]

	const previous = () => {
		if (currentStep > 1)
			setCurrentStep(currentStep - 1)
		setButtonText('Continue')
		setButtonColor('primary')
	}

	const next = () => {
		if (currentStep < 3) {
			setCurrentStep(currentStep + 1)
			if (currentStep === 2) {
				setButtonText('Save')
				setButtonColor('success')
			} else {
				setButtonText('Continue')
				setButtonColor('primary')
			}
			return
		}

		if (!depth_to_top) {
			//do nothing
		} else {

			console.log('Saving SV')

			onComplete({
				// guid: update ? guid : uuidv4(),
				// depth_to_top: depth_to_top2DP,
				// casing_depth: '',
				// depth_of_water: '',
				// depth_to_base: '',
				// seating_blows_1st_inc: 0,
				// seating_blows_2nd_inc: 0,
				// mm_over: 0,
				// mm_overSB: 0,
				// test_blows_1st_inc: 0,
				// test_blows_2nd_inc: 0,
				// test_blows_3rd_inc: 0,
				// test_blows_4th_inc: 0,
				// self_weight_penetration: '', 
				// spt_in_soft_rock: '', 
				// general_remarks: '',
				// pen_1: 0,
				// pen_2: 0,
				// pen_3: 0,
				// pen_4: 0,
				// pen_5: 0,
				// pen_6: 0,
				// seating_blows: 0,
				// total_blows: 0,
				// reported_result: '',
				// type: 'SV',
				// type_number: type_number_output,
				// utype: '',
				// ublows: 0,
				// sample_preparation: 0,
				// recovery: '',
				// size: size,
				// test_type: test_type,
				// sv_result1: '' + res1,
				// sv_result2: '' + res2,
				// sv_result3: '' + res3,
				// sv_result4: '' + res4,
				// average: outputText,
				// suitableForTesting: '' + suitableForTesting,
				// time_of_action: time_of_action ? time_of_action : isoDate,
				// update: update ? true : false,
				// create: update ? false : true,
				guid: update ? guid : uuidv4(),
				depth_to_top: depth_to_top2DP,
				casing_depth: '',
				depth_of_water: '',
				depth_to_base: '',
				seating_blows_1st_inc: 0,
				seating_blows_2nd_inc: 0,
				mm_over: 0,
				mm_overSB: 0,
				test_blows_1st_inc: 0,
				test_blows_2nd_inc: 0,
				test_blows_3rd_inc: 0,
				test_blows_4th_inc: 0,
				self_weight_penetration: '', 
				spt_in_soft_rock: '', 
				general_remarks: '',
				pen_1: 0,
				pen_2: 0,
				pen_3: 0,
				pen_4: 0,
				pen_5: 0,
				pen_6: 0,
				seating_blows: 0,
				total_blows: 0,
				reported_result: '',
				type: 'SV',
				type_number: type_number_output,
				utype: '',
				ublows: 0,
				sample_preparation: 0,
				recovery: '',
				size: size,
				test_type: test_type,
				sv_result1: '' + res1,
				sv_result2: '' + res2,
				sv_result3: '' + res3,
				sv_result4: '' + res4,
				average: outputText,
				suitableForTesting: '' + suitableForTesting,
				time_of_action: time_of_action ? time_of_action : isoDate,
				update: update ? true : false,
				create: update ? false : true,
			})

		}
	}

	console.log('test_type:', test_type)

	const MissingRequiredField = () => {
		if (currentStep === 5 && !depth_to_top) return (
			<div className="text-center bg-sptSB border border-red font-bold rounded mb-2 py-1 text-xs md:text-sm lg:text-base">
				<img src={warningIcon} alt="info" width="20" className="inline-block mr-1 pb-1" />
				Required field: 'Depth'
			</div>
		)
	
		return null
	}

	var depth_to_top2DP = parseFloat(depth_to_top).toFixed(2)
	
	var type_number_output = 0
	if (update) {
		type_number_output = defaultValues.type_number
	} else {
		type_number_output = new_type_number
	}

	return (
		<Modal title="SV" subtitle={borehole} toggleModal={onClose}>
			<div className="flex flex-col flex-grow px-1 sm:px-2 md:px-10">
				<WorkflowSteps steps={[
					{
						label: 'Depth',
						status: stepStatus(1, currentStep),
						onClick: () => (setCurrentStep(1), setButtonText('Continue'), setButtonColor('primary'))
					}, {
						label: 'Type',
						status: stepStatus(2, currentStep),
						onClick: () => (setCurrentStep(2), setButtonText('Continue'), setButtonColor('primary'))
					}, {
						label: 'Results',
						status: stepStatus(3, currentStep),
						onClick: () => (setCurrentStep(3), setButtonText('Save'), setButtonColor('success'))
					},
				]} className="mb-2" />

				<div className="flex-1 flex mx-auto w-full">
					{currentStep === 1 &&
						<NumberInput onUpdate={(value: any) => setDepth_to_top(value)} 
							defaultValue={depth_to_top} 
							title="Enter Depth (m):" 
							className="mb-10 text-xl md:text-2xl leading-5 md:leading-8"
							presets={[
								{ value: boreholeDepth, label: boreholeDepth },
							]}
							next={next}
						/>
					}

					{currentStep === 2 &&
						<div className="border border-grey-mid rounded px-2 bg-sptTB mb-4 w-full pb-2">
							<div className="mx-auto text-center text-xl">
								<div className="pb-2 text-base md:text-xl">Vane Size</div>

								<RadioSelect onUpdate={(value: any) => setVaneSize(value)}
									defaultValue={size}
									options={['Small', 'Medium', 'Large']}
									className="mb-10"
									radioCSS="py-4 text-base md:text-2xl"
								/>

								<div className="pb-2 text-base md:text-xl">Vane Type</div>

								<RadioSelect onUpdate={(value: any) => setVaneType(value)}
									defaultValue={test_type}
									options={['Borehole', 'Hand Vane', 'Pocket Pentrometer']}
									className="mb-10"
									radioCSS="py-4 text-base md:text-2xl px-0"
								/>
							</div>
						</div>
					}

					{currentStep === 3 &&
							<div className="w-full flex">

								<MultiNumberInput values={
									[
										{ defaultValue: result1_temp, onUpdate: setResult1, label: '' },
										{ defaultValue: result2, onUpdate: setResult2, label: '' },
										{ defaultValue: result3, onUpdate: setResult3, label: '' },
										{ defaultValue: result4, onUpdate: setResult4, label: '' },
									]
								}
									title="Results (kPa)"
									className="mb-2 text-base sm:text-xl md:text-xl"
									inputCSS="text-3xl sm:text-3xl md:text-4xl lg:text-4xl h-16 sm:h-16 mt-4"
									aboveInput={
										<div className="flex mx-auto w-full mt-2 mb-3">
											<div className={`text-base md:text-2xl rounded border px-2 py-2 ml-0 sm:ml-20 lg:ml-40 ${suitableForTestingButton ? 'bg-white border-orange' : 'bg-blue text-white'}`} onClick={() => setSuitableForTesting(!suitableForTesting)}>
												Not suitable for testing
											</div>

											{/* <div className="ml-auto py-2 text-2xl">Average: {((parseFloat(result1) + parseFloat(result2) + parseFloat(result3) + parseFloat(result4)) / numberOfTests)}</div> */}
											<div className="ml-auto py-2 text-lg md:text-2xl font-bold pr-0 md:pr-20 lg:pr-40">Average: {averageReading}</div>
										</div>
									} />
							</div>
					}
				</div>

				<MissingRequiredField />


				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">


							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?

								<div className="flex mt-4">
									<Button theme="secondary" 
										className="mr-5 text-sm sm:text-base px-3.5 md:px-7" 
										onClick={() => setDeleteConfirmOpen(false)}>
											Cancel
									</Button>

									<Button onClick={() => {
												onClose()
												onComplete({
													guid: guid,
													update: false,
													delete: true,
												})
												setShowSVEdit(false)
											}}
										className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">
										Delete
									</Button>
								</div>
							</div>


						</div>
					</div>
				}

				<div className="flex">
					{/* <Button theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button> */}
					<DeleteButton />

					<div className="flex ml-auto">
						<Button theme="primary" size="lg" className="px-4 sm:px-10" onClick={() => previous()}>Back</Button>
						<Button theme={saveButtonColor} size="lg" className="ml-4 px-8 sm:px-10 w-32 sm:w-60" onClick={() => next()}>{saveButtonText}</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

SV.defaultProps = defaultProps

export default SV
