import React, { FC, useEffect, useState } from 'react';
import { useGetLoggedInUser } from '../helpers/getLoggedInUser';
import { get, set } from 'idb-keyval';

import Container from './Container';
import InputGroup from '../components/form/InputGroup';

import logo from '../assets/logo.svg';
import bars from '../assets/bars.svg';

import logoOuter from '../assets/logo-icon-outer.svg';
import logoCenter from '../assets/logo-icon-center.svg';
import logoText from '../assets/logo-text.svg';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
} from 'react-router-dom';

import routes from '../routes';
import Sidebar from '../layout/Sidebar';

const projectlink = '/';

type Props = {
  toggleSidebar: () => void;
  middle?: React.ReactNode;
  dark?: boolean;
  children?: React.ReactNode;
  company_id?: number;
};

const defaultProps: Partial<Props> = {
  dark: false,
  company_id: 1,
};

const Header: FC<Props> = ({
  toggleSidebar,
  middle = null,
  dark = false,
  children = null,
  company_id = 1,
}) => {
  const loggedInUser = useGetLoggedInUser();

  const [companyInfo, setCompanyInfo] = useState({
    companyName: '',
    contactEmail: '',
    contactTelephone: '',
    companyAddress1: '',
    companyAddress2: '',
    companyAddress3: '',
    companyCounty: '',
    companyCountry: '',
    companyPostcode: '',
  });

  const [logoSrc, setLogoSrc] = useState<string>('');

  useEffect(() => {
    const getCompanyInfoFromCache = async () => {
      const response = await get('drillit-user');
      setCompanyInfo({
        companyName: response.user.company.name,
        contactEmail: response.user.company.email,
        contactTelephone: response.user.company.phone,
        companyAddress1: response.user.company.address1,
        companyAddress2: response.user.company.address2,
        companyAddress3: response.user.company.address3,
        companyCounty: response.user.company.county,
        companyCountry: response.user.company.country,
        companyPostcode: response.user.company.postcode,
      });
    };

    getCompanyInfoFromCache();
  }, []);

  useEffect(() => {
    async function fetchLogo() {
      try {
        const idbKey = `drillit-x_company-logo-${company_id}`;
        const idbLogo = await get(idbKey);
        if (idbLogo) {
          const objectURL = URL.createObjectURL(idbLogo);
          setLogoSrc(objectURL);
        } else {
          const externalLogoUrl = `https://drillitlogs.com/images/company/${company_id}.png`;
          const response = await fetch(externalLogoUrl);
          if (response.ok) {
            const blob = await response.blob();
            await set(idbKey, blob);
            const objectURL = URL.createObjectURL(blob);
            setLogoSrc(objectURL);
          } else {
            console.error('Failed to fetch the logo from external source.');
          }
        }
      } catch (error) {
        console.error('Error fetching the logo:', error);
      }
    }

    fetchLogo();
  }, [company_id]);

  const companyName = companyInfo.companyName;
  const companyTelephone = companyInfo.contactTelephone;
  const companyEmail = companyInfo.contactEmail;

  let companyAddress = companyInfo.companyAddress1;
  if (companyInfo.companyAddress2) { companyAddress += ', ' + companyInfo.companyAddress2; }
  if (companyInfo.companyAddress3) { companyAddress += ', ' + companyInfo.companyAddress3; }
  if (companyInfo.companyCounty) { companyAddress += ', ' + companyInfo.companyCounty; }
  if (companyInfo.companyCountry) { companyAddress += ', ' + companyInfo.companyCountry; }
  if (companyInfo.companyPostcode) { companyAddress += ', ' + companyInfo.companyPostcode; }

  return (
    <div className={`${dark ? 'bg-header-dark' : 'bg-header-light'} border-t-8 border-orange py-1 h-20`}>
      <Container>
        <div className="flex justify-between items-top">
          <div className="w-14 lg:w-72 h-16">
            <Link to={{ pathname: projectlink }}>
              <div className="text-center mx-auto w-14 h-14 absolute mt-0.5 items-left">
                <img src={logoOuter} className="logo-spin absolute h-14" alt="logo gear" />
                <img src={logoCenter} className="h-14 w-14 absolute" alt="logo inner" />
              </div>
            </Link>
            <div className="mt-4 invisible lg:visible w-0 lg:w-60">
              <img src={logoText} className="pl-0 md:pl-14 w-0 md:w-48 lg:w-60 pt0 md:pt-1 lg:pt-0" alt="logo" />
            </div>
          </div>

          <div className="text-charcoal text-center justify-center font-bold" style={{ fontSize: '0.65rem' }}>
		  <img
              className="h-10 w-auto mt-1.5 mb-1 border border-grey-charcoal rounded-md mx-auto"
              src={logoSrc || `https://drillitlogs.com/images/company/${company_id}.png`}
              alt="logo"
            />
            {companyName}
          </div>

          <div className="text-right w-14 lg:w-72 pt-2">
            <button onClick={toggleSidebar} className="align-right">
              <img src={bars} className="h-11 w-11" alt="" />
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
};

Header.defaultProps = defaultProps;

export default Header;
