import React, { Component } from 'react'

import Modal from '../../../components/Modal'
import ModalSmall from '../../../components/ModalSmall'
import InputGroup from '../../../components/form/InputGroup'
import InputGroupNumeric from '../../../components/form/InputGroupNumeric'
import Button from '../../../components/form/Button'
import WorkflowActions from '../../../components/form/WorkflowActions'
import NewBoreholeSaveButton from '../../../components/form/NewBoreholeSaveButton'
import NumberIncrement from '../../../components/form/NumberIncrement'

import Header from '../../../layout/Header'

import { v4 as uuidv4 } from "uuid";
import { Redirect } from "react-router-dom";
import { get, set, del } from "idb-keyval";
import { useGetIDBValues } from "../../../helpers/getIDBKey";
import { useSetIndexedDBValues } from "../../../helpers/addToIDB";
import { useSetIndexedDBValuesNewHole } from "../../../helpers/addToIDB_NewHole";
import { useSetIndexedDBValuesCounts } from "../../../helpers/addToIDB_Counts";
import { usePostToApi } from "../../../helpers/postToAPI";
import { Link } from 'react-router-dom'
import CrewList from '../../CrewList'
import RigList from '../../RigList'
import warningIcon from '../../../assets/icons/warning.png'
import ButtonOptions from '../../../components/form/ButtonOptions'
import { updateHoleInformation } from "../../../helpers/updateHoleInformation";

interface Props {
    toggleSidebar: () => void,
    router: any,
}


type BoreholeName = {
    id: number,
    guid: string,
    hole_name: string,
};


type Borehole = {
    id: number,
    guid: string,
    hole_name: string,
    activity_type: string,
};

type editingBorehole = {
    grid_northing: string;
    grid_easting: string;
    depth: string;
    samples: string;
    starting_hole_type_id: any;
    activity_type: string;
    guid: string;
    project_guid: string | undefined;
    hole_name: string;
};

interface State {
    projectCurrentStep: number,
    boreholes: any,
    clientName: string,
    clientContact: string,
    clientPhone: string,
    clientEmail: string,
    engineerName: string,
    engineerPhone: string,
    engineerEmail: string,
    siteLocation: string,
    startDate: string,
    endDate: string,
    startTime: string,
    projectNo: string,
    siteDescription: string,
    keySiteFeatures: string,
    siteHistory: string,
    keyHS: string,
    address1: string,
    address2: string,
    city: string,
    county: string,
    postcode: string,
    expectedStrata: string,
    notes: string,
    equipmentRequired: string,
    guid: string,
    company_guid: string,
    redirect: boolean,

    borehole_guid: string,
    holeName: string,
    holeName_raw: string,
    boreholeToUpdate: any,
    boreholeInformation: any,
    updatedHoleName: string,

    rig_guid: string,
    driller: string,
    driller_id: any,
    driller_cscs: string,
    updatedDriller: any,
    second_man: string,
    second_man_cscs: string,
    updatedSecondMan: any,
    currentRigValue: string,
    currentRigLabel: string,
    currentRigType: string,
    currentRigMakeModel: string,
    currentSPT_guid: string,
    currentSPT_label: string,
    currentSPT_energyRatio: string,

    incorrectRig: boolean,
    missingRig: boolean,
    missingHoleName: boolean,
    missingSPT: boolean,
    duplicateHoleName: boolean,

    showSecondMan: boolean,
    showRig: boolean,
    showPlantCheck: boolean,
    showCrewList: boolean,
    showRigList: boolean,
    crewSelectionType: string,
    hole_barcode: string,

    activity_type: string,
    starting_hole_type_id: number,
    redirect_location: string,

    // hole variables
    hole: string,
    hole_type: string,
    plantCheck: boolean,
    requiredDepth: boolean,
    otherRequirements: boolean,
    rig: boolean,
    userType: string,
    secondMan: boolean,
    plantCheckCP: boolean,
    plantCheckRT: boolean,
    plantCheckWS: boolean,
    plantCheckLink: string,
    hole_type_Select: number,
    buttonText: string,
    buttonTextSave: string,
    buttonTextFollowOn: string,
    buttonTextSaveFollowOn: string,
    buttonTextSaveFollowOnNewPrevious: string,
    followOnHole_temp: string,
    createdText: string,

    newHole: boolean,
    followOn: boolean,
    followOnHole: string,
    followOnHoleGuid: string,
    followOnHoleType: string,
    showFollowOnCable: boolean,
    showFollowOnRotary: boolean,
    showFollowOnExisting: boolean,
    showHeadTestInfo: boolean,
    showSoakawayInfo: boolean,
    followOnInputValue: string,

    newPreviousDepth: number,
    newPreviousType: string,
    newPreviousName: string,
    newPreviousGuid: string,
    newPreviousDcounts: number,
    newPreviousBcounts: number,
    newPreviousEScounts: number,
    newPreviousWScounts: number,
    newPreviousSPTcounts: number,
    newPreviousCPTcounts: number,
    newPreviousUcounts: number,
    newPreviousPScounts: number,
    newPreviousSVcounts: number,
    
    showPreviousTypeError: boolean,
    showPreviousNameError: boolean,

    filteredCableBoreholes: any,
    filteredRotaryBoreholes: any,
    filteredWindowSampleBoreholes: any,
    filtered_RT_WS_Boreholes: any,
    filtered_CP_WS_Boreholes: any,
    
    showSaveButton: boolean,
    
    company_id: any,
    
    newBoreholeToSave: any,
}

interface CreateBoreholeState {
    guid: string,
    project_guid: string,
    activity_purpose: string,
    activity_termination_reason: string,
    activity_type: string,
    driller: string,
    driller_id: any,
    driller_cscs: string,
    end_date: string,
    final_hole_depth: string,
    general_remarks: string,
    grid_easting: string,
    grid_northing: string,
    grid_ref_system: string,
    ground_level: number,
    hole_barcode: string,
    // hole_complete: string,
    hole_name: string,
    location_identifier: string,
    follow_on_guid: string,
    follow_on_name: string,
    follow_on_type: string,
    numberingType: string,
    osgb_grid_ref: string,
    rig_id: string,
    rig_name: string,
    make_model: string,
    second_man: string,
    second_man_cscs: string,
    spt_id: string,
    start_date: string,
    starting_hole_type_id: number, // 1,
    status: string,
}

class CreateBorehole extends React.Component<Props, State> {
    
    private inputRef: React.RefObject<HTMLInputElement>;

    // inputRef: React.RefObject<HTMLInputElement>;
    emptyBorehole: CreateBoreholeState;

    constructor(props: any) {
        super(props)

        // this.inputRef = React.createRef();

        this.state = {
            projectCurrentStep: 5,
            boreholes: [] as Borehole[],
            clientName: '',
            clientContact: '',
            clientPhone: '',
            clientEmail: '',
            engineerName: '',
            engineerPhone: '',
            engineerEmail: '',
            siteLocation: '',
            startDate: '',
            endDate: '',
            startTime: '',
            projectNo: '',
            siteDescription: '',
            keySiteFeatures: '',
            siteHistory: '',
            keyHS: '',
            address1: '',
            address2: '',
            city: '',
            county: '',
            postcode: '',
            expectedStrata: '',
            notes: '',
            equipmentRequired: '',
            guid: this.props.router.match.params.id,
            company_guid: '',
            redirect: false,

            borehole_guid: '',
            holeName: '',
            holeName_raw: '',
            boreholeToUpdate: [],
            boreholeInformation: [],
            updatedHoleName: '',

            rig_guid: '',
            driller: '',
            driller_id: 0,
            driller_cscs: '',
            updatedDriller: [],
            second_man: '',
            second_man_cscs: '',
            updatedSecondMan: [],
            currentRigValue: '',
            currentRigLabel: '',
            currentRigType: '',
            currentRigMakeModel: '',
            currentSPT_guid: '',
            currentSPT_label: '',
            currentSPT_energyRatio: '',

            incorrectRig: false,
            missingHoleName: false,
            missingRig: false,
            missingSPT: false,
            duplicateHoleName: false,

            showSecondMan: false,
            showRig: false,
            showPlantCheck: false,
            showCrewList: false,
            showRigList: false,
            crewSelectionType: '',
            hole_barcode: '',

            activity_type: '',
            starting_hole_type_id: 11,
            redirect_location: '',

            // hole variables
            hole: 'Cable Borehole',
            hole_type: 'Cable',
            plantCheck: false,
            requiredDepth: false,
            otherRequirements: false,
            rig: false,
            userType: '',
            secondMan: false,
            plantCheckCP: false,
            plantCheckRT: false,
            plantCheckWS: false,
            plantCheckLink: '',
            hole_type_Select: 1,
            buttonText: 'Create Borehole',
            buttonTextSave: 'Save Borehole',
            buttonTextFollowOn: 'Create Follow On Borehole',
            buttonTextSaveFollowOn: 'Save Follow On Borehole',
            buttonTextSaveFollowOnNewPrevious: 'Create Previous & Save Follow On',
            followOnHole_temp: '',
            createdText: 'Created Borehole:',

            newHole: true,
            followOn: false,
            followOnHole: '',
            followOnHoleGuid: '',
            followOnHoleType: '',
            showFollowOnCable: false,
            showFollowOnRotary: false,
            showFollowOnExisting: true,
            showHeadTestInfo: false,
            showSoakawayInfo: false,
            followOnInputValue: '',

            newPreviousDepth: 0.00,
            newPreviousType: '',
            newPreviousName: '',
            newPreviousGuid: uuidv4(),
            newPreviousDcounts: 0,
            newPreviousBcounts: 0,
            newPreviousEScounts: 0,
            newPreviousWScounts: 0,
            newPreviousSPTcounts: 0,
            newPreviousCPTcounts: 0,
            newPreviousUcounts: 0,
            newPreviousPScounts: 0,
            newPreviousSVcounts: 0,

            showPreviousTypeError: false,
            showPreviousNameError: false,

            filteredCableBoreholes: [],
            filteredRotaryBoreholes: [],
            filteredWindowSampleBoreholes: [],
            filtered_RT_WS_Boreholes: [],
            filtered_CP_WS_Boreholes: [],

            showSaveButton: false,

            company_id: '',

            newBoreholeToSave: [],
        }
        this.inputRef = React.createRef();


        this.emptyBorehole = {
            guid: uuidv4(),
            project_guid: this.props.router.match.params.id,
            activity_purpose: '',
            activity_termination_reason: '',
            activity_type: this.props.router.match.params.type,
            driller: this.state.driller,
            driller_id: this.state.driller_id,
            driller_cscs: this.state.driller_cscs,
            end_date: '',
            final_hole_depth: '',
            numberingType: '',
            general_remarks: '',
            grid_easting: '',
            grid_northing: '',
            grid_ref_system: 'OSGB',
            ground_level: 0.00,
            hole_barcode: this.state.projectNo + '-' + this.state.holeName,
            // hole_complete: '',
            hole_name: this.state.holeName.toUpperCase(),
            location_identifier: this.state.holeName,
            // follow_on_guid: this.state.followOnHoleGuid, // this.state.newPreviousGuid
            // follow_on_name: this.state.followOnHole, // this.state.newPreviousName
            // follow_on_type: this.state.followOnHoleType, // this.state.newPreviousType
            follow_on_guid: this.state.showFollowOnExisting ? this.state.followOnHoleGuid : this.state.newPreviousGuid,
            follow_on_name: this.state.showFollowOnExisting ? this.state.followOnHole : this.state.newPreviousName,
            follow_on_type: this.state.showFollowOnExisting ? this.state.followOnHoleType : this.state.newPreviousType,
            osgb_grid_ref: '',
            rig_id: this.state.currentRigValue,
            rig_name: this.state.currentRigLabel,
            make_model: this.state.currentRigMakeModel,
            second_man: this.state.second_man,
            second_man_cscs: this.state.second_man_cscs,
            spt_id: this.state.currentSPT_guid,
            start_date: '', // TODAYs date - format "2021-02-23",
            starting_hole_type_id: 1, // 1,
            status: "1",
        }

    }

    set_hole_type = (company_guid:any) => {
        const holeType = this.props.router.match.params.type



        const holeTypeMapping: Record<string, {
            starting_hole_type_id: number;
            activity_type: string;
            redirect_location: string;
            hole: string;
            hole_type: string;
            buttonTextSaveFollowOn: string;
            plantCheck: boolean;
            rig: boolean;
            secondMan: boolean;
            userType: string;
            requiredDepth: boolean;
            otherRequirements: boolean;
            plantCheckCP: boolean;
            plantCheckWS: boolean;
            plantCheckRT: boolean;
            plantCheckLink: string;
        }> = {
            CP: {
                starting_hole_type_id: 1,
                activity_type: 'Cable Percussive',
                redirect_location: 'cable-percussive',
                hole: 'Cable Borehole',
                hole_type: 'Cable',
                buttonTextSaveFollowOn: 'Save Cable Follow On Hole',
                plantCheck: true,
                rig: true,
                secondMan: true,
                userType: 'Driller',
                requiredDepth: false,
                otherRequirements: false,
                plantCheckCP: true,
                plantCheckWS: false,
                plantCheckRT: false,
                plantCheckLink: '/daily-plant-check-list-cable-percussive/' + company_guid + '/' + this.state?.guid,
            },
            WS: {
                starting_hole_type_id: 2,
                activity_type: 'Window Sample',
                redirect_location: 'window-sample',
                hole: 'Window Sample',
                hole_type: 'Window Sample',
                buttonTextSaveFollowOn: '',
                plantCheck: true,
                rig: true,
                secondMan: true,
                userType: 'Driller',
                requiredDepth: false,
                otherRequirements: false,
                plantCheckCP: false,
                plantCheckWS: true,
                plantCheckRT: false,
                plantCheckLink: '/daily-plant-check-list-window-sample/' + this.state?.company_guid + '/' + this.state?.guid,
            },
            RT: {
                starting_hole_type_id: 3,
                activity_type: 'Rotary',
                redirect_location: 'rotary',
                hole: 'Rotary Borehole',
                hole_type: 'Rotary',
                buttonTextSaveFollowOn: 'Save Rotary Follow On Hole',
                plantCheck: true,
                rig: true,
                secondMan: true,
                userType: 'Driller',
                requiredDepth: false,
                otherRequirements: false,
                plantCheckCP: false,
                plantCheckWS: false,
                plantCheckRT: true,
                plantCheckLink: '/daily-plant-check-list-rotary/' + this.state?.company_guid + '/' + this.state?.guid,
            },
            TP: {
                starting_hole_type_id: 4,
                activity_type: 'Trialpit',
                redirect_location: 'trialpit',
                hole: 'Trialpit',
                hole_type: 'Trialpit',
                buttonTextSaveFollowOn: '',
                plantCheck: false,
                rig: false,
                secondMan: false,
                userType: 'Engineer',
                requiredDepth: false,
                otherRequirements: false,
                plantCheckCP: false,
                plantCheckWS: false,
                plantCheckRT: false,
                plantCheckLink: '',
            },
            DP: {
                starting_hole_type_id: 5,
                activity_type: 'Dynamic Probe',
                redirect_location: 'dynamic-probe',
                hole: 'Dynamic Probe',
                hole_type: 'Dynamic Probe',
                buttonTextSaveFollowOn: '',
                plantCheck: true,
                rig: true,
                secondMan: true,
                userType: 'Driller',
                requiredDepth: false,
                otherRequirements: false,
                plantCheckCP: false,
                plantCheckWS: true,
                plantCheckRT: false,
                plantCheckLink: '/daily-plant-check-list-window-sample/' + this.state?.company_guid + '/' + this.state?.guid,
            },
            HT: {
                starting_hole_type_id: 6,
                activity_type: 'Head Test',
                redirect_location: 'head-test',
                hole: 'Head Test',
                hole_type: 'Head Test',
                buttonTextSaveFollowOn: '',
                plantCheck: false,
                rig: false,
                secondMan: false,
                userType: 'Engineer',
                requiredDepth: false,
                otherRequirements: false,
                plantCheckCP: false,
                plantCheckWS: false,
                plantCheckRT: false,
                plantCheckLink: '',
            },
            SA: {
                starting_hole_type_id: 7,
                activity_type: 'Soakaway',
                redirect_location: 'soakaway',
                hole: 'Soakaway',
                hole_type: 'Soakaway',
                buttonTextSaveFollowOn: '',
                plantCheck: false,
                rig: false,
                secondMan: false,
                userType: 'Engineer',
                requiredDepth: false,
                otherRequirements: false,
                plantCheckCP: false,
                plantCheckWS: false,
                plantCheckRT: false,
                plantCheckLink: '',
            },
            PT: {
                starting_hole_type_id: 8,
                activity_type: 'Plate Test',
                redirect_location: 'plate-test',
                hole: 'Plate Test',
                hole_type: 'Plate Test',
                buttonTextSaveFollowOn: '',
                plantCheck: false,
                rig: false,
                secondMan: false,
                userType: 'Engineer',
                requiredDepth: false,
                otherRequirements: false,
                plantCheckCP: false,
                plantCheckWS: false,
                plantCheckRT: false,
                plantCheckLink: '',
            },
            CB: {
                starting_hole_type_id: 9,
                activity_type: 'CBR',
                redirect_location: 'cbr',
                hole: 'CBR',
                hole_type: 'CBR',
                buttonTextSaveFollowOn: '',
                plantCheck: false,
                rig: false,
                secondMan: false,
                userType: 'Engineer',
                requiredDepth: false,
                otherRequirements: false,
                plantCheckCP: false,
                plantCheckWS: false,
                plantCheckRT: false,
                plantCheckLink: '',
            },
            TR: {
                starting_hole_type_id: 10,
                activity_type: 'TRL',
                redirect_location: 'trl',
                hole: 'TRL',
                hole_type: 'TRL',
                buttonTextSaveFollowOn: '',
                plantCheck: false,
                rig: false,
                secondMan: false,
                userType: 'Engineer',
                requiredDepth: false,
                otherRequirements: false,
                plantCheckCP: false,
                plantCheckWS: false,
                plantCheckRT: false,
                plantCheckLink: '',
            },
            LG: {
                starting_hole_type_id: 11,
                activity_type: 'Logging',
                redirect_location: 'logging',
                hole: 'Logging',
                hole_type: 'Logging',
                buttonTextSaveFollowOn: '',
                plantCheck: false,
                rig: false,
                secondMan: false,
                userType: 'Engineer',
                requiredDepth: false,
                otherRequirements: false,
                plantCheckCP: false,
                plantCheckWS: false,
                plantCheckRT: false,
                plantCheckLink: '',
            },
        };

        const selectedMapping = holeTypeMapping[holeType];

        if (selectedMapping) {
            const startingHoleTypeId = selectedMapping.starting_hole_type_id;
            const activityType = selectedMapping.activity_type;
            const redirectLocation = selectedMapping.redirect_location;
            const hole = selectedMapping.hole;
            const hole_Type = selectedMapping.hole_type;
            const plantCheck = selectedMapping.plantCheck;
            const rig = selectedMapping.rig;
            const secondMan = selectedMapping.secondMan;
            const userType = selectedMapping.userType;
            const requiredDepth = selectedMapping.requiredDepth;
            const otherRequirements = selectedMapping.otherRequirements;
            const plantCheckCP = selectedMapping.plantCheckCP;
            const plantCheckWS = selectedMapping.plantCheckWS;
            const plantCheckRT = selectedMapping.plantCheckRT;
            const plantCheckLink = selectedMapping.plantCheckLink;

            this.setState({
                starting_hole_type_id: startingHoleTypeId,
                activity_type: activityType,
                redirect_location: redirectLocation,
                hole: hole,
                hole_type: hole_Type,
                plantCheck: plantCheck,
                rig: rig,
                secondMan: secondMan,
                userType: userType,
                requiredDepth: requiredDepth,
                otherRequirements: otherRequirements,
                plantCheckCP: plantCheckCP,
                plantCheckWS: plantCheckWS,
                plantCheckRT: plantCheckRT,
                plantCheckLink: plantCheckLink,

                buttonText: 'Create ' + hole,
                buttonTextSave: 'Save ' + hole,
            });

            if (holeType === 'CP' || holeType === 'WS' || holeType === 'RT' || holeType === 'DP') {
                this.setState({
                    showSecondMan: true,
                    showRig: true,
                    showPlantCheck: true,
                })
            }

        } else {
            console.log('Invalid hole type');
        }
    }

    getCacheValues = async () => {
        let cachedValues = useGetIDBValues
        // get cached hole locations
        await cachedValues(`hole-locations/project/${this.state?.guid}`, (data: any) => {

            if (data){
                // console.log('')
                this.setState({
                    boreholes: data
                })

                // Filter the boreholes based on activity_type
                const filteredCableBoreholes = data.filter((borehole: any) => borehole.activity_type === 'Cable Percussive');
                const filteredRotaryBoreholes = data.filter((borehole: any) => borehole.activity_type === 'Rotary');
                const filteredWindowSampleBoreholes = data.filter((borehole: any) => borehole.activity_type === 'Window Sample');

                const filtered_RT_WS_Boreholes = data.filter((borehole: Borehole) =>
                borehole.activity_type === 'Rotary' || borehole.activity_type === 'Window Sample'
                );

                const filtered_CP_WS_Boreholes = data.filter((borehole: Borehole) =>
                borehole.activity_type === 'Cable Percussive' || borehole.activity_type === 'Window Sample'
                );

                // Set the filtered boreholes in the state
                this.setState({
                    filteredCableBoreholes: filteredCableBoreholes,
                    filteredRotaryBoreholes: filteredRotaryBoreholes,
                    filteredWindowSampleBoreholes: filteredWindowSampleBoreholes,
                    filtered_RT_WS_Boreholes: filtered_RT_WS_Boreholes,
                    filtered_CP_WS_Boreholes: filtered_CP_WS_Boreholes,
                });

                // CABLE FOLLOW ON
                if (this.state.starting_hole_type_id === 1 && this.state.filtered_RT_WS_Boreholes) {
                    this.setState({showFollowOnCable: true})
                }

                // ROTARY FOLLOW ON
                if (this.state.starting_hole_type_id === 3 && this.state.filtered_CP_WS_Boreholes) {
                    this.setState({showFollowOnRotary: true})
                }
            }
        })
            .then()
        await cachedValues(`projects/${this.state?.guid}`, (data: any) => {
            this.setState({
                clientName: data?.client_name,
                clientContact: data?.client_contact,
                clientPhone: data?.client_phone,
                clientEmail: data?.client_email,
                engineerName: data?.engineer_name,
                engineerPhone: data?.engineer_phone,
                engineerEmail: data?.engineer_email,
                siteLocation: data?.location,
                startDate: data?.start_date,
                endDate: data?.end_date,
                startTime: data?.start_time,
                projectNo: data?.project_identifier,
                siteDescription: data?.site_description,
                keySiteFeatures: data?.key_site_features,
                siteHistory: data?.site_history,
                keyHS: data?.key_hands,
                address1: data?.address,
                address2: data?.address2,
                city: data?.town_city,
                county: data?.county_state,
                postcode: data?.postcode,
                expectedStrata: data?.expected_strata,
                notes: data?.notes,
                equipmentRequired: data?.equipment_required,
                company_guid: data?.company_guid,
            })
        }).then()


        // get the current rig if it's set
        get(`drillit-currentRig`)
            .then(data => {
                // console.log('currentRig data:', data)
                if (data) {

                    this.setState({
                        currentRigValue: data?.guid,
                        currentRigLabel: data?.name,
                        currentRigMakeModel: data?.make_model,
                        currentSPT_guid: data?.sptGuid,
                        currentSPT_label: data?.sptID,
                        currentSPT_energyRatio: data.sptEnergyRatio,
                        currentRigType: data?.type,
                    })

                }
            }).then()

        // type Rig = {
        //     type: string
        //     spt: any
        //     guid: string;
        //     spt_guid: string;
        // };

        // type RigsArray = Rig[];

        // // get the current rig SPT ID
        // await cachedValues('rigs/' + this.state?.currentRigValue, (rigData: RigsArray) => {
        //     console.log('rigData from cachedValues:', rigData)
        //     if (rigData) {
        //         console.log('rigData:', rigData)
        //         const desiredRigGuid = this.state?.currentRigValue;
        //         const filteredRig = rigData.find((rig) => rig.guid === desiredRigGuid);

        //         if (filteredRig) {
        //             console.log('Filtered Rig:', filteredRig);

        //             this.setState({
        //                 currentSPT_guid: filteredRig?.spt_guid,
        //                 currentSPT_label: filteredRig?.spt?.id_ref,
        //                 currentSPT_energyRatio: filteredRig?.spt?.energy_ratio,
        //                 currentRigType: filteredRig?.type,
        //             });
        //         } else {
        //             // console.log(`**ERROR** RIG DATA NOT LOADED. ID: ${currentRigValue}`);
        //         }
        //         // console.log('rigData:', rigData);
        //     }
        // });

        this.updateBoreholes(this.state?.boreholeToUpdate)
        this.getDriller()
        this.getSecondMan()
        // this.getCurrentSPTdetails()
        this.checkRigType()



        this.setState({
            hole_barcode: this.state.projectNo + '-' + this.state.holeName
        })

    }

    // Helper function to use setState with async/await
    setStateAsync(state: any) {
        return new Promise<void>((resolve) => {
            this.setState(state, () => resolve());
        });
    }

    // getCurrentSPTdetails = async () => {
    //     type Rig = {
    //         type: string;
    //         spt: any;
    //         guid: string;
    //         spt_guid: string;
    //     };
    
    //     let cachedValues = useGetIDBValues;
    
    //     try {
    //         const rigData = await new Promise<Rig[]>((resolve, reject) => {
    //             cachedValues(`rigs/${this.state.currentRigValue}`, (data: Rig[] | undefined) => {
    //                 if (data) {
    //                     resolve(data);
    //                 } else {
    //                     reject(new Error('No rig data found'));
    //                 }
    //             });
    //         });
    
    //         console.log('rigData from cachedValues:', rigData);
    
    //         const desiredRigGuid = this.state.currentRigValue;
    //         const filteredRig = Array.isArray(rigData) ? rigData.find((rig) => rig.guid === desiredRigGuid) : null;
    
    //         if (filteredRig) {
    //             console.log('Filtered Rig:', filteredRig);
    
    //             this.setState({
    //                 currentSPT_guid: filteredRig.spt_guid,
    //                 currentSPT_label: filteredRig.spt?.id_ref,
    //                 currentSPT_energyRatio: filteredRig.spt?.energy_ratio,
    //                 currentRigType: filteredRig.type,
    //             });
    //         } else {
    //             console.error(`**ERROR** RIG DATA NOT LOADED. ID: ${desiredRigGuid}`);
    //         }
    //     } catch (error) {
    //         console.error('Error fetching rig data:', error);
    //     }

    //     this.checkRigType()
    // };

    // getCurrentRig = () => {
    //     let cachedValues = useGetIDBValues
    //     // get the current rig if it's set
    //     get(`drillit-currentRig`)
    //     .then(data => {
    //         if (data) {

    //             this.setState({
    //                 currentRigValue: data.guid,
    //                 currentRigLabel: data.name,
    //             })

    //         }
    //     }).then()

    //     type Rig = {
    //     type: string
    //     spt: any
    //     guid: string;
    //     spt_guid: string;
    //     };

    //     type RigsArray = Rig[];

    //     // get the current rig SPT ID
    //     await cachedValues('rigs/' + this.state?.currentRigValue, (rigData: RigsArray) => {
    //     if (rigData) {
    //         console.log('rigData:', rigData)
    //         const desiredRigGuid = this.state?.currentRigValue;
    //         const filteredRig = rigData.find((rig) => rig.guid === desiredRigGuid);

    //         if (filteredRig) {
    //             console.log('Filtered Rig:', filteredRig);

    //             this.setState({
    //                 currentSPT_guid: filteredRig?.spt_guid,
    //                 currentSPT_label: filteredRig?.spt?.id_ref,
    //                 currentSPT_energyRatio: filteredRig?.spt?.energy_ratio,
    //                 currentRigType: filteredRig?.type,
    //             });
    //         } else {
    //             // console.log(`**ERROR** RIG DATA NOT LOADED. ID: ${currentRigValue}`);
    //         }
    //         // console.log('rigData:', rigData);
    //     }
    //     });
    // }

    checkRigType = () => {
        const { starting_hole_type_id, currentRigType } = this.state;
    
        // Check if the rig type matches the hole type
        let incorrectRig = false;
    
        if ((starting_hole_type_id === 1 && currentRigType !== 'Cable Percussive') ||
            (starting_hole_type_id === 2 && currentRigType !== 'Window Sample') ||
            (starting_hole_type_id === 3 && currentRigType !== 'Rotary') ||
            (starting_hole_type_id === 5 && currentRigType !== 'Window Sample')) {
            incorrectRig = true;
        }
    
        // Update the state based on the check
        this.setState({ incorrectRig });

        if (this.state.currentRigLabel !== '') {
            this.setState({ missingRig: false })
        }
    }

    componentDidMount() {
        this.getCacheValues()
        this.set_hole_type(this.state?.company_guid)
        this.checkRigType()
        // Focus the input field when the component mounts
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    };

    // componentDidMount() {
    //     this.getCacheValues()
    //         .then(() => this.set_hole_type(this.state?.company_guid))
    //         .then(() => this.checkRigType())
    //         .catch((error) => console.error('Error in componentDidMount:', error));
    // }

    componentDidUpdate(prevProps:any, prevState:any) {
        if (prevState.currentRigType !== this.state.currentRigType ||
            prevState.starting_hole_type_id !== this.state.starting_hole_type_id) {
            this.checkRigType();
        }
    }

    next = (event: any) => {
        if (this.state.projectCurrentStep < 5) {
            this.setState({ projectCurrentStep: this.state.projectCurrentStep + 1 })

            return
        }

        // Form completed
        event.preventDefault()
        this.save()
    }




    save = async () => {

        // console.log('Boreholes on Save:', this.state.boreholes)
        if ((this.state.holeName !== '') && (this.state.boreholes.length > 0)) {

            this.setState({
                holeName: this.state.holeName.toUpperCase() // change to uppercase
            });

            const token = localStorage.getItem('drillit-token')

            fetch(`${process.env.REACT_APP_API_ADDR}hole-locations/project/json`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                mode: 'cors', // Explicitly allow CORS
                credentials: 'same-origin',
                body: JSON.stringify(this.state.boreholes)
            })
                //return the status code here
                .then(response => response)
                .catch(err => err)


        }

        let saveToIDB = useSetIndexedDBValues
        let saveToIDBNewHole = useSetIndexedDBValuesNewHole
        let saveToIDBCounts = useSetIndexedDBValuesCounts

        const newBoreholeToSave = this.state.newBoreholeToSave;
        const actionCounts = {
            guid: this.emptyBorehole.guid,
            project_guid: this.emptyBorehole.project_guid,
            hole_type: this.state.activity_type,
            hole_name: this.state.holeName,
            count_D: 0,
            count_B: 0,
            count_ES: 0,
            count_WS: 0,
            count_SPT: 0,
            count_CPT: 0,
            count_SPTCPT: 0,
            count_U: 0,
            count_PS: 0,
            count_SV: 0,
            total_depth: 0.00
        };
        
        const endPointActionCounts = 'action_counts';

        // console.log('newBoreholeToSave ************************************:', newBoreholeToSave)
        
        // // Save the new Borehole to IndexedDB first
        // saveToIDBNewHole(newBoreholeToSave, 'hole-locations')
        //     .then(() => {
        //         console.log('New borehole saved successfully');
                
        //         // Now save the ActionCounts to IndexedDB
        //         return saveToIDBCounts(actionCounts, endPointActionCounts);
        //     })
        //     .then(() => {
        //         console.log('ActionCounts saved successfully');
        //     })
        //     .catch((error: Error) => {
        //         console.error("An error occurred:", error);
        //         // Handle the error, e.g., display an error message to the user
        //     })

        try {
            await saveToIDBNewHole(newBoreholeToSave, 'hole-locations', true);
            // console.log('New borehole saved successfully');
            
            await saveToIDBCounts(actionCounts, 'action_counts', true);
            // console.log('ActionCounts saved successfully');

            this.setState({ redirect: true })
        } catch (error) {
            // console.error("An error occurred:", error);
            // Handle the error, e.g., display an error message to the user
        }
            // .then(() => this.setState({ redirect: true }))
    }

    saveFormAndNext = () => {
        this.save();  // Call saveForm logic
    };

    handleHoleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Update the updatedHoleName in the state when the input value changes
        this.setState({ holeName: event.target.value });

    };

    handleUpdateClick = () => {
        const { boreholes, boreholeToUpdate, holeName } = this.state;

        // Update the boreholeToUpdate with the new hole_name
        const updatedBorehole: Borehole = {
            ...boreholeToUpdate,
            hole_name: holeName,
        };

        // Find the index of the boreholeToUpdate in the boreholes array
        const updatedBoreholeIndex = boreholes.findIndex(
            (borehole: any) => borehole.guid === boreholeToUpdate.guid
        );

        // Create a new array with the updated borehole at the correct index
        const updatedBoreholes = [
            ...boreholes.slice(0, updatedBoreholeIndex),
            updatedBorehole,
            ...boreholes.slice(updatedBoreholeIndex + 1),
        ];

        this.setState({ boreholes: updatedBoreholes });

        // Update Hole Info

        this.setState((prevState) => ({
            boreholeInformation: {
                ...prevState.boreholeInformation,
                rig_id: this.state.currentRigValue,
                rig_name: this.state.currentRigLabel,
                make_model: this.state.currentRigMakeModel,
                spt_id: this.state.currentSPT_guid,
                hole_barcode: this.state.projectNo.toUpperCase() + '-' + this.state.holeName.toUpperCase(),
                location_identifier: this.state.holeName,

            },
        }), () => {
            
            let saveToIDB = useSetIndexedDBValues;
            const hole = `hole-locations/${this.state.borehole_guid}`;

            //   hole-locations/7be2d690-995e-4c28-930f-712f76ae5090 - Proj 777 - BH045

            // console.log('this.state.boreholeInformation sent to IDB: ', this.state.boreholeInformation)

            //   saveToIDB('updated values', hole, false, 'PUT')
            saveToIDB(
                {...this.state.boreholeInformation,},
                hole,
                false,
                'PUT' // ('Add to IDB PROPS: values:', values, 'idbKey', idbKey, 'create:', create, 'update:', update)
            );
        });


    };


    getDriller = () => {
        get('drillit-user').then(data => {
            // console.log('drillit-user data:', data)
            // console.log('drillit-user data cscs:', data.cscs_no)
            this.setState({
                driller: data.user.first_name + ' ' + data.user.last_name,
                driller_id: data.user.id,
                driller_cscs: data.user.cscs_no,
                company_id: data.user.company_id,
            })
        })
    }


    getSecondMan = () => {
        get('drillit-secondman').then(secondmanData => {
            if (secondmanData) {
                const firstName = secondmanData?.first_name
                const lastName = secondmanData?.last_name
                const fullName = `${firstName} ${lastName}`
                const cscs = secondmanData?.cscs_no
                this.setState({
                    second_man: fullName,
                    second_man_cscs: cscs,
                })
            }
        })
    }

    // Function to update boreholes array
    updateBoreholes = (updatedBorehole: Borehole) => {
        const { boreholes } = this.state;

        const updatedBoreholes: Borehole[] = boreholes.map((borehole: Borehole) =>
            borehole.guid === updatedBorehole.guid ? updatedBorehole : borehole
        );
    };

    handleCrewListClose = () => {
        this.setState({ showCrewList: false });
    };

    handleRigListClose = () => {
        this.setState({ showRigList: false });
    };

    handleCrewSelect = (selectedUser: any) => {

        if (this.state.crewSelectionType === 'driller') {
            this.setState({ updatedDriller: selectedUser });

            const firstName = selectedUser?.first_name;
            const lastName = selectedUser?.last_name;
            const fullName = `${firstName} ${lastName}`;
            const cscs = selectedUser?.cscs_no;
            const driller_id = selectedUser?.driller_id;

            this.setState(
                { 
                    driller: fullName,
                    driller_cscs: cscs,
                    driller_id: driller_id,
                 },
                () => {
                    this.handleDrillerUpdate();
                }
            );


        } else if (this.state.crewSelectionType === 'secondMan') {
            this.setState({ updatedSecondMan: selectedUser });
            const firstName = selectedUser?.first_name
            const lastName = selectedUser?.last_name
            const fullName = `${firstName} ${lastName}`
            const cscs = selectedUser?.cscs_no;
            this.setState(
                { 
                    second_man: fullName,
                    second_man_cscs: cscs,
                 },
                () => {
                    this.handleSecondManUpdate(); 
                }
            );
        }
    }

    handleRigSelect = (selectedRig: any) => {
		// console.log('selectedRig************************************:', selectedRig)

		const rigGuid = selectedRig?.guid;
		const rigName = selectedRig?.name;
		const rigType = selectedRig?.type;
		const rigMakeModel = selectedRig?.make_model;
		const sptGuid = selectedRig?.spt_guid;
		const sptLabel = selectedRig?.spt?.id_ref;
		const sptEnergyRatio = selectedRig?.spt?.energy_ratio;

            this.setState(
                { 
                    currentRigValue: rigGuid,
                    currentRigLabel: rigName,
                    currentRigType: rigType,
                    currentRigMakeModel: rigMakeModel,
                    currentSPT_guid: sptGuid,
                    currentSPT_label: sptLabel,
                    currentSPT_energyRatio: sptEnergyRatio,
                 })
                 
        this.checkRigType()

		const cR = {
			guid: this.state.currentRigValue,
			name: this.state.currentRigLabel,
			type: this.state.currentRigType,
			make_model: this.state.currentRigMakeModel,
			sptGuid: this.state.currentSPT_guid,
			sptID: this.state.currentSPT_label,
			sptEnergyRatio: this.state.currentSPT_energyRatio,
		}
		// return set(`drillit-currentRig-${this.props.router.match.params.project}`, cR).then(() => {
		return set(`drillit-currentRig`, cR).then(() => {
			// Additional actions after rig has been saved
			// console.log('Rig saved successfully');
			// Perform any other actions or state updates here
		  })
		  .catch((error) => {
			// console.error('Error saving rig:', error);
			// Handle the error if needed
		  });

    }

    updateDriller = (newDriller: any, newDrillerCSCS:any, newDrillerID:any) => {
        // console.log('newDriller:', newDriller)
        // console.log('newDrillerCSCS:', newDrillerCSCS)
        // console.log('newDrillerID:', newDrillerID)
        this.setState((prevState) => ({
            boreholeInformation: {
                ...prevState.boreholeInformation,
                driller: newDriller,
                driller_cscs: newDrillerCSCS,
                driller_id: newDrillerID,
            },
        })
        );
    };

    handleDrillerUpdate = () => {
        const newDriller = this.state.driller;
        const newDrillerCSCS = this.state.driller_cscs;
        const newDrillerID = this.state.driller_id;
        this.updateDriller(newDriller, newDrillerCSCS, newDrillerID);
    };

    updateSecondMan = (newSecondMan: any, newSecondManCSCS:any) => {

        this.setState((prevState) => ({
            boreholeInformation: {
                ...prevState.boreholeInformation,
                second_man: newSecondMan,
                second_man_cscs: newSecondManCSCS,

            },
        })
        );
    };

    handleSecondManUpdate = () => {
        const newSecondMan = this.state.second_man;
        const newSecondManCSCS = this.state.second_man_cscs;
        this.updateSecondMan(newSecondMan, newSecondManCSCS);
    };

    handleCancelBoreholeClick = () => {
        this.setState({
            showSaveButton: false
        })
    }

    handleCreateBoreholeClick = () => {

        const { starting_hole_type_id, currentRigLabel, holeName, driller, driller_id, driller_cscs, second_man, second_man_cscs, currentRigValue, currentSPT_guid, projectNo, activity_type, boreholes } = this.state;
    

        if (this.state.holeName === '') {
            this.setState({
                missingHoleName: true
            });
        } else {
            this.setState({
                missingHoleName: false
            });
        }

        // Check for duplicate hole name
        // const isDuplicate = boreholes.some((borehole: any) => borehole.hole_name === holeName);
        const isDuplicate = boreholes.some((borehole: any) => 
            borehole.hole_name.toUpperCase() === holeName.toUpperCase()
        );
        console.log('holeName in create:', holeName)

        if (isDuplicate) {
            this.setState({
                duplicateHoleName: true
            });
            return; // Exit the function
        } else {
            this.setState({
                duplicateHoleName: false
            });
        }

        // Check if starting_hole_type_id is 1, 2, 3, or 5 and currentRigLabel is missing
        if ([1, 2, 3, 5].includes(starting_hole_type_id) && !currentRigLabel) {
            // alert('Missing RIG: Please select a rig before proceeding.');
            this.setState({
                missingRig: true
            });
            return; // Exit the function
        } else {
            this.setState({
                missingRig: false
            });
        }

        // Check if SPT details are in place
        if (currentRigLabel && !this.state.currentSPT_label) {
            this.setState({
                missingSPT: true
            });
            return; // Exit the function
        } else {
            this.setState({
                missingSPT: false
            });
        }

                if (this.state.holeName !== '' && this.state.duplicateHoleName === false) {
        
                    this.setState({
                        holeName: this.state.holeName.toUpperCase() // change to uppercase
                    });
        
                    // console.log('this.state.holeName on SAVE', this.state.holeName)
        
                    const currentDate = new Date();
                    const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
        
                    // Now, formattedDate contains the current date in the format 'yyyy-mm-dd'
                    // console.log('Formatted Date:', formattedDate);
        
                    // console.log('this.state.driller_cscs AFTER UPDATE:', this.state.driller_cscs);
        
                    // Create a copy of emptyBorehole with updated properties if needed
                    const newBorehole = {
                        ...this.emptyBorehole,
                        driller: this.state.driller,
                        driller_id: this.state.driller_id,
                        driller_cscs: this.state.driller_cscs,
                        second_man: this.state.second_man,
                        second_man_cscs: this.state.second_man_cscs,
                        rig_id: this.state.currentRigValue,
                        rig_name: this.state.currentRigLabel,
                        make_model: this.state.currentRigMakeModel,
                        spt_id: this.state.currentSPT_guid,
                        hole_barcode: this.state.projectNo.toUpperCase() + '-' + this.state.holeName.toUpperCase(),
                        hole_name: this.state.holeName.toUpperCase(),
                        location_identifier: this.state.holeName.toUpperCase(),
                        follow_on_guid: this.state.followOnHoleGuid,
                        follow_on_name: this.state.followOnHole,
                        follow_on_type: this.state.followOnHoleType,
                        numberingType: '',
                        starting_hole_type_id: this.state.starting_hole_type_id,
                        activity_type: this.state.activity_type,
                        start_date: formattedDate, // TODAYs date - format "2021-02-23",
                    };
        
                    // Log the newBorehole to the console
                    // console.log('New Borehole:', newBorehole);
        
                    const existingBoreholes = this.state.boreholes;
        
                    // Amalgamate the new borehole into the existing boreholes
                    const updatedBoreholes = [...existingBoreholes, newBorehole];
        
                    // Log the updated boreholes array to the console
                    // console.log('Updated Boreholes:', updatedBoreholes);
        
                    // Set the state with the updated boreholes array
                    this.setState({
                        newBoreholeToSave: newBorehole,
                        boreholes: updatedBoreholes,
                    });
        
                    this.setState({
                        showSaveButton: true
                    })
        
                }
    }

    handleFollowOnClick = () => {
        this.setState({
            newHole: false
        })
    }






    handleDCountChange = (newCount:any) => {
        this.setState({ newPreviousDcounts: newCount });
    };

    handleBCountChange = (newCount:any) => {
        this.setState({ newPreviousBcounts: newCount });
    };

    handleESCountChange = (newCount:any) => {
        this.setState({ newPreviousEScounts: newCount });
    };

    handleWSCountChange = (newCount:any) => {
        this.setState({ newPreviousWScounts: newCount });
    };

    handleSPTCountChange = (newCount:any) => {
        this.setState({ newPreviousSPTcounts: newCount });
    };

    handleCPTCountChange = (newCount:any) => {
        this.setState({ newPreviousCPTcounts: newCount });
    };

    handleUCountChange = (newCount:any) => {
        this.setState({ newPreviousUcounts: newCount });
    };

    handlePSCountChange = (newCount:any) => {
        this.setState({ newPreviousPScounts: newCount });
    };

    handleSVCountChange = (newCount:any) => {
        this.setState({ newPreviousSVcounts: newCount });
    };

    handleNewHoleDepthChange = (value: any) => {
        this.setState({ newPreviousDepth: value });
    };

    handleNewHoleTypeChange = (value: any) => {
        this.setState({ 
            newPreviousType: value,
            showPreviousTypeError: false,
        });
    };



    handleCreateBoreholeNewPreviousHoleClick = () => {

        // console.log('this.state.starting_hole_type_id on SAVE', this.state.starting_hole_type_id)
        // console.log('this.state.currentRigLabel on SAVE', this.state.currentRigLabel)
        // console.log('this.state.borehole_guid on SAVE', this.state.borehole_guid)
        // console.log('this.emptyBorehole.guid on SAVE', this.emptyBorehole.guid)
        // console.log('this.emptyBorehole on SAVE', this.emptyBorehole)

        const { starting_hole_type_id, currentRigLabel, holeName, driller, driller_id, driller_cscs, second_man, second_man_cscs, currentRigValue, currentSPT_guid, projectNo, activity_type, boreholes } = this.state;
    

        if (this.state.holeName === '') {
            this.setState({
                missingHoleName: true
            });
        } else {
            this.setState({
                missingHoleName: false
            });
        }

        // Check if starting_hole_type_id is 1, 2, 3, or 5 and currentRigLabel is missing
        if ([1, 2, 3, 5].includes(starting_hole_type_id) && !currentRigLabel) {
            // alert('Missing RIG: Please select a rig before proceeding.');
            this.setState({
                missingRig: true
            });
            return; // Exit the function
        } else {
            this.setState({
                missingRig: false
            });
        }

        // // Check if newPreviousType is missing
        // if (!this.state.newPreviousType) {
        //     this.setState({
        //         showPreviousTypeError: true
        //     });
        //     return; // Exit the function
        // } else {
        //     this.setState({
        //         showPreviousTypeError: false
        //     });
        // }

        // // Check if newPreviousName is missing
        // if (!this.state.newPreviousName) {
        //     this.setState({
        //         showPreviousNameError: true
        //     });
        //     return; // Exit the function
        // } else {
        //     this.setState({
        //         showPreviousNameError: false
        //     });
        // }

        // Combined checks for newPreviousType and newPreviousName
        const { newPreviousType, newPreviousName } = this.state;

        if (!newPreviousType || !newPreviousName) {
            this.setState({
                showPreviousTypeError: !newPreviousType,
                showPreviousNameError: !newPreviousName,
            });
            return; // Exit the function
        } else {
            this.setState({
                showPreviousTypeError: false,
                showPreviousNameError: false,
            });
        }

                if (this.state.holeName !== '') {

                    // create counts for New Previous Hole

                    this.handleCreateNewPreviousHoleCounts()
        
                    this.setState({
                        holeName: this.state.holeName.toUpperCase() // change to uppercase
                    });
        
                    // console.log('this.state.holeName on SAVE', this.state.holeName)
        
                    const currentDate = new Date();
                    const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
        
                    // Now, formattedDate contains the current date in the format 'yyyy-mm-dd'
                    // console.log('Formatted Date:', formattedDate);
        
                    // console.log('this.state.driller_cscs AFTER UPDATE:', this.state.driller_cscs);
        
                    // Create a copy of emptyBorehole with updated properties if needed
                    const newBorehole = {
                        ...this.emptyBorehole,
                        driller: this.state.driller,
                        driller_id: this.state.driller_id,
                        driller_cscs: this.state.driller_cscs,
                        second_man: this.state.second_man,
                        second_man_cscs: this.state.second_man_cscs,
                        rig_id: this.state.currentRigValue,
                        rig_name: this.state.currentRigLabel,
                        make_model: this.state.currentRigMakeModel,
                        spt_id: this.state.currentSPT_guid,
                        hole_barcode: this.state.projectNo.toUpperCase() + '-' + this.state.holeName.toUpperCase(),
                        hole_name: this.state.holeName.toUpperCase(),
                        location_identifier: this.state.holeName.toUpperCase(),
                        follow_on_guid: this.state.newPreviousGuid,
                        follow_on_name: this.state.newPreviousName.toUpperCase(),
                        follow_on_type: this.state.newPreviousType,
                        numberingType: '',
                        starting_hole_type_id: this.state.starting_hole_type_id,
                        activity_type: this.state.activity_type,
                        start_date: formattedDate, // TODAYs date - format "2021-02-23",
                    };
        
                    // Log the newBorehole to the console
                    // console.log('New Borehole:', newBorehole);
        
                    const existingBoreholes = this.state.boreholes;
        
                    // Amalgamate the new borehole into the existing boreholes
                    const updatedBoreholes = [...existingBoreholes, newBorehole];
        
                    // Log the updated boreholes array to the console
                    // console.log('Updated Boreholes:', updatedBoreholes);
        
                    // Set the state with the updated boreholes array
                    this.setState({
                        boreholes: updatedBoreholes,
                    });
        
                    this.setState({
                        showSaveButton: true
                    })
        
                }
    }
    

    
    handleCreateNewPreviousHoleCounts = () => {

        let saveToIDB = useSetIndexedDBValues

        // Create action_counts

        const actionCounts = {
            guid: this.state.newPreviousGuid,
            project_guid: this.emptyBorehole.project_guid,
            hole_type: this.state.newPreviousType,
            hole_name: this.state.newPreviousName,
            count_D: Number(this.state.newPreviousDcounts),
            count_B: Number(this.state.newPreviousBcounts),
            count_ES: Number(this.state.newPreviousEScounts),
            count_WS: Number(this.state.newPreviousWScounts),
            count_SPT: Number(this.state.newPreviousSPTcounts),
            count_CPT: Number(this.state.newPreviousCPTcounts),
            count_SPTCPT: Number(this.state.newPreviousSPTcounts + this.state.newPreviousCPTcounts),
            count_U: Number(this.state.newPreviousUcounts),
            count_PS: Number(this.state.newPreviousPScounts),
            count_SV: Number(this.state.newPreviousSVcounts),
            total_depth: Number(this.state.newPreviousDepth),
        };

        const endPoint = 'action_counts';
        // const endPoint = 'action_counts/guid/' + this.state.borehole_guid; // update endpoint
        // Save the new ActionCounts to IndexedDB
        saveToIDB(actionCounts, endPoint, true).then(() => {
            // console.log('ActionCounts saved successfully for NEW Previous Hole');

            // got to look for the response - TODO

            // Delete the '-new' record from IndexedDB
            del(`drillit-action_counts-new`).then(() => {
                // console.log('New action_counts deleted successfully from IndexedDB');
            }).catch(error => {
                console.error('Error deleting new action_counts from IndexedDB:', error);
            });

        
        }).catch((error: Error) => {
            // console.error("An error occurred creating actionCounts for NEW Previous Hole:", error);
            // Handle the error, e.g., display an error message to the user
        })
    }





    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const regex = /^[a-zA-Z0-9_.-]*$/;
        if (regex.test(value)) {
            this.setState({ holeName: value });
        }
    }







    render() {
        console.log('boreholes in CLASS:', this.state.boreholes)
        // console.log('company_guid in CLASS:', this.state.company_guid)
        // console.log('boreholeToUpdate in CLASS:', this.state.boreholeToUpdate)
        // console.log('boreholeInformation in CLASS:', this.state.boreholeInformation)
        // console.log('driller:', this.state.driller)
        // console.log('driller_id:', this.state.driller_id)
        // console.log('updatedDriller:', this.state.updatedDriller)
        // console.log('second_man:', this.state.second_man)
        // console.log('holeName:', this.state.holeName)
        // console.log('crewSelectionType:', this.state.crewSelectionType)
        // console.log('currentSPT_guid:', this.state.currentSPT_guid)
        // console.log('projectNo:', this.state.projectNo)
        // console.log('holeName:', this.state.holeName)
        // console.log('hole_barcode:', this.state.projectNo + '-' + this.state.holeName)
        // console.log('this.state.driller_cscs:', this.state.driller_cscs)
        // console.log('this.state.filteredCableBoreholes:', this.state.filteredCableBoreholes)
        // console.log('this.state.filteredRotaryBoreholes:', this.state.filteredRotaryBoreholes)
        // console.log('this.state.filteredWindowSampleBoreholes:', this.state.filteredWindowSampleBoreholes)
        // console.log('this.state.filtered_RT_WS_Boreholes:', this.state.filtered_RT_WS_Boreholes)
        // console.log('this.state.filtered_CP_WS_Boreholes:', this.state.filtered_CP_WS_Boreholes)

        // console.log('starting_hole_type_id:', this.state.starting_hole_type_id)
        // console.log('showFollowOnCable:', this.state.showFollowOnCable)
        // console.log('currentSPT_guid:', this.state.currentSPT_guid)
        console.log('duplicateHoleName:', this.state.duplicateHoleName)
        
        // console.log('this.state:', this.state)
        // console.log('this.state.currentRigMakeModel:', this.state.currentRigMakeModel)

        { !this.state.showFollowOnExisting &&
            <>
            {/* console.log('newPreviousDcounts:', this.state.newPreviousDcounts)
            console.log('newPreviousBcounts:', this.state.newPreviousBcounts)
            console.log('newPreviousEScounts:', this.state.newPreviousEScounts)
            console.log('newPreviousWScounts:', this.state.newPreviousWScounts)
            console.log('newPreviousSPTcounts:', this.state.newPreviousSPTcounts)
            console.log('newPreviousCPTcounts:', this.state.newPreviousCPTcounts)
            console.log('newPreviousUcounts:', this.state.newPreviousUcounts)
            console.log('newPreviousPScounts:', this.state.newPreviousPScounts)
            console.log('newPreviousSVcounts:', this.state.newPreviousSVcounts)
            console.log('newPreviousDepth:', this.state.newPreviousDepth)
            console.log('newPreviousType:', this.state.newPreviousType)
            console.log('newPreviousGuid:', this.state.newPreviousGuid)
            console.log('newPreviousName:', this.state.newPreviousName) */}
            </>
        }


        const { redirect } = this.state

        if (redirect) {
            // console.log('Redirect to:', `/projects/dashboard/${this.props.router.match.params.id}/${this.state.redirect_location}`)
            return <Redirect to={`/projects/dashboard/company/${this.state.company_guid}/${this.props.router.match.params.id}/${this.state.redirect_location}/newhole/${this.state.holeName}`} />
        }

        const inputLabel = this.state.hole + ' Name'

        return (
            <>

                <Header toggleSidebar={this.props.toggleSidebar} dark={true} />

                <Modal title={this.state.buttonText} toggleModal={() => this.props.router.history.goBack()}>


                {this.state.showCrewList &&

                    <CrewList
                        selectionType={this.state.crewSelectionType}
                        onClose={this.handleCrewListClose}
                        onSelectItem={this.handleCrewSelect}
                        companyID={this.state.company_id}
                    />
                }


                {this.state.showRigList &&

                    <RigList
                        onClose={this.handleRigListClose}
                        onSelectItem={this.handleRigSelect}
                        companyGuid={this.state.company_guid}
                    />
                }

                

                { this.state.showSaveButton &&
                    <div className="fixed inset-0 px-2 sm:px-4 md:px-20 pt-20 md:pt-32 z-40 overflow-y-auto" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                        <div className={`flex flex-col rounded-lg border border-orange bg-white px-2 sm:px-2 md:px-2 pb-2 modalshadow`}>
                            <div className="mt-4 mb-4 text-xl text-center"> Confirm {this.state.hole} Details</div>

                            <table className="table-auto w-full md:w-5/6 text-sm text-center bg-white border border-grey-mid mx-auto text-sm md:text-base mb-4">
                                <tbody>

                                    <tr>
                                        <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                            Hole Name:
                                        </td>
                                        <td className={`w-8/12 md:w-8/12 lg:w-1/2 p-1 pl-2 border-r border-b border-grey-mid text-left`}>
                                            <div>
                                                {this.state.holeName}
                                            </div>
                                        </td>
                                    </tr>

                                    
                                    {this.state.followOn &&

                                        <tr>
                                            <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                                Follow On:
                                            </td>
                                            <td className={`w-8/12 md:w-8/12 lg:w-1/2 p-1 pl-2 border-r border-b border-grey-mid text-left`}>
                                                <div>

                                                    { this.state.showFollowOnExisting &&
                                                    <>
                                                        {this.state.followOnHole} ({this.state.followOnHoleType})
                                                    </>
                                                    }

                                                    { !this.state.showFollowOnExisting &&
                                                    <>
                                                        <span className="uppercase"> {this.state.newPreviousName}</span> ({this.state.newPreviousType})
                                                    </>
                                                    }
                                                    
                                                </div>
                                            </td>
                                        </tr>
                                    }

                                    <tr>
                                        <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                            {this.state.userType}:
                                        </td>
                                        <td className={`w-8/12 md:w-8/12 lg:w-1/2 p-1 pl-2 border-r border-b border-grey-mid text-left`}>
                                            <div>
                                                {this.state.driller}
                                            </div>
                                        </td>
                                    </tr>

                                    
                                {this.state.showSecondMan &&

                                    <tr>
                                        <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                            Second Man:
                                        </td>
                                        <td className={`w-8/12 md:w-8/12 lg:w-1/2 p-1 pl-2 border-r border-b border-grey-mid text-left`}>
                                            <div>
                                                {/* Content to render when this.state.driller is set */}
                                                {this.state.second_man}
                                            </div>
                                        </td>
                                    </tr>
                                }

                                    
                                {this.state.showRig &&

                                    <>

                                    <tr>
                                        <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                            Rig:
                                        </td>
                                        <td className={`w-8/12 md:w-8/12 lg:w-1/2 p-1 pl-2 border-r border-b border-grey-mid text-left`}>
                                            <div>
                                                {/* Content to render when this.state.driller is set */}
                                                {this.state.currentRigLabel}
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                            Rig Type:
                                        </td>
                                        <td className={`w-8/12 md:w-8/12 lg:w-1/2 p-1 pl-2 border-r border-b border-grey-mid text-left`}>
                                            <div>
                                                {/* Content to render when this.state.driller is set */}
                                                {this.state.currentRigType}
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                            SPT:
                                        </td>
                                        <td className={`w-8/12 md:w-8/12 lg:w-1/2 p-1 pl-2 border-r border-b border-grey-mid text-left`}>
                                            <div>
                                                {/* Content to render when this.state.driller is set */}
                                                {this.state.currentSPT_label}
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                            SPT Energy Ratio:
                                        </td>
                                        <td className={`w-8/12 md:w-8/12 lg:w-1/2 p-1 pl-2 border-r border-b border-grey-mid text-left`}>
                                            <div>
                                                {/* Content to render when this.state.driller is set */}
                                                {this.state.currentSPT_energyRatio}
                                            </div>
                                        </td>
                                    </tr>

                                    </>
                                }

                                </tbody>
                            </table>
                            
                            <form onSubmit={this.next}>
                                <div className="px-4 md:px-14">
                                    {!this.state.followOn &&
                                        <NewBoreholeSaveButton canExit={false} 
                                        button_text= {this.state.buttonTextSave} //'SAVE BOREHOLE'
                                        />
                                    }

                                    {this.state.followOn && this.state.showFollowOnExisting && (
                                            <NewBoreholeSaveButton canExit={false} 
                                            button_text= {this.state.buttonTextSaveFollowOn} //'SAVE FOLLOW ON BOREHOLE'
                                            />
                                        )
                                    }

                                    {this.state.followOn && !this.state.showFollowOnExisting && (
                                            <NewBoreholeSaveButton canExit={false} 
                                            button_text= {this.state.buttonTextSaveFollowOn} //'SAVE FOLLOW ON BOREHOLE'
                                            />
                                        )
                                    }

                                </div>
                                <Button onClick={() => {
                                    this.handleCancelBoreholeClick()
                                }} type="submit" theme="secondary" className="w-40 py-1 text-xs mx-auto mt-4">Cancel</Button>
                            </form>
                        </div>
                    </div>
                    }






                {/* NEW / FOLLOW ON / ETC */}
                {/* CABLE */}
                { this.state.starting_hole_type_id === 1 &&

                    <div className="flex border border-grey-mid rounded p-1 bg-sptTB mb-4 w-full sm:w-5/6 mx-auto text-center">
                        <div className={`w-1/2 rounded text-white ${this.state.newHole ? 'bg-orange text-white' : 'bg-sptTB text-charcoal'}`}
                            onClick={() => {
                                this.setState({
                                    newHole: true,
                                    followOn: false,
                                    followOnHole: '',
                                    followOnHoleGuid: '',
                                    followOnHoleType: '',
                                })
                            }}>New Hole</div>
                        <div className={`w-1/2 rounded ${this.state.followOn ? 'bg-orange text-white' : 'bg-sptTB text-charcoal'}`}
                            onClick={() => {
                                this.setState({
                                    newHole: false,
                                    followOn: true,
                                })
                            }}>{this.state.hole_type} Follow On Hole</div>
                    </div>

                }
                {/* ROTARY */}
                { this.state.starting_hole_type_id === 3 &&

                    <div className="flex border border-grey-mid rounded p-1 bg-sptTB mb-4 w-full sm:w-5/6 mx-auto text-center">
                        <div className={`w-1/2 rounded text-white ${this.state.newHole ? 'bg-orange text-white' : 'bg-sptTB text-charcoal'}`}
                            onClick={() => {
                                this.setState({
                                    newHole: true,
                                    followOn: false,
                                    followOnHole: '',
                                    followOnHoleGuid: '',
                                    followOnHoleType: '',
                                })
                            }}>New Hole</div>
                        <div className={`w-1/2 rounded ${this.state.followOn ? 'bg-orange text-white' : 'bg-sptTB text-charcoal'}`}
                            onClick={() => {
                                this.setState({
                                    newHole: false,
                                    followOn: true,
                                })
                            }}>{this.state.hole_type} Follow On Hole</div>
                    </div>

                }






                    {/* HEADER */}

                    <div className="border border-grey-mid rounded px-2 pt-2 bg-sptTB mb-4 w-full sm:w-5/6 mx-auto text-center">
                        <div className="w-full md:w-2/3 lg:w-1/3 mx-auto mb-2 text-xl">

                            {inputLabel}


                            {/* <InputGroup label=""
                                value={this.state.holeName}
                                onChange={(value) => this.setState({ holeName: value })}
                                className="mb-1 text-sm sm:text-base md:text-base w-full"
                                inputCSS="text-sm sm:text-base md:text-2xl w-full py-0 font-bold text-center uppercase"
                            /> */}

                            {/* <InputGroup
                                label=""
                                value={this.state.holeName}
                                onChange={(value) => {
                                    // Validate input using regex
                                    const regex = /^[a-zA-Z0-9_.-]*$/;
                                    if (regex.test(value)) {
                                        this.setState({ holeName: value });
                                    }
                                }}
                                className="mb-1 text-sm sm:text-base md:text-base w-full"
                                inputCSS="text-2xl sm:text-2xl md:text-2xl w-full py-0 font-bold text-center uppercase"
                                ref={this.inputRef}
                            /> */}

                            <input
                                type="text"
                                value={this.state.holeName}
                                onChange={this.handleChange}
                                className="mb-1 text-sm sm:text-base md:text-base w-full text-2xl sm:text-2xl md:text-2xl w-full py-1 font-bold text-center uppercase focus:outline-none border border-grey-mid rounded"
                                ref={this.inputRef} // Assign the ref to the input element
                            />

                            { this.state.missingHoleName &&
                            
                                <div className="my-1 p-0.5 border-r border-b border-grey-mid text-center text-xs font-bold text-white bg-red">
                                    Hole Name: Please add a name for this hole!
                                </div>
                            }

                            { this.state.duplicateHoleName &&
                            
                                <div className="my-1 p-0.5 border-r border-b border-grey-mid text-center text-xs font-bold text-white bg-red">
                                    There is already a hole in this project called {this.state.holeName.toUpperCase()}.<br/>Please re-name this hole
                                </div>
                            }

                        </div>

                        <div className="text-center text-xs w-full">{this.state.borehole_guid}</div>

                    </div>

                    <div className="border border-grey-mid rounded px-2 py-2 bg-sptTB mb-4 w-full sm:w-5/6 mx-auto">

                        <table className="table-auto w-full md:w-5/6 text-sm text-center bg-white border border-grey-mid mx-auto text-base md:text-xl">
                            <tbody>
                                <tr>
                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                        {this.state.userType}:
                                    </td>
                                    <td className={`w-8/12 md:w-8/12 lg:w-1/2 p-1 pl-2 border-r border-b border-grey-mid text-left`}>
                                        {/* <div className="w-full" onClick={openCrewListForDriller}> */}
                                        {/* <div className="w-full"
                                            onClick={() => {
                                                this.setState({
                                                    crewSelectionType: 'driller',
                                                    showCrewList: true,
                                                })
                                            }}
                                        > */}
                                        <div className="w-full">
                                            <div>
                                                {this.state.driller !== '' ? (
                                                    <div>
                                                        {/* Content to render when this.state.driller is set */}
                                                        {this.state.driller}
                                                    </div>
                                                    ) : (
                                                    <div className="text-sm pl-2 text-blue">
                                                        {/* Loading indicator while waiting for this.state.driller */}
                                                        loading...
                                                        {/* <LoadingIndicator /> */}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                {this.state.showSecondMan &&
                                    <tr>
                                        <td className={`p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                            {this.state.second_man === '' && (
                                                <img src={warningIcon} alt="info" width="20" className="inline-block mr-2 pt-0 pb-1" />
                                            )}
                                            2nd Man:
                                        </td>
                                        <td className={`p-1 pl-2 border-r border-b border-grey-mid text-left`}>

                                            {/* <div className="w-full" onClick={openCrewListForSecondMan}> */}
                                            <div className="w-full"
                                                onClick={() => {
                                                    this.setState({
                                                        crewSelectionType: 'secondMan',
                                                        showCrewList: true,
                                                    })
                                                }}>
                                                {this.state.second_man !== '' ? (
                                                    <div>
                                                        {/* Content to render when this.state.driller is set */}
                                                        {this.state.second_man}
                                                    </div>
                                                    ) : (
                                                    <div className="text-sm pl-2 text-blue">
                                                        {/* Loading indicator while waiting for this.state.driller */}
                                                        loading...
                                                        {/* <LoadingIndicator /> */}
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                }
                                {this.state.showRig &&
                                <>
                                    <tr>
                                        <td className={`p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                            {this.state.currentRigLabel === '' || this.state.incorrectRig && (
                                                <img src={warningIcon} alt="info" width="20" className="inline-block mr-2 pt-0 pb-1" />
                                            )}
                                            Rig:
                                        </td>
                                        <td className={`p-1 pl-2 border-r border-b border-grey-mid text-left`}>
                                            {/* <RigSelect
                                                company={company_guid}
                                                project={projectId}
                                            /> */}

                                            {/* <Link to={`/rigs/0000/${this.state?.company_guid}/${this.props.router.match.params?.id}`}> */}
                                            {/* <Link to={this.state.plantCheckLink}> */}
                                            <div className="w-full"
                                                onClick={() => {
                                                    this.setState({
                                                        showRigList: true,
                                                    })
                                                }}>
                                                    {this.state.currentRigLabel !== '' ? (
                                                        <div>
                                                            {/* Content to render when this.state.driller is set */}
                                                            {this.state.currentRigLabel}
                                                        </div>
                                                        ) : (
                                                        <div className="text-sm pl-2 text-blue">
                                                            {/* Loading indicator while waiting for this.state.driller */}
                                                            loading...
                                                            {/* <LoadingIndicator /> */}
                                                        </div>
                                                    )}
                                                </div>
                                            {/* </Link> */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={`p-1 pr-2 border-r border-b border-grey-mid text-right text-xs`}>
                                            Rig Type:
                                        </td>
                                        <td className={`p-1 pl-2 border-r border-b border-grey-mid text-left text-xs`}>
                                            {this.state.currentRigType}
                                        </td>
                                    </tr>
                                    {this.state.incorrectRig && this.state.currentRigType !== '' &&
                                        <tr>
                                            <td colSpan={2} className="p-0.5 border-r border-b border-grey-mid text-center text-xs font-bold text-white bg-red">
                                                Incorrect Type Of Rig Selected!
                                            </td>
                                        </tr>
                                    }
                                    <tr>
                                        <td className={`p-1 pr-2 border-r border-b border-grey-mid text-right text-xs text-charcoal`}>
                                            SPT ID:
                                        </td>
                                        <td className={`p-1 pl-2 border-r border-b border-grey-mid text-left text-xs text-charcoal`}>
                                            {this.state.currentSPT_label}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={`p-1 pr-2 border-r border-b border-grey-mid text-right text-xs text-charcoal`}>
                                            Energy Ratio:
                                        </td>
                                        <td className={`p-1 pl-2 border-r border-b border-grey-mid text-left text-xs text-charcoal`}>
                                            {this.state.currentSPT_energyRatio}
                                        </td>
                                    </tr>
                                </>
                                }
                                {this.state.showPlantCheck &&
                                    <tr>
                                        <td className={`px-0 py-2  border-r border-grey-mid text-center text-base md:text-xl font-bold`} colSpan={2}>
                                            <img src={warningIcon} alt="info" width="22" className="inline-block mr-2 pt-0 pb-1" />
                                            {/* <Link to={this.state.plantCheckLink}> */}
                                                {/* <Link to={'/daily-plant-check-cable-percussive/0000/bc5dffcf-39ae-436e-8c77-35dfc5f01a20/0364bb29-8b3c-4e3f-a162-f69d68e505ba'}> */}
                                                Daily Plant Check Not Completed Today!
                                            {/* </Link> */}
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>

                        <div className="mt-4 mb-2">

                            { this.state.missingRig &&
                                <div className="my-1 p-0.5 border-r border-b border-grey-mid text-center text-xs font-bold text-white bg-red">
                                    Missing RIG: Please select a rig before proceeding!
                                </div>
                            }

                            { !this.state.missingRig && this.state.missingSPT &&
                                <div className="my-1 p-0.5 border-r border-b border-grey-mid text-center text-xs font-bold text-white bg-red">
                                    Missing SPT Details: Please re-select rig before proceeding!
                                </div>
                            }

                        </div>

                    </div>

                

                { !this.state.showSaveButton &&
                    <div>
                        { !this.state.followOn &&
                            <>
                            <Button
                                theme="primary"
                                size="lg"
                                className="btn-lg m-auto w-72"
                                type="submit"
                                onClick={() => {
                                    this.handleCreateBoreholeClick()
                                }}
                            >
                                {this.state.buttonText}
                            </Button>
                            </>
                        }
                    </div>
                }

                {/* END OF NEW HOLE */}


                {this.state.followOn &&
                <>
                    {/* Cable Follow On */}

                    {this.state.showFollowOnCable &&
                        <>
                            <div className={"border border-grey-mid rounded px-2 py-1 bg-sptTB mb-2 w-full sm:w-5/6 mx-auto"}>

                            <div className="text-center mt-0 mb-1 text-xl">
                                Cable Percussive Follow On
                            </div>

                            <div className="text-center mb-1 italic text-xs">
                                Continue an existing Rotary or Window Sample borehole. Depths and sample numbering will continue from where the previous borehole was completed!
                            </div>

                            <div className="flex border border-grey-mid rounded p-1 bg-sptTB mb-4 w-full sm:w-5/6 mx-auto text-center">
                                <div className={`w-1/2 rounded text-white ${this.state.showFollowOnExisting ? 'bg-orange text-white' : 'bg-sptTB text-charcoal'}`}
                                    onClick={() => {
                                        this.setState({
                                            showFollowOnExisting: true,
                                        })
                                    }}>Existing Hole</div>
                                <div className={`w-1/2 rounded ${!this.state.showFollowOnExisting ? 'bg-orange text-white' : 'bg-sptTB text-charcoal'}`}
                                    onClick={() => {
                                        this.setState({
                                            showFollowOnExisting: false,
                                        })
                                    }}>Not in project</div>
                            </div>

                            { this.state.showFollowOnExisting &&

                            <>

                                <div className="w-full rounded px-4 bg-modal-bg">
                                    <ButtonOptions onUpdate={(value: any) => {
                                        const selectedBorehole = this.state.filtered_RT_WS_Boreholes.find((borehole: Borehole) => borehole['hole_name'] === value);
                                        if (selectedBorehole) {
                                            
                                            this.setState({
                                                holeName: value,
                                                followOnHole: value,
                                                followOnInputValue: value,
                                                // followOnHoleGuid: 'Cable follow on to use this guid--> ' + selectedBorehole['guid'] // Update the guid,
                                                followOnHoleGuid: selectedBorehole['guid'], // Update the guid,
                                                followOnHoleType: selectedBorehole['activity_type'] // Update the activity_type,
                                            });
                                            // setFollowOnHole(value); // Update the selected hole name
                                            // setInputValue(value); // Update inputValue if needed
                                            // setFollowOnHoleGuid('Cable follow on to use this guid--> ' + selectedBorehole['guid']); // Update the guid
                                        }
                                    }}
                                        title=""
                                        defaultValue={this.state.followOnHole}
                                        options={this.state.filtered_RT_WS_Boreholes.map((borehole: Borehole) => borehole.hole_name)}
                                        columns={6}
                                        columnsMobile={4}
                                        className="mb-2 w-20 w-3/5 md:w-full text-sm md:text-lg py-3"
                                        buttonHeight={12}
                                        buttonHeightMobile={10}
                                    />
                                </div>

                                <div className="mb-1">
                                    <Button
                                        theme="primary"
                                        size="lg"
                                        className="btn-lg m-auto w-72 px-2"
                                        type="submit"
                                        onClick={() => {
                                            this.handleCreateBoreholeClick()
                                        }}
                                    >
                                        {this.state.buttonTextFollowOn}
                                    </Button>
                                </div>
                            </>

                            }

                            { !this.state.showFollowOnExisting &&

                            <>

                                <div className="w-full rounded px-0 bg-modal-bg">
                                    <table className="table-auto w-full text-sm my-2 text-center bg-white border border-grey-mid mx-auto text-base md:text-xl">
                                        <tbody>
                                            <tr>
                                                <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                                    Previous Hole Type:
                                                </td>
                                                <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-left`}>
                                                <ButtonOptions onUpdate={(value: any) => this.handleNewHoleTypeChange(value)}
                                                    defaultValue={this.state.newPreviousType}
                                                    title=""
                                                    columns={2}
                                                    columnsMobile={2}
                                                    buttonHeight={10}
                                                    buttonHeightMobile={12}
                                                    options={[
                                                        // {
                                                        //     value: 'Cable Percussive', label: <div className="text-sm sm:text-base md:text-lg">Cable</div>
                                                        // },
                                                        {
                                                            value: 'Rotary', label: <div className="text-sm sm:text-base md:text-lg">Rotary</div>
                                                        },
                                                        {
                                                            value: 'Window Sample', label: <div className="text-sm sm:text-base md:text-lg">Window Sample</div>
                                                        },
                                                    ]} className="mb-0" />

                                                    {this.state.showPreviousTypeError &&
                                                        <div className="my-1 p-0.5 border-r border-b border-grey-mid text-center text-xs font-bold text-white bg-red">
                                                            Please select a hole type before proceeding!
                                                        </div>
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                                    Previous Depth:
                                                </td>
                                                <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-left`}>
                                                    <InputGroupNumeric
                                                        label=""
                                                        value={this.state.newPreviousDepth}
                                                        onChange={this.handleNewHoleDepthChange}
                                                        className="mb-1 text-sm sm:text-base md:text-base w-full"
                                                        inputCSS="text-2xl sm:text-2xl md:text-2xl w-full pl-2 py-0.5 font-bold text-left"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                                    Previous Hole Name:
                                                </td>
                                                <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-left`}>
                                                    <InputGroup
                                                        label=""
                                                        value={this.state.newPreviousName}
                                                        onChange={(value) => {
                                                            // Validate input using regex
                                                            const regex = /^[a-zA-Z0-9_.-]*$/;
                                                            if (regex.test(value)) {
                                                                this.setState({ 
                                                                    newPreviousName: value,
                                                                    showPreviousNameError: false, 
                                                                });
                                                            }
                                                        }}
                                                        className="mb-1 text-sm sm:text-base md:text-base w-full"
                                                        inputCSS="text-2xl sm:text-2xl md:text-2xl w-full py-0 pl-1 font-bold text-left uppercase"
                                                    />

                                                    {this.state.showPreviousNameError &&
                                                        <div className="my-1 p-0.5 border-r border-b border-grey-mid text-center text-xs font-bold text-white bg-red">
                                                            Please enter the previous hole name before proceeding!
                                                        </div>
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                                    Previous D Samples:
                                                </td>
                                                <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-left`}>
                                                    <NumberIncrement defaultNumber={this.state.newPreviousDcounts} onChange={this.handleDCountChange} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                                    Previous B Samples:
                                                </td>
                                                <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-left`}>
                                                    <NumberIncrement defaultNumber={this.state.newPreviousBcounts} onChange={this.handleBCountChange} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                                    Previous ES Samples:
                                                </td>
                                                <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-left`}>
                                                    <NumberIncrement defaultNumber={this.state.newPreviousEScounts} onChange={this.handleESCountChange} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                                    Previous WS Samples:
                                                </td>
                                                <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-left`}>
                                                    <NumberIncrement defaultNumber={this.state.newPreviousWScounts} onChange={this.handleWSCountChange} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                                    Previous SPT's:
                                                </td>
                                                <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-left`}>
                                                    <NumberIncrement defaultNumber={this.state.newPreviousSPTcounts} onChange={this.handleSPTCountChange} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                                    Previous CPT's:
                                                </td>
                                                <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-left`}>
                                                    <NumberIncrement defaultNumber={this.state.newPreviousCPTcounts} onChange={this.handleCPTCountChange} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                                    Previous U Samples:
                                                </td>
                                                <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-left`}>
                                                    <NumberIncrement defaultNumber={this.state.newPreviousUcounts} onChange={this.handleUCountChange} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                                    Previous Piston Samples:
                                                </td>
                                                <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-left`}>
                                                    <NumberIncrement defaultNumber={this.state.newPreviousPScounts} onChange={this.handlePSCountChange} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                                    Previous Shear Vanes:
                                                </td>
                                                <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-left`}>
                                                    <NumberIncrement defaultNumber={this.state.newPreviousSVcounts} onChange={this.handleSVCountChange} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="mt-2 mb-1">
                                    <Button
                                        theme="primary"
                                        size="lg"
                                        className="btn-lg m-auto w-72 px-2"
                                        type="submit"
                                        onClick={() => {
                                            this.handleCreateBoreholeNewPreviousHoleClick()
                                        }}
                                    >
                                        Create Follow On From New Hole
                                    </Button>
                                </div>

                                </>
                            
                            }

                        </div>
                        </>
                    }


                    {/* Rotary Follow On */}

                    {this.state.showFollowOnRotary &&
                        <>
                            <div className="border border-grey-mid rounded px-2 py-1 bg-sptTB mb-2 w-full sm:w-5/6 mx-auto">

                                <div className="text-center mt-2 mb-1 text-xl">
                                    Rotary Follow On
                                </div>

                                <div className="text-center mb-1 italic text-xs">
                                    Continue an existing Cable Percussive or Window Sample borehole. Depths and sample numbering will continue from where the previous borehole was completed!
                                </div>

                                <div className="flex border border-grey-mid rounded p-1 bg-sptTB mb-4 w-full sm:w-5/6 mx-auto text-center">
                                    <div className={`w-1/2 rounded text-white ${this.state.showFollowOnExisting ? 'bg-orange text-white' : 'bg-sptTB text-charcoal'}`}
                                        onClick={() => {
                                            this.setState({
                                                showFollowOnExisting: true,
                                            })
                                        }}>Existing Hole</div>
                                    <div className={`w-1/2 rounded ${!this.state.showFollowOnExisting ? 'bg-orange text-white' : 'bg-sptTB text-charcoal'}`}
                                        onClick={() => {
                                            this.setState({
                                                showFollowOnExisting: false,
                                            })
                                        }}>Not in project</div>
                                </div>
                                

                            { this.state.showFollowOnExisting &&

                                <>

                                <div className="w-full rounded px-4 bg-modal-bg">
                                    <ButtonOptions onUpdate={(value: any) => {
                                        const selectedBorehole = this.state.filtered_CP_WS_Boreholes.find((borehole: Borehole) => borehole['hole_name'] === value);
                                        if (selectedBorehole) {
                                        
                                            this.setState({
                                                holeName: value,
                                                followOnHole: value,
                                                followOnInputValue: value,
                                                // followOnHoleGuid: 'Rotary follow on to use this guid--> ' + selectedBorehole['guid'] // Update the guid,
                                                followOnHoleGuid: selectedBorehole['guid'], // Update the guid,
                                                followOnHoleType: selectedBorehole['activity_type'] // Update the activity_type,
                                            });
                                            // setFollowOnHole(value); // Update the selected hole name
                                            // setInputValue(value); // Update inputValue if needed
                                            // setFollowOnHoleGuid('Rotary follow on to use this guid--> ' + selectedBorehole['guid']); // Update the guid
                                        }
                                    }}
                                    title=""
                                    defaultValue={this.state.followOnHole}
                                    options={this.state.filtered_CP_WS_Boreholes.map((borehole: Borehole) => borehole.hole_name)}
                                    columns={6}
                                    columnsMobile={4}
                                    className="mb-2 w-20 w-3/5 md:w-full text-sm md:text-lg py-3"
                                    buttonHeight={12}
                                    buttonHeightMobile={10}
                                    />
                                </div>

                                <div className="mb-1">
                                    <Button
                                        theme="primary"
                                        size="lg"
                                        className="btn-lg m-auto w-72 px-2"
                                        type="submit"
                                        onClick={() => {
                                            this.handleCreateBoreholeClick()
                                        }}
                                    >
                                        {this.state.buttonTextFollowOn}
                                    </Button>
                                </div>

                                </>
                            }

                            { !this.state.showFollowOnExisting &&
    
                                <>
    
                                    <div className="w-full rounded px-0 bg-modal-bg">
                                        <table className="table-auto w-full text-sm my-2 text-center bg-white border border-grey-mid mx-auto text-base md:text-xl">
                                            <tbody>
                                                <tr>
                                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                                        Previous Hole Type:
                                                    </td>
                                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-left`}>
                                                    <ButtonOptions onUpdate={(value: any) => this.handleNewHoleTypeChange(value)}
                                                        defaultValue={this.state.newPreviousType}
                                                        title=""
                                                        columns={2}
                                                        columnsMobile={2}
                                                        buttonHeight={10}
                                                        buttonHeightMobile={12}
                                                        options={[
                                                            // {
                                                            //     value: 'Cable Percussive', label: <div className="text-sm sm:text-base md:text-lg">Cable</div>
                                                            // },
                                                            {
                                                                value: 'Cable Percussive', label: <div className="text-sm sm:text-base md:text-lg">Cable</div>
                                                            },
                                                            {
                                                                value: 'Window Sample', label: <div className="text-sm sm:text-base md:text-lg">Window Sample</div>
                                                            },
                                                        ]} className="mb-0" />
    
                                                        {this.state.showPreviousTypeError &&
                                                            <div className="my-1 p-0.5 border-r border-b border-grey-mid text-center text-xs font-bold text-white bg-red">
                                                                Please select a hole type before proceeding!
                                                            </div>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                                        Previous Depth:
                                                    </td>
                                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-left`}>
                                                        <InputGroupNumeric
                                                            label=""
                                                            value={this.state.newPreviousDepth}
                                                            onChange={this.handleNewHoleDepthChange}
                                                            className="mb-1 text-sm sm:text-base md:text-base w-full"
                                                            inputCSS="text-2xl sm:text-2xl md:text-2xl w-full pl-2 py-0.5 font-bold text-left"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                                        Previous Hole Name:
                                                    </td>
                                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-left`}>
                                                        <InputGroup
                                                            label=""
                                                            value={this.state.newPreviousName}
                                                            onChange={(value) => {
                                                                // Validate input using regex
                                                                const regex = /^[a-zA-Z0-9_.-]*$/;
                                                                if (regex.test(value)) {
                                                                    this.setState({ 
                                                                        newPreviousName: value,
                                                                        showPreviousNameError: false, 
                                                                    });
                                                                }
                                                            }}
                                                            className="mb-1 text-sm sm:text-base md:text-base w-full"
                                                            inputCSS="text-2xl sm:text-2xl md:text-2xl w-full py-0 pl-1 font-bold text-left uppercase"
                                                        />
    
                                                        {this.state.showPreviousNameError &&
                                                            <div className="my-1 p-0.5 border-r border-b border-grey-mid text-center text-xs font-bold text-white bg-red">
                                                                Please enter the previous hole name before proceeding!
                                                            </div>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                                        Previous D Samples:
                                                    </td>
                                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-left`}>
                                                        <NumberIncrement defaultNumber={this.state.newPreviousDcounts} onChange={this.handleDCountChange} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                                        Previous B Samples:
                                                    </td>
                                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-left`}>
                                                        <NumberIncrement defaultNumber={this.state.newPreviousBcounts} onChange={this.handleBCountChange} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                                        Previous ES Samples:
                                                    </td>
                                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-left`}>
                                                        <NumberIncrement defaultNumber={this.state.newPreviousEScounts} onChange={this.handleESCountChange} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                                        Previous WS Samples:
                                                    </td>
                                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-left`}>
                                                        <NumberIncrement defaultNumber={this.state.newPreviousWScounts} onChange={this.handleWSCountChange} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                                        Previous SPT's:
                                                    </td>
                                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-left`}>
                                                        <NumberIncrement defaultNumber={this.state.newPreviousSPTcounts} onChange={this.handleSPTCountChange} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                                        Previous CPT's:
                                                    </td>
                                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-left`}>
                                                        <NumberIncrement defaultNumber={this.state.newPreviousCPTcounts} onChange={this.handleCPTCountChange} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                                        Previous U Samples:
                                                    </td>
                                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-left`}>
                                                        <NumberIncrement defaultNumber={this.state.newPreviousUcounts} onChange={this.handleUCountChange} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                                        Previous Piston Samples:
                                                    </td>
                                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-left`}>
                                                        <NumberIncrement defaultNumber={this.state.newPreviousPScounts} onChange={this.handlePSCountChange} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-right`}>
                                                        Previous Shear Vanes:
                                                    </td>
                                                    <td className={`w-4/12 md:w-4/12 lg:w-1/2 p-1 pr-2 border-r border-b border-grey-mid text-left`}>
                                                        <NumberIncrement defaultNumber={this.state.newPreviousSVcounts} onChange={this.handleSVCountChange} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
    
                                    <div className="mt-2 mb-1">
                                        <Button
                                            theme="primary"
                                            size="lg"
                                            className="btn-lg m-auto w-72 px-2"
                                            type="submit"
                                            onClick={() => {
                                                this.handleCreateBoreholeNewPreviousHoleClick()
                                            }}
                                        >
                                            Create Follow On From New Hole
                                        </Button>
                                    </div>
    
                                    </>
                                
                            }

                            </div>
                        </>
                    }


                    {/* Head Test */}

                    {this.state.showHeadTestInfo &&
                        <>
                            {/* <div className="border border-grey-mid rounded px-2 py-1 bg-sptTB mb-2 w-full sm:w-5/6 mx-auto hidden">

                            <div className="text-center mt-2 mb-1 text-xl">
                                Base Head Test on existing Borehole
                            </div>

                            <div className="text-center mb-1 italic text-xs">
                                Populate the dimensions of the Head Test from an existing borehole.
                            </div>

                            <div className="w-full rounded px-4 bg-modal-bg">
                                <ButtonOptions onUpdate={(value: any) => setFollowOnHole(value)}
                                    title=""
                                    defaultValue={followOnHole}
                                    options={['BH001', 'BH002', 'BH003', 'BH004', 'BH005', 'RT001', 'RT002', 'RT003', 'RT004', 'RT005', 'WS001', 'WS002', 'WS003', 'WS004']}
                                    columns={6}
                                    columnsMobile={4}
                                    className="mb-4 w-20 w-3/5 md:w-full text-sm md:text-lg py-2"
                                    buttonHeight={12}
                                    buttonHeightMobile={10}
                                />
                            </div>


                            <div className="w-full">
                                <Button theme="primary" size="lg" className="btn-lg mx-auto w-60" >Create Head Test</Button>
                            </div>

                        </div> */}
                        </>
                    }


                    {/* Soakaway */}

                    {this.state.showSoakawayInfo &&
                        <>
                            {/* <div className="border border-grey-mid rounded px-2 py-1 bg-sptTB mb-2 w-full sm:w-5/6 mx-auto hidden">

                            <div className="text-center mt-2 mb-1 text-xl">
                                Base Soakaway on existing Trialpit
                            </div>

                            <div className="text-center mb-1 italic text-xs">
                                Populate the dimensions of the Soakaway from an existing trialpit.
                            </div>

                            <div className="w-full rounded px-4 bg-modal-bg">
                                <ButtonOptions onUpdate={(value: any) => setFollowOnHole(value)}
                                    title=""
                                    defaultValue={followOnHole}
                                    options={['TP001', 'TP002', 'TP003', 'TP004', 'TP005']}
                                    columns={6}
                                    columnsMobile={4}
                                    className="mb-4 w-20 w-3/5 md:w-full text-sm md:text-lg py-4"
                                    buttonHeight={12}
                                    buttonHeightMobile={10}
                                />
                            </div>


                            <div className="w-30">
                                <Button theme="primary" size="lg" className="btn-lg m-auto" >Create Soakaway</Button>
                            </div>

                        </div> */}
                        </>
                    }

            </>
            }

                    {/* <div className="px-2 sm:px-10">


                        <>

                            { this.state.showSaveButton &&
                                <form onSubmit={this.next}>
                                    <div className="pt-5 pb-5 sm:pb-4 md:pb-10 px-4 md:px-14">
                                        <NewBoreholeSaveButton canExit={false} 
                                        button_text= {this.state.buttonTextSave} //'SAVE BOREHOLE'
                                        />
                                    </div>
                                </form>
                             }

                        </>
                    </div> */}
                </Modal>
            </>
        )
    }
}

export default CreateBorehole
