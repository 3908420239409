import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { incrementDate, decrementDate } from '../../actions/Date';
import { updateSelectedProject } from '../../actions/SelectedProject';
import dateChangeIcon from '../../assets/icons/date-change.svg';
import { useGetIDBValues } from "../../helpers/getIDBKey";

type Props = {
	className?: string;
	projectId?: string;
	onDateChange: (date: string) => void; // Define the callback function type
};

const defaultProps: Props = {
	className: '',
	projectId: '',
	onDateChange: () => { } // Provide a default empty function
};

const ProjectDate: FunctionComponent<Props> = ({ className, projectId, onDateChange }) => {
	const date = useSelector((state: any) => state.date);
	const selectedProject = useSelector((state: any) => state.selectedProject);
	const dispatch = useDispatch();
	const [showDate, setShowDate] = useState<string>('');

	useEffect(() => {
		// Determine the showDate value
		const datedate = date.toLocaleDateString("en-UK");
		const today = new Date();
		const todaydate = today.toLocaleDateString("en-UK");
		const dateToShow = datedate === todaydate ? 'TODAY' : date.toLocaleDateString("en-UK");
		const dateToReturn = date.toISOString().split('T')[0];

		// Update the state with showDate value
		setShowDate(dateToShow);

		// Pass the showDate value to the parent component
		onDateChange(dateToReturn);

	}, [date, onDateChange]);

	const backhandler = () => {
		dispatch(decrementDate());
	};

	const forwardhandler = () => {
		dispatch(incrementDate());
	};

	// Define the updateEmptyProject function
	const updateEmptyProject = () => {
		let cachedValues = useGetIDBValues;
		cachedValues('projects', (data: any) => {
			// cachedValues(`projects/company/${company_id}`, (data:any) => {
			for (const project of data) {
				if (project.guid === projectId) {
					dispatch(updateSelectedProject({
						guid: project.guid,
						name: project.name,
						location: project.location,
						number: project.project_identifier,
					}))
				}
			}
		}).then();
	}

	// Call updateEmptyProject when selectedProject.guid is empty
	if (selectedProject.guid === '') {
		updateEmptyProject();
	}

	return (
		<div className={`flex justify-center flex-col ${className}`}>
			<div className="flex mx-auto">
				<div className="flex bg-soil-greyish px-2 rounded-l" onClick={backhandler}>
					<img src={dateChangeIcon} width="12" className="transform rotate-180" alt="" />
				</div>
				<div className="bg-white px-5 py-1 text-sm font-bold text-lg leading-5 text-center w-36 border border-grey-dark">
					{showDate}
				</div>
				<div className="flex bg-soil-greyish px-2 rounded-r" onClick={forwardhandler}>
					<img src={dateChangeIcon} width="12" alt="" />
				</div>
			</div>
		</div>
	);
}

ProjectDate.defaultProps = defaultProps;

export default ProjectDate;
