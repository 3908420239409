import React, { FunctionComponent, useState } from 'react'

import closeIcon from '../assets/icons/close.svg'

type Props = {
	title: string,
	subtitle?: string,
	bg?: string,
	toggleModal: () => void,
	confirm?: boolean,
	children?: React.ReactNode,
    onSelect: (item: any) => void; // Add the onSelect prop
    // closeModal?: () => void; // Add onClose with an optional (or default) value
}

const defaultProps: Props = {
	title: '',
	subtitle: '',
	bg: 'bg-modal-bg',
	toggleModal: () => {},
	confirm: true,
	children: [],
    onSelect: () => {}, // Add a default onSelect function or pass () => {} as the default value
    // closeModal: () => {} // Provide a default function for onClose
}

const Modal: FunctionComponent<Props> = ({ title, subtitle, bg, toggleModal, confirm, children, }) => {
	
	const [closeConfirmOpen, setCloseConfirmOpen] = useState<boolean>(false)

	const closeModalAutoClose = () => {
		if(confirm) {
			setCloseConfirmOpen(true)
		} else {
			toggleModal()
		}
	}

	return (
		<>
			<div className="fixed inset-0 px-1 sm:px-4 md:px-8 py-2 sm:py-4 z-40 overflow-y-auto">
				<div className={`flex flex-col rounded-lg border border-orange ${bg} px-2 sm:px-2 md:px-2 pb-2 min-h-full modalshadow`}>
					<div className="flex items-center border-b border-grey-mid py-1">

						<div className="flex-1 flex items-left">
							<div className="flex-none">

								{/* <span className="mr-auto">{subtitle}</span> */}
								<div className="flex-none text-base font-bold text-center">
									{subtitle}
								</div>

								<div className="flex-none">
									{/* <ModalDate/>  // ONLY SHOW IF WORKFLOW STEP */}
									
								</div>

							</div>
						</div>

						<div className="flex-1 flex justify-center text-lg md:text-xl font-bold text-text leading-6 text-center mx-auto">
							{title}
						</div>

						<div className="flex-1 flex justify-center">
							<span className="ml-auto">
								<button className="flex items-center justify-center w-8 h-8 text-4xl bold text-soil-grey bg-white border border-orange rounded" onClick={() => closeModalAutoClose()}><img src={closeIcon} width="32" alt="" /></button>
							</span>
						</div>
					</div>

					{/* <div onClick={() => closeModalOnDelete()}>close</div> */}

					<div className="flex flex-col mt-2 flex-grow">
						{children}
					</div>
					<div className="hidden">{closeConfirmOpen}</div>
				</div>
			</div>
		</>
	)
}

Modal.defaultProps = defaultProps

export default Modal
