import React, { FunctionComponent, useState, useEffect } from 'react'

import WorkflowSteps from '../../WorkflowSteps'
import Modal from '../../Modal'
import Button from '../../form/Button'
// import NumberInput from '../../NumberInput'
// import InputGroup from '../../form/InputGroup'
import ButtonOptions from '../../form/ButtonOptions'
import MultiNumberInput from "../../MultiNumberInput";
import {v4 as uuidv4} from 'uuid';
import stepStatus from '../../../helpers/stepStatus'
// import warningIcon from '../../../assets/icons/warning.png'

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: string,
	defaultValues?: any,
    selectedDate?: string,
	update?: any,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => {},
	onComplete: () => {},
	borehole: '',
	defaultValues: {
		guid: '',
		pit_set_out_by: '',
		cat_sweep: '',
		excavation_type: '',
		pit_length: '',
		pit_width: '',
		pit_depth: '',
		pit_continued: '',
		pit_sides: '',
		slight_instability_from: '',
		slight_instability_to: '',
		collapsed_completely_from: '',
		collapsed_completely_to: '',
	},
    selectedDate: '',
}

const Trialpit: FunctionComponent<Props> = ({ onClose, update, onComplete, borehole, defaultValues, 
	selectedDate, client, company_name, setShowTrialpitEdit, ...rest }) => {
	const [currentStep, setCurrentStep] = useState(1)

	const [guid, setGuid] = useState(defaultValues.guid)
	const [catSweep, setCatSweep] = useState(defaultValues.cat_sweep)
	const [pitDimensionsLength, setPitDimensionsLength] = useState(defaultValues.pit_length)
	const [pitDimensionsWidth, setPitDimensionsWidth] = useState(defaultValues.pit_width)
	const [pitDimensionsDepth, setPitDimensionsDepth] = useState(defaultValues.pit_depth)
	const [pitContinued, setPitContinued] = useState(defaultValues.pit_continued)
	const [pitSides, setPitSides] = useState(defaultValues.pit_sides)
	const [slightInstabilityFrom, setSlightInstabilityFrom] = useState(defaultValues.slight_instability_from)
	const [slightInstabilityTo, setSlightInstabilityTo] = useState(defaultValues.slight_instability_to)
	const [collapsedCompletelyFrom, setCollapsedCompletelyFrom] = useState(defaultValues.collapsed_completely_from)
	const [collapsedCompletelyTo, setCollapsedCompletelyTo] = useState(defaultValues.collapsed_completely_to)
	
	const [saveButtonText, setButtonText] = useState('Continue')
	const [saveButtonColor, setButtonColor] = useState('primary')
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	// console.log('pitDimensionsLength:', pitDimensionsLength, defaultValues.pit_length)

	const [excavation_type, setType] = useState('')
	const [excavation_type_temp, setTypeTemp] = useState('')
	useEffect(() => {
        if(defaultValues.excavation_type === 'Hand Tools') {setType(defaultValues.excavation_type)}
        else if(defaultValues.excavation_type === 'Mini Digger') {setType(defaultValues.excavation_type)}
        else if(defaultValues.excavation_type === 'JCB3CX') {setType(defaultValues.excavation_type)}
        else if(defaultValues.excavation_type === '5 ton 360') {setType(defaultValues.excavation_type)}
        else if(defaultValues.excavation_type === '8 ton Tracked') {setType(defaultValues.excavation_type)}
		else {
			setType('Other')
			setTypeTemp(defaultValues.excavation_type)
		}
    }, [defaultValues.excavation_type]);

	console.log('defaultValues.excavation_type: ', defaultValues.excavation_type)

	// console.log('Client: ', client)
	let client_text = 'Client'
	if (client==='') {client_text = 'Client'}
	else {client_text = client}
	
	const [companyName, setCompanyName] = useState(company_name)
	const [pitSetOutBy, setPitSetOutBy] = useState(defaultValues.pit_set_out_by)
	const [pitSetOutByTemp, setPitSetOutByTemp] = useState('')
	useEffect(() => {
        if(defaultValues.pit_set_out_by === companyName) {setPitSetOutBy(companyName)}
        else if(defaultValues.pit_set_out_by === 'Client') {setPitSetOutBy('Client')}
        else if(defaultValues.pit_set_out_by === client) {setPitSetOutBy(client)}
		else {
			setPitSetOutBy('Other')
			setPitSetOutByTemp(defaultValues.pit_set_out_by)
		}
    }, [defaultValues.pit_set_out_by, company_name]);

	console.log('pitSetOutBy:', pitSetOutBy)
	
	// let pitSetOutByText = ''
	// if ((pitSetOutBy === 'Other' && pitSetOutByTemp === '') || (pitSetOutBy === '' && pitSetOutByTemp === '')) {
	// 	pitSetOutByText = ''
	// } else if (pitSetOutBy === client) {
	// 	pitSetOutByText = client
	// } else if (pitSetOutBy === companyName) {
	// 	pitSetOutByText = companyName
	// } else {
	// 	pitSetOutByText = pitSetOutByTemp
	// }
	// console.log('pitSetOutByText:', pitSetOutByText)

	const DeleteButton = () => {
		if (!update) return null;
	
		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	const deleteRecordConfirm = () => {
		setDeleteConfirmOpen(false)
		// onComplete()
	}

	const [time_of_action, setTimeOfAction] = useState(defaultValues.time_of_action)

    const isoDate = selectedDate ? `${new Date(selectedDate).toISOString().split('T')[0]} 09:00:00` : new Date().toISOString().split('.')[0]

    const previous = () => {
		if(currentStep > 1)
			setCurrentStep(currentStep - 1)
			setButtonText('Continue')
			setButtonColor('primary')
	}

	const next = () => {
		if(currentStep < 5) {
			setCurrentStep(currentStep + 1)
			if(currentStep === 4) {
				setButtonText('Save') 
				setButtonColor('success')
			}else{
				setButtonText('Continue')
				setButtonColor('primary')
			}
			return
		}

		onComplete({
			guid: guid ? guid : uuidv4(),
			pit_set_out_by: pitSetOutBy === 'Other' ? pitSetOutByTemp : pitSetOutBy,
			cat_sweep: catSweep,
			excavation_type: excavation_type === 'Other' ? excavation_type_temp : excavation_type,
            pit_length: isNaN(parseFloat(pitDimensionsLength)) ? '' : parseFloat(pitDimensionsLength).toFixed(2),
            pit_width: isNaN(parseFloat(pitDimensionsWidth)) ? '' : parseFloat(pitDimensionsWidth).toFixed(2),
            pit_depth: isNaN(parseFloat(pitDimensionsDepth)) ? '' : parseFloat(pitDimensionsDepth).toFixed(2),
            pit_continued: isNaN(parseFloat(pitContinued)) ? '' : parseFloat(pitContinued).toFixed(2),
			pit_sides: pitSides,
            slight_instability_from: isNaN(parseFloat(slightInstabilityFrom)) ? '' : parseFloat(slightInstabilityFrom).toFixed(2),
			slight_instability_to: isNaN(parseFloat(slightInstabilityTo)) ? '' : parseFloat(slightInstabilityTo).toFixed(2),
			collapsed_completely_from: isNaN(parseFloat(collapsedCompletelyFrom)) ? '' : parseFloat(collapsedCompletelyFrom).toFixed(2),
			collapsed_completely_to: isNaN(parseFloat(collapsedCompletelyTo)) ? '' : parseFloat(collapsedCompletelyTo).toFixed(2),
			general_remarks: '',
			update: update ? true : false,
            time_of_action: time_of_action ? time_of_action : isoDate,
		})
	}

	return (
		<Modal title="Trialpit Details" subtitle={borehole} toggleModal={onClose}>
			<div className="flex flex-col flex-grow px-1 sm:px-2 md:px-10">
				<WorkflowSteps steps={[
					{
						label: 'Details',
						status: stepStatus(1, currentStep),
						onClick: () => (setCurrentStep(1), setButtonText('Continue'), setButtonColor('primary'))
					}, {
						label: 'Dimensions',
						status: stepStatus(2, currentStep),
						onClick: () => (setCurrentStep(2), setButtonText('Continue'), setButtonColor('primary'))
					}, {
						label: 'Pit Sides',
						status: stepStatus(3, currentStep),
						onClick: () => (setCurrentStep(3), setButtonText('Continue'), setButtonColor('primary'))
					}, {
						label: 'Instability',
						status: stepStatus(4, currentStep),
						onClick: () => (setCurrentStep(4), setButtonText('Continue'), setButtonColor('primary'))
					}, {
						label: 'Collapsed',
						status: stepStatus(5, currentStep),
						onClick: () => (setCurrentStep(5), setButtonText('Save'), setButtonColor('success'))
					},
				]} className="mb-2" />

				<div className="flex-1 text-center">

					{currentStep === 1 &&
						<>
						<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
								<div className="font-bold text-center text-bold mb-3 text-lg">
								Pit set out by
								</div>
							<ButtonOptions onUpdate={(value: any) => setPitSetOutBy(value)} 
							defaultValue={pitSetOutBy} 
							title="" 
							columns={3} 
							columnsMobile={3} 
							buttonHeight={12}
							buttonHeightMobile={8}
							className="mb-4 w-20" 
							options={[
								client_text,
								companyName, // this is the Main Company Name
								'Other', // this requires keyboard input
							]} 
							/>

							{pitSetOutBy === 'Other' && (
								<textarea className="form-control w-full mb-2" 
								placeholder="Enter who set the pit out" 
								rows={2} 
								onChange={(event) => setPitSetOutByTemp(event.target.value)}
								value={pitSetOutByTemp}
								readOnly={false} // Ensure it's not set to true
								>
								</textarea>
							 )}
						</div>

						<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
								<div className="font-bold text-center text-bold mb-3 text-lg">
								CAT Sweep
								</div>
							<ButtonOptions onUpdate={(value: any) => setCatSweep(value)} 
							defaultValue={catSweep} 
							title="" 
							columns={3} 
							columnsMobile={3} 
							buttonHeight={12}
							buttonHeightMobile={8}
							className="mb-4 w-20 text-xs sm:text-lg" 
							options={[
								'OK',
								'Trace',
								'Pit Moved',
							]}  
							/>
						</div>

						<div className="border border-grey-mid rounded px-2 bg-sptTB mb-2">
								<div className="font-bold text-center text-bold mb-3 text-lg">
								Excavation Method
								</div>
							<ButtonOptions onUpdate={(value: any) => setType(value)} 
								defaultValue={excavation_type} 
								title=""
								columns={3} 
								columnsMobile={3} 
								buttonHeight={12}
								buttonHeightMobile={8}
								className="mb-4 w-20 text-xs sm:text-lg" 
								options={[
									'Hand Tools',
									//'Plant',
									'Mini Digger',
									'JCB3CX',
									'5 ton 360',
									'8 ton Tracked',
									'Other',
								]} 
							/>

							{excavation_type === 'Other' &&
								<textarea className="form-control w-full mb-auto" 
								placeholder="Enter the excavation method" 
								value={excavation_type_temp}
								rows={2} onChange={(event) => setTypeTemp(event.target.value)}></textarea>
							}
						</div>
						</>
					}

					{currentStep === 2 &&
						<>
								<MultiNumberInput
								title="Pit Dimensions" 
								values={
									[
										{defaultValue: pitDimensionsLength, onUpdate: setPitDimensionsLength, label: 'Length (m)'},
										{defaultValue: pitDimensionsWidth, onUpdate: setPitDimensionsWidth, label: 'Width (m)'},
										{defaultValue: pitDimensionsDepth, onUpdate: setPitDimensionsDepth, label: 'Depth (m)'},
										{defaultValue: pitContinued, onUpdate: setPitContinued, label: 'Continued / Complete at (m)'},
									]
								} 
								className="mb-12 md:mb-10 text-xs sm:text-base md:text-base" 
								inputCSS="text-2xl sm:text-2xl md:text-3xl lg:text-4xl h-14 sm:h-16 mb-4" 
								labelCSS="h-10 leading-4 pt-1" 
								/>

						</>
					}

					{currentStep === 3 &&
						<>

							<div className="border border-grey-mid rounded px-2 pb-8 bg-sptTB mb-2 w-full sm:w-4/6 mx-auto">
								<div className="font-bold text-center text-bold mb-3 text-lg">
									Pit Sides
								</div>

								<div className="flex-1 items-center">
									<ButtonOptions onUpdate={(value: any) => setPitSides(value)} 
									defaultValue={pitSides} 
									columns={2} 
									buttonHeight={12}
									buttonHeightMobile={8}
									gap={4}
									options={[
										'Stable',
										'Unstable',
									]} 
									next={next}
									className=" w-full sm:w-3/5 mx-auto text-xl" />
								</div>
							</div>
						</>
					}

					{currentStep === 4 &&
						<>
								<MultiNumberInput
								title="Slight instability at / in" 
								values={
									[
										{defaultValue: slightInstabilityFrom, onUpdate: setSlightInstabilityFrom, label: 'From (m)'},
										{defaultValue: slightInstabilityTo, onUpdate: setSlightInstabilityTo, label: 'To (m)'},
									]
								} 
								className="mb-12 md:mb-10 text-xs sm:text-base md:text-base" 
								inputCSS="text-2xl sm:text-2xl md:text-3xl lg:text-4xl h-14 sm:h-16 mb-4" 
								labelCSS="" 
								/>
						</>
					}

					{currentStep === 5 &&
						<>
									<MultiNumberInput
									title="Collapsed completely at / in" 
									values={
										[
											{defaultValue: collapsedCompletelyFrom, onUpdate: setCollapsedCompletelyFrom, label: 'From (m)'},
											{defaultValue: collapsedCompletelyTo, onUpdate: setCollapsedCompletelyTo, label: 'To (m)'},
										]
									} 
									className="mb-12 md:mb-10 text-xs sm:text-base md:text-base" 
									inputCSS="text-2xl sm:text-2xl md:text-3xl lg:text-4xl h-14 sm:h-16 mb-4" 
									labelCSS="" 
									/>
						</>
					}

				</div>
				

				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">
	
	
							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?
	
								<div className="flex mt-4">
									<Button theme="secondary" 
										className="mr-5 text-sm sm:text-base px-3.5 md:px-7" 
										onClick={() => setDeleteConfirmOpen(false)}>
											Cancel
									</Button>

									<Button onClick={() => {
												onClose()
												onComplete({
													guid: guid,
													update: false,
													delete: true,
												})
												setShowTrialpitEdit(false)
											}}
										className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">
										Delete
									</Button>
								</div>
							</div>
	
	
						</div>
					</div>
				}

				<div className="flex">
					{/* <Button theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button> */}
					<DeleteButton/>

					<div className="flex ml-auto">
						<Button theme="primary" size="lg" className="px-4 sm:px-10" onClick={() => previous()}>Back</Button>
						<Button theme={saveButtonColor} 
							size="lg" 
							className="ml-4 px-8 sm:px-10 w-32 sm:w-60" 
							onClick={() => next()}>{saveButtonText}
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

Trialpit.defaultProps = defaultProps

export default Trialpit
