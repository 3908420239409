import React, { FunctionComponent, useState, useEffect, Component } from 'react'

import { useLocation } from "react-router-dom";

import Header from '../layout/Header'

import ModalAutoClose from '../components/ModalAutoClose'
import WorkflowSteps from '../components/WorkflowSteps'
import NumberInput from '../components/NumberInput'
import Button from '../components/form/Button'
import InputGroup from '../components/form/InputGroup'
import TextArea from '../components/form/TextArea'
import WorkflowActions from '../components/form/WorkflowActions'

import stepStatus from '../helpers/stepStatus'

import Label from '../components/Label'
import { Link } from 'react-router-dom'
import RadioSelect from '../components/form/RadioSelect'

import { useGetIDBValues } from "../helpers/getIDBKey";
import { get, set } from 'idb-keyval'

type Props = {
	onClose: any,
	// onComplete: any,
	defaultValues?: any,
	projectId?: string,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => { },
	// onComplete: () => {},
	projectId: '',
	defaultValues: {
		guid: '',
	},
}

interface User {
	id: number;
	guid: string;
	first_name: string;
	last_name: string;
	company_id: number;
	// Add other properties as needed
}



const CrewList: FunctionComponent<Props> = ({ onClose, defaultValues, onSelectItem, selectionType, companyID, ...rest }) => {

	const [selectedName, setSelectedName] = useState('');

	console.log('selectionType:', selectionType)

	const handleItemClick = ({ item, selectionType }: { item: string; selectionType: string }) => {
		console.log('item:', item)
		if (item === 'none') {
		  const noneUser = {
			first_name: '',
			last_name: '',
			cscs_no: '',
		  };
	  
		  set(`drillit-secondman`, noneUser)
		  onSelectItem(noneUser);
		  onClose();
		} else {
		  // Find the selected user based on the username
		  const selectedUser = users.find(user => `${user.first_name} ${user.last_name}` === item);
	  
		  // Check if selectedUser is defined before proceeding
		  if (selectedUser) {
			if (selectionType === 'secondMan') {
			  // Save the full user details to the IndexedDB under a specific key
			  set(`drillit-secondman`, selectedUser)
				.then(() => {
				  // Additional actions after saving SecondMan details
				  // console.log('secondman details saved successfully to IDB');
				  // Perform any other actions or state updates here
				  onSelectItem(selectedUser);
				  onClose();
				})
				.catch((error) => {
				  // console.error('Error saving secondman details:', error);
				  // Handle the error if needed
				});
			} else if (selectionType === 'driller') {
			  // const { item, selectionType } = selection;
			  // setSelectedName(item);
			  onSelectItem(selectedUser);
			  onClose();
			}
	  
			// Add other conditions or actions based on selectionType if needed
			// ...
		  } else {
			// Handle the case where the user is not found
			console.error('Selected user not found or undefined.');
		  }
		}
	  };


	// const company_id = 1
	const [company_id, setCompany_id] = useState(companyID);

	const [users, setUsers] = useState<User[]>([]);

	useEffect(() => {
		// Fetch users data from IndexedDB or API and update state
		getUsers();
	}, []); // Empty dependency array ensures the effect runs only once when the component mounts

	const getUsers = () => {
		let cachedValues = useGetIDBValues;

		const companyKey = `users/company/${company_id}`;

		// Retrieve users for a specific company
		cachedValues(companyKey, (data: any) => {
			if (data && Array.isArray(data.users)) {

				data.users.push({
					first_name: 'none',
					last_name: '',
					cscs_no: '',
				  });

				setUsers(data.users); // Update state with user data
				console.log('user data:', data.users);
			} else {
				// console.error('Invalid user data structure:', data);
			}
		});
	};

  // Extract first_name and last_name into a new array
  let userNames = users.map(user => `${user.first_name} ${user.last_name}`);

  // Move the user with the name 'none' to the end of the array
  const noneIndex = userNames.indexOf('none');
  if (noneIndex !== -1) {
    userNames = [
      ...userNames.slice(0, noneIndex),
      ...userNames.slice(noneIndex + 1),
      'none', // add 'none' to the end
    ];
  }

	return (

		<>

			<ModalAutoClose title="Crew List" subtitle="" toggleModal={onClose} confirm={false} onSelect={(selection) => handleItemClick(selection)} >

				<table className="table-auto w-5/6 text-sm text-center bg-white border border-grey-mid mx-auto text-xl">
					<tbody>
					{userNames.map((userName, index) => (
						<tr key={index}>
							<td
								className={`px-4 py-2 border-r border-b border-grey-mid text-center cursor-pointer ${selectedName === userName ? 'bg-blue text-white' : ''
									}`}
								onClick={() => handleItemClick({ item: userName, selectionType })} // Pass both item and selectionType
							>
								{userName}
							</td>
						</tr>
					))}
					</tbody>
				</table>

			</ModalAutoClose>

		</>

	)
}

CrewList.defaultProps = defaultProps

export default CrewList