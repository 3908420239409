import React, { FunctionComponent, useState } from 'react'

import WorkflowSteps from '../../WorkflowSteps'
import Modal from '../../Modal'
import Button from '../../form/Button'
import ButtonOptions from '../../form/ButtonOptions'
import NumberInput from '../../NumberInput'
// import MultiNumberInput from '../../MultiNumberInput'

import { v4 as uuidv4 } from 'uuid';
import stepStatus from '../../../helpers/stepStatus'
//import TimeInput from "../../TimeInput";
// import DurationInput from "../../DurationInput"
import warningIcon from '../../../assets/icons/warning.png'

type Props = {
	onClose: any,
	onComplete: any,
	borehole?: string,
	defaultValues?: any,
	selectedDate?: string,
	update?: boolean,
	[x: string]: any;
}

const defaultProps: Props = {
	onClose: () => { },
	onComplete: () => { },
	borehole: '',
	defaultValues: {
		guid: '',
		depth_to_top: '',
		depth_to_base: '',
		//duration: '',
		duration: '00:00',
	},
	selectedDate: '',
}

const InspectionPit: FunctionComponent<Props> = ({ onClose, update, onComplete, borehole, defaultValues, selectedDate, ...rest }) => {
	const [currentStep, setCurrentStep] = useState(1)

	// const [guid, setGuid] = useState(defaultValues.guid)
	const guid = defaultValues.guid
	const [reaction_load, setReactionLoad] = useState(defaultValues.reaction_load)
	const [moisture_content, setMoistureContent] = useState(defaultValues.moisture_content)
	const [force_device_number, setForceDeviceNumber] = useState(defaultValues.force_device_number)
	const [diameter, setDiameter] = useState(defaultValues.diameter)

	const [saveButtonText, setButtonText] = useState('Continue')
	const [saveButtonColor, setButtonColor] = useState('primary')
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

	const DeleteButton = () => {
		if (!update) return null;

		return (
			<Button onClick={deleteRecord} theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button>
		);
	};

	const deleteRecord = () => {
		setDeleteConfirmOpen(true)
	}

	const deleteRecordConfirm = () => {
		setDeleteConfirmOpen(false)
		// onComplete()
	}

	// const [time_of_action, setTimeOfAction] = useState(defaultValues.time_of_action)
	const time_of_action = defaultValues.time_of_action

	const isoDate = selectedDate ? `${new Date(selectedDate).toISOString().split('T')[0]} 09:00:00` : new Date().toISOString().split('.')[0]

	const previous = () => {
		if (currentStep > 1)
			setCurrentStep(currentStep - 1)
		setButtonText('Continue')
		setButtonColor('primary')
	}

	const next = () => {
		if (currentStep < 4) {
			setCurrentStep(currentStep + 1)
			if (currentStep == 4) {
				setButtonText('Save')
				setButtonColor('success')
			} else {
				setButtonText('Continue')
				setButtonColor('primary')
			}
			return
		}

		if (!diameter) {
			//do nothing
		} else {

			onComplete({
				guid: update ? guid : uuidv4(),
				reaction_load: reaction_load,
				moisture_content: moisture_content,
				force_device_number: force_device_number,
				diameter: diameter,
				time_of_action: time_of_action ? time_of_action : isoDate,
				update: update ? true : false,
			})
		}
	}

	let requiredLoadText = ''
	let requiredDiameterText = ''

	if (!reaction_load) {
		requiredLoadText = "'Reaction Load' - "
	}

	if (!diameter) {
		requiredDiameterText = "'Plate Diameter' - "
	}

	const MissingRequiredField = () => {
		if (currentStep === 4 && (!diameter || !reaction_load)) return (
			<div className="text-center bg-sptSB border border-red font-bold rounded mb-2 py-1 text-xs md:text-sm lg:text-base">
				<img src={warningIcon} alt="info" width="20" className="inline-block mr-1 pb-1" />
				Required field: {requiredLoadText} {requiredDiameterText}
			</div>
		)

		return null
	}

	// console.log('depth_to_top: ', depth_to_top)
	// let depth_to_top2DP = ''
	// if (!depth_to_top) {
	// 	depth_to_top2DP = ''
	// } else if (depth_to_top === 'GL' || depth_to_top === 'G') {
	// 	depth_to_top2DP = 'GL'
	// } else {
	// 	depth_to_top2DP = parseFloat(depth_to_top).toFixed(2)
	// }

	// let depth_to_base2DP = ''
	// if (!depth_to_base) {
	// 	depth_to_base2DP = ''
	// } else {
	// 	depth_to_base2DP = parseFloat(depth_to_base).toFixed(2)
	// }

	return (
		<Modal title="Plate Test Setup" subtitle={borehole} toggleModal={onClose}>
			<div className="flex flex-col flex-grow px-1 sm:px-2 md:px-10">
				<WorkflowSteps steps={[
					{
						label: 'Reaction Load',
						status: stepStatus(1, currentStep),
						onClick: () => {
							setCurrentStep(1)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Moisture Content',
						status: stepStatus(2, currentStep),
						onClick: () => {
							setCurrentStep(2)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Force Device #',
						status: stepStatus(3, currentStep),
						onClick: () => {
							setCurrentStep(3)
							setButtonText('Continue')
							setButtonColor('primary')
						}
					}, {
						label: 'Plate Diameter',
						status: stepStatus(4, currentStep),
						onClick: () => {
							setCurrentStep(4)
							setButtonText('Save')
							setButtonColor('success')
						}
					},
				]} className="mb-2" />

				<div className="flex-1 flex">
					{currentStep === 1 &&
						<NumberInput onUpdate={(value: any) => setReactionLoad(value)}
							title="Enter Reaction Load"
							defaultValue={reaction_load}
							className="mb-10 text-2xl"
						/>
					}

					{currentStep === 2 &&
						<NumberInput onUpdate={(value: any) => setMoistureContent(value)}
							title="Moisture Content (%)"
							defaultValue={moisture_content}
							className="mb-10 text-2xl"
						/>
					}

					{currentStep === 3 &&
						//<TimeInput onUpdate={(value: any) => setTime(value)} defaultValue={duration} className="mb-10" />
						<NumberInput onUpdate={(value: any) => setForceDeviceNumber(value)}
							title="Force Device Number"
							defaultValue={force_device_number}
							className="mb-10 text-2xl"
						/>
					}

					{currentStep === 4 &&
						
						<ButtonOptions onUpdate={(value: any) => setDiameter(value)} defaultValue={diameter} options={[
							{ value: '300', label: <><div className="text-2xl sm:text-3xl pb-2 pt-0 sm:pt-4 h-40">300mm</div></> },
							{ value: '450', label: <><div className="text-2xl sm:text-3xl pb-2 pt-0 sm:pt-4 h-40">450mm</div></> },
							{ value: '600', label: <><div className="text-2xl sm:text-3xl pb-2 pt-0 sm:pt-4 h-40">600mm</div></> },
							{ value: '720', label: <><div className="text-2xl sm:text-3xl pb-2 pt-0 sm:pt-4 h-40">720mm</div></> },
						]}
							columns={4}
							columnsMobile={2}
							className="mb-4 w-20 text-base sm:text-lg"
							buttonHeight={20}
							buttonHeightMobile={12}
							title="Select Plate Diameter (mm)"
						/>
					}
				</div>

				<MissingRequiredField />


				{deleteConfirmOpen &&
					<div className="fixed inset-0 z-50">
						<div className="flex items-center justify-center min-h-full w-5/6 sm:w-7/12 md:w-7/12 lg:w-4/12 mx-auto">


							<div className="bg-white rounded-lg border-2 border-red px-8 py-6 text-center modalshadow">
								Are you sure you want to delete this record?

								<div className="flex mt-4">
									<Button theme="secondary" className="mr-5 text-sm sm:text-base px-3.5 md:px-7" onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>

									<Button onClick={deleteRecordConfirm} className="text-sm sm:text-base px-16 md:px-16 bg-red border-red">Delete</Button>
								</div>
							</div>


						</div>
					</div>
				}

				<div className="flex">
					{/* <Button theme="secondary" size="lg" id="deletebutt" className="px-2 sm:px-10">Delete</Button> */}
					<DeleteButton />

					<div className="flex ml-auto">
						<Button theme="primary" size="lg" className="px-4 sm:px-10" onClick={() => previous()}>Back</Button>
						<Button theme={saveButtonColor}
							size="lg"
							className="ml-4 px-8 sm:px-10 w-32 sm:w-60"
							onClick={() => next()}>{saveButtonText}
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

InspectionPit.defaultProps = defaultProps

export default InspectionPit
